package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseAssignmentMarkDao_JdbcKt(
  public val _db: DoorDatabase
) : CourseAssignmentMarkDao() {
  public val _insertAdapterCourseAssignmentMark_: EntityInsertionAdapter<CourseAssignmentMark> =
      object : EntityInsertionAdapter<CourseAssignmentMark>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CourseAssignmentMark (camUid, camAssignmentUid, camSubmitterUid, camMark, camPenalty, camLct) VALUES(?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseAssignmentMark (camUid, camAssignmentUid, camSubmitterUid, camMark, camPenalty, camLct) VALUES(COALESCE(?,nextval('CourseAssignmentMark_camUid_seq')), ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING camUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseAssignmentMark): Unit {
      if(entity.camUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.camUid)
      }
      stmt.setLong(2, entity.camAssignmentUid)
      stmt.setLong(3, entity.camSubmitterUid)
      stmt.setFloat(4, entity.camMark)
      stmt.setInt(5, entity.camPenalty)
      stmt.setLong(6, entity.camLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseAssignmentMarkReplicate(camPk, camDestination)
    |      SELECT DISTINCT CourseAssignmentMark.camUid AS camPk,
    |             ? AS camDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentMark
    |                    ON CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid
    |                    
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentMark.camLct != COALESCE(
    |             (SELECT camVersionId
    |                FROM CourseAssignmentMarkReplicate
    |               WHERE camPk = CourseAssignmentMark.camUid
    |                 AND camDestination = ?), 0) 
    |      /*psql ON CONFLICT(camPk, camDestination) DO UPDATE
    |             SET camPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentMarkReplicate(camPk, camDestination)
    |      SELECT DISTINCT CourseAssignmentMark.camUid AS camPk,
    |             ? AS camDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentMark
    |                    ON CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid
    |                    
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentMark.camLct != COALESCE(
    |             (SELECT camVersionId
    |                FROM CourseAssignmentMarkReplicate
    |               WHERE camPk = CourseAssignmentMark.camUid
    |                 AND camDestination = ?), 0) 
    |       ON CONFLICT(camPk, camDestination) DO UPDATE
    |             SET camPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO CourseAssignmentMarkReplicate(camPk, camDestination)
    |  SELECT DISTINCT CourseAssignmentMark.camUid AS camPk,
    |         UserSession.usClientNodeId AS camDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentMark
    |             ON ChangeLog.chTableId = 523
    |                AND ChangeLog.chEntityPk = CourseAssignmentMark.camUid
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentMark.camLct != COALESCE(
    |         (SELECT camVersionId
    |            FROM CourseAssignmentMarkReplicate
    |           WHERE camPk = CourseAssignmentMark.camUid
    |             AND camDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(camPk, camDestination) DO UPDATE
    |     SET camPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentMarkReplicate(camPk, camDestination)
    |  SELECT DISTINCT CourseAssignmentMark.camUid AS camPk,
    |         UserSession.usClientNodeId AS camDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentMark
    |             ON ChangeLog.chTableId = 523
    |                AND ChangeLog.chEntityPk = CourseAssignmentMark.camUid
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentMark.camLct != COALESCE(
    |         (SELECT camVersionId
    |            FROM CourseAssignmentMarkReplicate
    |           WHERE camPk = CourseAssignmentMark.camUid
    |             AND camDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(camPk, camDestination) DO UPDATE
    |     SET camPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getMarkOfAssignmentForSubmitterLiveData(assignmentUid: Long,
      submitterUid: Long): DoorLiveData<CourseAssignmentMark?> {
    val _result = DoorLiveDataImpl<CourseAssignmentMark?>(_db, listOf("CourseAssignmentMark"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.CourseAssignmentMark??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT * 
      |          FROM CourseAssignmentMark               
      |         WHERE camAssignmentUid = ?
      |           AND camSubmitterUid = ?
      |      ORDER BY camLct DESC
      |         LIMIT 1
      |    
      """.trimMargin() , postgreSql = """
      |
      |        SELECT * 
      |          FROM CourseAssignmentMark               
      |         WHERE camAssignmentUid = ?
      |           AND camSubmitterUid = ?
      |      ORDER BY camLct DESC
      |         LIMIT 1
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, assignmentUid)
        _stmt.setLong(2, submitterUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_camUid = _resultSet.getLong("camUid")
            val tmp_camAssignmentUid = _resultSet.getLong("camAssignmentUid")
            val tmp_camSubmitterUid = _resultSet.getLong("camSubmitterUid")
            val tmp_camMark = _resultSet.getFloat("camMark")
            val tmp_camPenalty = _resultSet.getInt("camPenalty")
            val tmp_camLct = _resultSet.getLong("camLct")
            val _entity = CourseAssignmentMark()
            _entity.camUid = tmp_camUid
            _entity.camAssignmentUid = tmp_camAssignmentUid
            _entity.camSubmitterUid = tmp_camSubmitterUid
            _entity.camMark = tmp_camMark
            _entity.camPenalty = tmp_camPenalty
            _entity.camLct = tmp_camLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun getMarkOfAssignmentForStudent(assignmentUid: Long, submitterUid: Long):
      CourseAssignmentMark? {
    var _result = null as com.ustadmobile.lib.db.entities.CourseAssignmentMark??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM CourseAssignmentMark
    |         WHERE camAssignmentUid = ?
    |           AND camSubmitterUid = ?
    |      ORDER BY camLct DESC
    |         LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM CourseAssignmentMark
    |         WHERE camAssignmentUid = ?
    |           AND camSubmitterUid = ?
    |      ORDER BY camLct DESC
    |         LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, assignmentUid)
      _stmt.setLong(2, submitterUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_camUid = _resultSet.getLong("camUid")
          val tmp_camAssignmentUid = _resultSet.getLong("camAssignmentUid")
          val tmp_camSubmitterUid = _resultSet.getLong("camSubmitterUid")
          val tmp_camMark = _resultSet.getFloat("camMark")
          val tmp_camPenalty = _resultSet.getInt("camPenalty")
          val tmp_camLct = _resultSet.getLong("camLct")
          val _entity = CourseAssignmentMark()
          _entity.camUid = tmp_camUid
          _entity.camAssignmentUid = tmp_camAssignmentUid
          _entity.camSubmitterUid = tmp_camSubmitterUid
          _entity.camMark = tmp_camMark
          _entity.camPenalty = tmp_camPenalty
          _entity.camLct = tmp_camLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findNextSubmitterToMarkForAssignment(assignmentUid: Long,
      submitterUid: Long): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         SELECT COALESCE((
    |            SELECT casSubmitterUid
    |              FROM CourseAssignmentSubmission
    |              
    |                   LEFT JOIN CourseAssignmentMark
    |                   ON CourseAssignmentMark.camSubmitterUid = CourseAssignmentSubmission.casSubmitterUid
    |                   AND CourseAssignmentMark.camAssignmentUid = ?
    |                   
    |             WHERE CourseAssignmentSubmission.casSubmitterUid != ?
    |               AND CourseAssignmentMark.camUid IS NULL
    |          GROUP BY casSubmitterUid
    |         LIMIT 1),0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |         SELECT COALESCE((
    |            SELECT casSubmitterUid
    |              FROM CourseAssignmentSubmission
    |              
    |                   LEFT JOIN CourseAssignmentMark
    |                   ON CourseAssignmentMark.camSubmitterUid = CourseAssignmentSubmission.casSubmitterUid
    |                   AND CourseAssignmentMark.camAssignmentUid = ?
    |                   
    |             WHERE CourseAssignmentSubmission.casSubmitterUid != ?
    |               AND CourseAssignmentMark.camUid IS NULL
    |          GROUP BY casSubmitterUid
    |         LIMIT 1),0)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, assignmentUid)
      _stmt.setLong(2, submitterUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: CourseAssignmentMark): Long {
    val _retVal = _insertAdapterCourseAssignmentMark_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseAssignmentMark): Long {
    val _retVal = _insertAdapterCourseAssignmentMark_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CourseAssignmentMark>): Unit {
    _insertAdapterCourseAssignmentMark_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseAssignmentMark>): Unit {
    val _sql =
        "UPDATE CourseAssignmentMark SET camAssignmentUid = ?, camSubmitterUid = ?, camMark = ?, camPenalty = ?, camLct = ? WHERE camUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.camAssignmentUid)
        _stmt.setLong(2, _entity.camSubmitterUid)
        _stmt.setFloat(3, _entity.camMark)
        _stmt.setInt(4, _entity.camPenalty)
        _stmt.setLong(5, _entity.camLct)
        _stmt.setLong(6, _entity.camUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseAssignmentMark): Unit {
    val _sql =
        "UPDATE CourseAssignmentMark SET camAssignmentUid = ?, camSubmitterUid = ?, camMark = ?, camPenalty = ?, camLct = ? WHERE camUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.camAssignmentUid)
      _stmt.setLong(2, entity.camSubmitterUid)
      _stmt.setFloat(3, entity.camMark)
      _stmt.setInt(4, entity.camPenalty)
      _stmt.setLong(5, entity.camLct)
      _stmt.setLong(6, entity.camUid)
      _stmt.executeUpdate()
    }
  }
}
