package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Clazz
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazz
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazzAndAttendance
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithPerson
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithClazzEnrolmentDetails
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ClazzEnrolmentDao_JdbcKt(
  public val _db: DoorDatabase
) : ClazzEnrolmentDao() {
  public val _insertAdapterClazzEnrolment_: EntityInsertionAdapter<ClazzEnrolment> = object :
      EntityInsertionAdapter<ClazzEnrolment>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ClazzEnrolment (clazzEnrolmentUid, clazzEnrolmentPersonUid, clazzEnrolmentClazzUid, clazzEnrolmentDateJoined, clazzEnrolmentDateLeft, clazzEnrolmentRole, clazzEnrolmentAttendancePercentage, clazzEnrolmentActive, clazzEnrolmentLeavingReasonUid, clazzEnrolmentOutcome, clazzEnrolmentLocalChangeSeqNum, clazzEnrolmentMasterChangeSeqNum, clazzEnrolmentLastChangedBy, clazzEnrolmentLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ClazzEnrolment (clazzEnrolmentUid, clazzEnrolmentPersonUid, clazzEnrolmentClazzUid, clazzEnrolmentDateJoined, clazzEnrolmentDateLeft, clazzEnrolmentRole, clazzEnrolmentAttendancePercentage, clazzEnrolmentActive, clazzEnrolmentLeavingReasonUid, clazzEnrolmentOutcome, clazzEnrolmentLocalChangeSeqNum, clazzEnrolmentMasterChangeSeqNum, clazzEnrolmentLastChangedBy, clazzEnrolmentLct) VALUES(COALESCE(?,nextval('ClazzEnrolment_clazzEnrolmentUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING clazzEnrolmentUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzEnrolment):
        Unit {
      if(entity.clazzEnrolmentUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzEnrolmentUid)
      }
      stmt.setLong(2, entity.clazzEnrolmentPersonUid)
      stmt.setLong(3, entity.clazzEnrolmentClazzUid)
      stmt.setLong(4, entity.clazzEnrolmentDateJoined)
      stmt.setLong(5, entity.clazzEnrolmentDateLeft)
      stmt.setInt(6, entity.clazzEnrolmentRole)
      stmt.setFloat(7, entity.clazzEnrolmentAttendancePercentage)
      stmt.setBoolean(8, entity.clazzEnrolmentActive)
      stmt.setLong(9, entity.clazzEnrolmentLeavingReasonUid)
      stmt.setInt(10, entity.clazzEnrolmentOutcome)
      stmt.setLong(11, entity.clazzEnrolmentLocalChangeSeqNum)
      stmt.setLong(12, entity.clazzEnrolmentMasterChangeSeqNum)
      stmt.setInt(13, entity.clazzEnrolmentLastChangedBy)
      stmt.setLong(14, entity.clazzEnrolmentLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzEnrolmentReplicate(cePk, ceDestination)
    |      SELECT DISTINCT ClazzEnrolment.clazzEnrolmentUid AS ceUid,
    |             ? AS ceDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                   ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    64 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |         
    |             JOIN ClazzEnrolment 
    |                   ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ClazzEnrolment.clazzEnrolmentLct != COALESCE(
    |             (SELECT ceVersionId
    |                FROM ClazzEnrolmentReplicate
    |               WHERE cePk = ClazzEnrolment.clazzEnrolmentUid
    |                 AND ceDestination = ?), 0) 
    |      /*psql ON CONFLICT(cePk, ceDestination) DO UPDATE
    |             SET cePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzEnrolmentReplicate(cePk, ceDestination)
    |      SELECT DISTINCT ClazzEnrolment.clazzEnrolmentUid AS ceUid,
    |             ? AS ceDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                   ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    64 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |         
    |             JOIN ClazzEnrolment 
    |                   ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ClazzEnrolment.clazzEnrolmentLct != COALESCE(
    |             (SELECT ceVersionId
    |                FROM ClazzEnrolmentReplicate
    |               WHERE cePk = ClazzEnrolment.clazzEnrolmentUid
    |                 AND ceDestination = ?), 0) 
    |       ON CONFLICT(cePk, ceDestination) DO UPDATE
    |             SET cePending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateClazzEnrolmentOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzEnrolmentReplicate(cePk, ceDestination)
    |  SELECT DISTINCT ClazzEnrolment.clazzEnrolmentUid AS ceUid,
    |         UserSession.usClientNodeId AS ceDestination
    |    FROM ChangeLog
    |         JOIN ClazzEnrolment
    |             ON ChangeLog.chTableId = 65
    |                AND ChangeLog.chEntityPk = ClazzEnrolment.clazzEnrolmentUid
    |         JOIN Clazz
    |             ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |             2
    |             
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzEnrolment.clazzEnrolmentLct != COALESCE(
    |         (SELECT ceVersionId
    |            FROM ClazzEnrolmentReplicate
    |           WHERE cePk = ClazzEnrolment.clazzEnrolmentUid
    |             AND ceDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cePk, ceDestination) DO UPDATE
    |     SET cePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzEnrolmentReplicate(cePk, ceDestination)
    |  SELECT DISTINCT ClazzEnrolment.clazzEnrolmentUid AS ceUid,
    |         UserSession.usClientNodeId AS ceDestination
    |    FROM ChangeLog
    |         JOIN ClazzEnrolment
    |             ON ChangeLog.chTableId = 65
    |                AND ChangeLog.chEntityPk = ClazzEnrolment.clazzEnrolmentUid
    |         JOIN Clazz
    |             ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |             2
    |             
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzEnrolment.clazzEnrolmentLct != COALESCE(
    |         (SELECT ceVersionId
    |            FROM ClazzEnrolmentReplicate
    |           WHERE cePk = ClazzEnrolment.clazzEnrolmentUid
    |             AND ceDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cePk, ceDestination) DO UPDATE
    |     SET cePending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insertListAsync(entityList: List<out ClazzEnrolment>): Unit {
    _insertAdapterClazzEnrolment_.insertList(entityList)
  }

  public override suspend fun findByPersonUidAndClazzUidAsync(personUid: Long, clazzUid: Long):
      ClazzEnrolment? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzEnrolment??
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentPersonUid = ? 
    |        AND clazzEnrolmentClazzUid = ? 
    |        AND clazzEnrolmentOutcome = 200 LIMIT 1
    """.trimMargin() , postgreSql = """
    |SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentPersonUid = ? 
    |        AND clazzEnrolmentClazzUid = ? 
    |        AND clazzEnrolmentOutcome = 200 LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, personUid)
      _stmt.setLong(2, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
          val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
          val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
          val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
          val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
          val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
          val tmp_clazzEnrolmentAttendancePercentage =
              _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
          val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
          val tmp_clazzEnrolmentLeavingReasonUid =
              _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
          val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
          val tmp_clazzEnrolmentLocalChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
          val tmp_clazzEnrolmentMasterChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
          val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
          val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
          val _entity = ClazzEnrolment()
          _entity.clazzEnrolmentUid = tmp_clazzEnrolmentUid
          _entity.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
          _entity.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
          _entity.clazzEnrolmentDateJoined = tmp_clazzEnrolmentDateJoined
          _entity.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
          _entity.clazzEnrolmentRole = tmp_clazzEnrolmentRole
          _entity.clazzEnrolmentAttendancePercentage = tmp_clazzEnrolmentAttendancePercentage
          _entity.clazzEnrolmentActive = tmp_clazzEnrolmentActive
          _entity.clazzEnrolmentLeavingReasonUid = tmp_clazzEnrolmentLeavingReasonUid
          _entity.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
          _entity.clazzEnrolmentLocalChangeSeqNum = tmp_clazzEnrolmentLocalChangeSeqNum
          _entity.clazzEnrolmentMasterChangeSeqNum = tmp_clazzEnrolmentMasterChangeSeqNum
          _entity.clazzEnrolmentLastChangedBy = tmp_clazzEnrolmentLastChangedBy
          _entity.clazzEnrolmentLct = tmp_clazzEnrolmentLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findAllEnrolmentsByPersonAndClazzUid(personUid: Long, clazzUid: Long):
      DoorDataSourceFactory<Int, ClazzEnrolmentWithLeavingReason> {
    val _result = object : DoorDataSourceFactory<Int, ClazzEnrolmentWithLeavingReason>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ClazzEnrolmentWithLeavingReason>> =
          DoorLiveDataImpl<List<ClazzEnrolmentWithLeavingReason>>(_db, listOf("ClazzEnrolment",
          "LeavingReason", "Clazz", "School"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT ClazzEnrolment.*, LeavingReason.*, 
        |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
        |         FROM ClazzEnrolment LEFT JOIN
        |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
        |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
        |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
        |        WHERE clazzEnrolmentPersonUid = ? 
        |        AND ClazzEnrolment.clazzEnrolmentActive 
        |        AND clazzEnrolmentClazzUid = ? ORDER BY clazzEnrolmentDateLeft DESC) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT ClazzEnrolment.*, LeavingReason.*, 
        |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
        |         FROM ClazzEnrolment LEFT JOIN
        |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
        |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
        |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
        |        WHERE clazzEnrolmentPersonUid = ? 
        |        AND ClazzEnrolment.clazzEnrolmentActive 
        |        AND clazzEnrolmentClazzUid = ? ORDER BY clazzEnrolmentDateLeft DESC) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, clazzUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_timeZone = _resultSet.getString("timeZone")
              val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
              val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
              val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
              val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
              val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
              val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
              val tmp_clazzEnrolmentAttendancePercentage =
                  _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
              val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
              val tmp_clazzEnrolmentLeavingReasonUid =
                  _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
              val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
              val tmp_clazzEnrolmentLocalChangeSeqNum =
                  _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
              val tmp_clazzEnrolmentMasterChangeSeqNum =
                  _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
              val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
              val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
              val _entity = ClazzEnrolmentWithLeavingReason()
              _entity.timeZone = tmp_timeZone
              _entity.clazzEnrolmentUid = tmp_clazzEnrolmentUid
              _entity.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
              _entity.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
              _entity.clazzEnrolmentDateJoined = tmp_clazzEnrolmentDateJoined
              _entity.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
              _entity.clazzEnrolmentRole = tmp_clazzEnrolmentRole
              _entity.clazzEnrolmentAttendancePercentage = tmp_clazzEnrolmentAttendancePercentage
              _entity.clazzEnrolmentActive = tmp_clazzEnrolmentActive
              _entity.clazzEnrolmentLeavingReasonUid = tmp_clazzEnrolmentLeavingReasonUid
              _entity.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
              _entity.clazzEnrolmentLocalChangeSeqNum = tmp_clazzEnrolmentLocalChangeSeqNum
              _entity.clazzEnrolmentMasterChangeSeqNum = tmp_clazzEnrolmentMasterChangeSeqNum
              _entity.clazzEnrolmentLastChangedBy = tmp_clazzEnrolmentLastChangedBy
              _entity.clazzEnrolmentLct = tmp_clazzEnrolmentLct
              var _leavingReason_nullFieldCount = 0
              val tmp_leavingReasonUid = _resultSet.getLong("leavingReasonUid")
              if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
              val tmp_leavingReasonTitle = _resultSet.getString("leavingReasonTitle")
              if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
              val tmp_leavingReasonMCSN = _resultSet.getLong("leavingReasonMCSN")
              if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
              val tmp_leavingReasonCSN = _resultSet.getLong("leavingReasonCSN")
              if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
              val tmp_leavingReasonLCB = _resultSet.getInt("leavingReasonLCB")
              if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
              val tmp_leavingReasonLct = _resultSet.getLong("leavingReasonLct")
              if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
              if(_leavingReason_nullFieldCount < 6) {
                if(_entity.leavingReason == null) {
                  _entity.leavingReason = LeavingReason()
                }
                _entity.leavingReason!!.leavingReasonUid = tmp_leavingReasonUid
                _entity.leavingReason!!.leavingReasonTitle = tmp_leavingReasonTitle
                _entity.leavingReason!!.leavingReasonMCSN = tmp_leavingReasonMCSN
                _entity.leavingReason!!.leavingReasonCSN = tmp_leavingReasonCSN
                _entity.leavingReason!!.leavingReasonLCB = tmp_leavingReasonLCB
                _entity.leavingReason!!.leavingReasonLct = tmp_leavingReasonLct
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ClazzEnrolment", "LeavingReason", "Clazz", "School"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT ClazzEnrolment.*, LeavingReason.*, 
        |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
        |         FROM ClazzEnrolment LEFT JOIN
        |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
        |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
        |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
        |        WHERE clazzEnrolmentPersonUid = ? 
        |        AND ClazzEnrolment.clazzEnrolmentActive 
        |        AND clazzEnrolmentClazzUid = ? ORDER BY clazzEnrolmentDateLeft DESC) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT ClazzEnrolment.*, LeavingReason.*, 
        |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
        |         FROM ClazzEnrolment LEFT JOIN
        |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
        |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
        |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
        |        WHERE clazzEnrolmentPersonUid = ? 
        |        AND ClazzEnrolment.clazzEnrolmentActive 
        |        AND clazzEnrolmentClazzUid = ? ORDER BY clazzEnrolmentDateLeft DESC) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, clazzUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun findEnrolmentWithLeavingReason(enrolmentUid: Long):
      ClazzEnrolmentWithLeavingReason? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason??
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT ClazzEnrolment.*, LeavingReason.*,
    |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
    |         FROM ClazzEnrolment LEFT JOIN
    |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
    |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
    |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
    |        WHERE ClazzEnrolment.clazzEnrolmentUid = ?
    """.trimMargin() , postgreSql = """
    |SELECT ClazzEnrolment.*, LeavingReason.*,
    |         COALESCE(Clazz.clazzTimeZone, COALESCE(School.schoolTimeZone, 'UTC')) as timeZone
    |         FROM ClazzEnrolment LEFT JOIN
    |        LeavingReason ON LeavingReason.leavingReasonUid = ClazzEnrolment.clazzEnrolmentLeavingReasonUid
    |        LEFT JOIN Clazz ON Clazz.clazzUid = ClazzEnrolment.clazzEnrolmentClazzUid
    |        LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid
    |        WHERE ClazzEnrolment.clazzEnrolmentUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, enrolmentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_timeZone = _resultSet.getString("timeZone")
          val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
          val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
          val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
          val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
          val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
          val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
          val tmp_clazzEnrolmentAttendancePercentage =
              _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
          val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
          val tmp_clazzEnrolmentLeavingReasonUid =
              _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
          val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
          val tmp_clazzEnrolmentLocalChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
          val tmp_clazzEnrolmentMasterChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
          val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
          val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
          val _entity = ClazzEnrolmentWithLeavingReason()
          _entity.timeZone = tmp_timeZone
          _entity.clazzEnrolmentUid = tmp_clazzEnrolmentUid
          _entity.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
          _entity.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
          _entity.clazzEnrolmentDateJoined = tmp_clazzEnrolmentDateJoined
          _entity.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
          _entity.clazzEnrolmentRole = tmp_clazzEnrolmentRole
          _entity.clazzEnrolmentAttendancePercentage = tmp_clazzEnrolmentAttendancePercentage
          _entity.clazzEnrolmentActive = tmp_clazzEnrolmentActive
          _entity.clazzEnrolmentLeavingReasonUid = tmp_clazzEnrolmentLeavingReasonUid
          _entity.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
          _entity.clazzEnrolmentLocalChangeSeqNum = tmp_clazzEnrolmentLocalChangeSeqNum
          _entity.clazzEnrolmentMasterChangeSeqNum = tmp_clazzEnrolmentMasterChangeSeqNum
          _entity.clazzEnrolmentLastChangedBy = tmp_clazzEnrolmentLastChangedBy
          _entity.clazzEnrolmentLct = tmp_clazzEnrolmentLct
          var _leavingReason_nullFieldCount = 0
          val tmp_leavingReasonUid = _resultSet.getLong("leavingReasonUid")
          if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
          val tmp_leavingReasonTitle = _resultSet.getString("leavingReasonTitle")
          if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
          val tmp_leavingReasonMCSN = _resultSet.getLong("leavingReasonMCSN")
          if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
          val tmp_leavingReasonCSN = _resultSet.getLong("leavingReasonCSN")
          if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
          val tmp_leavingReasonLCB = _resultSet.getInt("leavingReasonLCB")
          if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
          val tmp_leavingReasonLct = _resultSet.getLong("leavingReasonLct")
          if(_resultSet.wasNull()) { _leavingReason_nullFieldCount++ }
          if(_leavingReason_nullFieldCount < 6) {
            if(_entity.leavingReason == null) {
              _entity.leavingReason = LeavingReason()
            }
            _entity.leavingReason!!.leavingReasonUid = tmp_leavingReasonUid
            _entity.leavingReason!!.leavingReasonTitle = tmp_leavingReasonTitle
            _entity.leavingReason!!.leavingReasonMCSN = tmp_leavingReasonMCSN
            _entity.leavingReason!!.leavingReasonCSN = tmp_leavingReasonCSN
            _entity.leavingReason!!.leavingReasonLCB = tmp_leavingReasonLCB
            _entity.leavingReason!!.leavingReasonLct = tmp_leavingReasonLct
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateDateLeftByUid(
    clazzEnrolmentUid: Long,
    endDate: Long,
    updateTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ClazzEnrolment 
    |          SET clazzEnrolmentDateLeft = ?,
    |              clazzEnrolmentLct = ?
    |        WHERE clazzEnrolmentUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ClazzEnrolment 
    |          SET clazzEnrolmentDateLeft = ?,
    |              clazzEnrolmentLct = ?
    |        WHERE clazzEnrolmentUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, endDate)
      _stmt.setLong(2, updateTime)
      _stmt.setLong(3, clazzEnrolmentUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateAsync(entity: ClazzEnrolment): Int {
    var _result = 0
    val _sql =
        "UPDATE ClazzEnrolment SET clazzEnrolmentPersonUid = ?, clazzEnrolmentClazzUid = ?, clazzEnrolmentDateJoined = ?, clazzEnrolmentDateLeft = ?, clazzEnrolmentRole = ?, clazzEnrolmentAttendancePercentage = ?, clazzEnrolmentActive = ?, clazzEnrolmentLeavingReasonUid = ?, clazzEnrolmentOutcome = ?, clazzEnrolmentLocalChangeSeqNum = ?, clazzEnrolmentMasterChangeSeqNum = ?, clazzEnrolmentLastChangedBy = ?, clazzEnrolmentLct = ? WHERE clazzEnrolmentUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzEnrolmentPersonUid)
      _stmt.setLong(2, entity.clazzEnrolmentClazzUid)
      _stmt.setLong(3, entity.clazzEnrolmentDateJoined)
      _stmt.setLong(4, entity.clazzEnrolmentDateLeft)
      _stmt.setInt(5, entity.clazzEnrolmentRole)
      _stmt.setFloat(6, entity.clazzEnrolmentAttendancePercentage)
      _stmt.setBoolean(7, entity.clazzEnrolmentActive)
      _stmt.setLong(8, entity.clazzEnrolmentLeavingReasonUid)
      _stmt.setInt(9, entity.clazzEnrolmentOutcome)
      _stmt.setLong(10, entity.clazzEnrolmentLocalChangeSeqNum)
      _stmt.setLong(11, entity.clazzEnrolmentMasterChangeSeqNum)
      _stmt.setInt(12, entity.clazzEnrolmentLastChangedBy)
      _stmt.setLong(13, entity.clazzEnrolmentLct)
      _stmt.setLong(14, entity.clazzEnrolmentUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun findAllClazzesByPersonWithClazz(personUid: Long): DoorDataSourceFactory<Int,
      ClazzEnrolmentWithClazzAndAttendance> {
    val _result = object : DoorDataSourceFactory<Int, ClazzEnrolmentWithClazzAndAttendance>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ClazzEnrolmentWithClazzAndAttendance>> =
          DoorLiveDataImpl<List<ClazzEnrolmentWithClazzAndAttendance>>(_db,
          listOf("ClazzLogAttendanceRecord", "ClazzLog", "ClazzEnrolment", "Clazz"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazzAndAttendance>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT ClazzEnrolment.*, Clazz.*, (SELECT ((CAST(COUNT(DISTINCT CASE WHEN 
        |        ClazzLogAttendanceRecord.attendanceStatus = 1 THEN 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid ELSE NULL END) AS REAL) / 
        |        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
        |        FROM ClazzLogAttendanceRecord LEFT JOIN ClazzLog ON 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid WHERE 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = ? 
        |        AND ClazzLog.clazzLogClazzUid = Clazz.clazzUid AND ClazzLog.logDate 
        |        BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft) 
        |        as attendance
        |        FROM ClazzEnrolment
        |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
        |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
        |        AND ClazzEnrolment.clazzEnrolmentActive
        |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT ClazzEnrolment.*, Clazz.*, (SELECT ((CAST(COUNT(DISTINCT CASE WHEN 
        |        ClazzLogAttendanceRecord.attendanceStatus = 1 THEN 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid ELSE NULL END) AS REAL) / 
        |        GREATEST(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
        |        FROM ClazzLogAttendanceRecord LEFT JOIN ClazzLog ON 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid WHERE 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = ? 
        |        AND ClazzLog.clazzLogClazzUid = Clazz.clazzUid AND ClazzLog.logDate 
        |        BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft) 
        |        as attendance
        |        FROM ClazzEnrolment
        |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
        |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
        |        AND ClazzEnrolment.clazzEnrolmentActive
        |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, personUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_attendance = _resultSet.getFloat("attendance")
              val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
              val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
              val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
              val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
              val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
              val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
              val tmp_clazzEnrolmentAttendancePercentage =
                  _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
              val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
              val tmp_clazzEnrolmentLeavingReasonUid =
                  _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
              val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
              val tmp_clazzEnrolmentLocalChangeSeqNum =
                  _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
              val tmp_clazzEnrolmentMasterChangeSeqNum =
                  _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
              val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
              val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
              val _entity = ClazzEnrolmentWithClazzAndAttendance()
              _entity.attendance = tmp_attendance
              _entity.clazzEnrolmentUid = tmp_clazzEnrolmentUid
              _entity.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
              _entity.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
              _entity.clazzEnrolmentDateJoined = tmp_clazzEnrolmentDateJoined
              _entity.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
              _entity.clazzEnrolmentRole = tmp_clazzEnrolmentRole
              _entity.clazzEnrolmentAttendancePercentage = tmp_clazzEnrolmentAttendancePercentage
              _entity.clazzEnrolmentActive = tmp_clazzEnrolmentActive
              _entity.clazzEnrolmentLeavingReasonUid = tmp_clazzEnrolmentLeavingReasonUid
              _entity.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
              _entity.clazzEnrolmentLocalChangeSeqNum = tmp_clazzEnrolmentLocalChangeSeqNum
              _entity.clazzEnrolmentMasterChangeSeqNum = tmp_clazzEnrolmentMasterChangeSeqNum
              _entity.clazzEnrolmentLastChangedBy = tmp_clazzEnrolmentLastChangedBy
              _entity.clazzEnrolmentLct = tmp_clazzEnrolmentLct
              var _clazz_nullFieldCount = 0
              val tmp_clazzUid = _resultSet.getLong("clazzUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzName = _resultSet.getString("clazzName")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzDesc = _resultSet.getString("clazzDesc")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzLct = _resultSet.getLong("clazzLct")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzStudentsPersonGroupUid =
                  _resultSet.getLong("clazzStudentsPersonGroupUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzTeachersPersonGroupUid =
                  _resultSet.getLong("clazzTeachersPersonGroupUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzPendingStudentsPersonGroupUid =
                  _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              val tmp_clazzCode = _resultSet.getString("clazzCode")
              if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
              if(_clazz_nullFieldCount < 24) {
                if(_entity.clazz == null) {
                  _entity.clazz = Clazz()
                }
                _entity.clazz!!.clazzUid = tmp_clazzUid
                _entity.clazz!!.clazzName = tmp_clazzName
                _entity.clazz!!.clazzDesc = tmp_clazzDesc
                _entity.clazz!!.attendanceAverage = tmp_attendanceAverage
                _entity.clazz!!.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
                _entity.clazz!!.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
                _entity.clazz!!.isClazzActive = tmp_isClazzActive
                _entity.clazz!!.clazzLocationUid = tmp_clazzLocationUid
                _entity.clazz!!.clazzStartTime = tmp_clazzStartTime
                _entity.clazz!!.clazzEndTime = tmp_clazzEndTime
                _entity.clazz!!.clazzFeatures = tmp_clazzFeatures
                _entity.clazz!!.clazzSchoolUid = tmp_clazzSchoolUid
                _entity.clazz!!.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
                _entity.clazz!!.clazzTerminologyUid = tmp_clazzTerminologyUid
                _entity.clazz!!.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
                _entity.clazz!!.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
                _entity.clazz!!.clazzLastChangedBy = tmp_clazzLastChangedBy
                _entity.clazz!!.clazzLct = tmp_clazzLct
                _entity.clazz!!.clazzTimeZone = tmp_clazzTimeZone
                _entity.clazz!!.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
                _entity.clazz!!.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
                _entity.clazz!!.clazzPendingStudentsPersonGroupUid =
                    tmp_clazzPendingStudentsPersonGroupUid
                _entity.clazz!!.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
                _entity.clazz!!.clazzCode = tmp_clazzCode
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ClazzLogAttendanceRecord", "ClazzLog", "ClazzEnrolment", "Clazz"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT ClazzEnrolment.*, Clazz.*, (SELECT ((CAST(COUNT(DISTINCT CASE WHEN 
        |        ClazzLogAttendanceRecord.attendanceStatus = 1 THEN 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid ELSE NULL END) AS REAL) / 
        |        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
        |        FROM ClazzLogAttendanceRecord LEFT JOIN ClazzLog ON 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid WHERE 
        |        ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = ? 
        |        AND ClazzLog.clazzLogClazzUid = Clazz.clazzUid AND ClazzLog.logDate 
        |        BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined AND ClazzEnrolment.clazzEnrolmentDateLeft) 
        |        as attendance
        |        FROM ClazzEnrolment
        |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid
        |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
        |        AND ClazzEnrolment.clazzEnrolmentActive
        |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
        |    ) 
        """.trimMargin() )
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, personUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun findMaxEndDateForEnrolment(
    selectedClazz: Long,
    selectedPerson: Long,
    selectedEnrolment: Long
  ): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT COALESCE(MAX(clazzEnrolmentDateLeft),0) FROM ClazzEnrolment WHERE 
    |        ClazzEnrolment.clazzEnrolmentPersonUid = ? 
    |        AND ClazzEnrolment.clazzEnrolmentActive 
    |        AND clazzEnrolmentClazzUid = ? AND clazzEnrolmentUid != ?
    |    
    """.trimMargin() , postgreSql = """
    |SELECT COALESCE(MAX(clazzEnrolmentDateLeft),0) FROM ClazzEnrolment WHERE 
    |        ClazzEnrolment.clazzEnrolmentPersonUid = ? 
    |        AND ClazzEnrolment.clazzEnrolmentActive 
    |        AND clazzEnrolmentClazzUid = ? AND clazzEnrolmentUid != ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, selectedPerson)
      _stmt.setLong(2, selectedClazz)
      _stmt.setLong(3, selectedEnrolment)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findAllClazzesByPersonWithClazzAsListAsync(personUid: Long):
      List<ClazzEnrolmentWithClazz> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazz>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT ClazzEnrolment.*, Clazz.* 
    |        FROM ClazzEnrolment 
    |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
    |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ? 
    |        AND ClazzEnrolment.clazzEnrolmentActive
    |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
    |    
    """.trimMargin() , postgreSql = """
    |SELECT ClazzEnrolment.*, Clazz.* 
    |        FROM ClazzEnrolment 
    |        LEFT JOIN Clazz ON ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
    |        WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ? 
    |        AND ClazzEnrolment.clazzEnrolmentActive
    |        ORDER BY ClazzEnrolment.clazzEnrolmentDateLeft DESC
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, personUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
          val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
          val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
          val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
          val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
          val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
          val tmp_clazzEnrolmentAttendancePercentage =
              _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
          val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
          val tmp_clazzEnrolmentLeavingReasonUid =
              _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
          val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
          val tmp_clazzEnrolmentLocalChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
          val tmp_clazzEnrolmentMasterChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
          val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
          val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
          val _entity = ClazzEnrolmentWithClazz()
          _entity.clazzEnrolmentUid = tmp_clazzEnrolmentUid
          _entity.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
          _entity.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
          _entity.clazzEnrolmentDateJoined = tmp_clazzEnrolmentDateJoined
          _entity.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
          _entity.clazzEnrolmentRole = tmp_clazzEnrolmentRole
          _entity.clazzEnrolmentAttendancePercentage = tmp_clazzEnrolmentAttendancePercentage
          _entity.clazzEnrolmentActive = tmp_clazzEnrolmentActive
          _entity.clazzEnrolmentLeavingReasonUid = tmp_clazzEnrolmentLeavingReasonUid
          _entity.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
          _entity.clazzEnrolmentLocalChangeSeqNum = tmp_clazzEnrolmentLocalChangeSeqNum
          _entity.clazzEnrolmentMasterChangeSeqNum = tmp_clazzEnrolmentMasterChangeSeqNum
          _entity.clazzEnrolmentLastChangedBy = tmp_clazzEnrolmentLastChangedBy
          _entity.clazzEnrolmentLct = tmp_clazzEnrolmentLct
          var _clazz_nullFieldCount = 0
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzName = _resultSet.getString("clazzName")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          if(_resultSet.wasNull()) { _clazz_nullFieldCount++ }
          if(_clazz_nullFieldCount < 24) {
            if(_entity.clazz == null) {
              _entity.clazz = Clazz()
            }
            _entity.clazz!!.clazzUid = tmp_clazzUid
            _entity.clazz!!.clazzName = tmp_clazzName
            _entity.clazz!!.clazzDesc = tmp_clazzDesc
            _entity.clazz!!.attendanceAverage = tmp_attendanceAverage
            _entity.clazz!!.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
            _entity.clazz!!.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
            _entity.clazz!!.isClazzActive = tmp_isClazzActive
            _entity.clazz!!.clazzLocationUid = tmp_clazzLocationUid
            _entity.clazz!!.clazzStartTime = tmp_clazzStartTime
            _entity.clazz!!.clazzEndTime = tmp_clazzEndTime
            _entity.clazz!!.clazzFeatures = tmp_clazzFeatures
            _entity.clazz!!.clazzSchoolUid = tmp_clazzSchoolUid
            _entity.clazz!!.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
            _entity.clazz!!.clazzTerminologyUid = tmp_clazzTerminologyUid
            _entity.clazz!!.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
            _entity.clazz!!.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
            _entity.clazz!!.clazzLastChangedBy = tmp_clazzLastChangedBy
            _entity.clazz!!.clazzLct = tmp_clazzLct
            _entity.clazz!!.clazzTimeZone = tmp_clazzTimeZone
            _entity.clazz!!.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
            _entity.clazz!!.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
            _entity.clazz!!.clazzPendingStudentsPersonGroupUid =
                tmp_clazzPendingStudentsPersonGroupUid
            _entity.clazz!!.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
            _entity.clazz!!.clazzCode = tmp_clazzCode
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun getAllClazzEnrolledAtTimeAsync(
    clazzUid: Long,
    date: Long,
    roleFilter: Int,
    personUidFilter: Long
  ): List<ClazzEnrolmentWithPerson> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ClazzEnrolmentWithPerson>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ClazzEnrolment.*, Person.*
    |          FROM ClazzEnrolment
    |    LEFT JOIN Person ON ClazzEnrolment.clazzEnrolmentPersonUid = Person.personUid
    |        WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ?
    |              AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
    |              AND ClazzEnrolment.clazzEnrolmentDateLeft
    |              AND CAST(clazzEnrolmentActive AS INTEGER) = 1
    |              AND (? = 0 OR ClazzEnrolment.clazzEnrolmentRole = ?)
    |              AND (? = 0 OR ClazzEnrolment.clazzEnrolmentPersonUid = ?)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ClazzEnrolment.*, Person.*
    |          FROM ClazzEnrolment
    |    LEFT JOIN Person ON ClazzEnrolment.clazzEnrolmentPersonUid = Person.personUid
    |        WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ?
    |              AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
    |              AND ClazzEnrolment.clazzEnrolmentDateLeft
    |              AND CAST(clazzEnrolmentActive AS INTEGER) = 1
    |              AND (? = 0 OR ClazzEnrolment.clazzEnrolmentRole = ?)
    |              AND (? = 0 OR ClazzEnrolment.clazzEnrolmentPersonUid = ?)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.setLong(2, date)
      _stmt.setInt(3, roleFilter)
      _stmt.setInt(4, roleFilter)
      _stmt.setLong(5, personUidFilter)
      _stmt.setLong(6, personUidFilter)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
          val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
          val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
          val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
          val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
          val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
          val tmp_clazzEnrolmentAttendancePercentage =
              _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
          val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
          val tmp_clazzEnrolmentLeavingReasonUid =
              _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
          val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
          val tmp_clazzEnrolmentLocalChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
          val tmp_clazzEnrolmentMasterChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
          val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
          val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
          val _entity = ClazzEnrolmentWithPerson()
          _entity.clazzEnrolmentUid = tmp_clazzEnrolmentUid
          _entity.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
          _entity.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
          _entity.clazzEnrolmentDateJoined = tmp_clazzEnrolmentDateJoined
          _entity.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
          _entity.clazzEnrolmentRole = tmp_clazzEnrolmentRole
          _entity.clazzEnrolmentAttendancePercentage = tmp_clazzEnrolmentAttendancePercentage
          _entity.clazzEnrolmentActive = tmp_clazzEnrolmentActive
          _entity.clazzEnrolmentLeavingReasonUid = tmp_clazzEnrolmentLeavingReasonUid
          _entity.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
          _entity.clazzEnrolmentLocalChangeSeqNum = tmp_clazzEnrolmentLocalChangeSeqNum
          _entity.clazzEnrolmentMasterChangeSeqNum = tmp_clazzEnrolmentMasterChangeSeqNum
          _entity.clazzEnrolmentLastChangedBy = tmp_clazzEnrolmentLastChangedBy
          _entity.clazzEnrolmentLct = tmp_clazzEnrolmentLct
          var _person_nullFieldCount = 0
          val tmp_personUid = _resultSet.getLong("personUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_username = _resultSet.getString("username")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_firstNames = _resultSet.getString("firstNames")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_lastName = _resultSet.getString("lastName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_gender = _resultSet.getInt("gender")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_active = _resultSet.getBoolean("active")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_admin = _resultSet.getBoolean("admin")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personNotes = _resultSet.getString("personNotes")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherName = _resultSet.getString("fatherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherName = _resultSet.getString("motherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherNum = _resultSet.getString("motherNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personAddress = _resultSet.getString("personAddress")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLct = _resultSet.getLong("personLct")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personCountry = _resultSet.getString("personCountry")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personType = _resultSet.getInt("personType")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          if(_person_nullFieldCount < 24) {
            if(_entity.person == null) {
              _entity.person = Person()
            }
            _entity.person!!.personUid = tmp_personUid
            _entity.person!!.username = tmp_username
            _entity.person!!.firstNames = tmp_firstNames
            _entity.person!!.lastName = tmp_lastName
            _entity.person!!.emailAddr = tmp_emailAddr
            _entity.person!!.phoneNum = tmp_phoneNum
            _entity.person!!.gender = tmp_gender
            _entity.person!!.active = tmp_active
            _entity.person!!.admin = tmp_admin
            _entity.person!!.personNotes = tmp_personNotes
            _entity.person!!.fatherName = tmp_fatherName
            _entity.person!!.fatherNumber = tmp_fatherNumber
            _entity.person!!.motherName = tmp_motherName
            _entity.person!!.motherNum = tmp_motherNum
            _entity.person!!.dateOfBirth = tmp_dateOfBirth
            _entity.person!!.personAddress = tmp_personAddress
            _entity.person!!.personOrgId = tmp_personOrgId
            _entity.person!!.personGroupUid = tmp_personGroupUid
            _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
            _entity.person!!.personLct = tmp_personLct
            _entity.person!!.personCountry = tmp_personCountry
            _entity.person!!.personType = tmp_personType
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByUid(uid: Long): ClazzEnrolment? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzEnrolment??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentUid = ?" ,
        postgreSql = """
    |SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
          val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
          val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
          val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
          val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
          val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
          val tmp_clazzEnrolmentAttendancePercentage =
              _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
          val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
          val tmp_clazzEnrolmentLeavingReasonUid =
              _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
          val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
          val tmp_clazzEnrolmentLocalChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
          val tmp_clazzEnrolmentMasterChangeSeqNum =
              _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
          val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
          val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
          val _entity = ClazzEnrolment()
          _entity.clazzEnrolmentUid = tmp_clazzEnrolmentUid
          _entity.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
          _entity.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
          _entity.clazzEnrolmentDateJoined = tmp_clazzEnrolmentDateJoined
          _entity.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
          _entity.clazzEnrolmentRole = tmp_clazzEnrolmentRole
          _entity.clazzEnrolmentAttendancePercentage = tmp_clazzEnrolmentAttendancePercentage
          _entity.clazzEnrolmentActive = tmp_clazzEnrolmentActive
          _entity.clazzEnrolmentLeavingReasonUid = tmp_clazzEnrolmentLeavingReasonUid
          _entity.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
          _entity.clazzEnrolmentLocalChangeSeqNum = tmp_clazzEnrolmentLocalChangeSeqNum
          _entity.clazzEnrolmentMasterChangeSeqNum = tmp_clazzEnrolmentMasterChangeSeqNum
          _entity.clazzEnrolmentLastChangedBy = tmp_clazzEnrolmentLastChangedBy
          _entity.clazzEnrolmentLct = tmp_clazzEnrolmentLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<ClazzEnrolment?> {
    val _result = DoorLiveDataImpl<ClazzEnrolment?>(_db, listOf("ClazzEnrolment"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ClazzEnrolment??
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentUid = ?" ,
          postgreSql = """
      |SELECT * FROM ClazzEnrolment WHERE clazzEnrolmentUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
            val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
            val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
            val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
            val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
            val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
            val tmp_clazzEnrolmentAttendancePercentage =
                _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
            val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
            val tmp_clazzEnrolmentLeavingReasonUid =
                _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
            val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
            val tmp_clazzEnrolmentLocalChangeSeqNum =
                _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
            val tmp_clazzEnrolmentMasterChangeSeqNum =
                _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
            val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
            val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
            val _entity = ClazzEnrolment()
            _entity.clazzEnrolmentUid = tmp_clazzEnrolmentUid
            _entity.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
            _entity.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
            _entity.clazzEnrolmentDateJoined = tmp_clazzEnrolmentDateJoined
            _entity.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
            _entity.clazzEnrolmentRole = tmp_clazzEnrolmentRole
            _entity.clazzEnrolmentAttendancePercentage = tmp_clazzEnrolmentAttendancePercentage
            _entity.clazzEnrolmentActive = tmp_clazzEnrolmentActive
            _entity.clazzEnrolmentLeavingReasonUid = tmp_clazzEnrolmentLeavingReasonUid
            _entity.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
            _entity.clazzEnrolmentLocalChangeSeqNum = tmp_clazzEnrolmentLocalChangeSeqNum
            _entity.clazzEnrolmentMasterChangeSeqNum = tmp_clazzEnrolmentMasterChangeSeqNum
            _entity.clazzEnrolmentLastChangedBy = tmp_clazzEnrolmentLastChangedBy
            _entity.clazzEnrolmentLct = tmp_clazzEnrolmentLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateClazzEnrolmentActiveForPersonAndClazz(
    personUid: Long,
    clazzUid: Long,
    roleId: Int,
    active: Boolean,
    changeTime: Long
  ): Int {
    var _result = 0
    val _stmtConfig = PreparedStatementConfig("""
    |
    |                UPDATE ClazzEnrolment
    |                   SET clazzEnrolmentActive = ?,
    |                       clazzEnrolmentLct= ?
    |                WHERE clazzEnrolmentPersonUid = ? 
    |                      AND clazzEnrolmentClazzUid = ?
    |                      AND clazzEnrolmentRole = ?
    """.trimMargin() , postgreSql = """
    |
    |                UPDATE ClazzEnrolment
    |                   SET clazzEnrolmentActive = ?,
    |                       clazzEnrolmentLct= ?
    |                WHERE clazzEnrolmentPersonUid = ? 
    |                      AND clazzEnrolmentClazzUid = ?
    |                      AND clazzEnrolmentRole = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, active)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, personUid)
      _stmt.setLong(4, clazzUid)
      _stmt.setInt(5, roleId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
      _result = _numUpdates
    }
    return _result
  }

  public override fun findByClazzUidAndRole(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long
  ): DoorDataSourceFactory<Int, PersonWithClazzEnrolmentDetails> {
    val _result = object : DoorDataSourceFactory<Int, PersonWithClazzEnrolmentDetails>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<PersonWithClazzEnrolmentDetails>> =
          DoorLiveDataImpl<List<PersonWithClazzEnrolmentDetails>>(_db, listOf("Clazz", "Person",
          "ClazzEnrolment", "PersonGroupMember", "ScopedGrant"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.PersonWithClazzEnrolmentDetails>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT Person.*, 
        |               (SELECT ((CAST(COUNT(DISTINCT 
        |                        CASE WHEN ClazzLogAttendanceRecord.attendanceStatus = 1 
        |                                  THEN ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid 
        |                             ELSE NULL 
        |                             END) 
        |                        AS REAL) / 
        |                        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
        |                   FROM ClazzLogAttendanceRecord 
        |                        JOIN ClazzLog 
        |                             ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid 
        |                  WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid 
        |                    AND ClazzLog.clazzLogClazzUid = ?)  AS attendance, 
        |        
        |    	       (SELECT MIN(ClazzEnrolment.clazzEnrolmentDateJoined) 
        |                  FROM ClazzEnrolment 
        |                 WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS earliestJoinDate, 
        |        
        |    	      (SELECT MAX(ClazzEnrolment.clazzEnrolmentDateLeft) 
        |                 FROM ClazzEnrolment 
        |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS latestDateLeft, 
        |        
        |              (SELECT clazzEnrolmentRole 
        |                 FROM clazzEnrolment 
        |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid 
        |                  AND ClazzEnrolment.clazzEnrolmentClazzUid = ? 
        |        AND ClazzEnrolment.clazzEnrolmentActive) AS enrolmentRole
        |        FROM PersonGroupMember
        |        
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 64 
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |         
        |        
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND Person.personUid IN (SELECT clazzEnrolmentPersonUid 
        |                                      FROM ClazzEnrolment 
        |                                     WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ? 
        |                                       AND ClazzEnrolment.clazzEnrolmentActive 
        |                                       AND ClazzEnrolment.clazzEnrolmentRole = ? 
        |                                       AND (? != 1 
        |                                        OR (? 
        |                                            BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                                            AND ClazzEnrolment.clazzEnrolmentDateLeft))) 
        |          AND Person.firstNames || ' ' || Person.lastName LIKE ?
        |     GROUP BY Person.personUid
        |     ORDER BY CASE(?)
        |                WHEN 1 THEN Person.firstNames
        |                WHEN 3 THEN Person.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN Person.firstNames
        |                WHEN 4 THEN Person.lastName
        |                ELSE ''
        |            END DESC,
        |            CASE(?)
        |                WHEN 5 THEN attendance
        |                WHEN 7 THEN earliestJoinDate
        |                WHEN 9 THEN latestDateLeft
        |                ELSE 0
        |            END ASC,
        |            CASE(?)
        |                WHEN 6 THEN attendance
        |                WHEN 8 THEN earliestJoinDate
        |                WHEN 10 THEN latestDateLeft
        |                ELSE 0
        |            END DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT Person.*, 
        |               (SELECT ((CAST(COUNT(DISTINCT 
        |                        CASE WHEN ClazzLogAttendanceRecord.attendanceStatus = 1 
        |                                  THEN ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid 
        |                             ELSE NULL 
        |                             END) 
        |                        AS REAL) / 
        |                        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
        |                   FROM ClazzLogAttendanceRecord 
        |                        JOIN ClazzLog 
        |                             ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid 
        |                  WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid 
        |                    AND ClazzLog.clazzLogClazzUid = ?)  AS attendance, 
        |        
        |    	       (SELECT MIN(ClazzEnrolment.clazzEnrolmentDateJoined) 
        |                  FROM ClazzEnrolment 
        |                 WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS earliestJoinDate, 
        |        
        |    	      (SELECT MAX(ClazzEnrolment.clazzEnrolmentDateLeft) 
        |                 FROM ClazzEnrolment 
        |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS latestDateLeft, 
        |        
        |              (SELECT clazzEnrolmentRole 
        |                 FROM clazzEnrolment 
        |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid 
        |                  AND ClazzEnrolment.clazzEnrolmentClazzUid = ? 
        |        AND ClazzEnrolment.clazzEnrolmentActive) AS enrolmentRole
        |        FROM PersonGroupMember
        |        
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 64 
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |         
        |        
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND Person.personUid IN (SELECT clazzEnrolmentPersonUid 
        |                                      FROM ClazzEnrolment 
        |                                     WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ? 
        |                                       AND ClazzEnrolment.clazzEnrolmentActive 
        |                                       AND ClazzEnrolment.clazzEnrolmentRole = ? 
        |                                       AND (? != 1 
        |                                        OR (? 
        |                                            BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                                            AND ClazzEnrolment.clazzEnrolmentDateLeft))) 
        |          AND Person.firstNames || ' ' || Person.lastName LIKE ?
        |     GROUP BY Person.personUid
        |     ORDER BY CASE(?)
        |                WHEN 1 THEN Person.firstNames
        |                WHEN 3 THEN Person.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN Person.firstNames
        |                WHEN 4 THEN Person.lastName
        |                ELSE ''
        |            END DESC,
        |            CASE(?)
        |                WHEN 5 THEN attendance
        |                WHEN 7 THEN earliestJoinDate
        |                WHEN 9 THEN latestDateLeft
        |                ELSE 0
        |            END ASC,
        |            CASE(?)
        |                WHEN 6 THEN attendance
        |                WHEN 8 THEN earliestJoinDate
        |                WHEN 10 THEN latestDateLeft
        |                ELSE 0
        |            END DESC
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.setLong(2, clazzUid)
          _stmt.setLong(3, accountPersonUid)
          _stmt.setLong(4, clazzUid)
          _stmt.setInt(5, roleId)
          _stmt.setInt(6, filter)
          _stmt.setLong(7, currentTime)
          _stmt.setString(8, searchText)
          _stmt.setInt(9, sortOrder)
          _stmt.setInt(10, sortOrder)
          _stmt.setInt(11, sortOrder)
          _stmt.setInt(12, sortOrder)
          _stmt.setInt(13, _limit)
          _stmt.setInt(14, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_attendance = _resultSet.getFloat("attendance")
              val tmp_earliestJoinDate = _resultSet.getLong("earliestJoinDate")
              val tmp_latestDateLeft = _resultSet.getLong("latestDateLeft")
              val tmp_enrolmentRole = _resultSet.getInt("enrolmentRole")
              val tmp_personUid = _resultSet.getLong("personUid")
              val tmp_username = _resultSet.getString("username")
              val tmp_firstNames = _resultSet.getString("firstNames")
              val tmp_lastName = _resultSet.getString("lastName")
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              val tmp_gender = _resultSet.getInt("gender")
              val tmp_active = _resultSet.getBoolean("active")
              val tmp_admin = _resultSet.getBoolean("admin")
              val tmp_personNotes = _resultSet.getString("personNotes")
              val tmp_fatherName = _resultSet.getString("fatherName")
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              val tmp_motherName = _resultSet.getString("motherName")
              val tmp_motherNum = _resultSet.getString("motherNum")
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              val tmp_personAddress = _resultSet.getString("personAddress")
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              val tmp_personLct = _resultSet.getLong("personLct")
              val tmp_personCountry = _resultSet.getString("personCountry")
              val tmp_personType = _resultSet.getInt("personType")
              val _entity = PersonWithClazzEnrolmentDetails()
              _entity.attendance = tmp_attendance
              _entity.earliestJoinDate = tmp_earliestJoinDate
              _entity.latestDateLeft = tmp_latestDateLeft
              _entity.enrolmentRole = tmp_enrolmentRole
              _entity.personUid = tmp_personUid
              _entity.username = tmp_username
              _entity.firstNames = tmp_firstNames
              _entity.lastName = tmp_lastName
              _entity.emailAddr = tmp_emailAddr
              _entity.phoneNum = tmp_phoneNum
              _entity.gender = tmp_gender
              _entity.active = tmp_active
              _entity.admin = tmp_admin
              _entity.personNotes = tmp_personNotes
              _entity.fatherName = tmp_fatherName
              _entity.fatherNumber = tmp_fatherNumber
              _entity.motherName = tmp_motherName
              _entity.motherNum = tmp_motherNum
              _entity.dateOfBirth = tmp_dateOfBirth
              _entity.personAddress = tmp_personAddress
              _entity.personOrgId = tmp_personOrgId
              _entity.personGroupUid = tmp_personGroupUid
              _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
              _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
              _entity.personLastChangedBy = tmp_personLastChangedBy
              _entity.personLct = tmp_personLct
              _entity.personCountry = tmp_personCountry
              _entity.personType = tmp_personType
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Clazz", "Person", "ClazzEnrolment", "PersonGroupMember", "ScopedGrant"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT Person.*, 
        |               (SELECT ((CAST(COUNT(DISTINCT 
        |                        CASE WHEN ClazzLogAttendanceRecord.attendanceStatus = 1 
        |                                  THEN ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid 
        |                             ELSE NULL 
        |                             END) 
        |                        AS REAL) / 
        |                        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
        |                   FROM ClazzLogAttendanceRecord 
        |                        JOIN ClazzLog 
        |                             ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid 
        |                  WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid 
        |                    AND ClazzLog.clazzLogClazzUid = ?)  AS attendance, 
        |        
        |    	       (SELECT MIN(ClazzEnrolment.clazzEnrolmentDateJoined) 
        |                  FROM ClazzEnrolment 
        |                 WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS earliestJoinDate, 
        |        
        |    	      (SELECT MAX(ClazzEnrolment.clazzEnrolmentDateLeft) 
        |                 FROM ClazzEnrolment 
        |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS latestDateLeft, 
        |        
        |              (SELECT clazzEnrolmentRole 
        |                 FROM clazzEnrolment 
        |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid 
        |                  AND ClazzEnrolment.clazzEnrolmentClazzUid = ? 
        |        AND ClazzEnrolment.clazzEnrolmentActive) AS enrolmentRole
        |        FROM PersonGroupMember
        |        
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 64 
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |         
        |        
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND Person.personUid IN (SELECT clazzEnrolmentPersonUid 
        |                                      FROM ClazzEnrolment 
        |                                     WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ? 
        |                                       AND ClazzEnrolment.clazzEnrolmentActive 
        |                                       AND ClazzEnrolment.clazzEnrolmentRole = ? 
        |                                       AND (? != 1 
        |                                        OR (? 
        |                                            BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                                            AND ClazzEnrolment.clazzEnrolmentDateLeft))) 
        |          AND Person.firstNames || ' ' || Person.lastName LIKE ?
        |     GROUP BY Person.personUid
        |     ORDER BY CASE(?)
        |                WHEN 1 THEN Person.firstNames
        |                WHEN 3 THEN Person.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN Person.firstNames
        |                WHEN 4 THEN Person.lastName
        |                ELSE ''
        |            END DESC,
        |            CASE(?)
        |                WHEN 5 THEN attendance
        |                WHEN 7 THEN earliestJoinDate
        |                WHEN 9 THEN latestDateLeft
        |                ELSE 0
        |            END ASC,
        |            CASE(?)
        |                WHEN 6 THEN attendance
        |                WHEN 8 THEN earliestJoinDate
        |                WHEN 10 THEN latestDateLeft
        |                ELSE 0
        |            END DESC
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT Person.*, 
        |               (SELECT ((CAST(COUNT(DISTINCT 
        |                        CASE WHEN ClazzLogAttendanceRecord.attendanceStatus = 1 
        |                                  THEN ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid 
        |                             ELSE NULL 
        |                             END) 
        |                        AS REAL) / 
        |                        MAX(COUNT(ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid),1)) * 100) 
        |                   FROM ClazzLogAttendanceRecord 
        |                        JOIN ClazzLog 
        |                             ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid 
        |                  WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid 
        |                    AND ClazzLog.clazzLogClazzUid = ?)  AS attendance, 
        |        
        |    	       (SELECT MIN(ClazzEnrolment.clazzEnrolmentDateJoined) 
        |                  FROM ClazzEnrolment 
        |                 WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS earliestJoinDate, 
        |        
        |    	      (SELECT MAX(ClazzEnrolment.clazzEnrolmentDateLeft) 
        |                 FROM ClazzEnrolment 
        |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid) AS latestDateLeft, 
        |        
        |              (SELECT clazzEnrolmentRole 
        |                 FROM clazzEnrolment 
        |                WHERE Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid 
        |                  AND ClazzEnrolment.clazzEnrolmentClazzUid = ? 
        |        AND ClazzEnrolment.clazzEnrolmentActive) AS enrolmentRole
        |        FROM PersonGroupMember
        |        
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 64 
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |         
        |        
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND Person.personUid IN (SELECT clazzEnrolmentPersonUid 
        |                                      FROM ClazzEnrolment 
        |                                     WHERE ClazzEnrolment.clazzEnrolmentClazzUid = ? 
        |                                       AND ClazzEnrolment.clazzEnrolmentActive 
        |                                       AND ClazzEnrolment.clazzEnrolmentRole = ? 
        |                                       AND (? != 1 
        |                                        OR (? 
        |                                            BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                                            AND ClazzEnrolment.clazzEnrolmentDateLeft))) 
        |          AND Person.firstNames || ' ' || Person.lastName LIKE ?
        |     GROUP BY Person.personUid
        |     ORDER BY CASE(?)
        |                WHEN 1 THEN Person.firstNames
        |                WHEN 3 THEN Person.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN Person.firstNames
        |                WHEN 4 THEN Person.lastName
        |                ELSE ''
        |            END DESC,
        |            CASE(?)
        |                WHEN 5 THEN attendance
        |                WHEN 7 THEN earliestJoinDate
        |                WHEN 9 THEN latestDateLeft
        |                ELSE 0
        |            END ASC,
        |            CASE(?)
        |                WHEN 6 THEN attendance
        |                WHEN 8 THEN earliestJoinDate
        |                WHEN 10 THEN latestDateLeft
        |                ELSE 0
        |            END DESC
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.setLong(2, clazzUid)
          _stmt.setLong(3, accountPersonUid)
          _stmt.setLong(4, clazzUid)
          _stmt.setInt(5, roleId)
          _stmt.setInt(6, filter)
          _stmt.setLong(7, currentTime)
          _stmt.setString(8, searchText)
          _stmt.setInt(9, sortOrder)
          _stmt.setInt(10, sortOrder)
          _stmt.setInt(11, sortOrder)
          _stmt.setInt(12, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun updateClazzEnrolmentActiveForClazzEnrolment(
    clazzEnrolmentUid: Long,
    enrolled: Boolean,
    timeChanged: Long
  ): Int {
    var _result = 0
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ClazzEnrolment 
    |          SET clazzEnrolmentActive = ?,
    |              clazzEnrolmentLct = ?
    |        WHERE clazzEnrolmentUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ClazzEnrolment 
    |          SET clazzEnrolmentActive = ?,
    |              clazzEnrolmentLct = ?
    |        WHERE clazzEnrolmentUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, enrolled)
      _stmt.setLong(2, timeChanged)
      _stmt.setLong(3, clazzEnrolmentUid)
      val _numUpdates = _stmt.executeUpdate()
      _result = _numUpdates
    }
    return _result
  }

  public override suspend fun updateClazzEnrolmentRole(
    personUid: Long,
    clazzUid: Long,
    newRole: Int,
    oldRole: Int,
    updateTime: Long
  ): Int {
    var _result = 0
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            UPDATE ClazzEnrolment 
    |               SET clazzEnrolmentRole = ?,
    |                   clazzEnrolmentLct = ?      
    |             -- Avoid potential for duplicate approvals if user was previously refused      
    |             WHERE clazzEnrolmentUid = COALESCE( 
    |                    (SELECT clazzEnrolmentUid
    |                       FROM ClazzEnrolment
    |                      WHERE clazzEnrolmentPersonUid = ? 
    |                            AND clazzEnrolmentClazzUid = ?
    |                            AND clazzEnrolmentRole = ?
    |                            AND CAST(clazzEnrolmentActive AS INTEGER) = 1
    |                      LIMIT 1), 0)
    """.trimMargin() , postgreSql = """
    |
    |            UPDATE ClazzEnrolment 
    |               SET clazzEnrolmentRole = ?,
    |                   clazzEnrolmentLct = ?      
    |             -- Avoid potential for duplicate approvals if user was previously refused      
    |             WHERE clazzEnrolmentUid = COALESCE( 
    |                    (SELECT clazzEnrolmentUid
    |                       FROM ClazzEnrolment
    |                      WHERE clazzEnrolmentPersonUid = ? 
    |                            AND clazzEnrolmentClazzUid = ?
    |                            AND clazzEnrolmentRole = ?
    |                            AND CAST(clazzEnrolmentActive AS INTEGER) = 1
    |                      LIMIT 1), 0)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, newRole)
      _stmt.setLong(2, updateTime)
      _stmt.setLong(3, personUid)
      _stmt.setLong(4, clazzUid)
      _stmt.setInt(5, oldRole)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
      _result = _numUpdates
    }
    return _result
  }

  public override fun insert(entity: ClazzEnrolment): Long {
    val _retVal = _insertAdapterClazzEnrolment_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzEnrolment): Long {
    val _retVal = _insertAdapterClazzEnrolment_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ClazzEnrolment>): Unit {
    _insertAdapterClazzEnrolment_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzEnrolment>): Unit {
    val _sql =
        "UPDATE ClazzEnrolment SET clazzEnrolmentPersonUid = ?, clazzEnrolmentClazzUid = ?, clazzEnrolmentDateJoined = ?, clazzEnrolmentDateLeft = ?, clazzEnrolmentRole = ?, clazzEnrolmentAttendancePercentage = ?, clazzEnrolmentActive = ?, clazzEnrolmentLeavingReasonUid = ?, clazzEnrolmentOutcome = ?, clazzEnrolmentLocalChangeSeqNum = ?, clazzEnrolmentMasterChangeSeqNum = ?, clazzEnrolmentLastChangedBy = ?, clazzEnrolmentLct = ? WHERE clazzEnrolmentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.clazzEnrolmentPersonUid)
        _stmt.setLong(2, _entity.clazzEnrolmentClazzUid)
        _stmt.setLong(3, _entity.clazzEnrolmentDateJoined)
        _stmt.setLong(4, _entity.clazzEnrolmentDateLeft)
        _stmt.setInt(5, _entity.clazzEnrolmentRole)
        _stmt.setFloat(6, _entity.clazzEnrolmentAttendancePercentage)
        _stmt.setBoolean(7, _entity.clazzEnrolmentActive)
        _stmt.setLong(8, _entity.clazzEnrolmentLeavingReasonUid)
        _stmt.setInt(9, _entity.clazzEnrolmentOutcome)
        _stmt.setLong(10, _entity.clazzEnrolmentLocalChangeSeqNum)
        _stmt.setLong(11, _entity.clazzEnrolmentMasterChangeSeqNum)
        _stmt.setInt(12, _entity.clazzEnrolmentLastChangedBy)
        _stmt.setLong(13, _entity.clazzEnrolmentLct)
        _stmt.setLong(14, _entity.clazzEnrolmentUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzEnrolment): Unit {
    val _sql =
        "UPDATE ClazzEnrolment SET clazzEnrolmentPersonUid = ?, clazzEnrolmentClazzUid = ?, clazzEnrolmentDateJoined = ?, clazzEnrolmentDateLeft = ?, clazzEnrolmentRole = ?, clazzEnrolmentAttendancePercentage = ?, clazzEnrolmentActive = ?, clazzEnrolmentLeavingReasonUid = ?, clazzEnrolmentOutcome = ?, clazzEnrolmentLocalChangeSeqNum = ?, clazzEnrolmentMasterChangeSeqNum = ?, clazzEnrolmentLastChangedBy = ?, clazzEnrolmentLct = ? WHERE clazzEnrolmentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzEnrolmentPersonUid)
      _stmt.setLong(2, entity.clazzEnrolmentClazzUid)
      _stmt.setLong(3, entity.clazzEnrolmentDateJoined)
      _stmt.setLong(4, entity.clazzEnrolmentDateLeft)
      _stmt.setInt(5, entity.clazzEnrolmentRole)
      _stmt.setFloat(6, entity.clazzEnrolmentAttendancePercentage)
      _stmt.setBoolean(7, entity.clazzEnrolmentActive)
      _stmt.setLong(8, entity.clazzEnrolmentLeavingReasonUid)
      _stmt.setInt(9, entity.clazzEnrolmentOutcome)
      _stmt.setLong(10, entity.clazzEnrolmentLocalChangeSeqNum)
      _stmt.setLong(11, entity.clazzEnrolmentMasterChangeSeqNum)
      _stmt.setInt(12, entity.clazzEnrolmentLastChangedBy)
      _stmt.setLong(13, entity.clazzEnrolmentLct)
      _stmt.setLong(14, entity.clazzEnrolmentUid)
      _stmt.executeUpdate()
    }
  }
}
