package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.LearnerGroupMember
import com.ustadmobile.lib.db.entities.LearnerGroupMemberWithPerson
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class LearnerGroupMemberDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: LearnerGroupMemberDao
) : LearnerGroupMemberDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findLearnerGroupMembersByGroupIdAndEntry(learnerGroupUid: Long,
      contentEntryUid: Long): DoorDataSourceFactory<Int, LearnerGroupMemberWithPerson> =
      _dao.findLearnerGroupMembersByGroupIdAndEntry(learnerGroupUid, contentEntryUid)

  public override suspend fun findLearnerGroupMembersByGroupIdAndEntryList(learnerGroupUid: Long,
      contentEntryUid: Long): List<LearnerGroupMemberWithPerson> =
      _dao.findLearnerGroupMembersByGroupIdAndEntryList(learnerGroupUid, contentEntryUid)

  public override fun insert(entity: LearnerGroupMember): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: LearnerGroupMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.learnerGroupMemberUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(300)
      entity.learnerGroupMemberUid = _newPk
    }
    entity.learnerGroupMemberLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.learnerGroupMemberUid
  }

  public override fun insertList(entityList: List<out LearnerGroupMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out LearnerGroupMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: LearnerGroupMember): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
