package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.XObjectEntity
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class XObjectDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: XObjectDao
) : XObjectDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByObjectId(id: String?): XObjectEntity? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByXobjectUid(xObjectUid: Long): XObjectEntity? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: XObjectEntity): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: XObjectEntity): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.xObjectUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(64)
      entity.xObjectUid = _newPk
    }
    entity.xObjectLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.xObjectUid
  }

  public override fun insertList(entityList: List<out XObjectEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out XObjectEntity>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: XObjectEntity): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
