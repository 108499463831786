package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.lib.db.entities.ScopedGrant
import com.ustadmobile.lib.db.entities.ScopedGrantAndName
import com.ustadmobile.lib.db.entities.ScopedGrantWithName
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ScopedGrantDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ScopedGrantDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : ScopedGrantDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _dao.replicateOnChangeClazzBased()
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeClazzBased(newNodeId)
  }

  public override suspend fun replicateOnChangePersonBased(): Unit {
    _dao.replicateOnChangePersonBased()
  }

  public override suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodePersonBased(newNodeId)
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _dao.replicateOnChangeSchoolBased()
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeSchoolBased(newNodeId)
  }

  public override suspend fun insertAsync(scopedGrant: ScopedGrant): Long {
    val _result = _dao.insertAsync(scopedGrant)
    return _result
  }

  public override suspend fun insertListAsync(scopedGrantList: List<out ScopedGrant>): Unit {
    _dao.insertListAsync(scopedGrantList)
  }

  public override suspend fun updateAsync(scopedGrant: ScopedGrant): Unit {
    _dao.updateAsync(scopedGrant)
  }

  public override suspend fun updateListAsync(scopedGrantList: List<out ScopedGrant>): Unit {
    _dao.updateListAsync(scopedGrantList)
  }

  public override suspend fun findByTableIdAndEntityUid(tableId: Int, entityUid: Long):
      List<ScopedGrantAndName> {
    val _result = _dao.findByTableIdAndEntityUid(tableId, entityUid)
    return _result
  }

  public override fun findByTableIdAndEntityUidWithNameAsDataSource(tableId: Int, entityUid: Long):
      DoorDataSourceFactory<Int, ScopedGrantWithName> {
    val _result = _dao.findByTableIdAndEntityUidWithNameAsDataSource(tableId, entityUid)
    return _result
  }

  public override fun findByTableIdAndEntityIdSync(tableId: Int, entityUid: Long):
      List<ScopedGrant> {
    val _result = _dao.findByTableIdAndEntityIdSync(tableId, entityUid)
    return _result
  }

  public override suspend fun findByUid(sgUid: Long): ScopedGrant? {
    val _result = _dao.findByUid(sgUid)
    return _result
  }

  public override fun findByUidLiveWithName(sgUid: Long): DoorLiveData<ScopedGrantWithName?> {
    val _result = _dao.findByUidLiveWithName(sgUid)
    return _result
  }
}
