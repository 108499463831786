package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseAssignmentMarkDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: CourseAssignmentMarkDao
) : CourseAssignmentMarkDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun getMarkOfAssignmentForSubmitterLiveData(assignmentUid: Long,
      submitterUid: Long): DoorLiveData<CourseAssignmentMark?> =
      _dao.getMarkOfAssignmentForSubmitterLiveData(assignmentUid, submitterUid)

  public override fun getMarkOfAssignmentForStudent(assignmentUid: Long, submitterUid: Long):
      CourseAssignmentMark? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findNextSubmitterToMarkForAssignment(assignmentUid: Long,
      submitterUid: Long): Long = _dao.findNextSubmitterToMarkForAssignment(assignmentUid,
      submitterUid)

  public override fun insert(entity: CourseAssignmentMark): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseAssignmentMark): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.camUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(523)
      entity.camUid = _newPk
    }
    entity.camLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.camUid
  }

  public override fun insertList(entityList: List<out CourseAssignmentMark>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out CourseAssignmentMark>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseAssignmentMark): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
