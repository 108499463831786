package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CustomFieldValueOption
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CustomFieldValueOptionDao_JdbcKt(
  public val _db: DoorDatabase
) : CustomFieldValueOptionDao() {
  public val _insertAdapterCustomFieldValueOption_: EntityInsertionAdapter<CustomFieldValueOption> =
      object : EntityInsertionAdapter<CustomFieldValueOption>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CustomFieldValueOption (customFieldValueOptionUid, customFieldValueOptionName, customFieldValueOptionFieldUid, customFieldValueOptionIcon, customFieldValueOptionMessageId, customFieldValueOptionActive, customFieldValueOptionMCSN, customFieldValueOptionLCSN, customFieldValueOptionLCB, customFieldValueLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CustomFieldValueOption (customFieldValueOptionUid, customFieldValueOptionName, customFieldValueOptionFieldUid, customFieldValueOptionIcon, customFieldValueOptionMessageId, customFieldValueOptionActive, customFieldValueOptionMCSN, customFieldValueOptionLCSN, customFieldValueOptionLCB, customFieldValueLct) VALUES(COALESCE(?,nextval('CustomFieldValueOption_customFieldValueOptionUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING customFieldValueOptionUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CustomFieldValueOption): Unit {
      if(entity.customFieldValueOptionUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.customFieldValueOptionUid)
      }
      stmt.setString(2, entity.customFieldValueOptionName)
      stmt.setLong(3, entity.customFieldValueOptionFieldUid)
      stmt.setString(4, entity.customFieldValueOptionIcon)
      stmt.setInt(5, entity.customFieldValueOptionMessageId)
      stmt.setBoolean(6, entity.customFieldValueOptionActive)
      stmt.setLong(7, entity.customFieldValueOptionMCSN)
      stmt.setLong(8, entity.customFieldValueOptionLCSN)
      stmt.setInt(9, entity.customFieldValueOptionLCB)
      stmt.setLong(10, entity.customFieldValueLct)
    }
  }

  public override suspend fun updateAsync(entity: CustomFieldValueOption): Int {
    var _result = 0
    val _sql =
        "UPDATE CustomFieldValueOption SET customFieldValueOptionName = ?, customFieldValueOptionFieldUid = ?, customFieldValueOptionIcon = ?, customFieldValueOptionMessageId = ?, customFieldValueOptionActive = ?, customFieldValueOptionMCSN = ?, customFieldValueOptionLCSN = ?, customFieldValueOptionLCB = ?, customFieldValueLct = ? WHERE customFieldValueOptionUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.customFieldValueOptionName)
      _stmt.setLong(2, entity.customFieldValueOptionFieldUid)
      _stmt.setString(3, entity.customFieldValueOptionIcon)
      _stmt.setInt(4, entity.customFieldValueOptionMessageId)
      _stmt.setBoolean(5, entity.customFieldValueOptionActive)
      _stmt.setLong(6, entity.customFieldValueOptionMCSN)
      _stmt.setLong(7, entity.customFieldValueOptionLCSN)
      _stmt.setInt(8, entity.customFieldValueOptionLCB)
      _stmt.setLong(9, entity.customFieldValueLct)
      _stmt.setLong(10, entity.customFieldValueOptionUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): CustomFieldValueOption? {
    var _result = null as com.ustadmobile.lib.db.entities.CustomFieldValueOption??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM CustomFieldValueOption WHERE customFieldValueOptionUid = ?"
        , postgreSql = """
    |SELECT * FROM CustomFieldValueOption WHERE customFieldValueOptionUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_customFieldValueOptionUid = _resultSet.getLong("customFieldValueOptionUid")
          val tmp_customFieldValueOptionName = _resultSet.getString("customFieldValueOptionName")
          val tmp_customFieldValueOptionFieldUid =
              _resultSet.getLong("customFieldValueOptionFieldUid")
          val tmp_customFieldValueOptionIcon = _resultSet.getString("customFieldValueOptionIcon")
          val tmp_customFieldValueOptionMessageId =
              _resultSet.getInt("customFieldValueOptionMessageId")
          val tmp_customFieldValueOptionActive =
              _resultSet.getBoolean("customFieldValueOptionActive")
          val tmp_customFieldValueOptionMCSN = _resultSet.getLong("customFieldValueOptionMCSN")
          val tmp_customFieldValueOptionLCSN = _resultSet.getLong("customFieldValueOptionLCSN")
          val tmp_customFieldValueOptionLCB = _resultSet.getInt("customFieldValueOptionLCB")
          val tmp_customFieldValueLct = _resultSet.getLong("customFieldValueLct")
          val _entity = CustomFieldValueOption()
          _entity.customFieldValueOptionUid = tmp_customFieldValueOptionUid
          _entity.customFieldValueOptionName = tmp_customFieldValueOptionName
          _entity.customFieldValueOptionFieldUid = tmp_customFieldValueOptionFieldUid
          _entity.customFieldValueOptionIcon = tmp_customFieldValueOptionIcon
          _entity.customFieldValueOptionMessageId = tmp_customFieldValueOptionMessageId
          _entity.customFieldValueOptionActive = tmp_customFieldValueOptionActive
          _entity.customFieldValueOptionMCSN = tmp_customFieldValueOptionMCSN
          _entity.customFieldValueOptionLCSN = tmp_customFieldValueOptionLCSN
          _entity.customFieldValueOptionLCB = tmp_customFieldValueOptionLCB
          _entity.customFieldValueLct = tmp_customFieldValueLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: CustomFieldValueOption): Long {
    val _retVal = _insertAdapterCustomFieldValueOption_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CustomFieldValueOption): Long {
    val _retVal = _insertAdapterCustomFieldValueOption_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CustomFieldValueOption>): Unit {
    _insertAdapterCustomFieldValueOption_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CustomFieldValueOption>): Unit {
    val _sql =
        "UPDATE CustomFieldValueOption SET customFieldValueOptionName = ?, customFieldValueOptionFieldUid = ?, customFieldValueOptionIcon = ?, customFieldValueOptionMessageId = ?, customFieldValueOptionActive = ?, customFieldValueOptionMCSN = ?, customFieldValueOptionLCSN = ?, customFieldValueOptionLCB = ?, customFieldValueLct = ? WHERE customFieldValueOptionUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.customFieldValueOptionName)
        _stmt.setLong(2, _entity.customFieldValueOptionFieldUid)
        _stmt.setString(3, _entity.customFieldValueOptionIcon)
        _stmt.setInt(4, _entity.customFieldValueOptionMessageId)
        _stmt.setBoolean(5, _entity.customFieldValueOptionActive)
        _stmt.setLong(6, _entity.customFieldValueOptionMCSN)
        _stmt.setLong(7, _entity.customFieldValueOptionLCSN)
        _stmt.setInt(8, _entity.customFieldValueOptionLCB)
        _stmt.setLong(9, _entity.customFieldValueLct)
        _stmt.setLong(10, _entity.customFieldValueOptionUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CustomFieldValueOption): Unit {
    val _sql =
        "UPDATE CustomFieldValueOption SET customFieldValueOptionName = ?, customFieldValueOptionFieldUid = ?, customFieldValueOptionIcon = ?, customFieldValueOptionMessageId = ?, customFieldValueOptionActive = ?, customFieldValueOptionMCSN = ?, customFieldValueOptionLCSN = ?, customFieldValueOptionLCB = ?, customFieldValueLct = ? WHERE customFieldValueOptionUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.customFieldValueOptionName)
      _stmt.setLong(2, entity.customFieldValueOptionFieldUid)
      _stmt.setString(3, entity.customFieldValueOptionIcon)
      _stmt.setInt(4, entity.customFieldValueOptionMessageId)
      _stmt.setBoolean(5, entity.customFieldValueOptionActive)
      _stmt.setLong(6, entity.customFieldValueOptionMCSN)
      _stmt.setLong(7, entity.customFieldValueOptionLCSN)
      _stmt.setInt(8, entity.customFieldValueOptionLCB)
      _stmt.setLong(9, entity.customFieldValueLct)
      _stmt.setLong(10, entity.customFieldValueOptionUid)
      _stmt.executeUpdate()
    }
  }
}
