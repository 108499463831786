package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.UserSession
import com.ustadmobile.lib.db.entities.UserSessionAndPerson
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class UserSessionDao_JdbcKt(
  public val _db: DoorDatabase
) : UserSessionDao() {
  public val _insertAdapterUserSession_: EntityInsertionAdapter<UserSession> = object :
      EntityInsertionAdapter<UserSession>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO UserSession (usUid, usPcsn, usLcsn, usLcb, usLct, usPersonUid, usClientNodeId, usStartTime, usEndTime, usStatus, usReason, usAuth, usSessionType) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO UserSession (usUid, usPcsn, usLcsn, usLcb, usLct, usPersonUid, usClientNodeId, usStartTime, usEndTime, usStatus, usReason, usAuth, usSessionType) VALUES(COALESCE(?,nextval('UserSession_usUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING usUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: UserSession):
        Unit {
      if(entity.usUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.usUid)
      }
      stmt.setLong(2, entity.usPcsn)
      stmt.setLong(3, entity.usLcsn)
      stmt.setInt(4, entity.usLcb)
      stmt.setLong(5, entity.usLct)
      stmt.setLong(6, entity.usPersonUid)
      stmt.setLong(7, entity.usClientNodeId)
      stmt.setLong(8, entity.usStartTime)
      stmt.setLong(9, entity.usEndTime)
      stmt.setInt(10, entity.usStatus)
      stmt.setInt(11, entity.usReason)
      stmt.setString(12, entity.usAuth)
      stmt.setInt(13, entity.usSessionType)
    }
  }

  public override suspend fun updateReplicationTrackers(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO UserSessionReplicate(usPk, usDestination)
    |         SELECT DISTINCT UserSessionSubject.usUid AS usPk,
    |                UserSession.usClientNodeId AS usDestination
    |           FROM ChangeLog
    |                JOIN UserSession UserSessionSubject
    |                     ON ChangeLog.chTableId = 679
    |                        AND ChangeLog.chEntityPk = UserSessionSubject.usUid
    |                        AND UserSessionSubject.usSessionType = 1
    |                JOIN Person
    |                     ON UserSessionSubject.usPersonUid = Person.personUid
    |                
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |                    64
    |                    /* Modify second part of query - remove requirement for session to be active.
    |                     * This ensures that deactivations are distributed
    |                     */
    |                    ) > 0
    |                     JOIN PersonGroupMember AS PrsGrpMbr
    |                          ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                     JOIN UserSession
    |                          ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |          WHERE UserSessionSubject.usClientNodeId = UserSessionSubject.usClientNodeId                
    |          --notpsql              
    |            AND UserSessionSubject.usLct != COALESCE(
    |                (SELECT usVersionId
    |                   FROM UserSessionReplicate
    |                  WHERE UserSessionReplicate.usPk = UserSessionSubject.usUid
    |                    AND UserSessionReplicate.usDestination = UserSession.usClientNodeId), 0)
    |          --endnotpsql                       
    |        /*psql ON CONFLICT(usPk, usDestination) 
    |                DO UPDATE SET usPending = 
    |                   (SELECT UserSession.usLct
    |                      FROM UserSession
    |                     WHERE UserSession.usUid = EXCLUDED.usPk ) 
    |                        != UserSessionReplicate.usVersionId
    |         */         
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO UserSessionReplicate(usPk, usDestination)
    |         SELECT DISTINCT UserSessionSubject.usUid AS usPk,
    |                UserSession.usClientNodeId AS usDestination
    |           FROM ChangeLog
    |                JOIN UserSession UserSessionSubject
    |                     ON ChangeLog.chTableId = 679
    |                        AND ChangeLog.chEntityPk = UserSessionSubject.usUid
    |                        AND UserSessionSubject.usSessionType = 1
    |                JOIN Person
    |                     ON UserSessionSubject.usPersonUid = Person.personUid
    |                
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |                    64
    |                    /* Modify second part of query - remove requirement for session to be active.
    |                     * This ensures that deactivations are distributed
    |                     */
    |                    ) > 0
    |                     JOIN PersonGroupMember AS PrsGrpMbr
    |                          ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                     JOIN UserSession
    |                          ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |          WHERE UserSessionSubject.usClientNodeId = UserSessionSubject.usClientNodeId                
    |         ON CONFLICT(usPk, usDestination) 
    |                DO UPDATE SET usPending = 
    |                   (SELECT UserSession.usLct
    |                      FROM UserSession
    |                     WHERE UserSession.usUid = EXCLUDED.usPk ) 
    |                        != UserSessionReplicate.usVersionId
    |                  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateReplicationTrackersOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO UserSessionReplicate(usPk, usDestination)
    |         SELECT DISTINCT UserSessionSubject.usUid AS usPk,
    |                UserSession.usClientNodeId AS usDestination
    |           FROM UserSession 
    |                JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |                JOIN UserSession UserSessionSubject
    |                     ON UserSessionSubject.usPersonUid = Person.personUid
    |                        AND UserSessionSubject.usSessionType = 1
    |                        AND UserSessionSubject.usClientNodeId = ?
    |          WHERE UserSession.usClientNodeId = ?
    |          --notpsql
    |            AND UserSessionSubject.usLct != COALESCE(
    |                (SELECT usVersionId
    |                   FROM UserSessionReplicate
    |                  WHERE UserSessionReplicate.usPk = UserSessionSubject.usUid
    |                    AND UserSessionReplicate.usDestination = UserSession.usClientNodeId), 0)
    |          --endnotpsql          
    |         /*psql ON CONFLICT(usPk, usDestination) 
    |                DO UPDATE SET usPending = 
    |                   (SELECT UserSession.usLct
    |                      FROM UserSession
    |                     WHERE UserSession.usUid = EXCLUDED.usPk ) 
    |                        != UserSessionReplicate.usVersionId
    |         */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO UserSessionReplicate(usPk, usDestination)
    |         SELECT DISTINCT UserSessionSubject.usUid AS usPk,
    |                UserSession.usClientNodeId AS usDestination
    |           FROM UserSession 
    |                JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |                JOIN UserSession UserSessionSubject
    |                     ON UserSessionSubject.usPersonUid = Person.personUid
    |                        AND UserSessionSubject.usSessionType = 1
    |                        AND UserSessionSubject.usClientNodeId = ?
    |          WHERE UserSession.usClientNodeId = ?
    |          ON CONFLICT(usPk, usDestination) 
    |                DO UPDATE SET usPending = 
    |                   (SELECT UserSession.usLct
    |                      FROM UserSession
    |                     WHERE UserSession.usUid = EXCLUDED.usPk ) 
    |                        != UserSessionReplicate.usVersionId
    |         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertSession(session: UserSession): Long {
    val _retVal = _insertAdapterUserSession_.insertAndReturnIdAsync(session)
    return _retVal
  }

  public override suspend fun findSessionsByPerson(personUid: Long): List<UserSession> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.UserSession>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT UserSession.*
    |          FROM UserSession
    |         WHERE usPersonUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT UserSession.*
    |          FROM UserSession
    |         WHERE usPersonUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, personUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_usUid = _resultSet.getLong("usUid")
          val tmp_usPcsn = _resultSet.getLong("usPcsn")
          val tmp_usLcsn = _resultSet.getLong("usLcsn")
          val tmp_usLcb = _resultSet.getInt("usLcb")
          val tmp_usLct = _resultSet.getLong("usLct")
          val tmp_usPersonUid = _resultSet.getLong("usPersonUid")
          val tmp_usClientNodeId = _resultSet.getLong("usClientNodeId")
          val tmp_usStartTime = _resultSet.getLong("usStartTime")
          val tmp_usEndTime = _resultSet.getLong("usEndTime")
          val tmp_usStatus = _resultSet.getInt("usStatus")
          val tmp_usReason = _resultSet.getInt("usReason")
          val tmp_usAuth = _resultSet.getString("usAuth")
          val tmp_usSessionType = _resultSet.getInt("usSessionType")
          val _entity = UserSession()
          _entity.usUid = tmp_usUid
          _entity.usPcsn = tmp_usPcsn
          _entity.usLcsn = tmp_usLcsn
          _entity.usLcb = tmp_usLcb
          _entity.usLct = tmp_usLct
          _entity.usPersonUid = tmp_usPersonUid
          _entity.usClientNodeId = tmp_usClientNodeId
          _entity.usStartTime = tmp_usStartTime
          _entity.usEndTime = tmp_usEndTime
          _entity.usStatus = tmp_usStatus
          _entity.usReason = tmp_usReason
          _entity.usAuth = tmp_usAuth
          _entity.usSessionType = tmp_usSessionType
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllLocalSessionsLive(): DoorLiveData<List<UserSessionAndPerson>> {
    val _result = DoorLiveDataImpl<List<UserSessionAndPerson>>(_db, listOf("UserSession", "Person",
        "SyncNode"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.UserSessionAndPerson>()
      val _stmtConfig = PreparedStatementConfig("""
      |
      |            SELECT UserSession.*, Person.*
      |              FROM UserSession
      |                   JOIN Person ON UserSession.usPersonUid = Person.personUid
      |             WHERE UserSession.usClientNodeId = (
      |                   SELECT COALESCE(
      |                          (SELECT nodeClientId 
      |                            FROM SyncNode
      |                           LIMIT 1), 0))
      |               AND UserSession.usStatus = 1        
      |            
      """.trimMargin() , postgreSql = """
      |
      |            SELECT UserSession.*, Person.*
      |              FROM UserSession
      |                   JOIN Person ON UserSession.usPersonUid = Person.personUid
      |             WHERE UserSession.usClientNodeId = (
      |                   SELECT COALESCE(
      |                          (SELECT nodeClientId 
      |                            FROM SyncNode
      |                           LIMIT 1), 0))
      |               AND UserSession.usStatus = 1        
      |            
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val _entity = UserSessionAndPerson()
            var _person_nullFieldCount = 0
            val tmp_personUid = _resultSet.getLong("personUid")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_username = _resultSet.getString("username")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_firstNames = _resultSet.getString("firstNames")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_lastName = _resultSet.getString("lastName")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_emailAddr = _resultSet.getString("emailAddr")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_phoneNum = _resultSet.getString("phoneNum")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_gender = _resultSet.getInt("gender")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_active = _resultSet.getBoolean("active")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_admin = _resultSet.getBoolean("admin")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personNotes = _resultSet.getString("personNotes")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_fatherName = _resultSet.getString("fatherName")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_fatherNumber = _resultSet.getString("fatherNumber")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_motherName = _resultSet.getString("motherName")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_motherNum = _resultSet.getString("motherNum")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personAddress = _resultSet.getString("personAddress")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personOrgId = _resultSet.getString("personOrgId")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personLct = _resultSet.getLong("personLct")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personCountry = _resultSet.getString("personCountry")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            val tmp_personType = _resultSet.getInt("personType")
            if(_resultSet.wasNull()) { _person_nullFieldCount++ }
            if(_person_nullFieldCount < 24) {
              if(_entity.person == null) {
                _entity.person = Person()
              }
              _entity.person!!.personUid = tmp_personUid
              _entity.person!!.username = tmp_username
              _entity.person!!.firstNames = tmp_firstNames
              _entity.person!!.lastName = tmp_lastName
              _entity.person!!.emailAddr = tmp_emailAddr
              _entity.person!!.phoneNum = tmp_phoneNum
              _entity.person!!.gender = tmp_gender
              _entity.person!!.active = tmp_active
              _entity.person!!.admin = tmp_admin
              _entity.person!!.personNotes = tmp_personNotes
              _entity.person!!.fatherName = tmp_fatherName
              _entity.person!!.fatherNumber = tmp_fatherNumber
              _entity.person!!.motherName = tmp_motherName
              _entity.person!!.motherNum = tmp_motherNum
              _entity.person!!.dateOfBirth = tmp_dateOfBirth
              _entity.person!!.personAddress = tmp_personAddress
              _entity.person!!.personOrgId = tmp_personOrgId
              _entity.person!!.personGroupUid = tmp_personGroupUid
              _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
              _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
              _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
              _entity.person!!.personLct = tmp_personLct
              _entity.person!!.personCountry = tmp_personCountry
              _entity.person!!.personType = tmp_personType
            }
            var _userSession_nullFieldCount = 0
            val tmp_usUid = _resultSet.getLong("usUid")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usPcsn = _resultSet.getLong("usPcsn")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usLcsn = _resultSet.getLong("usLcsn")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usLcb = _resultSet.getInt("usLcb")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usLct = _resultSet.getLong("usLct")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usPersonUid = _resultSet.getLong("usPersonUid")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usClientNodeId = _resultSet.getLong("usClientNodeId")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usStartTime = _resultSet.getLong("usStartTime")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usEndTime = _resultSet.getLong("usEndTime")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usStatus = _resultSet.getInt("usStatus")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usReason = _resultSet.getInt("usReason")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usAuth = _resultSet.getString("usAuth")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            val tmp_usSessionType = _resultSet.getInt("usSessionType")
            if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
            if(_userSession_nullFieldCount < 13) {
              if(_entity.userSession == null) {
                _entity.userSession = UserSession()
              }
              _entity.userSession!!.usUid = tmp_usUid
              _entity.userSession!!.usPcsn = tmp_usPcsn
              _entity.userSession!!.usLcsn = tmp_usLcsn
              _entity.userSession!!.usLcb = tmp_usLcb
              _entity.userSession!!.usLct = tmp_usLct
              _entity.userSession!!.usPersonUid = tmp_usPersonUid
              _entity.userSession!!.usClientNodeId = tmp_usClientNodeId
              _entity.userSession!!.usStartTime = tmp_usStartTime
              _entity.userSession!!.usEndTime = tmp_usEndTime
              _entity.userSession!!.usStatus = tmp_usStatus
              _entity.userSession!!.usReason = tmp_usReason
              _entity.userSession!!.usAuth = tmp_usAuth
              _entity.userSession!!.usSessionType = tmp_usSessionType
            }
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override suspend fun findAllLocalSessionsAsync(): List<UserSessionAndPerson> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.UserSessionAndPerson>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            SELECT UserSession.*, Person.*
    |              FROM UserSession
    |                   JOIN Person ON UserSession.usPersonUid = Person.personUid
    |             WHERE UserSession.usClientNodeId = (
    |                   SELECT COALESCE(
    |                          (SELECT nodeClientId 
    |                            FROM SyncNode
    |                           LIMIT 1), 0))
    |               AND UserSession.usStatus = 1        
    |            
    """.trimMargin() , postgreSql = """
    |
    |            SELECT UserSession.*, Person.*
    |              FROM UserSession
    |                   JOIN Person ON UserSession.usPersonUid = Person.personUid
    |             WHERE UserSession.usClientNodeId = (
    |                   SELECT COALESCE(
    |                          (SELECT nodeClientId 
    |                            FROM SyncNode
    |                           LIMIT 1), 0))
    |               AND UserSession.usStatus = 1        
    |            
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = UserSessionAndPerson()
          var _person_nullFieldCount = 0
          val tmp_personUid = _resultSet.getLong("personUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_username = _resultSet.getString("username")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_firstNames = _resultSet.getString("firstNames")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_lastName = _resultSet.getString("lastName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_gender = _resultSet.getInt("gender")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_active = _resultSet.getBoolean("active")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_admin = _resultSet.getBoolean("admin")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personNotes = _resultSet.getString("personNotes")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherName = _resultSet.getString("fatherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherName = _resultSet.getString("motherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherNum = _resultSet.getString("motherNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personAddress = _resultSet.getString("personAddress")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLct = _resultSet.getLong("personLct")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personCountry = _resultSet.getString("personCountry")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personType = _resultSet.getInt("personType")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          if(_person_nullFieldCount < 24) {
            if(_entity.person == null) {
              _entity.person = Person()
            }
            _entity.person!!.personUid = tmp_personUid
            _entity.person!!.username = tmp_username
            _entity.person!!.firstNames = tmp_firstNames
            _entity.person!!.lastName = tmp_lastName
            _entity.person!!.emailAddr = tmp_emailAddr
            _entity.person!!.phoneNum = tmp_phoneNum
            _entity.person!!.gender = tmp_gender
            _entity.person!!.active = tmp_active
            _entity.person!!.admin = tmp_admin
            _entity.person!!.personNotes = tmp_personNotes
            _entity.person!!.fatherName = tmp_fatherName
            _entity.person!!.fatherNumber = tmp_fatherNumber
            _entity.person!!.motherName = tmp_motherName
            _entity.person!!.motherNum = tmp_motherNum
            _entity.person!!.dateOfBirth = tmp_dateOfBirth
            _entity.person!!.personAddress = tmp_personAddress
            _entity.person!!.personOrgId = tmp_personOrgId
            _entity.person!!.personGroupUid = tmp_personGroupUid
            _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
            _entity.person!!.personLct = tmp_personLct
            _entity.person!!.personCountry = tmp_personCountry
            _entity.person!!.personType = tmp_personType
          }
          var _userSession_nullFieldCount = 0
          val tmp_usUid = _resultSet.getLong("usUid")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usPcsn = _resultSet.getLong("usPcsn")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usLcsn = _resultSet.getLong("usLcsn")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usLcb = _resultSet.getInt("usLcb")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usLct = _resultSet.getLong("usLct")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usPersonUid = _resultSet.getLong("usPersonUid")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usClientNodeId = _resultSet.getLong("usClientNodeId")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usStartTime = _resultSet.getLong("usStartTime")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usEndTime = _resultSet.getLong("usEndTime")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usStatus = _resultSet.getInt("usStatus")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usReason = _resultSet.getInt("usReason")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usAuth = _resultSet.getString("usAuth")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          val tmp_usSessionType = _resultSet.getInt("usSessionType")
          if(_resultSet.wasNull()) { _userSession_nullFieldCount++ }
          if(_userSession_nullFieldCount < 13) {
            if(_entity.userSession == null) {
              _entity.userSession = UserSession()
            }
            _entity.userSession!!.usUid = tmp_usUid
            _entity.userSession!!.usPcsn = tmp_usPcsn
            _entity.userSession!!.usLcsn = tmp_usLcsn
            _entity.userSession!!.usLcb = tmp_usLcb
            _entity.userSession!!.usLct = tmp_usLct
            _entity.userSession!!.usPersonUid = tmp_usPersonUid
            _entity.userSession!!.usClientNodeId = tmp_usClientNodeId
            _entity.userSession!!.usStartTime = tmp_usStartTime
            _entity.userSession!!.usEndTime = tmp_usEndTime
            _entity.userSession!!.usStatus = tmp_usStatus
            _entity.userSession!!.usReason = tmp_usReason
            _entity.userSession!!.usAuth = tmp_usAuth
            _entity.userSession!!.usSessionType = tmp_usSessionType
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun countAllLocalSessionsAsync(maxDateOfBirth: Long): Int {
    var _result = 0
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT COUNT(*)
    |          FROM UserSession
    |               JOIN Person 
    |                    ON UserSession.usPersonUid = Person.personUid
    |         WHERE UserSession.usClientNodeId = (
    |                   SELECT COALESCE(
    |                          (SELECT nodeClientId 
    |                            FROM SyncNode
    |                           LIMIT 1), 0))
    |           AND UserSession.usStatus = 1                
    |           AND (? = 0 OR Person.dateOfBirth < ?)                 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT COUNT(*)
    |          FROM UserSession
    |               JOIN Person 
    |                    ON UserSession.usPersonUid = Person.personUid
    |         WHERE UserSession.usClientNodeId = (
    |                   SELECT COALESCE(
    |                          (SELECT nodeClientId 
    |                            FROM SyncNode
    |                           LIMIT 1), 0))
    |           AND UserSession.usStatus = 1                
    |           AND (? = 0 OR Person.dateOfBirth < ?)                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, maxDateOfBirth)
      _stmt.setLong(2, maxDateOfBirth)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getInt(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun endSession(
    sessionUid: Long,
    newStatus: Int,
    reason: Int
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE UserSession
    |           SET usAuth = null,
    |               usStatus = ?,
    |               usReason = ?,
    |               usLcb = COALESCE(
    |                               (SELECT nodeClientId
    |                                  FROM SyncNode
    |                                 LIMIT 1), 0)
    |         WHERE UserSession.usUid = ?                        
    |               
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE UserSession
    |           SET usAuth = null,
    |               usStatus = ?,
    |               usReason = ?,
    |               usLcb = COALESCE(
    |                               (SELECT nodeClientId
    |                                  FROM SyncNode
    |                                 LIMIT 1), 0)
    |         WHERE UserSession.usUid = ?                        
    |               
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, newStatus)
      _stmt.setInt(2, reason)
      _stmt.setLong(3, sessionUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUidLive(sessionUid: Long): DoorLiveData<UserSession?> {
    val _result = DoorLiveDataImpl<UserSession?>(_db, listOf("UserSession"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.UserSession??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT UserSession.*
      |          FROM UserSession
      |         WHERE UserSession.usUid = ?
      |         LIMIT 1
      |    
      """.trimMargin() , postgreSql = """
      |
      |        SELECT UserSession.*
      |          FROM UserSession
      |         WHERE UserSession.usUid = ?
      |         LIMIT 1
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, sessionUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_usUid = _resultSet.getLong("usUid")
            val tmp_usPcsn = _resultSet.getLong("usPcsn")
            val tmp_usLcsn = _resultSet.getLong("usLcsn")
            val tmp_usLcb = _resultSet.getInt("usLcb")
            val tmp_usLct = _resultSet.getLong("usLct")
            val tmp_usPersonUid = _resultSet.getLong("usPersonUid")
            val tmp_usClientNodeId = _resultSet.getLong("usClientNodeId")
            val tmp_usStartTime = _resultSet.getLong("usStartTime")
            val tmp_usEndTime = _resultSet.getLong("usEndTime")
            val tmp_usStatus = _resultSet.getInt("usStatus")
            val tmp_usReason = _resultSet.getInt("usReason")
            val tmp_usAuth = _resultSet.getString("usAuth")
            val tmp_usSessionType = _resultSet.getInt("usSessionType")
            val _entity = UserSession()
            _entity.usUid = tmp_usUid
            _entity.usPcsn = tmp_usPcsn
            _entity.usLcsn = tmp_usLcsn
            _entity.usLcb = tmp_usLcb
            _entity.usLct = tmp_usLct
            _entity.usPersonUid = tmp_usPersonUid
            _entity.usClientNodeId = tmp_usClientNodeId
            _entity.usStartTime = tmp_usStartTime
            _entity.usEndTime = tmp_usEndTime
            _entity.usStatus = tmp_usStatus
            _entity.usReason = tmp_usReason
            _entity.usAuth = tmp_usAuth
            _entity.usSessionType = tmp_usSessionType
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun endOtherSessions(
    personUid: Long,
    exemptNodeId: Long,
    newStatus: Int,
    reason: Int,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE UserSession
    |           SET usAuth = null,
    |               usStatus = ?,
    |               usReason = ?,
    |               usLct = ?
    |         WHERE usPersonUid = ?
    |           AND usClientNodeId != ?
    |           AND usStatus != ?                     
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE UserSession
    |           SET usAuth = null,
    |               usStatus = ?,
    |               usReason = ?,
    |               usLct = ?
    |         WHERE usPersonUid = ?
    |           AND usClientNodeId != ?
    |           AND usStatus != ?                     
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, newStatus)
      _stmt.setInt(2, reason)
      _stmt.setLong(3, changeTime)
      _stmt.setLong(4, personUid)
      _stmt.setLong(5, exemptNodeId)
      _stmt.setInt(6, newStatus)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findActiveNodeIdsByPersonUids(personUids: List<Long>): List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT DISTINCT UserSession.usClientNodeId
    |          FROM UserSession
    |         WHERE UserSession.usPersonUid IN (?)
    |           AND UserSession.usStatus = 1
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        SELECT DISTINCT UserSession.usClientNodeId
    |          FROM UserSession
    |         WHERE UserSession.usPersonUid IN (?)
    |           AND UserSession.usStatus = 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          personUids.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findActiveNodesIdsByGroupUids(groupUids: List<Long>): List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT DISTINCT UserSession.usClientNodeId
    |          FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON PersonGroupMember.groupMemberPersonUid = UserSession.usPersonUid
    |         WHERE PersonGroupMember.groupMemberGroupUid IN (?)            
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        SELECT DISTINCT UserSession.usClientNodeId
    |          FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON PersonGroupMember.groupMemberPersonUid = UserSession.usPersonUid
    |         WHERE PersonGroupMember.groupMemberGroupUid IN (?)            
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          groupUids.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findAllActiveNodeIdsWithClazzBasedPermission(clazzUids: List<Long>):
      List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT UserSession.usClientNodeId
    |          FROM ScopedGrant
    |               JOIN PersonGroupMember 
    |                    ON PersonGroupMember.groupMemberGroupUid = ScopedGrant.sgGroupUid
    |               JOIN UserSession
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         WHERE (ScopedGrant.sgTableId = 6 AND ScopedGrant.sgEntityUid IN (?))
    |            OR (ScopedGrant.sgTableId = 164 AND ScopedGrant.sgEntityUid IN 
    |                (SELECT clazzSchoolUid
    |                   FROM Clazz
    |                  WHERE clazzUid IN (?)))
    |          
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        SELECT UserSession.usClientNodeId
    |          FROM ScopedGrant
    |               JOIN PersonGroupMember 
    |                    ON PersonGroupMember.groupMemberGroupUid = ScopedGrant.sgGroupUid
    |               JOIN UserSession
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         WHERE (ScopedGrant.sgTableId = 6 AND ScopedGrant.sgEntityUid IN (?))
    |            OR (ScopedGrant.sgTableId = 164 AND ScopedGrant.sgEntityUid IN 
    |                (SELECT clazzSchoolUid
    |                   FROM Clazz
    |                  WHERE clazzUid IN (?)))
    |          
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          clazzUids.toTypedArray()))
      _stmt.setArray(2, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          clazzUids.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids: List<Long>):
      List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT UserSession.usClientNodeId
    |          FROM ScopedGrant
    |               JOIN PersonGroupMember 
    |                    ON PersonGroupMember.groupMemberGroupUid = ScopedGrant.sgGroupUid
    |               JOIN UserSession
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         WHERE ScopedGrant.sgTableId = 164 
    |           AND ScopedGrant.sgEntityUid IN (?) 
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        SELECT UserSession.usClientNodeId
    |          FROM ScopedGrant
    |               JOIN PersonGroupMember 
    |                    ON PersonGroupMember.groupMemberGroupUid = ScopedGrant.sgGroupUid
    |               JOIN UserSession
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         WHERE ScopedGrant.sgTableId = 164 
    |           AND ScopedGrant.sgEntityUid IN (?) 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          schoolUids.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }
}
