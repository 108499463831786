package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonParentJoinWithMinorPerson
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class PersonParentJoinDao_JdbcKt(
  public val _db: DoorDatabase
) : PersonParentJoinDao() {
  public val _insertAdapterPersonParentJoin_: EntityInsertionAdapter<PersonParentJoin> = object :
      EntityInsertionAdapter<PersonParentJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO PersonParentJoin (ppjUid, ppjPcsn, ppjLcsn, ppjLcb, ppjLct, ppjParentPersonUid, ppjMinorPersonUid, ppjRelationship, ppjEmail, ppjPhone, ppjInactive, ppjStatus, ppjApprovalTiemstamp, ppjApprovalIpAddr) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO PersonParentJoin (ppjUid, ppjPcsn, ppjLcsn, ppjLcb, ppjLct, ppjParentPersonUid, ppjMinorPersonUid, ppjRelationship, ppjEmail, ppjPhone, ppjInactive, ppjStatus, ppjApprovalTiemstamp, ppjApprovalIpAddr) VALUES(COALESCE(?,nextval('PersonParentJoin_ppjUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING ppjUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonParentJoin):
        Unit {
      if(entity.ppjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ppjUid)
      }
      stmt.setLong(2, entity.ppjPcsn)
      stmt.setLong(3, entity.ppjLcsn)
      stmt.setInt(4, entity.ppjLcb)
      stmt.setLong(5, entity.ppjLct)
      stmt.setLong(6, entity.ppjParentPersonUid)
      stmt.setLong(7, entity.ppjMinorPersonUid)
      stmt.setInt(8, entity.ppjRelationship)
      stmt.setString(9, entity.ppjEmail)
      stmt.setString(10, entity.ppjPhone)
      stmt.setBoolean(11, entity.ppjInactive)
      stmt.setInt(12, entity.ppjStatus)
      stmt.setLong(13, entity.ppjApprovalTiemstamp)
      stmt.setString(14, entity.ppjApprovalIpAddr)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO PersonParentJoinReplicate(ppjPk, ppjDestination)
    |      SELECT DISTINCT PersonParentJoin.ppjUid AS ppjPk,
    |             ? AS ppjDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonParentJoin
    |                  ON PersonParentJoin.ppjParentPersonUid = Person.personUid       
    |       WHERE UserSession.usStatus = 1
    |         AND PersonParentJoin.ppjLct != COALESCE(
    |             (SELECT ppjVersionId
    |                FROM PersonParentJoinReplicate
    |               WHERE ppjPk = PersonParentJoin.ppjUid
    |                 AND ppjDestination = ?), 0) 
    |      /*psql ON CONFLICT(ppjPk, ppjDestination) DO UPDATE
    |             SET ppjPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonParentJoinReplicate(ppjPk, ppjDestination)
    |      SELECT DISTINCT PersonParentJoin.ppjUid AS ppjPk,
    |             ? AS ppjDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonParentJoin
    |                  ON PersonParentJoin.ppjParentPersonUid = Person.personUid       
    |       WHERE UserSession.usStatus = 1
    |         AND PersonParentJoin.ppjLct != COALESCE(
    |             (SELECT ppjVersionId
    |                FROM PersonParentJoinReplicate
    |               WHERE ppjPk = PersonParentJoin.ppjUid
    |                 AND ppjDestination = ?), 0) 
    |       ON CONFLICT(ppjPk, ppjDestination) DO UPDATE
    |             SET ppjPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonParentJoinReplicate(ppjPk, ppjDestination)
    |  SELECT DISTINCT PersonParentJoin.ppjUid AS ppjUid,
    |         UserSession.usClientNodeId AS ppjDestination
    |    FROM ChangeLog
    |         JOIN PersonParentJoin
    |             ON ChangeLog.chTableId = 512
    |                AND ChangeLog.chEntityPk = PersonParentJoin.ppjUid
    |         JOIN Person
    |              ON PersonParentJoin.ppjParentPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |               
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND PersonParentJoin.ppjLct != COALESCE(
    |         (SELECT ppjVersionId
    |            FROM PersonParentJoinReplicate
    |           WHERE ppjPk = PersonParentJoin.ppjUid
    |             AND ppjDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ppjPk, ppjDestination) DO UPDATE
    |     SET ppjPending = true
    |  */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonParentJoinReplicate(ppjPk, ppjDestination)
    |  SELECT DISTINCT PersonParentJoin.ppjUid AS ppjUid,
    |         UserSession.usClientNodeId AS ppjDestination
    |    FROM ChangeLog
    |         JOIN PersonParentJoin
    |             ON ChangeLog.chTableId = 512
    |                AND ChangeLog.chEntityPk = PersonParentJoin.ppjUid
    |         JOIN Person
    |              ON PersonParentJoin.ppjParentPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |               
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND PersonParentJoin.ppjLct != COALESCE(
    |         (SELECT ppjVersionId
    |            FROM PersonParentJoinReplicate
    |           WHERE ppjPk = PersonParentJoin.ppjUid
    |             AND ppjDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ppjPk, ppjDestination) DO UPDATE
    |     SET ppjPending = true
    |  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertAsync(entity: PersonParentJoin): Long {
    val _retVal = _insertAdapterPersonParentJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override suspend fun findByUidWithMinorAsync(uid: Long): PersonParentJoinWithMinorPerson? {
    var _result = null as com.ustadmobile.lib.db.entities.PersonParentJoinWithMinorPerson??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT PersonParentJoin.*, Person.*
    |          FROM PersonParentJoin
    |     LEFT JOIN Person ON Person.personUid = PersonParentJoin.ppjMinorPersonUid    
    |         WHERE PersonParentJoin.ppjUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT PersonParentJoin.*, Person.*
    |          FROM PersonParentJoin
    |     LEFT JOIN Person ON Person.personUid = PersonParentJoin.ppjMinorPersonUid    
    |         WHERE PersonParentJoin.ppjUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_ppjUid = _resultSet.getLong("ppjUid")
          val tmp_ppjPcsn = _resultSet.getLong("ppjPcsn")
          val tmp_ppjLcsn = _resultSet.getLong("ppjLcsn")
          val tmp_ppjLcb = _resultSet.getInt("ppjLcb")
          val tmp_ppjLct = _resultSet.getLong("ppjLct")
          val tmp_ppjParentPersonUid = _resultSet.getLong("ppjParentPersonUid")
          val tmp_ppjMinorPersonUid = _resultSet.getLong("ppjMinorPersonUid")
          val tmp_ppjRelationship = _resultSet.getInt("ppjRelationship")
          val tmp_ppjEmail = _resultSet.getString("ppjEmail")
          val tmp_ppjPhone = _resultSet.getString("ppjPhone")
          val tmp_ppjInactive = _resultSet.getBoolean("ppjInactive")
          val tmp_ppjStatus = _resultSet.getInt("ppjStatus")
          val tmp_ppjApprovalTiemstamp = _resultSet.getLong("ppjApprovalTiemstamp")
          val tmp_ppjApprovalIpAddr = _resultSet.getString("ppjApprovalIpAddr")
          val _entity = PersonParentJoinWithMinorPerson()
          _entity.ppjUid = tmp_ppjUid
          _entity.ppjPcsn = tmp_ppjPcsn
          _entity.ppjLcsn = tmp_ppjLcsn
          _entity.ppjLcb = tmp_ppjLcb
          _entity.ppjLct = tmp_ppjLct
          _entity.ppjParentPersonUid = tmp_ppjParentPersonUid
          _entity.ppjMinorPersonUid = tmp_ppjMinorPersonUid
          _entity.ppjRelationship = tmp_ppjRelationship
          _entity.ppjEmail = tmp_ppjEmail
          _entity.ppjPhone = tmp_ppjPhone
          _entity.ppjInactive = tmp_ppjInactive
          _entity.ppjStatus = tmp_ppjStatus
          _entity.ppjApprovalTiemstamp = tmp_ppjApprovalTiemstamp
          _entity.ppjApprovalIpAddr = tmp_ppjApprovalIpAddr
          var _minorPerson_nullFieldCount = 0
          val tmp_personUid = _resultSet.getLong("personUid")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_username = _resultSet.getString("username")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_firstNames = _resultSet.getString("firstNames")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_lastName = _resultSet.getString("lastName")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_gender = _resultSet.getInt("gender")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_active = _resultSet.getBoolean("active")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_admin = _resultSet.getBoolean("admin")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personNotes = _resultSet.getString("personNotes")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_fatherName = _resultSet.getString("fatherName")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_motherName = _resultSet.getString("motherName")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_motherNum = _resultSet.getString("motherNum")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personAddress = _resultSet.getString("personAddress")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personLct = _resultSet.getLong("personLct")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personCountry = _resultSet.getString("personCountry")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personType = _resultSet.getInt("personType")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          if(_minorPerson_nullFieldCount < 24) {
            if(_entity.minorPerson == null) {
              _entity.minorPerson = Person()
            }
            _entity.minorPerson!!.personUid = tmp_personUid
            _entity.minorPerson!!.username = tmp_username
            _entity.minorPerson!!.firstNames = tmp_firstNames
            _entity.minorPerson!!.lastName = tmp_lastName
            _entity.minorPerson!!.emailAddr = tmp_emailAddr
            _entity.minorPerson!!.phoneNum = tmp_phoneNum
            _entity.minorPerson!!.gender = tmp_gender
            _entity.minorPerson!!.active = tmp_active
            _entity.minorPerson!!.admin = tmp_admin
            _entity.minorPerson!!.personNotes = tmp_personNotes
            _entity.minorPerson!!.fatherName = tmp_fatherName
            _entity.minorPerson!!.fatherNumber = tmp_fatherNumber
            _entity.minorPerson!!.motherName = tmp_motherName
            _entity.minorPerson!!.motherNum = tmp_motherNum
            _entity.minorPerson!!.dateOfBirth = tmp_dateOfBirth
            _entity.minorPerson!!.personAddress = tmp_personAddress
            _entity.minorPerson!!.personOrgId = tmp_personOrgId
            _entity.minorPerson!!.personGroupUid = tmp_personGroupUid
            _entity.minorPerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.minorPerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.minorPerson!!.personLastChangedBy = tmp_personLastChangedBy
            _entity.minorPerson!!.personLct = tmp_personLct
            _entity.minorPerson!!.personCountry = tmp_personCountry
            _entity.minorPerson!!.personType = tmp_personType
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidWithMinorAsyncFromWeb(uid: Long):
      PersonParentJoinWithMinorPerson? {
    var _result = null as com.ustadmobile.lib.db.entities.PersonParentJoinWithMinorPerson??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT PersonParentJoin.*, Person.*
    |          FROM PersonParentJoin
    |     LEFT JOIN Person ON Person.personUid = PersonParentJoin.ppjMinorPersonUid    
    |         WHERE PersonParentJoin.ppjUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT PersonParentJoin.*, Person.*
    |          FROM PersonParentJoin
    |     LEFT JOIN Person ON Person.personUid = PersonParentJoin.ppjMinorPersonUid    
    |         WHERE PersonParentJoin.ppjUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_ppjUid = _resultSet.getLong("ppjUid")
          val tmp_ppjPcsn = _resultSet.getLong("ppjPcsn")
          val tmp_ppjLcsn = _resultSet.getLong("ppjLcsn")
          val tmp_ppjLcb = _resultSet.getInt("ppjLcb")
          val tmp_ppjLct = _resultSet.getLong("ppjLct")
          val tmp_ppjParentPersonUid = _resultSet.getLong("ppjParentPersonUid")
          val tmp_ppjMinorPersonUid = _resultSet.getLong("ppjMinorPersonUid")
          val tmp_ppjRelationship = _resultSet.getInt("ppjRelationship")
          val tmp_ppjEmail = _resultSet.getString("ppjEmail")
          val tmp_ppjPhone = _resultSet.getString("ppjPhone")
          val tmp_ppjInactive = _resultSet.getBoolean("ppjInactive")
          val tmp_ppjStatus = _resultSet.getInt("ppjStatus")
          val tmp_ppjApprovalTiemstamp = _resultSet.getLong("ppjApprovalTiemstamp")
          val tmp_ppjApprovalIpAddr = _resultSet.getString("ppjApprovalIpAddr")
          val _entity = PersonParentJoinWithMinorPerson()
          _entity.ppjUid = tmp_ppjUid
          _entity.ppjPcsn = tmp_ppjPcsn
          _entity.ppjLcsn = tmp_ppjLcsn
          _entity.ppjLcb = tmp_ppjLcb
          _entity.ppjLct = tmp_ppjLct
          _entity.ppjParentPersonUid = tmp_ppjParentPersonUid
          _entity.ppjMinorPersonUid = tmp_ppjMinorPersonUid
          _entity.ppjRelationship = tmp_ppjRelationship
          _entity.ppjEmail = tmp_ppjEmail
          _entity.ppjPhone = tmp_ppjPhone
          _entity.ppjInactive = tmp_ppjInactive
          _entity.ppjStatus = tmp_ppjStatus
          _entity.ppjApprovalTiemstamp = tmp_ppjApprovalTiemstamp
          _entity.ppjApprovalIpAddr = tmp_ppjApprovalIpAddr
          var _minorPerson_nullFieldCount = 0
          val tmp_personUid = _resultSet.getLong("personUid")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_username = _resultSet.getString("username")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_firstNames = _resultSet.getString("firstNames")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_lastName = _resultSet.getString("lastName")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_gender = _resultSet.getInt("gender")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_active = _resultSet.getBoolean("active")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_admin = _resultSet.getBoolean("admin")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personNotes = _resultSet.getString("personNotes")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_fatherName = _resultSet.getString("fatherName")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_motherName = _resultSet.getString("motherName")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_motherNum = _resultSet.getString("motherNum")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personAddress = _resultSet.getString("personAddress")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personLct = _resultSet.getLong("personLct")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personCountry = _resultSet.getString("personCountry")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          val tmp_personType = _resultSet.getInt("personType")
          if(_resultSet.wasNull()) { _minorPerson_nullFieldCount++ }
          if(_minorPerson_nullFieldCount < 24) {
            if(_entity.minorPerson == null) {
              _entity.minorPerson = Person()
            }
            _entity.minorPerson!!.personUid = tmp_personUid
            _entity.minorPerson!!.username = tmp_username
            _entity.minorPerson!!.firstNames = tmp_firstNames
            _entity.minorPerson!!.lastName = tmp_lastName
            _entity.minorPerson!!.emailAddr = tmp_emailAddr
            _entity.minorPerson!!.phoneNum = tmp_phoneNum
            _entity.minorPerson!!.gender = tmp_gender
            _entity.minorPerson!!.active = tmp_active
            _entity.minorPerson!!.admin = tmp_admin
            _entity.minorPerson!!.personNotes = tmp_personNotes
            _entity.minorPerson!!.fatherName = tmp_fatherName
            _entity.minorPerson!!.fatherNumber = tmp_fatherNumber
            _entity.minorPerson!!.motherName = tmp_motherName
            _entity.minorPerson!!.motherNum = tmp_motherNum
            _entity.minorPerson!!.dateOfBirth = tmp_dateOfBirth
            _entity.minorPerson!!.personAddress = tmp_personAddress
            _entity.minorPerson!!.personOrgId = tmp_personOrgId
            _entity.minorPerson!!.personGroupUid = tmp_personGroupUid
            _entity.minorPerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.minorPerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.minorPerson!!.personLastChangedBy = tmp_personLastChangedBy
            _entity.minorPerson!!.personLct = tmp_personLct
            _entity.minorPerson!!.personCountry = tmp_personCountry
            _entity.minorPerson!!.personType = tmp_personType
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByMinorPersonUid(minorPersonUid: Long): List<PersonParentJoin> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.PersonParentJoin>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT PersonParentJoin.*
    |          FROM PersonParentJoin
    |         WHERE ppjMinorPersonUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT PersonParentJoin.*
    |          FROM PersonParentJoin
    |         WHERE ppjMinorPersonUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, minorPersonUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_ppjUid = _resultSet.getLong("ppjUid")
          val tmp_ppjPcsn = _resultSet.getLong("ppjPcsn")
          val tmp_ppjLcsn = _resultSet.getLong("ppjLcsn")
          val tmp_ppjLcb = _resultSet.getInt("ppjLcb")
          val tmp_ppjLct = _resultSet.getLong("ppjLct")
          val tmp_ppjParentPersonUid = _resultSet.getLong("ppjParentPersonUid")
          val tmp_ppjMinorPersonUid = _resultSet.getLong("ppjMinorPersonUid")
          val tmp_ppjRelationship = _resultSet.getInt("ppjRelationship")
          val tmp_ppjEmail = _resultSet.getString("ppjEmail")
          val tmp_ppjPhone = _resultSet.getString("ppjPhone")
          val tmp_ppjInactive = _resultSet.getBoolean("ppjInactive")
          val tmp_ppjStatus = _resultSet.getInt("ppjStatus")
          val tmp_ppjApprovalTiemstamp = _resultSet.getLong("ppjApprovalTiemstamp")
          val tmp_ppjApprovalIpAddr = _resultSet.getString("ppjApprovalIpAddr")
          val _entity = PersonParentJoin()
          _entity.ppjUid = tmp_ppjUid
          _entity.ppjPcsn = tmp_ppjPcsn
          _entity.ppjLcsn = tmp_ppjLcsn
          _entity.ppjLcb = tmp_ppjLcb
          _entity.ppjLct = tmp_ppjLct
          _entity.ppjParentPersonUid = tmp_ppjParentPersonUid
          _entity.ppjMinorPersonUid = tmp_ppjMinorPersonUid
          _entity.ppjRelationship = tmp_ppjRelationship
          _entity.ppjEmail = tmp_ppjEmail
          _entity.ppjPhone = tmp_ppjPhone
          _entity.ppjInactive = tmp_ppjInactive
          _entity.ppjStatus = tmp_ppjStatus
          _entity.ppjApprovalTiemstamp = tmp_ppjApprovalTiemstamp
          _entity.ppjApprovalIpAddr = tmp_ppjApprovalIpAddr
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend
      fun findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid: Long,
      clazzUidFilter: Long): List<PersonParentJoinDao.ParentEnrolmentRequired> {
    var _result =
        mutableListOf<com.ustadmobile.core.db.dao.PersonParentJoinDao.ParentEnrolmentRequired>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT PersonParentJoin.ppjParentPersonUid AS parentPersonUid,
    |               ChildEnrolment.clazzEnrolmentClazzUid AS clazzUid
    |          FROM PersonParentJoin
    |               JOIN ClazzEnrolment ChildEnrolment 
    |                    ON ChildEnrolment.clazzEnrolmentPersonUid = ?
    |                   AND (? = 0 OR ChildEnrolment.clazzEnrolmentClazzUid = ?)
    |         WHERE PersonParentJoin.ppjMinorPersonUid = ?
    |           AND PersonParentJoin.ppjParentPersonUid != 0
    |           AND NOT EXISTS(
    |               SELECT clazzEnrolmentUid 
    |                 FROM ClazzEnrolment
    |                WHERE ClazzEnrolment.clazzEnrolmentPersonUid = PersonParentJoin.ppjParentPersonUid
    |                  AND ClazzEnrolment.clazzEnrolmentClazzUid = ChildEnrolment.clazzEnrolmentClazzUid
    |                  AND ClazzEnrolment.clazzEnrolmentRole = 1003
    |                  AND CAST(ClazzEnrolment.clazzEnrolmentActive AS INTEGER) = 1)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT PersonParentJoin.ppjParentPersonUid AS parentPersonUid,
    |               ChildEnrolment.clazzEnrolmentClazzUid AS clazzUid
    |          FROM PersonParentJoin
    |               JOIN ClazzEnrolment ChildEnrolment 
    |                    ON ChildEnrolment.clazzEnrolmentPersonUid = ?
    |                   AND (? = 0 OR ChildEnrolment.clazzEnrolmentClazzUid = ?)
    |         WHERE PersonParentJoin.ppjMinorPersonUid = ?
    |           AND PersonParentJoin.ppjParentPersonUid != 0
    |           AND NOT EXISTS(
    |               SELECT clazzEnrolmentUid 
    |                 FROM ClazzEnrolment
    |                WHERE ClazzEnrolment.clazzEnrolmentPersonUid = PersonParentJoin.ppjParentPersonUid
    |                  AND ClazzEnrolment.clazzEnrolmentClazzUid = ChildEnrolment.clazzEnrolmentClazzUid
    |                  AND ClazzEnrolment.clazzEnrolmentRole = 1003
    |                  AND CAST(ClazzEnrolment.clazzEnrolmentActive AS INTEGER) = 1)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, minorPersonUid)
      _stmt.setLong(2, clazzUidFilter)
      _stmt.setLong(3, clazzUidFilter)
      _stmt.setLong(4, minorPersonUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_parentPersonUid = _resultSet.getLong("parentPersonUid")
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val _entity = PersonParentJoinDao.ParentEnrolmentRequired()
          _entity.parentPersonUid = tmp_parentPersonUid
          _entity.clazzUid = tmp_clazzUid
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun isParentOf(userPersonUid: Long, minorPersonUid: Long): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT EXISTS(
    |               SELECT ppjUid
    |                 FROM PersonParentJoin
    |                WHERE ppjMinorPersonUid = ?
    |                      AND ppjParentPersonUid = ?
    |                      AND CAST(ppjInactive AS INTEGER) = 0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT EXISTS(
    |               SELECT ppjUid
    |                 FROM PersonParentJoin
    |                WHERE ppjMinorPersonUid = ?
    |                      AND ppjParentPersonUid = ?
    |                      AND CAST(ppjInactive AS INTEGER) = 0)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, minorPersonUid)
      _stmt.setLong(2, userPersonUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(personParentJoin: PersonParentJoin): Unit {
    val _sql =
        "UPDATE PersonParentJoin SET ppjPcsn = ?, ppjLcsn = ?, ppjLcb = ?, ppjLct = ?, ppjParentPersonUid = ?, ppjMinorPersonUid = ?, ppjRelationship = ?, ppjEmail = ?, ppjPhone = ?, ppjInactive = ?, ppjStatus = ?, ppjApprovalTiemstamp = ?, ppjApprovalIpAddr = ? WHERE ppjUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, personParentJoin.ppjPcsn)
      _stmt.setLong(2, personParentJoin.ppjLcsn)
      _stmt.setInt(3, personParentJoin.ppjLcb)
      _stmt.setLong(4, personParentJoin.ppjLct)
      _stmt.setLong(5, personParentJoin.ppjParentPersonUid)
      _stmt.setLong(6, personParentJoin.ppjMinorPersonUid)
      _stmt.setInt(7, personParentJoin.ppjRelationship)
      _stmt.setString(8, personParentJoin.ppjEmail)
      _stmt.setString(9, personParentJoin.ppjPhone)
      _stmt.setBoolean(10, personParentJoin.ppjInactive)
      _stmt.setInt(11, personParentJoin.ppjStatus)
      _stmt.setLong(12, personParentJoin.ppjApprovalTiemstamp)
      _stmt.setString(13, personParentJoin.ppjApprovalIpAddr)
      _stmt.setLong(14, personParentJoin.ppjUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun isMinorApproved(minorPersonUid: Long): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT EXISTS(
    |               SELECT ppjUid
    |                 FROM PersonParentJoin
    |                WHERE ppjMinorPersonUid = ?
    |                  AND CAST(ppjInactive AS INTEGER) = 0
    |                  AND ppjStatus = 1)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT EXISTS(
    |               SELECT ppjUid
    |                 FROM PersonParentJoin
    |                WHERE ppjMinorPersonUid = ?
    |                  AND CAST(ppjInactive AS INTEGER) = 0
    |                  AND ppjStatus = 1)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, minorPersonUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }
}
