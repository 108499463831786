package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ClazzContentJoin
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzContentJoinDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: ClazzContentJoinDao
) : ClazzContentJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun toggleVisibilityClazzContent(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long
  ): Unit {
    _dao.toggleVisibilityClazzContent(toggleVisibility, selectedItem, changedTime)
  }

  public override suspend fun listOfEntriesInClazz(clazzUid: Long): List<Long> =
      _dao.listOfEntriesInClazz(clazzUid)

  public override fun insert(entity: ClazzContentJoin): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzContentJoin): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.ccjUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(134)
      entity.ccjUid = _newPk
    }
    entity.ccjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.ccjUid
  }

  public override fun insertList(entityList: List<out ClazzContentJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out ClazzContentJoin>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzContentJoin): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
