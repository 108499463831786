package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentCategorySchema
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentCategorySchemaDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentCategorySchemaDao() {
  public val _insertAdapterContentCategorySchema_: EntityInsertionAdapter<ContentCategorySchema> =
      object : EntityInsertionAdapter<ContentCategorySchema>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentCategorySchema (contentCategorySchemaUid, schemaName, schemaUrl, contentCategorySchemaLocalChangeSeqNum, contentCategorySchemaMasterChangeSeqNum, contentCategorySchemaLastChangedBy, contentCategorySchemaLct) VALUES(?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentCategorySchema (contentCategorySchemaUid, schemaName, schemaUrl, contentCategorySchemaLocalChangeSeqNum, contentCategorySchemaMasterChangeSeqNum, contentCategorySchemaLastChangedBy, contentCategorySchemaLct) VALUES(COALESCE(?,nextval('ContentCategorySchema_contentCategorySchemaUid_seq')), ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING contentCategorySchemaUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentCategorySchema): Unit {
      if(entity.contentCategorySchemaUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentCategorySchemaUid)
      }
      stmt.setString(2, entity.schemaName)
      stmt.setString(3, entity.schemaUrl)
      stmt.setLong(4, entity.contentCategorySchemaLocalChangeSeqNum)
      stmt.setLong(5, entity.contentCategorySchemaMasterChangeSeqNum)
      stmt.setInt(6, entity.contentCategorySchemaLastChangedBy)
      stmt.setLong(7, entity.contentCategorySchemaLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentCategorySchemaReplicate(ccsPk, ccsDestination)
    |      SELECT DISTINCT ContentCategorySchema.contentCategorySchemaUid AS ccsPk,
    |             ? AS ccsDestination
    |        FROM ContentCategorySchema
    |       WHERE ContentCategorySchema.contentCategorySchemaLct != COALESCE(
    |             (SELECT ccsVersionId
    |                FROM ContentCategorySchemaReplicate
    |               WHERE ccsPk = ContentCategorySchema.contentCategorySchemaUid
    |                 AND ccsDestination = ?), 0) 
    |      /*psql ON CONFLICT(ccsPk, ccsDestination) DO UPDATE
    |             SET ccsPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentCategorySchemaReplicate(ccsPk, ccsDestination)
    |      SELECT DISTINCT ContentCategorySchema.contentCategorySchemaUid AS ccsPk,
    |             ? AS ccsDestination
    |        FROM ContentCategorySchema
    |       WHERE ContentCategorySchema.contentCategorySchemaLct != COALESCE(
    |             (SELECT ccsVersionId
    |                FROM ContentCategorySchemaReplicate
    |               WHERE ccsPk = ContentCategorySchema.contentCategorySchemaUid
    |                 AND ccsDestination = ?), 0) 
    |       ON CONFLICT(ccsPk, ccsDestination) DO UPDATE
    |             SET ccsPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ContentCategorySchemaReplicate(ccsPk, ccsDestination)
    |  SELECT DISTINCT ContentCategorySchema.contentCategorySchemaUid AS ccsUid,
    |         UserSession.usClientNodeId AS ccsDestination
    |    FROM ChangeLog
    |         JOIN ContentCategorySchema
    |             ON ChangeLog.chTableId = 2
    |                AND ChangeLog.chEntityPk = ContentCategorySchema.contentCategorySchemaUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentCategorySchema.contentCategorySchemaLct != COALESCE(
    |         (SELECT ccsVersionId
    |            FROM ContentCategorySchemaReplicate
    |           WHERE ccsPk = ContentCategorySchema.contentCategorySchemaUid
    |             AND ccsDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ccsPk, ccsDestination) DO UPDATE
    |     SET ccsPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentCategorySchemaReplicate(ccsPk, ccsDestination)
    |  SELECT DISTINCT ContentCategorySchema.contentCategorySchemaUid AS ccsUid,
    |         UserSession.usClientNodeId AS ccsDestination
    |    FROM ChangeLog
    |         JOIN ContentCategorySchema
    |             ON ChangeLog.chTableId = 2
    |                AND ChangeLog.chEntityPk = ContentCategorySchema.contentCategorySchemaUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentCategorySchema.contentCategorySchemaLct != COALESCE(
    |         (SELECT ccsVersionId
    |            FROM ContentCategorySchemaReplicate
    |           WHERE ccsPk = ContentCategorySchema.contentCategorySchemaUid
    |             AND ccsDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ccsPk, ccsDestination) DO UPDATE
    |     SET ccsPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun publicContentCategorySchemas(): List<ContentCategorySchema> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentCategorySchema>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentCategorySchema.* FROM ContentCategorySchema" ,
        postgreSql = """
    |SELECT ContentCategorySchema.* FROM ContentCategorySchema
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentCategorySchemaUid = _resultSet.getLong("contentCategorySchemaUid")
          val tmp_schemaName = _resultSet.getString("schemaName")
          val tmp_schemaUrl = _resultSet.getString("schemaUrl")
          val tmp_contentCategorySchemaLocalChangeSeqNum =
              _resultSet.getLong("contentCategorySchemaLocalChangeSeqNum")
          val tmp_contentCategorySchemaMasterChangeSeqNum =
              _resultSet.getLong("contentCategorySchemaMasterChangeSeqNum")
          val tmp_contentCategorySchemaLastChangedBy =
              _resultSet.getInt("contentCategorySchemaLastChangedBy")
          val tmp_contentCategorySchemaLct = _resultSet.getLong("contentCategorySchemaLct")
          val _entity = ContentCategorySchema()
          _entity.contentCategorySchemaUid = tmp_contentCategorySchemaUid
          _entity.schemaName = tmp_schemaName
          _entity.schemaUrl = tmp_schemaUrl
          _entity.contentCategorySchemaLocalChangeSeqNum =
              tmp_contentCategorySchemaLocalChangeSeqNum
          _entity.contentCategorySchemaMasterChangeSeqNum =
              tmp_contentCategorySchemaMasterChangeSeqNum
          _entity.contentCategorySchemaLastChangedBy = tmp_contentCategorySchemaLastChangedBy
          _entity.contentCategorySchemaLct = tmp_contentCategorySchemaLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findBySchemaUrl(schemaUrl: String): ContentCategorySchema? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentCategorySchema??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentCategorySchema WHERE schemaUrl = ?" ,
        postgreSql = """
    |SELECT * FROM ContentCategorySchema WHERE schemaUrl = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, schemaUrl)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentCategorySchemaUid = _resultSet.getLong("contentCategorySchemaUid")
          val tmp_schemaName = _resultSet.getString("schemaName")
          val tmp_schemaUrl = _resultSet.getString("schemaUrl")
          val tmp_contentCategorySchemaLocalChangeSeqNum =
              _resultSet.getLong("contentCategorySchemaLocalChangeSeqNum")
          val tmp_contentCategorySchemaMasterChangeSeqNum =
              _resultSet.getLong("contentCategorySchemaMasterChangeSeqNum")
          val tmp_contentCategorySchemaLastChangedBy =
              _resultSet.getInt("contentCategorySchemaLastChangedBy")
          val tmp_contentCategorySchemaLct = _resultSet.getLong("contentCategorySchemaLct")
          val _entity = ContentCategorySchema()
          _entity.contentCategorySchemaUid = tmp_contentCategorySchemaUid
          _entity.schemaName = tmp_schemaName
          _entity.schemaUrl = tmp_schemaUrl
          _entity.contentCategorySchemaLocalChangeSeqNum =
              tmp_contentCategorySchemaLocalChangeSeqNum
          _entity.contentCategorySchemaMasterChangeSeqNum =
              tmp_contentCategorySchemaMasterChangeSeqNum
          _entity.contentCategorySchemaLastChangedBy = tmp_contentCategorySchemaLastChangedBy
          _entity.contentCategorySchemaLct = tmp_contentCategorySchemaLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ContentCategorySchema): Long {
    val _retVal = _insertAdapterContentCategorySchema_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentCategorySchema): Long {
    val _retVal = _insertAdapterContentCategorySchema_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContentCategorySchema>): Unit {
    _insertAdapterContentCategorySchema_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentCategorySchema>): Unit {
    val _sql =
        "UPDATE ContentCategorySchema SET schemaName = ?, schemaUrl = ?, contentCategorySchemaLocalChangeSeqNum = ?, contentCategorySchemaMasterChangeSeqNum = ?, contentCategorySchemaLastChangedBy = ?, contentCategorySchemaLct = ? WHERE contentCategorySchemaUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.schemaName)
        _stmt.setString(2, _entity.schemaUrl)
        _stmt.setLong(3, _entity.contentCategorySchemaLocalChangeSeqNum)
        _stmt.setLong(4, _entity.contentCategorySchemaMasterChangeSeqNum)
        _stmt.setInt(5, _entity.contentCategorySchemaLastChangedBy)
        _stmt.setLong(6, _entity.contentCategorySchemaLct)
        _stmt.setLong(7, _entity.contentCategorySchemaUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContentCategorySchema): Unit {
    val _sql =
        "UPDATE ContentCategorySchema SET schemaName = ?, schemaUrl = ?, contentCategorySchemaLocalChangeSeqNum = ?, contentCategorySchemaMasterChangeSeqNum = ?, contentCategorySchemaLastChangedBy = ?, contentCategorySchemaLct = ? WHERE contentCategorySchemaUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.schemaName)
      _stmt.setString(2, entity.schemaUrl)
      _stmt.setLong(3, entity.contentCategorySchemaLocalChangeSeqNum)
      _stmt.setLong(4, entity.contentCategorySchemaMasterChangeSeqNum)
      _stmt.setInt(5, entity.contentCategorySchemaLastChangedBy)
      _stmt.setLong(6, entity.contentCategorySchemaLct)
      _stmt.setLong(7, entity.contentCategorySchemaUid)
      _stmt.executeUpdate()
    }
  }
}
