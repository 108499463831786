package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.LocallyAvailableContainer
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Unit
import kotlin.collections.List

public class LocallyAvailableContainerDao_JdbcKt(
  public val _db: DoorDatabase
) : LocallyAvailableContainerDao() {
  public val _insertAdapterLocallyAvailableContainer_upsert:
      EntityInsertionAdapter<LocallyAvailableContainer> = object :
      EntityInsertionAdapter<LocallyAvailableContainer>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO LocallyAvailableContainer (laContainerUid) VALUES(?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO LocallyAvailableContainer (laContainerUid) VALUES(?) ON CONFLICT (laContainerUid) DO UPDATE SET " + if(returnsId) { " RETURNING laContainerUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: LocallyAvailableContainer): Unit {
      stmt.setLong(1, entity.laContainerUid)
    }
  }

  public override suspend
      fun insertList(locallyAvailableContainers: List<LocallyAvailableContainer>): Unit {
    _insertAdapterLocallyAvailableContainer_upsert.insertListAsync(locallyAvailableContainers)
  }

  public override suspend
      fun deleteList(locallyAvailableContainers: List<LocallyAvailableContainer>): Unit {
    var _numChanges = 0
    _db.prepareAndUseStatementAsync("DELETE FROM LocallyAvailableContainer WHERE laContainerUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in locallyAvailableContainers) {
        _stmt.setLong(1, _entity.laContainerUid)
        _numChanges += _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun deleteAll(): Unit {
    val _stmtConfig = PreparedStatementConfig("DELETE FROM LocallyAvailableContainer" , postgreSql =
        """
    |DELETE FROM LocallyAvailableContainer
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdate()
    }
  }
}
