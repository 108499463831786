package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.AccessToken
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAuth
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonAuthDao_JdbcKt(
  public val _db: DoorDatabase
) : PersonAuthDao() {
  public val _insertAdapterAccessToken_: EntityInsertionAdapter<AccessToken> = object :
      EntityInsertionAdapter<AccessToken>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO AccessToken (token, accessTokenPersonUid, expires) VALUES(?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO AccessToken (token, accessTokenPersonUid, expires) VALUES(?, ?, ?)" + if(returnsId) { " RETURNING token" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: AccessToken):
        Unit {
      stmt.setString(1, entity.token)
      stmt.setLong(2, entity.accessTokenPersonUid)
      stmt.setLong(3, entity.expires)
    }
  }

  public val _insertAdapterPersonAuth_: EntityInsertionAdapter<PersonAuth> = object :
      EntityInsertionAdapter<PersonAuth>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO PersonAuth (personAuthUid, passwordHash, personAuthStatus) VALUES(?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO PersonAuth (personAuthUid, passwordHash, personAuthStatus) VALUES(COALESCE(?,nextval('PersonAuth_personAuthUid_seq')), ?, ?)" + if(returnsId) { " RETURNING personAuthUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonAuth):
        Unit {
      if(entity.personAuthUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personAuthUid)
      }
      stmt.setString(2, entity.passwordHash)
      stmt.setInt(3, entity.personAuthStatus)
    }
  }

  public override suspend fun findByUidAsync(uid: Long): PersonAuth? {
    var _result = null as com.ustadmobile.lib.db.entities.PersonAuth??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM PersonAuth WHERE personAuthUid = ?" ,
        postgreSql = """
    |SELECT * FROM PersonAuth WHERE personAuthUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personAuthUid = _resultSet.getLong("personAuthUid")
          val tmp_passwordHash = _resultSet.getString("passwordHash")
          val tmp_personAuthStatus = _resultSet.getInt("personAuthStatus")
          val _entity = PersonAuth()
          _entity.personAuthUid = tmp_personAuthUid
          _entity.passwordHash = tmp_passwordHash
          _entity.personAuthStatus = tmp_personAuthStatus
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUid(uid: Long): PersonAuth? {
    var _result = null as com.ustadmobile.lib.db.entities.PersonAuth??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM PersonAuth WHERE personAuthUid = ?" ,
        postgreSql = """
    |SELECT * FROM PersonAuth WHERE personAuthUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personAuthUid = _resultSet.getLong("personAuthUid")
          val tmp_passwordHash = _resultSet.getString("passwordHash")
          val tmp_personAuthStatus = _resultSet.getInt("personAuthStatus")
          val _entity = PersonAuth()
          _entity.personAuthUid = tmp_personAuthUid
          _entity.passwordHash = tmp_passwordHash
          _entity.personAuthStatus = tmp_personAuthStatus
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findPersonByUsername(username: String): Person? {
    var _result = null as com.ustadmobile.lib.db.entities.Person??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Person WHERE username = ?" , postgreSql
        = """
    |SELECT * FROM Person WHERE username = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, username)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: PersonAuth): Int {
    var _result = 0
    val _sql =
        "UPDATE PersonAuth SET passwordHash = ?, personAuthStatus = ? WHERE personAuthUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.passwordHash)
      _stmt.setInt(2, entity.personAuthStatus)
      _stmt.setLong(3, entity.personAuthUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun isPersonAdmin(uid: Long): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("SELECT admin from Person WHERE personUid = ?" ,
        postgreSql = """
    |SELECT admin from Person WHERE personUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updatePasswordForPersonUid(personUid: Long, passwordHash: String):
      Int {
    var _result = 0
    val _stmtConfig =
        PreparedStatementConfig("UPDATE PersonAuth set passwordHash = ?  WHERE personAuthUid = ?" ,
        postgreSql = """
    |UPDATE PersonAuth set passwordHash = ?  WHERE personAuthUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, passwordHash)
      _stmt.setLong(2, personUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
      _result = _numUpdates
    }
    return _result
  }

  public override fun insertAccessToken(token: AccessToken): Unit {
    _insertAdapterAccessToken_.insert(token)
  }

  public override fun insert(entity: PersonAuth): Long {
    val _retVal = _insertAdapterPersonAuth_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: PersonAuth): Long {
    val _retVal = _insertAdapterPersonAuth_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out PersonAuth>): Unit {
    _insertAdapterPersonAuth_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out PersonAuth>): Unit {
    val _sql =
        "UPDATE PersonAuth SET passwordHash = ?, personAuthStatus = ? WHERE personAuthUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.passwordHash)
        _stmt.setInt(2, _entity.personAuthStatus)
        _stmt.setLong(3, _entity.personAuthUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: PersonAuth): Unit {
    val _sql =
        "UPDATE PersonAuth SET passwordHash = ?, personAuthStatus = ? WHERE personAuthUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.passwordHash)
      _stmt.setInt(2, entity.personAuthStatus)
      _stmt.setLong(3, entity.personAuthUid)
      _stmt.executeUpdate()
    }
  }
}
