package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.AssignmentProgressSummary
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ClazzAssignmentWithCourseBlock
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.PersonGroupAssignmentSummary
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ClazzAssignmentDao_JdbcKt(
  public val _db: DoorDatabase
) : ClazzAssignmentDao() {
  public val _insertAdapterClazzAssignment_: EntityInsertionAdapter<ClazzAssignment> = object :
      EntityInsertionAdapter<ClazzAssignment>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ClazzAssignment (caUid, caTitle, caDescription, caGroupUid, caActive, caClassCommentEnabled, caPrivateCommentsEnabled, caCompletionCriteria, caRequireFileSubmission, caFileType, caSizeLimit, caNumberOfFiles, caSubmissionPolicy, caMarkingType, caRequireTextSubmission, caTextLimitType, caTextLimit, caXObjectUid, caClazzUid, caLocalChangeSeqNum, caMasterChangeSeqNum, caLastChangedBy, caLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ClazzAssignment (caUid, caTitle, caDescription, caGroupUid, caActive, caClassCommentEnabled, caPrivateCommentsEnabled, caCompletionCriteria, caRequireFileSubmission, caFileType, caSizeLimit, caNumberOfFiles, caSubmissionPolicy, caMarkingType, caRequireTextSubmission, caTextLimitType, caTextLimit, caXObjectUid, caClazzUid, caLocalChangeSeqNum, caMasterChangeSeqNum, caLastChangedBy, caLct) VALUES(COALESCE(?,nextval('ClazzAssignment_caUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING caUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzAssignment):
        Unit {
      if(entity.caUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.caUid)
      }
      stmt.setString(2, entity.caTitle)
      stmt.setString(3, entity.caDescription)
      stmt.setLong(4, entity.caGroupUid)
      stmt.setBoolean(5, entity.caActive)
      stmt.setBoolean(6, entity.caClassCommentEnabled)
      stmt.setBoolean(7, entity.caPrivateCommentsEnabled)
      stmt.setInt(8, entity.caCompletionCriteria)
      stmt.setBoolean(9, entity.caRequireFileSubmission)
      stmt.setInt(10, entity.caFileType)
      stmt.setInt(11, entity.caSizeLimit)
      stmt.setInt(12, entity.caNumberOfFiles)
      stmt.setInt(13, entity.caSubmissionPolicy)
      stmt.setInt(14, entity.caMarkingType)
      stmt.setBoolean(15, entity.caRequireTextSubmission)
      stmt.setInt(16, entity.caTextLimitType)
      stmt.setInt(17, entity.caTextLimit)
      stmt.setLong(18, entity.caXObjectUid)
      stmt.setLong(19, entity.caClazzUid)
      stmt.setLong(20, entity.caLocalChangeSeqNum)
      stmt.setLong(21, entity.caMasterChangeSeqNum)
      stmt.setInt(22, entity.caLastChangedBy)
      stmt.setLong(23, entity.caLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzAssignmentReplicate(caPk, caDestination)
    |      SELECT DISTINCT ClazzAssignment.caUid AS caUid,
    |             ? AS caDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ClazzAssignment.caLct != COALESCE(
    |             (SELECT caVersionId
    |                FROM ClazzAssignmentReplicate
    |               WHERE caPk = ClazzAssignment.caUid
    |                 AND caDestination = ?), 0) 
    |      /*psql ON CONFLICT(caPk, caDestination) DO UPDATE
    |             SET caPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentReplicate(caPk, caDestination)
    |      SELECT DISTINCT ClazzAssignment.caUid AS caUid,
    |             ? AS caDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ClazzAssignment.caLct != COALESCE(
    |             (SELECT caVersionId
    |                FROM ClazzAssignmentReplicate
    |               WHERE caPk = ClazzAssignment.caUid
    |                 AND caDestination = ?), 0) 
    |       ON CONFLICT(caPk, caDestination) DO UPDATE
    |             SET caPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzAssignmentReplicate(caPk, caDestination)
    |  SELECT DISTINCT ClazzAssignment.caUid AS caUid,
    |         UserSession.usClientNodeId AS caDestination
    |    FROM ChangeLog
    |         JOIN ClazzAssignment
    |             ON ChangeLog.chTableId = 520
    |                AND ChangeLog.chEntityPk = ClazzAssignment.caUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzAssignment.caClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzAssignment.caLct != COALESCE(
    |         (SELECT caVersionId
    |            FROM ClazzAssignmentReplicate
    |           WHERE caPk = ClazzAssignment.caUid
    |             AND caDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(caPk, caDestination) DO UPDATE
    |     SET caPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentReplicate(caPk, caDestination)
    |  SELECT DISTINCT ClazzAssignment.caUid AS caUid,
    |         UserSession.usClientNodeId AS caDestination
    |    FROM ChangeLog
    |         JOIN ClazzAssignment
    |             ON ChangeLog.chTableId = 520
    |                AND ChangeLog.chEntityPk = ClazzAssignment.caUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzAssignment.caClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzAssignment.caLct != COALESCE(
    |         (SELECT caVersionId
    |            FROM ClazzAssignmentReplicate
    |           WHERE caPk = ClazzAssignment.caUid
    |             AND caDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(caPk, caDestination) DO UPDATE
    |     SET caPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun getAllAssignmentsByClazzUidAsync(clazzUid: Long):
      List<ClazzAssignment> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ClazzAssignment>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM ClazzAssignment
    |         WHERE ClazzAssignment.caClazzUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM ClazzAssignment
    |         WHERE ClazzAssignment.caClazzUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_caUid = _resultSet.getLong("caUid")
          val tmp_caTitle = _resultSet.getString("caTitle")
          val tmp_caDescription = _resultSet.getString("caDescription")
          val tmp_caGroupUid = _resultSet.getLong("caGroupUid")
          val tmp_caActive = _resultSet.getBoolean("caActive")
          val tmp_caClassCommentEnabled = _resultSet.getBoolean("caClassCommentEnabled")
          val tmp_caPrivateCommentsEnabled = _resultSet.getBoolean("caPrivateCommentsEnabled")
          val tmp_caCompletionCriteria = _resultSet.getInt("caCompletionCriteria")
          val tmp_caRequireFileSubmission = _resultSet.getBoolean("caRequireFileSubmission")
          val tmp_caFileType = _resultSet.getInt("caFileType")
          val tmp_caSizeLimit = _resultSet.getInt("caSizeLimit")
          val tmp_caNumberOfFiles = _resultSet.getInt("caNumberOfFiles")
          val tmp_caSubmissionPolicy = _resultSet.getInt("caSubmissionPolicy")
          val tmp_caMarkingType = _resultSet.getInt("caMarkingType")
          val tmp_caRequireTextSubmission = _resultSet.getBoolean("caRequireTextSubmission")
          val tmp_caTextLimitType = _resultSet.getInt("caTextLimitType")
          val tmp_caTextLimit = _resultSet.getInt("caTextLimit")
          val tmp_caXObjectUid = _resultSet.getLong("caXObjectUid")
          val tmp_caClazzUid = _resultSet.getLong("caClazzUid")
          val tmp_caLocalChangeSeqNum = _resultSet.getLong("caLocalChangeSeqNum")
          val tmp_caMasterChangeSeqNum = _resultSet.getLong("caMasterChangeSeqNum")
          val tmp_caLastChangedBy = _resultSet.getInt("caLastChangedBy")
          val tmp_caLct = _resultSet.getLong("caLct")
          val _entity = ClazzAssignment()
          _entity.caUid = tmp_caUid
          _entity.caTitle = tmp_caTitle
          _entity.caDescription = tmp_caDescription
          _entity.caGroupUid = tmp_caGroupUid
          _entity.caActive = tmp_caActive
          _entity.caClassCommentEnabled = tmp_caClassCommentEnabled
          _entity.caPrivateCommentsEnabled = tmp_caPrivateCommentsEnabled
          _entity.caCompletionCriteria = tmp_caCompletionCriteria
          _entity.caRequireFileSubmission = tmp_caRequireFileSubmission
          _entity.caFileType = tmp_caFileType
          _entity.caSizeLimit = tmp_caSizeLimit
          _entity.caNumberOfFiles = tmp_caNumberOfFiles
          _entity.caSubmissionPolicy = tmp_caSubmissionPolicy
          _entity.caMarkingType = tmp_caMarkingType
          _entity.caRequireTextSubmission = tmp_caRequireTextSubmission
          _entity.caTextLimitType = tmp_caTextLimitType
          _entity.caTextLimit = tmp_caTextLimit
          _entity.caXObjectUid = tmp_caXObjectUid
          _entity.caClazzUid = tmp_caClazzUid
          _entity.caLocalChangeSeqNum = tmp_caLocalChangeSeqNum
          _entity.caMasterChangeSeqNum = tmp_caMasterChangeSeqNum
          _entity.caLastChangedBy = tmp_caLastChangedBy
          _entity.caLct = tmp_caLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ClazzAssignment 
    |           SET caActive = ?, 
    |               caLct = ?
    |         WHERE caUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ClazzAssignment 
    |           SET caActive = ?, 
    |               caLct = ?
    |         WHERE caUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, active)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, cbUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getProgressSummaryForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String
  ): DoorLiveData<AssignmentProgressSummary?> {
    val _result = DoorLiveDataImpl<AssignmentProgressSummary?>(_db, listOf("ClazzEnrolment",
        "Person", "ClazzAssignment", "CourseBlock", "CourseGroupMember",
        "CourseAssignmentSubmission", "CourseAssignmentMark"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.AssignmentProgressSummary??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |            
      |            WITH SubmitterList (submitterId, name)
      |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
      |                       Person.firstNames || ' ' || Person.lastName AS name
      |                  FROM ClazzEnrolment
      |                  
      |                       JOIN Person 
      |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
      |                        
      |                       JOIN ClazzAssignment
      |                       ON ClazzAssignment.caUid = ?
      |
      |                       JOIN CourseBlock
      |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
      |                       AND CourseBlock.cbType = 103
      |                       
      |                 WHERE ClazzAssignment.caGroupUid = 0
      |                   AND clazzEnrolmentClazzUid = ?
      |                   AND clazzEnrolmentActive
      |                   AND clazzEnrolmentRole = 1000
      |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
      |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
      |              GROUP BY submitterId, name
      |            UNION                 
      |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
      |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
      |               FROM CourseGroupMember
      |                    JOIN ClazzAssignment
      |                    ON ClazzAssignment.caUid = ?
      |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
      |                AND ClazzAssignment.caGroupUid != 0
      |                AND CourseGroupMember.cgmGroupNumber != 0
      |           GROUP BY submitterId, name
      |            )
      |        
      |            
      |            SELECT (SELECT COUNT(*) FROM SubmitterList) AS totalStudents,
      |            
      |                    0 as notSubmittedStudents,
      |                    
      |                    (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
      |                      FROM CourseAssignmentSubmission
      |                           LEFT JOIN CourseAssignmentMark
      |                           ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
      |                           AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
      |                     WHERE CourseAssignmentSubmission.casAssignmentUid = ?
      |                       AND CourseAssignmentMark.camUid IS NULL
      |                       AND CourseAssignmentSubmission.casSubmitterUid IN (SELECT submitterId 
      |                                                                            FROM SubmitterList))
      |                      AS submittedStudents,
      |                     
      |                     
      |                     (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
      |                        FROM CourseAssignmentMark
      |                            
      |                             JOIN CourseAssignmentSubmission
      |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
      |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
      |                             
      |                       WHERE CourseAssignmentMark.camAssignmentUid = ?
      |                         AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
      |                                                                            FROM SubmitterList))
      |                         AS markedStudents, 
      |                         
      |                         'TRUE' AS hasMetricsPermission
      |                         
      |         FROM  ClazzAssignment
      |        WHERE caActive
      |          AND caClazzUid = ? 
      |          AND caUid = ?                  
      |    
      """.trimMargin() , postgreSql = """
      |
      |            
      |            WITH SubmitterList (submitterId, name)
      |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
      |                       Person.firstNames || ' ' || Person.lastName AS name
      |                  FROM ClazzEnrolment
      |                  
      |                       JOIN Person 
      |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
      |                        
      |                       JOIN ClazzAssignment
      |                       ON ClazzAssignment.caUid = ?
      |
      |                       JOIN CourseBlock
      |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
      |                       AND CourseBlock.cbType = 103
      |                       
      |                 WHERE ClazzAssignment.caGroupUid = 0
      |                   AND clazzEnrolmentClazzUid = ?
      |                   AND clazzEnrolmentActive
      |                   AND clazzEnrolmentRole = 1000
      |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
      |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
      |              GROUP BY submitterId, name
      |            UNION                 
      |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
      |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
      |               FROM CourseGroupMember
      |                    JOIN ClazzAssignment
      |                    ON ClazzAssignment.caUid = ?
      |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
      |                AND ClazzAssignment.caGroupUid != 0
      |                AND CourseGroupMember.cgmGroupNumber != 0
      |           GROUP BY submitterId, name
      |            )
      |        
      |            
      |            SELECT (SELECT COUNT(*) FROM SubmitterList) AS totalStudents,
      |            
      |                    0 as notSubmittedStudents,
      |                    
      |                    (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
      |                      FROM CourseAssignmentSubmission
      |                           LEFT JOIN CourseAssignmentMark
      |                           ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
      |                           AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
      |                     WHERE CourseAssignmentSubmission.casAssignmentUid = ?
      |                       AND CourseAssignmentMark.camUid IS NULL
      |                       AND CourseAssignmentSubmission.casSubmitterUid IN (SELECT submitterId 
      |                                                                            FROM SubmitterList))
      |                      AS submittedStudents,
      |                     
      |                     
      |                     (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
      |                        FROM CourseAssignmentMark
      |                            
      |                             JOIN CourseAssignmentSubmission
      |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
      |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
      |                             
      |                       WHERE CourseAssignmentMark.camAssignmentUid = ?
      |                         AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
      |                                                                            FROM SubmitterList))
      |                         AS markedStudents, 
      |                         
      |                         'TRUE' AS hasMetricsPermission
      |                         
      |         FROM  ClazzAssignment
      |        WHERE caActive
      |          AND caClazzUid = ? 
      |          AND caUid = ?                  
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, assignmentUid)
        _stmt.setLong(2, clazzUid)
        _stmt.setString(3, group)
        _stmt.setLong(4, assignmentUid)
        _stmt.setLong(5, assignmentUid)
        _stmt.setLong(6, assignmentUid)
        _stmt.setLong(7, clazzUid)
        _stmt.setLong(8, assignmentUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_notSubmittedStudents = _resultSet.getInt("notSubmittedStudents")
            val tmp_submittedStudents = _resultSet.getInt("submittedStudents")
            val tmp_totalStudents = _resultSet.getInt("totalStudents")
            val tmp_markedStudents = _resultSet.getInt("markedStudents")
            val tmp_hasMetricsPermission = _resultSet.getBoolean("hasMetricsPermission")
            val _entity = AssignmentProgressSummary()
            _entity.notSubmittedStudents = tmp_notSubmittedStudents
            _entity.submittedStudents = tmp_submittedStudents
            _entity.totalStudents = tmp_totalStudents
            _entity.markedStudents = tmp_markedStudents
            _entity.hasMetricsPermission = tmp_hasMetricsPermission
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun getSubmitterListForAssignment(
    assignmentUid: Long,
    clazzUid: Long,
    group: String,
    searchText: String
  ): DoorDataSourceFactory<Int, PersonGroupAssignmentSummary> {
    val _result = object : DoorDataSourceFactory<Int, PersonGroupAssignmentSummary>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<PersonGroupAssignmentSummary>> =
          DoorLiveDataImpl<List<PersonGroupAssignmentSummary>>(_db, listOf("ClazzEnrolment",
          "Person", "ClazzAssignment", "CourseBlock", "CourseGroupMember", "CourseAssignmentMark",
          "CourseAssignmentSubmission", "Comments"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.PersonGroupAssignmentSummary>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |         
        |            WITH SubmitterList (submitterId, name)
        |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
        |                       Person.firstNames || ' ' || Person.lastName AS name
        |                  FROM ClazzEnrolment
        |                  
        |                       JOIN Person 
        |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
        |                        
        |                       JOIN ClazzAssignment
        |                       ON ClazzAssignment.caUid = ?
        |
        |                       JOIN CourseBlock
        |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
        |                       AND CourseBlock.cbType = 103
        |                       
        |                 WHERE ClazzAssignment.caGroupUid = 0
        |                   AND clazzEnrolmentClazzUid = ?
        |                   AND clazzEnrolmentActive
        |                   AND clazzEnrolmentRole = 1000
        |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
        |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
        |              GROUP BY submitterId, name
        |            UNION                 
        |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
        |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
        |               FROM CourseGroupMember
        |                    JOIN ClazzAssignment
        |                    ON ClazzAssignment.caUid = ?
        |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
        |                AND ClazzAssignment.caGroupUid != 0
        |                AND CourseGroupMember.cgmGroupNumber != 0
        |           GROUP BY submitterId, name
        |            )
        |        
        |        
        |         SELECT submitterId AS submitterUid,
        |                name, 
        |                
        |                 COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
        |                          THEN 2 
        |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
        |                          THEN 1 
        |                          ELSE 0 END), 
        |                               0) AS fileSubmissionStatus,
        |                
        |                (CASE WHEN ClazzAssignment.caGroupUid = 0 
        |                 THEN 'TRUE' 
        |                 ELSE 'FALSE' END) AS isGroupAssignment,
        |                 
        |                 cm.commentsText AS latestPrivateComment 
        |
        |           FROM SubmitterList
        |                JOIN ClazzAssignment
        |                ON ClazzAssignment.caUid = ?
        |           
        |                LEFT JOIN CourseAssignmentMark
        |                ON CourseAssignmentMark.camUid = (SELECT camUid
        |                                                    FROM CourseAssignmentMark
        |                                                   WHERE camAssignmentUid = ?
        |                                                     AND camSubmitterUid = SubmitterList.submitterId
        |                                                ORDER BY camLct DESC 
        |                                                   LIMIT 1)
        |                
        |                LEFT JOIN CourseAssignmentSubmission
        |                ON CourseAssignmentSubmission.casUid = (SELECT casUid
        |                                                          FROM CourseAssignmentSubmission
        |                                                         WHERE casAssignmentUid = ?
        |                                                           AND casSubmitterUid = SubmitterList.submitterId
        |                                                      ORDER BY casTimestamp DESC 
        |                                                         LIMIT 1)
        |                LEFT JOIN Comments AS cm 
        |                    ON cm.commentsUid = (
        |                                 SELECT Comments.commentsUid 
        |                                   FROM Comments 
        |                                  WHERE Comments.commentsEntityType = 520
        |                                    AND commentsEntityUid = ?
        |                                    AND NOT commentsInActive
        |                                    AND NOT commentsPublic
        |                                    AND (CASE WHEN ClazzAssignment.caGroupUid = 0
        |                                              THEN commentsPersonUid = SubmitterList.submitterId
        |                                              ELSE commentSubmitterUid = SubmitterList.submitterId END)
        |                               ORDER BY commentsDateTimeAdded DESC LIMIT 1)                                                      
        |                                                                      
        |          WHERE name LIKE ?
        |       ORDER BY name 
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |         
        |            WITH SubmitterList (submitterId, name)
        |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
        |                       Person.firstNames || ' ' || Person.lastName AS name
        |                  FROM ClazzEnrolment
        |                  
        |                       JOIN Person 
        |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
        |                        
        |                       JOIN ClazzAssignment
        |                       ON ClazzAssignment.caUid = ?
        |
        |                       JOIN CourseBlock
        |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
        |                       AND CourseBlock.cbType = 103
        |                       
        |                 WHERE ClazzAssignment.caGroupUid = 0
        |                   AND clazzEnrolmentClazzUid = ?
        |                   AND clazzEnrolmentActive
        |                   AND clazzEnrolmentRole = 1000
        |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
        |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
        |              GROUP BY submitterId, name
        |            UNION                 
        |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
        |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
        |               FROM CourseGroupMember
        |                    JOIN ClazzAssignment
        |                    ON ClazzAssignment.caUid = ?
        |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
        |                AND ClazzAssignment.caGroupUid != 0
        |                AND CourseGroupMember.cgmGroupNumber != 0
        |           GROUP BY submitterId, name
        |            )
        |        
        |        
        |         SELECT submitterId AS submitterUid,
        |                name, 
        |                
        |                 COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
        |                          THEN 2 
        |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
        |                          THEN 1 
        |                          ELSE 0 END), 
        |                               0) AS fileSubmissionStatus,
        |                
        |                (CASE WHEN ClazzAssignment.caGroupUid = 0 
        |                 THEN 'TRUE' 
        |                 ELSE 'FALSE' END) AS isGroupAssignment,
        |                 
        |                 cm.commentsText AS latestPrivateComment 
        |
        |           FROM SubmitterList
        |                JOIN ClazzAssignment
        |                ON ClazzAssignment.caUid = ?
        |           
        |                LEFT JOIN CourseAssignmentMark
        |                ON CourseAssignmentMark.camUid = (SELECT camUid
        |                                                    FROM CourseAssignmentMark
        |                                                   WHERE camAssignmentUid = ?
        |                                                     AND camSubmitterUid = SubmitterList.submitterId
        |                                                ORDER BY camLct DESC 
        |                                                   LIMIT 1)
        |                
        |                LEFT JOIN CourseAssignmentSubmission
        |                ON CourseAssignmentSubmission.casUid = (SELECT casUid
        |                                                          FROM CourseAssignmentSubmission
        |                                                         WHERE casAssignmentUid = ?
        |                                                           AND casSubmitterUid = SubmitterList.submitterId
        |                                                      ORDER BY casTimestamp DESC 
        |                                                         LIMIT 1)
        |                LEFT JOIN Comments AS cm 
        |                    ON cm.commentsUid = (
        |                                 SELECT Comments.commentsUid 
        |                                   FROM Comments 
        |                                  WHERE Comments.commentsEntityType = 520
        |                                    AND commentsEntityUid = ?
        |                                    AND NOT commentsInActive
        |                                    AND NOT commentsPublic
        |                                    AND (CASE WHEN ClazzAssignment.caGroupUid = 0
        |                                              THEN commentsPersonUid = SubmitterList.submitterId
        |                                              ELSE commentSubmitterUid = SubmitterList.submitterId END)
        |                               ORDER BY commentsDateTimeAdded DESC LIMIT 1)                                                      
        |                                                                      
        |          WHERE name LIKE ?
        |       ORDER BY name 
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, assignmentUid)
          _stmt.setLong(2, clazzUid)
          _stmt.setString(3, group)
          _stmt.setLong(4, assignmentUid)
          _stmt.setLong(5, assignmentUid)
          _stmt.setLong(6, assignmentUid)
          _stmt.setLong(7, assignmentUid)
          _stmt.setLong(8, assignmentUid)
          _stmt.setString(9, searchText)
          _stmt.setInt(10, _limit)
          _stmt.setInt(11, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_submitterUid = _resultSet.getLong("submitterUid")
              val tmp_name = _resultSet.getString("name")
              val tmp_latestPrivateComment = _resultSet.getString("latestPrivateComment")
              val tmp_fileSubmissionStatus = _resultSet.getInt("fileSubmissionStatus")
              val tmp_isGroupAssignment = _resultSet.getBoolean("isGroupAssignment")
              val _entity = PersonGroupAssignmentSummary()
              _entity.submitterUid = tmp_submitterUid
              _entity.name = tmp_name
              _entity.latestPrivateComment = tmp_latestPrivateComment
              _entity.fileSubmissionStatus = tmp_fileSubmissionStatus
              _entity.isGroupAssignment = tmp_isGroupAssignment
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ClazzEnrolment", "Person", "ClazzAssignment", "CourseBlock", "CourseGroupMember",
          "CourseAssignmentMark", "CourseAssignmentSubmission", "Comments"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |         
        |            WITH SubmitterList (submitterId, name)
        |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
        |                       Person.firstNames || ' ' || Person.lastName AS name
        |                  FROM ClazzEnrolment
        |                  
        |                       JOIN Person 
        |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
        |                        
        |                       JOIN ClazzAssignment
        |                       ON ClazzAssignment.caUid = ?
        |
        |                       JOIN CourseBlock
        |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
        |                       AND CourseBlock.cbType = 103
        |                       
        |                 WHERE ClazzAssignment.caGroupUid = 0
        |                   AND clazzEnrolmentClazzUid = ?
        |                   AND clazzEnrolmentActive
        |                   AND clazzEnrolmentRole = 1000
        |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
        |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
        |              GROUP BY submitterId, name
        |            UNION                 
        |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
        |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
        |               FROM CourseGroupMember
        |                    JOIN ClazzAssignment
        |                    ON ClazzAssignment.caUid = ?
        |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
        |                AND ClazzAssignment.caGroupUid != 0
        |                AND CourseGroupMember.cgmGroupNumber != 0
        |           GROUP BY submitterId, name
        |            )
        |        
        |        
        |         SELECT submitterId AS submitterUid,
        |                name, 
        |                
        |                 COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
        |                          THEN 2 
        |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
        |                          THEN 1 
        |                          ELSE 0 END), 
        |                               0) AS fileSubmissionStatus,
        |                
        |                (CASE WHEN ClazzAssignment.caGroupUid = 0 
        |                 THEN 'TRUE' 
        |                 ELSE 'FALSE' END) AS isGroupAssignment,
        |                 
        |                 cm.commentsText AS latestPrivateComment 
        |
        |           FROM SubmitterList
        |                JOIN ClazzAssignment
        |                ON ClazzAssignment.caUid = ?
        |           
        |                LEFT JOIN CourseAssignmentMark
        |                ON CourseAssignmentMark.camUid = (SELECT camUid
        |                                                    FROM CourseAssignmentMark
        |                                                   WHERE camAssignmentUid = ?
        |                                                     AND camSubmitterUid = SubmitterList.submitterId
        |                                                ORDER BY camLct DESC 
        |                                                   LIMIT 1)
        |                
        |                LEFT JOIN CourseAssignmentSubmission
        |                ON CourseAssignmentSubmission.casUid = (SELECT casUid
        |                                                          FROM CourseAssignmentSubmission
        |                                                         WHERE casAssignmentUid = ?
        |                                                           AND casSubmitterUid = SubmitterList.submitterId
        |                                                      ORDER BY casTimestamp DESC 
        |                                                         LIMIT 1)
        |                LEFT JOIN Comments AS cm 
        |                    ON cm.commentsUid = (
        |                                 SELECT Comments.commentsUid 
        |                                   FROM Comments 
        |                                  WHERE Comments.commentsEntityType = 520
        |                                    AND commentsEntityUid = ?
        |                                    AND NOT commentsInActive
        |                                    AND NOT commentsPublic
        |                                    AND (CASE WHEN ClazzAssignment.caGroupUid = 0
        |                                              THEN commentsPersonUid = SubmitterList.submitterId
        |                                              ELSE commentSubmitterUid = SubmitterList.submitterId END)
        |                               ORDER BY commentsDateTimeAdded DESC LIMIT 1)                                                      
        |                                                                      
        |          WHERE name LIKE ?
        |       ORDER BY name 
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |         
        |            WITH SubmitterList (submitterId, name)
        |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId, 
        |                       Person.firstNames || ' ' || Person.lastName AS name
        |                  FROM ClazzEnrolment
        |                  
        |                       JOIN Person 
        |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
        |                        
        |                       JOIN ClazzAssignment
        |                       ON ClazzAssignment.caUid = ?
        |
        |                       JOIN CourseBlock
        |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
        |                       AND CourseBlock.cbType = 103
        |                       
        |                 WHERE ClazzAssignment.caGroupUid = 0
        |                   AND clazzEnrolmentClazzUid = ?
        |                   AND clazzEnrolmentActive
        |                   AND clazzEnrolmentRole = 1000
        |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
        |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
        |              GROUP BY submitterId, name
        |            UNION                 
        |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
        |                    ? || ' ' || CourseGroupMember.cgmGroupNumber AS name  
        |               FROM CourseGroupMember
        |                    JOIN ClazzAssignment
        |                    ON ClazzAssignment.caUid = ?
        |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
        |                AND ClazzAssignment.caGroupUid != 0
        |                AND CourseGroupMember.cgmGroupNumber != 0
        |           GROUP BY submitterId, name
        |            )
        |        
        |        
        |         SELECT submitterId AS submitterUid,
        |                name, 
        |                
        |                 COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
        |                          THEN 2 
        |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
        |                          THEN 1 
        |                          ELSE 0 END), 
        |                               0) AS fileSubmissionStatus,
        |                
        |                (CASE WHEN ClazzAssignment.caGroupUid = 0 
        |                 THEN 'TRUE' 
        |                 ELSE 'FALSE' END) AS isGroupAssignment,
        |                 
        |                 cm.commentsText AS latestPrivateComment 
        |
        |           FROM SubmitterList
        |                JOIN ClazzAssignment
        |                ON ClazzAssignment.caUid = ?
        |           
        |                LEFT JOIN CourseAssignmentMark
        |                ON CourseAssignmentMark.camUid = (SELECT camUid
        |                                                    FROM CourseAssignmentMark
        |                                                   WHERE camAssignmentUid = ?
        |                                                     AND camSubmitterUid = SubmitterList.submitterId
        |                                                ORDER BY camLct DESC 
        |                                                   LIMIT 1)
        |                
        |                LEFT JOIN CourseAssignmentSubmission
        |                ON CourseAssignmentSubmission.casUid = (SELECT casUid
        |                                                          FROM CourseAssignmentSubmission
        |                                                         WHERE casAssignmentUid = ?
        |                                                           AND casSubmitterUid = SubmitterList.submitterId
        |                                                      ORDER BY casTimestamp DESC 
        |                                                         LIMIT 1)
        |                LEFT JOIN Comments AS cm 
        |                    ON cm.commentsUid = (
        |                                 SELECT Comments.commentsUid 
        |                                   FROM Comments 
        |                                  WHERE Comments.commentsEntityType = 520
        |                                    AND commentsEntityUid = ?
        |                                    AND NOT commentsInActive
        |                                    AND NOT commentsPublic
        |                                    AND (CASE WHEN ClazzAssignment.caGroupUid = 0
        |                                              THEN commentsPersonUid = SubmitterList.submitterId
        |                                              ELSE commentSubmitterUid = SubmitterList.submitterId END)
        |                               ORDER BY commentsDateTimeAdded DESC LIMIT 1)                                                      
        |                                                                      
        |          WHERE name LIKE ?
        |       ORDER BY name 
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, assignmentUid)
          _stmt.setLong(2, clazzUid)
          _stmt.setString(3, group)
          _stmt.setLong(4, assignmentUid)
          _stmt.setLong(5, assignmentUid)
          _stmt.setLong(6, assignmentUid)
          _stmt.setLong(7, assignmentUid)
          _stmt.setLong(8, assignmentUid)
          _stmt.setString(9, searchText)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun getSubmitterUid(assignmentUid: Long, personUid: Long): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT (CASE WHEN ClazzAssignment.caGroupUid = 0 
    |                     THEN ? 
    |                     WHEN CourseGroupMember.cgmUid IS NULL 
    |                     THEN 0 
    |                     ELSE CourseGroupMember.cgmGroupNumber END) as submitterUid
    |          FROM ClazzAssignment
    |               LEFT JOIN CourseGroupMember
    |               ON cgmSetUid = ClazzAssignment.caGroupUid
    |               AND cgmPersonUid = ?
    |         WHERE caUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT (CASE WHEN ClazzAssignment.caGroupUid = 0 
    |                     THEN ? 
    |                     WHEN CourseGroupMember.cgmUid IS NULL 
    |                     THEN 0 
    |                     ELSE CourseGroupMember.cgmGroupNumber END) as submitterUid
    |          FROM ClazzAssignment
    |               LEFT JOIN CourseGroupMember
    |               ON cgmSetUid = ClazzAssignment.caGroupUid
    |               AND cgmPersonUid = ?
    |         WHERE caUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, personUid)
      _stmt.setLong(2, personUid)
      _stmt.setLong(3, assignmentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(clazzAssignment: ClazzAssignment): Unit {
    val _sql =
        "UPDATE ClazzAssignment SET caTitle = ?, caDescription = ?, caGroupUid = ?, caActive = ?, caClassCommentEnabled = ?, caPrivateCommentsEnabled = ?, caCompletionCriteria = ?, caRequireFileSubmission = ?, caFileType = ?, caSizeLimit = ?, caNumberOfFiles = ?, caSubmissionPolicy = ?, caMarkingType = ?, caRequireTextSubmission = ?, caTextLimitType = ?, caTextLimit = ?, caXObjectUid = ?, caClazzUid = ?, caLocalChangeSeqNum = ?, caMasterChangeSeqNum = ?, caLastChangedBy = ?, caLct = ? WHERE caUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, clazzAssignment.caTitle)
      _stmt.setString(2, clazzAssignment.caDescription)
      _stmt.setLong(3, clazzAssignment.caGroupUid)
      _stmt.setBoolean(4, clazzAssignment.caActive)
      _stmt.setBoolean(5, clazzAssignment.caClassCommentEnabled)
      _stmt.setBoolean(6, clazzAssignment.caPrivateCommentsEnabled)
      _stmt.setInt(7, clazzAssignment.caCompletionCriteria)
      _stmt.setBoolean(8, clazzAssignment.caRequireFileSubmission)
      _stmt.setInt(9, clazzAssignment.caFileType)
      _stmt.setInt(10, clazzAssignment.caSizeLimit)
      _stmt.setInt(11, clazzAssignment.caNumberOfFiles)
      _stmt.setInt(12, clazzAssignment.caSubmissionPolicy)
      _stmt.setInt(13, clazzAssignment.caMarkingType)
      _stmt.setBoolean(14, clazzAssignment.caRequireTextSubmission)
      _stmt.setInt(15, clazzAssignment.caTextLimitType)
      _stmt.setInt(16, clazzAssignment.caTextLimit)
      _stmt.setLong(17, clazzAssignment.caXObjectUid)
      _stmt.setLong(18, clazzAssignment.caClazzUid)
      _stmt.setLong(19, clazzAssignment.caLocalChangeSeqNum)
      _stmt.setLong(20, clazzAssignment.caMasterChangeSeqNum)
      _stmt.setInt(21, clazzAssignment.caLastChangedBy)
      _stmt.setLong(22, clazzAssignment.caLct)
      _stmt.setLong(23, clazzAssignment.caUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByUidAsync(uid: Long): ClazzAssignment? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzAssignment??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM ClazzAssignment 
    |         WHERE caUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM ClazzAssignment 
    |         WHERE caUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_caUid = _resultSet.getLong("caUid")
          val tmp_caTitle = _resultSet.getString("caTitle")
          val tmp_caDescription = _resultSet.getString("caDescription")
          val tmp_caGroupUid = _resultSet.getLong("caGroupUid")
          val tmp_caActive = _resultSet.getBoolean("caActive")
          val tmp_caClassCommentEnabled = _resultSet.getBoolean("caClassCommentEnabled")
          val tmp_caPrivateCommentsEnabled = _resultSet.getBoolean("caPrivateCommentsEnabled")
          val tmp_caCompletionCriteria = _resultSet.getInt("caCompletionCriteria")
          val tmp_caRequireFileSubmission = _resultSet.getBoolean("caRequireFileSubmission")
          val tmp_caFileType = _resultSet.getInt("caFileType")
          val tmp_caSizeLimit = _resultSet.getInt("caSizeLimit")
          val tmp_caNumberOfFiles = _resultSet.getInt("caNumberOfFiles")
          val tmp_caSubmissionPolicy = _resultSet.getInt("caSubmissionPolicy")
          val tmp_caMarkingType = _resultSet.getInt("caMarkingType")
          val tmp_caRequireTextSubmission = _resultSet.getBoolean("caRequireTextSubmission")
          val tmp_caTextLimitType = _resultSet.getInt("caTextLimitType")
          val tmp_caTextLimit = _resultSet.getInt("caTextLimit")
          val tmp_caXObjectUid = _resultSet.getLong("caXObjectUid")
          val tmp_caClazzUid = _resultSet.getLong("caClazzUid")
          val tmp_caLocalChangeSeqNum = _resultSet.getLong("caLocalChangeSeqNum")
          val tmp_caMasterChangeSeqNum = _resultSet.getLong("caMasterChangeSeqNum")
          val tmp_caLastChangedBy = _resultSet.getInt("caLastChangedBy")
          val tmp_caLct = _resultSet.getLong("caLct")
          val _entity = ClazzAssignment()
          _entity.caUid = tmp_caUid
          _entity.caTitle = tmp_caTitle
          _entity.caDescription = tmp_caDescription
          _entity.caGroupUid = tmp_caGroupUid
          _entity.caActive = tmp_caActive
          _entity.caClassCommentEnabled = tmp_caClassCommentEnabled
          _entity.caPrivateCommentsEnabled = tmp_caPrivateCommentsEnabled
          _entity.caCompletionCriteria = tmp_caCompletionCriteria
          _entity.caRequireFileSubmission = tmp_caRequireFileSubmission
          _entity.caFileType = tmp_caFileType
          _entity.caSizeLimit = tmp_caSizeLimit
          _entity.caNumberOfFiles = tmp_caNumberOfFiles
          _entity.caSubmissionPolicy = tmp_caSubmissionPolicy
          _entity.caMarkingType = tmp_caMarkingType
          _entity.caRequireTextSubmission = tmp_caRequireTextSubmission
          _entity.caTextLimitType = tmp_caTextLimitType
          _entity.caTextLimit = tmp_caTextLimit
          _entity.caXObjectUid = tmp_caXObjectUid
          _entity.caClazzUid = tmp_caClazzUid
          _entity.caLocalChangeSeqNum = tmp_caLocalChangeSeqNum
          _entity.caMasterChangeSeqNum = tmp_caMasterChangeSeqNum
          _entity.caLastChangedBy = tmp_caLastChangedBy
          _entity.caLct = tmp_caLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun getGroupUidFromAssignment(uid: Long): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |          SELECT COALESCE((
    |           SELECT caGroupUid
    |           FROM ClazzAssignment
    |          WHERE caUid = ?),-1)
    |    
    """.trimMargin() , postgreSql = """
    |
    |          SELECT COALESCE((
    |           SELECT caGroupUid
    |           FROM ClazzAssignment
    |          WHERE caUid = ?),-1)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidWithBlockAsync(uid: Long): ClazzAssignmentWithCourseBlock? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzAssignmentWithCourseBlock??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM ClazzAssignment
    |               LEFT JOIN CourseBlock
    |               ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |               AND CourseBlock.cbType = 103
    |         WHERE caUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM ClazzAssignment
    |               LEFT JOIN CourseBlock
    |               ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |               AND CourseBlock.cbType = 103
    |         WHERE caUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_caUid = _resultSet.getLong("caUid")
          val tmp_caTitle = _resultSet.getString("caTitle")
          val tmp_caDescription = _resultSet.getString("caDescription")
          val tmp_caGroupUid = _resultSet.getLong("caGroupUid")
          val tmp_caActive = _resultSet.getBoolean("caActive")
          val tmp_caClassCommentEnabled = _resultSet.getBoolean("caClassCommentEnabled")
          val tmp_caPrivateCommentsEnabled = _resultSet.getBoolean("caPrivateCommentsEnabled")
          val tmp_caCompletionCriteria = _resultSet.getInt("caCompletionCriteria")
          val tmp_caRequireFileSubmission = _resultSet.getBoolean("caRequireFileSubmission")
          val tmp_caFileType = _resultSet.getInt("caFileType")
          val tmp_caSizeLimit = _resultSet.getInt("caSizeLimit")
          val tmp_caNumberOfFiles = _resultSet.getInt("caNumberOfFiles")
          val tmp_caSubmissionPolicy = _resultSet.getInt("caSubmissionPolicy")
          val tmp_caMarkingType = _resultSet.getInt("caMarkingType")
          val tmp_caRequireTextSubmission = _resultSet.getBoolean("caRequireTextSubmission")
          val tmp_caTextLimitType = _resultSet.getInt("caTextLimitType")
          val tmp_caTextLimit = _resultSet.getInt("caTextLimit")
          val tmp_caXObjectUid = _resultSet.getLong("caXObjectUid")
          val tmp_caClazzUid = _resultSet.getLong("caClazzUid")
          val tmp_caLocalChangeSeqNum = _resultSet.getLong("caLocalChangeSeqNum")
          val tmp_caMasterChangeSeqNum = _resultSet.getLong("caMasterChangeSeqNum")
          val tmp_caLastChangedBy = _resultSet.getInt("caLastChangedBy")
          val tmp_caLct = _resultSet.getLong("caLct")
          val _entity = ClazzAssignmentWithCourseBlock()
          _entity.caUid = tmp_caUid
          _entity.caTitle = tmp_caTitle
          _entity.caDescription = tmp_caDescription
          _entity.caGroupUid = tmp_caGroupUid
          _entity.caActive = tmp_caActive
          _entity.caClassCommentEnabled = tmp_caClassCommentEnabled
          _entity.caPrivateCommentsEnabled = tmp_caPrivateCommentsEnabled
          _entity.caCompletionCriteria = tmp_caCompletionCriteria
          _entity.caRequireFileSubmission = tmp_caRequireFileSubmission
          _entity.caFileType = tmp_caFileType
          _entity.caSizeLimit = tmp_caSizeLimit
          _entity.caNumberOfFiles = tmp_caNumberOfFiles
          _entity.caSubmissionPolicy = tmp_caSubmissionPolicy
          _entity.caMarkingType = tmp_caMarkingType
          _entity.caRequireTextSubmission = tmp_caRequireTextSubmission
          _entity.caTextLimitType = tmp_caTextLimitType
          _entity.caTextLimit = tmp_caTextLimit
          _entity.caXObjectUid = tmp_caXObjectUid
          _entity.caClazzUid = tmp_caClazzUid
          _entity.caLocalChangeSeqNum = tmp_caLocalChangeSeqNum
          _entity.caMasterChangeSeqNum = tmp_caMasterChangeSeqNum
          _entity.caLastChangedBy = tmp_caLastChangedBy
          _entity.caLct = tmp_caLct
          var _block_nullFieldCount = 0
          val tmp_cbUid = _resultSet.getLong("cbUid")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbType = _resultSet.getInt("cbType")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbIndentLevel = _resultSet.getInt("cbIndentLevel")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbModuleParentBlockUid = _resultSet.getLong("cbModuleParentBlockUid")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbTitle = _resultSet.getString("cbTitle")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbDescription = _resultSet.getString("cbDescription")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbCompletionCriteria = _resultSet.getInt("cbCompletionCriteria")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbHideUntilDate = _resultSet.getLong("cbHideUntilDate")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbDeadlineDate = _resultSet.getLong("cbDeadlineDate")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbLateSubmissionPenalty = _resultSet.getInt("cbLateSubmissionPenalty")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbGracePeriodDate = _resultSet.getLong("cbGracePeriodDate")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbMaxPoints = _resultSet.getInt("cbMaxPoints")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbMinPoints = _resultSet.getInt("cbMinPoints")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbIndex = _resultSet.getInt("cbIndex")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbClazzUid = _resultSet.getLong("cbClazzUid")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbActive = _resultSet.getBoolean("cbActive")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbHidden = _resultSet.getBoolean("cbHidden")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbEntityUid = _resultSet.getLong("cbEntityUid")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbLct = _resultSet.getLong("cbLct")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          if(_block_nullFieldCount < 19) {
            if(_entity.block == null) {
              _entity.block = CourseBlock()
            }
            _entity.block!!.cbUid = tmp_cbUid
            _entity.block!!.cbType = tmp_cbType
            _entity.block!!.cbIndentLevel = tmp_cbIndentLevel
            _entity.block!!.cbModuleParentBlockUid = tmp_cbModuleParentBlockUid
            _entity.block!!.cbTitle = tmp_cbTitle
            _entity.block!!.cbDescription = tmp_cbDescription
            _entity.block!!.cbCompletionCriteria = tmp_cbCompletionCriteria
            _entity.block!!.cbHideUntilDate = tmp_cbHideUntilDate
            _entity.block!!.cbDeadlineDate = tmp_cbDeadlineDate
            _entity.block!!.cbLateSubmissionPenalty = tmp_cbLateSubmissionPenalty
            _entity.block!!.cbGracePeriodDate = tmp_cbGracePeriodDate
            _entity.block!!.cbMaxPoints = tmp_cbMaxPoints
            _entity.block!!.cbMinPoints = tmp_cbMinPoints
            _entity.block!!.cbIndex = tmp_cbIndex
            _entity.block!!.cbClazzUid = tmp_cbClazzUid
            _entity.block!!.cbActive = tmp_cbActive
            _entity.block!!.cbHidden = tmp_cbHidden
            _entity.block!!.cbEntityUid = tmp_cbEntityUid
            _entity.block!!.cbLct = tmp_cbLct
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findClazzAssignment(): ClazzAssignment? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzAssignment??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM ClazzAssignment LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM ClazzAssignment LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_caUid = _resultSet.getLong("caUid")
          val tmp_caTitle = _resultSet.getString("caTitle")
          val tmp_caDescription = _resultSet.getString("caDescription")
          val tmp_caGroupUid = _resultSet.getLong("caGroupUid")
          val tmp_caActive = _resultSet.getBoolean("caActive")
          val tmp_caClassCommentEnabled = _resultSet.getBoolean("caClassCommentEnabled")
          val tmp_caPrivateCommentsEnabled = _resultSet.getBoolean("caPrivateCommentsEnabled")
          val tmp_caCompletionCriteria = _resultSet.getInt("caCompletionCriteria")
          val tmp_caRequireFileSubmission = _resultSet.getBoolean("caRequireFileSubmission")
          val tmp_caFileType = _resultSet.getInt("caFileType")
          val tmp_caSizeLimit = _resultSet.getInt("caSizeLimit")
          val tmp_caNumberOfFiles = _resultSet.getInt("caNumberOfFiles")
          val tmp_caSubmissionPolicy = _resultSet.getInt("caSubmissionPolicy")
          val tmp_caMarkingType = _resultSet.getInt("caMarkingType")
          val tmp_caRequireTextSubmission = _resultSet.getBoolean("caRequireTextSubmission")
          val tmp_caTextLimitType = _resultSet.getInt("caTextLimitType")
          val tmp_caTextLimit = _resultSet.getInt("caTextLimit")
          val tmp_caXObjectUid = _resultSet.getLong("caXObjectUid")
          val tmp_caClazzUid = _resultSet.getLong("caClazzUid")
          val tmp_caLocalChangeSeqNum = _resultSet.getLong("caLocalChangeSeqNum")
          val tmp_caMasterChangeSeqNum = _resultSet.getLong("caMasterChangeSeqNum")
          val tmp_caLastChangedBy = _resultSet.getInt("caLastChangedBy")
          val tmp_caLct = _resultSet.getLong("caLct")
          val _entity = ClazzAssignment()
          _entity.caUid = tmp_caUid
          _entity.caTitle = tmp_caTitle
          _entity.caDescription = tmp_caDescription
          _entity.caGroupUid = tmp_caGroupUid
          _entity.caActive = tmp_caActive
          _entity.caClassCommentEnabled = tmp_caClassCommentEnabled
          _entity.caPrivateCommentsEnabled = tmp_caPrivateCommentsEnabled
          _entity.caCompletionCriteria = tmp_caCompletionCriteria
          _entity.caRequireFileSubmission = tmp_caRequireFileSubmission
          _entity.caFileType = tmp_caFileType
          _entity.caSizeLimit = tmp_caSizeLimit
          _entity.caNumberOfFiles = tmp_caNumberOfFiles
          _entity.caSubmissionPolicy = tmp_caSubmissionPolicy
          _entity.caMarkingType = tmp_caMarkingType
          _entity.caRequireTextSubmission = tmp_caRequireTextSubmission
          _entity.caTextLimitType = tmp_caTextLimitType
          _entity.caTextLimit = tmp_caTextLimit
          _entity.caXObjectUid = tmp_caXObjectUid
          _entity.caClazzUid = tmp_caClazzUid
          _entity.caLocalChangeSeqNum = tmp_caLocalChangeSeqNum
          _entity.caMasterChangeSeqNum = tmp_caMasterChangeSeqNum
          _entity.caLastChangedBy = tmp_caLastChangedBy
          _entity.caLct = tmp_caLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<ClazzAssignment?> {
    val _result = DoorLiveDataImpl<ClazzAssignment?>(_db, listOf("ClazzAssignment"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ClazzAssignment??
      val _stmtConfig = PreparedStatementConfig("""
      |SELECT * 
      |                      FROM ClazzAssignment 
      |                     WHERE caUid = ?
      """.trimMargin() , postgreSql = """
      |SELECT * 
      |                      FROM ClazzAssignment 
      |                     WHERE caUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_caUid = _resultSet.getLong("caUid")
            val tmp_caTitle = _resultSet.getString("caTitle")
            val tmp_caDescription = _resultSet.getString("caDescription")
            val tmp_caGroupUid = _resultSet.getLong("caGroupUid")
            val tmp_caActive = _resultSet.getBoolean("caActive")
            val tmp_caClassCommentEnabled = _resultSet.getBoolean("caClassCommentEnabled")
            val tmp_caPrivateCommentsEnabled = _resultSet.getBoolean("caPrivateCommentsEnabled")
            val tmp_caCompletionCriteria = _resultSet.getInt("caCompletionCriteria")
            val tmp_caRequireFileSubmission = _resultSet.getBoolean("caRequireFileSubmission")
            val tmp_caFileType = _resultSet.getInt("caFileType")
            val tmp_caSizeLimit = _resultSet.getInt("caSizeLimit")
            val tmp_caNumberOfFiles = _resultSet.getInt("caNumberOfFiles")
            val tmp_caSubmissionPolicy = _resultSet.getInt("caSubmissionPolicy")
            val tmp_caMarkingType = _resultSet.getInt("caMarkingType")
            val tmp_caRequireTextSubmission = _resultSet.getBoolean("caRequireTextSubmission")
            val tmp_caTextLimitType = _resultSet.getInt("caTextLimitType")
            val tmp_caTextLimit = _resultSet.getInt("caTextLimit")
            val tmp_caXObjectUid = _resultSet.getLong("caXObjectUid")
            val tmp_caClazzUid = _resultSet.getLong("caClazzUid")
            val tmp_caLocalChangeSeqNum = _resultSet.getLong("caLocalChangeSeqNum")
            val tmp_caMasterChangeSeqNum = _resultSet.getLong("caMasterChangeSeqNum")
            val tmp_caLastChangedBy = _resultSet.getInt("caLastChangedBy")
            val tmp_caLct = _resultSet.getLong("caLct")
            val _entity = ClazzAssignment()
            _entity.caUid = tmp_caUid
            _entity.caTitle = tmp_caTitle
            _entity.caDescription = tmp_caDescription
            _entity.caGroupUid = tmp_caGroupUid
            _entity.caActive = tmp_caActive
            _entity.caClassCommentEnabled = tmp_caClassCommentEnabled
            _entity.caPrivateCommentsEnabled = tmp_caPrivateCommentsEnabled
            _entity.caCompletionCriteria = tmp_caCompletionCriteria
            _entity.caRequireFileSubmission = tmp_caRequireFileSubmission
            _entity.caFileType = tmp_caFileType
            _entity.caSizeLimit = tmp_caSizeLimit
            _entity.caNumberOfFiles = tmp_caNumberOfFiles
            _entity.caSubmissionPolicy = tmp_caSubmissionPolicy
            _entity.caMarkingType = tmp_caMarkingType
            _entity.caRequireTextSubmission = tmp_caRequireTextSubmission
            _entity.caTextLimitType = tmp_caTextLimitType
            _entity.caTextLimit = tmp_caTextLimit
            _entity.caXObjectUid = tmp_caXObjectUid
            _entity.caClazzUid = tmp_caClazzUid
            _entity.caLocalChangeSeqNum = tmp_caLocalChangeSeqNum
            _entity.caMasterChangeSeqNum = tmp_caMasterChangeSeqNum
            _entity.caLastChangedBy = tmp_caLastChangedBy
            _entity.caLct = tmp_caLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun insert(entity: ClazzAssignment): Long {
    val _retVal = _insertAdapterClazzAssignment_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzAssignment): Long {
    val _retVal = _insertAdapterClazzAssignment_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ClazzAssignment>): Unit {
    _insertAdapterClazzAssignment_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzAssignment>): Unit {
    val _sql =
        "UPDATE ClazzAssignment SET caTitle = ?, caDescription = ?, caGroupUid = ?, caActive = ?, caClassCommentEnabled = ?, caPrivateCommentsEnabled = ?, caCompletionCriteria = ?, caRequireFileSubmission = ?, caFileType = ?, caSizeLimit = ?, caNumberOfFiles = ?, caSubmissionPolicy = ?, caMarkingType = ?, caRequireTextSubmission = ?, caTextLimitType = ?, caTextLimit = ?, caXObjectUid = ?, caClazzUid = ?, caLocalChangeSeqNum = ?, caMasterChangeSeqNum = ?, caLastChangedBy = ?, caLct = ? WHERE caUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.caTitle)
        _stmt.setString(2, _entity.caDescription)
        _stmt.setLong(3, _entity.caGroupUid)
        _stmt.setBoolean(4, _entity.caActive)
        _stmt.setBoolean(5, _entity.caClassCommentEnabled)
        _stmt.setBoolean(6, _entity.caPrivateCommentsEnabled)
        _stmt.setInt(7, _entity.caCompletionCriteria)
        _stmt.setBoolean(8, _entity.caRequireFileSubmission)
        _stmt.setInt(9, _entity.caFileType)
        _stmt.setInt(10, _entity.caSizeLimit)
        _stmt.setInt(11, _entity.caNumberOfFiles)
        _stmt.setInt(12, _entity.caSubmissionPolicy)
        _stmt.setInt(13, _entity.caMarkingType)
        _stmt.setBoolean(14, _entity.caRequireTextSubmission)
        _stmt.setInt(15, _entity.caTextLimitType)
        _stmt.setInt(16, _entity.caTextLimit)
        _stmt.setLong(17, _entity.caXObjectUid)
        _stmt.setLong(18, _entity.caClazzUid)
        _stmt.setLong(19, _entity.caLocalChangeSeqNum)
        _stmt.setLong(20, _entity.caMasterChangeSeqNum)
        _stmt.setInt(21, _entity.caLastChangedBy)
        _stmt.setLong(22, _entity.caLct)
        _stmt.setLong(23, _entity.caUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzAssignment): Unit {
    val _sql =
        "UPDATE ClazzAssignment SET caTitle = ?, caDescription = ?, caGroupUid = ?, caActive = ?, caClassCommentEnabled = ?, caPrivateCommentsEnabled = ?, caCompletionCriteria = ?, caRequireFileSubmission = ?, caFileType = ?, caSizeLimit = ?, caNumberOfFiles = ?, caSubmissionPolicy = ?, caMarkingType = ?, caRequireTextSubmission = ?, caTextLimitType = ?, caTextLimit = ?, caXObjectUid = ?, caClazzUid = ?, caLocalChangeSeqNum = ?, caMasterChangeSeqNum = ?, caLastChangedBy = ?, caLct = ? WHERE caUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.caTitle)
      _stmt.setString(2, entity.caDescription)
      _stmt.setLong(3, entity.caGroupUid)
      _stmt.setBoolean(4, entity.caActive)
      _stmt.setBoolean(5, entity.caClassCommentEnabled)
      _stmt.setBoolean(6, entity.caPrivateCommentsEnabled)
      _stmt.setInt(7, entity.caCompletionCriteria)
      _stmt.setBoolean(8, entity.caRequireFileSubmission)
      _stmt.setInt(9, entity.caFileType)
      _stmt.setInt(10, entity.caSizeLimit)
      _stmt.setInt(11, entity.caNumberOfFiles)
      _stmt.setInt(12, entity.caSubmissionPolicy)
      _stmt.setInt(13, entity.caMarkingType)
      _stmt.setBoolean(14, entity.caRequireTextSubmission)
      _stmt.setInt(15, entity.caTextLimitType)
      _stmt.setInt(16, entity.caTextLimit)
      _stmt.setLong(17, entity.caXObjectUid)
      _stmt.setLong(18, entity.caClazzUid)
      _stmt.setLong(19, entity.caLocalChangeSeqNum)
      _stmt.setLong(20, entity.caMasterChangeSeqNum)
      _stmt.setInt(21, entity.caLastChangedBy)
      _stmt.setLong(22, entity.caLct)
      _stmt.setLong(23, entity.caUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out ClazzAssignment>): Unit {
    _insertAdapterClazzAssignment_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out ClazzAssignment>): Unit {
    val _sql =
        "UPDATE ClazzAssignment SET caTitle = ?, caDescription = ?, caGroupUid = ?, caActive = ?, caClassCommentEnabled = ?, caPrivateCommentsEnabled = ?, caCompletionCriteria = ?, caRequireFileSubmission = ?, caFileType = ?, caSizeLimit = ?, caNumberOfFiles = ?, caSubmissionPolicy = ?, caMarkingType = ?, caRequireTextSubmission = ?, caTextLimitType = ?, caTextLimit = ?, caXObjectUid = ?, caClazzUid = ?, caLocalChangeSeqNum = ?, caMasterChangeSeqNum = ?, caLastChangedBy = ?, caLct = ? WHERE caUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.caTitle)
        _stmt.setString(2, _entity.caDescription)
        _stmt.setLong(3, _entity.caGroupUid)
        _stmt.setBoolean(4, _entity.caActive)
        _stmt.setBoolean(5, _entity.caClassCommentEnabled)
        _stmt.setBoolean(6, _entity.caPrivateCommentsEnabled)
        _stmt.setInt(7, _entity.caCompletionCriteria)
        _stmt.setBoolean(8, _entity.caRequireFileSubmission)
        _stmt.setInt(9, _entity.caFileType)
        _stmt.setInt(10, _entity.caSizeLimit)
        _stmt.setInt(11, _entity.caNumberOfFiles)
        _stmt.setInt(12, _entity.caSubmissionPolicy)
        _stmt.setInt(13, _entity.caMarkingType)
        _stmt.setBoolean(14, _entity.caRequireTextSubmission)
        _stmt.setInt(15, _entity.caTextLimitType)
        _stmt.setInt(16, _entity.caTextLimit)
        _stmt.setLong(17, _entity.caXObjectUid)
        _stmt.setLong(18, _entity.caClazzUid)
        _stmt.setLong(19, _entity.caLocalChangeSeqNum)
        _stmt.setLong(20, _entity.caMasterChangeSeqNum)
        _stmt.setInt(21, _entity.caLastChangedBy)
        _stmt.setLong(22, _entity.caLct)
        _stmt.setLong(23, _entity.caUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }
}
