package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.CourseBlockWithCompleteEntity
import com.ustadmobile.lib.db.entities.CourseBlockWithEntityDb
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseBlockDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseBlockDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : CourseBlockDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByUidAsync(uid: Long): CourseBlock? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override suspend fun updateAsync(entity: CourseBlock): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override suspend fun replaceListAsync(list: List<out CourseBlock>): Unit {
    _dao.replaceListAsync(list)
  }

  public override suspend fun findAllCourseBlockByClazzUidAsync(clazzUid: Long):
      List<CourseBlockWithEntityDb> {
    val _result = _dao.findAllCourseBlockByClazzUidAsync(clazzUid)
    return _result
  }

  public override fun findAllCourseBlockByClazzUidLive(
    clazzUid: Long,
    personUid: Long,
    collapseList: List<Long>,
    currentTime: Long
  ): DoorDataSourceFactory<Int, CourseBlockWithCompleteEntity> {
    val _result = _dao.findAllCourseBlockByClazzUidLive(clazzUid, personUid, collapseList,
        currentTime)
    return _result
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    _dao.updateActiveByUid(cbUid, active, changeTime)
  }

  public override fun insert(entity: CourseBlock): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: CourseBlock): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out CourseBlock>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseBlock>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: CourseBlock): Unit {
    _dao.update(entity)
  }

  public override suspend fun insertListAsync(entityList: List<out CourseBlock>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out CourseBlock>): Unit {
    _dao.updateListAsync(entityList)
  }
}
