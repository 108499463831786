package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryFileUidAndPath
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContainerEntryFileDao_JdbcKt(
  public val _db: DoorDatabase
) : ContainerEntryFileDao() {
  public val _insertAdapterContainerEntryFile_: EntityInsertionAdapter<ContainerEntryFile> = object
      : EntityInsertionAdapter<ContainerEntryFile>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContainerEntryFile (cefUid, cefMd5, cefPath, ceTotalSize, ceCompressedSize, compression, lastModified) VALUES(?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContainerEntryFile (cefUid, cefMd5, cefPath, ceTotalSize, ceCompressedSize, compression, lastModified) VALUES(COALESCE(?,nextval('ContainerEntryFile_cefUid_seq')), ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cefUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContainerEntryFile): Unit {
      if(entity.cefUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cefUid)
      }
      stmt.setString(2, entity.cefMd5)
      stmt.setString(3, entity.cefPath)
      stmt.setLong(4, entity.ceTotalSize)
      stmt.setLong(5, entity.ceCompressedSize)
      stmt.setInt(6, entity.compression)
      stmt.setLong(7, entity.lastModified)
    }
  }

  public override suspend fun insertListAsync(list: List<ContainerEntryFile>): Unit {
    _insertAdapterContainerEntryFile_.insertListAsync(list)
  }

  public override fun findEntriesByMd5Sums(md5Sums: List<String>): List<ContainerEntryFile> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryFile>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "TEXT", md5Sums.toTypedArray()))
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cefUid = _resultSet.getLong("cefUid")
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          val tmp_cefPath = _resultSet.getString("cefPath")
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          val tmp_compression = _resultSet.getInt("compression")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val _entity = ContainerEntryFile()
          _entity.cefUid = tmp_cefUid
          _entity.cefMd5 = tmp_cefMd5
          _entity.cefPath = tmp_cefPath
          _entity.ceTotalSize = tmp_ceTotalSize
          _entity.ceCompressedSize = tmp_ceCompressedSize
          _entity.compression = tmp_compression
          _entity.lastModified = tmp_lastModified
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findEntriesByMd5SumsAsync(md5Sums: List<String>):
      List<ContainerEntryFile> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryFile>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "TEXT", md5Sums.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cefUid = _resultSet.getLong("cefUid")
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          val tmp_cefPath = _resultSet.getString("cefPath")
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          val tmp_compression = _resultSet.getInt("compression")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val _entity = ContainerEntryFile()
          _entity.cefUid = tmp_cefUid
          _entity.cefMd5 = tmp_cefMd5
          _entity.cefPath = tmp_cefPath
          _entity.ceTotalSize = tmp_ceTotalSize
          _entity.ceCompressedSize = tmp_ceCompressedSize
          _entity.compression = tmp_compression
          _entity.lastModified = tmp_lastModified
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findExistingMd5SumsByMd5SumsAsync(md5Sums: List<String>):
      List<String?> {
    var _result = mutableListOf<kotlin.String?>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntryFile.cefMd5 FROM ContainerEntryFile WHERE cefMd5 IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT ContainerEntryFile.cefMd5 FROM ContainerEntryFile WHERE cefMd5 IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "TEXT", md5Sums.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getString(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findEntriesByUids(uidList: List<Long>): List<ContainerEntryFile> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryFile>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefUid IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", uidList.toTypedArray()))
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cefUid = _resultSet.getLong("cefUid")
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          val tmp_cefPath = _resultSet.getString("cefPath")
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          val tmp_compression = _resultSet.getInt("compression")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val _entity = ContainerEntryFile()
          _entity.cefUid = tmp_cefUid
          _entity.cefMd5 = tmp_cefMd5
          _entity.cefPath = tmp_cefPath
          _entity.ceTotalSize = tmp_ceTotalSize
          _entity.ceCompressedSize = tmp_ceCompressedSize
          _entity.compression = tmp_compression
          _entity.lastModified = tmp_lastModified
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun updateFilePath(cefUid: Long, path: String): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ContainerEntryFile SET cefPath = ? WHERE cefUid = ?" ,
        postgreSql = """
    |UPDATE ContainerEntryFile SET cefPath = ? WHERE cefUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, path)
      _stmt.setLong(2, cefUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun findByUid(uid: Long): ContainerEntryFile? {
    var _result = null as com.ustadmobile.lib.db.entities.ContainerEntryFile??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM ContainerEntryFile WHERE cefUid = ?" ,
        postgreSql = """
    |SELECT * FROM ContainerEntryFile WHERE cefUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cefUid = _resultSet.getLong("cefUid")
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          val tmp_cefPath = _resultSet.getString("cefPath")
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          val tmp_compression = _resultSet.getInt("compression")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val _entity = ContainerEntryFile()
          _entity.cefUid = tmp_cefUid
          _entity.cefMd5 = tmp_cefMd5
          _entity.cefPath = tmp_cefPath
          _entity.ceTotalSize = tmp_ceTotalSize
          _entity.ceCompressedSize = tmp_ceCompressedSize
          _entity.compression = tmp_compression
          _entity.lastModified = tmp_lastModified
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun sumContainerFileEntrySizes(containerUid: Long): Long {
    var _result = 0L
    val _stmtConfig =
        PreparedStatementConfig("SELECT SUM(ContainerEntryFile.ceCompressedSize) FROM ContainerEntry JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?"
        , postgreSql = """
    |SELECT SUM(ContainerEntryFile.ceCompressedSize) FROM ContainerEntry JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun getAllFilesForCompression(): List<ContainerEntryFile> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryFile>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContainerEntryFile WHERE compression = 0 AND NOT EXISTS(SELECT * FROM ContainerEntry WHERE ceCefUid = ContainerEntryFile.cefUid AND (ContainerEntry.cePath LIKE '%.webm' OR ContainerEntry.cePath LIKE '%.mp4')) LIMIT 100"
        , postgreSql = """
    |SELECT * FROM ContainerEntryFile WHERE compression = 0 AND NOT EXISTS(SELECT * FROM ContainerEntry WHERE ceCefUid = ContainerEntryFile.cefUid AND (ContainerEntry.cePath LIKE '%.webm' OR ContainerEntry.cePath LIKE '%.mp4')) LIMIT 100
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cefUid = _resultSet.getLong("cefUid")
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          val tmp_cefPath = _resultSet.getString("cefPath")
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          val tmp_compression = _resultSet.getInt("compression")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val _entity = ContainerEntryFile()
          _entity.cefUid = tmp_cefUid
          _entity.cefMd5 = tmp_cefMd5
          _entity.cefPath = tmp_cefPath
          _entity.ceTotalSize = tmp_ceTotalSize
          _entity.ceCompressedSize = tmp_ceCompressedSize
          _entity.compression = tmp_compression
          _entity.lastModified = tmp_lastModified
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun updateCompressedFile(
    compression: Int,
    ceCompressedSize: Long,
    cefUid: Long
  ): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ContainerEntryFile SET compression = ?, ceCompressedSize = ? WHERE cefUid = ?"
        , postgreSql = """
    |UPDATE ContainerEntryFile SET compression = ?, ceCompressedSize = ? WHERE cefUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, compression)
      _stmt.setLong(2, ceCompressedSize)
      _stmt.setLong(3, cefUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun findZombieEntries(): List<ContainerEntryFile> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryFile>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT ContainerEntryFile.* 
    |                      FROM ContainerEntryFile 
    |                     WHERE NOT EXISTS (SELECT ContainerEntry.ceCefUid 
    |                                     FROM ContainerEntry 
    |                                    WHERE ContainerEntryFile.cefUid = ContainerEntry.ceCefUid) 
    |                     LIMIT 100
    """.trimMargin() , postgreSql = """
    |SELECT ContainerEntryFile.* 
    |                      FROM ContainerEntryFile 
    |                     WHERE NOT EXISTS (SELECT ContainerEntry.ceCefUid 
    |                                     FROM ContainerEntry 
    |                                    WHERE ContainerEntryFile.cefUid = ContainerEntry.ceCefUid) 
    |                     LIMIT 100
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cefUid = _resultSet.getLong("cefUid")
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          val tmp_cefPath = _resultSet.getString("cefPath")
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          val tmp_compression = _resultSet.getInt("compression")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val _entity = ContainerEntryFile()
          _entity.cefUid = tmp_cefUid
          _entity.cefMd5 = tmp_cefMd5
          _entity.cefPath = tmp_cefPath
          _entity.ceTotalSize = tmp_ceTotalSize
          _entity.ceCompressedSize = tmp_ceCompressedSize
          _entity.compression = tmp_compression
          _entity.lastModified = tmp_lastModified
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findZombieUidsAndPath(limit: Int):
      List<ContainerEntryFileUidAndPath> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryFileUidAndPath>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT cefUid, cefPath
    |          FROM ContainerEntryFile
    |         WHERE NOT EXISTS 
    |               (SELECT ContainerEntry.ceCefUid 
    |                  FROM ContainerEntry 
    |                 WHERE ContainerEntry.ceCefUid = ContainerEntryFile.cefUid 
    |                 LIMIT 1)
    |         LIMIT ?     
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT cefUid, cefPath
    |          FROM ContainerEntryFile
    |         WHERE NOT EXISTS 
    |               (SELECT ContainerEntry.ceCefUid 
    |                  FROM ContainerEntry 
    |                 WHERE ContainerEntry.ceCefUid = ContainerEntryFile.cefUid 
    |                 LIMIT 1)
    |         LIMIT ?     
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, limit)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cefUid = _resultSet.getLong("cefUid")
          val tmp_cefPath = _resultSet.getString("cefPath")
          val _entity = ContainerEntryFileUidAndPath()
          _entity.cefUid = tmp_cefUid
          _entity.cefPath = tmp_cefPath
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun deleteByUidList(uidList: List<Long>): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        DELETE FROM ContainerEntryFile
    |              WHERE cefUid IN (?) 
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        DELETE FROM ContainerEntryFile
    |              WHERE cefUid IN (?) 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", uidList.toTypedArray()))
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun deleteListOfEntryFiles(entriesToDelete: List<ContainerEntryFile>): Unit {
    var _numChanges = 0
    _db.prepareAndUseStatement("DELETE FROM ContainerEntryFile WHERE cefUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entriesToDelete) {
        _stmt.setLong(1, _entity.cefUid)
        _numChanges += _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun findEntryByMd5Sum(md5Sum: String): ContainerEntryFile? {
    var _result = null as com.ustadmobile.lib.db.entities.ContainerEntryFile??
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 = ?"
        , postgreSql = """
    |SELECT ContainerEntryFile.* FROM ContainerEntryFile WHERE cefMd5 = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, md5Sum)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cefUid = _resultSet.getLong("cefUid")
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          val tmp_cefPath = _resultSet.getString("cefPath")
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          val tmp_compression = _resultSet.getInt("compression")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val _entity = ContainerEntryFile()
          _entity.cefUid = tmp_cefUid
          _entity.cefMd5 = tmp_cefMd5
          _entity.cefPath = tmp_cefPath
          _entity.ceTotalSize = tmp_ceTotalSize
          _entity.ceCompressedSize = tmp_ceCompressedSize
          _entity.compression = tmp_compression
          _entity.lastModified = tmp_lastModified
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ContainerEntryFile): Long {
    val _retVal = _insertAdapterContainerEntryFile_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerEntryFile): Long {
    val _retVal = _insertAdapterContainerEntryFile_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContainerEntryFile>): Unit {
    _insertAdapterContainerEntryFile_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContainerEntryFile>): Unit {
    val _sql =
        "UPDATE ContainerEntryFile SET cefMd5 = ?, cefPath = ?, ceTotalSize = ?, ceCompressedSize = ?, compression = ?, lastModified = ? WHERE cefUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.cefMd5)
        _stmt.setString(2, _entity.cefPath)
        _stmt.setLong(3, _entity.ceTotalSize)
        _stmt.setLong(4, _entity.ceCompressedSize)
        _stmt.setInt(5, _entity.compression)
        _stmt.setLong(6, _entity.lastModified)
        _stmt.setLong(7, _entity.cefUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContainerEntryFile): Unit {
    val _sql =
        "UPDATE ContainerEntryFile SET cefMd5 = ?, cefPath = ?, ceTotalSize = ?, ceCompressedSize = ?, compression = ?, lastModified = ? WHERE cefUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.cefMd5)
      _stmt.setString(2, entity.cefPath)
      _stmt.setLong(3, entity.ceTotalSize)
      _stmt.setLong(4, entity.ceCompressedSize)
      _stmt.setInt(5, entity.compression)
      _stmt.setLong(6, entity.lastModified)
      _stmt.setLong(7, entity.cefUid)
      _stmt.executeUpdate()
    }
  }
}
