package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import com.ustadmobile.lib.db.entities.SchoolWithMemberCountAndLocation
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SchoolDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: SchoolDao
) : SchoolDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByUidAsync(schoolUid: Long): School? =
      _dao.findByUidAsync(schoolUid)

  public override suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      SchoolWithHolidayCalendar? = _dao.findByUidWithHolidayCalendarAsync(uid)

  public override suspend fun findBySchoolCode(code: String): School? = _dao.findBySchoolCode(code)

  public override suspend fun findBySchoolCodeFromWeb(code: String): School? =
      _dao.findBySchoolCodeFromWeb(code)

  public override suspend fun personHasPermissionWithSchool(
    accountPersonUid: Long,
    schoolUid: Long,
    permission: Long
  ): Boolean = _dao.personHasPermissionWithSchool(accountPersonUid, schoolUid, permission)

  public override fun findAllActiveSchoolWithMemberCountAndLocationName(
    searchBit: String,
    personUid: Long,
    permission: Long,
    sortOrder: Int
  ): DoorDataSourceFactory<Int, SchoolWithMemberCountAndLocation> =
      _dao.findAllActiveSchoolWithMemberCountAndLocationName(searchBit, personUid, permission,
      sortOrder)

  public override suspend fun updateAsync(entity: School): Int {
    entity.schoolLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override fun insert(entity: School): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: School): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.schoolUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(164)
      entity.schoolUid = _newPk
    }
    entity.schoolLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.schoolUid
  }

  public override fun insertList(entityList: List<out School>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out School>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: School): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
