package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.XObjectEntity
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class XObjectDao_JdbcKt(
  public val _db: DoorDatabase
) : XObjectDao() {
  public val _insertAdapterXObjectEntity_: EntityInsertionAdapter<XObjectEntity> = object :
      EntityInsertionAdapter<XObjectEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO XObjectEntity (xObjectUid, objectType, objectId, definitionType, interactionType, correctResponsePattern, objectContentEntryUid, objectStatementRefUid, xObjectMasterChangeSeqNum, xObjectocalChangeSeqNum, xObjectLastChangedBy, xObjectLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO XObjectEntity (xObjectUid, objectType, objectId, definitionType, interactionType, correctResponsePattern, objectContentEntryUid, objectStatementRefUid, xObjectMasterChangeSeqNum, xObjectocalChangeSeqNum, xObjectLastChangedBy, xObjectLct) VALUES(COALESCE(?,nextval('XObjectEntity_xObjectUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING xObjectUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: XObjectEntity):
        Unit {
      if(entity.xObjectUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.xObjectUid)
      }
      stmt.setString(2, entity.objectType)
      stmt.setString(3, entity.objectId)
      stmt.setString(4, entity.definitionType)
      stmt.setString(5, entity.interactionType)
      stmt.setString(6, entity.correctResponsePattern)
      stmt.setLong(7, entity.objectContentEntryUid)
      stmt.setLong(8, entity.objectStatementRefUid)
      stmt.setLong(9, entity.xObjectMasterChangeSeqNum)
      stmt.setLong(10, entity.xObjectocalChangeSeqNum)
      stmt.setInt(11, entity.xObjectLastChangedBy)
      stmt.setLong(12, entity.xObjectLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO XObjectEntityReplicate(xoePk, xoeDestination)
    |      SELECT DISTINCT XObjectEntity.xObjectUid AS xoePk,
    |             ? AS xoeDestination
    |        FROM XObjectEntity
    |       WHERE XObjectEntity.xObjectLct != COALESCE(
    |             (SELECT xoeVersionId
    |                FROM XObjectEntityReplicate
    |               WHERE xoePk = XObjectEntity.xObjectUid
    |                 AND xoeDestination = ?), 0) 
    |      /*psql ON CONFLICT(xoePk, xoeDestination) DO UPDATE
    |             SET xoePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO XObjectEntityReplicate(xoePk, xoeDestination)
    |      SELECT DISTINCT XObjectEntity.xObjectUid AS xoePk,
    |             ? AS xoeDestination
    |        FROM XObjectEntity
    |       WHERE XObjectEntity.xObjectLct != COALESCE(
    |             (SELECT xoeVersionId
    |                FROM XObjectEntityReplicate
    |               WHERE xoePk = XObjectEntity.xObjectUid
    |                 AND xoeDestination = ?), 0) 
    |       ON CONFLICT(xoePk, xoeDestination) DO UPDATE
    |             SET xoePending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO XObjectEntityReplicate(xoePk, xoeDestination)
    |  SELECT DISTINCT XObjectEntity.xObjectUid AS xoeUid,
    |         UserSession.usClientNodeId AS xoeDestination
    |    FROM ChangeLog
    |         JOIN XObjectEntity
    |             ON ChangeLog.chTableId = 64
    |                AND ChangeLog.chEntityPk = XObjectEntity.xObjectUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND XObjectEntity.xObjectLct != COALESCE(
    |         (SELECT xoeVersionId
    |            FROM XObjectEntityReplicate
    |           WHERE xoePk = XObjectEntity.xObjectUid
    |             AND xoeDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(xoePk, xoeDestination) DO UPDATE
    |     SET xoePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO XObjectEntityReplicate(xoePk, xoeDestination)
    |  SELECT DISTINCT XObjectEntity.xObjectUid AS xoeUid,
    |         UserSession.usClientNodeId AS xoeDestination
    |    FROM ChangeLog
    |         JOIN XObjectEntity
    |             ON ChangeLog.chTableId = 64
    |                AND ChangeLog.chEntityPk = XObjectEntity.xObjectUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND XObjectEntity.xObjectLct != COALESCE(
    |         (SELECT xoeVersionId
    |            FROM XObjectEntityReplicate
    |           WHERE xoePk = XObjectEntity.xObjectUid
    |             AND xoeDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(xoePk, xoeDestination) DO UPDATE
    |     SET xoePending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByObjectId(id: String?): XObjectEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.XObjectEntity??
    val _stmtConfig = PreparedStatementConfig("SELECT * from XObjectEntity WHERE objectId = ?" ,
        postgreSql = """
    |SELECT * from XObjectEntity WHERE objectId = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, id)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_xObjectUid = _resultSet.getLong("xObjectUid")
          val tmp_objectType = _resultSet.getString("objectType")
          val tmp_objectId = _resultSet.getString("objectId")
          val tmp_definitionType = _resultSet.getString("definitionType")
          val tmp_interactionType = _resultSet.getString("interactionType")
          val tmp_correctResponsePattern = _resultSet.getString("correctResponsePattern")
          val tmp_objectContentEntryUid = _resultSet.getLong("objectContentEntryUid")
          val tmp_objectStatementRefUid = _resultSet.getLong("objectStatementRefUid")
          val tmp_xObjectMasterChangeSeqNum = _resultSet.getLong("xObjectMasterChangeSeqNum")
          val tmp_xObjectocalChangeSeqNum = _resultSet.getLong("xObjectocalChangeSeqNum")
          val tmp_xObjectLastChangedBy = _resultSet.getInt("xObjectLastChangedBy")
          val tmp_xObjectLct = _resultSet.getLong("xObjectLct")
          val _entity = XObjectEntity()
          _entity.xObjectUid = tmp_xObjectUid
          _entity.objectType = tmp_objectType
          _entity.objectId = tmp_objectId
          _entity.definitionType = tmp_definitionType
          _entity.interactionType = tmp_interactionType
          _entity.correctResponsePattern = tmp_correctResponsePattern
          _entity.objectContentEntryUid = tmp_objectContentEntryUid
          _entity.objectStatementRefUid = tmp_objectStatementRefUid
          _entity.xObjectMasterChangeSeqNum = tmp_xObjectMasterChangeSeqNum
          _entity.xObjectocalChangeSeqNum = tmp_xObjectocalChangeSeqNum
          _entity.xObjectLastChangedBy = tmp_xObjectLastChangedBy
          _entity.xObjectLct = tmp_xObjectLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByXobjectUid(xObjectUid: Long): XObjectEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.XObjectEntity??
    val _stmtConfig = PreparedStatementConfig("SELECT * from XObjectEntity WHERE xObjectUid = ?" ,
        postgreSql = """
    |SELECT * from XObjectEntity WHERE xObjectUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, xObjectUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_xObjectUid = _resultSet.getLong("xObjectUid")
          val tmp_objectType = _resultSet.getString("objectType")
          val tmp_objectId = _resultSet.getString("objectId")
          val tmp_definitionType = _resultSet.getString("definitionType")
          val tmp_interactionType = _resultSet.getString("interactionType")
          val tmp_correctResponsePattern = _resultSet.getString("correctResponsePattern")
          val tmp_objectContentEntryUid = _resultSet.getLong("objectContentEntryUid")
          val tmp_objectStatementRefUid = _resultSet.getLong("objectStatementRefUid")
          val tmp_xObjectMasterChangeSeqNum = _resultSet.getLong("xObjectMasterChangeSeqNum")
          val tmp_xObjectocalChangeSeqNum = _resultSet.getLong("xObjectocalChangeSeqNum")
          val tmp_xObjectLastChangedBy = _resultSet.getInt("xObjectLastChangedBy")
          val tmp_xObjectLct = _resultSet.getLong("xObjectLct")
          val _entity = XObjectEntity()
          _entity.xObjectUid = tmp_xObjectUid
          _entity.objectType = tmp_objectType
          _entity.objectId = tmp_objectId
          _entity.definitionType = tmp_definitionType
          _entity.interactionType = tmp_interactionType
          _entity.correctResponsePattern = tmp_correctResponsePattern
          _entity.objectContentEntryUid = tmp_objectContentEntryUid
          _entity.objectStatementRefUid = tmp_objectStatementRefUid
          _entity.xObjectMasterChangeSeqNum = tmp_xObjectMasterChangeSeqNum
          _entity.xObjectocalChangeSeqNum = tmp_xObjectocalChangeSeqNum
          _entity.xObjectLastChangedBy = tmp_xObjectLastChangedBy
          _entity.xObjectLct = tmp_xObjectLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: XObjectEntity): Long {
    val _retVal = _insertAdapterXObjectEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: XObjectEntity): Long {
    val _retVal = _insertAdapterXObjectEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out XObjectEntity>): Unit {
    _insertAdapterXObjectEntity_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out XObjectEntity>): Unit {
    val _sql =
        "UPDATE XObjectEntity SET objectType = ?, objectId = ?, definitionType = ?, interactionType = ?, correctResponsePattern = ?, objectContentEntryUid = ?, objectStatementRefUid = ?, xObjectMasterChangeSeqNum = ?, xObjectocalChangeSeqNum = ?, xObjectLastChangedBy = ?, xObjectLct = ? WHERE xObjectUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.objectType)
        _stmt.setString(2, _entity.objectId)
        _stmt.setString(3, _entity.definitionType)
        _stmt.setString(4, _entity.interactionType)
        _stmt.setString(5, _entity.correctResponsePattern)
        _stmt.setLong(6, _entity.objectContentEntryUid)
        _stmt.setLong(7, _entity.objectStatementRefUid)
        _stmt.setLong(8, _entity.xObjectMasterChangeSeqNum)
        _stmt.setLong(9, _entity.xObjectocalChangeSeqNum)
        _stmt.setInt(10, _entity.xObjectLastChangedBy)
        _stmt.setLong(11, _entity.xObjectLct)
        _stmt.setLong(12, _entity.xObjectUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: XObjectEntity): Unit {
    val _sql =
        "UPDATE XObjectEntity SET objectType = ?, objectId = ?, definitionType = ?, interactionType = ?, correctResponsePattern = ?, objectContentEntryUid = ?, objectStatementRefUid = ?, xObjectMasterChangeSeqNum = ?, xObjectocalChangeSeqNum = ?, xObjectLastChangedBy = ?, xObjectLct = ? WHERE xObjectUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.objectType)
      _stmt.setString(2, entity.objectId)
      _stmt.setString(3, entity.definitionType)
      _stmt.setString(4, entity.interactionType)
      _stmt.setString(5, entity.correctResponsePattern)
      _stmt.setLong(6, entity.objectContentEntryUid)
      _stmt.setLong(7, entity.objectStatementRefUid)
      _stmt.setLong(8, entity.xObjectMasterChangeSeqNum)
      _stmt.setLong(9, entity.xObjectocalChangeSeqNum)
      _stmt.setInt(10, entity.xObjectLastChangedBy)
      _stmt.setLong(11, entity.xObjectLct)
      _stmt.setLong(12, entity.xObjectUid)
      _stmt.executeUpdate()
    }
  }
}
