package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CourseDiscussion
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseDiscussionDao_JdbcKt(
  public val _db: DoorDatabase
) : CourseDiscussionDao() {
  public val _insertAdapterCourseDiscussion_upsert: EntityInsertionAdapter<CourseDiscussion> =
      object : EntityInsertionAdapter<CourseDiscussion>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO CourseDiscussion (courseDiscussionUid, courseDiscussionTitle, courseDiscussionDesc, courseDiscussionClazzUid, courseDiscussionActive, courseDiscussionLct) VALUES(?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseDiscussion (courseDiscussionUid, courseDiscussionTitle, courseDiscussionDesc, courseDiscussionClazzUid, courseDiscussionActive, courseDiscussionLct) VALUES(COALESCE(?,nextval('CourseDiscussion_courseDiscussionUid_seq')), ?, ?, ?, ?, ?) ON CONFLICT (courseDiscussionUid) DO UPDATE SET courseDiscussionTitle = excluded.courseDiscussionTitle,courseDiscussionDesc = excluded.courseDiscussionDesc,courseDiscussionClazzUid = excluded.courseDiscussionClazzUid,courseDiscussionActive = excluded.courseDiscussionActive,courseDiscussionLct = excluded.courseDiscussionLct" + if(returnsId) { " RETURNING courseDiscussionUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseDiscussion):
        Unit {
      if(entity.courseDiscussionUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.courseDiscussionUid)
      }
      stmt.setString(2, entity.courseDiscussionTitle)
      stmt.setString(3, entity.courseDiscussionDesc)
      stmt.setLong(4, entity.courseDiscussionClazzUid)
      stmt.setBoolean(5, entity.courseDiscussionActive)
      stmt.setLong(6, entity.courseDiscussionLct)
    }
  }

  public val _insertAdapterCourseDiscussion_: EntityInsertionAdapter<CourseDiscussion> = object :
      EntityInsertionAdapter<CourseDiscussion>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CourseDiscussion (courseDiscussionUid, courseDiscussionTitle, courseDiscussionDesc, courseDiscussionClazzUid, courseDiscussionActive, courseDiscussionLct) VALUES(?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseDiscussion (courseDiscussionUid, courseDiscussionTitle, courseDiscussionDesc, courseDiscussionClazzUid, courseDiscussionActive, courseDiscussionLct) VALUES(COALESCE(?,nextval('CourseDiscussion_courseDiscussionUid_seq')), ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING courseDiscussionUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseDiscussion):
        Unit {
      if(entity.courseDiscussionUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.courseDiscussionUid)
      }
      stmt.setString(2, entity.courseDiscussionTitle)
      stmt.setString(3, entity.courseDiscussionDesc)
      stmt.setLong(4, entity.courseDiscussionClazzUid)
      stmt.setBoolean(5, entity.courseDiscussionActive)
      stmt.setLong(6, entity.courseDiscussionLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseDiscussionReplicate(courseDiscussionPk, courseDiscussionDestination)
    |      SELECT DISTINCT CourseDiscussion.courseDiscussionUid AS courseDiscussionPk,
    |             ? AS courseDiscussionDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |             JOIN CourseDiscussion 
    |                  ON CourseDiscussion.courseDiscussionClazzUid = Clazz.clazzUid
    |                  
    |       WHERE CourseDiscussion.courseDiscussionLct != COALESCE(
    |             (SELECT courseDiscussionVersionId
    |                FROM courseDiscussionReplicate
    |               WHERE courseDiscussionPk = CourseDiscussion.courseDiscussionUid
    |                 AND courseDiscussionDestination = ?), 0) 
    |      /*psql ON CONFLICT(courseDiscussionPk, courseDiscussionDestination) DO UPDATE
    |             SET courseDiscussionPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseDiscussionReplicate(courseDiscussionPk, courseDiscussionDestination)
    |      SELECT DISTINCT CourseDiscussion.courseDiscussionUid AS courseDiscussionPk,
    |             ? AS courseDiscussionDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |             JOIN CourseDiscussion 
    |                  ON CourseDiscussion.courseDiscussionClazzUid = Clazz.clazzUid
    |                  
    |       WHERE CourseDiscussion.courseDiscussionLct != COALESCE(
    |             (SELECT courseDiscussionVersionId
    |                FROM courseDiscussionReplicate
    |               WHERE courseDiscussionPk = CourseDiscussion.courseDiscussionUid
    |                 AND courseDiscussionDestination = ?), 0) 
    |       ON CONFLICT(courseDiscussionPk, courseDiscussionDestination) DO UPDATE
    |             SET courseDiscussionPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO CourseDiscussionReplicate(courseDiscussionPk, courseDiscussionDestination)
    |          SELECT DISTINCT CourseDiscussion.courseDiscussionUid AS courseDiscussionUid,
    |                 UserSession.usClientNodeId AS courseDiscussionDestination
    |            FROM ChangeLog
    |                 JOIN CourseDiscussion
    |                     ON ChangeLog.chTableId = 130
    |                        AND ChangeLog.chEntityPk = CourseDiscussion.courseDiscussionUid
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = CourseDiscussion.courseDiscussionClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND CourseDiscussion.courseDiscussionLct != COALESCE(
    |                 (SELECT courseDiscussionVersionId
    |                    FROM courseDiscussionReplicate
    |                   WHERE courseDiscussionPk = CourseDiscussion.courseDiscussionUid
    |                     AND courseDiscussionDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(courseDiscussionPk, courseDiscussionDestination) DO UPDATE
    |             SET courseDiscussionPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseDiscussionReplicate(courseDiscussionPk, courseDiscussionDestination)
    |          SELECT DISTINCT CourseDiscussion.courseDiscussionUid AS courseDiscussionUid,
    |                 UserSession.usClientNodeId AS courseDiscussionDestination
    |            FROM ChangeLog
    |                 JOIN CourseDiscussion
    |                     ON ChangeLog.chTableId = 130
    |                        AND ChangeLog.chEntityPk = CourseDiscussion.courseDiscussionUid
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = CourseDiscussion.courseDiscussionClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND CourseDiscussion.courseDiscussionLct != COALESCE(
    |                 (SELECT courseDiscussionVersionId
    |                    FROM courseDiscussionReplicate
    |                   WHERE courseDiscussionPk = CourseDiscussion.courseDiscussionUid
    |                     AND courseDiscussionDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(courseDiscussionPk, courseDiscussionDestination) DO UPDATE
    |             SET courseDiscussionPending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE CourseDiscussion 
    |           SET courseDiscussionActive = ?, 
    |               courseDiscussionLct = ?
    |         WHERE courseDiscussionUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE CourseDiscussion 
    |           SET courseDiscussionActive = ?, 
    |               courseDiscussionLct = ?
    |         WHERE courseDiscussionUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, active)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, cbUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getCourseDiscussionByUid(courseDiscussionUid: Long):
      DoorLiveData<CourseDiscussion?> {
    val _result = DoorLiveDataImpl<CourseDiscussion?>(_db, listOf("CourseDiscussion"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.CourseDiscussion??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT CourseDiscussion.* 
      |          FROM CourseDiscussion
      |         WHERE CourseDiscussion.courseDiscussionUid = ? 
      |           AND CAST(CourseDiscussion.courseDiscussionActive AS INTEGER) = 1 
      |         
      |         
      """.trimMargin() , postgreSql = """
      |
      |        SELECT CourseDiscussion.* 
      |          FROM CourseDiscussion
      |         WHERE CourseDiscussion.courseDiscussionUid = ? 
      |           AND CAST(CourseDiscussion.courseDiscussionActive AS INTEGER) = 1 
      |         
      |         
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, courseDiscussionUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_courseDiscussionUid = _resultSet.getLong("courseDiscussionUid")
            val tmp_courseDiscussionTitle = _resultSet.getString("courseDiscussionTitle")
            val tmp_courseDiscussionDesc = _resultSet.getString("courseDiscussionDesc")
            val tmp_courseDiscussionClazzUid = _resultSet.getLong("courseDiscussionClazzUid")
            val tmp_courseDiscussionActive = _resultSet.getBoolean("courseDiscussionActive")
            val tmp_courseDiscussionLct = _resultSet.getLong("courseDiscussionLct")
            val _entity = CourseDiscussion()
            _entity.courseDiscussionUid = tmp_courseDiscussionUid
            _entity.courseDiscussionTitle = tmp_courseDiscussionTitle
            _entity.courseDiscussionDesc = tmp_courseDiscussionDesc
            _entity.courseDiscussionClazzUid = tmp_courseDiscussionClazzUid
            _entity.courseDiscussionActive = tmp_courseDiscussionActive
            _entity.courseDiscussionLct = tmp_courseDiscussionLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun replaceListAsync(list: List<out CourseDiscussion>): Unit {
    _insertAdapterCourseDiscussion_upsert.insertListAsync(list)
  }

  public override fun insert(entity: CourseDiscussion): Long {
    val _retVal = _insertAdapterCourseDiscussion_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseDiscussion): Long {
    val _retVal = _insertAdapterCourseDiscussion_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CourseDiscussion>): Unit {
    _insertAdapterCourseDiscussion_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseDiscussion>): Unit {
    val _sql =
        "UPDATE CourseDiscussion SET courseDiscussionTitle = ?, courseDiscussionDesc = ?, courseDiscussionClazzUid = ?, courseDiscussionActive = ?, courseDiscussionLct = ? WHERE courseDiscussionUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.courseDiscussionTitle)
        _stmt.setString(2, _entity.courseDiscussionDesc)
        _stmt.setLong(3, _entity.courseDiscussionClazzUid)
        _stmt.setBoolean(4, _entity.courseDiscussionActive)
        _stmt.setLong(5, _entity.courseDiscussionLct)
        _stmt.setLong(6, _entity.courseDiscussionUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseDiscussion): Unit {
    val _sql =
        "UPDATE CourseDiscussion SET courseDiscussionTitle = ?, courseDiscussionDesc = ?, courseDiscussionClazzUid = ?, courseDiscussionActive = ?, courseDiscussionLct = ? WHERE courseDiscussionUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.courseDiscussionTitle)
      _stmt.setString(2, entity.courseDiscussionDesc)
      _stmt.setLong(3, entity.courseDiscussionClazzUid)
      _stmt.setBoolean(4, entity.courseDiscussionActive)
      _stmt.setLong(5, entity.courseDiscussionLct)
      _stmt.setLong(6, entity.courseDiscussionUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out CourseDiscussion>): Unit {
    _insertAdapterCourseDiscussion_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out CourseDiscussion>): Unit {
    val _sql =
        "UPDATE CourseDiscussion SET courseDiscussionTitle = ?, courseDiscussionDesc = ?, courseDiscussionClazzUid = ?, courseDiscussionActive = ?, courseDiscussionLct = ? WHERE courseDiscussionUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.courseDiscussionTitle)
        _stmt.setString(2, _entity.courseDiscussionDesc)
        _stmt.setLong(3, _entity.courseDiscussionClazzUid)
        _stmt.setBoolean(4, _entity.courseDiscussionActive)
        _stmt.setLong(5, _entity.courseDiscussionLct)
        _stmt.setLong(6, _entity.courseDiscussionUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }
}
