package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Holiday
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class HolidayDao_JdbcKt(
  public val _db: DoorDatabase
) : HolidayDao() {
  public val _insertAdapterHoliday_: EntityInsertionAdapter<Holiday> = object :
      EntityInsertionAdapter<Holiday>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Holiday (holUid, holMasterCsn, holLocalCsn, holLastModBy, holLct, holActive, holHolidayCalendarUid, holStartTime, holEndTime, holName) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Holiday (holUid, holMasterCsn, holLocalCsn, holLastModBy, holLct, holActive, holHolidayCalendarUid, holStartTime, holEndTime, holName) VALUES(COALESCE(?,nextval('Holiday_holUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING holUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Holiday): Unit {
      if(entity.holUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.holUid)
      }
      stmt.setLong(2, entity.holMasterCsn)
      stmt.setLong(3, entity.holLocalCsn)
      stmt.setInt(4, entity.holLastModBy)
      stmt.setLong(5, entity.holLct)
      stmt.setBoolean(6, entity.holActive)
      stmt.setLong(7, entity.holHolidayCalendarUid)
      stmt.setLong(8, entity.holStartTime)
      stmt.setLong(9, entity.holEndTime)
      stmt.setString(10, entity.holName)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO HolidayReplicate(holidayPk, holidayDestination)
    |      SELECT DISTINCT Holiday.holUid AS holidayPk,
    |             ? AS holidayDestination
    |        FROM Holiday
    |       WHERE Holiday.holLct != COALESCE(
    |             (SELECT holidayVersionId
    |                FROM HolidayReplicate
    |               WHERE holidayPk = Holiday.holUid
    |                 AND holidayDestination = ?), 0) 
    |      /*psql ON CONFLICT(holidayPk, holidayDestination) DO UPDATE
    |             SET holidayPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO HolidayReplicate(holidayPk, holidayDestination)
    |      SELECT DISTINCT Holiday.holUid AS holidayPk,
    |             ? AS holidayDestination
    |        FROM Holiday
    |       WHERE Holiday.holLct != COALESCE(
    |             (SELECT holidayVersionId
    |                FROM HolidayReplicate
    |               WHERE holidayPk = Holiday.holUid
    |                 AND holidayDestination = ?), 0) 
    |       ON CONFLICT(holidayPk, holidayDestination) DO UPDATE
    |             SET holidayPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO HolidayReplicate(holidayPk, holidayDestination)
    |  SELECT DISTINCT Holiday.holUid AS holidayUid,
    |         UserSession.usClientNodeId AS holidayDestination
    |    FROM ChangeLog
    |         JOIN Holiday
    |             ON ChangeLog.chTableId = 99
    |                AND ChangeLog.chEntityPk = Holiday.holUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Holiday.holLct != COALESCE(
    |         (SELECT holidayVersionId
    |            FROM HolidayReplicate
    |           WHERE holidayPk = Holiday.holUid
    |             AND holidayDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(holidayPk, holidayDestination) DO UPDATE
    |     SET holidayPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO HolidayReplicate(holidayPk, holidayDestination)
    |  SELECT DISTINCT Holiday.holUid AS holidayUid,
    |         UserSession.usClientNodeId AS holidayDestination
    |    FROM ChangeLog
    |         JOIN Holiday
    |             ON ChangeLog.chTableId = 99
    |                AND ChangeLog.chEntityPk = Holiday.holUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Holiday.holLct != COALESCE(
    |         (SELECT holidayVersionId
    |            FROM HolidayReplicate
    |           WHERE holidayPk = Holiday.holUid
    |             AND holidayDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(holidayPk, holidayDestination) DO UPDATE
    |     SET holidayPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByHolidayCalendaUid(holidayCalendarUid: Long): List<Holiday> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Holiday>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Holiday WHERE holHolidayCalendarUid = ?" , postgreSql
        = """
    |SELECT * FROM Holiday WHERE holHolidayCalendarUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, holidayCalendarUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_holUid = _resultSet.getLong("holUid")
          val tmp_holMasterCsn = _resultSet.getLong("holMasterCsn")
          val tmp_holLocalCsn = _resultSet.getLong("holLocalCsn")
          val tmp_holLastModBy = _resultSet.getInt("holLastModBy")
          val tmp_holLct = _resultSet.getLong("holLct")
          val tmp_holActive = _resultSet.getBoolean("holActive")
          val tmp_holHolidayCalendarUid = _resultSet.getLong("holHolidayCalendarUid")
          val tmp_holStartTime = _resultSet.getLong("holStartTime")
          val tmp_holEndTime = _resultSet.getLong("holEndTime")
          val tmp_holName = _resultSet.getString("holName")
          val _entity = Holiday()
          _entity.holUid = tmp_holUid
          _entity.holMasterCsn = tmp_holMasterCsn
          _entity.holLocalCsn = tmp_holLocalCsn
          _entity.holLastModBy = tmp_holLastModBy
          _entity.holLct = tmp_holLct
          _entity.holActive = tmp_holActive
          _entity.holHolidayCalendarUid = tmp_holHolidayCalendarUid
          _entity.holStartTime = tmp_holStartTime
          _entity.holEndTime = tmp_holEndTime
          _entity.holName = tmp_holName
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByHolidayCalendaUidAsync(holidayCalendarUid: Long):
      List<Holiday> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Holiday>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Holiday WHERE holHolidayCalendarUid = ?" , postgreSql
        = """
    |SELECT * FROM Holiday WHERE holHolidayCalendarUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, holidayCalendarUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_holUid = _resultSet.getLong("holUid")
          val tmp_holMasterCsn = _resultSet.getLong("holMasterCsn")
          val tmp_holLocalCsn = _resultSet.getLong("holLocalCsn")
          val tmp_holLastModBy = _resultSet.getInt("holLastModBy")
          val tmp_holLct = _resultSet.getLong("holLct")
          val tmp_holActive = _resultSet.getBoolean("holActive")
          val tmp_holHolidayCalendarUid = _resultSet.getLong("holHolidayCalendarUid")
          val tmp_holStartTime = _resultSet.getLong("holStartTime")
          val tmp_holEndTime = _resultSet.getLong("holEndTime")
          val tmp_holName = _resultSet.getString("holName")
          val _entity = Holiday()
          _entity.holUid = tmp_holUid
          _entity.holMasterCsn = tmp_holMasterCsn
          _entity.holLocalCsn = tmp_holLocalCsn
          _entity.holLastModBy = tmp_holLastModBy
          _entity.holLct = tmp_holLct
          _entity.holActive = tmp_holActive
          _entity.holHolidayCalendarUid = tmp_holHolidayCalendarUid
          _entity.holStartTime = tmp_holStartTime
          _entity.holEndTime = tmp_holEndTime
          _entity.holName = tmp_holName
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun updateActiveByUid(
    holidayUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE Holiday 
    |           SET holActive = ?, 
    |               holLct = ?
    |         WHERE holUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE Holiday 
    |           SET holActive = ?, 
    |               holLct = ?
    |         WHERE holUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, active)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, holidayUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override suspend fun updateAsync(entity: Holiday): Unit {
    _insertAdapterHoliday_.insertAsync(entity)
  }

  public override fun insert(entity: Holiday): Long {
    val _retVal = _insertAdapterHoliday_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Holiday): Long {
    val _retVal = _insertAdapterHoliday_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Holiday>): Unit {
    _insertAdapterHoliday_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Holiday>): Unit {
    val _sql =
        "UPDATE Holiday SET holMasterCsn = ?, holLocalCsn = ?, holLastModBy = ?, holLct = ?, holActive = ?, holHolidayCalendarUid = ?, holStartTime = ?, holEndTime = ?, holName = ? WHERE holUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.holMasterCsn)
        _stmt.setLong(2, _entity.holLocalCsn)
        _stmt.setInt(3, _entity.holLastModBy)
        _stmt.setLong(4, _entity.holLct)
        _stmt.setBoolean(5, _entity.holActive)
        _stmt.setLong(6, _entity.holHolidayCalendarUid)
        _stmt.setLong(7, _entity.holStartTime)
        _stmt.setLong(8, _entity.holEndTime)
        _stmt.setString(9, _entity.holName)
        _stmt.setLong(10, _entity.holUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Holiday): Unit {
    val _sql =
        "UPDATE Holiday SET holMasterCsn = ?, holLocalCsn = ?, holLastModBy = ?, holLct = ?, holActive = ?, holHolidayCalendarUid = ?, holStartTime = ?, holEndTime = ?, holName = ? WHERE holUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.holMasterCsn)
      _stmt.setLong(2, entity.holLocalCsn)
      _stmt.setInt(3, entity.holLastModBy)
      _stmt.setLong(4, entity.holLct)
      _stmt.setBoolean(5, entity.holActive)
      _stmt.setLong(6, entity.holHolidayCalendarUid)
      _stmt.setLong(7, entity.holStartTime)
      _stmt.setLong(8, entity.holEndTime)
      _stmt.setString(9, entity.holName)
      _stmt.setLong(10, entity.holUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out Holiday>): Unit {
    _insertAdapterHoliday_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out Holiday>): Unit {
    val _sql =
        "UPDATE Holiday SET holMasterCsn = ?, holLocalCsn = ?, holLastModBy = ?, holLct = ?, holActive = ?, holHolidayCalendarUid = ?, holStartTime = ?, holEndTime = ?, holName = ? WHERE holUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.holMasterCsn)
        _stmt.setLong(2, _entity.holLocalCsn)
        _stmt.setInt(3, _entity.holLastModBy)
        _stmt.setLong(4, _entity.holLct)
        _stmt.setBoolean(5, _entity.holActive)
        _stmt.setLong(6, _entity.holHolidayCalendarUid)
        _stmt.setLong(7, _entity.holStartTime)
        _stmt.setLong(8, _entity.holEndTime)
        _stmt.setString(9, _entity.holName)
        _stmt.setLong(10, _entity.holUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }
}
