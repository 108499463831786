package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentJob
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit

public class ContentJobDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentJobDao() {
  public val _insertAdapterContentJob_: EntityInsertionAdapter<ContentJob> = object :
      EntityInsertionAdapter<ContentJob>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentJob (cjUid, toUri, cjProgress, cjTotal, cjNotificationTitle, cjIsMeteredAllowed, params) VALUES(?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentJob (cjUid, toUri, cjProgress, cjTotal, cjNotificationTitle, cjIsMeteredAllowed, params) VALUES(COALESCE(?,nextval('ContentJob_cjUid_seq')), ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cjUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentJob):
        Unit {
      if(entity.cjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cjUid)
      }
      stmt.setString(2, entity.toUri)
      stmt.setLong(3, entity.cjProgress)
      stmt.setLong(4, entity.cjTotal)
      stmt.setString(5, entity.cjNotificationTitle)
      stmt.setBoolean(6, entity.cjIsMeteredAllowed)
      stmt.setString(7, entity.params)
    }
  }

  public override suspend fun insertAsync(contentJob: ContentJob): Long {
    val _retVal = _insertAdapterContentJob_.insertAndReturnIdAsync(contentJob)
    return _retVal
  }

  public override fun findByUid(cjUid: Long): ContentJob? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentJob??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentJob.*
    |          FROM ContentJob
    |         WHERE cjUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentJob.*
    |          FROM ContentJob
    |         WHERE cjUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, cjUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cjUid = _resultSet.getLong("cjUid")
          val tmp_toUri = _resultSet.getString("toUri")
          val tmp_cjProgress = _resultSet.getLong("cjProgress")
          val tmp_cjTotal = _resultSet.getLong("cjTotal")
          val tmp_cjNotificationTitle = _resultSet.getString("cjNotificationTitle")
          val tmp_cjIsMeteredAllowed = _resultSet.getBoolean("cjIsMeteredAllowed")
          val tmp_params = _resultSet.getString("params")
          val _entity = ContentJob()
          _entity.cjUid = tmp_cjUid
          _entity.toUri = tmp_toUri
          _entity.cjProgress = tmp_cjProgress
          _entity.cjTotal = tmp_cjTotal
          _entity.cjNotificationTitle = tmp_cjNotificationTitle
          _entity.cjIsMeteredAllowed = tmp_cjIsMeteredAllowed
          _entity.params = tmp_params
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(cjUid: Long): ContentJob? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentJob??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentJob.*
    |          FROM ContentJob
    |         WHERE cjUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentJob.*
    |          FROM ContentJob
    |         WHERE cjUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, cjUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cjUid = _resultSet.getLong("cjUid")
          val tmp_toUri = _resultSet.getString("toUri")
          val tmp_cjProgress = _resultSet.getLong("cjProgress")
          val tmp_cjTotal = _resultSet.getLong("cjTotal")
          val tmp_cjNotificationTitle = _resultSet.getString("cjNotificationTitle")
          val tmp_cjIsMeteredAllowed = _resultSet.getBoolean("cjIsMeteredAllowed")
          val tmp_params = _resultSet.getString("params")
          val _entity = ContentJob()
          _entity.cjUid = tmp_cjUid
          _entity.toUri = tmp_toUri
          _entity.cjProgress = tmp_cjProgress
          _entity.cjTotal = tmp_cjTotal
          _entity.cjNotificationTitle = tmp_cjNotificationTitle
          _entity.cjIsMeteredAllowed = tmp_cjIsMeteredAllowed
          _entity.params = tmp_params
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findLiveDataByUid(cjUid: Long): DoorLiveData<ContentJob?> {
    val _result = DoorLiveDataImpl<ContentJob?>(_db, listOf("ContentJob"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ContentJob??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT ContentJob.*
      |          FROM ContentJob
      |         WHERE cjUid = ?
      |    
      """.trimMargin() , postgreSql = """
      |
      |        SELECT ContentJob.*
      |          FROM ContentJob
      |         WHERE cjUid = ?
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, cjUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_cjUid = _resultSet.getLong("cjUid")
            val tmp_toUri = _resultSet.getString("toUri")
            val tmp_cjProgress = _resultSet.getLong("cjProgress")
            val tmp_cjTotal = _resultSet.getLong("cjTotal")
            val tmp_cjNotificationTitle = _resultSet.getString("cjNotificationTitle")
            val tmp_cjIsMeteredAllowed = _resultSet.getBoolean("cjIsMeteredAllowed")
            val tmp_params = _resultSet.getString("params")
            val _entity = ContentJob()
            _entity.cjUid = tmp_cjUid
            _entity.toUri = tmp_toUri
            _entity.cjProgress = tmp_cjProgress
            _entity.cjTotal = tmp_cjTotal
            _entity.cjNotificationTitle = tmp_cjNotificationTitle
            _entity.cjIsMeteredAllowed = tmp_cjIsMeteredAllowed
            _entity.params = tmp_params
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateDestinationDir(cjUid: Long, toUri: String): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJob
    |           SET toUri = ?
    |         WHERE cjUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJob
    |           SET toUri = ?
    |         WHERE cjUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, toUri)
      _stmt.setLong(2, cjUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findMeteredAllowedLiveData(contentJobId: Long): DoorLiveData<Boolean> {
    val _result = DoorLiveDataImpl<Boolean>(_db, listOf("ContentJob"))  {
      var _liveResult = false
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT COALESCE((SELECT ContentJob.cjIsMeteredAllowed
      |          FROM ContentJob
      |         WHERE cjUid = ?
      |         LIMIT 1), 0)
      |    
      """.trimMargin() , postgreSql = """
      |
      |        SELECT COALESCE((SELECT ContentJob.cjIsMeteredAllowed
      |          FROM ContentJob
      |         WHERE cjUid = ?
      |         LIMIT 1), FALSE)
      |    
      """.trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, contentJobId)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val _entity = _resultSet.getBoolean(1)
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateMeteredAllowedForEntry(contentEntryUid: Long,
      meteredAllowed: Boolean): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJob 
    |           SET cjIsMeteredAllowed = ?
    |         WHERE cjUid IN (SELECT cjiJobUid 
    |                           FROM ContentJobItem
    |                          WHERE cjiContentEntryUid = ?
    |                             OR cjiParentContentEntryUid = ?)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJob 
    |           SET cjIsMeteredAllowed = ?
    |         WHERE cjUid IN (SELECT cjiJobUid 
    |                           FROM ContentJobItem
    |                          WHERE cjiContentEntryUid = ?
    |                             OR cjiParentContentEntryUid = ?)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, meteredAllowed)
      _stmt.setLong(2, contentEntryUid)
      _stmt.setLong(3, contentEntryUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun isConnectivityAcceptableForJob(jobId: Long): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |          WITH ConnectivityStateCte(state) AS 
    |             (SELECT COALESCE(
    |                     (SELECT connectivityState 
    |                        FROM ConnectivityStatus 
    |                       LIMIT 1), 0))
    |   
    |           SELECT COALESCE((
    |                  SELECT 1 
    |                    FROM ContentJob 
    |                   WHERE cjUid = ?
    |                    AND ((cjIsMeteredAllowed 
    |                         AND (SELECT state FROM ConnectivityStateCte) = 3)
    |			             OR (SELECT state FROM ConnectivityStateCte) = 4)
    |                  ) ,0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |          WITH ConnectivityStateCte(state) AS 
    |             (SELECT COALESCE(
    |                     (SELECT connectivityState 
    |                        FROM ConnectivityStatus 
    |                       LIMIT 1), 0))
    |   
    |           SELECT COALESCE((
    |                  SELECT 1 
    |                    FROM ContentJob 
    |                   WHERE cjUid = ?
    |                    AND ((cjIsMeteredAllowed 
    |                         AND (SELECT state FROM ConnectivityStateCte) = 3)
    |			             OR (SELECT state FROM ConnectivityStateCte) = 4)
    |                  ) ,0)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, jobId)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }
}
