package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.NetworkNode
import kotlin.Array
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class NetworkNodeDao_JdbcKt(
  public val _db: DoorDatabase
) : NetworkNodeDao() {
  public val _insertAdapterNetworkNode_upsert: EntityInsertionAdapter<NetworkNode> = object :
      EntityInsertionAdapter<NetworkNode>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO NetworkNode (nodeId, bluetoothMacAddress, ipAddress, wifiDirectMacAddress, deviceWifiDirectName, endpointUrl, lastUpdateTimeStamp, networkServiceLastUpdated, nsdServiceName, port, numFailureCount, wifiDirectDeviceStatus, groupSsid) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO NetworkNode (nodeId, bluetoothMacAddress, ipAddress, wifiDirectMacAddress, deviceWifiDirectName, endpointUrl, lastUpdateTimeStamp, networkServiceLastUpdated, nsdServiceName, port, numFailureCount, wifiDirectDeviceStatus, groupSsid) VALUES(COALESCE(?,nextval('NetworkNode_nodeId_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (nodeId) DO UPDATE SET bluetoothMacAddress = excluded.bluetoothMacAddress,ipAddress = excluded.ipAddress,wifiDirectMacAddress = excluded.wifiDirectMacAddress,deviceWifiDirectName = excluded.deviceWifiDirectName,endpointUrl = excluded.endpointUrl,lastUpdateTimeStamp = excluded.lastUpdateTimeStamp,networkServiceLastUpdated = excluded.networkServiceLastUpdated,nsdServiceName = excluded.nsdServiceName,port = excluded.port,numFailureCount = excluded.numFailureCount,wifiDirectDeviceStatus = excluded.wifiDirectDeviceStatus,groupSsid = excluded.groupSsid" + if(returnsId) { " RETURNING nodeId" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: NetworkNode):
        Unit {
      if(entity.nodeId == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.nodeId)
      }
      stmt.setString(2, entity.bluetoothMacAddress)
      stmt.setString(3, entity.ipAddress)
      stmt.setString(4, entity.wifiDirectMacAddress)
      stmt.setString(5, entity.deviceWifiDirectName)
      stmt.setString(6, entity.endpointUrl)
      stmt.setLong(7, entity.lastUpdateTimeStamp)
      stmt.setLong(8, entity.networkServiceLastUpdated)
      stmt.setString(9, entity.nsdServiceName)
      stmt.setInt(10, entity.port)
      stmt.setInt(11, entity.numFailureCount)
      stmt.setInt(12, entity.wifiDirectDeviceStatus)
      stmt.setString(13, entity.groupSsid)
    }
  }

  public override fun all(): List<NetworkNode> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.NetworkNode>()
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM NetworkNode" , postgreSql = """
    |SELECT * FROM NetworkNode
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_nodeId = _resultSet.getLong("nodeId")
          val tmp_bluetoothMacAddress = _resultSet.getString("bluetoothMacAddress")
          val tmp_ipAddress = _resultSet.getString("ipAddress")
          val tmp_wifiDirectMacAddress = _resultSet.getString("wifiDirectMacAddress")
          val tmp_deviceWifiDirectName = _resultSet.getString("deviceWifiDirectName")
          val tmp_endpointUrl = _resultSet.getString("endpointUrl")
          val tmp_lastUpdateTimeStamp = _resultSet.getLong("lastUpdateTimeStamp")
          val tmp_networkServiceLastUpdated = _resultSet.getLong("networkServiceLastUpdated")
          val tmp_nsdServiceName = _resultSet.getString("nsdServiceName")
          val tmp_port = _resultSet.getInt("port")
          val tmp_numFailureCount = _resultSet.getInt("numFailureCount")
          val tmp_wifiDirectDeviceStatus = _resultSet.getInt("wifiDirectDeviceStatus")
          val tmp_groupSsid = _resultSet.getString("groupSsid")
          val _entity = NetworkNode()
          _entity.nodeId = tmp_nodeId
          _entity.bluetoothMacAddress = tmp_bluetoothMacAddress
          _entity.ipAddress = tmp_ipAddress
          _entity.wifiDirectMacAddress = tmp_wifiDirectMacAddress
          _entity.deviceWifiDirectName = tmp_deviceWifiDirectName
          _entity.endpointUrl = tmp_endpointUrl
          _entity.lastUpdateTimeStamp = tmp_lastUpdateTimeStamp
          _entity.networkServiceLastUpdated = tmp_networkServiceLastUpdated
          _entity.nsdServiceName = tmp_nsdServiceName
          _entity.port = tmp_port
          _entity.numFailureCount = tmp_numFailureCount
          _entity.wifiDirectDeviceStatus = tmp_wifiDirectDeviceStatus
          _entity.groupSsid = tmp_groupSsid
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findNodeByIpAddress(ipAddress: String): NetworkNode? {
    var _result = null as com.ustadmobile.lib.db.entities.NetworkNode??
    val _stmtConfig = PreparedStatementConfig("SELECT * From NetworkNode WHERE ipAddress = ?" ,
        postgreSql = """
    |SELECT * From NetworkNode WHERE ipAddress = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, ipAddress)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_nodeId = _resultSet.getLong("nodeId")
          val tmp_bluetoothMacAddress = _resultSet.getString("bluetoothMacAddress")
          val tmp_ipAddress = _resultSet.getString("ipAddress")
          val tmp_wifiDirectMacAddress = _resultSet.getString("wifiDirectMacAddress")
          val tmp_deviceWifiDirectName = _resultSet.getString("deviceWifiDirectName")
          val tmp_endpointUrl = _resultSet.getString("endpointUrl")
          val tmp_lastUpdateTimeStamp = _resultSet.getLong("lastUpdateTimeStamp")
          val tmp_networkServiceLastUpdated = _resultSet.getLong("networkServiceLastUpdated")
          val tmp_nsdServiceName = _resultSet.getString("nsdServiceName")
          val tmp_port = _resultSet.getInt("port")
          val tmp_numFailureCount = _resultSet.getInt("numFailureCount")
          val tmp_wifiDirectDeviceStatus = _resultSet.getInt("wifiDirectDeviceStatus")
          val tmp_groupSsid = _resultSet.getString("groupSsid")
          val _entity = NetworkNode()
          _entity.nodeId = tmp_nodeId
          _entity.bluetoothMacAddress = tmp_bluetoothMacAddress
          _entity.ipAddress = tmp_ipAddress
          _entity.wifiDirectMacAddress = tmp_wifiDirectMacAddress
          _entity.deviceWifiDirectName = tmp_deviceWifiDirectName
          _entity.endpointUrl = tmp_endpointUrl
          _entity.lastUpdateTimeStamp = tmp_lastUpdateTimeStamp
          _entity.networkServiceLastUpdated = tmp_networkServiceLastUpdated
          _entity.nsdServiceName = tmp_nsdServiceName
          _entity.port = tmp_port
          _entity.numFailureCount = tmp_numFailureCount
          _entity.wifiDirectDeviceStatus = tmp_wifiDirectDeviceStatus
          _entity.groupSsid = tmp_groupSsid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findNodeById(nodeId: Long): NetworkNode? {
    var _result = null as com.ustadmobile.lib.db.entities.NetworkNode??
    val _stmtConfig = PreparedStatementConfig("SELECT * From NetworkNode WHERE nodeId = ?" ,
        postgreSql = """
    |SELECT * From NetworkNode WHERE nodeId = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, nodeId)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_nodeId = _resultSet.getLong("nodeId")
          val tmp_bluetoothMacAddress = _resultSet.getString("bluetoothMacAddress")
          val tmp_ipAddress = _resultSet.getString("ipAddress")
          val tmp_wifiDirectMacAddress = _resultSet.getString("wifiDirectMacAddress")
          val tmp_deviceWifiDirectName = _resultSet.getString("deviceWifiDirectName")
          val tmp_endpointUrl = _resultSet.getString("endpointUrl")
          val tmp_lastUpdateTimeStamp = _resultSet.getLong("lastUpdateTimeStamp")
          val tmp_networkServiceLastUpdated = _resultSet.getLong("networkServiceLastUpdated")
          val tmp_nsdServiceName = _resultSet.getString("nsdServiceName")
          val tmp_port = _resultSet.getInt("port")
          val tmp_numFailureCount = _resultSet.getInt("numFailureCount")
          val tmp_wifiDirectDeviceStatus = _resultSet.getInt("wifiDirectDeviceStatus")
          val tmp_groupSsid = _resultSet.getString("groupSsid")
          val _entity = NetworkNode()
          _entity.nodeId = tmp_nodeId
          _entity.bluetoothMacAddress = tmp_bluetoothMacAddress
          _entity.ipAddress = tmp_ipAddress
          _entity.wifiDirectMacAddress = tmp_wifiDirectMacAddress
          _entity.deviceWifiDirectName = tmp_deviceWifiDirectName
          _entity.endpointUrl = tmp_endpointUrl
          _entity.lastUpdateTimeStamp = tmp_lastUpdateTimeStamp
          _entity.networkServiceLastUpdated = tmp_networkServiceLastUpdated
          _entity.nsdServiceName = tmp_nsdServiceName
          _entity.port = tmp_port
          _entity.numFailureCount = tmp_numFailureCount
          _entity.wifiDirectDeviceStatus = tmp_wifiDirectDeviceStatus
          _entity.groupSsid = tmp_groupSsid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findNodeByIpOrWifiDirectMacAddress(ipAddress: String,
      wifiDirectMacAddress: String): NetworkNode? {
    var _result = null as com.ustadmobile.lib.db.entities.NetworkNode??
    val _stmtConfig =
        PreparedStatementConfig("Select * From NetworkNode Where ((ipAddress = ? AND ipAddress IS NOT NULL) OR (wifiDirectMacAddress = ? AND wifiDirectMacAddress IS NOT NULL))"
        , postgreSql = """
    |Select * From NetworkNode Where ((ipAddress = ? AND ipAddress IS NOT NULL) OR (wifiDirectMacAddress = ? AND wifiDirectMacAddress IS NOT NULL))
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, ipAddress)
      _stmt.setString(2, wifiDirectMacAddress)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_nodeId = _resultSet.getLong("nodeId")
          val tmp_bluetoothMacAddress = _resultSet.getString("bluetoothMacAddress")
          val tmp_ipAddress = _resultSet.getString("ipAddress")
          val tmp_wifiDirectMacAddress = _resultSet.getString("wifiDirectMacAddress")
          val tmp_deviceWifiDirectName = _resultSet.getString("deviceWifiDirectName")
          val tmp_endpointUrl = _resultSet.getString("endpointUrl")
          val tmp_lastUpdateTimeStamp = _resultSet.getLong("lastUpdateTimeStamp")
          val tmp_networkServiceLastUpdated = _resultSet.getLong("networkServiceLastUpdated")
          val tmp_nsdServiceName = _resultSet.getString("nsdServiceName")
          val tmp_port = _resultSet.getInt("port")
          val tmp_numFailureCount = _resultSet.getInt("numFailureCount")
          val tmp_wifiDirectDeviceStatus = _resultSet.getInt("wifiDirectDeviceStatus")
          val tmp_groupSsid = _resultSet.getString("groupSsid")
          val _entity = NetworkNode()
          _entity.nodeId = tmp_nodeId
          _entity.bluetoothMacAddress = tmp_bluetoothMacAddress
          _entity.ipAddress = tmp_ipAddress
          _entity.wifiDirectMacAddress = tmp_wifiDirectMacAddress
          _entity.deviceWifiDirectName = tmp_deviceWifiDirectName
          _entity.endpointUrl = tmp_endpointUrl
          _entity.lastUpdateTimeStamp = tmp_lastUpdateTimeStamp
          _entity.networkServiceLastUpdated = tmp_networkServiceLastUpdated
          _entity.nsdServiceName = tmp_nsdServiceName
          _entity.port = tmp_port
          _entity.numFailureCount = tmp_numFailureCount
          _entity.wifiDirectDeviceStatus = tmp_wifiDirectDeviceStatus
          _entity.groupSsid = tmp_groupSsid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findNodeByBluetoothAddress(bluetoothAddress: String): NetworkNode? {
    var _result = null as com.ustadmobile.lib.db.entities.NetworkNode??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * from NetworkNode WHERE bluetoothMacAddress = ?" ,
        postgreSql = """
    |SELECT * from NetworkNode WHERE bluetoothMacAddress = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, bluetoothAddress)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_nodeId = _resultSet.getLong("nodeId")
          val tmp_bluetoothMacAddress = _resultSet.getString("bluetoothMacAddress")
          val tmp_ipAddress = _resultSet.getString("ipAddress")
          val tmp_wifiDirectMacAddress = _resultSet.getString("wifiDirectMacAddress")
          val tmp_deviceWifiDirectName = _resultSet.getString("deviceWifiDirectName")
          val tmp_endpointUrl = _resultSet.getString("endpointUrl")
          val tmp_lastUpdateTimeStamp = _resultSet.getLong("lastUpdateTimeStamp")
          val tmp_networkServiceLastUpdated = _resultSet.getLong("networkServiceLastUpdated")
          val tmp_nsdServiceName = _resultSet.getString("nsdServiceName")
          val tmp_port = _resultSet.getInt("port")
          val tmp_numFailureCount = _resultSet.getInt("numFailureCount")
          val tmp_wifiDirectDeviceStatus = _resultSet.getInt("wifiDirectDeviceStatus")
          val tmp_groupSsid = _resultSet.getString("groupSsid")
          val _entity = NetworkNode()
          _entity.nodeId = tmp_nodeId
          _entity.bluetoothMacAddress = tmp_bluetoothMacAddress
          _entity.ipAddress = tmp_ipAddress
          _entity.wifiDirectMacAddress = tmp_wifiDirectMacAddress
          _entity.deviceWifiDirectName = tmp_deviceWifiDirectName
          _entity.endpointUrl = tmp_endpointUrl
          _entity.lastUpdateTimeStamp = tmp_lastUpdateTimeStamp
          _entity.networkServiceLastUpdated = tmp_networkServiceLastUpdated
          _entity.nsdServiceName = tmp_nsdServiceName
          _entity.port = tmp_port
          _entity.numFailureCount = tmp_numFailureCount
          _entity.wifiDirectDeviceStatus = tmp_wifiDirectDeviceStatus
          _entity.groupSsid = tmp_groupSsid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun replace(node: NetworkNode): Long {
    val _retVal = _insertAdapterNetworkNode_upsert.insertAndReturnId(node)
    return _retVal
  }

  public override suspend fun insertAsync(node: NetworkNode): Long {
    val _retVal = _insertAdapterNetworkNode_upsert.insertAndReturnIdAsync(node)
    return _retVal
  }

  public override fun insertList(nodeList: List<out NetworkNode>): Array<Long> {
    val _retVal = _insertAdapterNetworkNode_upsert.insertListAndReturnIds(nodeList)
    return _retVal.toTypedArray()
  }

  public override fun update(node: NetworkNode): Unit {
    val _sql =
        "UPDATE NetworkNode SET bluetoothMacAddress = ?, ipAddress = ?, wifiDirectMacAddress = ?, deviceWifiDirectName = ?, endpointUrl = ?, lastUpdateTimeStamp = ?, networkServiceLastUpdated = ?, nsdServiceName = ?, port = ?, numFailureCount = ?, wifiDirectDeviceStatus = ?, groupSsid = ? WHERE nodeId = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, node.bluetoothMacAddress)
      _stmt.setString(2, node.ipAddress)
      _stmt.setString(3, node.wifiDirectMacAddress)
      _stmt.setString(4, node.deviceWifiDirectName)
      _stmt.setString(5, node.endpointUrl)
      _stmt.setLong(6, node.lastUpdateTimeStamp)
      _stmt.setLong(7, node.networkServiceLastUpdated)
      _stmt.setString(8, node.nsdServiceName)
      _stmt.setInt(9, node.port)
      _stmt.setInt(10, node.numFailureCount)
      _stmt.setInt(11, node.wifiDirectDeviceStatus)
      _stmt.setString(12, node.groupSsid)
      _stmt.setLong(13, node.nodeId)
      _stmt.executeUpdate()
    }
  }

  public override fun deleteByBluetoothAddress(bluetoothAddress: String): Unit {
    val _stmtConfig =
        PreparedStatementConfig("DELETE FROM NetworkNode WHERE bluetoothMacAddress = ?" , postgreSql
        = """
    |DELETE FROM NetworkNode WHERE bluetoothMacAddress = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, bluetoothAddress)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override suspend fun deleteAllAsync(): Unit {
    val _stmtConfig = PreparedStatementConfig("DELETE FROM NetworkNode" , postgreSql = """
    |DELETE FROM NetworkNode
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateRetryCountAsync(nodeId: Long): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE NetworkNode SET numFailureCount = numFailureCount + 1 WHERE nodeId = ?"
        , postgreSql = """
    |UPDATE NetworkNode SET numFailureCount = numFailureCount + 1 WHERE nodeId = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, nodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllActiveNodes(lastUpdatedTime: Long, maxNumFailure: Int):
      List<NetworkNode> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.NetworkNode>()
    val _stmtConfig =
        PreparedStatementConfig("Select * From NetworkNode WHERE lastUpdateTimeStamp >= ? AND numFailureCount <= ?"
        , postgreSql = """
    |Select * From NetworkNode WHERE lastUpdateTimeStamp >= ? AND numFailureCount <= ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, lastUpdatedTime)
      _stmt.setInt(2, maxNumFailure)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_nodeId = _resultSet.getLong("nodeId")
          val tmp_bluetoothMacAddress = _resultSet.getString("bluetoothMacAddress")
          val tmp_ipAddress = _resultSet.getString("ipAddress")
          val tmp_wifiDirectMacAddress = _resultSet.getString("wifiDirectMacAddress")
          val tmp_deviceWifiDirectName = _resultSet.getString("deviceWifiDirectName")
          val tmp_endpointUrl = _resultSet.getString("endpointUrl")
          val tmp_lastUpdateTimeStamp = _resultSet.getLong("lastUpdateTimeStamp")
          val tmp_networkServiceLastUpdated = _resultSet.getLong("networkServiceLastUpdated")
          val tmp_nsdServiceName = _resultSet.getString("nsdServiceName")
          val tmp_port = _resultSet.getInt("port")
          val tmp_numFailureCount = _resultSet.getInt("numFailureCount")
          val tmp_wifiDirectDeviceStatus = _resultSet.getInt("wifiDirectDeviceStatus")
          val tmp_groupSsid = _resultSet.getString("groupSsid")
          val _entity = NetworkNode()
          _entity.nodeId = tmp_nodeId
          _entity.bluetoothMacAddress = tmp_bluetoothMacAddress
          _entity.ipAddress = tmp_ipAddress
          _entity.wifiDirectMacAddress = tmp_wifiDirectMacAddress
          _entity.deviceWifiDirectName = tmp_deviceWifiDirectName
          _entity.endpointUrl = tmp_endpointUrl
          _entity.lastUpdateTimeStamp = tmp_lastUpdateTimeStamp
          _entity.networkServiceLastUpdated = tmp_networkServiceLastUpdated
          _entity.nsdServiceName = tmp_nsdServiceName
          _entity.port = tmp_port
          _entity.numFailureCount = tmp_numFailureCount
          _entity.wifiDirectDeviceStatus = tmp_wifiDirectDeviceStatus
          _entity.groupSsid = tmp_groupSsid
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun updateLastSeenAsync(bluetoothAddress: String,
      lastUpdateTimeStamp: Long): Int {
    var _result = 0
    val _stmtConfig =
        PreparedStatementConfig("UPDATE NetworkNode set lastUpdateTimeStamp = ?, numFailureCount = 0 WHERE bluetoothMacAddress = ?"
        , postgreSql = """
    |UPDATE NetworkNode set lastUpdateTimeStamp = ?, numFailureCount = 0 WHERE bluetoothMacAddress = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, lastUpdateTimeStamp)
      _stmt.setString(2, bluetoothAddress)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
      _result = _numUpdates
    }
    return _result
  }

  public override fun deleteOldAndBadNode(minLastSeenTimestamp: Long, maxFailuresInPeriod: Int):
      Unit {
    val _stmtConfig =
        PreparedStatementConfig("DELETE FROM NetworkNode WHERE NetworkNode.lastUpdateTimeStamp < ? OR NetworkNode.numFailureCount >= ?"
        , postgreSql = """
    |DELETE FROM NetworkNode WHERE NetworkNode.lastUpdateTimeStamp < ? OR NetworkNode.numFailureCount >= ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, minLastSeenTimestamp)
      _stmt.setInt(2, maxFailuresInPeriod)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun updateNetworkNodeGroupSsid(
    nodeId: Long,
    groupSsid: String,
    endpointUrl: String
  ): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE NetworkNode SET groupSsid = ?, endpointUrl = ?  WHERE nodeId = ?"
        , postgreSql = """
    |UPDATE NetworkNode SET groupSsid = ?, endpointUrl = ?  WHERE nodeId = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, groupSsid)
      _stmt.setString(2, endpointUrl)
      _stmt.setLong(3, nodeId)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun getEndpointUrlByGroupSsid(ssid: String): String? {
    var _result = null as kotlin.String??
    val _stmtConfig =
        PreparedStatementConfig("SELECT endpointUrl FROM NetworkNode WHERE groupSsid = ?" ,
        postgreSql = """
    |SELECT endpointUrl FROM NetworkNode WHERE groupSsid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, ssid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getString(1)
          _result = _entity
        }
      }
    }
    return _result
  }
}
