package com.ustadmobile.core.view

interface RegisterAgeRedirectView : UstadView {

    var dateOfBirth: Long

    companion object {

        val VIEW_NAME = "RegisterAgeRedirect"

    }

}