package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.lib.db.entities.ContainerETag
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContainerETagDao_JdbcKt(
  public val _db: DoorDatabase
) : ContainerETagDao() {
  public val _insertAdapterContainerETag_: EntityInsertionAdapter<ContainerETag> = object :
      EntityInsertionAdapter<ContainerETag>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContainerETag (ceContainerUid, cetag) VALUES(?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContainerETag (ceContainerUid, cetag) VALUES(?, ?)" + if(returnsId) { " RETURNING ceContainerUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContainerETag):
        Unit {
      stmt.setLong(1, entity.ceContainerUid)
      stmt.setString(2, entity.cetag)
    }
  }

  public override fun getEtagOfContainer(containerUid: Long): String? {
    var _result = null as kotlin.String??
    val _stmtConfig =
        PreparedStatementConfig("SELECT cetag FROM ContainerETag WHERE ceContainerUid = ?" ,
        postgreSql = """
    |SELECT cetag FROM ContainerETag WHERE ceContainerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getString(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ContainerETag): Long {
    val _retVal = _insertAdapterContainerETag_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerETag): Long {
    val _retVal = _insertAdapterContainerETag_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContainerETag>): Unit {
    _insertAdapterContainerETag_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContainerETag>): Unit {
    val _sql = "UPDATE ContainerETag SET cetag = ? WHERE ceContainerUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.cetag)
        _stmt.setLong(2, _entity.ceContainerUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContainerETag): Unit {
    val _sql = "UPDATE ContainerETag SET cetag = ? WHERE ceContainerUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.cetag)
      _stmt.setLong(2, entity.ceContainerUid)
      _stmt.executeUpdate()
    }
  }
}
