package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ScopedGrant
import com.ustadmobile.lib.db.entities.ScopedGrantAndName
import com.ustadmobile.lib.db.entities.ScopedGrantWithName
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ScopedGrantDao_JdbcKt(
  public val _db: DoorDatabase
) : ScopedGrantDao() {
  public val _insertAdapterScopedGrant_: EntityInsertionAdapter<ScopedGrant> = object :
      EntityInsertionAdapter<ScopedGrant>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ScopedGrant (sgUid, sgPcsn, sgLcsn, sgLcb, sgLct, sgTableId, sgEntityUid, sgPermissions, sgGroupUid, sgIndex, sgFlags) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ScopedGrant (sgUid, sgPcsn, sgLcsn, sgLcb, sgLct, sgTableId, sgEntityUid, sgPermissions, sgGroupUid, sgIndex, sgFlags) VALUES(COALESCE(?,nextval('ScopedGrant_sgUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING sgUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ScopedGrant):
        Unit {
      if(entity.sgUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.sgUid)
      }
      stmt.setLong(2, entity.sgPcsn)
      stmt.setLong(3, entity.sgLcsn)
      stmt.setInt(4, entity.sgLcb)
      stmt.setLong(5, entity.sgLct)
      stmt.setInt(6, entity.sgTableId)
      stmt.setLong(7, entity.sgEntityUid)
      stmt.setLong(8, entity.sgPermissions)
      stmt.setLong(9, entity.sgGroupUid)
      stmt.setInt(10, entity.sgIndex)
      stmt.setInt(11, entity.sgFlags)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantWithPerm.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |             JOIN ScopedGrant ScopedGrantWithPerm
    |                    ON PersonsWithPerm_GroupMember.groupMemberGroupUid = ScopedGrantWithPerm.sgGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ScopedGrantWithPerm.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantWithPerm.sgUid
    |                 AND sgDestination = ?), 0) 
    |      /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |             SET sgPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantWithPerm.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |             JOIN ScopedGrant ScopedGrantWithPerm
    |                    ON PersonsWithPerm_GroupMember.groupMemberGroupUid = ScopedGrantWithPerm.sgGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ScopedGrantWithPerm.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantWithPerm.sgUid
    |                 AND sgDestination = ?), 0) 
    |       ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |             SET sgPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |             ON ChangeLog.chTableId = 48
    |                AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN PersonGroupMember
    |              ON PersonGroupMember.groupMemberGroupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |             ON ChangeLog.chTableId = 48
    |                AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN PersonGroupMember
    |              ON PersonGroupMember.groupMemberGroupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON Clazz.clazzUid = ScopedGrant.sgEntityUid
    |                       AND ScopedGrantEntity.sgTableId = 6
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = ?), 0) 
    |      /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |             SET sgPending = true
    |      */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON Clazz.clazzUid = ScopedGrant.sgEntityUid
    |                       AND ScopedGrantEntity.sgTableId = 6
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = ?), 0) 
    |       ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |             SET sgPending = true
    |      
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangePersonBased(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN Person
    |              ON ScopedGrantEntity.sgTableId = 9
    |                 AND ScopedGrantEntity.sgEntityUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |            64
    |            
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |             
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */                    
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN Person
    |              ON ScopedGrantEntity.sgTableId = 9
    |                 AND ScopedGrantEntity.sgEntityUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |            64
    |            
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |             
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |         (SELECT sgVersionId
    |            FROM ScopedGrantReplicate
    |           WHERE sgPk = ScopedGrantEntity.sgUid
    |             AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                      
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON ScopedGrantEntity.sgTableId = 9
    |                       AND ScopedGrantEntity.sgEntityUid = Person.personUid 
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1 
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */                                                       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON ScopedGrantEntity.sgTableId = 9
    |                       AND ScopedGrantEntity.sgEntityUid = Person.personUid 
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1 
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                                                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN School
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |                  536870912
    |                  
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |              
    |   WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */                 
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |  SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |         UserSession.usClientNodeId AS sgDestination
    |    FROM ChangeLog
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON ChangeLog.chTableId = 48
    |                 AND ChangeLog.chEntityPk = ScopedGrantEntity.sgUid
    |         JOIN School
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |                  536870912
    |                  
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |              
    |   WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |     AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                    536870912
    |                    
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON ScopedGrantEntity.sgTableId = 164
    |                       AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1 
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |  */                                                                                 
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScopedGrantReplicate(sgPk, sgDestination)
    |      SELECT DISTINCT ScopedGrantEntity.sgUid AS sgPk,
    |             ? AS sgDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                    536870912
    |                    
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |               JOIN ScopedGrant ScopedGrantEntity
    |                    ON ScopedGrantEntity.sgTableId = 164
    |                       AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1 
    |         AND ScopedGrantEntity.sgLct != COALESCE(
    |             (SELECT sgVersionId
    |                FROM ScopedGrantReplicate
    |               WHERE sgPk = ScopedGrantEntity.sgUid
    |                 AND sgDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sgPk, sgDestination) DO UPDATE
    |     SET sgPending = true
    |                                                                                   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertAsync(scopedGrant: ScopedGrant): Long {
    val _retVal = _insertAdapterScopedGrant_.insertAndReturnIdAsync(scopedGrant)
    return _retVal
  }

  public override suspend fun insertListAsync(scopedGrantList: List<out ScopedGrant>): Unit {
    _insertAdapterScopedGrant_.insertListAsync(scopedGrantList)
  }

  public override suspend fun updateAsync(scopedGrant: ScopedGrant): Unit {
    val _sql =
        "UPDATE ScopedGrant SET sgPcsn = ?, sgLcsn = ?, sgLcb = ?, sgLct = ?, sgTableId = ?, sgEntityUid = ?, sgPermissions = ?, sgGroupUid = ?, sgIndex = ?, sgFlags = ? WHERE sgUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, scopedGrant.sgPcsn)
      _stmt.setLong(2, scopedGrant.sgLcsn)
      _stmt.setInt(3, scopedGrant.sgLcb)
      _stmt.setLong(4, scopedGrant.sgLct)
      _stmt.setInt(5, scopedGrant.sgTableId)
      _stmt.setLong(6, scopedGrant.sgEntityUid)
      _stmt.setLong(7, scopedGrant.sgPermissions)
      _stmt.setLong(8, scopedGrant.sgGroupUid)
      _stmt.setInt(9, scopedGrant.sgIndex)
      _stmt.setInt(10, scopedGrant.sgFlags)
      _stmt.setLong(11, scopedGrant.sgUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateListAsync(scopedGrantList: List<out ScopedGrant>): Unit {
    val _sql =
        "UPDATE ScopedGrant SET sgPcsn = ?, sgLcsn = ?, sgLcb = ?, sgLct = ?, sgTableId = ?, sgEntityUid = ?, sgPermissions = ?, sgGroupUid = ?, sgIndex = ?, sgFlags = ? WHERE sgUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in scopedGrantList) {
        _stmt.setLong(1, _entity.sgPcsn)
        _stmt.setLong(2, _entity.sgLcsn)
        _stmt.setInt(3, _entity.sgLcb)
        _stmt.setLong(4, _entity.sgLct)
        _stmt.setInt(5, _entity.sgTableId)
        _stmt.setLong(6, _entity.sgEntityUid)
        _stmt.setLong(7, _entity.sgPermissions)
        _stmt.setLong(8, _entity.sgGroupUid)
        _stmt.setInt(9, _entity.sgIndex)
        _stmt.setInt(10, _entity.sgFlags)
        _stmt.setLong(11, _entity.sgUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun findByTableIdAndEntityUid(tableId: Int, entityUid: Long):
      List<ScopedGrantAndName> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ScopedGrantAndName>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ScopedGrant.*,
    |               CASE
    |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
    |               ELSE PersonGroup.groupName 
    |               END AS name
    |          FROM ScopedGrant
    |               JOIN PersonGroup 
    |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
    |               LEFT JOIN Person
    |                         ON Person.personGroupUid = PersonGroup.groupUid
    |         WHERE ScopedGrant.sgTableId = ?
    |               AND ScopedGrant.sgEntityUid = ?  
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ScopedGrant.*,
    |               CASE
    |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
    |               ELSE PersonGroup.groupName 
    |               END AS name
    |          FROM ScopedGrant
    |               JOIN PersonGroup 
    |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
    |               LEFT JOIN Person
    |                         ON Person.personGroupUid = PersonGroup.groupUid
    |         WHERE ScopedGrant.sgTableId = ?
    |               AND ScopedGrant.sgEntityUid = ?  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, tableId)
      _stmt.setLong(2, entityUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_name = _resultSet.getString("name")
          val _entity = ScopedGrantAndName()
          _entity.name = tmp_name
          var _scopedGrant_nullFieldCount = 0
          val tmp_sgUid = _resultSet.getLong("sgUid")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgPcsn = _resultSet.getLong("sgPcsn")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgLcsn = _resultSet.getLong("sgLcsn")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgLcb = _resultSet.getInt("sgLcb")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgLct = _resultSet.getLong("sgLct")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgTableId = _resultSet.getInt("sgTableId")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgEntityUid = _resultSet.getLong("sgEntityUid")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgPermissions = _resultSet.getLong("sgPermissions")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgGroupUid = _resultSet.getLong("sgGroupUid")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgIndex = _resultSet.getInt("sgIndex")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          val tmp_sgFlags = _resultSet.getInt("sgFlags")
          if(_resultSet.wasNull()) { _scopedGrant_nullFieldCount++ }
          if(_scopedGrant_nullFieldCount < 11) {
            if(_entity.scopedGrant == null) {
              _entity.scopedGrant = ScopedGrant()
            }
            _entity.scopedGrant!!.sgUid = tmp_sgUid
            _entity.scopedGrant!!.sgPcsn = tmp_sgPcsn
            _entity.scopedGrant!!.sgLcsn = tmp_sgLcsn
            _entity.scopedGrant!!.sgLcb = tmp_sgLcb
            _entity.scopedGrant!!.sgLct = tmp_sgLct
            _entity.scopedGrant!!.sgTableId = tmp_sgTableId
            _entity.scopedGrant!!.sgEntityUid = tmp_sgEntityUid
            _entity.scopedGrant!!.sgPermissions = tmp_sgPermissions
            _entity.scopedGrant!!.sgGroupUid = tmp_sgGroupUid
            _entity.scopedGrant!!.sgIndex = tmp_sgIndex
            _entity.scopedGrant!!.sgFlags = tmp_sgFlags
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByTableIdAndEntityUidWithNameAsDataSource(tableId: Int, entityUid: Long):
      DoorDataSourceFactory<Int, ScopedGrantWithName> {
    val _result = object : DoorDataSourceFactory<Int, ScopedGrantWithName>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ScopedGrantWithName>> = DoorLiveDataImpl<List<ScopedGrantWithName>>(_db,
          listOf("ScopedGrant", "PersonGroup", "Person"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.ScopedGrantWithName>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT ScopedGrant.*,
        |               CASE
        |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
        |               ELSE PersonGroup.groupName 
        |               END AS name
        |          FROM ScopedGrant
        |               JOIN PersonGroup 
        |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
        |               LEFT JOIN Person
        |                         ON Person.personGroupUid = PersonGroup.groupUid
        |         WHERE ScopedGrant.sgTableId = ?
        |               AND ScopedGrant.sgEntityUid = ?  
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT ScopedGrant.*,
        |               CASE
        |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
        |               ELSE PersonGroup.groupName 
        |               END AS name
        |          FROM ScopedGrant
        |               JOIN PersonGroup 
        |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
        |               LEFT JOIN Person
        |                         ON Person.personGroupUid = PersonGroup.groupUid
        |         WHERE ScopedGrant.sgTableId = ?
        |               AND ScopedGrant.sgEntityUid = ?  
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, tableId)
          _stmt.setLong(2, entityUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_name = _resultSet.getString("name")
              val tmp_sgUid = _resultSet.getLong("sgUid")
              val tmp_sgPcsn = _resultSet.getLong("sgPcsn")
              val tmp_sgLcsn = _resultSet.getLong("sgLcsn")
              val tmp_sgLcb = _resultSet.getInt("sgLcb")
              val tmp_sgLct = _resultSet.getLong("sgLct")
              val tmp_sgTableId = _resultSet.getInt("sgTableId")
              val tmp_sgEntityUid = _resultSet.getLong("sgEntityUid")
              val tmp_sgPermissions = _resultSet.getLong("sgPermissions")
              val tmp_sgGroupUid = _resultSet.getLong("sgGroupUid")
              val tmp_sgIndex = _resultSet.getInt("sgIndex")
              val tmp_sgFlags = _resultSet.getInt("sgFlags")
              val _entity = ScopedGrantWithName()
              _entity.name = tmp_name
              _entity.sgUid = tmp_sgUid
              _entity.sgPcsn = tmp_sgPcsn
              _entity.sgLcsn = tmp_sgLcsn
              _entity.sgLcb = tmp_sgLcb
              _entity.sgLct = tmp_sgLct
              _entity.sgTableId = tmp_sgTableId
              _entity.sgEntityUid = tmp_sgEntityUid
              _entity.sgPermissions = tmp_sgPermissions
              _entity.sgGroupUid = tmp_sgGroupUid
              _entity.sgIndex = tmp_sgIndex
              _entity.sgFlags = tmp_sgFlags
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ScopedGrant", "PersonGroup", "Person"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT ScopedGrant.*,
        |               CASE
        |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
        |               ELSE PersonGroup.groupName 
        |               END AS name
        |          FROM ScopedGrant
        |               JOIN PersonGroup 
        |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
        |               LEFT JOIN Person
        |                         ON Person.personGroupUid = PersonGroup.groupUid
        |         WHERE ScopedGrant.sgTableId = ?
        |               AND ScopedGrant.sgEntityUid = ?  
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT ScopedGrant.*,
        |               CASE
        |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
        |               ELSE PersonGroup.groupName 
        |               END AS name
        |          FROM ScopedGrant
        |               JOIN PersonGroup 
        |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
        |               LEFT JOIN Person
        |                         ON Person.personGroupUid = PersonGroup.groupUid
        |         WHERE ScopedGrant.sgTableId = ?
        |               AND ScopedGrant.sgEntityUid = ?  
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, tableId)
          _stmt.setLong(2, entityUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findByTableIdAndEntityIdSync(tableId: Int, entityUid: Long):
      List<ScopedGrant> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ScopedGrant>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ScopedGrant.*
    |          FROM ScopedGrant
    |         WHERE sgTableId = ?
    |           AND sgEntityUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ScopedGrant.*
    |          FROM ScopedGrant
    |         WHERE sgTableId = ?
    |           AND sgEntityUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, tableId)
      _stmt.setLong(2, entityUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_sgUid = _resultSet.getLong("sgUid")
          val tmp_sgPcsn = _resultSet.getLong("sgPcsn")
          val tmp_sgLcsn = _resultSet.getLong("sgLcsn")
          val tmp_sgLcb = _resultSet.getInt("sgLcb")
          val tmp_sgLct = _resultSet.getLong("sgLct")
          val tmp_sgTableId = _resultSet.getInt("sgTableId")
          val tmp_sgEntityUid = _resultSet.getLong("sgEntityUid")
          val tmp_sgPermissions = _resultSet.getLong("sgPermissions")
          val tmp_sgGroupUid = _resultSet.getLong("sgGroupUid")
          val tmp_sgIndex = _resultSet.getInt("sgIndex")
          val tmp_sgFlags = _resultSet.getInt("sgFlags")
          val _entity = ScopedGrant()
          _entity.sgUid = tmp_sgUid
          _entity.sgPcsn = tmp_sgPcsn
          _entity.sgLcsn = tmp_sgLcsn
          _entity.sgLcb = tmp_sgLcb
          _entity.sgLct = tmp_sgLct
          _entity.sgTableId = tmp_sgTableId
          _entity.sgEntityUid = tmp_sgEntityUid
          _entity.sgPermissions = tmp_sgPermissions
          _entity.sgGroupUid = tmp_sgGroupUid
          _entity.sgIndex = tmp_sgIndex
          _entity.sgFlags = tmp_sgFlags
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByUid(sgUid: Long): ScopedGrant? {
    var _result = null as com.ustadmobile.lib.db.entities.ScopedGrant??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ScopedGrant.*
    |          FROM ScopedGrant
    |         WHERE sgUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ScopedGrant.*
    |          FROM ScopedGrant
    |         WHERE sgUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, sgUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_sgUid = _resultSet.getLong("sgUid")
          val tmp_sgPcsn = _resultSet.getLong("sgPcsn")
          val tmp_sgLcsn = _resultSet.getLong("sgLcsn")
          val tmp_sgLcb = _resultSet.getInt("sgLcb")
          val tmp_sgLct = _resultSet.getLong("sgLct")
          val tmp_sgTableId = _resultSet.getInt("sgTableId")
          val tmp_sgEntityUid = _resultSet.getLong("sgEntityUid")
          val tmp_sgPermissions = _resultSet.getLong("sgPermissions")
          val tmp_sgGroupUid = _resultSet.getLong("sgGroupUid")
          val tmp_sgIndex = _resultSet.getInt("sgIndex")
          val tmp_sgFlags = _resultSet.getInt("sgFlags")
          val _entity = ScopedGrant()
          _entity.sgUid = tmp_sgUid
          _entity.sgPcsn = tmp_sgPcsn
          _entity.sgLcsn = tmp_sgLcsn
          _entity.sgLcb = tmp_sgLcb
          _entity.sgLct = tmp_sgLct
          _entity.sgTableId = tmp_sgTableId
          _entity.sgEntityUid = tmp_sgEntityUid
          _entity.sgPermissions = tmp_sgPermissions
          _entity.sgGroupUid = tmp_sgGroupUid
          _entity.sgIndex = tmp_sgIndex
          _entity.sgFlags = tmp_sgFlags
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUidLiveWithName(sgUid: Long): DoorLiveData<ScopedGrantWithName?> {
    val _result = DoorLiveDataImpl<ScopedGrantWithName?>(_db, listOf("ScopedGrant", "PersonGroup",
        "Person"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ScopedGrantWithName??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT ScopedGrant.*, 
      |               CASE
      |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
      |               ELSE PersonGroup.groupName 
      |               END AS name
      |          FROM ScopedGrant
      |               LEFT JOIN PersonGroup 
      |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
      |               LEFT JOIN Person
      |                    ON Person.personGroupUid = PersonGroup.groupUid
      |         WHERE ScopedGrant.sgUid = ? 
      |    
      """.trimMargin() , postgreSql = """
      |
      |        SELECT ScopedGrant.*, 
      |               CASE
      |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
      |               ELSE PersonGroup.groupName 
      |               END AS name
      |          FROM ScopedGrant
      |               LEFT JOIN PersonGroup 
      |                    ON ScopedGrant.sgGroupUid = PersonGroup.groupUid
      |               LEFT JOIN Person
      |                    ON Person.personGroupUid = PersonGroup.groupUid
      |         WHERE ScopedGrant.sgUid = ? 
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, sgUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_name = _resultSet.getString("name")
            val tmp_sgUid = _resultSet.getLong("sgUid")
            val tmp_sgPcsn = _resultSet.getLong("sgPcsn")
            val tmp_sgLcsn = _resultSet.getLong("sgLcsn")
            val tmp_sgLcb = _resultSet.getInt("sgLcb")
            val tmp_sgLct = _resultSet.getLong("sgLct")
            val tmp_sgTableId = _resultSet.getInt("sgTableId")
            val tmp_sgEntityUid = _resultSet.getLong("sgEntityUid")
            val tmp_sgPermissions = _resultSet.getLong("sgPermissions")
            val tmp_sgGroupUid = _resultSet.getLong("sgGroupUid")
            val tmp_sgIndex = _resultSet.getInt("sgIndex")
            val tmp_sgFlags = _resultSet.getInt("sgFlags")
            val _entity = ScopedGrantWithName()
            _entity.name = tmp_name
            _entity.sgUid = tmp_sgUid
            _entity.sgPcsn = tmp_sgPcsn
            _entity.sgLcsn = tmp_sgLcsn
            _entity.sgLcb = tmp_sgLcb
            _entity.sgLct = tmp_sgLct
            _entity.sgTableId = tmp_sgTableId
            _entity.sgEntityUid = tmp_sgEntityUid
            _entity.sgPermissions = tmp_sgPermissions
            _entity.sgGroupUid = tmp_sgGroupUid
            _entity.sgIndex = tmp_sgIndex
            _entity.sgFlags = tmp_sgFlags
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }
}
