package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.attachments.storeAttachment
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentEntryPicture
import com.ustadmobile.lib.db.entities.asEntityWithAttachment
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryPictureDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: ContentEntryPictureDao
) : ContentEntryPictureDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByContentEntryUidAsync(entryUid: Long): ContentEntryPicture? =
      _dao.findByContentEntryUidAsync(entryUid)

  public override fun findByContentEntryUidLive(entryUid: Long): DoorLiveData<ContentEntryPicture?>
      = _dao.findByContentEntryUidLive(entryUid)

  public override suspend fun updateAsync(ContentEntryPicture: ContentEntryPicture): Unit {
    _db.storeAttachment(ContentEntryPicture.asEntityWithAttachment())
    ContentEntryPicture.cepTimestamp = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(ContentEntryPicture)
  }

  public override fun insert(entity: ContentEntryPicture): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContentEntryPicture): Long {
    _db.storeAttachment(entity.asEntityWithAttachment())
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cepUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(138)
      entity.cepUid = _newPk
    }
    entity.cepTimestamp = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cepUid
  }

  public override fun insertList(entityList: List<out ContentEntryPicture>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out ContentEntryPicture>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ContentEntryPicture): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
