package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.lib.db.entities.DiscussionPost
import com.ustadmobile.lib.db.entities.DiscussionPostWithDetails
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class DiscussionPostDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: DiscussionPostDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : DiscussionPostDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun getPostsByDiscussionTopic(discussionTopicUid: Long):
      DoorDataSourceFactory<Int, DiscussionPostWithDetails> {
    val _result = _dao.getPostsByDiscussionTopic(discussionTopicUid)
    return _result
  }

  public override suspend fun getPostTitle(postUid: Long): String? {
    val _result = _dao.getPostTitle(postUid)
    return _result
  }

  public override suspend fun findByUid(uid: Long): DiscussionPost? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override suspend fun findWithDetailsByUid(uid: Long): DiscussionPostWithDetails? {
    val _result = _dao.findWithDetailsByUid(uid)
    return _result
  }

  public override fun findWithDetailsByUidLive(uid: Long):
      DoorLiveData<DiscussionPostWithDetails?> {
    val _result = _dao.findWithDetailsByUidLive(uid)
    return _result
  }

  public override suspend fun updateAsync(entity: DiscussionPost): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override fun insert(entity: DiscussionPost): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: DiscussionPost): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out DiscussionPost>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out DiscussionPost>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: DiscussionPost): Unit {
    _dao.update(entity)
  }
}
