package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CustomFieldValue
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CustomFieldValueDao_JdbcKt(
  public val _db: DoorDatabase
) : CustomFieldValueDao() {
  public val _insertAdapterCustomFieldValue_: EntityInsertionAdapter<CustomFieldValue> = object :
      EntityInsertionAdapter<CustomFieldValue>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CustomFieldValue (customFieldValueUid, customFieldValueFieldUid, customFieldValueEntityUid, customFieldValueValue, customFieldValueCustomFieldValueOptionUid, customFieldValueMCSN, customFieldValueLCSN, customFieldValueLCB, customFieldLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CustomFieldValue (customFieldValueUid, customFieldValueFieldUid, customFieldValueEntityUid, customFieldValueValue, customFieldValueCustomFieldValueOptionUid, customFieldValueMCSN, customFieldValueLCSN, customFieldValueLCB, customFieldLct) VALUES(COALESCE(?,nextval('CustomFieldValue_customFieldValueUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING customFieldValueUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CustomFieldValue):
        Unit {
      if(entity.customFieldValueUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.customFieldValueUid)
      }
      stmt.setLong(2, entity.customFieldValueFieldUid)
      stmt.setLong(3, entity.customFieldValueEntityUid)
      stmt.setString(4, entity.customFieldValueValue)
      stmt.setLong(5, entity.customFieldValueCustomFieldValueOptionUid)
      stmt.setLong(6, entity.customFieldValueMCSN)
      stmt.setLong(7, entity.customFieldValueLCSN)
      stmt.setInt(8, entity.customFieldValueLCB)
      stmt.setLong(9, entity.customFieldLct)
    }
  }

  public override suspend fun findValueByCustomFieldUidAndEntityUid(fieldUid: Long,
      entityUid: Long): CustomFieldValue? {
    var _result = null as com.ustadmobile.lib.db.entities.CustomFieldValue??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM CustomFieldValue WHERE customFieldValueFieldUid = ? AND  customFieldValueEntityUid = ? "
        , postgreSql = """
    |SELECT * FROM CustomFieldValue WHERE customFieldValueFieldUid = ? AND  customFieldValueEntityUid = ? 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, fieldUid)
      _stmt.setLong(2, entityUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_customFieldValueUid = _resultSet.getLong("customFieldValueUid")
          val tmp_customFieldValueFieldUid = _resultSet.getLong("customFieldValueFieldUid")
          val tmp_customFieldValueEntityUid = _resultSet.getLong("customFieldValueEntityUid")
          val tmp_customFieldValueValue = _resultSet.getString("customFieldValueValue")
          val tmp_customFieldValueCustomFieldValueOptionUid =
              _resultSet.getLong("customFieldValueCustomFieldValueOptionUid")
          val tmp_customFieldValueMCSN = _resultSet.getLong("customFieldValueMCSN")
          val tmp_customFieldValueLCSN = _resultSet.getLong("customFieldValueLCSN")
          val tmp_customFieldValueLCB = _resultSet.getInt("customFieldValueLCB")
          val tmp_customFieldLct = _resultSet.getLong("customFieldLct")
          val _entity = CustomFieldValue()
          _entity.customFieldValueUid = tmp_customFieldValueUid
          _entity.customFieldValueFieldUid = tmp_customFieldValueFieldUid
          _entity.customFieldValueEntityUid = tmp_customFieldValueEntityUid
          _entity.customFieldValueValue = tmp_customFieldValueValue
          _entity.customFieldValueCustomFieldValueOptionUid =
              tmp_customFieldValueCustomFieldValueOptionUid
          _entity.customFieldValueMCSN = tmp_customFieldValueMCSN
          _entity.customFieldValueLCSN = tmp_customFieldValueLCSN
          _entity.customFieldValueLCB = tmp_customFieldValueLCB
          _entity.customFieldLct = tmp_customFieldLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findValueByCustomFieldUidAndEntityUidSync(fieldUid: Long, entityUid: Long):
      CustomFieldValue? {
    var _result = null as com.ustadmobile.lib.db.entities.CustomFieldValue??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM CustomFieldValue WHERE customFieldValueFieldUid = ? AND  customFieldValueEntityUid = ? "
        , postgreSql = """
    |SELECT * FROM CustomFieldValue WHERE customFieldValueFieldUid = ? AND  customFieldValueEntityUid = ? 
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, fieldUid)
      _stmt.setLong(2, entityUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_customFieldValueUid = _resultSet.getLong("customFieldValueUid")
          val tmp_customFieldValueFieldUid = _resultSet.getLong("customFieldValueFieldUid")
          val tmp_customFieldValueEntityUid = _resultSet.getLong("customFieldValueEntityUid")
          val tmp_customFieldValueValue = _resultSet.getString("customFieldValueValue")
          val tmp_customFieldValueCustomFieldValueOptionUid =
              _resultSet.getLong("customFieldValueCustomFieldValueOptionUid")
          val tmp_customFieldValueMCSN = _resultSet.getLong("customFieldValueMCSN")
          val tmp_customFieldValueLCSN = _resultSet.getLong("customFieldValueLCSN")
          val tmp_customFieldValueLCB = _resultSet.getInt("customFieldValueLCB")
          val tmp_customFieldLct = _resultSet.getLong("customFieldLct")
          val _entity = CustomFieldValue()
          _entity.customFieldValueUid = tmp_customFieldValueUid
          _entity.customFieldValueFieldUid = tmp_customFieldValueFieldUid
          _entity.customFieldValueEntityUid = tmp_customFieldValueEntityUid
          _entity.customFieldValueValue = tmp_customFieldValueValue
          _entity.customFieldValueCustomFieldValueOptionUid =
              tmp_customFieldValueCustomFieldValueOptionUid
          _entity.customFieldValueMCSN = tmp_customFieldValueMCSN
          _entity.customFieldValueLCSN = tmp_customFieldValueLCSN
          _entity.customFieldValueLCB = tmp_customFieldValueLCB
          _entity.customFieldLct = tmp_customFieldLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun insertListAsync(entityList: List<CustomFieldValue>): Unit {
    _insertAdapterCustomFieldValue_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<CustomFieldValue>): Unit {
    val _sql =
        "UPDATE CustomFieldValue SET customFieldValueFieldUid = ?, customFieldValueEntityUid = ?, customFieldValueValue = ?, customFieldValueCustomFieldValueOptionUid = ?, customFieldValueMCSN = ?, customFieldValueLCSN = ?, customFieldValueLCB = ?, customFieldLct = ? WHERE customFieldValueUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.customFieldValueFieldUid)
        _stmt.setLong(2, _entity.customFieldValueEntityUid)
        _stmt.setString(3, _entity.customFieldValueValue)
        _stmt.setLong(4, _entity.customFieldValueCustomFieldValueOptionUid)
        _stmt.setLong(5, _entity.customFieldValueMCSN)
        _stmt.setLong(6, _entity.customFieldValueLCSN)
        _stmt.setInt(7, _entity.customFieldValueLCB)
        _stmt.setLong(8, _entity.customFieldLct)
        _stmt.setLong(9, _entity.customFieldValueUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun insert(entity: CustomFieldValue): Long {
    val _retVal = _insertAdapterCustomFieldValue_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CustomFieldValue): Long {
    val _retVal = _insertAdapterCustomFieldValue_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CustomFieldValue>): Unit {
    _insertAdapterCustomFieldValue_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CustomFieldValue>): Unit {
    val _sql =
        "UPDATE CustomFieldValue SET customFieldValueFieldUid = ?, customFieldValueEntityUid = ?, customFieldValueValue = ?, customFieldValueCustomFieldValueOptionUid = ?, customFieldValueMCSN = ?, customFieldValueLCSN = ?, customFieldValueLCB = ?, customFieldLct = ? WHERE customFieldValueUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.customFieldValueFieldUid)
        _stmt.setLong(2, _entity.customFieldValueEntityUid)
        _stmt.setString(3, _entity.customFieldValueValue)
        _stmt.setLong(4, _entity.customFieldValueCustomFieldValueOptionUid)
        _stmt.setLong(5, _entity.customFieldValueMCSN)
        _stmt.setLong(6, _entity.customFieldValueLCSN)
        _stmt.setInt(7, _entity.customFieldValueLCB)
        _stmt.setLong(8, _entity.customFieldLct)
        _stmt.setLong(9, _entity.customFieldValueUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CustomFieldValue): Unit {
    val _sql =
        "UPDATE CustomFieldValue SET customFieldValueFieldUid = ?, customFieldValueEntityUid = ?, customFieldValueValue = ?, customFieldValueCustomFieldValueOptionUid = ?, customFieldValueMCSN = ?, customFieldValueLCSN = ?, customFieldValueLCB = ?, customFieldLct = ? WHERE customFieldValueUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.customFieldValueFieldUid)
      _stmt.setLong(2, entity.customFieldValueEntityUid)
      _stmt.setString(3, entity.customFieldValueValue)
      _stmt.setLong(4, entity.customFieldValueCustomFieldValueOptionUid)
      _stmt.setLong(5, entity.customFieldValueMCSN)
      _stmt.setLong(6, entity.customFieldValueLCSN)
      _stmt.setInt(7, entity.customFieldValueLCB)
      _stmt.setLong(8, entity.customFieldLct)
      _stmt.setLong(9, entity.customFieldValueUid)
      _stmt.executeUpdate()
    }
  }
}
