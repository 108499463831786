package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentEntryParentChildJoinDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentEntryParentChildJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : ContentEntryParentChildJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entityList: List<ContentEntryParentChildJoin>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override fun publicContentEntryParentChildJoins(): List<ContentEntryParentChildJoin> {
    val _result = _dao.publicContentEntryParentChildJoins()
    return _result
  }

  public override fun all(): List<ContentEntryParentChildJoin> {
    val _result = _dao.all()
    return _result
  }

  public override fun findParentByChildUuids(childEntryContentUid: Long):
      ContentEntryParentChildJoin? {
    val _result = _dao.findParentByChildUuids(childEntryContentUid)
    return _result
  }

  public override fun findListOfParentsByChildUuid(childEntryContentUid: Long):
      List<ContentEntryParentChildJoin> {
    val _result = _dao.findListOfParentsByChildUuid(childEntryContentUid)
    return _result
  }

  public override fun findListOfChildsByParentUuid(parentUid: Long):
      List<ContentEntryParentChildJoin> {
    val _result = _dao.findListOfChildsByParentUuid(parentUid)
    return _result
  }

  public override fun findJoinByParentChildUuids(parentUid: Long, childUid: Long):
      ContentEntryParentChildJoin? {
    val _result = _dao.findJoinByParentChildUuids(parentUid, childUid)
    return _result
  }

  public override suspend fun selectTopEntries(): List<ContentEntry> {
    val _result = _dao.selectTopEntries()
    return _result
  }

  public override fun replaceList(entries: List<ContentEntryParentChildJoin>): Unit {
    _dao.replaceList(entries)
  }

  public override fun insertWithReplace(parentChildJoinDao: ContentEntryParentChildJoin): Unit {
    _dao.insertWithReplace(parentChildJoinDao)
  }

  public override suspend fun moveListOfEntriesToNewParent(
    contentEntryUid: Long,
    selectedItems: List<Long>,
    updateTime: Long
  ): Unit {
    _dao.moveListOfEntriesToNewParent(contentEntryUid, selectedItems, updateTime)
  }

  public override fun insert(entity: ContentEntryParentChildJoin): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ContentEntryParentChildJoin): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out ContentEntryParentChildJoin>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentEntryParentChildJoin>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ContentEntryParentChildJoin): Unit {
    _dao.update(entity)
  }
}
