package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Message
import com.ustadmobile.lib.db.entities.MessageWithPerson
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class MessageDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: MessageDao
) : MessageDao() {
  public override suspend fun replicateOnNewNodeChats(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeChats(newNodeId)
  }

  public override suspend fun replicateOnNewNodePosts(newNodeId: Long): Unit {
    _dao.replicateOnNewNodePosts(newNodeId)
  }

  public override suspend fun replicateOnChangeChat(): Unit {
    _dao.replicateOnChangeChat()
  }

  public override suspend fun replicateOnChangePosts(): Unit {
    _dao.replicateOnChangePosts()
  }

  public override fun findAllMessagesByChatUid(
    entityUid: Long,
    tableId: Int,
    loggedInPersonUid: Long
  ): DoorDataSourceFactory<Int, MessageWithPerson> = _dao.findAllMessagesByChatUid(entityUid,
      tableId, loggedInPersonUid)

  public override fun insert(entity: Message): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Message): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.messageUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(126)
      entity.messageUid = _newPk
    }
    entity.messageLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.messageUid
  }

  public override fun insertList(entityList: List<out Message>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out Message>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Message): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
