package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.lib.db.entities.ScrapeQueueItem
import com.ustadmobile.lib.db.entities.ScrapeQueueItemWithScrapeRun
import com.ustadmobile.lib.db.entities.ScrapeRun
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ScrapeQueueItemDao_JdbcKt(
  public val _db: DoorDatabase
) : ScrapeQueueItemDao() {
  public val _insertAdapterScrapeQueueItem_: EntityInsertionAdapter<ScrapeQueueItem> = object :
      EntityInsertionAdapter<ScrapeQueueItem>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ScrapeQueueItem (sqiUid, sqiContentEntryParentUid, sqiContentEntryUid, destDir, scrapeUrl, status, runId, itemType, errorCode, contentType, timeAdded, timeStarted, timeFinished, priority, overrideEntry) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ScrapeQueueItem (sqiUid, sqiContentEntryParentUid, sqiContentEntryUid, destDir, scrapeUrl, status, runId, itemType, errorCode, contentType, timeAdded, timeStarted, timeFinished, priority, overrideEntry) VALUES(COALESCE(?,nextval('ScrapeQueueItem_sqiUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING sqiUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ScrapeQueueItem):
        Unit {
      if(entity.sqiUid == 0) {
        stmt.setObject(1, null)
      } else {
        stmt.setInt(1, entity.sqiUid)
      }
      stmt.setLong(2, entity.sqiContentEntryParentUid)
      stmt.setLong(3, entity.sqiContentEntryUid)
      stmt.setString(4, entity.destDir)
      stmt.setString(5, entity.scrapeUrl)
      stmt.setInt(6, entity.status)
      stmt.setInt(7, entity.runId)
      stmt.setInt(8, entity.itemType)
      stmt.setInt(9, entity.errorCode)
      stmt.setString(10, entity.contentType)
      stmt.setLong(11, entity.timeAdded)
      stmt.setLong(12, entity.timeStarted)
      stmt.setLong(13, entity.timeFinished)
      stmt.setInt(14, entity.priority)
      stmt.setBoolean(15, entity.overrideEntry)
    }
  }

  public override fun findNextQueueItems(itemType: Int): DoorLiveData<List<ScrapeQueueItem>> {
    val _result = DoorLiveDataImpl<List<ScrapeQueueItem>>(_db, listOf("ScrapeQueueItem"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.ScrapeQueueItem>()
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM ScrapeQueueItem WHERE status = 1 AND itemType = ? ORDER BY priority ASC LIMIT 10"
          , postgreSql = """
      |SELECT * FROM ScrapeQueueItem WHERE status = 1 AND itemType = ? ORDER BY priority ASC LIMIT 10
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setInt(1, itemType)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_sqiUid = _resultSet.getInt("sqiUid")
            val tmp_sqiContentEntryParentUid = _resultSet.getLong("sqiContentEntryParentUid")
            val tmp_sqiContentEntryUid = _resultSet.getLong("sqiContentEntryUid")
            val tmp_destDir = _resultSet.getString("destDir")
            val tmp_scrapeUrl = _resultSet.getString("scrapeUrl")
            val tmp_status = _resultSet.getInt("status")
            val tmp_runId = _resultSet.getInt("runId")
            val tmp_itemType = _resultSet.getInt("itemType")
            val tmp_errorCode = _resultSet.getInt("errorCode")
            val tmp_contentType = _resultSet.getString("contentType")
            val tmp_timeAdded = _resultSet.getLong("timeAdded")
            val tmp_timeStarted = _resultSet.getLong("timeStarted")
            val tmp_timeFinished = _resultSet.getLong("timeFinished")
            val tmp_priority = _resultSet.getInt("priority")
            val tmp_overrideEntry = _resultSet.getBoolean("overrideEntry")
            val _entity = ScrapeQueueItem()
            _entity.sqiUid = tmp_sqiUid
            _entity.sqiContentEntryParentUid = tmp_sqiContentEntryParentUid
            _entity.sqiContentEntryUid = tmp_sqiContentEntryUid
            _entity.destDir = tmp_destDir
            _entity.scrapeUrl = tmp_scrapeUrl
            _entity.status = tmp_status
            _entity.runId = tmp_runId
            _entity.itemType = tmp_itemType
            _entity.errorCode = tmp_errorCode
            _entity.contentType = tmp_contentType
            _entity.timeAdded = tmp_timeAdded
            _entity.timeStarted = tmp_timeStarted
            _entity.timeFinished = tmp_timeFinished
            _entity.priority = tmp_priority
            _entity.overrideEntry = tmp_overrideEntry
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override fun updateSetStatusById(
    uid: Int,
    status: Int,
    errorCode: Int
  ): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ScrapeQueueItem SET status = ?, errorCode = ? WHERE sqiUid = ?"
        , postgreSql = """
    |UPDATE ScrapeQueueItem SET status = ?, errorCode = ? WHERE sqiUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, status)
      _stmt.setInt(2, errorCode)
      _stmt.setInt(3, uid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun getExistingQueueItem(runId: Int, indexUrl: String): ScrapeQueueItem? {
    var _result = null as com.ustadmobile.lib.db.entities.ScrapeQueueItem??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * from ScrapeQueueItem WHERE runId = ? AND scrapeUrl = ? LIMIT 1"
        , postgreSql = """
    |SELECT * from ScrapeQueueItem WHERE runId = ? AND scrapeUrl = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, runId)
      _stmt.setString(2, indexUrl)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_sqiUid = _resultSet.getInt("sqiUid")
          val tmp_sqiContentEntryParentUid = _resultSet.getLong("sqiContentEntryParentUid")
          val tmp_sqiContentEntryUid = _resultSet.getLong("sqiContentEntryUid")
          val tmp_destDir = _resultSet.getString("destDir")
          val tmp_scrapeUrl = _resultSet.getString("scrapeUrl")
          val tmp_status = _resultSet.getInt("status")
          val tmp_runId = _resultSet.getInt("runId")
          val tmp_itemType = _resultSet.getInt("itemType")
          val tmp_errorCode = _resultSet.getInt("errorCode")
          val tmp_contentType = _resultSet.getString("contentType")
          val tmp_timeAdded = _resultSet.getLong("timeAdded")
          val tmp_timeStarted = _resultSet.getLong("timeStarted")
          val tmp_timeFinished = _resultSet.getLong("timeFinished")
          val tmp_priority = _resultSet.getInt("priority")
          val tmp_overrideEntry = _resultSet.getBoolean("overrideEntry")
          val _entity = ScrapeQueueItem()
          _entity.sqiUid = tmp_sqiUid
          _entity.sqiContentEntryParentUid = tmp_sqiContentEntryParentUid
          _entity.sqiContentEntryUid = tmp_sqiContentEntryUid
          _entity.destDir = tmp_destDir
          _entity.scrapeUrl = tmp_scrapeUrl
          _entity.status = tmp_status
          _entity.runId = tmp_runId
          _entity.itemType = tmp_itemType
          _entity.errorCode = tmp_errorCode
          _entity.contentType = tmp_contentType
          _entity.timeAdded = tmp_timeAdded
          _entity.timeStarted = tmp_timeStarted
          _entity.timeFinished = tmp_timeFinished
          _entity.priority = tmp_priority
          _entity.overrideEntry = tmp_overrideEntry
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findExistingQueueItem(runId: Int, entryUid: Long): ScrapeQueueItem? {
    var _result = null as com.ustadmobile.lib.db.entities.ScrapeQueueItem??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * from ScrapeQueueItem WHERE runId = ? AND sqiContentEntryUid = ? LIMIT 1"
        , postgreSql = """
    |SELECT * from ScrapeQueueItem WHERE runId = ? AND sqiContentEntryUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, runId)
      _stmt.setLong(2, entryUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_sqiUid = _resultSet.getInt("sqiUid")
          val tmp_sqiContentEntryParentUid = _resultSet.getLong("sqiContentEntryParentUid")
          val tmp_sqiContentEntryUid = _resultSet.getLong("sqiContentEntryUid")
          val tmp_destDir = _resultSet.getString("destDir")
          val tmp_scrapeUrl = _resultSet.getString("scrapeUrl")
          val tmp_status = _resultSet.getInt("status")
          val tmp_runId = _resultSet.getInt("runId")
          val tmp_itemType = _resultSet.getInt("itemType")
          val tmp_errorCode = _resultSet.getInt("errorCode")
          val tmp_contentType = _resultSet.getString("contentType")
          val tmp_timeAdded = _resultSet.getLong("timeAdded")
          val tmp_timeStarted = _resultSet.getLong("timeStarted")
          val tmp_timeFinished = _resultSet.getLong("timeFinished")
          val tmp_priority = _resultSet.getInt("priority")
          val tmp_overrideEntry = _resultSet.getBoolean("overrideEntry")
          val _entity = ScrapeQueueItem()
          _entity.sqiUid = tmp_sqiUid
          _entity.sqiContentEntryParentUid = tmp_sqiContentEntryParentUid
          _entity.sqiContentEntryUid = tmp_sqiContentEntryUid
          _entity.destDir = tmp_destDir
          _entity.scrapeUrl = tmp_scrapeUrl
          _entity.status = tmp_status
          _entity.runId = tmp_runId
          _entity.itemType = tmp_itemType
          _entity.errorCode = tmp_errorCode
          _entity.contentType = tmp_contentType
          _entity.timeAdded = tmp_timeAdded
          _entity.timeStarted = tmp_timeStarted
          _entity.timeFinished = tmp_timeFinished
          _entity.priority = tmp_priority
          _entity.overrideEntry = tmp_overrideEntry
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun setTimeStarted(uid: Int, timeStarted: Long): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ScrapeQueueItem SET timeStarted = ? WHERE sqiUid = ?" ,
        postgreSql = """
    |UPDATE ScrapeQueueItem SET timeStarted = ? WHERE sqiUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, timeStarted)
      _stmt.setInt(2, uid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun setTimeFinished(uid: Int, timeFinished: Long): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ScrapeQueueItem SET timeFinished = ? WHERE sqiUid = ?" ,
        postgreSql = """
    |UPDATE ScrapeQueueItem SET timeFinished = ? WHERE sqiUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, timeFinished)
      _stmt.setInt(2, uid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun findByUid(sqiUid: Int): ScrapeQueueItemWithScrapeRun? {
    var _result = null as com.ustadmobile.lib.db.entities.ScrapeQueueItemWithScrapeRun??
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT ScrapeQueueItem.*, ScrapeRun.* FROM ScrapeQueueItem 
    |                    LEFT JOIN ScrapeRun ON  ScrapeQueueItem.runId = ScrapeRun.scrapeRunUid
    |                    WHERE ScrapeQueueItem.sqiUid = ?
    """.trimMargin() , postgreSql = """
    |SELECT ScrapeQueueItem.*, ScrapeRun.* FROM ScrapeQueueItem 
    |                    LEFT JOIN ScrapeRun ON  ScrapeQueueItem.runId = ScrapeRun.scrapeRunUid
    |                    WHERE ScrapeQueueItem.sqiUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, sqiUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_sqiUid = _resultSet.getInt("sqiUid")
          val tmp_sqiContentEntryParentUid = _resultSet.getLong("sqiContentEntryParentUid")
          val tmp_sqiContentEntryUid = _resultSet.getLong("sqiContentEntryUid")
          val tmp_destDir = _resultSet.getString("destDir")
          val tmp_scrapeUrl = _resultSet.getString("scrapeUrl")
          val tmp_status = _resultSet.getInt("status")
          val tmp_runId = _resultSet.getInt("runId")
          val tmp_itemType = _resultSet.getInt("itemType")
          val tmp_errorCode = _resultSet.getInt("errorCode")
          val tmp_contentType = _resultSet.getString("contentType")
          val tmp_timeAdded = _resultSet.getLong("timeAdded")
          val tmp_timeStarted = _resultSet.getLong("timeStarted")
          val tmp_timeFinished = _resultSet.getLong("timeFinished")
          val tmp_priority = _resultSet.getInt("priority")
          val tmp_overrideEntry = _resultSet.getBoolean("overrideEntry")
          val _entity = ScrapeQueueItemWithScrapeRun()
          _entity.sqiUid = tmp_sqiUid
          _entity.sqiContentEntryParentUid = tmp_sqiContentEntryParentUid
          _entity.sqiContentEntryUid = tmp_sqiContentEntryUid
          _entity.destDir = tmp_destDir
          _entity.scrapeUrl = tmp_scrapeUrl
          _entity.status = tmp_status
          _entity.runId = tmp_runId
          _entity.itemType = tmp_itemType
          _entity.errorCode = tmp_errorCode
          _entity.contentType = tmp_contentType
          _entity.timeAdded = tmp_timeAdded
          _entity.timeStarted = tmp_timeStarted
          _entity.timeFinished = tmp_timeFinished
          _entity.priority = tmp_priority
          _entity.overrideEntry = tmp_overrideEntry
          var _scrapeRun_nullFieldCount = 0
          val tmp_scrapeRunUid = _resultSet.getInt("scrapeRunUid")
          if(_resultSet.wasNull()) { _scrapeRun_nullFieldCount++ }
          val tmp_scrapeType = _resultSet.getString("scrapeType")
          if(_resultSet.wasNull()) { _scrapeRun_nullFieldCount++ }
          val tmp_scrapeRunStatus = _resultSet.getInt("scrapeRunStatus")
          if(_resultSet.wasNull()) { _scrapeRun_nullFieldCount++ }
          val tmp_conversionParams = _resultSet.getString("conversionParams")
          if(_resultSet.wasNull()) { _scrapeRun_nullFieldCount++ }
          if(_scrapeRun_nullFieldCount < 4) {
            if(_entity.scrapeRun == null) {
              _entity.scrapeRun = ScrapeRun()
            }
            _entity.scrapeRun!!.scrapeRunUid = tmp_scrapeRunUid
            _entity.scrapeRun!!.scrapeType = tmp_scrapeType
            _entity.scrapeRun!!.scrapeRunStatus = tmp_scrapeRunStatus
            _entity.scrapeRun!!.conversionParams = tmp_conversionParams
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ScrapeQueueItem): Long {
    val _retVal = _insertAdapterScrapeQueueItem_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ScrapeQueueItem): Long {
    val _retVal = _insertAdapterScrapeQueueItem_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ScrapeQueueItem>): Unit {
    _insertAdapterScrapeQueueItem_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ScrapeQueueItem>): Unit {
    val _sql =
        "UPDATE ScrapeQueueItem SET sqiContentEntryParentUid = ?, sqiContentEntryUid = ?, destDir = ?, scrapeUrl = ?, status = ?, runId = ?, itemType = ?, errorCode = ?, contentType = ?, timeAdded = ?, timeStarted = ?, timeFinished = ?, priority = ?, overrideEntry = ? WHERE sqiUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.sqiContentEntryParentUid)
        _stmt.setLong(2, _entity.sqiContentEntryUid)
        _stmt.setString(3, _entity.destDir)
        _stmt.setString(4, _entity.scrapeUrl)
        _stmt.setInt(5, _entity.status)
        _stmt.setInt(6, _entity.runId)
        _stmt.setInt(7, _entity.itemType)
        _stmt.setInt(8, _entity.errorCode)
        _stmt.setString(9, _entity.contentType)
        _stmt.setLong(10, _entity.timeAdded)
        _stmt.setLong(11, _entity.timeStarted)
        _stmt.setLong(12, _entity.timeFinished)
        _stmt.setInt(13, _entity.priority)
        _stmt.setBoolean(14, _entity.overrideEntry)
        _stmt.setInt(15, _entity.sqiUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ScrapeQueueItem): Unit {
    val _sql =
        "UPDATE ScrapeQueueItem SET sqiContentEntryParentUid = ?, sqiContentEntryUid = ?, destDir = ?, scrapeUrl = ?, status = ?, runId = ?, itemType = ?, errorCode = ?, contentType = ?, timeAdded = ?, timeStarted = ?, timeFinished = ?, priority = ?, overrideEntry = ? WHERE sqiUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.sqiContentEntryParentUid)
      _stmt.setLong(2, entity.sqiContentEntryUid)
      _stmt.setString(3, entity.destDir)
      _stmt.setString(4, entity.scrapeUrl)
      _stmt.setInt(5, entity.status)
      _stmt.setInt(6, entity.runId)
      _stmt.setInt(7, entity.itemType)
      _stmt.setInt(8, entity.errorCode)
      _stmt.setString(9, entity.contentType)
      _stmt.setLong(10, entity.timeAdded)
      _stmt.setLong(11, entity.timeStarted)
      _stmt.setLong(12, entity.timeFinished)
      _stmt.setInt(13, entity.priority)
      _stmt.setBoolean(14, entity.overrideEntry)
      _stmt.setInt(15, entity.sqiUid)
      _stmt.executeUpdate()
    }
  }
}
