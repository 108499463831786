package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.attachments.storeAttachment
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CoursePicture
import com.ustadmobile.lib.db.entities.asEntityWithAttachment
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CoursePictureDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: CoursePictureDao
) : CoursePictureDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByClazzUidAsync(clazzUid: Long): CoursePicture? =
      _dao.findByClazzUidAsync(clazzUid)

  public override fun findByClazzUidLive(clazzUid: Long): DoorLiveData<CoursePicture?> =
      _dao.findByClazzUidLive(clazzUid)

  public override suspend fun updateAsync(coursePicture: CoursePicture): Unit {
    _db.storeAttachment(coursePicture.asEntityWithAttachment())
    coursePicture.coursePictureLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(coursePicture)
  }

  public override fun insert(entity: CoursePicture): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CoursePicture): Long {
    _db.storeAttachment(entity.asEntityWithAttachment())
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.coursePictureUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(125)
      entity.coursePictureUid = _newPk
    }
    entity.coursePictureLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.coursePictureUid
  }

  public override fun insertList(entityList: List<out CoursePicture>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out CoursePicture>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CoursePicture): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
