package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CourseGroupSet
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseGroupSetDao_JdbcKt(
  public val _db: DoorDatabase
) : CourseGroupSetDao() {
  public val _insertAdapterCourseGroupSet_: EntityInsertionAdapter<CourseGroupSet> = object :
      EntityInsertionAdapter<CourseGroupSet>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CourseGroupSet (cgsUid, cgsName, cgsTotalGroups, cgsActive, cgsClazzUid, cgsLct) VALUES(?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseGroupSet (cgsUid, cgsName, cgsTotalGroups, cgsActive, cgsClazzUid, cgsLct) VALUES(COALESCE(?,nextval('CourseGroupSet_cgsUid_seq')), ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cgsUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseGroupSet):
        Unit {
      if(entity.cgsUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cgsUid)
      }
      stmt.setString(2, entity.cgsName)
      stmt.setInt(3, entity.cgsTotalGroups)
      stmt.setBoolean(4, entity.cgsActive)
      stmt.setLong(5, entity.cgsClazzUid)
      stmt.setLong(6, entity.cgsLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseGroupSetReplicate(cgsPk, cgsDestination)
    |      SELECT DISTINCT CourseGroupSet.cgsUid AS cgsUid,
    |             ? AS cgsDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN CourseGroupSet
    |                    ON CourseGroupSet.cgsClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseGroupSet.cgsLct != COALESCE(
    |             (SELECT cgsVersionId
    |                FROM CourseGroupSetReplicate
    |               WHERE cgsPk = CourseGroupSet.cgsUid
    |                 AND cgsDestination = ?), 0) 
    |      /*psql ON CONFLICT(cgsPk, cgsDestination) DO UPDATE
    |             SET cgsPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseGroupSetReplicate(cgsPk, cgsDestination)
    |      SELECT DISTINCT CourseGroupSet.cgsUid AS cgsUid,
    |             ? AS cgsDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN CourseGroupSet
    |                    ON CourseGroupSet.cgsClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseGroupSet.cgsLct != COALESCE(
    |             (SELECT cgsVersionId
    |                FROM CourseGroupSetReplicate
    |               WHERE cgsPk = CourseGroupSet.cgsUid
    |                 AND cgsDestination = ?), 0) 
    |       ON CONFLICT(cgsPk, cgsDestination) DO UPDATE
    |             SET cgsPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO CourseGroupSetReplicate(cgsPk, cgsDestination)
    |  SELECT DISTINCT CourseGroupSet.cgsUid AS cgsUid,
    |         UserSession.usClientNodeId AS cgsDestination
    |    FROM ChangeLog
    |         JOIN CourseGroupSet
    |             ON ChangeLog.chTableId = 242
    |                AND ChangeLog.chEntityPk = CourseGroupSet.cgsUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = CourseGroupSet.cgsClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseGroupSet.cgsLct != COALESCE(
    |         (SELECT cgsVersionId
    |            FROM CourseGroupSetReplicate
    |           WHERE cgsPk = CourseGroupSet.cgsUid
    |             AND cgsDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cgsPk, cgsDestination) DO UPDATE
    |     SET cgsPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseGroupSetReplicate(cgsPk, cgsDestination)
    |  SELECT DISTINCT CourseGroupSet.cgsUid AS cgsUid,
    |         UserSession.usClientNodeId AS cgsDestination
    |    FROM ChangeLog
    |         JOIN CourseGroupSet
    |             ON ChangeLog.chTableId = 242
    |                AND ChangeLog.chEntityPk = CourseGroupSet.cgsUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = CourseGroupSet.cgsClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseGroupSet.cgsLct != COALESCE(
    |         (SELECT cgsVersionId
    |            FROM CourseGroupSetReplicate
    |           WHERE cgsPk = CourseGroupSet.cgsUid
    |             AND cgsDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cgsPk, cgsDestination) DO UPDATE
    |     SET cgsPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateAsync(entity: CourseGroupSet): Int {
    var _result = 0
    val _sql =
        "UPDATE CourseGroupSet SET cgsName = ?, cgsTotalGroups = ?, cgsActive = ?, cgsClazzUid = ?, cgsLct = ? WHERE cgsUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.cgsName)
      _stmt.setInt(2, entity.cgsTotalGroups)
      _stmt.setBoolean(3, entity.cgsActive)
      _stmt.setLong(4, entity.cgsClazzUid)
      _stmt.setLong(5, entity.cgsLct)
      _stmt.setLong(6, entity.cgsUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun findAllCourseGroupSetForClazz(clazzUid: Long): DoorDataSourceFactory<Int,
      CourseGroupSet> {
    val _result = object : DoorDataSourceFactory<Int, CourseGroupSet>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<CourseGroupSet>> =
          DoorLiveDataImpl<List<CourseGroupSet>>(_db, listOf("CourseGroupSet"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.CourseGroupSet>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT *
        |         FROM CourseGroupSet
        |        WHERE cgsActive
        |          AND cgsClazzUid = ?
        |     ORDER BY cgsName   
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT *
        |         FROM CourseGroupSet
        |        WHERE cgsActive
        |          AND cgsClazzUid = ?
        |     ORDER BY cgsName   
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_cgsUid = _resultSet.getLong("cgsUid")
              val tmp_cgsName = _resultSet.getString("cgsName")
              val tmp_cgsTotalGroups = _resultSet.getInt("cgsTotalGroups")
              val tmp_cgsActive = _resultSet.getBoolean("cgsActive")
              val tmp_cgsClazzUid = _resultSet.getLong("cgsClazzUid")
              val tmp_cgsLct = _resultSet.getLong("cgsLct")
              val _entity = CourseGroupSet()
              _entity.cgsUid = tmp_cgsUid
              _entity.cgsName = tmp_cgsName
              _entity.cgsTotalGroups = tmp_cgsTotalGroups
              _entity.cgsActive = tmp_cgsActive
              _entity.cgsClazzUid = tmp_cgsClazzUid
              _entity.cgsLct = tmp_cgsLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("CourseGroupSet"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT *
        |         FROM CourseGroupSet
        |        WHERE cgsActive
        |          AND cgsClazzUid = ?
        |     ORDER BY cgsName   
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT *
        |         FROM CourseGroupSet
        |        WHERE cgsActive
        |          AND cgsClazzUid = ?
        |     ORDER BY cgsName   
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findAllCourseGroupSetForClazzList(clazzUid: Long): List<CourseGroupSet> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.CourseGroupSet>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT *
    |         FROM CourseGroupSet
    |        WHERE cgsActive
    |          AND cgsClazzUid = ?
    |     ORDER BY cgsName   
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT *
    |         FROM CourseGroupSet
    |        WHERE cgsActive
    |          AND cgsClazzUid = ?
    |     ORDER BY cgsName   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cgsUid = _resultSet.getLong("cgsUid")
          val tmp_cgsName = _resultSet.getString("cgsName")
          val tmp_cgsTotalGroups = _resultSet.getInt("cgsTotalGroups")
          val tmp_cgsActive = _resultSet.getBoolean("cgsActive")
          val tmp_cgsClazzUid = _resultSet.getLong("cgsClazzUid")
          val tmp_cgsLct = _resultSet.getLong("cgsLct")
          val _entity = CourseGroupSet()
          _entity.cgsUid = tmp_cgsUid
          _entity.cgsName = tmp_cgsName
          _entity.cgsTotalGroups = tmp_cgsTotalGroups
          _entity.cgsActive = tmp_cgsActive
          _entity.cgsClazzUid = tmp_cgsClazzUid
          _entity.cgsLct = tmp_cgsLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): CourseGroupSet? {
    var _result = null as com.ustadmobile.lib.db.entities.CourseGroupSet??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |         FROM CourseGroupSet 
    |        WHERE cgsUid = ?
    |        
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |         FROM CourseGroupSet 
    |        WHERE cgsUid = ?
    |        
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cgsUid = _resultSet.getLong("cgsUid")
          val tmp_cgsName = _resultSet.getString("cgsName")
          val tmp_cgsTotalGroups = _resultSet.getInt("cgsTotalGroups")
          val tmp_cgsActive = _resultSet.getBoolean("cgsActive")
          val tmp_cgsClazzUid = _resultSet.getLong("cgsClazzUid")
          val tmp_cgsLct = _resultSet.getLong("cgsLct")
          val _entity = CourseGroupSet()
          _entity.cgsUid = tmp_cgsUid
          _entity.cgsName = tmp_cgsName
          _entity.cgsTotalGroups = tmp_cgsTotalGroups
          _entity.cgsActive = tmp_cgsActive
          _entity.cgsClazzUid = tmp_cgsClazzUid
          _entity.cgsLct = tmp_cgsLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: CourseGroupSet): Long {
    val _retVal = _insertAdapterCourseGroupSet_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseGroupSet): Long {
    val _retVal = _insertAdapterCourseGroupSet_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CourseGroupSet>): Unit {
    _insertAdapterCourseGroupSet_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseGroupSet>): Unit {
    val _sql =
        "UPDATE CourseGroupSet SET cgsName = ?, cgsTotalGroups = ?, cgsActive = ?, cgsClazzUid = ?, cgsLct = ? WHERE cgsUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.cgsName)
        _stmt.setInt(2, _entity.cgsTotalGroups)
        _stmt.setBoolean(3, _entity.cgsActive)
        _stmt.setLong(4, _entity.cgsClazzUid)
        _stmt.setLong(5, _entity.cgsLct)
        _stmt.setLong(6, _entity.cgsUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseGroupSet): Unit {
    val _sql =
        "UPDATE CourseGroupSet SET cgsName = ?, cgsTotalGroups = ?, cgsActive = ?, cgsClazzUid = ?, cgsLct = ? WHERE cgsUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.cgsName)
      _stmt.setInt(2, entity.cgsTotalGroups)
      _stmt.setBoolean(3, entity.cgsActive)
      _stmt.setLong(4, entity.cgsClazzUid)
      _stmt.setLong(5, entity.cgsLct)
      _stmt.setLong(6, entity.cgsUid)
      _stmt.executeUpdate()
    }
  }
}
