package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord
import com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzLogAttendanceRecordDao_JdbcKt(
  public val _db: DoorDatabase
) : ClazzLogAttendanceRecordDao() {
  public val _insertAdapterClazzLogAttendanceRecord_:
      EntityInsertionAdapter<ClazzLogAttendanceRecord> = object :
      EntityInsertionAdapter<ClazzLogAttendanceRecord>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ClazzLogAttendanceRecord (clazzLogAttendanceRecordUid, clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ClazzLogAttendanceRecord (clazzLogAttendanceRecordUid, clazzLogAttendanceRecordClazzLogUid, clazzLogAttendanceRecordPersonUid, attendanceStatus, clazzLogAttendanceRecordMasterChangeSeqNum, clazzLogAttendanceRecordLocalChangeSeqNum, clazzLogAttendanceRecordLastChangedBy, clazzLogAttendanceRecordLastChangedTime) VALUES(COALESCE(?,nextval('ClazzLogAttendanceRecord_clazzLogAttendanceRecordUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING clazzLogAttendanceRecordUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ClazzLogAttendanceRecord): Unit {
      if(entity.clazzLogAttendanceRecordUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzLogAttendanceRecordUid)
      }
      stmt.setLong(2, entity.clazzLogAttendanceRecordClazzLogUid)
      stmt.setLong(3, entity.clazzLogAttendanceRecordPersonUid)
      stmt.setInt(4, entity.attendanceStatus)
      stmt.setLong(5, entity.clazzLogAttendanceRecordMasterChangeSeqNum)
      stmt.setLong(6, entity.clazzLogAttendanceRecordLocalChangeSeqNum)
      stmt.setInt(7, entity.clazzLogAttendanceRecordLastChangedBy)
      stmt.setLong(8, entity.clazzLogAttendanceRecordLastChangedTime)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzLogAttendanceRecordReplicate(clarPk, clarDestination)
    |      SELECT DISTINCT ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid AS clarUid,
    |             ? AS clarDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2048 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzLog
    |                  ON ClazzLog.clazzLogClazzUid = Clazz.clazzUid
    |             JOIN ClazzLogAttendanceRecord 
    |                  ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid
    |       WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordLastChangedTime != COALESCE(
    |             (SELECT clarVersionId
    |                FROM ClazzLogAttendanceRecordReplicate
    |               WHERE clarPk = ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid
    |                 AND clarDestination = ?), 0) 
    |      /*psql ON CONFLICT(clarPk, clarDestination) DO UPDATE
    |             SET clarPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzLogAttendanceRecordReplicate(clarPk, clarDestination)
    |      SELECT DISTINCT ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid AS clarUid,
    |             ? AS clarDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2048 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzLog
    |                  ON ClazzLog.clazzLogClazzUid = Clazz.clazzUid
    |             JOIN ClazzLogAttendanceRecord 
    |                  ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid = ClazzLog.clazzLogUid
    |       WHERE ClazzLogAttendanceRecord.clazzLogAttendanceRecordLastChangedTime != COALESCE(
    |             (SELECT clarVersionId
    |                FROM ClazzLogAttendanceRecordReplicate
    |               WHERE clarPk = ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid
    |                 AND clarDestination = ?), 0) 
    |       ON CONFLICT(clarPk, clarDestination) DO UPDATE
    |             SET clarPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzLogAttendanceRecordReplicate(clarPk, clarDestination)
    |  SELECT DISTINCT ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid AS clarUid,
    |         UserSession.usClientNodeId AS clarDestination
    |    FROM ChangeLog
    |         JOIN ClazzLogAttendanceRecord 
    |              ON ChangeLog.chTableId = 15 
    |             AND ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid = ChangeLog.chEntityPk
    |         JOIN ClazzLog
    |              ON ClazzLog.clazzLogUid = ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzLog.clazzLogClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzLogAttendanceRecord.clazzLogAttendanceRecordLastChangedTime != COALESCE(
    |             (SELECT clarVersionId
    |                FROM ClazzLogAttendanceRecordReplicate
    |               WHERE clarPk = ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid
    |                 AND clarDestination = UserSession.usClientNodeId), 0) 
    | /*psql ON CONFLICT(clarPk, clarDestination) DO UPDATE
    |     SET clarPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzLogAttendanceRecordReplicate(clarPk, clarDestination)
    |  SELECT DISTINCT ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid AS clarUid,
    |         UserSession.usClientNodeId AS clarDestination
    |    FROM ChangeLog
    |         JOIN ClazzLogAttendanceRecord 
    |              ON ChangeLog.chTableId = 15 
    |             AND ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid = ChangeLog.chEntityPk
    |         JOIN ClazzLog
    |              ON ClazzLog.clazzLogUid = ClazzLogAttendanceRecord.clazzLogAttendanceRecordClazzLogUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzLog.clazzLogClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzLogAttendanceRecord.clazzLogAttendanceRecordLastChangedTime != COALESCE(
    |             (SELECT clarVersionId
    |                FROM ClazzLogAttendanceRecordReplicate
    |               WHERE clarPk = ClazzLogAttendanceRecord.clazzLogAttendanceRecordUid
    |                 AND clarDestination = UserSession.usClientNodeId), 0) 
    |  ON CONFLICT(clarPk, clarDestination) DO UPDATE
    |     SET clarPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(entities: List<out ClazzLogAttendanceRecord>): Unit {
    _insertAdapterClazzLogAttendanceRecord_.insertListAsync(entities)
  }

  public override fun findByUid(uid: Long): ClazzLogAttendanceRecord? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecord??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * from ClazzLogAttendanceRecord WHERE clazzLogAttendanceRecordUid = ?"
        , postgreSql = """
    |SELECT * from ClazzLogAttendanceRecord WHERE clazzLogAttendanceRecordUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzLogAttendanceRecordUid = _resultSet.getLong("clazzLogAttendanceRecordUid")
          val tmp_clazzLogAttendanceRecordClazzLogUid =
              _resultSet.getLong("clazzLogAttendanceRecordClazzLogUid")
          val tmp_clazzLogAttendanceRecordPersonUid =
              _resultSet.getLong("clazzLogAttendanceRecordPersonUid")
          val tmp_attendanceStatus = _resultSet.getInt("attendanceStatus")
          val tmp_clazzLogAttendanceRecordMasterChangeSeqNum =
              _resultSet.getLong("clazzLogAttendanceRecordMasterChangeSeqNum")
          val tmp_clazzLogAttendanceRecordLocalChangeSeqNum =
              _resultSet.getLong("clazzLogAttendanceRecordLocalChangeSeqNum")
          val tmp_clazzLogAttendanceRecordLastChangedBy =
              _resultSet.getInt("clazzLogAttendanceRecordLastChangedBy")
          val tmp_clazzLogAttendanceRecordLastChangedTime =
              _resultSet.getLong("clazzLogAttendanceRecordLastChangedTime")
          val _entity = ClazzLogAttendanceRecord()
          _entity.clazzLogAttendanceRecordUid = tmp_clazzLogAttendanceRecordUid
          _entity.clazzLogAttendanceRecordClazzLogUid = tmp_clazzLogAttendanceRecordClazzLogUid
          _entity.clazzLogAttendanceRecordPersonUid = tmp_clazzLogAttendanceRecordPersonUid
          _entity.attendanceStatus = tmp_attendanceStatus
          _entity.clazzLogAttendanceRecordMasterChangeSeqNum =
              tmp_clazzLogAttendanceRecordMasterChangeSeqNum
          _entity.clazzLogAttendanceRecordLocalChangeSeqNum =
              tmp_clazzLogAttendanceRecordLocalChangeSeqNum
          _entity.clazzLogAttendanceRecordLastChangedBy = tmp_clazzLogAttendanceRecordLastChangedBy
          _entity.clazzLogAttendanceRecordLastChangedTime =
              tmp_clazzLogAttendanceRecordLastChangedTime
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateListAsync(entities: List<out ClazzLogAttendanceRecord>): Unit {
    val _sql =
        "UPDATE ClazzLogAttendanceRecord SET clazzLogAttendanceRecordClazzLogUid = ?, clazzLogAttendanceRecordPersonUid = ?, attendanceStatus = ?, clazzLogAttendanceRecordMasterChangeSeqNum = ?, clazzLogAttendanceRecordLocalChangeSeqNum = ?, clazzLogAttendanceRecordLastChangedBy = ?, clazzLogAttendanceRecordLastChangedTime = ? WHERE clazzLogAttendanceRecordUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entities) {
        _stmt.setLong(1, _entity.clazzLogAttendanceRecordClazzLogUid)
        _stmt.setLong(2, _entity.clazzLogAttendanceRecordPersonUid)
        _stmt.setInt(3, _entity.attendanceStatus)
        _stmt.setLong(4, _entity.clazzLogAttendanceRecordMasterChangeSeqNum)
        _stmt.setLong(5, _entity.clazzLogAttendanceRecordLocalChangeSeqNum)
        _stmt.setInt(6, _entity.clazzLogAttendanceRecordLastChangedBy)
        _stmt.setLong(7, _entity.clazzLogAttendanceRecordLastChangedTime)
        _stmt.setLong(8, _entity.clazzLogAttendanceRecordUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override suspend fun findByClazzLogUid(clazzLogUid: Long):
      List<ClazzLogAttendanceRecordWithPerson> {
    var _result =
        mutableListOf<com.ustadmobile.lib.db.entities.ClazzLogAttendanceRecordWithPerson>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT ClazzLogAttendanceRecord.*, Person.*
    |         FROM ClazzLogAttendanceRecord 
    |         LEFT JOIN Person ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid
    |         WHERE clazzLogAttendanceRecordClazzLogUid = ?
    """.trimMargin() , postgreSql = """
    |SELECT ClazzLogAttendanceRecord.*, Person.*
    |         FROM ClazzLogAttendanceRecord 
    |         LEFT JOIN Person ON ClazzLogAttendanceRecord.clazzLogAttendanceRecordPersonUid = Person.personUid
    |         WHERE clazzLogAttendanceRecordClazzLogUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzLogUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzLogAttendanceRecordUid = _resultSet.getLong("clazzLogAttendanceRecordUid")
          val tmp_clazzLogAttendanceRecordClazzLogUid =
              _resultSet.getLong("clazzLogAttendanceRecordClazzLogUid")
          val tmp_clazzLogAttendanceRecordPersonUid =
              _resultSet.getLong("clazzLogAttendanceRecordPersonUid")
          val tmp_attendanceStatus = _resultSet.getInt("attendanceStatus")
          val tmp_clazzLogAttendanceRecordMasterChangeSeqNum =
              _resultSet.getLong("clazzLogAttendanceRecordMasterChangeSeqNum")
          val tmp_clazzLogAttendanceRecordLocalChangeSeqNum =
              _resultSet.getLong("clazzLogAttendanceRecordLocalChangeSeqNum")
          val tmp_clazzLogAttendanceRecordLastChangedBy =
              _resultSet.getInt("clazzLogAttendanceRecordLastChangedBy")
          val tmp_clazzLogAttendanceRecordLastChangedTime =
              _resultSet.getLong("clazzLogAttendanceRecordLastChangedTime")
          val _entity = ClazzLogAttendanceRecordWithPerson()
          _entity.clazzLogAttendanceRecordUid = tmp_clazzLogAttendanceRecordUid
          _entity.clazzLogAttendanceRecordClazzLogUid = tmp_clazzLogAttendanceRecordClazzLogUid
          _entity.clazzLogAttendanceRecordPersonUid = tmp_clazzLogAttendanceRecordPersonUid
          _entity.attendanceStatus = tmp_attendanceStatus
          _entity.clazzLogAttendanceRecordMasterChangeSeqNum =
              tmp_clazzLogAttendanceRecordMasterChangeSeqNum
          _entity.clazzLogAttendanceRecordLocalChangeSeqNum =
              tmp_clazzLogAttendanceRecordLocalChangeSeqNum
          _entity.clazzLogAttendanceRecordLastChangedBy = tmp_clazzLogAttendanceRecordLastChangedBy
          _entity.clazzLogAttendanceRecordLastChangedTime =
              tmp_clazzLogAttendanceRecordLastChangedTime
          var _person_nullFieldCount = 0
          val tmp_personUid = _resultSet.getLong("personUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_username = _resultSet.getString("username")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_firstNames = _resultSet.getString("firstNames")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_lastName = _resultSet.getString("lastName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_gender = _resultSet.getInt("gender")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_active = _resultSet.getBoolean("active")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_admin = _resultSet.getBoolean("admin")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personNotes = _resultSet.getString("personNotes")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherName = _resultSet.getString("fatherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherName = _resultSet.getString("motherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherNum = _resultSet.getString("motherNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personAddress = _resultSet.getString("personAddress")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLct = _resultSet.getLong("personLct")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personCountry = _resultSet.getString("personCountry")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personType = _resultSet.getInt("personType")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          if(_person_nullFieldCount < 24) {
            if(_entity.person == null) {
              _entity.person = Person()
            }
            _entity.person!!.personUid = tmp_personUid
            _entity.person!!.username = tmp_username
            _entity.person!!.firstNames = tmp_firstNames
            _entity.person!!.lastName = tmp_lastName
            _entity.person!!.emailAddr = tmp_emailAddr
            _entity.person!!.phoneNum = tmp_phoneNum
            _entity.person!!.gender = tmp_gender
            _entity.person!!.active = tmp_active
            _entity.person!!.admin = tmp_admin
            _entity.person!!.personNotes = tmp_personNotes
            _entity.person!!.fatherName = tmp_fatherName
            _entity.person!!.fatherNumber = tmp_fatherNumber
            _entity.person!!.motherName = tmp_motherName
            _entity.person!!.motherNum = tmp_motherNum
            _entity.person!!.dateOfBirth = tmp_dateOfBirth
            _entity.person!!.personAddress = tmp_personAddress
            _entity.person!!.personOrgId = tmp_personOrgId
            _entity.person!!.personGroupUid = tmp_personGroupUid
            _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
            _entity.person!!.personLct = tmp_personLct
            _entity.person!!.personCountry = tmp_personCountry
            _entity.person!!.personType = tmp_personType
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun updateRescheduledClazzLogUids(
    oldClazzLogUid: Long,
    newClazzLogUid: Long,
    changedTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ClazzLogAttendanceRecord
    |           SET clazzLogAttendanceRecordClazzLogUid = ?,
    |               clazzLogAttendanceRecordLastChangedTime = ?
    |        WHERE clazzLogAttendanceRecordClazzLogUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ClazzLogAttendanceRecord
    |           SET clazzLogAttendanceRecordClazzLogUid = ?,
    |               clazzLogAttendanceRecordLastChangedTime = ?
    |        WHERE clazzLogAttendanceRecordClazzLogUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newClazzLogUid)
      _stmt.setLong(2, changedTime)
      _stmt.setLong(3, oldClazzLogUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun insert(entity: ClazzLogAttendanceRecord): Long {
    val _retVal = _insertAdapterClazzLogAttendanceRecord_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzLogAttendanceRecord): Long {
    val _retVal = _insertAdapterClazzLogAttendanceRecord_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ClazzLogAttendanceRecord>): Unit {
    _insertAdapterClazzLogAttendanceRecord_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzLogAttendanceRecord>): Unit {
    val _sql =
        "UPDATE ClazzLogAttendanceRecord SET clazzLogAttendanceRecordClazzLogUid = ?, clazzLogAttendanceRecordPersonUid = ?, attendanceStatus = ?, clazzLogAttendanceRecordMasterChangeSeqNum = ?, clazzLogAttendanceRecordLocalChangeSeqNum = ?, clazzLogAttendanceRecordLastChangedBy = ?, clazzLogAttendanceRecordLastChangedTime = ? WHERE clazzLogAttendanceRecordUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.clazzLogAttendanceRecordClazzLogUid)
        _stmt.setLong(2, _entity.clazzLogAttendanceRecordPersonUid)
        _stmt.setInt(3, _entity.attendanceStatus)
        _stmt.setLong(4, _entity.clazzLogAttendanceRecordMasterChangeSeqNum)
        _stmt.setLong(5, _entity.clazzLogAttendanceRecordLocalChangeSeqNum)
        _stmt.setInt(6, _entity.clazzLogAttendanceRecordLastChangedBy)
        _stmt.setLong(7, _entity.clazzLogAttendanceRecordLastChangedTime)
        _stmt.setLong(8, _entity.clazzLogAttendanceRecordUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzLogAttendanceRecord): Unit {
    val _sql =
        "UPDATE ClazzLogAttendanceRecord SET clazzLogAttendanceRecordClazzLogUid = ?, clazzLogAttendanceRecordPersonUid = ?, attendanceStatus = ?, clazzLogAttendanceRecordMasterChangeSeqNum = ?, clazzLogAttendanceRecordLocalChangeSeqNum = ?, clazzLogAttendanceRecordLastChangedBy = ?, clazzLogAttendanceRecordLastChangedTime = ? WHERE clazzLogAttendanceRecordUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzLogAttendanceRecordClazzLogUid)
      _stmt.setLong(2, entity.clazzLogAttendanceRecordPersonUid)
      _stmt.setInt(3, entity.attendanceStatus)
      _stmt.setLong(4, entity.clazzLogAttendanceRecordMasterChangeSeqNum)
      _stmt.setLong(5, entity.clazzLogAttendanceRecordLocalChangeSeqNum)
      _stmt.setInt(6, entity.clazzLogAttendanceRecordLastChangedBy)
      _stmt.setLong(7, entity.clazzLogAttendanceRecordLastChangedTime)
      _stmt.setLong(8, entity.clazzLogAttendanceRecordUid)
      _stmt.executeUpdate()
    }
  }
}
