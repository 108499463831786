package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.columnIndexMap
import com.ustadmobile.door.ext.copy
import com.ustadmobile.door.ext.copyWithExtraParams
import com.ustadmobile.door.ext.hasListOrArrayParams
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonWithAttemptsSummary
import com.ustadmobile.lib.db.entities.PersonWithSessionsDisplay
import com.ustadmobile.lib.db.entities.StatementEntity
import com.ustadmobile.lib.db.entities.StatementEntityWithDisplayDetails
import com.ustadmobile.lib.db.entities.StatementWithSessionDetailDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.Boolean
import kotlin.Byte
import kotlin.Float
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class StatementDao_JdbcKt(
  public val _db: DoorDatabase
) : StatementDao() {
  public val _insertAdapterStatementEntity_: EntityInsertionAdapter<StatementEntity> = object :
      EntityInsertionAdapter<StatementEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO StatementEntity (statementUid, statementId, statementPersonUid, statementVerbUid, xObjectUid, subStatementActorUid, substatementVerbUid, subStatementObjectUid, agentUid, instructorUid, authorityUid, teamUid, resultCompletion, resultSuccess, resultScoreScaled, resultScoreRaw, resultScoreMin, resultScoreMax, resultDuration, resultResponse, timestamp, stored, contextRegistration, contextPlatform, contextStatementId, fullStatement, statementMasterChangeSeqNum, statementLocalChangeSeqNum, statementLastChangedBy, statementLct, extensionProgress, contentEntryRoot, statementContentEntryUid, statementLearnerGroupUid, statementClazzUid) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO StatementEntity (statementUid, statementId, statementPersonUid, statementVerbUid, xObjectUid, subStatementActorUid, substatementVerbUid, subStatementObjectUid, agentUid, instructorUid, authorityUid, teamUid, resultCompletion, resultSuccess, resultScoreScaled, resultScoreRaw, resultScoreMin, resultScoreMax, resultDuration, resultResponse, timestamp, stored, contextRegistration, contextPlatform, contextStatementId, fullStatement, statementMasterChangeSeqNum, statementLocalChangeSeqNum, statementLastChangedBy, statementLct, extensionProgress, contentEntryRoot, statementContentEntryUid, statementLearnerGroupUid, statementClazzUid) VALUES(COALESCE(?,nextval('StatementEntity_statementUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING statementUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: StatementEntity):
        Unit {
      if(entity.statementUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.statementUid)
      }
      stmt.setString(2, entity.statementId)
      stmt.setLong(3, entity.statementPersonUid)
      stmt.setLong(4, entity.statementVerbUid)
      stmt.setLong(5, entity.xObjectUid)
      stmt.setLong(6, entity.subStatementActorUid)
      stmt.setLong(7, entity.substatementVerbUid)
      stmt.setLong(8, entity.subStatementObjectUid)
      stmt.setLong(9, entity.agentUid)
      stmt.setLong(10, entity.instructorUid)
      stmt.setLong(11, entity.authorityUid)
      stmt.setLong(12, entity.teamUid)
      stmt.setBoolean(13, entity.resultCompletion)
      stmt.setByte(14, entity.resultSuccess)
      stmt.setFloat(15, entity.resultScoreScaled)
      stmt.setLong(16, entity.resultScoreRaw)
      stmt.setLong(17, entity.resultScoreMin)
      stmt.setLong(18, entity.resultScoreMax)
      stmt.setLong(19, entity.resultDuration)
      stmt.setString(20, entity.resultResponse)
      stmt.setLong(21, entity.timestamp)
      stmt.setLong(22, entity.stored)
      stmt.setString(23, entity.contextRegistration)
      stmt.setString(24, entity.contextPlatform)
      stmt.setString(25, entity.contextStatementId)
      stmt.setString(26, entity.fullStatement)
      stmt.setLong(27, entity.statementMasterChangeSeqNum)
      stmt.setLong(28, entity.statementLocalChangeSeqNum)
      stmt.setInt(29, entity.statementLastChangedBy)
      stmt.setLong(30, entity.statementLct)
      stmt.setInt(31, entity.extensionProgress)
      stmt.setBoolean(32, entity.contentEntryRoot)
      stmt.setLong(33, entity.statementContentEntryUid)
      stmt.setLong(34, entity.statementLearnerGroupUid)
      stmt.setLong(35, entity.statementClazzUid)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO StatementEntityReplicate(sePk, seDestination)
    |      SELECT DISTINCT StatementEntity.statementUid AS sePk,
    |             ? AS seDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |             JOIN StatementEntity
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz 
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |            )         
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         -- Temporary measure to prevent admin user getting clogged up
    |         -- Restrict to the last 30 days of data
    |         AND StatementEntity.timestamp > ( 
    |       --notpsql
    |       strftime('%s', 'now') * 1000
    |       --endnotpsql
    |       /*psql
    |       ROUND(EXTRACT(epoch from NOW())*1000)
    |       */
    |       - (30 * CAST(86400000 AS BIGINT)))
    |       --notpsql
    |         AND StatementEntity.statementLct != COALESCE(
    |             (SELECT seVersionId
    |                FROM StatementEntityReplicate
    |               WHERE sePk = StatementEntity.statementUid
    |                 AND seDestination = UserSession.usClientNodeId), 0)
    |       --endnotpsql           
    |      /*psql ON CONFLICT(sePk, seDestination) DO UPDATE
    |             SET sePending = (SELECT StatementEntity.statementLct
    |            FROM StatementEntity
    |           WHERE StatementEntity.statementUid = EXCLUDED.sePk ) 
    |                 != StatementEntityReplicate.seVersionId
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO StatementEntityReplicate(sePk, seDestination)
    |      SELECT DISTINCT StatementEntity.statementUid AS sePk,
    |             ? AS seDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |             JOIN StatementEntity
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz 
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |            )         
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         -- Temporary measure to prevent admin user getting clogged up
    |         -- Restrict to the last 30 days of data
    |         AND StatementEntity.timestamp > ( 
    |       
    |       ROUND(EXTRACT(epoch from NOW())*1000)
    |       
    |       - (30 * CAST(86400000 AS BIGINT)))
    |       ON CONFLICT(sePk, seDestination) DO UPDATE
    |             SET sePending = (SELECT StatementEntity.statementLct
    |            FROM StatementEntity
    |           WHERE StatementEntity.statementUid = EXCLUDED.sePk ) 
    |                 != StatementEntityReplicate.seVersionId
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO StatementEntityReplicate(sePk, seDestination)
    |  SELECT DISTINCT StatementEntity.statementUid AS seUid,
    |         UserSession.usClientNodeId AS seDestination
    |    FROM ChangeLog
    |         JOIN StatementEntity
    |               ON ChangeLog.chTableId = 60
    |                  AND ChangeLog.chEntityPk = StatementEntity.statementUid
    |         JOIN ScopedGrant
    |              ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |             )
    |        
    |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |         JOIN PersonGroupMember
    |              ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |         JOIN UserSession
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                 AND UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND StatementEntity.statementLct != COALESCE(
    |         (SELECT seVersionId
    |            FROM StatementEntityReplicate
    |           WHERE sePk = StatementEntity.statementUid
    |             AND seDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sePk, seDestination) DO UPDATE
    |     SET sePending = true
    |  */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO StatementEntityReplicate(sePk, seDestination)
    |  SELECT DISTINCT StatementEntity.statementUid AS seUid,
    |         UserSession.usClientNodeId AS seDestination
    |    FROM ChangeLog
    |         JOIN StatementEntity
    |               ON ChangeLog.chTableId = 60
    |                  AND ChangeLog.chEntityPk = StatementEntity.statementUid
    |         JOIN ScopedGrant
    |              ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |             )
    |        
    |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |         JOIN PersonGroupMember
    |              ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |         JOIN UserSession
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                 AND UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND StatementEntity.statementLct != COALESCE(
    |         (SELECT seVersionId
    |            FROM StatementEntityReplicate
    |           WHERE sePk = StatementEntity.statementUid
    |             AND seDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sePk, seDestination) DO UPDATE
    |     SET sePending = true
    |  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out StatementEntity>): Unit {
    _insertAdapterStatementEntity_.insertListAsync(entityList)
  }

  public override fun getOneStatement(): DoorLiveData<StatementEntity?> {
    val _result = DoorLiveDataImpl<StatementEntity?>(_db, listOf("StatementEntity"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.StatementEntity??
      val _stmtConfig = PreparedStatementConfig("SELECT * From StatementEntity LIMIT 1" , postgreSql
          = """
      |SELECT * From StatementEntity LIMIT 1
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_statementUid = _resultSet.getLong("statementUid")
            val tmp_statementId = _resultSet.getString("statementId")
            val tmp_statementPersonUid = _resultSet.getLong("statementPersonUid")
            val tmp_statementVerbUid = _resultSet.getLong("statementVerbUid")
            val tmp_xObjectUid = _resultSet.getLong("xObjectUid")
            val tmp_subStatementActorUid = _resultSet.getLong("subStatementActorUid")
            val tmp_substatementVerbUid = _resultSet.getLong("substatementVerbUid")
            val tmp_subStatementObjectUid = _resultSet.getLong("subStatementObjectUid")
            val tmp_agentUid = _resultSet.getLong("agentUid")
            val tmp_instructorUid = _resultSet.getLong("instructorUid")
            val tmp_authorityUid = _resultSet.getLong("authorityUid")
            val tmp_teamUid = _resultSet.getLong("teamUid")
            val tmp_resultCompletion = _resultSet.getBoolean("resultCompletion")
            val tmp_resultSuccess = _resultSet.getByte("resultSuccess")
            val tmp_resultScoreScaled = _resultSet.getFloat("resultScoreScaled")
            val tmp_resultScoreRaw = _resultSet.getLong("resultScoreRaw")
            val tmp_resultScoreMin = _resultSet.getLong("resultScoreMin")
            val tmp_resultScoreMax = _resultSet.getLong("resultScoreMax")
            val tmp_resultDuration = _resultSet.getLong("resultDuration")
            val tmp_resultResponse = _resultSet.getString("resultResponse")
            val tmp_timestamp = _resultSet.getLong("timestamp")
            val tmp_stored = _resultSet.getLong("stored")
            val tmp_contextRegistration = _resultSet.getString("contextRegistration")
            val tmp_contextPlatform = _resultSet.getString("contextPlatform")
            val tmp_contextStatementId = _resultSet.getString("contextStatementId")
            val tmp_fullStatement = _resultSet.getString("fullStatement")
            val tmp_statementMasterChangeSeqNum = _resultSet.getLong("statementMasterChangeSeqNum")
            val tmp_statementLocalChangeSeqNum = _resultSet.getLong("statementLocalChangeSeqNum")
            val tmp_statementLastChangedBy = _resultSet.getInt("statementLastChangedBy")
            val tmp_statementLct = _resultSet.getLong("statementLct")
            val tmp_extensionProgress = _resultSet.getInt("extensionProgress")
            val tmp_contentEntryRoot = _resultSet.getBoolean("contentEntryRoot")
            val tmp_statementContentEntryUid = _resultSet.getLong("statementContentEntryUid")
            val tmp_statementLearnerGroupUid = _resultSet.getLong("statementLearnerGroupUid")
            val tmp_statementClazzUid = _resultSet.getLong("statementClazzUid")
            val _entity = StatementEntity()
            _entity.statementUid = tmp_statementUid
            _entity.statementId = tmp_statementId
            _entity.statementPersonUid = tmp_statementPersonUid
            _entity.statementVerbUid = tmp_statementVerbUid
            _entity.xObjectUid = tmp_xObjectUid
            _entity.subStatementActorUid = tmp_subStatementActorUid
            _entity.substatementVerbUid = tmp_substatementVerbUid
            _entity.subStatementObjectUid = tmp_subStatementObjectUid
            _entity.agentUid = tmp_agentUid
            _entity.instructorUid = tmp_instructorUid
            _entity.authorityUid = tmp_authorityUid
            _entity.teamUid = tmp_teamUid
            _entity.resultCompletion = tmp_resultCompletion
            _entity.resultSuccess = tmp_resultSuccess
            _entity.resultScoreScaled = tmp_resultScoreScaled
            _entity.resultScoreRaw = tmp_resultScoreRaw
            _entity.resultScoreMin = tmp_resultScoreMin
            _entity.resultScoreMax = tmp_resultScoreMax
            _entity.resultDuration = tmp_resultDuration
            _entity.resultResponse = tmp_resultResponse
            _entity.timestamp = tmp_timestamp
            _entity.stored = tmp_stored
            _entity.contextRegistration = tmp_contextRegistration
            _entity.contextPlatform = tmp_contextPlatform
            _entity.contextStatementId = tmp_contextStatementId
            _entity.fullStatement = tmp_fullStatement
            _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
            _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
            _entity.statementLastChangedBy = tmp_statementLastChangedBy
            _entity.statementLct = tmp_statementLct
            _entity.extensionProgress = tmp_extensionProgress
            _entity.contentEntryRoot = tmp_contentEntryRoot
            _entity.statementContentEntryUid = tmp_statementContentEntryUid
            _entity.statementLearnerGroupUid = tmp_statementLearnerGroupUid
            _entity.statementClazzUid = tmp_statementClazzUid
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun findByStatementId(id: String): StatementEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.StatementEntity??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM StatementEntity WHERE statementId = ? LIMIT 1" ,
        postgreSql = """
    |SELECT * FROM StatementEntity WHERE statementId = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, id)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_statementUid = _resultSet.getLong("statementUid")
          val tmp_statementId = _resultSet.getString("statementId")
          val tmp_statementPersonUid = _resultSet.getLong("statementPersonUid")
          val tmp_statementVerbUid = _resultSet.getLong("statementVerbUid")
          val tmp_xObjectUid = _resultSet.getLong("xObjectUid")
          val tmp_subStatementActorUid = _resultSet.getLong("subStatementActorUid")
          val tmp_substatementVerbUid = _resultSet.getLong("substatementVerbUid")
          val tmp_subStatementObjectUid = _resultSet.getLong("subStatementObjectUid")
          val tmp_agentUid = _resultSet.getLong("agentUid")
          val tmp_instructorUid = _resultSet.getLong("instructorUid")
          val tmp_authorityUid = _resultSet.getLong("authorityUid")
          val tmp_teamUid = _resultSet.getLong("teamUid")
          val tmp_resultCompletion = _resultSet.getBoolean("resultCompletion")
          val tmp_resultSuccess = _resultSet.getByte("resultSuccess")
          val tmp_resultScoreScaled = _resultSet.getFloat("resultScoreScaled")
          val tmp_resultScoreRaw = _resultSet.getLong("resultScoreRaw")
          val tmp_resultScoreMin = _resultSet.getLong("resultScoreMin")
          val tmp_resultScoreMax = _resultSet.getLong("resultScoreMax")
          val tmp_resultDuration = _resultSet.getLong("resultDuration")
          val tmp_resultResponse = _resultSet.getString("resultResponse")
          val tmp_timestamp = _resultSet.getLong("timestamp")
          val tmp_stored = _resultSet.getLong("stored")
          val tmp_contextRegistration = _resultSet.getString("contextRegistration")
          val tmp_contextPlatform = _resultSet.getString("contextPlatform")
          val tmp_contextStatementId = _resultSet.getString("contextStatementId")
          val tmp_fullStatement = _resultSet.getString("fullStatement")
          val tmp_statementMasterChangeSeqNum = _resultSet.getLong("statementMasterChangeSeqNum")
          val tmp_statementLocalChangeSeqNum = _resultSet.getLong("statementLocalChangeSeqNum")
          val tmp_statementLastChangedBy = _resultSet.getInt("statementLastChangedBy")
          val tmp_statementLct = _resultSet.getLong("statementLct")
          val tmp_extensionProgress = _resultSet.getInt("extensionProgress")
          val tmp_contentEntryRoot = _resultSet.getBoolean("contentEntryRoot")
          val tmp_statementContentEntryUid = _resultSet.getLong("statementContentEntryUid")
          val tmp_statementLearnerGroupUid = _resultSet.getLong("statementLearnerGroupUid")
          val tmp_statementClazzUid = _resultSet.getLong("statementClazzUid")
          val _entity = StatementEntity()
          _entity.statementUid = tmp_statementUid
          _entity.statementId = tmp_statementId
          _entity.statementPersonUid = tmp_statementPersonUid
          _entity.statementVerbUid = tmp_statementVerbUid
          _entity.xObjectUid = tmp_xObjectUid
          _entity.subStatementActorUid = tmp_subStatementActorUid
          _entity.substatementVerbUid = tmp_substatementVerbUid
          _entity.subStatementObjectUid = tmp_subStatementObjectUid
          _entity.agentUid = tmp_agentUid
          _entity.instructorUid = tmp_instructorUid
          _entity.authorityUid = tmp_authorityUid
          _entity.teamUid = tmp_teamUid
          _entity.resultCompletion = tmp_resultCompletion
          _entity.resultSuccess = tmp_resultSuccess
          _entity.resultScoreScaled = tmp_resultScoreScaled
          _entity.resultScoreRaw = tmp_resultScoreRaw
          _entity.resultScoreMin = tmp_resultScoreMin
          _entity.resultScoreMax = tmp_resultScoreMax
          _entity.resultDuration = tmp_resultDuration
          _entity.resultResponse = tmp_resultResponse
          _entity.timestamp = tmp_timestamp
          _entity.stored = tmp_stored
          _entity.contextRegistration = tmp_contextRegistration
          _entity.contextPlatform = tmp_contextPlatform
          _entity.contextStatementId = tmp_contextStatementId
          _entity.fullStatement = tmp_fullStatement
          _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
          _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
          _entity.statementLastChangedBy = tmp_statementLastChangedBy
          _entity.statementLct = tmp_statementLct
          _entity.extensionProgress = tmp_extensionProgress
          _entity.contentEntryRoot = tmp_contentEntryRoot
          _entity.statementContentEntryUid = tmp_statementContentEntryUid
          _entity.statementLearnerGroupUid = tmp_statementLearnerGroupUid
          _entity.statementClazzUid = tmp_statementClazzUid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByStatementIdList(id: List<String>): List<StatementEntity> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.StatementEntity>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM StatementEntity WHERE statementId IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT * FROM StatementEntity WHERE statementId IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "TEXT", id.toTypedArray()))
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_statementUid = _resultSet.getLong("statementUid")
          val tmp_statementId = _resultSet.getString("statementId")
          val tmp_statementPersonUid = _resultSet.getLong("statementPersonUid")
          val tmp_statementVerbUid = _resultSet.getLong("statementVerbUid")
          val tmp_xObjectUid = _resultSet.getLong("xObjectUid")
          val tmp_subStatementActorUid = _resultSet.getLong("subStatementActorUid")
          val tmp_substatementVerbUid = _resultSet.getLong("substatementVerbUid")
          val tmp_subStatementObjectUid = _resultSet.getLong("subStatementObjectUid")
          val tmp_agentUid = _resultSet.getLong("agentUid")
          val tmp_instructorUid = _resultSet.getLong("instructorUid")
          val tmp_authorityUid = _resultSet.getLong("authorityUid")
          val tmp_teamUid = _resultSet.getLong("teamUid")
          val tmp_resultCompletion = _resultSet.getBoolean("resultCompletion")
          val tmp_resultSuccess = _resultSet.getByte("resultSuccess")
          val tmp_resultScoreScaled = _resultSet.getFloat("resultScoreScaled")
          val tmp_resultScoreRaw = _resultSet.getLong("resultScoreRaw")
          val tmp_resultScoreMin = _resultSet.getLong("resultScoreMin")
          val tmp_resultScoreMax = _resultSet.getLong("resultScoreMax")
          val tmp_resultDuration = _resultSet.getLong("resultDuration")
          val tmp_resultResponse = _resultSet.getString("resultResponse")
          val tmp_timestamp = _resultSet.getLong("timestamp")
          val tmp_stored = _resultSet.getLong("stored")
          val tmp_contextRegistration = _resultSet.getString("contextRegistration")
          val tmp_contextPlatform = _resultSet.getString("contextPlatform")
          val tmp_contextStatementId = _resultSet.getString("contextStatementId")
          val tmp_fullStatement = _resultSet.getString("fullStatement")
          val tmp_statementMasterChangeSeqNum = _resultSet.getLong("statementMasterChangeSeqNum")
          val tmp_statementLocalChangeSeqNum = _resultSet.getLong("statementLocalChangeSeqNum")
          val tmp_statementLastChangedBy = _resultSet.getInt("statementLastChangedBy")
          val tmp_statementLct = _resultSet.getLong("statementLct")
          val tmp_extensionProgress = _resultSet.getInt("extensionProgress")
          val tmp_contentEntryRoot = _resultSet.getBoolean("contentEntryRoot")
          val tmp_statementContentEntryUid = _resultSet.getLong("statementContentEntryUid")
          val tmp_statementLearnerGroupUid = _resultSet.getLong("statementLearnerGroupUid")
          val tmp_statementClazzUid = _resultSet.getLong("statementClazzUid")
          val _entity = StatementEntity()
          _entity.statementUid = tmp_statementUid
          _entity.statementId = tmp_statementId
          _entity.statementPersonUid = tmp_statementPersonUid
          _entity.statementVerbUid = tmp_statementVerbUid
          _entity.xObjectUid = tmp_xObjectUid
          _entity.subStatementActorUid = tmp_subStatementActorUid
          _entity.substatementVerbUid = tmp_substatementVerbUid
          _entity.subStatementObjectUid = tmp_subStatementObjectUid
          _entity.agentUid = tmp_agentUid
          _entity.instructorUid = tmp_instructorUid
          _entity.authorityUid = tmp_authorityUid
          _entity.teamUid = tmp_teamUid
          _entity.resultCompletion = tmp_resultCompletion
          _entity.resultSuccess = tmp_resultSuccess
          _entity.resultScoreScaled = tmp_resultScoreScaled
          _entity.resultScoreRaw = tmp_resultScoreRaw
          _entity.resultScoreMin = tmp_resultScoreMin
          _entity.resultScoreMax = tmp_resultScoreMax
          _entity.resultDuration = tmp_resultDuration
          _entity.resultResponse = tmp_resultResponse
          _entity.timestamp = tmp_timestamp
          _entity.stored = tmp_stored
          _entity.contextRegistration = tmp_contextRegistration
          _entity.contextPlatform = tmp_contextPlatform
          _entity.contextStatementId = tmp_contextStatementId
          _entity.fullStatement = tmp_fullStatement
          _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
          _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
          _entity.statementLastChangedBy = tmp_statementLastChangedBy
          _entity.statementLct = tmp_statementLct
          _entity.extensionProgress = tmp_extensionProgress
          _entity.contentEntryRoot = tmp_contentEntryRoot
          _entity.statementContentEntryUid = tmp_statementContentEntryUid
          _entity.statementLearnerGroupUid = tmp_statementLearnerGroupUid
          _entity.statementClazzUid = tmp_statementClazzUid
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun getResults(query: DoorQuery): List<StatementDao.ReportData> {
    var _result = mutableListOf<com.ustadmobile.core.db.dao.StatementDao.ReportData>()
    val _stmtConfig = PreparedStatementConfig(query.getSql(), hasListParams =
        query.hasListOrArrayParams())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      query.bindToPreparedStmt(_stmt, _db, _stmt.getConnection())
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        val _columnIndexMap = _resultSet.columnIndexMap()
        while(_resultSet.next()) {
          val tmp_yAxis: Float
          if(_columnIndexMap.containsKey("yAxis")) {
            tmp_yAxis  = _resultSet.getFloat("yAxis")
          } else {
            tmp_yAxis = 0.toFloat()
          }
          val tmp_xAxis: String?
          if(_columnIndexMap.containsKey("xAxis")) {
            tmp_xAxis  = _resultSet.getString("xAxis")
          } else {
            tmp_xAxis = null as String?
          }
          val tmp_subgroup: String?
          if(_columnIndexMap.containsKey("subgroup")) {
            tmp_subgroup  = _resultSet.getString("subgroup")
          } else {
            tmp_subgroup = null as String?
          }
          val _entity = StatementDao.ReportData()
          _entity.yAxis = tmp_yAxis
          _entity.xAxis = tmp_xAxis
          _entity.subgroup = tmp_subgroup
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun getListResults(query: DoorQuery): DoorDataSourceFactory<Int,
      StatementEntityWithDisplayDetails> {
    val _result = object : DoorDataSourceFactory<Int, StatementEntityWithDisplayDetails>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<StatementEntityWithDisplayDetails>> {
        val _rawQuery = query.copyWithExtraParams(
        sql = "SELECT * FROM (${query.getSql()}) LIMIT ? OFFSET ?",
        extraParams = arrayOf(_limit, _offset))
        return DoorLiveDataImpl<List<StatementEntityWithDisplayDetails>>(_db,
            listOf("StatementEntity", "Person", "XLangMapEntry"))  {
          var _liveResult =
              mutableListOf<com.ustadmobile.lib.db.entities.StatementEntityWithDisplayDetails>()
          val _stmtConfig = PreparedStatementConfig(_rawQuery.getSql(), hasListParams =
              _rawQuery.hasListOrArrayParams())
          _db.prepareAndUseStatementAsync(_stmtConfig) {
            _stmt ->
            _rawQuery.bindToPreparedStmt(_stmt, _db, _stmt.getConnection())
            _stmt.executeQueryAsyncKmp().useResults {
               _resultSet ->
              val _columnIndexMap = _resultSet.columnIndexMap()
              while(_resultSet.next()) {
                val tmp_statementUid: Long
                if(_columnIndexMap.containsKey("statementUid")) {
                  tmp_statementUid  = _resultSet.getLong("statementUid")
                } else {
                  tmp_statementUid = 0L
                }
                val tmp_statementId: String?
                if(_columnIndexMap.containsKey("statementId")) {
                  tmp_statementId  = _resultSet.getString("statementId")
                } else {
                  tmp_statementId = null as String?
                }
                val tmp_statementPersonUid: Long
                if(_columnIndexMap.containsKey("statementPersonUid")) {
                  tmp_statementPersonUid  = _resultSet.getLong("statementPersonUid")
                } else {
                  tmp_statementPersonUid = 0L
                }
                val tmp_statementVerbUid: Long
                if(_columnIndexMap.containsKey("statementVerbUid")) {
                  tmp_statementVerbUid  = _resultSet.getLong("statementVerbUid")
                } else {
                  tmp_statementVerbUid = 0L
                }
                val tmp_xObjectUid: Long
                if(_columnIndexMap.containsKey("xObjectUid")) {
                  tmp_xObjectUid  = _resultSet.getLong("xObjectUid")
                } else {
                  tmp_xObjectUid = 0L
                }
                val tmp_subStatementActorUid: Long
                if(_columnIndexMap.containsKey("subStatementActorUid")) {
                  tmp_subStatementActorUid  = _resultSet.getLong("subStatementActorUid")
                } else {
                  tmp_subStatementActorUid = 0L
                }
                val tmp_substatementVerbUid: Long
                if(_columnIndexMap.containsKey("substatementVerbUid")) {
                  tmp_substatementVerbUid  = _resultSet.getLong("substatementVerbUid")
                } else {
                  tmp_substatementVerbUid = 0L
                }
                val tmp_subStatementObjectUid: Long
                if(_columnIndexMap.containsKey("subStatementObjectUid")) {
                  tmp_subStatementObjectUid  = _resultSet.getLong("subStatementObjectUid")
                } else {
                  tmp_subStatementObjectUid = 0L
                }
                val tmp_agentUid: Long
                if(_columnIndexMap.containsKey("agentUid")) {
                  tmp_agentUid  = _resultSet.getLong("agentUid")
                } else {
                  tmp_agentUid = 0L
                }
                val tmp_instructorUid: Long
                if(_columnIndexMap.containsKey("instructorUid")) {
                  tmp_instructorUid  = _resultSet.getLong("instructorUid")
                } else {
                  tmp_instructorUid = 0L
                }
                val tmp_authorityUid: Long
                if(_columnIndexMap.containsKey("authorityUid")) {
                  tmp_authorityUid  = _resultSet.getLong("authorityUid")
                } else {
                  tmp_authorityUid = 0L
                }
                val tmp_teamUid: Long
                if(_columnIndexMap.containsKey("teamUid")) {
                  tmp_teamUid  = _resultSet.getLong("teamUid")
                } else {
                  tmp_teamUid = 0L
                }
                val tmp_resultCompletion: Boolean
                if(_columnIndexMap.containsKey("resultCompletion")) {
                  tmp_resultCompletion  = _resultSet.getBoolean("resultCompletion")
                } else {
                  tmp_resultCompletion = false
                }
                val tmp_resultSuccess: Byte
                if(_columnIndexMap.containsKey("resultSuccess")) {
                  tmp_resultSuccess  = _resultSet.getByte("resultSuccess")
                } else {
                  tmp_resultSuccess = 0.toByte()
                }
                val tmp_resultScoreScaled: Float
                if(_columnIndexMap.containsKey("resultScoreScaled")) {
                  tmp_resultScoreScaled  = _resultSet.getFloat("resultScoreScaled")
                } else {
                  tmp_resultScoreScaled = 0.toFloat()
                }
                val tmp_resultScoreRaw: Long
                if(_columnIndexMap.containsKey("resultScoreRaw")) {
                  tmp_resultScoreRaw  = _resultSet.getLong("resultScoreRaw")
                } else {
                  tmp_resultScoreRaw = 0L
                }
                val tmp_resultScoreMin: Long
                if(_columnIndexMap.containsKey("resultScoreMin")) {
                  tmp_resultScoreMin  = _resultSet.getLong("resultScoreMin")
                } else {
                  tmp_resultScoreMin = 0L
                }
                val tmp_resultScoreMax: Long
                if(_columnIndexMap.containsKey("resultScoreMax")) {
                  tmp_resultScoreMax  = _resultSet.getLong("resultScoreMax")
                } else {
                  tmp_resultScoreMax = 0L
                }
                val tmp_resultDuration: Long
                if(_columnIndexMap.containsKey("resultDuration")) {
                  tmp_resultDuration  = _resultSet.getLong("resultDuration")
                } else {
                  tmp_resultDuration = 0L
                }
                val tmp_resultResponse: String?
                if(_columnIndexMap.containsKey("resultResponse")) {
                  tmp_resultResponse  = _resultSet.getString("resultResponse")
                } else {
                  tmp_resultResponse = null as String?
                }
                val tmp_timestamp: Long
                if(_columnIndexMap.containsKey("timestamp")) {
                  tmp_timestamp  = _resultSet.getLong("timestamp")
                } else {
                  tmp_timestamp = 0L
                }
                val tmp_stored: Long
                if(_columnIndexMap.containsKey("stored")) {
                  tmp_stored  = _resultSet.getLong("stored")
                } else {
                  tmp_stored = 0L
                }
                val tmp_contextRegistration: String?
                if(_columnIndexMap.containsKey("contextRegistration")) {
                  tmp_contextRegistration  = _resultSet.getString("contextRegistration")
                } else {
                  tmp_contextRegistration = null as String?
                }
                val tmp_contextPlatform: String?
                if(_columnIndexMap.containsKey("contextPlatform")) {
                  tmp_contextPlatform  = _resultSet.getString("contextPlatform")
                } else {
                  tmp_contextPlatform = null as String?
                }
                val tmp_contextStatementId: String?
                if(_columnIndexMap.containsKey("contextStatementId")) {
                  tmp_contextStatementId  = _resultSet.getString("contextStatementId")
                } else {
                  tmp_contextStatementId = null as String?
                }
                val tmp_fullStatement: String?
                if(_columnIndexMap.containsKey("fullStatement")) {
                  tmp_fullStatement  = _resultSet.getString("fullStatement")
                } else {
                  tmp_fullStatement = null as String?
                }
                val tmp_statementMasterChangeSeqNum: Long
                if(_columnIndexMap.containsKey("statementMasterChangeSeqNum")) {
                  tmp_statementMasterChangeSeqNum  =
                      _resultSet.getLong("statementMasterChangeSeqNum")
                } else {
                  tmp_statementMasterChangeSeqNum = 0L
                }
                val tmp_statementLocalChangeSeqNum: Long
                if(_columnIndexMap.containsKey("statementLocalChangeSeqNum")) {
                  tmp_statementLocalChangeSeqNum  = _resultSet.getLong("statementLocalChangeSeqNum")
                } else {
                  tmp_statementLocalChangeSeqNum = 0L
                }
                val tmp_statementLastChangedBy: Int
                if(_columnIndexMap.containsKey("statementLastChangedBy")) {
                  tmp_statementLastChangedBy  = _resultSet.getInt("statementLastChangedBy")
                } else {
                  tmp_statementLastChangedBy = 0
                }
                val tmp_statementLct: Long
                if(_columnIndexMap.containsKey("statementLct")) {
                  tmp_statementLct  = _resultSet.getLong("statementLct")
                } else {
                  tmp_statementLct = 0L
                }
                val tmp_extensionProgress: Int
                if(_columnIndexMap.containsKey("extensionProgress")) {
                  tmp_extensionProgress  = _resultSet.getInt("extensionProgress")
                } else {
                  tmp_extensionProgress = 0
                }
                val tmp_contentEntryRoot: Boolean
                if(_columnIndexMap.containsKey("contentEntryRoot")) {
                  tmp_contentEntryRoot  = _resultSet.getBoolean("contentEntryRoot")
                } else {
                  tmp_contentEntryRoot = false
                }
                val tmp_statementContentEntryUid: Long
                if(_columnIndexMap.containsKey("statementContentEntryUid")) {
                  tmp_statementContentEntryUid  = _resultSet.getLong("statementContentEntryUid")
                } else {
                  tmp_statementContentEntryUid = 0L
                }
                val tmp_statementLearnerGroupUid: Long
                if(_columnIndexMap.containsKey("statementLearnerGroupUid")) {
                  tmp_statementLearnerGroupUid  = _resultSet.getLong("statementLearnerGroupUid")
                } else {
                  tmp_statementLearnerGroupUid = 0L
                }
                val tmp_statementClazzUid: Long
                if(_columnIndexMap.containsKey("statementClazzUid")) {
                  tmp_statementClazzUid  = _resultSet.getLong("statementClazzUid")
                } else {
                  tmp_statementClazzUid = 0L
                }
                val _entity = StatementEntityWithDisplayDetails()
                _entity.statementUid = tmp_statementUid
                _entity.statementId = tmp_statementId
                _entity.statementPersonUid = tmp_statementPersonUid
                _entity.statementVerbUid = tmp_statementVerbUid
                _entity.xObjectUid = tmp_xObjectUid
                _entity.subStatementActorUid = tmp_subStatementActorUid
                _entity.substatementVerbUid = tmp_substatementVerbUid
                _entity.subStatementObjectUid = tmp_subStatementObjectUid
                _entity.agentUid = tmp_agentUid
                _entity.instructorUid = tmp_instructorUid
                _entity.authorityUid = tmp_authorityUid
                _entity.teamUid = tmp_teamUid
                _entity.resultCompletion = tmp_resultCompletion
                _entity.resultSuccess = tmp_resultSuccess
                _entity.resultScoreScaled = tmp_resultScoreScaled
                _entity.resultScoreRaw = tmp_resultScoreRaw
                _entity.resultScoreMin = tmp_resultScoreMin
                _entity.resultScoreMax = tmp_resultScoreMax
                _entity.resultDuration = tmp_resultDuration
                _entity.resultResponse = tmp_resultResponse
                _entity.timestamp = tmp_timestamp
                _entity.stored = tmp_stored
                _entity.contextRegistration = tmp_contextRegistration
                _entity.contextPlatform = tmp_contextPlatform
                _entity.contextStatementId = tmp_contextStatementId
                _entity.fullStatement = tmp_fullStatement
                _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
                _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
                _entity.statementLastChangedBy = tmp_statementLastChangedBy
                _entity.statementLct = tmp_statementLct
                _entity.extensionProgress = tmp_extensionProgress
                _entity.contentEntryRoot = tmp_contentEntryRoot
                _entity.statementContentEntryUid = tmp_statementContentEntryUid
                _entity.statementLearnerGroupUid = tmp_statementLearnerGroupUid
                _entity.statementClazzUid = tmp_statementClazzUid
                var _person_nullFieldCount = 0
                val tmp_personUid: Long
                if(_columnIndexMap.containsKey("personUid")) {
                  tmp_personUid  = _resultSet.getLong("personUid")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personUid = 0L
                }
                val tmp_username: String?
                if(_columnIndexMap.containsKey("username")) {
                  tmp_username  = _resultSet.getString("username")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_username = null as String?
                }
                val tmp_firstNames: String?
                if(_columnIndexMap.containsKey("firstNames")) {
                  tmp_firstNames  = _resultSet.getString("firstNames")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_firstNames = null as String?
                }
                val tmp_lastName: String?
                if(_columnIndexMap.containsKey("lastName")) {
                  tmp_lastName  = _resultSet.getString("lastName")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_lastName = null as String?
                }
                val tmp_emailAddr: String?
                if(_columnIndexMap.containsKey("emailAddr")) {
                  tmp_emailAddr  = _resultSet.getString("emailAddr")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_emailAddr = null as String?
                }
                val tmp_phoneNum: String?
                if(_columnIndexMap.containsKey("phoneNum")) {
                  tmp_phoneNum  = _resultSet.getString("phoneNum")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_phoneNum = null as String?
                }
                val tmp_gender: Int
                if(_columnIndexMap.containsKey("gender")) {
                  tmp_gender  = _resultSet.getInt("gender")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_gender = 0
                }
                val tmp_active: Boolean
                if(_columnIndexMap.containsKey("active")) {
                  tmp_active  = _resultSet.getBoolean("active")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_active = false
                }
                val tmp_admin: Boolean
                if(_columnIndexMap.containsKey("admin")) {
                  tmp_admin  = _resultSet.getBoolean("admin")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_admin = false
                }
                val tmp_personNotes: String?
                if(_columnIndexMap.containsKey("personNotes")) {
                  tmp_personNotes  = _resultSet.getString("personNotes")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personNotes = null as String?
                }
                val tmp_fatherName: String?
                if(_columnIndexMap.containsKey("fatherName")) {
                  tmp_fatherName  = _resultSet.getString("fatherName")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_fatherName = null as String?
                }
                val tmp_fatherNumber: String?
                if(_columnIndexMap.containsKey("fatherNumber")) {
                  tmp_fatherNumber  = _resultSet.getString("fatherNumber")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_fatherNumber = null as String?
                }
                val tmp_motherName: String?
                if(_columnIndexMap.containsKey("motherName")) {
                  tmp_motherName  = _resultSet.getString("motherName")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_motherName = null as String?
                }
                val tmp_motherNum: String?
                if(_columnIndexMap.containsKey("motherNum")) {
                  tmp_motherNum  = _resultSet.getString("motherNum")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_motherNum = null as String?
                }
                val tmp_dateOfBirth: Long
                if(_columnIndexMap.containsKey("dateOfBirth")) {
                  tmp_dateOfBirth  = _resultSet.getLong("dateOfBirth")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_dateOfBirth = 0L
                }
                val tmp_personAddress: String?
                if(_columnIndexMap.containsKey("personAddress")) {
                  tmp_personAddress  = _resultSet.getString("personAddress")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personAddress = null as String?
                }
                val tmp_personOrgId: String?
                if(_columnIndexMap.containsKey("personOrgId")) {
                  tmp_personOrgId  = _resultSet.getString("personOrgId")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personOrgId = null as String?
                }
                val tmp_personGroupUid: Long
                if(_columnIndexMap.containsKey("personGroupUid")) {
                  tmp_personGroupUid  = _resultSet.getLong("personGroupUid")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personGroupUid = 0L
                }
                val tmp_personMasterChangeSeqNum: Long
                if(_columnIndexMap.containsKey("personMasterChangeSeqNum")) {
                  tmp_personMasterChangeSeqNum  = _resultSet.getLong("personMasterChangeSeqNum")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personMasterChangeSeqNum = 0L
                }
                val tmp_personLocalChangeSeqNum: Long
                if(_columnIndexMap.containsKey("personLocalChangeSeqNum")) {
                  tmp_personLocalChangeSeqNum  = _resultSet.getLong("personLocalChangeSeqNum")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personLocalChangeSeqNum = 0L
                }
                val tmp_personLastChangedBy: Int
                if(_columnIndexMap.containsKey("personLastChangedBy")) {
                  tmp_personLastChangedBy  = _resultSet.getInt("personLastChangedBy")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personLastChangedBy = 0
                }
                val tmp_personLct: Long
                if(_columnIndexMap.containsKey("personLct")) {
                  tmp_personLct  = _resultSet.getLong("personLct")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personLct = 0L
                }
                val tmp_personCountry: String?
                if(_columnIndexMap.containsKey("personCountry")) {
                  tmp_personCountry  = _resultSet.getString("personCountry")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personCountry = null as String?
                }
                val tmp_personType: Int
                if(_columnIndexMap.containsKey("personType")) {
                  tmp_personType  = _resultSet.getInt("personType")
                  if(_resultSet.wasNull()) { _person_nullFieldCount++ }
                } else {
                  _person_nullFieldCount++
                  tmp_personType = 0
                }
                if(_person_nullFieldCount < 24) {
                  if(_entity.person == null) {
                    _entity.person = Person()
                  }
                  _entity.person!!.personUid = tmp_personUid
                  _entity.person!!.username = tmp_username
                  _entity.person!!.firstNames = tmp_firstNames
                  _entity.person!!.lastName = tmp_lastName
                  _entity.person!!.emailAddr = tmp_emailAddr
                  _entity.person!!.phoneNum = tmp_phoneNum
                  _entity.person!!.gender = tmp_gender
                  _entity.person!!.active = tmp_active
                  _entity.person!!.admin = tmp_admin
                  _entity.person!!.personNotes = tmp_personNotes
                  _entity.person!!.fatherName = tmp_fatherName
                  _entity.person!!.fatherNumber = tmp_fatherNumber
                  _entity.person!!.motherName = tmp_motherName
                  _entity.person!!.motherNum = tmp_motherNum
                  _entity.person!!.dateOfBirth = tmp_dateOfBirth
                  _entity.person!!.personAddress = tmp_personAddress
                  _entity.person!!.personOrgId = tmp_personOrgId
                  _entity.person!!.personGroupUid = tmp_personGroupUid
                  _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                  _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                  _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
                  _entity.person!!.personLct = tmp_personLct
                  _entity.person!!.personCountry = tmp_personCountry
                  _entity.person!!.personType = tmp_personType
                }
                var _xlangMapEntry_nullFieldCount = 0
                val tmp_verbLangMapUid: Long
                if(_columnIndexMap.containsKey("verbLangMapUid")) {
                  tmp_verbLangMapUid  = _resultSet.getLong("verbLangMapUid")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_verbLangMapUid = 0L
                }
                val tmp_objectLangMapUid: Long
                if(_columnIndexMap.containsKey("objectLangMapUid")) {
                  tmp_objectLangMapUid  = _resultSet.getLong("objectLangMapUid")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_objectLangMapUid = 0L
                }
                val tmp_languageLangMapUid: Long
                if(_columnIndexMap.containsKey("languageLangMapUid")) {
                  tmp_languageLangMapUid  = _resultSet.getLong("languageLangMapUid")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_languageLangMapUid = 0L
                }
                val tmp_languageVariantLangMapUid: Long
                if(_columnIndexMap.containsKey("languageVariantLangMapUid")) {
                  tmp_languageVariantLangMapUid  = _resultSet.getLong("languageVariantLangMapUid")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_languageVariantLangMapUid = 0L
                }
                val tmp_valueLangMap: String?
                if(_columnIndexMap.containsKey("valueLangMap")) {
                  tmp_valueLangMap  = _resultSet.getString("valueLangMap")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_valueLangMap = null as String?
                }
                val tmp_statementLangMapMasterCsn: Int
                if(_columnIndexMap.containsKey("statementLangMapMasterCsn")) {
                  tmp_statementLangMapMasterCsn  = _resultSet.getInt("statementLangMapMasterCsn")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_statementLangMapMasterCsn = 0
                }
                val tmp_statementLangMapLocalCsn: Int
                if(_columnIndexMap.containsKey("statementLangMapLocalCsn")) {
                  tmp_statementLangMapLocalCsn  = _resultSet.getInt("statementLangMapLocalCsn")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_statementLangMapLocalCsn = 0
                }
                val tmp_statementLangMapLcb: Int
                if(_columnIndexMap.containsKey("statementLangMapLcb")) {
                  tmp_statementLangMapLcb  = _resultSet.getInt("statementLangMapLcb")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_statementLangMapLcb = 0
                }
                val tmp_statementLangMapLct: Long
                if(_columnIndexMap.containsKey("statementLangMapLct")) {
                  tmp_statementLangMapLct  = _resultSet.getLong("statementLangMapLct")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_statementLangMapLct = 0L
                }
                val tmp_statementLangMapUid: Long
                if(_columnIndexMap.containsKey("statementLangMapUid")) {
                  tmp_statementLangMapUid  = _resultSet.getLong("statementLangMapUid")
                  if(_resultSet.wasNull()) { _xlangMapEntry_nullFieldCount++ }
                } else {
                  _xlangMapEntry_nullFieldCount++
                  tmp_statementLangMapUid = 0L
                }
                if(_xlangMapEntry_nullFieldCount < 10) {
                  if(_entity.xlangMapEntry == null) {
                    _entity.xlangMapEntry = XLangMapEntry()
                  }
                  _entity.xlangMapEntry!!.verbLangMapUid = tmp_verbLangMapUid
                  _entity.xlangMapEntry!!.objectLangMapUid = tmp_objectLangMapUid
                  _entity.xlangMapEntry!!.languageLangMapUid = tmp_languageLangMapUid
                  _entity.xlangMapEntry!!.languageVariantLangMapUid = tmp_languageVariantLangMapUid
                  _entity.xlangMapEntry!!.valueLangMap = tmp_valueLangMap
                  _entity.xlangMapEntry!!.statementLangMapMasterCsn = tmp_statementLangMapMasterCsn
                  _entity.xlangMapEntry!!.statementLangMapLocalCsn = tmp_statementLangMapLocalCsn
                  _entity.xlangMapEntry!!.statementLangMapLcb = tmp_statementLangMapLcb
                  _entity.xlangMapEntry!!.statementLangMapLct = tmp_statementLangMapLct
                  _entity.xlangMapEntry!!.statementLangMapUid = tmp_statementLangMapUid
                }
                _liveResult.add(_entity)
              }
            }
          }
          _liveResult.toList()
        }
      }

      public override fun getLength(): DoorLiveData<Int> {
        val _rawQuery = query.copy(
        sql = "SELECT COUNT(*) FROM (${query.getSql()})")
        return DoorLiveDataImpl<Int>(_db, listOf("StatementEntity", "Person", "XLangMapEntry"))  {
          var _liveResult = 0
          val _stmtConfig = PreparedStatementConfig(_rawQuery.getSql(), hasListParams =
              _rawQuery.hasListOrArrayParams())
          _db.prepareAndUseStatementAsync(_stmtConfig) {
            _stmt ->
            _rawQuery.bindToPreparedStmt(_stmt, _db, _stmt.getConnection())
            _stmt.executeQueryAsyncKmp().useResults {
               _resultSet ->
              if(_resultSet.next()) {
                val _entity = _resultSet.getInt(1)
                _liveResult = _entity
              }
            }
          }
          _liveResult
        }
      }
    }
    return _result
  }

  public override fun getPerson(): Person? {
    var _result = null as com.ustadmobile.lib.db.entities.Person??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM PERSON LIMIT 1" , postgreSql = """
    |SELECT * FROM PERSON LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun getXLangMap(): XLangMapEntry? {
    var _result = null as com.ustadmobile.lib.db.entities.XLangMapEntry??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM XLangMapEntry LIMIT 1" , postgreSql =
        """
    |SELECT * FROM XLangMapEntry LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_verbLangMapUid = _resultSet.getLong("verbLangMapUid")
          val tmp_objectLangMapUid = _resultSet.getLong("objectLangMapUid")
          val tmp_languageLangMapUid = _resultSet.getLong("languageLangMapUid")
          val tmp_languageVariantLangMapUid = _resultSet.getLong("languageVariantLangMapUid")
          val tmp_valueLangMap = _resultSet.getString("valueLangMap")
          val tmp_statementLangMapMasterCsn = _resultSet.getInt("statementLangMapMasterCsn")
          val tmp_statementLangMapLocalCsn = _resultSet.getInt("statementLangMapLocalCsn")
          val tmp_statementLangMapLcb = _resultSet.getInt("statementLangMapLcb")
          val tmp_statementLangMapLct = _resultSet.getLong("statementLangMapLct")
          val tmp_statementLangMapUid = _resultSet.getLong("statementLangMapUid")
          val _entity = XLangMapEntry()
          _entity.verbLangMapUid = tmp_verbLangMapUid
          _entity.objectLangMapUid = tmp_objectLangMapUid
          _entity.languageLangMapUid = tmp_languageLangMapUid
          _entity.languageVariantLangMapUid = tmp_languageVariantLangMapUid
          _entity.valueLangMap = tmp_valueLangMap
          _entity.statementLangMapMasterCsn = tmp_statementLangMapMasterCsn
          _entity.statementLangMapLocalCsn = tmp_statementLangMapLocalCsn
          _entity.statementLangMapLcb = tmp_statementLangMapLcb
          _entity.statementLangMapLct = tmp_statementLangMapLct
          _entity.statementLangMapUid = tmp_statementLangMapUid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun updateProgress(
    uid: Long,
    progress: Int,
    updateTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE StatementEntity 
    |           SET extensionProgress = ?,
    |               statementLct = ? 
    |            WHERE statementUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE StatementEntity 
    |           SET extensionProgress = ?,
    |               statementLct = ? 
    |            WHERE statementUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, progress)
      _stmt.setLong(2, updateTime)
      _stmt.setLong(3, uid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun findPersonsWithContentEntryAttempts(
    contentEntryUid: Long,
    accountPersonUid: Long,
    searchText: String,
    sortOrder: Int
  ): DoorDataSourceFactory<Int, PersonWithAttemptsSummary> {
    val _result = object : DoorDataSourceFactory<Int, PersonWithAttemptsSummary>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<PersonWithAttemptsSummary>> =
          DoorLiveDataImpl<List<PersonWithAttemptsSummary>>(_db, listOf("PersonGroupMember",
          "ScopedGrant", "Person", "StatementEntity"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.PersonWithAttemptsSummary>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT ResultSource.personUid, ResultSource.firstNames, ResultSource.lastName,
        |            COUNT(DISTINCT(ResultSource.contextRegistration)) AS attempts, 
        |            MIN(ResultSource.timestamp) AS startDate, 
        |            MAX(ResultSource.timestamp) AS endDate, 
        |            SUM(ResultSource.resultDuration) AS duration, 
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreRaw
        |                ELSE 0 END) AS resultScore, 
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreMax
        |                ELSE 0 END) AS resultMax,   
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreScaled
        |                ELSE 0 END) AS resultScaled, 
        |            MAX(ResultSource.extensionProgress) AS progress,
        |            0 AS penalty,
        |            0 as resultWeight,
        |            'FALSE' AS contentComplete,
        |            0 AS success,
        |            
        |            CASE WHEN ResultSource.resultCompletion 
        |                THEN 1 ELSE 0 END AS totalCompletedContent,
        |                
        |            1 as totalContent, 
        |            
        |            0 as fileSubmissionStatus, 
        |         
        |            '' AS latestPrivateComment
        |        
        |         FROM (SELECT Person.personUid, Person.firstNames, Person.lastName, 
        |            StatementEntity.contextRegistration, StatementEntity.timestamp, 
        |            StatementEntity.resultDuration, StatementEntity.resultScoreRaw, 
        |            StatementEntity.resultScoreMax, StatementEntity.resultScoreScaled,
        |            StatementEntity.contentEntryRoot, StatementEntity.extensionProgress, 
        |            StatementEntity.resultCompletion
        |            FROM PersonGroupMember
        |            
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 549755813888 
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |             LEFT JOIN StatementEntity 
        |                ON StatementEntity.statementPersonUid = Person.personUid 
        |                    WHERE PersonGroupMember.groupMemberPersonUid = ? 
        |                        AND PersonGroupMember.groupMemberActive  
        |                        AND statementContentEntryUid = ?
        |                        AND Person.firstNames || ' ' || Person.lastName LIKE ?              
        |                   GROUP BY StatementEntity.statementUid 
        |                   ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC) AS ResultSource 
        |         GROUP BY ResultSource.personUid 
        |         ORDER BY CASE(?) 
        |                WHEN 1 THEN ResultSource.firstNames
        |                WHEN 3 THEN ResultSource.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN ResultSource.firstNames
        |                WHEN 4 THEN ResultSource.lastName
        |                ELSE ''
        |            END DESC,
        |            CASE(?)
        |                WHEN 5 THEN endDate 
        |                ELSE 0
        |            END ASC,
        |            CASE(?)
        |                WHEN 6 then endDate
        |                ELSE 0
        |            END DESC
        |         ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT ResultSource.personUid, ResultSource.firstNames, ResultSource.lastName,
        |            COUNT(DISTINCT(ResultSource.contextRegistration)) AS attempts, 
        |            MIN(ResultSource.timestamp) AS startDate, 
        |            MAX(ResultSource.timestamp) AS endDate, 
        |            SUM(ResultSource.resultDuration) AS duration, 
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreRaw
        |                ELSE 0 END) AS resultScore, 
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreMax
        |                ELSE 0 END) AS resultMax,   
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreScaled
        |                ELSE 0 END) AS resultScaled, 
        |            MAX(ResultSource.extensionProgress) AS progress,
        |            0 AS penalty,
        |            0 as resultWeight,
        |            'FALSE' AS contentComplete,
        |            0 AS success,
        |            
        |            CASE WHEN ResultSource.resultCompletion 
        |                THEN 1 ELSE 0 END AS totalCompletedContent,
        |                
        |            1 as totalContent, 
        |            
        |            0 as fileSubmissionStatus, 
        |         
        |            '' AS latestPrivateComment
        |        
        |         FROM (SELECT Person.personUid, Person.firstNames, Person.lastName, 
        |            StatementEntity.contextRegistration, StatementEntity.timestamp, 
        |            StatementEntity.resultDuration, StatementEntity.resultScoreRaw, 
        |            StatementEntity.resultScoreMax, StatementEntity.resultScoreScaled,
        |            StatementEntity.contentEntryRoot, StatementEntity.extensionProgress, 
        |            StatementEntity.resultCompletion
        |            FROM PersonGroupMember
        |            
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 549755813888 
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |             LEFT JOIN StatementEntity 
        |                ON StatementEntity.statementPersonUid = Person.personUid 
        |                    WHERE PersonGroupMember.groupMemberPersonUid = ? 
        |                        AND PersonGroupMember.groupMemberActive  
        |                        AND statementContentEntryUid = ?
        |                        AND Person.firstNames || ' ' || Person.lastName LIKE ?              
        |                   GROUP BY StatementEntity.statementUid 
        |                   ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC) AS ResultSource 
        |         GROUP BY ResultSource.personUid 
        |         ORDER BY CASE(?) 
        |                WHEN 1 THEN ResultSource.firstNames
        |                WHEN 3 THEN ResultSource.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN ResultSource.firstNames
        |                WHEN 4 THEN ResultSource.lastName
        |                ELSE ''
        |            END DESC,
        |            CASE(?)
        |                WHEN 5 THEN endDate 
        |                ELSE 0
        |            END ASC,
        |            CASE(?)
        |                WHEN 6 then endDate
        |                ELSE 0
        |            END DESC
        |         ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.setString(3, searchText)
          _stmt.setInt(4, sortOrder)
          _stmt.setInt(5, sortOrder)
          _stmt.setInt(6, sortOrder)
          _stmt.setInt(7, sortOrder)
          _stmt.setInt(8, _limit)
          _stmt.setInt(9, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_personUid = _resultSet.getLong("personUid")
              val tmp_firstNames = _resultSet.getString("firstNames")
              val tmp_lastName = _resultSet.getString("lastName")
              val tmp_attempts = _resultSet.getInt("attempts")
              val tmp_startDate = _resultSet.getLong("startDate")
              val tmp_endDate = _resultSet.getLong("endDate")
              val tmp_duration = _resultSet.getLong("duration")
              val tmp_latestPrivateComment = _resultSet.getString("latestPrivateComment")
              val tmp_fileSubmissionStatus = _resultSet.getInt("fileSubmissionStatus")
              val _entity = PersonWithAttemptsSummary()
              _entity.personUid = tmp_personUid
              _entity.firstNames = tmp_firstNames
              _entity.lastName = tmp_lastName
              _entity.attempts = tmp_attempts
              _entity.startDate = tmp_startDate
              _entity.endDate = tmp_endDate
              _entity.duration = tmp_duration
              _entity.latestPrivateComment = tmp_latestPrivateComment
              _entity.fileSubmissionStatus = tmp_fileSubmissionStatus
              var _scoreProgress_nullFieldCount = 0
              val tmp_resultScore = _resultSet.getInt("resultScore")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultMax = _resultSet.getInt("resultMax")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultScaled = _resultSet.getFloat("resultScaled")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultWeight = _resultSet.getInt("resultWeight")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_progress = _resultSet.getInt("progress")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_success = _resultSet.getByte("success")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_penalty = _resultSet.getInt("penalty")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalContent = _resultSet.getInt("totalContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              if(_scoreProgress_nullFieldCount < 10) {
                if(_entity.scoreProgress == null) {
                  _entity.scoreProgress = ContentEntryStatementScoreProgress()
                }
                _entity.scoreProgress!!.resultScore = tmp_resultScore
                _entity.scoreProgress!!.resultMax = tmp_resultMax
                _entity.scoreProgress!!.resultScaled = tmp_resultScaled
                _entity.scoreProgress!!.resultWeight = tmp_resultWeight
                _entity.scoreProgress!!.contentComplete = tmp_contentComplete
                _entity.scoreProgress!!.progress = tmp_progress
                _entity.scoreProgress!!.success = tmp_success
                _entity.scoreProgress!!.penalty = tmp_penalty
                _entity.scoreProgress!!.totalContent = tmp_totalContent
                _entity.scoreProgress!!.totalCompletedContent = tmp_totalCompletedContent
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("PersonGroupMember", "ScopedGrant", "Person", "StatementEntity"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT ResultSource.personUid, ResultSource.firstNames, ResultSource.lastName,
        |            COUNT(DISTINCT(ResultSource.contextRegistration)) AS attempts, 
        |            MIN(ResultSource.timestamp) AS startDate, 
        |            MAX(ResultSource.timestamp) AS endDate, 
        |            SUM(ResultSource.resultDuration) AS duration, 
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreRaw
        |                ELSE 0 END) AS resultScore, 
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreMax
        |                ELSE 0 END) AS resultMax,   
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreScaled
        |                ELSE 0 END) AS resultScaled, 
        |            MAX(ResultSource.extensionProgress) AS progress,
        |            0 AS penalty,
        |            0 as resultWeight,
        |            'FALSE' AS contentComplete,
        |            0 AS success,
        |            
        |            CASE WHEN ResultSource.resultCompletion 
        |                THEN 1 ELSE 0 END AS totalCompletedContent,
        |                
        |            1 as totalContent, 
        |            
        |            0 as fileSubmissionStatus, 
        |         
        |            '' AS latestPrivateComment
        |        
        |         FROM (SELECT Person.personUid, Person.firstNames, Person.lastName, 
        |            StatementEntity.contextRegistration, StatementEntity.timestamp, 
        |            StatementEntity.resultDuration, StatementEntity.resultScoreRaw, 
        |            StatementEntity.resultScoreMax, StatementEntity.resultScoreScaled,
        |            StatementEntity.contentEntryRoot, StatementEntity.extensionProgress, 
        |            StatementEntity.resultCompletion
        |            FROM PersonGroupMember
        |            
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 549755813888 
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |             LEFT JOIN StatementEntity 
        |                ON StatementEntity.statementPersonUid = Person.personUid 
        |                    WHERE PersonGroupMember.groupMemberPersonUid = ? 
        |                        AND PersonGroupMember.groupMemberActive  
        |                        AND statementContentEntryUid = ?
        |                        AND Person.firstNames || ' ' || Person.lastName LIKE ?              
        |                   GROUP BY StatementEntity.statementUid 
        |                   ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC) AS ResultSource 
        |         GROUP BY ResultSource.personUid 
        |         ORDER BY CASE(?) 
        |                WHEN 1 THEN ResultSource.firstNames
        |                WHEN 3 THEN ResultSource.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN ResultSource.firstNames
        |                WHEN 4 THEN ResultSource.lastName
        |                ELSE ''
        |            END DESC,
        |            CASE(?)
        |                WHEN 5 THEN endDate 
        |                ELSE 0
        |            END ASC,
        |            CASE(?)
        |                WHEN 6 then endDate
        |                ELSE 0
        |            END DESC
        |         ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT ResultSource.personUid, ResultSource.firstNames, ResultSource.lastName,
        |            COUNT(DISTINCT(ResultSource.contextRegistration)) AS attempts, 
        |            MIN(ResultSource.timestamp) AS startDate, 
        |            MAX(ResultSource.timestamp) AS endDate, 
        |            SUM(ResultSource.resultDuration) AS duration, 
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreRaw
        |                ELSE 0 END) AS resultScore, 
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreMax
        |                ELSE 0 END) AS resultMax,   
        |            MAX(CASE WHEN ResultSource.contentEntryRoot 
        |                THEN resultScoreScaled
        |                ELSE 0 END) AS resultScaled, 
        |            MAX(ResultSource.extensionProgress) AS progress,
        |            0 AS penalty,
        |            0 as resultWeight,
        |            'FALSE' AS contentComplete,
        |            0 AS success,
        |            
        |            CASE WHEN ResultSource.resultCompletion 
        |                THEN 1 ELSE 0 END AS totalCompletedContent,
        |                
        |            1 as totalContent, 
        |            
        |            0 as fileSubmissionStatus, 
        |         
        |            '' AS latestPrivateComment
        |        
        |         FROM (SELECT Person.personUid, Person.firstNames, Person.lastName, 
        |            StatementEntity.contextRegistration, StatementEntity.timestamp, 
        |            StatementEntity.resultDuration, StatementEntity.resultScoreRaw, 
        |            StatementEntity.resultScoreMax, StatementEntity.resultScoreScaled,
        |            StatementEntity.contentEntryRoot, StatementEntity.extensionProgress, 
        |            StatementEntity.resultCompletion
        |            FROM PersonGroupMember
        |            
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 549755813888 
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |             LEFT JOIN StatementEntity 
        |                ON StatementEntity.statementPersonUid = Person.personUid 
        |                    WHERE PersonGroupMember.groupMemberPersonUid = ? 
        |                        AND PersonGroupMember.groupMemberActive  
        |                        AND statementContentEntryUid = ?
        |                        AND Person.firstNames || ' ' || Person.lastName LIKE ?              
        |                   GROUP BY StatementEntity.statementUid 
        |                   ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC) AS ResultSource 
        |         GROUP BY ResultSource.personUid 
        |         ORDER BY CASE(?) 
        |                WHEN 1 THEN ResultSource.firstNames
        |                WHEN 3 THEN ResultSource.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN ResultSource.firstNames
        |                WHEN 4 THEN ResultSource.lastName
        |                ELSE ''
        |            END DESC,
        |            CASE(?)
        |                WHEN 5 THEN endDate 
        |                ELSE 0
        |            END ASC,
        |            CASE(?)
        |                WHEN 6 then endDate
        |                ELSE 0
        |            END DESC
        |         ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.setString(3, searchText)
          _stmt.setInt(4, sortOrder)
          _stmt.setInt(5, sortOrder)
          _stmt.setInt(6, sortOrder)
          _stmt.setInt(7, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun getBestScoreForContentForPerson(contentEntryUid: Long,
      accountPersonUid: Long): ContentEntryStatementScoreProgress? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT 
    |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
    |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
    |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
    |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
    |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
    |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
    |                0 as resultWeight,
    |                
    |                COALESCE((CASE WHEN resultCompletion 
    |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
    |                
    |                1 as totalContent, 
    |                0 as penalty
    |                
    |        FROM ContentEntry
    |            LEFT JOIN StatementEntity
    |							ON StatementEntity.statementUid = 
    |                                (SELECT statementUid 
    |							       FROM StatementEntity 
    |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
    |							        AND StatementEntity.statementPersonUid = ?
    |							        AND contentEntryRoot 
    |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
    |                               
    |       WHERE contentEntryUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT 
    |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
    |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
    |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
    |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
    |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
    |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
    |                0 as resultWeight,
    |                
    |                COALESCE((CASE WHEN resultCompletion 
    |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
    |                
    |                1 as totalContent, 
    |                0 as penalty
    |                
    |        FROM ContentEntry
    |            LEFT JOIN StatementEntity
    |							ON StatementEntity.statementUid = 
    |                                (SELECT statementUid 
    |							       FROM StatementEntity 
    |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
    |							        AND StatementEntity.statementPersonUid = ?
    |							        AND contentEntryRoot 
    |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
    |                               
    |       WHERE contentEntryUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, accountPersonUid)
      _stmt.setLong(2, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_resultScore = _resultSet.getInt("resultScore")
          val tmp_resultMax = _resultSet.getInt("resultMax")
          val tmp_resultScaled = _resultSet.getFloat("resultScaled")
          val tmp_resultWeight = _resultSet.getInt("resultWeight")
          val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
          val tmp_progress = _resultSet.getInt("progress")
          val tmp_success = _resultSet.getByte("success")
          val tmp_penalty = _resultSet.getInt("penalty")
          val tmp_totalContent = _resultSet.getInt("totalContent")
          val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
          val _entity = ContentEntryStatementScoreProgress()
          _entity.resultScore = tmp_resultScore
          _entity.resultMax = tmp_resultMax
          _entity.resultScaled = tmp_resultScaled
          _entity.resultWeight = tmp_resultWeight
          _entity.contentComplete = tmp_contentComplete
          _entity.progress = tmp_progress
          _entity.success = tmp_success
          _entity.penalty = tmp_penalty
          _entity.totalContent = tmp_totalContent
          _entity.totalCompletedContent = tmp_totalCompletedContent
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findNextStudentNotMarkedForAssignment(assignmentUid: Long,
      currentStudentUid: Long): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         SELECT COALESCE((
    |                SELECT DISTINCT(statementpersonUid)
    |                  FROM ClazzAssignment 
    |                      JOIN ClazzEnrolment
    |                       ON ClazzEnrolment.clazzEnrolmentClazzUid = ClazzAssignment.caClazzUid
    |                       
    |                       JOIN CourseBlock
    |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |                       AND CourseBlock.cbType = 103
    |                       
    |          	           JOIN StatementEntity AS SubmissionStatement
    |          	           ON SubmissionStatement.statementUid = (SELECT statementUid 
    |                                   FROM StatementEntity
    |                                  WHERE StatementEntity.statementContentEntryUid = 0
    |                                    AND xObjectUid = ClazzAssignment.caXObjectUid
    |                                    AND StatementEntity.statementPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                                    AND StatementEntity.timestamp 
    |                                        BETWEEN CourseBlock.cbHideUntilDate
    |                                        AND CourseBlock.cbGracePeriodDate
    |                               ORDER BY timestamp DESC LIMIT 1)
    |                               
    |          	           LEFT JOIN XObjectEntity
    |                       ON XObjectEntity.objectStatementRefUid = SubmissionStatement.statementUid  
    |               
    |                 WHERE ClazzAssignment.caUid = ?
    |                   AND XObjectEntity.xobjectUid IS NULL
    |                   AND ClazzEnrolment.clazzEnrolmentActive
    |                   AND ClazzEnrolment.clazzEnrolmentRole = 1000
    |                   AND ClazzEnrolment.clazzEnrolmentPersonUid != ?
    |            LIMIT 1),0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |         SELECT COALESCE((
    |                SELECT DISTINCT(statementpersonUid)
    |                  FROM ClazzAssignment 
    |                      JOIN ClazzEnrolment
    |                       ON ClazzEnrolment.clazzEnrolmentClazzUid = ClazzAssignment.caClazzUid
    |                       
    |                       JOIN CourseBlock
    |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |                       AND CourseBlock.cbType = 103
    |                       
    |          	           JOIN StatementEntity AS SubmissionStatement
    |          	           ON SubmissionStatement.statementUid = (SELECT statementUid 
    |                                   FROM StatementEntity
    |                                  WHERE StatementEntity.statementContentEntryUid = 0
    |                                    AND xObjectUid = ClazzAssignment.caXObjectUid
    |                                    AND StatementEntity.statementPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                                    AND StatementEntity.timestamp 
    |                                        BETWEEN CourseBlock.cbHideUntilDate
    |                                        AND CourseBlock.cbGracePeriodDate
    |                               ORDER BY timestamp DESC LIMIT 1)
    |                               
    |          	           LEFT JOIN XObjectEntity
    |                       ON XObjectEntity.objectStatementRefUid = SubmissionStatement.statementUid  
    |               
    |                 WHERE ClazzAssignment.caUid = ?
    |                   AND XObjectEntity.xobjectUid IS NULL
    |                   AND ClazzEnrolment.clazzEnrolmentActive
    |                   AND ClazzEnrolment.clazzEnrolmentRole = 1000
    |                   AND ClazzEnrolment.clazzEnrolmentPersonUid != ?
    |            LIMIT 1),0)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, assignmentUid)
      _stmt.setLong(2, currentStudentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findSubmittedStatementFromStudent(studentUid: Long,
      assignmentObjectUid: Long): StatementEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.StatementEntity??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM StatementEntity
    |         WHERE statementPersonUid = ?
    |           AND statementVerbUid = 10008
    |           AND xObjectUid = ?    
    |      ORDER BY timestamp                
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM StatementEntity
    |         WHERE statementPersonUid = ?
    |           AND statementVerbUid = 10008
    |           AND xObjectUid = ?    
    |      ORDER BY timestamp                
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, studentUid)
      _stmt.setLong(2, assignmentObjectUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_statementUid = _resultSet.getLong("statementUid")
          val tmp_statementId = _resultSet.getString("statementId")
          val tmp_statementPersonUid = _resultSet.getLong("statementPersonUid")
          val tmp_statementVerbUid = _resultSet.getLong("statementVerbUid")
          val tmp_xObjectUid = _resultSet.getLong("xObjectUid")
          val tmp_subStatementActorUid = _resultSet.getLong("subStatementActorUid")
          val tmp_substatementVerbUid = _resultSet.getLong("substatementVerbUid")
          val tmp_subStatementObjectUid = _resultSet.getLong("subStatementObjectUid")
          val tmp_agentUid = _resultSet.getLong("agentUid")
          val tmp_instructorUid = _resultSet.getLong("instructorUid")
          val tmp_authorityUid = _resultSet.getLong("authorityUid")
          val tmp_teamUid = _resultSet.getLong("teamUid")
          val tmp_resultCompletion = _resultSet.getBoolean("resultCompletion")
          val tmp_resultSuccess = _resultSet.getByte("resultSuccess")
          val tmp_resultScoreScaled = _resultSet.getFloat("resultScoreScaled")
          val tmp_resultScoreRaw = _resultSet.getLong("resultScoreRaw")
          val tmp_resultScoreMin = _resultSet.getLong("resultScoreMin")
          val tmp_resultScoreMax = _resultSet.getLong("resultScoreMax")
          val tmp_resultDuration = _resultSet.getLong("resultDuration")
          val tmp_resultResponse = _resultSet.getString("resultResponse")
          val tmp_timestamp = _resultSet.getLong("timestamp")
          val tmp_stored = _resultSet.getLong("stored")
          val tmp_contextRegistration = _resultSet.getString("contextRegistration")
          val tmp_contextPlatform = _resultSet.getString("contextPlatform")
          val tmp_contextStatementId = _resultSet.getString("contextStatementId")
          val tmp_fullStatement = _resultSet.getString("fullStatement")
          val tmp_statementMasterChangeSeqNum = _resultSet.getLong("statementMasterChangeSeqNum")
          val tmp_statementLocalChangeSeqNum = _resultSet.getLong("statementLocalChangeSeqNum")
          val tmp_statementLastChangedBy = _resultSet.getInt("statementLastChangedBy")
          val tmp_statementLct = _resultSet.getLong("statementLct")
          val tmp_extensionProgress = _resultSet.getInt("extensionProgress")
          val tmp_contentEntryRoot = _resultSet.getBoolean("contentEntryRoot")
          val tmp_statementContentEntryUid = _resultSet.getLong("statementContentEntryUid")
          val tmp_statementLearnerGroupUid = _resultSet.getLong("statementLearnerGroupUid")
          val tmp_statementClazzUid = _resultSet.getLong("statementClazzUid")
          val _entity = StatementEntity()
          _entity.statementUid = tmp_statementUid
          _entity.statementId = tmp_statementId
          _entity.statementPersonUid = tmp_statementPersonUid
          _entity.statementVerbUid = tmp_statementVerbUid
          _entity.xObjectUid = tmp_xObjectUid
          _entity.subStatementActorUid = tmp_subStatementActorUid
          _entity.substatementVerbUid = tmp_substatementVerbUid
          _entity.subStatementObjectUid = tmp_subStatementObjectUid
          _entity.agentUid = tmp_agentUid
          _entity.instructorUid = tmp_instructorUid
          _entity.authorityUid = tmp_authorityUid
          _entity.teamUid = tmp_teamUid
          _entity.resultCompletion = tmp_resultCompletion
          _entity.resultSuccess = tmp_resultSuccess
          _entity.resultScoreScaled = tmp_resultScoreScaled
          _entity.resultScoreRaw = tmp_resultScoreRaw
          _entity.resultScoreMin = tmp_resultScoreMin
          _entity.resultScoreMax = tmp_resultScoreMax
          _entity.resultDuration = tmp_resultDuration
          _entity.resultResponse = tmp_resultResponse
          _entity.timestamp = tmp_timestamp
          _entity.stored = tmp_stored
          _entity.contextRegistration = tmp_contextRegistration
          _entity.contextPlatform = tmp_contextPlatform
          _entity.contextStatementId = tmp_contextStatementId
          _entity.fullStatement = tmp_fullStatement
          _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
          _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
          _entity.statementLastChangedBy = tmp_statementLastChangedBy
          _entity.statementLct = tmp_statementLct
          _entity.extensionProgress = tmp_extensionProgress
          _entity.contentEntryRoot = tmp_contentEntryRoot
          _entity.statementContentEntryUid = tmp_statementContentEntryUid
          _entity.statementLearnerGroupUid = tmp_statementLearnerGroupUid
          _entity.statementClazzUid = tmp_statementClazzUid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findScoreStatementForStudent(studentUid: Long): StatementEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.StatementEntity??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM StatementEntity
    |         WHERE statementPersonUid = ?
    |           AND statementVerbUid = 10009
    |      ORDER BY timestamp                
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM StatementEntity
    |         WHERE statementPersonUid = ?
    |           AND statementVerbUid = 10009
    |      ORDER BY timestamp                
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, studentUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_statementUid = _resultSet.getLong("statementUid")
          val tmp_statementId = _resultSet.getString("statementId")
          val tmp_statementPersonUid = _resultSet.getLong("statementPersonUid")
          val tmp_statementVerbUid = _resultSet.getLong("statementVerbUid")
          val tmp_xObjectUid = _resultSet.getLong("xObjectUid")
          val tmp_subStatementActorUid = _resultSet.getLong("subStatementActorUid")
          val tmp_substatementVerbUid = _resultSet.getLong("substatementVerbUid")
          val tmp_subStatementObjectUid = _resultSet.getLong("subStatementObjectUid")
          val tmp_agentUid = _resultSet.getLong("agentUid")
          val tmp_instructorUid = _resultSet.getLong("instructorUid")
          val tmp_authorityUid = _resultSet.getLong("authorityUid")
          val tmp_teamUid = _resultSet.getLong("teamUid")
          val tmp_resultCompletion = _resultSet.getBoolean("resultCompletion")
          val tmp_resultSuccess = _resultSet.getByte("resultSuccess")
          val tmp_resultScoreScaled = _resultSet.getFloat("resultScoreScaled")
          val tmp_resultScoreRaw = _resultSet.getLong("resultScoreRaw")
          val tmp_resultScoreMin = _resultSet.getLong("resultScoreMin")
          val tmp_resultScoreMax = _resultSet.getLong("resultScoreMax")
          val tmp_resultDuration = _resultSet.getLong("resultDuration")
          val tmp_resultResponse = _resultSet.getString("resultResponse")
          val tmp_timestamp = _resultSet.getLong("timestamp")
          val tmp_stored = _resultSet.getLong("stored")
          val tmp_contextRegistration = _resultSet.getString("contextRegistration")
          val tmp_contextPlatform = _resultSet.getString("contextPlatform")
          val tmp_contextStatementId = _resultSet.getString("contextStatementId")
          val tmp_fullStatement = _resultSet.getString("fullStatement")
          val tmp_statementMasterChangeSeqNum = _resultSet.getLong("statementMasterChangeSeqNum")
          val tmp_statementLocalChangeSeqNum = _resultSet.getLong("statementLocalChangeSeqNum")
          val tmp_statementLastChangedBy = _resultSet.getInt("statementLastChangedBy")
          val tmp_statementLct = _resultSet.getLong("statementLct")
          val tmp_extensionProgress = _resultSet.getInt("extensionProgress")
          val tmp_contentEntryRoot = _resultSet.getBoolean("contentEntryRoot")
          val tmp_statementContentEntryUid = _resultSet.getLong("statementContentEntryUid")
          val tmp_statementLearnerGroupUid = _resultSet.getLong("statementLearnerGroupUid")
          val tmp_statementClazzUid = _resultSet.getLong("statementClazzUid")
          val _entity = StatementEntity()
          _entity.statementUid = tmp_statementUid
          _entity.statementId = tmp_statementId
          _entity.statementPersonUid = tmp_statementPersonUid
          _entity.statementVerbUid = tmp_statementVerbUid
          _entity.xObjectUid = tmp_xObjectUid
          _entity.subStatementActorUid = tmp_subStatementActorUid
          _entity.substatementVerbUid = tmp_substatementVerbUid
          _entity.subStatementObjectUid = tmp_subStatementObjectUid
          _entity.agentUid = tmp_agentUid
          _entity.instructorUid = tmp_instructorUid
          _entity.authorityUid = tmp_authorityUid
          _entity.teamUid = tmp_teamUid
          _entity.resultCompletion = tmp_resultCompletion
          _entity.resultSuccess = tmp_resultSuccess
          _entity.resultScoreScaled = tmp_resultScoreScaled
          _entity.resultScoreRaw = tmp_resultScoreRaw
          _entity.resultScoreMin = tmp_resultScoreMin
          _entity.resultScoreMax = tmp_resultScoreMax
          _entity.resultDuration = tmp_resultDuration
          _entity.resultResponse = tmp_resultResponse
          _entity.timestamp = tmp_timestamp
          _entity.stored = tmp_stored
          _entity.contextRegistration = tmp_contextRegistration
          _entity.contextPlatform = tmp_contextPlatform
          _entity.contextStatementId = tmp_contextStatementId
          _entity.fullStatement = tmp_fullStatement
          _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
          _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
          _entity.statementLastChangedBy = tmp_statementLastChangedBy
          _entity.statementLct = tmp_statementLct
          _entity.extensionProgress = tmp_extensionProgress
          _entity.contentEntryRoot = tmp_contentEntryRoot
          _entity.statementContentEntryUid = tmp_statementContentEntryUid
          _entity.statementLearnerGroupUid = tmp_statementLearnerGroupUid
          _entity.statementClazzUid = tmp_statementClazzUid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findSessionsForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long
  ): DoorDataSourceFactory<Int, PersonWithSessionsDisplay> {
    val _result = object : DoorDataSourceFactory<Int, PersonWithSessionsDisplay>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<PersonWithSessionsDisplay>> =
          DoorLiveDataImpl<List<PersonWithSessionsDisplay>>(_db, listOf("StatementEntity",
          "ScopedGrant", "PersonGroupMember"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.PersonWithSessionsDisplay>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT MIN(timestamp) AS startDate, 
        |            MAX(CASE 
        |                    WHEN StatementEntity.resultSuccess > 0 
        |                    AND StatementEntity.contentEntryRoot 
        |                    THEN StatementEntity.resultSuccess 
        |                    ELSE 0 END) AS resultSuccess, 
        |            SUM(CASE 
        |                     WHEN CAST(resultCompletion AS INTEGER) > 0 
        |                     AND StatementEntity.contentEntryRoot 
        |                     THEN 1 
        |                     ELSE 0 END) AS resultComplete, 
        |            SUM(resultDuration) AS duration, contextRegistration, 
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreRaw ELSE 0 END) AS resultScore, 
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreMax ELSE 0 END) AS resultMax,
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreScaled ELSE 0 END) AS resultScoreScaled,
        |                       
        |            SUM(CASE WHEN resultCompletion AND StatementEntity.contentEntryRoot 
        |                THEN 1 ELSE 0 END) AS totalCompletedContent,
        |                
        |             1 as totalContent          
        |                       
        |        FROM StatementEntity 
        |             JOIN ScopedGrant 
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                AND ScopedGrant.sgEntityUid = -2)
        |             OR (ScopedGrant.sgTableId = 9
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
        |             OR (ScopedGrant.sgTableId = 6
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
        |             OR (ScopedGrant.sgTableId = 164
        |                AND ScopedGrant.sgEntityUid = (
        |                    SELECT clazzSchoolUid
        |                      FROM Clazz
        |                     WHERE clazzUid = StatementEntity.statementClazzUid))
        |             )
        |        
        |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
        |             JOIN PersonGroupMember 
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
        |                AND PersonGroupMember.groupMemberPersonUid = ?
        |        WHERE statementContentEntryUid = ?   
        |          AND statementPersonUid = ? 
        |        GROUP BY StatementEntity.contextRegistration 
        |        ORDER BY startDate DESC, resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC
        |         ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT MIN(timestamp) AS startDate, 
        |            MAX(CASE 
        |                    WHEN StatementEntity.resultSuccess > 0 
        |                    AND StatementEntity.contentEntryRoot 
        |                    THEN StatementEntity.resultSuccess 
        |                    ELSE 0 END) AS resultSuccess, 
        |            SUM(CASE 
        |                     WHEN CAST(resultCompletion AS INTEGER) > 0 
        |                     AND StatementEntity.contentEntryRoot 
        |                     THEN 1 
        |                     ELSE 0 END) AS resultComplete, 
        |            SUM(resultDuration) AS duration, contextRegistration, 
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreRaw ELSE 0 END) AS resultScore, 
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreMax ELSE 0 END) AS resultMax,
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreScaled ELSE 0 END) AS resultScoreScaled,
        |                       
        |            SUM(CASE WHEN resultCompletion AND StatementEntity.contentEntryRoot 
        |                THEN 1 ELSE 0 END) AS totalCompletedContent,
        |                
        |             1 as totalContent          
        |                       
        |        FROM StatementEntity 
        |             JOIN ScopedGrant 
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                AND ScopedGrant.sgEntityUid = -2)
        |             OR (ScopedGrant.sgTableId = 9
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
        |             OR (ScopedGrant.sgTableId = 6
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
        |             OR (ScopedGrant.sgTableId = 164
        |                AND ScopedGrant.sgEntityUid = (
        |                    SELECT clazzSchoolUid
        |                      FROM Clazz
        |                     WHERE clazzUid = StatementEntity.statementClazzUid))
        |             )
        |        
        |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
        |             JOIN PersonGroupMember 
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
        |                AND PersonGroupMember.groupMemberPersonUid = ?
        |        WHERE statementContentEntryUid = ?   
        |          AND statementPersonUid = ? 
        |        GROUP BY StatementEntity.contextRegistration 
        |        ORDER BY startDate DESC, resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC
        |         ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.setLong(3, personUid)
          _stmt.setInt(4, _limit)
          _stmt.setInt(5, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_startDate = _resultSet.getLong("startDate")
              val tmp_contextRegistration = _resultSet.getString("contextRegistration")
              val tmp_duration = _resultSet.getLong("duration")
              val tmp_resultSuccess = _resultSet.getByte("resultSuccess")
              val tmp_resultComplete = _resultSet.getBoolean("resultComplete")
              val tmp_resultScoreScaled = _resultSet.getFloat("resultScoreScaled")
              val tmp_resultMax = _resultSet.getInt("resultMax")
              val tmp_resultScore = _resultSet.getInt("resultScore")
              val _entity = PersonWithSessionsDisplay()
              _entity.startDate = tmp_startDate
              _entity.contextRegistration = tmp_contextRegistration
              _entity.duration = tmp_duration
              _entity.resultSuccess = tmp_resultSuccess
              _entity.resultComplete = tmp_resultComplete
              _entity.resultScoreScaled = tmp_resultScoreScaled
              _entity.resultMax = tmp_resultMax
              _entity.resultScore = tmp_resultScore
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("StatementEntity", "ScopedGrant", "PersonGroupMember"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT MIN(timestamp) AS startDate, 
        |            MAX(CASE 
        |                    WHEN StatementEntity.resultSuccess > 0 
        |                    AND StatementEntity.contentEntryRoot 
        |                    THEN StatementEntity.resultSuccess 
        |                    ELSE 0 END) AS resultSuccess, 
        |            SUM(CASE 
        |                     WHEN CAST(resultCompletion AS INTEGER) > 0 
        |                     AND StatementEntity.contentEntryRoot 
        |                     THEN 1 
        |                     ELSE 0 END) AS resultComplete, 
        |            SUM(resultDuration) AS duration, contextRegistration, 
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreRaw ELSE 0 END) AS resultScore, 
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreMax ELSE 0 END) AS resultMax,
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreScaled ELSE 0 END) AS resultScoreScaled,
        |                       
        |            SUM(CASE WHEN resultCompletion AND StatementEntity.contentEntryRoot 
        |                THEN 1 ELSE 0 END) AS totalCompletedContent,
        |                
        |             1 as totalContent          
        |                       
        |        FROM StatementEntity 
        |             JOIN ScopedGrant 
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                AND ScopedGrant.sgEntityUid = -2)
        |             OR (ScopedGrant.sgTableId = 9
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
        |             OR (ScopedGrant.sgTableId = 6
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
        |             OR (ScopedGrant.sgTableId = 164
        |                AND ScopedGrant.sgEntityUid = (
        |                    SELECT clazzSchoolUid
        |                      FROM Clazz
        |                     WHERE clazzUid = StatementEntity.statementClazzUid))
        |             )
        |        
        |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
        |             JOIN PersonGroupMember 
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
        |                AND PersonGroupMember.groupMemberPersonUid = ?
        |        WHERE statementContentEntryUid = ?   
        |          AND statementPersonUid = ? 
        |        GROUP BY StatementEntity.contextRegistration 
        |        ORDER BY startDate DESC, resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC
        |         ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT MIN(timestamp) AS startDate, 
        |            MAX(CASE 
        |                    WHEN StatementEntity.resultSuccess > 0 
        |                    AND StatementEntity.contentEntryRoot 
        |                    THEN StatementEntity.resultSuccess 
        |                    ELSE 0 END) AS resultSuccess, 
        |            SUM(CASE 
        |                     WHEN CAST(resultCompletion AS INTEGER) > 0 
        |                     AND StatementEntity.contentEntryRoot 
        |                     THEN 1 
        |                     ELSE 0 END) AS resultComplete, 
        |            SUM(resultDuration) AS duration, contextRegistration, 
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreRaw ELSE 0 END) AS resultScore, 
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreMax ELSE 0 END) AS resultMax,
        |            MAX(CASE WHEN contentEntryRoot 
        |                     THEN resultScoreScaled ELSE 0 END) AS resultScoreScaled,
        |                       
        |            SUM(CASE WHEN resultCompletion AND StatementEntity.contentEntryRoot 
        |                THEN 1 ELSE 0 END) AS totalCompletedContent,
        |                
        |             1 as totalContent          
        |                       
        |        FROM StatementEntity 
        |             JOIN ScopedGrant 
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                AND ScopedGrant.sgEntityUid = -2)
        |             OR (ScopedGrant.sgTableId = 9
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
        |             OR (ScopedGrant.sgTableId = 6
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
        |             OR (ScopedGrant.sgTableId = 164
        |                AND ScopedGrant.sgEntityUid = (
        |                    SELECT clazzSchoolUid
        |                      FROM Clazz
        |                     WHERE clazzUid = StatementEntity.statementClazzUid))
        |             )
        |        
        |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
        |             JOIN PersonGroupMember 
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
        |                AND PersonGroupMember.groupMemberPersonUid = ?
        |        WHERE statementContentEntryUid = ?   
        |          AND statementPersonUid = ? 
        |        GROUP BY StatementEntity.contextRegistration 
        |        ORDER BY startDate DESC, resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC
        |         ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.setLong(3, personUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findSessionDetailForPerson(
    contentEntryUid: Long,
    accountPersonUid: Long,
    personUid: Long,
    contextRegistration: String
  ): DoorDataSourceFactory<Int, StatementWithSessionDetailDisplay> {
    val _result = object : DoorDataSourceFactory<Int, StatementWithSessionDetailDisplay>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<StatementWithSessionDetailDisplay>> =
          DoorLiveDataImpl<List<StatementWithSessionDetailDisplay>>(_db, listOf("StatementEntity",
          "ScopedGrant", "PersonGroupMember", "VerbEntity", "XLangMapEntry"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.StatementWithSessionDetailDisplay>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT StatementEntity.*, VerbEntity.*, 
        |            verbLangMap.valueLangMap AS verbDisplay, 
        |            xobjectMap.valueLangMap AS objectDisplay 
        |        FROM StatementEntity
        |                 JOIN ScopedGrant 
        |                    ON 
        |            ((ScopedGrant.sgTableId = -2
        |                AND ScopedGrant.sgEntityUid = -2)
        |             OR (ScopedGrant.sgTableId = 9
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
        |             OR (ScopedGrant.sgTableId = 6
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
        |             OR (ScopedGrant.sgTableId = 164
        |                AND ScopedGrant.sgEntityUid = (
        |                    SELECT clazzSchoolUid
        |                      FROM Clazz
        |                     WHERE clazzUid = StatementEntity.statementClazzUid))
        |             )
        |        
        |                    AND (ScopedGrant.sgPermissions & 549755813888) > 0
        |                 JOIN PersonGroupMember 
        |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
        |                AND PersonGroupMember.groupMemberPersonUid = ?
        |                LEFT JOIN VerbEntity 
        |                    ON VerbEntity.verbUid = StatementEntity.statementVerbUid 
        |                LEFT JOIN XLangMapEntry verbLangMap 
        |                    ON verbLangMap.verbLangMapUid = VerbEntity.verbUid
        |                LEFT JOIN XLangMapEntry xobjectMap 
        |                    ON xobjectMap.objectLangMapUid = StatementEntity.xObjectUid
        |         WHERE statementContentEntryUid = ? 
        |            AND statementPersonUid = ? 
        |            AND contextRegistration = ? 
        |         ORDER BY StatementEntity.timestamp DESC
        |         ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT StatementEntity.*, VerbEntity.*, 
        |            verbLangMap.valueLangMap AS verbDisplay, 
        |            xobjectMap.valueLangMap AS objectDisplay 
        |        FROM StatementEntity
        |                 JOIN ScopedGrant 
        |                    ON 
        |            ((ScopedGrant.sgTableId = -2
        |                AND ScopedGrant.sgEntityUid = -2)
        |             OR (ScopedGrant.sgTableId = 9
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
        |             OR (ScopedGrant.sgTableId = 6
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
        |             OR (ScopedGrant.sgTableId = 164
        |                AND ScopedGrant.sgEntityUid = (
        |                    SELECT clazzSchoolUid
        |                      FROM Clazz
        |                     WHERE clazzUid = StatementEntity.statementClazzUid))
        |             )
        |        
        |                    AND (ScopedGrant.sgPermissions & 549755813888) > 0
        |                 JOIN PersonGroupMember 
        |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
        |                AND PersonGroupMember.groupMemberPersonUid = ?
        |                LEFT JOIN VerbEntity 
        |                    ON VerbEntity.verbUid = StatementEntity.statementVerbUid 
        |                LEFT JOIN XLangMapEntry verbLangMap 
        |                    ON verbLangMap.verbLangMapUid = VerbEntity.verbUid
        |                LEFT JOIN XLangMapEntry xobjectMap 
        |                    ON xobjectMap.objectLangMapUid = StatementEntity.xObjectUid
        |         WHERE statementContentEntryUid = ? 
        |            AND statementPersonUid = ? 
        |            AND contextRegistration = ? 
        |         ORDER BY StatementEntity.timestamp DESC
        |         ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.setLong(3, personUid)
          _stmt.setString(4, contextRegistration)
          _stmt.setInt(5, _limit)
          _stmt.setInt(6, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_verbDisplay = _resultSet.getString("verbDisplay")
              val tmp_objectDisplay = _resultSet.getString("objectDisplay")
              val tmp_statementUid = _resultSet.getLong("statementUid")
              val tmp_statementId = _resultSet.getString("statementId")
              val tmp_statementPersonUid = _resultSet.getLong("statementPersonUid")
              val tmp_statementVerbUid = _resultSet.getLong("statementVerbUid")
              val tmp_xObjectUid = _resultSet.getLong("xObjectUid")
              val tmp_subStatementActorUid = _resultSet.getLong("subStatementActorUid")
              val tmp_substatementVerbUid = _resultSet.getLong("substatementVerbUid")
              val tmp_subStatementObjectUid = _resultSet.getLong("subStatementObjectUid")
              val tmp_agentUid = _resultSet.getLong("agentUid")
              val tmp_instructorUid = _resultSet.getLong("instructorUid")
              val tmp_authorityUid = _resultSet.getLong("authorityUid")
              val tmp_teamUid = _resultSet.getLong("teamUid")
              val tmp_resultCompletion = _resultSet.getBoolean("resultCompletion")
              val tmp_resultSuccess = _resultSet.getByte("resultSuccess")
              val tmp_resultScoreScaled = _resultSet.getFloat("resultScoreScaled")
              val tmp_resultScoreRaw = _resultSet.getLong("resultScoreRaw")
              val tmp_resultScoreMin = _resultSet.getLong("resultScoreMin")
              val tmp_resultScoreMax = _resultSet.getLong("resultScoreMax")
              val tmp_resultDuration = _resultSet.getLong("resultDuration")
              val tmp_resultResponse = _resultSet.getString("resultResponse")
              val tmp_timestamp = _resultSet.getLong("timestamp")
              val tmp_stored = _resultSet.getLong("stored")
              val tmp_contextRegistration = _resultSet.getString("contextRegistration")
              val tmp_contextPlatform = _resultSet.getString("contextPlatform")
              val tmp_contextStatementId = _resultSet.getString("contextStatementId")
              val tmp_fullStatement = _resultSet.getString("fullStatement")
              val tmp_statementMasterChangeSeqNum =
                  _resultSet.getLong("statementMasterChangeSeqNum")
              val tmp_statementLocalChangeSeqNum = _resultSet.getLong("statementLocalChangeSeqNum")
              val tmp_statementLastChangedBy = _resultSet.getInt("statementLastChangedBy")
              val tmp_statementLct = _resultSet.getLong("statementLct")
              val tmp_extensionProgress = _resultSet.getInt("extensionProgress")
              val tmp_contentEntryRoot = _resultSet.getBoolean("contentEntryRoot")
              val tmp_statementContentEntryUid = _resultSet.getLong("statementContentEntryUid")
              val tmp_statementLearnerGroupUid = _resultSet.getLong("statementLearnerGroupUid")
              val tmp_statementClazzUid = _resultSet.getLong("statementClazzUid")
              val _entity = StatementWithSessionDetailDisplay()
              _entity.verbDisplay = tmp_verbDisplay
              _entity.objectDisplay = tmp_objectDisplay
              _entity.statementUid = tmp_statementUid
              _entity.statementId = tmp_statementId
              _entity.statementPersonUid = tmp_statementPersonUid
              _entity.statementVerbUid = tmp_statementVerbUid
              _entity.xObjectUid = tmp_xObjectUid
              _entity.subStatementActorUid = tmp_subStatementActorUid
              _entity.substatementVerbUid = tmp_substatementVerbUid
              _entity.subStatementObjectUid = tmp_subStatementObjectUid
              _entity.agentUid = tmp_agentUid
              _entity.instructorUid = tmp_instructorUid
              _entity.authorityUid = tmp_authorityUid
              _entity.teamUid = tmp_teamUid
              _entity.resultCompletion = tmp_resultCompletion
              _entity.resultSuccess = tmp_resultSuccess
              _entity.resultScoreScaled = tmp_resultScoreScaled
              _entity.resultScoreRaw = tmp_resultScoreRaw
              _entity.resultScoreMin = tmp_resultScoreMin
              _entity.resultScoreMax = tmp_resultScoreMax
              _entity.resultDuration = tmp_resultDuration
              _entity.resultResponse = tmp_resultResponse
              _entity.timestamp = tmp_timestamp
              _entity.stored = tmp_stored
              _entity.contextRegistration = tmp_contextRegistration
              _entity.contextPlatform = tmp_contextPlatform
              _entity.contextStatementId = tmp_contextStatementId
              _entity.fullStatement = tmp_fullStatement
              _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
              _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
              _entity.statementLastChangedBy = tmp_statementLastChangedBy
              _entity.statementLct = tmp_statementLct
              _entity.extensionProgress = tmp_extensionProgress
              _entity.contentEntryRoot = tmp_contentEntryRoot
              _entity.statementContentEntryUid = tmp_statementContentEntryUid
              _entity.statementLearnerGroupUid = tmp_statementLearnerGroupUid
              _entity.statementClazzUid = tmp_statementClazzUid
              var _verb_nullFieldCount = 0
              val tmp_verbUid = _resultSet.getLong("verbUid")
              if(_resultSet.wasNull()) { _verb_nullFieldCount++ }
              val tmp_urlId = _resultSet.getString("urlId")
              if(_resultSet.wasNull()) { _verb_nullFieldCount++ }
              val tmp_verbInActive = _resultSet.getBoolean("verbInActive")
              if(_resultSet.wasNull()) { _verb_nullFieldCount++ }
              val tmp_verbMasterChangeSeqNum = _resultSet.getLong("verbMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _verb_nullFieldCount++ }
              val tmp_verbLocalChangeSeqNum = _resultSet.getLong("verbLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _verb_nullFieldCount++ }
              val tmp_verbLastChangedBy = _resultSet.getInt("verbLastChangedBy")
              if(_resultSet.wasNull()) { _verb_nullFieldCount++ }
              val tmp_verbLct = _resultSet.getLong("verbLct")
              if(_resultSet.wasNull()) { _verb_nullFieldCount++ }
              if(_verb_nullFieldCount < 7) {
                if(_entity.verb == null) {
                  _entity.verb = VerbEntity()
                }
                _entity.verb!!.verbUid = tmp_verbUid
                _entity.verb!!.urlId = tmp_urlId
                _entity.verb!!.verbInActive = tmp_verbInActive
                _entity.verb!!.verbMasterChangeSeqNum = tmp_verbMasterChangeSeqNum
                _entity.verb!!.verbLocalChangeSeqNum = tmp_verbLocalChangeSeqNum
                _entity.verb!!.verbLastChangedBy = tmp_verbLastChangedBy
                _entity.verb!!.verbLct = tmp_verbLct
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("StatementEntity", "ScopedGrant", "PersonGroupMember", "VerbEntity",
          "XLangMapEntry"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT StatementEntity.*, VerbEntity.*, 
        |            verbLangMap.valueLangMap AS verbDisplay, 
        |            xobjectMap.valueLangMap AS objectDisplay 
        |        FROM StatementEntity
        |                 JOIN ScopedGrant 
        |                    ON 
        |            ((ScopedGrant.sgTableId = -2
        |                AND ScopedGrant.sgEntityUid = -2)
        |             OR (ScopedGrant.sgTableId = 9
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
        |             OR (ScopedGrant.sgTableId = 6
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
        |             OR (ScopedGrant.sgTableId = 164
        |                AND ScopedGrant.sgEntityUid = (
        |                    SELECT clazzSchoolUid
        |                      FROM Clazz
        |                     WHERE clazzUid = StatementEntity.statementClazzUid))
        |             )
        |        
        |                    AND (ScopedGrant.sgPermissions & 549755813888) > 0
        |                 JOIN PersonGroupMember 
        |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
        |                AND PersonGroupMember.groupMemberPersonUid = ?
        |                LEFT JOIN VerbEntity 
        |                    ON VerbEntity.verbUid = StatementEntity.statementVerbUid 
        |                LEFT JOIN XLangMapEntry verbLangMap 
        |                    ON verbLangMap.verbLangMapUid = VerbEntity.verbUid
        |                LEFT JOIN XLangMapEntry xobjectMap 
        |                    ON xobjectMap.objectLangMapUid = StatementEntity.xObjectUid
        |         WHERE statementContentEntryUid = ? 
        |            AND statementPersonUid = ? 
        |            AND contextRegistration = ? 
        |         ORDER BY StatementEntity.timestamp DESC
        |         ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT StatementEntity.*, VerbEntity.*, 
        |            verbLangMap.valueLangMap AS verbDisplay, 
        |            xobjectMap.valueLangMap AS objectDisplay 
        |        FROM StatementEntity
        |                 JOIN ScopedGrant 
        |                    ON 
        |            ((ScopedGrant.sgTableId = -2
        |                AND ScopedGrant.sgEntityUid = -2)
        |             OR (ScopedGrant.sgTableId = 9
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
        |             OR (ScopedGrant.sgTableId = 6
        |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
        |             OR (ScopedGrant.sgTableId = 164
        |                AND ScopedGrant.sgEntityUid = (
        |                    SELECT clazzSchoolUid
        |                      FROM Clazz
        |                     WHERE clazzUid = StatementEntity.statementClazzUid))
        |             )
        |        
        |                    AND (ScopedGrant.sgPermissions & 549755813888) > 0
        |                 JOIN PersonGroupMember 
        |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid  
        |                AND PersonGroupMember.groupMemberPersonUid = ?
        |                LEFT JOIN VerbEntity 
        |                    ON VerbEntity.verbUid = StatementEntity.statementVerbUid 
        |                LEFT JOIN XLangMapEntry verbLangMap 
        |                    ON verbLangMap.verbLangMapUid = VerbEntity.verbUid
        |                LEFT JOIN XLangMapEntry xobjectMap 
        |                    ON xobjectMap.objectLangMapUid = StatementEntity.xObjectUid
        |         WHERE statementContentEntryUid = ? 
        |            AND statementPersonUid = ? 
        |            AND contextRegistration = ? 
        |         ORDER BY StatementEntity.timestamp DESC
        |         ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.setLong(3, personUid)
          _stmt.setString(4, contextRegistration)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun calculateScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT SUM(resultScoreRaw) AS resultScore, 
    |               SUM(resultScoreMax) AS resultMax,
    |               MAX(extensionProgress) AS progress,
    |               0 as resultWeight,
    |               0 as penalty,
    |               0 as success,
    |               'FALSE' as contentComplete,
    |               0 AS resultScaled, 
    |               COALESCE((CASE WHEN resultCompletion 
    |               THEN 1 ELSE 0 END),0) AS totalCompletedContent,
    |                
    |                1 as totalContent
    |               
    |         FROM (SELECT * 
    |                 FROM StatementEntity 
    |                WHERE contextRegistration = ?
    |                  AND NOT contentEntryRoot
    |                  AND statementVerbUid = 10007 
    |             GROUP BY xObjectUid) AS SessionStatements
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT SUM(resultScoreRaw) AS resultScore, 
    |               SUM(resultScoreMax) AS resultMax,
    |               MAX(extensionProgress) AS progress,
    |               0 as resultWeight,
    |               0 as penalty,
    |               0 as success,
    |               'FALSE' as contentComplete,
    |               0 AS resultScaled, 
    |               COALESCE((CASE WHEN resultCompletion 
    |               THEN 1 ELSE 0 END),0) AS totalCompletedContent,
    |                
    |                1 as totalContent
    |               
    |         FROM (SELECT * 
    |                 FROM StatementEntity 
    |                WHERE contextRegistration = ?
    |                  AND NOT contentEntryRoot
    |                  AND statementVerbUid = 10007 
    |             GROUP BY xObjectUid) AS SessionStatements
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, contextRegistration)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_resultScore = _resultSet.getInt("resultScore")
          val tmp_resultMax = _resultSet.getInt("resultMax")
          val tmp_resultScaled = _resultSet.getFloat("resultScaled")
          val tmp_resultWeight = _resultSet.getInt("resultWeight")
          val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
          val tmp_progress = _resultSet.getInt("progress")
          val tmp_success = _resultSet.getByte("success")
          val tmp_penalty = _resultSet.getInt("penalty")
          val tmp_totalContent = _resultSet.getInt("totalContent")
          val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
          val _entity = ContentEntryStatementScoreProgress()
          _entity.resultScore = tmp_resultScore
          _entity.resultMax = tmp_resultMax
          _entity.resultScaled = tmp_resultScaled
          _entity.resultWeight = tmp_resultWeight
          _entity.contentComplete = tmp_contentComplete
          _entity.progress = tmp_progress
          _entity.success = tmp_success
          _entity.penalty = tmp_penalty
          _entity.totalContent = tmp_totalContent
          _entity.totalCompletedContent = tmp_totalCompletedContent
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findCompletedScoreForSession(contextRegistration: String):
      ContentEntryStatementScoreProgress? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT resultScoreRaw AS resultScore, 
    |               resultScoreMax AS resultMax,
    |               extensionProgress AS progress,
    |               0 AS penalty,
    |               0 as resultWeight,
    |               resultSuccess AS success,
    |               resultCompletion AS contentComplete, 
    |               resultScoreScaled AS resultScaled,
    |                1 AS totalCompletedContent,
    |                1 as totalContent
    |               
    |          FROM StatementEntity
    |         WHERE resultCompletion
    |          AND contextRegistration = ?
    |          AND contentEntryRoot
    |     ORDER BY resultScoreScaled DESC, 
    |              extensionProgress DESC, 
    |              resultSuccess DESC 
    |              LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT resultScoreRaw AS resultScore, 
    |               resultScoreMax AS resultMax,
    |               extensionProgress AS progress,
    |               0 AS penalty,
    |               0 as resultWeight,
    |               resultSuccess AS success,
    |               resultCompletion AS contentComplete, 
    |               resultScoreScaled AS resultScaled,
    |                1 AS totalCompletedContent,
    |                1 as totalContent
    |               
    |          FROM StatementEntity
    |         WHERE resultCompletion
    |          AND contextRegistration = ?
    |          AND contentEntryRoot
    |     ORDER BY resultScoreScaled DESC, 
    |              extensionProgress DESC, 
    |              resultSuccess DESC 
    |              LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, contextRegistration)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_resultScore = _resultSet.getInt("resultScore")
          val tmp_resultMax = _resultSet.getInt("resultMax")
          val tmp_resultScaled = _resultSet.getFloat("resultScaled")
          val tmp_resultWeight = _resultSet.getInt("resultWeight")
          val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
          val tmp_progress = _resultSet.getInt("progress")
          val tmp_success = _resultSet.getByte("success")
          val tmp_penalty = _resultSet.getInt("penalty")
          val tmp_totalContent = _resultSet.getInt("totalContent")
          val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
          val _entity = ContentEntryStatementScoreProgress()
          _entity.resultScore = tmp_resultScore
          _entity.resultMax = tmp_resultMax
          _entity.resultScaled = tmp_resultScaled
          _entity.resultWeight = tmp_resultWeight
          _entity.contentComplete = tmp_contentComplete
          _entity.progress = tmp_progress
          _entity.success = tmp_success
          _entity.penalty = tmp_penalty
          _entity.totalContent = tmp_totalContent
          _entity.totalCompletedContent = tmp_totalCompletedContent
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findLatestRegistrationStatement(accountPersonUid: Long,
      entryUid: Long): String? {
    var _result = null as kotlin.String??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT contextRegistration 
    |          FROM StatementEntity
    |         WHERE statementPersonUid = ?
    |           AND statementContentEntryUid = ?
    |           AND NOT EXISTS (SELECT statementUid FROM StatementEntity
    |                            WHERE statementPersonUid = ?
    |                             AND statementContentEntryUid = ?
    |                             AND (statementVerbUid = 10001 
    |                                    OR statementVerbUid = 10004))
    |      ORDER BY timestamp DESC 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT contextRegistration 
    |          FROM StatementEntity
    |         WHERE statementPersonUid = ?
    |           AND statementContentEntryUid = ?
    |           AND NOT EXISTS (SELECT statementUid FROM StatementEntity
    |                            WHERE statementPersonUid = ?
    |                             AND statementContentEntryUid = ?
    |                             AND (statementVerbUid = 10001 
    |                                    OR statementVerbUid = 10004))
    |      ORDER BY timestamp DESC 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, accountPersonUid)
      _stmt.setLong(2, entryUid)
      _stmt.setLong(3, accountPersonUid)
      _stmt.setLong(4, entryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getString(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: StatementEntity): Long {
    val _retVal = _insertAdapterStatementEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: StatementEntity): Long {
    val _retVal = _insertAdapterStatementEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out StatementEntity>): Unit {
    _insertAdapterStatementEntity_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out StatementEntity>): Unit {
    val _sql =
        "UPDATE StatementEntity SET statementId = ?, statementPersonUid = ?, statementVerbUid = ?, xObjectUid = ?, subStatementActorUid = ?, substatementVerbUid = ?, subStatementObjectUid = ?, agentUid = ?, instructorUid = ?, authorityUid = ?, teamUid = ?, resultCompletion = ?, resultSuccess = ?, resultScoreScaled = ?, resultScoreRaw = ?, resultScoreMin = ?, resultScoreMax = ?, resultDuration = ?, resultResponse = ?, timestamp = ?, stored = ?, contextRegistration = ?, contextPlatform = ?, contextStatementId = ?, fullStatement = ?, statementMasterChangeSeqNum = ?, statementLocalChangeSeqNum = ?, statementLastChangedBy = ?, statementLct = ?, extensionProgress = ?, contentEntryRoot = ?, statementContentEntryUid = ?, statementLearnerGroupUid = ?, statementClazzUid = ? WHERE statementUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.statementId)
        _stmt.setLong(2, _entity.statementPersonUid)
        _stmt.setLong(3, _entity.statementVerbUid)
        _stmt.setLong(4, _entity.xObjectUid)
        _stmt.setLong(5, _entity.subStatementActorUid)
        _stmt.setLong(6, _entity.substatementVerbUid)
        _stmt.setLong(7, _entity.subStatementObjectUid)
        _stmt.setLong(8, _entity.agentUid)
        _stmt.setLong(9, _entity.instructorUid)
        _stmt.setLong(10, _entity.authorityUid)
        _stmt.setLong(11, _entity.teamUid)
        _stmt.setBoolean(12, _entity.resultCompletion)
        _stmt.setByte(13, _entity.resultSuccess)
        _stmt.setFloat(14, _entity.resultScoreScaled)
        _stmt.setLong(15, _entity.resultScoreRaw)
        _stmt.setLong(16, _entity.resultScoreMin)
        _stmt.setLong(17, _entity.resultScoreMax)
        _stmt.setLong(18, _entity.resultDuration)
        _stmt.setString(19, _entity.resultResponse)
        _stmt.setLong(20, _entity.timestamp)
        _stmt.setLong(21, _entity.stored)
        _stmt.setString(22, _entity.contextRegistration)
        _stmt.setString(23, _entity.contextPlatform)
        _stmt.setString(24, _entity.contextStatementId)
        _stmt.setString(25, _entity.fullStatement)
        _stmt.setLong(26, _entity.statementMasterChangeSeqNum)
        _stmt.setLong(27, _entity.statementLocalChangeSeqNum)
        _stmt.setInt(28, _entity.statementLastChangedBy)
        _stmt.setLong(29, _entity.statementLct)
        _stmt.setInt(30, _entity.extensionProgress)
        _stmt.setBoolean(31, _entity.contentEntryRoot)
        _stmt.setLong(32, _entity.statementContentEntryUid)
        _stmt.setLong(33, _entity.statementLearnerGroupUid)
        _stmt.setLong(34, _entity.statementClazzUid)
        _stmt.setLong(35, _entity.statementUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: StatementEntity): Unit {
    val _sql =
        "UPDATE StatementEntity SET statementId = ?, statementPersonUid = ?, statementVerbUid = ?, xObjectUid = ?, subStatementActorUid = ?, substatementVerbUid = ?, subStatementObjectUid = ?, agentUid = ?, instructorUid = ?, authorityUid = ?, teamUid = ?, resultCompletion = ?, resultSuccess = ?, resultScoreScaled = ?, resultScoreRaw = ?, resultScoreMin = ?, resultScoreMax = ?, resultDuration = ?, resultResponse = ?, timestamp = ?, stored = ?, contextRegistration = ?, contextPlatform = ?, contextStatementId = ?, fullStatement = ?, statementMasterChangeSeqNum = ?, statementLocalChangeSeqNum = ?, statementLastChangedBy = ?, statementLct = ?, extensionProgress = ?, contentEntryRoot = ?, statementContentEntryUid = ?, statementLearnerGroupUid = ?, statementClazzUid = ? WHERE statementUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.statementId)
      _stmt.setLong(2, entity.statementPersonUid)
      _stmt.setLong(3, entity.statementVerbUid)
      _stmt.setLong(4, entity.xObjectUid)
      _stmt.setLong(5, entity.subStatementActorUid)
      _stmt.setLong(6, entity.substatementVerbUid)
      _stmt.setLong(7, entity.subStatementObjectUid)
      _stmt.setLong(8, entity.agentUid)
      _stmt.setLong(9, entity.instructorUid)
      _stmt.setLong(10, entity.authorityUid)
      _stmt.setLong(11, entity.teamUid)
      _stmt.setBoolean(12, entity.resultCompletion)
      _stmt.setByte(13, entity.resultSuccess)
      _stmt.setFloat(14, entity.resultScoreScaled)
      _stmt.setLong(15, entity.resultScoreRaw)
      _stmt.setLong(16, entity.resultScoreMin)
      _stmt.setLong(17, entity.resultScoreMax)
      _stmt.setLong(18, entity.resultDuration)
      _stmt.setString(19, entity.resultResponse)
      _stmt.setLong(20, entity.timestamp)
      _stmt.setLong(21, entity.stored)
      _stmt.setString(22, entity.contextRegistration)
      _stmt.setString(23, entity.contextPlatform)
      _stmt.setString(24, entity.contextStatementId)
      _stmt.setString(25, entity.fullStatement)
      _stmt.setLong(26, entity.statementMasterChangeSeqNum)
      _stmt.setLong(27, entity.statementLocalChangeSeqNum)
      _stmt.setInt(28, entity.statementLastChangedBy)
      _stmt.setLong(29, entity.statementLct)
      _stmt.setInt(30, entity.extensionProgress)
      _stmt.setBoolean(31, entity.contentEntryRoot)
      _stmt.setLong(32, entity.statementContentEntryUid)
      _stmt.setLong(33, entity.statementLearnerGroupUid)
      _stmt.setLong(34, entity.statementClazzUid)
      _stmt.setLong(35, entity.statementUid)
      _stmt.executeUpdate()
    }
  }
}
