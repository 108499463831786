package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CourseGroupMember
import com.ustadmobile.lib.db.entities.CourseGroupMemberPerson
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseGroupMemberDao_JdbcKt(
  public val _db: DoorDatabase
) : CourseGroupMemberDao() {
  public val _insertAdapterCourseGroupMember_: EntityInsertionAdapter<CourseGroupMember> = object :
      EntityInsertionAdapter<CourseGroupMember>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CourseGroupMember (cgmUid, cgmSetUid, cgmGroupNumber, cgmPersonUid, cgmLct) VALUES(?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseGroupMember (cgmUid, cgmSetUid, cgmGroupNumber, cgmPersonUid, cgmLct) VALUES(COALESCE(?,nextval('CourseGroupMember_cgmUid_seq')), ?, ?, ?, ?)" + if(returnsId) { " RETURNING cgmUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseGroupMember): Unit {
      if(entity.cgmUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cgmUid)
      }
      stmt.setLong(2, entity.cgmSetUid)
      stmt.setInt(3, entity.cgmGroupNumber)
      stmt.setLong(4, entity.cgmPersonUid)
      stmt.setLong(5, entity.cgmLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseGroupMemberReplicate(cgmPk, cgmDestination)
    |      SELECT DISTINCT CourseGroupMember.cgmUid AS cgmUid,
    |             ? AS cgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN CourseGroupSet
    |                    ON CourseGroupSet.cgsClazzUid = Clazz.clazzUid
    |             JOIN CourseGroupMember
    |                    ON CourseGroupMember.cgmSetUid = CourseGroupSet.cgsUid       
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseGroupMember.cgmLct != COALESCE(
    |             (SELECT cgmVersionId
    |                FROM CourseGroupMemberReplicate
    |               WHERE cgmPk = CourseGroupMember.cgmUid
    |                 AND cgmDestination = ?), 0) 
    |      /*psql ON CONFLICT(cgmPk, cgmDestination) DO UPDATE
    |             SET cgmPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseGroupMemberReplicate(cgmPk, cgmDestination)
    |      SELECT DISTINCT CourseGroupMember.cgmUid AS cgmUid,
    |             ? AS cgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN CourseGroupSet
    |                    ON CourseGroupSet.cgsClazzUid = Clazz.clazzUid
    |             JOIN CourseGroupMember
    |                    ON CourseGroupMember.cgmSetUid = CourseGroupSet.cgsUid       
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseGroupMember.cgmLct != COALESCE(
    |             (SELECT cgmVersionId
    |                FROM CourseGroupMemberReplicate
    |               WHERE cgmPk = CourseGroupMember.cgmUid
    |                 AND cgmDestination = ?), 0) 
    |       ON CONFLICT(cgmPk, cgmDestination) DO UPDATE
    |             SET cgmPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO CourseGroupMemberReplicate(cgmPk, cgmDestination)
    |  SELECT DISTINCT CourseGroupMember.cgmUid AS cgmUid,
    |         UserSession.usClientNodeId AS cgmDestination
    |    FROM ChangeLog
    |         JOIN CourseGroupMember
    |               ON ChangeLog.chTableId = 243
    |                  AND ChangeLog.chEntityPk = CourseGroupMember.cgmUid
    |          JOIN CourseGroupSet
    |               ON CourseGroupSet.cgsUid = CourseGroupMember.cgmSetUid       
    |          JOIN Clazz 
    |               ON Clazz.clazzUid = CourseGroupSet.cgsClazzUid 
    |          
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseGroupMember.cgmLct != COALESCE(
    |         (SELECT cgmVersionId
    |            FROM CourseGroupMemberReplicate
    |           WHERE cgmPk = CourseGroupMember.cgmUid
    |             AND cgmDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cgmPk, cgmDestination) DO UPDATE
    |     SET cgmPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseGroupMemberReplicate(cgmPk, cgmDestination)
    |  SELECT DISTINCT CourseGroupMember.cgmUid AS cgmUid,
    |         UserSession.usClientNodeId AS cgmDestination
    |    FROM ChangeLog
    |         JOIN CourseGroupMember
    |               ON ChangeLog.chTableId = 243
    |                  AND ChangeLog.chEntityPk = CourseGroupMember.cgmUid
    |          JOIN CourseGroupSet
    |               ON CourseGroupSet.cgsUid = CourseGroupMember.cgmSetUid       
    |          JOIN Clazz 
    |               ON Clazz.clazzUid = CourseGroupSet.cgsClazzUid 
    |          
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseGroupMember.cgmLct != COALESCE(
    |         (SELECT cgmVersionId
    |            FROM CourseGroupMemberReplicate
    |           WHERE cgmPk = CourseGroupMember.cgmUid
    |             AND cgmDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cgmPk, cgmDestination) DO UPDATE
    |     SET cgmPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByGroupSetAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.CourseGroupMemberPerson>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Person.*, CourseGroupMember.* 
    |          FROM Person
    |               JOIN ClazzEnrolment 
    |               ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |               AND ClazzEnrolment.clazzEnrolmentRole = 1000
    |               AND ClazzEnrolment.clazzEnrolmentOutcome = 200
    |               
    |               LEFT JOIN CourseGroupMember
    |               ON CourseGroupMember.cgmPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |               AND CourseGroupMember.cgmSetUid = ?
    |               
    |         WHERE clazzEnrolmentClazzUid = ?
    |      ORDER BY Person.firstNames
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Person.*, CourseGroupMember.* 
    |          FROM Person
    |               JOIN ClazzEnrolment 
    |               ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |               AND ClazzEnrolment.clazzEnrolmentRole = 1000
    |               AND ClazzEnrolment.clazzEnrolmentOutcome = 200
    |               
    |               LEFT JOIN CourseGroupMember
    |               ON CourseGroupMember.cgmPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |               AND CourseGroupMember.cgmSetUid = ?
    |               
    |         WHERE clazzEnrolmentClazzUid = ?
    |      ORDER BY Person.firstNames
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, setUid)
      _stmt.setLong(2, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = CourseGroupMemberPerson()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          var _member_nullFieldCount = 0
          val tmp_cgmUid = _resultSet.getLong("cgmUid")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          val tmp_cgmSetUid = _resultSet.getLong("cgmSetUid")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          val tmp_cgmGroupNumber = _resultSet.getInt("cgmGroupNumber")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          val tmp_cgmPersonUid = _resultSet.getLong("cgmPersonUid")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          val tmp_cgmLct = _resultSet.getLong("cgmLct")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          if(_member_nullFieldCount < 5) {
            if(_entity.member == null) {
              _entity.member = CourseGroupMember()
            }
            _entity.member!!.cgmUid = tmp_cgmUid
            _entity.member!!.cgmSetUid = tmp_cgmSetUid
            _entity.member!!.cgmGroupNumber = tmp_cgmGroupNumber
            _entity.member!!.cgmPersonUid = tmp_cgmPersonUid
            _entity.member!!.cgmLct = tmp_cgmLct
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByGroupSetOrderedAsync(setUid: Long, clazzUid: Long):
      List<CourseGroupMemberPerson> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.CourseGroupMemberPerson>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Person.*, CourseGroupMember.* 
    |          FROM Person
    |               JOIN ClazzEnrolment 
    |               ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |               AND ClazzEnrolment.clazzEnrolmentRole = 1000 
    |               AND ClazzEnrolment.clazzEnrolmentOutcome = 200
    |               
    |               LEFT JOIN CourseGroupMember
    |               ON CourseGroupMember.cgmPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |               AND CourseGroupMember.cgmSetUid = ?
    |               
    |         WHERE clazzEnrolmentClazzUid = ?
    |      ORDER BY CourseGroupMember.cgmGroupNumber, Person.firstNames
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Person.*, CourseGroupMember.* 
    |          FROM Person
    |               JOIN ClazzEnrolment 
    |               ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |               AND ClazzEnrolment.clazzEnrolmentRole = 1000 
    |               AND ClazzEnrolment.clazzEnrolmentOutcome = 200
    |               
    |               LEFT JOIN CourseGroupMember
    |               ON CourseGroupMember.cgmPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |               AND CourseGroupMember.cgmSetUid = ?
    |               
    |         WHERE clazzEnrolmentClazzUid = ?
    |      ORDER BY CourseGroupMember.cgmGroupNumber, Person.firstNames
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, setUid)
      _stmt.setLong(2, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = CourseGroupMemberPerson()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          var _member_nullFieldCount = 0
          val tmp_cgmUid = _resultSet.getLong("cgmUid")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          val tmp_cgmSetUid = _resultSet.getLong("cgmSetUid")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          val tmp_cgmGroupNumber = _resultSet.getInt("cgmGroupNumber")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          val tmp_cgmPersonUid = _resultSet.getLong("cgmPersonUid")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          val tmp_cgmLct = _resultSet.getLong("cgmLct")
          if(_resultSet.wasNull()) { _member_nullFieldCount++ }
          if(_member_nullFieldCount < 5) {
            if(_entity.member == null) {
              _entity.member = CourseGroupMember()
            }
            _entity.member!!.cgmUid = tmp_cgmUid
            _entity.member!!.cgmSetUid = tmp_cgmSetUid
            _entity.member!!.cgmGroupNumber = tmp_cgmGroupNumber
            _entity.member!!.cgmPersonUid = tmp_cgmPersonUid
            _entity.member!!.cgmLct = tmp_cgmLct
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByPersonUid(groupSetUid: Long, studentUid: Long):
      CourseGroupMember? {
    var _result = null as com.ustadmobile.lib.db.entities.CourseGroupMember??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM CourseGroupMember
    |         WHERE cgmPersonUid = ? 
    |          AND cgmSetUid = ?
    |         LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM CourseGroupMember
    |         WHERE cgmPersonUid = ? 
    |          AND cgmSetUid = ?
    |         LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, studentUid)
      _stmt.setLong(2, groupSetUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cgmUid = _resultSet.getLong("cgmUid")
          val tmp_cgmSetUid = _resultSet.getLong("cgmSetUid")
          val tmp_cgmGroupNumber = _resultSet.getInt("cgmGroupNumber")
          val tmp_cgmPersonUid = _resultSet.getLong("cgmPersonUid")
          val tmp_cgmLct = _resultSet.getLong("cgmLct")
          val _entity = CourseGroupMember()
          _entity.cgmUid = tmp_cgmUid
          _entity.cgmSetUid = tmp_cgmSetUid
          _entity.cgmGroupNumber = tmp_cgmGroupNumber
          _entity.cgmPersonUid = tmp_cgmPersonUid
          _entity.cgmLct = tmp_cgmLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun insertListAsync(entityList: List<CourseGroupMember>): Unit {
    _insertAdapterCourseGroupMember_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<CourseGroupMember>): Unit {
    val _sql =
        "UPDATE CourseGroupMember SET cgmSetUid = ?, cgmGroupNumber = ?, cgmPersonUid = ?, cgmLct = ? WHERE cgmUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cgmSetUid)
        _stmt.setInt(2, _entity.cgmGroupNumber)
        _stmt.setLong(3, _entity.cgmPersonUid)
        _stmt.setLong(4, _entity.cgmLct)
        _stmt.setLong(5, _entity.cgmUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun insert(entity: CourseGroupMember): Long {
    val _retVal = _insertAdapterCourseGroupMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseGroupMember): Long {
    val _retVal = _insertAdapterCourseGroupMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CourseGroupMember>): Unit {
    _insertAdapterCourseGroupMember_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseGroupMember>): Unit {
    val _sql =
        "UPDATE CourseGroupMember SET cgmSetUid = ?, cgmGroupNumber = ?, cgmPersonUid = ?, cgmLct = ? WHERE cgmUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cgmSetUid)
        _stmt.setInt(2, _entity.cgmGroupNumber)
        _stmt.setLong(3, _entity.cgmPersonUid)
        _stmt.setLong(4, _entity.cgmLct)
        _stmt.setLong(5, _entity.cgmUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseGroupMember): Unit {
    val _sql =
        "UPDATE CourseGroupMember SET cgmSetUid = ?, cgmGroupNumber = ?, cgmPersonUid = ?, cgmLct = ? WHERE cgmUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cgmSetUid)
      _stmt.setInt(2, entity.cgmGroupNumber)
      _stmt.setLong(3, entity.cgmPersonUid)
      _stmt.setLong(4, entity.cgmLct)
      _stmt.setLong(5, entity.cgmUid)
      _stmt.executeUpdate()
    }
  }
}
