package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentEntryContentCategoryJoin
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryContentCategoryJoinDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentEntryContentCategoryJoinDao() {
  public val _insertAdapterContentEntryContentCategoryJoin_:
      EntityInsertionAdapter<ContentEntryContentCategoryJoin> = object :
      EntityInsertionAdapter<ContentEntryContentCategoryJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentEntryContentCategoryJoin (ceccjUid, ceccjContentEntryUid, ceccjContentCategoryUid, ceccjLocalChangeSeqNum, ceccjMasterChangeSeqNum, ceccjLastChangedBy, ceccjLct) VALUES(?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentEntryContentCategoryJoin (ceccjUid, ceccjContentEntryUid, ceccjContentCategoryUid, ceccjLocalChangeSeqNum, ceccjMasterChangeSeqNum, ceccjLastChangedBy, ceccjLct) VALUES(COALESCE(?,nextval('ContentEntryContentCategoryJoin_ceccjUid_seq')), ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING ceccjUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryContentCategoryJoin): Unit {
      if(entity.ceccjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ceccjUid)
      }
      stmt.setLong(2, entity.ceccjContentEntryUid)
      stmt.setLong(3, entity.ceccjContentCategoryUid)
      stmt.setLong(4, entity.ceccjLocalChangeSeqNum)
      stmt.setLong(5, entity.ceccjMasterChangeSeqNum)
      stmt.setInt(6, entity.ceccjLastChangedBy)
      stmt.setLong(7, entity.ceccjLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentEntryContentCategoryJoinReplicate(ceccjPk, ceccjDestination)
    |      SELECT DISTINCT ContentEntryContentCategoryJoin.ceccjUid AS ceccjPk,
    |             ? AS ceccjDestination
    |        FROM ContentEntryContentCategoryJoin
    |       WHERE ContentEntryContentCategoryJoin.ceccjLct != COALESCE(
    |             (SELECT ceccjVersionId
    |                FROM ContentEntryContentCategoryJoinReplicate
    |               WHERE ceccjPk = ContentEntryContentCategoryJoin.ceccjUid
    |                 AND ceccjDestination = ?), 0) 
    |      /*psql ON CONFLICT(ceccjPk, ceccjDestination) DO UPDATE
    |             SET ceccjPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryContentCategoryJoinReplicate(ceccjPk, ceccjDestination)
    |      SELECT DISTINCT ContentEntryContentCategoryJoin.ceccjUid AS ceccjPk,
    |             ? AS ceccjDestination
    |        FROM ContentEntryContentCategoryJoin
    |       WHERE ContentEntryContentCategoryJoin.ceccjLct != COALESCE(
    |             (SELECT ceccjVersionId
    |                FROM ContentEntryContentCategoryJoinReplicate
    |               WHERE ceccjPk = ContentEntryContentCategoryJoin.ceccjUid
    |                 AND ceccjDestination = ?), 0) 
    |       ON CONFLICT(ceccjPk, ceccjDestination) DO UPDATE
    |             SET ceccjPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ContentEntryContentCategoryJoinReplicate(ceccjPk, ceccjDestination)
    |  SELECT DISTINCT ContentEntryContentCategoryJoin.ceccjUid AS ceccjUid,
    |         UserSession.usClientNodeId AS ceccjDestination
    |    FROM ChangeLog
    |         JOIN ContentEntryContentCategoryJoin
    |             ON ChangeLog.chTableId = 3
    |                AND ChangeLog.chEntityPk = ContentEntryContentCategoryJoin.ceccjUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentEntryContentCategoryJoin.ceccjLct != COALESCE(
    |         (SELECT ceccjVersionId
    |            FROM ContentEntryContentCategoryJoinReplicate
    |           WHERE ceccjPk = ContentEntryContentCategoryJoin.ceccjUid
    |             AND ceccjDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ceccjPk, ceccjDestination) DO UPDATE
    |     SET ceccjPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryContentCategoryJoinReplicate(ceccjPk, ceccjDestination)
    |  SELECT DISTINCT ContentEntryContentCategoryJoin.ceccjUid AS ceccjUid,
    |         UserSession.usClientNodeId AS ceccjDestination
    |    FROM ChangeLog
    |         JOIN ContentEntryContentCategoryJoin
    |             ON ChangeLog.chTableId = 3
    |                AND ChangeLog.chEntityPk = ContentEntryContentCategoryJoin.ceccjUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentEntryContentCategoryJoin.ceccjLct != COALESCE(
    |         (SELECT ceccjVersionId
    |            FROM ContentEntryContentCategoryJoinReplicate
    |           WHERE ceccjPk = ContentEntryContentCategoryJoin.ceccjUid
    |             AND ceccjDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ceccjPk, ceccjDestination) DO UPDATE
    |     SET ceccjPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun publicContentEntryContentCategoryJoins():
      List<ContentEntryContentCategoryJoin> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryContentCategoryJoin>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntryContentCategoryJoin.* FROM ContentEntryContentCategoryJoin LEFT JOIN ContentEntry ON ContentEntryContentCategoryJoin.ceccjContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntry.publik"
        , postgreSql = """
    |SELECT ContentEntryContentCategoryJoin.* FROM ContentEntryContentCategoryJoin LEFT JOIN ContentEntry ON ContentEntryContentCategoryJoin.ceccjContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntry.publik
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_ceccjUid = _resultSet.getLong("ceccjUid")
          val tmp_ceccjContentEntryUid = _resultSet.getLong("ceccjContentEntryUid")
          val tmp_ceccjContentCategoryUid = _resultSet.getLong("ceccjContentCategoryUid")
          val tmp_ceccjLocalChangeSeqNum = _resultSet.getLong("ceccjLocalChangeSeqNum")
          val tmp_ceccjMasterChangeSeqNum = _resultSet.getLong("ceccjMasterChangeSeqNum")
          val tmp_ceccjLastChangedBy = _resultSet.getInt("ceccjLastChangedBy")
          val tmp_ceccjLct = _resultSet.getLong("ceccjLct")
          val _entity = ContentEntryContentCategoryJoin()
          _entity.ceccjUid = tmp_ceccjUid
          _entity.ceccjContentEntryUid = tmp_ceccjContentEntryUid
          _entity.ceccjContentCategoryUid = tmp_ceccjContentCategoryUid
          _entity.ceccjLocalChangeSeqNum = tmp_ceccjLocalChangeSeqNum
          _entity.ceccjMasterChangeSeqNum = tmp_ceccjMasterChangeSeqNum
          _entity.ceccjLastChangedBy = tmp_ceccjLastChangedBy
          _entity.ceccjLct = tmp_ceccjLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findJoinByParentChildUuids(categoryUid: Long, contentEntry: Long):
      ContentEntryContentCategoryJoin? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryContentCategoryJoin??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * from ContentEntryContentCategoryJoin WHERE ceccjContentCategoryUid = ? AND ceccjContentEntryUid = ?"
        , postgreSql = """
    |SELECT * from ContentEntryContentCategoryJoin WHERE ceccjContentCategoryUid = ? AND ceccjContentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, categoryUid)
      _stmt.setLong(2, contentEntry)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_ceccjUid = _resultSet.getLong("ceccjUid")
          val tmp_ceccjContentEntryUid = _resultSet.getLong("ceccjContentEntryUid")
          val tmp_ceccjContentCategoryUid = _resultSet.getLong("ceccjContentCategoryUid")
          val tmp_ceccjLocalChangeSeqNum = _resultSet.getLong("ceccjLocalChangeSeqNum")
          val tmp_ceccjMasterChangeSeqNum = _resultSet.getLong("ceccjMasterChangeSeqNum")
          val tmp_ceccjLastChangedBy = _resultSet.getInt("ceccjLastChangedBy")
          val tmp_ceccjLct = _resultSet.getLong("ceccjLct")
          val _entity = ContentEntryContentCategoryJoin()
          _entity.ceccjUid = tmp_ceccjUid
          _entity.ceccjContentEntryUid = tmp_ceccjContentEntryUid
          _entity.ceccjContentCategoryUid = tmp_ceccjContentCategoryUid
          _entity.ceccjLocalChangeSeqNum = tmp_ceccjLocalChangeSeqNum
          _entity.ceccjMasterChangeSeqNum = tmp_ceccjMasterChangeSeqNum
          _entity.ceccjLastChangedBy = tmp_ceccjLastChangedBy
          _entity.ceccjLct = tmp_ceccjLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ContentEntryContentCategoryJoin): Long {
    val _retVal = _insertAdapterContentEntryContentCategoryJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntryContentCategoryJoin): Long {
    val _retVal = _insertAdapterContentEntryContentCategoryJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContentEntryContentCategoryJoin>): Unit {
    _insertAdapterContentEntryContentCategoryJoin_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentEntryContentCategoryJoin>): Unit {
    val _sql =
        "UPDATE ContentEntryContentCategoryJoin SET ceccjContentEntryUid = ?, ceccjContentCategoryUid = ?, ceccjLocalChangeSeqNum = ?, ceccjMasterChangeSeqNum = ?, ceccjLastChangedBy = ?, ceccjLct = ? WHERE ceccjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.ceccjContentEntryUid)
        _stmt.setLong(2, _entity.ceccjContentCategoryUid)
        _stmt.setLong(3, _entity.ceccjLocalChangeSeqNum)
        _stmt.setLong(4, _entity.ceccjMasterChangeSeqNum)
        _stmt.setInt(5, _entity.ceccjLastChangedBy)
        _stmt.setLong(6, _entity.ceccjLct)
        _stmt.setLong(7, _entity.ceccjUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContentEntryContentCategoryJoin): Unit {
    val _sql =
        "UPDATE ContentEntryContentCategoryJoin SET ceccjContentEntryUid = ?, ceccjContentCategoryUid = ?, ceccjLocalChangeSeqNum = ?, ceccjMasterChangeSeqNum = ?, ceccjLastChangedBy = ?, ceccjLct = ? WHERE ceccjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.ceccjContentEntryUid)
      _stmt.setLong(2, entity.ceccjContentCategoryUid)
      _stmt.setLong(3, entity.ceccjLocalChangeSeqNum)
      _stmt.setLong(4, entity.ceccjMasterChangeSeqNum)
      _stmt.setInt(5, entity.ceccjLastChangedBy)
      _stmt.setLong(6, entity.ceccjLct)
      _stmt.setLong(7, entity.ceccjUid)
      _stmt.executeUpdate()
    }
  }
}
