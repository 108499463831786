package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.DiscussionPost
import com.ustadmobile.lib.db.entities.DiscussionPostWithDetails
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class DiscussionPostDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: DiscussionPostDao
) : DiscussionPostDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun getPostsByDiscussionTopic(discussionTopicUid: Long):
      DoorDataSourceFactory<Int, DiscussionPostWithDetails> =
      _dao.getPostsByDiscussionTopic(discussionTopicUid)

  public override suspend fun getPostTitle(postUid: Long): String? = _dao.getPostTitle(postUid)

  public override suspend fun findByUid(uid: Long): DiscussionPost? = _dao.findByUid(uid)

  public override suspend fun findWithDetailsByUid(uid: Long): DiscussionPostWithDetails? =
      _dao.findWithDetailsByUid(uid)

  public override fun findWithDetailsByUidLive(uid: Long): DoorLiveData<DiscussionPostWithDetails?>
      = _dao.findWithDetailsByUidLive(uid)

  public override suspend fun updateAsync(entity: DiscussionPost): Int {
    entity.discussionPostLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override fun insert(entity: DiscussionPost): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: DiscussionPost): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.discussionPostUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(132)
      entity.discussionPostUid = _newPk
    }
    entity.discussionPostLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.discussionPostUid
  }

  public override fun insertList(entityList: List<out DiscussionPost>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out DiscussionPost>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: DiscussionPost): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
