package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Schedule
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ScheduleDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: ScheduleDao
) : ScheduleDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun insert(entity: Schedule): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun updateAsync(entity: Schedule): Int {
    entity.scheduleLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    _dao.updateScheduleActivated(scheduleUid, active, changeTime)
  }

  public override fun findByUid(uid: Long): Schedule? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByUidAsync(uid: Long): Schedule? = _dao.findByUidAsync(uid)

  public override fun findAllSchedulesByClazzUid(clazzUid: Long): DoorDataSourceFactory<Int,
      Schedule> = _dao.findAllSchedulesByClazzUid(clazzUid)

  public override fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long):
      DoorLiveData<List<Schedule>> = _dao.findAllSchedulesByClazzUidAsLiveList(clazzUid)

  public override suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule> =
      _dao.findAllSchedulesByClazzUidAsync(clazzUid)

  public override suspend fun insertAsync(entity: Schedule): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.scheduleUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(21)
      entity.scheduleUid = _newPk
    }
    entity.scheduleLastChangedTime = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.scheduleUid
  }

  public override fun insertList(entityList: List<out Schedule>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out Schedule>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Schedule): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertListAsync(entityList: List<out Schedule>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.scheduleUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(21)
        it.scheduleUid = _newPk
        _generatedPks += _newPk
      }
      it.scheduleLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out Schedule>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.scheduleLastChangedTime = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
