package com.ustadmobile.core.controller

import com.ustadmobile.core.view.HarView
import org.kodein.di.DI

actual class HarContentPresenter actual constructor(context: Any, arguments: Map<String, String>,
                                                    view: HarView, localHttp: String,
                                                    di: DI) :
        HarContentPresenterCommon(context, arguments, view, localHttp, di) {


}