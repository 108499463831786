package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ContentCategory
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentCategoryDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: ContentCategoryDao
) : ContentCategoryDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun publicContentCategories(): List<ContentCategory> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findCategoryBySchemaIdAndName(schemaId: Long, name: String): ContentCategory?
      = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: ContentCategory): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ContentCategory): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.contentCategoryUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(1)
      entity.contentCategoryUid = _newPk
    }
    entity.contentCategoryLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.contentCategoryUid
  }

  public override fun insertList(entityList: List<out ContentCategory>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out ContentCategory>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ContentCategory): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
