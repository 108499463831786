package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.Holiday
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class HolidayDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: HolidayDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : HolidayDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByHolidayCalendaUid(holidayCalendarUid: Long): List<Holiday> {
    val _result = _dao.findByHolidayCalendaUid(holidayCalendarUid)
    return _result
  }

  public override suspend fun findByHolidayCalendaUidAsync(holidayCalendarUid: Long):
      List<Holiday> {
    val _result = _dao.findByHolidayCalendaUidAsync(holidayCalendarUid)
    return _result
  }

  public override fun updateActiveByUid(
    holidayUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    _dao.updateActiveByUid(holidayUid, active, changeTime)
  }

  public override suspend fun updateAsync(entity: Holiday): Unit {
    _dao.updateAsync(entity)
  }

  public override fun insert(entity: Holiday): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Holiday): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out Holiday>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Holiday>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Holiday): Unit {
    _dao.update(entity)
  }

  public override suspend fun insertListAsync(entityList: List<out Holiday>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out Holiday>): Unit {
    _dao.updateListAsync(entityList)
  }
}
