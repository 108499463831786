package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.MessageRead
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class MessageReadDao_JdbcKt(
  public val _db: DoorDatabase
) : MessageReadDao() {
  public val _insertAdapterMessageRead_: EntityInsertionAdapter<MessageRead> = object :
      EntityInsertionAdapter<MessageRead>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO MessageRead (messageReadUid, messageReadPersonUid, messageReadMessageUid, messageReadEntityUid, messageReadLct) VALUES(?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO MessageRead (messageReadUid, messageReadPersonUid, messageReadMessageUid, messageReadEntityUid, messageReadLct) VALUES(COALESCE(?,nextval('MessageRead_messageReadUid_seq')), ?, ?, ?, ?)" + if(returnsId) { " RETURNING messageReadUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: MessageRead):
        Unit {
      if(entity.messageReadUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.messageReadUid)
      }
      stmt.setLong(2, entity.messageReadPersonUid)
      stmt.setLong(3, entity.messageReadMessageUid)
      stmt.setLong(4, entity.messageReadEntityUid)
      stmt.setLong(5, entity.messageReadLct)
    }
  }

  public override suspend fun replicateOnNewNodeChats(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO MessageReadReplicate(messageReadPk, messageReadDestination)
    |      SELECT DISTINCT MessageRead.messageReadUid AS messageReadPk,
    |             ? AS messageReadDestination
    |        FROM UserSession 
    |              JOIN Message ON
    |                  ((    Message.messageTableId = 127
    |                    AND Message.messageEntityUid IN
    |                        (SELECT ChatMember.chatMemberChatUid 
    |                          FROM ChatMember
    |                         WHERE ChatMember.chatMemberPersonUid = UserSession.usPersonUid))
    |                  OR UserSession.usSessionType = 2)
    |                  
    |              JOIN MessageRead 
    |                   ON MessageRead.messageReadMessageUid = Message.messageUid
    |                   
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND MessageRead.messageReadLct != COALESCE(
    |             (SELECT messageReadVersionId
    |                FROM MessageReadReplicate
    |               WHERE messageReadPk = MessageRead.messageReadUid
    |                 AND messageReadDestination = ?), 0) 
    |      /*psql ON CONFLICT(messageReadPk, messageReadDestination) DO UPDATE
    |             SET messageReadPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReadReplicate(messageReadPk, messageReadDestination)
    |      SELECT DISTINCT MessageRead.messageReadUid AS messageReadPk,
    |             ? AS messageReadDestination
    |        FROM UserSession 
    |              JOIN Message ON
    |                  ((    Message.messageTableId = 127
    |                    AND Message.messageEntityUid IN
    |                        (SELECT ChatMember.chatMemberChatUid 
    |                          FROM ChatMember
    |                         WHERE ChatMember.chatMemberPersonUid = UserSession.usPersonUid))
    |                  OR UserSession.usSessionType = 2)
    |                  
    |              JOIN MessageRead 
    |                   ON MessageRead.messageReadMessageUid = Message.messageUid
    |                   
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND MessageRead.messageReadLct != COALESCE(
    |             (SELECT messageReadVersionId
    |                FROM MessageReadReplicate
    |               WHERE messageReadPk = MessageRead.messageReadUid
    |                 AND messageReadDestination = ?), 0) 
    |       ON CONFLICT(messageReadPk, messageReadDestination) DO UPDATE
    |             SET messageReadPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         REPLACE INTO MessageReadReplicate(messageReadPk, messageReadDestination)
    |          SELECT DISTINCT MessageRead.messageReadUid AS messageReadUid,
    |                 UserSession.usClientNodeId AS messageReadDestination
    |            FROM ChangeLog
    |            
    |                 JOIN MessageRead 
    |                      ON MessageRead.messageReadUid = ChangeLog.chEntityPk
    |                         AND ChangeLog.chTableId = 129
    |                         
    |
    |                 JOIN UserSession ON
    |                      ((UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid
    |                              FROM ChatMember
    |                             WHERE ChatMember.chatMemberChatUid = MessageRead.messageReadEntityUid))
    |                       OR UserSession.usSessionType = 2)
    |                   
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND MessageRead.messageReadLct != COALESCE(
    |                 (SELECT messageReadVersionId
    |                    FROM MessageReadReplicate
    |                   WHERE messageReadPk = MessageRead.messageReadUid
    |                     AND messageReadDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(messageReadPk, messageReadDestination) DO UPDATE
    |             SET messageReadPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReadReplicate(messageReadPk, messageReadDestination)
    |          SELECT DISTINCT MessageRead.messageReadUid AS messageReadUid,
    |                 UserSession.usClientNodeId AS messageReadDestination
    |            FROM ChangeLog
    |            
    |                 JOIN MessageRead 
    |                      ON MessageRead.messageReadUid = ChangeLog.chEntityPk
    |                         AND ChangeLog.chTableId = 129
    |                         
    |
    |                 JOIN UserSession ON
    |                      ((UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid
    |                              FROM ChatMember
    |                             WHERE ChatMember.chatMemberChatUid = MessageRead.messageReadEntityUid))
    |                       OR UserSession.usSessionType = 2)
    |                   
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND MessageRead.messageReadLct != COALESCE(
    |                 (SELECT messageReadVersionId
    |                    FROM MessageReadReplicate
    |                   WHERE messageReadPk = MessageRead.messageReadUid
    |                     AND messageReadDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(messageReadPk, messageReadDestination) DO UPDATE
    |             SET messageReadPending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: MessageRead): Long {
    val _retVal = _insertAdapterMessageRead_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: MessageRead): Long {
    val _retVal = _insertAdapterMessageRead_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out MessageRead>): Unit {
    _insertAdapterMessageRead_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out MessageRead>): Unit {
    val _sql =
        "UPDATE MessageRead SET messageReadPersonUid = ?, messageReadMessageUid = ?, messageReadEntityUid = ?, messageReadLct = ? WHERE messageReadUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.messageReadPersonUid)
        _stmt.setLong(2, _entity.messageReadMessageUid)
        _stmt.setLong(3, _entity.messageReadEntityUid)
        _stmt.setLong(4, _entity.messageReadLct)
        _stmt.setLong(5, _entity.messageReadUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: MessageRead): Unit {
    val _sql =
        "UPDATE MessageRead SET messageReadPersonUid = ?, messageReadMessageUid = ?, messageReadEntityUid = ?, messageReadLct = ? WHERE messageReadUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.messageReadPersonUid)
      _stmt.setLong(2, entity.messageReadMessageUid)
      _stmt.setLong(3, entity.messageReadEntityUid)
      _stmt.setLong(4, entity.messageReadLct)
      _stmt.setLong(5, entity.messageReadUid)
      _stmt.executeUpdate()
    }
  }
}
