package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.VerbDisplay
import com.ustadmobile.lib.db.entities.VerbEntity
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class VerbDao_JdbcKt(
  public val _db: DoorDatabase
) : VerbDao() {
  public val _insertAdapterVerbEntity_upsert: EntityInsertionAdapter<VerbEntity> = object :
      EntityInsertionAdapter<VerbEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO VerbEntity (verbUid, urlId, verbInActive, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, verbLct) VALUES(?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO VerbEntity (verbUid, urlId, verbInActive, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, verbLct) VALUES(COALESCE(?,nextval('VerbEntity_verbUid_seq')), ?, ?, ?, ?, ?, ?) ON CONFLICT (verbUid) DO UPDATE SET urlId = excluded.urlId,verbInActive = excluded.verbInActive,verbMasterChangeSeqNum = excluded.verbMasterChangeSeqNum,verbLocalChangeSeqNum = excluded.verbLocalChangeSeqNum,verbLastChangedBy = excluded.verbLastChangedBy,verbLct = excluded.verbLct" + if(returnsId) { " RETURNING verbUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: VerbEntity):
        Unit {
      if(entity.verbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.verbUid)
      }
      stmt.setString(2, entity.urlId)
      stmt.setBoolean(3, entity.verbInActive)
      stmt.setLong(4, entity.verbMasterChangeSeqNum)
      stmt.setLong(5, entity.verbLocalChangeSeqNum)
      stmt.setInt(6, entity.verbLastChangedBy)
      stmt.setLong(7, entity.verbLct)
    }
  }

  public val _insertAdapterVerbEntity_: EntityInsertionAdapter<VerbEntity> = object :
      EntityInsertionAdapter<VerbEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO VerbEntity (verbUid, urlId, verbInActive, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, verbLct) VALUES(?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO VerbEntity (verbUid, urlId, verbInActive, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, verbLct) VALUES(COALESCE(?,nextval('VerbEntity_verbUid_seq')), ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING verbUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: VerbEntity):
        Unit {
      if(entity.verbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.verbUid)
      }
      stmt.setString(2, entity.urlId)
      stmt.setBoolean(3, entity.verbInActive)
      stmt.setLong(4, entity.verbMasterChangeSeqNum)
      stmt.setLong(5, entity.verbLocalChangeSeqNum)
      stmt.setInt(6, entity.verbLastChangedBy)
      stmt.setLong(7, entity.verbLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO VerbEntityReplicate(vePk, veDestination)
    |      SELECT DISTINCT VerbEntity.verbUid AS vePk,
    |             ? AS veDestination
    |        FROM VerbEntity
    |       WHERE VerbEntity.verbLct != COALESCE(
    |             (SELECT veVersionId
    |                FROM VerbEntityReplicate
    |               WHERE vePk = VerbEntity.verbUid
    |                 AND veDestination = ?), 0) 
    |      /*psql ON CONFLICT(vePk, veDestination) DO UPDATE
    |             SET vePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO VerbEntityReplicate(vePk, veDestination)
    |      SELECT DISTINCT VerbEntity.verbUid AS vePk,
    |             ? AS veDestination
    |        FROM VerbEntity
    |       WHERE VerbEntity.verbLct != COALESCE(
    |             (SELECT veVersionId
    |                FROM VerbEntityReplicate
    |               WHERE vePk = VerbEntity.verbUid
    |                 AND veDestination = ?), 0) 
    |       ON CONFLICT(vePk, veDestination) DO UPDATE
    |             SET vePending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |    REPLACE INTO VerbEntityReplicate(vePk, veDestination)
    |    SELECT DISTINCT VerbEntity.verbUid AS veUid,
    |         UserSession.usClientNodeId AS veDestination
    |    FROM ChangeLog
    |         JOIN VerbEntity
    |             ON ChangeLog.chTableId = 62
    |                AND ChangeLog.chEntityPk = VerbEntity.verbUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND VerbEntity.verbLct != COALESCE(
    |         (SELECT veVersionId
    |            FROM VerbEntityReplicate
    |           WHERE vePk = VerbEntity.verbUid
    |             AND veDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(vePk, veDestination) DO UPDATE
    |     SET vePending = true
    |    */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO VerbEntityReplicate(vePk, veDestination)
    |    SELECT DISTINCT VerbEntity.verbUid AS veUid,
    |         UserSession.usClientNodeId AS veDestination
    |    FROM ChangeLog
    |         JOIN VerbEntity
    |             ON ChangeLog.chTableId = 62
    |                AND ChangeLog.chEntityPk = VerbEntity.verbUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND VerbEntity.verbLct != COALESCE(
    |         (SELECT veVersionId
    |            FROM VerbEntityReplicate
    |           WHERE vePk = VerbEntity.verbUid
    |             AND veDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(vePk, veDestination) DO UPDATE
    |     SET vePending = true
    |                   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUrl(urlId: String?): VerbEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.VerbEntity??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM VerbEntity WHERE urlId = ?" ,
        postgreSql = """
    |SELECT * FROM VerbEntity WHERE urlId = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, urlId)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_verbUid = _resultSet.getLong("verbUid")
          val tmp_urlId = _resultSet.getString("urlId")
          val tmp_verbInActive = _resultSet.getBoolean("verbInActive")
          val tmp_verbMasterChangeSeqNum = _resultSet.getLong("verbMasterChangeSeqNum")
          val tmp_verbLocalChangeSeqNum = _resultSet.getLong("verbLocalChangeSeqNum")
          val tmp_verbLastChangedBy = _resultSet.getInt("verbLastChangedBy")
          val tmp_verbLct = _resultSet.getLong("verbLct")
          val _entity = VerbEntity()
          _entity.verbUid = tmp_verbUid
          _entity.urlId = tmp_urlId
          _entity.verbInActive = tmp_verbInActive
          _entity.verbMasterChangeSeqNum = tmp_verbMasterChangeSeqNum
          _entity.verbLocalChangeSeqNum = tmp_verbLocalChangeSeqNum
          _entity.verbLastChangedBy = tmp_verbLastChangedBy
          _entity.verbLct = tmp_verbLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidList(uidList: List<Long>): List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("SELECT verbUid FROM VerbEntity WHERE verbUid IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT verbUid FROM VerbEntity WHERE verbUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", uidList.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun replaceList(entityList: List<VerbEntity>): Unit {
    _insertAdapterVerbEntity_upsert.insertListAsync(entityList)
  }

  public override fun findAllVerbsAscList(uidList: List<Long>): List<VerbDisplay> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.VerbDisplay>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display
    |        FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
    |         XLangMapEntry.verbLangMapUid NOT IN (?)
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display
    |        FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
    |         XLangMapEntry.verbLangMapUid NOT IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", uidList.toTypedArray()))
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_verbUid = _resultSet.getLong("verbUid")
          val tmp_urlId = _resultSet.getString("urlId")
          val tmp_display = _resultSet.getString("display")
          val _entity = VerbDisplay()
          _entity.verbUid = tmp_verbUid
          _entity.urlId = tmp_urlId
          _entity.display = tmp_display
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllVerbsAsc(uidList: List<Long>): DoorDataSourceFactory<Int,
      VerbDisplay> {
    val _result = object : DoorDataSourceFactory<Int, VerbDisplay>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<VerbDisplay>> =
          DoorLiveDataImpl<List<VerbDisplay>>(_db, listOf("VerbEntity", "XLangMapEntry"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.VerbDisplay>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
        |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
        |         VerbEntity.verbUid NOT IN (?) ORDER BY display ASC) LIMIT ? OFFSET ? 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT * FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
        |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
        |         VerbEntity.verbUid NOT IN (?) ORDER BY display ASC) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              uidList.toTypedArray()))
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_verbUid = _resultSet.getLong("verbUid")
              val tmp_urlId = _resultSet.getString("urlId")
              val tmp_display = _resultSet.getString("display")
              val _entity = VerbDisplay()
              _entity.verbUid = tmp_verbUid
              _entity.urlId = tmp_urlId
              _entity.display = tmp_display
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("VerbEntity", "XLangMapEntry"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
        |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
        |         VerbEntity.verbUid NOT IN (?) ORDER BY display ASC) 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT COUNT(*) FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
        |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
        |         VerbEntity.verbUid NOT IN (?) ORDER BY display ASC) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              uidList.toTypedArray()))
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findAllVerbsDesc(uidList: List<Long>): DoorDataSourceFactory<Int,
      VerbDisplay> {
    val _result = object : DoorDataSourceFactory<Int, VerbDisplay>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<VerbDisplay>> =
          DoorLiveDataImpl<List<VerbDisplay>>(_db, listOf("VerbEntity", "XLangMapEntry"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.VerbDisplay>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
        |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
        |        VerbEntity.verbUid NOT IN (?) ORDER BY display DESC) LIMIT ? OFFSET ? 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT * FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
        |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
        |        VerbEntity.verbUid NOT IN (?) ORDER BY display DESC) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              uidList.toTypedArray()))
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_verbUid = _resultSet.getLong("verbUid")
              val tmp_urlId = _resultSet.getString("urlId")
              val tmp_display = _resultSet.getString("display")
              val _entity = VerbDisplay()
              _entity.verbUid = tmp_verbUid
              _entity.urlId = tmp_urlId
              _entity.display = tmp_display
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("VerbEntity", "XLangMapEntry"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
        |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
        |        VerbEntity.verbUid NOT IN (?) ORDER BY display DESC) 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT COUNT(*) FROM (SELECT VerbEntity.verbUid, VerbEntity.urlId, XLangMapEntry.valueLangMap AS display 
        |         FROM VerbEntity LEFT JOIN XLangMapEntry on XLangMapEntry.verbLangMapUid = VerbEntity.verbUid WHERE 
        |        VerbEntity.verbUid NOT IN (?) ORDER BY display DESC) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              uidList.toTypedArray()))
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun insert(entity: VerbEntity): Long {
    val _retVal = _insertAdapterVerbEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: VerbEntity): Long {
    val _retVal = _insertAdapterVerbEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out VerbEntity>): Unit {
    _insertAdapterVerbEntity_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out VerbEntity>): Unit {
    val _sql =
        "UPDATE VerbEntity SET urlId = ?, verbInActive = ?, verbMasterChangeSeqNum = ?, verbLocalChangeSeqNum = ?, verbLastChangedBy = ?, verbLct = ? WHERE verbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.urlId)
        _stmt.setBoolean(2, _entity.verbInActive)
        _stmt.setLong(3, _entity.verbMasterChangeSeqNum)
        _stmt.setLong(4, _entity.verbLocalChangeSeqNum)
        _stmt.setInt(5, _entity.verbLastChangedBy)
        _stmt.setLong(6, _entity.verbLct)
        _stmt.setLong(7, _entity.verbUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: VerbEntity): Unit {
    val _sql =
        "UPDATE VerbEntity SET urlId = ?, verbInActive = ?, verbMasterChangeSeqNum = ?, verbLocalChangeSeqNum = ?, verbLastChangedBy = ?, verbLct = ? WHERE verbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.urlId)
      _stmt.setBoolean(2, entity.verbInActive)
      _stmt.setLong(3, entity.verbMasterChangeSeqNum)
      _stmt.setLong(4, entity.verbLocalChangeSeqNum)
      _stmt.setInt(5, entity.verbLastChangedBy)
      _stmt.setLong(6, entity.verbLct)
      _stmt.setLong(7, entity.verbUid)
      _stmt.executeUpdate()
    }
  }
}
