package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentCategory
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentCategoryDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentCategoryDao() {
  public val _insertAdapterContentCategory_: EntityInsertionAdapter<ContentCategory> = object :
      EntityInsertionAdapter<ContentCategory>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentCategory (contentCategoryUid, ctnCatContentCategorySchemaUid, name, contentCategoryLocalChangeSeqNum, contentCategoryMasterChangeSeqNum, contentCategoryLastChangedBy, contentCategoryLct) VALUES(?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentCategory (contentCategoryUid, ctnCatContentCategorySchemaUid, name, contentCategoryLocalChangeSeqNum, contentCategoryMasterChangeSeqNum, contentCategoryLastChangedBy, contentCategoryLct) VALUES(COALESCE(?,nextval('ContentCategory_contentCategoryUid_seq')), ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING contentCategoryUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentCategory):
        Unit {
      if(entity.contentCategoryUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentCategoryUid)
      }
      stmt.setLong(2, entity.ctnCatContentCategorySchemaUid)
      stmt.setString(3, entity.name)
      stmt.setLong(4, entity.contentCategoryLocalChangeSeqNum)
      stmt.setLong(5, entity.contentCategoryMasterChangeSeqNum)
      stmt.setInt(6, entity.contentCategoryLastChangedBy)
      stmt.setLong(7, entity.contentCategoryLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentCategoryReplicate(ccPk, ccDestination)
    |      SELECT DISTINCT ContentCategory.contentCategoryUid AS ccPk,
    |             ? AS ccDestination
    |        FROM ContentCategory
    |       WHERE ContentCategory.contentCategoryLct != COALESCE(
    |             (SELECT ccVersionId
    |                FROM ContentCategoryReplicate
    |               WHERE ccPk = ContentCategory.contentCategoryUid
    |                 AND ccDestination = ?), 0) 
    |      /*psql ON CONFLICT(ccPk, ccDestination) DO UPDATE
    |             SET ccPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentCategoryReplicate(ccPk, ccDestination)
    |      SELECT DISTINCT ContentCategory.contentCategoryUid AS ccPk,
    |             ? AS ccDestination
    |        FROM ContentCategory
    |       WHERE ContentCategory.contentCategoryLct != COALESCE(
    |             (SELECT ccVersionId
    |                FROM ContentCategoryReplicate
    |               WHERE ccPk = ContentCategory.contentCategoryUid
    |                 AND ccDestination = ?), 0) 
    |       ON CONFLICT(ccPk, ccDestination) DO UPDATE
    |             SET ccPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ContentCategoryReplicate(ccPk, ccDestination)
    |  SELECT DISTINCT ContentCategory.contentCategoryUid AS ccUid,
    |         UserSession.usClientNodeId AS ccDestination
    |    FROM ChangeLog
    |         JOIN ContentCategory
    |             ON ChangeLog.chTableId = 1
    |                AND ChangeLog.chEntityPk = ContentCategory.contentCategoryUid
    |         JOIN UserSession 
    |              ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentCategory.contentCategoryLct != COALESCE(
    |         (SELECT ccVersionId
    |            FROM ContentCategoryReplicate
    |           WHERE ccPk = ContentCategory.contentCategoryUid
    |             AND ccDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ccPk, ccDestination) DO UPDATE
    |     SET ccPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentCategoryReplicate(ccPk, ccDestination)
    |  SELECT DISTINCT ContentCategory.contentCategoryUid AS ccUid,
    |         UserSession.usClientNodeId AS ccDestination
    |    FROM ChangeLog
    |         JOIN ContentCategory
    |             ON ChangeLog.chTableId = 1
    |                AND ChangeLog.chEntityPk = ContentCategory.contentCategoryUid
    |         JOIN UserSession 
    |              ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentCategory.contentCategoryLct != COALESCE(
    |         (SELECT ccVersionId
    |            FROM ContentCategoryReplicate
    |           WHERE ccPk = ContentCategory.contentCategoryUid
    |             AND ccDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ccPk, ccDestination) DO UPDATE
    |     SET ccPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun publicContentCategories(): List<ContentCategory> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentCategory>()
    val _stmtConfig = PreparedStatementConfig("SELECT ContentCategory.* FROM ContentCategory" ,
        postgreSql = """
    |SELECT ContentCategory.* FROM ContentCategory
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentCategoryUid = _resultSet.getLong("contentCategoryUid")
          val tmp_ctnCatContentCategorySchemaUid =
              _resultSet.getLong("ctnCatContentCategorySchemaUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_contentCategoryLocalChangeSeqNum =
              _resultSet.getLong("contentCategoryLocalChangeSeqNum")
          val tmp_contentCategoryMasterChangeSeqNum =
              _resultSet.getLong("contentCategoryMasterChangeSeqNum")
          val tmp_contentCategoryLastChangedBy = _resultSet.getInt("contentCategoryLastChangedBy")
          val tmp_contentCategoryLct = _resultSet.getLong("contentCategoryLct")
          val _entity = ContentCategory()
          _entity.contentCategoryUid = tmp_contentCategoryUid
          _entity.ctnCatContentCategorySchemaUid = tmp_ctnCatContentCategorySchemaUid
          _entity.name = tmp_name
          _entity.contentCategoryLocalChangeSeqNum = tmp_contentCategoryLocalChangeSeqNum
          _entity.contentCategoryMasterChangeSeqNum = tmp_contentCategoryMasterChangeSeqNum
          _entity.contentCategoryLastChangedBy = tmp_contentCategoryLastChangedBy
          _entity.contentCategoryLct = tmp_contentCategoryLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findCategoryBySchemaIdAndName(schemaId: Long, name: String):
      ContentCategory? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentCategory??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentCategory WHERE ctnCatContentCategorySchemaUid = ? AND name = ?"
        , postgreSql = """
    |SELECT * FROM ContentCategory WHERE ctnCatContentCategorySchemaUid = ? AND name = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, schemaId)
      _stmt.setString(2, name)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentCategoryUid = _resultSet.getLong("contentCategoryUid")
          val tmp_ctnCatContentCategorySchemaUid =
              _resultSet.getLong("ctnCatContentCategorySchemaUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_contentCategoryLocalChangeSeqNum =
              _resultSet.getLong("contentCategoryLocalChangeSeqNum")
          val tmp_contentCategoryMasterChangeSeqNum =
              _resultSet.getLong("contentCategoryMasterChangeSeqNum")
          val tmp_contentCategoryLastChangedBy = _resultSet.getInt("contentCategoryLastChangedBy")
          val tmp_contentCategoryLct = _resultSet.getLong("contentCategoryLct")
          val _entity = ContentCategory()
          _entity.contentCategoryUid = tmp_contentCategoryUid
          _entity.ctnCatContentCategorySchemaUid = tmp_ctnCatContentCategorySchemaUid
          _entity.name = tmp_name
          _entity.contentCategoryLocalChangeSeqNum = tmp_contentCategoryLocalChangeSeqNum
          _entity.contentCategoryMasterChangeSeqNum = tmp_contentCategoryMasterChangeSeqNum
          _entity.contentCategoryLastChangedBy = tmp_contentCategoryLastChangedBy
          _entity.contentCategoryLct = tmp_contentCategoryLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ContentCategory): Long {
    val _retVal = _insertAdapterContentCategory_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentCategory): Long {
    val _retVal = _insertAdapterContentCategory_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContentCategory>): Unit {
    _insertAdapterContentCategory_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentCategory>): Unit {
    val _sql =
        "UPDATE ContentCategory SET ctnCatContentCategorySchemaUid = ?, name = ?, contentCategoryLocalChangeSeqNum = ?, contentCategoryMasterChangeSeqNum = ?, contentCategoryLastChangedBy = ?, contentCategoryLct = ? WHERE contentCategoryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.ctnCatContentCategorySchemaUid)
        _stmt.setString(2, _entity.name)
        _stmt.setLong(3, _entity.contentCategoryLocalChangeSeqNum)
        _stmt.setLong(4, _entity.contentCategoryMasterChangeSeqNum)
        _stmt.setInt(5, _entity.contentCategoryLastChangedBy)
        _stmt.setLong(6, _entity.contentCategoryLct)
        _stmt.setLong(7, _entity.contentCategoryUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContentCategory): Unit {
    val _sql =
        "UPDATE ContentCategory SET ctnCatContentCategorySchemaUid = ?, name = ?, contentCategoryLocalChangeSeqNum = ?, contentCategoryMasterChangeSeqNum = ?, contentCategoryLastChangedBy = ?, contentCategoryLct = ? WHERE contentCategoryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.ctnCatContentCategorySchemaUid)
      _stmt.setString(2, entity.name)
      _stmt.setLong(3, entity.contentCategoryLocalChangeSeqNum)
      _stmt.setLong(4, entity.contentCategoryMasterChangeSeqNum)
      _stmt.setInt(5, entity.contentCategoryLastChangedBy)
      _stmt.setLong(6, entity.contentCategoryLct)
      _stmt.setLong(7, entity.contentCategoryUid)
      _stmt.executeUpdate()
    }
  }
}
