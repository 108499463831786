package com.ustadmobile.core.view

interface SelectFolderView : UstadView {

    companion object {

        const val VIEW_NAME = "SelectFolderView"

    }
}
