package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.SchoolMember
import com.ustadmobile.lib.db.entities.SchoolMemberWithPerson
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class SchoolMemberDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: SchoolMemberDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : SchoolMemberDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUidAsync(schoolMemberUid: Long): SchoolMember? {
    val _result = _dao.findByUidAsync(schoolMemberUid)
    return _result
  }

  public override suspend fun updateAsync(entity: SchoolMember): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override suspend fun findBySchoolAndPersonAndRole(
    schoolUid: Long,
    personUid: Long,
    role: Int,
    timeFilter: Long
  ): List<SchoolMember> {
    val _result = _dao.findBySchoolAndPersonAndRole(schoolUid, personUid, role, timeFilter)
    return _result
  }

  public override fun findAllActiveMembersBySchoolAndRoleUid(
    schoolUid: Long,
    role: Int,
    sortOrder: Int,
    searchQuery: String,
    accountPersonUid: Long
  ): DoorDataSourceFactory<Int, SchoolMemberWithPerson> {
    val _result = _dao.findAllActiveMembersBySchoolAndRoleUid(schoolUid, role, sortOrder,
        searchQuery, accountPersonUid)
    return _result
  }

  public override suspend fun findAllTest(
    schoolUid: Long,
    role: Int,
    searchQuery: String
  ): List<SchoolMemberWithPerson> {
    val _result = _dao.findAllTest(schoolUid, role, searchQuery)
    return _result
  }

  public override fun insert(entity: SchoolMember): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: SchoolMember): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out SchoolMember>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out SchoolMember>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: SchoolMember): Unit {
    _dao.update(entity)
  }
}
