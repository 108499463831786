package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ErrorReport
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ErrorReportDao_JdbcKt(
  public val _db: DoorDatabase
) : ErrorReportDao() {
  public val _insertAdapterErrorReport_: EntityInsertionAdapter<ErrorReport> = object :
      EntityInsertionAdapter<ErrorReport>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ErrorReport (errUid, errPcsn, errLcsn, errLcb, errLct, severity, timestamp, presenterUri, appVersion, versionCode, errorCode, operatingSys, osVersion, stackTrace, message) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ErrorReport (errUid, errPcsn, errLcsn, errLcb, errLct, severity, timestamp, presenterUri, appVersion, versionCode, errorCode, operatingSys, osVersion, stackTrace, message) VALUES(COALESCE(?,nextval('ErrorReport_errUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING errUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ErrorReport):
        Unit {
      if(entity.errUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.errUid)
      }
      stmt.setLong(2, entity.errPcsn)
      stmt.setLong(3, entity.errLcsn)
      stmt.setInt(4, entity.errLcb)
      stmt.setLong(5, entity.errLct)
      stmt.setInt(6, entity.severity)
      stmt.setLong(7, entity.timestamp)
      stmt.setString(8, entity.presenterUri)
      stmt.setString(9, entity.appVersion)
      stmt.setInt(10, entity.versionCode)
      stmt.setInt(11, entity.errorCode)
      stmt.setString(12, entity.operatingSys)
      stmt.setString(13, entity.osVersion)
      stmt.setString(14, entity.stackTrace)
      stmt.setString(15, entity.message)
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ErrorReportReplicate(erPk, erDestination)
    |  SELECT DISTINCT ErrorReport.errUid AS erUid,
    |           UserSession.usClientNodeId AS erDestination
    |    FROM ChangeLog
    |         JOIN ErrorReport
    |             ON ChangeLog.chTableId = 419
    |                AND ChangeLog.chEntityPk = ErrorReport.errUid
    |         JOIN UserSession ON UserSession.usSessionType = 2
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ErrorReport.errLct != COALESCE(
    |         (SELECT erVersionId
    |            FROM ErrorReportReplicate
    |           WHERE erPk = ErrorReport.errUid
    |             AND erDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(erPk, erDestination) DO UPDATE
    |     SET erPending = true
    |    */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ErrorReportReplicate(erPk, erDestination)
    |  SELECT DISTINCT ErrorReport.errUid AS erUid,
    |           UserSession.usClientNodeId AS erDestination
    |    FROM ChangeLog
    |         JOIN ErrorReport
    |             ON ChangeLog.chTableId = 419
    |                AND ChangeLog.chEntityPk = ErrorReport.errUid
    |         JOIN UserSession ON UserSession.usSessionType = 2
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ErrorReport.errLct != COALESCE(
    |         (SELECT erVersionId
    |            FROM ErrorReportReplicate
    |           WHERE erPk = ErrorReport.errUid
    |             AND erDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(erPk, erDestination) DO UPDATE
    |     SET erPending = true
    |                   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertAsync(errorReport: ErrorReport): Long {
    val _retVal = _insertAdapterErrorReport_.insertAndReturnIdAsync(errorReport)
    return _retVal
  }

  public override suspend fun findByUidAsync(errUid: Long): ErrorReport? {
    var _result = null as com.ustadmobile.lib.db.entities.ErrorReport??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ErrorReport.* 
    |          FROM ErrorReport
    |         WHERE errUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ErrorReport.* 
    |          FROM ErrorReport
    |         WHERE errUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, errUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_errUid = _resultSet.getLong("errUid")
          val tmp_errPcsn = _resultSet.getLong("errPcsn")
          val tmp_errLcsn = _resultSet.getLong("errLcsn")
          val tmp_errLcb = _resultSet.getInt("errLcb")
          val tmp_errLct = _resultSet.getLong("errLct")
          val tmp_severity = _resultSet.getInt("severity")
          val tmp_timestamp = _resultSet.getLong("timestamp")
          val tmp_presenterUri = _resultSet.getString("presenterUri")
          val tmp_appVersion = _resultSet.getString("appVersion")
          val tmp_versionCode = _resultSet.getInt("versionCode")
          val tmp_errorCode = _resultSet.getInt("errorCode")
          val tmp_operatingSys = _resultSet.getString("operatingSys")
          val tmp_osVersion = _resultSet.getString("osVersion")
          val tmp_stackTrace = _resultSet.getString("stackTrace")
          val tmp_message = _resultSet.getString("message")
          val _entity = ErrorReport()
          _entity.errUid = tmp_errUid
          _entity.errPcsn = tmp_errPcsn
          _entity.errLcsn = tmp_errLcsn
          _entity.errLcb = tmp_errLcb
          _entity.errLct = tmp_errLct
          _entity.severity = tmp_severity
          _entity.timestamp = tmp_timestamp
          _entity.presenterUri = tmp_presenterUri
          _entity.appVersion = tmp_appVersion
          _entity.versionCode = tmp_versionCode
          _entity.errorCode = tmp_errorCode
          _entity.operatingSys = tmp_operatingSys
          _entity.osVersion = tmp_osVersion
          _entity.stackTrace = tmp_stackTrace
          _entity.message = tmp_message
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByErrorCode(errCode: Int): List<ErrorReport> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ErrorReport>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ErrorReport.*
    |          FROM ErrorReport
    |         WHERE errorCode = ?  
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ErrorReport.*
    |          FROM ErrorReport
    |         WHERE errorCode = ?  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, errCode)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_errUid = _resultSet.getLong("errUid")
          val tmp_errPcsn = _resultSet.getLong("errPcsn")
          val tmp_errLcsn = _resultSet.getLong("errLcsn")
          val tmp_errLcb = _resultSet.getInt("errLcb")
          val tmp_errLct = _resultSet.getLong("errLct")
          val tmp_severity = _resultSet.getInt("severity")
          val tmp_timestamp = _resultSet.getLong("timestamp")
          val tmp_presenterUri = _resultSet.getString("presenterUri")
          val tmp_appVersion = _resultSet.getString("appVersion")
          val tmp_versionCode = _resultSet.getInt("versionCode")
          val tmp_errorCode = _resultSet.getInt("errorCode")
          val tmp_operatingSys = _resultSet.getString("operatingSys")
          val tmp_osVersion = _resultSet.getString("osVersion")
          val tmp_stackTrace = _resultSet.getString("stackTrace")
          val tmp_message = _resultSet.getString("message")
          val _entity = ErrorReport()
          _entity.errUid = tmp_errUid
          _entity.errPcsn = tmp_errPcsn
          _entity.errLcsn = tmp_errLcsn
          _entity.errLcb = tmp_errLcb
          _entity.errLct = tmp_errLct
          _entity.severity = tmp_severity
          _entity.timestamp = tmp_timestamp
          _entity.presenterUri = tmp_presenterUri
          _entity.appVersion = tmp_appVersion
          _entity.versionCode = tmp_versionCode
          _entity.errorCode = tmp_errorCode
          _entity.operatingSys = tmp_operatingSys
          _entity.osVersion = tmp_osVersion
          _entity.stackTrace = tmp_stackTrace
          _entity.message = tmp_message
          _result.add(_entity)
        }
      }
    }
    return _result
  }
}
