package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Chat
import com.ustadmobile.lib.db.entities.ChatWithLatestMessageAndCount
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ChatDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: ChatDao
) : ChatDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findAllChatsForUser(searchBit: String, personUid: Long):
      DoorDataSourceFactory<Int, ChatWithLatestMessageAndCount> =
      _dao.findAllChatsForUser(searchBit, personUid)

  public override suspend fun getTitleChat(chatUid: Long, personUid: Long): String? =
      _dao.getTitleChat(chatUid, personUid)

  public override suspend fun getChatByOtherPerson(otherPersonUid: Long, loggedInPersonUid: Long):
      Chat? = _dao.getChatByOtherPerson(otherPersonUid, loggedInPersonUid)

  public override fun insert(entity: Chat): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Chat): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.chatUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(127)
      entity.chatUid = _newPk
    }
    entity.chatLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.chatUid
  }

  public override fun insertList(entityList: List<out Chat>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out Chat>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Chat): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
