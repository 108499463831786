package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryButtonModel
import com.ustadmobile.lib.db.entities.ContentEntryContentJobItemParams
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.ContentEntryWithBlockAndLanguage
import com.ustadmobile.lib.db.entities.ContentEntryWithLanguage
import com.ustadmobile.lib.db.entities.ContentEntryWithMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentJobItemProgressAndStatus
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.DistinctCategorySchema
import com.ustadmobile.lib.db.entities.DownloadJobSizeInfo
import com.ustadmobile.lib.db.entities.LangUidAndName
import com.ustadmobile.lib.db.entities.Language
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentEntryDao() {
  public val _insertAdapterContentEntry_: EntityInsertionAdapter<ContentEntry> = object :
      EntityInsertionAdapter<ContentEntry>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentEntry (contentEntryUid, title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentEntry (contentEntryUid, title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct) VALUES(COALESCE(?,nextval('ContentEntry_contentEntryUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING contentEntryUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentEntry):
        Unit {
      if(entity.contentEntryUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentEntryUid)
      }
      stmt.setString(2, entity.title)
      stmt.setString(3, entity.description)
      stmt.setString(4, entity.entryId)
      stmt.setString(5, entity.author)
      stmt.setString(6, entity.publisher)
      stmt.setInt(7, entity.licenseType)
      stmt.setString(8, entity.licenseName)
      stmt.setString(9, entity.licenseUrl)
      stmt.setString(10, entity.sourceUrl)
      stmt.setString(11, entity.thumbnailUrl)
      stmt.setLong(12, entity.lastModified)
      stmt.setLong(13, entity.primaryLanguageUid)
      stmt.setLong(14, entity.languageVariantUid)
      stmt.setInt(15, entity.contentFlags)
      stmt.setBoolean(16, entity.leaf)
      stmt.setBoolean(17, entity.publik)
      stmt.setBoolean(18, entity.ceInactive)
      stmt.setInt(19, entity.completionCriteria)
      stmt.setInt(20, entity.minScore)
      stmt.setInt(21, entity.contentTypeFlag)
      stmt.setLong(22, entity.contentOwner)
      stmt.setLong(23, entity.contentEntryLocalChangeSeqNum)
      stmt.setLong(24, entity.contentEntryMasterChangeSeqNum)
      stmt.setInt(25, entity.contentEntryLastChangedBy)
      stmt.setLong(26, entity.contentEntryLct)
    }
  }

  public val _insertAdapterContentEntry_upsert: EntityInsertionAdapter<ContentEntry> = object :
      EntityInsertionAdapter<ContentEntry>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO ContentEntry (contentEntryUid, title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentEntry (contentEntryUid, title, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, contentFlags, leaf, publik, ceInactive, completionCriteria, minScore, contentTypeFlag, contentOwner, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct) VALUES(COALESCE(?,nextval('ContentEntry_contentEntryUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (contentEntryUid) DO UPDATE SET title = excluded.title,description = excluded.description,entryId = excluded.entryId,author = excluded.author,publisher = excluded.publisher,licenseType = excluded.licenseType,licenseName = excluded.licenseName,licenseUrl = excluded.licenseUrl,sourceUrl = excluded.sourceUrl,thumbnailUrl = excluded.thumbnailUrl,lastModified = excluded.lastModified,primaryLanguageUid = excluded.primaryLanguageUid,languageVariantUid = excluded.languageVariantUid,contentFlags = excluded.contentFlags,leaf = excluded.leaf,publik = excluded.publik,ceInactive = excluded.ceInactive,completionCriteria = excluded.completionCriteria,minScore = excluded.minScore,contentTypeFlag = excluded.contentTypeFlag,contentOwner = excluded.contentOwner,contentEntryLocalChangeSeqNum = excluded.contentEntryLocalChangeSeqNum,contentEntryMasterChangeSeqNum = excluded.contentEntryMasterChangeSeqNum,contentEntryLastChangedBy = excluded.contentEntryLastChangedBy,contentEntryLct = excluded.contentEntryLct" + if(returnsId) { " RETURNING contentEntryUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentEntry):
        Unit {
      if(entity.contentEntryUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contentEntryUid)
      }
      stmt.setString(2, entity.title)
      stmt.setString(3, entity.description)
      stmt.setString(4, entity.entryId)
      stmt.setString(5, entity.author)
      stmt.setString(6, entity.publisher)
      stmt.setInt(7, entity.licenseType)
      stmt.setString(8, entity.licenseName)
      stmt.setString(9, entity.licenseUrl)
      stmt.setString(10, entity.sourceUrl)
      stmt.setString(11, entity.thumbnailUrl)
      stmt.setLong(12, entity.lastModified)
      stmt.setLong(13, entity.primaryLanguageUid)
      stmt.setLong(14, entity.languageVariantUid)
      stmt.setInt(15, entity.contentFlags)
      stmt.setBoolean(16, entity.leaf)
      stmt.setBoolean(17, entity.publik)
      stmt.setBoolean(18, entity.ceInactive)
      stmt.setInt(19, entity.completionCriteria)
      stmt.setInt(20, entity.minScore)
      stmt.setInt(21, entity.contentTypeFlag)
      stmt.setLong(22, entity.contentOwner)
      stmt.setLong(23, entity.contentEntryLocalChangeSeqNum)
      stmt.setLong(24, entity.contentEntryMasterChangeSeqNum)
      stmt.setInt(25, entity.contentEntryLastChangedBy)
      stmt.setLong(26, entity.contentEntryLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO ContentEntryReplicate(cePk, ceDestination)
    |         SELECT DISTINCT contentEntryUid AS ceUid,
    |                ? AS siteDestination
    |           FROM ContentEntry
    |          WHERE ContentEntry.contentEntryLct != COALESCE(
    |                (SELECT ceVersionId
    |                   FROM ContentEntryReplicate
    |                  WHERE cePk = ContentEntry.contentEntryUid
    |                    AND ceDestination = ?), -1) 
    |         /*psql ON CONFLICT(cePk, ceDestination) DO UPDATE
    |                SET cePending = true
    |         */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryReplicate(cePk, ceDestination)
    |         SELECT DISTINCT contentEntryUid AS ceUid,
    |                ? AS siteDestination
    |           FROM ContentEntry
    |          WHERE ContentEntry.contentEntryLct != COALESCE(
    |                (SELECT ceVersionId
    |                   FROM ContentEntryReplicate
    |                  WHERE cePk = ContentEntry.contentEntryUid
    |                    AND ceDestination = ?), -1) 
    |          ON CONFLICT(cePk, ceDestination) DO UPDATE
    |                SET cePending = true
    |                
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO ContentEntryReplicate(cePk, ceDestination)
    |         SELECT DISTINCT ContentEntry.contentEntryUid AS cePk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN ContentEntry
    |                    ON ChangeLog.chTableId = 42
    |                       AND ChangeLog.chEntityPk = ContentEntry.contentEntryUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND ContentEntry.contentEntryLct != COALESCE(
    |                (SELECT ceVersionId
    |                   FROM ContentEntryReplicate
    |                  WHERE cePk = ContentEntry.contentEntryUid
    |                    AND ceDestination = UserSession.usClientNodeId), 0)     
    |        /*psql ON CONFLICT(cePk, ceDestination) DO UPDATE
    |            SET cePending = true
    |         */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryReplicate(cePk, ceDestination)
    |         SELECT DISTINCT ContentEntry.contentEntryUid AS cePk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN ContentEntry
    |                    ON ChangeLog.chTableId = 42
    |                       AND ChangeLog.chEntityPk = ContentEntry.contentEntryUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND ContentEntry.contentEntryLct != COALESCE(
    |                (SELECT ceVersionId
    |                   FROM ContentEntryReplicate
    |                  WHERE cePk = ContentEntry.contentEntryUid
    |                    AND ceDestination = UserSession.usClientNodeId), 0)     
    |         ON CONFLICT(cePk, ceDestination) DO UPDATE
    |            SET cePending = true
    |                        
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out ContentEntry>): Unit {
    _insertAdapterContentEntry_.insertListAsync(entityList)
  }

  public override suspend fun findEntryWithLanguageByEntryIdAsync(entryUuid: Long):
      ContentEntryWithLanguage? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryWithLanguage??
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntry.*, Language.* FROM ContentEntry LEFT JOIN Language ON Language.langUid = ContentEntry.primaryLanguageUid WHERE ContentEntry.contentEntryUid=?"
        , postgreSql = """
    |SELECT ContentEntry.*, Language.* FROM ContentEntry LEFT JOIN Language ON Language.langUid = ContentEntry.primaryLanguageUid WHERE ContentEntry.contentEntryUid=?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, entryUuid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntryWithLanguage()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          var _language_nullFieldCount = 0
          val tmp_langUid = _resultSet.getLong("langUid")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_name = _resultSet.getString("name")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLct = _resultSet.getLong("langLct")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          if(_language_nullFieldCount < 11) {
            if(_entity.language == null) {
              _entity.language = Language()
            }
            _entity.language!!.langUid = tmp_langUid
            _entity.language!!.name = tmp_name
            _entity.language!!.iso_639_1_standard = tmp_iso_639_1_standard
            _entity.language!!.iso_639_2_standard = tmp_iso_639_2_standard
            _entity.language!!.iso_639_3_standard = tmp_iso_639_3_standard
            _entity.language!!.Language_Type = tmp_Language_Type
            _entity.language!!.languageActive = tmp_languageActive
            _entity.language!!.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
            _entity.language!!.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
            _entity.language!!.langLastChangedBy = tmp_langLastChangedBy
            _entity.language!!.langLct = tmp_langLct
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findEntryWithBlockAndLanguageByUidAsync(entityUid: Long):
      ContentEntryWithBlockAndLanguage? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryWithBlockAndLanguage??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentEntry.*, 
    |               Language.*,
    |               CourseBlock.*
    |          FROM ContentEntry
    |               LEFT JOIN Language 
    |               ON Language.langUid = ContentEntry.primaryLanguageUid 
    |               
    |               LEFT JOIN CourseBlock
    |               ON CourseBlock.cbType = 104
    |               AND CourseBlock.cbEntityUid = ?
    |               
    |         WHERE ContentEntry.contentEntryUid = ?       
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentEntry.*, 
    |               Language.*,
    |               CourseBlock.*
    |          FROM ContentEntry
    |               LEFT JOIN Language 
    |               ON Language.langUid = ContentEntry.primaryLanguageUid 
    |               
    |               LEFT JOIN CourseBlock
    |               ON CourseBlock.cbType = 104
    |               AND CourseBlock.cbEntityUid = ?
    |               
    |         WHERE ContentEntry.contentEntryUid = ?       
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, entityUid)
      _stmt.setLong(2, entityUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntryWithBlockAndLanguage()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          var _language_nullFieldCount = 0
          val tmp_langUid = _resultSet.getLong("langUid")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_name = _resultSet.getString("name")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLct = _resultSet.getLong("langLct")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          if(_language_nullFieldCount < 11) {
            if(_entity.language == null) {
              _entity.language = Language()
            }
            _entity.language!!.langUid = tmp_langUid
            _entity.language!!.name = tmp_name
            _entity.language!!.iso_639_1_standard = tmp_iso_639_1_standard
            _entity.language!!.iso_639_2_standard = tmp_iso_639_2_standard
            _entity.language!!.iso_639_3_standard = tmp_iso_639_3_standard
            _entity.language!!.Language_Type = tmp_Language_Type
            _entity.language!!.languageActive = tmp_languageActive
            _entity.language!!.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
            _entity.language!!.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
            _entity.language!!.langLastChangedBy = tmp_langLastChangedBy
            _entity.language!!.langLct = tmp_langLct
          }
          var _block_nullFieldCount = 0
          val tmp_cbUid = _resultSet.getLong("cbUid")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbType = _resultSet.getInt("cbType")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbIndentLevel = _resultSet.getInt("cbIndentLevel")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbModuleParentBlockUid = _resultSet.getLong("cbModuleParentBlockUid")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbTitle = _resultSet.getString("cbTitle")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbDescription = _resultSet.getString("cbDescription")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbCompletionCriteria = _resultSet.getInt("cbCompletionCriteria")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbHideUntilDate = _resultSet.getLong("cbHideUntilDate")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbDeadlineDate = _resultSet.getLong("cbDeadlineDate")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbLateSubmissionPenalty = _resultSet.getInt("cbLateSubmissionPenalty")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbGracePeriodDate = _resultSet.getLong("cbGracePeriodDate")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbMaxPoints = _resultSet.getInt("cbMaxPoints")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbMinPoints = _resultSet.getInt("cbMinPoints")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbIndex = _resultSet.getInt("cbIndex")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbClazzUid = _resultSet.getLong("cbClazzUid")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbActive = _resultSet.getBoolean("cbActive")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbHidden = _resultSet.getBoolean("cbHidden")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbEntityUid = _resultSet.getLong("cbEntityUid")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          val tmp_cbLct = _resultSet.getLong("cbLct")
          if(_resultSet.wasNull()) { _block_nullFieldCount++ }
          if(_block_nullFieldCount < 19) {
            if(_entity.block == null) {
              _entity.block = CourseBlock()
            }
            _entity.block!!.cbUid = tmp_cbUid
            _entity.block!!.cbType = tmp_cbType
            _entity.block!!.cbIndentLevel = tmp_cbIndentLevel
            _entity.block!!.cbModuleParentBlockUid = tmp_cbModuleParentBlockUid
            _entity.block!!.cbTitle = tmp_cbTitle
            _entity.block!!.cbDescription = tmp_cbDescription
            _entity.block!!.cbCompletionCriteria = tmp_cbCompletionCriteria
            _entity.block!!.cbHideUntilDate = tmp_cbHideUntilDate
            _entity.block!!.cbDeadlineDate = tmp_cbDeadlineDate
            _entity.block!!.cbLateSubmissionPenalty = tmp_cbLateSubmissionPenalty
            _entity.block!!.cbGracePeriodDate = tmp_cbGracePeriodDate
            _entity.block!!.cbMaxPoints = tmp_cbMaxPoints
            _entity.block!!.cbMinPoints = tmp_cbMinPoints
            _entity.block!!.cbIndex = tmp_cbIndex
            _entity.block!!.cbClazzUid = tmp_cbClazzUid
            _entity.block!!.cbActive = tmp_cbActive
            _entity.block!!.cbHidden = tmp_cbHidden
            _entity.block!!.cbEntityUid = tmp_cbEntityUid
            _entity.block!!.cbLct = tmp_cbLct
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findEntryWithContainerByEntryId(entryUuid: Long):
      ContentEntryWithMostRecentContainer? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryWithMostRecentContainer??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            SELECT ContentEntry.*, Container.* FROM ContentEntry LEFT 
    |                JOIN Container ON Container.containerUid = (
    |                    SELECT containerUid FROM Container WHERE containerContentEntryUid =  ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1) 
    |            WHERE ContentEntry.contentEntryUid=?
    |            
    """.trimMargin() , postgreSql = """
    |
    |            SELECT ContentEntry.*, Container.* FROM ContentEntry LEFT 
    |                JOIN Container ON Container.containerUid = (
    |                    SELECT containerUid FROM Container WHERE containerContentEntryUid =  ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1) 
    |            WHERE ContentEntry.contentEntryUid=?
    |            
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, entryUuid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntryWithMostRecentContainer()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          var _container_nullFieldCount = 0
          val tmp_containerUid = _resultSet.getLong("containerUid")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_cntLct = _resultSet.getLong("cntLct")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_fileSize = _resultSet.getLong("fileSize")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_mimeType = _resultSet.getString("mimeType")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_remarks = _resultSet.getString("remarks")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          if(_resultSet.wasNull()) { _container_nullFieldCount++ }
          if(_container_nullFieldCount < 12) {
            if(_entity.container == null) {
              _entity.container = Container()
            }
            _entity.container!!.containerUid = tmp_containerUid
            _entity.container!!.cntLocalCsn = tmp_cntLocalCsn
            _entity.container!!.cntMasterCsn = tmp_cntMasterCsn
            _entity.container!!.cntLastModBy = tmp_cntLastModBy
            _entity.container!!.cntLct = tmp_cntLct
            _entity.container!!.fileSize = tmp_fileSize
            _entity.container!!.containerContentEntryUid = tmp_containerContentEntryUid
            _entity.container!!.cntLastModified = tmp_cntLastModified
            _entity.container!!.mimeType = tmp_mimeType
            _entity.container!!.remarks = tmp_remarks
            _entity.container!!.mobileOptimized = tmp_mobileOptimized
            _entity.container!!.cntNumEntries = tmp_cntNumEntries
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findEntryWithContainerByEntryIdLive(entryUuid: Long):
      DoorLiveData<ContentEntryWithMostRecentContainer?> {
    val _result = DoorLiveDataImpl<ContentEntryWithMostRecentContainer?>(_db, listOf("ContentEntry",
        "Container"))  {
      var _liveResult = null as
          com.ustadmobile.lib.db.entities.ContentEntryWithMostRecentContainer??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |            SELECT ContentEntry.*, Container.* FROM ContentEntry LEFT 
      |                JOIN Container ON Container.containerUid = (
      |                    SELECT containerUid FROM Container WHERE containerContentEntryUid =  ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1) 
      |            WHERE ContentEntry.contentEntryUid=?
      |            
      """.trimMargin() , postgreSql = """
      |
      |            SELECT ContentEntry.*, Container.* FROM ContentEntry LEFT 
      |                JOIN Container ON Container.containerUid = (
      |                    SELECT containerUid FROM Container WHERE containerContentEntryUid =  ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1) 
      |            WHERE ContentEntry.contentEntryUid=?
      |            
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, entryUuid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
            val tmp_title = _resultSet.getString("title")
            val tmp_description = _resultSet.getString("description")
            val tmp_entryId = _resultSet.getString("entryId")
            val tmp_author = _resultSet.getString("author")
            val tmp_publisher = _resultSet.getString("publisher")
            val tmp_licenseType = _resultSet.getInt("licenseType")
            val tmp_licenseName = _resultSet.getString("licenseName")
            val tmp_licenseUrl = _resultSet.getString("licenseUrl")
            val tmp_sourceUrl = _resultSet.getString("sourceUrl")
            val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
            val tmp_lastModified = _resultSet.getLong("lastModified")
            val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
            val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
            val tmp_contentFlags = _resultSet.getInt("contentFlags")
            val tmp_leaf = _resultSet.getBoolean("leaf")
            val tmp_publik = _resultSet.getBoolean("publik")
            val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
            val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
            val tmp_minScore = _resultSet.getInt("minScore")
            val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
            val tmp_contentOwner = _resultSet.getLong("contentOwner")
            val tmp_contentEntryLocalChangeSeqNum =
                _resultSet.getLong("contentEntryLocalChangeSeqNum")
            val tmp_contentEntryMasterChangeSeqNum =
                _resultSet.getLong("contentEntryMasterChangeSeqNum")
            val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
            val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
            val _entity = ContentEntryWithMostRecentContainer()
            _entity.contentEntryUid = tmp_contentEntryUid
            _entity.title = tmp_title
            _entity.description = tmp_description
            _entity.entryId = tmp_entryId
            _entity.author = tmp_author
            _entity.publisher = tmp_publisher
            _entity.licenseType = tmp_licenseType
            _entity.licenseName = tmp_licenseName
            _entity.licenseUrl = tmp_licenseUrl
            _entity.sourceUrl = tmp_sourceUrl
            _entity.thumbnailUrl = tmp_thumbnailUrl
            _entity.lastModified = tmp_lastModified
            _entity.primaryLanguageUid = tmp_primaryLanguageUid
            _entity.languageVariantUid = tmp_languageVariantUid
            _entity.contentFlags = tmp_contentFlags
            _entity.leaf = tmp_leaf
            _entity.publik = tmp_publik
            _entity.ceInactive = tmp_ceInactive
            _entity.completionCriteria = tmp_completionCriteria
            _entity.minScore = tmp_minScore
            _entity.contentTypeFlag = tmp_contentTypeFlag
            _entity.contentOwner = tmp_contentOwner
            _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
            _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
            _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
            _entity.contentEntryLct = tmp_contentEntryLct
            var _container_nullFieldCount = 0
            val tmp_containerUid = _resultSet.getLong("containerUid")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_cntLct = _resultSet.getLong("cntLct")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_fileSize = _resultSet.getLong("fileSize")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_mimeType = _resultSet.getString("mimeType")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_remarks = _resultSet.getString("remarks")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
            if(_resultSet.wasNull()) { _container_nullFieldCount++ }
            if(_container_nullFieldCount < 12) {
              if(_entity.container == null) {
                _entity.container = Container()
              }
              _entity.container!!.containerUid = tmp_containerUid
              _entity.container!!.cntLocalCsn = tmp_cntLocalCsn
              _entity.container!!.cntMasterCsn = tmp_cntMasterCsn
              _entity.container!!.cntLastModBy = tmp_cntLastModBy
              _entity.container!!.cntLct = tmp_cntLct
              _entity.container!!.fileSize = tmp_fileSize
              _entity.container!!.containerContentEntryUid = tmp_containerContentEntryUid
              _entity.container!!.cntLastModified = tmp_cntLastModified
              _entity.container!!.mimeType = tmp_mimeType
              _entity.container!!.remarks = tmp_remarks
              _entity.container!!.mobileOptimized = tmp_mobileOptimized
              _entity.container!!.cntNumEntries = tmp_cntNumEntries
            }
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun findBySourceUrl(sourceUrl: String): ContentEntry? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntry??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentEntry WHERE sourceUrl = ? LIMIT 1" ,
        postgreSql = """
    |SELECT * FROM ContentEntry WHERE sourceUrl = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, sourceUrl)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findTitleByUidAsync(contentEntryUid: Long): String? {
    var _result = null as kotlin.String??
    val _stmtConfig =
        PreparedStatementConfig("SELECT title FROM ContentEntry WHERE contentEntryUid = ?" ,
        postgreSql = """
    |SELECT title FROM ContentEntry WHERE contentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getString(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun getChildrenByParentUid(parentUid: Long): DoorDataSourceFactory<Int,
      ContentEntry> {
    val _result = object : DoorDataSourceFactory<Int, ContentEntry>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<ContentEntry>> =
          DoorLiveDataImpl<List<ContentEntry>>(_db, listOf("ContentEntry",
          "ContentEntryParentChildJoin"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntry>()
        val _stmtConfig =
            PreparedStatementConfig("SELECT * FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?) LIMIT ? OFFSET ? "
            , postgreSql = """
        |SELECT * FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, parentUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
              val tmp_title = _resultSet.getString("title")
              val tmp_description = _resultSet.getString("description")
              val tmp_entryId = _resultSet.getString("entryId")
              val tmp_author = _resultSet.getString("author")
              val tmp_publisher = _resultSet.getString("publisher")
              val tmp_licenseType = _resultSet.getInt("licenseType")
              val tmp_licenseName = _resultSet.getString("licenseName")
              val tmp_licenseUrl = _resultSet.getString("licenseUrl")
              val tmp_sourceUrl = _resultSet.getString("sourceUrl")
              val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
              val tmp_lastModified = _resultSet.getLong("lastModified")
              val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
              val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
              val tmp_contentFlags = _resultSet.getInt("contentFlags")
              val tmp_leaf = _resultSet.getBoolean("leaf")
              val tmp_publik = _resultSet.getBoolean("publik")
              val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
              val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
              val tmp_minScore = _resultSet.getInt("minScore")
              val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
              val tmp_contentOwner = _resultSet.getLong("contentOwner")
              val tmp_contentEntryLocalChangeSeqNum =
                  _resultSet.getLong("contentEntryLocalChangeSeqNum")
              val tmp_contentEntryMasterChangeSeqNum =
                  _resultSet.getLong("contentEntryMasterChangeSeqNum")
              val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
              val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
              val _entity = ContentEntry()
              _entity.contentEntryUid = tmp_contentEntryUid
              _entity.title = tmp_title
              _entity.description = tmp_description
              _entity.entryId = tmp_entryId
              _entity.author = tmp_author
              _entity.publisher = tmp_publisher
              _entity.licenseType = tmp_licenseType
              _entity.licenseName = tmp_licenseName
              _entity.licenseUrl = tmp_licenseUrl
              _entity.sourceUrl = tmp_sourceUrl
              _entity.thumbnailUrl = tmp_thumbnailUrl
              _entity.lastModified = tmp_lastModified
              _entity.primaryLanguageUid = tmp_primaryLanguageUid
              _entity.languageVariantUid = tmp_languageVariantUid
              _entity.contentFlags = tmp_contentFlags
              _entity.leaf = tmp_leaf
              _entity.publik = tmp_publik
              _entity.ceInactive = tmp_ceInactive
              _entity.completionCriteria = tmp_completionCriteria
              _entity.minScore = tmp_minScore
              _entity.contentTypeFlag = tmp_contentTypeFlag
              _entity.contentOwner = tmp_contentOwner
              _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
              _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
              _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
              _entity.contentEntryLct = tmp_contentEntryLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ContentEntry", "ContentEntryParentChildJoin"))  {
        var _liveResult = 0
        val _stmtConfig =
            PreparedStatementConfig("SELECT COUNT(*) FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?) "
            , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT ContentEntry.* FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, parentUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun getChildrenByParentAsync(parentUid: Long): List<ContentEntry> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntry>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentEntry.*
    |          FROM ContentEntryParentChildJoin
    |               JOIN ContentEntry 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
    |         WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentEntry.*
    |          FROM ContentEntryParentChildJoin
    |               JOIN ContentEntry 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
    |         WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun getContentJobItemParamsByParentUid(
    parentUid: Long,
    limit: Int,
    offset: Int
  ): List<ContentEntryContentJobItemParams> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryContentJobItemParams>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentEntry.contentEntryUid AS contentEntryUid, ContentEntry.leaf AS leaf, 
    |               COALESCE(Container.containerUid, 0) AS mostRecentContainerUid,
    |               COALESCE(Container.fileSize, 0) AS mostRecentContainerSize
    |          FROM ContentEntryParentChildJoin
    |               JOIN ContentEntry 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
    |               LEFT JOIN Container
    |                    ON containerUid = 
    |                        (SELECT COALESCE((
    |                                SELECT Container.containerUid 
    |                                  FROM Container
    |                                 WHERE Container.containerContentEntryUid = ContentEntry.contentEntryUid
    |                              ORDER BY Container.cntLastModified DESC
    |                                 LIMIT 1),0))
    |         WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |         LIMIT ?
    |        OFFSET ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentEntry.contentEntryUid AS contentEntryUid, ContentEntry.leaf AS leaf, 
    |               COALESCE(Container.containerUid, 0) AS mostRecentContainerUid,
    |               COALESCE(Container.fileSize, 0) AS mostRecentContainerSize
    |          FROM ContentEntryParentChildJoin
    |               JOIN ContentEntry 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
    |               LEFT JOIN Container
    |                    ON containerUid = 
    |                        (SELECT COALESCE((
    |                                SELECT Container.containerUid 
    |                                  FROM Container
    |                                 WHERE Container.containerContentEntryUid = ContentEntry.contentEntryUid
    |                              ORDER BY Container.cntLastModified DESC
    |                                 LIMIT 1),0))
    |         WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |         LIMIT ?
    |        OFFSET ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.setInt(2, limit)
      _stmt.setInt(3, offset)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_mostRecentContainerUid = _resultSet.getLong("mostRecentContainerUid")
          val tmp_mostRecentContainerSize = _resultSet.getLong("mostRecentContainerSize")
          val _entity = ContentEntryContentJobItemParams()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.leaf = tmp_leaf
          _entity.mostRecentContainerUid = tmp_mostRecentContainerUid
          _entity.mostRecentContainerSize = tmp_mostRecentContainerSize
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun getCountNumberOfChildrenByParentUUidAsync(parentUid: Long): Int {
    var _result = 0
    val _stmtConfig =
        PreparedStatementConfig("SELECT COUNT(*) FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?"
        , postgreSql = """
    |SELECT COUNT(*) FROM ContentEntry LEFT Join ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getInt(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun getContentByUuidAsync(parentUid: Long): ContentEntry? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntry??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentEntry where contentEntryUid = ? LIMIT 1" ,
        postgreSql = """
    |SELECT * FROM ContentEntry where contentEntryUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findAllLanguageRelatedEntriesAsync(entryUuid: Long):
      List<ContentEntry> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntry>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryRelatedEntryJoin ON ContentEntryRelatedEntryJoin.cerejRelatedEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryRelatedEntryJoin.relType = 1 AND ContentEntryRelatedEntryJoin.cerejRelatedEntryUid != ?"
        , postgreSql = """
    |SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryRelatedEntryJoin ON ContentEntryRelatedEntryJoin.cerejRelatedEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryRelatedEntryJoin.relType = 1 AND ContentEntryRelatedEntryJoin.cerejRelatedEntryUid != ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, entryUuid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findListOfCategoriesAsync(parentUid: Long):
      List<DistinctCategorySchema> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.DistinctCategorySchema>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT DISTINCT ContentCategory.contentCategoryUid, ContentCategory.name AS categoryName, ContentCategorySchema.contentCategorySchemaUid, ContentCategorySchema.schemaName FROM ContentEntry LEFT JOIN ContentEntryContentCategoryJoin ON ContentEntryContentCategoryJoin.ceccjContentEntryUid = ContentEntry.contentEntryUid LEFT JOIN ContentCategory ON ContentCategory.contentCategoryUid = ContentEntryContentCategoryJoin.ceccjContentCategoryUid LEFT JOIN ContentCategorySchema ON ContentCategorySchema.contentCategorySchemaUid = ContentCategory.ctnCatContentCategorySchemaUid LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? AND ContentCategory.contentCategoryUid != 0 ORDER BY ContentCategory.name"
        , postgreSql = """
    |SELECT DISTINCT ContentCategory.contentCategoryUid, ContentCategory.name AS categoryName, ContentCategorySchema.contentCategorySchemaUid, ContentCategorySchema.schemaName FROM ContentEntry LEFT JOIN ContentEntryContentCategoryJoin ON ContentEntryContentCategoryJoin.ceccjContentEntryUid = ContentEntry.contentEntryUid LEFT JOIN ContentCategory ON ContentCategory.contentCategoryUid = ContentEntryContentCategoryJoin.ceccjContentCategoryUid LEFT JOIN ContentCategorySchema ON ContentCategorySchema.contentCategorySchemaUid = ContentCategory.ctnCatContentCategorySchemaUid LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? AND ContentCategory.contentCategoryUid != 0 ORDER BY ContentCategory.name
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentCategoryUid = _resultSet.getLong("contentCategoryUid")
          val tmp_categoryName = _resultSet.getString("categoryName")
          val tmp_contentCategorySchemaUid = _resultSet.getLong("contentCategorySchemaUid")
          val tmp_schemaName = _resultSet.getString("schemaName")
          val _entity = DistinctCategorySchema()
          _entity.contentCategoryUid = tmp_contentCategoryUid
          _entity.categoryName = tmp_categoryName
          _entity.contentCategorySchemaUid = tmp_contentCategorySchemaUid
          _entity.schemaName = tmp_schemaName
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findUniqueLanguagesInListAsync(parentUid: Long): List<Language> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Language>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT DISTINCT Language.* from Language LEFT JOIN ContentEntry ON ContentEntry.primaryLanguageUid = Language.langUid LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? ORDER BY Language.name"
        , postgreSql = """
    |SELECT DISTINCT Language.* from Language LEFT JOIN ContentEntry ON ContentEntry.primaryLanguageUid = Language.langUid LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? ORDER BY Language.name
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          val tmp_langLct = _resultSet.getLong("langLct")
          val _entity = Language()
          _entity.langUid = tmp_langUid
          _entity.name = tmp_name
          _entity.iso_639_1_standard = tmp_iso_639_1_standard
          _entity.iso_639_2_standard = tmp_iso_639_2_standard
          _entity.iso_639_3_standard = tmp_iso_639_3_standard
          _entity.Language_Type = tmp_Language_Type
          _entity.languageActive = tmp_languageActive
          _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
          _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
          _entity.langLastChangedBy = tmp_langLastChangedBy
          _entity.langLct = tmp_langLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findUniqueLanguageWithParentUid(parentUid: Long):
      List<LangUidAndName> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.LangUidAndName>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT DISTINCT Language.langUid, Language.name AS langName from Language
    |        LEFT JOIN ContentEntry ON ContentEntry.primaryLanguageUid = Language.langUid
    |        LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
    |        WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? ORDER BY Language.name
    """.trimMargin() , postgreSql = """
    |SELECT DISTINCT Language.langUid, Language.name AS langName from Language
    |        LEFT JOIN ContentEntry ON ContentEntry.primaryLanguageUid = Language.langUid
    |        LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
    |        WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? ORDER BY Language.name
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_langName = _resultSet.getString("langName")
          val _entity = LangUidAndName()
          _entity.langUid = tmp_langUid
          _entity.langName = tmp_langName
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun update(entity: ContentEntry): Unit {
    val _sql =
        "UPDATE ContentEntry SET title = ?, description = ?, entryId = ?, author = ?, publisher = ?, licenseType = ?, licenseName = ?, licenseUrl = ?, sourceUrl = ?, thumbnailUrl = ?, lastModified = ?, primaryLanguageUid = ?, languageVariantUid = ?, contentFlags = ?, leaf = ?, publik = ?, ceInactive = ?, completionCriteria = ?, minScore = ?, contentTypeFlag = ?, contentOwner = ?, contentEntryLocalChangeSeqNum = ?, contentEntryMasterChangeSeqNum = ?, contentEntryLastChangedBy = ?, contentEntryLct = ? WHERE contentEntryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.title)
      _stmt.setString(2, entity.description)
      _stmt.setString(3, entity.entryId)
      _stmt.setString(4, entity.author)
      _stmt.setString(5, entity.publisher)
      _stmt.setInt(6, entity.licenseType)
      _stmt.setString(7, entity.licenseName)
      _stmt.setString(8, entity.licenseUrl)
      _stmt.setString(9, entity.sourceUrl)
      _stmt.setString(10, entity.thumbnailUrl)
      _stmt.setLong(11, entity.lastModified)
      _stmt.setLong(12, entity.primaryLanguageUid)
      _stmt.setLong(13, entity.languageVariantUid)
      _stmt.setInt(14, entity.contentFlags)
      _stmt.setBoolean(15, entity.leaf)
      _stmt.setBoolean(16, entity.publik)
      _stmt.setBoolean(17, entity.ceInactive)
      _stmt.setInt(18, entity.completionCriteria)
      _stmt.setInt(19, entity.minScore)
      _stmt.setInt(20, entity.contentTypeFlag)
      _stmt.setLong(21, entity.contentOwner)
      _stmt.setLong(22, entity.contentEntryLocalChangeSeqNum)
      _stmt.setLong(23, entity.contentEntryMasterChangeSeqNum)
      _stmt.setInt(24, entity.contentEntryLastChangedBy)
      _stmt.setLong(25, entity.contentEntryLct)
      _stmt.setLong(26, entity.contentEntryUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun findByUidAsync(entryUid: Long): ContentEntry? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntry??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM ContentEntry WHERE contentEntryUid = ?"
        , postgreSql = """
    |SELECT * FROM ContentEntry WHERE contentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, entryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidWithLanguageAsync(uid: Long): ContentEntryWithLanguage? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryWithLanguage??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentEntry.*, Language.*
    |          FROM ContentEntry
    |               LEFT JOIN Language 
    |                      ON Language.langUid = ContentEntry.primaryLanguageUid 
    |         WHERE ContentEntry.contentEntryUid = ?              
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentEntry.*, Language.*
    |          FROM ContentEntry
    |               LEFT JOIN Language 
    |                      ON Language.langUid = ContentEntry.primaryLanguageUid 
    |         WHERE ContentEntry.contentEntryUid = ?              
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntryWithLanguage()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          var _language_nullFieldCount = 0
          val tmp_langUid = _resultSet.getLong("langUid")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_name = _resultSet.getString("name")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLct = _resultSet.getLong("langLct")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          if(_language_nullFieldCount < 11) {
            if(_entity.language == null) {
              _entity.language = Language()
            }
            _entity.language!!.langUid = tmp_langUid
            _entity.language!!.name = tmp_name
            _entity.language!!.iso_639_1_standard = tmp_iso_639_1_standard
            _entity.language!!.iso_639_2_standard = tmp_iso_639_2_standard
            _entity.language!!.iso_639_3_standard = tmp_iso_639_3_standard
            _entity.language!!.Language_Type = tmp_Language_Type
            _entity.language!!.languageActive = tmp_languageActive
            _entity.language!!.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
            _entity.language!!.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
            _entity.language!!.langLastChangedBy = tmp_langLastChangedBy
            _entity.language!!.langLct = tmp_langLct
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUid(entryUid: Long): ContentEntry? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntry??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM ContentEntry WHERE contentEntryUid = ?"
        , postgreSql = """
    |SELECT * FROM ContentEntry WHERE contentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, entryUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByTitle(title: String): DoorLiveData<ContentEntry?> {
    val _result = DoorLiveDataImpl<ContentEntry?>(_db, listOf("ContentEntry"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ContentEntry??
      val _stmtConfig = PreparedStatementConfig("SELECT * FROM ContentEntry WHERE title = ?" ,
          postgreSql = """
      |SELECT * FROM ContentEntry WHERE title = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setString(1, title)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
            val tmp_title = _resultSet.getString("title")
            val tmp_description = _resultSet.getString("description")
            val tmp_entryId = _resultSet.getString("entryId")
            val tmp_author = _resultSet.getString("author")
            val tmp_publisher = _resultSet.getString("publisher")
            val tmp_licenseType = _resultSet.getInt("licenseType")
            val tmp_licenseName = _resultSet.getString("licenseName")
            val tmp_licenseUrl = _resultSet.getString("licenseUrl")
            val tmp_sourceUrl = _resultSet.getString("sourceUrl")
            val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
            val tmp_lastModified = _resultSet.getLong("lastModified")
            val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
            val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
            val tmp_contentFlags = _resultSet.getInt("contentFlags")
            val tmp_leaf = _resultSet.getBoolean("leaf")
            val tmp_publik = _resultSet.getBoolean("publik")
            val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
            val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
            val tmp_minScore = _resultSet.getInt("minScore")
            val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
            val tmp_contentOwner = _resultSet.getLong("contentOwner")
            val tmp_contentEntryLocalChangeSeqNum =
                _resultSet.getLong("contentEntryLocalChangeSeqNum")
            val tmp_contentEntryMasterChangeSeqNum =
                _resultSet.getLong("contentEntryMasterChangeSeqNum")
            val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
            val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
            val _entity = ContentEntry()
            _entity.contentEntryUid = tmp_contentEntryUid
            _entity.title = tmp_title
            _entity.description = tmp_description
            _entity.entryId = tmp_entryId
            _entity.author = tmp_author
            _entity.publisher = tmp_publisher
            _entity.licenseType = tmp_licenseType
            _entity.licenseName = tmp_licenseName
            _entity.licenseUrl = tmp_licenseUrl
            _entity.sourceUrl = tmp_sourceUrl
            _entity.thumbnailUrl = tmp_thumbnailUrl
            _entity.lastModified = tmp_lastModified
            _entity.primaryLanguageUid = tmp_primaryLanguageUid
            _entity.languageVariantUid = tmp_languageVariantUid
            _entity.contentFlags = tmp_contentFlags
            _entity.leaf = tmp_leaf
            _entity.publik = tmp_publik
            _entity.ceInactive = tmp_ceInactive
            _entity.completionCriteria = tmp_completionCriteria
            _entity.minScore = tmp_minScore
            _entity.contentTypeFlag = tmp_contentTypeFlag
            _entity.contentOwner = tmp_contentOwner
            _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
            _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
            _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
            _entity.contentEntryLct = tmp_contentEntryLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun isMeteredAllowedForEntry(contentEntryUid: Long): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |       SELECT COALESCE((SELECT CAST(cjIsMeteredAllowed AS INTEGER) 
    |                FROM ContentJobItem 
    |                JOIN ContentJob
    |                    ON ContentJobItem.cjiJobUid = ContentJob.cjUid
    |               WHERE cjiContentEntryUid = ?
    |                AND cjiRecursiveStatus >= 4
    |                AND cjiRecursiveStatus <= 20 LIMIT 1),
    |                CAST(((SELECT connectivityState
    |                        FROM ConnectivityStatus
    |                       LIMIT 1) = 3) AS INTEGER),
    |                0) AS Status
    |    
    """.trimMargin() , postgreSql = """
    |
    |       SELECT COALESCE((SELECT CAST(cjIsMeteredAllowed AS INTEGER) 
    |                FROM ContentJobItem 
    |                JOIN ContentJob
    |                    ON ContentJobItem.cjiJobUid = ContentJob.cjUid
    |               WHERE cjiContentEntryUid = ?
    |                AND cjiRecursiveStatus >= 4
    |                AND cjiRecursiveStatus <= 20 LIMIT 1),
    |                CAST(((SELECT connectivityState
    |                        FROM ConnectivityStatus
    |                       LIMIT 1) = 3) AS INTEGER),
    |                0) AS Status
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findBySourceUrlWithContentEntryStatusAsync(sourceUrl: String):
      ContentEntry? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntry??
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntry.* FROM ContentEntry WHERE ContentEntry.sourceUrl = ?"
        , postgreSql = """
    |SELECT ContentEntry.* FROM ContentEntry WHERE ContentEntry.sourceUrl = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, sourceUrl)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun getChildrenByParentUidWithCategoryFilterOrderByName(
    parentUid: Long,
    langParam: Long,
    categoryParam0: Long,
    personUid: Long,
    showHidden: Boolean,
    onlyFolder: Boolean,
    sortOrder: Int
  ): DoorDataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = object : DoorDataSourceFactory<Int,
        ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>> =
          DoorLiveDataImpl<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>>(_db,
          listOf("ContentEntry", "ContentEntryParentChildJoin", "StatementEntity", "Container",
          "ContentEntryContentCategoryJoin"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |            FROM ContentEntry 
        |                    LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
        |                    
        |                    LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
        |                    
        |                    LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)
        |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? 
        |            AND (? = 0 OR ContentEntry.primaryLanguageUid = ?) 
        |            AND (NOT ContentEntry.ceInactive OR ContentEntry.ceInactive = ?) 
        |            AND (NOT ContentEntry.leaf OR NOT ContentEntry.leaf = ?) 
        |            AND (ContentEntry.publik 
        |                 OR (SELECT username
        |                        FROM Person
        |                       WHERE personUid = ?) IS NOT NULL) 
        |            AND 
        |            (? = 0 OR ? 
        |                IN (SELECT ceccjContentCategoryUid 
        |                      FROM ContentEntryContentCategoryJoin 
        |                     WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
        |            ORDER BY ContentEntryParentChildJoin.childIndex,
        |                     CASE(?)
        |                     WHEN 1 THEN ContentEntry.title
        |                     ELSE ''
        |                     END ASC,
        |                     CASE(?)
        |                     WHEN 2 THEN ContentEntry.title
        |                     ELSE ''
        |                     END DESC,             
        |                     ContentEntry.contentEntryUid) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |            FROM ContentEntry 
        |                    LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
        |                    
        |                    LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
        |                    
        |                    LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)
        |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? 
        |            AND (? = 0 OR ContentEntry.primaryLanguageUid = ?) 
        |            AND (NOT ContentEntry.ceInactive OR ContentEntry.ceInactive = ?) 
        |            AND (NOT ContentEntry.leaf OR NOT ContentEntry.leaf = ?) 
        |            AND (ContentEntry.publik 
        |                 OR (SELECT username
        |                        FROM Person
        |                       WHERE personUid = ?) IS NOT NULL) 
        |            AND 
        |            (? = 0 OR ? 
        |                IN (SELECT ceccjContentCategoryUid 
        |                      FROM ContentEntryContentCategoryJoin 
        |                     WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
        |            ORDER BY ContentEntryParentChildJoin.childIndex,
        |                     CASE(?)
        |                     WHEN 1 THEN ContentEntry.title
        |                     ELSE ''
        |                     END ASC,
        |                     CASE(?)
        |                     WHEN 2 THEN ContentEntry.title
        |                     ELSE ''
        |                     END DESC,             
        |                     ContentEntry.contentEntryUid) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, parentUid)
          _stmt.setLong(3, langParam)
          _stmt.setLong(4, langParam)
          _stmt.setBoolean(5, showHidden)
          _stmt.setBoolean(6, onlyFolder)
          _stmt.setLong(7, personUid)
          _stmt.setLong(8, categoryParam0)
          _stmt.setLong(9, categoryParam0)
          _stmt.setInt(10, sortOrder)
          _stmt.setInt(11, sortOrder)
          _stmt.setInt(12, _limit)
          _stmt.setInt(13, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_assignmentContentWeight = _resultSet.getInt("assignmentContentWeight")
              val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
              val tmp_title = _resultSet.getString("title")
              val tmp_description = _resultSet.getString("description")
              val tmp_entryId = _resultSet.getString("entryId")
              val tmp_author = _resultSet.getString("author")
              val tmp_publisher = _resultSet.getString("publisher")
              val tmp_licenseType = _resultSet.getInt("licenseType")
              val tmp_licenseName = _resultSet.getString("licenseName")
              val tmp_licenseUrl = _resultSet.getString("licenseUrl")
              val tmp_sourceUrl = _resultSet.getString("sourceUrl")
              val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
              val tmp_lastModified = _resultSet.getLong("lastModified")
              val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
              val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
              val tmp_contentFlags = _resultSet.getInt("contentFlags")
              val tmp_leaf = _resultSet.getBoolean("leaf")
              val tmp_publik = _resultSet.getBoolean("publik")
              val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
              val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
              val tmp_minScore = _resultSet.getInt("minScore")
              val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
              val tmp_contentOwner = _resultSet.getLong("contentOwner")
              val tmp_contentEntryLocalChangeSeqNum =
                  _resultSet.getLong("contentEntryLocalChangeSeqNum")
              val tmp_contentEntryMasterChangeSeqNum =
                  _resultSet.getLong("contentEntryMasterChangeSeqNum")
              val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
              val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
              val _entity = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
              _entity.assignmentContentWeight = tmp_assignmentContentWeight
              _entity.contentEntryUid = tmp_contentEntryUid
              _entity.title = tmp_title
              _entity.description = tmp_description
              _entity.entryId = tmp_entryId
              _entity.author = tmp_author
              _entity.publisher = tmp_publisher
              _entity.licenseType = tmp_licenseType
              _entity.licenseName = tmp_licenseName
              _entity.licenseUrl = tmp_licenseUrl
              _entity.sourceUrl = tmp_sourceUrl
              _entity.thumbnailUrl = tmp_thumbnailUrl
              _entity.lastModified = tmp_lastModified
              _entity.primaryLanguageUid = tmp_primaryLanguageUid
              _entity.languageVariantUid = tmp_languageVariantUid
              _entity.contentFlags = tmp_contentFlags
              _entity.leaf = tmp_leaf
              _entity.publik = tmp_publik
              _entity.ceInactive = tmp_ceInactive
              _entity.completionCriteria = tmp_completionCriteria
              _entity.minScore = tmp_minScore
              _entity.contentTypeFlag = tmp_contentTypeFlag
              _entity.contentOwner = tmp_contentOwner
              _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
              _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
              _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
              _entity.contentEntryLct = tmp_contentEntryLct
              var _mostRecentContainer_nullFieldCount = 0
              val tmp_containerUid = _resultSet.getLong("containerUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLct = _resultSet.getLong("cntLct")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_fileSize = _resultSet.getLong("fileSize")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mimeType = _resultSet.getString("mimeType")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_remarks = _resultSet.getString("remarks")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              if(_mostRecentContainer_nullFieldCount < 12) {
                if(_entity.mostRecentContainer == null) {
                  _entity.mostRecentContainer = Container()
                }
                _entity.mostRecentContainer!!.containerUid = tmp_containerUid
                _entity.mostRecentContainer!!.cntLocalCsn = tmp_cntLocalCsn
                _entity.mostRecentContainer!!.cntMasterCsn = tmp_cntMasterCsn
                _entity.mostRecentContainer!!.cntLastModBy = tmp_cntLastModBy
                _entity.mostRecentContainer!!.cntLct = tmp_cntLct
                _entity.mostRecentContainer!!.fileSize = tmp_fileSize
                _entity.mostRecentContainer!!.containerContentEntryUid =
                    tmp_containerContentEntryUid
                _entity.mostRecentContainer!!.cntLastModified = tmp_cntLastModified
                _entity.mostRecentContainer!!.mimeType = tmp_mimeType
                _entity.mostRecentContainer!!.remarks = tmp_remarks
                _entity.mostRecentContainer!!.mobileOptimized = tmp_mobileOptimized
                _entity.mostRecentContainer!!.cntNumEntries = tmp_cntNumEntries
              }
              var _contentEntryParentChildJoin_nullFieldCount = 0
              val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_childIndex = _resultSet.getInt("childIndex")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              if(_contentEntryParentChildJoin_nullFieldCount < 8) {
                if(_entity.contentEntryParentChildJoin == null) {
                  _entity.contentEntryParentChildJoin = ContentEntryParentChildJoin()
                }
                _entity.contentEntryParentChildJoin!!.cepcjParentContentEntryUid =
                    tmp_cepcjParentContentEntryUid
                _entity.contentEntryParentChildJoin!!.cepcjChildContentEntryUid =
                    tmp_cepcjChildContentEntryUid
                _entity.contentEntryParentChildJoin!!.childIndex = tmp_childIndex
                _entity.contentEntryParentChildJoin!!.cepcjUid = tmp_cepcjUid
                _entity.contentEntryParentChildJoin!!.cepcjLocalChangeSeqNum =
                    tmp_cepcjLocalChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjMasterChangeSeqNum =
                    tmp_cepcjMasterChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjLastChangedBy = tmp_cepcjLastChangedBy
                _entity.contentEntryParentChildJoin!!.cepcjLct = tmp_cepcjLct
              }
              var _scoreProgress_nullFieldCount = 0
              val tmp_resultScore = _resultSet.getInt("resultScore")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultMax = _resultSet.getInt("resultMax")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultScaled = _resultSet.getFloat("resultScaled")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultWeight = _resultSet.getInt("resultWeight")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_progress = _resultSet.getInt("progress")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_success = _resultSet.getByte("success")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_penalty = _resultSet.getInt("penalty")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalContent = _resultSet.getInt("totalContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              if(_scoreProgress_nullFieldCount < 10) {
                if(_entity.scoreProgress == null) {
                  _entity.scoreProgress = ContentEntryStatementScoreProgress()
                }
                _entity.scoreProgress!!.resultScore = tmp_resultScore
                _entity.scoreProgress!!.resultMax = tmp_resultMax
                _entity.scoreProgress!!.resultScaled = tmp_resultScaled
                _entity.scoreProgress!!.resultWeight = tmp_resultWeight
                _entity.scoreProgress!!.contentComplete = tmp_contentComplete
                _entity.scoreProgress!!.progress = tmp_progress
                _entity.scoreProgress!!.success = tmp_success
                _entity.scoreProgress!!.penalty = tmp_penalty
                _entity.scoreProgress!!.totalContent = tmp_totalContent
                _entity.scoreProgress!!.totalCompletedContent = tmp_totalCompletedContent
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ContentEntry", "ContentEntryParentChildJoin", "StatementEntity", "Container",
          "ContentEntryContentCategoryJoin"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |            FROM ContentEntry 
        |                    LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
        |                    
        |                    LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
        |                    
        |                    LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)
        |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? 
        |            AND (? = 0 OR ContentEntry.primaryLanguageUid = ?) 
        |            AND (NOT ContentEntry.ceInactive OR ContentEntry.ceInactive = ?) 
        |            AND (NOT ContentEntry.leaf OR NOT ContentEntry.leaf = ?) 
        |            AND (ContentEntry.publik 
        |                 OR (SELECT username
        |                        FROM Person
        |                       WHERE personUid = ?) IS NOT NULL) 
        |            AND 
        |            (? = 0 OR ? 
        |                IN (SELECT ceccjContentCategoryUid 
        |                      FROM ContentEntryContentCategoryJoin 
        |                     WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
        |            ORDER BY ContentEntryParentChildJoin.childIndex,
        |                     CASE(?)
        |                     WHEN 1 THEN ContentEntry.title
        |                     ELSE ''
        |                     END ASC,
        |                     CASE(?)
        |                     WHEN 2 THEN ContentEntry.title
        |                     ELSE ''
        |                     END DESC,             
        |                     ContentEntry.contentEntryUid) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |            SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |            FROM ContentEntry 
        |                    LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
        |                    
        |                    LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)
        |                    
        |                    LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)
        |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ? 
        |            AND (? = 0 OR ContentEntry.primaryLanguageUid = ?) 
        |            AND (NOT ContentEntry.ceInactive OR ContentEntry.ceInactive = ?) 
        |            AND (NOT ContentEntry.leaf OR NOT ContentEntry.leaf = ?) 
        |            AND (ContentEntry.publik 
        |                 OR (SELECT username
        |                        FROM Person
        |                       WHERE personUid = ?) IS NOT NULL) 
        |            AND 
        |            (? = 0 OR ? 
        |                IN (SELECT ceccjContentCategoryUid 
        |                      FROM ContentEntryContentCategoryJoin 
        |                     WHERE ceccjContentEntryUid = ContentEntry.contentEntryUid)) 
        |            ORDER BY ContentEntryParentChildJoin.childIndex,
        |                     CASE(?)
        |                     WHEN 1 THEN ContentEntry.title
        |                     ELSE ''
        |                     END ASC,
        |                     CASE(?)
        |                     WHEN 2 THEN ContentEntry.title
        |                     ELSE ''
        |                     END DESC,             
        |                     ContentEntry.contentEntryUid) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, parentUid)
          _stmt.setLong(3, langParam)
          _stmt.setLong(4, langParam)
          _stmt.setBoolean(5, showHidden)
          _stmt.setBoolean(6, onlyFolder)
          _stmt.setLong(7, personUid)
          _stmt.setLong(8, categoryParam0)
          _stmt.setLong(9, categoryParam0)
          _stmt.setInt(10, sortOrder)
          _stmt.setInt(11, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun getContentFromMyCourses(personUid: Long): DoorDataSourceFactory<Int,
      ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = object : DoorDataSourceFactory<Int,
        ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>> =
          DoorLiveDataImpl<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>>(_db,
          listOf("CourseBlock", "ContentEntry", "ContentEntryParentChildJoin", "StatementEntity",
          "Container", "ClazzEnrolment"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |          FROM CourseBlock
        |               JOIN ContentEntry 
        |                    ON CourseBlock.cbType = 104
        |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
        |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
        |               LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
        |               LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)  
        |                               
        |         WHERE CourseBlock.cbClazzUid IN
        |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
        |                  FROM ClazzEnrolment
        |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?)
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |          FROM CourseBlock
        |               JOIN ContentEntry 
        |                    ON CourseBlock.cbType = 104
        |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
        |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
        |               LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
        |               LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)  
        |                               
        |         WHERE CourseBlock.cbClazzUid IN
        |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
        |                  FROM ClazzEnrolment
        |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?)
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, personUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_assignmentContentWeight = _resultSet.getInt("assignmentContentWeight")
              val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
              val tmp_title = _resultSet.getString("title")
              val tmp_description = _resultSet.getString("description")
              val tmp_entryId = _resultSet.getString("entryId")
              val tmp_author = _resultSet.getString("author")
              val tmp_publisher = _resultSet.getString("publisher")
              val tmp_licenseType = _resultSet.getInt("licenseType")
              val tmp_licenseName = _resultSet.getString("licenseName")
              val tmp_licenseUrl = _resultSet.getString("licenseUrl")
              val tmp_sourceUrl = _resultSet.getString("sourceUrl")
              val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
              val tmp_lastModified = _resultSet.getLong("lastModified")
              val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
              val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
              val tmp_contentFlags = _resultSet.getInt("contentFlags")
              val tmp_leaf = _resultSet.getBoolean("leaf")
              val tmp_publik = _resultSet.getBoolean("publik")
              val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
              val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
              val tmp_minScore = _resultSet.getInt("minScore")
              val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
              val tmp_contentOwner = _resultSet.getLong("contentOwner")
              val tmp_contentEntryLocalChangeSeqNum =
                  _resultSet.getLong("contentEntryLocalChangeSeqNum")
              val tmp_contentEntryMasterChangeSeqNum =
                  _resultSet.getLong("contentEntryMasterChangeSeqNum")
              val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
              val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
              val _entity = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
              _entity.assignmentContentWeight = tmp_assignmentContentWeight
              _entity.contentEntryUid = tmp_contentEntryUid
              _entity.title = tmp_title
              _entity.description = tmp_description
              _entity.entryId = tmp_entryId
              _entity.author = tmp_author
              _entity.publisher = tmp_publisher
              _entity.licenseType = tmp_licenseType
              _entity.licenseName = tmp_licenseName
              _entity.licenseUrl = tmp_licenseUrl
              _entity.sourceUrl = tmp_sourceUrl
              _entity.thumbnailUrl = tmp_thumbnailUrl
              _entity.lastModified = tmp_lastModified
              _entity.primaryLanguageUid = tmp_primaryLanguageUid
              _entity.languageVariantUid = tmp_languageVariantUid
              _entity.contentFlags = tmp_contentFlags
              _entity.leaf = tmp_leaf
              _entity.publik = tmp_publik
              _entity.ceInactive = tmp_ceInactive
              _entity.completionCriteria = tmp_completionCriteria
              _entity.minScore = tmp_minScore
              _entity.contentTypeFlag = tmp_contentTypeFlag
              _entity.contentOwner = tmp_contentOwner
              _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
              _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
              _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
              _entity.contentEntryLct = tmp_contentEntryLct
              var _mostRecentContainer_nullFieldCount = 0
              val tmp_containerUid = _resultSet.getLong("containerUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLct = _resultSet.getLong("cntLct")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_fileSize = _resultSet.getLong("fileSize")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mimeType = _resultSet.getString("mimeType")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_remarks = _resultSet.getString("remarks")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              if(_mostRecentContainer_nullFieldCount < 12) {
                if(_entity.mostRecentContainer == null) {
                  _entity.mostRecentContainer = Container()
                }
                _entity.mostRecentContainer!!.containerUid = tmp_containerUid
                _entity.mostRecentContainer!!.cntLocalCsn = tmp_cntLocalCsn
                _entity.mostRecentContainer!!.cntMasterCsn = tmp_cntMasterCsn
                _entity.mostRecentContainer!!.cntLastModBy = tmp_cntLastModBy
                _entity.mostRecentContainer!!.cntLct = tmp_cntLct
                _entity.mostRecentContainer!!.fileSize = tmp_fileSize
                _entity.mostRecentContainer!!.containerContentEntryUid =
                    tmp_containerContentEntryUid
                _entity.mostRecentContainer!!.cntLastModified = tmp_cntLastModified
                _entity.mostRecentContainer!!.mimeType = tmp_mimeType
                _entity.mostRecentContainer!!.remarks = tmp_remarks
                _entity.mostRecentContainer!!.mobileOptimized = tmp_mobileOptimized
                _entity.mostRecentContainer!!.cntNumEntries = tmp_cntNumEntries
              }
              var _contentEntryParentChildJoin_nullFieldCount = 0
              val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_childIndex = _resultSet.getInt("childIndex")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              if(_contentEntryParentChildJoin_nullFieldCount < 8) {
                if(_entity.contentEntryParentChildJoin == null) {
                  _entity.contentEntryParentChildJoin = ContentEntryParentChildJoin()
                }
                _entity.contentEntryParentChildJoin!!.cepcjParentContentEntryUid =
                    tmp_cepcjParentContentEntryUid
                _entity.contentEntryParentChildJoin!!.cepcjChildContentEntryUid =
                    tmp_cepcjChildContentEntryUid
                _entity.contentEntryParentChildJoin!!.childIndex = tmp_childIndex
                _entity.contentEntryParentChildJoin!!.cepcjUid = tmp_cepcjUid
                _entity.contentEntryParentChildJoin!!.cepcjLocalChangeSeqNum =
                    tmp_cepcjLocalChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjMasterChangeSeqNum =
                    tmp_cepcjMasterChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjLastChangedBy = tmp_cepcjLastChangedBy
                _entity.contentEntryParentChildJoin!!.cepcjLct = tmp_cepcjLct
              }
              var _scoreProgress_nullFieldCount = 0
              val tmp_resultScore = _resultSet.getInt("resultScore")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultMax = _resultSet.getInt("resultMax")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultScaled = _resultSet.getFloat("resultScaled")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultWeight = _resultSet.getInt("resultWeight")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_progress = _resultSet.getInt("progress")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_success = _resultSet.getByte("success")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_penalty = _resultSet.getInt("penalty")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalContent = _resultSet.getInt("totalContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              if(_scoreProgress_nullFieldCount < 10) {
                if(_entity.scoreProgress == null) {
                  _entity.scoreProgress = ContentEntryStatementScoreProgress()
                }
                _entity.scoreProgress!!.resultScore = tmp_resultScore
                _entity.scoreProgress!!.resultMax = tmp_resultMax
                _entity.scoreProgress!!.resultScaled = tmp_resultScaled
                _entity.scoreProgress!!.resultWeight = tmp_resultWeight
                _entity.scoreProgress!!.contentComplete = tmp_contentComplete
                _entity.scoreProgress!!.progress = tmp_progress
                _entity.scoreProgress!!.success = tmp_success
                _entity.scoreProgress!!.penalty = tmp_penalty
                _entity.scoreProgress!!.totalContent = tmp_totalContent
                _entity.scoreProgress!!.totalCompletedContent = tmp_totalCompletedContent
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("CourseBlock", "ContentEntry", "ContentEntryParentChildJoin", "StatementEntity",
          "Container", "ClazzEnrolment"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |          FROM CourseBlock
        |               JOIN ContentEntry 
        |                    ON CourseBlock.cbType = 104
        |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
        |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
        |               LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
        |               LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)  
        |                               
        |         WHERE CourseBlock.cbClazzUid IN
        |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
        |                  FROM ClazzEnrolment
        |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?)
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |          FROM CourseBlock
        |               JOIN ContentEntry 
        |                    ON CourseBlock.cbType = 104
        |                       AND ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
        |                       AND CAST(CourseBlock.cbActive AS INTEGER) = 1
        |               LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
        |               LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)  
        |                               
        |         WHERE CourseBlock.cbClazzUid IN
        |               (SELECT ClazzEnrolment.clazzEnrolmentClazzUid
        |                  FROM ClazzEnrolment
        |                 WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?)
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, personUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun getContentByOwner(personUid: Long): DoorDataSourceFactory<Int,
      ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = object : DoorDataSourceFactory<Int,
        ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>> =
          DoorLiveDataImpl<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>>(_db,
          listOf("ContentEntry", "ContentEntryParentChildJoin", "StatementEntity", "Container"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |          FROM ContentEntry
        |               LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
        |               LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)  
        |         WHERE ContentEntry.contentOwner = ?
        |           AND NOT EXISTS(
        |               SELECT ContentEntryParentChildJoin.cepcjUid 
        |                 FROM ContentEntryParentChildJoin
        |                WHERE ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid)
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |          FROM ContentEntry
        |               LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
        |               LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)  
        |         WHERE ContentEntry.contentOwner = ?
        |           AND NOT EXISTS(
        |               SELECT ContentEntryParentChildJoin.cepcjUid 
        |                 FROM ContentEntryParentChildJoin
        |                WHERE ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid)
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, personUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_assignmentContentWeight = _resultSet.getInt("assignmentContentWeight")
              val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
              val tmp_title = _resultSet.getString("title")
              val tmp_description = _resultSet.getString("description")
              val tmp_entryId = _resultSet.getString("entryId")
              val tmp_author = _resultSet.getString("author")
              val tmp_publisher = _resultSet.getString("publisher")
              val tmp_licenseType = _resultSet.getInt("licenseType")
              val tmp_licenseName = _resultSet.getString("licenseName")
              val tmp_licenseUrl = _resultSet.getString("licenseUrl")
              val tmp_sourceUrl = _resultSet.getString("sourceUrl")
              val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
              val tmp_lastModified = _resultSet.getLong("lastModified")
              val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
              val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
              val tmp_contentFlags = _resultSet.getInt("contentFlags")
              val tmp_leaf = _resultSet.getBoolean("leaf")
              val tmp_publik = _resultSet.getBoolean("publik")
              val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
              val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
              val tmp_minScore = _resultSet.getInt("minScore")
              val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
              val tmp_contentOwner = _resultSet.getLong("contentOwner")
              val tmp_contentEntryLocalChangeSeqNum =
                  _resultSet.getLong("contentEntryLocalChangeSeqNum")
              val tmp_contentEntryMasterChangeSeqNum =
                  _resultSet.getLong("contentEntryMasterChangeSeqNum")
              val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
              val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
              val _entity = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
              _entity.assignmentContentWeight = tmp_assignmentContentWeight
              _entity.contentEntryUid = tmp_contentEntryUid
              _entity.title = tmp_title
              _entity.description = tmp_description
              _entity.entryId = tmp_entryId
              _entity.author = tmp_author
              _entity.publisher = tmp_publisher
              _entity.licenseType = tmp_licenseType
              _entity.licenseName = tmp_licenseName
              _entity.licenseUrl = tmp_licenseUrl
              _entity.sourceUrl = tmp_sourceUrl
              _entity.thumbnailUrl = tmp_thumbnailUrl
              _entity.lastModified = tmp_lastModified
              _entity.primaryLanguageUid = tmp_primaryLanguageUid
              _entity.languageVariantUid = tmp_languageVariantUid
              _entity.contentFlags = tmp_contentFlags
              _entity.leaf = tmp_leaf
              _entity.publik = tmp_publik
              _entity.ceInactive = tmp_ceInactive
              _entity.completionCriteria = tmp_completionCriteria
              _entity.minScore = tmp_minScore
              _entity.contentTypeFlag = tmp_contentTypeFlag
              _entity.contentOwner = tmp_contentOwner
              _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
              _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
              _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
              _entity.contentEntryLct = tmp_contentEntryLct
              var _mostRecentContainer_nullFieldCount = 0
              val tmp_containerUid = _resultSet.getLong("containerUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLct = _resultSet.getLong("cntLct")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_fileSize = _resultSet.getLong("fileSize")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mimeType = _resultSet.getString("mimeType")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_remarks = _resultSet.getString("remarks")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              if(_mostRecentContainer_nullFieldCount < 12) {
                if(_entity.mostRecentContainer == null) {
                  _entity.mostRecentContainer = Container()
                }
                _entity.mostRecentContainer!!.containerUid = tmp_containerUid
                _entity.mostRecentContainer!!.cntLocalCsn = tmp_cntLocalCsn
                _entity.mostRecentContainer!!.cntMasterCsn = tmp_cntMasterCsn
                _entity.mostRecentContainer!!.cntLastModBy = tmp_cntLastModBy
                _entity.mostRecentContainer!!.cntLct = tmp_cntLct
                _entity.mostRecentContainer!!.fileSize = tmp_fileSize
                _entity.mostRecentContainer!!.containerContentEntryUid =
                    tmp_containerContentEntryUid
                _entity.mostRecentContainer!!.cntLastModified = tmp_cntLastModified
                _entity.mostRecentContainer!!.mimeType = tmp_mimeType
                _entity.mostRecentContainer!!.remarks = tmp_remarks
                _entity.mostRecentContainer!!.mobileOptimized = tmp_mobileOptimized
                _entity.mostRecentContainer!!.cntNumEntries = tmp_cntNumEntries
              }
              var _contentEntryParentChildJoin_nullFieldCount = 0
              val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_childIndex = _resultSet.getInt("childIndex")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              if(_contentEntryParentChildJoin_nullFieldCount < 8) {
                if(_entity.contentEntryParentChildJoin == null) {
                  _entity.contentEntryParentChildJoin = ContentEntryParentChildJoin()
                }
                _entity.contentEntryParentChildJoin!!.cepcjParentContentEntryUid =
                    tmp_cepcjParentContentEntryUid
                _entity.contentEntryParentChildJoin!!.cepcjChildContentEntryUid =
                    tmp_cepcjChildContentEntryUid
                _entity.contentEntryParentChildJoin!!.childIndex = tmp_childIndex
                _entity.contentEntryParentChildJoin!!.cepcjUid = tmp_cepcjUid
                _entity.contentEntryParentChildJoin!!.cepcjLocalChangeSeqNum =
                    tmp_cepcjLocalChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjMasterChangeSeqNum =
                    tmp_cepcjMasterChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjLastChangedBy = tmp_cepcjLastChangedBy
                _entity.contentEntryParentChildJoin!!.cepcjLct = tmp_cepcjLct
              }
              var _scoreProgress_nullFieldCount = 0
              val tmp_resultScore = _resultSet.getInt("resultScore")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultMax = _resultSet.getInt("resultMax")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultScaled = _resultSet.getFloat("resultScaled")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultWeight = _resultSet.getInt("resultWeight")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_progress = _resultSet.getInt("progress")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_success = _resultSet.getByte("success")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_penalty = _resultSet.getInt("penalty")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalContent = _resultSet.getInt("totalContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              if(_scoreProgress_nullFieldCount < 10) {
                if(_entity.scoreProgress == null) {
                  _entity.scoreProgress = ContentEntryStatementScoreProgress()
                }
                _entity.scoreProgress!!.resultScore = tmp_resultScore
                _entity.scoreProgress!!.resultMax = tmp_resultMax
                _entity.scoreProgress!!.resultScaled = tmp_resultScaled
                _entity.scoreProgress!!.resultWeight = tmp_resultWeight
                _entity.scoreProgress!!.contentComplete = tmp_contentComplete
                _entity.scoreProgress!!.progress = tmp_progress
                _entity.scoreProgress!!.success = tmp_success
                _entity.scoreProgress!!.penalty = tmp_penalty
                _entity.scoreProgress!!.totalContent = tmp_totalContent
                _entity.scoreProgress!!.totalCompletedContent = tmp_totalCompletedContent
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ContentEntry", "ContentEntryParentChildJoin", "StatementEntity", "Container"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |          FROM ContentEntry
        |               LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
        |               LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)  
        |         WHERE ContentEntry.contentOwner = ?
        |           AND NOT EXISTS(
        |               SELECT ContentEntryParentChildJoin.cepcjUid 
        |                 FROM ContentEntryParentChildJoin
        |                WHERE ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid)
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT ContentEntry.*, ContentEntryParentChildJoin.*, Container.*, 
        |                COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                0 AS assignmentContentWeight,
        |                
        |                1 as totalContent, 
        |                
        |                0 as penalty
        |          FROM ContentEntry
        |               LEFT JOIN ContentEntryParentChildJoin 
        |                    ON ContentEntryParentChildJoin.cepcjUid = 0 
        |               LEFT JOIN StatementEntity
        |							ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, extensionProgress DESC, resultSuccess DESC LIMIT 1)     
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)  
        |         WHERE ContentEntry.contentOwner = ?
        |           AND NOT EXISTS(
        |               SELECT ContentEntryParentChildJoin.cepcjUid 
        |                 FROM ContentEntryParentChildJoin
        |                WHERE ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid)
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, personUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: ContentEntry): Int {
    var _result = 0
    val _sql =
        "UPDATE ContentEntry SET title = ?, description = ?, entryId = ?, author = ?, publisher = ?, licenseType = ?, licenseName = ?, licenseUrl = ?, sourceUrl = ?, thumbnailUrl = ?, lastModified = ?, primaryLanguageUid = ?, languageVariantUid = ?, contentFlags = ?, leaf = ?, publik = ?, ceInactive = ?, completionCriteria = ?, minScore = ?, contentTypeFlag = ?, contentOwner = ?, contentEntryLocalChangeSeqNum = ?, contentEntryMasterChangeSeqNum = ?, contentEntryLastChangedBy = ?, contentEntryLct = ? WHERE contentEntryUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.title)
      _stmt.setString(2, entity.description)
      _stmt.setString(3, entity.entryId)
      _stmt.setString(4, entity.author)
      _stmt.setString(5, entity.publisher)
      _stmt.setInt(6, entity.licenseType)
      _stmt.setString(7, entity.licenseName)
      _stmt.setString(8, entity.licenseUrl)
      _stmt.setString(9, entity.sourceUrl)
      _stmt.setString(10, entity.thumbnailUrl)
      _stmt.setLong(11, entity.lastModified)
      _stmt.setLong(12, entity.primaryLanguageUid)
      _stmt.setLong(13, entity.languageVariantUid)
      _stmt.setInt(14, entity.contentFlags)
      _stmt.setBoolean(15, entity.leaf)
      _stmt.setBoolean(16, entity.publik)
      _stmt.setBoolean(17, entity.ceInactive)
      _stmt.setInt(18, entity.completionCriteria)
      _stmt.setInt(19, entity.minScore)
      _stmt.setInt(20, entity.contentTypeFlag)
      _stmt.setLong(21, entity.contentOwner)
      _stmt.setLong(22, entity.contentEntryLocalChangeSeqNum)
      _stmt.setLong(23, entity.contentEntryMasterChangeSeqNum)
      _stmt.setInt(24, entity.contentEntryLastChangedBy)
      _stmt.setLong(25, entity.contentEntryLct)
      _stmt.setLong(26, entity.contentEntryUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun getChildrenByAll(parentUid: Long): List<ContentEntry> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntry>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?"
        , postgreSql = """
    |SELECT ContentEntry.* FROM ContentEntry LEFT JOIN ContentEntryParentChildJoin ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findLiveContentEntry(parentUid: Long): DoorLiveData<ContentEntry?> {
    val _result = DoorLiveDataImpl<ContentEntry?>(_db, listOf("ContentEntry"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ContentEntry??
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM ContentEntry where contentEntryUid = ? LIMIT 1" ,
          postgreSql = """
      |SELECT * FROM ContentEntry where contentEntryUid = ? LIMIT 1
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, parentUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
            val tmp_title = _resultSet.getString("title")
            val tmp_description = _resultSet.getString("description")
            val tmp_entryId = _resultSet.getString("entryId")
            val tmp_author = _resultSet.getString("author")
            val tmp_publisher = _resultSet.getString("publisher")
            val tmp_licenseType = _resultSet.getInt("licenseType")
            val tmp_licenseName = _resultSet.getString("licenseName")
            val tmp_licenseUrl = _resultSet.getString("licenseUrl")
            val tmp_sourceUrl = _resultSet.getString("sourceUrl")
            val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
            val tmp_lastModified = _resultSet.getLong("lastModified")
            val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
            val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
            val tmp_contentFlags = _resultSet.getInt("contentFlags")
            val tmp_leaf = _resultSet.getBoolean("leaf")
            val tmp_publik = _resultSet.getBoolean("publik")
            val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
            val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
            val tmp_minScore = _resultSet.getInt("minScore")
            val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
            val tmp_contentOwner = _resultSet.getLong("contentOwner")
            val tmp_contentEntryLocalChangeSeqNum =
                _resultSet.getLong("contentEntryLocalChangeSeqNum")
            val tmp_contentEntryMasterChangeSeqNum =
                _resultSet.getLong("contentEntryMasterChangeSeqNum")
            val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
            val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
            val _entity = ContentEntry()
            _entity.contentEntryUid = tmp_contentEntryUid
            _entity.title = tmp_title
            _entity.description = tmp_description
            _entity.entryId = tmp_entryId
            _entity.author = tmp_author
            _entity.publisher = tmp_publisher
            _entity.licenseType = tmp_licenseType
            _entity.licenseName = tmp_licenseName
            _entity.licenseUrl = tmp_licenseUrl
            _entity.sourceUrl = tmp_sourceUrl
            _entity.thumbnailUrl = tmp_thumbnailUrl
            _entity.lastModified = tmp_lastModified
            _entity.primaryLanguageUid = tmp_primaryLanguageUid
            _entity.languageVariantUid = tmp_languageVariantUid
            _entity.contentFlags = tmp_contentFlags
            _entity.leaf = tmp_leaf
            _entity.publik = tmp_publik
            _entity.ceInactive = tmp_ceInactive
            _entity.completionCriteria = tmp_completionCriteria
            _entity.minScore = tmp_minScore
            _entity.contentTypeFlag = tmp_contentTypeFlag
            _entity.contentOwner = tmp_contentOwner
            _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
            _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
            _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
            _entity.contentEntryLct = tmp_contentEntryLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun getContentEntryUidFromXapiObjectId(objectId: String): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT COALESCE((SELECT contentEntryUid 
    |                                      FROM ContentEntry 
    |                                     WHERE entryId = ? 
    |                                     LIMIT 1),0) AS ID
    """.trimMargin() , postgreSql = """
    |SELECT COALESCE((SELECT contentEntryUid 
    |                                      FROM ContentEntry 
    |                                     WHERE entryId = ? 
    |                                     LIMIT 1),0) AS ID
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, objectId)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findSimilarIdEntryForKhan(sourceUrl: String): List<ContentEntry> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntry>()
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM ContentEntry WHERE sourceUrl LIKE ?" ,
        postgreSql = """
    |SELECT * FROM ContentEntry WHERE sourceUrl LIKE ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, sourceUrl)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun getRecursiveDownloadTotals(contentEntryUid: Long):
      DownloadJobSizeInfo? {
    var _result = null as com.ustadmobile.lib.db.entities.DownloadJobSizeInfo??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        WITH RECURSIVE 
    |               ContentEntry_recursive(contentEntryUid, containerSize) AS (
    |               SELECT contentEntryUid, 
    |                            (SELECT COALESCE((SELECT fileSize 
    |                                           FROM Container 
    |                                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
    |                                       ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize 
    |                 FROM ContentEntry 
    |                WHERE contentEntryUid = ?
    |                  AND NOT ceInactive
    |        UNION 
    |            SELECT ContentEntry.contentEntryUid, 
    |                (SELECT COALESCE((SELECT fileSize 
    |                                    FROM Container 
    |                                   WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
    |                                ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize  
    |                  FROM ContentEntry
    |             LEFT JOIN ContentEntryParentChildJoin 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid,
    |                            ContentEntry_recursive
    |                  WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid
    |                    AND NOT ceInactive)
    |        SELECT COUNT(*) AS numEntries, 
    |               SUM(containerSize) AS totalSize 
    |          FROM ContentEntry_recursive
    """.trimMargin() , postgreSql = """
    |
    |        WITH RECURSIVE 
    |               ContentEntry_recursive(contentEntryUid, containerSize) AS (
    |               SELECT contentEntryUid, 
    |                            (SELECT COALESCE((SELECT fileSize 
    |                                           FROM Container 
    |                                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
    |                                       ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize 
    |                 FROM ContentEntry 
    |                WHERE contentEntryUid = ?
    |                  AND NOT ceInactive
    |        UNION 
    |            SELECT ContentEntry.contentEntryUid, 
    |                (SELECT COALESCE((SELECT fileSize 
    |                                    FROM Container 
    |                                   WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
    |                                ORDER BY cntLastModified DESC LIMIT 1), 0)) AS containerSize  
    |                  FROM ContentEntry
    |             LEFT JOIN ContentEntryParentChildJoin 
    |                    ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid,
    |                            ContentEntry_recursive
    |                  WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid
    |                    AND NOT ceInactive)
    |        SELECT COUNT(*) AS numEntries, 
    |               SUM(containerSize) AS totalSize 
    |          FROM ContentEntry_recursive
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_numEntries = _resultSet.getInt("numEntries")
          val tmp_totalSize = _resultSet.getLong("totalSize")
          val _entity = DownloadJobSizeInfo()
          _entity.numEntries = tmp_numEntries
          _entity.totalSize = tmp_totalSize
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun getAllEntriesRecursively(contentEntryUid: Long): DoorDataSourceFactory<Int,
      ContentEntryWithParentChildJoinAndMostRecentContainer> {
    val _result = object : DoorDataSourceFactory<Int,
        ContentEntryWithParentChildJoinAndMostRecentContainer>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ContentEntryWithParentChildJoinAndMostRecentContainer>> =
          DoorLiveDataImpl<List<ContentEntryWithParentChildJoinAndMostRecentContainer>>(_db,
          listOf("ContentEntry", "ContentEntryParentChildJoin", "Container"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndMostRecentContainer>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (WITH RECURSIVE ContentEntry_recursive(
        |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
        |            
        |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
        |            
        |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
        |            ) AS (
        |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
        |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
        |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
        |            FROM 
        |            ContentEntry
        |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
        |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
        |            WHERE ContentEntry.contentEntryUid = ?
        |            UNION
        |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
        |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
        |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
        |            FROM 
        |            ContentEntry
        |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
        |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
        |            ContentEntry_recursive
        |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
        |            SELECT * FROM ContentEntry_recursive) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (WITH RECURSIVE ContentEntry_recursive(
        |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
        |            
        |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
        |            
        |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
        |            ) AS (
        |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
        |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
        |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
        |            FROM 
        |            ContentEntry
        |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
        |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
        |            WHERE ContentEntry.contentEntryUid = ?
        |            UNION
        |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
        |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
        |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
        |            FROM 
        |            ContentEntry
        |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
        |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
        |            ContentEntry_recursive
        |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
        |            SELECT * FROM ContentEntry_recursive) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, contentEntryUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
              val tmp_title = _resultSet.getString("title")
              val tmp_description = _resultSet.getString("description")
              val tmp_entryId = _resultSet.getString("entryId")
              val tmp_author = _resultSet.getString("author")
              val tmp_publisher = _resultSet.getString("publisher")
              val tmp_licenseType = _resultSet.getInt("licenseType")
              val tmp_licenseName = _resultSet.getString("licenseName")
              val tmp_licenseUrl = _resultSet.getString("licenseUrl")
              val tmp_sourceUrl = _resultSet.getString("sourceUrl")
              val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
              val tmp_lastModified = _resultSet.getLong("lastModified")
              val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
              val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
              val tmp_contentFlags = _resultSet.getInt("contentFlags")
              val tmp_leaf = _resultSet.getBoolean("leaf")
              val tmp_publik = _resultSet.getBoolean("publik")
              val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
              val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
              val tmp_minScore = _resultSet.getInt("minScore")
              val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
              val tmp_contentOwner = _resultSet.getLong("contentOwner")
              val tmp_contentEntryLocalChangeSeqNum =
                  _resultSet.getLong("contentEntryLocalChangeSeqNum")
              val tmp_contentEntryMasterChangeSeqNum =
                  _resultSet.getLong("contentEntryMasterChangeSeqNum")
              val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
              val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
              val _entity = ContentEntryWithParentChildJoinAndMostRecentContainer()
              _entity.contentEntryUid = tmp_contentEntryUid
              _entity.title = tmp_title
              _entity.description = tmp_description
              _entity.entryId = tmp_entryId
              _entity.author = tmp_author
              _entity.publisher = tmp_publisher
              _entity.licenseType = tmp_licenseType
              _entity.licenseName = tmp_licenseName
              _entity.licenseUrl = tmp_licenseUrl
              _entity.sourceUrl = tmp_sourceUrl
              _entity.thumbnailUrl = tmp_thumbnailUrl
              _entity.lastModified = tmp_lastModified
              _entity.primaryLanguageUid = tmp_primaryLanguageUid
              _entity.languageVariantUid = tmp_languageVariantUid
              _entity.contentFlags = tmp_contentFlags
              _entity.leaf = tmp_leaf
              _entity.publik = tmp_publik
              _entity.ceInactive = tmp_ceInactive
              _entity.completionCriteria = tmp_completionCriteria
              _entity.minScore = tmp_minScore
              _entity.contentTypeFlag = tmp_contentTypeFlag
              _entity.contentOwner = tmp_contentOwner
              _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
              _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
              _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
              _entity.contentEntryLct = tmp_contentEntryLct
              var _mostRecentContainer_nullFieldCount = 0
              val tmp_containerUid = _resultSet.getLong("containerUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLct = _resultSet.getLong("cntLct")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_fileSize = _resultSet.getLong("fileSize")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mimeType = _resultSet.getString("mimeType")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_remarks = _resultSet.getString("remarks")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              if(_mostRecentContainer_nullFieldCount < 12) {
                if(_entity.mostRecentContainer == null) {
                  _entity.mostRecentContainer = Container()
                }
                _entity.mostRecentContainer!!.containerUid = tmp_containerUid
                _entity.mostRecentContainer!!.cntLocalCsn = tmp_cntLocalCsn
                _entity.mostRecentContainer!!.cntMasterCsn = tmp_cntMasterCsn
                _entity.mostRecentContainer!!.cntLastModBy = tmp_cntLastModBy
                _entity.mostRecentContainer!!.cntLct = tmp_cntLct
                _entity.mostRecentContainer!!.fileSize = tmp_fileSize
                _entity.mostRecentContainer!!.containerContentEntryUid =
                    tmp_containerContentEntryUid
                _entity.mostRecentContainer!!.cntLastModified = tmp_cntLastModified
                _entity.mostRecentContainer!!.mimeType = tmp_mimeType
                _entity.mostRecentContainer!!.remarks = tmp_remarks
                _entity.mostRecentContainer!!.mobileOptimized = tmp_mobileOptimized
                _entity.mostRecentContainer!!.cntNumEntries = tmp_cntNumEntries
              }
              var _contentEntryParentChildJoin_nullFieldCount = 0
              val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_childIndex = _resultSet.getInt("childIndex")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              if(_contentEntryParentChildJoin_nullFieldCount < 8) {
                if(_entity.contentEntryParentChildJoin == null) {
                  _entity.contentEntryParentChildJoin = ContentEntryParentChildJoin()
                }
                _entity.contentEntryParentChildJoin!!.cepcjParentContentEntryUid =
                    tmp_cepcjParentContentEntryUid
                _entity.contentEntryParentChildJoin!!.cepcjChildContentEntryUid =
                    tmp_cepcjChildContentEntryUid
                _entity.contentEntryParentChildJoin!!.childIndex = tmp_childIndex
                _entity.contentEntryParentChildJoin!!.cepcjUid = tmp_cepcjUid
                _entity.contentEntryParentChildJoin!!.cepcjLocalChangeSeqNum =
                    tmp_cepcjLocalChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjMasterChangeSeqNum =
                    tmp_cepcjMasterChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjLastChangedBy = tmp_cepcjLastChangedBy
                _entity.contentEntryParentChildJoin!!.cepcjLct = tmp_cepcjLct
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ContentEntry", "ContentEntryParentChildJoin", "Container"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (WITH RECURSIVE ContentEntry_recursive(
        |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
        |            
        |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
        |            
        |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
        |            ) AS (
        |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
        |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
        |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
        |            FROM 
        |            ContentEntry
        |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
        |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
        |            WHERE ContentEntry.contentEntryUid = ?
        |            UNION
        |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
        |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
        |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
        |            FROM 
        |            ContentEntry
        |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
        |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
        |            ContentEntry_recursive
        |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
        |            SELECT * FROM ContentEntry_recursive) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (WITH RECURSIVE ContentEntry_recursive(
        |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
        |            
        |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
        |            
        |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
        |            ) AS (
        |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
        |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
        |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
        |            FROM 
        |            ContentEntry
        |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
        |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
        |            WHERE ContentEntry.contentEntryUid = ?
        |            UNION
        |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
        |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
        |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
        |            FROM 
        |            ContentEntry
        |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
        |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
        |            ContentEntry_recursive
        |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
        |            SELECT * FROM ContentEntry_recursive) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, contentEntryUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun getAllEntriesRecursivelyAsList(contentEntryUid: Long):
      List<ContentEntryWithParentChildJoinAndMostRecentContainer> {
    var _result =
        mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndMostRecentContainer>()
    val _stmtConfig = PreparedStatementConfig("""
    |WITH RECURSIVE ContentEntry_recursive(
    |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
    |            
    |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
    |            
    |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
    |            ) AS (
    |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
    |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
    |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
    |            FROM 
    |            ContentEntry
    |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
    |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
    |            WHERE ContentEntry.contentEntryUid = ?
    |            UNION
    |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
    |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
    |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
    |            FROM 
    |            ContentEntry
    |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
    |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
    |            ContentEntry_recursive
    |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
    |            SELECT * FROM ContentEntry_recursive
    """.trimMargin() , postgreSql = """
    |WITH RECURSIVE ContentEntry_recursive(
    |            contentEntryUid, title, ceInactive, contentFlags, description, entryId, author, publisher, licenseType, licenseName, licenseUrl, sourceUrl, thumbnailUrl, lastModified, primaryLanguageUid, languageVariantUid, leaf, publik,  completionCriteria, minScore, contentOwner, contentTypeFlag, contentEntryLocalChangeSeqNum, contentEntryMasterChangeSeqNum, contentEntryLastChangedBy, contentEntryLct,
    |            
    |            cepcjUid, cepcjChildContentEntryUid, cepcjParentContentEntryUid, childIndex, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct,
    |            
    |            containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries, cntLct
    |            ) AS (
    |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
    |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct,
    |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
    |            FROM 
    |            ContentEntry
    |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
    |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0))
    |            WHERE ContentEntry.contentEntryUid = ?
    |            UNION
    |            SELECT ContentEntry.contentEntryUid, ContentEntry.title, ContentEntry.ceInactive, ContentEntry.contentFlags, ContentEntry.description, ContentEntry.entryId, ContentEntry.author, ContentEntry.publisher, ContentEntry.licenseType, ContentEntry.licenseName, ContentEntry.licenseUrl, ContentEntry.sourceUrl, ContentEntry.thumbnailUrl, ContentEntry.lastModified, ContentEntry.primaryLanguageUid, ContentEntry.languageVariantUid, ContentEntry.leaf, ContentEntry.publik, ContentEntry.completionCriteria, ContentEntry.minScore, ContentEntry.contentOwner, ContentEntry.contentTypeFlag, ContentEntry.contentEntryLocalChangeSeqNum, ContentEntry.contentEntryMasterChangeSeqNum, ContentEntry.contentEntryLastChangedBy, ContentEntry.contentEntryLct,
    |            ContentEntryParentChildJoin.cepcjUid, ContentEntryParentChildJoin.cepcjChildContentEntryUid, ContentEntryParentChildJoin.cepcjParentContentEntryUid, ContentEntryParentChildJoin.childIndex, ContentEntryParentChildJoin.cepcjLocalChangeSeqNum, ContentEntryParentChildJoin.cepcjMasterChangeSeqNum, ContentEntryParentChildJoin.cepcjLastChangedBy, ContentEntryParentChildJoin.cepcjLct, 
    |            Container.containerUid, Container.cntLocalCsn, Container.cntMasterCsn, Container.cntLastModBy, Container.fileSize, Container.containerContentEntryUid, Container.cntLastModified, Container.mimeType, Container.remarks, Container.mobileOptimized, Container.cntNumEntries, Container.cntLct
    |            FROM 
    |            ContentEntry
    |            LEFT JOIN ContentEntryParentChildJoin ON ContentEntry.contentEntryUid = ContentEntryParentChildJoin.cepcjChildContentEntryUid 
    |            LEFT JOIN Container ON Container.containerUid = (SELECT COALESCE((SELECT containerUid FROM Container WHERE containerContentEntryUid = ContentEntry.contentEntryUid ORDER BY cntLastModified DESC LIMIT 1), 0)),
    |            ContentEntry_recursive
    |            WHERE ContentEntryParentChildJoin.cepcjParentContentEntryUid = ContentEntry_recursive.contentEntryUid)
    |            SELECT * FROM ContentEntry_recursive
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntryWithParentChildJoinAndMostRecentContainer()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          var _mostRecentContainer_nullFieldCount = 0
          val tmp_containerUid = _resultSet.getLong("containerUid")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntLct = _resultSet.getLong("cntLct")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_fileSize = _resultSet.getLong("fileSize")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_mimeType = _resultSet.getString("mimeType")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_remarks = _resultSet.getString("remarks")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          if(_mostRecentContainer_nullFieldCount < 12) {
            if(_entity.mostRecentContainer == null) {
              _entity.mostRecentContainer = Container()
            }
            _entity.mostRecentContainer!!.containerUid = tmp_containerUid
            _entity.mostRecentContainer!!.cntLocalCsn = tmp_cntLocalCsn
            _entity.mostRecentContainer!!.cntMasterCsn = tmp_cntMasterCsn
            _entity.mostRecentContainer!!.cntLastModBy = tmp_cntLastModBy
            _entity.mostRecentContainer!!.cntLct = tmp_cntLct
            _entity.mostRecentContainer!!.fileSize = tmp_fileSize
            _entity.mostRecentContainer!!.containerContentEntryUid = tmp_containerContentEntryUid
            _entity.mostRecentContainer!!.cntLastModified = tmp_cntLastModified
            _entity.mostRecentContainer!!.mimeType = tmp_mimeType
            _entity.mostRecentContainer!!.remarks = tmp_remarks
            _entity.mostRecentContainer!!.mobileOptimized = tmp_mobileOptimized
            _entity.mostRecentContainer!!.cntNumEntries = tmp_cntNumEntries
          }
          var _contentEntryParentChildJoin_nullFieldCount = 0
          val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_childIndex = _resultSet.getInt("childIndex")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          if(_contentEntryParentChildJoin_nullFieldCount < 8) {
            if(_entity.contentEntryParentChildJoin == null) {
              _entity.contentEntryParentChildJoin = ContentEntryParentChildJoin()
            }
            _entity.contentEntryParentChildJoin!!.cepcjParentContentEntryUid =
                tmp_cepcjParentContentEntryUid
            _entity.contentEntryParentChildJoin!!.cepcjChildContentEntryUid =
                tmp_cepcjChildContentEntryUid
            _entity.contentEntryParentChildJoin!!.childIndex = tmp_childIndex
            _entity.contentEntryParentChildJoin!!.cepcjUid = tmp_cepcjUid
            _entity.contentEntryParentChildJoin!!.cepcjLocalChangeSeqNum =
                tmp_cepcjLocalChangeSeqNum
            _entity.contentEntryParentChildJoin!!.cepcjMasterChangeSeqNum =
                tmp_cepcjMasterChangeSeqNum
            _entity.contentEntryParentChildJoin!!.cepcjLastChangedBy = tmp_cepcjLastChangedBy
            _entity.contentEntryParentChildJoin!!.cepcjLct = tmp_cepcjLct
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun updateContentEntryInActive(
    contentEntryUid: Long,
    ceInactive: Boolean,
    changedTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            UPDATE ContentEntry 
    |               SET ceInactive = ?,
    |                   contentEntryLct = ?        
    |            WHERE ContentEntry.contentEntryUid = ?
    """.trimMargin() , postgreSql = """
    |
    |            UPDATE ContentEntry 
    |               SET ceInactive = ?,
    |                   contentEntryLct = ?        
    |            WHERE ContentEntry.contentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, ceInactive)
      _stmt.setLong(2, changedTime)
      _stmt.setLong(3, contentEntryUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun updateContentEntryContentFlag(
    contentFlag: Int,
    contentEntryUid: Long,
    changedTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentEntry 
    |           SET contentTypeFlag = ?,
    |               contentEntryLct = ? 
    |         WHERE ContentEntry.contentEntryUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentEntry 
    |           SET contentTypeFlag = ?,
    |               contentEntryLct = ? 
    |         WHERE ContentEntry.contentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, contentFlag)
      _stmt.setLong(2, changedTime)
      _stmt.setLong(3, contentEntryUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun replaceList(entries: List<out ContentEntry>): Unit {
    _insertAdapterContentEntry_upsert.insertList(entries)
  }

  public override suspend fun getContentEntryFromUids(contentEntryUids: List<Long>):
      List<UidAndLabel> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.UidAndLabel>()
    val _stmtConfig = PreparedStatementConfig("""
    |Select ContentEntry.contentEntryUid AS uid, ContentEntry.title As labelName 
    |                    from ContentEntry WHERE contentEntryUid IN (?)
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |Select ContentEntry.contentEntryUid AS uid, ContentEntry.title As labelName 
    |                    from ContentEntry WHERE contentEntryUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          contentEntryUids.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_uid = _resultSet.getLong("uid")
          val tmp_labelName = _resultSet.getString("labelName")
          val _entity = UidAndLabel()
          _entity.uid = tmp_uid
          _entity.labelName = tmp_labelName
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun insertWithReplace(entry: ContentEntry): Unit {
    _insertAdapterContentEntry_upsert.insert(entry)
  }

  public override fun findAllLive(): DoorLiveData<List<ContentEntryWithLanguage>> {
    val _result = DoorLiveDataImpl<List<ContentEntryWithLanguage>>(_db, listOf("ContentEntry",
        "Language"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryWithLanguage>()
      val _stmtConfig =
          PreparedStatementConfig("SELECT ContentEntry.*, Language.* FROM ContentEntry LEFT JOIN Language ON Language.langUid = ContentEntry.primaryLanguageUid"
          , postgreSql = """
      |SELECT ContentEntry.*, Language.* FROM ContentEntry LEFT JOIN Language ON Language.langUid = ContentEntry.primaryLanguageUid
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
            val tmp_title = _resultSet.getString("title")
            val tmp_description = _resultSet.getString("description")
            val tmp_entryId = _resultSet.getString("entryId")
            val tmp_author = _resultSet.getString("author")
            val tmp_publisher = _resultSet.getString("publisher")
            val tmp_licenseType = _resultSet.getInt("licenseType")
            val tmp_licenseName = _resultSet.getString("licenseName")
            val tmp_licenseUrl = _resultSet.getString("licenseUrl")
            val tmp_sourceUrl = _resultSet.getString("sourceUrl")
            val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
            val tmp_lastModified = _resultSet.getLong("lastModified")
            val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
            val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
            val tmp_contentFlags = _resultSet.getInt("contentFlags")
            val tmp_leaf = _resultSet.getBoolean("leaf")
            val tmp_publik = _resultSet.getBoolean("publik")
            val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
            val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
            val tmp_minScore = _resultSet.getInt("minScore")
            val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
            val tmp_contentOwner = _resultSet.getLong("contentOwner")
            val tmp_contentEntryLocalChangeSeqNum =
                _resultSet.getLong("contentEntryLocalChangeSeqNum")
            val tmp_contentEntryMasterChangeSeqNum =
                _resultSet.getLong("contentEntryMasterChangeSeqNum")
            val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
            val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
            val _entity = ContentEntryWithLanguage()
            _entity.contentEntryUid = tmp_contentEntryUid
            _entity.title = tmp_title
            _entity.description = tmp_description
            _entity.entryId = tmp_entryId
            _entity.author = tmp_author
            _entity.publisher = tmp_publisher
            _entity.licenseType = tmp_licenseType
            _entity.licenseName = tmp_licenseName
            _entity.licenseUrl = tmp_licenseUrl
            _entity.sourceUrl = tmp_sourceUrl
            _entity.thumbnailUrl = tmp_thumbnailUrl
            _entity.lastModified = tmp_lastModified
            _entity.primaryLanguageUid = tmp_primaryLanguageUid
            _entity.languageVariantUid = tmp_languageVariantUid
            _entity.contentFlags = tmp_contentFlags
            _entity.leaf = tmp_leaf
            _entity.publik = tmp_publik
            _entity.ceInactive = tmp_ceInactive
            _entity.completionCriteria = tmp_completionCriteria
            _entity.minScore = tmp_minScore
            _entity.contentTypeFlag = tmp_contentTypeFlag
            _entity.contentOwner = tmp_contentOwner
            _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
            _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
            _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
            _entity.contentEntryLct = tmp_contentEntryLct
            var _language_nullFieldCount = 0
            val tmp_langUid = _resultSet.getLong("langUid")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_name = _resultSet.getString("name")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_Language_Type = _resultSet.getString("Language_Type")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_languageActive = _resultSet.getBoolean("languageActive")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            val tmp_langLct = _resultSet.getLong("langLct")
            if(_resultSet.wasNull()) { _language_nullFieldCount++ }
            if(_language_nullFieldCount < 11) {
              if(_entity.language == null) {
                _entity.language = Language()
              }
              _entity.language!!.langUid = tmp_langUid
              _entity.language!!.name = tmp_name
              _entity.language!!.iso_639_1_standard = tmp_iso_639_1_standard
              _entity.language!!.iso_639_2_standard = tmp_iso_639_2_standard
              _entity.language!!.iso_639_3_standard = tmp_iso_639_3_standard
              _entity.language!!.Language_Type = tmp_Language_Type
              _entity.language!!.languageActive = tmp_languageActive
              _entity.language!!.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
              _entity.language!!.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
              _entity.language!!.langLastChangedBy = tmp_langLastChangedBy
              _entity.language!!.langLct = tmp_langLct
            }
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override suspend fun personHasPermissionWithContentEntry(
    accountPersonUid: Long,
    contentEntryUid: Long,
    permission: Long
  ): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT EXISTS(SELECT 1 FROM ContentEntry WHERE ContentEntry.contentEntryUid = ? AND ? IN (
    |            SELECT DISTINCT Person.PersonUid FROM Person
    |            LEFT JOIN PersonGroupMember ON Person.personUid = PersonGroupMember.groupMemberPersonUid
    |            LEFT JOIN EntityRole ON EntityRole.erGroupUid = PersonGroupMember.groupMemberGroupUid
    |            LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid
    |            WHERE 
    |            CAST(Person.admin AS INTEGER) = 1
    |            OR 
    |            (EntityRole.ertableId = 42 AND 
    |            EntityRole.erEntityUid = ContentEntry.contentEntryUid AND
    |            (Role.rolePermissions &  
    |         ? ) > 0)))
    """.trimMargin() , postgreSql = """
    |SELECT EXISTS(SELECT 1 FROM ContentEntry WHERE ContentEntry.contentEntryUid = ? AND ? IN (
    |            SELECT DISTINCT Person.PersonUid FROM Person
    |            LEFT JOIN PersonGroupMember ON Person.personUid = PersonGroupMember.groupMemberPersonUid
    |            LEFT JOIN EntityRole ON EntityRole.erGroupUid = PersonGroupMember.groupMemberGroupUid
    |            LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid
    |            WHERE 
    |            CAST(Person.admin AS INTEGER) = 1
    |            OR 
    |            (EntityRole.ertableId = 42 AND 
    |            EntityRole.erEntityUid = ContentEntry.contentEntryUid AND
    |            (Role.rolePermissions &  
    |         ? ) > 0)))
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.setLong(2, accountPersonUid)
      _stmt.setLong(3, permission)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun updateContentEntryActiveByContentJobUid(
    jobId: Long,
    inactive: Boolean,
    changedTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentEntry
    |           SET ceInactive = ?,
    |               contentEntryLct = ?
    |         WHERE contentEntryUid IN 
    |               (SELECT cjiContentEntryUid 
    |                  FROM ContentJobItem
    |                 WHERE cjiJobUid = ?
    |                   AND CAST(ContentJobItem.cjiContentDeletedOnCancellation AS INTEGER) = 1)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentEntry
    |           SET ceInactive = ?,
    |               contentEntryLct = ?
    |         WHERE contentEntryUid IN 
    |               (SELECT cjiContentEntryUid 
    |                  FROM ContentJobItem
    |                 WHERE cjiJobUid = ?
    |                   AND CAST(ContentJobItem.cjiContentDeletedOnCancellation AS INTEGER) = 1)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, inactive)
      _stmt.setLong(2, changedTime)
      _stmt.setLong(3, jobId)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override suspend fun toggleVisibilityContentEntryItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentEntry 
    |           SET ceInactive = ?, 
    |               contentEntryLct = ? 
    |         WHERE contentEntryUid IN (?)
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        UPDATE ContentEntry 
    |           SET ceInactive = ?, 
    |               contentEntryLct = ? 
    |         WHERE contentEntryUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, toggleVisibility)
      _stmt.setLong(2, changedTime)
      _stmt.setArray(3, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          selectedItem.toTypedArray()))
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend
      fun findContentEntriesWhereIsLeafAndLatestContainerHasNoEntriesOrHasZeroFileSize():
      List<ContentEntry> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntry>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |SELECT ContentEntry.*
    |  FROM ContentEntry
    |       JOIN Container ON Container.containerUid = 
    |       (SELECT containerUid 
    |          FROM Container
    |         WHERE Container.containercontententryUid = ContentEntry.contentEntryUid
    |           AND Container.cntLastModified = 
    |               (SELECT MAX(ContainerInternal.cntLastModified)
    |                  FROM Container ContainerInternal
    |                 WHERE ContainerInternal.containercontententryUid = ContentEntry.contentEntryUid))
    | WHERE ContentEntry.leaf 
    |   AND NOT ContentEntry.ceInactive
    |   AND (NOT EXISTS 
    |       (SELECT ContainerEntry.ceUid
    |          FROM ContainerEntry
    |         WHERE ContainerEntry.ceContainerUid = Container.containerUid)
    |        OR Container.fileSize = 0)   
    |    
    """.trimMargin() , postgreSql = """
    |
    |SELECT ContentEntry.*
    |  FROM ContentEntry
    |       JOIN Container ON Container.containerUid = 
    |       (SELECT containerUid 
    |          FROM Container
    |         WHERE Container.containercontententryUid = ContentEntry.contentEntryUid
    |           AND Container.cntLastModified = 
    |               (SELECT MAX(ContainerInternal.cntLastModified)
    |                  FROM Container ContainerInternal
    |                 WHERE ContainerInternal.containercontententryUid = ContentEntry.contentEntryUid))
    | WHERE ContentEntry.leaf 
    |   AND NOT ContentEntry.ceInactive
    |   AND (NOT EXISTS 
    |       (SELECT ContainerEntry.ceUid
    |          FROM ContainerEntry
    |         WHERE ContainerEntry.ceContainerUid = Container.containerUid)
    |        OR Container.fileSize = 0)   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun buttonsToShowForContentEntry(contentEntryUid: Long,
      platformDownloadEnabled: Boolean): ContentEntryButtonModel? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryButtonModel??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        WITH ContentEntryContainerUids AS 
    |             (SELECT Container.containerUid
    |                FROM Container
    |               WHERE Container.containerContentEntryUid = ?
    |                   AND Container.fileSize > 0),
    |                   
    |             
    |            LatestDownloadedContainer(containerUid) AS
    |             (SELECT COALESCE(
    |                     (SELECT containerUid
    |                        FROM Container
    |                       WHERE Container.containerContentEntryUid = ? 
    |                         AND EXISTS(
    |                             SELECT 1
    |                               FROM ContainerEntry
    |                              WHERE ContainerEntry.ceContainerUid = Container.containerUid)
    |                    ORDER BY cntLastModified DESC
    |                       LIMIT 1), 0))
    |        ,
    |                            
    |             
    |            ActiveContentJobItems(cjiRecursiveStatus, cjiPluginId) AS
    |             (SELECT cjiRecursiveStatus, cjiPluginId
    |                FROM ContentJobItem
    |               WHERE cjiContentEntryUid = ?
    |                 AND cjiStatus BETWEEN 4 AND 20)
    |        ,
    |                  
    |            ShowDownload(showDownload) AS 
    |            (SELECT CAST(? AS INTEGER) = 1
    |                AND (SELECT containerUid FROM LatestDownloadedContainer) = 0
    |                AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0
    |                AND (SELECT COUNT(*) FROM ContentEntryContainerUids) > 0)
    |                   
    |        SELECT (SELECT showDownload FROM ShowDownload)
    |               AS showDownloadButton,
    |        
    |               CAST(? AS INTEGER) = 0
    |               OR (SELECT containerUid FROM LatestDownloadedContainer) != 0          
    |               AS showOpenButton,
    |       
    |               (SELECT NOT showDownload FROM ShowDownload)
    |           AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0    
    |           AND (SELECT COALESCE(
    |                       (SELECT cntLastModified
    |                          FROM Container
    |                         WHERE containerContentEntryUid = ?
    |                           AND fileSize > 0
    |                      ORDER BY cntLastModified DESC), 0)) 
    |               > (SELECT COALESCE(
    |                         (SELECT cntLastModified
    |                            FROM Container
    |                           WHERE Container.containerUid = 
    |                                 (SELECT LatestDownloadedContainer.containerUid
    |                                    FROM LatestDownloadedContainer)), 0)) 
    |               AS showUpdateButton,
    |               
    |               CAST(? AS INTEGER) = 1
    |           AND (SELECT containerUid FROM LatestDownloadedContainer) != 0
    |           AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0    
    |               AS showDeleteButton,
    |               
    |               (SELECT COUNT(*) 
    |                  FROM ActiveContentJobItems 
    |                 WHERE cjiPluginId = 10) > 0
    |               AS showManageDownloadButton
    |    
    """.trimMargin() , postgreSql = """
    |
    |        WITH ContentEntryContainerUids AS 
    |             (SELECT Container.containerUid
    |                FROM Container
    |               WHERE Container.containerContentEntryUid = ?
    |                   AND Container.fileSize > 0),
    |                   
    |             
    |            LatestDownloadedContainer(containerUid) AS
    |             (SELECT COALESCE(
    |                     (SELECT containerUid
    |                        FROM Container
    |                       WHERE Container.containerContentEntryUid = ? 
    |                         AND EXISTS(
    |                             SELECT 1
    |                               FROM ContainerEntry
    |                              WHERE ContainerEntry.ceContainerUid = Container.containerUid)
    |                    ORDER BY cntLastModified DESC
    |                       LIMIT 1), 0))
    |        ,
    |                            
    |             
    |            ActiveContentJobItems(cjiRecursiveStatus, cjiPluginId) AS
    |             (SELECT cjiRecursiveStatus, cjiPluginId
    |                FROM ContentJobItem
    |               WHERE cjiContentEntryUid = ?
    |                 AND cjiStatus BETWEEN 4 AND 20)
    |        ,
    |                  
    |            ShowDownload(showDownload) AS 
    |            (SELECT CAST(? AS INTEGER) = 1
    |                AND (SELECT containerUid FROM LatestDownloadedContainer) = 0
    |                AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0
    |                AND (SELECT COUNT(*) FROM ContentEntryContainerUids) > 0)
    |                   
    |        SELECT (SELECT showDownload FROM ShowDownload)
    |               AS showDownloadButton,
    |        
    |               CAST(? AS INTEGER) = 0
    |               OR (SELECT containerUid FROM LatestDownloadedContainer) != 0          
    |               AS showOpenButton,
    |       
    |               (SELECT NOT showDownload FROM ShowDownload)
    |           AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0    
    |           AND (SELECT COALESCE(
    |                       (SELECT cntLastModified
    |                          FROM Container
    |                         WHERE containerContentEntryUid = ?
    |                           AND fileSize > 0
    |                      ORDER BY cntLastModified DESC), 0)) 
    |               > (SELECT COALESCE(
    |                         (SELECT cntLastModified
    |                            FROM Container
    |                           WHERE Container.containerUid = 
    |                                 (SELECT LatestDownloadedContainer.containerUid
    |                                    FROM LatestDownloadedContainer)), 0)) 
    |               AS showUpdateButton,
    |               
    |               CAST(? AS INTEGER) = 1
    |           AND (SELECT containerUid FROM LatestDownloadedContainer) != 0
    |           AND (SELECT COUNT(*) FROM ActiveContentJobItems) = 0    
    |               AS showDeleteButton,
    |               
    |               (SELECT COUNT(*) 
    |                  FROM ActiveContentJobItems 
    |                 WHERE cjiPluginId = 10) > 0
    |               AS showManageDownloadButton
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.setLong(2, contentEntryUid)
      _stmt.setLong(3, contentEntryUid)
      _stmt.setBoolean(4, platformDownloadEnabled)
      _stmt.setBoolean(5, platformDownloadEnabled)
      _stmt.setLong(6, contentEntryUid)
      _stmt.setBoolean(7, platformDownloadEnabled)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_showDownloadButton = _resultSet.getBoolean("showDownloadButton")
          val tmp_showOpenButton = _resultSet.getBoolean("showOpenButton")
          val tmp_showUpdateButton = _resultSet.getBoolean("showUpdateButton")
          val tmp_showDeleteButton = _resultSet.getBoolean("showDeleteButton")
          val tmp_showManageDownloadButton = _resultSet.getBoolean("showManageDownloadButton")
          val _entity = ContentEntryButtonModel()
          _entity.showDownloadButton = tmp_showDownloadButton
          _entity.showOpenButton = tmp_showOpenButton
          _entity.showUpdateButton = tmp_showUpdateButton
          _entity.showDeleteButton = tmp_showDeleteButton
          _entity.showManageDownloadButton = tmp_showManageDownloadButton
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun statusForDownloadDialog(contentEntryUid: Long): Int {
    var _result = 0
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentJobItem.cjiRecursiveStatus AS status
    |         FROM ContentJobItem
    |        WHERE ContentJobItem.cjiContentEntryUid = ?
    |          AND ContentJobItem.cjiPluginId != 14
    |          AND ContentJobItem.cjiStatus BETWEEN 4 AND 25
    |          AND NOT EXISTS(
    |              SELECT 1
    |                FROM ContentJobItem ContentJobItemInternal
    |               WHERE ContentJobItemInternal.cjiContentEntryUid = ?
    |                 AND ContentJobItemInternal.cjiPluginId = 14
    |                 AND ContentJobItemInternal.cjiFinishTime > ContentJobItem.cjiStartTime)
    |     ORDER BY ContentJobItem.cjiFinishTime DESC
    |        LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentJobItem.cjiRecursiveStatus AS status
    |         FROM ContentJobItem
    |        WHERE ContentJobItem.cjiContentEntryUid = ?
    |          AND ContentJobItem.cjiPluginId != 14
    |          AND ContentJobItem.cjiStatus BETWEEN 4 AND 25
    |          AND NOT EXISTS(
    |              SELECT 1
    |                FROM ContentJobItem ContentJobItemInternal
    |               WHERE ContentJobItemInternal.cjiContentEntryUid = ?
    |                 AND ContentJobItemInternal.cjiPluginId = 14
    |                 AND ContentJobItemInternal.cjiFinishTime > ContentJobItem.cjiStartTime)
    |     ORDER BY ContentJobItem.cjiFinishTime DESC
    |        LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.setLong(2, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getInt(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun statusForContentEntryList(contentEntryUid: Long):
      ContentJobItemProgressAndStatus? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentJobItemProgressAndStatus??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentJobItem.cjiRecursiveStatus AS status, 
    |               ContentJobItem.cjiRecursiveProgress AS progress,
    |               ContentJobItem.cjiRecursiveTotal AS total
    |         FROM ContentJobItem
    |        WHERE ContentJobItem.cjiContentEntryUid = ?
    |          AND ContentJobItem.cjiPluginId != 14
    |          AND ContentJobItem.cjiStatus BETWEEN 4 AND 25
    |          AND NOT EXISTS(
    |              SELECT 1
    |                FROM ContentJobItem ContentJobItemInternal
    |               WHERE ContentJobItemInternal.cjiContentEntryUid = ?
    |                 AND ContentJobItemInternal.cjiPluginId = 14
    |                 AND ContentJobItemInternal.cjiFinishTime > ContentJobItem.cjiStartTime)
    |     ORDER BY ContentJobItem.cjiFinishTime DESC
    |        LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentJobItem.cjiRecursiveStatus AS status, 
    |               ContentJobItem.cjiRecursiveProgress AS progress,
    |               ContentJobItem.cjiRecursiveTotal AS total
    |         FROM ContentJobItem
    |        WHERE ContentJobItem.cjiContentEntryUid = ?
    |          AND ContentJobItem.cjiPluginId != 14
    |          AND ContentJobItem.cjiStatus BETWEEN 4 AND 25
    |          AND NOT EXISTS(
    |              SELECT 1
    |                FROM ContentJobItem ContentJobItemInternal
    |               WHERE ContentJobItemInternal.cjiContentEntryUid = ?
    |                 AND ContentJobItemInternal.cjiPluginId = 14
    |                 AND ContentJobItemInternal.cjiFinishTime > ContentJobItem.cjiStartTime)
    |     ORDER BY ContentJobItem.cjiFinishTime DESC
    |        LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.setLong(2, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_status = _resultSet.getInt("status")
          val tmp_progress = _resultSet.getLong("progress")
          val tmp_total = _resultSet.getLong("total")
          val _entity = ContentJobItemProgressAndStatus()
          _entity.status = tmp_status
          _entity.progress = tmp_progress
          _entity.total = tmp_total
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ContentEntry): Long {
    val _retVal = _insertAdapterContentEntry_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntry): Long {
    val _retVal = _insertAdapterContentEntry_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContentEntry>): Unit {
    _insertAdapterContentEntry_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentEntry>): Unit {
    val _sql =
        "UPDATE ContentEntry SET title = ?, description = ?, entryId = ?, author = ?, publisher = ?, licenseType = ?, licenseName = ?, licenseUrl = ?, sourceUrl = ?, thumbnailUrl = ?, lastModified = ?, primaryLanguageUid = ?, languageVariantUid = ?, contentFlags = ?, leaf = ?, publik = ?, ceInactive = ?, completionCriteria = ?, minScore = ?, contentTypeFlag = ?, contentOwner = ?, contentEntryLocalChangeSeqNum = ?, contentEntryMasterChangeSeqNum = ?, contentEntryLastChangedBy = ?, contentEntryLct = ? WHERE contentEntryUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.title)
        _stmt.setString(2, _entity.description)
        _stmt.setString(3, _entity.entryId)
        _stmt.setString(4, _entity.author)
        _stmt.setString(5, _entity.publisher)
        _stmt.setInt(6, _entity.licenseType)
        _stmt.setString(7, _entity.licenseName)
        _stmt.setString(8, _entity.licenseUrl)
        _stmt.setString(9, _entity.sourceUrl)
        _stmt.setString(10, _entity.thumbnailUrl)
        _stmt.setLong(11, _entity.lastModified)
        _stmt.setLong(12, _entity.primaryLanguageUid)
        _stmt.setLong(13, _entity.languageVariantUid)
        _stmt.setInt(14, _entity.contentFlags)
        _stmt.setBoolean(15, _entity.leaf)
        _stmt.setBoolean(16, _entity.publik)
        _stmt.setBoolean(17, _entity.ceInactive)
        _stmt.setInt(18, _entity.completionCriteria)
        _stmt.setInt(19, _entity.minScore)
        _stmt.setInt(20, _entity.contentTypeFlag)
        _stmt.setLong(21, _entity.contentOwner)
        _stmt.setLong(22, _entity.contentEntryLocalChangeSeqNum)
        _stmt.setLong(23, _entity.contentEntryMasterChangeSeqNum)
        _stmt.setInt(24, _entity.contentEntryLastChangedBy)
        _stmt.setLong(25, _entity.contentEntryLct)
        _stmt.setLong(26, _entity.contentEntryUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }
}
