package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class LeavingReasonDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: LeavingReasonDao
) : LeavingReasonDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findAllReasons(): DoorDataSourceFactory<Int, LeavingReason> =
      _dao.findAllReasons()

  public override fun findAllReasonsLive(): List<LeavingReason> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByUidAsync(uid: Long): LeavingReason? = _dao.findByUidAsync(uid)

  public override suspend fun findByUidList(uidList: List<Long>): List<Long> =
      _dao.findByUidList(uidList)

  public override fun findByUidLive(uid: Long): DoorLiveData<LeavingReason?> =
      _dao.findByUidLive(uid)

  public override suspend fun getReasonsFromUids(uidList: List<Long>): List<UidAndLabel> =
      _dao.getReasonsFromUids(uidList)

  public override suspend fun replaceList(entityList: List<LeavingReason>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.leavingReasonUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(410)
        it.leavingReasonUid = _newPk
        _generatedPks += _newPk
      }
      it.leavingReasonLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceList(entityList)
  }

  public override suspend fun updateAsync(entity: LeavingReason): Int {
    entity.leavingReasonLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override fun insert(entity: LeavingReason): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: LeavingReason): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.leavingReasonUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(410)
      entity.leavingReasonUid = _newPk
    }
    entity.leavingReasonLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.leavingReasonUid
  }

  public override fun insertList(entityList: List<out LeavingReason>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out LeavingReason>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: LeavingReason): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
