package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeAndVersionHeaders
import com.ustadmobile.door.ext.getOrNull
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import com.ustadmobile.lib.db.entities.SchoolWithMemberCountAndLocation
import io.ktor.client.HttpClient
import io.ktor.client.request.header
import io.ktor.client.request.parameter
import io.ktor.http.takeFrom
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class SchoolDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: SchoolDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : SchoolDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByUidAsync(schoolUid: Long): School? {
    val _result = _dao.findByUidAsync(schoolUid)
    return _result
  }

  public override suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      SchoolWithHolidayCalendar? {
    val _result = _dao.findByUidWithHolidayCalendarAsync(uid)
    return _result
  }

  public override suspend fun findBySchoolCode(code: String): School? {
    val _result = _dao.findBySchoolCode(code)
    return _result
  }

  public override suspend fun findBySchoolCodeFromWeb(code: String): School? =
      _httpClient.getOrNull<School?> {
    url {
      takeFrom(_endpoint)
      encodedPath = "${encodedPath}SchoolDao/findBySchoolCodeFromWeb"
    }
    doorNodeAndVersionHeaders(_repo)
    header("x-nid", _clientId)
    parameter("code", code)

  }

  public override suspend fun personHasPermissionWithSchool(
    accountPersonUid: Long,
    schoolUid: Long,
    permission: Long
  ): Boolean {
    val _result = _dao.personHasPermissionWithSchool(accountPersonUid, schoolUid, permission)
    return _result
  }

  public override fun findAllActiveSchoolWithMemberCountAndLocationName(
    searchBit: String,
    personUid: Long,
    permission: Long,
    sortOrder: Int
  ): DoorDataSourceFactory<Int, SchoolWithMemberCountAndLocation> {
    val _result = _dao.findAllActiveSchoolWithMemberCountAndLocationName(searchBit, personUid,
        permission, sortOrder)
    return _result
  }

  public override suspend fun updateAsync(entity: School): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override fun insert(entity: School): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: School): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out School>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out School>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: School): Unit {
    _dao.update(entity)
  }
}
