package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Message
import com.ustadmobile.lib.db.entities.MessageRead
import com.ustadmobile.lib.db.entities.MessageWithPerson
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class MessageDao_JdbcKt(
  public val _db: DoorDatabase
) : MessageDao() {
  public val _insertAdapterMessage_: EntityInsertionAdapter<Message> = object :
      EntityInsertionAdapter<Message>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Message (messageUid, messageSenderPersonUid, messageTableId, messageEntityUid, messageText, messageTimestamp, messageClazzUid, messageLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Message (messageUid, messageSenderPersonUid, messageTableId, messageEntityUid, messageText, messageTimestamp, messageClazzUid, messageLct) VALUES(COALESCE(?,nextval('Message_messageUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING messageUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Message): Unit {
      if(entity.messageUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.messageUid)
      }
      stmt.setLong(2, entity.messageSenderPersonUid)
      stmt.setInt(3, entity.messageTableId)
      stmt.setLong(4, entity.messageEntityUid)
      stmt.setString(5, entity.messageText)
      stmt.setLong(6, entity.messageTimestamp)
      stmt.setLong(7, entity.messageClazzUid)
      stmt.setLong(8, entity.messageLct)
    }
  }

  public override suspend fun replicateOnNewNodeChats(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO MessageReplicate(messagePk, messageDestination)
    |      SELECT DISTINCT Message.messageUid AS messagePk,
    |             ? AS messageDestination
    |        FROM UserSession
    |             JOIN Message ON
    |                  ((    Message.messageTableId = 127
    |                    AND Message.messageEntityUid IN
    |                        (SELECT ChatMember.chatMemberChatUid 
    |                          FROM ChatMember
    |                         WHERE ChatMember.chatMemberPersonUid = UserSession.usPersonUid))
    |                  OR UserSession.usSessionType = 2)
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Message.messageLct != COALESCE(
    |             (SELECT messageVersionId
    |                FROM MessageReplicate
    |               WHERE messagePk = Message.messageUid
    |                 AND messageDestination = ?), 0) 
    |      /*psql ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReplicate(messagePk, messageDestination)
    |      SELECT DISTINCT Message.messageUid AS messagePk,
    |             ? AS messageDestination
    |        FROM UserSession
    |             JOIN Message ON
    |                  ((    Message.messageTableId = 127
    |                    AND Message.messageEntityUid IN
    |                        (SELECT ChatMember.chatMemberChatUid 
    |                          FROM ChatMember
    |                         WHERE ChatMember.chatMemberPersonUid = UserSession.usPersonUid))
    |                  OR UserSession.usSessionType = 2)
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Message.messageLct != COALESCE(
    |             (SELECT messageVersionId
    |                FROM MessageReplicate
    |               WHERE messagePk = Message.messageUid
    |                 AND messageDestination = ?), 0) 
    |       ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodePosts(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO MessageReplicate(messagePk, messageDestination)
    |      SELECT DISTINCT Message.messageUid AS messagePk,
    |             ? AS messageDestination
    |        FROM UserSession
    |        
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |            
    |             JOIN Message 
    |                  ON Message.messageTableId = 132
    |                  AND Message.messageClazzUid = Clazz.clazzUid
    |            
    |            
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Message.messageLct != COALESCE(
    |             (SELECT messageVersionId
    |                FROM MessageReplicate
    |               WHERE messagePk = Message.messageUid
    |                 AND messageDestination = ?), 0) 
    |      /*psql ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReplicate(messagePk, messageDestination)
    |      SELECT DISTINCT Message.messageUid AS messagePk,
    |             ? AS messageDestination
    |        FROM UserSession
    |        
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |            
    |             JOIN Message 
    |                  ON Message.messageTableId = 132
    |                  AND Message.messageClazzUid = Clazz.clazzUid
    |            
    |            
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Message.messageLct != COALESCE(
    |             (SELECT messageVersionId
    |                FROM MessageReplicate
    |               WHERE messagePk = Message.messageUid
    |                 AND messageDestination = ?), 0) 
    |       ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeChat(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         REPLACE INTO MessageReplicate(messagePk, messageDestination)
    |          SELECT DISTINCT Message.messageUid AS messageUid,
    |                 UserSession.usClientNodeId AS messageDestination
    |            FROM ChangeLog
    |                 JOIN Message
    |                     ON ChangeLog.chTableId = 126
    |                        AND ChangeLog.chEntityPk = Message.messageUid
    |                        AND Message.messageTableId = 127
    |                 JOIN UserSession ON
    |                      ((UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid
    |                              FROM ChatMember
    |                             WHERE ChatMember.chatMemberChatUid = Message.messageEntityUid))
    |                       OR UserSession.usSessionType = 2)       
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Message.messageLct != COALESCE(
    |                 (SELECT messageVersionId
    |                    FROM MessageReplicate
    |                   WHERE messagePk = Message.messageUid
    |                     AND messageDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReplicate(messagePk, messageDestination)
    |          SELECT DISTINCT Message.messageUid AS messageUid,
    |                 UserSession.usClientNodeId AS messageDestination
    |            FROM ChangeLog
    |                 JOIN Message
    |                     ON ChangeLog.chTableId = 126
    |                        AND ChangeLog.chEntityPk = Message.messageUid
    |                        AND Message.messageTableId = 127
    |                 JOIN UserSession ON
    |                      ((UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid
    |                              FROM ChatMember
    |                             WHERE ChatMember.chatMemberChatUid = Message.messageEntityUid))
    |                       OR UserSession.usSessionType = 2)       
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Message.messageLct != COALESCE(
    |                 (SELECT messageVersionId
    |                    FROM MessageReplicate
    |                   WHERE messagePk = Message.messageUid
    |                     AND messageDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangePosts(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         REPLACE INTO MessageReplicate(messagePk, messageDestination)
    |          SELECT DISTINCT Message.messageUid AS messageUid,
    |                 UserSession.usClientNodeId AS messageDestination
    |            FROM ChangeLog
    |            
    |                 JOIN Message
    |                     ON ChangeLog.chTableId = 126
    |                        AND ChangeLog.chEntityPk = Message.messageUid
    |                        AND Message.messageTableId = 132
    |                        
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = Message.messageClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |            
    |                       
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Message.messageLct != COALESCE(
    |                 (SELECT messageVersionId
    |                    FROM MessageReplicate
    |                   WHERE messagePk = Message.messageUid
    |                     AND messageDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO MessageReplicate(messagePk, messageDestination)
    |          SELECT DISTINCT Message.messageUid AS messageUid,
    |                 UserSession.usClientNodeId AS messageDestination
    |            FROM ChangeLog
    |            
    |                 JOIN Message
    |                     ON ChangeLog.chTableId = 126
    |                        AND ChangeLog.chEntityPk = Message.messageUid
    |                        AND Message.messageTableId = 132
    |                        
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = Message.messageClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |            
    |                       
    |           WHERE UserSession.usStatus = 1
    |             AND UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND Message.messageLct != COALESCE(
    |                 (SELECT messageVersionId
    |                    FROM MessageReplicate
    |                   WHERE messagePk = Message.messageUid
    |                     AND messageDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(messagePk, messageDestination) DO UPDATE
    |             SET messagePending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllMessagesByChatUid(
    entityUid: Long,
    tableId: Int,
    loggedInPersonUid: Long
  ): DoorDataSourceFactory<Int, MessageWithPerson> {
    val _result = object : DoorDataSourceFactory<Int, MessageWithPerson>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<MessageWithPerson>>
          = DoorLiveDataImpl<List<MessageWithPerson>>(_db, listOf("Message", "Person",
          "MessageRead"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.MessageWithPerson>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |       SELECT
        |              Message.*,
        |              Person.*,
        |              MessageRead.*
        |        FROM Message
        |        LEFT JOIN Person
        |          ON Message.messageSenderPersonUid = Person.personUid
        |        LEFT JOIN MessageRead
        |          ON MessageRead.messageReadMessageUid = Message.messageUid
        |             AND MessageRead.messageReadPersonUid = ?
        |       WHERE Message.messageTableId = ?
        |              AND Message.messageEntityUid = ?
        |    ORDER BY Message.messageTimestamp DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |       SELECT
        |              Message.*,
        |              Person.*,
        |              MessageRead.*
        |        FROM Message
        |        LEFT JOIN Person
        |          ON Message.messageSenderPersonUid = Person.personUid
        |        LEFT JOIN MessageRead
        |          ON MessageRead.messageReadMessageUid = Message.messageUid
        |             AND MessageRead.messageReadPersonUid = ?
        |       WHERE Message.messageTableId = ?
        |              AND Message.messageEntityUid = ?
        |    ORDER BY Message.messageTimestamp DESC
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, loggedInPersonUid)
          _stmt.setInt(2, tableId)
          _stmt.setLong(3, entityUid)
          _stmt.setInt(4, _limit)
          _stmt.setInt(5, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_messageUid = _resultSet.getLong("messageUid")
              val tmp_messageSenderPersonUid = _resultSet.getLong("messageSenderPersonUid")
              val tmp_messageTableId = _resultSet.getInt("messageTableId")
              val tmp_messageEntityUid = _resultSet.getLong("messageEntityUid")
              val tmp_messageText = _resultSet.getString("messageText")
              val tmp_messageTimestamp = _resultSet.getLong("messageTimestamp")
              val tmp_messageClazzUid = _resultSet.getLong("messageClazzUid")
              val tmp_messageLct = _resultSet.getLong("messageLct")
              val _entity = MessageWithPerson()
              _entity.messageUid = tmp_messageUid
              _entity.messageSenderPersonUid = tmp_messageSenderPersonUid
              _entity.messageTableId = tmp_messageTableId
              _entity.messageEntityUid = tmp_messageEntityUid
              _entity.messageText = tmp_messageText
              _entity.messageTimestamp = tmp_messageTimestamp
              _entity.messageClazzUid = tmp_messageClazzUid
              _entity.messageLct = tmp_messageLct
              var _messagePerson_nullFieldCount = 0
              val tmp_personUid = _resultSet.getLong("personUid")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_username = _resultSet.getString("username")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_firstNames = _resultSet.getString("firstNames")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_lastName = _resultSet.getString("lastName")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_gender = _resultSet.getInt("gender")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_active = _resultSet.getBoolean("active")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_admin = _resultSet.getBoolean("admin")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personNotes = _resultSet.getString("personNotes")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_fatherName = _resultSet.getString("fatherName")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_motherName = _resultSet.getString("motherName")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_motherNum = _resultSet.getString("motherNum")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personAddress = _resultSet.getString("personAddress")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personLct = _resultSet.getLong("personLct")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personCountry = _resultSet.getString("personCountry")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              val tmp_personType = _resultSet.getInt("personType")
              if(_resultSet.wasNull()) { _messagePerson_nullFieldCount++ }
              if(_messagePerson_nullFieldCount < 24) {
                if(_entity.messagePerson == null) {
                  _entity.messagePerson = Person()
                }
                _entity.messagePerson!!.personUid = tmp_personUid
                _entity.messagePerson!!.username = tmp_username
                _entity.messagePerson!!.firstNames = tmp_firstNames
                _entity.messagePerson!!.lastName = tmp_lastName
                _entity.messagePerson!!.emailAddr = tmp_emailAddr
                _entity.messagePerson!!.phoneNum = tmp_phoneNum
                _entity.messagePerson!!.gender = tmp_gender
                _entity.messagePerson!!.active = tmp_active
                _entity.messagePerson!!.admin = tmp_admin
                _entity.messagePerson!!.personNotes = tmp_personNotes
                _entity.messagePerson!!.fatherName = tmp_fatherName
                _entity.messagePerson!!.fatherNumber = tmp_fatherNumber
                _entity.messagePerson!!.motherName = tmp_motherName
                _entity.messagePerson!!.motherNum = tmp_motherNum
                _entity.messagePerson!!.dateOfBirth = tmp_dateOfBirth
                _entity.messagePerson!!.personAddress = tmp_personAddress
                _entity.messagePerson!!.personOrgId = tmp_personOrgId
                _entity.messagePerson!!.personGroupUid = tmp_personGroupUid
                _entity.messagePerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                _entity.messagePerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                _entity.messagePerson!!.personLastChangedBy = tmp_personLastChangedBy
                _entity.messagePerson!!.personLct = tmp_personLct
                _entity.messagePerson!!.personCountry = tmp_personCountry
                _entity.messagePerson!!.personType = tmp_personType
              }
              var _messageRead_nullFieldCount = 0
              val tmp_messageReadUid = _resultSet.getLong("messageReadUid")
              if(_resultSet.wasNull()) { _messageRead_nullFieldCount++ }
              val tmp_messageReadPersonUid = _resultSet.getLong("messageReadPersonUid")
              if(_resultSet.wasNull()) { _messageRead_nullFieldCount++ }
              val tmp_messageReadMessageUid = _resultSet.getLong("messageReadMessageUid")
              if(_resultSet.wasNull()) { _messageRead_nullFieldCount++ }
              val tmp_messageReadEntityUid = _resultSet.getLong("messageReadEntityUid")
              if(_resultSet.wasNull()) { _messageRead_nullFieldCount++ }
              val tmp_messageReadLct = _resultSet.getLong("messageReadLct")
              if(_resultSet.wasNull()) { _messageRead_nullFieldCount++ }
              if(_messageRead_nullFieldCount < 5) {
                if(_entity.messageRead == null) {
                  _entity.messageRead = MessageRead()
                }
                _entity.messageRead!!.messageReadUid = tmp_messageReadUid
                _entity.messageRead!!.messageReadPersonUid = tmp_messageReadPersonUid
                _entity.messageRead!!.messageReadMessageUid = tmp_messageReadMessageUid
                _entity.messageRead!!.messageReadEntityUid = tmp_messageReadEntityUid
                _entity.messageRead!!.messageReadLct = tmp_messageReadLct
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Message", "Person", "MessageRead"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |       SELECT
        |              Message.*,
        |              Person.*,
        |              MessageRead.*
        |        FROM Message
        |        LEFT JOIN Person
        |          ON Message.messageSenderPersonUid = Person.personUid
        |        LEFT JOIN MessageRead
        |          ON MessageRead.messageReadMessageUid = Message.messageUid
        |             AND MessageRead.messageReadPersonUid = ?
        |       WHERE Message.messageTableId = ?
        |              AND Message.messageEntityUid = ?
        |    ORDER BY Message.messageTimestamp DESC
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |       SELECT
        |              Message.*,
        |              Person.*,
        |              MessageRead.*
        |        FROM Message
        |        LEFT JOIN Person
        |          ON Message.messageSenderPersonUid = Person.personUid
        |        LEFT JOIN MessageRead
        |          ON MessageRead.messageReadMessageUid = Message.messageUid
        |             AND MessageRead.messageReadPersonUid = ?
        |       WHERE Message.messageTableId = ?
        |              AND Message.messageEntityUid = ?
        |    ORDER BY Message.messageTimestamp DESC
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, loggedInPersonUid)
          _stmt.setInt(2, tableId)
          _stmt.setLong(3, entityUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun insert(entity: Message): Long {
    val _retVal = _insertAdapterMessage_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Message): Long {
    val _retVal = _insertAdapterMessage_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Message>): Unit {
    _insertAdapterMessage_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Message>): Unit {
    val _sql =
        "UPDATE Message SET messageSenderPersonUid = ?, messageTableId = ?, messageEntityUid = ?, messageText = ?, messageTimestamp = ?, messageClazzUid = ?, messageLct = ? WHERE messageUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.messageSenderPersonUid)
        _stmt.setInt(2, _entity.messageTableId)
        _stmt.setLong(3, _entity.messageEntityUid)
        _stmt.setString(4, _entity.messageText)
        _stmt.setLong(5, _entity.messageTimestamp)
        _stmt.setLong(6, _entity.messageClazzUid)
        _stmt.setLong(7, _entity.messageLct)
        _stmt.setLong(8, _entity.messageUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Message): Unit {
    val _sql =
        "UPDATE Message SET messageSenderPersonUid = ?, messageTableId = ?, messageEntityUid = ?, messageText = ?, messageTimestamp = ?, messageClazzUid = ?, messageLct = ? WHERE messageUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.messageSenderPersonUid)
      _stmt.setInt(2, entity.messageTableId)
      _stmt.setLong(3, entity.messageEntityUid)
      _stmt.setString(4, entity.messageText)
      _stmt.setLong(5, entity.messageTimestamp)
      _stmt.setLong(6, entity.messageClazzUid)
      _stmt.setLong(7, entity.messageLct)
      _stmt.setLong(8, entity.messageUid)
      _stmt.executeUpdate()
    }
  }
}
