package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ConnectivityStatus
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit

public class ConnectivityStatusDao_JdbcKt(
  public val _db: DoorDatabase
) : ConnectivityStatusDao() {
  public val _insertAdapterConnectivityStatus_upsert: EntityInsertionAdapter<ConnectivityStatus> =
      object : EntityInsertionAdapter<ConnectivityStatus>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO ConnectivityStatus (csUid, connectivityState, wifiSsid, connectedOrConnecting) VALUES(?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ConnectivityStatus (csUid, connectivityState, wifiSsid, connectedOrConnecting) VALUES(?, ?, ?, ?) ON CONFLICT (csUid) DO UPDATE SET connectivityState = excluded.connectivityState,wifiSsid = excluded.wifiSsid,connectedOrConnecting = excluded.connectedOrConnecting" + if(returnsId) { " RETURNING csUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ConnectivityStatus): Unit {
      stmt.setInt(1, entity.csUid)
      stmt.setInt(2, entity.connectivityState)
      stmt.setString(3, entity.wifiSsid)
      stmt.setBoolean(4, entity.connectedOrConnecting)
    }
  }

  public override fun statusLive(): DoorLiveData<ConnectivityStatus?> {
    val _result = DoorLiveDataImpl<ConnectivityStatus?>(_db, listOf("ConnectivityStatus"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ConnectivityStatus??
      val _stmtConfig =
          PreparedStatementConfig("SELECT ConnectivityStatus.* FROM ConnectivityStatus LIMIT 1" ,
          postgreSql = """
      |SELECT ConnectivityStatus.* FROM ConnectivityStatus LIMIT 1
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_csUid = _resultSet.getInt("csUid")
            val tmp_connectivityState = _resultSet.getInt("connectivityState")
            val tmp_wifiSsid = _resultSet.getString("wifiSsid")
            val tmp_connectedOrConnecting = _resultSet.getBoolean("connectedOrConnecting")
            val _entity = ConnectivityStatus()
            _entity.csUid = tmp_csUid
            _entity.connectivityState = tmp_connectivityState
            _entity.wifiSsid = tmp_wifiSsid
            _entity.connectedOrConnecting = tmp_connectedOrConnecting
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun status(): ConnectivityStatus? {
    var _result = null as com.ustadmobile.lib.db.entities.ConnectivityStatus??
    val _stmtConfig =
        PreparedStatementConfig("SELECT ConnectivityStatus.* FROM ConnectivityStatus LIMIT 1" ,
        postgreSql = """
    |SELECT ConnectivityStatus.* FROM ConnectivityStatus LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_csUid = _resultSet.getInt("csUid")
          val tmp_connectivityState = _resultSet.getInt("connectivityState")
          val tmp_wifiSsid = _resultSet.getString("wifiSsid")
          val tmp_connectedOrConnecting = _resultSet.getBoolean("connectedOrConnecting")
          val _entity = ConnectivityStatus()
          _entity.csUid = tmp_csUid
          _entity.connectivityState = tmp_connectivityState
          _entity.wifiSsid = tmp_wifiSsid
          _entity.connectedOrConnecting = tmp_connectedOrConnecting
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(connectivityStatus: ConnectivityStatus): Long {
    val _retVal = _insertAdapterConnectivityStatus_upsert.insertAndReturnId(connectivityStatus)
    return _retVal
  }

  public override suspend fun insertAsync(connectivityStatus: ConnectivityStatus): Long {
    val _retVal = _insertAdapterConnectivityStatus_upsert.insertAndReturnIdAsync(connectivityStatus)
    return _retVal
  }

  public override suspend fun updateStateAsync(connectivityState: Int): Unit {
    val _stmtConfig = PreparedStatementConfig("UPDATE ConnectivityStatus SET connectivityState = ?"
        , postgreSql = """
    |UPDATE ConnectivityStatus SET connectivityState = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, connectivityState)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateState(connectivityState: Int, wifiSsid: String): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ConnectivityStatus SET connectivityState = ? , wifiSsid = ?"
        , postgreSql = """
    |UPDATE ConnectivityStatus SET connectivityState = ? , wifiSsid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, connectivityState)
      _stmt.setString(2, wifiSsid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun updateStateSync(connectivityState: Int, wifiSsid: String): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ConnectivityStatus SET connectivityState = ? , wifiSsid = ?"
        , postgreSql = """
    |UPDATE ConnectivityStatus SET connectivityState = ? , wifiSsid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, connectivityState)
      _stmt.setString(2, wifiSsid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }
}
