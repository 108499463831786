package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.PersonPicture
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class PersonPictureDao_JdbcKt(
  public val _db: DoorDatabase
) : PersonPictureDao() {
  public val _insertAdapterPersonPicture_: EntityInsertionAdapter<PersonPicture> = object :
      EntityInsertionAdapter<PersonPicture>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO PersonPicture (personPictureUid, personPicturePersonUid, personPictureMasterCsn, personPictureLocalCsn, personPictureLastChangedBy, personPictureLct, personPictureUri, personPictureMd5, fileSize, picTimestamp, mimeType, personPictureActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO PersonPicture (personPictureUid, personPicturePersonUid, personPictureMasterCsn, personPictureLocalCsn, personPictureLastChangedBy, personPictureLct, personPictureUri, personPictureMd5, fileSize, picTimestamp, mimeType, personPictureActive) VALUES(COALESCE(?,nextval('PersonPicture_personPictureUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING personPictureUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonPicture):
        Unit {
      if(entity.personPictureUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personPictureUid)
      }
      stmt.setLong(2, entity.personPicturePersonUid)
      stmt.setLong(3, entity.personPictureMasterCsn)
      stmt.setLong(4, entity.personPictureLocalCsn)
      stmt.setInt(5, entity.personPictureLastChangedBy)
      stmt.setLong(6, entity.personPictureLct)
      stmt.setString(7, entity.personPictureUri)
      stmt.setString(8, entity.personPictureMd5)
      stmt.setInt(9, entity.fileSize)
      stmt.setLong(10, entity.picTimestamp)
      stmt.setString(11, entity.mimeType)
      stmt.setBoolean(12, entity.personPictureActive)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO PersonPictureReplicate(ppPk, ppDestination)
    |      SELECT DISTINCT PersonPicture.personPictureUid AS ppPk,
    |             ? AS ppDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  1048576
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonPicture
    |                  ON PersonPicture.personPicturePersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonPicture.personPictureLct != COALESCE(
    |             (SELECT ppVersionId
    |                FROM PersonPictureReplicate
    |               WHERE ppPk = PersonPicture.personPictureUid
    |                 AND ppDestination = ?), 0) 
    |      /*psql ON CONFLICT(ppPk, ppDestination) DO UPDATE
    |             SET ppPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonPictureReplicate(ppPk, ppDestination)
    |      SELECT DISTINCT PersonPicture.personPictureUid AS ppPk,
    |             ? AS ppDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  1048576
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonPicture
    |                  ON PersonPicture.personPicturePersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonPicture.personPictureLct != COALESCE(
    |             (SELECT ppVersionId
    |                FROM PersonPictureReplicate
    |               WHERE ppPk = PersonPicture.personPictureUid
    |                 AND ppDestination = ?), 0) 
    |       ON CONFLICT(ppPk, ppDestination) DO UPDATE
    |             SET ppPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonPictureReplicate(ppPk, ppDestination)
    |  SELECT DISTINCT PersonPicture.personPictureUid AS ppUid,
    |         UserSession.usClientNodeId AS ppDestination
    |    FROM ChangeLog
    |         JOIN PersonPicture
    |              ON ChangeLog.chTableId = 50
    |                 AND ChangeLog.chEntityPk = PersonPicture.personPictureUid
    |         JOIN Person
    |              ON Person.personUid = PersonPicture.personPicturePersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              1048576
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND PersonPicture.personPictureLct != COALESCE(
    |         (SELECT ppVersionId
    |            FROM PersonPictureReplicate
    |           WHERE ppPk = PersonPicture.personPictureUid
    |             AND ppDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ppPk, ppDestination) DO UPDATE
    |     SET ppPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonPictureReplicate(ppPk, ppDestination)
    |  SELECT DISTINCT PersonPicture.personPictureUid AS ppUid,
    |         UserSession.usClientNodeId AS ppDestination
    |    FROM ChangeLog
    |         JOIN PersonPicture
    |              ON ChangeLog.chTableId = 50
    |                 AND ChangeLog.chEntityPk = PersonPicture.personPictureUid
    |         JOIN Person
    |              ON Person.personUid = PersonPicture.personPicturePersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              1048576
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND PersonPicture.personPictureLct != COALESCE(
    |         (SELECT ppVersionId
    |            FROM PersonPictureReplicate
    |           WHERE ppPk = PersonPicture.personPictureUid
    |             AND ppDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ppPk, ppDestination) DO UPDATE
    |     SET ppPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByPersonUidAsync(personUid: Long): PersonPicture? {
    var _result = null as com.ustadmobile.lib.db.entities.PersonPicture??
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT * FROM PersonPicture 
    |        WHERE personPicturePersonUid = ?
    |        AND CAST(personPictureActive AS INTEGER) = 1
    |        ORDER BY picTimestamp DESC LIMIT 1
    """.trimMargin() , postgreSql = """
    |SELECT * FROM PersonPicture 
    |        WHERE personPicturePersonUid = ?
    |        AND CAST(personPictureActive AS INTEGER) = 1
    |        ORDER BY picTimestamp DESC LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, personUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personPictureUid = _resultSet.getLong("personPictureUid")
          val tmp_personPicturePersonUid = _resultSet.getLong("personPicturePersonUid")
          val tmp_personPictureMasterCsn = _resultSet.getLong("personPictureMasterCsn")
          val tmp_personPictureLocalCsn = _resultSet.getLong("personPictureLocalCsn")
          val tmp_personPictureLastChangedBy = _resultSet.getInt("personPictureLastChangedBy")
          val tmp_personPictureLct = _resultSet.getLong("personPictureLct")
          val tmp_personPictureUri = _resultSet.getString("personPictureUri")
          val tmp_personPictureMd5 = _resultSet.getString("personPictureMd5")
          val tmp_fileSize = _resultSet.getInt("fileSize")
          val tmp_picTimestamp = _resultSet.getLong("picTimestamp")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_personPictureActive = _resultSet.getBoolean("personPictureActive")
          val _entity = PersonPicture()
          _entity.personPictureUid = tmp_personPictureUid
          _entity.personPicturePersonUid = tmp_personPicturePersonUid
          _entity.personPictureMasterCsn = tmp_personPictureMasterCsn
          _entity.personPictureLocalCsn = tmp_personPictureLocalCsn
          _entity.personPictureLastChangedBy = tmp_personPictureLastChangedBy
          _entity.personPictureLct = tmp_personPictureLct
          _entity.personPictureUri = tmp_personPictureUri
          _entity.personPictureMd5 = tmp_personPictureMd5
          _entity.fileSize = tmp_fileSize
          _entity.picTimestamp = tmp_picTimestamp
          _entity.mimeType = tmp_mimeType
          _entity.personPictureActive = tmp_personPictureActive
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByPersonUidLive(personUid: Long): DoorLiveData<PersonPicture?> {
    val _result = DoorLiveDataImpl<PersonPicture?>(_db, listOf("PersonPicture"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.PersonPicture??
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM PersonPicture where personPicturePersonUid = ? ORDER BY  picTimestamp DESC LIMIT 1"
          , postgreSql = """
      |SELECT * FROM PersonPicture where personPicturePersonUid = ? ORDER BY  picTimestamp DESC LIMIT 1
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, personUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_personPictureUid = _resultSet.getLong("personPictureUid")
            val tmp_personPicturePersonUid = _resultSet.getLong("personPicturePersonUid")
            val tmp_personPictureMasterCsn = _resultSet.getLong("personPictureMasterCsn")
            val tmp_personPictureLocalCsn = _resultSet.getLong("personPictureLocalCsn")
            val tmp_personPictureLastChangedBy = _resultSet.getInt("personPictureLastChangedBy")
            val tmp_personPictureLct = _resultSet.getLong("personPictureLct")
            val tmp_personPictureUri = _resultSet.getString("personPictureUri")
            val tmp_personPictureMd5 = _resultSet.getString("personPictureMd5")
            val tmp_fileSize = _resultSet.getInt("fileSize")
            val tmp_picTimestamp = _resultSet.getLong("picTimestamp")
            val tmp_mimeType = _resultSet.getString("mimeType")
            val tmp_personPictureActive = _resultSet.getBoolean("personPictureActive")
            val _entity = PersonPicture()
            _entity.personPictureUid = tmp_personPictureUid
            _entity.personPicturePersonUid = tmp_personPicturePersonUid
            _entity.personPictureMasterCsn = tmp_personPictureMasterCsn
            _entity.personPictureLocalCsn = tmp_personPictureLocalCsn
            _entity.personPictureLastChangedBy = tmp_personPictureLastChangedBy
            _entity.personPictureLct = tmp_personPictureLct
            _entity.personPictureUri = tmp_personPictureUri
            _entity.personPictureMd5 = tmp_personPictureMd5
            _entity.fileSize = tmp_fileSize
            _entity.picTimestamp = tmp_picTimestamp
            _entity.mimeType = tmp_mimeType
            _entity.personPictureActive = tmp_personPictureActive
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateAsync(personPicture: PersonPicture): Unit {
    val _sql =
        "UPDATE PersonPicture SET personPicturePersonUid = ?, personPictureMasterCsn = ?, personPictureLocalCsn = ?, personPictureLastChangedBy = ?, personPictureLct = ?, personPictureUri = ?, personPictureMd5 = ?, fileSize = ?, picTimestamp = ?, mimeType = ?, personPictureActive = ? WHERE personPictureUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, personPicture.personPicturePersonUid)
      _stmt.setLong(2, personPicture.personPictureMasterCsn)
      _stmt.setLong(3, personPicture.personPictureLocalCsn)
      _stmt.setInt(4, personPicture.personPictureLastChangedBy)
      _stmt.setLong(5, personPicture.personPictureLct)
      _stmt.setString(6, personPicture.personPictureUri)
      _stmt.setString(7, personPicture.personPictureMd5)
      _stmt.setInt(8, personPicture.fileSize)
      _stmt.setLong(9, personPicture.picTimestamp)
      _stmt.setString(10, personPicture.mimeType)
      _stmt.setBoolean(11, personPicture.personPictureActive)
      _stmt.setLong(12, personPicture.personPictureUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: PersonPicture): Long {
    val _retVal = _insertAdapterPersonPicture_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: PersonPicture): Long {
    val _retVal = _insertAdapterPersonPicture_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out PersonPicture>): Unit {
    _insertAdapterPersonPicture_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out PersonPicture>): Unit {
    val _sql =
        "UPDATE PersonPicture SET personPicturePersonUid = ?, personPictureMasterCsn = ?, personPictureLocalCsn = ?, personPictureLastChangedBy = ?, personPictureLct = ?, personPictureUri = ?, personPictureMd5 = ?, fileSize = ?, picTimestamp = ?, mimeType = ?, personPictureActive = ? WHERE personPictureUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.personPicturePersonUid)
        _stmt.setLong(2, _entity.personPictureMasterCsn)
        _stmt.setLong(3, _entity.personPictureLocalCsn)
        _stmt.setInt(4, _entity.personPictureLastChangedBy)
        _stmt.setLong(5, _entity.personPictureLct)
        _stmt.setString(6, _entity.personPictureUri)
        _stmt.setString(7, _entity.personPictureMd5)
        _stmt.setInt(8, _entity.fileSize)
        _stmt.setLong(9, _entity.picTimestamp)
        _stmt.setString(10, _entity.mimeType)
        _stmt.setBoolean(11, _entity.personPictureActive)
        _stmt.setLong(12, _entity.personPictureUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: PersonPicture): Unit {
    val _sql =
        "UPDATE PersonPicture SET personPicturePersonUid = ?, personPictureMasterCsn = ?, personPictureLocalCsn = ?, personPictureLastChangedBy = ?, personPictureLct = ?, personPictureUri = ?, personPictureMd5 = ?, fileSize = ?, picTimestamp = ?, mimeType = ?, personPictureActive = ? WHERE personPictureUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.personPicturePersonUid)
      _stmt.setLong(2, entity.personPictureMasterCsn)
      _stmt.setLong(3, entity.personPictureLocalCsn)
      _stmt.setInt(4, entity.personPictureLastChangedBy)
      _stmt.setLong(5, entity.personPictureLct)
      _stmt.setString(6, entity.personPictureUri)
      _stmt.setString(7, entity.personPictureMd5)
      _stmt.setInt(8, entity.fileSize)
      _stmt.setLong(9, entity.picTimestamp)
      _stmt.setString(10, entity.mimeType)
      _stmt.setBoolean(11, entity.personPictureActive)
      _stmt.setLong(12, entity.personPictureUid)
      _stmt.executeUpdate()
    }
  }
}
