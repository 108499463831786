package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Site
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit

public class SiteDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: SiteDao
) : SiteDao() {
  public override fun getSite(): Site? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun getSiteAsync(): Site? = _dao.getSiteAsync()

  public override suspend fun replaceAsync(site: Site): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(site.siteUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(189)
      site.siteUid = _newPk
    }
    site.siteLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.replaceAsync(site)
    return site.siteUid
  }

  public override fun insert(site: Site): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun updateAsync(workspace: Site): Unit {
    workspace.siteLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(workspace)
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }
}
