package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ClazzLog
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzLogDao_JdbcKt(
  public val _db: DoorDatabase
) : ClazzLogDao() {
  public val _insertAdapterClazzLog_upsert: EntityInsertionAdapter<ClazzLog> = object :
      EntityInsertionAdapter<ClazzLog>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO ClazzLog (clazzLogUid, clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ClazzLog (clazzLogUid, clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime) VALUES(COALESCE(?,nextval('ClazzLog_clazzLogUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (clazzLogUid) DO UPDATE SET clazzLogClazzUid = excluded.clazzLogClazzUid,logDate = excluded.logDate,timeRecorded = excluded.timeRecorded,clazzLogDone = excluded.clazzLogDone,cancellationNote = excluded.cancellationNote,clazzLogCancelled = excluded.clazzLogCancelled,clazzLogNumPresent = excluded.clazzLogNumPresent,clazzLogNumAbsent = excluded.clazzLogNumAbsent,clazzLogNumPartial = excluded.clazzLogNumPartial,clazzLogScheduleUid = excluded.clazzLogScheduleUid,clazzLogStatusFlag = excluded.clazzLogStatusFlag,clazzLogMSQN = excluded.clazzLogMSQN,clazzLogLCSN = excluded.clazzLogLCSN,clazzLogLCB = excluded.clazzLogLCB,clazzLogLastChangedTime = excluded.clazzLogLastChangedTime" + if(returnsId) { " RETURNING clazzLogUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzLog): Unit {
      if(entity.clazzLogUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzLogUid)
      }
      stmt.setLong(2, entity.clazzLogClazzUid)
      stmt.setLong(3, entity.logDate)
      stmt.setLong(4, entity.timeRecorded)
      stmt.setBoolean(5, entity.clazzLogDone)
      stmt.setString(6, entity.cancellationNote)
      stmt.setBoolean(7, entity.clazzLogCancelled)
      stmt.setInt(8, entity.clazzLogNumPresent)
      stmt.setInt(9, entity.clazzLogNumAbsent)
      stmt.setInt(10, entity.clazzLogNumPartial)
      stmt.setLong(11, entity.clazzLogScheduleUid)
      stmt.setInt(12, entity.clazzLogStatusFlag)
      stmt.setLong(13, entity.clazzLogMSQN)
      stmt.setLong(14, entity.clazzLogLCSN)
      stmt.setInt(15, entity.clazzLogLCB)
      stmt.setLong(16, entity.clazzLogLastChangedTime)
    }
  }

  public val _insertAdapterClazzLog_: EntityInsertionAdapter<ClazzLog> = object :
      EntityInsertionAdapter<ClazzLog>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ClazzLog (clazzLogUid, clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ClazzLog (clazzLogUid, clazzLogClazzUid, logDate, timeRecorded, clazzLogDone, cancellationNote, clazzLogCancelled, clazzLogNumPresent, clazzLogNumAbsent, clazzLogNumPartial, clazzLogScheduleUid, clazzLogStatusFlag, clazzLogMSQN, clazzLogLCSN, clazzLogLCB, clazzLogLastChangedTime) VALUES(COALESCE(?,nextval('ClazzLog_clazzLogUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING clazzLogUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzLog): Unit {
      if(entity.clazzLogUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzLogUid)
      }
      stmt.setLong(2, entity.clazzLogClazzUid)
      stmt.setLong(3, entity.logDate)
      stmt.setLong(4, entity.timeRecorded)
      stmt.setBoolean(5, entity.clazzLogDone)
      stmt.setString(6, entity.cancellationNote)
      stmt.setBoolean(7, entity.clazzLogCancelled)
      stmt.setInt(8, entity.clazzLogNumPresent)
      stmt.setInt(9, entity.clazzLogNumAbsent)
      stmt.setInt(10, entity.clazzLogNumPartial)
      stmt.setLong(11, entity.clazzLogScheduleUid)
      stmt.setInt(12, entity.clazzLogStatusFlag)
      stmt.setLong(13, entity.clazzLogMSQN)
      stmt.setLong(14, entity.clazzLogLCSN)
      stmt.setInt(15, entity.clazzLogLCB)
      stmt.setLong(16, entity.clazzLogLastChangedTime)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzLogReplicate(clPk, clDestination)
    |      SELECT DISTINCT ClazzLog.clazzLogUid AS clUid,
    |             ? AS clDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzLog
    |                  ON ClazzLog.clazzLogClazzUid = Clazz.clazzUid
    |       WHERE ClazzLog.clazzLogLastChangedTime != COALESCE(
    |             (SELECT clVersionId
    |                FROM ClazzLogReplicate
    |               WHERE clPk = ClazzLog.clazzLogUid
    |                 AND clDestination = ?), 0) 
    |      /*psql ON CONFLICT(clPk, clDestination) DO UPDATE
    |             SET clPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzLogReplicate(clPk, clDestination)
    |      SELECT DISTINCT ClazzLog.clazzLogUid AS clUid,
    |             ? AS clDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzLog
    |                  ON ClazzLog.clazzLogClazzUid = Clazz.clazzUid
    |       WHERE ClazzLog.clazzLogLastChangedTime != COALESCE(
    |             (SELECT clVersionId
    |                FROM ClazzLogReplicate
    |               WHERE clPk = ClazzLog.clazzLogUid
    |                 AND clDestination = ?), 0) 
    |       ON CONFLICT(clPk, clDestination) DO UPDATE
    |             SET clPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun replace(entity: ClazzLog): Long {
    val _retVal = _insertAdapterClazzLog_upsert.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzLogReplicate(clPk, clDestination)
    |  SELECT DISTINCT ClazzLog.clazzLogUid AS clUid,
    |         UserSession.usClientNodeId AS clDestination
    |    FROM ChangeLog
    |         JOIN ClazzLog 
    |              ON ChangeLog.chTableId = 14 
    |             AND ClazzLog.clazzLogUid = ChangeLog.chEntityPk
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzLog.clazzLogClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzLog.clazzLogLastChangedTime != COALESCE(
    |         (SELECT clVersionId
    |            FROM ClazzLogReplicate
    |           WHERE clPk = ClazzLog.clazzLogUid
    |             AND clDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(clPk, clDestination) DO UPDATE
    |     SET clPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzLogReplicate(clPk, clDestination)
    |  SELECT DISTINCT ClazzLog.clazzLogUid AS clUid,
    |         UserSession.usClientNodeId AS clDestination
    |    FROM ChangeLog
    |         JOIN ClazzLog 
    |              ON ChangeLog.chTableId = 14 
    |             AND ClazzLog.clazzLogUid = ChangeLog.chEntityPk
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzLog.clazzLogClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzLog.clazzLogLastChangedTime != COALESCE(
    |         (SELECT clVersionId
    |            FROM ClazzLogReplicate
    |           WHERE clPk = ClazzLog.clazzLogUid
    |             AND clDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(clPk, clDestination) DO UPDATE
    |     SET clPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): ClazzLog? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzLog??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM ClazzLog WHERE clazzLogUid = ?" ,
        postgreSql = """
    |SELECT * FROM ClazzLog WHERE clazzLogUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
          val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
          val tmp_logDate = _resultSet.getLong("logDate")
          val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
          val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
          val tmp_cancellationNote = _resultSet.getString("cancellationNote")
          val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
          val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
          val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
          val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
          val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
          val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
          val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
          val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
          val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
          val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
          val _entity = ClazzLog()
          _entity.clazzLogUid = tmp_clazzLogUid
          _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
          _entity.logDate = tmp_logDate
          _entity.timeRecorded = tmp_timeRecorded
          _entity.clazzLogDone = tmp_clazzLogDone
          _entity.cancellationNote = tmp_cancellationNote
          _entity.clazzLogCancelled = tmp_clazzLogCancelled
          _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
          _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
          _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
          _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
          _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
          _entity.clazzLogMSQN = tmp_clazzLogMSQN
          _entity.clazzLogLCSN = tmp_clazzLogLCSN
          _entity.clazzLogLCB = tmp_clazzLogLCB
          _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): ClazzLog? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzLog??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM ClazzLog WHERE clazzLogUid = ?" ,
        postgreSql = """
    |SELECT * FROM ClazzLog WHERE clazzLogUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
          val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
          val tmp_logDate = _resultSet.getLong("logDate")
          val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
          val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
          val tmp_cancellationNote = _resultSet.getString("cancellationNote")
          val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
          val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
          val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
          val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
          val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
          val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
          val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
          val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
          val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
          val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
          val _entity = ClazzLog()
          _entity.clazzLogUid = tmp_clazzLogUid
          _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
          _entity.logDate = tmp_logDate
          _entity.timeRecorded = tmp_timeRecorded
          _entity.clazzLogDone = tmp_clazzLogDone
          _entity.cancellationNote = tmp_cancellationNote
          _entity.clazzLogCancelled = tmp_clazzLogCancelled
          _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
          _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
          _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
          _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
          _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
          _entity.clazzLogMSQN = tmp_clazzLogMSQN
          _entity.clazzLogLCSN = tmp_clazzLogLCSN
          _entity.clazzLogLCB = tmp_clazzLogLCB
          _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<ClazzLog?> {
    val _result = DoorLiveDataImpl<ClazzLog?>(_db, listOf("ClazzLog"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ClazzLog??
      val _stmtConfig = PreparedStatementConfig("SELECT * FROM ClazzLog WHERE clazzLogUid = ?" ,
          postgreSql = """
      |SELECT * FROM ClazzLog WHERE clazzLogUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
            val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
            val tmp_logDate = _resultSet.getLong("logDate")
            val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
            val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
            val tmp_cancellationNote = _resultSet.getString("cancellationNote")
            val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
            val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
            val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
            val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
            val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
            val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
            val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
            val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
            val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
            val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
            val _entity = ClazzLog()
            _entity.clazzLogUid = tmp_clazzLogUid
            _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
            _entity.logDate = tmp_logDate
            _entity.timeRecorded = tmp_timeRecorded
            _entity.clazzLogDone = tmp_clazzLogDone
            _entity.cancellationNote = tmp_cancellationNote
            _entity.clazzLogCancelled = tmp_clazzLogCancelled
            _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
            _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
            _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
            _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
            _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
            _entity.clazzLogMSQN = tmp_clazzLogMSQN
            _entity.clazzLogLCSN = tmp_clazzLogLCSN
            _entity.clazzLogLCB = tmp_clazzLogLCB
            _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun findByClazzUidAsFactory(clazzUid: Long, excludeStatus: Int):
      DoorDataSourceFactory<Int, ClazzLog> {
    val _result = object : DoorDataSourceFactory<Int, ClazzLog>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<ClazzLog>> =
          DoorLiveDataImpl<List<ClazzLog>>(_db, listOf("ClazzLog"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.ClazzLog>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT ClazzLog.* FROM ClazzLog 
        |        WHERE clazzLogClazzUid = ?
        |        AND clazzLog.clazzLogStatusFlag != ?
        |        ORDER BY ClazzLog.logDate DESC) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT ClazzLog.* FROM ClazzLog 
        |        WHERE clazzLogClazzUid = ?
        |        AND clazzLog.clazzLogStatusFlag != ?
        |        ORDER BY ClazzLog.logDate DESC) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.setInt(2, excludeStatus)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
              val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
              val tmp_logDate = _resultSet.getLong("logDate")
              val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
              val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
              val tmp_cancellationNote = _resultSet.getString("cancellationNote")
              val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
              val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
              val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
              val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
              val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
              val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
              val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
              val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
              val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
              val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
              val _entity = ClazzLog()
              _entity.clazzLogUid = tmp_clazzLogUid
              _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
              _entity.logDate = tmp_logDate
              _entity.timeRecorded = tmp_timeRecorded
              _entity.clazzLogDone = tmp_clazzLogDone
              _entity.cancellationNote = tmp_cancellationNote
              _entity.clazzLogCancelled = tmp_clazzLogCancelled
              _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
              _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
              _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
              _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
              _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
              _entity.clazzLogMSQN = tmp_clazzLogMSQN
              _entity.clazzLogLCSN = tmp_clazzLogLCSN
              _entity.clazzLogLCB = tmp_clazzLogLCB
              _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ClazzLog"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT ClazzLog.* FROM ClazzLog 
        |        WHERE clazzLogClazzUid = ?
        |        AND clazzLog.clazzLogStatusFlag != ?
        |        ORDER BY ClazzLog.logDate DESC) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT ClazzLog.* FROM ClazzLog 
        |        WHERE clazzLogClazzUid = ?
        |        AND clazzLog.clazzLogStatusFlag != ?
        |        ORDER BY ClazzLog.logDate DESC) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.setInt(2, excludeStatus)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun findByClazzUidAsync(clazzUid: Long, excludeStatus: Int):
      List<ClazzLog> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ClazzLog>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT ClazzLog.* FROM ClazzLog 
    |        WHERE clazzLogClazzUid = ?
    |        AND clazzLog.clazzLogStatusFlag != ?
    |        ORDER BY ClazzLog.logDate ASC
    """.trimMargin() , postgreSql = """
    |SELECT ClazzLog.* FROM ClazzLog 
    |        WHERE clazzLogClazzUid = ?
    |        AND clazzLog.clazzLogStatusFlag != ?
    |        ORDER BY ClazzLog.logDate ASC
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.setInt(2, excludeStatus)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
          val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
          val tmp_logDate = _resultSet.getLong("logDate")
          val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
          val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
          val tmp_cancellationNote = _resultSet.getString("cancellationNote")
          val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
          val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
          val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
          val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
          val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
          val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
          val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
          val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
          val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
          val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
          val _entity = ClazzLog()
          _entity.clazzLogUid = tmp_clazzLogUid
          _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
          _entity.logDate = tmp_logDate
          _entity.timeRecorded = tmp_timeRecorded
          _entity.clazzLogDone = tmp_clazzLogDone
          _entity.cancellationNote = tmp_cancellationNote
          _entity.clazzLogCancelled = tmp_clazzLogCancelled
          _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
          _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
          _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
          _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
          _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
          _entity.clazzLogMSQN = tmp_clazzLogMSQN
          _entity.clazzLogLCSN = tmp_clazzLogLCSN
          _entity.clazzLogLCB = tmp_clazzLogLCB
          _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByClazzUidWithinTimeRangeAsync(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int
  ): List<ClazzLog> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ClazzLog>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT ClazzLog.* FROM ClazzLog 
    |        WHERE 
    |        ClazzLog.clazzLogClazzUid = ? 
    |        AND ClazzLog.logDate BETWEEN ? AND ?
    |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0))
    |        ORDER BY ClazzLog.logDate DESC
    |        LIMIT ?
    |    
    """.trimMargin() , postgreSql = """
    |SELECT ClazzLog.* FROM ClazzLog 
    |        WHERE 
    |        ClazzLog.clazzLogClazzUid = ? 
    |        AND ClazzLog.logDate BETWEEN ? AND ?
    |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0))
    |        ORDER BY ClazzLog.logDate DESC
    |        LIMIT ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.setLong(2, fromTime)
      _stmt.setLong(3, toTime)
      _stmt.setInt(4, excludeStatusFilter)
      _stmt.setInt(5, excludeStatusFilter)
      _stmt.setInt(6, limit)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
          val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
          val tmp_logDate = _resultSet.getLong("logDate")
          val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
          val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
          val tmp_cancellationNote = _resultSet.getString("cancellationNote")
          val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
          val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
          val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
          val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
          val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
          val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
          val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
          val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
          val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
          val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
          val _entity = ClazzLog()
          _entity.clazzLogUid = tmp_clazzLogUid
          _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
          _entity.logDate = tmp_logDate
          _entity.timeRecorded = tmp_timeRecorded
          _entity.clazzLogDone = tmp_clazzLogDone
          _entity.cancellationNote = tmp_cancellationNote
          _entity.clazzLogCancelled = tmp_clazzLogCancelled
          _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
          _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
          _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
          _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
          _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
          _entity.clazzLogMSQN = tmp_clazzLogMSQN
          _entity.clazzLogLCSN = tmp_clazzLogLCSN
          _entity.clazzLogLCB = tmp_clazzLogLCB
          _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByClazzUidWithinTimeRange(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    excludeStatusFilter: Int,
    limit: Int
  ): List<ClazzLog> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ClazzLog>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT ClazzLog.* FROM ClazzLog 
    |        WHERE 
    |        ClazzLog.clazzLogClazzUid = ? 
    |        AND ClazzLog.logDate BETWEEN ? AND ?
    |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0))
    |        ORDER BY ClazzLog.logDate DESC
    |        LIMIT ?
    |    
    """.trimMargin() , postgreSql = """
    |SELECT ClazzLog.* FROM ClazzLog 
    |        WHERE 
    |        ClazzLog.clazzLogClazzUid = ? 
    |        AND ClazzLog.logDate BETWEEN ? AND ?
    |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0))
    |        ORDER BY ClazzLog.logDate DESC
    |        LIMIT ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.setLong(2, fromTime)
      _stmt.setLong(3, toTime)
      _stmt.setInt(4, excludeStatusFilter)
      _stmt.setInt(5, excludeStatusFilter)
      _stmt.setInt(6, limit)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
          val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
          val tmp_logDate = _resultSet.getLong("logDate")
          val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
          val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
          val tmp_cancellationNote = _resultSet.getString("cancellationNote")
          val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
          val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
          val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
          val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
          val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
          val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
          val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
          val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
          val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
          val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
          val _entity = ClazzLog()
          _entity.clazzLogUid = tmp_clazzLogUid
          _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
          _entity.logDate = tmp_logDate
          _entity.timeRecorded = tmp_timeRecorded
          _entity.clazzLogDone = tmp_clazzLogDone
          _entity.cancellationNote = tmp_cancellationNote
          _entity.clazzLogCancelled = tmp_clazzLogCancelled
          _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
          _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
          _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
          _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
          _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
          _entity.clazzLogMSQN = tmp_clazzLogMSQN
          _entity.clazzLogLCSN = tmp_clazzLogLCSN
          _entity.clazzLogLCB = tmp_clazzLogLCB
          _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByClazzUidWithinTimeRangeLive(
    clazzUid: Long,
    fromTime: Long,
    toTime: Long,
    statusFilter: Int
  ): DoorLiveData<List<ClazzLog>> {
    val _result = DoorLiveDataImpl<List<ClazzLog>>(_db, listOf("ClazzLog"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.ClazzLog>()
      val _stmtConfig = PreparedStatementConfig("""
      |SELECT ClazzLog.* FROM ClazzLog 
      |        WHERE 
      |        ClazzLog.clazzLogClazzUid = ? 
      |        AND ClazzLog.logDate BETWEEN ? AND ?
      |        AND (? = 0 OR ClazzLog.clazzLogStatusFlag = ?)
      |        ORDER BY ClazzLog.logDate
      |    
      """.trimMargin() , postgreSql = """
      |SELECT ClazzLog.* FROM ClazzLog 
      |        WHERE 
      |        ClazzLog.clazzLogClazzUid = ? 
      |        AND ClazzLog.logDate BETWEEN ? AND ?
      |        AND (? = 0 OR ClazzLog.clazzLogStatusFlag = ?)
      |        ORDER BY ClazzLog.logDate
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, clazzUid)
        _stmt.setLong(2, fromTime)
        _stmt.setLong(3, toTime)
        _stmt.setInt(4, statusFilter)
        _stmt.setInt(5, statusFilter)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
            val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
            val tmp_logDate = _resultSet.getLong("logDate")
            val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
            val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
            val tmp_cancellationNote = _resultSet.getString("cancellationNote")
            val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
            val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
            val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
            val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
            val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
            val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
            val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
            val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
            val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
            val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
            val _entity = ClazzLog()
            _entity.clazzLogUid = tmp_clazzLogUid
            _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
            _entity.logDate = tmp_logDate
            _entity.timeRecorded = tmp_timeRecorded
            _entity.clazzLogDone = tmp_clazzLogDone
            _entity.cancellationNote = tmp_cancellationNote
            _entity.clazzLogCancelled = tmp_clazzLogCancelled
            _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
            _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
            _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
            _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
            _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
            _entity.clazzLogMSQN = tmp_clazzLogMSQN
            _entity.clazzLogLCSN = tmp_clazzLogLCSN
            _entity.clazzLogLCB = tmp_clazzLogLCB
            _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override fun clazzHasScheduleLive(clazzUid: Long, excludeStatusFilter: Int):
      DoorLiveData<Boolean> {
    val _result = DoorLiveDataImpl<Boolean>(_db, listOf("ClazzLog"))  {
      var _liveResult = false
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT EXISTS(SELECT ClazzLog.clazzLogUid FROM ClazzLog WHERE clazzLogClazzUid = ? 
      |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0)))
      |    
      """.trimMargin() , postgreSql = """
      |
      |        SELECT EXISTS(SELECT ClazzLog.clazzLogUid FROM ClazzLog WHERE clazzLogClazzUid = ? 
      |        AND (? = 0 OR ((ClazzLog.clazzLogStatusFlag & ?) = 0)))
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, clazzUid)
        _stmt.setInt(2, excludeStatusFilter)
        _stmt.setInt(3, excludeStatusFilter)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val _entity = _resultSet.getBoolean(1)
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun updateStatusByClazzLogUid(
    clazzLogUid: Long,
    newStatus: Int,
    timeChanged: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |UPDATE ClazzLog 
    |        SET clazzLogStatusFlag = ?,
    |        clazzLogLastChangedTime = ?
    |        WHERE clazzLogUid = ?
    """.trimMargin() , postgreSql = """
    |UPDATE ClazzLog 
    |        SET clazzLogStatusFlag = ?,
    |        clazzLogLastChangedTime = ?
    |        WHERE clazzLogUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, newStatus)
      _stmt.setLong(2, timeChanged)
      _stmt.setLong(3, clazzLogUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override suspend fun updateAsync(clazzLog: ClazzLog): Unit {
    val _sql =
        "UPDATE ClazzLog SET clazzLogClazzUid = ?, logDate = ?, timeRecorded = ?, clazzLogDone = ?, cancellationNote = ?, clazzLogCancelled = ?, clazzLogNumPresent = ?, clazzLogNumAbsent = ?, clazzLogNumPartial = ?, clazzLogScheduleUid = ?, clazzLogStatusFlag = ?, clazzLogMSQN = ?, clazzLogLCSN = ?, clazzLogLCB = ?, clazzLogLastChangedTime = ? WHERE clazzLogUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, clazzLog.clazzLogClazzUid)
      _stmt.setLong(2, clazzLog.logDate)
      _stmt.setLong(3, clazzLog.timeRecorded)
      _stmt.setBoolean(4, clazzLog.clazzLogDone)
      _stmt.setString(5, clazzLog.cancellationNote)
      _stmt.setBoolean(6, clazzLog.clazzLogCancelled)
      _stmt.setInt(7, clazzLog.clazzLogNumPresent)
      _stmt.setInt(8, clazzLog.clazzLogNumAbsent)
      _stmt.setInt(9, clazzLog.clazzLogNumPartial)
      _stmt.setLong(10, clazzLog.clazzLogScheduleUid)
      _stmt.setInt(11, clazzLog.clazzLogStatusFlag)
      _stmt.setLong(12, clazzLog.clazzLogMSQN)
      _stmt.setLong(13, clazzLog.clazzLogLCSN)
      _stmt.setInt(14, clazzLog.clazzLogLCB)
      _stmt.setLong(15, clazzLog.clazzLogLastChangedTime)
      _stmt.setLong(16, clazzLog.clazzLogUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: ClazzLog): Long {
    val _retVal = _insertAdapterClazzLog_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzLog): Long {
    val _retVal = _insertAdapterClazzLog_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ClazzLog>): Unit {
    _insertAdapterClazzLog_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzLog>): Unit {
    val _sql =
        "UPDATE ClazzLog SET clazzLogClazzUid = ?, logDate = ?, timeRecorded = ?, clazzLogDone = ?, cancellationNote = ?, clazzLogCancelled = ?, clazzLogNumPresent = ?, clazzLogNumAbsent = ?, clazzLogNumPartial = ?, clazzLogScheduleUid = ?, clazzLogStatusFlag = ?, clazzLogMSQN = ?, clazzLogLCSN = ?, clazzLogLCB = ?, clazzLogLastChangedTime = ? WHERE clazzLogUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.clazzLogClazzUid)
        _stmt.setLong(2, _entity.logDate)
        _stmt.setLong(3, _entity.timeRecorded)
        _stmt.setBoolean(4, _entity.clazzLogDone)
        _stmt.setString(5, _entity.cancellationNote)
        _stmt.setBoolean(6, _entity.clazzLogCancelled)
        _stmt.setInt(7, _entity.clazzLogNumPresent)
        _stmt.setInt(8, _entity.clazzLogNumAbsent)
        _stmt.setInt(9, _entity.clazzLogNumPartial)
        _stmt.setLong(10, _entity.clazzLogScheduleUid)
        _stmt.setInt(11, _entity.clazzLogStatusFlag)
        _stmt.setLong(12, _entity.clazzLogMSQN)
        _stmt.setLong(13, _entity.clazzLogLCSN)
        _stmt.setInt(14, _entity.clazzLogLCB)
        _stmt.setLong(15, _entity.clazzLogLastChangedTime)
        _stmt.setLong(16, _entity.clazzLogUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzLog): Unit {
    val _sql =
        "UPDATE ClazzLog SET clazzLogClazzUid = ?, logDate = ?, timeRecorded = ?, clazzLogDone = ?, cancellationNote = ?, clazzLogCancelled = ?, clazzLogNumPresent = ?, clazzLogNumAbsent = ?, clazzLogNumPartial = ?, clazzLogScheduleUid = ?, clazzLogStatusFlag = ?, clazzLogMSQN = ?, clazzLogLCSN = ?, clazzLogLCB = ?, clazzLogLastChangedTime = ? WHERE clazzLogUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.clazzLogClazzUid)
      _stmt.setLong(2, entity.logDate)
      _stmt.setLong(3, entity.timeRecorded)
      _stmt.setBoolean(4, entity.clazzLogDone)
      _stmt.setString(5, entity.cancellationNote)
      _stmt.setBoolean(6, entity.clazzLogCancelled)
      _stmt.setInt(7, entity.clazzLogNumPresent)
      _stmt.setInt(8, entity.clazzLogNumAbsent)
      _stmt.setInt(9, entity.clazzLogNumPartial)
      _stmt.setLong(10, entity.clazzLogScheduleUid)
      _stmt.setInt(11, entity.clazzLogStatusFlag)
      _stmt.setLong(12, entity.clazzLogMSQN)
      _stmt.setLong(13, entity.clazzLogLCSN)
      _stmt.setInt(14, entity.clazzLogLCB)
      _stmt.setLong(15, entity.clazzLogLastChangedTime)
      _stmt.setLong(16, entity.clazzLogUid)
      _stmt.executeUpdate()
    }
  }
}
