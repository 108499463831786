package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.ext.doorNodeAndVersionHeaders
import com.ustadmobile.door.ext.getOrNull
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonParentJoinWithMinorPerson
import io.ktor.client.HttpClient
import io.ktor.client.request.header
import io.ktor.client.request.parameter
import io.ktor.http.takeFrom
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonParentJoinDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonParentJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : PersonParentJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertAsync(entity: PersonParentJoin): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override suspend fun findByUidWithMinorAsync(uid: Long): PersonParentJoinWithMinorPerson? {
    val _result = _dao.findByUidWithMinorAsync(uid)
    return _result
  }

  public override suspend fun findByUidWithMinorAsyncFromWeb(uid: Long):
      PersonParentJoinWithMinorPerson? = _httpClient.getOrNull<PersonParentJoinWithMinorPerson?> {
    url {
      takeFrom(_endpoint)
      encodedPath = "${encodedPath}PersonParentJoinDao/findByUidWithMinorAsyncFromWeb"
    }
    doorNodeAndVersionHeaders(_repo)
    header("x-nid", _clientId)
    parameter("uid", uid)

  }

  public override suspend fun findByMinorPersonUid(minorPersonUid: Long): List<PersonParentJoin> {
    val _result = _dao.findByMinorPersonUid(minorPersonUid)
    return _result
  }

  public override suspend
      fun findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid: Long,
      clazzUidFilter: Long): List<PersonParentJoinDao.ParentEnrolmentRequired> {
    val _result = _dao.findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid,
        clazzUidFilter)
    return _result
  }

  public override suspend fun isParentOf(userPersonUid: Long, minorPersonUid: Long): Boolean {
    val _result = _dao.isParentOf(userPersonUid, minorPersonUid)
    return _result
  }

  public override suspend fun updateAsync(personParentJoin: PersonParentJoin): Unit {
    _dao.updateAsync(personParentJoin)
  }

  public override suspend fun isMinorApproved(minorPersonUid: Long): Boolean {
    val _result = _dao.isMinorApproved(minorPersonUid)
    return _result
  }
}
