package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.AccessToken
import com.ustadmobile.lib.db.entities.AuditLog
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAuth
import com.ustadmobile.lib.db.entities.PersonGroup
import com.ustadmobile.lib.db.entities.PersonGroupMember
import com.ustadmobile.lib.db.entities.PersonWithAccount
import com.ustadmobile.lib.db.entities.PersonWithDisplayDetails
import com.ustadmobile.lib.db.entities.PersonWithPersonParentJoin
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: PersonDao
) : PersonDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entityList: List<out Person>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.personUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(9)
        it.personUid = _newPk
        _generatedPks += _newPk
      }
      it.personLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun insertOrReplace(person: Person): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(person.personUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(9)
      person.personUid = _newPk
    }
    person.personLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertOrReplace(person)
  }

  public override suspend fun findByUsernameCount(username: String): Int =
      _dao.findByUsernameCount(username)

  public override fun isValidToken(token: String, personUid: Long): Boolean = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insertAccessToken(token: AccessToken): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findUidAndPasswordHashAsync(username: String):
      PersonDao.PersonUidAndPasswordHash? = _dao.findUidAndPasswordHashAsync(username)

  public override suspend fun findByUsernameAndPasswordHash2(username: String,
      passwordHash: String): Person? = _dao.findByUsernameAndPasswordHash2(username, passwordHash)

  public override fun insertPersonAuth(personAuth: PersonAuth): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun personHasPermissionAsync(
    accountPersonUid: Long,
    personUid: Long,
    permission: Long
  ): Boolean = _dao.personHasPermissionAsync(accountPersonUid, personUid, permission)

  public override suspend fun personIsAdmin(accountPersonUid: Long): Boolean =
      _dao.personIsAdmin(accountPersonUid)

  public override fun findByUsername(username: String?): Person? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findSystemAccount(nodeId: Long): Person? =
      _dao.findSystemAccount(nodeId)

  public override fun findByUid(uid: Long): Person? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findPersonAccountByUid(uid: Long): PersonWithAccount? =
      _dao.findPersonAccountByUid(uid)

  public override fun findByUidLive(uid: Long): DoorLiveData<Person?> = _dao.findByUidLive(uid)

  public override suspend fun findByUidAsync(uid: Long): Person? = _dao.findByUidAsync(uid)

  public override suspend fun updateAsync(entity: Person): Int {
    entity.personLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override suspend fun insertPersonGroup(personGroup: PersonGroup): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(personGroup.groupUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(43)
      personGroup.groupUid = _newPk
    }
    personGroup.groupLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertPersonGroup(personGroup)
    return personGroup.groupUid
  }

  public override suspend fun insertPersonGroupMember(personGroupMember: PersonGroupMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(personGroupMember.groupMemberUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(44)
      personGroupMember.groupMemberUid = _newPk
    }
    personGroupMember.groupMemberLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertPersonGroupMember(personGroupMember)
    return personGroupMember.groupMemberUid
  }

  public override fun findPersonsWithPermission(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?
  ): DoorDataSourceFactory<Int, PersonWithDisplayDetails> =
      _dao.findPersonsWithPermission(timestamp, excludeClazz, excludeSchool, excludeSelected,
      accountPersonUid, sortOrder, searchText)

  public override fun findPersonsWithPermissionAsList(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?
  ): List<Person> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findByUidWithDisplayDetailsLive(personUid: Long, activeUserPersonUid: Long):
      DoorLiveData<PersonWithPersonParentJoin?> = _dao.findByUidWithDisplayDetailsLive(personUid,
      activeUserPersonUid)

  public override fun insertAuditLog(entity: AuditLog): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun getAllPerson(): List<Person> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun insert(entity: Person): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Person): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.personUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(9)
      entity.personUid = _newPk
    }
    entity.personLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.personUid
  }

  public override fun insertList(entityList: List<out Person>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out Person>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Person): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
