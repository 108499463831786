package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.DiscussionTopic
import com.ustadmobile.lib.db.entities.DiscussionTopicListDetail
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class DiscussionTopicDao_JdbcKt(
  public val _db: DoorDatabase
) : DiscussionTopicDao() {
  public val _insertAdapterDiscussionTopic_upsert: EntityInsertionAdapter<DiscussionTopic> = object
      : EntityInsertionAdapter<DiscussionTopic>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO DiscussionTopic (discussionTopicUid, discussionTopicTitle, discussionTopicDesc, discussionTopicStartDate, discussionTopicCourseDiscussionUid, discussionTopicVisible, discussionTopicArchive, discussionTopicIndex, discussionTopicClazzUid, discussionTopicLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO DiscussionTopic (discussionTopicUid, discussionTopicTitle, discussionTopicDesc, discussionTopicStartDate, discussionTopicCourseDiscussionUid, discussionTopicVisible, discussionTopicArchive, discussionTopicIndex, discussionTopicClazzUid, discussionTopicLct) VALUES(COALESCE(?,nextval('DiscussionTopic_discussionTopicUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (discussionTopicUid) DO UPDATE SET discussionTopicTitle = excluded.discussionTopicTitle,discussionTopicDesc = excluded.discussionTopicDesc,discussionTopicStartDate = excluded.discussionTopicStartDate,discussionTopicCourseDiscussionUid = excluded.discussionTopicCourseDiscussionUid,discussionTopicVisible = excluded.discussionTopicVisible,discussionTopicArchive = excluded.discussionTopicArchive,discussionTopicIndex = excluded.discussionTopicIndex,discussionTopicClazzUid = excluded.discussionTopicClazzUid,discussionTopicLct = excluded.discussionTopicLct" + if(returnsId) { " RETURNING discussionTopicUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: DiscussionTopic):
        Unit {
      if(entity.discussionTopicUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.discussionTopicUid)
      }
      stmt.setString(2, entity.discussionTopicTitle)
      stmt.setString(3, entity.discussionTopicDesc)
      stmt.setLong(4, entity.discussionTopicStartDate)
      stmt.setLong(5, entity.discussionTopicCourseDiscussionUid)
      stmt.setBoolean(6, entity.discussionTopicVisible)
      stmt.setBoolean(7, entity.discussionTopicArchive)
      stmt.setInt(8, entity.discussionTopicIndex)
      stmt.setLong(9, entity.discussionTopicClazzUid)
      stmt.setLong(10, entity.discussionTopicLct)
    }
  }

  public val _insertAdapterDiscussionTopic_: EntityInsertionAdapter<DiscussionTopic> = object :
      EntityInsertionAdapter<DiscussionTopic>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO DiscussionTopic (discussionTopicUid, discussionTopicTitle, discussionTopicDesc, discussionTopicStartDate, discussionTopicCourseDiscussionUid, discussionTopicVisible, discussionTopicArchive, discussionTopicIndex, discussionTopicClazzUid, discussionTopicLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO DiscussionTopic (discussionTopicUid, discussionTopicTitle, discussionTopicDesc, discussionTopicStartDate, discussionTopicCourseDiscussionUid, discussionTopicVisible, discussionTopicArchive, discussionTopicIndex, discussionTopicClazzUid, discussionTopicLct) VALUES(COALESCE(?,nextval('DiscussionTopic_discussionTopicUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING discussionTopicUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: DiscussionTopic):
        Unit {
      if(entity.discussionTopicUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.discussionTopicUid)
      }
      stmt.setString(2, entity.discussionTopicTitle)
      stmt.setString(3, entity.discussionTopicDesc)
      stmt.setLong(4, entity.discussionTopicStartDate)
      stmt.setLong(5, entity.discussionTopicCourseDiscussionUid)
      stmt.setBoolean(6, entity.discussionTopicVisible)
      stmt.setBoolean(7, entity.discussionTopicArchive)
      stmt.setInt(8, entity.discussionTopicIndex)
      stmt.setLong(9, entity.discussionTopicClazzUid)
      stmt.setLong(10, entity.discussionTopicLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO DiscussionTopicReplicate(discussionTopicPk, discussionTopicDestination)
    |      SELECT DISTINCT DiscussionTopic.discussionTopicUid AS discussionTopicPk,
    |             ? AS discussionTopicDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |             JOIN DiscussionTopic 
    |                  ON DiscussionTopic.discussionTopicClazzUid = Clazz.clazzUid
    |                  
    |       WHERE DiscussionTopic.discussionTopicLct != COALESCE(
    |             (SELECT discussionTopicVersionId
    |                FROM discussionTopicReplicate
    |               WHERE discussionTopicPk = DiscussionTopic.discussionTopicUid
    |                 AND discussionTopicDestination = ?), 0) 
    |      /*psql ON CONFLICT(discussionTopicPk, discussionTopicDestination) DO UPDATE
    |             SET discussionTopicPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO DiscussionTopicReplicate(discussionTopicPk, discussionTopicDestination)
    |      SELECT DISTINCT DiscussionTopic.discussionTopicUid AS discussionTopicPk,
    |             ? AS discussionTopicDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |             JOIN DiscussionTopic 
    |                  ON DiscussionTopic.discussionTopicClazzUid = Clazz.clazzUid
    |                  
    |       WHERE DiscussionTopic.discussionTopicLct != COALESCE(
    |             (SELECT discussionTopicVersionId
    |                FROM discussionTopicReplicate
    |               WHERE discussionTopicPk = DiscussionTopic.discussionTopicUid
    |                 AND discussionTopicDestination = ?), 0) 
    |       ON CONFLICT(discussionTopicPk, discussionTopicDestination) DO UPDATE
    |             SET discussionTopicPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO DiscussionTopicReplicate(discussionTopicPk, discussionTopicDestination)
    |          SELECT DISTINCT DiscussionTopic.discussionTopicUid AS discussionTopicUid,
    |                 UserSession.usClientNodeId AS discussionTopicDestination
    |            FROM ChangeLog
    |                 JOIN DiscussionTopic
    |                     ON ChangeLog.chTableId = 131
    |                        AND ChangeLog.chEntityPk = DiscussionTopic.discussionTopicUid
    |                        
    |                        
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = DiscussionTopic.discussionTopicClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |             
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND DiscussionTopic.discussionTopicLct != COALESCE(
    |                 (SELECT discussionTopicVersionId
    |                    FROM discussionTopicReplicate
    |                   WHERE discussionTopicPk = DiscussionTopic.discussionTopicUid
    |                     AND DiscussionTopicDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(discussionTopicPk, discussionTopicDestination) DO UPDATE
    |             SET discussionTopicPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO DiscussionTopicReplicate(discussionTopicPk, discussionTopicDestination)
    |          SELECT DISTINCT DiscussionTopic.discussionTopicUid AS discussionTopicUid,
    |                 UserSession.usClientNodeId AS discussionTopicDestination
    |            FROM ChangeLog
    |                 JOIN DiscussionTopic
    |                     ON ChangeLog.chTableId = 131
    |                        AND ChangeLog.chEntityPk = DiscussionTopic.discussionTopicUid
    |                        
    |                        
    |                 JOIN Clazz
    |                      ON Clazz.clazzUid = DiscussionTopic.discussionTopicClazzUid
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |             
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND DiscussionTopic.discussionTopicLct != COALESCE(
    |                 (SELECT discussionTopicVersionId
    |                    FROM discussionTopicReplicate
    |                   WHERE discussionTopicPk = DiscussionTopic.discussionTopicUid
    |                     AND DiscussionTopicDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(discussionTopicPk, discussionTopicDestination) DO UPDATE
    |             SET discussionTopicPending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getListOfTopicsByDiscussion(discussionUid: Long): DoorDataSourceFactory<Int,
      DiscussionTopicListDetail> {
    val _result = object : DoorDataSourceFactory<Int, DiscussionTopicListDetail>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<DiscussionTopicListDetail>> =
          DoorLiveDataImpl<List<DiscussionTopicListDetail>>(_db, listOf("DiscussionPost",
          "DiscussionTopic"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.DiscussionTopicListDetail>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT DiscussionTopic.*,
        |                ( 
        |                    SELECT COUNT(*) 
        |                      FROM DiscussionPost 
        |                     WHERE DiscussionPost.discussionPostDiscussionTopicUid = DiscussionTopic.discussionTopicUid
        |                ) as numPosts,
        |                (
        |                    0
        |                )as lastActiveTimestamp
        |          FROM DiscussionTopic     
        |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid = ? 
        |           AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
        |           AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER) = 0
        |      ORDER BY DiscussionTopic.discussionTopicIndex
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT DiscussionTopic.*,
        |                ( 
        |                    SELECT COUNT(*) 
        |                      FROM DiscussionPost 
        |                     WHERE DiscussionPost.discussionPostDiscussionTopicUid = DiscussionTopic.discussionTopicUid
        |                ) as numPosts,
        |                (
        |                    0
        |                )as lastActiveTimestamp
        |          FROM DiscussionTopic     
        |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid = ? 
        |           AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
        |           AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER) = 0
        |      ORDER BY DiscussionTopic.discussionTopicIndex
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, discussionUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_numPosts = _resultSet.getInt("numPosts")
              val tmp_lastActiveTimestamp = _resultSet.getLong("lastActiveTimestamp")
              val tmp_discussionTopicUid = _resultSet.getLong("discussionTopicUid")
              val tmp_discussionTopicTitle = _resultSet.getString("discussionTopicTitle")
              val tmp_discussionTopicDesc = _resultSet.getString("discussionTopicDesc")
              val tmp_discussionTopicStartDate = _resultSet.getLong("discussionTopicStartDate")
              val tmp_discussionTopicCourseDiscussionUid =
                  _resultSet.getLong("discussionTopicCourseDiscussionUid")
              val tmp_discussionTopicVisible = _resultSet.getBoolean("discussionTopicVisible")
              val tmp_discussionTopicArchive = _resultSet.getBoolean("discussionTopicArchive")
              val tmp_discussionTopicIndex = _resultSet.getInt("discussionTopicIndex")
              val tmp_discussionTopicClazzUid = _resultSet.getLong("discussionTopicClazzUid")
              val tmp_discussionTopicLct = _resultSet.getLong("discussionTopicLct")
              val _entity = DiscussionTopicListDetail()
              _entity.numPosts = tmp_numPosts
              _entity.lastActiveTimestamp = tmp_lastActiveTimestamp
              _entity.discussionTopicUid = tmp_discussionTopicUid
              _entity.discussionTopicTitle = tmp_discussionTopicTitle
              _entity.discussionTopicDesc = tmp_discussionTopicDesc
              _entity.discussionTopicStartDate = tmp_discussionTopicStartDate
              _entity.discussionTopicCourseDiscussionUid = tmp_discussionTopicCourseDiscussionUid
              _entity.discussionTopicVisible = tmp_discussionTopicVisible
              _entity.discussionTopicArchive = tmp_discussionTopicArchive
              _entity.discussionTopicIndex = tmp_discussionTopicIndex
              _entity.discussionTopicClazzUid = tmp_discussionTopicClazzUid
              _entity.discussionTopicLct = tmp_discussionTopicLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("DiscussionPost", "DiscussionTopic"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT DiscussionTopic.*,
        |                ( 
        |                    SELECT COUNT(*) 
        |                      FROM DiscussionPost 
        |                     WHERE DiscussionPost.discussionPostDiscussionTopicUid = DiscussionTopic.discussionTopicUid
        |                ) as numPosts,
        |                (
        |                    0
        |                )as lastActiveTimestamp
        |          FROM DiscussionTopic     
        |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid = ? 
        |           AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
        |           AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER) = 0
        |      ORDER BY DiscussionTopic.discussionTopicIndex
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT DiscussionTopic.*,
        |                ( 
        |                    SELECT COUNT(*) 
        |                      FROM DiscussionPost 
        |                     WHERE DiscussionPost.discussionPostDiscussionTopicUid = DiscussionTopic.discussionTopicUid
        |                ) as numPosts,
        |                (
        |                    0
        |                )as lastActiveTimestamp
        |          FROM DiscussionTopic     
        |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid = ? 
        |           AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
        |           AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER) = 0
        |      ORDER BY DiscussionTopic.discussionTopicIndex
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, discussionUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun getTopicsByClazz(clazzUid: Long): List<DiscussionTopic> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.DiscussionTopic>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT DiscussionTopic.*
    |          FROM DiscussionTopic
    |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid IN 
    |                (SELECT CourseDiscussion.courseDiscussionUid 
    |                   FROM CourseDiscussion
    |                  WHERE CourseDiscussion.courseDiscussionClazzUid = ? ) 
    |          AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
    |          AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER)  = 0
    |                        
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT DiscussionTopic.*
    |          FROM DiscussionTopic
    |         WHERE DiscussionTopic.discussionTopicCourseDiscussionUid IN 
    |                (SELECT CourseDiscussion.courseDiscussionUid 
    |                   FROM CourseDiscussion
    |                  WHERE CourseDiscussion.courseDiscussionClazzUid = ? ) 
    |          AND CAST(DiscussionTopic.discussionTopicVisible AS INTEGER) = 1
    |          AND CAST(DiscussionTopic.discussionTopicArchive AS INTEGER)  = 0
    |                        
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_discussionTopicUid = _resultSet.getLong("discussionTopicUid")
          val tmp_discussionTopicTitle = _resultSet.getString("discussionTopicTitle")
          val tmp_discussionTopicDesc = _resultSet.getString("discussionTopicDesc")
          val tmp_discussionTopicStartDate = _resultSet.getLong("discussionTopicStartDate")
          val tmp_discussionTopicCourseDiscussionUid =
              _resultSet.getLong("discussionTopicCourseDiscussionUid")
          val tmp_discussionTopicVisible = _resultSet.getBoolean("discussionTopicVisible")
          val tmp_discussionTopicArchive = _resultSet.getBoolean("discussionTopicArchive")
          val tmp_discussionTopicIndex = _resultSet.getInt("discussionTopicIndex")
          val tmp_discussionTopicClazzUid = _resultSet.getLong("discussionTopicClazzUid")
          val tmp_discussionTopicLct = _resultSet.getLong("discussionTopicLct")
          val _entity = DiscussionTopic()
          _entity.discussionTopicUid = tmp_discussionTopicUid
          _entity.discussionTopicTitle = tmp_discussionTopicTitle
          _entity.discussionTopicDesc = tmp_discussionTopicDesc
          _entity.discussionTopicStartDate = tmp_discussionTopicStartDate
          _entity.discussionTopicCourseDiscussionUid = tmp_discussionTopicCourseDiscussionUid
          _entity.discussionTopicVisible = tmp_discussionTopicVisible
          _entity.discussionTopicArchive = tmp_discussionTopicArchive
          _entity.discussionTopicIndex = tmp_discussionTopicIndex
          _entity.discussionTopicClazzUid = tmp_discussionTopicClazzUid
          _entity.discussionTopicLct = tmp_discussionTopicLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun getDiscussionTopicByUid(discussionTopicUid: Long):
      DoorLiveData<DiscussionTopic?> {
    val _result = DoorLiveDataImpl<DiscussionTopic?>(_db, listOf("DiscussionTopic"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.DiscussionTopic??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT DiscussionTopic.*
      |          FROM DiscussionTopic
      |         WHERE DiscussionTopic.discussionTopicUid = ?
      |         
      |         
      """.trimMargin() , postgreSql = """
      |
      |        SELECT DiscussionTopic.*
      |          FROM DiscussionTopic
      |         WHERE DiscussionTopic.discussionTopicUid = ?
      |         
      |         
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, discussionTopicUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_discussionTopicUid = _resultSet.getLong("discussionTopicUid")
            val tmp_discussionTopicTitle = _resultSet.getString("discussionTopicTitle")
            val tmp_discussionTopicDesc = _resultSet.getString("discussionTopicDesc")
            val tmp_discussionTopicStartDate = _resultSet.getLong("discussionTopicStartDate")
            val tmp_discussionTopicCourseDiscussionUid =
                _resultSet.getLong("discussionTopicCourseDiscussionUid")
            val tmp_discussionTopicVisible = _resultSet.getBoolean("discussionTopicVisible")
            val tmp_discussionTopicArchive = _resultSet.getBoolean("discussionTopicArchive")
            val tmp_discussionTopicIndex = _resultSet.getInt("discussionTopicIndex")
            val tmp_discussionTopicClazzUid = _resultSet.getLong("discussionTopicClazzUid")
            val tmp_discussionTopicLct = _resultSet.getLong("discussionTopicLct")
            val _entity = DiscussionTopic()
            _entity.discussionTopicUid = tmp_discussionTopicUid
            _entity.discussionTopicTitle = tmp_discussionTopicTitle
            _entity.discussionTopicDesc = tmp_discussionTopicDesc
            _entity.discussionTopicStartDate = tmp_discussionTopicStartDate
            _entity.discussionTopicCourseDiscussionUid = tmp_discussionTopicCourseDiscussionUid
            _entity.discussionTopicVisible = tmp_discussionTopicVisible
            _entity.discussionTopicArchive = tmp_discussionTopicArchive
            _entity.discussionTopicIndex = tmp_discussionTopicIndex
            _entity.discussionTopicClazzUid = tmp_discussionTopicClazzUid
            _entity.discussionTopicLct = tmp_discussionTopicLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateActiveByUid(
    uid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE DiscussionTopic 
    |           SET discussionTopicVisible = ?, 
    |               discussionTopicLct = ?
    |         WHERE discussionTopicUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE DiscussionTopic 
    |           SET discussionTopicVisible = ?, 
    |               discussionTopicLct = ?
    |         WHERE discussionTopicUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, active)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, uid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replaceListAsync(list: List<out DiscussionTopic>): Unit {
    _insertAdapterDiscussionTopic_upsert.insertListAsync(list)
  }

  public override fun insert(entity: DiscussionTopic): Long {
    val _retVal = _insertAdapterDiscussionTopic_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: DiscussionTopic): Long {
    val _retVal = _insertAdapterDiscussionTopic_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out DiscussionTopic>): Unit {
    _insertAdapterDiscussionTopic_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out DiscussionTopic>): Unit {
    val _sql =
        "UPDATE DiscussionTopic SET discussionTopicTitle = ?, discussionTopicDesc = ?, discussionTopicStartDate = ?, discussionTopicCourseDiscussionUid = ?, discussionTopicVisible = ?, discussionTopicArchive = ?, discussionTopicIndex = ?, discussionTopicClazzUid = ?, discussionTopicLct = ? WHERE discussionTopicUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.discussionTopicTitle)
        _stmt.setString(2, _entity.discussionTopicDesc)
        _stmt.setLong(3, _entity.discussionTopicStartDate)
        _stmt.setLong(4, _entity.discussionTopicCourseDiscussionUid)
        _stmt.setBoolean(5, _entity.discussionTopicVisible)
        _stmt.setBoolean(6, _entity.discussionTopicArchive)
        _stmt.setInt(7, _entity.discussionTopicIndex)
        _stmt.setLong(8, _entity.discussionTopicClazzUid)
        _stmt.setLong(9, _entity.discussionTopicLct)
        _stmt.setLong(10, _entity.discussionTopicUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: DiscussionTopic): Unit {
    val _sql =
        "UPDATE DiscussionTopic SET discussionTopicTitle = ?, discussionTopicDesc = ?, discussionTopicStartDate = ?, discussionTopicCourseDiscussionUid = ?, discussionTopicVisible = ?, discussionTopicArchive = ?, discussionTopicIndex = ?, discussionTopicClazzUid = ?, discussionTopicLct = ? WHERE discussionTopicUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.discussionTopicTitle)
      _stmt.setString(2, entity.discussionTopicDesc)
      _stmt.setLong(3, entity.discussionTopicStartDate)
      _stmt.setLong(4, entity.discussionTopicCourseDiscussionUid)
      _stmt.setBoolean(5, entity.discussionTopicVisible)
      _stmt.setBoolean(6, entity.discussionTopicArchive)
      _stmt.setInt(7, entity.discussionTopicIndex)
      _stmt.setLong(8, entity.discussionTopicClazzUid)
      _stmt.setLong(9, entity.discussionTopicLct)
      _stmt.setLong(10, entity.discussionTopicUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out DiscussionTopic>): Unit {
    _insertAdapterDiscussionTopic_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out DiscussionTopic>): Unit {
    val _sql =
        "UPDATE DiscussionTopic SET discussionTopicTitle = ?, discussionTopicDesc = ?, discussionTopicStartDate = ?, discussionTopicCourseDiscussionUid = ?, discussionTopicVisible = ?, discussionTopicArchive = ?, discussionTopicIndex = ?, discussionTopicClazzUid = ?, discussionTopicLct = ? WHERE discussionTopicUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.discussionTopicTitle)
        _stmt.setString(2, _entity.discussionTopicDesc)
        _stmt.setLong(3, _entity.discussionTopicStartDate)
        _stmt.setLong(4, _entity.discussionTopicCourseDiscussionUid)
        _stmt.setBoolean(5, _entity.discussionTopicVisible)
        _stmt.setBoolean(6, _entity.discussionTopicArchive)
        _stmt.setInt(7, _entity.discussionTopicIndex)
        _stmt.setLong(8, _entity.discussionTopicClazzUid)
        _stmt.setLong(9, _entity.discussionTopicLct)
        _stmt.setLong(10, _entity.discussionTopicUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }
}
