package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseAssignmentMarkDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseAssignmentMarkDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : CourseAssignmentMarkDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun getMarkOfAssignmentForSubmitterLiveData(assignmentUid: Long,
      submitterUid: Long): DoorLiveData<CourseAssignmentMark?> {
    val _result = _dao.getMarkOfAssignmentForSubmitterLiveData(assignmentUid, submitterUid)
    return _result
  }

  public override fun getMarkOfAssignmentForStudent(assignmentUid: Long, submitterUid: Long):
      CourseAssignmentMark? {
    val _result = _dao.getMarkOfAssignmentForStudent(assignmentUid, submitterUid)
    return _result
  }

  public override suspend fun findNextSubmitterToMarkForAssignment(assignmentUid: Long,
      submitterUid: Long): Long {
    val _result = _dao.findNextSubmitterToMarkForAssignment(assignmentUid, submitterUid)
    return _result
  }

  public override fun insert(entity: CourseAssignmentMark): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: CourseAssignmentMark): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out CourseAssignmentMark>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseAssignmentMark>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: CourseAssignmentMark): Unit {
    _dao.update(entity)
  }
}
