package com.ustadmobile.core.util

/**
 * Class responsible to manage file storage on web
 */
object StorageUtil{

    fun getStorage(): Array<dynamic>{
        return arrayOf()
    }

    fun deleteFile(path: String): Boolean{
        return false
    }

    fun createDir(path: String){

    }

    fun createTempDir(prefix: String): String{
        return ""
    }
}