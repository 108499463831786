package com.ustadmobile.core.db

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.SiteTerms
import com.ustadmobile.lib.db.entities.SiteTermsWithLanguage
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SiteTermsDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: SiteTermsDao
) : SiteTermsDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findSiteTerms(langCode: String): SiteTerms? =
      _dao.findSiteTerms(langCode)

  public override suspend fun insertAsync(siteTerms: SiteTerms): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(siteTerms.sTermsUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(272)
      siteTerms.sTermsUid = _newPk
    }
    siteTerms.sTermsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(siteTerms)
    return siteTerms.sTermsUid
  }

  public override suspend fun findByUidAsync(uid: Long): SiteTerms? = _dao.findByUidAsync(uid)

  public override fun findAllTermsAsFactory(): DoorDataSourceFactory<Int, SiteTermsWithLanguage> =
      _dao.findAllTermsAsFactory()

  public override suspend fun findAllWithLanguageAsList(): List<SiteTermsWithLanguage> =
      _dao.findAllWithLanguageAsList()

  public override suspend fun updateActiveByUid(
    sTermsUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    _dao.updateActiveByUid(sTermsUid, active, changeTime)
  }

  public override suspend fun insertListAsync(entityList: List<out SiteTerms>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.sTermsUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(272)
        it.sTermsUid = _newPk
        _generatedPks += _newPk
      }
      it.sTermsLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out SiteTerms>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.sTermsLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
