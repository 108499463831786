package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar
import com.ustadmobile.lib.db.entities.SchoolWithMemberCountAndLocation
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SchoolDao_JdbcKt(
  public val _db: DoorDatabase
) : SchoolDao() {
  public val _insertAdapterSchool_: EntityInsertionAdapter<School> = object :
      EntityInsertionAdapter<School>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO School (schoolUid, schoolName, schoolDesc, schoolAddress, schoolActive, schoolPhoneNumber, schoolGender, schoolHolidayCalendarUid, schoolFeatures, schoolLocationLong, schoolLocationLatt, schoolEmailAddress, schoolTeachersPersonGroupUid, schoolStudentsPersonGroupUid, schoolPendingStudentsPersonGroupUid, schoolCode, schoolMasterChangeSeqNum, schoolLocalChangeSeqNum, schoolLastChangedBy, schoolLct, schoolTimeZone) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO School (schoolUid, schoolName, schoolDesc, schoolAddress, schoolActive, schoolPhoneNumber, schoolGender, schoolHolidayCalendarUid, schoolFeatures, schoolLocationLong, schoolLocationLatt, schoolEmailAddress, schoolTeachersPersonGroupUid, schoolStudentsPersonGroupUid, schoolPendingStudentsPersonGroupUid, schoolCode, schoolMasterChangeSeqNum, schoolLocalChangeSeqNum, schoolLastChangedBy, schoolLct, schoolTimeZone) VALUES(COALESCE(?,nextval('School_schoolUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING schoolUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: School): Unit {
      if(entity.schoolUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.schoolUid)
      }
      stmt.setString(2, entity.schoolName)
      stmt.setString(3, entity.schoolDesc)
      stmt.setString(4, entity.schoolAddress)
      stmt.setBoolean(5, entity.schoolActive)
      stmt.setString(6, entity.schoolPhoneNumber)
      stmt.setInt(7, entity.schoolGender)
      stmt.setLong(8, entity.schoolHolidayCalendarUid)
      stmt.setLong(9, entity.schoolFeatures)
      stmt.setDouble(10, entity.schoolLocationLong)
      stmt.setDouble(11, entity.schoolLocationLatt)
      stmt.setString(12, entity.schoolEmailAddress)
      stmt.setLong(13, entity.schoolTeachersPersonGroupUid)
      stmt.setLong(14, entity.schoolStudentsPersonGroupUid)
      stmt.setLong(15, entity.schoolPendingStudentsPersonGroupUid)
      stmt.setString(16, entity.schoolCode)
      stmt.setLong(17, entity.schoolMasterChangeSeqNum)
      stmt.setLong(18, entity.schoolLocalChangeSeqNum)
      stmt.setInt(19, entity.schoolLastChangedBy)
      stmt.setLong(20, entity.schoolLct)
      stmt.setString(21, entity.schoolTimeZone)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO SchoolReplicate(schoolPk, schoolDestination)
    |      SELECT DISTINCT School.schoolUid AS schoolPk,
    |             ? AS schoolDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                  536870912
    |                  
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND School.schoolLct != COALESCE(
    |             (SELECT schoolVersionId
    |                FROM SchoolReplicate
    |               WHERE schoolPk = School.schoolUid
    |                 AND schoolDestination = ?), 0) 
    |      /*psql ON CONFLICT(schoolPk, schoolDestination) DO UPDATE
    |             SET schoolPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SchoolReplicate(schoolPk, schoolDestination)
    |      SELECT DISTINCT School.schoolUid AS schoolPk,
    |             ? AS schoolDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |                  536870912
    |                  
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND School.schoolLct != COALESCE(
    |             (SELECT schoolVersionId
    |                FROM SchoolReplicate
    |               WHERE schoolPk = School.schoolUid
    |                 AND schoolDestination = ?), 0) 
    |       ON CONFLICT(schoolPk, schoolDestination) DO UPDATE
    |             SET schoolPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO SchoolReplicate(schoolPk, schoolDestination)
    |  SELECT DISTINCT School.schoolUid AS schoolUid,
    |         UserSession.usClientNodeId AS schoolDestination
    |    FROM ChangeLog
    |         JOIN School
    |              ON ChangeLog.chTableId = 164
    |                  AND ChangeLog.chEntityPk = School.schoolUid
    |              
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |                  536870912
    |                  
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND School.schoolLct != COALESCE(
    |         (SELECT schoolVersionId
    |            FROM SchoolReplicate
    |           WHERE schoolPk = School.schoolUid
    |             AND schoolDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(schoolPk, schoolDestination) DO UPDATE
    |     SET schoolPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SchoolReplicate(schoolPk, schoolDestination)
    |  SELECT DISTINCT School.schoolUid AS schoolUid,
    |         UserSession.usClientNodeId AS schoolDestination
    |    FROM ChangeLog
    |         JOIN School
    |              ON ChangeLog.chTableId = 164
    |                  AND ChangeLog.chEntityPk = School.schoolUid
    |              
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |                  536870912
    |                  
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND School.schoolLct != COALESCE(
    |         (SELECT schoolVersionId
    |            FROM SchoolReplicate
    |           WHERE schoolPk = School.schoolUid
    |             AND schoolDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(schoolPk, schoolDestination) DO UPDATE
    |     SET schoolPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByUidAsync(schoolUid: Long): School? {
    var _result = null as com.ustadmobile.lib.db.entities.School??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM School WHERE schoolUid = ? AND CAST(schoolActive AS INTEGER) = 1"
        , postgreSql = """
    |SELECT * FROM School WHERE schoolUid = ? AND CAST(schoolActive AS INTEGER) = 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, schoolUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_schoolUid = _resultSet.getLong("schoolUid")
          val tmp_schoolName = _resultSet.getString("schoolName")
          val tmp_schoolDesc = _resultSet.getString("schoolDesc")
          val tmp_schoolAddress = _resultSet.getString("schoolAddress")
          val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
          val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
          val tmp_schoolGender = _resultSet.getInt("schoolGender")
          val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
          val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
          val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
          val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
          val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
          val tmp_schoolTeachersPersonGroupUid = _resultSet.getLong("schoolTeachersPersonGroupUid")
          val tmp_schoolStudentsPersonGroupUid = _resultSet.getLong("schoolStudentsPersonGroupUid")
          val tmp_schoolPendingStudentsPersonGroupUid =
              _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
          val tmp_schoolCode = _resultSet.getString("schoolCode")
          val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
          val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
          val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
          val tmp_schoolLct = _resultSet.getLong("schoolLct")
          val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
          val _entity = School()
          _entity.schoolUid = tmp_schoolUid
          _entity.schoolName = tmp_schoolName
          _entity.schoolDesc = tmp_schoolDesc
          _entity.schoolAddress = tmp_schoolAddress
          _entity.schoolActive = tmp_schoolActive
          _entity.schoolPhoneNumber = tmp_schoolPhoneNumber
          _entity.schoolGender = tmp_schoolGender
          _entity.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
          _entity.schoolFeatures = tmp_schoolFeatures
          _entity.schoolLocationLong = tmp_schoolLocationLong
          _entity.schoolLocationLatt = tmp_schoolLocationLatt
          _entity.schoolEmailAddress = tmp_schoolEmailAddress
          _entity.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
          _entity.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
          _entity.schoolPendingStudentsPersonGroupUid = tmp_schoolPendingStudentsPersonGroupUid
          _entity.schoolCode = tmp_schoolCode
          _entity.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
          _entity.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
          _entity.schoolLastChangedBy = tmp_schoolLastChangedBy
          _entity.schoolLct = tmp_schoolLct
          _entity.schoolTimeZone = tmp_schoolTimeZone
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      SchoolWithHolidayCalendar? {
    var _result = null as com.ustadmobile.lib.db.entities.SchoolWithHolidayCalendar??
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT School.*, HolidayCalendar.* FROM School 
    |            LEFT JOIN HolidayCalendar ON School.schoolHolidayCalendarUid = HolidayCalendar.umCalendarUid
    |            WHERE School.schoolUid = ?
    """.trimMargin() , postgreSql = """
    |SELECT School.*, HolidayCalendar.* FROM School 
    |            LEFT JOIN HolidayCalendar ON School.schoolHolidayCalendarUid = HolidayCalendar.umCalendarUid
    |            WHERE School.schoolUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_schoolUid = _resultSet.getLong("schoolUid")
          val tmp_schoolName = _resultSet.getString("schoolName")
          val tmp_schoolDesc = _resultSet.getString("schoolDesc")
          val tmp_schoolAddress = _resultSet.getString("schoolAddress")
          val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
          val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
          val tmp_schoolGender = _resultSet.getInt("schoolGender")
          val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
          val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
          val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
          val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
          val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
          val tmp_schoolTeachersPersonGroupUid = _resultSet.getLong("schoolTeachersPersonGroupUid")
          val tmp_schoolStudentsPersonGroupUid = _resultSet.getLong("schoolStudentsPersonGroupUid")
          val tmp_schoolPendingStudentsPersonGroupUid =
              _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
          val tmp_schoolCode = _resultSet.getString("schoolCode")
          val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
          val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
          val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
          val tmp_schoolLct = _resultSet.getLong("schoolLct")
          val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
          val _entity = SchoolWithHolidayCalendar()
          _entity.schoolUid = tmp_schoolUid
          _entity.schoolName = tmp_schoolName
          _entity.schoolDesc = tmp_schoolDesc
          _entity.schoolAddress = tmp_schoolAddress
          _entity.schoolActive = tmp_schoolActive
          _entity.schoolPhoneNumber = tmp_schoolPhoneNumber
          _entity.schoolGender = tmp_schoolGender
          _entity.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
          _entity.schoolFeatures = tmp_schoolFeatures
          _entity.schoolLocationLong = tmp_schoolLocationLong
          _entity.schoolLocationLatt = tmp_schoolLocationLatt
          _entity.schoolEmailAddress = tmp_schoolEmailAddress
          _entity.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
          _entity.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
          _entity.schoolPendingStudentsPersonGroupUid = tmp_schoolPendingStudentsPersonGroupUid
          _entity.schoolCode = tmp_schoolCode
          _entity.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
          _entity.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
          _entity.schoolLastChangedBy = tmp_schoolLastChangedBy
          _entity.schoolLct = tmp_schoolLct
          _entity.schoolTimeZone = tmp_schoolTimeZone
          var _holidayCalendar_nullFieldCount = 0
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarName = _resultSet.getString("umCalendarName")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarMasterChangeSeqNum = _resultSet.getLong("umCalendarMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLocalChangeSeqNum = _resultSet.getLong("umCalendarLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          if(_holidayCalendar_nullFieldCount < 8) {
            if(_entity.holidayCalendar == null) {
              _entity.holidayCalendar = HolidayCalendar()
            }
            _entity.holidayCalendar!!.umCalendarUid = tmp_umCalendarUid
            _entity.holidayCalendar!!.umCalendarName = tmp_umCalendarName
            _entity.holidayCalendar!!.umCalendarCategory = tmp_umCalendarCategory
            _entity.holidayCalendar!!.umCalendarActive = tmp_umCalendarActive
            _entity.holidayCalendar!!.umCalendarMasterChangeSeqNum =
                tmp_umCalendarMasterChangeSeqNum
            _entity.holidayCalendar!!.umCalendarLocalChangeSeqNum = tmp_umCalendarLocalChangeSeqNum
            _entity.holidayCalendar!!.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
            _entity.holidayCalendar!!.umCalendarLct = tmp_umCalendarLct
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findBySchoolCode(code: String): School? {
    var _result = null as com.ustadmobile.lib.db.entities.School??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM School WHERE schoolCode = ?" ,
        postgreSql = """
    |SELECT * FROM School WHERE schoolCode = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, code)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_schoolUid = _resultSet.getLong("schoolUid")
          val tmp_schoolName = _resultSet.getString("schoolName")
          val tmp_schoolDesc = _resultSet.getString("schoolDesc")
          val tmp_schoolAddress = _resultSet.getString("schoolAddress")
          val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
          val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
          val tmp_schoolGender = _resultSet.getInt("schoolGender")
          val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
          val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
          val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
          val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
          val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
          val tmp_schoolTeachersPersonGroupUid = _resultSet.getLong("schoolTeachersPersonGroupUid")
          val tmp_schoolStudentsPersonGroupUid = _resultSet.getLong("schoolStudentsPersonGroupUid")
          val tmp_schoolPendingStudentsPersonGroupUid =
              _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
          val tmp_schoolCode = _resultSet.getString("schoolCode")
          val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
          val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
          val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
          val tmp_schoolLct = _resultSet.getLong("schoolLct")
          val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
          val _entity = School()
          _entity.schoolUid = tmp_schoolUid
          _entity.schoolName = tmp_schoolName
          _entity.schoolDesc = tmp_schoolDesc
          _entity.schoolAddress = tmp_schoolAddress
          _entity.schoolActive = tmp_schoolActive
          _entity.schoolPhoneNumber = tmp_schoolPhoneNumber
          _entity.schoolGender = tmp_schoolGender
          _entity.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
          _entity.schoolFeatures = tmp_schoolFeatures
          _entity.schoolLocationLong = tmp_schoolLocationLong
          _entity.schoolLocationLatt = tmp_schoolLocationLatt
          _entity.schoolEmailAddress = tmp_schoolEmailAddress
          _entity.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
          _entity.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
          _entity.schoolPendingStudentsPersonGroupUid = tmp_schoolPendingStudentsPersonGroupUid
          _entity.schoolCode = tmp_schoolCode
          _entity.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
          _entity.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
          _entity.schoolLastChangedBy = tmp_schoolLastChangedBy
          _entity.schoolLct = tmp_schoolLct
          _entity.schoolTimeZone = tmp_schoolTimeZone
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findBySchoolCodeFromWeb(code: String): School? {
    var _result = null as com.ustadmobile.lib.db.entities.School??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM School WHERE schoolCode = ?" ,
        postgreSql = """
    |SELECT * FROM School WHERE schoolCode = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, code)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_schoolUid = _resultSet.getLong("schoolUid")
          val tmp_schoolName = _resultSet.getString("schoolName")
          val tmp_schoolDesc = _resultSet.getString("schoolDesc")
          val tmp_schoolAddress = _resultSet.getString("schoolAddress")
          val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
          val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
          val tmp_schoolGender = _resultSet.getInt("schoolGender")
          val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
          val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
          val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
          val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
          val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
          val tmp_schoolTeachersPersonGroupUid = _resultSet.getLong("schoolTeachersPersonGroupUid")
          val tmp_schoolStudentsPersonGroupUid = _resultSet.getLong("schoolStudentsPersonGroupUid")
          val tmp_schoolPendingStudentsPersonGroupUid =
              _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
          val tmp_schoolCode = _resultSet.getString("schoolCode")
          val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
          val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
          val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
          val tmp_schoolLct = _resultSet.getLong("schoolLct")
          val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
          val _entity = School()
          _entity.schoolUid = tmp_schoolUid
          _entity.schoolName = tmp_schoolName
          _entity.schoolDesc = tmp_schoolDesc
          _entity.schoolAddress = tmp_schoolAddress
          _entity.schoolActive = tmp_schoolActive
          _entity.schoolPhoneNumber = tmp_schoolPhoneNumber
          _entity.schoolGender = tmp_schoolGender
          _entity.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
          _entity.schoolFeatures = tmp_schoolFeatures
          _entity.schoolLocationLong = tmp_schoolLocationLong
          _entity.schoolLocationLatt = tmp_schoolLocationLatt
          _entity.schoolEmailAddress = tmp_schoolEmailAddress
          _entity.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
          _entity.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
          _entity.schoolPendingStudentsPersonGroupUid = tmp_schoolPendingStudentsPersonGroupUid
          _entity.schoolCode = tmp_schoolCode
          _entity.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
          _entity.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
          _entity.schoolLastChangedBy = tmp_schoolLastChangedBy
          _entity.schoolLct = tmp_schoolLct
          _entity.schoolTimeZone = tmp_schoolTimeZone
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun personHasPermissionWithSchool(
    accountPersonUid: Long,
    schoolUid: Long,
    permission: Long
  ): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |    Select EXISTS(
    |           SELECT School.schoolUid 
    |             FROM School
    |                  
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |         ?) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                      AND PrsGrpMbr.groupMemberPersonUid = ?
    |            WHERE School.schoolUid = ?)      
    |    
    """.trimMargin() , postgreSql = """
    |
    |    Select EXISTS(
    |           SELECT School.schoolUid 
    |             FROM School
    |                  
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |         ?) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                      AND PrsGrpMbr.groupMemberPersonUid = ?
    |            WHERE School.schoolUid = ?)      
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, permission)
      _stmt.setLong(2, accountPersonUid)
      _stmt.setLong(3, schoolUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findAllActiveSchoolWithMemberCountAndLocationName(
    searchBit: String,
    personUid: Long,
    permission: Long,
    sortOrder: Int
  ): DoorDataSourceFactory<Int, SchoolWithMemberCountAndLocation> {
    val _result = object : DoorDataSourceFactory<Int, SchoolWithMemberCountAndLocation>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<SchoolWithMemberCountAndLocation>> =
          DoorLiveDataImpl<List<SchoolWithMemberCountAndLocation>>(_db, listOf("SchoolMember",
          "Clazz", "PersonGroupMember", "ScopedGrant", "School"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.SchoolWithMemberCountAndLocation>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |       SELECT School.*, 
        |              (SELECT COUNT(*) 
        |                  FROM SchoolMember 
        |                 WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
        |                   AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
        |                   AND SchoolMember.schoolMemberRole = 1003) as numStudents,
        |              (SELECT COUNT(*) 
        |                 FROM SchoolMember 
        |                WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
        |                  AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
        |                  AND SchoolMember.schoolMemberRole = 1004) as numTeachers, 
        |               '' as locationName,
        |              (SELECT COUNT(*) 
        |                 FROM Clazz 
        |                WHERE Clazz.clazzSchoolUid = School.schoolUid 
        |                  AND CAST(Clazz.clazzUid AS INTEGER) = 1 ) as clazzCount
        |         FROM PersonGroupMember
        |              
        |            JOIN ScopedGrant 
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                        AND (ScopedGrant.sgPermissions &
        |                 
        |        
        |                    ?
        |                    
        |                    ) > 0
        |            JOIN School
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                OR (ScopedGrant.sgTableId = 164
        |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
        |        
        |        
        |        WHERE PersonGroupMember.groupMemberPersonUid = ?
        |          AND PersonGroupMember.groupMemberActive 
        |          AND CAST(schoolActive AS INTEGER) = 1
        |          AND schoolName LIKE ?
        |     GROUP BY School.schoolUid
        |     ORDER BY CASE(?)
        |              WHEN 1 THEN School.schoolName
        |              ELSE ''
        |              END ASC,
        |              CASE(?)
        |              WHEN 2 THEN School.schoolName
        |              ELSE ''
        |              END DESC) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |       SELECT School.*, 
        |              (SELECT COUNT(*) 
        |                  FROM SchoolMember 
        |                 WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
        |                   AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
        |                   AND SchoolMember.schoolMemberRole = 1003) as numStudents,
        |              (SELECT COUNT(*) 
        |                 FROM SchoolMember 
        |                WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
        |                  AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
        |                  AND SchoolMember.schoolMemberRole = 1004) as numTeachers, 
        |               '' as locationName,
        |              (SELECT COUNT(*) 
        |                 FROM Clazz 
        |                WHERE Clazz.clazzSchoolUid = School.schoolUid 
        |                  AND CAST(Clazz.clazzUid AS INTEGER) = 1 ) as clazzCount
        |         FROM PersonGroupMember
        |              
        |            JOIN ScopedGrant 
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                        AND (ScopedGrant.sgPermissions &
        |                 
        |        
        |                    ?
        |                    
        |                    ) > 0
        |            JOIN School
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                OR (ScopedGrant.sgTableId = 164
        |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
        |        
        |        
        |        WHERE PersonGroupMember.groupMemberPersonUid = ?
        |          AND PersonGroupMember.groupMemberActive 
        |          AND CAST(schoolActive AS INTEGER) = 1
        |          AND schoolName LIKE ?
        |     GROUP BY School.schoolUid
        |     ORDER BY CASE(?)
        |              WHEN 1 THEN School.schoolName
        |              ELSE ''
        |              END ASC,
        |              CASE(?)
        |              WHEN 2 THEN School.schoolName
        |              ELSE ''
        |              END DESC) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, permission)
          _stmt.setLong(2, personUid)
          _stmt.setString(3, searchBit)
          _stmt.setInt(4, sortOrder)
          _stmt.setInt(5, sortOrder)
          _stmt.setInt(6, _limit)
          _stmt.setInt(7, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_numStudents = _resultSet.getInt("numStudents")
              val tmp_numTeachers = _resultSet.getInt("numTeachers")
              val tmp_locationName = _resultSet.getString("locationName")
              val tmp_clazzCount = _resultSet.getInt("clazzCount")
              val tmp_schoolUid = _resultSet.getLong("schoolUid")
              val tmp_schoolName = _resultSet.getString("schoolName")
              val tmp_schoolDesc = _resultSet.getString("schoolDesc")
              val tmp_schoolAddress = _resultSet.getString("schoolAddress")
              val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
              val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
              val tmp_schoolGender = _resultSet.getInt("schoolGender")
              val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
              val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
              val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
              val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
              val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
              val tmp_schoolTeachersPersonGroupUid =
                  _resultSet.getLong("schoolTeachersPersonGroupUid")
              val tmp_schoolStudentsPersonGroupUid =
                  _resultSet.getLong("schoolStudentsPersonGroupUid")
              val tmp_schoolPendingStudentsPersonGroupUid =
                  _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
              val tmp_schoolCode = _resultSet.getString("schoolCode")
              val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
              val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
              val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
              val tmp_schoolLct = _resultSet.getLong("schoolLct")
              val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
              val _entity = SchoolWithMemberCountAndLocation()
              _entity.numStudents = tmp_numStudents
              _entity.numTeachers = tmp_numTeachers
              _entity.locationName = tmp_locationName
              _entity.clazzCount = tmp_clazzCount
              _entity.schoolUid = tmp_schoolUid
              _entity.schoolName = tmp_schoolName
              _entity.schoolDesc = tmp_schoolDesc
              _entity.schoolAddress = tmp_schoolAddress
              _entity.schoolActive = tmp_schoolActive
              _entity.schoolPhoneNumber = tmp_schoolPhoneNumber
              _entity.schoolGender = tmp_schoolGender
              _entity.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
              _entity.schoolFeatures = tmp_schoolFeatures
              _entity.schoolLocationLong = tmp_schoolLocationLong
              _entity.schoolLocationLatt = tmp_schoolLocationLatt
              _entity.schoolEmailAddress = tmp_schoolEmailAddress
              _entity.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
              _entity.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
              _entity.schoolPendingStudentsPersonGroupUid = tmp_schoolPendingStudentsPersonGroupUid
              _entity.schoolCode = tmp_schoolCode
              _entity.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
              _entity.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
              _entity.schoolLastChangedBy = tmp_schoolLastChangedBy
              _entity.schoolLct = tmp_schoolLct
              _entity.schoolTimeZone = tmp_schoolTimeZone
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("SchoolMember", "Clazz", "PersonGroupMember", "ScopedGrant", "School"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |       SELECT School.*, 
        |              (SELECT COUNT(*) 
        |                  FROM SchoolMember 
        |                 WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
        |                   AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
        |                   AND SchoolMember.schoolMemberRole = 1003) as numStudents,
        |              (SELECT COUNT(*) 
        |                 FROM SchoolMember 
        |                WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
        |                  AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
        |                  AND SchoolMember.schoolMemberRole = 1004) as numTeachers, 
        |               '' as locationName,
        |              (SELECT COUNT(*) 
        |                 FROM Clazz 
        |                WHERE Clazz.clazzSchoolUid = School.schoolUid 
        |                  AND CAST(Clazz.clazzUid AS INTEGER) = 1 ) as clazzCount
        |         FROM PersonGroupMember
        |              
        |            JOIN ScopedGrant 
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                        AND (ScopedGrant.sgPermissions &
        |                 
        |        
        |                    ?
        |                    
        |                    ) > 0
        |            JOIN School
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                OR (ScopedGrant.sgTableId = 164
        |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
        |        
        |        
        |        WHERE PersonGroupMember.groupMemberPersonUid = ?
        |          AND PersonGroupMember.groupMemberActive 
        |          AND CAST(schoolActive AS INTEGER) = 1
        |          AND schoolName LIKE ?
        |     GROUP BY School.schoolUid
        |     ORDER BY CASE(?)
        |              WHEN 1 THEN School.schoolName
        |              ELSE ''
        |              END ASC,
        |              CASE(?)
        |              WHEN 2 THEN School.schoolName
        |              ELSE ''
        |              END DESC) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |       SELECT School.*, 
        |              (SELECT COUNT(*) 
        |                  FROM SchoolMember 
        |                 WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
        |                   AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
        |                   AND SchoolMember.schoolMemberRole = 1003) as numStudents,
        |              (SELECT COUNT(*) 
        |                 FROM SchoolMember 
        |                WHERE SchoolMember.schoolMemberSchoolUid = School.schoolUid 
        |                  AND CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1 
        |                  AND SchoolMember.schoolMemberRole = 1004) as numTeachers, 
        |               '' as locationName,
        |              (SELECT COUNT(*) 
        |                 FROM Clazz 
        |                WHERE Clazz.clazzSchoolUid = School.schoolUid 
        |                  AND CAST(Clazz.clazzUid AS INTEGER) = 1 ) as clazzCount
        |         FROM PersonGroupMember
        |              
        |            JOIN ScopedGrant 
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                        AND (ScopedGrant.sgPermissions &
        |                 
        |        
        |                    ?
        |                    
        |                    ) > 0
        |            JOIN School
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                OR (ScopedGrant.sgTableId = 164
        |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
        |        
        |        
        |        WHERE PersonGroupMember.groupMemberPersonUid = ?
        |          AND PersonGroupMember.groupMemberActive 
        |          AND CAST(schoolActive AS INTEGER) = 1
        |          AND schoolName LIKE ?
        |     GROUP BY School.schoolUid
        |     ORDER BY CASE(?)
        |              WHEN 1 THEN School.schoolName
        |              ELSE ''
        |              END ASC,
        |              CASE(?)
        |              WHEN 2 THEN School.schoolName
        |              ELSE ''
        |              END DESC) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, permission)
          _stmt.setLong(2, personUid)
          _stmt.setString(3, searchBit)
          _stmt.setInt(4, sortOrder)
          _stmt.setInt(5, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: School): Int {
    var _result = 0
    val _sql =
        "UPDATE School SET schoolName = ?, schoolDesc = ?, schoolAddress = ?, schoolActive = ?, schoolPhoneNumber = ?, schoolGender = ?, schoolHolidayCalendarUid = ?, schoolFeatures = ?, schoolLocationLong = ?, schoolLocationLatt = ?, schoolEmailAddress = ?, schoolTeachersPersonGroupUid = ?, schoolStudentsPersonGroupUid = ?, schoolPendingStudentsPersonGroupUid = ?, schoolCode = ?, schoolMasterChangeSeqNum = ?, schoolLocalChangeSeqNum = ?, schoolLastChangedBy = ?, schoolLct = ?, schoolTimeZone = ? WHERE schoolUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.schoolName)
      _stmt.setString(2, entity.schoolDesc)
      _stmt.setString(3, entity.schoolAddress)
      _stmt.setBoolean(4, entity.schoolActive)
      _stmt.setString(5, entity.schoolPhoneNumber)
      _stmt.setInt(6, entity.schoolGender)
      _stmt.setLong(7, entity.schoolHolidayCalendarUid)
      _stmt.setLong(8, entity.schoolFeatures)
      _stmt.setDouble(9, entity.schoolLocationLong)
      _stmt.setDouble(10, entity.schoolLocationLatt)
      _stmt.setString(11, entity.schoolEmailAddress)
      _stmt.setLong(12, entity.schoolTeachersPersonGroupUid)
      _stmt.setLong(13, entity.schoolStudentsPersonGroupUid)
      _stmt.setLong(14, entity.schoolPendingStudentsPersonGroupUid)
      _stmt.setString(15, entity.schoolCode)
      _stmt.setLong(16, entity.schoolMasterChangeSeqNum)
      _stmt.setLong(17, entity.schoolLocalChangeSeqNum)
      _stmt.setInt(18, entity.schoolLastChangedBy)
      _stmt.setLong(19, entity.schoolLct)
      _stmt.setString(20, entity.schoolTimeZone)
      _stmt.setLong(21, entity.schoolUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun insert(entity: School): Long {
    val _retVal = _insertAdapterSchool_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: School): Long {
    val _retVal = _insertAdapterSchool_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out School>): Unit {
    _insertAdapterSchool_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out School>): Unit {
    val _sql =
        "UPDATE School SET schoolName = ?, schoolDesc = ?, schoolAddress = ?, schoolActive = ?, schoolPhoneNumber = ?, schoolGender = ?, schoolHolidayCalendarUid = ?, schoolFeatures = ?, schoolLocationLong = ?, schoolLocationLatt = ?, schoolEmailAddress = ?, schoolTeachersPersonGroupUid = ?, schoolStudentsPersonGroupUid = ?, schoolPendingStudentsPersonGroupUid = ?, schoolCode = ?, schoolMasterChangeSeqNum = ?, schoolLocalChangeSeqNum = ?, schoolLastChangedBy = ?, schoolLct = ?, schoolTimeZone = ? WHERE schoolUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.schoolName)
        _stmt.setString(2, _entity.schoolDesc)
        _stmt.setString(3, _entity.schoolAddress)
        _stmt.setBoolean(4, _entity.schoolActive)
        _stmt.setString(5, _entity.schoolPhoneNumber)
        _stmt.setInt(6, _entity.schoolGender)
        _stmt.setLong(7, _entity.schoolHolidayCalendarUid)
        _stmt.setLong(8, _entity.schoolFeatures)
        _stmt.setDouble(9, _entity.schoolLocationLong)
        _stmt.setDouble(10, _entity.schoolLocationLatt)
        _stmt.setString(11, _entity.schoolEmailAddress)
        _stmt.setLong(12, _entity.schoolTeachersPersonGroupUid)
        _stmt.setLong(13, _entity.schoolStudentsPersonGroupUid)
        _stmt.setLong(14, _entity.schoolPendingStudentsPersonGroupUid)
        _stmt.setString(15, _entity.schoolCode)
        _stmt.setLong(16, _entity.schoolMasterChangeSeqNum)
        _stmt.setLong(17, _entity.schoolLocalChangeSeqNum)
        _stmt.setInt(18, _entity.schoolLastChangedBy)
        _stmt.setLong(19, _entity.schoolLct)
        _stmt.setString(20, _entity.schoolTimeZone)
        _stmt.setLong(21, _entity.schoolUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: School): Unit {
    val _sql =
        "UPDATE School SET schoolName = ?, schoolDesc = ?, schoolAddress = ?, schoolActive = ?, schoolPhoneNumber = ?, schoolGender = ?, schoolHolidayCalendarUid = ?, schoolFeatures = ?, schoolLocationLong = ?, schoolLocationLatt = ?, schoolEmailAddress = ?, schoolTeachersPersonGroupUid = ?, schoolStudentsPersonGroupUid = ?, schoolPendingStudentsPersonGroupUid = ?, schoolCode = ?, schoolMasterChangeSeqNum = ?, schoolLocalChangeSeqNum = ?, schoolLastChangedBy = ?, schoolLct = ?, schoolTimeZone = ? WHERE schoolUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.schoolName)
      _stmt.setString(2, entity.schoolDesc)
      _stmt.setString(3, entity.schoolAddress)
      _stmt.setBoolean(4, entity.schoolActive)
      _stmt.setString(5, entity.schoolPhoneNumber)
      _stmt.setInt(6, entity.schoolGender)
      _stmt.setLong(7, entity.schoolHolidayCalendarUid)
      _stmt.setLong(8, entity.schoolFeatures)
      _stmt.setDouble(9, entity.schoolLocationLong)
      _stmt.setDouble(10, entity.schoolLocationLatt)
      _stmt.setString(11, entity.schoolEmailAddress)
      _stmt.setLong(12, entity.schoolTeachersPersonGroupUid)
      _stmt.setLong(13, entity.schoolStudentsPersonGroupUid)
      _stmt.setLong(14, entity.schoolPendingStudentsPersonGroupUid)
      _stmt.setString(15, entity.schoolCode)
      _stmt.setLong(16, entity.schoolMasterChangeSeqNum)
      _stmt.setLong(17, entity.schoolLocalChangeSeqNum)
      _stmt.setInt(18, entity.schoolLastChangedBy)
      _stmt.setLong(19, entity.schoolLct)
      _stmt.setString(20, entity.schoolTimeZone)
      _stmt.setLong(21, entity.schoolUid)
      _stmt.executeUpdate()
    }
  }
}
