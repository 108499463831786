package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.UserSession
import com.ustadmobile.lib.db.entities.UserSessionAndPerson
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class UserSessionDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: UserSessionDao
) : UserSessionDao() {
  public override suspend fun updateReplicationTrackers(): Unit {
    _dao.updateReplicationTrackers()
  }

  public override suspend fun updateReplicationTrackersOnNewNode(newNodeId: Long): Unit {
    _dao.updateReplicationTrackersOnNewNode(newNodeId)
  }

  public override suspend fun insertSession(session: UserSession): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(session.usUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(679)
      session.usUid = _newPk
    }
    session.usLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertSession(session)
    return session.usUid
  }

  public override suspend fun findSessionsByPerson(personUid: Long): List<UserSession> =
      _dao.findSessionsByPerson(personUid)

  public override fun findAllLocalSessionsLive(): DoorLiveData<List<UserSessionAndPerson>> =
      _dao.findAllLocalSessionsLive()

  public override suspend fun findAllLocalSessionsAsync(): List<UserSessionAndPerson> =
      _dao.findAllLocalSessionsAsync()

  public override suspend fun countAllLocalSessionsAsync(maxDateOfBirth: Long): Int =
      _dao.countAllLocalSessionsAsync(maxDateOfBirth)

  public override suspend fun endSession(
    sessionUid: Long,
    newStatus: Int,
    reason: Int
  ): Unit {
    _dao.endSession(sessionUid, newStatus, reason)
  }

  public override fun findByUidLive(sessionUid: Long): DoorLiveData<UserSession?> =
      _dao.findByUidLive(sessionUid)

  public override suspend fun endOtherSessions(
    personUid: Long,
    exemptNodeId: Long,
    newStatus: Int,
    reason: Int,
    changeTime: Long
  ): Unit {
    _dao.endOtherSessions(personUid, exemptNodeId, newStatus, reason, changeTime)
  }

  public override suspend fun findActiveNodeIdsByPersonUids(personUids: List<Long>): List<Long> =
      _dao.findActiveNodeIdsByPersonUids(personUids)

  public override suspend fun findActiveNodesIdsByGroupUids(groupUids: List<Long>): List<Long> =
      _dao.findActiveNodesIdsByGroupUids(groupUids)

  public override suspend fun findAllActiveNodeIdsWithClazzBasedPermission(clazzUids: List<Long>):
      List<Long> = _dao.findAllActiveNodeIdsWithClazzBasedPermission(clazzUids)

  public override suspend fun findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids: List<Long>):
      List<Long> = _dao.findAllActiveNodeIdsWithSchoolBasedPermission(schoolUids)
}
