package com.ustadmobile.core.db

import com.ustadmobile.door.ext.DoorDatabaseMetadata
import com.ustadmobile.door.util.DoorJsImplClasses
import kotlin.reflect.KClass

public object UmAppDatabaseJsImplementations : DoorJsImplClasses<UmAppDatabase>() {
  public override val dbKClass: KClass<UmAppDatabase> = UmAppDatabase::class

  public override val dbImplKClass: KClass<*> = UmAppDatabase_JdbcKt::class

  public override val replicateWrapperImplClass: KClass<*>? = UmAppDatabase_ReplicateWrapper::class

  public override val repositoryImplClass: KClass<*>? = UmAppDatabase_Repo::class

  public override val metadata: DoorDatabaseMetadata<UmAppDatabase> = UmAppDatabase_DoorMetadata()
}
