package com.ustadmobile.core.db

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Language
import com.ustadmobile.lib.db.entities.SiteTerms
import com.ustadmobile.lib.db.entities.SiteTermsWithLanguage
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SiteTermsDao_JdbcKt(
  public val _db: DoorDatabase
) : SiteTermsDao() {
  public val _insertAdapterSiteTerms_: EntityInsertionAdapter<SiteTerms> = object :
      EntityInsertionAdapter<SiteTerms>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO SiteTerms (sTermsUid, termsHtml, sTermsLang, sTermsLangUid, sTermsActive, sTermsLastChangedBy, sTermsPrimaryCsn, sTermsLocalCsn, sTermsLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO SiteTerms (sTermsUid, termsHtml, sTermsLang, sTermsLangUid, sTermsActive, sTermsLastChangedBy, sTermsPrimaryCsn, sTermsLocalCsn, sTermsLct) VALUES(COALESCE(?,nextval('SiteTerms_sTermsUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING sTermsUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: SiteTerms): Unit {
      if(entity.sTermsUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.sTermsUid)
      }
      stmt.setString(2, entity.termsHtml)
      stmt.setString(3, entity.sTermsLang)
      stmt.setLong(4, entity.sTermsLangUid)
      stmt.setBoolean(5, entity.sTermsActive)
      stmt.setInt(6, entity.sTermsLastChangedBy)
      stmt.setLong(7, entity.sTermsPrimaryCsn)
      stmt.setLong(8, entity.sTermsLocalCsn)
      stmt.setLong(9, entity.sTermsLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO SiteTermsReplicate(stPk, stDestination)
    |      SELECT DISTINCT SiteTerms.sTermsUid AS stPk,
    |             ? AS stDestination
    |        FROM SiteTerms
    |       WHERE SiteTerms.sTermsLct != COALESCE(
    |             (SELECT stVersionId
    |                FROM SiteTermsReplicate
    |               WHERE stPk = SiteTerms.sTermsUid
    |                 AND stDestination = ?), 0) 
    |      /*psql ON CONFLICT(stPk, stDestination) DO UPDATE
    |             SET stPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SiteTermsReplicate(stPk, stDestination)
    |      SELECT DISTINCT SiteTerms.sTermsUid AS stPk,
    |             ? AS stDestination
    |        FROM SiteTerms
    |       WHERE SiteTerms.sTermsLct != COALESCE(
    |             (SELECT stVersionId
    |                FROM SiteTermsReplicate
    |               WHERE stPk = SiteTerms.sTermsUid
    |                 AND stDestination = ?), 0) 
    |       ON CONFLICT(stPk, stDestination) DO UPDATE
    |             SET stPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO SiteTermsReplicate(stPk, stDestination)
    |  SELECT DISTINCT SiteTerms.sTermsUid AS stUid,
    |         UserSession.usClientNodeId AS stDestination
    |    FROM ChangeLog
    |         JOIN SiteTerms
    |             ON ChangeLog.chTableId = 272
    |                AND ChangeLog.chEntityPk = SiteTerms.sTermsUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND SiteTerms.sTermsLct != COALESCE(
    |         (SELECT stVersionId
    |            FROM SiteTermsReplicate
    |           WHERE stPk = SiteTerms.sTermsUid
    |             AND stDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(stPk, stDestination) DO UPDATE
    |     SET stPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO SiteTermsReplicate(stPk, stDestination)
    |  SELECT DISTINCT SiteTerms.sTermsUid AS stUid,
    |         UserSession.usClientNodeId AS stDestination
    |    FROM ChangeLog
    |         JOIN SiteTerms
    |             ON ChangeLog.chTableId = 272
    |                AND ChangeLog.chEntityPk = SiteTerms.sTermsUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND SiteTerms.sTermsLct != COALESCE(
    |         (SELECT stVersionId
    |            FROM SiteTermsReplicate
    |           WHERE stPk = SiteTerms.sTermsUid
    |             AND stDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(stPk, stDestination) DO UPDATE
    |     SET stPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findSiteTerms(langCode: String): SiteTerms? {
    var _result = null as com.ustadmobile.lib.db.entities.SiteTerms??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * FROM SiteTerms WHERE sTermsUid = coalesce(
    |            (SELECT sTermsUid FROM SiteTerms st_int WHERE st_int.sTermsLang = ? LIMIT 1),
    |            (SELECT sTermsUid FROM SiteTerms st_int WHERE st_int.sTermsLang = 'en' LIMIT 1),
    |            0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * FROM SiteTerms WHERE sTermsUid = coalesce(
    |            (SELECT sTermsUid FROM SiteTerms st_int WHERE st_int.sTermsLang = ? LIMIT 1),
    |            (SELECT sTermsUid FROM SiteTerms st_int WHERE st_int.sTermsLang = 'en' LIMIT 1),
    |            0)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, langCode)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_sTermsUid = _resultSet.getLong("sTermsUid")
          val tmp_termsHtml = _resultSet.getString("termsHtml")
          val tmp_sTermsLang = _resultSet.getString("sTermsLang")
          val tmp_sTermsLangUid = _resultSet.getLong("sTermsLangUid")
          val tmp_sTermsActive = _resultSet.getBoolean("sTermsActive")
          val tmp_sTermsLastChangedBy = _resultSet.getInt("sTermsLastChangedBy")
          val tmp_sTermsPrimaryCsn = _resultSet.getLong("sTermsPrimaryCsn")
          val tmp_sTermsLocalCsn = _resultSet.getLong("sTermsLocalCsn")
          val tmp_sTermsLct = _resultSet.getLong("sTermsLct")
          val _entity = SiteTerms()
          _entity.sTermsUid = tmp_sTermsUid
          _entity.termsHtml = tmp_termsHtml
          _entity.sTermsLang = tmp_sTermsLang
          _entity.sTermsLangUid = tmp_sTermsLangUid
          _entity.sTermsActive = tmp_sTermsActive
          _entity.sTermsLastChangedBy = tmp_sTermsLastChangedBy
          _entity.sTermsPrimaryCsn = tmp_sTermsPrimaryCsn
          _entity.sTermsLocalCsn = tmp_sTermsLocalCsn
          _entity.sTermsLct = tmp_sTermsLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun insertAsync(siteTerms: SiteTerms): Long {
    val _retVal = _insertAdapterSiteTerms_.insertAndReturnIdAsync(siteTerms)
    return _retVal
  }

  public override suspend fun findByUidAsync(uid: Long): SiteTerms? {
    var _result = null as com.ustadmobile.lib.db.entities.SiteTerms??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM SiteTerms WHERE sTermsUid = ?" ,
        postgreSql = """
    |SELECT * FROM SiteTerms WHERE sTermsUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_sTermsUid = _resultSet.getLong("sTermsUid")
          val tmp_termsHtml = _resultSet.getString("termsHtml")
          val tmp_sTermsLang = _resultSet.getString("sTermsLang")
          val tmp_sTermsLangUid = _resultSet.getLong("sTermsLangUid")
          val tmp_sTermsActive = _resultSet.getBoolean("sTermsActive")
          val tmp_sTermsLastChangedBy = _resultSet.getInt("sTermsLastChangedBy")
          val tmp_sTermsPrimaryCsn = _resultSet.getLong("sTermsPrimaryCsn")
          val tmp_sTermsLocalCsn = _resultSet.getLong("sTermsLocalCsn")
          val tmp_sTermsLct = _resultSet.getLong("sTermsLct")
          val _entity = SiteTerms()
          _entity.sTermsUid = tmp_sTermsUid
          _entity.termsHtml = tmp_termsHtml
          _entity.sTermsLang = tmp_sTermsLang
          _entity.sTermsLangUid = tmp_sTermsLangUid
          _entity.sTermsActive = tmp_sTermsActive
          _entity.sTermsLastChangedBy = tmp_sTermsLastChangedBy
          _entity.sTermsPrimaryCsn = tmp_sTermsPrimaryCsn
          _entity.sTermsLocalCsn = tmp_sTermsLocalCsn
          _entity.sTermsLct = tmp_sTermsLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findAllTermsAsFactory(): DoorDataSourceFactory<Int, SiteTermsWithLanguage> {
    val _result = object : DoorDataSourceFactory<Int, SiteTermsWithLanguage>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<SiteTermsWithLanguage>> =
          DoorLiveDataImpl<List<SiteTermsWithLanguage>>(_db, listOf("SiteTerms", "Language"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.SiteTermsWithLanguage>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT SiteTerms.*, Language.* 
        |        FROM SiteTerms 
        |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
        |        WHERE CAST(sTermsActive AS INTEGER) = 1
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT SiteTerms.*, Language.* 
        |        FROM SiteTerms 
        |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
        |        WHERE CAST(sTermsActive AS INTEGER) = 1
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, _limit)
          _stmt.setInt(2, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_sTermsUid = _resultSet.getLong("sTermsUid")
              val tmp_termsHtml = _resultSet.getString("termsHtml")
              val tmp_sTermsLang = _resultSet.getString("sTermsLang")
              val tmp_sTermsLangUid = _resultSet.getLong("sTermsLangUid")
              val tmp_sTermsActive = _resultSet.getBoolean("sTermsActive")
              val tmp_sTermsLastChangedBy = _resultSet.getInt("sTermsLastChangedBy")
              val tmp_sTermsPrimaryCsn = _resultSet.getLong("sTermsPrimaryCsn")
              val tmp_sTermsLocalCsn = _resultSet.getLong("sTermsLocalCsn")
              val tmp_sTermsLct = _resultSet.getLong("sTermsLct")
              val _entity = SiteTermsWithLanguage()
              _entity.sTermsUid = tmp_sTermsUid
              _entity.termsHtml = tmp_termsHtml
              _entity.sTermsLang = tmp_sTermsLang
              _entity.sTermsLangUid = tmp_sTermsLangUid
              _entity.sTermsActive = tmp_sTermsActive
              _entity.sTermsLastChangedBy = tmp_sTermsLastChangedBy
              _entity.sTermsPrimaryCsn = tmp_sTermsPrimaryCsn
              _entity.sTermsLocalCsn = tmp_sTermsLocalCsn
              _entity.sTermsLct = tmp_sTermsLct
              var _stLanguage_nullFieldCount = 0
              val tmp_langUid = _resultSet.getLong("langUid")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_name = _resultSet.getString("name")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_Language_Type = _resultSet.getString("Language_Type")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_languageActive = _resultSet.getBoolean("languageActive")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              val tmp_langLct = _resultSet.getLong("langLct")
              if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
              if(_stLanguage_nullFieldCount < 11) {
                if(_entity.stLanguage == null) {
                  _entity.stLanguage = Language()
                }
                _entity.stLanguage!!.langUid = tmp_langUid
                _entity.stLanguage!!.name = tmp_name
                _entity.stLanguage!!.iso_639_1_standard = tmp_iso_639_1_standard
                _entity.stLanguage!!.iso_639_2_standard = tmp_iso_639_2_standard
                _entity.stLanguage!!.iso_639_3_standard = tmp_iso_639_3_standard
                _entity.stLanguage!!.Language_Type = tmp_Language_Type
                _entity.stLanguage!!.languageActive = tmp_languageActive
                _entity.stLanguage!!.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
                _entity.stLanguage!!.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
                _entity.stLanguage!!.langLastChangedBy = tmp_langLastChangedBy
                _entity.stLanguage!!.langLct = tmp_langLct
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("SiteTerms", "Language"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT SiteTerms.*, Language.* 
        |        FROM SiteTerms 
        |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
        |        WHERE CAST(sTermsActive AS INTEGER) = 1
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT SiteTerms.*, Language.* 
        |        FROM SiteTerms 
        |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
        |        WHERE CAST(sTermsActive AS INTEGER) = 1
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun findAllWithLanguageAsList(): List<SiteTermsWithLanguage> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.SiteTermsWithLanguage>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT SiteTerms.*, Language.*
    |        FROM SiteTerms
    |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
    |        WHERE CAST(sTermsActive AS INTEGER) = 1
    |    
    """.trimMargin() , postgreSql = """
    |SELECT SiteTerms.*, Language.*
    |        FROM SiteTerms
    |        LEFT JOIN Language ON SiteTerms.sTermsLangUid = Language.langUid
    |        WHERE CAST(sTermsActive AS INTEGER) = 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_sTermsUid = _resultSet.getLong("sTermsUid")
          val tmp_termsHtml = _resultSet.getString("termsHtml")
          val tmp_sTermsLang = _resultSet.getString("sTermsLang")
          val tmp_sTermsLangUid = _resultSet.getLong("sTermsLangUid")
          val tmp_sTermsActive = _resultSet.getBoolean("sTermsActive")
          val tmp_sTermsLastChangedBy = _resultSet.getInt("sTermsLastChangedBy")
          val tmp_sTermsPrimaryCsn = _resultSet.getLong("sTermsPrimaryCsn")
          val tmp_sTermsLocalCsn = _resultSet.getLong("sTermsLocalCsn")
          val tmp_sTermsLct = _resultSet.getLong("sTermsLct")
          val _entity = SiteTermsWithLanguage()
          _entity.sTermsUid = tmp_sTermsUid
          _entity.termsHtml = tmp_termsHtml
          _entity.sTermsLang = tmp_sTermsLang
          _entity.sTermsLangUid = tmp_sTermsLangUid
          _entity.sTermsActive = tmp_sTermsActive
          _entity.sTermsLastChangedBy = tmp_sTermsLastChangedBy
          _entity.sTermsPrimaryCsn = tmp_sTermsPrimaryCsn
          _entity.sTermsLocalCsn = tmp_sTermsLocalCsn
          _entity.sTermsLct = tmp_sTermsLct
          var _stLanguage_nullFieldCount = 0
          val tmp_langUid = _resultSet.getLong("langUid")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_name = _resultSet.getString("name")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          val tmp_langLct = _resultSet.getLong("langLct")
          if(_resultSet.wasNull()) { _stLanguage_nullFieldCount++ }
          if(_stLanguage_nullFieldCount < 11) {
            if(_entity.stLanguage == null) {
              _entity.stLanguage = Language()
            }
            _entity.stLanguage!!.langUid = tmp_langUid
            _entity.stLanguage!!.name = tmp_name
            _entity.stLanguage!!.iso_639_1_standard = tmp_iso_639_1_standard
            _entity.stLanguage!!.iso_639_2_standard = tmp_iso_639_2_standard
            _entity.stLanguage!!.iso_639_3_standard = tmp_iso_639_3_standard
            _entity.stLanguage!!.Language_Type = tmp_Language_Type
            _entity.stLanguage!!.languageActive = tmp_languageActive
            _entity.stLanguage!!.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
            _entity.stLanguage!!.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
            _entity.stLanguage!!.langLastChangedBy = tmp_langLastChangedBy
            _entity.stLanguage!!.langLct = tmp_langLct
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun updateActiveByUid(
    sTermsUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE SiteTerms 
    |           SET sTermsActive = ?,
    |               sTermsLct = ?
    |         WHERE sTermsUid = ?
    |        
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE SiteTerms 
    |           SET sTermsActive = ?,
    |               sTermsLct = ?
    |         WHERE sTermsUid = ?
    |        
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, active)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, sTermsUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out SiteTerms>): Unit {
    _insertAdapterSiteTerms_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out SiteTerms>): Unit {
    val _sql =
        "UPDATE SiteTerms SET termsHtml = ?, sTermsLang = ?, sTermsLangUid = ?, sTermsActive = ?, sTermsLastChangedBy = ?, sTermsPrimaryCsn = ?, sTermsLocalCsn = ?, sTermsLct = ? WHERE sTermsUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.termsHtml)
        _stmt.setString(2, _entity.sTermsLang)
        _stmt.setLong(3, _entity.sTermsLangUid)
        _stmt.setBoolean(4, _entity.sTermsActive)
        _stmt.setInt(5, _entity.sTermsLastChangedBy)
        _stmt.setLong(6, _entity.sTermsPrimaryCsn)
        _stmt.setLong(7, _entity.sTermsLocalCsn)
        _stmt.setLong(8, _entity.sTermsLct)
        _stmt.setLong(9, _entity.sTermsUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }
}
