package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.PersonAuth2
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonAuth2Dao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonAuth2Dao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : PersonAuth2Dao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(auths: List<PersonAuth2>): Unit {
    _dao.insertListAsync(auths)
  }

  public override suspend fun insertAsync(auth: PersonAuth2): Long {
    val _result = _dao.insertAsync(auth)
    return _result
  }

  public override suspend fun findByPersonUid(personUid: Long): PersonAuth2? {
    val _result = _dao.findByPersonUid(personUid)
    return _result
  }

  public override suspend fun findByUsername(username: String): PersonAuth2? {
    val _result = _dao.findByUsername(username)
    return _result
  }
}
