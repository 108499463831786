package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorNodeAndVersionHeaders
import com.ustadmobile.door.ext.getOrNull
import com.ustadmobile.lib.db.entities.Clazz
import com.ustadmobile.lib.db.entities.ClazzWithDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithHolidayCalendarAndSchoolAndTerminology
import com.ustadmobile.lib.db.entities.ClazzWithListDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithSchool
import com.ustadmobile.lib.db.entities.UidAndLabel
import io.ktor.client.HttpClient
import io.ktor.client.request.header
import io.ktor.client.request.parameter
import io.ktor.http.takeFrom
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : ClazzDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUid(uid: Long): Clazz? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<Clazz?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override suspend fun findByClazzCode(code: String): Clazz? {
    val _result = _dao.findByClazzCode(code)
    return _result
  }

  public override suspend fun findByClazzCodeFromWeb(code: String): Clazz? =
      _httpClient.getOrNull<Clazz?> {
    url {
      takeFrom(_endpoint)
      encodedPath = "${encodedPath}ClazzDao/findByClazzCodeFromWeb"
    }
    doorNodeAndVersionHeaders(_repo)
    header("x-nid", _clientId)
    parameter("code", code)

  }

  public override fun findAllLive(): DoorLiveData<List<Clazz>> {
    val _result = _dao.findAllLive()
    return _result
  }

  public override fun findAll(): List<Clazz> {
    val _result = _dao.findAll()
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): Clazz? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      ClazzWithHolidayCalendarAndSchoolAndTerminology? {
    val _result = _dao.findByUidWithHolidayCalendarAsync(uid)
    return _result
  }

  public override suspend fun updateAsync(entity: Clazz): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override suspend fun findAllClazzesBySchool(schoolUid: Long): List<Clazz> {
    val _result = _dao.findAllClazzesBySchool(schoolUid)
    return _result
  }

  public override fun findAllClazzesBySchoolLive(schoolUid: Long): DoorDataSourceFactory<Int,
      Clazz> {
    val _result = _dao.findAllClazzesBySchoolLive(schoolUid)
    return _result
  }

  public override fun findClazzesWithPermission(
    searchQuery: String,
    accountPersonUid: Long,
    excludeSelectedClazzList: List<Long>,
    excludeSchoolUid: Long,
    sortOrder: Int,
    filter: Int,
    currentTime: Long,
    permission: Long,
    selectedSchool: Long
  ): DoorDataSourceFactory<Int, ClazzWithListDisplayDetails> {
    val _result = _dao.findClazzesWithPermission(searchQuery, accountPersonUid,
        excludeSelectedClazzList, excludeSchoolUid, sortOrder, filter, currentTime, permission,
        selectedSchool)
    return _result
  }

  public override suspend fun getClassNamesFromListOfIds(ids: List<Long>): List<UidAndLabel> {
    val _result = _dao.getClassNamesFromListOfIds(ids)
    return _result
  }

  public override fun findByClazzName(name: String): List<Clazz> {
    val _result = _dao.findByClazzName(name)
    return _result
  }

  public override suspend fun updateClazzAttendanceAverageAsync(clazzUid: Long, timeChanged: Long):
      Unit {
    _dao.updateClazzAttendanceAverageAsync(clazzUid, timeChanged)
  }

  public override suspend fun personHasPermissionWithClazz(
    accountPersonUid: Long,
    clazzUid: Long,
    permission: Long
  ): Boolean {
    val _result = _dao.personHasPermissionWithClazz(accountPersonUid, clazzUid, permission)
    return _result
  }

  public override suspend fun selectDelegatablePermissions(accountPersonUid: Long, clazzUid: Long):
      List<Long> {
    val _result = _dao.selectDelegatablePermissions(accountPersonUid, clazzUid)
    return _result
  }

  public override fun getClazzWithDisplayDetails(clazzUid: Long, currentTime: Long):
      DoorLiveData<ClazzWithDisplayDetails?> {
    val _result = _dao.getClazzWithDisplayDetails(clazzUid, currentTime)
    return _result
  }

  public override fun findClazzesWithEffectiveHolidayCalendarAndFilter(filterUid: Long):
      List<ClazzWithHolidayCalendarAndSchoolAndTerminology> {
    val _result = _dao.findClazzesWithEffectiveHolidayCalendarAndFilter(filterUid)
    return _result
  }

  public override suspend fun getClazzWithSchool(clazzUid: Long): ClazzWithSchool? {
    val _result = _dao.getClazzWithSchool(clazzUid)
    return _result
  }

  public override fun insert(entity: Clazz): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Clazz): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out Clazz>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Clazz>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Clazz): Unit {
    _dao.update(entity)
  }
}
