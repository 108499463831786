package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ClazzAssignmentRollUp
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzAssignmentRollUpDao_JdbcKt(
  public val _db: DoorDatabase
) : ClazzAssignmentRollUpDao() {
  public val _insertAdapterClazzAssignmentRollUp_: EntityInsertionAdapter<ClazzAssignmentRollUp> =
      object : EntityInsertionAdapter<ClazzAssignmentRollUp>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ClazzAssignmentRollUp (cacheUid, cachePersonUid, cacheContentEntryUid, cacheClazzAssignmentUid, cacheStudentScore, cacheMaxScore, cacheFinalWeightScoreWithPenalty, cacheWeight, cacheProgress, cacheContentComplete, cacheSuccess, cachePenalty, lastCsnChecked) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ClazzAssignmentRollUp (cacheUid, cachePersonUid, cacheContentEntryUid, cacheClazzAssignmentUid, cacheStudentScore, cacheMaxScore, cacheFinalWeightScoreWithPenalty, cacheWeight, cacheProgress, cacheContentComplete, cacheSuccess, cachePenalty, lastCsnChecked) VALUES(COALESCE(?,nextval('ClazzAssignmentRollUp_cacheUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cacheUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ClazzAssignmentRollUp): Unit {
      if(entity.cacheUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cacheUid)
      }
      stmt.setLong(2, entity.cachePersonUid)
      stmt.setLong(3, entity.cacheContentEntryUid)
      stmt.setLong(4, entity.cacheClazzAssignmentUid)
      stmt.setInt(5, entity.cacheStudentScore)
      stmt.setInt(6, entity.cacheMaxScore)
      stmt.setFloat(7, entity.cacheFinalWeightScoreWithPenalty)
      stmt.setInt(8, entity.cacheWeight)
      stmt.setInt(9, entity.cacheProgress)
      stmt.setBoolean(10, entity.cacheContentComplete)
      stmt.setByte(11, entity.cacheSuccess)
      stmt.setInt(12, entity.cachePenalty)
      stmt.setLong(13, entity.lastCsnChecked)
    }
  }

  public override suspend fun cacheBestStatements(
    clazzUid: Long,
    assignmentUid: Long,
    personUid: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    | 
    |        REPLACE INTO ClazzAssignmentRollUp 
    |                (cachePersonUid, cacheContentEntryUid, cacheClazzAssignmentUid, 
    |                 cacheStudentScore, cacheMaxScore, cacheWeight,  cacheProgress,
    |                 cacheContentComplete, cacheSuccess,cachePenalty, cacheFinalWeightScoreWithPenalty, lastCsnChecked)
    |                 
    |        WITH MaxScoreTable (maxScore, maxScoreContentEntryUid) 
    |                AS (SELECT MAX(resultScoreMax), statementContentEntryUid 
    |                      FROM StatementEntity
    |                     WHERE contentEntryRoot 
    |                  GROUP BY statementContentEntryUid)               
    |
    |       SELECT clazzEnrolmentPersonUid AS cachePersonUid, 
    |                COALESCE(cacjContentUid,0) AS cacheContentEntryUid, caUid AS cacheClazzAssignmentUid, 
    |               COALESCE(resultScoreRaw,0) AS cacheStudentScore, 
    |              
    |              
    |               COALESCE((SELECT maxScore 
    |                          FROM MaxScoreTable 
    |                         WHERE cacjContentUid = maxScoreContentEntryUid), 0) AS cacheMaxScore,
    |                         
    |               COALESCE(cacjWeight, 0) AS cacheWeight,
    |                        
    |                          
    |               COALESCE(StatementEntity.extensionProgress,0) AS cacheProgress,
    |               COALESCE(StatementEntity.resultCompletion,'FALSE') AS cacheContentComplete, 
    |               COALESCE(StatementEntity.resultSuccess,0) AS cacheSuccess,
    |               (CASE WHEN StatementEntity.timestamp > CourseBlock.cbDeadlineDate
    |                     THEN CourseBlock.cbLateSubmissionPenalty 
    |                     ELSE 0 END) AS cachePenalty,
    |                     
    |              (CASE WHEN StatementEntity.timestamp > CourseBlock.cbDeadlineDate 
    |                     THEN (COALESCE(CAST(resultScoreRaw AS REAL),0) / COALESCE((SELECT maxScore 
    |                          FROM MaxScoreTable WHERE cacjContentUid = maxScoreContentEntryUid),0) * 100 * cacjWeight * (1 - (CAST(cbLateSubmissionPenalty AS REAL)/100)))
    |                     ELSE (COALESCE(CAST(resultScoreRaw AS REAL),0) / COALESCE((SELECT maxScore 
    |                          FROM MaxScoreTable WHERE cacjContentUid = maxScoreContentEntryUid),0) * 100 * cacjWeight)  END) AS cacheFinalWeightScoreWithPenalty,   
    |                     
    |               0 AS lastCsnChecked
    |          FROM ClazzAssignmentContentJoin
    |	            JOIN ClazzAssignment 
    |                ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid
    |                                
    |                JOIN ClazzEnrolment
    |                ON ClazzEnrolment.clazzEnrolmentClazzUid = ClazzAssignment.caClazzUid
    |                
    |                JOIN CourseBlock
    |                ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |               AND CourseBlock.cbType = 103 
    |					      	      
    |			    LEFT JOIN StatementEntity 
    |	            ON statementUid = (SELECT statementUid 
    |                                     FROM StatementEntity 
    |                                            LEFT JOIN ClazzAssignment 
    |                                            ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid 
    |                                              JOIN CourseBlock
    |                                                ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |                                               AND CourseBlock.cbType = 103 
    |                                    WHERE StatementEntity.statementContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
    |                                      AND StatementEntity.statementPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                                      AND StatementEntity.contentEntryRoot  
    |                                      AND StatementEntity.timestamp 
    |                                            BETWEEN CourseBlock.cbHideUntilDate
    |                                            AND CourseBlock.cbGracePeriodDate
    |                                  ORDER BY CASE WHEN StatementEntity.timestamp > CourseBlock.cbDeadlineDate 
    |                                                THEN StatementEntity.resultScoreScaled * (1 - (CAST(CourseBlock.cbLateSubmissionPenalty AS REAL)/100))
    |                                                ELSE StatementEntity.resultScoreScaled END DESC, 
    |                                            StatementEntity.extensionProgress DESC, 
    |                                            StatementEntity.resultSuccess DESC LIMIT 1)      
    |                LEFT JOIN ClazzAssignmentRollUp
    |                ON ClazzAssignmentRollUp.cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid 
    |                AND ClazzAssignmentRollUp.cachePersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                AND ClazzAssignmentRollUp.cacheClazzAssignmentUid = ClazzAssignment.caUid
    |                                            
    |                                            
    |	     WHERE ClazzEnrolment.clazzEnrolmentRole = 1000
    |           AND ClazzEnrolment.clazzEnrolmentOutcome = 200
    |           AND ClazzEnrolment.clazzEnrolmentActive
    |           AND ClazzAssignment.caActive
    |           AND ClazzAssignmentContentJoin.cacjActive
    |           AND (? = 0 OR ClazzAssignment.caClazzUid = ?)
    |           AND (? = 0 OR ClazzAssignment.caUid = ?)
    |           AND (? = 0 OR ClazzEnrolment.clazzEnrolmentPersonUid = ?)
    |           AND (COALESCE(StatementEntity.resultScoreRaw,0) >= COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0)
    |                    AND COALESCE(StatementEntity.extensionProgress,0) >= COALESCE(ClazzAssignmentRollUp.cacheProgress,0)
    |                    AND COALESCE(StatementEntity.resultSuccess,0) >= COALESCE(ClazzAssignmentRollUp.cacheSuccess,0))
    |      GROUP BY cacheClazzAssignmentUid, cacheContentEntryUid, cachePersonUid
    |         UNION 
    |         SELECT clazzEnrolmentPersonUid AS cachePersonUid, 
    |                0 AS cacheContentEntryUid, 
    |                caUid AS cacheClazzAssignmentUid, 
    |                COALESCE(MarkingStatement.resultScoreRaw,0) AS cacheStudentScore, 
    |                COALESCE(cbMaxPoints,0) AS cacheMaxScore,
    |                0 AS cacheWeight,
    |                
    |                COALESCE(MarkingStatement.extensionProgress,0) AS cacheProgress,
    |                COALESCE(MarkingStatement.resultCompletion,'FALSE') AS cacheContentComplete, 
    |                COALESCE(MarkingStatement.resultSuccess,0) AS cacheSuccess,
    |                (CASE WHEN SubmissionStatement.timestamp > CourseBlock.cbDeadlineDate 
    |                     THEN CourseBlock.cbLateSubmissionPenalty 
    |                     ELSE 0 END) AS cachePenalty,
    |                     
    |              (CASE WHEN SubmissionStatement.timestamp > CourseBlock.cbDeadlineDate 
    |                     THEN (COALESCE(CAST(MarkingStatement.resultScoreRaw AS REAL),0) / COALESCE(CourseBlock.cbMaxPoints,0) * 
    |                            100 * (1 - (CAST(cbLateSubmissionPenalty AS REAL)/100)))
    |                     ELSE (COALESCE(CAST(MarkingStatement.resultScoreRaw AS REAL),0) / COALESCE(cbMaxPoints,0) * 
    |                            100)  END) AS cacheFinalWeightScoreWithPenalty, 
    |                     
    |                   
    |               0 AS lastCsnChecked
    |         FROM ClazzAssignment
    |              JOIN ClazzEnrolment
    |              ON ClazzEnrolment.clazzEnrolmentClazzUid = ClazzAssignment.caClazzUid
    |              
    |               JOIN CourseBlock
    |                ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |               AND CourseBlock.cbType = 103 
    |              
    |              LEFT JOIN StatementEntity AS SubmissionStatement
    |	          ON SubmissionStatement.statementUid = (SELECT statementUid 
    |                                   FROM StatementEntity
    |                                  WHERE StatementEntity.statementContentEntryUid = 0
    |                                    AND xObjectUid = ClazzAssignment.caXObjectUid
    |                                    AND StatementEntity.statementPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                                    AND StatementEntity.timestamp 
    |                                        BETWEEN CourseBlock.cbHideUntilDate
    |                                        AND CourseBlock.cbGracePeriodDate
    |                               ORDER BY timestamp DESC LIMIT 1
    |                                  )
    |              LEFT JOIN XObjectEntity AS ObjectStatementRef
    |              ON ObjectStatementRef.objectStatementRefUid = SubmissionStatement.statementUid                    
    |                                  
    |              LEFT JOIN StatementEntity AS MarkingStatement
    |               ON MarkingStatement.timestamp = (SELECT timestamp 
    |                                                  FROM StatementEntity 
    |                                                 WHERE xObjectUid = ObjectStatementRef.xObjectUid 
    |                                              ORDER BY timestamp DESC 
    |                                                 LIMIT 1)
    |              
    |        WHERE ClazzEnrolment.clazzEnrolmentRole = 1000
    |          AND ClazzEnrolment.clazzEnrolmentOutcome = 200
    |          AND ClazzEnrolment.clazzEnrolmentActive
    |          AND ClazzAssignment.caActive
    |          AND ClazzAssignment.caRequireFileSubmission
    |          AND (? = 0 OR ClazzAssignment.caClazzUid = ?)
    |          AND (? = 0 OR ClazzAssignment.caUid = ?)
    |          AND (? = 0 OR ClazzEnrolment.clazzEnrolmentPersonUid = ?)
    |      GROUP BY cacheClazzAssignmentUid, cacheContentEntryUid, cachePersonUid     
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentRollUp 
    |                (cachePersonUid, cacheContentEntryUid, cacheClazzAssignmentUid, 
    |                 cacheStudentScore, cacheMaxScore, cacheWeight,  cacheProgress,
    |                 cacheContentComplete, cacheSuccess,cachePenalty, cacheFinalWeightScoreWithPenalty, lastCsnChecked)
    |                 
    |        WITH MaxScoreTable (maxScore, maxScoreContentEntryUid) 
    |                AS (SELECT MAX(resultScoreMax), statementContentEntryUid 
    |                      FROM StatementEntity
    |                     WHERE contentEntryRoot 
    |                  GROUP BY statementContentEntryUid)               
    |
    |       SELECT clazzEnrolmentPersonUid AS cachePersonUid, 
    |                COALESCE(cacjContentUid,0) AS cacheContentEntryUid, caUid AS cacheClazzAssignmentUid, 
    |               COALESCE(resultScoreRaw,0) AS cacheStudentScore, 
    |              
    |              
    |               COALESCE((SELECT maxScore 
    |                          FROM MaxScoreTable 
    |                         WHERE cacjContentUid = maxScoreContentEntryUid), 0) AS cacheMaxScore,
    |                         
    |               COALESCE(cacjWeight, 0) AS cacheWeight,
    |                        
    |                          
    |               COALESCE(StatementEntity.extensionProgress,0) AS cacheProgress,
    |               COALESCE(StatementEntity.resultCompletion,'FALSE') AS cacheContentComplete, 
    |               COALESCE(StatementEntity.resultSuccess,0) AS cacheSuccess,
    |               (CASE WHEN StatementEntity.timestamp > CourseBlock.cbDeadlineDate
    |                     THEN CourseBlock.cbLateSubmissionPenalty 
    |                     ELSE 0 END) AS cachePenalty,
    |                     
    |              (CASE WHEN StatementEntity.timestamp > CourseBlock.cbDeadlineDate 
    |                     THEN (COALESCE(CAST(resultScoreRaw AS REAL),0) / COALESCE((SELECT maxScore 
    |                          FROM MaxScoreTable WHERE cacjContentUid = maxScoreContentEntryUid),0) * 100 * cacjWeight * (1 - (CAST(cbLateSubmissionPenalty AS REAL)/100)))
    |                     ELSE (COALESCE(CAST(resultScoreRaw AS REAL),0) / COALESCE((SELECT maxScore 
    |                          FROM MaxScoreTable WHERE cacjContentUid = maxScoreContentEntryUid),0) * 100 * cacjWeight)  END) AS cacheFinalWeightScoreWithPenalty,   
    |                     
    |               0 AS lastCsnChecked
    |          FROM ClazzAssignmentContentJoin
    |	            JOIN ClazzAssignment 
    |                ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid
    |                                
    |                JOIN ClazzEnrolment
    |                ON ClazzEnrolment.clazzEnrolmentClazzUid = ClazzAssignment.caClazzUid
    |                
    |                JOIN CourseBlock
    |                ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |               AND CourseBlock.cbType = 103 
    |					      	      
    |			    LEFT JOIN StatementEntity 
    |	            ON statementUid = (SELECT statementUid 
    |                                     FROM StatementEntity 
    |                                            LEFT JOIN ClazzAssignment 
    |                                            ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid 
    |                                              JOIN CourseBlock
    |                                                ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |                                               AND CourseBlock.cbType = 103 
    |                                    WHERE StatementEntity.statementContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
    |                                      AND StatementEntity.statementPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                                      AND StatementEntity.contentEntryRoot  
    |                                      AND StatementEntity.timestamp 
    |                                            BETWEEN CourseBlock.cbHideUntilDate
    |                                            AND CourseBlock.cbGracePeriodDate
    |                                  ORDER BY CASE WHEN StatementEntity.timestamp > CourseBlock.cbDeadlineDate 
    |                                                THEN StatementEntity.resultScoreScaled * (1 - (CAST(CourseBlock.cbLateSubmissionPenalty AS REAL)/100))
    |                                                ELSE StatementEntity.resultScoreScaled END DESC, 
    |                                            StatementEntity.extensionProgress DESC, 
    |                                            StatementEntity.resultSuccess DESC LIMIT 1)      
    |                LEFT JOIN ClazzAssignmentRollUp
    |                ON ClazzAssignmentRollUp.cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid 
    |                AND ClazzAssignmentRollUp.cachePersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                AND ClazzAssignmentRollUp.cacheClazzAssignmentUid = ClazzAssignment.caUid
    |                                            
    |                                            
    |	     WHERE ClazzEnrolment.clazzEnrolmentRole = 1000
    |           AND ClazzEnrolment.clazzEnrolmentOutcome = 200
    |           AND ClazzEnrolment.clazzEnrolmentActive
    |           AND ClazzAssignment.caActive
    |           AND ClazzAssignmentContentJoin.cacjActive
    |           AND (? = 0 OR ClazzAssignment.caClazzUid = ?)
    |           AND (? = 0 OR ClazzAssignment.caUid = ?)
    |           AND (? = 0 OR ClazzEnrolment.clazzEnrolmentPersonUid = ?)
    |           AND (COALESCE(StatementEntity.resultScoreRaw,0) >= COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0)
    |                    AND COALESCE(StatementEntity.extensionProgress,0) >= COALESCE(ClazzAssignmentRollUp.cacheProgress,0)
    |                    AND COALESCE(StatementEntity.resultSuccess,0) >= COALESCE(ClazzAssignmentRollUp.cacheSuccess,0))
    |      GROUP BY cacheClazzAssignmentUid, cacheContentEntryUid, cachePersonUid
    |         UNION 
    |         SELECT clazzEnrolmentPersonUid AS cachePersonUid, 
    |                0 AS cacheContentEntryUid, 
    |                caUid AS cacheClazzAssignmentUid, 
    |                COALESCE(MarkingStatement.resultScoreRaw,0) AS cacheStudentScore, 
    |                COALESCE(cbMaxPoints,0) AS cacheMaxScore,
    |                0 AS cacheWeight,
    |                
    |                COALESCE(MarkingStatement.extensionProgress,0) AS cacheProgress,
    |                COALESCE(MarkingStatement.resultCompletion,'FALSE') AS cacheContentComplete, 
    |                COALESCE(MarkingStatement.resultSuccess,0) AS cacheSuccess,
    |                (CASE WHEN SubmissionStatement.timestamp > CourseBlock.cbDeadlineDate 
    |                     THEN CourseBlock.cbLateSubmissionPenalty 
    |                     ELSE 0 END) AS cachePenalty,
    |                     
    |              (CASE WHEN SubmissionStatement.timestamp > CourseBlock.cbDeadlineDate 
    |                     THEN (COALESCE(CAST(MarkingStatement.resultScoreRaw AS REAL),0) / COALESCE(CourseBlock.cbMaxPoints,0) * 
    |                            100 * (1 - (CAST(cbLateSubmissionPenalty AS REAL)/100)))
    |                     ELSE (COALESCE(CAST(MarkingStatement.resultScoreRaw AS REAL),0) / COALESCE(cbMaxPoints,0) * 
    |                            100)  END) AS cacheFinalWeightScoreWithPenalty, 
    |                     
    |                   
    |               0 AS lastCsnChecked
    |         FROM ClazzAssignment
    |              JOIN ClazzEnrolment
    |              ON ClazzEnrolment.clazzEnrolmentClazzUid = ClazzAssignment.caClazzUid
    |              
    |               JOIN CourseBlock
    |                ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
    |               AND CourseBlock.cbType = 103 
    |              
    |              LEFT JOIN StatementEntity AS SubmissionStatement
    |	          ON SubmissionStatement.statementUid = (SELECT statementUid 
    |                                   FROM StatementEntity
    |                                  WHERE StatementEntity.statementContentEntryUid = 0
    |                                    AND xObjectUid = ClazzAssignment.caXObjectUid
    |                                    AND StatementEntity.statementPersonUid = ClazzEnrolment.clazzEnrolmentPersonUid
    |                                    AND StatementEntity.timestamp 
    |                                        BETWEEN CourseBlock.cbHideUntilDate
    |                                        AND CourseBlock.cbGracePeriodDate
    |                               ORDER BY timestamp DESC LIMIT 1
    |                                  )
    |              LEFT JOIN XObjectEntity AS ObjectStatementRef
    |              ON ObjectStatementRef.objectStatementRefUid = SubmissionStatement.statementUid                    
    |                                  
    |              LEFT JOIN StatementEntity AS MarkingStatement
    |               ON MarkingStatement.timestamp = (SELECT timestamp 
    |                                                  FROM StatementEntity 
    |                                                 WHERE xObjectUid = ObjectStatementRef.xObjectUid 
    |                                              ORDER BY timestamp DESC 
    |                                                 LIMIT 1)
    |              
    |        WHERE ClazzEnrolment.clazzEnrolmentRole = 1000
    |          AND ClazzEnrolment.clazzEnrolmentOutcome = 200
    |          AND ClazzEnrolment.clazzEnrolmentActive
    |          AND ClazzAssignment.caActive
    |          AND ClazzAssignment.caRequireFileSubmission
    |          AND (? = 0 OR ClazzAssignment.caClazzUid = ?)
    |          AND (? = 0 OR ClazzAssignment.caUid = ?)
    |          AND (? = 0 OR ClazzEnrolment.clazzEnrolmentPersonUid = ?)
    |      GROUP BY cacheClazzAssignmentUid, cacheContentEntryUid, cachePersonUid     
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.setLong(2, clazzUid)
      _stmt.setLong(3, assignmentUid)
      _stmt.setLong(4, assignmentUid)
      _stmt.setLong(5, personUid)
      _stmt.setLong(6, personUid)
      _stmt.setLong(7, clazzUid)
      _stmt.setLong(8, clazzUid)
      _stmt.setLong(9, assignmentUid)
      _stmt.setLong(10, assignmentUid)
      _stmt.setLong(11, personUid)
      _stmt.setLong(12, personUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun deleteCachedInactiveContent(caUid: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        DELETE
    |         FROM ClazzAssignmentRollUp
    |        WHERE cacheContentEntryUid 
    |                IN (SELECT cacjContentUid 
    |                     FROM ClazzAssignmentContentJoin
    |                    WHERE NOT cacjActive)
    |           OR (cacheClazzAssignmentUid 
    |              IN (SELECT caUid 
    |                   FROM ClazzAssignment
    |                  WHERE caUid = ?
    |                    AND NOT caRequireFileSubmission) 
    |               AND cacheContentEntryUid = 0)                                        
    |    
    """.trimMargin() , postgreSql = """
    |
    |        DELETE
    |         FROM ClazzAssignmentRollUp
    |        WHERE cacheContentEntryUid 
    |                IN (SELECT cacjContentUid 
    |                     FROM ClazzAssignmentContentJoin
    |                    WHERE NOT cacjActive)
    |           OR (cacheClazzAssignmentUid 
    |              IN (SELECT caUid 
    |                   FROM ClazzAssignment
    |                  WHERE caUid = ?
    |                    AND NOT caRequireFileSubmission) 
    |               AND cacheContentEntryUid = 0)                                        
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, caUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun invalidateCacheByAssignment(changedAssignmentUid: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ClazzAssignmentRollUp 
    |           SET lastCsnChecked = 0
    |         WHERE cacheClazzAssignmentUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ClazzAssignmentRollUp 
    |           SET lastCsnChecked = 0
    |         WHERE cacheClazzAssignmentUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, changedAssignmentUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun invalidateCacheByAssignmentList(changedAssignmentUid: List<Long>):
      Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ClazzAssignmentRollUp 
    |           SET lastCsnChecked = 0
    |         WHERE cacheClazzAssignmentUid IN (?)
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        UPDATE ClazzAssignmentRollUp 
    |           SET lastCsnChecked = 0
    |         WHERE cacheClazzAssignmentUid IN (?)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          changedAssignmentUid.toTypedArray()))
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: ClazzAssignmentRollUp): Long {
    val _retVal = _insertAdapterClazzAssignmentRollUp_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzAssignmentRollUp): Long {
    val _retVal = _insertAdapterClazzAssignmentRollUp_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ClazzAssignmentRollUp>): Unit {
    _insertAdapterClazzAssignmentRollUp_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzAssignmentRollUp>): Unit {
    val _sql =
        "UPDATE ClazzAssignmentRollUp SET cachePersonUid = ?, cacheContentEntryUid = ?, cacheClazzAssignmentUid = ?, cacheStudentScore = ?, cacheMaxScore = ?, cacheFinalWeightScoreWithPenalty = ?, cacheWeight = ?, cacheProgress = ?, cacheContentComplete = ?, cacheSuccess = ?, cachePenalty = ?, lastCsnChecked = ? WHERE cacheUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cachePersonUid)
        _stmt.setLong(2, _entity.cacheContentEntryUid)
        _stmt.setLong(3, _entity.cacheClazzAssignmentUid)
        _stmt.setInt(4, _entity.cacheStudentScore)
        _stmt.setInt(5, _entity.cacheMaxScore)
        _stmt.setFloat(6, _entity.cacheFinalWeightScoreWithPenalty)
        _stmt.setInt(7, _entity.cacheWeight)
        _stmt.setInt(8, _entity.cacheProgress)
        _stmt.setBoolean(9, _entity.cacheContentComplete)
        _stmt.setByte(10, _entity.cacheSuccess)
        _stmt.setInt(11, _entity.cachePenalty)
        _stmt.setLong(12, _entity.lastCsnChecked)
        _stmt.setLong(13, _entity.cacheUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzAssignmentRollUp): Unit {
    val _sql =
        "UPDATE ClazzAssignmentRollUp SET cachePersonUid = ?, cacheContentEntryUid = ?, cacheClazzAssignmentUid = ?, cacheStudentScore = ?, cacheMaxScore = ?, cacheFinalWeightScoreWithPenalty = ?, cacheWeight = ?, cacheProgress = ?, cacheContentComplete = ?, cacheSuccess = ?, cachePenalty = ?, lastCsnChecked = ? WHERE cacheUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cachePersonUid)
      _stmt.setLong(2, entity.cacheContentEntryUid)
      _stmt.setLong(3, entity.cacheClazzAssignmentUid)
      _stmt.setInt(4, entity.cacheStudentScore)
      _stmt.setInt(5, entity.cacheMaxScore)
      _stmt.setFloat(6, entity.cacheFinalWeightScoreWithPenalty)
      _stmt.setInt(7, entity.cacheWeight)
      _stmt.setInt(8, entity.cacheProgress)
      _stmt.setBoolean(9, entity.cacheContentComplete)
      _stmt.setByte(10, entity.cacheSuccess)
      _stmt.setInt(11, entity.cachePenalty)
      _stmt.setLong(12, entity.lastCsnChecked)
      _stmt.setLong(13, entity.cacheUid)
      _stmt.executeUpdate()
    }
  }
}
