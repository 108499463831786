package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Site
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit

public class SiteDao_JdbcKt(
  public val _db: DoorDatabase
) : SiteDao() {
  public val _insertAdapterSite_upsert: EntityInsertionAdapter<Site> = object :
      EntityInsertionAdapter<Site>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO Site (siteUid, sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Site (siteUid, sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt) VALUES(COALESCE(?,nextval('Site_siteUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (siteUid) DO UPDATE SET sitePcsn = excluded.sitePcsn,siteLcsn = excluded.siteLcsn,siteLcb = excluded.siteLcb,siteLct = excluded.siteLct,siteName = excluded.siteName,guestLogin = excluded.guestLogin,registrationAllowed = excluded.registrationAllowed,authSalt = excluded.authSalt" + if(returnsId) { " RETURNING siteUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Site): Unit {
      if(entity.siteUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.siteUid)
      }
      stmt.setLong(2, entity.sitePcsn)
      stmt.setLong(3, entity.siteLcsn)
      stmt.setInt(4, entity.siteLcb)
      stmt.setLong(5, entity.siteLct)
      stmt.setString(6, entity.siteName)
      stmt.setBoolean(7, entity.guestLogin)
      stmt.setBoolean(8, entity.registrationAllowed)
      stmt.setString(9, entity.authSalt)
    }
  }

  public val _insertAdapterSite_: EntityInsertionAdapter<Site> = object :
      EntityInsertionAdapter<Site>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Site (siteUid, sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Site (siteUid, sitePcsn, siteLcsn, siteLcb, siteLct, siteName, guestLogin, registrationAllowed, authSalt) VALUES(COALESCE(?,nextval('Site_siteUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING siteUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Site): Unit {
      if(entity.siteUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.siteUid)
      }
      stmt.setLong(2, entity.sitePcsn)
      stmt.setLong(3, entity.siteLcsn)
      stmt.setInt(4, entity.siteLcb)
      stmt.setLong(5, entity.siteLct)
      stmt.setString(6, entity.siteName)
      stmt.setBoolean(7, entity.guestLogin)
      stmt.setBoolean(8, entity.registrationAllowed)
      stmt.setString(9, entity.authSalt)
    }
  }

  public override fun getSite(): Site? {
    var _result = null as com.ustadmobile.lib.db.entities.Site??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Site LIMIT 1" , postgreSql = """
    |SELECT * FROM Site LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_siteUid = _resultSet.getLong("siteUid")
          val tmp_sitePcsn = _resultSet.getLong("sitePcsn")
          val tmp_siteLcsn = _resultSet.getLong("siteLcsn")
          val tmp_siteLcb = _resultSet.getInt("siteLcb")
          val tmp_siteLct = _resultSet.getLong("siteLct")
          val tmp_siteName = _resultSet.getString("siteName")
          val tmp_guestLogin = _resultSet.getBoolean("guestLogin")
          val tmp_registrationAllowed = _resultSet.getBoolean("registrationAllowed")
          val tmp_authSalt = _resultSet.getString("authSalt")
          val _entity = Site()
          _entity.siteUid = tmp_siteUid
          _entity.sitePcsn = tmp_sitePcsn
          _entity.siteLcsn = tmp_siteLcsn
          _entity.siteLcb = tmp_siteLcb
          _entity.siteLct = tmp_siteLct
          _entity.siteName = tmp_siteName
          _entity.guestLogin = tmp_guestLogin
          _entity.registrationAllowed = tmp_registrationAllowed
          _entity.authSalt = tmp_authSalt
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun getSiteAsync(): Site? {
    var _result = null as com.ustadmobile.lib.db.entities.Site??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Site LIMIT 1" , postgreSql = """
    |SELECT * FROM Site LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_siteUid = _resultSet.getLong("siteUid")
          val tmp_sitePcsn = _resultSet.getLong("sitePcsn")
          val tmp_siteLcsn = _resultSet.getLong("siteLcsn")
          val tmp_siteLcb = _resultSet.getInt("siteLcb")
          val tmp_siteLct = _resultSet.getLong("siteLct")
          val tmp_siteName = _resultSet.getString("siteName")
          val tmp_guestLogin = _resultSet.getBoolean("guestLogin")
          val tmp_registrationAllowed = _resultSet.getBoolean("registrationAllowed")
          val tmp_authSalt = _resultSet.getString("authSalt")
          val _entity = Site()
          _entity.siteUid = tmp_siteUid
          _entity.sitePcsn = tmp_sitePcsn
          _entity.siteLcsn = tmp_siteLcsn
          _entity.siteLcb = tmp_siteLcb
          _entity.siteLct = tmp_siteLct
          _entity.siteName = tmp_siteName
          _entity.guestLogin = tmp_guestLogin
          _entity.registrationAllowed = tmp_registrationAllowed
          _entity.authSalt = tmp_authSalt
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun replaceAsync(site: Site): Long {
    val _retVal = _insertAdapterSite_upsert.insertAndReturnIdAsync(site)
    return _retVal
  }

  public override fun insert(site: Site): Long {
    val _retVal = _insertAdapterSite_.insertAndReturnId(site)
    return _retVal
  }

  public override suspend fun updateAsync(workspace: Site): Unit {
    val _sql =
        "UPDATE Site SET sitePcsn = ?, siteLcsn = ?, siteLcb = ?, siteLct = ?, siteName = ?, guestLogin = ?, registrationAllowed = ?, authSalt = ? WHERE siteUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, workspace.sitePcsn)
      _stmt.setLong(2, workspace.siteLcsn)
      _stmt.setInt(3, workspace.siteLcb)
      _stmt.setLong(4, workspace.siteLct)
      _stmt.setString(5, workspace.siteName)
      _stmt.setBoolean(6, workspace.guestLogin)
      _stmt.setBoolean(7, workspace.registrationAllowed)
      _stmt.setString(8, workspace.authSalt)
      _stmt.setLong(9, workspace.siteUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO SiteReplicate(sitePk, siteDestination)
    |         SELECT DISTINCT Site.siteUid AS sitePk,
    |                ? AS siteDestination
    |           FROM Site
    |          WHERE Site.siteLct != COALESCE(
    |                (SELECT siteVersionId
    |                   FROM SiteReplicate
    |                  WHERE sitePk = Site.siteUid
    |                    AND siteDestination = ?), 0) 
    |         /*psql ON CONFLICT(sitePk, siteDestination) DO UPDATE
    |                SET sitePending = true
    |         */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SiteReplicate(sitePk, siteDestination)
    |         SELECT DISTINCT Site.siteUid AS sitePk,
    |                ? AS siteDestination
    |           FROM Site
    |          WHERE Site.siteLct != COALESCE(
    |                (SELECT siteVersionId
    |                   FROM SiteReplicate
    |                  WHERE sitePk = Site.siteUid
    |                    AND siteDestination = ?), 0) 
    |          ON CONFLICT(sitePk, siteDestination) DO UPDATE
    |                SET sitePending = true
    |                
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO SiteReplicate(sitePk, siteDestination)
    |         SELECT DISTINCT Site.siteUid AS sitePk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN Site 
    |                    ON ChangeLog.chTableId = 189 
    |                       AND ChangeLog.chEntityPk = Site.siteUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND Site.siteLct != COALESCE(
    |                (SELECT siteVersionId
    |                   FROM SiteReplicate
    |                  WHERE sitePk = Site.siteUid
    |                    AND siteDestination = UserSession.usClientNodeId), 0)     
    |        /*psql  ON CONFLICT(sitePk, siteDestination) DO UPDATE
    |            SET sitePending = true
    |         */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SiteReplicate(sitePk, siteDestination)
    |         SELECT DISTINCT Site.siteUid AS sitePk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN Site 
    |                    ON ChangeLog.chTableId = 189 
    |                       AND ChangeLog.chEntityPk = Site.siteUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND Site.siteLct != COALESCE(
    |                (SELECT siteVersionId
    |                   FROM SiteReplicate
    |                  WHERE sitePk = Site.siteUid
    |                    AND siteDestination = UserSession.usClientNodeId), 0)     
    |          ON CONFLICT(sitePk, siteDestination) DO UPDATE
    |            SET sitePending = true
    |                        
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }
}
