package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.attachments.storeAttachment
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonPicture
import com.ustadmobile.lib.db.entities.asEntityWithAttachment
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class PersonPictureDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: PersonPictureDao
) : PersonPictureDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByPersonUidAsync(personUid: Long): PersonPicture? =
      _dao.findByPersonUidAsync(personUid)

  public override fun findByPersonUidLive(personUid: Long): DoorLiveData<PersonPicture?> =
      _dao.findByPersonUidLive(personUid)

  public override suspend fun updateAsync(personPicture: PersonPicture): Unit {
    _db.storeAttachment(personPicture.asEntityWithAttachment())
    personPicture.personPictureLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(personPicture)
  }

  public override fun insert(entity: PersonPicture): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: PersonPicture): Long {
    _db.storeAttachment(entity.asEntityWithAttachment())
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.personPictureUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(50)
      entity.personPictureUid = _newPk
    }
    entity.personPictureLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.personPictureUid
  }

  public override fun insertList(entityList: List<out PersonPicture>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out PersonPicture>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: PersonPicture): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
