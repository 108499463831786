package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.lib.db.entities.PersonGroup
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonGroupDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonGroupDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : PersonGroupDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _dao.replicateOnChangeClazzBased()
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeClazzBased(newNodeId)
  }

  public override suspend fun replicateOnChangePersonBased(): Unit {
    _dao.replicateOnChangePersonBased()
  }

  public override suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodePersonBased(newNodeId)
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _dao.replicateOnChangeSchoolBased()
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeSchoolBased(newNodeId)
  }

  public override fun findByUid(uid: Long): PersonGroup? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): PersonGroup? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<PersonGroup?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override suspend fun updateAsync(entity: PersonGroup): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override suspend fun findNameByGroupUid(groupUid: Long): String? {
    val _result = _dao.findNameByGroupUid(groupUid)
    return _result
  }

  public override fun insert(entity: PersonGroup): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: PersonGroup): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out PersonGroup>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out PersonGroup>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: PersonGroup): Unit {
    _dao.update(entity)
  }
}
