package com.ustadmobile.core.db

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.SiteTerms
import com.ustadmobile.lib.db.entities.SiteTermsWithLanguage
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class SiteTermsDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: SiteTermsDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : SiteTermsDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findSiteTerms(langCode: String): SiteTerms? {
    val _result = _dao.findSiteTerms(langCode)
    return _result
  }

  public override suspend fun insertAsync(siteTerms: SiteTerms): Long {
    val _result = _dao.insertAsync(siteTerms)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): SiteTerms? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun findAllTermsAsFactory(): DoorDataSourceFactory<Int, SiteTermsWithLanguage> {
    val _result = _dao.findAllTermsAsFactory()
    return _result
  }

  public override suspend fun findAllWithLanguageAsList(): List<SiteTermsWithLanguage> {
    val _result = _dao.findAllWithLanguageAsList()
    return _result
  }

  public override suspend fun updateActiveByUid(
    sTermsUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    _dao.updateActiveByUid(sTermsUid, active, changeTime)
  }

  public override suspend fun insertListAsync(entityList: List<out SiteTerms>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out SiteTerms>): Unit {
    _dao.updateListAsync(entityList)
  }
}
