package com.ustadmobile.lib.db.entities

class ContentJobItemProgress {

    var progress: Int = 0

    var total: Int = 0

    var progressTitle: String? = null

    var cjiUid: Long = 0L

}