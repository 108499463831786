package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContainerUidAndMimeType
import com.ustadmobile.lib.db.entities.ContainerWithContentEntry
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContainerDao_JdbcKt(
  public val _db: DoorDatabase
) : ContainerDao() {
  public val _insertAdapterContainer_: EntityInsertionAdapter<Container> = object :
      EntityInsertionAdapter<Container>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Container (containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, cntLct, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Container (containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, cntLct, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries) VALUES(COALESCE(?,nextval('Container_containerUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING containerUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Container): Unit {
      if(entity.containerUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.containerUid)
      }
      stmt.setLong(2, entity.cntLocalCsn)
      stmt.setLong(3, entity.cntMasterCsn)
      stmt.setInt(4, entity.cntLastModBy)
      stmt.setLong(5, entity.cntLct)
      stmt.setLong(6, entity.fileSize)
      stmt.setLong(7, entity.containerContentEntryUid)
      stmt.setLong(8, entity.cntLastModified)
      stmt.setString(9, entity.mimeType)
      stmt.setString(10, entity.remarks)
      stmt.setBoolean(11, entity.mobileOptimized)
      stmt.setInt(12, entity.cntNumEntries)
    }
  }

  public val _insertAdapterContainer_upsert: EntityInsertionAdapter<Container> = object :
      EntityInsertionAdapter<Container>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO Container (containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, cntLct, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Container (containerUid, cntLocalCsn, cntMasterCsn, cntLastModBy, cntLct, fileSize, containerContentEntryUid, cntLastModified, mimeType, remarks, mobileOptimized, cntNumEntries) VALUES(COALESCE(?,nextval('Container_containerUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (containerUid) DO UPDATE SET cntLocalCsn = excluded.cntLocalCsn,cntMasterCsn = excluded.cntMasterCsn,cntLastModBy = excluded.cntLastModBy,cntLct = excluded.cntLct,fileSize = excluded.fileSize,containerContentEntryUid = excluded.containerContentEntryUid,cntLastModified = excluded.cntLastModified,mimeType = excluded.mimeType,remarks = excluded.remarks,mobileOptimized = excluded.mobileOptimized,cntNumEntries = excluded.cntNumEntries" + if(returnsId) { " RETURNING containerUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Container): Unit {
      if(entity.containerUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.containerUid)
      }
      stmt.setLong(2, entity.cntLocalCsn)
      stmt.setLong(3, entity.cntMasterCsn)
      stmt.setInt(4, entity.cntLastModBy)
      stmt.setLong(5, entity.cntLct)
      stmt.setLong(6, entity.fileSize)
      stmt.setLong(7, entity.containerContentEntryUid)
      stmt.setLong(8, entity.cntLastModified)
      stmt.setString(9, entity.mimeType)
      stmt.setString(10, entity.remarks)
      stmt.setBoolean(11, entity.mobileOptimized)
      stmt.setInt(12, entity.cntNumEntries)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         REPLACE INTO ContainerReplicate(containerPk, containerDestination)
    |          SELECT DISTINCT Container.containerUid AS containerPk,
    |                 ? AS containerDestination
    |            FROM Container
    |           WHERE Container.cntLct != COALESCE(
    |                 (SELECT containerVersionId
    |                    FROM ContainerReplicate
    |                   WHERE containerPk = Container.containerUid
    |                     AND containerDestination = ?), 0) 
    |          /*psql ON CONFLICT(containerPk, containerDestination) DO UPDATE
    |                 SET containerPending = true
    |          */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContainerReplicate(containerPk, containerDestination)
    |          SELECT DISTINCT Container.containerUid AS containerPk,
    |                 ? AS containerDestination
    |            FROM Container
    |           WHERE Container.cntLct != COALESCE(
    |                 (SELECT containerVersionId
    |                    FROM ContainerReplicate
    |                   WHERE containerPk = Container.containerUid
    |                     AND containerDestination = ?), 0) 
    |           ON CONFLICT(containerPk, containerDestination) DO UPDATE
    |                 SET containerPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ContainerReplicate(containerPk, containerDestination)
    |  SELECT DISTINCT Container.containerUid AS containerUid,
    |         UserSession.usClientNodeId AS containerDestination
    |    FROM ChangeLog
    |         JOIN Container
    |             ON ChangeLog.chTableId = 51
    |                AND ChangeLog.chEntityPk = Container.containerUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Container.cntLct != COALESCE(
    |         (SELECT containerVersionId
    |            FROM ContainerReplicate
    |           WHERE containerPk = Container.containerUid
    |             AND containerDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(containerPk, containerDestination) DO UPDATE
    |     SET containerPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContainerReplicate(containerPk, containerDestination)
    |  SELECT DISTINCT Container.containerUid AS containerUid,
    |         UserSession.usClientNodeId AS containerDestination
    |    FROM ChangeLog
    |         JOIN Container
    |             ON ChangeLog.chTableId = 51
    |                AND ChangeLog.chEntityPk = Container.containerUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Container.cntLct != COALESCE(
    |         (SELECT containerVersionId
    |            FROM ContainerReplicate
    |           WHERE containerPk = Container.containerUid
    |             AND containerDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(containerPk, containerDestination) DO UPDATE
    |     SET containerPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(containerList: List<out Container>): Unit {
    _insertAdapterContainer_.insertListAsync(containerList)
  }

  public override suspend
      fun getMostRecentDownloadedContainerForContentEntryAsync(contentEntry: Long): Container? {
    var _result = null as com.ustadmobile.lib.db.entities.Container??
    val _stmtConfig =
        PreparedStatementConfig("Select Container.* FROM Container WHERE Container.containerContentEntryUid = ? ORDER BY Container.cntLastModified DESC LIMIT 1"
        , postgreSql = """
    |Select Container.* FROM Container WHERE Container.containerContentEntryUid = ? ORDER BY Container.cntLastModified DESC LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntry)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun getMostRecentContainerForContentEntry(contentEntryUid: Long): Container? {
    var _result = null as com.ustadmobile.lib.db.entities.Container??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            SELECT Container.*
    |            
    |            FROM Container
    |             WHERE Container.containerContentEntryUid = ?
    |               AND 
    |            Container.fileSize > 0
    |             
    |          ORDER BY Container.cntLastModified DESC 
    |          LIMIT 1
    |        
    |        
    """.trimMargin() , postgreSql = """
    |
    |            SELECT Container.*
    |            
    |            FROM Container
    |             WHERE Container.containerContentEntryUid = ?
    |               AND 
    |            Container.fileSize > 0
    |             
    |          ORDER BY Container.cntLastModified DESC 
    |          LIMIT 1
    |        
    |        
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUid(uid: Long): Container? {
    var _result = null as com.ustadmobile.lib.db.entities.Container??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Container WHERE containerUid = ?" ,
        postgreSql = """
    |SELECT * FROM Container WHERE containerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findSizeByUid(uid: Long): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT(COALESCE((
    |               SELECT fileSize
    |                 FROM Container
    |                WHERE containerUid = ?), 0))
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT(COALESCE((
    |               SELECT fileSize
    |                 FROM Container
    |                WHERE containerUid = ?), 0))
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend
      fun findRecentContainerToBeMonitoredWithEntriesUid(contentEntries: List<Long>):
      List<Container> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Container>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT recent.* FROM Container recent LEFT JOIN Container old ON (recent.containerContentEntryUid = old.containerContentEntryUid AND recent.cntLastModified < old.cntLastModified) WHERE old.containerUid IS NULL AND recent.containerContentEntryUid IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT recent.* FROM Container recent LEFT JOIN Container old ON (recent.containerContentEntryUid = old.containerContentEntryUid AND recent.cntLastModified < old.cntLastModified) WHERE old.containerUid IS NULL AND recent.containerContentEntryUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          contentEntries.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findContainersForContentEntryUid(contentEntryUid: Long):
      List<Container> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Container>()
    val _stmtConfig = PreparedStatementConfig("""
    |Select Container.* FROM Container 
    |                    WHERE Container.containerContentEntryUid = ?
    |                    ORDER BY Container.cntLastModified DESC
    """.trimMargin() , postgreSql = """
    |Select Container.* FROM Container 
    |                    WHERE Container.containerContentEntryUid = ?
    |                    ORDER BY Container.cntLastModified DESC
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun hasContainerWithFilesToDownload(contentEntryUid: Long):
      DoorLiveData<Boolean> {
    val _result = DoorLiveDataImpl<Boolean>(_db, listOf("Container", "ContainerEntry"))  {
      var _liveResult = false
      val _stmtConfig = PreparedStatementConfig("""
      |
      |          SELECT EXISTS(SELECT 1
      |                          FROM Container 
      |                         WHERE Container.containerContentEntryUid = ?
      |                           AND NOT EXISTS (SELECT ContainerEntry.ceUid 
      |                                         FROM ContainerEntry
      |                                        WHERE ContainerEntry.ceContainerUid = Container.containerUid)   
      |                      ORDER BY cntLastModified DESC LIMIT 1)
      |    
      """.trimMargin() , postgreSql = """
      |
      |          SELECT EXISTS(SELECT 1
      |                          FROM Container 
      |                         WHERE Container.containerContentEntryUid = ?
      |                           AND NOT EXISTS (SELECT ContainerEntry.ceUid 
      |                                         FROM ContainerEntry
      |                                        WHERE ContainerEntry.ceContainerUid = Container.containerUid)   
      |                      ORDER BY cntLastModified DESC LIMIT 1)
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, contentEntryUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val _entity = _resultSet.getBoolean(1)
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun findContainerWithFilesByContentEntryUid(contentEntryUid: Long):
      Container? {
    var _result = null as com.ustadmobile.lib.db.entities.Container??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            SELECT Container.*
    |              FROM Container
    |             WHERE Container.containerContentEntryUid = ?
    |               AND EXISTS (SELECT ContainerEntry.ceUid 
    |                             FROM ContainerEntry
    |                            WHERE ContainerEntry.ceContainerUid = Container.containerUid)     
    |          ORDER BY Container.cntLastModified DESC LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |            SELECT Container.*
    |              FROM Container
    |             WHERE Container.containerContentEntryUid = ?
    |               AND EXISTS (SELECT ContainerEntry.ceUid 
    |                             FROM ContainerEntry
    |                            WHERE ContainerEntry.ceContainerUid = Container.containerUid)     
    |          ORDER BY Container.cntLastModified DESC LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findAllPublikContainers(): List<Container> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Container>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT Container.* FROM Container LEFT JOIN ContentEntry ON ContentEntry.contentEntryUid = containerContentEntryUid WHERE ContentEntry.publik"
        , postgreSql = """
    |SELECT Container.* FROM Container LEFT JOIN ContentEntry ON ContentEntry.contentEntryUid = containerContentEntryUid WHERE ContentEntry.publik
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(containerUid: Long): Container? {
    var _result = null as com.ustadmobile.lib.db.entities.Container??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * From Container WHERE Container.containerUid = ? LIMIT 1" ,
        postgreSql = """
    |SELECT * From Container WHERE Container.containerUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun updateContainerSizeAndNumEntries(containerUid: Long, changeTime: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            UPDATE Container 
    |               SET cntNumEntries = COALESCE(
    |                   (SELECT COUNT(*) 
    |                      FROM ContainerEntry 
    |                     WHERE ceContainerUid = Container.containerUid), 0),
    |                   fileSize = COALESCE(
    |                   (SELECT SUM(ContainerEntryFile.ceCompressedSize) AS totalSize 
    |                      FROM ContainerEntry
    |                      JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid
    |                     WHERE ContainerEntry.ceContainerUid = Container.containerUid), 0),
    |                   cntLct = ?   
    |                     
    |             WHERE containerUid = ?
    |        
    """.trimMargin() , postgreSql = """
    |
    |            UPDATE Container 
    |               SET cntNumEntries = COALESCE(
    |                   (SELECT COUNT(*) 
    |                      FROM ContainerEntry 
    |                     WHERE ceContainerUid = Container.containerUid), 0),
    |                   fileSize = COALESCE(
    |                   (SELECT SUM(ContainerEntryFile.ceCompressedSize) AS totalSize 
    |                      FROM ContainerEntry
    |                      JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid
    |                     WHERE ContainerEntry.ceContainerUid = Container.containerUid), 0),
    |                   cntLct = ?   
    |                     
    |             WHERE containerUid = ?
    |        
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, changeTime)
      _stmt.setLong(2, containerUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override suspend fun updateContainerSizeAndNumEntriesAsync(containerUid: Long,
      changeTime: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            UPDATE Container 
    |               SET cntNumEntries = COALESCE(
    |                   (SELECT COUNT(*) 
    |                      FROM ContainerEntry 
    |                     WHERE ceContainerUid = Container.containerUid), 0),
    |                   fileSize = COALESCE(
    |                   (SELECT SUM(ContainerEntryFile.ceCompressedSize) AS totalSize 
    |                      FROM ContainerEntry
    |                      JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid
    |                     WHERE ContainerEntry.ceContainerUid = Container.containerUid), 0),
    |                   cntLct = ?   
    |                     
    |             WHERE containerUid = ?
    |        
    """.trimMargin() , postgreSql = """
    |
    |            UPDATE Container 
    |               SET cntNumEntries = COALESCE(
    |                   (SELECT COUNT(*) 
    |                      FROM ContainerEntry 
    |                     WHERE ceContainerUid = Container.containerUid), 0),
    |                   fileSize = COALESCE(
    |                   (SELECT SUM(ContainerEntryFile.ceCompressedSize) AS totalSize 
    |                      FROM ContainerEntry
    |                      JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid
    |                     WHERE ContainerEntry.ceContainerUid = Container.containerUid), 0),
    |                   cntLct = ?   
    |                     
    |             WHERE containerUid = ?
    |        
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, changeTime)
      _stmt.setLong(2, containerUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findLocalAvailabilityByUid(containerUid: Long): Long {
    var _result = 0L
    val _stmtConfig =
        PreparedStatementConfig("SELECT Container.containerUid FROM Container WHERE Container.containerUid = ? AND (SELECT COUNT(*) FROM ContainerEntry WHERE ceContainerUid = Container.containerUid) = Container.cntNumEntries"
        , postgreSql = """
    |SELECT Container.containerUid FROM Container WHERE Container.containerUid = ? AND (SELECT COUNT(*) FROM ContainerEntry WHERE ceContainerUid = Container.containerUid) = Container.cntNumEntries
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findAllWithId(containerUid: Long): List<Container> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Container>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Container WHERE Container.containerUid = ?" ,
        postgreSql = """
    |SELECT * FROM Container WHERE Container.containerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findKhanContainers(): List<ContainerWithContentEntry> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContainerWithContentEntry>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT Container.*, ContentEntry.entryId, ContentEntry.sourceUrl FROM Container LEFT JOIN ContentEntry ON Container.containerContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntry.publisher LIKE '%Khan Academy%' AND Container.mimeType = 'video/mp4'"
        , postgreSql = """
    |SELECT Container.*, ContentEntry.entryId, ContentEntry.sourceUrl FROM Container LEFT JOIN ContentEntry ON Container.containerContentEntryUid = ContentEntry.contentEntryUid WHERE ContentEntry.publisher LIKE '%Khan Academy%' AND Container.mimeType = 'video/mp4'
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = ContainerWithContentEntry()
          _entity.entryId = tmp_entryId
          _entity.sourceUrl = tmp_sourceUrl
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun deleteByUid(containerUid: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("DELETE FROM Container WHERE containerUid = ?" ,
        postgreSql = """
    |DELETE FROM Container WHERE containerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun updateMimeType(mimeType: String, containerUid: Long): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE Container SET mimeType = ? WHERE Container.containerUid = ?"
        , postgreSql = """
    |UPDATE Container SET mimeType = ? WHERE Container.containerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, mimeType)
      _stmt.setLong(2, containerUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override suspend fun getMostRecentContainerForContentEntryAsync(contentEntryUid: Long):
      Container? {
    var _result = null as com.ustadmobile.lib.db.entities.Container??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            SELECT Container.*
    |            
    |            FROM Container
    |             WHERE Container.containerContentEntryUid = ?
    |               AND 
    |            Container.fileSize > 0
    |             
    |          ORDER BY Container.cntLastModified DESC 
    |          LIMIT 1
    |        
    |        
    """.trimMargin() , postgreSql = """
    |
    |            SELECT Container.*
    |            
    |            FROM Container
    |             WHERE Container.containerContentEntryUid = ?
    |               AND 
    |            Container.fileSize > 0
    |             
    |          ORDER BY Container.cntLastModified DESC 
    |          LIMIT 1
    |        
    |        
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          val tmp_cntLct = _resultSet.getLong("cntLct")
          val tmp_fileSize = _resultSet.getLong("fileSize")
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val tmp_remarks = _resultSet.getString("remarks")
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          val _entity = Container()
          _entity.containerUid = tmp_containerUid
          _entity.cntLocalCsn = tmp_cntLocalCsn
          _entity.cntMasterCsn = tmp_cntMasterCsn
          _entity.cntLastModBy = tmp_cntLastModBy
          _entity.cntLct = tmp_cntLct
          _entity.fileSize = tmp_fileSize
          _entity.containerContentEntryUid = tmp_containerContentEntryUid
          _entity.cntLastModified = tmp_cntLastModified
          _entity.mimeType = tmp_mimeType
          _entity.remarks = tmp_remarks
          _entity.mobileOptimized = tmp_mobileOptimized
          _entity.cntNumEntries = tmp_cntNumEntries
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun getMostRecentContainerUidForContentEntryAsync(contentEntryUid: Long):
      Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT COALESCE((
    |                SELECT containerUid 
    |                 
    |            FROM Container
    |             WHERE Container.containerContentEntryUid = ?
    |               AND 
    |            Container.fileSize > 0
    |             
    |          ORDER BY Container.cntLastModified DESC 
    |          LIMIT 1
    |        ), 0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT COALESCE((
    |                SELECT containerUid 
    |                 
    |            FROM Container
    |             WHERE Container.containerContentEntryUid = ?
    |               AND 
    |            Container.fileSize > 0
    |             
    |          ORDER BY Container.cntLastModified DESC 
    |          LIMIT 1
    |        ), 0)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun getMostRecentAvailableContainerUidAndMimeType(contentEntryUid: Long,
      downloadRequired: Boolean): ContainerUidAndMimeType? {
    var _result = null as com.ustadmobile.lib.db.entities.ContainerUidAndMimeType??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Container.containerUid, Container.mimeType 
    |          FROM Container
    |         WHERE Container.containerContentEntryUid = ?
    |           AND 
    |            Container.fileSize > 0
    |        
    |           AND (CAST(? AS INTEGER) = 0
    |                OR EXISTS (SELECT ContainerEntry.ceUid 
    |                             FROM ContainerEntry
    |                            WHERE ContainerEntry.ceContainerUid = Container.containerUid))
    |      ORDER BY Container.cntLastModified DESC 
    |         LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Container.containerUid, Container.mimeType 
    |          FROM Container
    |         WHERE Container.containerContentEntryUid = ?
    |           AND 
    |            Container.fileSize > 0
    |        
    |           AND (CAST(? AS INTEGER) = 0
    |                OR EXISTS (SELECT ContainerEntry.ceUid 
    |                             FROM ContainerEntry
    |                            WHERE ContainerEntry.ceContainerUid = Container.containerUid))
    |      ORDER BY Container.cntLastModified DESC 
    |         LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.setBoolean(2, downloadRequired)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_containerUid = _resultSet.getLong("containerUid")
          val tmp_mimeType = _resultSet.getString("mimeType")
          val _entity = ContainerUidAndMimeType()
          _entity.containerUid = tmp_containerUid
          _entity.mimeType = tmp_mimeType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun replaceList(entries: List<out Container>): Unit {
    _insertAdapterContainer_upsert.insertList(entries)
  }

  public override fun insertWithReplace(container: Container): Unit {
    _insertAdapterContainer_upsert.insert(container)
  }

  public override suspend fun getContainerSizeByUid(containerUid: Long): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT COALESCE(
    |               (SELECT fileSize
    |                  FROM Container
    |                 WHERE containerUid = ?), -1)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT COALESCE(
    |               (SELECT fileSize
    |                  FROM Container
    |                 WHERE containerUid = ?), -1)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: Container): Long {
    val _retVal = _insertAdapterContainer_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Container): Long {
    val _retVal = _insertAdapterContainer_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Container>): Unit {
    _insertAdapterContainer_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Container>): Unit {
    val _sql =
        "UPDATE Container SET cntLocalCsn = ?, cntMasterCsn = ?, cntLastModBy = ?, cntLct = ?, fileSize = ?, containerContentEntryUid = ?, cntLastModified = ?, mimeType = ?, remarks = ?, mobileOptimized = ?, cntNumEntries = ? WHERE containerUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cntLocalCsn)
        _stmt.setLong(2, _entity.cntMasterCsn)
        _stmt.setInt(3, _entity.cntLastModBy)
        _stmt.setLong(4, _entity.cntLct)
        _stmt.setLong(5, _entity.fileSize)
        _stmt.setLong(6, _entity.containerContentEntryUid)
        _stmt.setLong(7, _entity.cntLastModified)
        _stmt.setString(8, _entity.mimeType)
        _stmt.setString(9, _entity.remarks)
        _stmt.setBoolean(10, _entity.mobileOptimized)
        _stmt.setInt(11, _entity.cntNumEntries)
        _stmt.setLong(12, _entity.containerUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Container): Unit {
    val _sql =
        "UPDATE Container SET cntLocalCsn = ?, cntMasterCsn = ?, cntLastModBy = ?, cntLct = ?, fileSize = ?, containerContentEntryUid = ?, cntLastModified = ?, mimeType = ?, remarks = ?, mobileOptimized = ?, cntNumEntries = ? WHERE containerUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cntLocalCsn)
      _stmt.setLong(2, entity.cntMasterCsn)
      _stmt.setInt(3, entity.cntLastModBy)
      _stmt.setLong(4, entity.cntLct)
      _stmt.setLong(5, entity.fileSize)
      _stmt.setLong(6, entity.containerContentEntryUid)
      _stmt.setLong(7, entity.cntLastModified)
      _stmt.setString(8, entity.mimeType)
      _stmt.setString(9, entity.remarks)
      _stmt.setBoolean(10, entity.mobileOptimized)
      _stmt.setInt(11, entity.cntNumEntries)
      _stmt.setLong(12, entity.containerUid)
      _stmt.executeUpdate()
    }
  }
}
