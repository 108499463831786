package com.ustadmobile.lib.db.entities

class PersonGroupAssignmentSummary {

    var submitterUid: Long = 0

    var name: String? = null

    var latestPrivateComment: String? = null

    var fileSubmissionStatus: Int = 0

    var isGroupAssignment: Boolean = false

}