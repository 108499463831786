package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.lib.db.entities.DiscussionTopic
import com.ustadmobile.lib.db.entities.DiscussionTopicListDetail
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class DiscussionTopicDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: DiscussionTopicDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : DiscussionTopicDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun getListOfTopicsByDiscussion(discussionUid: Long): DoorDataSourceFactory<Int,
      DiscussionTopicListDetail> {
    val _result = _dao.getListOfTopicsByDiscussion(discussionUid)
    return _result
  }

  public override suspend fun getTopicsByClazz(clazzUid: Long): List<DiscussionTopic> {
    val _result = _dao.getTopicsByClazz(clazzUid)
    return _result
  }

  public override fun getDiscussionTopicByUid(discussionTopicUid: Long):
      DoorLiveData<DiscussionTopic?> {
    val _result = _dao.getDiscussionTopicByUid(discussionTopicUid)
    return _result
  }

  public override suspend fun updateActiveByUid(
    uid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    _dao.updateActiveByUid(uid, active, changeTime)
  }

  public override suspend fun replaceListAsync(list: List<out DiscussionTopic>): Unit {
    _dao.replaceListAsync(list)
  }

  public override fun insert(entity: DiscussionTopic): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: DiscussionTopic): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out DiscussionTopic>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out DiscussionTopic>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: DiscussionTopic): Unit {
    _dao.update(entity)
  }

  public override suspend fun insertListAsync(entityList: List<out DiscussionTopic>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out DiscussionTopic>): Unit {
    _dao.updateListAsync(entityList)
  }
}
