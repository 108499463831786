package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.CourseBlockWithCompleteEntity
import com.ustadmobile.lib.db.entities.CourseBlockWithEntityDb
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseBlockDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: CourseBlockDao
) : CourseBlockDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findByUidAsync(uid: Long): CourseBlock? = _dao.findByUidAsync(uid)

  public override suspend fun updateAsync(entity: CourseBlock): Int {
    entity.cbLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override suspend fun replaceListAsync(list: List<out CourseBlock>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    list.iterator().forEach  {
      if(it.cbUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(124)
        it.cbUid = _newPk
        _generatedPks += _newPk
      }
      it.cbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.replaceListAsync(list)
  }

  public override suspend fun findAllCourseBlockByClazzUidAsync(clazzUid: Long):
      List<CourseBlockWithEntityDb> = _dao.findAllCourseBlockByClazzUidAsync(clazzUid)

  public override fun findAllCourseBlockByClazzUidLive(
    clazzUid: Long,
    personUid: Long,
    collapseList: List<Long>,
    currentTime: Long
  ): DoorDataSourceFactory<Int, CourseBlockWithCompleteEntity> =
      _dao.findAllCourseBlockByClazzUidLive(clazzUid, personUid, collapseList, currentTime)

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    _dao.updateActiveByUid(cbUid, active, changeTime)
  }

  public override fun insert(entity: CourseBlock): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseBlock): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.cbUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(124)
      entity.cbUid = _newPk
    }
    entity.cbLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.cbUid
  }

  public override fun insertList(entityList: List<out CourseBlock>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out CourseBlock>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseBlock): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertListAsync(entityList: List<out CourseBlock>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.cbUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(124)
        it.cbUid = _newPk
        _generatedPks += _newPk
      }
      it.cbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out CourseBlock>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.cbLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
