package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.LearnerGroupMember
import com.ustadmobile.lib.db.entities.LearnerGroupMemberWithPerson
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class LearnerGroupMemberDao_JdbcKt(
  public val _db: DoorDatabase
) : LearnerGroupMemberDao() {
  public val _insertAdapterLearnerGroupMember_: EntityInsertionAdapter<LearnerGroupMember> = object
      : EntityInsertionAdapter<LearnerGroupMember>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO LearnerGroupMember (learnerGroupMemberUid, learnerGroupMemberPersonUid, learnerGroupMemberLgUid, learnerGroupMemberRole, learnerGroupMemberActive, learnerGroupMemberMCSN, learnerGroupMemberCSN, learnerGroupMemberLCB, learnerGroupMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO LearnerGroupMember (learnerGroupMemberUid, learnerGroupMemberPersonUid, learnerGroupMemberLgUid, learnerGroupMemberRole, learnerGroupMemberActive, learnerGroupMemberMCSN, learnerGroupMemberCSN, learnerGroupMemberLCB, learnerGroupMemberLct) VALUES(COALESCE(?,nextval('LearnerGroupMember_learnerGroupMemberUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING learnerGroupMemberUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: LearnerGroupMember): Unit {
      if(entity.learnerGroupMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.learnerGroupMemberUid)
      }
      stmt.setLong(2, entity.learnerGroupMemberPersonUid)
      stmt.setLong(3, entity.learnerGroupMemberLgUid)
      stmt.setInt(4, entity.learnerGroupMemberRole)
      stmt.setBoolean(5, entity.learnerGroupMemberActive)
      stmt.setLong(6, entity.learnerGroupMemberMCSN)
      stmt.setLong(7, entity.learnerGroupMemberCSN)
      stmt.setInt(8, entity.learnerGroupMemberLCB)
      stmt.setLong(9, entity.learnerGroupMemberLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO LearnerGroupMemberReplicate(lgmPk, lgmDestination)
    |      SELECT DISTINCT LearnerGroupMember.learnerGroupMemberUid AS lgmPk,
    |             ? AS lgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND LearnerGroupMember.learnerGroupMemberLct != COALESCE(
    |             (SELECT lgmVersionId
    |                FROM LearnerGroupMemberReplicate
    |               WHERE lgmPk = LearnerGroupMember.learnerGroupMemberUid
    |                 AND lgmDestination = ?), 0) 
    |      /*psql ON CONFLICT(lgmPk, lgmDestination) DO UPDATE
    |             SET lgmPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LearnerGroupMemberReplicate(lgmPk, lgmDestination)
    |      SELECT DISTINCT LearnerGroupMember.learnerGroupMemberUid AS lgmPk,
    |             ? AS lgmDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND LearnerGroupMember.learnerGroupMemberLct != COALESCE(
    |             (SELECT lgmVersionId
    |                FROM LearnerGroupMemberReplicate
    |               WHERE lgmPk = LearnerGroupMember.learnerGroupMemberUid
    |                 AND lgmDestination = ?), 0) 
    |       ON CONFLICT(lgmPk, lgmDestination) DO UPDATE
    |             SET lgmPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO LearnerGroupMemberReplicate(lgmPk, lgmDestination)
    |  SELECT DISTINCT LearnerGroupMember.learnerGroupMemberUid AS lgmUid,
    |         UserSession.usClientNodeId AS lgmDestination
    |    FROM ChangeLog
    |         JOIN LearnerGroupMember
    |              ON ChangeLog.chTableId = 300
    |                 AND ChangeLog.chEntityPk = LearnerGroupMember.learnerGroupMemberUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LearnerGroupMember.learnerGroupMemberLct != COALESCE(
    |         (SELECT lgmVersionId
    |            FROM LearnerGroupMemberReplicate
    |           WHERE lgmPk = LearnerGroupMember.learnerGroupMemberUid
    |             AND lgmDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(lgmPk, lgmDestination) DO UPDATE
    |     SET lgmPending = true
    |  */
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LearnerGroupMemberReplicate(lgmPk, lgmDestination)
    |  SELECT DISTINCT LearnerGroupMember.learnerGroupMemberUid AS lgmUid,
    |         UserSession.usClientNodeId AS lgmDestination
    |    FROM ChangeLog
    |         JOIN LearnerGroupMember
    |              ON ChangeLog.chTableId = 300
    |                 AND ChangeLog.chEntityPk = LearnerGroupMember.learnerGroupMemberUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LearnerGroupMember.learnerGroupMemberLct != COALESCE(
    |         (SELECT lgmVersionId
    |            FROM LearnerGroupMemberReplicate
    |           WHERE lgmPk = LearnerGroupMember.learnerGroupMemberUid
    |             AND lgmDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(lgmPk, lgmDestination) DO UPDATE
    |     SET lgmPending = true
    |  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findLearnerGroupMembersByGroupIdAndEntry(learnerGroupUid: Long,
      contentEntryUid: Long): DoorDataSourceFactory<Int, LearnerGroupMemberWithPerson> {
    val _result = object : DoorDataSourceFactory<Int, LearnerGroupMemberWithPerson>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<LearnerGroupMemberWithPerson>> =
          DoorLiveDataImpl<List<LearnerGroupMemberWithPerson>>(_db, listOf("LearnerGroupMember",
          "Person", "GroupLearningSession"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.LearnerGroupMemberWithPerson>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
        |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
        |        LEFT JOIN GroupLearningSession ON 
        |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
        |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
        |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
        |    ORDER BY learnerGroupMemberRole ASC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
        |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
        |        LEFT JOIN GroupLearningSession ON 
        |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
        |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
        |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
        |    ORDER BY learnerGroupMemberRole ASC
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, learnerGroupUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_learnerGroupMemberUid = _resultSet.getLong("learnerGroupMemberUid")
              val tmp_learnerGroupMemberPersonUid =
                  _resultSet.getLong("learnerGroupMemberPersonUid")
              val tmp_learnerGroupMemberLgUid = _resultSet.getLong("learnerGroupMemberLgUid")
              val tmp_learnerGroupMemberRole = _resultSet.getInt("learnerGroupMemberRole")
              val tmp_learnerGroupMemberActive = _resultSet.getBoolean("learnerGroupMemberActive")
              val tmp_learnerGroupMemberMCSN = _resultSet.getLong("learnerGroupMemberMCSN")
              val tmp_learnerGroupMemberCSN = _resultSet.getLong("learnerGroupMemberCSN")
              val tmp_learnerGroupMemberLCB = _resultSet.getInt("learnerGroupMemberLCB")
              val tmp_learnerGroupMemberLct = _resultSet.getLong("learnerGroupMemberLct")
              val _entity = LearnerGroupMemberWithPerson()
              _entity.learnerGroupMemberUid = tmp_learnerGroupMemberUid
              _entity.learnerGroupMemberPersonUid = tmp_learnerGroupMemberPersonUid
              _entity.learnerGroupMemberLgUid = tmp_learnerGroupMemberLgUid
              _entity.learnerGroupMemberRole = tmp_learnerGroupMemberRole
              _entity.learnerGroupMemberActive = tmp_learnerGroupMemberActive
              _entity.learnerGroupMemberMCSN = tmp_learnerGroupMemberMCSN
              _entity.learnerGroupMemberCSN = tmp_learnerGroupMemberCSN
              _entity.learnerGroupMemberLCB = tmp_learnerGroupMemberLCB
              _entity.learnerGroupMemberLct = tmp_learnerGroupMemberLct
              var _person_nullFieldCount = 0
              val tmp_personUid = _resultSet.getLong("personUid")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_username = _resultSet.getString("username")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_firstNames = _resultSet.getString("firstNames")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_lastName = _resultSet.getString("lastName")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_gender = _resultSet.getInt("gender")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_active = _resultSet.getBoolean("active")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_admin = _resultSet.getBoolean("admin")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personNotes = _resultSet.getString("personNotes")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_fatherName = _resultSet.getString("fatherName")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_motherName = _resultSet.getString("motherName")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_motherNum = _resultSet.getString("motherNum")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personAddress = _resultSet.getString("personAddress")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personLct = _resultSet.getLong("personLct")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personCountry = _resultSet.getString("personCountry")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personType = _resultSet.getInt("personType")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              if(_person_nullFieldCount < 24) {
                if(_entity.person == null) {
                  _entity.person = Person()
                }
                _entity.person!!.personUid = tmp_personUid
                _entity.person!!.username = tmp_username
                _entity.person!!.firstNames = tmp_firstNames
                _entity.person!!.lastName = tmp_lastName
                _entity.person!!.emailAddr = tmp_emailAddr
                _entity.person!!.phoneNum = tmp_phoneNum
                _entity.person!!.gender = tmp_gender
                _entity.person!!.active = tmp_active
                _entity.person!!.admin = tmp_admin
                _entity.person!!.personNotes = tmp_personNotes
                _entity.person!!.fatherName = tmp_fatherName
                _entity.person!!.fatherNumber = tmp_fatherNumber
                _entity.person!!.motherName = tmp_motherName
                _entity.person!!.motherNum = tmp_motherNum
                _entity.person!!.dateOfBirth = tmp_dateOfBirth
                _entity.person!!.personAddress = tmp_personAddress
                _entity.person!!.personOrgId = tmp_personOrgId
                _entity.person!!.personGroupUid = tmp_personGroupUid
                _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
                _entity.person!!.personLct = tmp_personLct
                _entity.person!!.personCountry = tmp_personCountry
                _entity.person!!.personType = tmp_personType
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("LearnerGroupMember", "Person", "GroupLearningSession"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
        |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
        |        LEFT JOIN GroupLearningSession ON 
        |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
        |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
        |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
        |    ORDER BY learnerGroupMemberRole ASC
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
        |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
        |        LEFT JOIN GroupLearningSession ON 
        |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
        |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
        |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
        |    ORDER BY learnerGroupMemberRole ASC
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, learnerGroupUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun findLearnerGroupMembersByGroupIdAndEntryList(learnerGroupUid: Long,
      contentEntryUid: Long): List<LearnerGroupMemberWithPerson> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.LearnerGroupMemberWithPerson>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
    |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
    |        LEFT JOIN GroupLearningSession ON 
    |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
    |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
    |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
    |    ORDER BY learnerGroupMemberRole ASC
    |    
    """.trimMargin() , postgreSql = """
    |SELECT LearnerGroupMember.*, Person.* FROM LearnerGroupMember 
    |        LEFT JOIN Person ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid 
    |        LEFT JOIN GroupLearningSession ON 
    |    GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid 
    |    WHERE GroupLearningSession.groupLearningSessionLearnerGroupUid = ? 
    |    AND GroupLearningSession.groupLearningSessionContentUid = ? 
    |    ORDER BY learnerGroupMemberRole ASC
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, learnerGroupUid)
      _stmt.setLong(2, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_learnerGroupMemberUid = _resultSet.getLong("learnerGroupMemberUid")
          val tmp_learnerGroupMemberPersonUid = _resultSet.getLong("learnerGroupMemberPersonUid")
          val tmp_learnerGroupMemberLgUid = _resultSet.getLong("learnerGroupMemberLgUid")
          val tmp_learnerGroupMemberRole = _resultSet.getInt("learnerGroupMemberRole")
          val tmp_learnerGroupMemberActive = _resultSet.getBoolean("learnerGroupMemberActive")
          val tmp_learnerGroupMemberMCSN = _resultSet.getLong("learnerGroupMemberMCSN")
          val tmp_learnerGroupMemberCSN = _resultSet.getLong("learnerGroupMemberCSN")
          val tmp_learnerGroupMemberLCB = _resultSet.getInt("learnerGroupMemberLCB")
          val tmp_learnerGroupMemberLct = _resultSet.getLong("learnerGroupMemberLct")
          val _entity = LearnerGroupMemberWithPerson()
          _entity.learnerGroupMemberUid = tmp_learnerGroupMemberUid
          _entity.learnerGroupMemberPersonUid = tmp_learnerGroupMemberPersonUid
          _entity.learnerGroupMemberLgUid = tmp_learnerGroupMemberLgUid
          _entity.learnerGroupMemberRole = tmp_learnerGroupMemberRole
          _entity.learnerGroupMemberActive = tmp_learnerGroupMemberActive
          _entity.learnerGroupMemberMCSN = tmp_learnerGroupMemberMCSN
          _entity.learnerGroupMemberCSN = tmp_learnerGroupMemberCSN
          _entity.learnerGroupMemberLCB = tmp_learnerGroupMemberLCB
          _entity.learnerGroupMemberLct = tmp_learnerGroupMemberLct
          var _person_nullFieldCount = 0
          val tmp_personUid = _resultSet.getLong("personUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_username = _resultSet.getString("username")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_firstNames = _resultSet.getString("firstNames")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_lastName = _resultSet.getString("lastName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_gender = _resultSet.getInt("gender")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_active = _resultSet.getBoolean("active")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_admin = _resultSet.getBoolean("admin")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personNotes = _resultSet.getString("personNotes")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherName = _resultSet.getString("fatherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherName = _resultSet.getString("motherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherNum = _resultSet.getString("motherNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personAddress = _resultSet.getString("personAddress")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLct = _resultSet.getLong("personLct")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personCountry = _resultSet.getString("personCountry")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personType = _resultSet.getInt("personType")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          if(_person_nullFieldCount < 24) {
            if(_entity.person == null) {
              _entity.person = Person()
            }
            _entity.person!!.personUid = tmp_personUid
            _entity.person!!.username = tmp_username
            _entity.person!!.firstNames = tmp_firstNames
            _entity.person!!.lastName = tmp_lastName
            _entity.person!!.emailAddr = tmp_emailAddr
            _entity.person!!.phoneNum = tmp_phoneNum
            _entity.person!!.gender = tmp_gender
            _entity.person!!.active = tmp_active
            _entity.person!!.admin = tmp_admin
            _entity.person!!.personNotes = tmp_personNotes
            _entity.person!!.fatherName = tmp_fatherName
            _entity.person!!.fatherNumber = tmp_fatherNumber
            _entity.person!!.motherName = tmp_motherName
            _entity.person!!.motherNum = tmp_motherNum
            _entity.person!!.dateOfBirth = tmp_dateOfBirth
            _entity.person!!.personAddress = tmp_personAddress
            _entity.person!!.personOrgId = tmp_personOrgId
            _entity.person!!.personGroupUid = tmp_personGroupUid
            _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
            _entity.person!!.personLct = tmp_personLct
            _entity.person!!.personCountry = tmp_personCountry
            _entity.person!!.personType = tmp_personType
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun insert(entity: LearnerGroupMember): Long {
    val _retVal = _insertAdapterLearnerGroupMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: LearnerGroupMember): Long {
    val _retVal = _insertAdapterLearnerGroupMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out LearnerGroupMember>): Unit {
    _insertAdapterLearnerGroupMember_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out LearnerGroupMember>): Unit {
    val _sql =
        "UPDATE LearnerGroupMember SET learnerGroupMemberPersonUid = ?, learnerGroupMemberLgUid = ?, learnerGroupMemberRole = ?, learnerGroupMemberActive = ?, learnerGroupMemberMCSN = ?, learnerGroupMemberCSN = ?, learnerGroupMemberLCB = ?, learnerGroupMemberLct = ? WHERE learnerGroupMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.learnerGroupMemberPersonUid)
        _stmt.setLong(2, _entity.learnerGroupMemberLgUid)
        _stmt.setInt(3, _entity.learnerGroupMemberRole)
        _stmt.setBoolean(4, _entity.learnerGroupMemberActive)
        _stmt.setLong(5, _entity.learnerGroupMemberMCSN)
        _stmt.setLong(6, _entity.learnerGroupMemberCSN)
        _stmt.setInt(7, _entity.learnerGroupMemberLCB)
        _stmt.setLong(8, _entity.learnerGroupMemberLct)
        _stmt.setLong(9, _entity.learnerGroupMemberUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: LearnerGroupMember): Unit {
    val _sql =
        "UPDATE LearnerGroupMember SET learnerGroupMemberPersonUid = ?, learnerGroupMemberLgUid = ?, learnerGroupMemberRole = ?, learnerGroupMemberActive = ?, learnerGroupMemberMCSN = ?, learnerGroupMemberCSN = ?, learnerGroupMemberLCB = ?, learnerGroupMemberLct = ? WHERE learnerGroupMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.learnerGroupMemberPersonUid)
      _stmt.setLong(2, entity.learnerGroupMemberLgUid)
      _stmt.setInt(3, entity.learnerGroupMemberRole)
      _stmt.setBoolean(4, entity.learnerGroupMemberActive)
      _stmt.setLong(5, entity.learnerGroupMemberMCSN)
      _stmt.setLong(6, entity.learnerGroupMemberCSN)
      _stmt.setInt(7, entity.learnerGroupMemberLCB)
      _stmt.setLong(8, entity.learnerGroupMemberLct)
      _stmt.setLong(9, entity.learnerGroupMemberUid)
      _stmt.executeUpdate()
    }
  }
}
