package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Schedule
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ScheduleDao_JdbcKt(
  public val _db: DoorDatabase
) : ScheduleDao() {
  public val _insertAdapterSchedule_: EntityInsertionAdapter<Schedule> = object :
      EntityInsertionAdapter<Schedule>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Schedule (scheduleUid, sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Schedule (scheduleUid, sceduleStartTime, scheduleEndTime, scheduleDay, scheduleMonth, scheduleFrequency, umCalendarUid, scheduleClazzUid, scheduleMasterChangeSeqNum, scheduleLocalChangeSeqNum, scheduleLastChangedBy, scheduleLastChangedTime, scheduleActive) VALUES(COALESCE(?,nextval('Schedule_scheduleUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING scheduleUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Schedule): Unit {
      if(entity.scheduleUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.scheduleUid)
      }
      stmt.setLong(2, entity.sceduleStartTime)
      stmt.setLong(3, entity.scheduleEndTime)
      stmt.setInt(4, entity.scheduleDay)
      stmt.setInt(5, entity.scheduleMonth)
      stmt.setInt(6, entity.scheduleFrequency)
      stmt.setLong(7, entity.umCalendarUid)
      stmt.setLong(8, entity.scheduleClazzUid)
      stmt.setLong(9, entity.scheduleMasterChangeSeqNum)
      stmt.setLong(10, entity.scheduleLocalChangeSeqNum)
      stmt.setInt(11, entity.scheduleLastChangedBy)
      stmt.setLong(12, entity.scheduleLastChangedTime)
      stmt.setBoolean(13, entity.scheduleActive)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ScheduleReplicate(schedulePk, scheduleDestination)
    |      SELECT DISTINCT Schedule.scheduleUid AS schedulePk,
    |             ? AS scheduleDestination
    |        FROM UserSession
    |              JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |              
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |              JOIN Schedule
    |                   ON Schedule.scheduleClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Schedule.scheduleLastChangedTime != COALESCE(
    |             (SELECT scheduleVersionId
    |                FROM ScheduleReplicate
    |               WHERE schedulePk = Schedule.scheduleUid
    |                 AND scheduleDestination = ?), 0) 
    |      /*psql ON CONFLICT(schedulePk, scheduleDestination) DO UPDATE
    |             SET schedulePending = true
    |      */       
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScheduleReplicate(schedulePk, scheduleDestination)
    |      SELECT DISTINCT Schedule.scheduleUid AS schedulePk,
    |             ? AS scheduleDestination
    |        FROM UserSession
    |              JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |              
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |              JOIN Schedule
    |                   ON Schedule.scheduleClazzUid = Clazz.clazzUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND Schedule.scheduleLastChangedTime != COALESCE(
    |             (SELECT scheduleVersionId
    |                FROM ScheduleReplicate
    |               WHERE schedulePk = Schedule.scheduleUid
    |                 AND scheduleDestination = ?), 0) 
    |       ON CONFLICT(schedulePk, scheduleDestination) DO UPDATE
    |             SET schedulePending = true
    |             
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ScheduleReplicate(schedulePk, scheduleDestination)
    |  SELECT DISTINCT Schedule.scheduleUid AS scheduleUid,
    |         UserSession.usClientNodeId AS scheduleDestination
    |    FROM ChangeLog
    |         JOIN Schedule
    |              ON ChangeLog.chTableId = 21
    |                 AND Schedule.scheduleUid = ChangeLog.chEntityPk
    |         JOIN Clazz
    |              ON Clazz.clazzUid = Schedule.scheduleClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Schedule.scheduleLastChangedTime != COALESCE(
    |         (SELECT scheduleVersionId
    |            FROM ScheduleReplicate
    |           WHERE schedulePk = Schedule.scheduleUid
    |             AND scheduleDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(schedulePk, scheduleDestination) DO UPDATE
    |     SET schedulePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ScheduleReplicate(schedulePk, scheduleDestination)
    |  SELECT DISTINCT Schedule.scheduleUid AS scheduleUid,
    |         UserSession.usClientNodeId AS scheduleDestination
    |    FROM ChangeLog
    |         JOIN Schedule
    |              ON ChangeLog.chTableId = 21
    |                 AND Schedule.scheduleUid = ChangeLog.chEntityPk
    |         JOIN Clazz
    |              ON Clazz.clazzUid = Schedule.scheduleClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Schedule.scheduleLastChangedTime != COALESCE(
    |         (SELECT scheduleVersionId
    |            FROM ScheduleReplicate
    |           WHERE schedulePk = Schedule.scheduleUid
    |             AND scheduleDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(schedulePk, scheduleDestination) DO UPDATE
    |     SET schedulePending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: Schedule): Long {
    val _retVal = _insertAdapterSchedule_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun updateAsync(entity: Schedule): Int {
    var _result = 0
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.sceduleStartTime)
      _stmt.setLong(2, entity.scheduleEndTime)
      _stmt.setInt(3, entity.scheduleDay)
      _stmt.setInt(4, entity.scheduleMonth)
      _stmt.setInt(5, entity.scheduleFrequency)
      _stmt.setLong(6, entity.umCalendarUid)
      _stmt.setLong(7, entity.scheduleClazzUid)
      _stmt.setLong(8, entity.scheduleMasterChangeSeqNum)
      _stmt.setLong(9, entity.scheduleLocalChangeSeqNum)
      _stmt.setInt(10, entity.scheduleLastChangedBy)
      _stmt.setLong(11, entity.scheduleLastChangedTime)
      _stmt.setBoolean(12, entity.scheduleActive)
      _stmt.setLong(13, entity.scheduleUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun updateScheduleActivated(
    scheduleUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE Schedule 
    |           SET scheduleActive = ?,
    |               scheduleLastChangedTime = ?
    |         WHERE scheduleUid = ?
    |            
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE Schedule 
    |           SET scheduleActive = ?,
    |               scheduleLastChangedTime = ?
    |         WHERE scheduleUid = ?
    |            
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, active)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, scheduleUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): Schedule? {
    var _result = null as com.ustadmobile.lib.db.entities.Schedule??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleUid = ?" ,
        postgreSql = """
    |SELECT * FROM Schedule WHERE scheduleUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_scheduleUid = _resultSet.getLong("scheduleUid")
          val tmp_sceduleStartTime = _resultSet.getLong("sceduleStartTime")
          val tmp_scheduleEndTime = _resultSet.getLong("scheduleEndTime")
          val tmp_scheduleDay = _resultSet.getInt("scheduleDay")
          val tmp_scheduleMonth = _resultSet.getInt("scheduleMonth")
          val tmp_scheduleFrequency = _resultSet.getInt("scheduleFrequency")
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          val tmp_scheduleClazzUid = _resultSet.getLong("scheduleClazzUid")
          val tmp_scheduleMasterChangeSeqNum = _resultSet.getLong("scheduleMasterChangeSeqNum")
          val tmp_scheduleLocalChangeSeqNum = _resultSet.getLong("scheduleLocalChangeSeqNum")
          val tmp_scheduleLastChangedBy = _resultSet.getInt("scheduleLastChangedBy")
          val tmp_scheduleLastChangedTime = _resultSet.getLong("scheduleLastChangedTime")
          val tmp_scheduleActive = _resultSet.getBoolean("scheduleActive")
          val _entity = Schedule()
          _entity.scheduleUid = tmp_scheduleUid
          _entity.sceduleStartTime = tmp_sceduleStartTime
          _entity.scheduleEndTime = tmp_scheduleEndTime
          _entity.scheduleDay = tmp_scheduleDay
          _entity.scheduleMonth = tmp_scheduleMonth
          _entity.scheduleFrequency = tmp_scheduleFrequency
          _entity.umCalendarUid = tmp_umCalendarUid
          _entity.scheduleClazzUid = tmp_scheduleClazzUid
          _entity.scheduleMasterChangeSeqNum = tmp_scheduleMasterChangeSeqNum
          _entity.scheduleLocalChangeSeqNum = tmp_scheduleLocalChangeSeqNum
          _entity.scheduleLastChangedBy = tmp_scheduleLastChangedBy
          _entity.scheduleLastChangedTime = tmp_scheduleLastChangedTime
          _entity.scheduleActive = tmp_scheduleActive
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): Schedule? {
    var _result = null as com.ustadmobile.lib.db.entities.Schedule??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleUid = ?" ,
        postgreSql = """
    |SELECT * FROM Schedule WHERE scheduleUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_scheduleUid = _resultSet.getLong("scheduleUid")
          val tmp_sceduleStartTime = _resultSet.getLong("sceduleStartTime")
          val tmp_scheduleEndTime = _resultSet.getLong("scheduleEndTime")
          val tmp_scheduleDay = _resultSet.getInt("scheduleDay")
          val tmp_scheduleMonth = _resultSet.getInt("scheduleMonth")
          val tmp_scheduleFrequency = _resultSet.getInt("scheduleFrequency")
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          val tmp_scheduleClazzUid = _resultSet.getLong("scheduleClazzUid")
          val tmp_scheduleMasterChangeSeqNum = _resultSet.getLong("scheduleMasterChangeSeqNum")
          val tmp_scheduleLocalChangeSeqNum = _resultSet.getLong("scheduleLocalChangeSeqNum")
          val tmp_scheduleLastChangedBy = _resultSet.getInt("scheduleLastChangedBy")
          val tmp_scheduleLastChangedTime = _resultSet.getLong("scheduleLastChangedTime")
          val tmp_scheduleActive = _resultSet.getBoolean("scheduleActive")
          val _entity = Schedule()
          _entity.scheduleUid = tmp_scheduleUid
          _entity.sceduleStartTime = tmp_sceduleStartTime
          _entity.scheduleEndTime = tmp_scheduleEndTime
          _entity.scheduleDay = tmp_scheduleDay
          _entity.scheduleMonth = tmp_scheduleMonth
          _entity.scheduleFrequency = tmp_scheduleFrequency
          _entity.umCalendarUid = tmp_umCalendarUid
          _entity.scheduleClazzUid = tmp_scheduleClazzUid
          _entity.scheduleMasterChangeSeqNum = tmp_scheduleMasterChangeSeqNum
          _entity.scheduleLocalChangeSeqNum = tmp_scheduleLocalChangeSeqNum
          _entity.scheduleLastChangedBy = tmp_scheduleLastChangedBy
          _entity.scheduleLastChangedTime = tmp_scheduleLastChangedTime
          _entity.scheduleActive = tmp_scheduleActive
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findAllSchedulesByClazzUid(clazzUid: Long): DoorDataSourceFactory<Int,
      Schedule> {
    val _result = object : DoorDataSourceFactory<Int, Schedule>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<Schedule>> =
          DoorLiveDataImpl<List<Schedule>>(_db, listOf("Schedule"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.Schedule>()
        val _stmtConfig =
            PreparedStatementConfig("SELECT * FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 ) LIMIT ? OFFSET ? "
            , postgreSql = """
        |SELECT * FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_scheduleUid = _resultSet.getLong("scheduleUid")
              val tmp_sceduleStartTime = _resultSet.getLong("sceduleStartTime")
              val tmp_scheduleEndTime = _resultSet.getLong("scheduleEndTime")
              val tmp_scheduleDay = _resultSet.getInt("scheduleDay")
              val tmp_scheduleMonth = _resultSet.getInt("scheduleMonth")
              val tmp_scheduleFrequency = _resultSet.getInt("scheduleFrequency")
              val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
              val tmp_scheduleClazzUid = _resultSet.getLong("scheduleClazzUid")
              val tmp_scheduleMasterChangeSeqNum = _resultSet.getLong("scheduleMasterChangeSeqNum")
              val tmp_scheduleLocalChangeSeqNum = _resultSet.getLong("scheduleLocalChangeSeqNum")
              val tmp_scheduleLastChangedBy = _resultSet.getInt("scheduleLastChangedBy")
              val tmp_scheduleLastChangedTime = _resultSet.getLong("scheduleLastChangedTime")
              val tmp_scheduleActive = _resultSet.getBoolean("scheduleActive")
              val _entity = Schedule()
              _entity.scheduleUid = tmp_scheduleUid
              _entity.sceduleStartTime = tmp_sceduleStartTime
              _entity.scheduleEndTime = tmp_scheduleEndTime
              _entity.scheduleDay = tmp_scheduleDay
              _entity.scheduleMonth = tmp_scheduleMonth
              _entity.scheduleFrequency = tmp_scheduleFrequency
              _entity.umCalendarUid = tmp_umCalendarUid
              _entity.scheduleClazzUid = tmp_scheduleClazzUid
              _entity.scheduleMasterChangeSeqNum = tmp_scheduleMasterChangeSeqNum
              _entity.scheduleLocalChangeSeqNum = tmp_scheduleLocalChangeSeqNum
              _entity.scheduleLastChangedBy = tmp_scheduleLastChangedBy
              _entity.scheduleLastChangedTime = tmp_scheduleLastChangedTime
              _entity.scheduleActive = tmp_scheduleActive
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Schedule"))  {
        var _liveResult = 0
        val _stmtConfig =
            PreparedStatementConfig("SELECT COUNT(*) FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 ) "
            , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findAllSchedulesByClazzUidAsList(clazzUid: Long): List<Schedule> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Schedule>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1"
        , postgreSql = """
    |SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_scheduleUid = _resultSet.getLong("scheduleUid")
          val tmp_sceduleStartTime = _resultSet.getLong("sceduleStartTime")
          val tmp_scheduleEndTime = _resultSet.getLong("scheduleEndTime")
          val tmp_scheduleDay = _resultSet.getInt("scheduleDay")
          val tmp_scheduleMonth = _resultSet.getInt("scheduleMonth")
          val tmp_scheduleFrequency = _resultSet.getInt("scheduleFrequency")
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          val tmp_scheduleClazzUid = _resultSet.getLong("scheduleClazzUid")
          val tmp_scheduleMasterChangeSeqNum = _resultSet.getLong("scheduleMasterChangeSeqNum")
          val tmp_scheduleLocalChangeSeqNum = _resultSet.getLong("scheduleLocalChangeSeqNum")
          val tmp_scheduleLastChangedBy = _resultSet.getInt("scheduleLastChangedBy")
          val tmp_scheduleLastChangedTime = _resultSet.getLong("scheduleLastChangedTime")
          val tmp_scheduleActive = _resultSet.getBoolean("scheduleActive")
          val _entity = Schedule()
          _entity.scheduleUid = tmp_scheduleUid
          _entity.sceduleStartTime = tmp_sceduleStartTime
          _entity.scheduleEndTime = tmp_scheduleEndTime
          _entity.scheduleDay = tmp_scheduleDay
          _entity.scheduleMonth = tmp_scheduleMonth
          _entity.scheduleFrequency = tmp_scheduleFrequency
          _entity.umCalendarUid = tmp_umCalendarUid
          _entity.scheduleClazzUid = tmp_scheduleClazzUid
          _entity.scheduleMasterChangeSeqNum = tmp_scheduleMasterChangeSeqNum
          _entity.scheduleLocalChangeSeqNum = tmp_scheduleLocalChangeSeqNum
          _entity.scheduleLastChangedBy = tmp_scheduleLastChangedBy
          _entity.scheduleLastChangedTime = tmp_scheduleLastChangedTime
          _entity.scheduleActive = tmp_scheduleActive
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllSchedulesByClazzUidAsLiveList(clazzUid: Long):
      DoorLiveData<List<Schedule>> {
    val _result = DoorLiveDataImpl<List<Schedule>>(_db, listOf("Schedule"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.Schedule>()
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1"
          , postgreSql = """
      |SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, clazzUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_scheduleUid = _resultSet.getLong("scheduleUid")
            val tmp_sceduleStartTime = _resultSet.getLong("sceduleStartTime")
            val tmp_scheduleEndTime = _resultSet.getLong("scheduleEndTime")
            val tmp_scheduleDay = _resultSet.getInt("scheduleDay")
            val tmp_scheduleMonth = _resultSet.getInt("scheduleMonth")
            val tmp_scheduleFrequency = _resultSet.getInt("scheduleFrequency")
            val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
            val tmp_scheduleClazzUid = _resultSet.getLong("scheduleClazzUid")
            val tmp_scheduleMasterChangeSeqNum = _resultSet.getLong("scheduleMasterChangeSeqNum")
            val tmp_scheduleLocalChangeSeqNum = _resultSet.getLong("scheduleLocalChangeSeqNum")
            val tmp_scheduleLastChangedBy = _resultSet.getInt("scheduleLastChangedBy")
            val tmp_scheduleLastChangedTime = _resultSet.getLong("scheduleLastChangedTime")
            val tmp_scheduleActive = _resultSet.getBoolean("scheduleActive")
            val _entity = Schedule()
            _entity.scheduleUid = tmp_scheduleUid
            _entity.sceduleStartTime = tmp_sceduleStartTime
            _entity.scheduleEndTime = tmp_scheduleEndTime
            _entity.scheduleDay = tmp_scheduleDay
            _entity.scheduleMonth = tmp_scheduleMonth
            _entity.scheduleFrequency = tmp_scheduleFrequency
            _entity.umCalendarUid = tmp_umCalendarUid
            _entity.scheduleClazzUid = tmp_scheduleClazzUid
            _entity.scheduleMasterChangeSeqNum = tmp_scheduleMasterChangeSeqNum
            _entity.scheduleLocalChangeSeqNum = tmp_scheduleLocalChangeSeqNum
            _entity.scheduleLastChangedBy = tmp_scheduleLastChangedBy
            _entity.scheduleLastChangedTime = tmp_scheduleLastChangedTime
            _entity.scheduleActive = tmp_scheduleActive
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override suspend fun findAllSchedulesByClazzUidAsync(clazzUid: Long): List<Schedule> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Schedule>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 "
        , postgreSql = """
    |SELECT * FROM Schedule WHERE scheduleClazzUid = ? AND CAST(scheduleActive AS INTEGER) = 1 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_scheduleUid = _resultSet.getLong("scheduleUid")
          val tmp_sceduleStartTime = _resultSet.getLong("sceduleStartTime")
          val tmp_scheduleEndTime = _resultSet.getLong("scheduleEndTime")
          val tmp_scheduleDay = _resultSet.getInt("scheduleDay")
          val tmp_scheduleMonth = _resultSet.getInt("scheduleMonth")
          val tmp_scheduleFrequency = _resultSet.getInt("scheduleFrequency")
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          val tmp_scheduleClazzUid = _resultSet.getLong("scheduleClazzUid")
          val tmp_scheduleMasterChangeSeqNum = _resultSet.getLong("scheduleMasterChangeSeqNum")
          val tmp_scheduleLocalChangeSeqNum = _resultSet.getLong("scheduleLocalChangeSeqNum")
          val tmp_scheduleLastChangedBy = _resultSet.getInt("scheduleLastChangedBy")
          val tmp_scheduleLastChangedTime = _resultSet.getLong("scheduleLastChangedTime")
          val tmp_scheduleActive = _resultSet.getBoolean("scheduleActive")
          val _entity = Schedule()
          _entity.scheduleUid = tmp_scheduleUid
          _entity.sceduleStartTime = tmp_sceduleStartTime
          _entity.scheduleEndTime = tmp_scheduleEndTime
          _entity.scheduleDay = tmp_scheduleDay
          _entity.scheduleMonth = tmp_scheduleMonth
          _entity.scheduleFrequency = tmp_scheduleFrequency
          _entity.umCalendarUid = tmp_umCalendarUid
          _entity.scheduleClazzUid = tmp_scheduleClazzUid
          _entity.scheduleMasterChangeSeqNum = tmp_scheduleMasterChangeSeqNum
          _entity.scheduleLocalChangeSeqNum = tmp_scheduleLocalChangeSeqNum
          _entity.scheduleLastChangedBy = tmp_scheduleLastChangedBy
          _entity.scheduleLastChangedTime = tmp_scheduleLastChangedTime
          _entity.scheduleActive = tmp_scheduleActive
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun insertAsync(entity: Schedule): Long {
    val _retVal = _insertAdapterSchedule_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Schedule>): Unit {
    _insertAdapterSchedule_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Schedule>): Unit {
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.sceduleStartTime)
        _stmt.setLong(2, _entity.scheduleEndTime)
        _stmt.setInt(3, _entity.scheduleDay)
        _stmt.setInt(4, _entity.scheduleMonth)
        _stmt.setInt(5, _entity.scheduleFrequency)
        _stmt.setLong(6, _entity.umCalendarUid)
        _stmt.setLong(7, _entity.scheduleClazzUid)
        _stmt.setLong(8, _entity.scheduleMasterChangeSeqNum)
        _stmt.setLong(9, _entity.scheduleLocalChangeSeqNum)
        _stmt.setInt(10, _entity.scheduleLastChangedBy)
        _stmt.setLong(11, _entity.scheduleLastChangedTime)
        _stmt.setBoolean(12, _entity.scheduleActive)
        _stmt.setLong(13, _entity.scheduleUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Schedule): Unit {
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.sceduleStartTime)
      _stmt.setLong(2, entity.scheduleEndTime)
      _stmt.setInt(3, entity.scheduleDay)
      _stmt.setInt(4, entity.scheduleMonth)
      _stmt.setInt(5, entity.scheduleFrequency)
      _stmt.setLong(6, entity.umCalendarUid)
      _stmt.setLong(7, entity.scheduleClazzUid)
      _stmt.setLong(8, entity.scheduleMasterChangeSeqNum)
      _stmt.setLong(9, entity.scheduleLocalChangeSeqNum)
      _stmt.setInt(10, entity.scheduleLastChangedBy)
      _stmt.setLong(11, entity.scheduleLastChangedTime)
      _stmt.setBoolean(12, entity.scheduleActive)
      _stmt.setLong(13, entity.scheduleUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out Schedule>): Unit {
    _insertAdapterSchedule_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out Schedule>): Unit {
    val _sql =
        "UPDATE Schedule SET sceduleStartTime = ?, scheduleEndTime = ?, scheduleDay = ?, scheduleMonth = ?, scheduleFrequency = ?, umCalendarUid = ?, scheduleClazzUid = ?, scheduleMasterChangeSeqNum = ?, scheduleLocalChangeSeqNum = ?, scheduleLastChangedBy = ?, scheduleLastChangedTime = ?, scheduleActive = ? WHERE scheduleUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.sceduleStartTime)
        _stmt.setLong(2, _entity.scheduleEndTime)
        _stmt.setInt(3, _entity.scheduleDay)
        _stmt.setInt(4, _entity.scheduleMonth)
        _stmt.setInt(5, _entity.scheduleFrequency)
        _stmt.setLong(6, _entity.umCalendarUid)
        _stmt.setLong(7, _entity.scheduleClazzUid)
        _stmt.setLong(8, _entity.scheduleMasterChangeSeqNum)
        _stmt.setLong(9, _entity.scheduleLocalChangeSeqNum)
        _stmt.setInt(10, _entity.scheduleLastChangedBy)
        _stmt.setLong(11, _entity.scheduleLastChangedTime)
        _stmt.setBoolean(12, _entity.scheduleActive)
        _stmt.setLong(13, _entity.scheduleUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }
}
