package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonGroupMember
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class PersonGroupMemberDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: PersonGroupMemberDao
) : PersonGroupMemberDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _dao.replicateOnChangeClazzBased()
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeClazzBased(newNodeId)
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _dao.replicateOnChangeSchoolBased()
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeSchoolBased(newNodeId)
  }

  public override suspend fun findAllGroupWherePersonIsIn(personUid: Long): List<PersonGroupMember>
      = _dao.findAllGroupWherePersonIsIn(personUid)

  public override suspend fun checkPersonBelongsToGroup(groupUid: Long, personUid: Long):
      List<PersonGroupMember> = _dao.checkPersonBelongsToGroup(groupUid, personUid)

  public override suspend fun moveGroupAsync(
    personUid: Long,
    newGroup: Long,
    oldGroup: Long,
    changeTime: Long
  ): Int = _dao.moveGroupAsync(personUid, newGroup, oldGroup, changeTime)

  public override suspend fun updateGroupMemberActive(
    activeStatus: Boolean,
    personUid: Long,
    groupUid: Long,
    updateTime: Long
  ): Unit {
    _dao.updateGroupMemberActive(activeStatus, personUid, groupUid, updateTime)
  }

  public override suspend fun findByPersonUidAndGroupUid(personUid: Long, groupUid: Long):
      PersonGroupMember? = _dao.findByPersonUidAndGroupUid(personUid, groupUid)

  public override fun insert(entity: PersonGroupMember): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: PersonGroupMember): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.groupMemberUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(44)
      entity.groupMemberUid = _newPk
    }
    entity.groupMemberLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.groupMemberUid
  }

  public override fun insertList(entityList: List<out PersonGroupMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out PersonGroupMember>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: PersonGroupMember): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
