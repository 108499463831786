package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazz
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithClazzAndAttendance
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithLeavingReason
import com.ustadmobile.lib.db.entities.ClazzEnrolmentWithPerson
import com.ustadmobile.lib.db.entities.PersonWithClazzEnrolmentDetails
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ClazzEnrolmentDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: ClazzEnrolmentDao
) : ClazzEnrolmentDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateClazzEnrolmentOnChange(): Unit {
    _dao.replicateClazzEnrolmentOnChange()
  }

  public override fun insertListAsync(entityList: List<out ClazzEnrolment>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun findByPersonUidAndClazzUidAsync(personUid: Long, clazzUid: Long):
      ClazzEnrolment? = _dao.findByPersonUidAndClazzUidAsync(personUid, clazzUid)

  public override fun findAllEnrolmentsByPersonAndClazzUid(personUid: Long, clazzUid: Long):
      DoorDataSourceFactory<Int, ClazzEnrolmentWithLeavingReason> =
      _dao.findAllEnrolmentsByPersonAndClazzUid(personUid, clazzUid)

  public override suspend fun findEnrolmentWithLeavingReason(enrolmentUid: Long):
      ClazzEnrolmentWithLeavingReason? = _dao.findEnrolmentWithLeavingReason(enrolmentUid)

  public override suspend fun updateDateLeftByUid(
    clazzEnrolmentUid: Long,
    endDate: Long,
    updateTime: Long
  ): Unit {
    _dao.updateDateLeftByUid(clazzEnrolmentUid, endDate, updateTime)
  }

  public override suspend fun updateAsync(entity: ClazzEnrolment): Int {
    entity.clazzEnrolmentLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    return _dao.updateAsync(entity)
  }

  public override fun findAllClazzesByPersonWithClazz(personUid: Long): DoorDataSourceFactory<Int,
      ClazzEnrolmentWithClazzAndAttendance> = _dao.findAllClazzesByPersonWithClazz(personUid)

  public override suspend fun findMaxEndDateForEnrolment(
    selectedClazz: Long,
    selectedPerson: Long,
    selectedEnrolment: Long
  ): Long = _dao.findMaxEndDateForEnrolment(selectedClazz, selectedPerson, selectedEnrolment)

  public override suspend fun findAllClazzesByPersonWithClazzAsListAsync(personUid: Long):
      List<ClazzEnrolmentWithClazz> = _dao.findAllClazzesByPersonWithClazzAsListAsync(personUid)

  public override suspend fun getAllClazzEnrolledAtTimeAsync(
    clazzUid: Long,
    date: Long,
    roleFilter: Int,
    personUidFilter: Long
  ): List<ClazzEnrolmentWithPerson> = _dao.getAllClazzEnrolledAtTimeAsync(clazzUid, date,
      roleFilter, personUidFilter)

  public override suspend fun findByUid(uid: Long): ClazzEnrolment? = _dao.findByUid(uid)

  public override fun findByUidLive(uid: Long): DoorLiveData<ClazzEnrolment?> =
      _dao.findByUidLive(uid)

  public override suspend fun updateClazzEnrolmentActiveForPersonAndClazz(
    personUid: Long,
    clazzUid: Long,
    roleId: Int,
    active: Boolean,
    changeTime: Long
  ): Int = _dao.updateClazzEnrolmentActiveForPersonAndClazz(personUid, clazzUid, roleId, active,
      changeTime)

  public override fun findByClazzUidAndRole(
    clazzUid: Long,
    roleId: Int,
    sortOrder: Int,
    searchText: String?,
    filter: Int,
    accountPersonUid: Long,
    currentTime: Long
  ): DoorDataSourceFactory<Int, PersonWithClazzEnrolmentDetails> =
      _dao.findByClazzUidAndRole(clazzUid, roleId, sortOrder, searchText, filter, accountPersonUid,
      currentTime)

  public override fun updateClazzEnrolmentActiveForClazzEnrolment(
    clazzEnrolmentUid: Long,
    enrolled: Boolean,
    timeChanged: Long
  ): Int = throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun updateClazzEnrolmentRole(
    personUid: Long,
    clazzUid: Long,
    newRole: Int,
    oldRole: Int,
    updateTime: Long
  ): Int = _dao.updateClazzEnrolmentRole(personUid, clazzUid, newRole, oldRole, updateTime)

  public override fun insert(entity: ClazzEnrolment): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: ClazzEnrolment): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.clazzEnrolmentUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(65)
      entity.clazzEnrolmentUid = _newPk
    }
    entity.clazzEnrolmentLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.clazzEnrolmentUid
  }

  public override fun insertList(entityList: List<out ClazzEnrolment>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out ClazzEnrolment>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: ClazzEnrolment): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
