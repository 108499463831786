package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.Comments
import com.ustadmobile.lib.db.entities.CommentsWithPerson
import kotlin.Boolean
import kotlin.IllegalStateException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CommentsDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: CommentsDao
) : CommentsDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByUidAsync(uid: Long): Comments? = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun findPublicByEntityTypeAndUidLive(entityType: Int, entityUid: Long):
      DoorDataSourceFactory<Int, CommentsWithPerson> =
      _dao.findPublicByEntityTypeAndUidLive(entityType, entityUid)

  public override fun findPrivateByEntityTypeAndUidAndForPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long
  ): DoorDataSourceFactory<Int, CommentsWithPerson> =
      _dao.findPrivateByEntityTypeAndUidAndForPersonLive(entityType, entityUid, personUid)

  public override fun findPrivateByEntityTypeAndUidAndForPersonLive2(
    entityType: Int,
    entityUid: Long,
    submitterUid: Long
  ): DoorDataSourceFactory<Int, CommentsWithPerson> =
      _dao.findPrivateByEntityTypeAndUidAndForPersonLive2(entityType, entityUid, submitterUid)

  public override fun findPrivateByEntityTypeAndUidAndPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long
  ): DoorDataSourceFactory<Int, CommentsWithPerson> =
      _dao.findPrivateByEntityTypeAndUidAndPersonLive(entityType, entityUid, personUid)

  public override fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToLive(
    entityType: Int,
    entityUid: Long,
    personFrom: Long
  ): DoorDataSourceFactory<Int, CommentsWithPerson> =
      _dao.findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToLive(entityType, entityUid,
      personFrom)

  public override fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToTest(
    entityType: Int,
    entityUid: Long,
    personFrom: Long,
    personTo: Long
  ): List<CommentsWithPerson> = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun updateInActiveByCommentUid(
    uid: Long,
    inActive: Boolean,
    changeTime: Long
  ): Unit {
    _dao.updateInActiveByCommentUid(uid, inActive, changeTime)
  }

  public override fun insert(entity: Comments): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: Comments): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.commentsUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(208)
      entity.commentsUid = _newPk
    }
    entity.commentsLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.commentsUid
  }

  public override fun insertList(entityList: List<out Comments>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out Comments>): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: Comments): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertListAsync(entityList: List<out Comments>): Unit {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.commentsUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(208)
        it.commentsUid = _newPk
        _generatedPks += _newPk
      }
      it.commentsLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out Comments>): Unit {
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      it.commentsLct = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.updateListAsync(entityList)
  }
}
