package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.LearnerGroup
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class LearnerGroupDao_JdbcKt(
  public val _db: DoorDatabase
) : LearnerGroupDao() {
  public val _insertAdapterLearnerGroup_: EntityInsertionAdapter<LearnerGroup> = object :
      EntityInsertionAdapter<LearnerGroup>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO LearnerGroup (learnerGroupUid, learnerGroupName, learnerGroupDescription, learnerGroupActive, learnerGroupMCSN, learnerGroupCSN, learnerGroupLCB, learnerGroupLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO LearnerGroup (learnerGroupUid, learnerGroupName, learnerGroupDescription, learnerGroupActive, learnerGroupMCSN, learnerGroupCSN, learnerGroupLCB, learnerGroupLct) VALUES(COALESCE(?,nextval('LearnerGroup_learnerGroupUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING learnerGroupUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: LearnerGroup):
        Unit {
      if(entity.learnerGroupUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.learnerGroupUid)
      }
      stmt.setString(2, entity.learnerGroupName)
      stmt.setString(3, entity.learnerGroupDescription)
      stmt.setBoolean(4, entity.learnerGroupActive)
      stmt.setLong(5, entity.learnerGroupMCSN)
      stmt.setLong(6, entity.learnerGroupCSN)
      stmt.setInt(7, entity.learnerGroupLCB)
      stmt.setLong(8, entity.learnerGroupLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO LearnerGroupReplicate(lgPk, lgDestination)
    |      SELECT DISTINCT LearnerGroup.learnerGroupUid AS lgPk,
    |             ? AS lgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |             JOIN LearnerGroup
    |                  ON LearnerGroup.learnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid
    |            WHERE UserSession.usClientNodeId = ?
    |              AND UserSession.usStatus = 1
    |              AND LearnerGroup.learnerGroupLct != COALESCE(
    |                  (SELECT lgVersionId
    |                     FROM LearnerGroupReplicate
    |                    WHERE lgPk = LearnerGroup.learnerGroupUid
    |                      AND lgDestination = ?), 0) 
    |      /*psql ON CONFLICT(lgPk, lgDestination) DO UPDATE
    |             SET lgPending = true
    |      */       
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO LearnerGroupReplicate(lgPk, lgDestination)
    |      SELECT DISTINCT LearnerGroup.learnerGroupUid AS lgPk,
    |             ? AS lgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                  64
    |                  
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN LearnerGroupMember
    |                  ON LearnerGroupMember.learnerGroupMemberPersonUid = Person.personUid
    |             JOIN LearnerGroup
    |                  ON LearnerGroup.learnerGroupUid = LearnerGroupMember.learnerGroupMemberLgUid
    |            WHERE UserSession.usClientNodeId = ?
    |              AND UserSession.usStatus = 1
    |              AND LearnerGroup.learnerGroupLct != COALESCE(
    |                  (SELECT lgVersionId
    |                     FROM LearnerGroupReplicate
    |                    WHERE lgPk = LearnerGroup.learnerGroupUid
    |                      AND lgDestination = ?), 0) 
    |       ON CONFLICT(lgPk, lgDestination) DO UPDATE
    |             SET lgPending = true
    |             
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO LearnerGroupReplicate(lgPk, lgDestination)
    |  SELECT DISTINCT LearnerGroup.learnerGroupUid AS lgUid,
    |         UserSession.usClientNodeId AS lgDestination
    |    FROM ChangeLog
    |         JOIN LearnerGroup
    |              ON ChangeLog.chTableId = 301
    |                 AND ChangeLog.chEntityPk = LearnerGroup.learnerGroupUid
    |         JOIN LearnerGroupMember
    |              ON LearnerGroupMember.learnerGroupMemberLgUid = LearnerGroup.learnerGroupUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    | /*psql ON CONFLICT(lgPk, lgDestination) DO UPDATE
    |     SET lgPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LearnerGroupReplicate(lgPk, lgDestination)
    |  SELECT DISTINCT LearnerGroup.learnerGroupUid AS lgUid,
    |         UserSession.usClientNodeId AS lgDestination
    |    FROM ChangeLog
    |         JOIN LearnerGroup
    |              ON ChangeLog.chTableId = 301
    |                 AND ChangeLog.chEntityPk = LearnerGroup.learnerGroupUid
    |         JOIN LearnerGroupMember
    |              ON LearnerGroupMember.learnerGroupMemberLgUid = LearnerGroup.learnerGroupUid
    |         JOIN Person
    |              ON Person.personUid = LearnerGroupMember.learnerGroupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |  ON CONFLICT(lgPk, lgDestination) DO UPDATE
    |     SET lgPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findGroupsForEntryAsync(contentEntryUid: Long): DoorDataSourceFactory<Int,
      LearnerGroup> {
    val _result = object : DoorDataSourceFactory<Int, LearnerGroup>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<LearnerGroup>> =
          DoorLiveDataImpl<List<LearnerGroup>>(_db, listOf("LearnerGroup", "GroupLearningSession"))
           {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.LearnerGroup>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT LearnerGroup.* FROM LearnerGroup 
        |            LEFT JOIN GroupLearningSession ON 
        |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
        |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT LearnerGroup.* FROM LearnerGroup 
        |            LEFT JOIN GroupLearningSession ON 
        |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
        |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, contentEntryUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_learnerGroupUid = _resultSet.getLong("learnerGroupUid")
              val tmp_learnerGroupName = _resultSet.getString("learnerGroupName")
              val tmp_learnerGroupDescription = _resultSet.getString("learnerGroupDescription")
              val tmp_learnerGroupActive = _resultSet.getBoolean("learnerGroupActive")
              val tmp_learnerGroupMCSN = _resultSet.getLong("learnerGroupMCSN")
              val tmp_learnerGroupCSN = _resultSet.getLong("learnerGroupCSN")
              val tmp_learnerGroupLCB = _resultSet.getInt("learnerGroupLCB")
              val tmp_learnerGroupLct = _resultSet.getLong("learnerGroupLct")
              val _entity = LearnerGroup()
              _entity.learnerGroupUid = tmp_learnerGroupUid
              _entity.learnerGroupName = tmp_learnerGroupName
              _entity.learnerGroupDescription = tmp_learnerGroupDescription
              _entity.learnerGroupActive = tmp_learnerGroupActive
              _entity.learnerGroupMCSN = tmp_learnerGroupMCSN
              _entity.learnerGroupCSN = tmp_learnerGroupCSN
              _entity.learnerGroupLCB = tmp_learnerGroupLCB
              _entity.learnerGroupLct = tmp_learnerGroupLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("LearnerGroup", "GroupLearningSession"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT LearnerGroup.* FROM LearnerGroup 
        |            LEFT JOIN GroupLearningSession ON 
        |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
        |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT LearnerGroup.* FROM LearnerGroup 
        |            LEFT JOIN GroupLearningSession ON 
        |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
        |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, contentEntryUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findGroupListForEntry(contentEntryUid: Long): List<LearnerGroup> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.LearnerGroup>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT LearnerGroup.* FROM LearnerGroup 
    |            LEFT JOIN GroupLearningSession ON 
    |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
    |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?
    """.trimMargin() , postgreSql = """
    |SELECT LearnerGroup.* FROM LearnerGroup 
    |            LEFT JOIN GroupLearningSession ON 
    |            GroupLearningSession.groupLearningSessionLearnerGroupUid = LearnerGroup.learnerGroupUid 
    |            WHERE GroupLearningSession.groupLearningSessionContentUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_learnerGroupUid = _resultSet.getLong("learnerGroupUid")
          val tmp_learnerGroupName = _resultSet.getString("learnerGroupName")
          val tmp_learnerGroupDescription = _resultSet.getString("learnerGroupDescription")
          val tmp_learnerGroupActive = _resultSet.getBoolean("learnerGroupActive")
          val tmp_learnerGroupMCSN = _resultSet.getLong("learnerGroupMCSN")
          val tmp_learnerGroupCSN = _resultSet.getLong("learnerGroupCSN")
          val tmp_learnerGroupLCB = _resultSet.getInt("learnerGroupLCB")
          val tmp_learnerGroupLct = _resultSet.getLong("learnerGroupLct")
          val _entity = LearnerGroup()
          _entity.learnerGroupUid = tmp_learnerGroupUid
          _entity.learnerGroupName = tmp_learnerGroupName
          _entity.learnerGroupDescription = tmp_learnerGroupDescription
          _entity.learnerGroupActive = tmp_learnerGroupActive
          _entity.learnerGroupMCSN = tmp_learnerGroupMCSN
          _entity.learnerGroupCSN = tmp_learnerGroupCSN
          _entity.learnerGroupLCB = tmp_learnerGroupLCB
          _entity.learnerGroupLct = tmp_learnerGroupLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun insert(entity: LearnerGroup): Long {
    val _retVal = _insertAdapterLearnerGroup_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: LearnerGroup): Long {
    val _retVal = _insertAdapterLearnerGroup_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out LearnerGroup>): Unit {
    _insertAdapterLearnerGroup_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out LearnerGroup>): Unit {
    val _sql =
        "UPDATE LearnerGroup SET learnerGroupName = ?, learnerGroupDescription = ?, learnerGroupActive = ?, learnerGroupMCSN = ?, learnerGroupCSN = ?, learnerGroupLCB = ?, learnerGroupLct = ? WHERE learnerGroupUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.learnerGroupName)
        _stmt.setString(2, _entity.learnerGroupDescription)
        _stmt.setBoolean(3, _entity.learnerGroupActive)
        _stmt.setLong(4, _entity.learnerGroupMCSN)
        _stmt.setLong(5, _entity.learnerGroupCSN)
        _stmt.setInt(6, _entity.learnerGroupLCB)
        _stmt.setLong(7, _entity.learnerGroupLct)
        _stmt.setLong(8, _entity.learnerGroupUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: LearnerGroup): Unit {
    val _sql =
        "UPDATE LearnerGroup SET learnerGroupName = ?, learnerGroupDescription = ?, learnerGroupActive = ?, learnerGroupMCSN = ?, learnerGroupCSN = ?, learnerGroupLCB = ?, learnerGroupLct = ? WHERE learnerGroupUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.learnerGroupName)
      _stmt.setString(2, entity.learnerGroupDescription)
      _stmt.setBoolean(3, entity.learnerGroupActive)
      _stmt.setLong(4, entity.learnerGroupMCSN)
      _stmt.setLong(5, entity.learnerGroupCSN)
      _stmt.setInt(6, entity.learnerGroupLCB)
      _stmt.setLong(7, entity.learnerGroupLct)
      _stmt.setLong(8, entity.learnerGroupUid)
      _stmt.executeUpdate()
    }
  }
}
