package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.lib.db.entities.ScrapeRun
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ScrapeRunDao_JdbcKt(
  public val _db: DoorDatabase
) : ScrapeRunDao() {
  public val _insertAdapterScrapeRun_: EntityInsertionAdapter<ScrapeRun> = object :
      EntityInsertionAdapter<ScrapeRun>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ScrapeRun (scrapeRunUid, scrapeType, scrapeRunStatus, conversionParams) VALUES(?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ScrapeRun (scrapeRunUid, scrapeType, scrapeRunStatus, conversionParams) VALUES(COALESCE(?,nextval('ScrapeRun_scrapeRunUid_seq')), ?, ?, ?)" + if(returnsId) { " RETURNING scrapeRunUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ScrapeRun): Unit {
      if(entity.scrapeRunUid == 0) {
        stmt.setObject(1, null)
      } else {
        stmt.setInt(1, entity.scrapeRunUid)
      }
      stmt.setString(2, entity.scrapeType)
      stmt.setInt(3, entity.scrapeRunStatus)
      stmt.setString(4, entity.conversionParams)
    }
  }

  public override fun insert(entity: ScrapeRun): Long {
    val _retVal = _insertAdapterScrapeRun_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ScrapeRun): Long {
    val _retVal = _insertAdapterScrapeRun_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ScrapeRun>): Unit {
    _insertAdapterScrapeRun_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ScrapeRun>): Unit {
    val _sql =
        "UPDATE ScrapeRun SET scrapeType = ?, scrapeRunStatus = ?, conversionParams = ? WHERE scrapeRunUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.scrapeType)
        _stmt.setInt(2, _entity.scrapeRunStatus)
        _stmt.setString(3, _entity.conversionParams)
        _stmt.setInt(4, _entity.scrapeRunUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ScrapeRun): Unit {
    val _sql =
        "UPDATE ScrapeRun SET scrapeType = ?, scrapeRunStatus = ?, conversionParams = ? WHERE scrapeRunUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.scrapeType)
      _stmt.setInt(2, entity.scrapeRunStatus)
      _stmt.setString(3, entity.conversionParams)
      _stmt.setInt(4, entity.scrapeRunUid)
      _stmt.executeUpdate()
    }
  }
}
