package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CustomField
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CustomFieldDao_JdbcKt(
  public val _db: DoorDatabase
) : CustomFieldDao() {
  public val _insertAdapterCustomField_: EntityInsertionAdapter<CustomField> = object :
      EntityInsertionAdapter<CustomField>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CustomField (customFieldUid, customFieldName, customFieldNameAlt, customFieldLabelMessageID, customFieldIcon, customFieldIconId, actionOnClick, customFieldType, customFieldEntityType, customFieldActive, customFieldDefaultValue, customFieldMCSN, customFieldLCSN, customFieldLCB, customFieldLct, customFieldInputType) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CustomField (customFieldUid, customFieldName, customFieldNameAlt, customFieldLabelMessageID, customFieldIcon, customFieldIconId, actionOnClick, customFieldType, customFieldEntityType, customFieldActive, customFieldDefaultValue, customFieldMCSN, customFieldLCSN, customFieldLCB, customFieldLct, customFieldInputType) VALUES(COALESCE(?,nextval('CustomField_customFieldUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING customFieldUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CustomField):
        Unit {
      if(entity.customFieldUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.customFieldUid)
      }
      stmt.setString(2, entity.customFieldName)
      stmt.setString(3, entity.customFieldNameAlt)
      stmt.setInt(4, entity.customFieldLabelMessageID)
      stmt.setString(5, entity.customFieldIcon)
      stmt.setInt(6, entity.customFieldIconId)
      stmt.setString(7, entity.actionOnClick)
      stmt.setInt(8, entity.customFieldType)
      stmt.setInt(9, entity.customFieldEntityType)
      stmt.setBoolean(10, entity.customFieldActive)
      stmt.setString(11, entity.customFieldDefaultValue)
      stmt.setLong(12, entity.customFieldMCSN)
      stmt.setLong(13, entity.customFieldLCSN)
      stmt.setInt(14, entity.customFieldLCB)
      stmt.setLong(15, entity.customFieldLct)
      stmt.setInt(16, entity.customFieldInputType)
    }
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<CustomField?> {
    val _result = DoorLiveDataImpl<CustomField?>(_db, listOf("CustomField"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.CustomField??
      val _stmtConfig = PreparedStatementConfig("SELECT * FROM CustomField WHERE customFieldUid = ?"
          , postgreSql = """
      |SELECT * FROM CustomField WHERE customFieldUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_customFieldUid = _resultSet.getLong("customFieldUid")
            val tmp_customFieldName = _resultSet.getString("customFieldName")
            val tmp_customFieldNameAlt = _resultSet.getString("customFieldNameAlt")
            val tmp_customFieldLabelMessageID = _resultSet.getInt("customFieldLabelMessageID")
            val tmp_customFieldIcon = _resultSet.getString("customFieldIcon")
            val tmp_customFieldIconId = _resultSet.getInt("customFieldIconId")
            val tmp_actionOnClick = _resultSet.getString("actionOnClick")
            val tmp_customFieldType = _resultSet.getInt("customFieldType")
            val tmp_customFieldEntityType = _resultSet.getInt("customFieldEntityType")
            val tmp_customFieldActive = _resultSet.getBoolean("customFieldActive")
            val tmp_customFieldDefaultValue = _resultSet.getString("customFieldDefaultValue")
            val tmp_customFieldMCSN = _resultSet.getLong("customFieldMCSN")
            val tmp_customFieldLCSN = _resultSet.getLong("customFieldLCSN")
            val tmp_customFieldLCB = _resultSet.getInt("customFieldLCB")
            val tmp_customFieldLct = _resultSet.getLong("customFieldLct")
            val tmp_customFieldInputType = _resultSet.getInt("customFieldInputType")
            val _entity = CustomField()
            _entity.customFieldUid = tmp_customFieldUid
            _entity.customFieldName = tmp_customFieldName
            _entity.customFieldNameAlt = tmp_customFieldNameAlt
            _entity.customFieldLabelMessageID = tmp_customFieldLabelMessageID
            _entity.customFieldIcon = tmp_customFieldIcon
            _entity.customFieldIconId = tmp_customFieldIconId
            _entity.actionOnClick = tmp_actionOnClick
            _entity.customFieldType = tmp_customFieldType
            _entity.customFieldEntityType = tmp_customFieldEntityType
            _entity.customFieldActive = tmp_customFieldActive
            _entity.customFieldDefaultValue = tmp_customFieldDefaultValue
            _entity.customFieldMCSN = tmp_customFieldMCSN
            _entity.customFieldLCSN = tmp_customFieldLCSN
            _entity.customFieldLCB = tmp_customFieldLCB
            _entity.customFieldLct = tmp_customFieldLct
            _entity.customFieldInputType = tmp_customFieldInputType
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): CustomField? {
    var _result = null as com.ustadmobile.lib.db.entities.CustomField??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM CustomField WHERE customFieldUid = ?" ,
        postgreSql = """
    |SELECT * FROM CustomField WHERE customFieldUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_customFieldUid = _resultSet.getLong("customFieldUid")
          val tmp_customFieldName = _resultSet.getString("customFieldName")
          val tmp_customFieldNameAlt = _resultSet.getString("customFieldNameAlt")
          val tmp_customFieldLabelMessageID = _resultSet.getInt("customFieldLabelMessageID")
          val tmp_customFieldIcon = _resultSet.getString("customFieldIcon")
          val tmp_customFieldIconId = _resultSet.getInt("customFieldIconId")
          val tmp_actionOnClick = _resultSet.getString("actionOnClick")
          val tmp_customFieldType = _resultSet.getInt("customFieldType")
          val tmp_customFieldEntityType = _resultSet.getInt("customFieldEntityType")
          val tmp_customFieldActive = _resultSet.getBoolean("customFieldActive")
          val tmp_customFieldDefaultValue = _resultSet.getString("customFieldDefaultValue")
          val tmp_customFieldMCSN = _resultSet.getLong("customFieldMCSN")
          val tmp_customFieldLCSN = _resultSet.getLong("customFieldLCSN")
          val tmp_customFieldLCB = _resultSet.getInt("customFieldLCB")
          val tmp_customFieldLct = _resultSet.getLong("customFieldLct")
          val tmp_customFieldInputType = _resultSet.getInt("customFieldInputType")
          val _entity = CustomField()
          _entity.customFieldUid = tmp_customFieldUid
          _entity.customFieldName = tmp_customFieldName
          _entity.customFieldNameAlt = tmp_customFieldNameAlt
          _entity.customFieldLabelMessageID = tmp_customFieldLabelMessageID
          _entity.customFieldIcon = tmp_customFieldIcon
          _entity.customFieldIconId = tmp_customFieldIconId
          _entity.actionOnClick = tmp_actionOnClick
          _entity.customFieldType = tmp_customFieldType
          _entity.customFieldEntityType = tmp_customFieldEntityType
          _entity.customFieldActive = tmp_customFieldActive
          _entity.customFieldDefaultValue = tmp_customFieldDefaultValue
          _entity.customFieldMCSN = tmp_customFieldMCSN
          _entity.customFieldLCSN = tmp_customFieldLCSN
          _entity.customFieldLCB = tmp_customFieldLCB
          _entity.customFieldLct = tmp_customFieldLct
          _entity.customFieldInputType = tmp_customFieldInputType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: CustomField): Int {
    var _result = 0
    val _sql =
        "UPDATE CustomField SET customFieldName = ?, customFieldNameAlt = ?, customFieldLabelMessageID = ?, customFieldIcon = ?, customFieldIconId = ?, actionOnClick = ?, customFieldType = ?, customFieldEntityType = ?, customFieldActive = ?, customFieldDefaultValue = ?, customFieldMCSN = ?, customFieldLCSN = ?, customFieldLCB = ?, customFieldLct = ?, customFieldInputType = ? WHERE customFieldUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.customFieldName)
      _stmt.setString(2, entity.customFieldNameAlt)
      _stmt.setInt(3, entity.customFieldLabelMessageID)
      _stmt.setString(4, entity.customFieldIcon)
      _stmt.setInt(5, entity.customFieldIconId)
      _stmt.setString(6, entity.actionOnClick)
      _stmt.setInt(7, entity.customFieldType)
      _stmt.setInt(8, entity.customFieldEntityType)
      _stmt.setBoolean(9, entity.customFieldActive)
      _stmt.setString(10, entity.customFieldDefaultValue)
      _stmt.setLong(11, entity.customFieldMCSN)
      _stmt.setLong(12, entity.customFieldLCSN)
      _stmt.setInt(13, entity.customFieldLCB)
      _stmt.setLong(14, entity.customFieldLct)
      _stmt.setInt(15, entity.customFieldInputType)
      _stmt.setLong(16, entity.customFieldUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun insert(entity: CustomField): Long {
    val _retVal = _insertAdapterCustomField_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CustomField): Long {
    val _retVal = _insertAdapterCustomField_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CustomField>): Unit {
    _insertAdapterCustomField_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CustomField>): Unit {
    val _sql =
        "UPDATE CustomField SET customFieldName = ?, customFieldNameAlt = ?, customFieldLabelMessageID = ?, customFieldIcon = ?, customFieldIconId = ?, actionOnClick = ?, customFieldType = ?, customFieldEntityType = ?, customFieldActive = ?, customFieldDefaultValue = ?, customFieldMCSN = ?, customFieldLCSN = ?, customFieldLCB = ?, customFieldLct = ?, customFieldInputType = ? WHERE customFieldUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.customFieldName)
        _stmt.setString(2, _entity.customFieldNameAlt)
        _stmt.setInt(3, _entity.customFieldLabelMessageID)
        _stmt.setString(4, _entity.customFieldIcon)
        _stmt.setInt(5, _entity.customFieldIconId)
        _stmt.setString(6, _entity.actionOnClick)
        _stmt.setInt(7, _entity.customFieldType)
        _stmt.setInt(8, _entity.customFieldEntityType)
        _stmt.setBoolean(9, _entity.customFieldActive)
        _stmt.setString(10, _entity.customFieldDefaultValue)
        _stmt.setLong(11, _entity.customFieldMCSN)
        _stmt.setLong(12, _entity.customFieldLCSN)
        _stmt.setInt(13, _entity.customFieldLCB)
        _stmt.setLong(14, _entity.customFieldLct)
        _stmt.setInt(15, _entity.customFieldInputType)
        _stmt.setLong(16, _entity.customFieldUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CustomField): Unit {
    val _sql =
        "UPDATE CustomField SET customFieldName = ?, customFieldNameAlt = ?, customFieldLabelMessageID = ?, customFieldIcon = ?, customFieldIconId = ?, actionOnClick = ?, customFieldType = ?, customFieldEntityType = ?, customFieldActive = ?, customFieldDefaultValue = ?, customFieldMCSN = ?, customFieldLCSN = ?, customFieldLCB = ?, customFieldLct = ?, customFieldInputType = ? WHERE customFieldUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.customFieldName)
      _stmt.setString(2, entity.customFieldNameAlt)
      _stmt.setInt(3, entity.customFieldLabelMessageID)
      _stmt.setString(4, entity.customFieldIcon)
      _stmt.setInt(5, entity.customFieldIconId)
      _stmt.setString(6, entity.actionOnClick)
      _stmt.setInt(7, entity.customFieldType)
      _stmt.setInt(8, entity.customFieldEntityType)
      _stmt.setBoolean(9, entity.customFieldActive)
      _stmt.setString(10, entity.customFieldDefaultValue)
      _stmt.setLong(11, entity.customFieldMCSN)
      _stmt.setLong(12, entity.customFieldLCSN)
      _stmt.setInt(13, entity.customFieldLCB)
      _stmt.setLong(14, entity.customFieldLct)
      _stmt.setInt(15, entity.customFieldInputType)
      _stmt.setLong(16, entity.customFieldUid)
      _stmt.executeUpdate()
    }
  }
}
