package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContainerEntry
import com.ustadmobile.lib.db.entities.ContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryWithContainerEntryFile
import com.ustadmobile.lib.db.entities.ContainerEntryWithMd5
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContainerEntryDao_JdbcKt(
  public val _db: DoorDatabase
) : ContainerEntryDao() {
  public val _insertAdapterContainerEntry_: EntityInsertionAdapter<ContainerEntry> = object :
      EntityInsertionAdapter<ContainerEntry>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContainerEntry (ceUid, ceContainerUid, cePath, ceCefUid) VALUES(?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContainerEntry (ceUid, ceContainerUid, cePath, ceCefUid) VALUES(COALESCE(?,nextval('ContainerEntry_ceUid_seq')), ?, ?, ?)" + if(returnsId) { " RETURNING ceUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContainerEntry):
        Unit {
      if(entity.ceUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ceUid)
      }
      stmt.setLong(2, entity.ceContainerUid)
      stmt.setString(3, entity.cePath)
      stmt.setLong(4, entity.ceCefUid)
    }
  }

  public override suspend fun insertListAsync(containerEntryList: List<out ContainerEntry>): Unit {
    _insertAdapterContainerEntry_.insertListAsync(containerEntryList)
  }

  public override fun findByContainer(containerUid: Long):
      List<ContainerEntryWithContainerEntryFile> {
    var _result =
        mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryWithContainerEntryFile>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?"
        , postgreSql = """
    |SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_ceUid = _resultSet.getLong("ceUid")
          val tmp_ceContainerUid = _resultSet.getLong("ceContainerUid")
          val tmp_cePath = _resultSet.getString("cePath")
          val tmp_ceCefUid = _resultSet.getLong("ceCefUid")
          val _entity = ContainerEntryWithContainerEntryFile()
          _entity.ceUid = tmp_ceUid
          _entity.ceContainerUid = tmp_ceContainerUid
          _entity.cePath = tmp_cePath
          _entity.ceCefUid = tmp_ceCefUid
          var _containerEntryFile_nullFieldCount = 0
          val tmp_cefUid = _resultSet.getLong("cefUid")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_cefPath = _resultSet.getString("cefPath")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_compression = _resultSet.getInt("compression")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_lastModified = _resultSet.getLong("lastModified")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          if(_containerEntryFile_nullFieldCount < 7) {
            if(_entity.containerEntryFile == null) {
              _entity.containerEntryFile = ContainerEntryFile()
            }
            _entity.containerEntryFile!!.cefUid = tmp_cefUid
            _entity.containerEntryFile!!.cefMd5 = tmp_cefMd5
            _entity.containerEntryFile!!.cefPath = tmp_cefPath
            _entity.containerEntryFile!!.ceTotalSize = tmp_ceTotalSize
            _entity.containerEntryFile!!.ceCompressedSize = tmp_ceCompressedSize
            _entity.containerEntryFile!!.compression = tmp_compression
            _entity.containerEntryFile!!.lastModified = tmp_lastModified
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByPathInContainer(containerUid: Long, pathInContainer: String):
      ContainerEntryWithContainerEntryFile? {
    var _result = null as com.ustadmobile.lib.db.entities.ContainerEntryWithContainerEntryFile??
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ? AND ContainerEntry.cePath = ?"
        , postgreSql = """
    |SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ? AND ContainerEntry.cePath = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.setString(2, pathInContainer)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_ceUid = _resultSet.getLong("ceUid")
          val tmp_ceContainerUid = _resultSet.getLong("ceContainerUid")
          val tmp_cePath = _resultSet.getString("cePath")
          val tmp_ceCefUid = _resultSet.getLong("ceCefUid")
          val _entity = ContainerEntryWithContainerEntryFile()
          _entity.ceUid = tmp_ceUid
          _entity.ceContainerUid = tmp_ceContainerUid
          _entity.cePath = tmp_cePath
          _entity.ceCefUid = tmp_ceCefUid
          var _containerEntryFile_nullFieldCount = 0
          val tmp_cefUid = _resultSet.getLong("cefUid")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_cefPath = _resultSet.getString("cefPath")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_compression = _resultSet.getInt("compression")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_lastModified = _resultSet.getLong("lastModified")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          if(_containerEntryFile_nullFieldCount < 7) {
            if(_entity.containerEntryFile == null) {
              _entity.containerEntryFile = ContainerEntryFile()
            }
            _entity.containerEntryFile!!.cefUid = tmp_cefUid
            _entity.containerEntryFile!!.cefMd5 = tmp_cefMd5
            _entity.containerEntryFile!!.cefPath = tmp_cefPath
            _entity.containerEntryFile!!.ceTotalSize = tmp_ceTotalSize
            _entity.containerEntryFile!!.ceCompressedSize = tmp_ceCompressedSize
            _entity.containerEntryFile!!.compression = tmp_compression
            _entity.containerEntryFile!!.lastModified = tmp_lastModified
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByContainerWithMd5(containerUid: Long): List<ContainerEntryWithMd5> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryWithMd5>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntry.*, ContainerEntryFile.cefMd5 AS cefMd5 FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?"
        , postgreSql = """
    |SELECT ContainerEntry.*, ContainerEntryFile.cefMd5 AS cefMd5 FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          val tmp_ceUid = _resultSet.getLong("ceUid")
          val tmp_ceContainerUid = _resultSet.getLong("ceContainerUid")
          val tmp_cePath = _resultSet.getString("cePath")
          val tmp_ceCefUid = _resultSet.getLong("ceCefUid")
          val _entity = ContainerEntryWithMd5()
          _entity.cefMd5 = tmp_cefMd5
          _entity.ceUid = tmp_ceUid
          _entity.ceContainerUid = tmp_ceContainerUid
          _entity.cePath = tmp_cePath
          _entity.ceCefUid = tmp_ceCefUid
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByContainerAsync(containerUid: Long):
      List<ContainerEntryWithContainerEntryFile> {
    var _result =
        mutableListOf<com.ustadmobile.lib.db.entities.ContainerEntryWithContainerEntryFile>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?"
        , postgreSql = """
    |SELECT ContainerEntry.*, ContainerEntryFile.* FROM ContainerEntry LEFT JOIN ContainerEntryFile ON ContainerEntry.ceCefUid = ContainerEntryFile.cefUid WHERE ContainerEntry.ceContainerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_ceUid = _resultSet.getLong("ceUid")
          val tmp_ceContainerUid = _resultSet.getLong("ceContainerUid")
          val tmp_cePath = _resultSet.getString("cePath")
          val tmp_ceCefUid = _resultSet.getLong("ceCefUid")
          val _entity = ContainerEntryWithContainerEntryFile()
          _entity.ceUid = tmp_ceUid
          _entity.ceContainerUid = tmp_ceContainerUid
          _entity.cePath = tmp_cePath
          _entity.ceCefUid = tmp_ceCefUid
          var _containerEntryFile_nullFieldCount = 0
          val tmp_cefUid = _resultSet.getLong("cefUid")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_cefMd5 = _resultSet.getString("cefMd5")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_cefPath = _resultSet.getString("cefPath")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_ceTotalSize = _resultSet.getLong("ceTotalSize")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_ceCompressedSize = _resultSet.getLong("ceCompressedSize")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_compression = _resultSet.getInt("compression")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          val tmp_lastModified = _resultSet.getLong("lastModified")
          if(_resultSet.wasNull()) { _containerEntryFile_nullFieldCount++ }
          if(_containerEntryFile_nullFieldCount < 7) {
            if(_entity.containerEntryFile == null) {
              _entity.containerEntryFile = ContainerEntryFile()
            }
            _entity.containerEntryFile!!.cefUid = tmp_cefUid
            _entity.containerEntryFile!!.cefMd5 = tmp_cefMd5
            _entity.containerEntryFile!!.cefPath = tmp_cefPath
            _entity.containerEntryFile!!.ceTotalSize = tmp_ceTotalSize
            _entity.containerEntryFile!!.ceCompressedSize = tmp_ceCompressedSize
            _entity.containerEntryFile!!.compression = tmp_compression
            _entity.containerEntryFile!!.lastModified = tmp_lastModified
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun deleteByContainerUid(containerUid: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("DELETE FROM ContainerEntry WHERE ceContainerUid = ?"
        , postgreSql = """
    |DELETE FROM ContainerEntry WHERE ceContainerUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun deleteList(entries: List<out ContainerEntry>): Unit {
    var _numChanges = 0
    _db.prepareAndUseStatement("DELETE FROM ContainerEntry WHERE ceUid = ?") {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entries) {
        _stmt.setLong(1, _entity.ceUid)
        _numChanges += _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun deleteContainerEntriesCreatedByJobs(jobId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |            DELETE FROM ContainerEntry 
    |             WHERE ceContainerUid 
    |                IN (SELECT cjiContainerUid 
    |                      FROM ContentJobItem
    |                      JOIN ContentJob 
    |                           ON ContentJobItem.cjiJobUid = ContentJob.cjUid
    |                     WHERE ContentJob.cjUid = ?)
    """.trimMargin() , postgreSql = """
    |
    |            DELETE FROM ContainerEntry 
    |             WHERE ceContainerUid 
    |                IN (SELECT cjiContainerUid 
    |                      FROM ContentJobItem
    |                      JOIN ContentJob 
    |                           ON ContentJobItem.cjiJobUid = ContentJob.cjUid
    |                     WHERE ContentJob.cjUid = ?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, jobId)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun deleteByContentEntryUid(contentEntryUid: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        DELETE FROM ContainerEntry
    |         WHERE ceContainerUid
    |            IN (SELECT containerUid
    |                  FROM Container
    |                 WHERE containerContentEntryUid = ?) 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        DELETE FROM ContainerEntry
    |         WHERE ceContainerUid
    |            IN (SELECT containerUid
    |                  FROM Container
    |                 WHERE containerContentEntryUid = ?) 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override suspend fun insertWithMd5SumsAsync(
    containerUid: Long,
    path: String,
    md5: String
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        INSERT INTO ContainerEntry(ceContainerUid, cePath, ceCefUid) 
    |        SELECT ? AS ceContainerUid, ? AS cePath, 
    |               (SELECT COALESCE(
    |                      (SELECT cefUid 
    |                         FROM ContainerEntryFile
    |                        WHERE cefMd5 = ?
    |                        LIMIT 1), 0))  
    |    
    """.trimMargin() , postgreSql = """
    |
    |        INSERT INTO ContainerEntry(ceContainerUid, cePath, ceCefUid) 
    |        SELECT ? AS ceContainerUid, ? AS cePath, 
    |               (SELECT COALESCE(
    |                      (SELECT cefUid 
    |                         FROM ContainerEntryFile
    |                        WHERE cefMd5 = ?
    |                        LIMIT 1), 0))  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.setString(2, path)
      _stmt.setString(3, md5)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: ContainerEntry): Long {
    val _retVal = _insertAdapterContainerEntry_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerEntry): Long {
    val _retVal = _insertAdapterContainerEntry_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContainerEntry>): Unit {
    _insertAdapterContainerEntry_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContainerEntry>): Unit {
    val _sql =
        "UPDATE ContainerEntry SET ceContainerUid = ?, cePath = ?, ceCefUid = ? WHERE ceUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.ceContainerUid)
        _stmt.setString(2, _entity.cePath)
        _stmt.setLong(3, _entity.ceCefUid)
        _stmt.setLong(4, _entity.ceUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContainerEntry): Unit {
    val _sql =
        "UPDATE ContainerEntry SET ceContainerUid = ?, cePath = ?, ceCefUid = ? WHERE ceUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.ceContainerUid)
      _stmt.setString(2, entity.cePath)
      _stmt.setLong(3, entity.ceCefUid)
      _stmt.setLong(4, entity.ceUid)
      _stmt.executeUpdate()
    }
  }
}
