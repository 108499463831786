package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContainerImportJob
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContainerImportJobDao_JdbcKt(
  public val _db: DoorDatabase
) : ContainerImportJobDao() {
  public val _insertAdapterContainerImportJob_: EntityInsertionAdapter<ContainerImportJob> = object
      : EntityInsertionAdapter<ContainerImportJob>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContainerImportJob (cijUid, cijContainerUid, cijUri, cijImportMode, cijContainerBaseDir, cijContentEntryUid, cijMimeType, cijSessionId, cijJobStatus, cijBytesSoFar, cijImportCompleted, cijContentLength, cijContainerEntryFileUids, cijConversionParams) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContainerImportJob (cijUid, cijContainerUid, cijUri, cijImportMode, cijContainerBaseDir, cijContentEntryUid, cijMimeType, cijSessionId, cijJobStatus, cijBytesSoFar, cijImportCompleted, cijContentLength, cijContainerEntryFileUids, cijConversionParams) VALUES(COALESCE(?,nextval('ContainerImportJob_cijUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cijUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContainerImportJob): Unit {
      if(entity.cijUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cijUid)
      }
      stmt.setLong(2, entity.cijContainerUid)
      stmt.setString(3, entity.cijUri)
      stmt.setInt(4, entity.cijImportMode)
      stmt.setString(5, entity.cijContainerBaseDir)
      stmt.setLong(6, entity.cijContentEntryUid)
      stmt.setString(7, entity.cijMimeType)
      stmt.setString(8, entity.cijSessionId)
      stmt.setInt(9, entity.cijJobStatus)
      stmt.setLong(10, entity.cijBytesSoFar)
      stmt.setBoolean(11, entity.cijImportCompleted)
      stmt.setLong(12, entity.cijContentLength)
      stmt.setString(13, entity.cijContainerEntryFileUids)
      stmt.setString(14, entity.cijConversionParams)
    }
  }

  public override fun findBySessionId(sessionId: String): ContainerImportJob? {
    var _result = null as com.ustadmobile.lib.db.entities.ContainerImportJob??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContainerImportJob WHERE cijSessionId = ?" ,
        postgreSql = """
    |SELECT * FROM ContainerImportJob WHERE cijSessionId = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, sessionId)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cijUid = _resultSet.getLong("cijUid")
          val tmp_cijContainerUid = _resultSet.getLong("cijContainerUid")
          val tmp_cijUri = _resultSet.getString("cijUri")
          val tmp_cijImportMode = _resultSet.getInt("cijImportMode")
          val tmp_cijContainerBaseDir = _resultSet.getString("cijContainerBaseDir")
          val tmp_cijContentEntryUid = _resultSet.getLong("cijContentEntryUid")
          val tmp_cijMimeType = _resultSet.getString("cijMimeType")
          val tmp_cijSessionId = _resultSet.getString("cijSessionId")
          val tmp_cijJobStatus = _resultSet.getInt("cijJobStatus")
          val tmp_cijBytesSoFar = _resultSet.getLong("cijBytesSoFar")
          val tmp_cijImportCompleted = _resultSet.getBoolean("cijImportCompleted")
          val tmp_cijContentLength = _resultSet.getLong("cijContentLength")
          val tmp_cijContainerEntryFileUids = _resultSet.getString("cijContainerEntryFileUids")
          val tmp_cijConversionParams = _resultSet.getString("cijConversionParams")
          val _entity = ContainerImportJob()
          _entity.cijUid = tmp_cijUid
          _entity.cijContainerUid = tmp_cijContainerUid
          _entity.cijUri = tmp_cijUri
          _entity.cijImportMode = tmp_cijImportMode
          _entity.cijContainerBaseDir = tmp_cijContainerBaseDir
          _entity.cijContentEntryUid = tmp_cijContentEntryUid
          _entity.cijMimeType = tmp_cijMimeType
          _entity.cijSessionId = tmp_cijSessionId
          _entity.cijJobStatus = tmp_cijJobStatus
          _entity.cijBytesSoFar = tmp_cijBytesSoFar
          _entity.cijImportCompleted = tmp_cijImportCompleted
          _entity.cijContentLength = tmp_cijContentLength
          _entity.cijContainerEntryFileUids = tmp_cijContainerEntryFileUids
          _entity.cijConversionParams = tmp_cijConversionParams
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUid(uploadId: Long): ContainerImportJob? {
    var _result = null as com.ustadmobile.lib.db.entities.ContainerImportJob??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM ContainerImportJob where cijUid = ?" ,
        postgreSql = """
    |SELECT * FROM ContainerImportJob where cijUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uploadId)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cijUid = _resultSet.getLong("cijUid")
          val tmp_cijContainerUid = _resultSet.getLong("cijContainerUid")
          val tmp_cijUri = _resultSet.getString("cijUri")
          val tmp_cijImportMode = _resultSet.getInt("cijImportMode")
          val tmp_cijContainerBaseDir = _resultSet.getString("cijContainerBaseDir")
          val tmp_cijContentEntryUid = _resultSet.getLong("cijContentEntryUid")
          val tmp_cijMimeType = _resultSet.getString("cijMimeType")
          val tmp_cijSessionId = _resultSet.getString("cijSessionId")
          val tmp_cijJobStatus = _resultSet.getInt("cijJobStatus")
          val tmp_cijBytesSoFar = _resultSet.getLong("cijBytesSoFar")
          val tmp_cijImportCompleted = _resultSet.getBoolean("cijImportCompleted")
          val tmp_cijContentLength = _resultSet.getLong("cijContentLength")
          val tmp_cijContainerEntryFileUids = _resultSet.getString("cijContainerEntryFileUids")
          val tmp_cijConversionParams = _resultSet.getString("cijConversionParams")
          val _entity = ContainerImportJob()
          _entity.cijUid = tmp_cijUid
          _entity.cijContainerUid = tmp_cijContainerUid
          _entity.cijUri = tmp_cijUri
          _entity.cijImportMode = tmp_cijImportMode
          _entity.cijContainerBaseDir = tmp_cijContainerBaseDir
          _entity.cijContentEntryUid = tmp_cijContentEntryUid
          _entity.cijMimeType = tmp_cijMimeType
          _entity.cijSessionId = tmp_cijSessionId
          _entity.cijJobStatus = tmp_cijJobStatus
          _entity.cijBytesSoFar = tmp_cijBytesSoFar
          _entity.cijImportCompleted = tmp_cijImportCompleted
          _entity.cijContentLength = tmp_cijContentLength
          _entity.cijContainerEntryFileUids = tmp_cijContainerEntryFileUids
          _entity.cijConversionParams = tmp_cijConversionParams
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findJobs(): DoorLiveData<List<ContainerImportJob>> {
    val _result = DoorLiveDataImpl<List<ContainerImportJob>>(_db, listOf("ContainerImportJob",
        "ConnectivityStatus"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.ContainerImportJob>()
      val _stmtConfig = PreparedStatementConfig("""
      |
      |            SELECT * 
      |              FROM ContainerImportJob 
      |             WHERE cijJobStatus = 4
      |                   AND (NOT cijImportCompleted OR 
      |                   (SELECT connectivityState 
      |                      FROM ConnectivityStatus)
      |                   IN (3, 4))
      |             LIMIT 10
      """.trimMargin() , postgreSql = """
      |
      |            SELECT * 
      |              FROM ContainerImportJob 
      |             WHERE cijJobStatus = 4
      |                   AND (NOT cijImportCompleted OR 
      |                   (SELECT connectivityState 
      |                      FROM ConnectivityStatus)
      |                   IN (3, 4))
      |             LIMIT 10
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_cijUid = _resultSet.getLong("cijUid")
            val tmp_cijContainerUid = _resultSet.getLong("cijContainerUid")
            val tmp_cijUri = _resultSet.getString("cijUri")
            val tmp_cijImportMode = _resultSet.getInt("cijImportMode")
            val tmp_cijContainerBaseDir = _resultSet.getString("cijContainerBaseDir")
            val tmp_cijContentEntryUid = _resultSet.getLong("cijContentEntryUid")
            val tmp_cijMimeType = _resultSet.getString("cijMimeType")
            val tmp_cijSessionId = _resultSet.getString("cijSessionId")
            val tmp_cijJobStatus = _resultSet.getInt("cijJobStatus")
            val tmp_cijBytesSoFar = _resultSet.getLong("cijBytesSoFar")
            val tmp_cijImportCompleted = _resultSet.getBoolean("cijImportCompleted")
            val tmp_cijContentLength = _resultSet.getLong("cijContentLength")
            val tmp_cijContainerEntryFileUids = _resultSet.getString("cijContainerEntryFileUids")
            val tmp_cijConversionParams = _resultSet.getString("cijConversionParams")
            val _entity = ContainerImportJob()
            _entity.cijUid = tmp_cijUid
            _entity.cijContainerUid = tmp_cijContainerUid
            _entity.cijUri = tmp_cijUri
            _entity.cijImportMode = tmp_cijImportMode
            _entity.cijContainerBaseDir = tmp_cijContainerBaseDir
            _entity.cijContentEntryUid = tmp_cijContentEntryUid
            _entity.cijMimeType = tmp_cijMimeType
            _entity.cijSessionId = tmp_cijSessionId
            _entity.cijJobStatus = tmp_cijJobStatus
            _entity.cijBytesSoFar = tmp_cijBytesSoFar
            _entity.cijImportCompleted = tmp_cijImportCompleted
            _entity.cijContentLength = tmp_cijContentLength
            _entity.cijContainerEntryFileUids = tmp_cijContainerEntryFileUids
            _entity.cijConversionParams = tmp_cijConversionParams
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override suspend fun setStatusToQueueAsync(uploadJobId: Long): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ContainerImportJob SET cijJobStatus = 4 WHERE cijUid = ? AND cijJobStatus = 0"
        , postgreSql = """
    |UPDATE ContainerImportJob SET cijJobStatus = 4 WHERE cijUid = ? AND cijJobStatus = 0
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uploadJobId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun updateProgress(
    progress: Long,
    contentLength: Long,
    uploadJobId: Long
  ): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ContainerImportJob SET cijBytesSoFar = ?, cijContentLength = ? WHERE cijUid = ?"
        , postgreSql = """
    |UPDATE ContainerImportJob SET cijBytesSoFar = ?, cijContentLength = ? WHERE cijUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, progress)
      _stmt.setLong(2, contentLength)
      _stmt.setLong(3, uploadJobId)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun updateStatus(status: Int, uploadJobId: Long): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ContainerImportJob SET cijJobStatus = ? WHERE cijUid = ?" ,
        postgreSql = """
    |UPDATE ContainerImportJob SET cijJobStatus = ? WHERE cijUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, status)
      _stmt.setLong(2, uploadJobId)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun updateImportComplete(
    importCompleted: Boolean,
    containerUid: Long,
    importJobUid: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |UPDATE ContainerImportJob 
    |                       SET cijImportCompleted = ?,
    |                           cijContainerUid = ?
    |                     WHERE cijUid = ?
    """.trimMargin() , postgreSql = """
    |UPDATE ContainerImportJob 
    |                       SET cijImportCompleted = ?,
    |                           cijContainerUid = ?
    |                     WHERE cijUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, importCompleted)
      _stmt.setLong(2, containerUid)
      _stmt.setLong(3, importJobUid)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override suspend fun getTitleOfEntry(importJobUid: Long): String? {
    var _result = null as kotlin.String??
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntry.title FROM ContainerImportJob LEFT JOIN ContentEntry ON ContainerImportJob.cijContentEntryUid = ContentEntry.contentEntryUid WHERE ContainerImportJob.cijUid = ?"
        , postgreSql = """
    |SELECT ContentEntry.title FROM ContainerImportJob LEFT JOIN ContentEntry ON ContainerImportJob.cijContentEntryUid = ContentEntry.contentEntryUid WHERE ContainerImportJob.cijUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, importJobUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getString(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun getImportJobLiveData(importJobUid: Long): DoorLiveData<ContainerImportJob?> {
    val _result = DoorLiveDataImpl<ContainerImportJob?>(_db, listOf("ContainerImportJob"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ContainerImportJob??
      val _stmtConfig =
          PreparedStatementConfig("SELECT * From  ContainerImportJob WHERE ContainerImportJob.cijUid = ?"
          , postgreSql = """
      |SELECT * From  ContainerImportJob WHERE ContainerImportJob.cijUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, importJobUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_cijUid = _resultSet.getLong("cijUid")
            val tmp_cijContainerUid = _resultSet.getLong("cijContainerUid")
            val tmp_cijUri = _resultSet.getString("cijUri")
            val tmp_cijImportMode = _resultSet.getInt("cijImportMode")
            val tmp_cijContainerBaseDir = _resultSet.getString("cijContainerBaseDir")
            val tmp_cijContentEntryUid = _resultSet.getLong("cijContentEntryUid")
            val tmp_cijMimeType = _resultSet.getString("cijMimeType")
            val tmp_cijSessionId = _resultSet.getString("cijSessionId")
            val tmp_cijJobStatus = _resultSet.getInt("cijJobStatus")
            val tmp_cijBytesSoFar = _resultSet.getLong("cijBytesSoFar")
            val tmp_cijImportCompleted = _resultSet.getBoolean("cijImportCompleted")
            val tmp_cijContentLength = _resultSet.getLong("cijContentLength")
            val tmp_cijContainerEntryFileUids = _resultSet.getString("cijContainerEntryFileUids")
            val tmp_cijConversionParams = _resultSet.getString("cijConversionParams")
            val _entity = ContainerImportJob()
            _entity.cijUid = tmp_cijUid
            _entity.cijContainerUid = tmp_cijContainerUid
            _entity.cijUri = tmp_cijUri
            _entity.cijImportMode = tmp_cijImportMode
            _entity.cijContainerBaseDir = tmp_cijContainerBaseDir
            _entity.cijContentEntryUid = tmp_cijContentEntryUid
            _entity.cijMimeType = tmp_cijMimeType
            _entity.cijSessionId = tmp_cijSessionId
            _entity.cijJobStatus = tmp_cijJobStatus
            _entity.cijBytesSoFar = tmp_cijBytesSoFar
            _entity.cijImportCompleted = tmp_cijImportCompleted
            _entity.cijContentLength = tmp_cijContentLength
            _entity.cijContainerEntryFileUids = tmp_cijContainerEntryFileUids
            _entity.cijConversionParams = tmp_cijConversionParams
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateSessionId(importJobUid: Long, sessionId: String): Unit {
    val _stmtConfig =
        PreparedStatementConfig("UPDATE ContainerImportJob SET cijSessionId = ? WHERE cijUid = ?" ,
        postgreSql = """
    |UPDATE ContainerImportJob SET cijSessionId = ? WHERE cijUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, sessionId)
      _stmt.setLong(2, importJobUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: ContainerImportJob): Long {
    val _retVal = _insertAdapterContainerImportJob_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContainerImportJob): Long {
    val _retVal = _insertAdapterContainerImportJob_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContainerImportJob>): Unit {
    _insertAdapterContainerImportJob_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContainerImportJob>): Unit {
    val _sql =
        "UPDATE ContainerImportJob SET cijContainerUid = ?, cijUri = ?, cijImportMode = ?, cijContainerBaseDir = ?, cijContentEntryUid = ?, cijMimeType = ?, cijSessionId = ?, cijJobStatus = ?, cijBytesSoFar = ?, cijImportCompleted = ?, cijContentLength = ?, cijContainerEntryFileUids = ?, cijConversionParams = ? WHERE cijUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cijContainerUid)
        _stmt.setString(2, _entity.cijUri)
        _stmt.setInt(3, _entity.cijImportMode)
        _stmt.setString(4, _entity.cijContainerBaseDir)
        _stmt.setLong(5, _entity.cijContentEntryUid)
        _stmt.setString(6, _entity.cijMimeType)
        _stmt.setString(7, _entity.cijSessionId)
        _stmt.setInt(8, _entity.cijJobStatus)
        _stmt.setLong(9, _entity.cijBytesSoFar)
        _stmt.setBoolean(10, _entity.cijImportCompleted)
        _stmt.setLong(11, _entity.cijContentLength)
        _stmt.setString(12, _entity.cijContainerEntryFileUids)
        _stmt.setString(13, _entity.cijConversionParams)
        _stmt.setLong(14, _entity.cijUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContainerImportJob): Unit {
    val _sql =
        "UPDATE ContainerImportJob SET cijContainerUid = ?, cijUri = ?, cijImportMode = ?, cijContainerBaseDir = ?, cijContentEntryUid = ?, cijMimeType = ?, cijSessionId = ?, cijJobStatus = ?, cijBytesSoFar = ?, cijImportCompleted = ?, cijContentLength = ?, cijContainerEntryFileUids = ?, cijConversionParams = ? WHERE cijUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cijContainerUid)
      _stmt.setString(2, entity.cijUri)
      _stmt.setInt(3, entity.cijImportMode)
      _stmt.setString(4, entity.cijContainerBaseDir)
      _stmt.setLong(5, entity.cijContentEntryUid)
      _stmt.setString(6, entity.cijMimeType)
      _stmt.setString(7, entity.cijSessionId)
      _stmt.setInt(8, entity.cijJobStatus)
      _stmt.setLong(9, entity.cijBytesSoFar)
      _stmt.setBoolean(10, entity.cijImportCompleted)
      _stmt.setLong(11, entity.cijContentLength)
      _stmt.setString(12, entity.cijContainerEntryFileUids)
      _stmt.setString(13, entity.cijConversionParams)
      _stmt.setLong(14, entity.cijUid)
      _stmt.executeUpdate()
    }
  }
}
