package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.attachments.storeAttachment
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionAttachment
import com.ustadmobile.lib.db.entities.asEntityWithAttachment
import kotlin.IllegalStateException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseAssignmentSubmissionAttachmentDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: CourseAssignmentSubmissionAttachmentDao
) : CourseAssignmentSubmissionAttachmentDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend
      fun insertListAsync(entityList: List<CourseAssignmentSubmissionAttachment>): Unit {
    entityList.forEach {
      _db.storeAttachment(it.asEntityWithAttachment())
    }
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    val _generatedPks = mutableListOf<Long>()
    entityList.iterator().forEach  {
      if(it.casaUid == 0L) {
        val _newPk = _pkManager.nextIdAsync(90)
        it.casaUid = _newPk
        _generatedPks += _newPk
      }
      it.casaTimestamp = systemTimeInMillis()
    }
    //must set versionid and/or primary key here
    _dao.insertListAsync(entityList)
  }

  public override fun insert(entity: CourseAssignmentSubmissionAttachment): Long = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override suspend fun insertAsync(entity: CourseAssignmentSubmissionAttachment): Long {
    _db.storeAttachment(entity.asEntityWithAttachment())
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.casaUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(90)
      entity.casaUid = _newPk
    }
    entity.casaTimestamp = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.casaUid
  }

  public override fun insertList(entityList: List<out CourseAssignmentSubmissionAttachment>): Unit =
      throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun updateList(entityList: List<out CourseAssignmentSubmissionAttachment>): Unit =
      throw IllegalStateException("Synchronous db access is NOT possible on Javascript!")

  public override fun update(entity: CourseAssignmentSubmissionAttachment): Unit = throw
      IllegalStateException("Synchronous db access is NOT possible on Javascript!")
}
