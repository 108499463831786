package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContainerUidAndMimeType
import com.ustadmobile.lib.db.entities.ContainerWithContentEntry
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContainerDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContainerDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : ContainerDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(containerList: List<out Container>): Unit {
    _dao.insertListAsync(containerList)
  }

  public override suspend
      fun getMostRecentDownloadedContainerForContentEntryAsync(contentEntry: Long): Container? {
    val _result = _dao.getMostRecentDownloadedContainerForContentEntryAsync(contentEntry)
    return _result
  }

  public override fun getMostRecentContainerForContentEntry(contentEntryUid: Long): Container? {
    val _result = _dao.getMostRecentContainerForContentEntry(contentEntryUid)
    return _result
  }

  public override fun findByUid(uid: Long): Container? {
    val _result = _dao.findByUid(uid)
    return _result
  }

  public override suspend fun findSizeByUid(uid: Long): Long {
    val _result = _dao.findSizeByUid(uid)
    return _result
  }

  public override suspend
      fun findRecentContainerToBeMonitoredWithEntriesUid(contentEntries: List<Long>):
      List<Container> {
    val _result = _dao.findRecentContainerToBeMonitoredWithEntriesUid(contentEntries)
    return _result
  }

  public override suspend fun findContainersForContentEntryUid(contentEntryUid: Long):
      List<Container> {
    val _result = _dao.findContainersForContentEntryUid(contentEntryUid)
    return _result
  }

  public override fun hasContainerWithFilesToDownload(contentEntryUid: Long):
      DoorLiveData<Boolean> {
    val _result = _dao.hasContainerWithFilesToDownload(contentEntryUid)
    return _result
  }

  public override suspend fun findContainerWithFilesByContentEntryUid(contentEntryUid: Long):
      Container? {
    val _result = _dao.findContainerWithFilesByContentEntryUid(contentEntryUid)
    return _result
  }

  public override fun findAllPublikContainers(): List<Container> {
    val _result = _dao.findAllPublikContainers()
    return _result
  }

  public override suspend fun findByUidAsync(containerUid: Long): Container? {
    val _result = _dao.findByUidAsync(containerUid)
    return _result
  }

  public override fun updateContainerSizeAndNumEntries(containerUid: Long, changeTime: Long): Unit {
    _dao.updateContainerSizeAndNumEntries(containerUid, changeTime)
  }

  public override suspend fun updateContainerSizeAndNumEntriesAsync(containerUid: Long,
      changeTime: Long): Unit {
    _dao.updateContainerSizeAndNumEntriesAsync(containerUid, changeTime)
  }

  public override fun findLocalAvailabilityByUid(containerUid: Long): Long {
    val _result = _dao.findLocalAvailabilityByUid(containerUid)
    return _result
  }

  public override fun findAllWithId(containerUid: Long): List<Container> {
    val _result = _dao.findAllWithId(containerUid)
    return _result
  }

  public override fun findKhanContainers(): List<ContainerWithContentEntry> {
    val _result = _dao.findKhanContainers()
    return _result
  }

  public override fun deleteByUid(containerUid: Long): Unit {
    _dao.deleteByUid(containerUid)
  }

  public override fun updateMimeType(mimeType: String, containerUid: Long): Unit {
    _dao.updateMimeType(mimeType, containerUid)
  }

  public override suspend fun getMostRecentContainerForContentEntryAsync(contentEntryUid: Long):
      Container? {
    val _result = _dao.getMostRecentContainerForContentEntryAsync(contentEntryUid)
    return _result
  }

  public override suspend fun getMostRecentContainerUidForContentEntryAsync(contentEntryUid: Long):
      Long {
    val _result = _dao.getMostRecentContainerUidForContentEntryAsync(contentEntryUid)
    return _result
  }

  public override suspend fun getMostRecentAvailableContainerUidAndMimeType(contentEntryUid: Long,
      downloadRequired: Boolean): ContainerUidAndMimeType? {
    val _result = _dao.getMostRecentAvailableContainerUidAndMimeType(contentEntryUid,
        downloadRequired)
    return _result
  }

  public override fun replaceList(entries: List<out Container>): Unit {
    _dao.replaceList(entries)
  }

  public override fun insertWithReplace(container: Container): Unit {
    _dao.insertWithReplace(container)
  }

  public override suspend fun getContainerSizeByUid(containerUid: Long): Long {
    val _result = _dao.getContainerSizeByUid(containerUid)
    return _result
  }

  public override fun insert(entity: Container): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Container): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out Container>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Container>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: Container): Unit {
    _dao.update(entity)
  }
}
