package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.EntityRole
import com.ustadmobile.lib.db.entities.EntityRoleWithNameAndRole
import com.ustadmobile.lib.db.entities.Role
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class EntityRoleDao_JdbcKt(
  public val _db: DoorDatabase
) : EntityRoleDao() {
  public val _insertAdapterEntityRole_upsert: EntityInsertionAdapter<EntityRole> = object :
      EntityInsertionAdapter<EntityRole>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO EntityRole (erUid, erMasterCsn, erLocalCsn, erLastChangedBy, erLct, erTableId, erEntityUid, erGroupUid, erRoleUid, erActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO EntityRole (erUid, erMasterCsn, erLocalCsn, erLastChangedBy, erLct, erTableId, erEntityUid, erGroupUid, erRoleUid, erActive) VALUES(COALESCE(?,nextval('EntityRole_erUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (erUid) DO UPDATE SET erMasterCsn = excluded.erMasterCsn,erLocalCsn = excluded.erLocalCsn,erLastChangedBy = excluded.erLastChangedBy,erLct = excluded.erLct,erTableId = excluded.erTableId,erEntityUid = excluded.erEntityUid,erGroupUid = excluded.erGroupUid,erRoleUid = excluded.erRoleUid,erActive = excluded.erActive" + if(returnsId) { " RETURNING erUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: EntityRole):
        Unit {
      if(entity.erUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.erUid)
      }
      stmt.setLong(2, entity.erMasterCsn)
      stmt.setLong(3, entity.erLocalCsn)
      stmt.setInt(4, entity.erLastChangedBy)
      stmt.setLong(5, entity.erLct)
      stmt.setInt(6, entity.erTableId)
      stmt.setLong(7, entity.erEntityUid)
      stmt.setLong(8, entity.erGroupUid)
      stmt.setLong(9, entity.erRoleUid)
      stmt.setBoolean(10, entity.erActive)
    }
  }

  public override suspend fun userHasTableLevelPermission(accountPersonUid: Long, permission: Long):
      Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT COALESCE((
    |               SELECT admin 
    |                 FROM Person 
    |                WHERE personUid = ?), 0)
    |            OR EXISTS(SELECT EntityRole.erUid FROM EntityRole 
    |               JOIN Role 
    |                    ON EntityRole.erRoleUid = Role.roleUid 
    |               JOIN PersonGroupMember 
    |                    ON EntityRole.erGroupUid = PersonGroupMember.groupMemberGroupUid
    |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
    |               AND (Role.rolePermissions & ?) > 0) AS hasPermission
    """.trimMargin() , postgreSql = """
    |
    |        SELECT COALESCE((
    |               SELECT admin 
    |                 FROM Person 
    |                WHERE personUid = ?), false)
    |            OR EXISTS(SELECT EntityRole.erUid FROM EntityRole 
    |               JOIN Role 
    |                    ON EntityRole.erRoleUid = Role.roleUid 
    |               JOIN PersonGroupMember 
    |                    ON EntityRole.erGroupUid = PersonGroupMember.groupMemberGroupUid
    |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
    |               AND (Role.rolePermissions & ?) > 0) AS hasPermission
    |    
    """.trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, accountPersonUid)
      _stmt.setLong(2, accountPersonUid)
      _stmt.setLong(3, permission)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByEntitiyAndPersonGroupAndRole(
    tableId: Int,
    entityUid: Long,
    groupUid: Long,
    roleUid: Long
  ): List<EntityRole> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.EntityRole>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM EntityRole WHERE erTableId = ?  AND erEntityUid = ? AND erGroupUid = ?  AND erRoleUid = ? "
        , postgreSql = """
    |SELECT * FROM EntityRole WHERE erTableId = ?  AND erEntityUid = ? AND erGroupUid = ?  AND erRoleUid = ? 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, tableId)
      _stmt.setLong(2, entityUid)
      _stmt.setLong(3, groupUid)
      _stmt.setLong(4, roleUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_erUid = _resultSet.getLong("erUid")
          val tmp_erMasterCsn = _resultSet.getLong("erMasterCsn")
          val tmp_erLocalCsn = _resultSet.getLong("erLocalCsn")
          val tmp_erLastChangedBy = _resultSet.getInt("erLastChangedBy")
          val tmp_erLct = _resultSet.getLong("erLct")
          val tmp_erTableId = _resultSet.getInt("erTableId")
          val tmp_erEntityUid = _resultSet.getLong("erEntityUid")
          val tmp_erGroupUid = _resultSet.getLong("erGroupUid")
          val tmp_erRoleUid = _resultSet.getLong("erRoleUid")
          val tmp_erActive = _resultSet.getBoolean("erActive")
          val _entity = EntityRole()
          _entity.erUid = tmp_erUid
          _entity.erMasterCsn = tmp_erMasterCsn
          _entity.erLocalCsn = tmp_erLocalCsn
          _entity.erLastChangedBy = tmp_erLastChangedBy
          _entity.erLct = tmp_erLct
          _entity.erTableId = tmp_erTableId
          _entity.erEntityUid = tmp_erEntityUid
          _entity.erGroupUid = tmp_erGroupUid
          _entity.erRoleUid = tmp_erRoleUid
          _entity.erActive = tmp_erActive
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun filterByPersonWithExtra(personGroupUid: Long): DoorDataSourceFactory<Int,
      EntityRoleWithNameAndRole> {
    val _result = object : DoorDataSourceFactory<Int, EntityRoleWithNameAndRole>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<EntityRoleWithNameAndRole>> =
          DoorLiveDataImpl<List<EntityRoleWithNameAndRole>>(_db, listOf("Clazz", "Person", "School",
          "EntityRole", "Role"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.EntityRoleWithNameAndRole>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |                    SELECT  
        |                    (CASE 
        |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
        |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
        |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
        |                        ELSE '' 
        |                    END) as entityRoleScopeName,
        |                    Role.*, EntityRole.* FROM EntityRole
        |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
        |                    WHERE EntityRole.erGroupUid = ?
        |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
        |                ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |                    SELECT  
        |                    (CASE 
        |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
        |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
        |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
        |                        ELSE '' 
        |                    END) as entityRoleScopeName,
        |                    Role.*, EntityRole.* FROM EntityRole
        |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
        |                    WHERE EntityRole.erGroupUid = ?
        |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
        |                ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personGroupUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_entityRoleScopeName = _resultSet.getString("entityRoleScopeName")
              val tmp_erUid = _resultSet.getLong("erUid")
              val tmp_erMasterCsn = _resultSet.getLong("erMasterCsn")
              val tmp_erLocalCsn = _resultSet.getLong("erLocalCsn")
              val tmp_erLastChangedBy = _resultSet.getInt("erLastChangedBy")
              val tmp_erLct = _resultSet.getLong("erLct")
              val tmp_erTableId = _resultSet.getInt("erTableId")
              val tmp_erEntityUid = _resultSet.getLong("erEntityUid")
              val tmp_erGroupUid = _resultSet.getLong("erGroupUid")
              val tmp_erRoleUid = _resultSet.getLong("erRoleUid")
              val tmp_erActive = _resultSet.getBoolean("erActive")
              val _entity = EntityRoleWithNameAndRole()
              _entity.entityRoleScopeName = tmp_entityRoleScopeName
              _entity.erUid = tmp_erUid
              _entity.erMasterCsn = tmp_erMasterCsn
              _entity.erLocalCsn = tmp_erLocalCsn
              _entity.erLastChangedBy = tmp_erLastChangedBy
              _entity.erLct = tmp_erLct
              _entity.erTableId = tmp_erTableId
              _entity.erEntityUid = tmp_erEntityUid
              _entity.erGroupUid = tmp_erGroupUid
              _entity.erRoleUid = tmp_erRoleUid
              _entity.erActive = tmp_erActive
              var _entityRoleRole_nullFieldCount = 0
              val tmp_roleUid = _resultSet.getLong("roleUid")
              if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
              val tmp_roleName = _resultSet.getString("roleName")
              if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
              val tmp_roleActive = _resultSet.getBoolean("roleActive")
              if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
              val tmp_roleMasterCsn = _resultSet.getLong("roleMasterCsn")
              if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
              val tmp_roleLocalCsn = _resultSet.getLong("roleLocalCsn")
              if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
              val tmp_roleLastChangedBy = _resultSet.getInt("roleLastChangedBy")
              if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
              val tmp_roleLct = _resultSet.getLong("roleLct")
              if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
              val tmp_rolePermissions = _resultSet.getLong("rolePermissions")
              if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
              if(_entityRoleRole_nullFieldCount < 8) {
                if(_entity.entityRoleRole == null) {
                  _entity.entityRoleRole = Role()
                }
                _entity.entityRoleRole!!.roleUid = tmp_roleUid
                _entity.entityRoleRole!!.roleName = tmp_roleName
                _entity.entityRoleRole!!.roleActive = tmp_roleActive
                _entity.entityRoleRole!!.roleMasterCsn = tmp_roleMasterCsn
                _entity.entityRoleRole!!.roleLocalCsn = tmp_roleLocalCsn
                _entity.entityRoleRole!!.roleLastChangedBy = tmp_roleLastChangedBy
                _entity.entityRoleRole!!.roleLct = tmp_roleLct
                _entity.entityRoleRole!!.rolePermissions = tmp_rolePermissions
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Clazz", "Person", "School", "EntityRole", "Role"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |                    SELECT  
        |                    (CASE 
        |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
        |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
        |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
        |                        ELSE '' 
        |                    END) as entityRoleScopeName,
        |                    Role.*, EntityRole.* FROM EntityRole
        |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
        |                    WHERE EntityRole.erGroupUid = ?
        |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
        |                ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |                    SELECT  
        |                    (CASE 
        |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
        |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
        |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
        |                        ELSE '' 
        |                    END) as entityRoleScopeName,
        |                    Role.*, EntityRole.* FROM EntityRole
        |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
        |                    WHERE EntityRole.erGroupUid = ?
        |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
        |                ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personGroupUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun filterByPersonWithExtraAsList(personGroupUid: Long):
      List<EntityRoleWithNameAndRole> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.EntityRoleWithNameAndRole>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |                    SELECT  
    |                    (CASE 
    |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
    |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
    |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
    |                        ELSE '' 
    |                    END) as entityRoleScopeName,
    |                    Role.*, EntityRole.* FROM EntityRole
    |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
    |                    WHERE EntityRole.erGroupUid = ?
    |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
    |                
    """.trimMargin() , postgreSql = """
    |
    |                    SELECT  
    |                    (CASE 
    |                        WHEN EntityRole.erTableId = 6	THEN (SELECT Clazz.clazzName FROM Clazz WHERE Clazz.clazzUid = EntityRole.erEntityUid)
    |                        WHEN EntityRole.erTableId = 9	THEN (SELECT Person.firstNames||' '||Person.lastName FROM Person WHERE Person.personUid = EntityRole.erEntityUid)
    |                        WHEN EntityRole.erTableId = 164	THEN (SELECT School.schoolName FROM School WHERE School.schoolUid = EntityRole.erEntityUid)
    |                        ELSE '' 
    |                    END) as entityRoleScopeName,
    |                    Role.*, EntityRole.* FROM EntityRole
    |                    LEFT JOIN Role ON EntityRole.erRoleUid = Role.roleUid 
    |                    WHERE EntityRole.erGroupUid = ?
    |                    AND CAST(EntityRole.erActive AS INTEGER) = 1 
    |                
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, personGroupUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_entityRoleScopeName = _resultSet.getString("entityRoleScopeName")
          val tmp_erUid = _resultSet.getLong("erUid")
          val tmp_erMasterCsn = _resultSet.getLong("erMasterCsn")
          val tmp_erLocalCsn = _resultSet.getLong("erLocalCsn")
          val tmp_erLastChangedBy = _resultSet.getInt("erLastChangedBy")
          val tmp_erLct = _resultSet.getLong("erLct")
          val tmp_erTableId = _resultSet.getInt("erTableId")
          val tmp_erEntityUid = _resultSet.getLong("erEntityUid")
          val tmp_erGroupUid = _resultSet.getLong("erGroupUid")
          val tmp_erRoleUid = _resultSet.getLong("erRoleUid")
          val tmp_erActive = _resultSet.getBoolean("erActive")
          val _entity = EntityRoleWithNameAndRole()
          _entity.entityRoleScopeName = tmp_entityRoleScopeName
          _entity.erUid = tmp_erUid
          _entity.erMasterCsn = tmp_erMasterCsn
          _entity.erLocalCsn = tmp_erLocalCsn
          _entity.erLastChangedBy = tmp_erLastChangedBy
          _entity.erLct = tmp_erLct
          _entity.erTableId = tmp_erTableId
          _entity.erEntityUid = tmp_erEntityUid
          _entity.erGroupUid = tmp_erGroupUid
          _entity.erRoleUid = tmp_erRoleUid
          _entity.erActive = tmp_erActive
          var _entityRoleRole_nullFieldCount = 0
          val tmp_roleUid = _resultSet.getLong("roleUid")
          if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
          val tmp_roleName = _resultSet.getString("roleName")
          if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
          val tmp_roleActive = _resultSet.getBoolean("roleActive")
          if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
          val tmp_roleMasterCsn = _resultSet.getLong("roleMasterCsn")
          if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
          val tmp_roleLocalCsn = _resultSet.getLong("roleLocalCsn")
          if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
          val tmp_roleLastChangedBy = _resultSet.getInt("roleLastChangedBy")
          if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
          val tmp_roleLct = _resultSet.getLong("roleLct")
          if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
          val tmp_rolePermissions = _resultSet.getLong("rolePermissions")
          if(_resultSet.wasNull()) { _entityRoleRole_nullFieldCount++ }
          if(_entityRoleRole_nullFieldCount < 8) {
            if(_entity.entityRoleRole == null) {
              _entity.entityRoleRole = Role()
            }
            _entity.entityRoleRole!!.roleUid = tmp_roleUid
            _entity.entityRoleRole!!.roleName = tmp_roleName
            _entity.entityRoleRole!!.roleActive = tmp_roleActive
            _entity.entityRoleRole!!.roleMasterCsn = tmp_roleMasterCsn
            _entity.entityRoleRole!!.roleLocalCsn = tmp_roleLocalCsn
            _entity.entityRoleRole!!.roleLastChangedBy = tmp_roleLastChangedBy
            _entity.entityRoleRole!!.roleLct = tmp_roleLct
            _entity.entityRoleRole!!.rolePermissions = tmp_rolePermissions
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): EntityRole? {
    var _result = null as com.ustadmobile.lib.db.entities.EntityRole??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM EntityRole WHERE erUid = ?" ,
        postgreSql = """
    |SELECT * FROM EntityRole WHERE erUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_erUid = _resultSet.getLong("erUid")
          val tmp_erMasterCsn = _resultSet.getLong("erMasterCsn")
          val tmp_erLocalCsn = _resultSet.getLong("erLocalCsn")
          val tmp_erLastChangedBy = _resultSet.getInt("erLastChangedBy")
          val tmp_erLct = _resultSet.getLong("erLct")
          val tmp_erTableId = _resultSet.getInt("erTableId")
          val tmp_erEntityUid = _resultSet.getLong("erEntityUid")
          val tmp_erGroupUid = _resultSet.getLong("erGroupUid")
          val tmp_erRoleUid = _resultSet.getLong("erRoleUid")
          val tmp_erActive = _resultSet.getBoolean("erActive")
          val _entity = EntityRole()
          _entity.erUid = tmp_erUid
          _entity.erMasterCsn = tmp_erMasterCsn
          _entity.erLocalCsn = tmp_erLocalCsn
          _entity.erLastChangedBy = tmp_erLastChangedBy
          _entity.erLct = tmp_erLct
          _entity.erTableId = tmp_erTableId
          _entity.erEntityUid = tmp_erEntityUid
          _entity.erGroupUid = tmp_erGroupUid
          _entity.erRoleUid = tmp_erRoleUid
          _entity.erActive = tmp_erActive
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<EntityRole?> {
    val _result = DoorLiveDataImpl<EntityRole?>(_db, listOf("EntityRole"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.EntityRole??
      val _stmtConfig = PreparedStatementConfig("SELECT * FROM EntityRole WHERE erUid = ?" ,
          postgreSql = """
      |SELECT * FROM EntityRole WHERE erUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_erUid = _resultSet.getLong("erUid")
            val tmp_erMasterCsn = _resultSet.getLong("erMasterCsn")
            val tmp_erLocalCsn = _resultSet.getLong("erLocalCsn")
            val tmp_erLastChangedBy = _resultSet.getInt("erLastChangedBy")
            val tmp_erLct = _resultSet.getLong("erLct")
            val tmp_erTableId = _resultSet.getInt("erTableId")
            val tmp_erEntityUid = _resultSet.getLong("erEntityUid")
            val tmp_erGroupUid = _resultSet.getLong("erGroupUid")
            val tmp_erRoleUid = _resultSet.getLong("erRoleUid")
            val tmp_erActive = _resultSet.getBoolean("erActive")
            val _entity = EntityRole()
            _entity.erUid = tmp_erUid
            _entity.erMasterCsn = tmp_erMasterCsn
            _entity.erLocalCsn = tmp_erLocalCsn
            _entity.erLastChangedBy = tmp_erLastChangedBy
            _entity.erLct = tmp_erLct
            _entity.erTableId = tmp_erTableId
            _entity.erEntityUid = tmp_erEntityUid
            _entity.erGroupUid = tmp_erGroupUid
            _entity.erRoleUid = tmp_erRoleUid
            _entity.erActive = tmp_erActive
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateAsync(entity: EntityRole): Int {
    var _result = 0
    val _sql =
        "UPDATE EntityRole SET erMasterCsn = ?, erLocalCsn = ?, erLastChangedBy = ?, erLct = ?, erTableId = ?, erEntityUid = ?, erGroupUid = ?, erRoleUid = ?, erActive = ? WHERE erUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.erMasterCsn)
      _stmt.setLong(2, entity.erLocalCsn)
      _stmt.setInt(3, entity.erLastChangedBy)
      _stmt.setLong(4, entity.erLct)
      _stmt.setInt(5, entity.erTableId)
      _stmt.setLong(6, entity.erEntityUid)
      _stmt.setLong(7, entity.erGroupUid)
      _stmt.setLong(8, entity.erRoleUid)
      _stmt.setBoolean(9, entity.erActive)
      _stmt.setLong(10, entity.erUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun insertOrReplace(entity: EntityRole): Unit {
    _insertAdapterEntityRole_upsert.insertAsync(entity)
  }
}
