package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.AssignmentProgressSummary
import com.ustadmobile.lib.db.entities.ClazzAssignment
import com.ustadmobile.lib.db.entities.ClazzAssignmentWithMetrics
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import com.ustadmobile.lib.db.entities.CourseAssignmentMark
import com.ustadmobile.lib.db.entities.CourseBlock
import com.ustadmobile.lib.db.entities.CourseBlockWithCompleteEntity
import com.ustadmobile.lib.db.entities.CourseBlockWithEntityDb
import com.ustadmobile.lib.db.entities.CourseDiscussion
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseBlockDao_JdbcKt(
  public val _db: DoorDatabase
) : CourseBlockDao() {
  public val _insertAdapterCourseBlock_upsert: EntityInsertionAdapter<CourseBlock> = object :
      EntityInsertionAdapter<CourseBlock>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO CourseBlock (cbUid, cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbActive, cbHidden, cbEntityUid, cbLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseBlock (cbUid, cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbActive, cbHidden, cbEntityUid, cbLct) VALUES(COALESCE(?,nextval('CourseBlock_cbUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (cbUid) DO UPDATE SET cbType = excluded.cbType,cbIndentLevel = excluded.cbIndentLevel,cbModuleParentBlockUid = excluded.cbModuleParentBlockUid,cbTitle = excluded.cbTitle,cbDescription = excluded.cbDescription,cbCompletionCriteria = excluded.cbCompletionCriteria,cbHideUntilDate = excluded.cbHideUntilDate,cbDeadlineDate = excluded.cbDeadlineDate,cbLateSubmissionPenalty = excluded.cbLateSubmissionPenalty,cbGracePeriodDate = excluded.cbGracePeriodDate,cbMaxPoints = excluded.cbMaxPoints,cbMinPoints = excluded.cbMinPoints,cbIndex = excluded.cbIndex,cbClazzUid = excluded.cbClazzUid,cbActive = excluded.cbActive,cbHidden = excluded.cbHidden,cbEntityUid = excluded.cbEntityUid,cbLct = excluded.cbLct" + if(returnsId) { " RETURNING cbUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseBlock):
        Unit {
      if(entity.cbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cbUid)
      }
      stmt.setInt(2, entity.cbType)
      stmt.setInt(3, entity.cbIndentLevel)
      stmt.setLong(4, entity.cbModuleParentBlockUid)
      stmt.setString(5, entity.cbTitle)
      stmt.setString(6, entity.cbDescription)
      stmt.setInt(7, entity.cbCompletionCriteria)
      stmt.setLong(8, entity.cbHideUntilDate)
      stmt.setLong(9, entity.cbDeadlineDate)
      stmt.setInt(10, entity.cbLateSubmissionPenalty)
      stmt.setLong(11, entity.cbGracePeriodDate)
      stmt.setInt(12, entity.cbMaxPoints)
      stmt.setInt(13, entity.cbMinPoints)
      stmt.setInt(14, entity.cbIndex)
      stmt.setLong(15, entity.cbClazzUid)
      stmt.setBoolean(16, entity.cbActive)
      stmt.setBoolean(17, entity.cbHidden)
      stmt.setLong(18, entity.cbEntityUid)
      stmt.setLong(19, entity.cbLct)
    }
  }

  public val _insertAdapterCourseBlock_: EntityInsertionAdapter<CourseBlock> = object :
      EntityInsertionAdapter<CourseBlock>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CourseBlock (cbUid, cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbActive, cbHidden, cbEntityUid, cbLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseBlock (cbUid, cbType, cbIndentLevel, cbModuleParentBlockUid, cbTitle, cbDescription, cbCompletionCriteria, cbHideUntilDate, cbDeadlineDate, cbLateSubmissionPenalty, cbGracePeriodDate, cbMaxPoints, cbMinPoints, cbIndex, cbClazzUid, cbActive, cbHidden, cbEntityUid, cbLct) VALUES(COALESCE(?,nextval('CourseBlock_cbUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cbUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: CourseBlock):
        Unit {
      if(entity.cbUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cbUid)
      }
      stmt.setInt(2, entity.cbType)
      stmt.setInt(3, entity.cbIndentLevel)
      stmt.setLong(4, entity.cbModuleParentBlockUid)
      stmt.setString(5, entity.cbTitle)
      stmt.setString(6, entity.cbDescription)
      stmt.setInt(7, entity.cbCompletionCriteria)
      stmt.setLong(8, entity.cbHideUntilDate)
      stmt.setLong(9, entity.cbDeadlineDate)
      stmt.setInt(10, entity.cbLateSubmissionPenalty)
      stmt.setLong(11, entity.cbGracePeriodDate)
      stmt.setInt(12, entity.cbMaxPoints)
      stmt.setInt(13, entity.cbMinPoints)
      stmt.setInt(14, entity.cbIndex)
      stmt.setLong(15, entity.cbClazzUid)
      stmt.setBoolean(16, entity.cbActive)
      stmt.setBoolean(17, entity.cbHidden)
      stmt.setLong(18, entity.cbEntityUid)
      stmt.setLong(19, entity.cbLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |    REPLACE INTO CourseBlockReplicate(cbPk, cbDestination)
    |      SELECT DISTINCT CourseBlock.cbUid AS cbPk,
    |             ? AS cbDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN CourseBlock
    |                    ON CourseBlock.cbClazzUid = Clazz.clazzUid                
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseBlock.cbLct != COALESCE(
    |             (SELECT cbVersionId
    |                FROM CourseBlockReplicate
    |               WHERE cbPk = CourseBlock.cbUid
    |                 AND cbDestination = ?), 0) 
    |      /*psql ON CONFLICT(cbPk, cbDestination) DO UPDATE
    |             SET cbPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseBlockReplicate(cbPk, cbDestination)
    |      SELECT DISTINCT CourseBlock.cbUid AS cbPk,
    |             ? AS cbDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN CourseBlock
    |                    ON CourseBlock.cbClazzUid = Clazz.clazzUid                
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseBlock.cbLct != COALESCE(
    |             (SELECT cbVersionId
    |                FROM CourseBlockReplicate
    |               WHERE cbPk = CourseBlock.cbUid
    |                 AND cbDestination = ?), 0) 
    |       ON CONFLICT(cbPk, cbDestination) DO UPDATE
    |             SET cbPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         REPLACE INTO CourseBlockReplicate(cbPk, cbDestination)
    |  SELECT DISTINCT CourseBlock.cbUid AS cbPk,
    |         UserSession.usClientNodeId AS cbDestination
    |    FROM ChangeLog
    |         JOIN CourseBlock
    |             ON ChangeLog.chTableId = 124
    |                AND ChangeLog.chEntityPk = CourseBlock.cbUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = CourseBlock.cbClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseBlock.cbLct != COALESCE(
    |         (SELECT cbVersionId
    |            FROM CourseBlockReplicate
    |           WHERE cbPk = CourseBlock.cbUid
    |             AND cbDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cbPk, cbDestination) DO UPDATE
    |     SET cbPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseBlockReplicate(cbPk, cbDestination)
    |  SELECT DISTINCT CourseBlock.cbUid AS cbPk,
    |         UserSession.usClientNodeId AS cbDestination
    |    FROM ChangeLog
    |         JOIN CourseBlock
    |             ON ChangeLog.chTableId = 124
    |                AND ChangeLog.chEntityPk = CourseBlock.cbUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = CourseBlock.cbClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              2
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseBlock.cbLct != COALESCE(
    |         (SELECT cbVersionId
    |            FROM CourseBlockReplicate
    |           WHERE cbPk = CourseBlock.cbUid
    |             AND cbDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cbPk, cbDestination) DO UPDATE
    |     SET cbPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByUidAsync(uid: Long): CourseBlock? {
    var _result = null as com.ustadmobile.lib.db.entities.CourseBlock??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM CourseBlock WHERE cbUid = ?" ,
        postgreSql = """
    |SELECT * FROM CourseBlock WHERE cbUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cbUid = _resultSet.getLong("cbUid")
          val tmp_cbType = _resultSet.getInt("cbType")
          val tmp_cbIndentLevel = _resultSet.getInt("cbIndentLevel")
          val tmp_cbModuleParentBlockUid = _resultSet.getLong("cbModuleParentBlockUid")
          val tmp_cbTitle = _resultSet.getString("cbTitle")
          val tmp_cbDescription = _resultSet.getString("cbDescription")
          val tmp_cbCompletionCriteria = _resultSet.getInt("cbCompletionCriteria")
          val tmp_cbHideUntilDate = _resultSet.getLong("cbHideUntilDate")
          val tmp_cbDeadlineDate = _resultSet.getLong("cbDeadlineDate")
          val tmp_cbLateSubmissionPenalty = _resultSet.getInt("cbLateSubmissionPenalty")
          val tmp_cbGracePeriodDate = _resultSet.getLong("cbGracePeriodDate")
          val tmp_cbMaxPoints = _resultSet.getInt("cbMaxPoints")
          val tmp_cbMinPoints = _resultSet.getInt("cbMinPoints")
          val tmp_cbIndex = _resultSet.getInt("cbIndex")
          val tmp_cbClazzUid = _resultSet.getLong("cbClazzUid")
          val tmp_cbActive = _resultSet.getBoolean("cbActive")
          val tmp_cbHidden = _resultSet.getBoolean("cbHidden")
          val tmp_cbEntityUid = _resultSet.getLong("cbEntityUid")
          val tmp_cbLct = _resultSet.getLong("cbLct")
          val _entity = CourseBlock()
          _entity.cbUid = tmp_cbUid
          _entity.cbType = tmp_cbType
          _entity.cbIndentLevel = tmp_cbIndentLevel
          _entity.cbModuleParentBlockUid = tmp_cbModuleParentBlockUid
          _entity.cbTitle = tmp_cbTitle
          _entity.cbDescription = tmp_cbDescription
          _entity.cbCompletionCriteria = tmp_cbCompletionCriteria
          _entity.cbHideUntilDate = tmp_cbHideUntilDate
          _entity.cbDeadlineDate = tmp_cbDeadlineDate
          _entity.cbLateSubmissionPenalty = tmp_cbLateSubmissionPenalty
          _entity.cbGracePeriodDate = tmp_cbGracePeriodDate
          _entity.cbMaxPoints = tmp_cbMaxPoints
          _entity.cbMinPoints = tmp_cbMinPoints
          _entity.cbIndex = tmp_cbIndex
          _entity.cbClazzUid = tmp_cbClazzUid
          _entity.cbActive = tmp_cbActive
          _entity.cbHidden = tmp_cbHidden
          _entity.cbEntityUid = tmp_cbEntityUid
          _entity.cbLct = tmp_cbLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: CourseBlock): Int {
    var _result = 0
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ? WHERE cbUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setInt(1, entity.cbType)
      _stmt.setInt(2, entity.cbIndentLevel)
      _stmt.setLong(3, entity.cbModuleParentBlockUid)
      _stmt.setString(4, entity.cbTitle)
      _stmt.setString(5, entity.cbDescription)
      _stmt.setInt(6, entity.cbCompletionCriteria)
      _stmt.setLong(7, entity.cbHideUntilDate)
      _stmt.setLong(8, entity.cbDeadlineDate)
      _stmt.setInt(9, entity.cbLateSubmissionPenalty)
      _stmt.setLong(10, entity.cbGracePeriodDate)
      _stmt.setInt(11, entity.cbMaxPoints)
      _stmt.setInt(12, entity.cbMinPoints)
      _stmt.setInt(13, entity.cbIndex)
      _stmt.setLong(14, entity.cbClazzUid)
      _stmt.setBoolean(15, entity.cbActive)
      _stmt.setBoolean(16, entity.cbHidden)
      _stmt.setLong(17, entity.cbEntityUid)
      _stmt.setLong(18, entity.cbLct)
      _stmt.setLong(19, entity.cbUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun replaceListAsync(list: List<out CourseBlock>): Unit {
    _insertAdapterCourseBlock_upsert.insertListAsync(list)
  }

  public override suspend fun findAllCourseBlockByClazzUidAsync(clazzUid: Long):
      List<CourseBlockWithEntityDb> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.CourseBlockWithEntityDb>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM CourseBlock 
    |               LEFT JOIN ClazzAssignment as assignment
    |               ON assignment.caUid = CourseBlock.cbEntityUid
    |               AND CourseBlock.cbType = 103
    |               LEFT JOIN CourseDiscussion as courseDiscussion
    |               ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
    |               AND CourseBlock.cbType = 105
    |               LEFT JOIN ContentEntry as entry
    |               ON entry.contentEntryUid = CourseBlock.cbEntityUid
    |               AND CourseBlock.cbType = 104
    |               
    |               LEFT JOIN Language
    |               ON Language.langUid = entry.primaryLanguageUid
    |                AND CourseBlock.cbType = 104
    |               
    |         WHERE cbClazzUid = ?
    |           AND cbActive
    |      ORDER BY cbIndex
    |          
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM CourseBlock 
    |               LEFT JOIN ClazzAssignment as assignment
    |               ON assignment.caUid = CourseBlock.cbEntityUid
    |               AND CourseBlock.cbType = 103
    |               LEFT JOIN CourseDiscussion as courseDiscussion
    |               ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
    |               AND CourseBlock.cbType = 105
    |               LEFT JOIN ContentEntry as entry
    |               ON entry.contentEntryUid = CourseBlock.cbEntityUid
    |               AND CourseBlock.cbType = 104
    |               
    |               LEFT JOIN Language
    |               ON Language.langUid = entry.primaryLanguageUid
    |                AND CourseBlock.cbType = 104
    |               
    |         WHERE cbClazzUid = ?
    |           AND cbActive
    |      ORDER BY cbIndex
    |          
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cbUid = _resultSet.getLong("cbUid")
          val tmp_cbType = _resultSet.getInt("cbType")
          val tmp_cbIndentLevel = _resultSet.getInt("cbIndentLevel")
          val tmp_cbModuleParentBlockUid = _resultSet.getLong("cbModuleParentBlockUid")
          val tmp_cbTitle = _resultSet.getString("cbTitle")
          val tmp_cbDescription = _resultSet.getString("cbDescription")
          val tmp_cbCompletionCriteria = _resultSet.getInt("cbCompletionCriteria")
          val tmp_cbHideUntilDate = _resultSet.getLong("cbHideUntilDate")
          val tmp_cbDeadlineDate = _resultSet.getLong("cbDeadlineDate")
          val tmp_cbLateSubmissionPenalty = _resultSet.getInt("cbLateSubmissionPenalty")
          val tmp_cbGracePeriodDate = _resultSet.getLong("cbGracePeriodDate")
          val tmp_cbMaxPoints = _resultSet.getInt("cbMaxPoints")
          val tmp_cbMinPoints = _resultSet.getInt("cbMinPoints")
          val tmp_cbIndex = _resultSet.getInt("cbIndex")
          val tmp_cbClazzUid = _resultSet.getLong("cbClazzUid")
          val tmp_cbActive = _resultSet.getBoolean("cbActive")
          val tmp_cbHidden = _resultSet.getBoolean("cbHidden")
          val tmp_cbEntityUid = _resultSet.getLong("cbEntityUid")
          val tmp_cbLct = _resultSet.getLong("cbLct")
          val _entity = CourseBlockWithEntityDb()
          _entity.cbUid = tmp_cbUid
          _entity.cbType = tmp_cbType
          _entity.cbIndentLevel = tmp_cbIndentLevel
          _entity.cbModuleParentBlockUid = tmp_cbModuleParentBlockUid
          _entity.cbTitle = tmp_cbTitle
          _entity.cbDescription = tmp_cbDescription
          _entity.cbCompletionCriteria = tmp_cbCompletionCriteria
          _entity.cbHideUntilDate = tmp_cbHideUntilDate
          _entity.cbDeadlineDate = tmp_cbDeadlineDate
          _entity.cbLateSubmissionPenalty = tmp_cbLateSubmissionPenalty
          _entity.cbGracePeriodDate = tmp_cbGracePeriodDate
          _entity.cbMaxPoints = tmp_cbMaxPoints
          _entity.cbMinPoints = tmp_cbMinPoints
          _entity.cbIndex = tmp_cbIndex
          _entity.cbClazzUid = tmp_cbClazzUid
          _entity.cbActive = tmp_cbActive
          _entity.cbHidden = tmp_cbHidden
          _entity.cbEntityUid = tmp_cbEntityUid
          _entity.cbLct = tmp_cbLct
          var _assignment_nullFieldCount = 0
          val tmp_caUid = _resultSet.getLong("caUid")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caTitle = _resultSet.getString("caTitle")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caDescription = _resultSet.getString("caDescription")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caGroupUid = _resultSet.getLong("caGroupUid")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caActive = _resultSet.getBoolean("caActive")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caClassCommentEnabled = _resultSet.getBoolean("caClassCommentEnabled")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caPrivateCommentsEnabled = _resultSet.getBoolean("caPrivateCommentsEnabled")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caCompletionCriteria = _resultSet.getInt("caCompletionCriteria")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caRequireFileSubmission = _resultSet.getBoolean("caRequireFileSubmission")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caFileType = _resultSet.getInt("caFileType")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caSizeLimit = _resultSet.getInt("caSizeLimit")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caNumberOfFiles = _resultSet.getInt("caNumberOfFiles")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caSubmissionPolicy = _resultSet.getInt("caSubmissionPolicy")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caMarkingType = _resultSet.getInt("caMarkingType")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caRequireTextSubmission = _resultSet.getBoolean("caRequireTextSubmission")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caTextLimitType = _resultSet.getInt("caTextLimitType")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caTextLimit = _resultSet.getInt("caTextLimit")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caXObjectUid = _resultSet.getLong("caXObjectUid")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caClazzUid = _resultSet.getLong("caClazzUid")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caLocalChangeSeqNum = _resultSet.getLong("caLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caMasterChangeSeqNum = _resultSet.getLong("caMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caLastChangedBy = _resultSet.getInt("caLastChangedBy")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          val tmp_caLct = _resultSet.getLong("caLct")
          if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
          if(_assignment_nullFieldCount < 23) {
            if(_entity.assignment == null) {
              _entity.assignment = ClazzAssignment()
            }
            _entity.assignment!!.caUid = tmp_caUid
            _entity.assignment!!.caTitle = tmp_caTitle
            _entity.assignment!!.caDescription = tmp_caDescription
            _entity.assignment!!.caGroupUid = tmp_caGroupUid
            _entity.assignment!!.caActive = tmp_caActive
            _entity.assignment!!.caClassCommentEnabled = tmp_caClassCommentEnabled
            _entity.assignment!!.caPrivateCommentsEnabled = tmp_caPrivateCommentsEnabled
            _entity.assignment!!.caCompletionCriteria = tmp_caCompletionCriteria
            _entity.assignment!!.caRequireFileSubmission = tmp_caRequireFileSubmission
            _entity.assignment!!.caFileType = tmp_caFileType
            _entity.assignment!!.caSizeLimit = tmp_caSizeLimit
            _entity.assignment!!.caNumberOfFiles = tmp_caNumberOfFiles
            _entity.assignment!!.caSubmissionPolicy = tmp_caSubmissionPolicy
            _entity.assignment!!.caMarkingType = tmp_caMarkingType
            _entity.assignment!!.caRequireTextSubmission = tmp_caRequireTextSubmission
            _entity.assignment!!.caTextLimitType = tmp_caTextLimitType
            _entity.assignment!!.caTextLimit = tmp_caTextLimit
            _entity.assignment!!.caXObjectUid = tmp_caXObjectUid
            _entity.assignment!!.caClazzUid = tmp_caClazzUid
            _entity.assignment!!.caLocalChangeSeqNum = tmp_caLocalChangeSeqNum
            _entity.assignment!!.caMasterChangeSeqNum = tmp_caMasterChangeSeqNum
            _entity.assignment!!.caLastChangedBy = tmp_caLastChangedBy
            _entity.assignment!!.caLct = tmp_caLct
          }
          var _entry_nullFieldCount = 0
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_title = _resultSet.getString("title")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_description = _resultSet.getString("description")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_entryId = _resultSet.getString("entryId")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_author = _resultSet.getString("author")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_publisher = _resultSet.getString("publisher")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_licenseType = _resultSet.getInt("licenseType")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_licenseName = _resultSet.getString("licenseName")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_lastModified = _resultSet.getLong("lastModified")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_leaf = _resultSet.getBoolean("leaf")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_publik = _resultSet.getBoolean("publik")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_minScore = _resultSet.getInt("minScore")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
          if(_entry_nullFieldCount < 26) {
            if(_entity.entry == null) {
              _entity.entry = ContentEntry()
            }
            _entity.entry!!.contentEntryUid = tmp_contentEntryUid
            _entity.entry!!.title = tmp_title
            _entity.entry!!.description = tmp_description
            _entity.entry!!.entryId = tmp_entryId
            _entity.entry!!.author = tmp_author
            _entity.entry!!.publisher = tmp_publisher
            _entity.entry!!.licenseType = tmp_licenseType
            _entity.entry!!.licenseName = tmp_licenseName
            _entity.entry!!.licenseUrl = tmp_licenseUrl
            _entity.entry!!.sourceUrl = tmp_sourceUrl
            _entity.entry!!.thumbnailUrl = tmp_thumbnailUrl
            _entity.entry!!.lastModified = tmp_lastModified
            _entity.entry!!.primaryLanguageUid = tmp_primaryLanguageUid
            _entity.entry!!.languageVariantUid = tmp_languageVariantUid
            _entity.entry!!.contentFlags = tmp_contentFlags
            _entity.entry!!.leaf = tmp_leaf
            _entity.entry!!.publik = tmp_publik
            _entity.entry!!.ceInactive = tmp_ceInactive
            _entity.entry!!.completionCriteria = tmp_completionCriteria
            _entity.entry!!.minScore = tmp_minScore
            _entity.entry!!.contentTypeFlag = tmp_contentTypeFlag
            _entity.entry!!.contentOwner = tmp_contentOwner
            _entity.entry!!.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
            _entity.entry!!.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
            _entity.entry!!.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
            _entity.entry!!.contentEntryLct = tmp_contentEntryLct
          }
          var _courseDiscussion_nullFieldCount = 0
          val tmp_courseDiscussionUid = _resultSet.getLong("courseDiscussionUid")
          if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
          val tmp_courseDiscussionTitle = _resultSet.getString("courseDiscussionTitle")
          if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
          val tmp_courseDiscussionDesc = _resultSet.getString("courseDiscussionDesc")
          if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
          val tmp_courseDiscussionClazzUid = _resultSet.getLong("courseDiscussionClazzUid")
          if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
          val tmp_courseDiscussionActive = _resultSet.getBoolean("courseDiscussionActive")
          if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
          val tmp_courseDiscussionLct = _resultSet.getLong("courseDiscussionLct")
          if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
          if(_courseDiscussion_nullFieldCount < 6) {
            if(_entity.courseDiscussion == null) {
              _entity.courseDiscussion = CourseDiscussion()
            }
            _entity.courseDiscussion!!.courseDiscussionUid = tmp_courseDiscussionUid
            _entity.courseDiscussion!!.courseDiscussionTitle = tmp_courseDiscussionTitle
            _entity.courseDiscussion!!.courseDiscussionDesc = tmp_courseDiscussionDesc
            _entity.courseDiscussion!!.courseDiscussionClazzUid = tmp_courseDiscussionClazzUid
            _entity.courseDiscussion!!.courseDiscussionActive = tmp_courseDiscussionActive
            _entity.courseDiscussion!!.courseDiscussionLct = tmp_courseDiscussionLct
          }
          var _language_nullFieldCount = 0
          val tmp_langUid = _resultSet.getLong("langUid")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_name = _resultSet.getString("name")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          val tmp_langLct = _resultSet.getLong("langLct")
          if(_resultSet.wasNull()) { _language_nullFieldCount++ }
          if(_language_nullFieldCount < 11) {
            if(_entity.language == null) {
              _entity.language = Language()
            }
            _entity.language!!.langUid = tmp_langUid
            _entity.language!!.name = tmp_name
            _entity.language!!.iso_639_1_standard = tmp_iso_639_1_standard
            _entity.language!!.iso_639_2_standard = tmp_iso_639_2_standard
            _entity.language!!.iso_639_3_standard = tmp_iso_639_3_standard
            _entity.language!!.Language_Type = tmp_Language_Type
            _entity.language!!.languageActive = tmp_languageActive
            _entity.language!!.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
            _entity.language!!.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
            _entity.language!!.langLastChangedBy = tmp_langLastChangedBy
            _entity.language!!.langLct = tmp_langLct
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllCourseBlockByClazzUidLive(
    clazzUid: Long,
    personUid: Long,
    collapseList: List<Long>,
    currentTime: Long
  ): DoorDataSourceFactory<Int, CourseBlockWithCompleteEntity> {
    val _result = object : DoorDataSourceFactory<Int, CourseBlockWithCompleteEntity>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<CourseBlockWithCompleteEntity>> =
          DoorLiveDataImpl<List<CourseBlockWithCompleteEntity>>(_db, listOf("CourseBlock",
          "ClazzAssignment", "CourseDiscussion", "ContentEntry", "CourseAssignmentMark",
          "StatementEntity", "Container", "ContentEntryParentChildJoin", "PersonGroupMember",
          "Clazz", "ScopedGrant", "ClazzEnrolment", "CourseAssignmentSubmission",
          "CourseGroupMember"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.CourseBlockWithCompleteEntity>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |         WITH CtePermissionCheck (hasPermission) 
        |            AS (SELECT EXISTS( 
        |               SELECT PrsGrpMbr.groupMemberPersonUid
        |                  FROM Clazz
        |                       
        |            JOIN ScopedGrant
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                                AND ScopedGrant.sgEntityUid = -2)
        |                            OR (ScopedGrant.sgTableId = 6
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
        |                            OR (ScopedGrant.sgTableId = 164
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
        |        
        |                    AND (ScopedGrant.sgPermissions & 
        |        
        |                          549755813888
        |                          
        |                                                       ) > 0
        |             JOIN PersonGroupMember AS PrsGrpMbr
        |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
        |        
        |                 WHERE Clazz.clazzUid = ?
        |                   AND PrsGrpMbr.groupMemberPersonUid = ?)), 
        |                   
        |        
        |            SubmitterList (submitterId, assignmentUid)
        |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId,
        |                       ClazzAssignment.caUid AS assignmentUid
        |                  
        |                  FROM ClazzEnrolment
        |                  
        |                       JOIN Person 
        |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
        |                        
        |                       JOIN ClazzAssignment
        |                       ON ClazzAssignment.caClazzUid = ?
        |
        |                       JOIN CourseBlock
        |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
        |                       AND CourseBlock.cbType = 103
        |                       
        |                 WHERE ClazzAssignment.caGroupUid = 0
        |                   AND clazzEnrolmentClazzUid = ?
        |                   AND clazzEnrolmentActive
        |                   AND clazzEnrolmentRole = 1000
        |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
        |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
        |              GROUP BY submitterId, assignmentUid
        |            UNION                 
        |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
        |                    ClazzAssignment.caUid AS assignmentUid
        |               FROM CourseGroupMember
        |                    JOIN ClazzAssignment
        |                    ON ClazzAssignment.caClazzUid = ?
        |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
        |                AND ClazzAssignment.caGroupUid != 0
        |                AND CourseGroupMember.cgmGroupNumber != 0
        |           GROUP BY submitterId, assignmentUid
        |            )
        |                   
        |                   
        |
        |        SELECT CourseBlock.*, ClazzAssignment.*, ContentEntry.*, CourseDiscussion.*, ContentEntryParentChildJoin.*, 
        |               Container.*, CourseAssignmentMark.*, (CourseBlock.cbUid NOT IN (?)) AS expanded,
        |               
        |               COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                
        |                0 AS assignmentContentWeight,
        |                1 as totalContent, 
        |                0 as penalty,
        |                
        |                (SELECT hasPermission FROM CtePermissionCheck) AS hasMetricsPermission,
        |                
        |             
        |                 (SELECT COUNT(*) 
        |                    FROM SubmitterList 
        |                   WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid) 
        |                        AS totalStudents, 
        | 
        |               0 AS notSubmittedStudents,
        |               
        |               (CASE WHEN (SELECT hasPermission 
        |                          FROM CtePermissionCheck)
        |                     THEN (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
        |                             FROM CourseAssignmentSubmission
        |                                   LEFT JOIN CourseAssignmentMark
        |                                   ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
        |                                   AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
        |                            WHERE CourseAssignmentMark.camUid IS NULL
        |                              AND CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
        |                              AND CourseAssignmentSubmission.casSubmitterUid IN 
        |                                                    (SELECT submitterId 
        |                                                      FROM SubmitterList
        |                                                     WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))  
        |                      ELSE 0 END) AS submittedStudents,         
        |               
        |                (CASE WHEN (SELECT hasPermission 
        |                           FROM CtePermissionCheck)       
        |                   THEN (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
        |                           FROM CourseAssignmentMark
        |                            
        |                             JOIN CourseAssignmentSubmission
        |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
        |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
        |                             
        |                          WHERE CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid 
        |                            AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
        |                                                                            FROM SubmitterList
        |                                                                           WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))
        |                   ELSE 0 END) AS markedStudents,
        |                   
        |                   COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
        |                          THEN 2 
        |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
        |                          THEN 1 
        |                          ELSE 0 END), 
        |                               0) AS fileSubmissionStatus
        |                
        |                
        |          FROM CourseBlock 
        |          
        |               LEFT JOIN CourseBlock AS parentBlock
        |               ON CourseBlock.cbModuleParentBlockUid = parentBlock.cbUid
        |               AND CourseBlock.cbTYpe != 100
        |          
        |               LEFT JOIN ClazzAssignment
        |               ON ClazzAssignment.caUid = CourseBlock.cbEntityUid
        |               AND CourseBlock.cbType = 103
        |               
        |               LEFT JOIN ContentEntry
        |               ON ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
        |               AND NOT ceInactive
        |               AND CourseBlock.cbType = 104
        |               
        |               LEFT JOIN CourseDiscussion 
        |                      ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
        |                     AND CourseBlock.cbType = 105
        |               
        |               LEFT JOIN ContentEntryParentChildJoin 
        |               ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
        |               
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)
        |                       
        |              LEFT JOIN StatementEntity
        |				     ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, 
        |                                        extensionProgress DESC, 
        |                                        resultSuccess DESC 
        |                                  LIMIT 1) 
        |                                  
        |               LEFT JOIN CourseAssignmentSubmission
        |                ON casUid = (SELECT casUid 
        |                                     FROM CourseAssignmentSubmission
        |                                    WHERE casAssignmentUid = ClazzAssignment.caUid
        |                                      AND casSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
        |                                                                          THEN ? 
        |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
        |                                                                          THEN 0 
        |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
        |                                                                          END) as submitterUid
        |                                                               FROM ClazzAssignment AS ref
        |                                                                    LEFT JOIN CourseGroupMember
        |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
        |                                                                     AND cgmPersonUid = ?
        |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
        |                                 ORDER BY casTimestamp DESC
        |                                    LIMIT 1)
        |                                          
        |               LEFT JOIN CourseAssignmentMark
        |                      ON camUid = (SELECT camUid 
        |                                     FROM CourseAssignmentMark
        |                                    WHERE camAssignmentUid = ClazzAssignment.caUid
        |                                      AND camSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
        |                                                                          THEN ? 
        |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
        |                                                                          THEN 0 
        |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
        |                                                                          END) as submitterUid
        |                                                               FROM ClazzAssignment AS ref
        |                                                                    LEFT JOIN CourseGroupMember
        |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
        |                                                                     AND cgmPersonUid = ?
        |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
        |                                 ORDER BY camLct DESC
        |                                    LIMIT 1)       
        |         WHERE CourseBlock.cbClazzUid = ?
        |           AND CourseBlock.cbActive
        |           AND NOT CourseBlock.cbHidden
        |           AND ? > CourseBlock.cbHideUntilDate
        |           AND ? > COALESCE(parentBlock.cbHideUntilDate,0)
        |           AND CourseBlock.cbModuleParentBlockUid NOT IN (?)
        |      ORDER BY CourseBlock.cbIndex
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT * FROM (
        |         WITH CtePermissionCheck (hasPermission) 
        |            AS (SELECT EXISTS( 
        |               SELECT PrsGrpMbr.groupMemberPersonUid
        |                  FROM Clazz
        |                       
        |            JOIN ScopedGrant
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                                AND ScopedGrant.sgEntityUid = -2)
        |                            OR (ScopedGrant.sgTableId = 6
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
        |                            OR (ScopedGrant.sgTableId = 164
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
        |        
        |                    AND (ScopedGrant.sgPermissions & 
        |        
        |                          549755813888
        |                          
        |                                                       ) > 0
        |             JOIN PersonGroupMember AS PrsGrpMbr
        |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
        |        
        |                 WHERE Clazz.clazzUid = ?
        |                   AND PrsGrpMbr.groupMemberPersonUid = ?)), 
        |                   
        |        
        |            SubmitterList (submitterId, assignmentUid)
        |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId,
        |                       ClazzAssignment.caUid AS assignmentUid
        |                  
        |                  FROM ClazzEnrolment
        |                  
        |                       JOIN Person 
        |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
        |                        
        |                       JOIN ClazzAssignment
        |                       ON ClazzAssignment.caClazzUid = ?
        |
        |                       JOIN CourseBlock
        |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
        |                       AND CourseBlock.cbType = 103
        |                       
        |                 WHERE ClazzAssignment.caGroupUid = 0
        |                   AND clazzEnrolmentClazzUid = ?
        |                   AND clazzEnrolmentActive
        |                   AND clazzEnrolmentRole = 1000
        |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
        |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
        |              GROUP BY submitterId, assignmentUid
        |            UNION                 
        |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
        |                    ClazzAssignment.caUid AS assignmentUid
        |               FROM CourseGroupMember
        |                    JOIN ClazzAssignment
        |                    ON ClazzAssignment.caClazzUid = ?
        |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
        |                AND ClazzAssignment.caGroupUid != 0
        |                AND CourseGroupMember.cgmGroupNumber != 0
        |           GROUP BY submitterId, assignmentUid
        |            )
        |                   
        |                   
        |
        |        SELECT CourseBlock.*, ClazzAssignment.*, ContentEntry.*, CourseDiscussion.*, ContentEntryParentChildJoin.*, 
        |               Container.*, CourseAssignmentMark.*, (CourseBlock.cbUid NOT IN (?)) AS expanded,
        |               
        |               COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                
        |                0 AS assignmentContentWeight,
        |                1 as totalContent, 
        |                0 as penalty,
        |                
        |                (SELECT hasPermission FROM CtePermissionCheck) AS hasMetricsPermission,
        |                
        |             
        |                 (SELECT COUNT(*) 
        |                    FROM SubmitterList 
        |                   WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid) 
        |                        AS totalStudents, 
        | 
        |               0 AS notSubmittedStudents,
        |               
        |               (CASE WHEN (SELECT hasPermission 
        |                          FROM CtePermissionCheck)
        |                     THEN (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
        |                             FROM CourseAssignmentSubmission
        |                                   LEFT JOIN CourseAssignmentMark
        |                                   ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
        |                                   AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
        |                            WHERE CourseAssignmentMark.camUid IS NULL
        |                              AND CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
        |                              AND CourseAssignmentSubmission.casSubmitterUid IN 
        |                                                    (SELECT submitterId 
        |                                                      FROM SubmitterList
        |                                                     WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))  
        |                      ELSE 0 END) AS submittedStudents,         
        |               
        |                (CASE WHEN (SELECT hasPermission 
        |                           FROM CtePermissionCheck)       
        |                   THEN (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
        |                           FROM CourseAssignmentMark
        |                            
        |                             JOIN CourseAssignmentSubmission
        |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
        |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
        |                             
        |                          WHERE CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid 
        |                            AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
        |                                                                            FROM SubmitterList
        |                                                                           WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))
        |                   ELSE 0 END) AS markedStudents,
        |                   
        |                   COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
        |                          THEN 2 
        |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
        |                          THEN 1 
        |                          ELSE 0 END), 
        |                               0) AS fileSubmissionStatus
        |                
        |                
        |          FROM CourseBlock 
        |          
        |               LEFT JOIN CourseBlock AS parentBlock
        |               ON CourseBlock.cbModuleParentBlockUid = parentBlock.cbUid
        |               AND CourseBlock.cbTYpe != 100
        |          
        |               LEFT JOIN ClazzAssignment
        |               ON ClazzAssignment.caUid = CourseBlock.cbEntityUid
        |               AND CourseBlock.cbType = 103
        |               
        |               LEFT JOIN ContentEntry
        |               ON ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
        |               AND NOT ceInactive
        |               AND CourseBlock.cbType = 104
        |               
        |               LEFT JOIN CourseDiscussion 
        |                      ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
        |                     AND CourseBlock.cbType = 105
        |               
        |               LEFT JOIN ContentEntryParentChildJoin 
        |               ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
        |               
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)
        |                       
        |              LEFT JOIN StatementEntity
        |				     ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, 
        |                                        extensionProgress DESC, 
        |                                        resultSuccess DESC 
        |                                  LIMIT 1) 
        |                                  
        |               LEFT JOIN CourseAssignmentSubmission
        |                ON casUid = (SELECT casUid 
        |                                     FROM CourseAssignmentSubmission
        |                                    WHERE casAssignmentUid = ClazzAssignment.caUid
        |                                      AND casSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
        |                                                                          THEN ? 
        |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
        |                                                                          THEN 0 
        |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
        |                                                                          END) as submitterUid
        |                                                               FROM ClazzAssignment AS ref
        |                                                                    LEFT JOIN CourseGroupMember
        |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
        |                                                                     AND cgmPersonUid = ?
        |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
        |                                 ORDER BY casTimestamp DESC
        |                                    LIMIT 1)
        |                                          
        |               LEFT JOIN CourseAssignmentMark
        |                      ON camUid = (SELECT camUid 
        |                                     FROM CourseAssignmentMark
        |                                    WHERE camAssignmentUid = ClazzAssignment.caUid
        |                                      AND camSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
        |                                                                          THEN ? 
        |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
        |                                                                          THEN 0 
        |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
        |                                                                          END) as submitterUid
        |                                                               FROM ClazzAssignment AS ref
        |                                                                    LEFT JOIN CourseGroupMember
        |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
        |                                                                     AND cgmPersonUid = ?
        |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
        |                                 ORDER BY camLct DESC
        |                                    LIMIT 1)       
        |         WHERE CourseBlock.cbClazzUid = ?
        |           AND CourseBlock.cbActive
        |           AND NOT CourseBlock.cbHidden
        |           AND ? > CourseBlock.cbHideUntilDate
        |           AND ? > COALESCE(parentBlock.cbHideUntilDate,0)
        |           AND CourseBlock.cbModuleParentBlockUid NOT IN (?)
        |      ORDER BY CourseBlock.cbIndex
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.setLong(2, personUid)
          _stmt.setLong(3, clazzUid)
          _stmt.setLong(4, clazzUid)
          _stmt.setLong(5, clazzUid)
          _stmt.setArray(6, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              collapseList.toTypedArray()))
          _stmt.setLong(7, personUid)
          _stmt.setLong(8, personUid)
          _stmt.setLong(9, personUid)
          _stmt.setLong(10, personUid)
          _stmt.setLong(11, personUid)
          _stmt.setLong(12, clazzUid)
          _stmt.setLong(13, currentTime)
          _stmt.setLong(14, currentTime)
          _stmt.setArray(15, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              collapseList.toTypedArray()))
          _stmt.setInt(16, _limit)
          _stmt.setInt(17, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_expanded = _resultSet.getBoolean("expanded")
              val tmp_cbUid = _resultSet.getLong("cbUid")
              val tmp_cbType = _resultSet.getInt("cbType")
              val tmp_cbIndentLevel = _resultSet.getInt("cbIndentLevel")
              val tmp_cbModuleParentBlockUid = _resultSet.getLong("cbModuleParentBlockUid")
              val tmp_cbTitle = _resultSet.getString("cbTitle")
              val tmp_cbDescription = _resultSet.getString("cbDescription")
              val tmp_cbCompletionCriteria = _resultSet.getInt("cbCompletionCriteria")
              val tmp_cbHideUntilDate = _resultSet.getLong("cbHideUntilDate")
              val tmp_cbDeadlineDate = _resultSet.getLong("cbDeadlineDate")
              val tmp_cbLateSubmissionPenalty = _resultSet.getInt("cbLateSubmissionPenalty")
              val tmp_cbGracePeriodDate = _resultSet.getLong("cbGracePeriodDate")
              val tmp_cbMaxPoints = _resultSet.getInt("cbMaxPoints")
              val tmp_cbMinPoints = _resultSet.getInt("cbMinPoints")
              val tmp_cbIndex = _resultSet.getInt("cbIndex")
              val tmp_cbClazzUid = _resultSet.getLong("cbClazzUid")
              val tmp_cbActive = _resultSet.getBoolean("cbActive")
              val tmp_cbHidden = _resultSet.getBoolean("cbHidden")
              val tmp_cbEntityUid = _resultSet.getLong("cbEntityUid")
              val tmp_cbLct = _resultSet.getLong("cbLct")
              val _entity = CourseBlockWithCompleteEntity()
              _entity.expanded = tmp_expanded
              _entity.cbUid = tmp_cbUid
              _entity.cbType = tmp_cbType
              _entity.cbIndentLevel = tmp_cbIndentLevel
              _entity.cbModuleParentBlockUid = tmp_cbModuleParentBlockUid
              _entity.cbTitle = tmp_cbTitle
              _entity.cbDescription = tmp_cbDescription
              _entity.cbCompletionCriteria = tmp_cbCompletionCriteria
              _entity.cbHideUntilDate = tmp_cbHideUntilDate
              _entity.cbDeadlineDate = tmp_cbDeadlineDate
              _entity.cbLateSubmissionPenalty = tmp_cbLateSubmissionPenalty
              _entity.cbGracePeriodDate = tmp_cbGracePeriodDate
              _entity.cbMaxPoints = tmp_cbMaxPoints
              _entity.cbMinPoints = tmp_cbMinPoints
              _entity.cbIndex = tmp_cbIndex
              _entity.cbClazzUid = tmp_cbClazzUid
              _entity.cbActive = tmp_cbActive
              _entity.cbHidden = tmp_cbHidden
              _entity.cbEntityUid = tmp_cbEntityUid
              _entity.cbLct = tmp_cbLct
              var _assignment_nullFieldCount = 0
              val tmp_fileSubmissionStatus = _resultSet.getInt("fileSubmissionStatus")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caUid = _resultSet.getLong("caUid")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caTitle = _resultSet.getString("caTitle")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caDescription = _resultSet.getString("caDescription")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caGroupUid = _resultSet.getLong("caGroupUid")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caActive = _resultSet.getBoolean("caActive")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caClassCommentEnabled = _resultSet.getBoolean("caClassCommentEnabled")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caPrivateCommentsEnabled = _resultSet.getBoolean("caPrivateCommentsEnabled")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caCompletionCriteria = _resultSet.getInt("caCompletionCriteria")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caRequireFileSubmission = _resultSet.getBoolean("caRequireFileSubmission")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caFileType = _resultSet.getInt("caFileType")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caSizeLimit = _resultSet.getInt("caSizeLimit")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caNumberOfFiles = _resultSet.getInt("caNumberOfFiles")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caSubmissionPolicy = _resultSet.getInt("caSubmissionPolicy")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caMarkingType = _resultSet.getInt("caMarkingType")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caRequireTextSubmission = _resultSet.getBoolean("caRequireTextSubmission")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caTextLimitType = _resultSet.getInt("caTextLimitType")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caTextLimit = _resultSet.getInt("caTextLimit")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caXObjectUid = _resultSet.getLong("caXObjectUid")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caClazzUid = _resultSet.getLong("caClazzUid")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caLocalChangeSeqNum = _resultSet.getLong("caLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caMasterChangeSeqNum = _resultSet.getLong("caMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caLastChangedBy = _resultSet.getInt("caLastChangedBy")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              val tmp_caLct = _resultSet.getLong("caLct")
              if(_resultSet.wasNull()) { _assignment_nullFieldCount++ }
              if(_assignment_nullFieldCount < 26) {
                if(_entity.assignment == null) {
                  _entity.assignment = ClazzAssignmentWithMetrics()
                }
                _entity.assignment!!.fileSubmissionStatus = tmp_fileSubmissionStatus
                _entity.assignment!!.caUid = tmp_caUid
                _entity.assignment!!.caTitle = tmp_caTitle
                _entity.assignment!!.caDescription = tmp_caDescription
                _entity.assignment!!.caGroupUid = tmp_caGroupUid
                _entity.assignment!!.caActive = tmp_caActive
                _entity.assignment!!.caClassCommentEnabled = tmp_caClassCommentEnabled
                _entity.assignment!!.caPrivateCommentsEnabled = tmp_caPrivateCommentsEnabled
                _entity.assignment!!.caCompletionCriteria = tmp_caCompletionCriteria
                _entity.assignment!!.caRequireFileSubmission = tmp_caRequireFileSubmission
                _entity.assignment!!.caFileType = tmp_caFileType
                _entity.assignment!!.caSizeLimit = tmp_caSizeLimit
                _entity.assignment!!.caNumberOfFiles = tmp_caNumberOfFiles
                _entity.assignment!!.caSubmissionPolicy = tmp_caSubmissionPolicy
                _entity.assignment!!.caMarkingType = tmp_caMarkingType
                _entity.assignment!!.caRequireTextSubmission = tmp_caRequireTextSubmission
                _entity.assignment!!.caTextLimitType = tmp_caTextLimitType
                _entity.assignment!!.caTextLimit = tmp_caTextLimit
                _entity.assignment!!.caXObjectUid = tmp_caXObjectUid
                _entity.assignment!!.caClazzUid = tmp_caClazzUid
                _entity.assignment!!.caLocalChangeSeqNum = tmp_caLocalChangeSeqNum
                _entity.assignment!!.caMasterChangeSeqNum = tmp_caMasterChangeSeqNum
                _entity.assignment!!.caLastChangedBy = tmp_caLastChangedBy
                _entity.assignment!!.caLct = tmp_caLct
              }
              var _progressSummary_nullFieldCount = 0
              val tmp_notSubmittedStudents = _resultSet.getInt("notSubmittedStudents")
              if(_resultSet.wasNull()) { _progressSummary_nullFieldCount++ }
              val tmp_submittedStudents = _resultSet.getInt("submittedStudents")
              if(_resultSet.wasNull()) { _progressSummary_nullFieldCount++ }
              val tmp_totalStudents = _resultSet.getInt("totalStudents")
              if(_resultSet.wasNull()) { _progressSummary_nullFieldCount++ }
              val tmp_markedStudents = _resultSet.getInt("markedStudents")
              if(_resultSet.wasNull()) { _progressSummary_nullFieldCount++ }
              val tmp_hasMetricsPermission = _resultSet.getBoolean("hasMetricsPermission")
              if(_resultSet.wasNull()) { _progressSummary_nullFieldCount++ }
              if(_progressSummary_nullFieldCount < 5) {
                if(_entity.assignment == null) {
                  _entity.assignment = ClazzAssignmentWithMetrics()
                }
                if(_entity.assignment!!.progressSummary == null) {
                  _entity.assignment!!.progressSummary = AssignmentProgressSummary()
                }
                _entity.assignment!!.progressSummary!!.notSubmittedStudents =
                    tmp_notSubmittedStudents
                _entity.assignment!!.progressSummary!!.submittedStudents = tmp_submittedStudents
                _entity.assignment!!.progressSummary!!.totalStudents = tmp_totalStudents
                _entity.assignment!!.progressSummary!!.markedStudents = tmp_markedStudents
                _entity.assignment!!.progressSummary!!.hasMetricsPermission =
                    tmp_hasMetricsPermission
              }
              var _mark_nullFieldCount = 0
              val tmp_camUid = _resultSet.getLong("camUid")
              if(_resultSet.wasNull()) { _mark_nullFieldCount++ }
              val tmp_camAssignmentUid = _resultSet.getLong("camAssignmentUid")
              if(_resultSet.wasNull()) { _mark_nullFieldCount++ }
              val tmp_camSubmitterUid = _resultSet.getLong("camSubmitterUid")
              if(_resultSet.wasNull()) { _mark_nullFieldCount++ }
              val tmp_camMark = _resultSet.getFloat("camMark")
              if(_resultSet.wasNull()) { _mark_nullFieldCount++ }
              val tmp_camPenalty = _resultSet.getInt("camPenalty")
              if(_resultSet.wasNull()) { _mark_nullFieldCount++ }
              val tmp_camLct = _resultSet.getLong("camLct")
              if(_resultSet.wasNull()) { _mark_nullFieldCount++ }
              if(_mark_nullFieldCount < 6) {
                if(_entity.assignment == null) {
                  _entity.assignment = ClazzAssignmentWithMetrics()
                }
                if(_entity.assignment!!.mark == null) {
                  _entity.assignment!!.mark = CourseAssignmentMark()
                }
                _entity.assignment!!.mark!!.camUid = tmp_camUid
                _entity.assignment!!.mark!!.camAssignmentUid = tmp_camAssignmentUid
                _entity.assignment!!.mark!!.camSubmitterUid = tmp_camSubmitterUid
                _entity.assignment!!.mark!!.camMark = tmp_camMark
                _entity.assignment!!.mark!!.camPenalty = tmp_camPenalty
                _entity.assignment!!.mark!!.camLct = tmp_camLct
              }
              var _entry_nullFieldCount = 0
              val tmp_assignmentContentWeight = _resultSet.getInt("assignmentContentWeight")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_title = _resultSet.getString("title")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_description = _resultSet.getString("description")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_entryId = _resultSet.getString("entryId")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_author = _resultSet.getString("author")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_publisher = _resultSet.getString("publisher")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_licenseType = _resultSet.getInt("licenseType")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_licenseName = _resultSet.getString("licenseName")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_licenseUrl = _resultSet.getString("licenseUrl")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_sourceUrl = _resultSet.getString("sourceUrl")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_lastModified = _resultSet.getLong("lastModified")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_contentFlags = _resultSet.getInt("contentFlags")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_leaf = _resultSet.getBoolean("leaf")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_publik = _resultSet.getBoolean("publik")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_minScore = _resultSet.getInt("minScore")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_contentOwner = _resultSet.getLong("contentOwner")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_contentEntryLocalChangeSeqNum =
                  _resultSet.getLong("contentEntryLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_contentEntryMasterChangeSeqNum =
                  _resultSet.getLong("contentEntryMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
              if(_resultSet.wasNull()) { _entry_nullFieldCount++ }
              if(_entry_nullFieldCount < 30) {
                if(_entity.entry == null) {
                  _entity.entry = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
                }
                _entity.entry!!.assignmentContentWeight = tmp_assignmentContentWeight
                _entity.entry!!.contentEntryUid = tmp_contentEntryUid
                _entity.entry!!.title = tmp_title
                _entity.entry!!.description = tmp_description
                _entity.entry!!.entryId = tmp_entryId
                _entity.entry!!.author = tmp_author
                _entity.entry!!.publisher = tmp_publisher
                _entity.entry!!.licenseType = tmp_licenseType
                _entity.entry!!.licenseName = tmp_licenseName
                _entity.entry!!.licenseUrl = tmp_licenseUrl
                _entity.entry!!.sourceUrl = tmp_sourceUrl
                _entity.entry!!.thumbnailUrl = tmp_thumbnailUrl
                _entity.entry!!.lastModified = tmp_lastModified
                _entity.entry!!.primaryLanguageUid = tmp_primaryLanguageUid
                _entity.entry!!.languageVariantUid = tmp_languageVariantUid
                _entity.entry!!.contentFlags = tmp_contentFlags
                _entity.entry!!.leaf = tmp_leaf
                _entity.entry!!.publik = tmp_publik
                _entity.entry!!.ceInactive = tmp_ceInactive
                _entity.entry!!.completionCriteria = tmp_completionCriteria
                _entity.entry!!.minScore = tmp_minScore
                _entity.entry!!.contentTypeFlag = tmp_contentTypeFlag
                _entity.entry!!.contentOwner = tmp_contentOwner
                _entity.entry!!.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
                _entity.entry!!.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
                _entity.entry!!.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
                _entity.entry!!.contentEntryLct = tmp_contentEntryLct
              }
              var _mostRecentContainer_nullFieldCount = 0
              val tmp_containerUid = _resultSet.getLong("containerUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLct = _resultSet.getLong("cntLct")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_fileSize = _resultSet.getLong("fileSize")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mimeType = _resultSet.getString("mimeType")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_remarks = _resultSet.getString("remarks")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              if(_mostRecentContainer_nullFieldCount < 12) {
                if(_entity.entry == null) {
                  _entity.entry = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
                }
                if(_entity.entry!!.mostRecentContainer == null) {
                  _entity.entry!!.mostRecentContainer = Container()
                }
                _entity.entry!!.mostRecentContainer!!.containerUid = tmp_containerUid
                _entity.entry!!.mostRecentContainer!!.cntLocalCsn = tmp_cntLocalCsn
                _entity.entry!!.mostRecentContainer!!.cntMasterCsn = tmp_cntMasterCsn
                _entity.entry!!.mostRecentContainer!!.cntLastModBy = tmp_cntLastModBy
                _entity.entry!!.mostRecentContainer!!.cntLct = tmp_cntLct
                _entity.entry!!.mostRecentContainer!!.fileSize = tmp_fileSize
                _entity.entry!!.mostRecentContainer!!.containerContentEntryUid =
                    tmp_containerContentEntryUid
                _entity.entry!!.mostRecentContainer!!.cntLastModified = tmp_cntLastModified
                _entity.entry!!.mostRecentContainer!!.mimeType = tmp_mimeType
                _entity.entry!!.mostRecentContainer!!.remarks = tmp_remarks
                _entity.entry!!.mostRecentContainer!!.mobileOptimized = tmp_mobileOptimized
                _entity.entry!!.mostRecentContainer!!.cntNumEntries = tmp_cntNumEntries
              }
              var _contentEntryParentChildJoin_nullFieldCount = 0
              val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_childIndex = _resultSet.getInt("childIndex")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              if(_contentEntryParentChildJoin_nullFieldCount < 8) {
                if(_entity.entry == null) {
                  _entity.entry = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
                }
                if(_entity.entry!!.contentEntryParentChildJoin == null) {
                  _entity.entry!!.contentEntryParentChildJoin = ContentEntryParentChildJoin()
                }
                _entity.entry!!.contentEntryParentChildJoin!!.cepcjParentContentEntryUid =
                    tmp_cepcjParentContentEntryUid
                _entity.entry!!.contentEntryParentChildJoin!!.cepcjChildContentEntryUid =
                    tmp_cepcjChildContentEntryUid
                _entity.entry!!.contentEntryParentChildJoin!!.childIndex = tmp_childIndex
                _entity.entry!!.contentEntryParentChildJoin!!.cepcjUid = tmp_cepcjUid
                _entity.entry!!.contentEntryParentChildJoin!!.cepcjLocalChangeSeqNum =
                    tmp_cepcjLocalChangeSeqNum
                _entity.entry!!.contentEntryParentChildJoin!!.cepcjMasterChangeSeqNum =
                    tmp_cepcjMasterChangeSeqNum
                _entity.entry!!.contentEntryParentChildJoin!!.cepcjLastChangedBy =
                    tmp_cepcjLastChangedBy
                _entity.entry!!.contentEntryParentChildJoin!!.cepcjLct = tmp_cepcjLct
              }
              var _scoreProgress_nullFieldCount = 0
              val tmp_resultScore = _resultSet.getInt("resultScore")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultMax = _resultSet.getInt("resultMax")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultScaled = _resultSet.getFloat("resultScaled")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultWeight = _resultSet.getInt("resultWeight")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_progress = _resultSet.getInt("progress")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_success = _resultSet.getByte("success")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_penalty = _resultSet.getInt("penalty")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalContent = _resultSet.getInt("totalContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              if(_scoreProgress_nullFieldCount < 10) {
                if(_entity.entry == null) {
                  _entity.entry = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
                }
                if(_entity.entry!!.scoreProgress == null) {
                  _entity.entry!!.scoreProgress = ContentEntryStatementScoreProgress()
                }
                _entity.entry!!.scoreProgress!!.resultScore = tmp_resultScore
                _entity.entry!!.scoreProgress!!.resultMax = tmp_resultMax
                _entity.entry!!.scoreProgress!!.resultScaled = tmp_resultScaled
                _entity.entry!!.scoreProgress!!.resultWeight = tmp_resultWeight
                _entity.entry!!.scoreProgress!!.contentComplete = tmp_contentComplete
                _entity.entry!!.scoreProgress!!.progress = tmp_progress
                _entity.entry!!.scoreProgress!!.success = tmp_success
                _entity.entry!!.scoreProgress!!.penalty = tmp_penalty
                _entity.entry!!.scoreProgress!!.totalContent = tmp_totalContent
                _entity.entry!!.scoreProgress!!.totalCompletedContent = tmp_totalCompletedContent
              }
              var _courseDiscussion_nullFieldCount = 0
              val tmp_courseDiscussionUid = _resultSet.getLong("courseDiscussionUid")
              if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
              val tmp_courseDiscussionTitle = _resultSet.getString("courseDiscussionTitle")
              if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
              val tmp_courseDiscussionDesc = _resultSet.getString("courseDiscussionDesc")
              if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
              val tmp_courseDiscussionClazzUid = _resultSet.getLong("courseDiscussionClazzUid")
              if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
              val tmp_courseDiscussionActive = _resultSet.getBoolean("courseDiscussionActive")
              if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
              val tmp_courseDiscussionLct = _resultSet.getLong("courseDiscussionLct")
              if(_resultSet.wasNull()) { _courseDiscussion_nullFieldCount++ }
              if(_courseDiscussion_nullFieldCount < 6) {
                if(_entity.courseDiscussion == null) {
                  _entity.courseDiscussion = CourseDiscussion()
                }
                _entity.courseDiscussion!!.courseDiscussionUid = tmp_courseDiscussionUid
                _entity.courseDiscussion!!.courseDiscussionTitle = tmp_courseDiscussionTitle
                _entity.courseDiscussion!!.courseDiscussionDesc = tmp_courseDiscussionDesc
                _entity.courseDiscussion!!.courseDiscussionClazzUid = tmp_courseDiscussionClazzUid
                _entity.courseDiscussion!!.courseDiscussionActive = tmp_courseDiscussionActive
                _entity.courseDiscussion!!.courseDiscussionLct = tmp_courseDiscussionLct
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("CourseBlock", "ClazzAssignment", "CourseDiscussion", "ContentEntry",
          "CourseAssignmentMark", "StatementEntity", "Container", "ContentEntryParentChildJoin",
          "PersonGroupMember", "Clazz", "ScopedGrant", "ClazzEnrolment",
          "CourseAssignmentSubmission", "CourseGroupMember"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |         WITH CtePermissionCheck (hasPermission) 
        |            AS (SELECT EXISTS( 
        |               SELECT PrsGrpMbr.groupMemberPersonUid
        |                  FROM Clazz
        |                       
        |            JOIN ScopedGrant
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                                AND ScopedGrant.sgEntityUid = -2)
        |                            OR (ScopedGrant.sgTableId = 6
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
        |                            OR (ScopedGrant.sgTableId = 164
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
        |        
        |                    AND (ScopedGrant.sgPermissions & 
        |        
        |                          549755813888
        |                          
        |                                                       ) > 0
        |             JOIN PersonGroupMember AS PrsGrpMbr
        |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
        |        
        |                 WHERE Clazz.clazzUid = ?
        |                   AND PrsGrpMbr.groupMemberPersonUid = ?)), 
        |                   
        |        
        |            SubmitterList (submitterId, assignmentUid)
        |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId,
        |                       ClazzAssignment.caUid AS assignmentUid
        |                  
        |                  FROM ClazzEnrolment
        |                  
        |                       JOIN Person 
        |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
        |                        
        |                       JOIN ClazzAssignment
        |                       ON ClazzAssignment.caClazzUid = ?
        |
        |                       JOIN CourseBlock
        |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
        |                       AND CourseBlock.cbType = 103
        |                       
        |                 WHERE ClazzAssignment.caGroupUid = 0
        |                   AND clazzEnrolmentClazzUid = ?
        |                   AND clazzEnrolmentActive
        |                   AND clazzEnrolmentRole = 1000
        |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
        |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
        |              GROUP BY submitterId, assignmentUid
        |            UNION                 
        |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
        |                    ClazzAssignment.caUid AS assignmentUid
        |               FROM CourseGroupMember
        |                    JOIN ClazzAssignment
        |                    ON ClazzAssignment.caClazzUid = ?
        |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
        |                AND ClazzAssignment.caGroupUid != 0
        |                AND CourseGroupMember.cgmGroupNumber != 0
        |           GROUP BY submitterId, assignmentUid
        |            )
        |                   
        |                   
        |
        |        SELECT CourseBlock.*, ClazzAssignment.*, ContentEntry.*, CourseDiscussion.*, ContentEntryParentChildJoin.*, 
        |               Container.*, CourseAssignmentMark.*, (CourseBlock.cbUid NOT IN (?)) AS expanded,
        |               
        |               COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                
        |                0 AS assignmentContentWeight,
        |                1 as totalContent, 
        |                0 as penalty,
        |                
        |                (SELECT hasPermission FROM CtePermissionCheck) AS hasMetricsPermission,
        |                
        |             
        |                 (SELECT COUNT(*) 
        |                    FROM SubmitterList 
        |                   WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid) 
        |                        AS totalStudents, 
        | 
        |               0 AS notSubmittedStudents,
        |               
        |               (CASE WHEN (SELECT hasPermission 
        |                          FROM CtePermissionCheck)
        |                     THEN (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
        |                             FROM CourseAssignmentSubmission
        |                                   LEFT JOIN CourseAssignmentMark
        |                                   ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
        |                                   AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
        |                            WHERE CourseAssignmentMark.camUid IS NULL
        |                              AND CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
        |                              AND CourseAssignmentSubmission.casSubmitterUid IN 
        |                                                    (SELECT submitterId 
        |                                                      FROM SubmitterList
        |                                                     WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))  
        |                      ELSE 0 END) AS submittedStudents,         
        |               
        |                (CASE WHEN (SELECT hasPermission 
        |                           FROM CtePermissionCheck)       
        |                   THEN (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
        |                           FROM CourseAssignmentMark
        |                            
        |                             JOIN CourseAssignmentSubmission
        |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
        |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
        |                             
        |                          WHERE CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid 
        |                            AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
        |                                                                            FROM SubmitterList
        |                                                                           WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))
        |                   ELSE 0 END) AS markedStudents,
        |                   
        |                   COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
        |                          THEN 2 
        |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
        |                          THEN 1 
        |                          ELSE 0 END), 
        |                               0) AS fileSubmissionStatus
        |                
        |                
        |          FROM CourseBlock 
        |          
        |               LEFT JOIN CourseBlock AS parentBlock
        |               ON CourseBlock.cbModuleParentBlockUid = parentBlock.cbUid
        |               AND CourseBlock.cbTYpe != 100
        |          
        |               LEFT JOIN ClazzAssignment
        |               ON ClazzAssignment.caUid = CourseBlock.cbEntityUid
        |               AND CourseBlock.cbType = 103
        |               
        |               LEFT JOIN ContentEntry
        |               ON ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
        |               AND NOT ceInactive
        |               AND CourseBlock.cbType = 104
        |               
        |               LEFT JOIN CourseDiscussion 
        |                      ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
        |                     AND CourseBlock.cbType = 105
        |               
        |               LEFT JOIN ContentEntryParentChildJoin 
        |               ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
        |               
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)
        |                       
        |              LEFT JOIN StatementEntity
        |				     ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, 
        |                                        extensionProgress DESC, 
        |                                        resultSuccess DESC 
        |                                  LIMIT 1) 
        |                                  
        |               LEFT JOIN CourseAssignmentSubmission
        |                ON casUid = (SELECT casUid 
        |                                     FROM CourseAssignmentSubmission
        |                                    WHERE casAssignmentUid = ClazzAssignment.caUid
        |                                      AND casSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
        |                                                                          THEN ? 
        |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
        |                                                                          THEN 0 
        |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
        |                                                                          END) as submitterUid
        |                                                               FROM ClazzAssignment AS ref
        |                                                                    LEFT JOIN CourseGroupMember
        |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
        |                                                                     AND cgmPersonUid = ?
        |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
        |                                 ORDER BY casTimestamp DESC
        |                                    LIMIT 1)
        |                                          
        |               LEFT JOIN CourseAssignmentMark
        |                      ON camUid = (SELECT camUid 
        |                                     FROM CourseAssignmentMark
        |                                    WHERE camAssignmentUid = ClazzAssignment.caUid
        |                                      AND camSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
        |                                                                          THEN ? 
        |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
        |                                                                          THEN 0 
        |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
        |                                                                          END) as submitterUid
        |                                                               FROM ClazzAssignment AS ref
        |                                                                    LEFT JOIN CourseGroupMember
        |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
        |                                                                     AND cgmPersonUid = ?
        |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
        |                                 ORDER BY camLct DESC
        |                                    LIMIT 1)       
        |         WHERE CourseBlock.cbClazzUid = ?
        |           AND CourseBlock.cbActive
        |           AND NOT CourseBlock.cbHidden
        |           AND ? > CourseBlock.cbHideUntilDate
        |           AND ? > COALESCE(parentBlock.cbHideUntilDate,0)
        |           AND CourseBlock.cbModuleParentBlockUid NOT IN (?)
        |      ORDER BY CourseBlock.cbIndex
        |    ) 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT COUNT(*) FROM (
        |         WITH CtePermissionCheck (hasPermission) 
        |            AS (SELECT EXISTS( 
        |               SELECT PrsGrpMbr.groupMemberPersonUid
        |                  FROM Clazz
        |                       
        |            JOIN ScopedGrant
        |                 ON 
        |            ((ScopedGrant.sgTableId = -2
        |                                AND ScopedGrant.sgEntityUid = -2)
        |                            OR (ScopedGrant.sgTableId = 6
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
        |                            OR (ScopedGrant.sgTableId = 164
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
        |        
        |                    AND (ScopedGrant.sgPermissions & 
        |        
        |                          549755813888
        |                          
        |                                                       ) > 0
        |             JOIN PersonGroupMember AS PrsGrpMbr
        |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
        |        
        |                 WHERE Clazz.clazzUid = ?
        |                   AND PrsGrpMbr.groupMemberPersonUid = ?)), 
        |                   
        |        
        |            SubmitterList (submitterId, assignmentUid)
        |            AS (SELECT DISTINCT ClazzEnrolment.clazzEnrolmentPersonUid AS submitterId,
        |                       ClazzAssignment.caUid AS assignmentUid
        |                  
        |                  FROM ClazzEnrolment
        |                  
        |                       JOIN Person 
        |                       ON Person.personUid = ClazzEnrolment.clazzEnrolmentPersonUid
        |                        
        |                       JOIN ClazzAssignment
        |                       ON ClazzAssignment.caClazzUid = ?
        |
        |                       JOIN CourseBlock
        |                       ON CourseBlock.cbEntityUid = ClazzAssignment.caUid
        |                       AND CourseBlock.cbType = 103
        |                       
        |                 WHERE ClazzAssignment.caGroupUid = 0
        |                   AND clazzEnrolmentClazzUid = ?
        |                   AND clazzEnrolmentActive
        |                   AND clazzEnrolmentRole = 1000
        |                   AND CourseBlock.cbGracePeriodDate <= ClazzEnrolment.clazzEnrolmentDateLeft
        |                   AND ClazzEnrolment.clazzEnrolmentDateJoined <= CourseBlock.cbGracePeriodDate
        |              GROUP BY submitterId, assignmentUid
        |            UNION                 
        |             SELECT DISTINCT CourseGroupMember.cgmGroupNumber AS submitterId,
        |                    ClazzAssignment.caUid AS assignmentUid
        |               FROM CourseGroupMember
        |                    JOIN ClazzAssignment
        |                    ON ClazzAssignment.caClazzUid = ?
        |              WHERE CourseGroupMember.cgmSetUid = ClazzAssignment.caGroupUid
        |                AND ClazzAssignment.caGroupUid != 0
        |                AND CourseGroupMember.cgmGroupNumber != 0
        |           GROUP BY submitterId, assignmentUid
        |            )
        |                   
        |                   
        |
        |        SELECT CourseBlock.*, ClazzAssignment.*, ContentEntry.*, CourseDiscussion.*, ContentEntryParentChildJoin.*, 
        |               Container.*, CourseAssignmentMark.*, (CourseBlock.cbUid NOT IN (?)) AS expanded,
        |               
        |               COALESCE(StatementEntity.resultScoreMax,0) AS resultMax, 
        |                COALESCE(StatementEntity.resultScoreRaw,0) AS resultScore, 
        |                COALESCE(StatementEntity.resultScoreScaled,0) AS resultScaled, 
        |                COALESCE(StatementEntity.extensionProgress,0) AS progress, 
        |                COALESCE(StatementEntity.resultCompletion,'FALSE') AS contentComplete,
        |                COALESCE(StatementEntity.resultSuccess, 0) AS success,
        |                
        |                COALESCE((CASE WHEN StatementEntity.resultCompletion 
        |                THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                
        |                0 AS assignmentContentWeight,
        |                1 as totalContent, 
        |                0 as penalty,
        |                
        |                (SELECT hasPermission FROM CtePermissionCheck) AS hasMetricsPermission,
        |                
        |             
        |                 (SELECT COUNT(*) 
        |                    FROM SubmitterList 
        |                   WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid) 
        |                        AS totalStudents, 
        | 
        |               0 AS notSubmittedStudents,
        |               
        |               (CASE WHEN (SELECT hasPermission 
        |                          FROM CtePermissionCheck)
        |                     THEN (SELECT COUNT(DISTINCT CourseAssignmentSubmission.casSubmitterUid) 
        |                             FROM CourseAssignmentSubmission
        |                                   LEFT JOIN CourseAssignmentMark
        |                                   ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
        |                                   AND CourseAssignmentMark.camAssignmentUid = CourseAssignmentSubmission.casAssignmentUid
        |                            WHERE CourseAssignmentMark.camUid IS NULL
        |                              AND CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
        |                              AND CourseAssignmentSubmission.casSubmitterUid IN 
        |                                                    (SELECT submitterId 
        |                                                      FROM SubmitterList
        |                                                     WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))  
        |                      ELSE 0 END) AS submittedStudents,         
        |               
        |                (CASE WHEN (SELECT hasPermission 
        |                           FROM CtePermissionCheck)       
        |                   THEN (SELECT COUNT(DISTINCT CourseAssignmentMark.camSubmitterUid) 
        |                           FROM CourseAssignmentMark
        |                            
        |                             JOIN CourseAssignmentSubmission
        |                             ON CourseAssignmentSubmission.casSubmitterUid = CourseAssignmentMark.camSubmitterUid
        |                             AND CourseAssignmentSubmission.casAssignmentUid = CourseAssignmentMark.camAssignmentUid
        |                             
        |                          WHERE CourseAssignmentMark.camAssignmentUid = ClazzAssignment.caUid 
        |                            AND CourseAssignmentMark.camSubmitterUid IN (SELECT submitterId 
        |                                                                            FROM SubmitterList
        |                                                                           WHERE SubmitterList.assignmentUid = ClazzAssignment.caUid))
        |                   ELSE 0 END) AS markedStudents,
        |                   
        |                   COALESCE((CASE WHEN CourseAssignmentMark.camUid IS NOT NULL 
        |                          THEN 2 
        |                          WHEN CourseAssignmentSubmission.casUid IS NOT NULL 
        |                          THEN 1 
        |                          ELSE 0 END), 
        |                               0) AS fileSubmissionStatus
        |                
        |                
        |          FROM CourseBlock 
        |          
        |               LEFT JOIN CourseBlock AS parentBlock
        |               ON CourseBlock.cbModuleParentBlockUid = parentBlock.cbUid
        |               AND CourseBlock.cbTYpe != 100
        |          
        |               LEFT JOIN ClazzAssignment
        |               ON ClazzAssignment.caUid = CourseBlock.cbEntityUid
        |               AND CourseBlock.cbType = 103
        |               
        |               LEFT JOIN ContentEntry
        |               ON ContentEntry.contentEntryUid = CourseBlock.cbEntityUid
        |               AND NOT ceInactive
        |               AND CourseBlock.cbType = 104
        |               
        |               LEFT JOIN CourseDiscussion 
        |                      ON CourseDiscussion.courseDiscussionUid = CourseBlock.cbEntityUid
        |                     AND CourseBlock.cbType = 105
        |               
        |               LEFT JOIN ContentEntryParentChildJoin 
        |               ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid
        |               
        |               LEFT JOIN Container 
        |                    ON Container.containerUid = 
        |                        (SELECT containerUid 
        |                           FROM Container 
        |                          WHERE containerContentEntryUid = ContentEntry.contentEntryUid 
        |                       ORDER BY cntLastModified DESC LIMIT 1)
        |                       
        |              LEFT JOIN StatementEntity
        |				     ON StatementEntity.statementUid = 
        |                                (SELECT statementUid 
        |							       FROM StatementEntity 
        |                                  WHERE statementContentEntryUid = ContentEntry.contentEntryUid 
        |							        AND StatementEntity.statementPersonUid = ?
        |							        AND contentEntryRoot 
        |                               ORDER BY resultScoreScaled DESC, 
        |                                        extensionProgress DESC, 
        |                                        resultSuccess DESC 
        |                                  LIMIT 1) 
        |                                  
        |               LEFT JOIN CourseAssignmentSubmission
        |                ON casUid = (SELECT casUid 
        |                                     FROM CourseAssignmentSubmission
        |                                    WHERE casAssignmentUid = ClazzAssignment.caUid
        |                                      AND casSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
        |                                                                          THEN ? 
        |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
        |                                                                          THEN 0 
        |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
        |                                                                          END) as submitterUid
        |                                                               FROM ClazzAssignment AS ref
        |                                                                    LEFT JOIN CourseGroupMember
        |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
        |                                                                     AND cgmPersonUid = ?
        |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
        |                                 ORDER BY casTimestamp DESC
        |                                    LIMIT 1)
        |                                          
        |               LEFT JOIN CourseAssignmentMark
        |                      ON camUid = (SELECT camUid 
        |                                     FROM CourseAssignmentMark
        |                                    WHERE camAssignmentUid = ClazzAssignment.caUid
        |                                      AND camSubmitterUid = (SELECT (CASE WHEN ref.caGroupUid = 0 
        |                                                                          THEN ? 
        |                                                                          WHEN CourseGroupMember.cgmUid IS NULL 
        |                                                                          THEN 0 
        |                                                                          ELSE CourseGroupMember.cgmGroupNumber 
        |                                                                          END) as submitterUid
        |                                                               FROM ClazzAssignment AS ref
        |                                                                    LEFT JOIN CourseGroupMember
        |                                                                     ON cgmSetUid = ClazzAssignment.caGroupUid
        |                                                                     AND cgmPersonUid = ?
        |                                                              WHERE ref.caUid = ClazzAssignment.caUid)
        |                                 ORDER BY camLct DESC
        |                                    LIMIT 1)       
        |         WHERE CourseBlock.cbClazzUid = ?
        |           AND CourseBlock.cbActive
        |           AND NOT CourseBlock.cbHidden
        |           AND ? > CourseBlock.cbHideUntilDate
        |           AND ? > COALESCE(parentBlock.cbHideUntilDate,0)
        |           AND CourseBlock.cbModuleParentBlockUid NOT IN (?)
        |      ORDER BY CourseBlock.cbIndex
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, clazzUid)
          _stmt.setLong(2, personUid)
          _stmt.setLong(3, clazzUid)
          _stmt.setLong(4, clazzUid)
          _stmt.setLong(5, clazzUid)
          _stmt.setArray(6, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              collapseList.toTypedArray()))
          _stmt.setLong(7, personUid)
          _stmt.setLong(8, personUid)
          _stmt.setLong(9, personUid)
          _stmt.setLong(10, personUid)
          _stmt.setLong(11, personUid)
          _stmt.setLong(12, clazzUid)
          _stmt.setLong(13, currentTime)
          _stmt.setLong(14, currentTime)
          _stmt.setArray(15, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              collapseList.toTypedArray()))
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun updateActiveByUid(
    cbUid: Long,
    active: Boolean,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE CourseBlock 
    |           SET cbActive = ?, 
    |               cbLct = ?
    |         WHERE cbUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE CourseBlock 
    |           SET cbActive = ?, 
    |               cbLct = ?
    |         WHERE cbUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, active)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, cbUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: CourseBlock): Long {
    val _retVal = _insertAdapterCourseBlock_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseBlock): Long {
    val _retVal = _insertAdapterCourseBlock_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CourseBlock>): Unit {
    _insertAdapterCourseBlock_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseBlock>): Unit {
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ? WHERE cbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setInt(1, _entity.cbType)
        _stmt.setInt(2, _entity.cbIndentLevel)
        _stmt.setLong(3, _entity.cbModuleParentBlockUid)
        _stmt.setString(4, _entity.cbTitle)
        _stmt.setString(5, _entity.cbDescription)
        _stmt.setInt(6, _entity.cbCompletionCriteria)
        _stmt.setLong(7, _entity.cbHideUntilDate)
        _stmt.setLong(8, _entity.cbDeadlineDate)
        _stmt.setInt(9, _entity.cbLateSubmissionPenalty)
        _stmt.setLong(10, _entity.cbGracePeriodDate)
        _stmt.setInt(11, _entity.cbMaxPoints)
        _stmt.setInt(12, _entity.cbMinPoints)
        _stmt.setInt(13, _entity.cbIndex)
        _stmt.setLong(14, _entity.cbClazzUid)
        _stmt.setBoolean(15, _entity.cbActive)
        _stmt.setBoolean(16, _entity.cbHidden)
        _stmt.setLong(17, _entity.cbEntityUid)
        _stmt.setLong(18, _entity.cbLct)
        _stmt.setLong(19, _entity.cbUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseBlock): Unit {
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ? WHERE cbUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setInt(1, entity.cbType)
      _stmt.setInt(2, entity.cbIndentLevel)
      _stmt.setLong(3, entity.cbModuleParentBlockUid)
      _stmt.setString(4, entity.cbTitle)
      _stmt.setString(5, entity.cbDescription)
      _stmt.setInt(6, entity.cbCompletionCriteria)
      _stmt.setLong(7, entity.cbHideUntilDate)
      _stmt.setLong(8, entity.cbDeadlineDate)
      _stmt.setInt(9, entity.cbLateSubmissionPenalty)
      _stmt.setLong(10, entity.cbGracePeriodDate)
      _stmt.setInt(11, entity.cbMaxPoints)
      _stmt.setInt(12, entity.cbMinPoints)
      _stmt.setInt(13, entity.cbIndex)
      _stmt.setLong(14, entity.cbClazzUid)
      _stmt.setBoolean(15, entity.cbActive)
      _stmt.setBoolean(16, entity.cbHidden)
      _stmt.setLong(17, entity.cbEntityUid)
      _stmt.setLong(18, entity.cbLct)
      _stmt.setLong(19, entity.cbUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out CourseBlock>): Unit {
    _insertAdapterCourseBlock_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out CourseBlock>): Unit {
    val _sql =
        "UPDATE CourseBlock SET cbType = ?, cbIndentLevel = ?, cbModuleParentBlockUid = ?, cbTitle = ?, cbDescription = ?, cbCompletionCriteria = ?, cbHideUntilDate = ?, cbDeadlineDate = ?, cbLateSubmissionPenalty = ?, cbGracePeriodDate = ?, cbMaxPoints = ?, cbMinPoints = ?, cbIndex = ?, cbClazzUid = ?, cbActive = ?, cbHidden = ?, cbEntityUid = ?, cbLct = ? WHERE cbUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setInt(1, _entity.cbType)
        _stmt.setInt(2, _entity.cbIndentLevel)
        _stmt.setLong(3, _entity.cbModuleParentBlockUid)
        _stmt.setString(4, _entity.cbTitle)
        _stmt.setString(5, _entity.cbDescription)
        _stmt.setInt(6, _entity.cbCompletionCriteria)
        _stmt.setLong(7, _entity.cbHideUntilDate)
        _stmt.setLong(8, _entity.cbDeadlineDate)
        _stmt.setInt(9, _entity.cbLateSubmissionPenalty)
        _stmt.setLong(10, _entity.cbGracePeriodDate)
        _stmt.setInt(11, _entity.cbMaxPoints)
        _stmt.setInt(12, _entity.cbMinPoints)
        _stmt.setInt(13, _entity.cbIndex)
        _stmt.setLong(14, _entity.cbClazzUid)
        _stmt.setBoolean(15, _entity.cbActive)
        _stmt.setBoolean(16, _entity.cbHidden)
        _stmt.setLong(17, _entity.cbEntityUid)
        _stmt.setLong(18, _entity.cbLct)
        _stmt.setLong(19, _entity.cbUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }
}
