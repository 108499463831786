package com.ustadmobile.core.generated.locale

/** DO NOT EDIT. GENERATED CODE */
object MessageID{

    const val cancel = 2018

    const val my_content = 2787

    const val sel_question_type_multiple_choice = 2640

    const val year = 2219

    const val upload = 2838

    const val saved = 2842

    const val no_video_file_found = 2096

    const val from_to_date = 2550

    const val score = 2190

    const val entry_details_license = 2169

    const val instructions_for_students = 2532

    const val absent = 2312

    const val not_recorded = 2454

    const val err_registering_new_user = 2003

    const val untitled = 2570

    const val last_month = 2359

    const val mark_complete = 2599

    const val shake_feedback = 2510

    const val assign_reviewers = 2962

    const val optional = 2063

    const val select_subtitle_video = 2094

    const val anyone_with_this_can_join_school = 2784

    const val public_comments = 2617

    const val folder = 2772

    const val strings_not_allowed = 2697

    const val parent_consent_explanation = 2720

    const val role = 2458

    const val weekly = 2345

    const val characters = 2869

    const val moved_x_entries = 2678

    const val connect_as_guest = 2632

    const val edit_assignment = 2586

    const val content_entry_export_message = 2182

    const val connect = 2694

    const val address = 2319

    const val no_subtitle = 2095

    const val clazz_work = 2581

    const val app_name = 2000

    const val edit_person = 2478

    const val bar_chart = 2188

    const val days = 2428

    const val add_or_edit_assignment = 2816

    const val download_cloud_availability = 2162

    const val register = 2044

    const val standard = 2920

    const val reports = 2071

    const val field_content_progress = 2252

    const val edit_module = 2886

    const val notes = 2364

    const val continue_using_link_recommended = 2740

    const val moved = 2375

    const val join_school = 2712

    const val content_entries_completed = 2769

    const val discussion_board = 2883

    const val condition_greater_than = 2256

    const val submission_already_made = 2950

    const val permission_activity_update = 2495

    const val add_verb_filter = 2262

    const val change_password = 2518

    const val institution = 2889

    const val attendance_percentage = 2450

    const val maximum_score = 2613

    const val grades_class_age = 2789

    const val add_topic = 2909

    const val post = 2940

    const val add_student_to_school = 2671

    const val edit_site = 2689

    const val deleting_content = 2754

    const val female = 2049

    const val offline_sharing_enable_bluetooth_prompt = 2280

    const val export = 2424

    const val total_content_usage_duration_class = 2832

    const val add = 2523

    const val add_staff_to_school = 2672

    const val content_editor_save_error = 2180

    const val select_item = 2549

    const val visibility = 2895

    const val add_a_new_person = 2477

    const val new_custom_date_range = 2216

    const val group_setting_desc = 2456

    const val article = 2108

    const val users = 2436

    const val role_name = 2470

    const val activity_enter_any_notes = 2410

    const val add_a_new_clazzwork = 2582

    const val more_information = 2650

    const val success = 2091

    const val join_code_instructions = 2676

    const val start_from_scratch = 2742

    const val permission_activity_select = 2493

    const val phone_number = 2045

    const val error_code = 2809

    const val send_feedback = 2512

    const val relative_date = 2126

    const val assign_to_random_groups = 2905

    const val roles = 2457

    const val use_the_public_lib_env = 2777

    const val content_creation_folder_new_message = 2175

    const val confirm_password = 2036

    const val edit_clazzes = 2820

    const val scope_by = 2474

    const val undo = 2698

    const val recycled = 2013

    const val waiting_for_connection = 2087

    const val condition_less_than = 2257

    const val scope = 2646

    const val average_usage_time_per_user = 2196

    const val edit_schedule = 2304

    const val first_name = 2038

    const val option_value = 2508

    const val add_block = 2876

    const val add_class_comment = 2620

    const val message = 2912

    const val enrolment_policy = 2948

    const val select_terminology = 2899

    const val add_file_description = 2120

    const val leaving_reason = 2382

    const val age = 2427

    const val username = 2034

    const val field_content_completion = 2250

    const val score_greater_than_zero = 2867

    const val percent_students_completed = 2198

    const val add_a_schedule = 2303

    const val edit_block = 2877

    const val xapi_options_y_axes = 2228

    const val permission_sel_select = 2499

    const val attendance_report = 2313

    const val license_type_cc_0 = 2294

    const val add_from_gallery = 2748

    const val toC = 2358

    const val create_my_account = 2793

    const val licence_type_cc_by = 2287

    const val currently_enrolled = 2931

    const val sel_question_type_free_text = 2639

    const val multiple_choice = 2171

    const val share_link = 2661

    const val enrolment = 2391

    const val schools = 2529

    const val accept_enrolment_requests_automatically = 2894

    const val partial = 2402

    const val field_type = 2504

    const val permission_password_reset = 2673

    const val submit_grade_and_mark_next = 2865

    const val error_opening_file = 2027

    const val other = 2050

    const val content_total_duration = 2191

    const val class_description = 2335

    const val save = 2517

    const val gender_literal = 2440

    const val number_of_active_users_over_time = 2836

    const val quiz_questions = 2614

    const val login = 2001

    const val holiday = 2337

    const val edit_role = 2460

    const val permission_sel_update = 2500

    const val content_pieces_completed = 2701

    const val content_usage_time = 2770

    const val share = 2082

    const val total_score = 2587

    const val add_activity_literal = 2416

    const val outcome = 2389

    const val add_video_or_audio_from_the_device_gallery = 2749

    const val monday = 2351

    const val what_is_your_date_of_birth = 2713

    const val question = 2328

    const val question_type = 2642

    const val last_name = 2037

    const val licence_type_cc_by_sa_nc = 2289

    const val ascending = 2395

    const val opening_link = 2707

    const val add_a_holiday = 2338

    const val completion_criteria = 2598

    const val private_comments = 2618

    const val permission_clazz_assignment_view = 2560

    const val logged_in_as = 2636

    const val submit_all_at_once_submission_policy = 2946

    const val field_person_gender = 2248

    const val you_are_already_in_school = 2710

    const val points = 2594

    const val add_educational_content_using_supported_formats_eg_pdf_xapi_h5p = 2756

    const val file_type = 2858

    const val members = 2471

    const val all_permissions = 2771

    const val all = 2431

    const val settings = 2052

    const val xapi_day = 2206

    const val custom_fields = 2501

    const val multiple_submission_allowed_submission_policy = 2947

    const val dialog_download_from_playstore_cancel = 2134

    const val create_a_new_learning_env = 2773

    const val guest_login_enabled = 2685

    const val add_a_teacher = 2401

    const val monthly = 2348

    const val copy_code = 2660

    const val open_enrolment = 2892

    const val indent = 2887

    const val download_downloading_placeholder = 2156

    const val view_socioeconomic_details_of_members = 2735

    const val sort_by = 2300

    const val new_enrolment = 2379

    const val use_device_language = 2548

    const val role_description = 2648

    const val connectivity_full = 2446

    const val teacher = 2400

    const val percentage_of_students_attending_over_time = 2828

    const val and = 2114

    const val today = 2299

    const val friday = 2355

    const val edit_terms_and_policies = 2696

    const val add_person_or_group = 2737

    const val import_link_content_not_supported = 2276

    const val due_date = 2542

    const val automatic = 2596

    const val save_as_template = 2073

    const val add_to = 2553

    const val select_one = 2417

    const val exams = 2070

    const val download_cancel_label = 2158

    const val add_question = 2329

    const val add_a_new_role = 2459

    const val add_new_terminology = 2900

    const val forgot_password = 2077

    const val entry_details_author = 2167

    const val sel_question_set_desc = 2435

    const val import_link_big_size = 2277

    const val basic_details = 2318

    const val error_this_device_doesnt_support_bluetooth_sharing = 2121

    const val xapi_options_x_axes = 2229

    const val you_must_comply_with_license = 2780

    const val edit_holiday = 2339

    const val password = 2035

    const val from_my_courses = 2961

    const val add_discussion = 2954

    const val add_using_a_web_link_then = 2747

    const val past_enrollments = 2932

    const val add_a_student = 2076

    const val completed = 2093

    const val edit_basic_profile_of_members = 2818

    const val dropdown = 2506

    const val no_account = 2273

    const val register_empty_fields = 2004

    const val xapi_options_filters = 2231

    const val xapi_result_header = 2270

    const val connectivity_limited = 2445

    const val file_document = 2861

    const val graduated = 2373

    const val download_entry_state_paused = 2164

    const val sort_by_text = 2397

    const val id_verbentity_urlid = 2246

    const val assign_role = 2649

    const val permission_clazz_select = 2485

    const val short_text = 2607

    const val document = 2107

    const val edit_course = 2930

    const val students = 2075

    const val number_unique_students_attending = 2205

    const val dont_show_before = 2845

    const val formatted_text_to_show_to_course_participants = 2880

    const val disabled = 2960

    const val class_name = 2334

    const val quiz = 2609

    const val languages = 2057

    const val xapi_hours = 2266

    const val num_items_with_name = 2551

    const val permission_sel_question_update = 2498

    const val refresh = 2524

    const val not_submitted_cap = 2574

    const val xapi_minutes = 2267

    const val past_enrolments = 2919

    const val report_filter_edit_field = 2238

    const val join_existing_school = 2528

    const val call = 2566

    const val add_educational_content_to_new_learning_env = 2774

    const val download_continue_stacked_label = 2149

    const val permission_clazz_update = 2487

    const val last_two_week_date_range = 2213

    const val time_period = 2421

    const val import_content = 2099

    const val primary_user = 2405

    const val content_usage_by_class = 2831

    const val activity_change = 2360

    const val organisation = 2629

    const val add_new = 2563

    const val edit_after_submission = 2852

    const val participant = 2404

    const val edit_language = 2058

    const val mother = 2315

    const val incomplete = 2092

    const val holidays = 2340

    const val app_store_link = 2766

    const val school = 2525

    const val view_profile = 2385

    const val learning_environment = 2788

    const val add_course_block_content_desc = 2881

    const val nearby_share = 2764

    const val sort_by_name_desc = 2368

    const val condition_is = 2254

    const val enroled = 2371

    const val select_account = 2706

    const val courses = 2914

    const val allow_class_comments = 2592

    const val last = 2306

    const val recommended_for_advanced_users = 2763

    const val downloaded = 2012

    const val sync = 2453

    const val add_permission_for_a_person = 2744

    const val permission_person_delegate = 2664

    const val onboarding_no_internet_subheadline = 2141

    const val dropped_out = 2374

    const val add_link_description = 2118

    const val name = 2046

    const val onboarding_get_started_label = 2138

    const val unhide = 2112

    const val we_sent_a_message_to_your_parent = 2715

    const val next = 2031

    const val percentage_score = 2535

    const val almost_done = 2797

    const val submit = 2623

    const val add_remove_and_modify_subjects = 2792

    const val classes = 2069

    const val x_percent_attended = 2576

    const val person_exists = 2637

    const val description = 2047

    const val video = 2699

    const val return_and_mark_next = 2626

    const val submit_grade = 2864

    const val add_new_language = 2055

    const val add_role_permission = 2645

    const val time_range = 2218

    const val open_folder = 2184

    const val terms_and_policies_text = 2695

    const val revoking_consent_will = 2779

    const val offline_sharing_enable_wifi_promot = 2281

    const val submitted_cap = 2573

    const val overview = 2562

    const val search_in = 2800

    const val yes = 2687

    const val libraries = 2014

    const val student_image = 2327

    const val holiday_calendars = 2432

    const val number_students_completed = 2197

    const val add_a_new_occurrence = 2681

    const val join_existing_class = 2326

    const val continue_using_the_link = 2741

    const val content_already_added_to_class = 2823

    const val require_text_submission = 2847

    const val time = 2615

    const val total_content_duration_gender = 2834

    const val assignments = 2530

    const val memory_card = 2042

    const val interactive = 2105

    const val add_new_content = 2544

    const val edit_holiday_calendar = 2343

    const val started_date = 2536

    const val person_enrolment_in_class = 2390

    const val items_selected = 2555

    const val logout = 2028

    const val in_progress = 2388

    const val i_consent = 2722

    const val connectivity = 2442

    const val mobile_internet_access = 2444

    const val options = 2507

    const val download_wifi_only = 2151

    const val edit_school = 2527

    const val selected_custom_range = 2217

    const val dashboard = 2264

    const val class_enrolment_outcome = 2386

    const val permission_attendance_insert = 2491

    const val uom_duration_title = 2412

    const val add_content_filter = 2263

    const val sunday = 2350

    const val phone = 2067

    const val type_here = 2956

    const val edit_leaving_reason = 2377

    const val no_app_found = 2166

    const val audit_log_setting_desc = 2466

    const val copy_link = 2659

    const val syncing = 2295

    const val log = 2301

    const val assign_to = 2475

    const val edit_clazzwork = 2583

    const val enabled = 2959

    const val connect_to_an_existing_learning_environment = 2775

    const val hidden_enrolment_via_links_code_or_invitation = 2896

    const val onboarding_stay_organized_subheading = 2145

    const val update_grade = 2952

    const val xapi_score = 2271

    const val download_state_download = 2153

    const val start_date = 2514

    const val not_allowed = 2855

    const val new_group = 2935

    const val audit_log = 2465

    const val enter_url = 2274

    const val confirm_delete_message = 2008

    const val xapi_options_what = 2234

    const val class_enrolment = 2372

    const val prefer_not_to_say = 2447

    const val new_leaving_reason = 2376

    const val nomination = 2437

    const val drag_and_drop_or_click_to_add_file = 2840

    const val question_text = 2641

    const val add_using_link = 2746

    const val deleting = 2024

    const val after_deadline_date_error = 2593

    const val invalid_register_code = 2186

    const val chat = 2936

    const val login_network_error = 2010

    const val class_setup = 2783

    const val accounts = 2080

    const val course_blocks = 2875

    const val None = 2531

    const val xapi_clear = 2211

    const val holiday_calendar = 2341

    const val feedback_thanks = 2513

    const val edit_class_content = 2731

    const val attendance_over_time_by_class = 2827

    const val num_replies = 2907

    const val add_module = 2927

    const val xapi_options_data_set = 2225

    const val manage_site_settings = 2684

    const val share_offline_zip_checkbox_label = 2122

    const val insufficient_space = 2163

    const val xapi_options_when = 2235

    const val visible_from_date = 2604

    const val last_month_date_range = 2214

    const val change_implemented_if_any = 2361

    const val manage_parental_consent = 2729

    const val day = 2357

    const val share_offline_dialog_message = 2123

    const val sort_by_name_asc = 2367

    const val class_enrolment_leaving = 2387

    const val group = 2844

    const val marked = 2601

    const val licence = 2103

    const val does_your_class_already_have_learning_env = 2776

    const val xapi_custom_date = 2209

    const val permission_sel_question_select = 2496

    const val pause_download = 2015

    const val ask_your_teacher_for_code = 2656

    const val number_of_students_completed_time = 2830

    const val done = 2221

    const val action_hidden = 2185

    const val line_chart = 2189

    const val licence_type_cc_by_sa = 2288

    const val daily = 2347

    const val sorry_something_went_wrong = 2807

    const val use_public_library_site = 2630

    const val timezone = 2521

    const val permission_school_update = 2670

    const val penalty_label = 2872

    const val edit_report = 2243

    const val max_number_of_files = 2863

    const val import_link_error = 2278

    const val count_session = 2193

    const val add_class = 2333

    const val entry_details_publisher = 2168

    const val permission_clazz_add_student = 2489

    const val attachment = 2608

    const val reject = 2655

    const val uom_frequency_title = 2411

    const val oops = 2806

    const val from_my_classes = 2786

    const val course_setup = 2903

    const val visible_for_all = 2897

    const val move = 2110

    const val filed_password_no_match = 2129

    const val teachers_literal = 2403

    const val file_image = 2862

    const val edit_filters = 2247

    const val add_activity = 2415

    const val edit_socioeconomic_details_of_members = 2736

    const val marking = 2625

    const val update_grade_and_mark_next = 2953

    const val last_week = 2307

    const val permission_school_select = 2668

    const val attendance = 2068

    const val account = 2079

    const val parent = 2837

    const val condition_not_in_list = 2259

    const val class_id = 2439

    const val activity_went_good = 2408

    const val add_file = 2750

    const val permission_person_picture_insert = 2483

    const val edit_discussion = 2955

    const val file_selected = 2430

    const val pending_requests = 2652

    const val incident_id = 2808

    const val enrolment_requests_must_be_approved = 2893

    const val permission_clazz_insert = 2486

    const val phone_memory = 2041

    const val num_items_with_name_with_comma = 2552

    const val new_module = 2885

    const val you = 2937

    const val repo_loading_status_failed_connection_error = 2286

    const val profile = 2131

    const val active = 2438

    const val supported_files = 2179

    const val permission_role_select = 2666

    const val everything_works_offline = 2767

    const val recommended_for_offline_installation = 2765

    const val canceled = 2019

    const val submitted = 2602

    const val invite_link_desc = 2658

    const val submissions = 2873

    const val regcode = 2065

    const val parent_child_register_message_subject = 2718

    const val custom_fields_desc = 2503

    const val join_class = 2711

    const val average = 2426

    const val show_hidden_items = 2183

    const val role_assignment_setting_desc = 2462

    const val content_creation_folder_update_message = 2176

    const val lets_get_started = 2738

    const val new_group_set = 2925

    const val search = 2449

    const val locations_setting_desc = 2464

    const val add_leaving_reason = 2381

    const val wednesday = 2353

    const val x_teachers_y_students = 2565

    const val fixed_date = 2125

    const val deadline = 2611

    const val add_text = 2874

    const val managed_enrolment = 2944

    const val custom_field = 2502

    const val field_attendance_percentage = 2253

    const val edit_contact_details_of_members = 2734

    const val activity_went_bad = 2409

    const val subjects = 2791

    const val words = 2868

    const val edit_question = 2330

    const val thursday = 2354

    const val staff = 2564

    const val file_type_any = 2860

    const val add_person = 2332

    const val home_internet_access = 2443

    const val student_progress = 2546

    const val create_account = 2633

    const val file_type_chosen = 2859

    const val enter_register_code = 2187

    const val deleted = 2021

    const val two_letter_code = 2059

    const val answer = 2577

    const val maximum = 2848

    const val onboarding_offline_sharing = 2142

    const val status_consent_denied = 2727

    const val present_late_absent = 2571

    const val view_clazzes = 2812

    const val choose_template = 2072

    const val marked_cap = 2572

    const val added = 2115

    const val powered_by = 2704

    const val new_chat = 2934

    const val questions = 2331

    const val language = 2053

    const val download_storage_option_device = 2160

    const val down = 2407

    const val size_limit = 2849

    const val add_assignment_block_content_desc = 2882

    const val repo_loading_status_failed_noconnection = 2285

    const val you_are_already_in_class = 2709

    const val entity_code = 2675

    const val course_code = 2922

    const val minimum_score = 2595

    const val download_state_downloading = 2154

    const val edit_enrolment = 2378

    const val download_pause_download = 2159

    const val languages_description = 2061

    const val organization_id = 2567

    const val view_clazz = 2811

    const val number_active_users = 2195

    const val content_from_file = 2097

    const val school_code = 2674

    const val select_account_to_continue = 2708

    const val add_subject = 2760

    const val waiting = 2086

    const val feedback_hint = 2511

    const val report_filter_edit_values = 2240

    const val edit_clazz = 2324

    const val back = 2033

    const val add_new_clazz_to_school = 2821

    const val title = 2102

    const val licence_type_cc_by_nc = 2290

    const val content = 2543

    const val duration = 2362

    const val class_timezone_set = 2870

    const val terminology = 2898

    const val lets_get_started_label = 2139

    const val passed = 2090

    const val just_want_to_browse = 2778

    const val date_left = 2370

    const val over = 2452

    const val field_person_age = 2249

    const val text_file_submission_error = 2945

    const val permission = 2468

    const val home = 2066

    const val registration_allowed = 2686

    const val field_required_prompt = 2127

    const val schedule = 2302

    const val location_permission_details = 2148

    const val attendance_low_to_high = 2399

    const val other_legal_guardian = 2728

    const val licence_type_cc_by_nc_sa = 2291

    const val alternatively_you_can_download_the_apk = 2803

    const val current_password = 2519

    const val remove_from = 2554

    const val register_now = 2039

    const val add_a_new_holiday_calendar = 2342

    const val download_continue_btn_label = 2150

    const val advanced = 2062

    const val peers = 2857

    const val number_of_groups = 2904

    const val add_a_new_verb = 2245

    const val marked_by = 2856

    const val text = 2505

    const val looks_like_installed_app_from_link = 2739

    const val verb = 2244

    const val loading = 2023

    const val your_words_for = 2902

    const val xapi_options_visual_type = 2224

    const val condition_between = 2260

    const val contents = 2074

    const val xapi_options_report_title = 2223

    const val messages = 2911

    const val total_absences = 2200

    const val status = 2547

    const val share_application = 2081

    const val permission_person_select = 2479

    const val submitliteral = 2575

    const val library = 2913

    const val member = 2472

    const val class_timezone = 2612

    const val graded = 2866

    const val download_state_downloaded = 2155

    const val leaving_reasons = 2383

    const val create_a_new_report = 2242

    const val supported_link = 2279

    const val percentage_complete = 2534

    const val topics = 2908

    const val limited_i_avoid_downloading_too_much = 2805

    const val download_calculating = 2152

    const val share_apk_file = 2762

    const val hide = 2111

    const val ebook = 2104

    const val terms_and_policies = 2683

    const val error_start_date_before_previous_enrolment_date = 2393

    const val interaction_recorded = 2194

    const val report = 2241

    const val last_active = 2516

    const val this_field_is_mandatory = 2665

    const val repo_loading_status_loading_mirror = 2284

    const val record_attendance = 2298

    const val permission_person_update = 2481

    const val created_partnership = 2137

    const val please_download_the_app = 2802

    const val add_content = 2545

    const val no_submission_required = 2606

    const val holiday_calendars_desc = 2433

    const val add_from_contacts = 2796

    const val current_status_consent_granted = 2757

    const val uom_boolean_title = 2413

    const val unset = 2051

    const val posts = 2939

    const val importing = 2752

    const val download = 2011

    const val view_class_content = 2730

    const val error_start_date_before_clazz_date = 2392

    const val please_enter_the_linK = 2692

    const val downloading = 2085

    const val rols_assignment = 2461

    const val grade_out_of_range = 2951

    const val limit = 2850

    const val add_a_new_school = 2526

    const val content_entry_export_inprogress = 2181

    const val add_folder = 2751

    const val add_a_reply = 2910

    const val role_not_selected_error = 2663

    const val late_submission = 2588

    const val my = 2634

    const val feed = 2296

    const val entry = 2467

    const val deadline_has_passed = 2957

    const val present = 2311

    const val errors = 2509

    const val birthday = 2316

    const val site_link = 2691

    const val no = 2688

    const val student = 2568

    const val view_class_learning_records = 2732

    const val view_school = 2819

    const val mixed = 2538

    const val delete = 2020

    const val ignore_the_link = 2743

    const val copied_to_clipboard = 2657

    const val add_new_groups = 2906

    const val select_leaving_reason = 2380

    const val add_folder_description = 2755

    const val ok = 2016

    const val roles_and_permissions = 2644

    const val select_country = 2758

    const val allow_private_comments_from_students = 2610

    const val or = 2693

    const val total_attendances = 2199

    const val last_three_months = 2423

    const val content_usage_duration = 2702

    const val take_new_photo_from_camera = 2558

    const val total_lates = 2201

    const val permission_role_insert = 2667

    const val xapi_seconds = 2268

    const val store_title = 2539

    const val users_settings_desc = 2522

    const val please_wait_for_approval = 2654

    const val queued = 2088

    const val about = 2029

    const val invalid_email = 2705

    const val condition_in_list = 2258

    const val permission_clazz_asignment_edit = 2561

    const val incorrect_current_password = 2638

    const val share_via = 2761

    const val select_content = 2799

    const val dialog_download_from_playstore_ok = 2133

    const val location_permission_message = 2147

    const val content_editor_create_new_category = 2700

    const val reviews_per_user_group = 2963

    const val edit = 2321

    const val yes_no = 2420

    const val scope_description = 2647

    const val module = 2878

    const val invalid_link = 2275

    const val xapi_options_series = 2226

    const val accept = 2135

    const val enrol_and_unenrol_students = 2813

    const val content_average_duration = 2192

    const val store_description_short = 2540

    const val see_supported_sites = 2782

    const val get_app = 2165

    const val enter_link = 2759

    const val processing = 2025

    const val assign_random_reviewers = 2964

    const val return_only = 2627

    const val device = 2043

    const val last_week_date_range = 2212

    const val unassigned_error = 2958

    const val register_incorrect_email = 2005

    const val male = 2048

    const val create_site = 2631

    const val end_date = 2515

    const val unique_content_users_over_time = 2835

    const val add_a_new_course = 2928

    const val saturday = 2356

    const val activity = 2365

    const val sel_question_set = 2434

    const val unread = 2933

    const val update_content = 2100

    const val allowed_till_deadline = 2853

    const val attendance_high_to_low = 2398

    const val late_penalty = 2600

    const val new_assignment = 2585

    const val date_enroll = 2369

    const val take_me_home = 2810

    const val audio = 2109

    const val onboarding_offline_sharing_subheading = 2143

    const val relationship = 2721

    const val email = 2064

    const val change_photo = 2556

    const val add_permission_for_a_group = 2745

    const val permission_attendance_update = 2492

    const val field_password_error_min = 2128

    const val select_language = 2056

    const val mark_all_absent = 2310

    const val end_of_grace_period = 2591

    const val mark_all_present = 2309

    const val error_too_long_text = 2943

    const val parental_consent = 2717

    const val started = 2537

    const val contact_details = 2569

    const val reviewer = 2965

    const val add_option = 2643

    const val people = 2297

    const val confirm = 2017

    const val permission_person_picture_select = 2482

    const val error_message_load_page = 2177

    const val of_content = 2578

    const val first_names = 2317

    const val new_tab = 2022

    const val total_number_of_classes = 2204

    const val view_assignments = 2815

    const val status_consent_granted = 2726

    const val xapi_verb_header = 2269

    const val to = 2336

    const val open = 2084

    const val country = 2441

    const val workspace = 2628

    const val error_message_update_document = 2178

    const val add_another = 2635

    const val edit_text = 2926

    const val allowed_till_grace = 2854

    const val add_private_comment = 2621

    const val file_required_prompt = 2174

    const val extra_active_tab_warning = 2841

    const val frequency = 2419

    const val adult_account_required = 2825

    const val up = 2406

    const val xapi_options_did = 2233

    const val collection = 2106

    const val edit_topic = 2938

    const val not_answered = 2580

    const val course_comments = 2917

    const val report_filter_edit_condition = 2239

    const val permission_attendance_select = 2490

    const val add_group = 2923

    const val uom_default_title = 2414

    const val onboarding_stay_organized_headline = 2144

    const val once = 2346

    const val group_number = 2921

    const val import_title_not_entered = 2272

    const val yearly = 2349

    const val preview = 2172

    const val add_discussion_board_desc = 2884

    const val unindent = 2888

    const val file_not_found = 2170

    const val licence_type_public_domain = 2292

    const val father = 2314

    const val pending = 2653

    const val update = 2130

    const val permission_school_insert = 2669

    const val add_remove_and_modify_grades = 2790

    const val condition_is_not = 2255

    const val xapi_week = 2207

    const val submission_type = 2605

    const val restore_consent = 2725

    const val previous = 2032

    const val apply = 2429

    const val download_summary_title = 2157

    const val blank_report = 2826

    const val xapi_options_where = 2236

    const val sel_question_set_to_use = 2448

    const val you_can_copypaste_a_link = 2781

    const val new_password = 2040

    const val location = 2422

    const val class_code = 2651

    const val dialog_download_from_playstore_message = 2132

    const val measurement_type = 2418

    const val view_basic_profile_of_members = 2817

    const val selected_file_summary = 2839

    const val this_class = 2785

    const val type = 2801

    const val add_a_new_language = 2054

    const val permission_activity_insert = 2494

    const val three_letter_code = 2060

    const val download_locally_availability = 2161

    const val edit_terminology = 2901

    const val enable = 2282

    const val permissions = 2469

    const val revoke_consent = 2724

    const val xapi_options_general = 2222

    const val permission_person_insert = 2480

    const val enrol_and_unenrol_teachers = 2814

    const val attempts = 2116

    const val compress = 2113

    const val permission_clazz_add_teacher = 2488

    const val permission_sel_question_insert = 2497

    const val nothing_here = 2579

    const val swipe_to_next = 2768

    const val course_module = 2879

    const val clazz_assignment = 2584

    const val activity_import_link = 2265

    const val late_submission_penalty = 2590

    const val location_permission_title = 2146

    const val group_activity = 2006

    const val individual_submission = 2941

    const val require_file_submission = 2846

    const val notifications = 2078

    const val xapi_month = 2208

    const val time_range_all = 2237

    const val publicly_accessible = 2101

    const val xapi_content_entry = 2210

    const val content_from_link = 2098

    const val last_three_months_date_range = 2215

    const val number_of_files = 2851

    const val currently_enroled = 2918

    const val features_enabled = 2344

    const val class_comments = 2622

    const val student_enrolment_policy = 2890

    const val select_new_photo_from_gallery = 2559

    const val your_account_needs_approved = 2824

    const val parents_email_address = 2714

    const val course = 2915

    const val time_present = 2394

    const val join_existing_course = 2929

    const val underRegistrationAgeError = 2117

    const val xapi_options_who = 2232

    const val recommend_it = 2794

    const val edit_group = 2924

    const val invite_with_link = 2662

    const val select_group_members = 2007

    const val percent_students_attended_or_late = 2203

    const val mark_penalty = 2589

    const val filter = 2451

    const val role_assignment = 2473

    const val toggle_visibility = 2716

    const val content_completion = 2829

    const val wrong_user_pass_combo = 2009

    const val not_submitted = 2603

    const val site = 2690

    const val download_all = 2030

    const val full_i_can_download_as_much_as_i_like = 2804

    const val content_usage_over_time = 2833

    const val percent_students_attended = 2202

    const val submission = 2616

    const val how_did_it_go = 2363

    const val record_attendance_for_most_recent_occurrence = 2680

    const val first = 2305

    const val date = 2425

    const val student_marks_content = 2597

    const val unassigned = 2942

    const val permission_person_picture_update = 2484

    const val maximum_points = 2871

    const val descending = 2396

    const val not_started = 2533

    const val onboarding_no_internet_headline = 2140

    const val i_do_not_consent = 2723

    const val reports_and_analytics = 2916

    const val uploading = 2026

    const val content_creation_storage_option_title = 2173

    const val from = 2220

    const val mark_all = 2308

    const val sort_by_name = 2366

    const val submission_policy = 2949

    const val password_unchanged = 2520

    const val individual = 2843

    const val repo_loading_status_loading_cloud = 2283

    const val three_num_items_with_name_with_comma = 2624

    const val active_classes = 2682

    const val add_person_to_class = 2320

    const val xapi_options_subgroup = 2230

    const val store_description_full = 2541

    const val failed = 2089

    const val also_available_in = 2136

    const val end_is_before_start_error = 2679

    const val manage_download = 2703

    const val add_gallery_description = 2119

    const val preparing = 2124

    const val record_for_student = 2677

    const val person = 2476

    const val parent_child_register_message = 2719

    const val teacher_enrolment_policy = 2891

    const val send = 2083

    const val xapi_options_axes = 2227

    const val submit_your_answer = 2619

    const val error = 2002

    const val add_a_new_class = 2323

    const val edit_permissions = 2795

    const val licence_type_all_rights = 2293

    const val field_content_entry = 2251

    const val add_a_class = 2325

    const val your_username = 2798

    const val remove_photo = 2557

    const val groups = 2455

    const val leaving_reason_manage = 2384

    const val view_contact_details_of_members = 2733

    const val tuesday = 2352

    const val downloading_content = 2753

    const val locations = 2463

    const val add_person_filter = 2261

    const val clazz = 2322

    const val added_to_class_content = 2822

}