package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.CourseGroupSet
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseGroupSetDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseGroupSetDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : CourseGroupSetDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun updateAsync(entity: CourseGroupSet): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override fun findAllCourseGroupSetForClazz(clazzUid: Long): DoorDataSourceFactory<Int,
      CourseGroupSet> {
    val _result = _dao.findAllCourseGroupSetForClazz(clazzUid)
    return _result
  }

  public override fun findAllCourseGroupSetForClazzList(clazzUid: Long): List<CourseGroupSet> {
    val _result = _dao.findAllCourseGroupSetForClazzList(clazzUid)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): CourseGroupSet? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun insert(entity: CourseGroupSet): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: CourseGroupSet): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out CourseGroupSet>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseGroupSet>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: CourseGroupSet): Unit {
    _dao.update(entity)
  }
}
