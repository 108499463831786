package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryParentChildJoinDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentEntryParentChildJoinDao() {
  public val _insertAdapterContentEntryParentChildJoin_:
      EntityInsertionAdapter<ContentEntryParentChildJoin> = object :
      EntityInsertionAdapter<ContentEntryParentChildJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentEntryParentChildJoin (cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex, cepcjUid, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentEntryParentChildJoin (cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex, cepcjUid, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct) VALUES(?, ?, ?, COALESCE(?,nextval('ContentEntryParentChildJoin_cepcjUid_seq')), ?, ?, ?, ?)" + if(returnsId) { " RETURNING cepcjUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryParentChildJoin): Unit {
      stmt.setLong(1, entity.cepcjParentContentEntryUid)
      stmt.setLong(2, entity.cepcjChildContentEntryUid)
      stmt.setInt(3, entity.childIndex)
      if(entity.cepcjUid == 0L) {
        stmt.setObject(4, null)
      } else {
        stmt.setLong(4, entity.cepcjUid)
      }
      stmt.setLong(5, entity.cepcjLocalChangeSeqNum)
      stmt.setLong(6, entity.cepcjMasterChangeSeqNum)
      stmt.setInt(7, entity.cepcjLastChangedBy)
      stmt.setLong(8, entity.cepcjLct)
    }
  }

  public val _insertAdapterContentEntryParentChildJoin_upsert:
      EntityInsertionAdapter<ContentEntryParentChildJoin> = object :
      EntityInsertionAdapter<ContentEntryParentChildJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO ContentEntryParentChildJoin (cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex, cepcjUid, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentEntryParentChildJoin (cepcjParentContentEntryUid, cepcjChildContentEntryUid, childIndex, cepcjUid, cepcjLocalChangeSeqNum, cepcjMasterChangeSeqNum, cepcjLastChangedBy, cepcjLct) VALUES(?, ?, ?, COALESCE(?,nextval('ContentEntryParentChildJoin_cepcjUid_seq')), ?, ?, ?, ?) ON CONFLICT (cepcjUid) DO UPDATE SET cepcjParentContentEntryUid = excluded.cepcjParentContentEntryUid,cepcjChildContentEntryUid = excluded.cepcjChildContentEntryUid,childIndex = excluded.childIndex,cepcjLocalChangeSeqNum = excluded.cepcjLocalChangeSeqNum,cepcjMasterChangeSeqNum = excluded.cepcjMasterChangeSeqNum,cepcjLastChangedBy = excluded.cepcjLastChangedBy,cepcjLct = excluded.cepcjLct" + if(returnsId) { " RETURNING cepcjUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryParentChildJoin): Unit {
      stmt.setLong(1, entity.cepcjParentContentEntryUid)
      stmt.setLong(2, entity.cepcjChildContentEntryUid)
      stmt.setInt(3, entity.childIndex)
      if(entity.cepcjUid == 0L) {
        stmt.setObject(4, null)
      } else {
        stmt.setLong(4, entity.cepcjUid)
      }
      stmt.setLong(5, entity.cepcjLocalChangeSeqNum)
      stmt.setLong(6, entity.cepcjMasterChangeSeqNum)
      stmt.setInt(7, entity.cepcjLastChangedBy)
      stmt.setLong(8, entity.cepcjLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentEntryParentChildJoinReplicate(cepcjPk, cepcjDestination)
    |      SELECT DISTINCT ContentEntryParentChildJoin.cepcjUid AS cepcjUid,
    |             ? AS cepcjDestination
    |        FROM ContentEntryParentChildJoin
    |       WHERE ContentEntryParentChildJoin.cepcjLct != COALESCE(
    |             (SELECT cepcjVersionId
    |                FROM ContentEntryParentChildJoinReplicate
    |               WHERE cepcjPk = ContentEntryParentChildJoin.cepcjUid
    |                 AND cepcjDestination = ?), -1) 
    |      /*psql ON CONFLICT(cepcjPk, cepcjDestination) DO UPDATE
    |             SET cepcjPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryParentChildJoinReplicate(cepcjPk, cepcjDestination)
    |      SELECT DISTINCT ContentEntryParentChildJoin.cepcjUid AS cepcjUid,
    |             ? AS cepcjDestination
    |        FROM ContentEntryParentChildJoin
    |       WHERE ContentEntryParentChildJoin.cepcjLct != COALESCE(
    |             (SELECT cepcjVersionId
    |                FROM ContentEntryParentChildJoinReplicate
    |               WHERE cepcjPk = ContentEntryParentChildJoin.cepcjUid
    |                 AND cepcjDestination = ?), -1) 
    |       ON CONFLICT(cepcjPk, cepcjDestination) DO UPDATE
    |             SET cepcjPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |    REPLACE INTO ContentEntryParentChildJoinReplicate(cepcjPk, cepcjDestination)
    |    SELECT DISTINCT ContentEntryParentChildJoin.cepcjUid AS cepcjUid,
    |         UserSession.usClientNodeId AS cepcjDestination
    |    FROM ChangeLog
    |         JOIN ContentEntryParentChildJoin
    |             ON ChangeLog.chTableId = 7
    |                AND ChangeLog.chEntityPk = ContentEntryParentChildJoin.cepcjUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentEntryParentChildJoin.cepcjLct != COALESCE(
    |         (SELECT cepcjVersionId
    |            FROM ContentEntryParentChildJoinReplicate
    |           WHERE cepcjPk = ContentEntryParentChildJoin.cepcjUid
    |             AND cepcjDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(cepcjPk, cepcjDestination) DO UPDATE
    |     SET cepcjPending = true
    |    */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryParentChildJoinReplicate(cepcjPk, cepcjDestination)
    |    SELECT DISTINCT ContentEntryParentChildJoin.cepcjUid AS cepcjUid,
    |         UserSession.usClientNodeId AS cepcjDestination
    |    FROM ChangeLog
    |         JOIN ContentEntryParentChildJoin
    |             ON ChangeLog.chTableId = 7
    |                AND ChangeLog.chEntityPk = ContentEntryParentChildJoin.cepcjUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentEntryParentChildJoin.cepcjLct != COALESCE(
    |         (SELECT cepcjVersionId
    |            FROM ContentEntryParentChildJoinReplicate
    |           WHERE cepcjPk = ContentEntryParentChildJoin.cepcjUid
    |             AND cepcjDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(cepcjPk, cepcjDestination) DO UPDATE
    |     SET cepcjPending = true
    |                   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(entityList: List<ContentEntryParentChildJoin>): Unit {
    _insertAdapterContentEntryParentChildJoin_.insertListAsync(entityList)
  }

  public override fun publicContentEntryParentChildJoins(): List<ContentEntryParentChildJoin> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntryParentChildJoin.* FROM ContentEntryParentChildJoin LEFT JOIN ContentEntry parentEntry ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = parentEntry.contentEntryUid LEFT JOIN ContentEntry childEntry ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = childEntry.contentEntryUid WHERE parentEntry.publik AND childEntry.publik"
        , postgreSql = """
    |SELECT ContentEntryParentChildJoin.* FROM ContentEntryParentChildJoin LEFT JOIN ContentEntry parentEntry ON ContentEntryParentChildJoin.cepcjParentContentEntryUid = parentEntry.contentEntryUid LEFT JOIN ContentEntry childEntry ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = childEntry.contentEntryUid WHERE parentEntry.publik AND childEntry.publik
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
          val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
          val tmp_childIndex = _resultSet.getInt("childIndex")
          val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
          val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
          val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
          val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
          val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
          val _entity = ContentEntryParentChildJoin()
          _entity.cepcjParentContentEntryUid = tmp_cepcjParentContentEntryUid
          _entity.cepcjChildContentEntryUid = tmp_cepcjChildContentEntryUid
          _entity.childIndex = tmp_childIndex
          _entity.cepcjUid = tmp_cepcjUid
          _entity.cepcjLocalChangeSeqNum = tmp_cepcjLocalChangeSeqNum
          _entity.cepcjMasterChangeSeqNum = tmp_cepcjMasterChangeSeqNum
          _entity.cepcjLastChangedBy = tmp_cepcjLastChangedBy
          _entity.cepcjLct = tmp_cepcjLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun all(): List<ContentEntryParentChildJoin> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin>()
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM ContentEntryParentChildJoin" ,
        postgreSql = """
    |SELECT * FROM ContentEntryParentChildJoin
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
          val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
          val tmp_childIndex = _resultSet.getInt("childIndex")
          val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
          val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
          val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
          val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
          val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
          val _entity = ContentEntryParentChildJoin()
          _entity.cepcjParentContentEntryUid = tmp_cepcjParentContentEntryUid
          _entity.cepcjChildContentEntryUid = tmp_cepcjChildContentEntryUid
          _entity.childIndex = tmp_childIndex
          _entity.cepcjUid = tmp_cepcjUid
          _entity.cepcjLocalChangeSeqNum = tmp_cepcjLocalChangeSeqNum
          _entity.cepcjMasterChangeSeqNum = tmp_cepcjMasterChangeSeqNum
          _entity.cepcjLastChangedBy = tmp_cepcjLastChangedBy
          _entity.cepcjLct = tmp_cepcjLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findParentByChildUuids(childEntryContentUid: Long):
      ContentEntryParentChildJoin? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ? LIMIT 1"
        , postgreSql = """
    |SELECT * FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, childEntryContentUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
          val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
          val tmp_childIndex = _resultSet.getInt("childIndex")
          val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
          val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
          val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
          val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
          val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
          val _entity = ContentEntryParentChildJoin()
          _entity.cepcjParentContentEntryUid = tmp_cepcjParentContentEntryUid
          _entity.cepcjChildContentEntryUid = tmp_cepcjChildContentEntryUid
          _entity.childIndex = tmp_childIndex
          _entity.cepcjUid = tmp_cepcjUid
          _entity.cepcjLocalChangeSeqNum = tmp_cepcjLocalChangeSeqNum
          _entity.cepcjMasterChangeSeqNum = tmp_cepcjMasterChangeSeqNum
          _entity.cepcjLastChangedBy = tmp_cepcjLastChangedBy
          _entity.cepcjLct = tmp_cepcjLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findListOfParentsByChildUuid(childEntryContentUid: Long):
      List<ContentEntryParentChildJoin> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ?"
        , postgreSql = """
    |SELECT * FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, childEntryContentUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
          val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
          val tmp_childIndex = _resultSet.getInt("childIndex")
          val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
          val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
          val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
          val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
          val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
          val _entity = ContentEntryParentChildJoin()
          _entity.cepcjParentContentEntryUid = tmp_cepcjParentContentEntryUid
          _entity.cepcjChildContentEntryUid = tmp_cepcjChildContentEntryUid
          _entity.childIndex = tmp_childIndex
          _entity.cepcjUid = tmp_cepcjUid
          _entity.cepcjLocalChangeSeqNum = tmp_cepcjLocalChangeSeqNum
          _entity.cepcjMasterChangeSeqNum = tmp_cepcjMasterChangeSeqNum
          _entity.cepcjLastChangedBy = tmp_cepcjLastChangedBy
          _entity.cepcjLct = tmp_cepcjLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findListOfChildsByParentUuid(parentUid: Long):
      List<ContentEntryParentChildJoin> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = ?"
        , postgreSql = """
    |SELECT * FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
          val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
          val tmp_childIndex = _resultSet.getInt("childIndex")
          val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
          val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
          val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
          val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
          val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
          val _entity = ContentEntryParentChildJoin()
          _entity.cepcjParentContentEntryUid = tmp_cepcjParentContentEntryUid
          _entity.cepcjChildContentEntryUid = tmp_cepcjChildContentEntryUid
          _entity.childIndex = tmp_childIndex
          _entity.cepcjUid = tmp_cepcjUid
          _entity.cepcjLocalChangeSeqNum = tmp_cepcjLocalChangeSeqNum
          _entity.cepcjMasterChangeSeqNum = tmp_cepcjMasterChangeSeqNum
          _entity.cepcjLastChangedBy = tmp_cepcjLastChangedBy
          _entity.cepcjLct = tmp_cepcjLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findJoinByParentChildUuids(parentUid: Long, childUid: Long):
      ContentEntryParentChildJoin? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = ? AND cepcjChildContentEntryUid = ? LIMIT 1"
        , postgreSql = """
    |SELECT * FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = ? AND cepcjChildContentEntryUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, parentUid)
      _stmt.setLong(2, childUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
          val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
          val tmp_childIndex = _resultSet.getInt("childIndex")
          val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
          val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
          val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
          val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
          val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
          val _entity = ContentEntryParentChildJoin()
          _entity.cepcjParentContentEntryUid = tmp_cepcjParentContentEntryUid
          _entity.cepcjChildContentEntryUid = tmp_cepcjChildContentEntryUid
          _entity.childIndex = tmp_childIndex
          _entity.cepcjUid = tmp_cepcjUid
          _entity.cepcjLocalChangeSeqNum = tmp_cepcjLocalChangeSeqNum
          _entity.cepcjMasterChangeSeqNum = tmp_cepcjMasterChangeSeqNum
          _entity.cepcjLastChangedBy = tmp_cepcjLastChangedBy
          _entity.cepcjLct = tmp_cepcjLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun selectTopEntries(): List<ContentEntry> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntry>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntry.* FROM ContentEntry WHERE NOT EXISTS(SELECT cepcjUid FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ContentEntry.contentEntryUid) AND EXISTS(SELECT cepcjUid FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = ContentEntry.contentEntryUid)"
        , postgreSql = """
    |SELECT ContentEntry.* FROM ContentEntry WHERE NOT EXISTS(SELECT cepcjUid FROM ContentEntryParentChildJoin WHERE cepcjChildContentEntryUid = ContentEntry.contentEntryUid) AND EXISTS(SELECT cepcjUid FROM ContentEntryParentChildJoin WHERE cepcjParentContentEntryUid = ContentEntry.contentEntryUid)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntry()
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun replaceList(entries: List<ContentEntryParentChildJoin>): Unit {
    _insertAdapterContentEntryParentChildJoin_upsert.insertList(entries)
  }

  public override fun insertWithReplace(parentChildJoinDao: ContentEntryParentChildJoin): Unit {
    _insertAdapterContentEntryParentChildJoin_upsert.insert(parentChildJoinDao)
  }

  public override suspend fun moveListOfEntriesToNewParent(
    contentEntryUid: Long,
    selectedItems: List<Long>,
    updateTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentEntryParentChildJoin 
    |           SET cepcjParentContentEntryUid = ?, 
    |               cepcjLct = ? 
    |               WHERE cepcjUid IN (?)
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        UPDATE ContentEntryParentChildJoin 
    |           SET cepcjParentContentEntryUid = ?, 
    |               cepcjLct = ? 
    |               WHERE cepcjUid IN (?)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.setLong(2, updateTime)
      _stmt.setArray(3, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          selectedItems.toTypedArray()))
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: ContentEntryParentChildJoin): Long {
    val _retVal = _insertAdapterContentEntryParentChildJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntryParentChildJoin): Long {
    val _retVal = _insertAdapterContentEntryParentChildJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContentEntryParentChildJoin>): Unit {
    _insertAdapterContentEntryParentChildJoin_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentEntryParentChildJoin>): Unit {
    val _sql =
        "UPDATE ContentEntryParentChildJoin SET cepcjParentContentEntryUid = ?, cepcjChildContentEntryUid = ?, childIndex = ?, cepcjLocalChangeSeqNum = ?, cepcjMasterChangeSeqNum = ?, cepcjLastChangedBy = ?, cepcjLct = ? WHERE cepcjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cepcjParentContentEntryUid)
        _stmt.setLong(2, _entity.cepcjChildContentEntryUid)
        _stmt.setInt(3, _entity.childIndex)
        _stmt.setLong(4, _entity.cepcjLocalChangeSeqNum)
        _stmt.setLong(5, _entity.cepcjMasterChangeSeqNum)
        _stmt.setInt(6, _entity.cepcjLastChangedBy)
        _stmt.setLong(7, _entity.cepcjLct)
        _stmt.setLong(8, _entity.cepcjUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContentEntryParentChildJoin): Unit {
    val _sql =
        "UPDATE ContentEntryParentChildJoin SET cepcjParentContentEntryUid = ?, cepcjChildContentEntryUid = ?, childIndex = ?, cepcjLocalChangeSeqNum = ?, cepcjMasterChangeSeqNum = ?, cepcjLastChangedBy = ?, cepcjLct = ? WHERE cepcjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cepcjParentContentEntryUid)
      _stmt.setLong(2, entity.cepcjChildContentEntryUid)
      _stmt.setInt(3, entity.childIndex)
      _stmt.setLong(4, entity.cepcjLocalChangeSeqNum)
      _stmt.setLong(5, entity.cepcjMasterChangeSeqNum)
      _stmt.setInt(6, entity.cepcjLastChangedBy)
      _stmt.setLong(7, entity.cepcjLct)
      _stmt.setLong(8, entity.cepcjUid)
      _stmt.executeUpdate()
    }
  }
}
