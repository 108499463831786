package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.SchoolMember
import com.ustadmobile.lib.db.entities.SchoolMemberWithPerson
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class SchoolMemberDao_JdbcKt(
  public val _db: DoorDatabase
) : SchoolMemberDao() {
  public val _insertAdapterSchoolMember_: EntityInsertionAdapter<SchoolMember> = object :
      EntityInsertionAdapter<SchoolMember>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO SchoolMember (schoolMemberUid, schoolMemberPersonUid, schoolMemberSchoolUid, schoolMemberJoinDate, schoolMemberLeftDate, schoolMemberRole, schoolMemberActive, schoolMemberLocalChangeSeqNum, schoolMemberMasterChangeSeqNum, schoolMemberLastChangedBy, schoolMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO SchoolMember (schoolMemberUid, schoolMemberPersonUid, schoolMemberSchoolUid, schoolMemberJoinDate, schoolMemberLeftDate, schoolMemberRole, schoolMemberActive, schoolMemberLocalChangeSeqNum, schoolMemberMasterChangeSeqNum, schoolMemberLastChangedBy, schoolMemberLct) VALUES(COALESCE(?,nextval('SchoolMember_schoolMemberUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING schoolMemberUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: SchoolMember):
        Unit {
      if(entity.schoolMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.schoolMemberUid)
      }
      stmt.setLong(2, entity.schoolMemberPersonUid)
      stmt.setLong(3, entity.schoolMemberSchoolUid)
      stmt.setLong(4, entity.schoolMemberJoinDate)
      stmt.setLong(5, entity.schoolMemberLeftDate)
      stmt.setInt(6, entity.schoolMemberRole)
      stmt.setBoolean(7, entity.schoolMemberActive)
      stmt.setLong(8, entity.schoolMemberLocalChangeSeqNum)
      stmt.setLong(9, entity.schoolMemberMasterChangeSeqNum)
      stmt.setInt(10, entity.schoolMemberLastChangedBy)
      stmt.setLong(11, entity.schoolMemberLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO SchoolMemberReplicate(smPk, smDestination)
    |      SELECT DISTINCT SchoolMember.schoolMemberUid AS smPk,
    |             ? AS smDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions &  64) > 0
    |             JOIN SchoolMember
    |                  ON 
    |            ((ScopedGrant.sgTableId = -2
    |                  AND ScopedGrant.sgEntityUid = -2)
    |              OR (ScopedGrant.sgTableId = 9
    |                  AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberPersonUid)
    |              OR (ScopedGrant.sgTableId = 164
    |                 AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberSchoolUid))
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND SchoolMember.schoolMemberLct != COALESCE(
    |             (SELECT smVersionId
    |                FROM SchoolMemberReplicate
    |               WHERE smPk = SchoolMember.schoolMemberUid
    |                 AND smDestination = ?), 0) 
    |      /*psql ON CONFLICT(smPk, smDestination) DO UPDATE
    |             SET smPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO SchoolMemberReplicate(smPk, smDestination)
    |      SELECT DISTINCT SchoolMember.schoolMemberUid AS smPk,
    |             ? AS smDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions &  64) > 0
    |             JOIN SchoolMember
    |                  ON 
    |            ((ScopedGrant.sgTableId = -2
    |                  AND ScopedGrant.sgEntityUid = -2)
    |              OR (ScopedGrant.sgTableId = 9
    |                  AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberPersonUid)
    |              OR (ScopedGrant.sgTableId = 164
    |                 AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberSchoolUid))
    |        
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND SchoolMember.schoolMemberLct != COALESCE(
    |             (SELECT smVersionId
    |                FROM SchoolMemberReplicate
    |               WHERE smPk = SchoolMember.schoolMemberUid
    |                 AND smDestination = ?), 0) 
    |       ON CONFLICT(smPk, smDestination) DO UPDATE
    |             SET smPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO SchoolMemberReplicate(smPk, smDestination)
    |  SELECT DISTINCT SchoolMember.schoolMemberUid AS smUid,
    |         UserSession.usClientNodeId AS smDestination
    |    FROM ChangeLog
    |         JOIN SchoolMember
    |              ON ChangeLog.chTableId = 200
    |                  AND ChangeLog.chEntityPk = SchoolMember.schoolMemberUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                  AND ScopedGrant.sgEntityUid = -2)
    |              OR (ScopedGrant.sgTableId = 9
    |                  AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberPersonUid)
    |              OR (ScopedGrant.sgTableId = 164
    |                 AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions &
    |        
    |              64
    |              
    |            ) > 0  
    |            JOIN PersonGroupMember 
    |                   ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |            JOIN UserSession
    |                   ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                      AND UserSession.usStatus =1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND SchoolMember.schoolMemberLct != COALESCE(
    |         (SELECT smVersionId
    |            FROM SchoolMemberReplicate
    |           WHERE smPk = SchoolMember.schoolMemberUid
    |             AND smDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(smPk, smDestination) DO UPDATE
    |     SET smPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO SchoolMemberReplicate(smPk, smDestination)
    |  SELECT DISTINCT SchoolMember.schoolMemberUid AS smUid,
    |         UserSession.usClientNodeId AS smDestination
    |    FROM ChangeLog
    |         JOIN SchoolMember
    |              ON ChangeLog.chTableId = 200
    |                  AND ChangeLog.chEntityPk = SchoolMember.schoolMemberUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                  AND ScopedGrant.sgEntityUid = -2)
    |              OR (ScopedGrant.sgTableId = 9
    |                  AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberPersonUid)
    |              OR (ScopedGrant.sgTableId = 164
    |                 AND ScopedGrant.sgEntityUid = SchoolMember.schoolMemberSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions &
    |        
    |              64
    |              
    |            ) > 0  
    |            JOIN PersonGroupMember 
    |                   ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |            JOIN UserSession
    |                   ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                      AND UserSession.usStatus =1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND SchoolMember.schoolMemberLct != COALESCE(
    |         (SELECT smVersionId
    |            FROM SchoolMemberReplicate
    |           WHERE smPk = SchoolMember.schoolMemberUid
    |             AND smDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(smPk, smDestination) DO UPDATE
    |     SET smPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUidAsync(schoolMemberUid: Long): SchoolMember? {
    var _result = null as com.ustadmobile.lib.db.entities.SchoolMember??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM SchoolMember WHERE schoolMemberUid = ?  AND CAST(schoolMemberActive AS INTEGER) = 1"
        , postgreSql = """
    |SELECT * FROM SchoolMember WHERE schoolMemberUid = ?  AND CAST(schoolMemberActive AS INTEGER) = 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, schoolMemberUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_schoolMemberUid = _resultSet.getLong("schoolMemberUid")
          val tmp_schoolMemberPersonUid = _resultSet.getLong("schoolMemberPersonUid")
          val tmp_schoolMemberSchoolUid = _resultSet.getLong("schoolMemberSchoolUid")
          val tmp_schoolMemberJoinDate = _resultSet.getLong("schoolMemberJoinDate")
          val tmp_schoolMemberLeftDate = _resultSet.getLong("schoolMemberLeftDate")
          val tmp_schoolMemberRole = _resultSet.getInt("schoolMemberRole")
          val tmp_schoolMemberActive = _resultSet.getBoolean("schoolMemberActive")
          val tmp_schoolMemberLocalChangeSeqNum =
              _resultSet.getLong("schoolMemberLocalChangeSeqNum")
          val tmp_schoolMemberMasterChangeSeqNum =
              _resultSet.getLong("schoolMemberMasterChangeSeqNum")
          val tmp_schoolMemberLastChangedBy = _resultSet.getInt("schoolMemberLastChangedBy")
          val tmp_schoolMemberLct = _resultSet.getLong("schoolMemberLct")
          val _entity = SchoolMember()
          _entity.schoolMemberUid = tmp_schoolMemberUid
          _entity.schoolMemberPersonUid = tmp_schoolMemberPersonUid
          _entity.schoolMemberSchoolUid = tmp_schoolMemberSchoolUid
          _entity.schoolMemberJoinDate = tmp_schoolMemberJoinDate
          _entity.schoolMemberLeftDate = tmp_schoolMemberLeftDate
          _entity.schoolMemberRole = tmp_schoolMemberRole
          _entity.schoolMemberActive = tmp_schoolMemberActive
          _entity.schoolMemberLocalChangeSeqNum = tmp_schoolMemberLocalChangeSeqNum
          _entity.schoolMemberMasterChangeSeqNum = tmp_schoolMemberMasterChangeSeqNum
          _entity.schoolMemberLastChangedBy = tmp_schoolMemberLastChangedBy
          _entity.schoolMemberLct = tmp_schoolMemberLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: SchoolMember): Int {
    var _result = 0
    val _sql =
        "UPDATE SchoolMember SET schoolMemberPersonUid = ?, schoolMemberSchoolUid = ?, schoolMemberJoinDate = ?, schoolMemberLeftDate = ?, schoolMemberRole = ?, schoolMemberActive = ?, schoolMemberLocalChangeSeqNum = ?, schoolMemberMasterChangeSeqNum = ?, schoolMemberLastChangedBy = ?, schoolMemberLct = ? WHERE schoolMemberUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.schoolMemberPersonUid)
      _stmt.setLong(2, entity.schoolMemberSchoolUid)
      _stmt.setLong(3, entity.schoolMemberJoinDate)
      _stmt.setLong(4, entity.schoolMemberLeftDate)
      _stmt.setInt(5, entity.schoolMemberRole)
      _stmt.setBoolean(6, entity.schoolMemberActive)
      _stmt.setLong(7, entity.schoolMemberLocalChangeSeqNum)
      _stmt.setLong(8, entity.schoolMemberMasterChangeSeqNum)
      _stmt.setInt(9, entity.schoolMemberLastChangedBy)
      _stmt.setLong(10, entity.schoolMemberLct)
      _stmt.setLong(11, entity.schoolMemberUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun findBySchoolAndPersonAndRole(
    schoolUid: Long,
    personUid: Long,
    role: Int,
    timeFilter: Long
  ): List<SchoolMember> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.SchoolMember>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * FROM SchoolMember WHERE schoolMemberSchoolUid = ?
    |        AND schoolMemberPersonUid = ?
    |        AND (? = 0 OR schoolMemberRole = ?)
    |        AND (? = 0 OR ? BETWEEN SchoolMember.schoolMemberJoinDate AND SchoolMember.schoolMemberLeftDate) 
    |        AND CAST(schoolMemberActive AS INTEGER) = 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * FROM SchoolMember WHERE schoolMemberSchoolUid = ?
    |        AND schoolMemberPersonUid = ?
    |        AND (? = 0 OR schoolMemberRole = ?)
    |        AND (? = 0 OR ? BETWEEN SchoolMember.schoolMemberJoinDate AND SchoolMember.schoolMemberLeftDate) 
    |        AND CAST(schoolMemberActive AS INTEGER) = 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, schoolUid)
      _stmt.setLong(2, personUid)
      _stmt.setInt(3, role)
      _stmt.setInt(4, role)
      _stmt.setLong(5, timeFilter)
      _stmt.setLong(6, timeFilter)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_schoolMemberUid = _resultSet.getLong("schoolMemberUid")
          val tmp_schoolMemberPersonUid = _resultSet.getLong("schoolMemberPersonUid")
          val tmp_schoolMemberSchoolUid = _resultSet.getLong("schoolMemberSchoolUid")
          val tmp_schoolMemberJoinDate = _resultSet.getLong("schoolMemberJoinDate")
          val tmp_schoolMemberLeftDate = _resultSet.getLong("schoolMemberLeftDate")
          val tmp_schoolMemberRole = _resultSet.getInt("schoolMemberRole")
          val tmp_schoolMemberActive = _resultSet.getBoolean("schoolMemberActive")
          val tmp_schoolMemberLocalChangeSeqNum =
              _resultSet.getLong("schoolMemberLocalChangeSeqNum")
          val tmp_schoolMemberMasterChangeSeqNum =
              _resultSet.getLong("schoolMemberMasterChangeSeqNum")
          val tmp_schoolMemberLastChangedBy = _resultSet.getInt("schoolMemberLastChangedBy")
          val tmp_schoolMemberLct = _resultSet.getLong("schoolMemberLct")
          val _entity = SchoolMember()
          _entity.schoolMemberUid = tmp_schoolMemberUid
          _entity.schoolMemberPersonUid = tmp_schoolMemberPersonUid
          _entity.schoolMemberSchoolUid = tmp_schoolMemberSchoolUid
          _entity.schoolMemberJoinDate = tmp_schoolMemberJoinDate
          _entity.schoolMemberLeftDate = tmp_schoolMemberLeftDate
          _entity.schoolMemberRole = tmp_schoolMemberRole
          _entity.schoolMemberActive = tmp_schoolMemberActive
          _entity.schoolMemberLocalChangeSeqNum = tmp_schoolMemberLocalChangeSeqNum
          _entity.schoolMemberMasterChangeSeqNum = tmp_schoolMemberMasterChangeSeqNum
          _entity.schoolMemberLastChangedBy = tmp_schoolMemberLastChangedBy
          _entity.schoolMemberLct = tmp_schoolMemberLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllActiveMembersBySchoolAndRoleUid(
    schoolUid: Long,
    role: Int,
    sortOrder: Int,
    searchQuery: String,
    accountPersonUid: Long
  ): DoorDataSourceFactory<Int, SchoolMemberWithPerson> {
    val _result = object : DoorDataSourceFactory<Int, SchoolMemberWithPerson>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<SchoolMemberWithPerson>> =
          DoorLiveDataImpl<List<SchoolMemberWithPerson>>(_db, listOf("PersonGroupMember",
          "ScopedGrant", "Person", "SchoolMember"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.SchoolMemberWithPerson>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT SchoolMember.*, Person.*
        |          FROM PersonGroupMember
        |                
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 
        |                64 
        |                
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |          LEFT JOIN SchoolMember ON Person.personUid = SchoolMember.schoolMemberPersonUid 
        |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
        |         AND PersonGroupMember.groupMemberActive  
        |        AND SchoolMember.schoolMemberActive
        |        AND SchoolMember.schoolMemberSchoolUid = ? 
        |        AND SchoolMember.schoolMemberRole = ?
        |        AND Person.active
        |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
        |        GROUP BY Person.personUid, SchoolMember.schoolMemberUid
        |         ORDER BY CASE(?)
        |                WHEN 1 THEN Person.firstNames
        |                WHEN 3 THEN Person.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN Person.firstNames
        |                WHEN 4 THEN Person.lastName
        |                ELSE ''
        |            END DESC
        |            ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT SchoolMember.*, Person.*
        |          FROM PersonGroupMember
        |                
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 
        |                64 
        |                
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |          LEFT JOIN SchoolMember ON Person.personUid = SchoolMember.schoolMemberPersonUid 
        |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
        |         AND PersonGroupMember.groupMemberActive  
        |        AND SchoolMember.schoolMemberActive
        |        AND SchoolMember.schoolMemberSchoolUid = ? 
        |        AND SchoolMember.schoolMemberRole = ?
        |        AND Person.active
        |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
        |        GROUP BY Person.personUid, SchoolMember.schoolMemberUid
        |         ORDER BY CASE(?)
        |                WHEN 1 THEN Person.firstNames
        |                WHEN 3 THEN Person.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN Person.firstNames
        |                WHEN 4 THEN Person.lastName
        |                ELSE ''
        |            END DESC
        |            ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, schoolUid)
          _stmt.setInt(3, role)
          _stmt.setString(4, searchQuery)
          _stmt.setInt(5, sortOrder)
          _stmt.setInt(6, sortOrder)
          _stmt.setInt(7, _limit)
          _stmt.setInt(8, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_schoolMemberUid = _resultSet.getLong("schoolMemberUid")
              val tmp_schoolMemberPersonUid = _resultSet.getLong("schoolMemberPersonUid")
              val tmp_schoolMemberSchoolUid = _resultSet.getLong("schoolMemberSchoolUid")
              val tmp_schoolMemberJoinDate = _resultSet.getLong("schoolMemberJoinDate")
              val tmp_schoolMemberLeftDate = _resultSet.getLong("schoolMemberLeftDate")
              val tmp_schoolMemberRole = _resultSet.getInt("schoolMemberRole")
              val tmp_schoolMemberActive = _resultSet.getBoolean("schoolMemberActive")
              val tmp_schoolMemberLocalChangeSeqNum =
                  _resultSet.getLong("schoolMemberLocalChangeSeqNum")
              val tmp_schoolMemberMasterChangeSeqNum =
                  _resultSet.getLong("schoolMemberMasterChangeSeqNum")
              val tmp_schoolMemberLastChangedBy = _resultSet.getInt("schoolMemberLastChangedBy")
              val tmp_schoolMemberLct = _resultSet.getLong("schoolMemberLct")
              val _entity = SchoolMemberWithPerson()
              _entity.schoolMemberUid = tmp_schoolMemberUid
              _entity.schoolMemberPersonUid = tmp_schoolMemberPersonUid
              _entity.schoolMemberSchoolUid = tmp_schoolMemberSchoolUid
              _entity.schoolMemberJoinDate = tmp_schoolMemberJoinDate
              _entity.schoolMemberLeftDate = tmp_schoolMemberLeftDate
              _entity.schoolMemberRole = tmp_schoolMemberRole
              _entity.schoolMemberActive = tmp_schoolMemberActive
              _entity.schoolMemberLocalChangeSeqNum = tmp_schoolMemberLocalChangeSeqNum
              _entity.schoolMemberMasterChangeSeqNum = tmp_schoolMemberMasterChangeSeqNum
              _entity.schoolMemberLastChangedBy = tmp_schoolMemberLastChangedBy
              _entity.schoolMemberLct = tmp_schoolMemberLct
              var _person_nullFieldCount = 0
              val tmp_personUid = _resultSet.getLong("personUid")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_username = _resultSet.getString("username")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_firstNames = _resultSet.getString("firstNames")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_lastName = _resultSet.getString("lastName")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_gender = _resultSet.getInt("gender")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_active = _resultSet.getBoolean("active")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_admin = _resultSet.getBoolean("admin")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personNotes = _resultSet.getString("personNotes")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_fatherName = _resultSet.getString("fatherName")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_motherName = _resultSet.getString("motherName")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_motherNum = _resultSet.getString("motherNum")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personAddress = _resultSet.getString("personAddress")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personLct = _resultSet.getLong("personLct")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personCountry = _resultSet.getString("personCountry")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              val tmp_personType = _resultSet.getInt("personType")
              if(_resultSet.wasNull()) { _person_nullFieldCount++ }
              if(_person_nullFieldCount < 24) {
                if(_entity.person == null) {
                  _entity.person = Person()
                }
                _entity.person!!.personUid = tmp_personUid
                _entity.person!!.username = tmp_username
                _entity.person!!.firstNames = tmp_firstNames
                _entity.person!!.lastName = tmp_lastName
                _entity.person!!.emailAddr = tmp_emailAddr
                _entity.person!!.phoneNum = tmp_phoneNum
                _entity.person!!.gender = tmp_gender
                _entity.person!!.active = tmp_active
                _entity.person!!.admin = tmp_admin
                _entity.person!!.personNotes = tmp_personNotes
                _entity.person!!.fatherName = tmp_fatherName
                _entity.person!!.fatherNumber = tmp_fatherNumber
                _entity.person!!.motherName = tmp_motherName
                _entity.person!!.motherNum = tmp_motherNum
                _entity.person!!.dateOfBirth = tmp_dateOfBirth
                _entity.person!!.personAddress = tmp_personAddress
                _entity.person!!.personOrgId = tmp_personOrgId
                _entity.person!!.personGroupUid = tmp_personGroupUid
                _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
                _entity.person!!.personLct = tmp_personLct
                _entity.person!!.personCountry = tmp_personCountry
                _entity.person!!.personType = tmp_personType
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("PersonGroupMember", "ScopedGrant", "Person", "SchoolMember"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT SchoolMember.*, Person.*
        |          FROM PersonGroupMember
        |                
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 
        |                64 
        |                
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |          LEFT JOIN SchoolMember ON Person.personUid = SchoolMember.schoolMemberPersonUid 
        |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
        |         AND PersonGroupMember.groupMemberActive  
        |        AND SchoolMember.schoolMemberActive
        |        AND SchoolMember.schoolMemberSchoolUid = ? 
        |        AND SchoolMember.schoolMemberRole = ?
        |        AND Person.active
        |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
        |        GROUP BY Person.personUid, SchoolMember.schoolMemberUid
        |         ORDER BY CASE(?)
        |                WHEN 1 THEN Person.firstNames
        |                WHEN 3 THEN Person.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN Person.firstNames
        |                WHEN 4 THEN Person.lastName
        |                ELSE ''
        |            END DESC
        |            ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT SchoolMember.*, Person.*
        |          FROM PersonGroupMember
        |                
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions & 
        |                64 
        |                
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |          LEFT JOIN SchoolMember ON Person.personUid = SchoolMember.schoolMemberPersonUid 
        |         WHERE PersonGroupMember.groupMemberPersonUid = ? 
        |         AND PersonGroupMember.groupMemberActive  
        |        AND SchoolMember.schoolMemberActive
        |        AND SchoolMember.schoolMemberSchoolUid = ? 
        |        AND SchoolMember.schoolMemberRole = ?
        |        AND Person.active
        |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
        |        GROUP BY Person.personUid, SchoolMember.schoolMemberUid
        |         ORDER BY CASE(?)
        |                WHEN 1 THEN Person.firstNames
        |                WHEN 3 THEN Person.lastName
        |                ELSE ''
        |            END ASC,
        |            CASE(?)
        |                WHEN 2 THEN Person.firstNames
        |                WHEN 4 THEN Person.lastName
        |                ELSE ''
        |            END DESC
        |            ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, schoolUid)
          _stmt.setInt(3, role)
          _stmt.setString(4, searchQuery)
          _stmt.setInt(5, sortOrder)
          _stmt.setInt(6, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun findAllTest(
    schoolUid: Long,
    role: Int,
    searchQuery: String
  ): List<SchoolMemberWithPerson> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.SchoolMemberWithPerson>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT SchoolMember.*, Person.* FROM SchoolMember
    |        LEFT JOIN Person ON Person.personUid = SchoolMember.schoolMemberPersonUid
    |        WHERE CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1
    |        AND SchoolMember.schoolMemberSchoolUid = ? 
    |        AND SchoolMember.schoolMemberRole = ?
    |        AND CAST(Person.active AS INTEGER) = 1
    |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
    |        ORDER BY Person.firstNames DESC
    """.trimMargin() , postgreSql = """
    |SELECT SchoolMember.*, Person.* FROM SchoolMember
    |        LEFT JOIN Person ON Person.personUid = SchoolMember.schoolMemberPersonUid
    |        WHERE CAST(SchoolMember.schoolMemberActive AS INTEGER) = 1
    |        AND SchoolMember.schoolMemberSchoolUid = ? 
    |        AND SchoolMember.schoolMemberRole = ?
    |        AND CAST(Person.active AS INTEGER) = 1
    |        AND (Person.firstNames || ' ' || Person.lastName) LIKE ?
    |        ORDER BY Person.firstNames DESC
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, schoolUid)
      _stmt.setInt(2, role)
      _stmt.setString(3, searchQuery)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_schoolMemberUid = _resultSet.getLong("schoolMemberUid")
          val tmp_schoolMemberPersonUid = _resultSet.getLong("schoolMemberPersonUid")
          val tmp_schoolMemberSchoolUid = _resultSet.getLong("schoolMemberSchoolUid")
          val tmp_schoolMemberJoinDate = _resultSet.getLong("schoolMemberJoinDate")
          val tmp_schoolMemberLeftDate = _resultSet.getLong("schoolMemberLeftDate")
          val tmp_schoolMemberRole = _resultSet.getInt("schoolMemberRole")
          val tmp_schoolMemberActive = _resultSet.getBoolean("schoolMemberActive")
          val tmp_schoolMemberLocalChangeSeqNum =
              _resultSet.getLong("schoolMemberLocalChangeSeqNum")
          val tmp_schoolMemberMasterChangeSeqNum =
              _resultSet.getLong("schoolMemberMasterChangeSeqNum")
          val tmp_schoolMemberLastChangedBy = _resultSet.getInt("schoolMemberLastChangedBy")
          val tmp_schoolMemberLct = _resultSet.getLong("schoolMemberLct")
          val _entity = SchoolMemberWithPerson()
          _entity.schoolMemberUid = tmp_schoolMemberUid
          _entity.schoolMemberPersonUid = tmp_schoolMemberPersonUid
          _entity.schoolMemberSchoolUid = tmp_schoolMemberSchoolUid
          _entity.schoolMemberJoinDate = tmp_schoolMemberJoinDate
          _entity.schoolMemberLeftDate = tmp_schoolMemberLeftDate
          _entity.schoolMemberRole = tmp_schoolMemberRole
          _entity.schoolMemberActive = tmp_schoolMemberActive
          _entity.schoolMemberLocalChangeSeqNum = tmp_schoolMemberLocalChangeSeqNum
          _entity.schoolMemberMasterChangeSeqNum = tmp_schoolMemberMasterChangeSeqNum
          _entity.schoolMemberLastChangedBy = tmp_schoolMemberLastChangedBy
          _entity.schoolMemberLct = tmp_schoolMemberLct
          var _person_nullFieldCount = 0
          val tmp_personUid = _resultSet.getLong("personUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_username = _resultSet.getString("username")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_firstNames = _resultSet.getString("firstNames")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_lastName = _resultSet.getString("lastName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_gender = _resultSet.getInt("gender")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_active = _resultSet.getBoolean("active")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_admin = _resultSet.getBoolean("admin")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personNotes = _resultSet.getString("personNotes")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherName = _resultSet.getString("fatherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherName = _resultSet.getString("motherName")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_motherNum = _resultSet.getString("motherNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personAddress = _resultSet.getString("personAddress")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personLct = _resultSet.getLong("personLct")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personCountry = _resultSet.getString("personCountry")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          val tmp_personType = _resultSet.getInt("personType")
          if(_resultSet.wasNull()) { _person_nullFieldCount++ }
          if(_person_nullFieldCount < 24) {
            if(_entity.person == null) {
              _entity.person = Person()
            }
            _entity.person!!.personUid = tmp_personUid
            _entity.person!!.username = tmp_username
            _entity.person!!.firstNames = tmp_firstNames
            _entity.person!!.lastName = tmp_lastName
            _entity.person!!.emailAddr = tmp_emailAddr
            _entity.person!!.phoneNum = tmp_phoneNum
            _entity.person!!.gender = tmp_gender
            _entity.person!!.active = tmp_active
            _entity.person!!.admin = tmp_admin
            _entity.person!!.personNotes = tmp_personNotes
            _entity.person!!.fatherName = tmp_fatherName
            _entity.person!!.fatherNumber = tmp_fatherNumber
            _entity.person!!.motherName = tmp_motherName
            _entity.person!!.motherNum = tmp_motherNum
            _entity.person!!.dateOfBirth = tmp_dateOfBirth
            _entity.person!!.personAddress = tmp_personAddress
            _entity.person!!.personOrgId = tmp_personOrgId
            _entity.person!!.personGroupUid = tmp_personGroupUid
            _entity.person!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.person!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.person!!.personLastChangedBy = tmp_personLastChangedBy
            _entity.person!!.personLct = tmp_personLct
            _entity.person!!.personCountry = tmp_personCountry
            _entity.person!!.personType = tmp_personType
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun insert(entity: SchoolMember): Long {
    val _retVal = _insertAdapterSchoolMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: SchoolMember): Long {
    val _retVal = _insertAdapterSchoolMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out SchoolMember>): Unit {
    _insertAdapterSchoolMember_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out SchoolMember>): Unit {
    val _sql =
        "UPDATE SchoolMember SET schoolMemberPersonUid = ?, schoolMemberSchoolUid = ?, schoolMemberJoinDate = ?, schoolMemberLeftDate = ?, schoolMemberRole = ?, schoolMemberActive = ?, schoolMemberLocalChangeSeqNum = ?, schoolMemberMasterChangeSeqNum = ?, schoolMemberLastChangedBy = ?, schoolMemberLct = ? WHERE schoolMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.schoolMemberPersonUid)
        _stmt.setLong(2, _entity.schoolMemberSchoolUid)
        _stmt.setLong(3, _entity.schoolMemberJoinDate)
        _stmt.setLong(4, _entity.schoolMemberLeftDate)
        _stmt.setInt(5, _entity.schoolMemberRole)
        _stmt.setBoolean(6, _entity.schoolMemberActive)
        _stmt.setLong(7, _entity.schoolMemberLocalChangeSeqNum)
        _stmt.setLong(8, _entity.schoolMemberMasterChangeSeqNum)
        _stmt.setInt(9, _entity.schoolMemberLastChangedBy)
        _stmt.setLong(10, _entity.schoolMemberLct)
        _stmt.setLong(11, _entity.schoolMemberUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: SchoolMember): Unit {
    val _sql =
        "UPDATE SchoolMember SET schoolMemberPersonUid = ?, schoolMemberSchoolUid = ?, schoolMemberJoinDate = ?, schoolMemberLeftDate = ?, schoolMemberRole = ?, schoolMemberActive = ?, schoolMemberLocalChangeSeqNum = ?, schoolMemberMasterChangeSeqNum = ?, schoolMemberLastChangedBy = ?, schoolMemberLct = ? WHERE schoolMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.schoolMemberPersonUid)
      _stmt.setLong(2, entity.schoolMemberSchoolUid)
      _stmt.setLong(3, entity.schoolMemberJoinDate)
      _stmt.setLong(4, entity.schoolMemberLeftDate)
      _stmt.setInt(5, entity.schoolMemberRole)
      _stmt.setBoolean(6, entity.schoolMemberActive)
      _stmt.setLong(7, entity.schoolMemberLocalChangeSeqNum)
      _stmt.setLong(8, entity.schoolMemberMasterChangeSeqNum)
      _stmt.setInt(9, entity.schoolMemberLastChangedBy)
      _stmt.setLong(10, entity.schoolMemberLct)
      _stmt.setLong(11, entity.schoolMemberUid)
      _stmt.executeUpdate()
    }
  }
}
