package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentJob
import com.ustadmobile.lib.db.entities.ContentJobItem
import com.ustadmobile.lib.db.entities.ContentJobItemAndContentJob
import com.ustadmobile.lib.db.entities.ContentJobItemProgress
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ContentJobItemDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentJobItemDao() {
  public val _insertAdapterContentJobItem_: EntityInsertionAdapter<ContentJobItem> = object :
      EntityInsertionAdapter<ContentJobItem>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentJobItem (cjiUid, cjiJobUid, sourceUri, cjiIsLeaf, cjiContentEntryUid, cjiParentContentEntryUid, cjiContainerUid, cjiItemProgress, cjiItemTotal, cjiRecursiveProgress, cjiRecursiveTotal, cjiStatus, cjiRecursiveStatus, cjiConnectivityNeeded, cjiPluginId, cjiAttemptCount, cjiParentCjiUid, cjiServerJobId, cjiStartTime, cjiFinishTime, cjiUploadSessionUid, cjiContentDeletedOnCancellation, cjiContainerProcessed) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentJobItem (cjiUid, cjiJobUid, sourceUri, cjiIsLeaf, cjiContentEntryUid, cjiParentContentEntryUid, cjiContainerUid, cjiItemProgress, cjiItemTotal, cjiRecursiveProgress, cjiRecursiveTotal, cjiStatus, cjiRecursiveStatus, cjiConnectivityNeeded, cjiPluginId, cjiAttemptCount, cjiParentCjiUid, cjiServerJobId, cjiStartTime, cjiFinishTime, cjiUploadSessionUid, cjiContentDeletedOnCancellation, cjiContainerProcessed) VALUES(COALESCE(?,nextval('ContentJobItem_cjiUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cjiUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ContentJobItem):
        Unit {
      if(entity.cjiUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cjiUid)
      }
      stmt.setLong(2, entity.cjiJobUid)
      stmt.setString(3, entity.sourceUri)
      stmt.setBoolean(4, entity.cjiIsLeaf)
      stmt.setLong(5, entity.cjiContentEntryUid)
      stmt.setLong(6, entity.cjiParentContentEntryUid)
      stmt.setLong(7, entity.cjiContainerUid)
      stmt.setLong(8, entity.cjiItemProgress)
      stmt.setLong(9, entity.cjiItemTotal)
      stmt.setLong(10, entity.cjiRecursiveProgress)
      stmt.setLong(11, entity.cjiRecursiveTotal)
      stmt.setInt(12, entity.cjiStatus)
      stmt.setInt(13, entity.cjiRecursiveStatus)
      stmt.setBoolean(14, entity.cjiConnectivityNeeded)
      stmt.setInt(15, entity.cjiPluginId)
      stmt.setInt(16, entity.cjiAttemptCount)
      stmt.setLong(17, entity.cjiParentCjiUid)
      stmt.setLong(18, entity.cjiServerJobId)
      stmt.setLong(19, entity.cjiStartTime)
      stmt.setLong(20, entity.cjiFinishTime)
      stmt.setString(21, entity.cjiUploadSessionUid)
      stmt.setBoolean(22, entity.cjiContentDeletedOnCancellation)
      stmt.setBoolean(23, entity.cjiContainerProcessed)
    }
  }

  public override suspend fun findNextItemsInQueue(contentJobUid: Long, limit: Int):
      List<ContentJobItemAndContentJob> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentJobItemAndContentJob>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        WITH ConnectivityStateCte(state) AS 
    |             (SELECT COALESCE(
    |                     (SELECT connectivityState 
    |                        FROM ConnectivityStatus 
    |                       LIMIT 1), 0))
    |                       
    |        SELECT ContentJobItem.*, ContentJob.*
    |          FROM ContentJobItem
    |               JOIN ContentJob
    |               ON ContentJobItem.cjiJobUid = ContentJob.cjUid
    |         WHERE ContentJobItem.cjiJobUid = ?
    |           AND (ContentJobItem.cjiStatus = 4 OR 
    |                ContentJobItem.cjiStatus = 5)
    |           AND (
    |                NOT cjiConnectivityNeeded 
    |                OR ((SELECT state FROM ConnectivityStateCte) = 4) 
    |                OR (cjIsMeteredAllowed 
    |                    AND (SELECT state FROM ConnectivityStateCte) = 3)
    |                )
    |         LIMIT ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        WITH ConnectivityStateCte(state) AS 
    |             (SELECT COALESCE(
    |                     (SELECT connectivityState 
    |                        FROM ConnectivityStatus 
    |                       LIMIT 1), 0))
    |                       
    |        SELECT ContentJobItem.*, ContentJob.*
    |          FROM ContentJobItem
    |               JOIN ContentJob
    |               ON ContentJobItem.cjiJobUid = ContentJob.cjUid
    |         WHERE ContentJobItem.cjiJobUid = ?
    |           AND (ContentJobItem.cjiStatus = 4 OR 
    |                ContentJobItem.cjiStatus = 5)
    |           AND (
    |                NOT cjiConnectivityNeeded 
    |                OR ((SELECT state FROM ConnectivityStateCte) = 4) 
    |                OR (cjIsMeteredAllowed 
    |                    AND (SELECT state FROM ConnectivityStateCte) = 3)
    |                )
    |         LIMIT ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentJobUid)
      _stmt.setInt(2, limit)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = ContentJobItemAndContentJob()
          var _contentJobItem_nullFieldCount = 0
          val tmp_cjiUid = _resultSet.getLong("cjiUid")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiJobUid = _resultSet.getLong("cjiJobUid")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_sourceUri = _resultSet.getString("sourceUri")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiIsLeaf = _resultSet.getBoolean("cjiIsLeaf")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiContentEntryUid = _resultSet.getLong("cjiContentEntryUid")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiParentContentEntryUid = _resultSet.getLong("cjiParentContentEntryUid")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiContainerUid = _resultSet.getLong("cjiContainerUid")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiItemProgress = _resultSet.getLong("cjiItemProgress")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiItemTotal = _resultSet.getLong("cjiItemTotal")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiRecursiveProgress = _resultSet.getLong("cjiRecursiveProgress")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiRecursiveTotal = _resultSet.getLong("cjiRecursiveTotal")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiStatus = _resultSet.getInt("cjiStatus")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiRecursiveStatus = _resultSet.getInt("cjiRecursiveStatus")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiConnectivityNeeded = _resultSet.getBoolean("cjiConnectivityNeeded")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiPluginId = _resultSet.getInt("cjiPluginId")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiAttemptCount = _resultSet.getInt("cjiAttemptCount")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiParentCjiUid = _resultSet.getLong("cjiParentCjiUid")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiServerJobId = _resultSet.getLong("cjiServerJobId")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiStartTime = _resultSet.getLong("cjiStartTime")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiFinishTime = _resultSet.getLong("cjiFinishTime")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiUploadSessionUid = _resultSet.getString("cjiUploadSessionUid")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiContentDeletedOnCancellation =
              _resultSet.getBoolean("cjiContentDeletedOnCancellation")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          val tmp_cjiContainerProcessed = _resultSet.getBoolean("cjiContainerProcessed")
          if(_resultSet.wasNull()) { _contentJobItem_nullFieldCount++ }
          if(_contentJobItem_nullFieldCount < 23) {
            if(_entity.contentJobItem == null) {
              _entity.contentJobItem = ContentJobItem()
            }
            _entity.contentJobItem!!.cjiUid = tmp_cjiUid
            _entity.contentJobItem!!.cjiJobUid = tmp_cjiJobUid
            _entity.contentJobItem!!.sourceUri = tmp_sourceUri
            _entity.contentJobItem!!.cjiIsLeaf = tmp_cjiIsLeaf
            _entity.contentJobItem!!.cjiContentEntryUid = tmp_cjiContentEntryUid
            _entity.contentJobItem!!.cjiParentContentEntryUid = tmp_cjiParentContentEntryUid
            _entity.contentJobItem!!.cjiContainerUid = tmp_cjiContainerUid
            _entity.contentJobItem!!.cjiItemProgress = tmp_cjiItemProgress
            _entity.contentJobItem!!.cjiItemTotal = tmp_cjiItemTotal
            _entity.contentJobItem!!.cjiRecursiveProgress = tmp_cjiRecursiveProgress
            _entity.contentJobItem!!.cjiRecursiveTotal = tmp_cjiRecursiveTotal
            _entity.contentJobItem!!.cjiStatus = tmp_cjiStatus
            _entity.contentJobItem!!.cjiRecursiveStatus = tmp_cjiRecursiveStatus
            _entity.contentJobItem!!.cjiConnectivityNeeded = tmp_cjiConnectivityNeeded
            _entity.contentJobItem!!.cjiPluginId = tmp_cjiPluginId
            _entity.contentJobItem!!.cjiAttemptCount = tmp_cjiAttemptCount
            _entity.contentJobItem!!.cjiParentCjiUid = tmp_cjiParentCjiUid
            _entity.contentJobItem!!.cjiServerJobId = tmp_cjiServerJobId
            _entity.contentJobItem!!.cjiStartTime = tmp_cjiStartTime
            _entity.contentJobItem!!.cjiFinishTime = tmp_cjiFinishTime
            _entity.contentJobItem!!.cjiUploadSessionUid = tmp_cjiUploadSessionUid
            _entity.contentJobItem!!.cjiContentDeletedOnCancellation =
                tmp_cjiContentDeletedOnCancellation
            _entity.contentJobItem!!.cjiContainerProcessed = tmp_cjiContainerProcessed
          }
          var _contentJob_nullFieldCount = 0
          val tmp_cjUid = _resultSet.getLong("cjUid")
          if(_resultSet.wasNull()) { _contentJob_nullFieldCount++ }
          val tmp_toUri = _resultSet.getString("toUri")
          if(_resultSet.wasNull()) { _contentJob_nullFieldCount++ }
          val tmp_cjProgress = _resultSet.getLong("cjProgress")
          if(_resultSet.wasNull()) { _contentJob_nullFieldCount++ }
          val tmp_cjTotal = _resultSet.getLong("cjTotal")
          if(_resultSet.wasNull()) { _contentJob_nullFieldCount++ }
          val tmp_cjNotificationTitle = _resultSet.getString("cjNotificationTitle")
          if(_resultSet.wasNull()) { _contentJob_nullFieldCount++ }
          val tmp_cjIsMeteredAllowed = _resultSet.getBoolean("cjIsMeteredAllowed")
          if(_resultSet.wasNull()) { _contentJob_nullFieldCount++ }
          val tmp_params = _resultSet.getString("params")
          if(_resultSet.wasNull()) { _contentJob_nullFieldCount++ }
          if(_contentJob_nullFieldCount < 7) {
            if(_entity.contentJob == null) {
              _entity.contentJob = ContentJob()
            }
            _entity.contentJob!!.cjUid = tmp_cjUid
            _entity.contentJob!!.toUri = tmp_toUri
            _entity.contentJob!!.cjProgress = tmp_cjProgress
            _entity.contentJob!!.cjTotal = tmp_cjTotal
            _entity.contentJob!!.cjNotificationTitle = tmp_cjNotificationTitle
            _entity.contentJob!!.cjIsMeteredAllowed = tmp_cjIsMeteredAllowed
            _entity.contentJob!!.params = tmp_params
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findActiveContentJobItems(contentEntryUid: Long):
      List<ContentJobItemProgress> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentJobItemProgress>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT cjiRecursiveProgress AS progress, 
    |               cjiRecursiveTotal AS total, 
    |               cjNotificationTitle as progressTitle,
    |               ContentJobItem.cjiUid
    |          FROM ContentJobItem
    |          JOIN ContentJob
    |            ON ContentJob.cjUid = ContentJobItem.cjiJobUid
    |         WHERE cjiContentEntryUid = ?
    |           AND cjiRecursiveStatus >= 4
    |           AND cjiRecursiveStatus <= 20
    |      ORDER BY cjiStartTime DESC
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT cjiRecursiveProgress AS progress, 
    |               cjiRecursiveTotal AS total, 
    |               cjNotificationTitle as progressTitle,
    |               ContentJobItem.cjiUid
    |          FROM ContentJobItem
    |          JOIN ContentJob
    |            ON ContentJob.cjUid = ContentJobItem.cjiJobUid
    |         WHERE cjiContentEntryUid = ?
    |           AND cjiRecursiveStatus >= 4
    |           AND cjiRecursiveStatus <= 20
    |      ORDER BY cjiStartTime DESC
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_progress = _resultSet.getInt("progress")
          val tmp_total = _resultSet.getInt("total")
          val tmp_progressTitle = _resultSet.getString("progressTitle")
          val tmp_cjiUid = _resultSet.getLong("cjiUid")
          val _entity = ContentJobItemProgress()
          _entity.progress = tmp_progress
          _entity.total = tmp_total
          _entity.progressTitle = tmp_progressTitle
          _entity.cjiUid = tmp_cjiUid
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun insertJobItem(jobItem: ContentJobItem): Long {
    val _retVal = _insertAdapterContentJobItem_.insertAndReturnIdAsync(jobItem)
    return _retVal
  }

  public override suspend fun insertJobItems(jobItems: List<ContentJobItem>): Unit {
    _insertAdapterContentJobItem_.insertListAsync(jobItems)
  }

  public override suspend fun updateItemStatus(cjiUid: Long, status: Int): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem 
    |           SET cjiStatus = ?
    |         WHERE cjiUid= ?  
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem 
    |           SET cjiStatus = ?
    |         WHERE cjiUid= ?  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, status)
      _stmt.setLong(2, cjiUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun isJobDone(jobUid: Long): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT NOT EXISTS(
    |               SELECT cjiUid 
    |                 FROM ContentJobItem
    |                WHERE cjiJobUid = ?
    |                  AND cjiStatus < 21) 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT NOT EXISTS(
    |               SELECT cjiUid 
    |                 FROM ContentJobItem
    |                WHERE cjiJobUid = ?
    |                  AND cjiStatus < 21) 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, jobUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findRootJobItemByJobId(jobUid: Long): ContentJobItem? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentJobItem??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM ContentJobItem
    |         WHERE cjiJobUid = ? 
    |           AND cjiParentCjiUid = 0 
    |         LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM ContentJobItem
    |         WHERE cjiJobUid = ? 
    |           AND cjiParentCjiUid = 0 
    |         LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, jobUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cjiUid = _resultSet.getLong("cjiUid")
          val tmp_cjiJobUid = _resultSet.getLong("cjiJobUid")
          val tmp_sourceUri = _resultSet.getString("sourceUri")
          val tmp_cjiIsLeaf = _resultSet.getBoolean("cjiIsLeaf")
          val tmp_cjiContentEntryUid = _resultSet.getLong("cjiContentEntryUid")
          val tmp_cjiParentContentEntryUid = _resultSet.getLong("cjiParentContentEntryUid")
          val tmp_cjiContainerUid = _resultSet.getLong("cjiContainerUid")
          val tmp_cjiItemProgress = _resultSet.getLong("cjiItemProgress")
          val tmp_cjiItemTotal = _resultSet.getLong("cjiItemTotal")
          val tmp_cjiRecursiveProgress = _resultSet.getLong("cjiRecursiveProgress")
          val tmp_cjiRecursiveTotal = _resultSet.getLong("cjiRecursiveTotal")
          val tmp_cjiStatus = _resultSet.getInt("cjiStatus")
          val tmp_cjiRecursiveStatus = _resultSet.getInt("cjiRecursiveStatus")
          val tmp_cjiConnectivityNeeded = _resultSet.getBoolean("cjiConnectivityNeeded")
          val tmp_cjiPluginId = _resultSet.getInt("cjiPluginId")
          val tmp_cjiAttemptCount = _resultSet.getInt("cjiAttemptCount")
          val tmp_cjiParentCjiUid = _resultSet.getLong("cjiParentCjiUid")
          val tmp_cjiServerJobId = _resultSet.getLong("cjiServerJobId")
          val tmp_cjiStartTime = _resultSet.getLong("cjiStartTime")
          val tmp_cjiFinishTime = _resultSet.getLong("cjiFinishTime")
          val tmp_cjiUploadSessionUid = _resultSet.getString("cjiUploadSessionUid")
          val tmp_cjiContentDeletedOnCancellation =
              _resultSet.getBoolean("cjiContentDeletedOnCancellation")
          val tmp_cjiContainerProcessed = _resultSet.getBoolean("cjiContainerProcessed")
          val _entity = ContentJobItem()
          _entity.cjiUid = tmp_cjiUid
          _entity.cjiJobUid = tmp_cjiJobUid
          _entity.sourceUri = tmp_sourceUri
          _entity.cjiIsLeaf = tmp_cjiIsLeaf
          _entity.cjiContentEntryUid = tmp_cjiContentEntryUid
          _entity.cjiParentContentEntryUid = tmp_cjiParentContentEntryUid
          _entity.cjiContainerUid = tmp_cjiContainerUid
          _entity.cjiItemProgress = tmp_cjiItemProgress
          _entity.cjiItemTotal = tmp_cjiItemTotal
          _entity.cjiRecursiveProgress = tmp_cjiRecursiveProgress
          _entity.cjiRecursiveTotal = tmp_cjiRecursiveTotal
          _entity.cjiStatus = tmp_cjiStatus
          _entity.cjiRecursiveStatus = tmp_cjiRecursiveStatus
          _entity.cjiConnectivityNeeded = tmp_cjiConnectivityNeeded
          _entity.cjiPluginId = tmp_cjiPluginId
          _entity.cjiAttemptCount = tmp_cjiAttemptCount
          _entity.cjiParentCjiUid = tmp_cjiParentCjiUid
          _entity.cjiServerJobId = tmp_cjiServerJobId
          _entity.cjiStartTime = tmp_cjiStartTime
          _entity.cjiFinishTime = tmp_cjiFinishTime
          _entity.cjiUploadSessionUid = tmp_cjiUploadSessionUid
          _entity.cjiContentDeletedOnCancellation = tmp_cjiContentDeletedOnCancellation
          _entity.cjiContainerProcessed = tmp_cjiContainerProcessed
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateItemProgress(
    cjiUid: Long,
    cjiProgress: Long,
    cjiTotal: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiItemProgress = ?,
    |               cjiItemTotal = ?
    |         WHERE cjiUid = ?     
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiItemProgress = ?,
    |               cjiItemTotal = ?
    |         WHERE cjiUid = ?     
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, cjiProgress)
      _stmt.setLong(2, cjiTotal)
      _stmt.setLong(3, cjiUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateConnectivityNeeded(contentJobItemId: Long,
      connectivityNeeded: Boolean): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiConnectivityNeeded = ?
    |         WHERE cjiUid = ?     
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiConnectivityNeeded = ?
    |         WHERE cjiUid = ?     
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, connectivityNeeded)
      _stmt.setLong(2, contentJobItemId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateContainerProcessed(contentJobItemId: Long,
      cjiContainerProcessed: Boolean): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiContainerProcessed = ?
    |         WHERE cjiUid = ?   
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiContainerProcessed = ?
    |         WHERE cjiUid = ?   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, cjiContainerProcessed)
      _stmt.setLong(2, contentJobItemId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateJobItemAttemptCountAndStatus(
    cjiUid: Long,
    attemptCount: Int,
    status: Int
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiStatus = ?,
    |               cjiAttemptCount = ?
    |         WHERE cjiUid = ?      
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiStatus = ?,
    |               cjiAttemptCount = ?
    |         WHERE cjiUid = ?      
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, status)
      _stmt.setInt(2, attemptCount)
      _stmt.setLong(3, cjiUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateStartTimeForJob(cjiUid: Long, startTime: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiStartTime = ?
    |         WHERE cjiUid = ?      
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiStartTime = ?
    |         WHERE cjiUid = ?      
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, startTime)
      _stmt.setLong(2, cjiUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateFinishTimeForJob(cjiUid: Long, finishTime: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiFinishTime = ?
    |         WHERE cjiUid = ?      
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiFinishTime = ?
    |         WHERE cjiUid = ?      
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, finishTime)
      _stmt.setLong(2, cjiUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateContentEntryUid(cjiUid: Long, contentEntryUid: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobITem
    |           SET cjiContentEntryUid = ?
    |         WHERE cjiUid = ?  
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobITem
    |           SET cjiContentEntryUid = ?
    |         WHERE cjiUid = ?  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.setLong(2, cjiUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun updateContentJobItemContainer(cjiUid: Long, containerUid: Long):
      Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiContainerUid = ?
    |         WHERE cjiUid = ?  
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiContainerUid = ?
    |         WHERE cjiUid = ?  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, containerUid)
      _stmt.setLong(2, cjiUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findAll(): List<ContentJobItem> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentJobItem>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM ContentJobItem
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM ContentJobItem
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cjiUid = _resultSet.getLong("cjiUid")
          val tmp_cjiJobUid = _resultSet.getLong("cjiJobUid")
          val tmp_sourceUri = _resultSet.getString("sourceUri")
          val tmp_cjiIsLeaf = _resultSet.getBoolean("cjiIsLeaf")
          val tmp_cjiContentEntryUid = _resultSet.getLong("cjiContentEntryUid")
          val tmp_cjiParentContentEntryUid = _resultSet.getLong("cjiParentContentEntryUid")
          val tmp_cjiContainerUid = _resultSet.getLong("cjiContainerUid")
          val tmp_cjiItemProgress = _resultSet.getLong("cjiItemProgress")
          val tmp_cjiItemTotal = _resultSet.getLong("cjiItemTotal")
          val tmp_cjiRecursiveProgress = _resultSet.getLong("cjiRecursiveProgress")
          val tmp_cjiRecursiveTotal = _resultSet.getLong("cjiRecursiveTotal")
          val tmp_cjiStatus = _resultSet.getInt("cjiStatus")
          val tmp_cjiRecursiveStatus = _resultSet.getInt("cjiRecursiveStatus")
          val tmp_cjiConnectivityNeeded = _resultSet.getBoolean("cjiConnectivityNeeded")
          val tmp_cjiPluginId = _resultSet.getInt("cjiPluginId")
          val tmp_cjiAttemptCount = _resultSet.getInt("cjiAttemptCount")
          val tmp_cjiParentCjiUid = _resultSet.getLong("cjiParentCjiUid")
          val tmp_cjiServerJobId = _resultSet.getLong("cjiServerJobId")
          val tmp_cjiStartTime = _resultSet.getLong("cjiStartTime")
          val tmp_cjiFinishTime = _resultSet.getLong("cjiFinishTime")
          val tmp_cjiUploadSessionUid = _resultSet.getString("cjiUploadSessionUid")
          val tmp_cjiContentDeletedOnCancellation =
              _resultSet.getBoolean("cjiContentDeletedOnCancellation")
          val tmp_cjiContainerProcessed = _resultSet.getBoolean("cjiContainerProcessed")
          val _entity = ContentJobItem()
          _entity.cjiUid = tmp_cjiUid
          _entity.cjiJobUid = tmp_cjiJobUid
          _entity.sourceUri = tmp_sourceUri
          _entity.cjiIsLeaf = tmp_cjiIsLeaf
          _entity.cjiContentEntryUid = tmp_cjiContentEntryUid
          _entity.cjiParentContentEntryUid = tmp_cjiParentContentEntryUid
          _entity.cjiContainerUid = tmp_cjiContainerUid
          _entity.cjiItemProgress = tmp_cjiItemProgress
          _entity.cjiItemTotal = tmp_cjiItemTotal
          _entity.cjiRecursiveProgress = tmp_cjiRecursiveProgress
          _entity.cjiRecursiveTotal = tmp_cjiRecursiveTotal
          _entity.cjiStatus = tmp_cjiStatus
          _entity.cjiRecursiveStatus = tmp_cjiRecursiveStatus
          _entity.cjiConnectivityNeeded = tmp_cjiConnectivityNeeded
          _entity.cjiPluginId = tmp_cjiPluginId
          _entity.cjiAttemptCount = tmp_cjiAttemptCount
          _entity.cjiParentCjiUid = tmp_cjiParentCjiUid
          _entity.cjiServerJobId = tmp_cjiServerJobId
          _entity.cjiStartTime = tmp_cjiStartTime
          _entity.cjiFinishTime = tmp_cjiFinishTime
          _entity.cjiUploadSessionUid = tmp_cjiUploadSessionUid
          _entity.cjiContentDeletedOnCancellation = tmp_cjiContentDeletedOnCancellation
          _entity.cjiContainerProcessed = tmp_cjiContainerProcessed
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(cjiUid: Long): ContentJobItem? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentJobItem??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentJobItem.*
    |          FROM ContentJobItem
    |         WHERE cjiUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentJobItem.*
    |          FROM ContentJobItem
    |         WHERE cjiUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, cjiUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cjiUid = _resultSet.getLong("cjiUid")
          val tmp_cjiJobUid = _resultSet.getLong("cjiJobUid")
          val tmp_sourceUri = _resultSet.getString("sourceUri")
          val tmp_cjiIsLeaf = _resultSet.getBoolean("cjiIsLeaf")
          val tmp_cjiContentEntryUid = _resultSet.getLong("cjiContentEntryUid")
          val tmp_cjiParentContentEntryUid = _resultSet.getLong("cjiParentContentEntryUid")
          val tmp_cjiContainerUid = _resultSet.getLong("cjiContainerUid")
          val tmp_cjiItemProgress = _resultSet.getLong("cjiItemProgress")
          val tmp_cjiItemTotal = _resultSet.getLong("cjiItemTotal")
          val tmp_cjiRecursiveProgress = _resultSet.getLong("cjiRecursiveProgress")
          val tmp_cjiRecursiveTotal = _resultSet.getLong("cjiRecursiveTotal")
          val tmp_cjiStatus = _resultSet.getInt("cjiStatus")
          val tmp_cjiRecursiveStatus = _resultSet.getInt("cjiRecursiveStatus")
          val tmp_cjiConnectivityNeeded = _resultSet.getBoolean("cjiConnectivityNeeded")
          val tmp_cjiPluginId = _resultSet.getInt("cjiPluginId")
          val tmp_cjiAttemptCount = _resultSet.getInt("cjiAttemptCount")
          val tmp_cjiParentCjiUid = _resultSet.getLong("cjiParentCjiUid")
          val tmp_cjiServerJobId = _resultSet.getLong("cjiServerJobId")
          val tmp_cjiStartTime = _resultSet.getLong("cjiStartTime")
          val tmp_cjiFinishTime = _resultSet.getLong("cjiFinishTime")
          val tmp_cjiUploadSessionUid = _resultSet.getString("cjiUploadSessionUid")
          val tmp_cjiContentDeletedOnCancellation =
              _resultSet.getBoolean("cjiContentDeletedOnCancellation")
          val tmp_cjiContainerProcessed = _resultSet.getBoolean("cjiContainerProcessed")
          val _entity = ContentJobItem()
          _entity.cjiUid = tmp_cjiUid
          _entity.cjiJobUid = tmp_cjiJobUid
          _entity.sourceUri = tmp_sourceUri
          _entity.cjiIsLeaf = tmp_cjiIsLeaf
          _entity.cjiContentEntryUid = tmp_cjiContentEntryUid
          _entity.cjiParentContentEntryUid = tmp_cjiParentContentEntryUid
          _entity.cjiContainerUid = tmp_cjiContainerUid
          _entity.cjiItemProgress = tmp_cjiItemProgress
          _entity.cjiItemTotal = tmp_cjiItemTotal
          _entity.cjiRecursiveProgress = tmp_cjiRecursiveProgress
          _entity.cjiRecursiveTotal = tmp_cjiRecursiveTotal
          _entity.cjiStatus = tmp_cjiStatus
          _entity.cjiRecursiveStatus = tmp_cjiRecursiveStatus
          _entity.cjiConnectivityNeeded = tmp_cjiConnectivityNeeded
          _entity.cjiPluginId = tmp_cjiPluginId
          _entity.cjiAttemptCount = tmp_cjiAttemptCount
          _entity.cjiParentCjiUid = tmp_cjiParentCjiUid
          _entity.cjiServerJobId = tmp_cjiServerJobId
          _entity.cjiStartTime = tmp_cjiStartTime
          _entity.cjiFinishTime = tmp_cjiFinishTime
          _entity.cjiUploadSessionUid = tmp_cjiUploadSessionUid
          _entity.cjiContentDeletedOnCancellation = tmp_cjiContentDeletedOnCancellation
          _entity.cjiContainerProcessed = tmp_cjiContainerProcessed
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun getActiveContentJobIdByContentEntryUid(contentEntryUid: Long): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT COALESCE(
    |               (SELECT ContentJobItem.cjiJobUid
    |                  FROM ContentJobItem
    |                 WHERE cjiContentEntryUid = ?
    |                   AND cjiStatus BETWEEN 4 AND 20
    |              ORDER BY cjiFinishTime DESC), 0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT COALESCE(
    |               (SELECT ContentJobItem.cjiJobUid
    |                  FROM ContentJobItem
    |                 WHERE cjiContentEntryUid = ?
    |                   AND cjiStatus BETWEEN 4 AND 20
    |              ORDER BY cjiFinishTime DESC), 0)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateUploadSessionUuid(cjiUid: Long, uploadSessionUuid: String):
      Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiUploadSessionUid = ?
    |         WHERE cjiUid = ?  
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiUploadSessionUid = ?
    |         WHERE cjiUid = ?  
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, uploadSessionUuid)
      _stmt.setLong(2, cjiUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllByJobId(jobId: Long): List<ContentJobItem> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentJobItem>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM ContentJobItem
    |         WHERE cjiJobUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM ContentJobItem
    |         WHERE cjiJobUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, jobId)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cjiUid = _resultSet.getLong("cjiUid")
          val tmp_cjiJobUid = _resultSet.getLong("cjiJobUid")
          val tmp_sourceUri = _resultSet.getString("sourceUri")
          val tmp_cjiIsLeaf = _resultSet.getBoolean("cjiIsLeaf")
          val tmp_cjiContentEntryUid = _resultSet.getLong("cjiContentEntryUid")
          val tmp_cjiParentContentEntryUid = _resultSet.getLong("cjiParentContentEntryUid")
          val tmp_cjiContainerUid = _resultSet.getLong("cjiContainerUid")
          val tmp_cjiItemProgress = _resultSet.getLong("cjiItemProgress")
          val tmp_cjiItemTotal = _resultSet.getLong("cjiItemTotal")
          val tmp_cjiRecursiveProgress = _resultSet.getLong("cjiRecursiveProgress")
          val tmp_cjiRecursiveTotal = _resultSet.getLong("cjiRecursiveTotal")
          val tmp_cjiStatus = _resultSet.getInt("cjiStatus")
          val tmp_cjiRecursiveStatus = _resultSet.getInt("cjiRecursiveStatus")
          val tmp_cjiConnectivityNeeded = _resultSet.getBoolean("cjiConnectivityNeeded")
          val tmp_cjiPluginId = _resultSet.getInt("cjiPluginId")
          val tmp_cjiAttemptCount = _resultSet.getInt("cjiAttemptCount")
          val tmp_cjiParentCjiUid = _resultSet.getLong("cjiParentCjiUid")
          val tmp_cjiServerJobId = _resultSet.getLong("cjiServerJobId")
          val tmp_cjiStartTime = _resultSet.getLong("cjiStartTime")
          val tmp_cjiFinishTime = _resultSet.getLong("cjiFinishTime")
          val tmp_cjiUploadSessionUid = _resultSet.getString("cjiUploadSessionUid")
          val tmp_cjiContentDeletedOnCancellation =
              _resultSet.getBoolean("cjiContentDeletedOnCancellation")
          val tmp_cjiContainerProcessed = _resultSet.getBoolean("cjiContainerProcessed")
          val _entity = ContentJobItem()
          _entity.cjiUid = tmp_cjiUid
          _entity.cjiJobUid = tmp_cjiJobUid
          _entity.sourceUri = tmp_sourceUri
          _entity.cjiIsLeaf = tmp_cjiIsLeaf
          _entity.cjiContentEntryUid = tmp_cjiContentEntryUid
          _entity.cjiParentContentEntryUid = tmp_cjiParentContentEntryUid
          _entity.cjiContainerUid = tmp_cjiContainerUid
          _entity.cjiItemProgress = tmp_cjiItemProgress
          _entity.cjiItemTotal = tmp_cjiItemTotal
          _entity.cjiRecursiveProgress = tmp_cjiRecursiveProgress
          _entity.cjiRecursiveTotal = tmp_cjiRecursiveTotal
          _entity.cjiStatus = tmp_cjiStatus
          _entity.cjiRecursiveStatus = tmp_cjiRecursiveStatus
          _entity.cjiConnectivityNeeded = tmp_cjiConnectivityNeeded
          _entity.cjiPluginId = tmp_cjiPluginId
          _entity.cjiAttemptCount = tmp_cjiAttemptCount
          _entity.cjiParentCjiUid = tmp_cjiParentCjiUid
          _entity.cjiServerJobId = tmp_cjiServerJobId
          _entity.cjiStartTime = tmp_cjiStartTime
          _entity.cjiFinishTime = tmp_cjiFinishTime
          _entity.cjiUploadSessionUid = tmp_cjiUploadSessionUid
          _entity.cjiContentDeletedOnCancellation = tmp_cjiContentDeletedOnCancellation
          _entity.cjiContainerProcessed = tmp_cjiContainerProcessed
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun getJobItemByUidLive(uid: Long): DoorLiveData<ContentJobItem?> {
    val _result = DoorLiveDataImpl<ContentJobItem?>(_db, listOf("ContentJobItem"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ContentJobItem??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT *
      |          FROM ContentJobItem
      |         WHERE cjiUid = ?   
      |    
      """.trimMargin() , postgreSql = """
      |
      |        SELECT *
      |          FROM ContentJobItem
      |         WHERE cjiUid = ?   
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_cjiUid = _resultSet.getLong("cjiUid")
            val tmp_cjiJobUid = _resultSet.getLong("cjiJobUid")
            val tmp_sourceUri = _resultSet.getString("sourceUri")
            val tmp_cjiIsLeaf = _resultSet.getBoolean("cjiIsLeaf")
            val tmp_cjiContentEntryUid = _resultSet.getLong("cjiContentEntryUid")
            val tmp_cjiParentContentEntryUid = _resultSet.getLong("cjiParentContentEntryUid")
            val tmp_cjiContainerUid = _resultSet.getLong("cjiContainerUid")
            val tmp_cjiItemProgress = _resultSet.getLong("cjiItemProgress")
            val tmp_cjiItemTotal = _resultSet.getLong("cjiItemTotal")
            val tmp_cjiRecursiveProgress = _resultSet.getLong("cjiRecursiveProgress")
            val tmp_cjiRecursiveTotal = _resultSet.getLong("cjiRecursiveTotal")
            val tmp_cjiStatus = _resultSet.getInt("cjiStatus")
            val tmp_cjiRecursiveStatus = _resultSet.getInt("cjiRecursiveStatus")
            val tmp_cjiConnectivityNeeded = _resultSet.getBoolean("cjiConnectivityNeeded")
            val tmp_cjiPluginId = _resultSet.getInt("cjiPluginId")
            val tmp_cjiAttemptCount = _resultSet.getInt("cjiAttemptCount")
            val tmp_cjiParentCjiUid = _resultSet.getLong("cjiParentCjiUid")
            val tmp_cjiServerJobId = _resultSet.getLong("cjiServerJobId")
            val tmp_cjiStartTime = _resultSet.getLong("cjiStartTime")
            val tmp_cjiFinishTime = _resultSet.getLong("cjiFinishTime")
            val tmp_cjiUploadSessionUid = _resultSet.getString("cjiUploadSessionUid")
            val tmp_cjiContentDeletedOnCancellation =
                _resultSet.getBoolean("cjiContentDeletedOnCancellation")
            val tmp_cjiContainerProcessed = _resultSet.getBoolean("cjiContainerProcessed")
            val _entity = ContentJobItem()
            _entity.cjiUid = tmp_cjiUid
            _entity.cjiJobUid = tmp_cjiJobUid
            _entity.sourceUri = tmp_sourceUri
            _entity.cjiIsLeaf = tmp_cjiIsLeaf
            _entity.cjiContentEntryUid = tmp_cjiContentEntryUid
            _entity.cjiParentContentEntryUid = tmp_cjiParentContentEntryUid
            _entity.cjiContainerUid = tmp_cjiContainerUid
            _entity.cjiItemProgress = tmp_cjiItemProgress
            _entity.cjiItemTotal = tmp_cjiItemTotal
            _entity.cjiRecursiveProgress = tmp_cjiRecursiveProgress
            _entity.cjiRecursiveTotal = tmp_cjiRecursiveTotal
            _entity.cjiStatus = tmp_cjiStatus
            _entity.cjiRecursiveStatus = tmp_cjiRecursiveStatus
            _entity.cjiConnectivityNeeded = tmp_cjiConnectivityNeeded
            _entity.cjiPluginId = tmp_cjiPluginId
            _entity.cjiAttemptCount = tmp_cjiAttemptCount
            _entity.cjiParentCjiUid = tmp_cjiParentCjiUid
            _entity.cjiServerJobId = tmp_cjiServerJobId
            _entity.cjiStartTime = tmp_cjiStartTime
            _entity.cjiFinishTime = tmp_cjiFinishTime
            _entity.cjiUploadSessionUid = tmp_cjiUploadSessionUid
            _entity.cjiContentDeletedOnCancellation = tmp_cjiContentDeletedOnCancellation
            _entity.cjiContainerProcessed = tmp_cjiContainerProcessed
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun getContainerUidByJobItemUid(uid: Long): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT cjiContainerUid
    |          FROM ContentJobItem
    |         WHERE cjiUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT cjiContainerUid
    |          FROM ContentJobItem
    |         WHERE cjiUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAllStatusesByJobUid(jobUid: Long, newStatus: Int): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE ContentJobItem
    |           SET cjiStatus = ?
    |         WHERE cjiJobUid = ?
    |           AND cjiStatus != ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE ContentJobItem
    |           SET cjiStatus = ?
    |         WHERE cjiJobUid = ?
    |           AND cjiStatus != ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, newStatus)
      _stmt.setLong(2, jobUid)
      _stmt.setInt(3, newStatus)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findAllContainersByJobUid(jobUid: Long): List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ContentJobItem.cjiContainerUid
    |          FROM ContentJobItem
    |         WHERE cjiJobUid = ? 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ContentJobItem.cjiContainerUid
    |          FROM ContentJobItem
    |         WHERE cjiJobUid = ? 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, jobUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }
}
