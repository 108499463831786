package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.AccessToken
import com.ustadmobile.lib.db.entities.AuditLog
import com.ustadmobile.lib.db.entities.Person
import com.ustadmobile.lib.db.entities.PersonAuth
import com.ustadmobile.lib.db.entities.PersonGroup
import com.ustadmobile.lib.db.entities.PersonGroupMember
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonWithAccount
import com.ustadmobile.lib.db.entities.PersonWithDisplayDetails
import com.ustadmobile.lib.db.entities.PersonWithPersonParentJoin
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonDao_JdbcKt(
  public val _db: DoorDatabase
) : PersonDao() {
  public val _insertAdapterPerson_: EntityInsertionAdapter<Person> = object :
      EntityInsertionAdapter<Person>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Person (personUid, username, firstNames, lastName, emailAddr, phoneNum, gender, active, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, dateOfBirth, personAddress, personOrgId, personGroupUid, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, personLct, personCountry, personType) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Person (personUid, username, firstNames, lastName, emailAddr, phoneNum, gender, active, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, dateOfBirth, personAddress, personOrgId, personGroupUid, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, personLct, personCountry, personType) VALUES(COALESCE(?,nextval('Person_personUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING personUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Person): Unit {
      if(entity.personUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personUid)
      }
      stmt.setString(2, entity.username)
      stmt.setString(3, entity.firstNames)
      stmt.setString(4, entity.lastName)
      stmt.setString(5, entity.emailAddr)
      stmt.setString(6, entity.phoneNum)
      stmt.setInt(7, entity.gender)
      stmt.setBoolean(8, entity.active)
      stmt.setBoolean(9, entity.admin)
      stmt.setString(10, entity.personNotes)
      stmt.setString(11, entity.fatherName)
      stmt.setString(12, entity.fatherNumber)
      stmt.setString(13, entity.motherName)
      stmt.setString(14, entity.motherNum)
      stmt.setLong(15, entity.dateOfBirth)
      stmt.setString(16, entity.personAddress)
      stmt.setString(17, entity.personOrgId)
      stmt.setLong(18, entity.personGroupUid)
      stmt.setLong(19, entity.personMasterChangeSeqNum)
      stmt.setLong(20, entity.personLocalChangeSeqNum)
      stmt.setInt(21, entity.personLastChangedBy)
      stmt.setLong(22, entity.personLct)
      stmt.setString(23, entity.personCountry)
      stmt.setInt(24, entity.personType)
    }
  }

  public val _insertAdapterPerson_upsert: EntityInsertionAdapter<Person> = object :
      EntityInsertionAdapter<Person>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO Person (personUid, username, firstNames, lastName, emailAddr, phoneNum, gender, active, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, dateOfBirth, personAddress, personOrgId, personGroupUid, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, personLct, personCountry, personType) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Person (personUid, username, firstNames, lastName, emailAddr, phoneNum, gender, active, admin, personNotes, fatherName, fatherNumber, motherName, motherNum, dateOfBirth, personAddress, personOrgId, personGroupUid, personMasterChangeSeqNum, personLocalChangeSeqNum, personLastChangedBy, personLct, personCountry, personType) VALUES(COALESCE(?,nextval('Person_personUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (personUid) DO UPDATE SET username = excluded.username,firstNames = excluded.firstNames,lastName = excluded.lastName,emailAddr = excluded.emailAddr,phoneNum = excluded.phoneNum,gender = excluded.gender,active = excluded.active,admin = excluded.admin,personNotes = excluded.personNotes,fatherName = excluded.fatherName,fatherNumber = excluded.fatherNumber,motherName = excluded.motherName,motherNum = excluded.motherNum,dateOfBirth = excluded.dateOfBirth,personAddress = excluded.personAddress,personOrgId = excluded.personOrgId,personGroupUid = excluded.personGroupUid,personMasterChangeSeqNum = excluded.personMasterChangeSeqNum,personLocalChangeSeqNum = excluded.personLocalChangeSeqNum,personLastChangedBy = excluded.personLastChangedBy,personLct = excluded.personLct,personCountry = excluded.personCountry,personType = excluded.personType" + if(returnsId) { " RETURNING personUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Person): Unit {
      if(entity.personUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personUid)
      }
      stmt.setString(2, entity.username)
      stmt.setString(3, entity.firstNames)
      stmt.setString(4, entity.lastName)
      stmt.setString(5, entity.emailAddr)
      stmt.setString(6, entity.phoneNum)
      stmt.setInt(7, entity.gender)
      stmt.setBoolean(8, entity.active)
      stmt.setBoolean(9, entity.admin)
      stmt.setString(10, entity.personNotes)
      stmt.setString(11, entity.fatherName)
      stmt.setString(12, entity.fatherNumber)
      stmt.setString(13, entity.motherName)
      stmt.setString(14, entity.motherNum)
      stmt.setLong(15, entity.dateOfBirth)
      stmt.setString(16, entity.personAddress)
      stmt.setString(17, entity.personOrgId)
      stmt.setLong(18, entity.personGroupUid)
      stmt.setLong(19, entity.personMasterChangeSeqNum)
      stmt.setLong(20, entity.personLocalChangeSeqNum)
      stmt.setInt(21, entity.personLastChangedBy)
      stmt.setLong(22, entity.personLct)
      stmt.setString(23, entity.personCountry)
      stmt.setInt(24, entity.personType)
    }
  }

  public val _insertAdapterAccessToken_: EntityInsertionAdapter<AccessToken> = object :
      EntityInsertionAdapter<AccessToken>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO AccessToken (token, accessTokenPersonUid, expires) VALUES(?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO AccessToken (token, accessTokenPersonUid, expires) VALUES(?, ?, ?)" + if(returnsId) { " RETURNING token" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: AccessToken):
        Unit {
      stmt.setString(1, entity.token)
      stmt.setLong(2, entity.accessTokenPersonUid)
      stmt.setLong(3, entity.expires)
    }
  }

  public val _insertAdapterPersonAuth_: EntityInsertionAdapter<PersonAuth> = object :
      EntityInsertionAdapter<PersonAuth>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO PersonAuth (personAuthUid, passwordHash, personAuthStatus) VALUES(?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO PersonAuth (personAuthUid, passwordHash, personAuthStatus) VALUES(COALESCE(?,nextval('PersonAuth_personAuthUid_seq')), ?, ?)" + if(returnsId) { " RETURNING personAuthUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonAuth):
        Unit {
      if(entity.personAuthUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.personAuthUid)
      }
      stmt.setString(2, entity.passwordHash)
      stmt.setInt(3, entity.personAuthStatus)
    }
  }

  public val _insertAdapterPersonGroup_: EntityInsertionAdapter<PersonGroup> = object :
      EntityInsertionAdapter<PersonGroup>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO PersonGroup (groupUid, groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO PersonGroup (groupUid, groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag) VALUES(COALESCE(?,nextval('PersonGroup_groupUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING groupUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonGroup):
        Unit {
      if(entity.groupUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupUid)
      }
      stmt.setLong(2, entity.groupMasterCsn)
      stmt.setLong(3, entity.groupLocalCsn)
      stmt.setInt(4, entity.groupLastChangedBy)
      stmt.setLong(5, entity.groupLct)
      stmt.setString(6, entity.groupName)
      stmt.setBoolean(7, entity.groupActive)
      stmt.setInt(8, entity.personGroupFlag)
    }
  }

  public val _insertAdapterPersonGroupMember_: EntityInsertionAdapter<PersonGroupMember> = object :
      EntityInsertionAdapter<PersonGroupMember>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO PersonGroupMember (groupMemberUid, groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO PersonGroupMember (groupMemberUid, groupMemberActive, groupMemberPersonUid, groupMemberGroupUid, groupMemberMasterCsn, groupMemberLocalCsn, groupMemberLastChangedBy, groupMemberLct) VALUES(COALESCE(?,nextval('PersonGroupMember_groupMemberUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING groupMemberUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: PersonGroupMember): Unit {
      if(entity.groupMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupMemberUid)
      }
      stmt.setBoolean(2, entity.groupMemberActive)
      stmt.setLong(3, entity.groupMemberPersonUid)
      stmt.setLong(4, entity.groupMemberGroupUid)
      stmt.setLong(5, entity.groupMemberMasterCsn)
      stmt.setLong(6, entity.groupMemberLocalCsn)
      stmt.setInt(7, entity.groupMemberLastChangedBy)
      stmt.setLong(8, entity.groupMemberLct)
    }
  }

  public val _insertAdapterAuditLog_: EntityInsertionAdapter<AuditLog> = object :
      EntityInsertionAdapter<AuditLog>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO AuditLog (auditLogUid, auditLogMasterChangeSeqNum, auditLogLocalChangeSeqNum, auditLogLastChangedBy, auditLogLct, auditLogActorPersonUid, auditLogTableUid, auditLogEntityUid, auditLogDate, notes) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO AuditLog (auditLogUid, auditLogMasterChangeSeqNum, auditLogLocalChangeSeqNum, auditLogLastChangedBy, auditLogLct, auditLogActorPersonUid, auditLogTableUid, auditLogEntityUid, auditLogDate, notes) VALUES(COALESCE(?,nextval('AuditLog_auditLogUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING auditLogUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: AuditLog): Unit {
      if(entity.auditLogUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.auditLogUid)
      }
      stmt.setLong(2, entity.auditLogMasterChangeSeqNum)
      stmt.setLong(3, entity.auditLogLocalChangeSeqNum)
      stmt.setInt(4, entity.auditLogLastChangedBy)
      stmt.setLong(5, entity.auditLogLct)
      stmt.setLong(6, entity.auditLogActorPersonUid)
      stmt.setInt(7, entity.auditLogTableUid)
      stmt.setLong(8, entity.auditLogEntityUid)
      stmt.setLong(9, entity.auditLogDate)
      stmt.setString(10, entity.notes)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO PersonReplicate(personPk, personDestination)
    |      SELECT DISTINCT Person.personUid AS personUid,
    |             ? AS personDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                   
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                   64
    |                   
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |       WHERE Person.personType = 0
    |         AND UserSession.usClientNodeId = ?
    |         AND Person.personLct != COALESCE(
    |             (SELECT personVersionId
    |                FROM PersonReplicate
    |               WHERE personPk = Person.personUid
    |                 AND personDestination = ?), 0)              
    |      /*psql ON CONFLICT(personPk, personDestination) DO UPDATE
    |             SET personPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonReplicate(personPk, personDestination)
    |      SELECT DISTINCT Person.personUid AS personUid,
    |             ? AS personDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                   
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                   64
    |                   
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |       WHERE Person.personType = 0
    |         AND UserSession.usClientNodeId = ?
    |         AND Person.personLct != COALESCE(
    |             (SELECT personVersionId
    |                FROM PersonReplicate
    |               WHERE personPk = Person.personUid
    |                 AND personDestination = ?), 0)              
    |       ON CONFLICT(personPk, personDestination) DO UPDATE
    |             SET personPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonReplicate(personPk, personDestination)
    |  SELECT DISTINCT Person.personUid AS personUid,
    |         UserSession.usClientNodeId AS personDestination
    |    FROM ChangeLog
    |         JOIN Person
    |             ON ChangeLog.chTableId = 9
    |                AND ChangeLog.chEntityPk = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |            64
    |            
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE Person.personType = 0
    |     AND UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Person.personLct != COALESCE(
    |         (SELECT personVersionId
    |            FROM PersonReplicate
    |           WHERE personPk = Person.personUid
    |             AND personDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(personPk, personDestination) DO UPDATE
    |     SET personPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonReplicate(personPk, personDestination)
    |  SELECT DISTINCT Person.personUid AS personUid,
    |         UserSession.usClientNodeId AS personDestination
    |    FROM ChangeLog
    |         JOIN Person
    |             ON ChangeLog.chTableId = 9
    |                AND ChangeLog.chEntityPk = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |            64
    |            
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE Person.personType = 0
    |     AND UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Person.personLct != COALESCE(
    |         (SELECT personVersionId
    |            FROM PersonReplicate
    |           WHERE personPk = Person.personUid
    |             AND personDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(personPk, personDestination) DO UPDATE
    |     SET personPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out Person>): Unit {
    _insertAdapterPerson_.insertListAsync(entityList)
  }

  public override suspend fun insertOrReplace(person: Person): Unit {
    _insertAdapterPerson_upsert.insertAsync(person)
  }

  public override suspend fun findByUsernameCount(username: String): Int {
    var _result = 0
    val _stmtConfig =
        PreparedStatementConfig("SELECT COUNT(*) FROM Person where Person.username = ?" , postgreSql
        = """
    |SELECT COUNT(*) FROM Person where Person.username = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, username)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getInt(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun isValidToken(token: String, personUid: Long): Boolean {
    var _result = false
    val _stmtConfig =
        PreparedStatementConfig("SELECT EXISTS(SELECT token FROM AccessToken WHERE token = ?  and accessTokenPersonUid = ?)"
        , postgreSql = """
    |SELECT EXISTS(SELECT token FROM AccessToken WHERE token = ?  and accessTokenPersonUid = ?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, token)
      _stmt.setLong(2, personUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insertAccessToken(token: AccessToken): Unit {
    _insertAdapterAccessToken_.insert(token)
  }

  public override suspend fun findUidAndPasswordHashAsync(username: String):
      PersonDao.PersonUidAndPasswordHash? {
    var _result = null as com.ustadmobile.core.db.dao.PersonDao.PersonUidAndPasswordHash??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Person.personUid, Person.admin, Person.firstNames, Person.lastName, 
    |               PersonAuth.passwordHash
    |          FROM Person
    |               JOIN PersonAuth
    |                    ON Person.personUid = PersonAuth.personAuthUid
    |         WHERE Person.username = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Person.personUid, Person.admin, Person.firstNames, Person.lastName, 
    |               PersonAuth.passwordHash
    |          FROM Person
    |               JOIN PersonAuth
    |                    ON Person.personUid = PersonAuth.personAuthUid
    |         WHERE Person.username = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, username)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_passwordHash = _resultSet.getString("passwordHash")
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_admin = _resultSet.getBoolean("admin")
          val _entity = PersonDao.PersonUidAndPasswordHash()
          _entity.passwordHash = tmp_passwordHash
          _entity.personUid = tmp_personUid
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.admin = tmp_admin
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUsernameAndPasswordHash2(username: String,
      passwordHash: String): Person? {
    var _result = null as com.ustadmobile.lib.db.entities.Person??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Person.*
    |          FROM Person
    |               JOIN PersonAuth2
    |                    ON Person.personUid = PersonAuth2.pauthUid
    |         WHERE Person.username = ? 
    |               AND PersonAuth2.pauthAuth = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Person.*
    |          FROM Person
    |               JOIN PersonAuth2
    |                    ON Person.personUid = PersonAuth2.pauthUid
    |         WHERE Person.username = ? 
    |               AND PersonAuth2.pauthAuth = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, username)
      _stmt.setString(2, passwordHash)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insertPersonAuth(personAuth: PersonAuth): Unit {
    _insertAdapterPersonAuth_.insert(personAuth)
  }

  public override suspend fun personHasPermissionAsync(
    accountPersonUid: Long,
    personUid: Long,
    permission: Long
  ): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT EXISTS(
    |                SELECT 1
    |                  FROM Person
    |                  JOIN ScopedGrant
    |                       ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |                  JOIN PersonGroupMember 
    |                       ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                 WHERE Person.personUid = ?
    |                   AND (ScopedGrant.sgPermissions & ?) > 0
    |                   AND PersonGroupMember.groupMemberPersonUid = ?
    |                 LIMIT 1)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT EXISTS(
    |                SELECT 1
    |                  FROM Person
    |                  JOIN ScopedGrant
    |                       ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |                  JOIN PersonGroupMember 
    |                       ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                 WHERE Person.personUid = ?
    |                   AND (ScopedGrant.sgPermissions & ?) > 0
    |                   AND PersonGroupMember.groupMemberPersonUid = ?
    |                 LIMIT 1)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, personUid)
      _stmt.setLong(2, permission)
      _stmt.setLong(3, accountPersonUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun personIsAdmin(accountPersonUid: Long): Boolean {
    var _result = false
    val _stmtConfig =
        PreparedStatementConfig("SELECT COALESCE((SELECT admin FROM Person WHERE personUid = ?), 0)"
        , postgreSql = "SELECT COALESCE((SELECT admin FROM Person WHERE personUid = ?), FALSE)")
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, accountPersonUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUsername(username: String?): Person? {
    var _result = null as com.ustadmobile.lib.db.entities.Person??
    val _stmtConfig =
        PreparedStatementConfig("SELECT Person.* FROM PERSON Where Person.username = ?" , postgreSql
        = """
    |SELECT Person.* FROM PERSON Where Person.username = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, username)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findSystemAccount(nodeId: Long): Person? {
    var _result = null as com.ustadmobile.lib.db.entities.Person??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Person.*
    |          FROM Person
    |         WHERE Person.dateOfBirth = ?
    |           AND Person.personType = 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Person.*
    |          FROM Person
    |         WHERE Person.dateOfBirth = ?
    |           AND Person.personType = 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, nodeId)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUid(uid: Long): Person? {
    var _result = null as com.ustadmobile.lib.db.entities.Person??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM PERSON WHERE Person.personUid = ?" ,
        postgreSql = """
    |SELECT * FROM PERSON WHERE Person.personUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findPersonAccountByUid(uid: Long): PersonWithAccount? {
    var _result = null as com.ustadmobile.lib.db.entities.PersonWithAccount??
    val _stmtConfig =
        PreparedStatementConfig("SELECT Person.*, null as newPassword, null as currentPassword,null as confirmedPassword FROM PERSON WHERE Person.personUid = ?"
        , postgreSql = """
    |SELECT Person.*, null as newPassword, null as currentPassword,null as confirmedPassword FROM PERSON WHERE Person.personUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_currentPassword = _resultSet.getString("currentPassword")
          val tmp_newPassword = _resultSet.getString("newPassword")
          val tmp_confirmedPassword = _resultSet.getString("confirmedPassword")
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = PersonWithAccount()
          _entity.currentPassword = tmp_currentPassword
          _entity.newPassword = tmp_newPassword
          _entity.confirmedPassword = tmp_confirmedPassword
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<Person?> {
    val _result = DoorLiveDataImpl<Person?>(_db, listOf("Person"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.Person??
      val _stmtConfig = PreparedStatementConfig("SELECT * From Person WHERE personUid = ?" ,
          postgreSql = """
      |SELECT * From Person WHERE personUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_personUid = _resultSet.getLong("personUid")
            val tmp_username = _resultSet.getString("username")
            val tmp_firstNames = _resultSet.getString("firstNames")
            val tmp_lastName = _resultSet.getString("lastName")
            val tmp_emailAddr = _resultSet.getString("emailAddr")
            val tmp_phoneNum = _resultSet.getString("phoneNum")
            val tmp_gender = _resultSet.getInt("gender")
            val tmp_active = _resultSet.getBoolean("active")
            val tmp_admin = _resultSet.getBoolean("admin")
            val tmp_personNotes = _resultSet.getString("personNotes")
            val tmp_fatherName = _resultSet.getString("fatherName")
            val tmp_fatherNumber = _resultSet.getString("fatherNumber")
            val tmp_motherName = _resultSet.getString("motherName")
            val tmp_motherNum = _resultSet.getString("motherNum")
            val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
            val tmp_personAddress = _resultSet.getString("personAddress")
            val tmp_personOrgId = _resultSet.getString("personOrgId")
            val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
            val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
            val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
            val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
            val tmp_personLct = _resultSet.getLong("personLct")
            val tmp_personCountry = _resultSet.getString("personCountry")
            val tmp_personType = _resultSet.getInt("personType")
            val _entity = Person()
            _entity.personUid = tmp_personUid
            _entity.username = tmp_username
            _entity.firstNames = tmp_firstNames
            _entity.lastName = tmp_lastName
            _entity.emailAddr = tmp_emailAddr
            _entity.phoneNum = tmp_phoneNum
            _entity.gender = tmp_gender
            _entity.active = tmp_active
            _entity.admin = tmp_admin
            _entity.personNotes = tmp_personNotes
            _entity.fatherName = tmp_fatherName
            _entity.fatherNumber = tmp_fatherNumber
            _entity.motherName = tmp_motherName
            _entity.motherNum = tmp_motherNum
            _entity.dateOfBirth = tmp_dateOfBirth
            _entity.personAddress = tmp_personAddress
            _entity.personOrgId = tmp_personOrgId
            _entity.personGroupUid = tmp_personGroupUid
            _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.personLastChangedBy = tmp_personLastChangedBy
            _entity.personLct = tmp_personLct
            _entity.personCountry = tmp_personCountry
            _entity.personType = tmp_personType
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): Person? {
    var _result = null as com.ustadmobile.lib.db.entities.Person??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Person WHERE personUid = ?" ,
        postgreSql = """
    |SELECT * FROM Person WHERE personUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: Person): Int {
    var _result = 0
    val _sql =
        "UPDATE Person SET username = ?, firstNames = ?, lastName = ?, emailAddr = ?, phoneNum = ?, gender = ?, active = ?, admin = ?, personNotes = ?, fatherName = ?, fatherNumber = ?, motherName = ?, motherNum = ?, dateOfBirth = ?, personAddress = ?, personOrgId = ?, personGroupUid = ?, personMasterChangeSeqNum = ?, personLocalChangeSeqNum = ?, personLastChangedBy = ?, personLct = ?, personCountry = ?, personType = ? WHERE personUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.username)
      _stmt.setString(2, entity.firstNames)
      _stmt.setString(3, entity.lastName)
      _stmt.setString(4, entity.emailAddr)
      _stmt.setString(5, entity.phoneNum)
      _stmt.setInt(6, entity.gender)
      _stmt.setBoolean(7, entity.active)
      _stmt.setBoolean(8, entity.admin)
      _stmt.setString(9, entity.personNotes)
      _stmt.setString(10, entity.fatherName)
      _stmt.setString(11, entity.fatherNumber)
      _stmt.setString(12, entity.motherName)
      _stmt.setString(13, entity.motherNum)
      _stmt.setLong(14, entity.dateOfBirth)
      _stmt.setString(15, entity.personAddress)
      _stmt.setString(16, entity.personOrgId)
      _stmt.setLong(17, entity.personGroupUid)
      _stmt.setLong(18, entity.personMasterChangeSeqNum)
      _stmt.setLong(19, entity.personLocalChangeSeqNum)
      _stmt.setInt(20, entity.personLastChangedBy)
      _stmt.setLong(21, entity.personLct)
      _stmt.setString(22, entity.personCountry)
      _stmt.setInt(23, entity.personType)
      _stmt.setLong(24, entity.personUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun insertPersonGroup(personGroup: PersonGroup): Long {
    val _retVal = _insertAdapterPersonGroup_.insertAndReturnIdAsync(personGroup)
    return _retVal
  }

  public override suspend fun insertPersonGroupMember(personGroupMember: PersonGroupMember): Long {
    val _retVal = _insertAdapterPersonGroupMember_.insertAndReturnIdAsync(personGroupMember)
    return _retVal
  }

  public override fun findPersonsWithPermission(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?
  ): DoorDataSourceFactory<Int, PersonWithDisplayDetails> {
    val _result = object : DoorDataSourceFactory<Int, PersonWithDisplayDetails>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<PersonWithDisplayDetails>> =
          DoorLiveDataImpl<List<PersonWithDisplayDetails>>(_db, listOf("PersonGroupMember",
          "ScopedGrant", "Person", "ClazzEnrolment", "SchoolMember"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.PersonWithDisplayDetails>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |         SELECT Person.* 
        |           FROM PersonGroupMember 
        |                
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions &
        |                    64
        |                    
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND (? = 0 OR ? NOT IN
        |                    (SELECT clazzEnrolmentClazzUid 
        |                       FROM ClazzEnrolment 
        |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
        |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
        |           AND ClazzEnrolment.clazzEnrolmentActive))
        |           AND (? = 0 OR ? NOT IN
        |                    (SELECT schoolMemberSchoolUid
        |                      FROM SchoolMember 
        |                     WHERE schoolMemberPersonUid = Person.personUid 
        |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
        |                            AND SchoolMember.schoolMemberLeftDate ))
        |           AND Person.personType = 0                  
        |           AND (Person.personUid NOT IN (?))
        |           AND (? = '%' 
        |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
        |      GROUP BY Person.personUid
        |      ORDER BY CASE(?)
        |               WHEN 1 THEN Person.firstNames
        |               WHEN 3 THEN Person.lastName
        |               ELSE ''
        |               END ASC,
        |               CASE(?)
        |               WHEN 2 THEN Person.firstNames
        |               WHEN 4 THEN Person.lastName
        |               ELSE ''
        |               END DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT * FROM (
        |         SELECT Person.* 
        |           FROM PersonGroupMember 
        |                
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions &
        |                    64
        |                    
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND (? = 0 OR ? NOT IN
        |                    (SELECT clazzEnrolmentClazzUid 
        |                       FROM ClazzEnrolment 
        |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
        |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
        |           AND ClazzEnrolment.clazzEnrolmentActive))
        |           AND (? = 0 OR ? NOT IN
        |                    (SELECT schoolMemberSchoolUid
        |                      FROM SchoolMember 
        |                     WHERE schoolMemberPersonUid = Person.personUid 
        |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
        |                            AND SchoolMember.schoolMemberLeftDate ))
        |           AND Person.personType = 0                  
        |           AND (Person.personUid NOT IN (?))
        |           AND (? = '%' 
        |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
        |      GROUP BY Person.personUid
        |      ORDER BY CASE(?)
        |               WHEN 1 THEN Person.firstNames
        |               WHEN 3 THEN Person.lastName
        |               ELSE ''
        |               END ASC,
        |               CASE(?)
        |               WHEN 2 THEN Person.firstNames
        |               WHEN 4 THEN Person.lastName
        |               ELSE ''
        |               END DESC
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, excludeClazz)
          _stmt.setLong(3, excludeClazz)
          _stmt.setLong(4, timestamp)
          _stmt.setLong(5, excludeSchool)
          _stmt.setLong(6, excludeSchool)
          _stmt.setLong(7, timestamp)
          _stmt.setArray(8, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              excludeSelected.toTypedArray()))
          _stmt.setString(9, searchText)
          _stmt.setString(10, searchText)
          _stmt.setInt(11, sortOrder)
          _stmt.setInt(12, sortOrder)
          _stmt.setInt(13, _limit)
          _stmt.setInt(14, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_personUid = _resultSet.getLong("personUid")
              val tmp_username = _resultSet.getString("username")
              val tmp_firstNames = _resultSet.getString("firstNames")
              val tmp_lastName = _resultSet.getString("lastName")
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              val tmp_gender = _resultSet.getInt("gender")
              val tmp_active = _resultSet.getBoolean("active")
              val tmp_admin = _resultSet.getBoolean("admin")
              val tmp_personNotes = _resultSet.getString("personNotes")
              val tmp_fatherName = _resultSet.getString("fatherName")
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              val tmp_motherName = _resultSet.getString("motherName")
              val tmp_motherNum = _resultSet.getString("motherNum")
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              val tmp_personAddress = _resultSet.getString("personAddress")
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              val tmp_personLct = _resultSet.getLong("personLct")
              val tmp_personCountry = _resultSet.getString("personCountry")
              val tmp_personType = _resultSet.getInt("personType")
              val _entity = PersonWithDisplayDetails()
              _entity.personUid = tmp_personUid
              _entity.username = tmp_username
              _entity.firstNames = tmp_firstNames
              _entity.lastName = tmp_lastName
              _entity.emailAddr = tmp_emailAddr
              _entity.phoneNum = tmp_phoneNum
              _entity.gender = tmp_gender
              _entity.active = tmp_active
              _entity.admin = tmp_admin
              _entity.personNotes = tmp_personNotes
              _entity.fatherName = tmp_fatherName
              _entity.fatherNumber = tmp_fatherNumber
              _entity.motherName = tmp_motherName
              _entity.motherNum = tmp_motherNum
              _entity.dateOfBirth = tmp_dateOfBirth
              _entity.personAddress = tmp_personAddress
              _entity.personOrgId = tmp_personOrgId
              _entity.personGroupUid = tmp_personGroupUid
              _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
              _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
              _entity.personLastChangedBy = tmp_personLastChangedBy
              _entity.personLct = tmp_personLct
              _entity.personCountry = tmp_personCountry
              _entity.personType = tmp_personType
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("PersonGroupMember", "ScopedGrant", "Person", "ClazzEnrolment", "SchoolMember"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |         SELECT Person.* 
        |           FROM PersonGroupMember 
        |                
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions &
        |                    64
        |                    
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND (? = 0 OR ? NOT IN
        |                    (SELECT clazzEnrolmentClazzUid 
        |                       FROM ClazzEnrolment 
        |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
        |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
        |           AND ClazzEnrolment.clazzEnrolmentActive))
        |           AND (? = 0 OR ? NOT IN
        |                    (SELECT schoolMemberSchoolUid
        |                      FROM SchoolMember 
        |                     WHERE schoolMemberPersonUid = Person.personUid 
        |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
        |                            AND SchoolMember.schoolMemberLeftDate ))
        |           AND Person.personType = 0                  
        |           AND (Person.personUid NOT IN (?))
        |           AND (? = '%' 
        |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
        |      GROUP BY Person.personUid
        |      ORDER BY CASE(?)
        |               WHEN 1 THEN Person.firstNames
        |               WHEN 3 THEN Person.lastName
        |               ELSE ''
        |               END ASC,
        |               CASE(?)
        |               WHEN 2 THEN Person.firstNames
        |               WHEN 4 THEN Person.lastName
        |               ELSE ''
        |               END DESC
        |    ) 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT COUNT(*) FROM (
        |         SELECT Person.* 
        |           FROM PersonGroupMember 
        |                
        |            JOIN ScopedGrant
        |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                    AND (ScopedGrant.sgPermissions &
        |                    64
        |                    
        |                                                    ) > 0
        |            JOIN Person 
        |                 ON 
        |                ((ScopedGrant.sgTableId = -2
        |                    AND ScopedGrant.sgEntityUid = -2)
        |                 OR (ScopedGrant.sgTableId = 9
        |                    AND ScopedGrant.sgEntityUid = Person.personUid)
        |                 OR (ScopedGrant.sgTableId = 6       
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT clazzEnrolmentPersonUid
        |                          FROM ClazzEnrolment
        |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
        |                           AND ClazzEnrolment.clazzEnrolmentActive))
        |                 OR (ScopedGrant.sgTableId = 164
        |                    AND Person.personUid IN (
        |                        SELECT DISTINCT schoolMemberPersonUid
        |                          FROM SchoolMember
        |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
        |                           AND schoolMemberActive))
        |                           )    
        |        
        |        
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND (? = 0 OR ? NOT IN
        |                    (SELECT clazzEnrolmentClazzUid 
        |                       FROM ClazzEnrolment 
        |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
        |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
        |           AND ClazzEnrolment.clazzEnrolmentActive))
        |           AND (? = 0 OR ? NOT IN
        |                    (SELECT schoolMemberSchoolUid
        |                      FROM SchoolMember 
        |                     WHERE schoolMemberPersonUid = Person.personUid 
        |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
        |                            AND SchoolMember.schoolMemberLeftDate ))
        |           AND Person.personType = 0                  
        |           AND (Person.personUid NOT IN (?))
        |           AND (? = '%' 
        |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
        |      GROUP BY Person.personUid
        |      ORDER BY CASE(?)
        |               WHEN 1 THEN Person.firstNames
        |               WHEN 3 THEN Person.lastName
        |               ELSE ''
        |               END ASC,
        |               CASE(?)
        |               WHEN 2 THEN Person.firstNames
        |               WHEN 4 THEN Person.lastName
        |               ELSE ''
        |               END DESC
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, accountPersonUid)
          _stmt.setLong(2, excludeClazz)
          _stmt.setLong(3, excludeClazz)
          _stmt.setLong(4, timestamp)
          _stmt.setLong(5, excludeSchool)
          _stmt.setLong(6, excludeSchool)
          _stmt.setLong(7, timestamp)
          _stmt.setArray(8, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              excludeSelected.toTypedArray()))
          _stmt.setString(9, searchText)
          _stmt.setString(10, searchText)
          _stmt.setInt(11, sortOrder)
          _stmt.setInt(12, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findPersonsWithPermissionAsList(
    timestamp: Long,
    excludeClazz: Long,
    excludeSchool: Long,
    excludeSelected: List<Long>,
    accountPersonUid: Long,
    sortOrder: Int,
    searchText: String?
  ): List<Person> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Person>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         SELECT Person.* 
    |           FROM PersonGroupMember 
    |                
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |         WHERE PersonGroupMember.groupMemberPersonUid = ?
    |           AND PersonGroupMember.groupMemberActive 
    |           AND (? = 0 OR ? NOT IN
    |                    (SELECT clazzEnrolmentClazzUid 
    |                       FROM ClazzEnrolment 
    |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
    |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
    |           AND ClazzEnrolment.clazzEnrolmentActive))
    |           AND (? = 0 OR ? NOT IN
    |                    (SELECT schoolMemberSchoolUid
    |                      FROM SchoolMember 
    |                     WHERE schoolMemberPersonUid = Person.personUid 
    |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
    |                            AND SchoolMember.schoolMemberLeftDate ))
    |           AND Person.personType = 0                  
    |           AND (Person.personUid NOT IN (?))
    |           AND (? = '%' 
    |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
    |      GROUP BY Person.personUid
    |      ORDER BY CASE(?)
    |               WHEN 1 THEN Person.firstNames
    |               WHEN 3 THEN Person.lastName
    |               ELSE ''
    |               END ASC,
    |               CASE(?)
    |               WHEN 2 THEN Person.firstNames
    |               WHEN 4 THEN Person.lastName
    |               ELSE ''
    |               END DESC
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |         SELECT Person.* 
    |           FROM PersonGroupMember 
    |                
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |         WHERE PersonGroupMember.groupMemberPersonUid = ?
    |           AND PersonGroupMember.groupMemberActive 
    |           AND (? = 0 OR ? NOT IN
    |                    (SELECT clazzEnrolmentClazzUid 
    |                       FROM ClazzEnrolment 
    |                      WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                            AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
    |                                AND ClazzEnrolment.clazzEnrolmentDateLeft
    |           AND ClazzEnrolment.clazzEnrolmentActive))
    |           AND (? = 0 OR ? NOT IN
    |                    (SELECT schoolMemberSchoolUid
    |                      FROM SchoolMember 
    |                     WHERE schoolMemberPersonUid = Person.personUid 
    |                       AND ? BETWEEN SchoolMember.schoolMemberJoinDate
    |                            AND SchoolMember.schoolMemberLeftDate ))
    |           AND Person.personType = 0                  
    |           AND (Person.personUid NOT IN (?))
    |           AND (? = '%' 
    |               OR Person.firstNames || ' ' || Person.lastName LIKE ?)
    |      GROUP BY Person.personUid
    |      ORDER BY CASE(?)
    |               WHEN 1 THEN Person.firstNames
    |               WHEN 3 THEN Person.lastName
    |               ELSE ''
    |               END ASC,
    |               CASE(?)
    |               WHEN 2 THEN Person.firstNames
    |               WHEN 4 THEN Person.lastName
    |               ELSE ''
    |               END DESC
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, accountPersonUid)
      _stmt.setLong(2, excludeClazz)
      _stmt.setLong(3, excludeClazz)
      _stmt.setLong(4, timestamp)
      _stmt.setLong(5, excludeSchool)
      _stmt.setLong(6, excludeSchool)
      _stmt.setLong(7, timestamp)
      _stmt.setArray(8, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          excludeSelected.toTypedArray()))
      _stmt.setString(9, searchText)
      _stmt.setString(10, searchText)
      _stmt.setInt(11, sortOrder)
      _stmt.setInt(12, sortOrder)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByUidWithDisplayDetailsLive(personUid: Long, activeUserPersonUid: Long):
      DoorLiveData<PersonWithPersonParentJoin?> {
    val _result = DoorLiveDataImpl<PersonWithPersonParentJoin?>(_db, listOf("Person",
        "PersonParentJoin"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.PersonWithPersonParentJoin??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT Person.*, PersonParentJoin.* 
      |          FROM Person
      |     LEFT JOIN PersonParentJoin on ppjUid = (
      |                SELECT ppjUid 
      |                  FROM PersonParentJoin
      |                 WHERE ppjMinorPersonUid = ? 
      |                       AND ppjParentPersonUid = ? 
      |                LIMIT 1)     
      |         WHERE Person.personUid = ?
      |        
      """.trimMargin() , postgreSql = """
      |
      |        SELECT Person.*, PersonParentJoin.* 
      |          FROM Person
      |     LEFT JOIN PersonParentJoin on ppjUid = (
      |                SELECT ppjUid 
      |                  FROM PersonParentJoin
      |                 WHERE ppjMinorPersonUid = ? 
      |                       AND ppjParentPersonUid = ? 
      |                LIMIT 1)     
      |         WHERE Person.personUid = ?
      |        
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, personUid)
        _stmt.setLong(2, activeUserPersonUid)
        _stmt.setLong(3, personUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_personUid = _resultSet.getLong("personUid")
            val tmp_username = _resultSet.getString("username")
            val tmp_firstNames = _resultSet.getString("firstNames")
            val tmp_lastName = _resultSet.getString("lastName")
            val tmp_emailAddr = _resultSet.getString("emailAddr")
            val tmp_phoneNum = _resultSet.getString("phoneNum")
            val tmp_gender = _resultSet.getInt("gender")
            val tmp_active = _resultSet.getBoolean("active")
            val tmp_admin = _resultSet.getBoolean("admin")
            val tmp_personNotes = _resultSet.getString("personNotes")
            val tmp_fatherName = _resultSet.getString("fatherName")
            val tmp_fatherNumber = _resultSet.getString("fatherNumber")
            val tmp_motherName = _resultSet.getString("motherName")
            val tmp_motherNum = _resultSet.getString("motherNum")
            val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
            val tmp_personAddress = _resultSet.getString("personAddress")
            val tmp_personOrgId = _resultSet.getString("personOrgId")
            val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
            val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
            val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
            val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
            val tmp_personLct = _resultSet.getLong("personLct")
            val tmp_personCountry = _resultSet.getString("personCountry")
            val tmp_personType = _resultSet.getInt("personType")
            val _entity = PersonWithPersonParentJoin()
            _entity.personUid = tmp_personUid
            _entity.username = tmp_username
            _entity.firstNames = tmp_firstNames
            _entity.lastName = tmp_lastName
            _entity.emailAddr = tmp_emailAddr
            _entity.phoneNum = tmp_phoneNum
            _entity.gender = tmp_gender
            _entity.active = tmp_active
            _entity.admin = tmp_admin
            _entity.personNotes = tmp_personNotes
            _entity.fatherName = tmp_fatherName
            _entity.fatherNumber = tmp_fatherNumber
            _entity.motherName = tmp_motherName
            _entity.motherNum = tmp_motherNum
            _entity.dateOfBirth = tmp_dateOfBirth
            _entity.personAddress = tmp_personAddress
            _entity.personOrgId = tmp_personOrgId
            _entity.personGroupUid = tmp_personGroupUid
            _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.personLastChangedBy = tmp_personLastChangedBy
            _entity.personLct = tmp_personLct
            _entity.personCountry = tmp_personCountry
            _entity.personType = tmp_personType
            var _parentJoin_nullFieldCount = 0
            val tmp_ppjUid = _resultSet.getLong("ppjUid")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjPcsn = _resultSet.getLong("ppjPcsn")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjLcsn = _resultSet.getLong("ppjLcsn")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjLcb = _resultSet.getInt("ppjLcb")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjLct = _resultSet.getLong("ppjLct")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjParentPersonUid = _resultSet.getLong("ppjParentPersonUid")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjMinorPersonUid = _resultSet.getLong("ppjMinorPersonUid")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjRelationship = _resultSet.getInt("ppjRelationship")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjEmail = _resultSet.getString("ppjEmail")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjPhone = _resultSet.getString("ppjPhone")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjInactive = _resultSet.getBoolean("ppjInactive")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjStatus = _resultSet.getInt("ppjStatus")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjApprovalTiemstamp = _resultSet.getLong("ppjApprovalTiemstamp")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            val tmp_ppjApprovalIpAddr = _resultSet.getString("ppjApprovalIpAddr")
            if(_resultSet.wasNull()) { _parentJoin_nullFieldCount++ }
            if(_parentJoin_nullFieldCount < 14) {
              if(_entity.parentJoin == null) {
                _entity.parentJoin = PersonParentJoin()
              }
              _entity.parentJoin!!.ppjUid = tmp_ppjUid
              _entity.parentJoin!!.ppjPcsn = tmp_ppjPcsn
              _entity.parentJoin!!.ppjLcsn = tmp_ppjLcsn
              _entity.parentJoin!!.ppjLcb = tmp_ppjLcb
              _entity.parentJoin!!.ppjLct = tmp_ppjLct
              _entity.parentJoin!!.ppjParentPersonUid = tmp_ppjParentPersonUid
              _entity.parentJoin!!.ppjMinorPersonUid = tmp_ppjMinorPersonUid
              _entity.parentJoin!!.ppjRelationship = tmp_ppjRelationship
              _entity.parentJoin!!.ppjEmail = tmp_ppjEmail
              _entity.parentJoin!!.ppjPhone = tmp_ppjPhone
              _entity.parentJoin!!.ppjInactive = tmp_ppjInactive
              _entity.parentJoin!!.ppjStatus = tmp_ppjStatus
              _entity.parentJoin!!.ppjApprovalTiemstamp = tmp_ppjApprovalTiemstamp
              _entity.parentJoin!!.ppjApprovalIpAddr = tmp_ppjApprovalIpAddr
            }
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun insertAuditLog(entity: AuditLog): Long {
    val _retVal = _insertAdapterAuditLog_.insertAndReturnId(entity)
    return _retVal
  }

  public override fun getAllPerson(): List<Person> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Person>()
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Person" , postgreSql = """
    |SELECT * FROM Person
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_personUid = _resultSet.getLong("personUid")
          val tmp_username = _resultSet.getString("username")
          val tmp_firstNames = _resultSet.getString("firstNames")
          val tmp_lastName = _resultSet.getString("lastName")
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          val tmp_gender = _resultSet.getInt("gender")
          val tmp_active = _resultSet.getBoolean("active")
          val tmp_admin = _resultSet.getBoolean("admin")
          val tmp_personNotes = _resultSet.getString("personNotes")
          val tmp_fatherName = _resultSet.getString("fatherName")
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          val tmp_motherName = _resultSet.getString("motherName")
          val tmp_motherNum = _resultSet.getString("motherNum")
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          val tmp_personAddress = _resultSet.getString("personAddress")
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          val tmp_personLct = _resultSet.getLong("personLct")
          val tmp_personCountry = _resultSet.getString("personCountry")
          val tmp_personType = _resultSet.getInt("personType")
          val _entity = Person()
          _entity.personUid = tmp_personUid
          _entity.username = tmp_username
          _entity.firstNames = tmp_firstNames
          _entity.lastName = tmp_lastName
          _entity.emailAddr = tmp_emailAddr
          _entity.phoneNum = tmp_phoneNum
          _entity.gender = tmp_gender
          _entity.active = tmp_active
          _entity.admin = tmp_admin
          _entity.personNotes = tmp_personNotes
          _entity.fatherName = tmp_fatherName
          _entity.fatherNumber = tmp_fatherNumber
          _entity.motherName = tmp_motherName
          _entity.motherNum = tmp_motherNum
          _entity.dateOfBirth = tmp_dateOfBirth
          _entity.personAddress = tmp_personAddress
          _entity.personOrgId = tmp_personOrgId
          _entity.personGroupUid = tmp_personGroupUid
          _entity.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
          _entity.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
          _entity.personLastChangedBy = tmp_personLastChangedBy
          _entity.personLct = tmp_personLct
          _entity.personCountry = tmp_personCountry
          _entity.personType = tmp_personType
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun insert(entity: Person): Long {
    val _retVal = _insertAdapterPerson_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Person): Long {
    val _retVal = _insertAdapterPerson_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Person>): Unit {
    _insertAdapterPerson_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Person>): Unit {
    val _sql =
        "UPDATE Person SET username = ?, firstNames = ?, lastName = ?, emailAddr = ?, phoneNum = ?, gender = ?, active = ?, admin = ?, personNotes = ?, fatherName = ?, fatherNumber = ?, motherName = ?, motherNum = ?, dateOfBirth = ?, personAddress = ?, personOrgId = ?, personGroupUid = ?, personMasterChangeSeqNum = ?, personLocalChangeSeqNum = ?, personLastChangedBy = ?, personLct = ?, personCountry = ?, personType = ? WHERE personUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.username)
        _stmt.setString(2, _entity.firstNames)
        _stmt.setString(3, _entity.lastName)
        _stmt.setString(4, _entity.emailAddr)
        _stmt.setString(5, _entity.phoneNum)
        _stmt.setInt(6, _entity.gender)
        _stmt.setBoolean(7, _entity.active)
        _stmt.setBoolean(8, _entity.admin)
        _stmt.setString(9, _entity.personNotes)
        _stmt.setString(10, _entity.fatherName)
        _stmt.setString(11, _entity.fatherNumber)
        _stmt.setString(12, _entity.motherName)
        _stmt.setString(13, _entity.motherNum)
        _stmt.setLong(14, _entity.dateOfBirth)
        _stmt.setString(15, _entity.personAddress)
        _stmt.setString(16, _entity.personOrgId)
        _stmt.setLong(17, _entity.personGroupUid)
        _stmt.setLong(18, _entity.personMasterChangeSeqNum)
        _stmt.setLong(19, _entity.personLocalChangeSeqNum)
        _stmt.setInt(20, _entity.personLastChangedBy)
        _stmt.setLong(21, _entity.personLct)
        _stmt.setString(22, _entity.personCountry)
        _stmt.setInt(23, _entity.personType)
        _stmt.setLong(24, _entity.personUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Person): Unit {
    val _sql =
        "UPDATE Person SET username = ?, firstNames = ?, lastName = ?, emailAddr = ?, phoneNum = ?, gender = ?, active = ?, admin = ?, personNotes = ?, fatherName = ?, fatherNumber = ?, motherName = ?, motherNum = ?, dateOfBirth = ?, personAddress = ?, personOrgId = ?, personGroupUid = ?, personMasterChangeSeqNum = ?, personLocalChangeSeqNum = ?, personLastChangedBy = ?, personLct = ?, personCountry = ?, personType = ? WHERE personUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.username)
      _stmt.setString(2, entity.firstNames)
      _stmt.setString(3, entity.lastName)
      _stmt.setString(4, entity.emailAddr)
      _stmt.setString(5, entity.phoneNum)
      _stmt.setInt(6, entity.gender)
      _stmt.setBoolean(7, entity.active)
      _stmt.setBoolean(8, entity.admin)
      _stmt.setString(9, entity.personNotes)
      _stmt.setString(10, entity.fatherName)
      _stmt.setString(11, entity.fatherNumber)
      _stmt.setString(12, entity.motherName)
      _stmt.setString(13, entity.motherNum)
      _stmt.setLong(14, entity.dateOfBirth)
      _stmt.setString(15, entity.personAddress)
      _stmt.setString(16, entity.personOrgId)
      _stmt.setLong(17, entity.personGroupUid)
      _stmt.setLong(18, entity.personMasterChangeSeqNum)
      _stmt.setLong(19, entity.personLocalChangeSeqNum)
      _stmt.setInt(20, entity.personLastChangedBy)
      _stmt.setLong(21, entity.personLct)
      _stmt.setString(22, entity.personCountry)
      _stmt.setInt(23, entity.personType)
      _stmt.setLong(24, entity.personUid)
      _stmt.executeUpdate()
    }
  }
}
