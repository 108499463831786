package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.XLangMapEntry
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class XLangMapEntryDao_JdbcKt(
  public val _db: DoorDatabase
) : XLangMapEntryDao() {
  public val _insertAdapterXLangMapEntry_: EntityInsertionAdapter<XLangMapEntry> = object :
      EntityInsertionAdapter<XLangMapEntry>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO XLangMapEntry (verbLangMapUid, objectLangMapUid, languageLangMapUid, languageVariantLangMapUid, valueLangMap, statementLangMapMasterCsn, statementLangMapLocalCsn, statementLangMapLcb, statementLangMapLct, statementLangMapUid) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO XLangMapEntry (verbLangMapUid, objectLangMapUid, languageLangMapUid, languageVariantLangMapUid, valueLangMap, statementLangMapMasterCsn, statementLangMapLocalCsn, statementLangMapLcb, statementLangMapLct, statementLangMapUid) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, COALESCE(?,nextval('XLangMapEntry_statementLangMapUid_seq')))" + if(returnsId) { " RETURNING statementLangMapUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: XLangMapEntry):
        Unit {
      stmt.setLong(1, entity.verbLangMapUid)
      stmt.setLong(2, entity.objectLangMapUid)
      stmt.setLong(3, entity.languageLangMapUid)
      stmt.setLong(4, entity.languageVariantLangMapUid)
      stmt.setString(5, entity.valueLangMap)
      stmt.setInt(6, entity.statementLangMapMasterCsn)
      stmt.setInt(7, entity.statementLangMapLocalCsn)
      stmt.setInt(8, entity.statementLangMapLcb)
      stmt.setLong(9, entity.statementLangMapLct)
      if(entity.statementLangMapUid == 0L) {
        stmt.setObject(10, null)
      } else {
        stmt.setLong(10, entity.statementLangMapUid)
      }
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         REPLACE INTO XLangMapEntryReplicate(xlmePk, xlmeDestination)
    |          SELECT DISTINCT XLangMapEntry.statementLangMapUid AS xlmePk,
    |                 ? AS xlmeDestination
    |            FROM XLangMapEntry
    |                 JOIN UserSession ON UserSession.usClientNodeId = ?
    |             --notpsql      
    |             WHERE XLangMapEntry.statementLangMapLct != COALESCE(
    |                 (SELECT xlmeVersionId
    |                    FROM XLangMapEntryReplicate
    |                   WHERE xlmePk = XLangMapEntry.statementLangMapUid
    |                     AND xlmeDestination = UserSession.usClientNodeId), 0)
    |             --endnotpsql         
    |          /*psql ON CONFLICT(xlmePk, xlmeDestination) DO UPDATE
    |                 SET xlmePending = (SELECT XLangMapEntry.statementLangMapLct
    |                                      FROM XLangmapEntry
    |                                     WHERE XLangmapEntry.statementLangMapUid = EXCLUDED.xlmePk)
    |                                        != XLangMapEntryReplicate.xlmeVersionId
    |          */       
    |     
    """.trimMargin() , postgreSql = """
    |INSERT INTO XLangMapEntryReplicate(xlmePk, xlmeDestination)
    |          SELECT DISTINCT XLangMapEntry.statementLangMapUid AS xlmePk,
    |                 ? AS xlmeDestination
    |            FROM XLangMapEntry
    |                 JOIN UserSession ON UserSession.usClientNodeId = ?
    |           ON CONFLICT(xlmePk, xlmeDestination) DO UPDATE
    |                 SET xlmePending = (SELECT XLangMapEntry.statementLangMapLct
    |                                      FROM XLangmapEntry
    |                                     WHERE XLangmapEntry.statementLangMapUid = EXCLUDED.xlmePk)
    |                                        != XLangMapEntryReplicate.xlmeVersionId
    |                 
    |     
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO XLangMapEntryReplicate(xlmePk, xlmeDestination)
    |  SELECT DISTINCT XLangMapEntry.statementLangMapUid AS xlmeUid,
    |         UserSession.usClientNodeId AS xlmeDestination
    |    FROM ChangeLog
    |         JOIN XLangMapEntry
    |             ON ChangeLog.chTableId = 74
    |                AND ChangeLog.chEntityPk = XLangMapEntry.statementLangMapUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND XLangMapEntry.statementLangMapLct != COALESCE(
    |         (SELECT xlmeVersionId
    |            FROM XLangMapEntryReplicate
    |           WHERE xlmePk = XLangMapEntry.statementLangMapUid
    |             AND xlmeDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(xlmePk, xlmeDestination) DO UPDATE
    |     SET xlmePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO XLangMapEntryReplicate(xlmePk, xlmeDestination)
    |  SELECT DISTINCT XLangMapEntry.statementLangMapUid AS xlmeUid,
    |         UserSession.usClientNodeId AS xlmeDestination
    |    FROM ChangeLog
    |         JOIN XLangMapEntry
    |             ON ChangeLog.chTableId = 74
    |                AND ChangeLog.chEntityPk = XLangMapEntry.statementLangMapUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND XLangMapEntry.statementLangMapLct != COALESCE(
    |         (SELECT xlmeVersionId
    |            FROM XLangMapEntryReplicate
    |           WHERE xlmePk = XLangMapEntry.statementLangMapUid
    |             AND xlmeDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(xlmePk, xlmeDestination) DO UPDATE
    |     SET xlmePending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun getValuesWithListOfId(ids: List<Int>): List<XLangMapEntry> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.XLangMapEntry>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM XLangMapEntry WHERE objectLangMapUid IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT * FROM XLangMapEntry WHERE objectLangMapUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "INTEGER", ids.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_verbLangMapUid = _resultSet.getLong("verbLangMapUid")
          val tmp_objectLangMapUid = _resultSet.getLong("objectLangMapUid")
          val tmp_languageLangMapUid = _resultSet.getLong("languageLangMapUid")
          val tmp_languageVariantLangMapUid = _resultSet.getLong("languageVariantLangMapUid")
          val tmp_valueLangMap = _resultSet.getString("valueLangMap")
          val tmp_statementLangMapMasterCsn = _resultSet.getInt("statementLangMapMasterCsn")
          val tmp_statementLangMapLocalCsn = _resultSet.getInt("statementLangMapLocalCsn")
          val tmp_statementLangMapLcb = _resultSet.getInt("statementLangMapLcb")
          val tmp_statementLangMapLct = _resultSet.getLong("statementLangMapLct")
          val tmp_statementLangMapUid = _resultSet.getLong("statementLangMapUid")
          val _entity = XLangMapEntry()
          _entity.verbLangMapUid = tmp_verbLangMapUid
          _entity.objectLangMapUid = tmp_objectLangMapUid
          _entity.languageLangMapUid = tmp_languageLangMapUid
          _entity.languageVariantLangMapUid = tmp_languageVariantLangMapUid
          _entity.valueLangMap = tmp_valueLangMap
          _entity.statementLangMapMasterCsn = tmp_statementLangMapMasterCsn
          _entity.statementLangMapLocalCsn = tmp_statementLangMapLocalCsn
          _entity.statementLangMapLcb = tmp_statementLangMapLcb
          _entity.statementLangMapLct = tmp_statementLangMapLct
          _entity.statementLangMapUid = tmp_statementLangMapUid
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun getXLangMapFromVerb(verbUid: Long, langMapUid: Long): XLangMapEntry? {
    var _result = null as com.ustadmobile.lib.db.entities.XLangMapEntry??
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT * FROM XLangMapEntry WHERE 
    |            verbLangMapUid = ? AND languageLangMapUid = ? LIMIT 1
    """.trimMargin() , postgreSql = """
    |SELECT * FROM XLangMapEntry WHERE 
    |            verbLangMapUid = ? AND languageLangMapUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, verbUid)
      _stmt.setLong(2, langMapUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_verbLangMapUid = _resultSet.getLong("verbLangMapUid")
          val tmp_objectLangMapUid = _resultSet.getLong("objectLangMapUid")
          val tmp_languageLangMapUid = _resultSet.getLong("languageLangMapUid")
          val tmp_languageVariantLangMapUid = _resultSet.getLong("languageVariantLangMapUid")
          val tmp_valueLangMap = _resultSet.getString("valueLangMap")
          val tmp_statementLangMapMasterCsn = _resultSet.getInt("statementLangMapMasterCsn")
          val tmp_statementLangMapLocalCsn = _resultSet.getInt("statementLangMapLocalCsn")
          val tmp_statementLangMapLcb = _resultSet.getInt("statementLangMapLcb")
          val tmp_statementLangMapLct = _resultSet.getLong("statementLangMapLct")
          val tmp_statementLangMapUid = _resultSet.getLong("statementLangMapUid")
          val _entity = XLangMapEntry()
          _entity.verbLangMapUid = tmp_verbLangMapUid
          _entity.objectLangMapUid = tmp_objectLangMapUid
          _entity.languageLangMapUid = tmp_languageLangMapUid
          _entity.languageVariantLangMapUid = tmp_languageVariantLangMapUid
          _entity.valueLangMap = tmp_valueLangMap
          _entity.statementLangMapMasterCsn = tmp_statementLangMapMasterCsn
          _entity.statementLangMapLocalCsn = tmp_statementLangMapLocalCsn
          _entity.statementLangMapLcb = tmp_statementLangMapLcb
          _entity.statementLangMapLct = tmp_statementLangMapLct
          _entity.statementLangMapUid = tmp_statementLangMapUid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun getXLangMapFromObject(objectUid: Long, langMapUid: Long): XLangMapEntry? {
    var _result = null as com.ustadmobile.lib.db.entities.XLangMapEntry??
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT * FROM XLangMapEntry WHERE 
    |            objectLangMapUid = ? AND languageLangMapUid = ? LIMIT 1
    """.trimMargin() , postgreSql = """
    |SELECT * FROM XLangMapEntry WHERE 
    |            objectLangMapUid = ? AND languageLangMapUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, objectUid)
      _stmt.setLong(2, langMapUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_verbLangMapUid = _resultSet.getLong("verbLangMapUid")
          val tmp_objectLangMapUid = _resultSet.getLong("objectLangMapUid")
          val tmp_languageLangMapUid = _resultSet.getLong("languageLangMapUid")
          val tmp_languageVariantLangMapUid = _resultSet.getLong("languageVariantLangMapUid")
          val tmp_valueLangMap = _resultSet.getString("valueLangMap")
          val tmp_statementLangMapMasterCsn = _resultSet.getInt("statementLangMapMasterCsn")
          val tmp_statementLangMapLocalCsn = _resultSet.getInt("statementLangMapLocalCsn")
          val tmp_statementLangMapLcb = _resultSet.getInt("statementLangMapLcb")
          val tmp_statementLangMapLct = _resultSet.getLong("statementLangMapLct")
          val tmp_statementLangMapUid = _resultSet.getLong("statementLangMapUid")
          val _entity = XLangMapEntry()
          _entity.verbLangMapUid = tmp_verbLangMapUid
          _entity.objectLangMapUid = tmp_objectLangMapUid
          _entity.languageLangMapUid = tmp_languageLangMapUid
          _entity.languageVariantLangMapUid = tmp_languageVariantLangMapUid
          _entity.valueLangMap = tmp_valueLangMap
          _entity.statementLangMapMasterCsn = tmp_statementLangMapMasterCsn
          _entity.statementLangMapLocalCsn = tmp_statementLangMapLocalCsn
          _entity.statementLangMapLcb = tmp_statementLangMapLcb
          _entity.statementLangMapLct = tmp_statementLangMapLct
          _entity.statementLangMapUid = tmp_statementLangMapUid
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: XLangMapEntry): Long {
    val _retVal = _insertAdapterXLangMapEntry_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: XLangMapEntry): Long {
    val _retVal = _insertAdapterXLangMapEntry_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out XLangMapEntry>): Unit {
    _insertAdapterXLangMapEntry_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out XLangMapEntry>): Unit {
    val _sql =
        "UPDATE XLangMapEntry SET verbLangMapUid = ?, objectLangMapUid = ?, languageLangMapUid = ?, languageVariantLangMapUid = ?, valueLangMap = ?, statementLangMapMasterCsn = ?, statementLangMapLocalCsn = ?, statementLangMapLcb = ?, statementLangMapLct = ? WHERE statementLangMapUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.verbLangMapUid)
        _stmt.setLong(2, _entity.objectLangMapUid)
        _stmt.setLong(3, _entity.languageLangMapUid)
        _stmt.setLong(4, _entity.languageVariantLangMapUid)
        _stmt.setString(5, _entity.valueLangMap)
        _stmt.setInt(6, _entity.statementLangMapMasterCsn)
        _stmt.setInt(7, _entity.statementLangMapLocalCsn)
        _stmt.setInt(8, _entity.statementLangMapLcb)
        _stmt.setLong(9, _entity.statementLangMapLct)
        _stmt.setLong(10, _entity.statementLangMapUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: XLangMapEntry): Unit {
    val _sql =
        "UPDATE XLangMapEntry SET verbLangMapUid = ?, objectLangMapUid = ?, languageLangMapUid = ?, languageVariantLangMapUid = ?, valueLangMap = ?, statementLangMapMasterCsn = ?, statementLangMapLocalCsn = ?, statementLangMapLcb = ?, statementLangMapLct = ? WHERE statementLangMapUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.verbLangMapUid)
      _stmt.setLong(2, entity.objectLangMapUid)
      _stmt.setLong(3, entity.languageLangMapUid)
      _stmt.setLong(4, entity.languageVariantLangMapUid)
      _stmt.setString(5, entity.valueLangMap)
      _stmt.setInt(6, entity.statementLangMapMasterCsn)
      _stmt.setInt(7, entity.statementLangMapLocalCsn)
      _stmt.setInt(8, entity.statementLangMapLcb)
      _stmt.setLong(9, entity.statementLangMapLct)
      _stmt.setLong(10, entity.statementLangMapUid)
      _stmt.executeUpdate()
    }
  }
}
