package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.DoorQuery
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.columnIndexMap
import com.ustadmobile.door.ext.hasListOrArrayParams
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Report
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ReportDao_JdbcKt(
  public val _db: DoorDatabase
) : ReportDao() {
  public val _insertAdapterReport_upsert: EntityInsertionAdapter<Report> = object :
      EntityInsertionAdapter<Report>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO Report (reportUid, reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Report (reportUid, reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct) VALUES(COALESCE(?,nextval('Report_reportUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (reportUid) DO UPDATE SET reportOwnerUid = excluded.reportOwnerUid,xAxis = excluded.xAxis,reportDateRangeSelection = excluded.reportDateRangeSelection,fromDate = excluded.fromDate,fromRelTo = excluded.fromRelTo,fromRelOffSet = excluded.fromRelOffSet,fromRelUnit = excluded.fromRelUnit,toDate = excluded.toDate,toRelTo = excluded.toRelTo,toRelOffSet = excluded.toRelOffSet,toRelUnit = excluded.toRelUnit,reportTitle = excluded.reportTitle,reportDescription = excluded.reportDescription,reportSeries = excluded.reportSeries,reportInactive = excluded.reportInactive,isTemplate = excluded.isTemplate,priority = excluded.priority,reportTitleId = excluded.reportTitleId,reportDescId = excluded.reportDescId,reportMasterChangeSeqNum = excluded.reportMasterChangeSeqNum,reportLocalChangeSeqNum = excluded.reportLocalChangeSeqNum,reportLastChangedBy = excluded.reportLastChangedBy,reportLct = excluded.reportLct" + if(returnsId) { " RETURNING reportUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Report): Unit {
      if(entity.reportUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.reportUid)
      }
      stmt.setLong(2, entity.reportOwnerUid)
      stmt.setInt(3, entity.xAxis)
      stmt.setInt(4, entity.reportDateRangeSelection)
      stmt.setLong(5, entity.fromDate)
      stmt.setInt(6, entity.fromRelTo)
      stmt.setInt(7, entity.fromRelOffSet)
      stmt.setInt(8, entity.fromRelUnit)
      stmt.setLong(9, entity.toDate)
      stmt.setInt(10, entity.toRelTo)
      stmt.setInt(11, entity.toRelOffSet)
      stmt.setInt(12, entity.toRelUnit)
      stmt.setString(13, entity.reportTitle)
      stmt.setString(14, entity.reportDescription)
      stmt.setString(15, entity.reportSeries)
      stmt.setBoolean(16, entity.reportInactive)
      stmt.setBoolean(17, entity.isTemplate)
      stmt.setInt(18, entity.priority)
      stmt.setInt(19, entity.reportTitleId)
      stmt.setInt(20, entity.reportDescId)
      stmt.setLong(21, entity.reportMasterChangeSeqNum)
      stmt.setLong(22, entity.reportLocalChangeSeqNum)
      stmt.setInt(23, entity.reportLastChangedBy)
      stmt.setLong(24, entity.reportLct)
    }
  }

  public val _insertAdapterReport_: EntityInsertionAdapter<Report> = object :
      EntityInsertionAdapter<Report>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Report (reportUid, reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Report (reportUid, reportOwnerUid, xAxis, reportDateRangeSelection, fromDate, fromRelTo, fromRelOffSet, fromRelUnit, toDate, toRelTo, toRelOffSet, toRelUnit, reportTitle, reportDescription, reportSeries, reportInactive, isTemplate, priority, reportTitleId, reportDescId, reportMasterChangeSeqNum, reportLocalChangeSeqNum, reportLastChangedBy, reportLct) VALUES(COALESCE(?,nextval('Report_reportUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING reportUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Report): Unit {
      if(entity.reportUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.reportUid)
      }
      stmt.setLong(2, entity.reportOwnerUid)
      stmt.setInt(3, entity.xAxis)
      stmt.setInt(4, entity.reportDateRangeSelection)
      stmt.setLong(5, entity.fromDate)
      stmt.setInt(6, entity.fromRelTo)
      stmt.setInt(7, entity.fromRelOffSet)
      stmt.setInt(8, entity.fromRelUnit)
      stmt.setLong(9, entity.toDate)
      stmt.setInt(10, entity.toRelTo)
      stmt.setInt(11, entity.toRelOffSet)
      stmt.setInt(12, entity.toRelUnit)
      stmt.setString(13, entity.reportTitle)
      stmt.setString(14, entity.reportDescription)
      stmt.setString(15, entity.reportSeries)
      stmt.setBoolean(16, entity.reportInactive)
      stmt.setBoolean(17, entity.isTemplate)
      stmt.setInt(18, entity.priority)
      stmt.setInt(19, entity.reportTitleId)
      stmt.setInt(20, entity.reportDescId)
      stmt.setLong(21, entity.reportMasterChangeSeqNum)
      stmt.setLong(22, entity.reportLocalChangeSeqNum)
      stmt.setInt(23, entity.reportLastChangedBy)
      stmt.setLong(24, entity.reportLct)
    }
  }

  public override suspend fun replicateOnNewNodeTemplates(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ReportReplicate(reportPk, reportDestination)
    |      SELECT DISTINCT Report.reportUid AS reportPk,
    |             ? AS reportDestination
    |        FROM Report
    |             JOIN UserSession
    |                  ON UserSession.usStatus = 1
    |                     AND CAST(Report.isTemplate AS INTEGER) = 1
    |       WHERE Report.reportLct != COALESCE(
    |             (SELECT reportVersionId
    |                FROM ReportReplicate
    |               WHERE reportPk = Report.reportUid
    |                 AND reportDestination = ?), 0) 
    |      /*psql ON CONFLICT(reportPk, reportDestination) DO UPDATE
    |             SET reportPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ReportReplicate(reportPk, reportDestination)
    |      SELECT DISTINCT Report.reportUid AS reportPk,
    |             ? AS reportDestination
    |        FROM Report
    |             JOIN UserSession
    |                  ON UserSession.usStatus = 1
    |                     AND CAST(Report.isTemplate AS INTEGER) = 1
    |       WHERE Report.reportLct != COALESCE(
    |             (SELECT reportVersionId
    |                FROM ReportReplicate
    |               WHERE reportPk = Report.reportUid
    |                 AND reportDestination = ?), 0) 
    |       ON CONFLICT(reportPk, reportDestination) DO UPDATE
    |             SET reportPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeTemplates(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ReportReplicate(reportPk, reportDestination)
    |  SELECT DISTINCT Report.reportUid AS reportUid,
    |         UserSession.usClientNodeId AS reportDestination
    |    FROM ChangeLog
    |         JOIN Report
    |              ON ChangeLog.chTableId = 101 
    |                 AND ChangeLog.chEntityPk = Report.reportUid
    |         JOIN UserSession
    |              ON UserSession.usStatus = 1
    |                 AND CAST(Report.isTemplate AS INTEGER) = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Report.reportLct != COALESCE(
    |         (SELECT reportVersionId
    |            FROM ReportReplicate
    |           WHERE reportPk = Report.reportUid
    |             AND reportDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(reportPk, reportDestination) DO UPDATE
    |     SET reportPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ReportReplicate(reportPk, reportDestination)
    |  SELECT DISTINCT Report.reportUid AS reportUid,
    |         UserSession.usClientNodeId AS reportDestination
    |    FROM ChangeLog
    |         JOIN Report
    |              ON ChangeLog.chTableId = 101 
    |                 AND ChangeLog.chEntityPk = Report.reportUid
    |         JOIN UserSession
    |              ON UserSession.usStatus = 1
    |                 AND CAST(Report.isTemplate AS INTEGER) = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Report.reportLct != COALESCE(
    |         (SELECT reportVersionId
    |            FROM ReportReplicate
    |           WHERE reportPk = Report.reportUid
    |             AND reportDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(reportPk, reportDestination) DO UPDATE
    |     SET reportPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getResults(query: DoorQuery): List<Report> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Report>()
    val _stmtConfig = PreparedStatementConfig(query.getSql(), hasListParams =
        query.hasListOrArrayParams())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      query.bindToPreparedStmt(_stmt, _db, _stmt.getConnection())
      _stmt.executeQuery().useResults {
         _resultSet ->
        val _columnIndexMap = _resultSet.columnIndexMap()
        while(_resultSet.next()) {
          val tmp_reportUid: Long
          if(_columnIndexMap.containsKey("reportUid")) {
            tmp_reportUid  = _resultSet.getLong("reportUid")
          } else {
            tmp_reportUid = 0L
          }
          val tmp_reportOwnerUid: Long
          if(_columnIndexMap.containsKey("reportOwnerUid")) {
            tmp_reportOwnerUid  = _resultSet.getLong("reportOwnerUid")
          } else {
            tmp_reportOwnerUid = 0L
          }
          val tmp_xAxis: Int
          if(_columnIndexMap.containsKey("xAxis")) {
            tmp_xAxis  = _resultSet.getInt("xAxis")
          } else {
            tmp_xAxis = 0
          }
          val tmp_reportDateRangeSelection: Int
          if(_columnIndexMap.containsKey("reportDateRangeSelection")) {
            tmp_reportDateRangeSelection  = _resultSet.getInt("reportDateRangeSelection")
          } else {
            tmp_reportDateRangeSelection = 0
          }
          val tmp_fromDate: Long
          if(_columnIndexMap.containsKey("fromDate")) {
            tmp_fromDate  = _resultSet.getLong("fromDate")
          } else {
            tmp_fromDate = 0L
          }
          val tmp_fromRelTo: Int
          if(_columnIndexMap.containsKey("fromRelTo")) {
            tmp_fromRelTo  = _resultSet.getInt("fromRelTo")
          } else {
            tmp_fromRelTo = 0
          }
          val tmp_fromRelOffSet: Int
          if(_columnIndexMap.containsKey("fromRelOffSet")) {
            tmp_fromRelOffSet  = _resultSet.getInt("fromRelOffSet")
          } else {
            tmp_fromRelOffSet = 0
          }
          val tmp_fromRelUnit: Int
          if(_columnIndexMap.containsKey("fromRelUnit")) {
            tmp_fromRelUnit  = _resultSet.getInt("fromRelUnit")
          } else {
            tmp_fromRelUnit = 0
          }
          val tmp_toDate: Long
          if(_columnIndexMap.containsKey("toDate")) {
            tmp_toDate  = _resultSet.getLong("toDate")
          } else {
            tmp_toDate = 0L
          }
          val tmp_toRelTo: Int
          if(_columnIndexMap.containsKey("toRelTo")) {
            tmp_toRelTo  = _resultSet.getInt("toRelTo")
          } else {
            tmp_toRelTo = 0
          }
          val tmp_toRelOffSet: Int
          if(_columnIndexMap.containsKey("toRelOffSet")) {
            tmp_toRelOffSet  = _resultSet.getInt("toRelOffSet")
          } else {
            tmp_toRelOffSet = 0
          }
          val tmp_toRelUnit: Int
          if(_columnIndexMap.containsKey("toRelUnit")) {
            tmp_toRelUnit  = _resultSet.getInt("toRelUnit")
          } else {
            tmp_toRelUnit = 0
          }
          val tmp_reportTitle: String?
          if(_columnIndexMap.containsKey("reportTitle")) {
            tmp_reportTitle  = _resultSet.getString("reportTitle")
          } else {
            tmp_reportTitle = null as String?
          }
          val tmp_reportDescription: String?
          if(_columnIndexMap.containsKey("reportDescription")) {
            tmp_reportDescription  = _resultSet.getString("reportDescription")
          } else {
            tmp_reportDescription = null as String?
          }
          val tmp_reportSeries: String?
          if(_columnIndexMap.containsKey("reportSeries")) {
            tmp_reportSeries  = _resultSet.getString("reportSeries")
          } else {
            tmp_reportSeries = null as String?
          }
          val tmp_reportInactive: Boolean
          if(_columnIndexMap.containsKey("reportInactive")) {
            tmp_reportInactive  = _resultSet.getBoolean("reportInactive")
          } else {
            tmp_reportInactive = false
          }
          val tmp_isTemplate: Boolean
          if(_columnIndexMap.containsKey("isTemplate")) {
            tmp_isTemplate  = _resultSet.getBoolean("isTemplate")
          } else {
            tmp_isTemplate = false
          }
          val tmp_priority: Int
          if(_columnIndexMap.containsKey("priority")) {
            tmp_priority  = _resultSet.getInt("priority")
          } else {
            tmp_priority = 0
          }
          val tmp_reportTitleId: Int
          if(_columnIndexMap.containsKey("reportTitleId")) {
            tmp_reportTitleId  = _resultSet.getInt("reportTitleId")
          } else {
            tmp_reportTitleId = 0
          }
          val tmp_reportDescId: Int
          if(_columnIndexMap.containsKey("reportDescId")) {
            tmp_reportDescId  = _resultSet.getInt("reportDescId")
          } else {
            tmp_reportDescId = 0
          }
          val tmp_reportMasterChangeSeqNum: Long
          if(_columnIndexMap.containsKey("reportMasterChangeSeqNum")) {
            tmp_reportMasterChangeSeqNum  = _resultSet.getLong("reportMasterChangeSeqNum")
          } else {
            tmp_reportMasterChangeSeqNum = 0L
          }
          val tmp_reportLocalChangeSeqNum: Long
          if(_columnIndexMap.containsKey("reportLocalChangeSeqNum")) {
            tmp_reportLocalChangeSeqNum  = _resultSet.getLong("reportLocalChangeSeqNum")
          } else {
            tmp_reportLocalChangeSeqNum = 0L
          }
          val tmp_reportLastChangedBy: Int
          if(_columnIndexMap.containsKey("reportLastChangedBy")) {
            tmp_reportLastChangedBy  = _resultSet.getInt("reportLastChangedBy")
          } else {
            tmp_reportLastChangedBy = 0
          }
          val tmp_reportLct: Long
          if(_columnIndexMap.containsKey("reportLct")) {
            tmp_reportLct  = _resultSet.getLong("reportLct")
          } else {
            tmp_reportLct = 0L
          }
          val _entity = Report()
          _entity.reportUid = tmp_reportUid
          _entity.reportOwnerUid = tmp_reportOwnerUid
          _entity.xAxis = tmp_xAxis
          _entity.reportDateRangeSelection = tmp_reportDateRangeSelection
          _entity.fromDate = tmp_fromDate
          _entity.fromRelTo = tmp_fromRelTo
          _entity.fromRelOffSet = tmp_fromRelOffSet
          _entity.fromRelUnit = tmp_fromRelUnit
          _entity.toDate = tmp_toDate
          _entity.toRelTo = tmp_toRelTo
          _entity.toRelOffSet = tmp_toRelOffSet
          _entity.toRelUnit = tmp_toRelUnit
          _entity.reportTitle = tmp_reportTitle
          _entity.reportDescription = tmp_reportDescription
          _entity.reportSeries = tmp_reportSeries
          _entity.reportInactive = tmp_reportInactive
          _entity.isTemplate = tmp_isTemplate
          _entity.priority = tmp_priority
          _entity.reportTitleId = tmp_reportTitleId
          _entity.reportDescId = tmp_reportDescId
          _entity.reportMasterChangeSeqNum = tmp_reportMasterChangeSeqNum
          _entity.reportLocalChangeSeqNum = tmp_reportLocalChangeSeqNum
          _entity.reportLastChangedBy = tmp_reportLastChangedBy
          _entity.reportLct = tmp_reportLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllActiveReport(
    searchBit: String,
    personUid: Long,
    sortOrder: Int,
    isTemplate: Boolean
  ): DoorDataSourceFactory<Int, Report> {
    val _result = object : DoorDataSourceFactory<Int, Report>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<Report>> =
          DoorLiveDataImpl<List<Report>>(_db, listOf("REPORT"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.Report>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
        |        AND reportOwnerUid = ?
        |        AND isTemplate = ?
        |        AND reportTitle LIKE ?
        |        ORDER BY priority, CASE(?)
        |            WHEN 1 THEN Report.reportTitle
        |            ELSE ''
        |        END ASC,
        |        CASE(?)
        |            WHEN 2 THEN Report.reportTitle
        |            ELSE ''
        |        END DESC
        |            ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
        |        AND reportOwnerUid = ?
        |        AND isTemplate = ?
        |        AND reportTitle LIKE ?
        |        ORDER BY priority, CASE(?)
        |            WHEN 1 THEN Report.reportTitle
        |            ELSE ''
        |        END ASC,
        |        CASE(?)
        |            WHEN 2 THEN Report.reportTitle
        |            ELSE ''
        |        END DESC
        |            ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setBoolean(2, isTemplate)
          _stmt.setString(3, searchBit)
          _stmt.setInt(4, sortOrder)
          _stmt.setInt(5, sortOrder)
          _stmt.setInt(6, _limit)
          _stmt.setInt(7, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_reportUid = _resultSet.getLong("reportUid")
              val tmp_reportOwnerUid = _resultSet.getLong("reportOwnerUid")
              val tmp_xAxis = _resultSet.getInt("xAxis")
              val tmp_reportDateRangeSelection = _resultSet.getInt("reportDateRangeSelection")
              val tmp_fromDate = _resultSet.getLong("fromDate")
              val tmp_fromRelTo = _resultSet.getInt("fromRelTo")
              val tmp_fromRelOffSet = _resultSet.getInt("fromRelOffSet")
              val tmp_fromRelUnit = _resultSet.getInt("fromRelUnit")
              val tmp_toDate = _resultSet.getLong("toDate")
              val tmp_toRelTo = _resultSet.getInt("toRelTo")
              val tmp_toRelOffSet = _resultSet.getInt("toRelOffSet")
              val tmp_toRelUnit = _resultSet.getInt("toRelUnit")
              val tmp_reportTitle = _resultSet.getString("reportTitle")
              val tmp_reportDescription = _resultSet.getString("reportDescription")
              val tmp_reportSeries = _resultSet.getString("reportSeries")
              val tmp_reportInactive = _resultSet.getBoolean("reportInactive")
              val tmp_isTemplate = _resultSet.getBoolean("isTemplate")
              val tmp_priority = _resultSet.getInt("priority")
              val tmp_reportTitleId = _resultSet.getInt("reportTitleId")
              val tmp_reportDescId = _resultSet.getInt("reportDescId")
              val tmp_reportMasterChangeSeqNum = _resultSet.getLong("reportMasterChangeSeqNum")
              val tmp_reportLocalChangeSeqNum = _resultSet.getLong("reportLocalChangeSeqNum")
              val tmp_reportLastChangedBy = _resultSet.getInt("reportLastChangedBy")
              val tmp_reportLct = _resultSet.getLong("reportLct")
              val _entity = Report()
              _entity.reportUid = tmp_reportUid
              _entity.reportOwnerUid = tmp_reportOwnerUid
              _entity.xAxis = tmp_xAxis
              _entity.reportDateRangeSelection = tmp_reportDateRangeSelection
              _entity.fromDate = tmp_fromDate
              _entity.fromRelTo = tmp_fromRelTo
              _entity.fromRelOffSet = tmp_fromRelOffSet
              _entity.fromRelUnit = tmp_fromRelUnit
              _entity.toDate = tmp_toDate
              _entity.toRelTo = tmp_toRelTo
              _entity.toRelOffSet = tmp_toRelOffSet
              _entity.toRelUnit = tmp_toRelUnit
              _entity.reportTitle = tmp_reportTitle
              _entity.reportDescription = tmp_reportDescription
              _entity.reportSeries = tmp_reportSeries
              _entity.reportInactive = tmp_reportInactive
              _entity.isTemplate = tmp_isTemplate
              _entity.priority = tmp_priority
              _entity.reportTitleId = tmp_reportTitleId
              _entity.reportDescId = tmp_reportDescId
              _entity.reportMasterChangeSeqNum = tmp_reportMasterChangeSeqNum
              _entity.reportLocalChangeSeqNum = tmp_reportLocalChangeSeqNum
              _entity.reportLastChangedBy = tmp_reportLastChangedBy
              _entity.reportLct = tmp_reportLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("REPORT"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
        |        AND reportOwnerUid = ?
        |        AND isTemplate = ?
        |        AND reportTitle LIKE ?
        |        ORDER BY priority, CASE(?)
        |            WHEN 1 THEN Report.reportTitle
        |            ELSE ''
        |        END ASC,
        |        CASE(?)
        |            WHEN 2 THEN Report.reportTitle
        |            ELSE ''
        |        END DESC
        |            ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT * FROM REPORT WHERE NOT reportInactive 
        |        AND reportOwnerUid = ?
        |        AND isTemplate = ?
        |        AND reportTitle LIKE ?
        |        ORDER BY priority, CASE(?)
        |            WHEN 1 THEN Report.reportTitle
        |            ELSE ''
        |        END ASC,
        |        CASE(?)
        |            WHEN 2 THEN Report.reportTitle
        |            ELSE ''
        |        END DESC
        |            ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setBoolean(2, isTemplate)
          _stmt.setString(3, searchBit)
          _stmt.setInt(4, sortOrder)
          _stmt.setInt(5, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun findByUid(entityUid: Long): Report? {
    var _result = null as com.ustadmobile.lib.db.entities.Report??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Report WHERE reportUid = ?" ,
        postgreSql = """
    |SELECT * FROM Report WHERE reportUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, entityUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_reportUid = _resultSet.getLong("reportUid")
          val tmp_reportOwnerUid = _resultSet.getLong("reportOwnerUid")
          val tmp_xAxis = _resultSet.getInt("xAxis")
          val tmp_reportDateRangeSelection = _resultSet.getInt("reportDateRangeSelection")
          val tmp_fromDate = _resultSet.getLong("fromDate")
          val tmp_fromRelTo = _resultSet.getInt("fromRelTo")
          val tmp_fromRelOffSet = _resultSet.getInt("fromRelOffSet")
          val tmp_fromRelUnit = _resultSet.getInt("fromRelUnit")
          val tmp_toDate = _resultSet.getLong("toDate")
          val tmp_toRelTo = _resultSet.getInt("toRelTo")
          val tmp_toRelOffSet = _resultSet.getInt("toRelOffSet")
          val tmp_toRelUnit = _resultSet.getInt("toRelUnit")
          val tmp_reportTitle = _resultSet.getString("reportTitle")
          val tmp_reportDescription = _resultSet.getString("reportDescription")
          val tmp_reportSeries = _resultSet.getString("reportSeries")
          val tmp_reportInactive = _resultSet.getBoolean("reportInactive")
          val tmp_isTemplate = _resultSet.getBoolean("isTemplate")
          val tmp_priority = _resultSet.getInt("priority")
          val tmp_reportTitleId = _resultSet.getInt("reportTitleId")
          val tmp_reportDescId = _resultSet.getInt("reportDescId")
          val tmp_reportMasterChangeSeqNum = _resultSet.getLong("reportMasterChangeSeqNum")
          val tmp_reportLocalChangeSeqNum = _resultSet.getLong("reportLocalChangeSeqNum")
          val tmp_reportLastChangedBy = _resultSet.getInt("reportLastChangedBy")
          val tmp_reportLct = _resultSet.getLong("reportLct")
          val _entity = Report()
          _entity.reportUid = tmp_reportUid
          _entity.reportOwnerUid = tmp_reportOwnerUid
          _entity.xAxis = tmp_xAxis
          _entity.reportDateRangeSelection = tmp_reportDateRangeSelection
          _entity.fromDate = tmp_fromDate
          _entity.fromRelTo = tmp_fromRelTo
          _entity.fromRelOffSet = tmp_fromRelOffSet
          _entity.fromRelUnit = tmp_fromRelUnit
          _entity.toDate = tmp_toDate
          _entity.toRelTo = tmp_toRelTo
          _entity.toRelOffSet = tmp_toRelOffSet
          _entity.toRelUnit = tmp_toRelUnit
          _entity.reportTitle = tmp_reportTitle
          _entity.reportDescription = tmp_reportDescription
          _entity.reportSeries = tmp_reportSeries
          _entity.reportInactive = tmp_reportInactive
          _entity.isTemplate = tmp_isTemplate
          _entity.priority = tmp_priority
          _entity.reportTitleId = tmp_reportTitleId
          _entity.reportDescId = tmp_reportDescId
          _entity.reportMasterChangeSeqNum = tmp_reportMasterChangeSeqNum
          _entity.reportLocalChangeSeqNum = tmp_reportLocalChangeSeqNum
          _entity.reportLastChangedBy = tmp_reportLastChangedBy
          _entity.reportLct = tmp_reportLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: Report): Unit {
    val _sql =
        "UPDATE Report SET reportOwnerUid = ?, xAxis = ?, reportDateRangeSelection = ?, fromDate = ?, fromRelTo = ?, fromRelOffSet = ?, fromRelUnit = ?, toDate = ?, toRelTo = ?, toRelOffSet = ?, toRelUnit = ?, reportTitle = ?, reportDescription = ?, reportSeries = ?, reportInactive = ?, isTemplate = ?, priority = ?, reportTitleId = ?, reportDescId = ?, reportMasterChangeSeqNum = ?, reportLocalChangeSeqNum = ?, reportLastChangedBy = ?, reportLct = ? WHERE reportUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.reportOwnerUid)
      _stmt.setInt(2, entity.xAxis)
      _stmt.setInt(3, entity.reportDateRangeSelection)
      _stmt.setLong(4, entity.fromDate)
      _stmt.setInt(5, entity.fromRelTo)
      _stmt.setInt(6, entity.fromRelOffSet)
      _stmt.setInt(7, entity.fromRelUnit)
      _stmt.setLong(8, entity.toDate)
      _stmt.setInt(9, entity.toRelTo)
      _stmt.setInt(10, entity.toRelOffSet)
      _stmt.setInt(11, entity.toRelUnit)
      _stmt.setString(12, entity.reportTitle)
      _stmt.setString(13, entity.reportDescription)
      _stmt.setString(14, entity.reportSeries)
      _stmt.setBoolean(15, entity.reportInactive)
      _stmt.setBoolean(16, entity.isTemplate)
      _stmt.setInt(17, entity.priority)
      _stmt.setInt(18, entity.reportTitleId)
      _stmt.setInt(19, entity.reportDescId)
      _stmt.setLong(20, entity.reportMasterChangeSeqNum)
      _stmt.setLong(21, entity.reportLocalChangeSeqNum)
      _stmt.setInt(22, entity.reportLastChangedBy)
      _stmt.setLong(23, entity.reportLct)
      _stmt.setLong(24, entity.reportUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<Report?> {
    val _result = DoorLiveDataImpl<Report?>(_db, listOf("Report"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.Report??
      val _stmtConfig = PreparedStatementConfig("SELECT * From Report WHERE  reportUid = ?" ,
          postgreSql = """
      |SELECT * From Report WHERE  reportUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_reportUid = _resultSet.getLong("reportUid")
            val tmp_reportOwnerUid = _resultSet.getLong("reportOwnerUid")
            val tmp_xAxis = _resultSet.getInt("xAxis")
            val tmp_reportDateRangeSelection = _resultSet.getInt("reportDateRangeSelection")
            val tmp_fromDate = _resultSet.getLong("fromDate")
            val tmp_fromRelTo = _resultSet.getInt("fromRelTo")
            val tmp_fromRelOffSet = _resultSet.getInt("fromRelOffSet")
            val tmp_fromRelUnit = _resultSet.getInt("fromRelUnit")
            val tmp_toDate = _resultSet.getLong("toDate")
            val tmp_toRelTo = _resultSet.getInt("toRelTo")
            val tmp_toRelOffSet = _resultSet.getInt("toRelOffSet")
            val tmp_toRelUnit = _resultSet.getInt("toRelUnit")
            val tmp_reportTitle = _resultSet.getString("reportTitle")
            val tmp_reportDescription = _resultSet.getString("reportDescription")
            val tmp_reportSeries = _resultSet.getString("reportSeries")
            val tmp_reportInactive = _resultSet.getBoolean("reportInactive")
            val tmp_isTemplate = _resultSet.getBoolean("isTemplate")
            val tmp_priority = _resultSet.getInt("priority")
            val tmp_reportTitleId = _resultSet.getInt("reportTitleId")
            val tmp_reportDescId = _resultSet.getInt("reportDescId")
            val tmp_reportMasterChangeSeqNum = _resultSet.getLong("reportMasterChangeSeqNum")
            val tmp_reportLocalChangeSeqNum = _resultSet.getLong("reportLocalChangeSeqNum")
            val tmp_reportLastChangedBy = _resultSet.getInt("reportLastChangedBy")
            val tmp_reportLct = _resultSet.getLong("reportLct")
            val _entity = Report()
            _entity.reportUid = tmp_reportUid
            _entity.reportOwnerUid = tmp_reportOwnerUid
            _entity.xAxis = tmp_xAxis
            _entity.reportDateRangeSelection = tmp_reportDateRangeSelection
            _entity.fromDate = tmp_fromDate
            _entity.fromRelTo = tmp_fromRelTo
            _entity.fromRelOffSet = tmp_fromRelOffSet
            _entity.fromRelUnit = tmp_fromRelUnit
            _entity.toDate = tmp_toDate
            _entity.toRelTo = tmp_toRelTo
            _entity.toRelOffSet = tmp_toRelOffSet
            _entity.toRelUnit = tmp_toRelUnit
            _entity.reportTitle = tmp_reportTitle
            _entity.reportDescription = tmp_reportDescription
            _entity.reportSeries = tmp_reportSeries
            _entity.reportInactive = tmp_reportInactive
            _entity.isTemplate = tmp_isTemplate
            _entity.priority = tmp_priority
            _entity.reportTitleId = tmp_reportTitleId
            _entity.reportDescId = tmp_reportDescId
            _entity.reportMasterChangeSeqNum = tmp_reportMasterChangeSeqNum
            _entity.reportLocalChangeSeqNum = tmp_reportLocalChangeSeqNum
            _entity.reportLastChangedBy = tmp_reportLastChangedBy
            _entity.reportLct = tmp_reportLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun findAllActiveReportLive(isTemplate: Boolean): DoorLiveData<List<Report>> {
    val _result = DoorLiveDataImpl<List<Report>>(_db, listOf("REPORT"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.Report>()
      val _stmtConfig = PreparedStatementConfig("""
      |SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND isTemplate = ?
      |        ORDER BY priority ASC
      |            
      """.trimMargin() , postgreSql = """
      |SELECT * FROM REPORT WHERE NOT reportInactive 
      |        AND isTemplate = ?
      |        ORDER BY priority ASC
      |            
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setBoolean(1, isTemplate)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_reportUid = _resultSet.getLong("reportUid")
            val tmp_reportOwnerUid = _resultSet.getLong("reportOwnerUid")
            val tmp_xAxis = _resultSet.getInt("xAxis")
            val tmp_reportDateRangeSelection = _resultSet.getInt("reportDateRangeSelection")
            val tmp_fromDate = _resultSet.getLong("fromDate")
            val tmp_fromRelTo = _resultSet.getInt("fromRelTo")
            val tmp_fromRelOffSet = _resultSet.getInt("fromRelOffSet")
            val tmp_fromRelUnit = _resultSet.getInt("fromRelUnit")
            val tmp_toDate = _resultSet.getLong("toDate")
            val tmp_toRelTo = _resultSet.getInt("toRelTo")
            val tmp_toRelOffSet = _resultSet.getInt("toRelOffSet")
            val tmp_toRelUnit = _resultSet.getInt("toRelUnit")
            val tmp_reportTitle = _resultSet.getString("reportTitle")
            val tmp_reportDescription = _resultSet.getString("reportDescription")
            val tmp_reportSeries = _resultSet.getString("reportSeries")
            val tmp_reportInactive = _resultSet.getBoolean("reportInactive")
            val tmp_isTemplate = _resultSet.getBoolean("isTemplate")
            val tmp_priority = _resultSet.getInt("priority")
            val tmp_reportTitleId = _resultSet.getInt("reportTitleId")
            val tmp_reportDescId = _resultSet.getInt("reportDescId")
            val tmp_reportMasterChangeSeqNum = _resultSet.getLong("reportMasterChangeSeqNum")
            val tmp_reportLocalChangeSeqNum = _resultSet.getLong("reportLocalChangeSeqNum")
            val tmp_reportLastChangedBy = _resultSet.getInt("reportLastChangedBy")
            val tmp_reportLct = _resultSet.getLong("reportLct")
            val _entity = Report()
            _entity.reportUid = tmp_reportUid
            _entity.reportOwnerUid = tmp_reportOwnerUid
            _entity.xAxis = tmp_xAxis
            _entity.reportDateRangeSelection = tmp_reportDateRangeSelection
            _entity.fromDate = tmp_fromDate
            _entity.fromRelTo = tmp_fromRelTo
            _entity.fromRelOffSet = tmp_fromRelOffSet
            _entity.fromRelUnit = tmp_fromRelUnit
            _entity.toDate = tmp_toDate
            _entity.toRelTo = tmp_toRelTo
            _entity.toRelOffSet = tmp_toRelOffSet
            _entity.toRelUnit = tmp_toRelUnit
            _entity.reportTitle = tmp_reportTitle
            _entity.reportDescription = tmp_reportDescription
            _entity.reportSeries = tmp_reportSeries
            _entity.reportInactive = tmp_reportInactive
            _entity.isTemplate = tmp_isTemplate
            _entity.priority = tmp_priority
            _entity.reportTitleId = tmp_reportTitleId
            _entity.reportDescId = tmp_reportDescId
            _entity.reportMasterChangeSeqNum = tmp_reportMasterChangeSeqNum
            _entity.reportLocalChangeSeqNum = tmp_reportLocalChangeSeqNum
            _entity.reportLastChangedBy = tmp_reportLastChangedBy
            _entity.reportLct = tmp_reportLct
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override fun findAllActiveReportList(isTemplate: Boolean): List<Report> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Report>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT * FROM REPORT WHERE NOT reportInactive 
    |        AND isTemplate = ?
    |        ORDER BY priority ASC
    |            
    """.trimMargin() , postgreSql = """
    |SELECT * FROM REPORT WHERE NOT reportInactive 
    |        AND isTemplate = ?
    |        ORDER BY priority ASC
    |            
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, isTemplate)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_reportUid = _resultSet.getLong("reportUid")
          val tmp_reportOwnerUid = _resultSet.getLong("reportOwnerUid")
          val tmp_xAxis = _resultSet.getInt("xAxis")
          val tmp_reportDateRangeSelection = _resultSet.getInt("reportDateRangeSelection")
          val tmp_fromDate = _resultSet.getLong("fromDate")
          val tmp_fromRelTo = _resultSet.getInt("fromRelTo")
          val tmp_fromRelOffSet = _resultSet.getInt("fromRelOffSet")
          val tmp_fromRelUnit = _resultSet.getInt("fromRelUnit")
          val tmp_toDate = _resultSet.getLong("toDate")
          val tmp_toRelTo = _resultSet.getInt("toRelTo")
          val tmp_toRelOffSet = _resultSet.getInt("toRelOffSet")
          val tmp_toRelUnit = _resultSet.getInt("toRelUnit")
          val tmp_reportTitle = _resultSet.getString("reportTitle")
          val tmp_reportDescription = _resultSet.getString("reportDescription")
          val tmp_reportSeries = _resultSet.getString("reportSeries")
          val tmp_reportInactive = _resultSet.getBoolean("reportInactive")
          val tmp_isTemplate = _resultSet.getBoolean("isTemplate")
          val tmp_priority = _resultSet.getInt("priority")
          val tmp_reportTitleId = _resultSet.getInt("reportTitleId")
          val tmp_reportDescId = _resultSet.getInt("reportDescId")
          val tmp_reportMasterChangeSeqNum = _resultSet.getLong("reportMasterChangeSeqNum")
          val tmp_reportLocalChangeSeqNum = _resultSet.getLong("reportLocalChangeSeqNum")
          val tmp_reportLastChangedBy = _resultSet.getInt("reportLastChangedBy")
          val tmp_reportLct = _resultSet.getLong("reportLct")
          val _entity = Report()
          _entity.reportUid = tmp_reportUid
          _entity.reportOwnerUid = tmp_reportOwnerUid
          _entity.xAxis = tmp_xAxis
          _entity.reportDateRangeSelection = tmp_reportDateRangeSelection
          _entity.fromDate = tmp_fromDate
          _entity.fromRelTo = tmp_fromRelTo
          _entity.fromRelOffSet = tmp_fromRelOffSet
          _entity.fromRelUnit = tmp_fromRelUnit
          _entity.toDate = tmp_toDate
          _entity.toRelTo = tmp_toRelTo
          _entity.toRelOffSet = tmp_toRelOffSet
          _entity.toRelUnit = tmp_toRelUnit
          _entity.reportTitle = tmp_reportTitle
          _entity.reportDescription = tmp_reportDescription
          _entity.reportSeries = tmp_reportSeries
          _entity.reportInactive = tmp_reportInactive
          _entity.isTemplate = tmp_isTemplate
          _entity.priority = tmp_priority
          _entity.reportTitleId = tmp_reportTitleId
          _entity.reportDescId = tmp_reportDescId
          _entity.reportMasterChangeSeqNum = tmp_reportMasterChangeSeqNum
          _entity.reportLocalChangeSeqNum = tmp_reportLocalChangeSeqNum
          _entity.reportLastChangedBy = tmp_reportLastChangedBy
          _entity.reportLct = tmp_reportLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByUidList(uidList: List<Long>): List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("SELECT reportUid FROM Report WHERE reportUid IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT reportUid FROM Report WHERE reportUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", uidList.toTypedArray()))
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun toggleVisibilityReportItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE Report 
    |           SET reportInactive = ?,
    |               reportLct = ? 
    |         WHERE reportUid IN (?)
    |    
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        UPDATE Report 
    |           SET reportInactive = ?,
    |               reportLct = ? 
    |         WHERE reportUid IN (?)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, toggleVisibility)
      _stmt.setLong(2, updateTime)
      _stmt.setArray(3, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          selectedItem.toTypedArray()))
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun replaceList(entityList: List<out Report>): Unit {
    _insertAdapterReport_upsert.insertList(entityList)
  }

  public override fun insert(entity: Report): Long {
    val _retVal = _insertAdapterReport_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Report): Long {
    val _retVal = _insertAdapterReport_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Report>): Unit {
    _insertAdapterReport_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Report>): Unit {
    val _sql =
        "UPDATE Report SET reportOwnerUid = ?, xAxis = ?, reportDateRangeSelection = ?, fromDate = ?, fromRelTo = ?, fromRelOffSet = ?, fromRelUnit = ?, toDate = ?, toRelTo = ?, toRelOffSet = ?, toRelUnit = ?, reportTitle = ?, reportDescription = ?, reportSeries = ?, reportInactive = ?, isTemplate = ?, priority = ?, reportTitleId = ?, reportDescId = ?, reportMasterChangeSeqNum = ?, reportLocalChangeSeqNum = ?, reportLastChangedBy = ?, reportLct = ? WHERE reportUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.reportOwnerUid)
        _stmt.setInt(2, _entity.xAxis)
        _stmt.setInt(3, _entity.reportDateRangeSelection)
        _stmt.setLong(4, _entity.fromDate)
        _stmt.setInt(5, _entity.fromRelTo)
        _stmt.setInt(6, _entity.fromRelOffSet)
        _stmt.setInt(7, _entity.fromRelUnit)
        _stmt.setLong(8, _entity.toDate)
        _stmt.setInt(9, _entity.toRelTo)
        _stmt.setInt(10, _entity.toRelOffSet)
        _stmt.setInt(11, _entity.toRelUnit)
        _stmt.setString(12, _entity.reportTitle)
        _stmt.setString(13, _entity.reportDescription)
        _stmt.setString(14, _entity.reportSeries)
        _stmt.setBoolean(15, _entity.reportInactive)
        _stmt.setBoolean(16, _entity.isTemplate)
        _stmt.setInt(17, _entity.priority)
        _stmt.setInt(18, _entity.reportTitleId)
        _stmt.setInt(19, _entity.reportDescId)
        _stmt.setLong(20, _entity.reportMasterChangeSeqNum)
        _stmt.setLong(21, _entity.reportLocalChangeSeqNum)
        _stmt.setInt(22, _entity.reportLastChangedBy)
        _stmt.setLong(23, _entity.reportLct)
        _stmt.setLong(24, _entity.reportUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Report): Unit {
    val _sql =
        "UPDATE Report SET reportOwnerUid = ?, xAxis = ?, reportDateRangeSelection = ?, fromDate = ?, fromRelTo = ?, fromRelOffSet = ?, fromRelUnit = ?, toDate = ?, toRelTo = ?, toRelOffSet = ?, toRelUnit = ?, reportTitle = ?, reportDescription = ?, reportSeries = ?, reportInactive = ?, isTemplate = ?, priority = ?, reportTitleId = ?, reportDescId = ?, reportMasterChangeSeqNum = ?, reportLocalChangeSeqNum = ?, reportLastChangedBy = ?, reportLct = ? WHERE reportUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.reportOwnerUid)
      _stmt.setInt(2, entity.xAxis)
      _stmt.setInt(3, entity.reportDateRangeSelection)
      _stmt.setLong(4, entity.fromDate)
      _stmt.setInt(5, entity.fromRelTo)
      _stmt.setInt(6, entity.fromRelOffSet)
      _stmt.setInt(7, entity.fromRelUnit)
      _stmt.setLong(8, entity.toDate)
      _stmt.setInt(9, entity.toRelTo)
      _stmt.setInt(10, entity.toRelOffSet)
      _stmt.setInt(11, entity.toRelUnit)
      _stmt.setString(12, entity.reportTitle)
      _stmt.setString(13, entity.reportDescription)
      _stmt.setString(14, entity.reportSeries)
      _stmt.setBoolean(15, entity.reportInactive)
      _stmt.setBoolean(16, entity.isTemplate)
      _stmt.setInt(17, entity.priority)
      _stmt.setInt(18, entity.reportTitleId)
      _stmt.setInt(19, entity.reportDescId)
      _stmt.setLong(20, entity.reportMasterChangeSeqNum)
      _stmt.setLong(21, entity.reportLocalChangeSeqNum)
      _stmt.setInt(22, entity.reportLastChangedBy)
      _stmt.setLong(23, entity.reportLct)
      _stmt.setLong(24, entity.reportUid)
      _stmt.executeUpdate()
    }
  }
}
