package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmission
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionAttachment
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionWithAttachment
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseAssignmentSubmissionDao_JdbcKt(
  public val _db: DoorDatabase
) : CourseAssignmentSubmissionDao() {
  public val _insertAdapterCourseAssignmentSubmission_:
      EntityInsertionAdapter<CourseAssignmentSubmission> = object :
      EntityInsertionAdapter<CourseAssignmentSubmission>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CourseAssignmentSubmission (casUid, casAssignmentUid, casSubmitterUid, casSubmitterPersonUid, casText, casType, casTimestamp) VALUES(?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseAssignmentSubmission (casUid, casAssignmentUid, casSubmitterUid, casSubmitterPersonUid, casText, casType, casTimestamp) VALUES(COALESCE(?,nextval('CourseAssignmentSubmission_casUid_seq')), ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING casUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseAssignmentSubmission): Unit {
      if(entity.casUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.casUid)
      }
      stmt.setLong(2, entity.casAssignmentUid)
      stmt.setLong(3, entity.casSubmitterUid)
      stmt.setLong(4, entity.casSubmitterPersonUid)
      stmt.setString(5, entity.casText)
      stmt.setInt(6, entity.casType)
      stmt.setLong(7, entity.casTimestamp)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseAssignmentSubmissionReplicate(casPk, casDestination)
    |      SELECT DISTINCT CourseAssignmentSubmission.casUid AS casPk,
    |             ? AS casDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentSubmission
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentSubmission.casTimestamp != COALESCE(
    |             (SELECT casVersionId
    |                FROM CourseAssignmentSubmissionReplicate
    |               WHERE casPk = CourseAssignmentSubmission.casUid
    |                 AND casDestination = ?), 0) 
    |      /*psql ON CONFLICT(casPk, casDestination) DO UPDATE
    |             SET casPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentSubmissionReplicate(casPk, casDestination)
    |      SELECT DISTINCT CourseAssignmentSubmission.casUid AS casPk,
    |             ? AS casDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentSubmission
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentSubmission.casTimestamp != COALESCE(
    |             (SELECT casVersionId
    |                FROM CourseAssignmentSubmissionReplicate
    |               WHERE casPk = CourseAssignmentSubmission.casUid
    |                 AND casDestination = ?), 0) 
    |       ON CONFLICT(casPk, casDestination) DO UPDATE
    |             SET casPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO CourseAssignmentSubmissionReplicate(casPk, casDestination)
    |  SELECT DISTINCT CourseAssignmentSubmission.casUid AS casPk,
    |         UserSession.usClientNodeId AS casDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentSubmission
    |             ON ChangeLog.chTableId = 522
    |                AND ChangeLog.chEntityPk = CourseAssignmentSubmission.casUid
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentSubmission.casTimestamp != COALESCE(
    |         (SELECT casVersionId
    |            FROM CourseAssignmentSubmissionReplicate
    |           WHERE casPk = CourseAssignmentSubmission.casUid
    |             AND casDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(casPk, casDestination) DO UPDATE
    |     SET casPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentSubmissionReplicate(casPk, casDestination)
    |  SELECT DISTINCT CourseAssignmentSubmission.casUid AS casPk,
    |         UserSession.usClientNodeId AS casDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentSubmission
    |             ON ChangeLog.chTableId = 522
    |                AND ChangeLog.chEntityPk = CourseAssignmentSubmission.casUid
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentSubmission.casTimestamp != COALESCE(
    |         (SELECT casVersionId
    |            FROM CourseAssignmentSubmissionReplicate
    |           WHERE casPk = CourseAssignmentSubmission.casUid
    |             AND casDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(casPk, casDestination) DO UPDATE
    |     SET casPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out CourseAssignmentSubmission>):
      Unit {
    _insertAdapterCourseAssignmentSubmission_.insertListAsync(entityList)
  }

  public override fun getAllSubmissionsFromSubmitter(assignmentUid: Long, submitterUid: Long):
      DoorDataSourceFactory<Int, CourseAssignmentSubmissionWithAttachment> {
    val _result = object : DoorDataSourceFactory<Int, CourseAssignmentSubmissionWithAttachment>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<CourseAssignmentSubmissionWithAttachment>> =
          DoorLiveDataImpl<List<CourseAssignmentSubmissionWithAttachment>>(_db,
          listOf("CourseAssignmentSubmission", "CourseAssignmentSubmissionAttachment"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionWithAttachment>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT * 
        |          FROM CourseAssignmentSubmission
        |          
        |               LEFT JOIN CourseAssignmentSubmissionAttachment
        |               ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid
        |               
        |         WHERE casAssignmentUid = ?
        |           AND casSubmitterUid = ?
        |      ORDER BY casTimestamp DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT * 
        |          FROM CourseAssignmentSubmission
        |          
        |               LEFT JOIN CourseAssignmentSubmissionAttachment
        |               ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid
        |               
        |         WHERE casAssignmentUid = ?
        |           AND casSubmitterUid = ?
        |      ORDER BY casTimestamp DESC
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, assignmentUid)
          _stmt.setLong(2, submitterUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_casUid = _resultSet.getLong("casUid")
              val tmp_casAssignmentUid = _resultSet.getLong("casAssignmentUid")
              val tmp_casSubmitterUid = _resultSet.getLong("casSubmitterUid")
              val tmp_casSubmitterPersonUid = _resultSet.getLong("casSubmitterPersonUid")
              val tmp_casText = _resultSet.getString("casText")
              val tmp_casType = _resultSet.getInt("casType")
              val tmp_casTimestamp = _resultSet.getLong("casTimestamp")
              val _entity = CourseAssignmentSubmissionWithAttachment()
              _entity.casUid = tmp_casUid
              _entity.casAssignmentUid = tmp_casAssignmentUid
              _entity.casSubmitterUid = tmp_casSubmitterUid
              _entity.casSubmitterPersonUid = tmp_casSubmitterPersonUid
              _entity.casText = tmp_casText
              _entity.casType = tmp_casType
              _entity.casTimestamp = tmp_casTimestamp
              var _attachment_nullFieldCount = 0
              val tmp_casaUid = _resultSet.getLong("casaUid")
              if(_resultSet.wasNull()) { _attachment_nullFieldCount++ }
              val tmp_casaSubmissionUid = _resultSet.getLong("casaSubmissionUid")
              if(_resultSet.wasNull()) { _attachment_nullFieldCount++ }
              val tmp_casaMimeType = _resultSet.getString("casaMimeType")
              if(_resultSet.wasNull()) { _attachment_nullFieldCount++ }
              val tmp_casaFileName = _resultSet.getString("casaFileName")
              if(_resultSet.wasNull()) { _attachment_nullFieldCount++ }
              val tmp_casaUri = _resultSet.getString("casaUri")
              if(_resultSet.wasNull()) { _attachment_nullFieldCount++ }
              val tmp_casaMd5 = _resultSet.getString("casaMd5")
              if(_resultSet.wasNull()) { _attachment_nullFieldCount++ }
              val tmp_casaSize = _resultSet.getInt("casaSize")
              if(_resultSet.wasNull()) { _attachment_nullFieldCount++ }
              val tmp_casaTimestamp = _resultSet.getLong("casaTimestamp")
              if(_resultSet.wasNull()) { _attachment_nullFieldCount++ }
              if(_attachment_nullFieldCount < 8) {
                if(_entity.attachment == null) {
                  _entity.attachment = CourseAssignmentSubmissionAttachment()
                }
                _entity.attachment!!.casaUid = tmp_casaUid
                _entity.attachment!!.casaSubmissionUid = tmp_casaSubmissionUid
                _entity.attachment!!.casaMimeType = tmp_casaMimeType
                _entity.attachment!!.casaFileName = tmp_casaFileName
                _entity.attachment!!.casaUri = tmp_casaUri
                _entity.attachment!!.casaMd5 = tmp_casaMd5
                _entity.attachment!!.casaSize = tmp_casaSize
                _entity.attachment!!.casaTimestamp = tmp_casaTimestamp
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("CourseAssignmentSubmission", "CourseAssignmentSubmissionAttachment"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT * 
        |          FROM CourseAssignmentSubmission
        |          
        |               LEFT JOIN CourseAssignmentSubmissionAttachment
        |               ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid
        |               
        |         WHERE casAssignmentUid = ?
        |           AND casSubmitterUid = ?
        |      ORDER BY casTimestamp DESC
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT * 
        |          FROM CourseAssignmentSubmission
        |          
        |               LEFT JOIN CourseAssignmentSubmissionAttachment
        |               ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid
        |               
        |         WHERE casAssignmentUid = ?
        |           AND casSubmitterUid = ?
        |      ORDER BY casTimestamp DESC
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, assignmentUid)
          _stmt.setLong(2, submitterUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun countFileSubmissionFromStudent(assignmentUid: Long,
      submitterUid: Long): Int {
    var _result = 0
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Count(casUid)
    |          FROM CourseAssignmentSubmission
    |         WHERE casAssignmentUid = ?
    |           AND casSubmitterUid = ?
    |           AND casType = 2
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Count(casUid)
    |          FROM CourseAssignmentSubmission
    |         WHERE casAssignmentUid = ?
    |           AND casSubmitterUid = ?
    |           AND casType = 2
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, assignmentUid)
      _stmt.setLong(2, submitterUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getInt(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun countSubmissionsFromSubmitter(assignmentUid: Long,
      submitterUid: Long): Int {
    var _result = 0
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Count(casUid)
    |          FROM CourseAssignmentSubmission
    |         WHERE casAssignmentUid = ?
    |           AND casSubmitterUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Count(casUid)
    |          FROM CourseAssignmentSubmission
    |         WHERE casAssignmentUid = ?
    |           AND casSubmitterUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, assignmentUid)
      _stmt.setLong(2, submitterUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getInt(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun getStatusOfAssignmentForSubmitter(assignmentUid: Long, submitterUid: Long):
      DoorLiveData<Int> {
    val _result = DoorLiveDataImpl<Int>(_db, listOf("CourseAssignmentSubmission",
        "CourseAssignmentMark"))  {
      var _liveResult = 0
      val _stmtConfig = PreparedStatementConfig("""
      |
      |           SELECT COALESCE((
      |                SELECT (CASE WHEN CourseAssignmentMark.camAssignmentUid IS NOT NULL 
      |                             THEN 2
      |                             ELSE 1 
      |                             END) AS status
      |                  FROM CourseAssignmentSubmission
      |                       
      |                       LEFT JOIN CourseAssignmentMark
      |                       ON CourseAssignmentMark.camAssignmentUid = ?
      |                       AND CourseAssignmentMark.camSubmitterUid = ?
      |                       
      |                 WHERE CourseAssignmentSubmission.casAssignmentUid = ?
      |                   AND CourseAssignmentSubmission.casSubmitterUid = ?
      |                 LIMIT 1
      |           ),0) AS Status
      |    
      """.trimMargin() , postgreSql = """
      |
      |           SELECT COALESCE((
      |                SELECT (CASE WHEN CourseAssignmentMark.camAssignmentUid IS NOT NULL 
      |                             THEN 2
      |                             ELSE 1 
      |                             END) AS status
      |                  FROM CourseAssignmentSubmission
      |                       
      |                       LEFT JOIN CourseAssignmentMark
      |                       ON CourseAssignmentMark.camAssignmentUid = ?
      |                       AND CourseAssignmentMark.camSubmitterUid = ?
      |                       
      |                 WHERE CourseAssignmentSubmission.casAssignmentUid = ?
      |                   AND CourseAssignmentSubmission.casSubmitterUid = ?
      |                 LIMIT 1
      |           ),0) AS Status
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, assignmentUid)
        _stmt.setLong(2, submitterUid)
        _stmt.setLong(3, assignmentUid)
        _stmt.setLong(4, submitterUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val _entity = _resultSet.getInt(1)
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun findLastSubmissionFromStudent(submitterUid: Long,
      assignmentUid: Long): CourseAssignmentSubmission? {
    var _result = null as com.ustadmobile.lib.db.entities.CourseAssignmentSubmission??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM CourseAssignmentSubmission
    |         WHERE CourseAssignmentSubmission.casAssignmentUid = ?
    |           AND CourseAssignmentSubmission.casSubmitterUid = ?
    |      ORDER BY casTimestamp DESC
    |         LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM CourseAssignmentSubmission
    |         WHERE CourseAssignmentSubmission.casAssignmentUid = ?
    |           AND CourseAssignmentSubmission.casSubmitterUid = ?
    |      ORDER BY casTimestamp DESC
    |         LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, assignmentUid)
      _stmt.setLong(2, submitterUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_casUid = _resultSet.getLong("casUid")
          val tmp_casAssignmentUid = _resultSet.getLong("casAssignmentUid")
          val tmp_casSubmitterUid = _resultSet.getLong("casSubmitterUid")
          val tmp_casSubmitterPersonUid = _resultSet.getLong("casSubmitterPersonUid")
          val tmp_casText = _resultSet.getString("casText")
          val tmp_casType = _resultSet.getInt("casType")
          val tmp_casTimestamp = _resultSet.getLong("casTimestamp")
          val _entity = CourseAssignmentSubmission()
          _entity.casUid = tmp_casUid
          _entity.casAssignmentUid = tmp_casAssignmentUid
          _entity.casSubmitterUid = tmp_casSubmitterUid
          _entity.casSubmitterPersonUid = tmp_casSubmitterPersonUid
          _entity.casText = tmp_casText
          _entity.casType = tmp_casType
          _entity.casTimestamp = tmp_casTimestamp
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun checkNoSubmissionsMade(assignmentUid: Long): DoorLiveData<Boolean> {
    val _result = DoorLiveDataImpl<Boolean>(_db, listOf("CourseAssignmentSubmission"))  {
      var _liveResult = false
      val _stmtConfig = PreparedStatementConfig("""
      |
      |         SELECT NOT EXISTS(SELECT 1
      |                        FROM CourseAssignmentSubmission
      |                       WHERE CourseAssignmentSubmission.casAssignmentUid = ?
      |                       LIMIT 1)
      |    
      """.trimMargin() , postgreSql = """
      |
      |         SELECT NOT EXISTS(SELECT 1
      |                        FROM CourseAssignmentSubmission
      |                       WHERE CourseAssignmentSubmission.casAssignmentUid = ?
      |                       LIMIT 1)
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, assignmentUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val _entity = _resultSet.getBoolean(1)
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun insert(entity: CourseAssignmentSubmission): Long {
    val _retVal = _insertAdapterCourseAssignmentSubmission_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseAssignmentSubmission): Long {
    val _retVal = _insertAdapterCourseAssignmentSubmission_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CourseAssignmentSubmission>): Unit {
    _insertAdapterCourseAssignmentSubmission_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseAssignmentSubmission>): Unit {
    val _sql =
        "UPDATE CourseAssignmentSubmission SET casAssignmentUid = ?, casSubmitterUid = ?, casSubmitterPersonUid = ?, casText = ?, casType = ?, casTimestamp = ? WHERE casUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.casAssignmentUid)
        _stmt.setLong(2, _entity.casSubmitterUid)
        _stmt.setLong(3, _entity.casSubmitterPersonUid)
        _stmt.setString(4, _entity.casText)
        _stmt.setInt(5, _entity.casType)
        _stmt.setLong(6, _entity.casTimestamp)
        _stmt.setLong(7, _entity.casUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseAssignmentSubmission): Unit {
    val _sql =
        "UPDATE CourseAssignmentSubmission SET casAssignmentUid = ?, casSubmitterUid = ?, casSubmitterPersonUid = ?, casText = ?, casType = ?, casTimestamp = ? WHERE casUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.casAssignmentUid)
      _stmt.setLong(2, entity.casSubmitterUid)
      _stmt.setLong(3, entity.casSubmitterPersonUid)
      _stmt.setString(4, entity.casText)
      _stmt.setInt(5, entity.casType)
      _stmt.setLong(6, entity.casTimestamp)
      _stmt.setLong(7, entity.casUid)
      _stmt.executeUpdate()
    }
  }
}
