package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.ext.doorNodeAndVersionHeaders
import com.ustadmobile.door.ext.getOrNull
import com.ustadmobile.lib.db.entities.ContentEntry
import com.ustadmobile.lib.db.entities.ContentEntryButtonModel
import com.ustadmobile.lib.db.entities.ContentEntryContentJobItemParams
import com.ustadmobile.lib.db.entities.ContentEntryWithBlockAndLanguage
import com.ustadmobile.lib.db.entities.ContentEntryWithLanguage
import com.ustadmobile.lib.db.entities.ContentEntryWithMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import com.ustadmobile.lib.db.entities.ContentJobItemProgressAndStatus
import com.ustadmobile.lib.db.entities.DistinctCategorySchema
import com.ustadmobile.lib.db.entities.DownloadJobSizeInfo
import com.ustadmobile.lib.db.entities.LangUidAndName
import com.ustadmobile.lib.db.entities.Language
import com.ustadmobile.lib.db.entities.UidAndLabel
import io.ktor.client.HttpClient
import io.ktor.client.request.`get`
import io.ktor.client.request.header
import io.ktor.client.request.parameter
import io.ktor.http.takeFrom
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContentEntryDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContentEntryDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : ContentEntryDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(entityList: List<out ContentEntry>): Unit {
    _dao.insertListAsync(entityList)
  }

  public override suspend fun findEntryWithLanguageByEntryIdAsync(entryUuid: Long):
      ContentEntryWithLanguage? {
    val _result = _dao.findEntryWithLanguageByEntryIdAsync(entryUuid)
    return _result
  }

  public override suspend fun findEntryWithBlockAndLanguageByUidAsync(entityUid: Long):
      ContentEntryWithBlockAndLanguage? {
    val _result = _dao.findEntryWithBlockAndLanguageByUidAsync(entityUid)
    return _result
  }

  public override suspend fun findEntryWithContainerByEntryId(entryUuid: Long):
      ContentEntryWithMostRecentContainer? {
    val _result = _dao.findEntryWithContainerByEntryId(entryUuid)
    return _result
  }

  public override fun findEntryWithContainerByEntryIdLive(entryUuid: Long):
      DoorLiveData<ContentEntryWithMostRecentContainer?> {
    val _result = _dao.findEntryWithContainerByEntryIdLive(entryUuid)
    return _result
  }

  public override fun findBySourceUrl(sourceUrl: String): ContentEntry? {
    val _result = _dao.findBySourceUrl(sourceUrl)
    return _result
  }

  public override suspend fun findTitleByUidAsync(contentEntryUid: Long): String? {
    val _result = _dao.findTitleByUidAsync(contentEntryUid)
    return _result
  }

  public override fun getChildrenByParentUid(parentUid: Long): DoorDataSourceFactory<Int,
      ContentEntry> {
    val _result = _dao.getChildrenByParentUid(parentUid)
    return _result
  }

  public override suspend fun getChildrenByParentAsync(parentUid: Long): List<ContentEntry> {
    val _result = _dao.getChildrenByParentAsync(parentUid)
    return _result
  }

  public override suspend fun getContentJobItemParamsByParentUid(
    parentUid: Long,
    limit: Int,
    offset: Int
  ): List<ContentEntryContentJobItemParams> {
    val _result = _dao.getContentJobItemParamsByParentUid(parentUid, limit, offset)
    return _result
  }

  public override suspend fun getCountNumberOfChildrenByParentUUidAsync(parentUid: Long): Int {
    val _result = _dao.getCountNumberOfChildrenByParentUUidAsync(parentUid)
    return _result
  }

  public override suspend fun getContentByUuidAsync(parentUid: Long): ContentEntry? {
    val _result = _dao.getContentByUuidAsync(parentUid)
    return _result
  }

  public override suspend fun findAllLanguageRelatedEntriesAsync(entryUuid: Long):
      List<ContentEntry> {
    val _result = _dao.findAllLanguageRelatedEntriesAsync(entryUuid)
    return _result
  }

  public override suspend fun findListOfCategoriesAsync(parentUid: Long):
      List<DistinctCategorySchema> = _httpClient.`get`<List<DistinctCategorySchema>> {
    url {
      takeFrom(_endpoint)
      encodedPath = "${encodedPath}ContentEntryDao/findListOfCategoriesAsync"
    }
    doorNodeAndVersionHeaders(_repo)
    header("x-nid", _clientId)
    parameter("parentUid", parentUid)

  }

  public override suspend fun findUniqueLanguagesInListAsync(parentUid: Long): List<Language> {
    val _result = _dao.findUniqueLanguagesInListAsync(parentUid)
    return _result
  }

  public override suspend fun findUniqueLanguageWithParentUid(parentUid: Long): List<LangUidAndName>
      = _httpClient.`get`<List<LangUidAndName>> {
    url {
      takeFrom(_endpoint)
      encodedPath = "${encodedPath}ContentEntryDao/findUniqueLanguageWithParentUid"
    }
    doorNodeAndVersionHeaders(_repo)
    header("x-nid", _clientId)
    parameter("parentUid", parentUid)

  }

  public override fun update(entity: ContentEntry): Unit {
    _dao.update(entity)
  }

  public override suspend fun findByUidAsync(entryUid: Long): ContentEntry? {
    val _result = _dao.findByUidAsync(entryUid)
    return _result
  }

  public override suspend fun findByUidWithLanguageAsync(uid: Long): ContentEntryWithLanguage? {
    val _result = _dao.findByUidWithLanguageAsync(uid)
    return _result
  }

  public override fun findByUid(entryUid: Long): ContentEntry? {
    val _result = _dao.findByUid(entryUid)
    return _result
  }

  public override fun findByTitle(title: String): DoorLiveData<ContentEntry?> {
    val _result = _dao.findByTitle(title)
    return _result
  }

  public override suspend fun isMeteredAllowedForEntry(contentEntryUid: Long): Boolean {
    val _result = _dao.isMeteredAllowedForEntry(contentEntryUid)
    return _result
  }

  public override suspend fun findBySourceUrlWithContentEntryStatusAsync(sourceUrl: String):
      ContentEntry? {
    val _result = _dao.findBySourceUrlWithContentEntryStatusAsync(sourceUrl)
    return _result
  }

  public override fun getChildrenByParentUidWithCategoryFilterOrderByName(
    parentUid: Long,
    langParam: Long,
    categoryParam0: Long,
    personUid: Long,
    showHidden: Boolean,
    onlyFolder: Boolean,
    sortOrder: Int
  ): DoorDataSourceFactory<Int, ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = _dao.getChildrenByParentUidWithCategoryFilterOrderByName(parentUid, langParam,
        categoryParam0, personUid, showHidden, onlyFolder, sortOrder)
    return _result
  }

  public override fun getContentFromMyCourses(personUid: Long): DoorDataSourceFactory<Int,
      ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = _dao.getContentFromMyCourses(personUid)
    return _result
  }

  public override fun getContentByOwner(personUid: Long): DoorDataSourceFactory<Int,
      ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = _dao.getContentByOwner(personUid)
    return _result
  }

  public override suspend fun updateAsync(entity: ContentEntry): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override fun getChildrenByAll(parentUid: Long): List<ContentEntry> {
    val _result = _dao.getChildrenByAll(parentUid)
    return _result
  }

  public override fun findLiveContentEntry(parentUid: Long): DoorLiveData<ContentEntry?> {
    val _result = _dao.findLiveContentEntry(parentUid)
    return _result
  }

  public override fun getContentEntryUidFromXapiObjectId(objectId: String): Long {
    val _result = _dao.getContentEntryUidFromXapiObjectId(objectId)
    return _result
  }

  public override fun findSimilarIdEntryForKhan(sourceUrl: String): List<ContentEntry> {
    val _result = _dao.findSimilarIdEntryForKhan(sourceUrl)
    return _result
  }

  public override suspend fun getRecursiveDownloadTotals(contentEntryUid: Long):
      DownloadJobSizeInfo? = _httpClient.getOrNull<DownloadJobSizeInfo?> {
    url {
      takeFrom(_endpoint)
      encodedPath = "${encodedPath}ContentEntryDao/getRecursiveDownloadTotals"
    }
    doorNodeAndVersionHeaders(_repo)
    header("x-nid", _clientId)
    parameter("contentEntryUid", contentEntryUid)

  }

  public override fun getAllEntriesRecursively(contentEntryUid: Long): DoorDataSourceFactory<Int,
      ContentEntryWithParentChildJoinAndMostRecentContainer> {
    val _result = _dao.getAllEntriesRecursively(contentEntryUid)
    return _result
  }

  public override fun getAllEntriesRecursivelyAsList(contentEntryUid: Long):
      List<ContentEntryWithParentChildJoinAndMostRecentContainer> {
    val _result = _dao.getAllEntriesRecursivelyAsList(contentEntryUid)
    return _result
  }

  public override fun updateContentEntryInActive(
    contentEntryUid: Long,
    ceInactive: Boolean,
    changedTime: Long
  ): Unit {
    _dao.updateContentEntryInActive(contentEntryUid, ceInactive, changedTime)
  }

  public override fun updateContentEntryContentFlag(
    contentFlag: Int,
    contentEntryUid: Long,
    changedTime: Long
  ): Unit {
    _dao.updateContentEntryContentFlag(contentFlag, contentEntryUid, changedTime)
  }

  public override fun replaceList(entries: List<out ContentEntry>): Unit {
    _dao.replaceList(entries)
  }

  public override suspend fun getContentEntryFromUids(contentEntryUids: List<Long>):
      List<UidAndLabel> {
    val _result = _dao.getContentEntryFromUids(contentEntryUids)
    return _result
  }

  public override fun insertWithReplace(entry: ContentEntry): Unit {
    _dao.insertWithReplace(entry)
  }

  public override fun findAllLive(): DoorLiveData<List<ContentEntryWithLanguage>> {
    val _result = _dao.findAllLive()
    return _result
  }

  public override suspend fun personHasPermissionWithContentEntry(
    accountPersonUid: Long,
    contentEntryUid: Long,
    permission: Long
  ): Boolean {
    val _result = _dao.personHasPermissionWithContentEntry(accountPersonUid, contentEntryUid,
        permission)
    return _result
  }

  public override fun updateContentEntryActiveByContentJobUid(
    jobId: Long,
    inactive: Boolean,
    changedTime: Long
  ): Unit {
    _dao.updateContentEntryActiveByContentJobUid(jobId, inactive, changedTime)
  }

  public override suspend fun toggleVisibilityContentEntryItems(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long
  ): Unit {
    _dao.toggleVisibilityContentEntryItems(toggleVisibility, selectedItem, changedTime)
  }

  public override suspend
      fun findContentEntriesWhereIsLeafAndLatestContainerHasNoEntriesOrHasZeroFileSize():
      List<ContentEntry> {
    val _result =
        _dao.findContentEntriesWhereIsLeafAndLatestContainerHasNoEntriesOrHasZeroFileSize()
    return _result
  }

  public override suspend fun buttonsToShowForContentEntry(contentEntryUid: Long,
      platformDownloadEnabled: Boolean): ContentEntryButtonModel? {
    val _result = _dao.buttonsToShowForContentEntry(contentEntryUid, platformDownloadEnabled)
    return _result
  }

  public override suspend fun statusForDownloadDialog(contentEntryUid: Long): Int {
    val _result = _dao.statusForDownloadDialog(contentEntryUid)
    return _result
  }

  public override suspend fun statusForContentEntryList(contentEntryUid: Long):
      ContentJobItemProgressAndStatus? {
    val _result = _dao.statusForContentEntryList(contentEntryUid)
    return _result
  }

  public override fun insert(entity: ContentEntry): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ContentEntry): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out ContentEntry>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentEntry>): Unit {
    _dao.updateList(entityList)
  }
}
