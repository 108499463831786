package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoin
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLangName
import com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLanguage
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryRelatedEntryJoinDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentEntryRelatedEntryJoinDao() {
  public val _insertAdapterContentEntryRelatedEntryJoin_:
      EntityInsertionAdapter<ContentEntryRelatedEntryJoin> = object :
      EntityInsertionAdapter<ContentEntryRelatedEntryJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentEntryRelatedEntryJoin (cerejUid, cerejContentEntryUid, cerejRelatedEntryUid, cerejLastChangedBy, relType, comment, cerejRelLanguageUid, cerejLocalChangeSeqNum, cerejMasterChangeSeqNum, cerejLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentEntryRelatedEntryJoin (cerejUid, cerejContentEntryUid, cerejRelatedEntryUid, cerejLastChangedBy, relType, comment, cerejRelLanguageUid, cerejLocalChangeSeqNum, cerejMasterChangeSeqNum, cerejLct) VALUES(COALESCE(?,nextval('ContentEntryRelatedEntryJoin_cerejUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cerejUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryRelatedEntryJoin): Unit {
      if(entity.cerejUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cerejUid)
      }
      stmt.setLong(2, entity.cerejContentEntryUid)
      stmt.setLong(3, entity.cerejRelatedEntryUid)
      stmt.setInt(4, entity.cerejLastChangedBy)
      stmt.setInt(5, entity.relType)
      stmt.setString(6, entity.comment)
      stmt.setLong(7, entity.cerejRelLanguageUid)
      stmt.setLong(8, entity.cerejLocalChangeSeqNum)
      stmt.setLong(9, entity.cerejMasterChangeSeqNum)
      stmt.setLong(10, entity.cerejLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentEntryRelatedEntryJoinReplicate(cerejPk, cerejDestination)
    |      SELECT DISTINCT ContentEntryRelatedEntryJoin.cerejUid AS cerejPk,
    |             ? AS cerejDestination
    |        FROM ContentEntryRelatedEntryJoin
    |       WHERE ContentEntryRelatedEntryJoin.cerejLct != COALESCE(
    |             (SELECT cerejVersionId
    |                FROM ContentEntryRelatedEntryJoinReplicate
    |               WHERE cerejPk = ContentEntryRelatedEntryJoin.cerejUid
    |                 AND cerejDestination = ?), 0) 
    |      /*psql ON CONFLICT(cerejPk, cerejDestination) DO UPDATE
    |             SET cerejPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryRelatedEntryJoinReplicate(cerejPk, cerejDestination)
    |      SELECT DISTINCT ContentEntryRelatedEntryJoin.cerejUid AS cerejPk,
    |             ? AS cerejDestination
    |        FROM ContentEntryRelatedEntryJoin
    |       WHERE ContentEntryRelatedEntryJoin.cerejLct != COALESCE(
    |             (SELECT cerejVersionId
    |                FROM ContentEntryRelatedEntryJoinReplicate
    |               WHERE cerejPk = ContentEntryRelatedEntryJoin.cerejUid
    |                 AND cerejDestination = ?), 0) 
    |       ON CONFLICT(cerejPk, cerejDestination) DO UPDATE
    |             SET cerejPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ContentEntryRelatedEntryJoinReplicate(cerejPk, cerejDestination)
    |  SELECT DISTINCT ContentEntryRelatedEntryJoin.cerejUid AS cerejUid,
    |         UserSession.usClientNodeId AS cerejDestination
    |    FROM ChangeLog
    |         JOIN ContentEntryRelatedEntryJoin
    |             ON ChangeLog.chTableId = 8
    |                AND ChangeLog.chEntityPk = ContentEntryRelatedEntryJoin.cerejUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentEntryRelatedEntryJoin.cerejLct != COALESCE(
    |         (SELECT cerejVersionId
    |            FROM ContentEntryRelatedEntryJoinReplicate
    |           WHERE cerejPk = ContentEntryRelatedEntryJoin.cerejUid
    |             AND cerejDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cerejPk, cerejDestination) DO UPDATE
    |     SET cerejPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryRelatedEntryJoinReplicate(cerejPk, cerejDestination)
    |  SELECT DISTINCT ContentEntryRelatedEntryJoin.cerejUid AS cerejUid,
    |         UserSession.usClientNodeId AS cerejDestination
    |    FROM ChangeLog
    |         JOIN ContentEntryRelatedEntryJoin
    |             ON ChangeLog.chTableId = 8
    |                AND ChangeLog.chEntityPk = ContentEntryRelatedEntryJoin.cerejUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ContentEntryRelatedEntryJoin.cerejLct != COALESCE(
    |         (SELECT cerejVersionId
    |            FROM ContentEntryRelatedEntryJoinReplicate
    |           WHERE cerejPk = ContentEntryRelatedEntryJoin.cerejUid
    |             AND cerejDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cerejPk, cerejDestination) DO UPDATE
    |     SET cerejPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun publicContentEntryRelatedEntryJoins(): List<ContentEntryRelatedEntryJoin> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoin>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntryRelatedEntryJoin.* FROM ContentEntryRelatedEntryJoin LEFT JOIN ContentEntry ON ContentEntryRelatedEntryJoin.cerejRelatedEntryUid = ContentEntry.contentEntryUid WHERE ContentEntry.publik"
        , postgreSql = """
    |SELECT ContentEntryRelatedEntryJoin.* FROM ContentEntryRelatedEntryJoin LEFT JOIN ContentEntry ON ContentEntryRelatedEntryJoin.cerejRelatedEntryUid = ContentEntry.contentEntryUid WHERE ContentEntry.publik
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cerejUid = _resultSet.getLong("cerejUid")
          val tmp_cerejContentEntryUid = _resultSet.getLong("cerejContentEntryUid")
          val tmp_cerejRelatedEntryUid = _resultSet.getLong("cerejRelatedEntryUid")
          val tmp_cerejLastChangedBy = _resultSet.getInt("cerejLastChangedBy")
          val tmp_relType = _resultSet.getInt("relType")
          val tmp_comment = _resultSet.getString("comment")
          val tmp_cerejRelLanguageUid = _resultSet.getLong("cerejRelLanguageUid")
          val tmp_cerejLocalChangeSeqNum = _resultSet.getLong("cerejLocalChangeSeqNum")
          val tmp_cerejMasterChangeSeqNum = _resultSet.getLong("cerejMasterChangeSeqNum")
          val tmp_cerejLct = _resultSet.getLong("cerejLct")
          val _entity = ContentEntryRelatedEntryJoin()
          _entity.cerejUid = tmp_cerejUid
          _entity.cerejContentEntryUid = tmp_cerejContentEntryUid
          _entity.cerejRelatedEntryUid = tmp_cerejRelatedEntryUid
          _entity.cerejLastChangedBy = tmp_cerejLastChangedBy
          _entity.relType = tmp_relType
          _entity.comment = tmp_comment
          _entity.cerejRelLanguageUid = tmp_cerejRelLanguageUid
          _entity.cerejLocalChangeSeqNum = tmp_cerejLocalChangeSeqNum
          _entity.cerejMasterChangeSeqNum = tmp_cerejMasterChangeSeqNum
          _entity.cerejLct = tmp_cerejLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findPrimaryByTranslation(contentEntryUid: Long):
      ContentEntryRelatedEntryJoin? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoin??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContentEntryRelatedEntryJoin WHERE cerejRelatedEntryUid = ? LIMIT 1"
        , postgreSql = """
    |SELECT * FROM ContentEntryRelatedEntryJoin WHERE cerejRelatedEntryUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cerejUid = _resultSet.getLong("cerejUid")
          val tmp_cerejContentEntryUid = _resultSet.getLong("cerejContentEntryUid")
          val tmp_cerejRelatedEntryUid = _resultSet.getLong("cerejRelatedEntryUid")
          val tmp_cerejLastChangedBy = _resultSet.getInt("cerejLastChangedBy")
          val tmp_relType = _resultSet.getInt("relType")
          val tmp_comment = _resultSet.getString("comment")
          val tmp_cerejRelLanguageUid = _resultSet.getLong("cerejRelLanguageUid")
          val tmp_cerejLocalChangeSeqNum = _resultSet.getLong("cerejLocalChangeSeqNum")
          val tmp_cerejMasterChangeSeqNum = _resultSet.getLong("cerejMasterChangeSeqNum")
          val tmp_cerejLct = _resultSet.getLong("cerejLct")
          val _entity = ContentEntryRelatedEntryJoin()
          _entity.cerejUid = tmp_cerejUid
          _entity.cerejContentEntryUid = tmp_cerejContentEntryUid
          _entity.cerejRelatedEntryUid = tmp_cerejRelatedEntryUid
          _entity.cerejLastChangedBy = tmp_cerejLastChangedBy
          _entity.relType = tmp_relType
          _entity.comment = tmp_comment
          _entity.cerejRelLanguageUid = tmp_cerejRelLanguageUid
          _entity.cerejLocalChangeSeqNum = tmp_cerejLocalChangeSeqNum
          _entity.cerejMasterChangeSeqNum = tmp_cerejMasterChangeSeqNum
          _entity.cerejLct = tmp_cerejLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findAllTranslationsForContentEntryAsync(contentEntryUid: Long):
      List<ContentEntryRelatedEntryJoinWithLangName> {
    var _result =
        mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLangName>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ContentEntryRelatedEntryJoin.cerejContentEntryUid, ContentEntryRelatedEntryJoin.cerejRelatedEntryUid, CASE ContentEntryRelatedEntryJoin.cerejRelatedEntryUid WHEN ? THEN (SELECT name FROM Language WHERE langUid = (SELECT primaryLanguageUid FROM ContentEntry WHERE contentEntryUid = ContentEntryRelatedEntryJoin.cerejContentEntryUid)) ELSE Language.name END languageName FROM ContentEntryRelatedEntryJoin LEFT JOIN Language ON ContentEntryRelatedEntryJoin.cerejRelLanguageUid = Language.langUid WHERE (ContentEntryRelatedEntryJoin.cerejContentEntryUid = ? OR ContentEntryRelatedEntryJoin.cerejContentEntryUid IN (SELECT cerejContentEntryUid FROM ContentEntryRelatedEntryJoin WHERE cerejRelatedEntryUid = ?)) AND ContentEntryRelatedEntryJoin.relType = 1"
        , postgreSql = """
    |SELECT ContentEntryRelatedEntryJoin.cerejContentEntryUid, ContentEntryRelatedEntryJoin.cerejRelatedEntryUid, CASE ContentEntryRelatedEntryJoin.cerejRelatedEntryUid WHEN ? THEN (SELECT name FROM Language WHERE langUid = (SELECT primaryLanguageUid FROM ContentEntry WHERE contentEntryUid = ContentEntryRelatedEntryJoin.cerejContentEntryUid)) ELSE Language.name END languageName FROM ContentEntryRelatedEntryJoin LEFT JOIN Language ON ContentEntryRelatedEntryJoin.cerejRelLanguageUid = Language.langUid WHERE (ContentEntryRelatedEntryJoin.cerejContentEntryUid = ? OR ContentEntryRelatedEntryJoin.cerejContentEntryUid IN (SELECT cerejContentEntryUid FROM ContentEntryRelatedEntryJoin WHERE cerejRelatedEntryUid = ?)) AND ContentEntryRelatedEntryJoin.relType = 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, contentEntryUid)
      _stmt.setLong(2, contentEntryUid)
      _stmt.setLong(3, contentEntryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_cerejContentEntryUid = _resultSet.getLong("cerejContentEntryUid")
          val tmp_cerejRelatedEntryUid = _resultSet.getLong("cerejRelatedEntryUid")
          val tmp_languageName = _resultSet.getString("languageName")
          val _entity = ContentEntryRelatedEntryJoinWithLangName()
          _entity.cerejContentEntryUid = tmp_cerejContentEntryUid
          _entity.cerejRelatedEntryUid = tmp_cerejRelatedEntryUid
          _entity.languageName = tmp_languageName
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllTranslationsWithContentEntryUid(contentEntryUid: Long):
      DoorDataSourceFactory<Int, ContentEntryRelatedEntryJoinWithLanguage> {
    val _result = object : DoorDataSourceFactory<Int, ContentEntryRelatedEntryJoinWithLanguage>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ContentEntryRelatedEntryJoinWithLanguage>> =
          DoorLiveDataImpl<List<ContentEntryRelatedEntryJoinWithLanguage>>(_db,
          listOf("ContentEntryRelatedEntryJoin", "Language"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryRelatedEntryJoinWithLanguage>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT ContentEntryRelatedEntryJoin.*, Language.* FROM ContentEntryRelatedEntryJoin
        |        LEFT JOIN Language ON ContentEntryRelatedEntryJoin.cerejRelLanguageUid = Language.langUid
        |        WHERE (ContentEntryRelatedEntryJoin.cerejContentEntryUid = ?
        |        OR ContentEntryRelatedEntryJoin.cerejContentEntryUid IN
        |        (SELECT cerejContentEntryUid FROM ContentEntryRelatedEntryJoin WHERE cerejRelatedEntryUid = ?))
        |        AND ContentEntryRelatedEntryJoin.relType = 1
        |        ORDER BY Language.name) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT ContentEntryRelatedEntryJoin.*, Language.* FROM ContentEntryRelatedEntryJoin
        |        LEFT JOIN Language ON ContentEntryRelatedEntryJoin.cerejRelLanguageUid = Language.langUid
        |        WHERE (ContentEntryRelatedEntryJoin.cerejContentEntryUid = ?
        |        OR ContentEntryRelatedEntryJoin.cerejContentEntryUid IN
        |        (SELECT cerejContentEntryUid FROM ContentEntryRelatedEntryJoin WHERE cerejRelatedEntryUid = ?))
        |        AND ContentEntryRelatedEntryJoin.relType = 1
        |        ORDER BY Language.name) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, contentEntryUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_cerejUid = _resultSet.getLong("cerejUid")
              val tmp_cerejContentEntryUid = _resultSet.getLong("cerejContentEntryUid")
              val tmp_cerejRelatedEntryUid = _resultSet.getLong("cerejRelatedEntryUid")
              val tmp_cerejLastChangedBy = _resultSet.getInt("cerejLastChangedBy")
              val tmp_relType = _resultSet.getInt("relType")
              val tmp_comment = _resultSet.getString("comment")
              val tmp_cerejRelLanguageUid = _resultSet.getLong("cerejRelLanguageUid")
              val tmp_cerejLocalChangeSeqNum = _resultSet.getLong("cerejLocalChangeSeqNum")
              val tmp_cerejMasterChangeSeqNum = _resultSet.getLong("cerejMasterChangeSeqNum")
              val tmp_cerejLct = _resultSet.getLong("cerejLct")
              val _entity = ContentEntryRelatedEntryJoinWithLanguage()
              _entity.cerejUid = tmp_cerejUid
              _entity.cerejContentEntryUid = tmp_cerejContentEntryUid
              _entity.cerejRelatedEntryUid = tmp_cerejRelatedEntryUid
              _entity.cerejLastChangedBy = tmp_cerejLastChangedBy
              _entity.relType = tmp_relType
              _entity.comment = tmp_comment
              _entity.cerejRelLanguageUid = tmp_cerejRelLanguageUid
              _entity.cerejLocalChangeSeqNum = tmp_cerejLocalChangeSeqNum
              _entity.cerejMasterChangeSeqNum = tmp_cerejMasterChangeSeqNum
              _entity.cerejLct = tmp_cerejLct
              var _language_nullFieldCount = 0
              val tmp_langUid = _resultSet.getLong("langUid")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_name = _resultSet.getString("name")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_Language_Type = _resultSet.getString("Language_Type")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_languageActive = _resultSet.getBoolean("languageActive")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              val tmp_langLct = _resultSet.getLong("langLct")
              if(_resultSet.wasNull()) { _language_nullFieldCount++ }
              if(_language_nullFieldCount < 11) {
                if(_entity.language == null) {
                  _entity.language = Language()
                }
                _entity.language!!.langUid = tmp_langUid
                _entity.language!!.name = tmp_name
                _entity.language!!.iso_639_1_standard = tmp_iso_639_1_standard
                _entity.language!!.iso_639_2_standard = tmp_iso_639_2_standard
                _entity.language!!.iso_639_3_standard = tmp_iso_639_3_standard
                _entity.language!!.Language_Type = tmp_Language_Type
                _entity.language!!.languageActive = tmp_languageActive
                _entity.language!!.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
                _entity.language!!.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
                _entity.language!!.langLastChangedBy = tmp_langLastChangedBy
                _entity.language!!.langLct = tmp_langLct
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ContentEntryRelatedEntryJoin", "Language"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT ContentEntryRelatedEntryJoin.*, Language.* FROM ContentEntryRelatedEntryJoin
        |        LEFT JOIN Language ON ContentEntryRelatedEntryJoin.cerejRelLanguageUid = Language.langUid
        |        WHERE (ContentEntryRelatedEntryJoin.cerejContentEntryUid = ?
        |        OR ContentEntryRelatedEntryJoin.cerejContentEntryUid IN
        |        (SELECT cerejContentEntryUid FROM ContentEntryRelatedEntryJoin WHERE cerejRelatedEntryUid = ?))
        |        AND ContentEntryRelatedEntryJoin.relType = 1
        |        ORDER BY Language.name) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT ContentEntryRelatedEntryJoin.*, Language.* FROM ContentEntryRelatedEntryJoin
        |        LEFT JOIN Language ON ContentEntryRelatedEntryJoin.cerejRelLanguageUid = Language.langUid
        |        WHERE (ContentEntryRelatedEntryJoin.cerejContentEntryUid = ?
        |        OR ContentEntryRelatedEntryJoin.cerejContentEntryUid IN
        |        (SELECT cerejContentEntryUid FROM ContentEntryRelatedEntryJoin WHERE cerejRelatedEntryUid = ?))
        |        AND ContentEntryRelatedEntryJoin.relType = 1
        |        ORDER BY Language.name) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, contentEntryUid)
          _stmt.setLong(2, contentEntryUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun update(entity: ContentEntryRelatedEntryJoin): Unit {
    val _sql =
        "UPDATE ContentEntryRelatedEntryJoin SET cerejContentEntryUid = ?, cerejRelatedEntryUid = ?, cerejLastChangedBy = ?, relType = ?, comment = ?, cerejRelLanguageUid = ?, cerejLocalChangeSeqNum = ?, cerejMasterChangeSeqNum = ?, cerejLct = ? WHERE cerejUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cerejContentEntryUid)
      _stmt.setLong(2, entity.cerejRelatedEntryUid)
      _stmt.setInt(3, entity.cerejLastChangedBy)
      _stmt.setInt(4, entity.relType)
      _stmt.setString(5, entity.comment)
      _stmt.setLong(6, entity.cerejRelLanguageUid)
      _stmt.setLong(7, entity.cerejLocalChangeSeqNum)
      _stmt.setLong(8, entity.cerejMasterChangeSeqNum)
      _stmt.setLong(9, entity.cerejLct)
      _stmt.setLong(10, entity.cerejUid)
      _stmt.executeUpdate()
    }
  }

  public override fun insert(entity: ContentEntryRelatedEntryJoin): Long {
    val _retVal = _insertAdapterContentEntryRelatedEntryJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntryRelatedEntryJoin): Long {
    val _retVal = _insertAdapterContentEntryRelatedEntryJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContentEntryRelatedEntryJoin>): Unit {
    _insertAdapterContentEntryRelatedEntryJoin_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentEntryRelatedEntryJoin>): Unit {
    val _sql =
        "UPDATE ContentEntryRelatedEntryJoin SET cerejContentEntryUid = ?, cerejRelatedEntryUid = ?, cerejLastChangedBy = ?, relType = ?, comment = ?, cerejRelLanguageUid = ?, cerejLocalChangeSeqNum = ?, cerejMasterChangeSeqNum = ?, cerejLct = ? WHERE cerejUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cerejContentEntryUid)
        _stmt.setLong(2, _entity.cerejRelatedEntryUid)
        _stmt.setInt(3, _entity.cerejLastChangedBy)
        _stmt.setInt(4, _entity.relType)
        _stmt.setString(5, _entity.comment)
        _stmt.setLong(6, _entity.cerejRelLanguageUid)
        _stmt.setLong(7, _entity.cerejLocalChangeSeqNum)
        _stmt.setLong(8, _entity.cerejMasterChangeSeqNum)
        _stmt.setLong(9, _entity.cerejLct)
        _stmt.setLong(10, _entity.cerejUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }
}
