package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ChatMember
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ChatMemberDao_JdbcKt(
  public val _db: DoorDatabase
) : ChatMemberDao() {
  public val _insertAdapterChatMember_: EntityInsertionAdapter<ChatMember> = object :
      EntityInsertionAdapter<ChatMember>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ChatMember (chatMemberUid, chatMemberChatUid, chatMemberPersonUid, chatMemberJoinedDate, chatMemberLeftDate, chatMemberLct) VALUES(?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ChatMember (chatMemberUid, chatMemberChatUid, chatMemberPersonUid, chatMemberJoinedDate, chatMemberLeftDate, chatMemberLct) VALUES(COALESCE(?,nextval('ChatMember_chatMemberUid_seq')), ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING chatMemberUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ChatMember):
        Unit {
      if(entity.chatMemberUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.chatMemberUid)
      }
      stmt.setLong(2, entity.chatMemberChatUid)
      stmt.setLong(3, entity.chatMemberPersonUid)
      stmt.setLong(4, entity.chatMemberJoinedDate)
      stmt.setLong(5, entity.chatMemberLeftDate)
      stmt.setLong(6, entity.chatMemberLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO chatMemberReplicate(chatMemberPk, chatMemberDestination)
    |      SELECT DISTINCT ChatMember.chatMemberUid AS chatMemberPk,
    |             ? AS chatMemberDestination
    |             
    |        FROM UserSession
    |            JOIN ChatMember 
    |                 ON ((ChatMember.chatMemberChatUid IN
    |                      (SELECT chatMemberInternal.chatMemberChatUid 
    |                         FROM ChatMember chatMemberInternal
    |                        WHERE chatMemberInternal.chatMemberPersonUid = UserSession.usPersonUid))
    |                     OR UserSession.usSessionType = 2)
    |                 AND UserSession.usStatus = 1 
    |       WHERE ChatMember.chatMemberLct != COALESCE(
    |             (SELECT chatMemberVersionId
    |                FROM chatMemberReplicate
    |               WHERE chatMemberPk = ChatMember.chatMemberUid
    |                 AND chatMemberDestination = ?), 0) 
    |      /*psql ON CONFLICT(chatMemberPk, chatMemberDestination) DO UPDATE
    |             SET chatMemberPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO chatMemberReplicate(chatMemberPk, chatMemberDestination)
    |      SELECT DISTINCT ChatMember.chatMemberUid AS chatMemberPk,
    |             ? AS chatMemberDestination
    |             
    |        FROM UserSession
    |            JOIN ChatMember 
    |                 ON ((ChatMember.chatMemberChatUid IN
    |                      (SELECT chatMemberInternal.chatMemberChatUid 
    |                         FROM ChatMember chatMemberInternal
    |                        WHERE chatMemberInternal.chatMemberPersonUid = UserSession.usPersonUid))
    |                     OR UserSession.usSessionType = 2)
    |                 AND UserSession.usStatus = 1 
    |       WHERE ChatMember.chatMemberLct != COALESCE(
    |             (SELECT chatMemberVersionId
    |                FROM chatMemberReplicate
    |               WHERE chatMemberPk = ChatMember.chatMemberUid
    |                 AND chatMemberDestination = ?), 0) 
    |       ON CONFLICT(chatMemberPk, chatMemberDestination) DO UPDATE
    |             SET chatMemberPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         REPLACE INTO chatMemberReplicate(chatMemberPk, chatMemberDestination)
    |          SELECT DISTINCT ChatMember.chatMemberUid AS chatMemberUid,
    |                 UserSession.usClientNodeId AS chatMemberDestination
    |            FROM ChangeLog
    |                 JOIN chatMember
    |                     ON ChangeLog.chTableId = 128
    |                        AND ChangeLog.chEntityPk = ChatMember.chatMemberUid
    |                        
    |                 JOIN UserSession ON 
    |                      (UserSession.usSessionType = 2
    |                      OR (UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid 
    |                              FROM ChatMember 
    |                             WHERE ChatMember.chatMemberChatUid IN 
    |                                   (SELECT ChatMemberInternal.chatMemberChatUid 
    |                                      FROM ChatMember ChatMemberInternal
    |                                     WHERE ChatMemberInternal.chatMemberPersonUid = 
    |                                           UserSession.usPersonUid))))
    |                      AND UserSession.usStatus = 1
    |                      
    |                
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND ChatMember.chatMemberLct != COALESCE(
    |                 (SELECT chatMemberVersionId
    |                    FROM chatMemberReplicate
    |                   WHERE chatMemberPk = ChatMember.chatMemberUid
    |                     AND chatMemberDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(chatMemberPk, chatMemberDestination) DO UPDATE
    |             SET chatMemberPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO chatMemberReplicate(chatMemberPk, chatMemberDestination)
    |          SELECT DISTINCT ChatMember.chatMemberUid AS chatMemberUid,
    |                 UserSession.usClientNodeId AS chatMemberDestination
    |            FROM ChangeLog
    |                 JOIN chatMember
    |                     ON ChangeLog.chTableId = 128
    |                        AND ChangeLog.chEntityPk = ChatMember.chatMemberUid
    |                        
    |                 JOIN UserSession ON 
    |                      (UserSession.usSessionType = 2
    |                      OR (UserSession.usPersonUid IN 
    |                           (SELECT ChatMember.chatMemberPersonUid 
    |                              FROM ChatMember 
    |                             WHERE ChatMember.chatMemberChatUid IN 
    |                                   (SELECT ChatMemberInternal.chatMemberChatUid 
    |                                      FROM ChatMember ChatMemberInternal
    |                                     WHERE ChatMemberInternal.chatMemberPersonUid = 
    |                                           UserSession.usPersonUid))))
    |                      AND UserSession.usStatus = 1
    |                      
    |                
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND ChatMember.chatMemberLct != COALESCE(
    |                 (SELECT chatMemberVersionId
    |                    FROM chatMemberReplicate
    |                   WHERE chatMemberPk = ChatMember.chatMemberUid
    |                     AND chatMemberDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(chatMemberPk, chatMemberDestination) DO UPDATE
    |             SET chatMemberPending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: ChatMember): Long {
    val _retVal = _insertAdapterChatMember_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ChatMember): Long {
    val _retVal = _insertAdapterChatMember_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ChatMember>): Unit {
    _insertAdapterChatMember_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ChatMember>): Unit {
    val _sql =
        "UPDATE ChatMember SET chatMemberChatUid = ?, chatMemberPersonUid = ?, chatMemberJoinedDate = ?, chatMemberLeftDate = ?, chatMemberLct = ? WHERE chatMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.chatMemberChatUid)
        _stmt.setLong(2, _entity.chatMemberPersonUid)
        _stmt.setLong(3, _entity.chatMemberJoinedDate)
        _stmt.setLong(4, _entity.chatMemberLeftDate)
        _stmt.setLong(5, _entity.chatMemberLct)
        _stmt.setLong(6, _entity.chatMemberUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ChatMember): Unit {
    val _sql =
        "UPDATE ChatMember SET chatMemberChatUid = ?, chatMemberPersonUid = ?, chatMemberJoinedDate = ?, chatMemberLeftDate = ?, chatMemberLct = ? WHERE chatMemberUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.chatMemberChatUid)
      _stmt.setLong(2, entity.chatMemberPersonUid)
      _stmt.setLong(3, entity.chatMemberJoinedDate)
      _stmt.setLong(4, entity.chatMemberLeftDate)
      _stmt.setLong(5, entity.chatMemberLct)
      _stmt.setLong(6, entity.chatMemberUid)
      _stmt.executeUpdate()
    }
  }
}
