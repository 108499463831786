package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.CourseTerminology
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class CourseTerminologyDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: CourseTerminologyDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : CourseTerminologyDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findAllCourseTerminology(): DoorDataSourceFactory<Int, CourseTerminology> {
    val _result = _dao.findAllCourseTerminology()
    return _result
  }

  public override fun findAllCourseTerminologyList(): List<CourseTerminology> {
    val _result = _dao.findAllCourseTerminologyList()
    return _result
  }

  public override suspend fun getTerminologyForClazz(clazzUid: Long): CourseTerminology? {
    val _result = _dao.getTerminologyForClazz(clazzUid)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): CourseTerminology? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override suspend fun updateAsync(entity: CourseTerminology): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override fun insert(entity: CourseTerminology): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: CourseTerminology): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out CourseTerminology>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseTerminology>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: CourseTerminology): Unit {
    _dao.update(entity)
  }
}
