package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Clazz
import com.ustadmobile.lib.db.entities.ClazzEnrolment
import com.ustadmobile.lib.db.entities.ClazzWithDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithHolidayCalendarAndSchoolAndTerminology
import com.ustadmobile.lib.db.entities.ClazzWithListDisplayDetails
import com.ustadmobile.lib.db.entities.ClazzWithSchool
import com.ustadmobile.lib.db.entities.CourseTerminology
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.School
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class ClazzDao_JdbcKt(
  public val _db: DoorDatabase
) : ClazzDao() {
  public val _insertAdapterClazz_: EntityInsertionAdapter<Clazz> = object :
      EntityInsertionAdapter<Clazz>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Clazz (clazzUid, clazzName, clazzDesc, attendanceAverage, clazzHolidayUMCalendarUid, clazzScheuleUMCalendarUid, isClazzActive, clazzLocationUid, clazzStartTime, clazzEndTime, clazzFeatures, clazzSchoolUid, clazzEnrolmentPolicy, clazzTerminologyUid, clazzMasterChangeSeqNum, clazzLocalChangeSeqNum, clazzLastChangedBy, clazzLct, clazzTimeZone, clazzStudentsPersonGroupUid, clazzTeachersPersonGroupUid, clazzPendingStudentsPersonGroupUid, clazzParentsPersonGroupUid, clazzCode) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Clazz (clazzUid, clazzName, clazzDesc, attendanceAverage, clazzHolidayUMCalendarUid, clazzScheuleUMCalendarUid, isClazzActive, clazzLocationUid, clazzStartTime, clazzEndTime, clazzFeatures, clazzSchoolUid, clazzEnrolmentPolicy, clazzTerminologyUid, clazzMasterChangeSeqNum, clazzLocalChangeSeqNum, clazzLastChangedBy, clazzLct, clazzTimeZone, clazzStudentsPersonGroupUid, clazzTeachersPersonGroupUid, clazzPendingStudentsPersonGroupUid, clazzParentsPersonGroupUid, clazzCode) VALUES(COALESCE(?,nextval('Clazz_clazzUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING clazzUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Clazz): Unit {
      if(entity.clazzUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.clazzUid)
      }
      stmt.setString(2, entity.clazzName)
      stmt.setString(3, entity.clazzDesc)
      stmt.setFloat(4, entity.attendanceAverage)
      stmt.setLong(5, entity.clazzHolidayUMCalendarUid)
      stmt.setLong(6, entity.clazzScheuleUMCalendarUid)
      stmt.setBoolean(7, entity.isClazzActive)
      stmt.setLong(8, entity.clazzLocationUid)
      stmt.setLong(9, entity.clazzStartTime)
      stmt.setLong(10, entity.clazzEndTime)
      stmt.setLong(11, entity.clazzFeatures)
      stmt.setLong(12, entity.clazzSchoolUid)
      stmt.setInt(13, entity.clazzEnrolmentPolicy)
      stmt.setLong(14, entity.clazzTerminologyUid)
      stmt.setLong(15, entity.clazzMasterChangeSeqNum)
      stmt.setLong(16, entity.clazzLocalChangeSeqNum)
      stmt.setInt(17, entity.clazzLastChangedBy)
      stmt.setLong(18, entity.clazzLct)
      stmt.setString(19, entity.clazzTimeZone)
      stmt.setLong(20, entity.clazzStudentsPersonGroupUid)
      stmt.setLong(21, entity.clazzTeachersPersonGroupUid)
      stmt.setLong(22, entity.clazzPendingStudentsPersonGroupUid)
      stmt.setLong(23, entity.clazzParentsPersonGroupUid)
      stmt.setString(24, entity.clazzCode)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzReplicate(clazzPk, clazzDestination)
    |      SELECT DISTINCT Clazz.clazzUid AS clazzUid,
    |             ? AS clazzDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |       WHERE UserSession.usClientNodeId = ? 
    |         AND Clazz.clazzLct != COALESCE(
    |             (SELECT clazzVersionId
    |                FROM ClazzReplicate
    |               WHERE clazzPk = Clazz.clazzUid
    |                 AND clazzDestination = ?), 0) 
    |      /*psql ON CONFLICT(clazzPk, clazzDestination) DO UPDATE
    |             SET clazzPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzReplicate(clazzPk, clazzDestination)
    |      SELECT DISTINCT Clazz.clazzUid AS clazzUid,
    |             ? AS clazzDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    2 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |       WHERE UserSession.usClientNodeId = ? 
    |         AND Clazz.clazzLct != COALESCE(
    |             (SELECT clazzVersionId
    |                FROM ClazzReplicate
    |               WHERE clazzPk = Clazz.clazzUid
    |                 AND clazzDestination = ?), 0) 
    |       ON CONFLICT(clazzPk, clazzDestination) DO UPDATE
    |             SET clazzPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzReplicate(clazzPk, clazzDestination)
    |  SELECT DISTINCT Clazz.clazzUid AS clazzUid,
    |         UserSession.usClientNodeId AS clazzDestination
    |    FROM ChangeLog
    |         JOIN Clazz
    |             ON ChangeLog.chTableId = 6
    |                AND ChangeLog.chEntityPk = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                    2
    |                    
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Clazz.clazzLct != COALESCE(
    |         (SELECT clazzVersionId
    |            FROM ClazzReplicate
    |           WHERE clazzPk = Clazz.clazzUid
    |             AND clazzDestination = UserSession.usClientNodeId), 0)
    |  /*psql ON CONFLICT(clazzPk, clazzDestination) DO UPDATE
    |      SET clazzPending = true
    |   */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzReplicate(clazzPk, clazzDestination)
    |  SELECT DISTINCT Clazz.clazzUid AS clazzUid,
    |         UserSession.usClientNodeId AS clazzDestination
    |    FROM ChangeLog
    |         JOIN Clazz
    |             ON ChangeLog.chTableId = 6
    |                AND ChangeLog.chEntityPk = Clazz.clazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                    2
    |                    
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Clazz.clazzLct != COALESCE(
    |         (SELECT clazzVersionId
    |            FROM ClazzReplicate
    |           WHERE clazzPk = Clazz.clazzUid
    |             AND clazzDestination = UserSession.usClientNodeId), 0)
    |   ON CONFLICT(clazzPk, clazzDestination) DO UPDATE
    |      SET clazzPending = true
    |                  
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): Clazz? {
    var _result = null as com.ustadmobile.lib.db.entities.Clazz??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzUid = ?" , postgreSql
        = """
    |SELECT * FROM Clazz WHERE clazzUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = Clazz()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<Clazz?> {
    val _result = DoorLiveDataImpl<Clazz?>(_db, listOf("Clazz"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.Clazz??
      val _stmtConfig = PreparedStatementConfig("SELECT * From Clazz WHERE clazzUid = ?" ,
          postgreSql = """
      |SELECT * From Clazz WHERE clazzUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_clazzUid = _resultSet.getLong("clazzUid")
            val tmp_clazzName = _resultSet.getString("clazzName")
            val tmp_clazzDesc = _resultSet.getString("clazzDesc")
            val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
            val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
            val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
            val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
            val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
            val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
            val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
            val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
            val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
            val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
            val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
            val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
            val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
            val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
            val tmp_clazzLct = _resultSet.getLong("clazzLct")
            val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
            val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
            val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
            val tmp_clazzPendingStudentsPersonGroupUid =
                _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
            val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
            val tmp_clazzCode = _resultSet.getString("clazzCode")
            val _entity = Clazz()
            _entity.clazzUid = tmp_clazzUid
            _entity.clazzName = tmp_clazzName
            _entity.clazzDesc = tmp_clazzDesc
            _entity.attendanceAverage = tmp_attendanceAverage
            _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
            _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
            _entity.isClazzActive = tmp_isClazzActive
            _entity.clazzLocationUid = tmp_clazzLocationUid
            _entity.clazzStartTime = tmp_clazzStartTime
            _entity.clazzEndTime = tmp_clazzEndTime
            _entity.clazzFeatures = tmp_clazzFeatures
            _entity.clazzSchoolUid = tmp_clazzSchoolUid
            _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
            _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
            _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
            _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
            _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
            _entity.clazzLct = tmp_clazzLct
            _entity.clazzTimeZone = tmp_clazzTimeZone
            _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
            _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
            _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
            _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
            _entity.clazzCode = tmp_clazzCode
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun findByClazzCode(code: String): Clazz? {
    var _result = null as com.ustadmobile.lib.db.entities.Clazz??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzCode = ?" , postgreSql
        = """
    |SELECT * FROM Clazz WHERE clazzCode = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, code)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = Clazz()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByClazzCodeFromWeb(code: String): Clazz? {
    var _result = null as com.ustadmobile.lib.db.entities.Clazz??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzCode = ?" , postgreSql
        = """
    |SELECT * FROM Clazz WHERE clazzCode = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, code)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = Clazz()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findAllLive(): DoorLiveData<List<Clazz>> {
    val _result = DoorLiveDataImpl<List<Clazz>>(_db, listOf("Clazz"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.Clazz>()
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM Clazz WHERE CAST(isClazzActive AS INTEGER) = 1" ,
          postgreSql = """
      |SELECT * FROM Clazz WHERE CAST(isClazzActive AS INTEGER) = 1
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_clazzUid = _resultSet.getLong("clazzUid")
            val tmp_clazzName = _resultSet.getString("clazzName")
            val tmp_clazzDesc = _resultSet.getString("clazzDesc")
            val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
            val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
            val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
            val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
            val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
            val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
            val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
            val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
            val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
            val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
            val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
            val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
            val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
            val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
            val tmp_clazzLct = _resultSet.getLong("clazzLct")
            val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
            val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
            val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
            val tmp_clazzPendingStudentsPersonGroupUid =
                _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
            val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
            val tmp_clazzCode = _resultSet.getString("clazzCode")
            val _entity = Clazz()
            _entity.clazzUid = tmp_clazzUid
            _entity.clazzName = tmp_clazzName
            _entity.clazzDesc = tmp_clazzDesc
            _entity.attendanceAverage = tmp_attendanceAverage
            _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
            _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
            _entity.isClazzActive = tmp_isClazzActive
            _entity.clazzLocationUid = tmp_clazzLocationUid
            _entity.clazzStartTime = tmp_clazzStartTime
            _entity.clazzEndTime = tmp_clazzEndTime
            _entity.clazzFeatures = tmp_clazzFeatures
            _entity.clazzSchoolUid = tmp_clazzSchoolUid
            _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
            _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
            _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
            _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
            _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
            _entity.clazzLct = tmp_clazzLct
            _entity.clazzTimeZone = tmp_clazzTimeZone
            _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
            _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
            _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
            _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
            _entity.clazzCode = tmp_clazzCode
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override fun findAll(): List<Clazz> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Clazz>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Clazz WHERE CAST(isClazzActive AS INTEGER) = 1" ,
        postgreSql = """
    |SELECT * FROM Clazz WHERE CAST(isClazzActive AS INTEGER) = 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = Clazz()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): Clazz? {
    var _result = null as com.ustadmobile.lib.db.entities.Clazz??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzUid = ?" , postgreSql
        = """
    |SELECT * FROM Clazz WHERE clazzUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = Clazz()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidWithHolidayCalendarAsync(uid: Long):
      ClazzWithHolidayCalendarAndSchoolAndTerminology? {
    var _result = null as
        com.ustadmobile.lib.db.entities.ClazzWithHolidayCalendarAndSchoolAndTerminology??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Clazz.*, 
    |               HolidayCalendar.*, 
    |               School.*,
    |               CourseTerminology.*
    |          FROM Clazz 
    |               LEFT JOIN HolidayCalendar 
    |               ON Clazz.clazzHolidayUMCalendarUid = HolidayCalendar.umCalendarUid
    |               
    |               LEFT JOIN School 
    |               ON School.schoolUid = Clazz.clazzSchoolUid
    |               
    |               LEFT JOIN CourseTerminology
    |               ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
    |         WHERE Clazz.clazzUid = ?
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Clazz.*, 
    |               HolidayCalendar.*, 
    |               School.*,
    |               CourseTerminology.*
    |          FROM Clazz 
    |               LEFT JOIN HolidayCalendar 
    |               ON Clazz.clazzHolidayUMCalendarUid = HolidayCalendar.umCalendarUid
    |               
    |               LEFT JOIN School 
    |               ON School.schoolUid = Clazz.clazzSchoolUid
    |               
    |               LEFT JOIN CourseTerminology
    |               ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
    |         WHERE Clazz.clazzUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = ClazzWithHolidayCalendarAndSchoolAndTerminology()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          var _holidayCalendar_nullFieldCount = 0
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarName = _resultSet.getString("umCalendarName")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarMasterChangeSeqNum = _resultSet.getLong("umCalendarMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLocalChangeSeqNum = _resultSet.getLong("umCalendarLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          if(_holidayCalendar_nullFieldCount < 8) {
            if(_entity.holidayCalendar == null) {
              _entity.holidayCalendar = HolidayCalendar()
            }
            _entity.holidayCalendar!!.umCalendarUid = tmp_umCalendarUid
            _entity.holidayCalendar!!.umCalendarName = tmp_umCalendarName
            _entity.holidayCalendar!!.umCalendarCategory = tmp_umCalendarCategory
            _entity.holidayCalendar!!.umCalendarActive = tmp_umCalendarActive
            _entity.holidayCalendar!!.umCalendarMasterChangeSeqNum =
                tmp_umCalendarMasterChangeSeqNum
            _entity.holidayCalendar!!.umCalendarLocalChangeSeqNum = tmp_umCalendarLocalChangeSeqNum
            _entity.holidayCalendar!!.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
            _entity.holidayCalendar!!.umCalendarLct = tmp_umCalendarLct
          }
          var _school_nullFieldCount = 0
          val tmp_schoolUid = _resultSet.getLong("schoolUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolName = _resultSet.getString("schoolName")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolDesc = _resultSet.getString("schoolDesc")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolAddress = _resultSet.getString("schoolAddress")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolGender = _resultSet.getInt("schoolGender")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolTeachersPersonGroupUid = _resultSet.getLong("schoolTeachersPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolStudentsPersonGroupUid = _resultSet.getLong("schoolStudentsPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolPendingStudentsPersonGroupUid =
              _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolCode = _resultSet.getString("schoolCode")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLct = _resultSet.getLong("schoolLct")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          if(_school_nullFieldCount < 21) {
            if(_entity.school == null) {
              _entity.school = School()
            }
            _entity.school!!.schoolUid = tmp_schoolUid
            _entity.school!!.schoolName = tmp_schoolName
            _entity.school!!.schoolDesc = tmp_schoolDesc
            _entity.school!!.schoolAddress = tmp_schoolAddress
            _entity.school!!.schoolActive = tmp_schoolActive
            _entity.school!!.schoolPhoneNumber = tmp_schoolPhoneNumber
            _entity.school!!.schoolGender = tmp_schoolGender
            _entity.school!!.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
            _entity.school!!.schoolFeatures = tmp_schoolFeatures
            _entity.school!!.schoolLocationLong = tmp_schoolLocationLong
            _entity.school!!.schoolLocationLatt = tmp_schoolLocationLatt
            _entity.school!!.schoolEmailAddress = tmp_schoolEmailAddress
            _entity.school!!.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
            _entity.school!!.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
            _entity.school!!.schoolPendingStudentsPersonGroupUid =
                tmp_schoolPendingStudentsPersonGroupUid
            _entity.school!!.schoolCode = tmp_schoolCode
            _entity.school!!.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
            _entity.school!!.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
            _entity.school!!.schoolLastChangedBy = tmp_schoolLastChangedBy
            _entity.school!!.schoolLct = tmp_schoolLct
            _entity.school!!.schoolTimeZone = tmp_schoolTimeZone
          }
          var _terminology_nullFieldCount = 0
          val tmp_ctUid = _resultSet.getLong("ctUid")
          if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
          val tmp_ctTitle = _resultSet.getString("ctTitle")
          if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
          val tmp_ctTerminology = _resultSet.getString("ctTerminology")
          if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
          val tmp_ctLct = _resultSet.getLong("ctLct")
          if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
          if(_terminology_nullFieldCount < 4) {
            if(_entity.terminology == null) {
              _entity.terminology = CourseTerminology()
            }
            _entity.terminology!!.ctUid = tmp_ctUid
            _entity.terminology!!.ctTitle = tmp_ctTitle
            _entity.terminology!!.ctTerminology = tmp_ctTerminology
            _entity.terminology!!.ctLct = tmp_ctLct
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: Clazz): Int {
    var _result = 0
    val _sql =
        "UPDATE Clazz SET clazzName = ?, clazzDesc = ?, attendanceAverage = ?, clazzHolidayUMCalendarUid = ?, clazzScheuleUMCalendarUid = ?, isClazzActive = ?, clazzLocationUid = ?, clazzStartTime = ?, clazzEndTime = ?, clazzFeatures = ?, clazzSchoolUid = ?, clazzEnrolmentPolicy = ?, clazzTerminologyUid = ?, clazzMasterChangeSeqNum = ?, clazzLocalChangeSeqNum = ?, clazzLastChangedBy = ?, clazzLct = ?, clazzTimeZone = ?, clazzStudentsPersonGroupUid = ?, clazzTeachersPersonGroupUid = ?, clazzPendingStudentsPersonGroupUid = ?, clazzParentsPersonGroupUid = ?, clazzCode = ? WHERE clazzUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.clazzName)
      _stmt.setString(2, entity.clazzDesc)
      _stmt.setFloat(3, entity.attendanceAverage)
      _stmt.setLong(4, entity.clazzHolidayUMCalendarUid)
      _stmt.setLong(5, entity.clazzScheuleUMCalendarUid)
      _stmt.setBoolean(6, entity.isClazzActive)
      _stmt.setLong(7, entity.clazzLocationUid)
      _stmt.setLong(8, entity.clazzStartTime)
      _stmt.setLong(9, entity.clazzEndTime)
      _stmt.setLong(10, entity.clazzFeatures)
      _stmt.setLong(11, entity.clazzSchoolUid)
      _stmt.setInt(12, entity.clazzEnrolmentPolicy)
      _stmt.setLong(13, entity.clazzTerminologyUid)
      _stmt.setLong(14, entity.clazzMasterChangeSeqNum)
      _stmt.setLong(15, entity.clazzLocalChangeSeqNum)
      _stmt.setInt(16, entity.clazzLastChangedBy)
      _stmt.setLong(17, entity.clazzLct)
      _stmt.setString(18, entity.clazzTimeZone)
      _stmt.setLong(19, entity.clazzStudentsPersonGroupUid)
      _stmt.setLong(20, entity.clazzTeachersPersonGroupUid)
      _stmt.setLong(21, entity.clazzPendingStudentsPersonGroupUid)
      _stmt.setLong(22, entity.clazzParentsPersonGroupUid)
      _stmt.setString(23, entity.clazzCode)
      _stmt.setLong(24, entity.clazzUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun findAllClazzesBySchool(schoolUid: Long): List<Clazz> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Clazz>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 "
        , postgreSql = """
    |SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, schoolUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = Clazz()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllClazzesBySchoolLive(schoolUid: Long): DoorDataSourceFactory<Int,
      Clazz> {
    val _result = object : DoorDataSourceFactory<Int, Clazz>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<Clazz>> =
          DoorLiveDataImpl<List<Clazz>>(_db, listOf("Clazz"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.Clazz>()
        val _stmtConfig =
            PreparedStatementConfig("SELECT * FROM (SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 ) LIMIT ? OFFSET ? "
            , postgreSql = """
        |SELECT * FROM (SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, schoolUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_clazzUid = _resultSet.getLong("clazzUid")
              val tmp_clazzName = _resultSet.getString("clazzName")
              val tmp_clazzDesc = _resultSet.getString("clazzDesc")
              val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
              val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
              val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
              val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
              val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
              val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
              val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
              val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
              val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
              val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
              val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
              val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
              val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
              val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
              val tmp_clazzLct = _resultSet.getLong("clazzLct")
              val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
              val tmp_clazzStudentsPersonGroupUid =
                  _resultSet.getLong("clazzStudentsPersonGroupUid")
              val tmp_clazzTeachersPersonGroupUid =
                  _resultSet.getLong("clazzTeachersPersonGroupUid")
              val tmp_clazzPendingStudentsPersonGroupUid =
                  _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
              val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
              val tmp_clazzCode = _resultSet.getString("clazzCode")
              val _entity = Clazz()
              _entity.clazzUid = tmp_clazzUid
              _entity.clazzName = tmp_clazzName
              _entity.clazzDesc = tmp_clazzDesc
              _entity.attendanceAverage = tmp_attendanceAverage
              _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
              _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
              _entity.isClazzActive = tmp_isClazzActive
              _entity.clazzLocationUid = tmp_clazzLocationUid
              _entity.clazzStartTime = tmp_clazzStartTime
              _entity.clazzEndTime = tmp_clazzEndTime
              _entity.clazzFeatures = tmp_clazzFeatures
              _entity.clazzSchoolUid = tmp_clazzSchoolUid
              _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
              _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
              _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
              _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
              _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
              _entity.clazzLct = tmp_clazzLct
              _entity.clazzTimeZone = tmp_clazzTimeZone
              _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
              _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
              _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
              _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
              _entity.clazzCode = tmp_clazzCode
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Clazz"))  {
        var _liveResult = 0
        val _stmtConfig =
            PreparedStatementConfig("SELECT COUNT(*) FROM (SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 ) "
            , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT * FROM Clazz WHERE clazzSchoolUid = ? AND CAST(isClazzActive AS INTEGER) = 1 ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, schoolUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findClazzesWithPermission(
    searchQuery: String,
    accountPersonUid: Long,
    excludeSelectedClazzList: List<Long>,
    excludeSchoolUid: Long,
    sortOrder: Int,
    filter: Int,
    currentTime: Long,
    permission: Long,
    selectedSchool: Long
  ): DoorDataSourceFactory<Int, ClazzWithListDisplayDetails> {
    val _result = object : DoorDataSourceFactory<Int, ClazzWithListDisplayDetails>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ClazzWithListDisplayDetails>> =
          DoorLiveDataImpl<List<ClazzWithListDisplayDetails>>(_db, listOf("Clazz", "ClazzEnrolment",
          "ScopedGrant", "PersonGroupMember", "CourseTerminology"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ClazzWithListDisplayDetails>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT Clazz.*, ClazzEnrolment.*,
        |               (SELECT COUNT(*) 
        |                  FROM ClazzEnrolment 
        |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
        |                   AND clazzEnrolmentRole = 1000 
        |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
        |               (SELECT COUNT(*) 
        |                  FROM ClazzEnrolment 
        |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
        |                   AND clazzEnrolmentRole = 1001
        |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
        |               '' AS teacherNames,
        |               0 AS lastRecorded,
        |               CourseTerminology.*
        |          FROM PersonGroupMember
        |               
        |               JOIN ScopedGrant
        |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                        AND (ScopedGrant.sgPermissions & 
        |        
        |                    ?
        |                    
        |                       ) > 0
        |               JOIN Clazz 
        |                    ON 
        |            ((ScopedGrant.sgTableId = -2
        |                                AND ScopedGrant.sgEntityUid = -2)
        |                            OR (ScopedGrant.sgTableId = 6
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
        |                            OR (ScopedGrant.sgTableId = 164
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
        |        
        |                  
        |               LEFT JOIN ClazzEnrolment 
        |                    ON ClazzEnrolment.clazzEnrolmentUid =
        |                       COALESCE(
        |                       (SELECT ClazzEnrolment.clazzEnrolmentUid 
        |                          FROM ClazzEnrolment
        |                         WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
        |                           AND ClazzEnrolment.clazzEnrolmentActive
        |                           AND ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid LIMIT 1), 0)
        |                LEFT JOIN CourseTerminology   
        |                ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid           
        |
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND CAST(Clazz.isClazzActive AS INTEGER) = 1
        |           AND Clazz.clazzName like ?
        |           AND (Clazz.clazzUid NOT IN (?))
        |           AND ( ? = 0 OR Clazz.clazzUid NOT IN (SELECT cl.clazzUid FROM Clazz AS cl WHERE cl.clazzSchoolUid = ?) ) 
        |           AND ( ? = 0 OR Clazz.clazzSchoolUid = 0 )
        |           AND ( ? = 0 OR (CASE WHEN ? = 5 
        |                                      THEN ? BETWEEN Clazz.clazzStartTime AND Clazz.clazzEndTime
        |                                      ELSE ? > Clazz.clazzEndTime 
        |                                      END))
        |           AND ( ? = 0 OR Clazz.clazzSchoolUid = ?)
        |      GROUP BY Clazz.clazzUid, ClazzEnrolment.clazzEnrolmentUid, CourseTerminology.ctUid
        |      ORDER BY CASE ?
        |               WHEN 3 THEN Clazz.attendanceAverage
        |               ELSE 0
        |               END ASC,
        |               CASE ?
        |               WHEN 1 THEN Clazz.clazzName
        |               ELSE ''
        |               END ASC,
        |               CASE ?
        |               WHEN 4 THEN Clazz.attendanceAverage
        |               ELSE 0
        |               END DESC,
        |               CASE ?
        |               WHEN 2 THEN clazz.Clazzname
        |               ELSE ''
        |               END DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT * FROM (
        |        SELECT Clazz.*, ClazzEnrolment.*,
        |               (SELECT COUNT(*) 
        |                  FROM ClazzEnrolment 
        |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
        |                   AND clazzEnrolmentRole = 1000 
        |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
        |               (SELECT COUNT(*) 
        |                  FROM ClazzEnrolment 
        |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
        |                   AND clazzEnrolmentRole = 1001
        |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
        |               '' AS teacherNames,
        |               0 AS lastRecorded,
        |               CourseTerminology.*
        |          FROM PersonGroupMember
        |               
        |               JOIN ScopedGrant
        |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                        AND (ScopedGrant.sgPermissions & 
        |        
        |                    ?
        |                    
        |                       ) > 0
        |               JOIN Clazz 
        |                    ON 
        |            ((ScopedGrant.sgTableId = -2
        |                                AND ScopedGrant.sgEntityUid = -2)
        |                            OR (ScopedGrant.sgTableId = 6
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
        |                            OR (ScopedGrant.sgTableId = 164
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
        |        
        |                  
        |               LEFT JOIN ClazzEnrolment 
        |                    ON ClazzEnrolment.clazzEnrolmentUid =
        |                       COALESCE(
        |                       (SELECT ClazzEnrolment.clazzEnrolmentUid 
        |                          FROM ClazzEnrolment
        |                         WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
        |                           AND ClazzEnrolment.clazzEnrolmentActive
        |                           AND ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid LIMIT 1), 0)
        |                LEFT JOIN CourseTerminology   
        |                ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid           
        |
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND CAST(Clazz.isClazzActive AS INTEGER) = 1
        |           AND Clazz.clazzName like ?
        |           AND (Clazz.clazzUid NOT IN (?))
        |           AND ( ? = 0 OR Clazz.clazzUid NOT IN (SELECT cl.clazzUid FROM Clazz AS cl WHERE cl.clazzSchoolUid = ?) ) 
        |           AND ( ? = 0 OR Clazz.clazzSchoolUid = 0 )
        |           AND ( ? = 0 OR (CASE WHEN ? = 5 
        |                                      THEN ? BETWEEN Clazz.clazzStartTime AND Clazz.clazzEndTime
        |                                      ELSE ? > Clazz.clazzEndTime 
        |                                      END))
        |           AND ( ? = 0 OR Clazz.clazzSchoolUid = ?)
        |      GROUP BY Clazz.clazzUid, ClazzEnrolment.clazzEnrolmentUid, CourseTerminology.ctUid
        |      ORDER BY CASE ?
        |               WHEN 3 THEN Clazz.attendanceAverage
        |               ELSE 0
        |               END ASC,
        |               CASE ?
        |               WHEN 1 THEN Clazz.clazzName
        |               ELSE ''
        |               END ASC,
        |               CASE ?
        |               WHEN 4 THEN Clazz.attendanceAverage
        |               ELSE 0
        |               END DESC,
        |               CASE ?
        |               WHEN 2 THEN clazz.Clazzname
        |               ELSE ''
        |               END DESC
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, currentTime)
          _stmt.setLong(2, currentTime)
          _stmt.setLong(3, permission)
          _stmt.setLong(4, accountPersonUid)
          _stmt.setLong(5, accountPersonUid)
          _stmt.setString(6, searchQuery)
          _stmt.setArray(7, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              excludeSelectedClazzList.toTypedArray()))
          _stmt.setLong(8, excludeSchoolUid)
          _stmt.setLong(9, excludeSchoolUid)
          _stmt.setLong(10, excludeSchoolUid)
          _stmt.setInt(11, filter)
          _stmt.setInt(12, filter)
          _stmt.setLong(13, currentTime)
          _stmt.setLong(14, currentTime)
          _stmt.setLong(15, selectedSchool)
          _stmt.setLong(16, selectedSchool)
          _stmt.setInt(17, sortOrder)
          _stmt.setInt(18, sortOrder)
          _stmt.setInt(19, sortOrder)
          _stmt.setInt(20, sortOrder)
          _stmt.setInt(21, _limit)
          _stmt.setInt(22, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_numStudents = _resultSet.getInt("numStudents")
              val tmp_numTeachers = _resultSet.getInt("numTeachers")
              val tmp_teacherNames = _resultSet.getString("teacherNames")
              val tmp_lastRecorded = _resultSet.getLong("lastRecorded")
              val tmp_clazzUid = _resultSet.getLong("clazzUid")
              val tmp_clazzName = _resultSet.getString("clazzName")
              val tmp_clazzDesc = _resultSet.getString("clazzDesc")
              val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
              val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
              val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
              val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
              val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
              val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
              val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
              val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
              val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
              val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
              val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
              val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
              val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
              val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
              val tmp_clazzLct = _resultSet.getLong("clazzLct")
              val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
              val tmp_clazzStudentsPersonGroupUid =
                  _resultSet.getLong("clazzStudentsPersonGroupUid")
              val tmp_clazzTeachersPersonGroupUid =
                  _resultSet.getLong("clazzTeachersPersonGroupUid")
              val tmp_clazzPendingStudentsPersonGroupUid =
                  _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
              val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
              val tmp_clazzCode = _resultSet.getString("clazzCode")
              val _entity = ClazzWithListDisplayDetails()
              _entity.numStudents = tmp_numStudents
              _entity.numTeachers = tmp_numTeachers
              _entity.teacherNames = tmp_teacherNames
              _entity.lastRecorded = tmp_lastRecorded
              _entity.clazzUid = tmp_clazzUid
              _entity.clazzName = tmp_clazzName
              _entity.clazzDesc = tmp_clazzDesc
              _entity.attendanceAverage = tmp_attendanceAverage
              _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
              _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
              _entity.isClazzActive = tmp_isClazzActive
              _entity.clazzLocationUid = tmp_clazzLocationUid
              _entity.clazzStartTime = tmp_clazzStartTime
              _entity.clazzEndTime = tmp_clazzEndTime
              _entity.clazzFeatures = tmp_clazzFeatures
              _entity.clazzSchoolUid = tmp_clazzSchoolUid
              _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
              _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
              _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
              _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
              _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
              _entity.clazzLct = tmp_clazzLct
              _entity.clazzTimeZone = tmp_clazzTimeZone
              _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
              _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
              _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
              _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
              _entity.clazzCode = tmp_clazzCode
              var _clazzActiveEnrolment_nullFieldCount = 0
              val tmp_clazzEnrolmentUid = _resultSet.getLong("clazzEnrolmentUid")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentPersonUid = _resultSet.getLong("clazzEnrolmentPersonUid")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentClazzUid = _resultSet.getLong("clazzEnrolmentClazzUid")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentDateJoined = _resultSet.getLong("clazzEnrolmentDateJoined")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentDateLeft = _resultSet.getLong("clazzEnrolmentDateLeft")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentRole = _resultSet.getInt("clazzEnrolmentRole")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentAttendancePercentage =
                  _resultSet.getFloat("clazzEnrolmentAttendancePercentage")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentActive = _resultSet.getBoolean("clazzEnrolmentActive")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentLeavingReasonUid =
                  _resultSet.getLong("clazzEnrolmentLeavingReasonUid")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentOutcome = _resultSet.getInt("clazzEnrolmentOutcome")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentLocalChangeSeqNum =
                  _resultSet.getLong("clazzEnrolmentLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentMasterChangeSeqNum =
                  _resultSet.getLong("clazzEnrolmentMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentLastChangedBy = _resultSet.getInt("clazzEnrolmentLastChangedBy")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              val tmp_clazzEnrolmentLct = _resultSet.getLong("clazzEnrolmentLct")
              if(_resultSet.wasNull()) { _clazzActiveEnrolment_nullFieldCount++ }
              if(_clazzActiveEnrolment_nullFieldCount < 14) {
                if(_entity.clazzActiveEnrolment == null) {
                  _entity.clazzActiveEnrolment = ClazzEnrolment()
                }
                _entity.clazzActiveEnrolment!!.clazzEnrolmentUid = tmp_clazzEnrolmentUid
                _entity.clazzActiveEnrolment!!.clazzEnrolmentPersonUid = tmp_clazzEnrolmentPersonUid
                _entity.clazzActiveEnrolment!!.clazzEnrolmentClazzUid = tmp_clazzEnrolmentClazzUid
                _entity.clazzActiveEnrolment!!.clazzEnrolmentDateJoined =
                    tmp_clazzEnrolmentDateJoined
                _entity.clazzActiveEnrolment!!.clazzEnrolmentDateLeft = tmp_clazzEnrolmentDateLeft
                _entity.clazzActiveEnrolment!!.clazzEnrolmentRole = tmp_clazzEnrolmentRole
                _entity.clazzActiveEnrolment!!.clazzEnrolmentAttendancePercentage =
                    tmp_clazzEnrolmentAttendancePercentage
                _entity.clazzActiveEnrolment!!.clazzEnrolmentActive = tmp_clazzEnrolmentActive
                _entity.clazzActiveEnrolment!!.clazzEnrolmentLeavingReasonUid =
                    tmp_clazzEnrolmentLeavingReasonUid
                _entity.clazzActiveEnrolment!!.clazzEnrolmentOutcome = tmp_clazzEnrolmentOutcome
                _entity.clazzActiveEnrolment!!.clazzEnrolmentLocalChangeSeqNum =
                    tmp_clazzEnrolmentLocalChangeSeqNum
                _entity.clazzActiveEnrolment!!.clazzEnrolmentMasterChangeSeqNum =
                    tmp_clazzEnrolmentMasterChangeSeqNum
                _entity.clazzActiveEnrolment!!.clazzEnrolmentLastChangedBy =
                    tmp_clazzEnrolmentLastChangedBy
                _entity.clazzActiveEnrolment!!.clazzEnrolmentLct = tmp_clazzEnrolmentLct
              }
              var _terminology_nullFieldCount = 0
              val tmp_ctUid = _resultSet.getLong("ctUid")
              if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
              val tmp_ctTitle = _resultSet.getString("ctTitle")
              if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
              val tmp_ctTerminology = _resultSet.getString("ctTerminology")
              if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
              val tmp_ctLct = _resultSet.getLong("ctLct")
              if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
              if(_terminology_nullFieldCount < 4) {
                if(_entity.terminology == null) {
                  _entity.terminology = CourseTerminology()
                }
                _entity.terminology!!.ctUid = tmp_ctUid
                _entity.terminology!!.ctTitle = tmp_ctTitle
                _entity.terminology!!.ctTerminology = tmp_ctTerminology
                _entity.terminology!!.ctLct = tmp_ctLct
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Clazz", "ClazzEnrolment", "ScopedGrant", "PersonGroupMember",
          "CourseTerminology"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT Clazz.*, ClazzEnrolment.*,
        |               (SELECT COUNT(*) 
        |                  FROM ClazzEnrolment 
        |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
        |                   AND clazzEnrolmentRole = 1000 
        |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
        |               (SELECT COUNT(*) 
        |                  FROM ClazzEnrolment 
        |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
        |                   AND clazzEnrolmentRole = 1001
        |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
        |               '' AS teacherNames,
        |               0 AS lastRecorded,
        |               CourseTerminology.*
        |          FROM PersonGroupMember
        |               
        |               JOIN ScopedGrant
        |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                        AND (ScopedGrant.sgPermissions & 
        |        
        |                    ?
        |                    
        |                       ) > 0
        |               JOIN Clazz 
        |                    ON 
        |            ((ScopedGrant.sgTableId = -2
        |                                AND ScopedGrant.sgEntityUid = -2)
        |                            OR (ScopedGrant.sgTableId = 6
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
        |                            OR (ScopedGrant.sgTableId = 164
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
        |        
        |                  
        |               LEFT JOIN ClazzEnrolment 
        |                    ON ClazzEnrolment.clazzEnrolmentUid =
        |                       COALESCE(
        |                       (SELECT ClazzEnrolment.clazzEnrolmentUid 
        |                          FROM ClazzEnrolment
        |                         WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
        |                           AND ClazzEnrolment.clazzEnrolmentActive
        |                           AND ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid LIMIT 1), 0)
        |                LEFT JOIN CourseTerminology   
        |                ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid           
        |
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND CAST(Clazz.isClazzActive AS INTEGER) = 1
        |           AND Clazz.clazzName like ?
        |           AND (Clazz.clazzUid NOT IN (?))
        |           AND ( ? = 0 OR Clazz.clazzUid NOT IN (SELECT cl.clazzUid FROM Clazz AS cl WHERE cl.clazzSchoolUid = ?) ) 
        |           AND ( ? = 0 OR Clazz.clazzSchoolUid = 0 )
        |           AND ( ? = 0 OR (CASE WHEN ? = 5 
        |                                      THEN ? BETWEEN Clazz.clazzStartTime AND Clazz.clazzEndTime
        |                                      ELSE ? > Clazz.clazzEndTime 
        |                                      END))
        |           AND ( ? = 0 OR Clazz.clazzSchoolUid = ?)
        |      GROUP BY Clazz.clazzUid, ClazzEnrolment.clazzEnrolmentUid, CourseTerminology.ctUid
        |      ORDER BY CASE ?
        |               WHEN 3 THEN Clazz.attendanceAverage
        |               ELSE 0
        |               END ASC,
        |               CASE ?
        |               WHEN 1 THEN Clazz.clazzName
        |               ELSE ''
        |               END ASC,
        |               CASE ?
        |               WHEN 4 THEN Clazz.attendanceAverage
        |               ELSE 0
        |               END DESC,
        |               CASE ?
        |               WHEN 2 THEN clazz.Clazzname
        |               ELSE ''
        |               END DESC
        |    ) 
        """.trimMargin() ,hasListParams = true, postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT Clazz.*, ClazzEnrolment.*,
        |               (SELECT COUNT(*) 
        |                  FROM ClazzEnrolment 
        |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
        |                   AND clazzEnrolmentRole = 1000 
        |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
        |               (SELECT COUNT(*) 
        |                  FROM ClazzEnrolment 
        |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
        |                   AND clazzEnrolmentRole = 1001
        |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
        |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
        |               '' AS teacherNames,
        |               0 AS lastRecorded,
        |               CourseTerminology.*
        |          FROM PersonGroupMember
        |               
        |               JOIN ScopedGrant
        |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
        |                        AND (ScopedGrant.sgPermissions & 
        |        
        |                    ?
        |                    
        |                       ) > 0
        |               JOIN Clazz 
        |                    ON 
        |            ((ScopedGrant.sgTableId = -2
        |                                AND ScopedGrant.sgEntityUid = -2)
        |                            OR (ScopedGrant.sgTableId = 6
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
        |                            OR (ScopedGrant.sgTableId = 164
        |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
        |        
        |                  
        |               LEFT JOIN ClazzEnrolment 
        |                    ON ClazzEnrolment.clazzEnrolmentUid =
        |                       COALESCE(
        |                       (SELECT ClazzEnrolment.clazzEnrolmentUid 
        |                          FROM ClazzEnrolment
        |                         WHERE ClazzEnrolment.clazzEnrolmentPersonUid = ?
        |                           AND ClazzEnrolment.clazzEnrolmentActive
        |                           AND ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid LIMIT 1), 0)
        |                LEFT JOIN CourseTerminology   
        |                ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid           
        |
        |         WHERE PersonGroupMember.groupMemberPersonUid = ?
        |           AND PersonGroupMember.groupMemberActive 
        |           AND CAST(Clazz.isClazzActive AS INTEGER) = 1
        |           AND Clazz.clazzName like ?
        |           AND (Clazz.clazzUid NOT IN (?))
        |           AND ( ? = 0 OR Clazz.clazzUid NOT IN (SELECT cl.clazzUid FROM Clazz AS cl WHERE cl.clazzSchoolUid = ?) ) 
        |           AND ( ? = 0 OR Clazz.clazzSchoolUid = 0 )
        |           AND ( ? = 0 OR (CASE WHEN ? = 5 
        |                                      THEN ? BETWEEN Clazz.clazzStartTime AND Clazz.clazzEndTime
        |                                      ELSE ? > Clazz.clazzEndTime 
        |                                      END))
        |           AND ( ? = 0 OR Clazz.clazzSchoolUid = ?)
        |      GROUP BY Clazz.clazzUid, ClazzEnrolment.clazzEnrolmentUid, CourseTerminology.ctUid
        |      ORDER BY CASE ?
        |               WHEN 3 THEN Clazz.attendanceAverage
        |               ELSE 0
        |               END ASC,
        |               CASE ?
        |               WHEN 1 THEN Clazz.clazzName
        |               ELSE ''
        |               END ASC,
        |               CASE ?
        |               WHEN 4 THEN Clazz.attendanceAverage
        |               ELSE 0
        |               END DESC,
        |               CASE ?
        |               WHEN 2 THEN clazz.Clazzname
        |               ELSE ''
        |               END DESC
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, currentTime)
          _stmt.setLong(2, currentTime)
          _stmt.setLong(3, permission)
          _stmt.setLong(4, accountPersonUid)
          _stmt.setLong(5, accountPersonUid)
          _stmt.setString(6, searchQuery)
          _stmt.setArray(7, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
              excludeSelectedClazzList.toTypedArray()))
          _stmt.setLong(8, excludeSchoolUid)
          _stmt.setLong(9, excludeSchoolUid)
          _stmt.setLong(10, excludeSchoolUid)
          _stmt.setInt(11, filter)
          _stmt.setInt(12, filter)
          _stmt.setLong(13, currentTime)
          _stmt.setLong(14, currentTime)
          _stmt.setLong(15, selectedSchool)
          _stmt.setLong(16, selectedSchool)
          _stmt.setInt(17, sortOrder)
          _stmt.setInt(18, sortOrder)
          _stmt.setInt(19, sortOrder)
          _stmt.setInt(20, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun getClassNamesFromListOfIds(ids: List<Long>): List<UidAndLabel> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.UidAndLabel>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT Clazz.clazzUid AS uid, Clazz.clazzName AS labelName From Clazz WHERE clazzUid IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT Clazz.clazzUid AS uid, Clazz.clazzName AS labelName From Clazz WHERE clazzUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", ids.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_uid = _resultSet.getLong("uid")
          val tmp_labelName = _resultSet.getString("labelName")
          val _entity = UidAndLabel()
          _entity.uid = tmp_uid
          _entity.labelName = tmp_labelName
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByClazzName(name: String): List<Clazz> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Clazz>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Clazz WHERE clazzName = ? and CAST(isClazzActive AS INTEGER) = 1"
        , postgreSql = """
    |SELECT * FROM Clazz WHERE clazzName = ? and CAST(isClazzActive AS INTEGER) = 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, name)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = Clazz()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun updateClazzAttendanceAverageAsync(clazzUid: Long, timeChanged: Long):
      Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE Clazz 
    |           SET attendanceAverage = 
    |               COALESCE(CAST(
    |                    (SELECT SUM(clazzLogNumPresent) 
    |                       FROM ClazzLog 
    |                      WHERE clazzLogClazzUid = ?
    |                       AND clazzLogStatusFlag = 4) AS REAL) /
    |                    
    |                    CAST(MAX(1.0, 
    |                        (SELECT SUM(clazzLogNumPresent) + SUM(clazzLogNumPartial) + SUM(clazzLogNumAbsent)
    |                        FROM ClazzLog 
    |                       WHERE clazzLogClazzUid = ? 
    |                        AND clazzLogStatusFlag = 4)) AS REAL), 0),
    |               clazzLct = ?         
    |         WHERE clazzUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE Clazz 
    |           SET attendanceAverage = 
    |               COALESCE(CAST(
    |                    (SELECT SUM(clazzLogNumPresent) 
    |                       FROM ClazzLog 
    |                      WHERE clazzLogClazzUid = ?
    |                       AND clazzLogStatusFlag = 4) AS REAL) /
    |                    
    |                    CAST(GREATEST(1.0, 
    |                        (SELECT SUM(clazzLogNumPresent) + SUM(clazzLogNumPartial) + SUM(clazzLogNumAbsent)
    |                        FROM ClazzLog 
    |                       WHERE clazzLogClazzUid = ? 
    |                        AND clazzLogStatusFlag = 4)) AS REAL), 0),
    |               clazzLct = ?         
    |         WHERE clazzUid = ?
    |    
    """.trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.setLong(2, clazzUid)
      _stmt.setLong(3, timeChanged)
      _stmt.setLong(4, clazzUid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun personHasPermissionWithClazz(
    accountPersonUid: Long,
    clazzUid: Long,
    permission: Long
  ): Boolean {
    var _result = false
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT EXISTS( 
    |               SELECT PrsGrpMbr.groupMemberPersonUid
    |                  FROM Clazz
    |                       
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                          ?
    |                          
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |        
    |                 WHERE Clazz.clazzUid = ?
    |                   AND PrsGrpMbr.groupMemberPersonUid = ?)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT EXISTS( 
    |               SELECT PrsGrpMbr.groupMemberPersonUid
    |                  FROM Clazz
    |                       
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                          ?
    |                          
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |        
    |                 WHERE Clazz.clazzUid = ?
    |                   AND PrsGrpMbr.groupMemberPersonUid = ?)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, permission)
      _stmt.setLong(2, clazzUid)
      _stmt.setLong(3, accountPersonUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getBoolean(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun selectDelegatablePermissions(accountPersonUid: Long, clazzUid: Long):
      List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ScopedGrant.sgPermissions
    |          FROM Clazz
    |               JOIN ScopedGrant
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |               JOIN PersonGroupMember AS PrsGrpMbr
    |                    ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |         WHERE Clazz.clazzUid = ?
    |           AND (ScopedGrant.sgPermissions & 4294967296) > 0
    |           AND PrsGrpMbr.groupMemberPersonUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ScopedGrant.sgPermissions
    |          FROM Clazz
    |               JOIN ScopedGrant
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |               JOIN PersonGroupMember AS PrsGrpMbr
    |                    ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |         WHERE Clazz.clazzUid = ?
    |           AND (ScopedGrant.sgPermissions & 4294967296) > 0
    |           AND PrsGrpMbr.groupMemberPersonUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.setLong(2, accountPersonUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun getClazzWithDisplayDetails(clazzUid: Long, currentTime: Long):
      DoorLiveData<ClazzWithDisplayDetails?> {
    val _result = DoorLiveDataImpl<ClazzWithDisplayDetails?>(_db, listOf("ClazzEnrolment", "Clazz",
        "HolidayCalendar", "School", "CourseTerminology"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ClazzWithDisplayDetails??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT Clazz.*, 
      |               HolidayCalendar.*, 
      |               School.*,
      |               (SELECT COUNT(*) 
      |                  FROM ClazzEnrolment 
      |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
      |                   AND clazzEnrolmentRole = 1000 
      |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
      |               (SELECT COUNT(*) 
      |                  FROM ClazzEnrolment 
      |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
      |                   AND clazzEnrolmentRole = 1001 
      |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
      |                CourseTerminology.*      
      |         FROM Clazz 
      |              LEFT JOIN HolidayCalendar 
      |              ON Clazz.clazzHolidayUMCalendarUid = HolidayCalendar.umCalendarUid
      |              LEFT JOIN School 
      |              ON School.schoolUid = Clazz.clazzSchoolUid
      |              LEFT JOIN CourseTerminology
      |              ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
      |        WHERE Clazz.clazzUid = ?
      """.trimMargin() , postgreSql = """
      |
      |        SELECT Clazz.*, 
      |               HolidayCalendar.*, 
      |               School.*,
      |               (SELECT COUNT(*) 
      |                  FROM ClazzEnrolment 
      |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
      |                   AND clazzEnrolmentRole = 1000 
      |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                        AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numStudents,
      |               (SELECT COUNT(*) 
      |                  FROM ClazzEnrolment 
      |                 WHERE ClazzEnrolment.clazzEnrolmentClazzUid = Clazz.clazzUid 
      |                   AND clazzEnrolmentRole = 1001 
      |                   AND ? BETWEEN ClazzEnrolment.clazzEnrolmentDateJoined 
      |                       AND ClazzEnrolment.clazzEnrolmentDateLeft) AS numTeachers,
      |                CourseTerminology.*      
      |         FROM Clazz 
      |              LEFT JOIN HolidayCalendar 
      |              ON Clazz.clazzHolidayUMCalendarUid = HolidayCalendar.umCalendarUid
      |              LEFT JOIN School 
      |              ON School.schoolUid = Clazz.clazzSchoolUid
      |              LEFT JOIN CourseTerminology
      |              ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
      |        WHERE Clazz.clazzUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, currentTime)
        _stmt.setLong(2, currentTime)
        _stmt.setLong(3, clazzUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_numStudents = _resultSet.getInt("numStudents")
            val tmp_numTeachers = _resultSet.getInt("numTeachers")
            val tmp_clazzUid = _resultSet.getLong("clazzUid")
            val tmp_clazzName = _resultSet.getString("clazzName")
            val tmp_clazzDesc = _resultSet.getString("clazzDesc")
            val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
            val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
            val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
            val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
            val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
            val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
            val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
            val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
            val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
            val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
            val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
            val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
            val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
            val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
            val tmp_clazzLct = _resultSet.getLong("clazzLct")
            val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
            val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
            val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
            val tmp_clazzPendingStudentsPersonGroupUid =
                _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
            val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
            val tmp_clazzCode = _resultSet.getString("clazzCode")
            val _entity = ClazzWithDisplayDetails()
            _entity.numStudents = tmp_numStudents
            _entity.numTeachers = tmp_numTeachers
            _entity.clazzUid = tmp_clazzUid
            _entity.clazzName = tmp_clazzName
            _entity.clazzDesc = tmp_clazzDesc
            _entity.attendanceAverage = tmp_attendanceAverage
            _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
            _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
            _entity.isClazzActive = tmp_isClazzActive
            _entity.clazzLocationUid = tmp_clazzLocationUid
            _entity.clazzStartTime = tmp_clazzStartTime
            _entity.clazzEndTime = tmp_clazzEndTime
            _entity.clazzFeatures = tmp_clazzFeatures
            _entity.clazzSchoolUid = tmp_clazzSchoolUid
            _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
            _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
            _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
            _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
            _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
            _entity.clazzLct = tmp_clazzLct
            _entity.clazzTimeZone = tmp_clazzTimeZone
            _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
            _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
            _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
            _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
            _entity.clazzCode = tmp_clazzCode
            var _clazzHolidayCalendar_nullFieldCount = 0
            val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
            if(_resultSet.wasNull()) { _clazzHolidayCalendar_nullFieldCount++ }
            val tmp_umCalendarName = _resultSet.getString("umCalendarName")
            if(_resultSet.wasNull()) { _clazzHolidayCalendar_nullFieldCount++ }
            val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
            if(_resultSet.wasNull()) { _clazzHolidayCalendar_nullFieldCount++ }
            val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
            if(_resultSet.wasNull()) { _clazzHolidayCalendar_nullFieldCount++ }
            val tmp_umCalendarMasterChangeSeqNum =
                _resultSet.getLong("umCalendarMasterChangeSeqNum")
            if(_resultSet.wasNull()) { _clazzHolidayCalendar_nullFieldCount++ }
            val tmp_umCalendarLocalChangeSeqNum = _resultSet.getLong("umCalendarLocalChangeSeqNum")
            if(_resultSet.wasNull()) { _clazzHolidayCalendar_nullFieldCount++ }
            val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
            if(_resultSet.wasNull()) { _clazzHolidayCalendar_nullFieldCount++ }
            val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
            if(_resultSet.wasNull()) { _clazzHolidayCalendar_nullFieldCount++ }
            if(_clazzHolidayCalendar_nullFieldCount < 8) {
              if(_entity.clazzHolidayCalendar == null) {
                _entity.clazzHolidayCalendar = HolidayCalendar()
              }
              _entity.clazzHolidayCalendar!!.umCalendarUid = tmp_umCalendarUid
              _entity.clazzHolidayCalendar!!.umCalendarName = tmp_umCalendarName
              _entity.clazzHolidayCalendar!!.umCalendarCategory = tmp_umCalendarCategory
              _entity.clazzHolidayCalendar!!.umCalendarActive = tmp_umCalendarActive
              _entity.clazzHolidayCalendar!!.umCalendarMasterChangeSeqNum =
                  tmp_umCalendarMasterChangeSeqNum
              _entity.clazzHolidayCalendar!!.umCalendarLocalChangeSeqNum =
                  tmp_umCalendarLocalChangeSeqNum
              _entity.clazzHolidayCalendar!!.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
              _entity.clazzHolidayCalendar!!.umCalendarLct = tmp_umCalendarLct
            }
            var _clazzSchool_nullFieldCount = 0
            val tmp_schoolUid = _resultSet.getLong("schoolUid")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolName = _resultSet.getString("schoolName")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolDesc = _resultSet.getString("schoolDesc")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolAddress = _resultSet.getString("schoolAddress")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolGender = _resultSet.getInt("schoolGender")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolTeachersPersonGroupUid =
                _resultSet.getLong("schoolTeachersPersonGroupUid")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolStudentsPersonGroupUid =
                _resultSet.getLong("schoolStudentsPersonGroupUid")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolPendingStudentsPersonGroupUid =
                _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolCode = _resultSet.getString("schoolCode")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolLct = _resultSet.getLong("schoolLct")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
            if(_resultSet.wasNull()) { _clazzSchool_nullFieldCount++ }
            if(_clazzSchool_nullFieldCount < 21) {
              if(_entity.clazzSchool == null) {
                _entity.clazzSchool = School()
              }
              _entity.clazzSchool!!.schoolUid = tmp_schoolUid
              _entity.clazzSchool!!.schoolName = tmp_schoolName
              _entity.clazzSchool!!.schoolDesc = tmp_schoolDesc
              _entity.clazzSchool!!.schoolAddress = tmp_schoolAddress
              _entity.clazzSchool!!.schoolActive = tmp_schoolActive
              _entity.clazzSchool!!.schoolPhoneNumber = tmp_schoolPhoneNumber
              _entity.clazzSchool!!.schoolGender = tmp_schoolGender
              _entity.clazzSchool!!.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
              _entity.clazzSchool!!.schoolFeatures = tmp_schoolFeatures
              _entity.clazzSchool!!.schoolLocationLong = tmp_schoolLocationLong
              _entity.clazzSchool!!.schoolLocationLatt = tmp_schoolLocationLatt
              _entity.clazzSchool!!.schoolEmailAddress = tmp_schoolEmailAddress
              _entity.clazzSchool!!.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
              _entity.clazzSchool!!.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
              _entity.clazzSchool!!.schoolPendingStudentsPersonGroupUid =
                  tmp_schoolPendingStudentsPersonGroupUid
              _entity.clazzSchool!!.schoolCode = tmp_schoolCode
              _entity.clazzSchool!!.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
              _entity.clazzSchool!!.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
              _entity.clazzSchool!!.schoolLastChangedBy = tmp_schoolLastChangedBy
              _entity.clazzSchool!!.schoolLct = tmp_schoolLct
              _entity.clazzSchool!!.schoolTimeZone = tmp_schoolTimeZone
            }
            var _terminology_nullFieldCount = 0
            val tmp_ctUid = _resultSet.getLong("ctUid")
            if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
            val tmp_ctTitle = _resultSet.getString("ctTitle")
            if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
            val tmp_ctTerminology = _resultSet.getString("ctTerminology")
            if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
            val tmp_ctLct = _resultSet.getLong("ctLct")
            if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
            if(_terminology_nullFieldCount < 4) {
              if(_entity.terminology == null) {
                _entity.terminology = CourseTerminology()
              }
              _entity.terminology!!.ctUid = tmp_ctUid
              _entity.terminology!!.ctTitle = tmp_ctTitle
              _entity.terminology!!.ctTerminology = tmp_ctTerminology
              _entity.terminology!!.ctLct = tmp_ctLct
            }
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override fun findClazzesWithEffectiveHolidayCalendarAndFilter(filterUid: Long):
      List<ClazzWithHolidayCalendarAndSchoolAndTerminology> {
    var _result =
        mutableListOf<com.ustadmobile.lib.db.entities.ClazzWithHolidayCalendarAndSchoolAndTerminology>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Clazz.*, 
    |               HolidayCalendar.*, 
    |               School.*,
    |               CourseTerminology.*
    |         FROM Clazz 
    |              LEFT JOIN HolidayCalendar 
    |              ON ((clazz.clazzHolidayUMCalendarUid != 0 
    |                AND HolidayCalendar.umCalendarUid = clazz.clazzHolidayUMCalendarUid)
    |                OR clazz.clazzHolidayUMCalendarUid = 0 AND clazz.clazzSchoolUid = 0 
    |                AND HolidayCalendar.umCalendarUid = (SELECT schoolHolidayCalendarUid 
    |                                                       FROM School 
    |                                                      WHERE schoolUid = clazz.clazzSchoolUid))
    |              LEFT JOIN School 
    |              ON School.schoolUid = Clazz.clazzSchoolUid
    |              
    |              LEFT JOIN CourseTerminology
    |              ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
    |                
    |        WHERE ? = 0 
    |           OR Clazz.clazzUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Clazz.*, 
    |               HolidayCalendar.*, 
    |               School.*,
    |               CourseTerminology.*
    |         FROM Clazz 
    |              LEFT JOIN HolidayCalendar 
    |              ON ((clazz.clazzHolidayUMCalendarUid != 0 
    |                AND HolidayCalendar.umCalendarUid = clazz.clazzHolidayUMCalendarUid)
    |                OR clazz.clazzHolidayUMCalendarUid = 0 AND clazz.clazzSchoolUid = 0 
    |                AND HolidayCalendar.umCalendarUid = (SELECT schoolHolidayCalendarUid 
    |                                                       FROM School 
    |                                                      WHERE schoolUid = clazz.clazzSchoolUid))
    |              LEFT JOIN School 
    |              ON School.schoolUid = Clazz.clazzSchoolUid
    |              
    |              LEFT JOIN CourseTerminology
    |              ON CourseTerminology.ctUid = Clazz.clazzTerminologyUid
    |                
    |        WHERE ? = 0 
    |           OR Clazz.clazzUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, filterUid)
      _stmt.setLong(2, filterUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = ClazzWithHolidayCalendarAndSchoolAndTerminology()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          var _holidayCalendar_nullFieldCount = 0
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarName = _resultSet.getString("umCalendarName")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarMasterChangeSeqNum = _resultSet.getLong("umCalendarMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLocalChangeSeqNum = _resultSet.getLong("umCalendarLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
          if(_resultSet.wasNull()) { _holidayCalendar_nullFieldCount++ }
          if(_holidayCalendar_nullFieldCount < 8) {
            if(_entity.holidayCalendar == null) {
              _entity.holidayCalendar = HolidayCalendar()
            }
            _entity.holidayCalendar!!.umCalendarUid = tmp_umCalendarUid
            _entity.holidayCalendar!!.umCalendarName = tmp_umCalendarName
            _entity.holidayCalendar!!.umCalendarCategory = tmp_umCalendarCategory
            _entity.holidayCalendar!!.umCalendarActive = tmp_umCalendarActive
            _entity.holidayCalendar!!.umCalendarMasterChangeSeqNum =
                tmp_umCalendarMasterChangeSeqNum
            _entity.holidayCalendar!!.umCalendarLocalChangeSeqNum = tmp_umCalendarLocalChangeSeqNum
            _entity.holidayCalendar!!.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
            _entity.holidayCalendar!!.umCalendarLct = tmp_umCalendarLct
          }
          var _school_nullFieldCount = 0
          val tmp_schoolUid = _resultSet.getLong("schoolUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolName = _resultSet.getString("schoolName")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolDesc = _resultSet.getString("schoolDesc")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolAddress = _resultSet.getString("schoolAddress")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolGender = _resultSet.getInt("schoolGender")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolTeachersPersonGroupUid = _resultSet.getLong("schoolTeachersPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolStudentsPersonGroupUid = _resultSet.getLong("schoolStudentsPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolPendingStudentsPersonGroupUid =
              _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolCode = _resultSet.getString("schoolCode")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLct = _resultSet.getLong("schoolLct")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          if(_school_nullFieldCount < 21) {
            if(_entity.school == null) {
              _entity.school = School()
            }
            _entity.school!!.schoolUid = tmp_schoolUid
            _entity.school!!.schoolName = tmp_schoolName
            _entity.school!!.schoolDesc = tmp_schoolDesc
            _entity.school!!.schoolAddress = tmp_schoolAddress
            _entity.school!!.schoolActive = tmp_schoolActive
            _entity.school!!.schoolPhoneNumber = tmp_schoolPhoneNumber
            _entity.school!!.schoolGender = tmp_schoolGender
            _entity.school!!.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
            _entity.school!!.schoolFeatures = tmp_schoolFeatures
            _entity.school!!.schoolLocationLong = tmp_schoolLocationLong
            _entity.school!!.schoolLocationLatt = tmp_schoolLocationLatt
            _entity.school!!.schoolEmailAddress = tmp_schoolEmailAddress
            _entity.school!!.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
            _entity.school!!.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
            _entity.school!!.schoolPendingStudentsPersonGroupUid =
                tmp_schoolPendingStudentsPersonGroupUid
            _entity.school!!.schoolCode = tmp_schoolCode
            _entity.school!!.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
            _entity.school!!.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
            _entity.school!!.schoolLastChangedBy = tmp_schoolLastChangedBy
            _entity.school!!.schoolLct = tmp_schoolLct
            _entity.school!!.schoolTimeZone = tmp_schoolTimeZone
          }
          var _terminology_nullFieldCount = 0
          val tmp_ctUid = _resultSet.getLong("ctUid")
          if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
          val tmp_ctTitle = _resultSet.getString("ctTitle")
          if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
          val tmp_ctTerminology = _resultSet.getString("ctTerminology")
          if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
          val tmp_ctLct = _resultSet.getLong("ctLct")
          if(_resultSet.wasNull()) { _terminology_nullFieldCount++ }
          if(_terminology_nullFieldCount < 4) {
            if(_entity.terminology == null) {
              _entity.terminology = CourseTerminology()
            }
            _entity.terminology!!.ctUid = tmp_ctUid
            _entity.terminology!!.ctTitle = tmp_ctTitle
            _entity.terminology!!.ctTerminology = tmp_ctTerminology
            _entity.terminology!!.ctLct = tmp_ctLct
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun getClazzWithSchool(clazzUid: Long): ClazzWithSchool? {
    var _result = null as com.ustadmobile.lib.db.entities.ClazzWithSchool??
    val _stmtConfig =
        PreparedStatementConfig("SELECT Clazz.*, School.* FROM Clazz LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid WHERE clazz.clazzUid = ?"
        , postgreSql = """
    |SELECT Clazz.*, School.* FROM Clazz LEFT JOIN School ON School.schoolUid = Clazz.clazzSchoolUid WHERE clazz.clazzUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_clazzUid = _resultSet.getLong("clazzUid")
          val tmp_clazzName = _resultSet.getString("clazzName")
          val tmp_clazzDesc = _resultSet.getString("clazzDesc")
          val tmp_attendanceAverage = _resultSet.getFloat("attendanceAverage")
          val tmp_clazzHolidayUMCalendarUid = _resultSet.getLong("clazzHolidayUMCalendarUid")
          val tmp_clazzScheuleUMCalendarUid = _resultSet.getLong("clazzScheuleUMCalendarUid")
          val tmp_isClazzActive = _resultSet.getBoolean("isClazzActive")
          val tmp_clazzLocationUid = _resultSet.getLong("clazzLocationUid")
          val tmp_clazzStartTime = _resultSet.getLong("clazzStartTime")
          val tmp_clazzEndTime = _resultSet.getLong("clazzEndTime")
          val tmp_clazzFeatures = _resultSet.getLong("clazzFeatures")
          val tmp_clazzSchoolUid = _resultSet.getLong("clazzSchoolUid")
          val tmp_clazzEnrolmentPolicy = _resultSet.getInt("clazzEnrolmentPolicy")
          val tmp_clazzTerminologyUid = _resultSet.getLong("clazzTerminologyUid")
          val tmp_clazzMasterChangeSeqNum = _resultSet.getLong("clazzMasterChangeSeqNum")
          val tmp_clazzLocalChangeSeqNum = _resultSet.getLong("clazzLocalChangeSeqNum")
          val tmp_clazzLastChangedBy = _resultSet.getInt("clazzLastChangedBy")
          val tmp_clazzLct = _resultSet.getLong("clazzLct")
          val tmp_clazzTimeZone = _resultSet.getString("clazzTimeZone")
          val tmp_clazzStudentsPersonGroupUid = _resultSet.getLong("clazzStudentsPersonGroupUid")
          val tmp_clazzTeachersPersonGroupUid = _resultSet.getLong("clazzTeachersPersonGroupUid")
          val tmp_clazzPendingStudentsPersonGroupUid =
              _resultSet.getLong("clazzPendingStudentsPersonGroupUid")
          val tmp_clazzParentsPersonGroupUid = _resultSet.getLong("clazzParentsPersonGroupUid")
          val tmp_clazzCode = _resultSet.getString("clazzCode")
          val _entity = ClazzWithSchool()
          _entity.clazzUid = tmp_clazzUid
          _entity.clazzName = tmp_clazzName
          _entity.clazzDesc = tmp_clazzDesc
          _entity.attendanceAverage = tmp_attendanceAverage
          _entity.clazzHolidayUMCalendarUid = tmp_clazzHolidayUMCalendarUid
          _entity.clazzScheuleUMCalendarUid = tmp_clazzScheuleUMCalendarUid
          _entity.isClazzActive = tmp_isClazzActive
          _entity.clazzLocationUid = tmp_clazzLocationUid
          _entity.clazzStartTime = tmp_clazzStartTime
          _entity.clazzEndTime = tmp_clazzEndTime
          _entity.clazzFeatures = tmp_clazzFeatures
          _entity.clazzSchoolUid = tmp_clazzSchoolUid
          _entity.clazzEnrolmentPolicy = tmp_clazzEnrolmentPolicy
          _entity.clazzTerminologyUid = tmp_clazzTerminologyUid
          _entity.clazzMasterChangeSeqNum = tmp_clazzMasterChangeSeqNum
          _entity.clazzLocalChangeSeqNum = tmp_clazzLocalChangeSeqNum
          _entity.clazzLastChangedBy = tmp_clazzLastChangedBy
          _entity.clazzLct = tmp_clazzLct
          _entity.clazzTimeZone = tmp_clazzTimeZone
          _entity.clazzStudentsPersonGroupUid = tmp_clazzStudentsPersonGroupUid
          _entity.clazzTeachersPersonGroupUid = tmp_clazzTeachersPersonGroupUid
          _entity.clazzPendingStudentsPersonGroupUid = tmp_clazzPendingStudentsPersonGroupUid
          _entity.clazzParentsPersonGroupUid = tmp_clazzParentsPersonGroupUid
          _entity.clazzCode = tmp_clazzCode
          var _school_nullFieldCount = 0
          val tmp_schoolUid = _resultSet.getLong("schoolUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolName = _resultSet.getString("schoolName")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolDesc = _resultSet.getString("schoolDesc")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolAddress = _resultSet.getString("schoolAddress")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolActive = _resultSet.getBoolean("schoolActive")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolPhoneNumber = _resultSet.getString("schoolPhoneNumber")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolGender = _resultSet.getInt("schoolGender")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolHolidayCalendarUid = _resultSet.getLong("schoolHolidayCalendarUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolFeatures = _resultSet.getLong("schoolFeatures")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocationLong = _resultSet.getDouble("schoolLocationLong")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocationLatt = _resultSet.getDouble("schoolLocationLatt")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolEmailAddress = _resultSet.getString("schoolEmailAddress")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolTeachersPersonGroupUid = _resultSet.getLong("schoolTeachersPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolStudentsPersonGroupUid = _resultSet.getLong("schoolStudentsPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolPendingStudentsPersonGroupUid =
              _resultSet.getLong("schoolPendingStudentsPersonGroupUid")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolCode = _resultSet.getString("schoolCode")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolMasterChangeSeqNum = _resultSet.getLong("schoolMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLocalChangeSeqNum = _resultSet.getLong("schoolLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLastChangedBy = _resultSet.getInt("schoolLastChangedBy")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolLct = _resultSet.getLong("schoolLct")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          val tmp_schoolTimeZone = _resultSet.getString("schoolTimeZone")
          if(_resultSet.wasNull()) { _school_nullFieldCount++ }
          if(_school_nullFieldCount < 21) {
            if(_entity.school == null) {
              _entity.school = School()
            }
            _entity.school!!.schoolUid = tmp_schoolUid
            _entity.school!!.schoolName = tmp_schoolName
            _entity.school!!.schoolDesc = tmp_schoolDesc
            _entity.school!!.schoolAddress = tmp_schoolAddress
            _entity.school!!.schoolActive = tmp_schoolActive
            _entity.school!!.schoolPhoneNumber = tmp_schoolPhoneNumber
            _entity.school!!.schoolGender = tmp_schoolGender
            _entity.school!!.schoolHolidayCalendarUid = tmp_schoolHolidayCalendarUid
            _entity.school!!.schoolFeatures = tmp_schoolFeatures
            _entity.school!!.schoolLocationLong = tmp_schoolLocationLong
            _entity.school!!.schoolLocationLatt = tmp_schoolLocationLatt
            _entity.school!!.schoolEmailAddress = tmp_schoolEmailAddress
            _entity.school!!.schoolTeachersPersonGroupUid = tmp_schoolTeachersPersonGroupUid
            _entity.school!!.schoolStudentsPersonGroupUid = tmp_schoolStudentsPersonGroupUid
            _entity.school!!.schoolPendingStudentsPersonGroupUid =
                tmp_schoolPendingStudentsPersonGroupUid
            _entity.school!!.schoolCode = tmp_schoolCode
            _entity.school!!.schoolMasterChangeSeqNum = tmp_schoolMasterChangeSeqNum
            _entity.school!!.schoolLocalChangeSeqNum = tmp_schoolLocalChangeSeqNum
            _entity.school!!.schoolLastChangedBy = tmp_schoolLastChangedBy
            _entity.school!!.schoolLct = tmp_schoolLct
            _entity.school!!.schoolTimeZone = tmp_schoolTimeZone
          }
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: Clazz): Long {
    val _retVal = _insertAdapterClazz_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Clazz): Long {
    val _retVal = _insertAdapterClazz_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Clazz>): Unit {
    _insertAdapterClazz_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Clazz>): Unit {
    val _sql =
        "UPDATE Clazz SET clazzName = ?, clazzDesc = ?, attendanceAverage = ?, clazzHolidayUMCalendarUid = ?, clazzScheuleUMCalendarUid = ?, isClazzActive = ?, clazzLocationUid = ?, clazzStartTime = ?, clazzEndTime = ?, clazzFeatures = ?, clazzSchoolUid = ?, clazzEnrolmentPolicy = ?, clazzTerminologyUid = ?, clazzMasterChangeSeqNum = ?, clazzLocalChangeSeqNum = ?, clazzLastChangedBy = ?, clazzLct = ?, clazzTimeZone = ?, clazzStudentsPersonGroupUid = ?, clazzTeachersPersonGroupUid = ?, clazzPendingStudentsPersonGroupUid = ?, clazzParentsPersonGroupUid = ?, clazzCode = ? WHERE clazzUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.clazzName)
        _stmt.setString(2, _entity.clazzDesc)
        _stmt.setFloat(3, _entity.attendanceAverage)
        _stmt.setLong(4, _entity.clazzHolidayUMCalendarUid)
        _stmt.setLong(5, _entity.clazzScheuleUMCalendarUid)
        _stmt.setBoolean(6, _entity.isClazzActive)
        _stmt.setLong(7, _entity.clazzLocationUid)
        _stmt.setLong(8, _entity.clazzStartTime)
        _stmt.setLong(9, _entity.clazzEndTime)
        _stmt.setLong(10, _entity.clazzFeatures)
        _stmt.setLong(11, _entity.clazzSchoolUid)
        _stmt.setInt(12, _entity.clazzEnrolmentPolicy)
        _stmt.setLong(13, _entity.clazzTerminologyUid)
        _stmt.setLong(14, _entity.clazzMasterChangeSeqNum)
        _stmt.setLong(15, _entity.clazzLocalChangeSeqNum)
        _stmt.setInt(16, _entity.clazzLastChangedBy)
        _stmt.setLong(17, _entity.clazzLct)
        _stmt.setString(18, _entity.clazzTimeZone)
        _stmt.setLong(19, _entity.clazzStudentsPersonGroupUid)
        _stmt.setLong(20, _entity.clazzTeachersPersonGroupUid)
        _stmt.setLong(21, _entity.clazzPendingStudentsPersonGroupUid)
        _stmt.setLong(22, _entity.clazzParentsPersonGroupUid)
        _stmt.setString(23, _entity.clazzCode)
        _stmt.setLong(24, _entity.clazzUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Clazz): Unit {
    val _sql =
        "UPDATE Clazz SET clazzName = ?, clazzDesc = ?, attendanceAverage = ?, clazzHolidayUMCalendarUid = ?, clazzScheuleUMCalendarUid = ?, isClazzActive = ?, clazzLocationUid = ?, clazzStartTime = ?, clazzEndTime = ?, clazzFeatures = ?, clazzSchoolUid = ?, clazzEnrolmentPolicy = ?, clazzTerminologyUid = ?, clazzMasterChangeSeqNum = ?, clazzLocalChangeSeqNum = ?, clazzLastChangedBy = ?, clazzLct = ?, clazzTimeZone = ?, clazzStudentsPersonGroupUid = ?, clazzTeachersPersonGroupUid = ?, clazzPendingStudentsPersonGroupUid = ?, clazzParentsPersonGroupUid = ?, clazzCode = ? WHERE clazzUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.clazzName)
      _stmt.setString(2, entity.clazzDesc)
      _stmt.setFloat(3, entity.attendanceAverage)
      _stmt.setLong(4, entity.clazzHolidayUMCalendarUid)
      _stmt.setLong(5, entity.clazzScheuleUMCalendarUid)
      _stmt.setBoolean(6, entity.isClazzActive)
      _stmt.setLong(7, entity.clazzLocationUid)
      _stmt.setLong(8, entity.clazzStartTime)
      _stmt.setLong(9, entity.clazzEndTime)
      _stmt.setLong(10, entity.clazzFeatures)
      _stmt.setLong(11, entity.clazzSchoolUid)
      _stmt.setInt(12, entity.clazzEnrolmentPolicy)
      _stmt.setLong(13, entity.clazzTerminologyUid)
      _stmt.setLong(14, entity.clazzMasterChangeSeqNum)
      _stmt.setLong(15, entity.clazzLocalChangeSeqNum)
      _stmt.setInt(16, entity.clazzLastChangedBy)
      _stmt.setLong(17, entity.clazzLct)
      _stmt.setString(18, entity.clazzTimeZone)
      _stmt.setLong(19, entity.clazzStudentsPersonGroupUid)
      _stmt.setLong(20, entity.clazzTeachersPersonGroupUid)
      _stmt.setLong(21, entity.clazzPendingStudentsPersonGroupUid)
      _stmt.setLong(22, entity.clazzParentsPersonGroupUid)
      _stmt.setString(23, entity.clazzCode)
      _stmt.setLong(24, entity.clazzUid)
      _stmt.executeUpdate()
    }
  }
}
