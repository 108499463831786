package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.ClazzAssignmentContentJoin
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import io.ktor.client.HttpClient
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzAssignmentContentJoinDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzAssignmentContentJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : ClazzAssignmentContentJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun findAllContentByClazzAssignmentUidAsync(clazzAssignmentUid: Long,
      personUid: Long): List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = _dao.findAllContentByClazzAssignmentUidAsync(clazzAssignmentUid, personUid)
    return _result
  }

  public override fun findAllContentByClazzAssignmentUidDF(clazzAssignmentUid: Long,
      personUid: Long): DoorDataSourceFactory<Int,
      ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = _dao.findAllContentByClazzAssignmentUidDF(clazzAssignmentUid, personUid)
    return _result
  }

  public override fun insert(entity: ClazzAssignmentContentJoin): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzAssignmentContentJoin): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out ClazzAssignmentContentJoin>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzAssignmentContentJoin>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ClazzAssignmentContentJoin): Unit {
    _dao.update(entity)
  }
}
