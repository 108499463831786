package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContextXObjectStatementJoin
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContextXObjectStatementJoinDao_JdbcKt(
  public val _db: DoorDatabase
) : ContextXObjectStatementJoinDao() {
  public val _insertAdapterContextXObjectStatementJoin_:
      EntityInsertionAdapter<ContextXObjectStatementJoin> = object :
      EntityInsertionAdapter<ContextXObjectStatementJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContextXObjectStatementJoin (contextXObjectStatementJoinUid, contextActivityFlag, contextStatementUid, contextXObjectUid, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, contextXObjectLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContextXObjectStatementJoin (contextXObjectStatementJoinUid, contextActivityFlag, contextStatementUid, contextXObjectUid, verbMasterChangeSeqNum, verbLocalChangeSeqNum, verbLastChangedBy, contextXObjectLct) VALUES(COALESCE(?,nextval('ContextXObjectStatementJoin_contextXObjectStatementJoinUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING contextXObjectStatementJoinUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContextXObjectStatementJoin): Unit {
      if(entity.contextXObjectStatementJoinUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.contextXObjectStatementJoinUid)
      }
      stmt.setInt(2, entity.contextActivityFlag)
      stmt.setLong(3, entity.contextStatementUid)
      stmt.setLong(4, entity.contextXObjectUid)
      stmt.setLong(5, entity.verbMasterChangeSeqNum)
      stmt.setLong(6, entity.verbLocalChangeSeqNum)
      stmt.setInt(7, entity.verbLastChangedBy)
      stmt.setLong(8, entity.contextXObjectLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |    REPLACE INTO ContextXObjectStatementJoinReplicate(cxosjPk, cxosjDestination)
    |    SELECT DISTINCT ContextXObjectStatementJoin.contextXObjectStatementJoinUid AS cxosjPk,
    |         UserSession.usClientNodeId AS cxosjDestination
    |    FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |             JOIN StatementEntity
    |                  ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz 
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |            )         
    |        
    |             JOIN ContextXObjectStatementJoin
    |                  ON ContextXObjectStatementJoin.contextStatementUid = StatementEntity.statementUid
    |   WHERE UserSession.usClientNodeId = ?
    |     -- Temporary measure to prevent admin user getting clogged up
    |     -- Restrict to the last 30 days of data
    |     AND StatementEntity.timestamp > ( 
    |   --notpsql
    |   strftime('%s', 'now') * 1000
    |   --endnotpsql
    |   /*psql
    |   ROUND(EXTRACT(epoch from NOW())*1000)
    |   */
    |   - (30 * CAST(86400000 AS BIGINT)))   
    |    --notpsql 
    |     AND ContextXObjectStatementJoin.contextXObjectLct != COALESCE(
    |         (SELECT cxosjVersionId
    |            FROM ContextXObjectStatementJoinReplicate
    |           WHERE cxosjPk = ContextXObjectStatementJoin.contextXObjectStatementJoinUid
    |             AND cxosjDestination = UserSession.usClientNodeId), 0) 
    |    --endnotpsql         
    |    /*psql ON CONFLICT(cxosjPk, cxosjDestination) DO UPDATE
    |     SET cxosjPending = (SELECT ContextXObjectStatementJoin.contextXObjectLct
    |                           FROM ContextXObjectStatementJoin
    |                          WHERE ContextXObjectStatementJoin.contextXObjectStatementJoinUid = EXCLUDED.cxosjPk ) 
    |                                != ContextXObjectStatementJoinReplicate.cxosjVersionId             
    |    */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContextXObjectStatementJoinReplicate(cxosjPk, cxosjDestination)
    |    SELECT DISTINCT ContextXObjectStatementJoin.contextXObjectStatementJoinUid AS cxosjPk,
    |         UserSession.usClientNodeId AS cxosjDestination
    |    FROM UserSession
    |             JOIN PersonGroupMember
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             JOIN ScopedGrant
    |                  ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                     AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |             JOIN StatementEntity
    |                  ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz 
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |            )         
    |        
    |             JOIN ContextXObjectStatementJoin
    |                  ON ContextXObjectStatementJoin.contextStatementUid = StatementEntity.statementUid
    |   WHERE UserSession.usClientNodeId = ?
    |     -- Temporary measure to prevent admin user getting clogged up
    |     -- Restrict to the last 30 days of data
    |     AND StatementEntity.timestamp > ( 
    |   
    |   ROUND(EXTRACT(epoch from NOW())*1000)
    |   
    |   - (30 * CAST(86400000 AS BIGINT)))   
    |     ON CONFLICT(cxosjPk, cxosjDestination) DO UPDATE
    |     SET cxosjPending = (SELECT ContextXObjectStatementJoin.contextXObjectLct
    |                           FROM ContextXObjectStatementJoin
    |                          WHERE ContextXObjectStatementJoin.contextXObjectStatementJoinUid = EXCLUDED.cxosjPk ) 
    |                                != ContextXObjectStatementJoinReplicate.cxosjVersionId             
    |           
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |    REPLACE INTO ContextXObjectStatementJoinReplicate(cxosjPk, cxosjDestination)
    |    SELECT DISTINCT ContextXObjectStatementJoin.contextXObjectStatementJoinUid AS cxosjUid,
    |         UserSession.usClientNodeId AS cxosjDestination
    |    FROM ChangeLog
    |         JOIN ContextXObjectStatementJoin
    |             ON ChangeLog.chTableId = 66
    |                AND ChangeLog.chEntityPk = ContextXObjectStatementJoin.contextXObjectStatementJoinUid
    |         JOIN StatementEntity
    |               ON ContextXObjectStatementJoin.contextStatementUid = StatementEntity.statementUid
    |         JOIN ScopedGrant
    |              ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |             )
    |        
    |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |         JOIN PersonGroupMember
    |              ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |         JOIN UserSession
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                 AND UserSession.usStatus = 1
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |   --notpsql       
    |     AND ContextXObjectStatementJoin.contextXObjectLct != COALESCE(
    |         (SELECT cxosjVersionId
    |            FROM ContextXObjectStatementJoinReplicate
    |           WHERE cxosjPk = ContextXObjectStatementJoin.contextXObjectStatementJoinUid
    |             AND cxosjDestination = UserSession.usClientNodeId), 0)
    |    --endnotpsql
    |    /*psql ON CONFLICT(cxosjPk, cxosjDestination) DO UPDATE
    |     SET cxosjPending = (SELECT ContextXObjectStatementJoin.contextXObjectLct
    |                           FROM ContextXObjectStatementJoin
    |                          WHERE ContextXObjectStatementJoin.contextXObjectStatementJoinUid = EXCLUDED.cxosjPk ) 
    |                                != ContextXObjectStatementJoinReplicate.cxosjVersionId             
    |    */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContextXObjectStatementJoinReplicate(cxosjPk, cxosjDestination)
    |    SELECT DISTINCT ContextXObjectStatementJoin.contextXObjectStatementJoinUid AS cxosjUid,
    |         UserSession.usClientNodeId AS cxosjDestination
    |    FROM ChangeLog
    |         JOIN ContextXObjectStatementJoin
    |             ON ChangeLog.chTableId = 66
    |                AND ChangeLog.chEntityPk = ContextXObjectStatementJoin.contextXObjectStatementJoinUid
    |         JOIN StatementEntity
    |               ON ContextXObjectStatementJoin.contextStatementUid = StatementEntity.statementUid
    |         JOIN ScopedGrant
    |              ON 
    |            ((ScopedGrant.sgTableId = -2
    |                AND ScopedGrant.sgEntityUid = -2)
    |             OR (ScopedGrant.sgTableId = 9
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementPersonUid)
    |             OR (ScopedGrant.sgTableId = 6
    |                AND ScopedGrant.sgEntityUid = StatementEntity.statementClazzUid)
    |             OR (ScopedGrant.sgTableId = 164
    |                AND ScopedGrant.sgEntityUid = (
    |                    SELECT clazzSchoolUid
    |                      FROM Clazz
    |                     WHERE clazzUid = StatementEntity.statementClazzUid))
    |             )
    |        
    |                 AND (ScopedGrant.sgPermissions & 549755813888) > 0
    |         JOIN PersonGroupMember
    |              ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |         JOIN UserSession
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |                 AND UserSession.usStatus = 1
    |    WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     ON CONFLICT(cxosjPk, cxosjDestination) DO UPDATE
    |     SET cxosjPending = (SELECT ContextXObjectStatementJoin.contextXObjectLct
    |                           FROM ContextXObjectStatementJoin
    |                          WHERE ContextXObjectStatementJoin.contextXObjectStatementJoinUid = EXCLUDED.cxosjPk ) 
    |                                != ContextXObjectStatementJoinReplicate.cxosjVersionId             
    |                   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByStatementAndObjectUid(statementUid: Long, objectUid: Long):
      ContextXObjectStatementJoin? {
    var _result = null as com.ustadmobile.lib.db.entities.ContextXObjectStatementJoin??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ContextXObjectStatementJoin where contextStatementUid = ? and contextXObjectUid = ?"
        , postgreSql = """
    |SELECT * FROM ContextXObjectStatementJoin where contextStatementUid = ? and contextXObjectUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, statementUid)
      _stmt.setLong(2, objectUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_contextXObjectStatementJoinUid =
              _resultSet.getLong("contextXObjectStatementJoinUid")
          val tmp_contextActivityFlag = _resultSet.getInt("contextActivityFlag")
          val tmp_contextStatementUid = _resultSet.getLong("contextStatementUid")
          val tmp_contextXObjectUid = _resultSet.getLong("contextXObjectUid")
          val tmp_verbMasterChangeSeqNum = _resultSet.getLong("verbMasterChangeSeqNum")
          val tmp_verbLocalChangeSeqNum = _resultSet.getLong("verbLocalChangeSeqNum")
          val tmp_verbLastChangedBy = _resultSet.getInt("verbLastChangedBy")
          val tmp_contextXObjectLct = _resultSet.getLong("contextXObjectLct")
          val _entity = ContextXObjectStatementJoin()
          _entity.contextXObjectStatementJoinUid = tmp_contextXObjectStatementJoinUid
          _entity.contextActivityFlag = tmp_contextActivityFlag
          _entity.contextStatementUid = tmp_contextStatementUid
          _entity.contextXObjectUid = tmp_contextXObjectUid
          _entity.verbMasterChangeSeqNum = tmp_verbMasterChangeSeqNum
          _entity.verbLocalChangeSeqNum = tmp_verbLocalChangeSeqNum
          _entity.verbLastChangedBy = tmp_verbLastChangedBy
          _entity.contextXObjectLct = tmp_contextXObjectLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ContextXObjectStatementJoin): Long {
    val _retVal = _insertAdapterContextXObjectStatementJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContextXObjectStatementJoin): Long {
    val _retVal = _insertAdapterContextXObjectStatementJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContextXObjectStatementJoin>): Unit {
    _insertAdapterContextXObjectStatementJoin_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContextXObjectStatementJoin>): Unit {
    val _sql =
        "UPDATE ContextXObjectStatementJoin SET contextActivityFlag = ?, contextStatementUid = ?, contextXObjectUid = ?, verbMasterChangeSeqNum = ?, verbLocalChangeSeqNum = ?, verbLastChangedBy = ?, contextXObjectLct = ? WHERE contextXObjectStatementJoinUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setInt(1, _entity.contextActivityFlag)
        _stmt.setLong(2, _entity.contextStatementUid)
        _stmt.setLong(3, _entity.contextXObjectUid)
        _stmt.setLong(4, _entity.verbMasterChangeSeqNum)
        _stmt.setLong(5, _entity.verbLocalChangeSeqNum)
        _stmt.setInt(6, _entity.verbLastChangedBy)
        _stmt.setLong(7, _entity.contextXObjectLct)
        _stmt.setLong(8, _entity.contextXObjectStatementJoinUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContextXObjectStatementJoin): Unit {
    val _sql =
        "UPDATE ContextXObjectStatementJoin SET contextActivityFlag = ?, contextStatementUid = ?, contextXObjectUid = ?, verbMasterChangeSeqNum = ?, verbLocalChangeSeqNum = ?, verbLastChangedBy = ?, contextXObjectLct = ? WHERE contextXObjectStatementJoinUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setInt(1, entity.contextActivityFlag)
      _stmt.setLong(2, entity.contextStatementUid)
      _stmt.setLong(3, entity.contextXObjectUid)
      _stmt.setLong(4, entity.verbMasterChangeSeqNum)
      _stmt.setLong(5, entity.verbLocalChangeSeqNum)
      _stmt.setInt(6, entity.verbLastChangedBy)
      _stmt.setLong(7, entity.contextXObjectLct)
      _stmt.setLong(8, entity.contextXObjectStatementJoinUid)
      _stmt.executeUpdate()
    }
  }
}
