package com.ustadmobile.lib.db.entities

import com.ustadmobile.door.attachments.EntityWithAttachment
import kotlin.Int
import kotlin.String

public inline class ContentEntryPicture_EwaAdapter(
  private val entity: ContentEntryPicture
) : EntityWithAttachment {
  public override var attachmentUri: String?
    get() = entity.cepUri
    set(`value`) {
      entity.cepUri = value
    }

  public override var attachmentMd5: String?
    get() = entity.cepMd5
    set(`value`) {
      entity.cepMd5 = value
    }

  public override var attachmentSize: Int
    get() = entity.cepFileSize
    set(`value`) {
      entity.cepFileSize = value
    }

  public override val tableName: String
    get() = "ContentEntryPicture"
}

public inline fun ContentEntryPicture.asEntityWithAttachment(): EntityWithAttachment =
    ContentEntryPicture_EwaAdapter(this)
