package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.Site
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class SiteDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: SiteDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : SiteDao() {
  public override fun getSite(): Site? {
    val _result = _dao.getSite()
    return _result
  }

  public override suspend fun getSiteAsync(): Site? {
    val _result = _dao.getSiteAsync()
    return _result
  }

  public override suspend fun replaceAsync(site: Site): Long {
    val _result = _dao.replaceAsync(site)
    return _result
  }

  public override fun insert(site: Site): Long {
    val _result = _dao.insert(site)
    return _result
  }

  public override suspend fun updateAsync(workspace: Site): Unit {
    _dao.updateAsync(workspace)
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }
}
