package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CourseAssignmentSubmissionAttachment
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseAssignmentSubmissionAttachmentDao_JdbcKt(
  public val _db: DoorDatabase
) : CourseAssignmentSubmissionAttachmentDao() {
  public val _insertAdapterCourseAssignmentSubmissionAttachment_:
      EntityInsertionAdapter<CourseAssignmentSubmissionAttachment> = object :
      EntityInsertionAdapter<CourseAssignmentSubmissionAttachment>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CourseAssignmentSubmissionAttachment (casaUid, casaSubmissionUid, casaMimeType, casaFileName, casaUri, casaMd5, casaSize, casaTimestamp) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseAssignmentSubmissionAttachment (casaUid, casaSubmissionUid, casaMimeType, casaFileName, casaUri, casaMd5, casaSize, casaTimestamp) VALUES(COALESCE(?,nextval('CourseAssignmentSubmissionAttachment_casaUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING casaUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseAssignmentSubmissionAttachment): Unit {
      if(entity.casaUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.casaUid)
      }
      stmt.setLong(2, entity.casaSubmissionUid)
      stmt.setString(3, entity.casaMimeType)
      stmt.setString(4, entity.casaFileName)
      stmt.setString(5, entity.casaUri)
      stmt.setString(6, entity.casaMd5)
      stmt.setInt(7, entity.casaSize)
      stmt.setLong(8, entity.casaTimestamp)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseAssignmentSubmissionAttachmentReplicate(casaPk, casaDestination)
    |      SELECT DISTINCT CourseAssignmentSubmissionAttachment.casaUid AS casaPk,
    |             ? AS casaDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzAssignment
    |                  ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentSubmission
    |                  ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |             JOIN CourseAssignmentSubmissionAttachment
    |                  ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentSubmissionAttachment.casaTimestamp != COALESCE(
    |             (SELECT casaVersionId
    |                FROM CourseAssignmentSubmissionAttachmentReplicate
    |               WHERE casaPk = CourseAssignmentSubmissionAttachment.casaUid
    |                 AND casaDestination = ?), 0) 
    |      /*psql ON CONFLICT(casaPk, casaDestination) DO UPDATE
    |             SET casaPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentSubmissionAttachmentReplicate(casaPk, casaDestination)
    |      SELECT DISTINCT CourseAssignmentSubmissionAttachment.casaUid AS casaPk,
    |             ? AS casaDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |             JOIN ClazzAssignment
    |                  ON ClazzAssignment.caClazzUid = Clazz.clazzUid                
    |             JOIN CourseAssignmentSubmission
    |                  ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |             JOIN CourseAssignmentSubmissionAttachment
    |                  ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND CourseAssignmentSubmissionAttachment.casaTimestamp != COALESCE(
    |             (SELECT casaVersionId
    |                FROM CourseAssignmentSubmissionAttachmentReplicate
    |               WHERE casaPk = CourseAssignmentSubmissionAttachment.casaUid
    |                 AND casaDestination = ?), 0) 
    |       ON CONFLICT(casaPk, casaDestination) DO UPDATE
    |             SET casaPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO CourseAssignmentSubmissionAttachmentReplicate(casaPk, casaDestination)
    |  SELECT DISTINCT CourseAssignmentSubmissionAttachment.casaUid AS casaPk,
    |         UserSession.usClientNodeId AS casaDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentSubmissionAttachment
    |             ON ChangeLog.chTableId = 90
    |                AND ChangeLog.chEntityPk = CourseAssignmentSubmissionAttachment.casaUid
    |             JOIN CourseAssignmentSubmission
    |                  ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid   
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentSubmissionAttachment.casaTimestamp != COALESCE(
    |         (SELECT casaVersionId
    |            FROM CourseAssignmentSubmissionAttachmentReplicate
    |           WHERE casaPk = CourseAssignmentSubmissionAttachment.casaUid
    |             AND casaDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(casaPk, casaDestination) DO UPDATE
    |     SET casaPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseAssignmentSubmissionAttachmentReplicate(casaPk, casaDestination)
    |  SELECT DISTINCT CourseAssignmentSubmissionAttachment.casaUid AS casaPk,
    |         UserSession.usClientNodeId AS casaDestination
    |    FROM ChangeLog
    |         JOIN CourseAssignmentSubmissionAttachment
    |             ON ChangeLog.chTableId = 90
    |                AND ChangeLog.chEntityPk = CourseAssignmentSubmissionAttachment.casaUid
    |             JOIN CourseAssignmentSubmission
    |                  ON CourseAssignmentSubmissionAttachment.casaSubmissionUid = CourseAssignmentSubmission.casUid   
    |             JOIN ClazzAssignment
    |                    ON CourseAssignmentSubmission.casAssignmentUid = ClazzAssignment.caUid
    |             JOIN Clazz
    |                    ON  Clazz.clazzUid = ClazzAssignment.caClazzUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |          
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseAssignmentSubmissionAttachment.casaTimestamp != COALESCE(
    |         (SELECT casaVersionId
    |            FROM CourseAssignmentSubmissionAttachmentReplicate
    |           WHERE casaPk = CourseAssignmentSubmissionAttachment.casaUid
    |             AND casaDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(casaPk, casaDestination) DO UPDATE
    |     SET casaPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend
      fun insertListAsync(entityList: List<CourseAssignmentSubmissionAttachment>): Unit {
    _insertAdapterCourseAssignmentSubmissionAttachment_.insertListAsync(entityList)
  }

  public override fun insert(entity: CourseAssignmentSubmissionAttachment): Long {
    val _retVal = _insertAdapterCourseAssignmentSubmissionAttachment_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseAssignmentSubmissionAttachment): Long {
    val _retVal = _insertAdapterCourseAssignmentSubmissionAttachment_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CourseAssignmentSubmissionAttachment>): Unit {
    _insertAdapterCourseAssignmentSubmissionAttachment_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseAssignmentSubmissionAttachment>): Unit {
    val _sql =
        "UPDATE CourseAssignmentSubmissionAttachment SET casaSubmissionUid = ?, casaMimeType = ?, casaFileName = ?, casaUri = ?, casaMd5 = ?, casaSize = ?, casaTimestamp = ? WHERE casaUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.casaSubmissionUid)
        _stmt.setString(2, _entity.casaMimeType)
        _stmt.setString(3, _entity.casaFileName)
        _stmt.setString(4, _entity.casaUri)
        _stmt.setString(5, _entity.casaMd5)
        _stmt.setInt(6, _entity.casaSize)
        _stmt.setLong(7, _entity.casaTimestamp)
        _stmt.setLong(8, _entity.casaUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseAssignmentSubmissionAttachment): Unit {
    val _sql =
        "UPDATE CourseAssignmentSubmissionAttachment SET casaSubmissionUid = ?, casaMimeType = ?, casaFileName = ?, casaUri = ?, casaMd5 = ?, casaSize = ?, casaTimestamp = ? WHERE casaUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.casaSubmissionUid)
      _stmt.setString(2, entity.casaMimeType)
      _stmt.setString(3, entity.casaFileName)
      _stmt.setString(4, entity.casaUri)
      _stmt.setString(5, entity.casaMd5)
      _stmt.setInt(6, entity.casaSize)
      _stmt.setLong(7, entity.casaTimestamp)
      _stmt.setLong(8, entity.casaUid)
      _stmt.executeUpdate()
    }
  }
}
