package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ClazzAssignmentContentJoin
import com.ustadmobile.lib.db.entities.Container
import com.ustadmobile.lib.db.entities.ContentEntryParentChildJoin
import com.ustadmobile.lib.db.entities.ContentEntryStatementScoreProgress
import com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzAssignmentContentJoinDao_JdbcKt(
  public val _db: DoorDatabase
) : ClazzAssignmentContentJoinDao() {
  public val _insertAdapterClazzAssignmentContentJoin_:
      EntityInsertionAdapter<ClazzAssignmentContentJoin> = object :
      EntityInsertionAdapter<ClazzAssignmentContentJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ClazzAssignmentContentJoin (cacjUid, cacjContentUid, cacjAssignmentUid, cacjActive, cacjWeight, cacjMCSN, cacjLCSN, cacjLCB, cacjLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ClazzAssignmentContentJoin (cacjUid, cacjContentUid, cacjAssignmentUid, cacjActive, cacjWeight, cacjMCSN, cacjLCSN, cacjLCB, cacjLct) VALUES(COALESCE(?,nextval('ClazzAssignmentContentJoin_cacjUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cacjUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ClazzAssignmentContentJoin): Unit {
      if(entity.cacjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cacjUid)
      }
      stmt.setLong(2, entity.cacjContentUid)
      stmt.setLong(3, entity.cacjAssignmentUid)
      stmt.setBoolean(4, entity.cacjActive)
      stmt.setInt(5, entity.cacjWeight)
      stmt.setLong(6, entity.cacjMCSN)
      stmt.setLong(7, entity.cacjLCSN)
      stmt.setInt(8, entity.cacjLCB)
      stmt.setLong(9, entity.cacjLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzAssignmentContentJoinReplicate(cacjPk, cacjDestination)
    |      SELECT DISTINCT ClazzAssignmentContentJoin.cacjUid AS cacjUid,
    |             ? AS cacjDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |         
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid
    |               JOIN ClazzAssignmentContentJoin
    |                    ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid     
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1             
    |         AND ClazzAssignmentContentJoin.cacjLct != COALESCE(
    |             (SELECT cacjVersionId
    |                FROM ClazzAssignmentContentJoinReplicate
    |               WHERE cacjPk = ClazzAssignmentContentJoin.cacjUid
    |                 AND cacjDestination = ?), 0) 
    |      /*psql ON CONFLICT(cacjPk, cacjDestination) DO UPDATE
    |             SET cacjPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentContentJoinReplicate(cacjPk, cacjDestination)
    |      SELECT DISTINCT ClazzAssignmentContentJoin.cacjUid AS cacjUid,
    |             ? AS cacjDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    8388608 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |         
    |               JOIN ClazzAssignment
    |                    ON ClazzAssignment.caClazzUid = Clazz.clazzUid
    |               JOIN ClazzAssignmentContentJoin
    |                    ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid     
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1             
    |         AND ClazzAssignmentContentJoin.cacjLct != COALESCE(
    |             (SELECT cacjVersionId
    |                FROM ClazzAssignmentContentJoinReplicate
    |               WHERE cacjPk = ClazzAssignmentContentJoin.cacjUid
    |                 AND cacjDestination = ?), 0) 
    |       ON CONFLICT(cacjPk, cacjDestination) DO UPDATE
    |             SET cacjPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzAssignmentContentJoinReplicate(cacjPk, cacjDestination)
    |  SELECT DISTINCT ClazzAssignmentContentJoin.cacjUid AS cacjUid,
    |         UserSession.usClientNodeId AS cacjDestination
    |    FROM ChangeLog
    |         JOIN ClazzAssignmentContentJoin
    |             ON ChangeLog.chTableId = 521
    |                AND ChangeLog.chEntityPk = ClazzAssignmentContentJoin.cacjUid
    |         JOIN ClazzAssignment
    |              ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzAssignment.caClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzAssignmentContentJoin.cacjLct != COALESCE(
    |         (SELECT cacjVersionId
    |            FROM ClazzAssignmentContentJoinReplicate
    |           WHERE cacjPk = ClazzAssignmentContentJoin.cacjUid
    |             AND cacjDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(cacjPk, cacjDestination) DO UPDATE
    |     SET cacjPending = true
    |  */               
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzAssignmentContentJoinReplicate(cacjPk, cacjDestination)
    |  SELECT DISTINCT ClazzAssignmentContentJoin.cacjUid AS cacjUid,
    |         UserSession.usClientNodeId AS cacjDestination
    |    FROM ChangeLog
    |         JOIN ClazzAssignmentContentJoin
    |             ON ChangeLog.chTableId = 521
    |                AND ChangeLog.chEntityPk = ClazzAssignmentContentJoin.cacjUid
    |         JOIN ClazzAssignment
    |              ON ClazzAssignment.caUid = ClazzAssignmentContentJoin.cacjAssignmentUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzAssignment.caClazzUid 
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              8388608
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzAssignmentContentJoin.cacjLct != COALESCE(
    |         (SELECT cacjVersionId
    |            FROM ClazzAssignmentContentJoinReplicate
    |           WHERE cacjPk = ClazzAssignmentContentJoin.cacjUid
    |             AND cacjDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(cacjPk, cacjDestination) DO UPDATE
    |     SET cacjPending = true
    |                 
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findAllContentByClazzAssignmentUidAsync(clazzAssignmentUid: Long,
      personUid: Long): List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    var _result =
        mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
    |                            Container.*, 
    |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
    |                                           
    |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
    |                                                         
    |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
    |                            
    |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
    |                                 
    |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
    |                             
    |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
    |                               
    |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
    |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
    |                        
    |                             0 as assignmentContentWeight,
    |                             1 as totalContent
    |                           
    |                             
    |                      FROM ClazzAssignmentContentJoin
    |                            LEFT JOIN ContentEntry 
    |                            ON ContentEntry.contentEntryUid = cacjContentUid 
    |                            
    |                            LEFT JOIN ContentEntryParentChildJoin 
    |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
    |                           
    |                            LEFT JOIN ClazzAssignmentRollUp
    |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
    |                                AND cachePersonUid = ?
    |                                AND cacheClazzAssignmentUid = ?
    |                                                        
    |                            
    |                            LEFT JOIN Container 
    |                            ON Container.containerUid = 
    |                                (SELECT containerUid 
    |                                   FROM Container 
    |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
    |                               ORDER BY cntLastModified DESC LIMIT 1)
    |                               
    |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
    |                      AND ClazzAssignmentContentJoin.cacjActive
    |                      AND NOT ContentEntry.ceInactive
    |                      AND (ContentEntry.publik OR ? != 0)
    |                      ORDER BY ContentEntry.title ASC , 
    |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
    |                               
    """.trimMargin() , postgreSql = """
    |
    |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
    |                            Container.*, 
    |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
    |                                           
    |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
    |                                                         
    |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
    |                            
    |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
    |                                 
    |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
    |                             
    |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
    |                               
    |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
    |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
    |                        
    |                             0 as assignmentContentWeight,
    |                             1 as totalContent
    |                           
    |                             
    |                      FROM ClazzAssignmentContentJoin
    |                            LEFT JOIN ContentEntry 
    |                            ON ContentEntry.contentEntryUid = cacjContentUid 
    |                            
    |                            LEFT JOIN ContentEntryParentChildJoin 
    |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
    |                           
    |                            LEFT JOIN ClazzAssignmentRollUp
    |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
    |                                AND cachePersonUid = ?
    |                                AND cacheClazzAssignmentUid = ?
    |                                                        
    |                            
    |                            LEFT JOIN Container 
    |                            ON Container.containerUid = 
    |                                (SELECT containerUid 
    |                                   FROM Container 
    |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
    |                               ORDER BY cntLastModified DESC LIMIT 1)
    |                               
    |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
    |                      AND ClazzAssignmentContentJoin.cacjActive
    |                      AND NOT ContentEntry.ceInactive
    |                      AND (ContentEntry.publik OR ? != 0)
    |                      ORDER BY ContentEntry.title ASC , 
    |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
    |                               
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, personUid)
      _stmt.setLong(2, clazzAssignmentUid)
      _stmt.setLong(3, clazzAssignmentUid)
      _stmt.setLong(4, personUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_assignmentContentWeight = _resultSet.getInt("assignmentContentWeight")
          val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
          val tmp_title = _resultSet.getString("title")
          val tmp_description = _resultSet.getString("description")
          val tmp_entryId = _resultSet.getString("entryId")
          val tmp_author = _resultSet.getString("author")
          val tmp_publisher = _resultSet.getString("publisher")
          val tmp_licenseType = _resultSet.getInt("licenseType")
          val tmp_licenseName = _resultSet.getString("licenseName")
          val tmp_licenseUrl = _resultSet.getString("licenseUrl")
          val tmp_sourceUrl = _resultSet.getString("sourceUrl")
          val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
          val tmp_lastModified = _resultSet.getLong("lastModified")
          val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
          val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
          val tmp_contentFlags = _resultSet.getInt("contentFlags")
          val tmp_leaf = _resultSet.getBoolean("leaf")
          val tmp_publik = _resultSet.getBoolean("publik")
          val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
          val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
          val tmp_minScore = _resultSet.getInt("minScore")
          val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
          val tmp_contentOwner = _resultSet.getLong("contentOwner")
          val tmp_contentEntryLocalChangeSeqNum =
              _resultSet.getLong("contentEntryLocalChangeSeqNum")
          val tmp_contentEntryMasterChangeSeqNum =
              _resultSet.getLong("contentEntryMasterChangeSeqNum")
          val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
          val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
          val _entity = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
          _entity.assignmentContentWeight = tmp_assignmentContentWeight
          _entity.contentEntryUid = tmp_contentEntryUid
          _entity.title = tmp_title
          _entity.description = tmp_description
          _entity.entryId = tmp_entryId
          _entity.author = tmp_author
          _entity.publisher = tmp_publisher
          _entity.licenseType = tmp_licenseType
          _entity.licenseName = tmp_licenseName
          _entity.licenseUrl = tmp_licenseUrl
          _entity.sourceUrl = tmp_sourceUrl
          _entity.thumbnailUrl = tmp_thumbnailUrl
          _entity.lastModified = tmp_lastModified
          _entity.primaryLanguageUid = tmp_primaryLanguageUid
          _entity.languageVariantUid = tmp_languageVariantUid
          _entity.contentFlags = tmp_contentFlags
          _entity.leaf = tmp_leaf
          _entity.publik = tmp_publik
          _entity.ceInactive = tmp_ceInactive
          _entity.completionCriteria = tmp_completionCriteria
          _entity.minScore = tmp_minScore
          _entity.contentTypeFlag = tmp_contentTypeFlag
          _entity.contentOwner = tmp_contentOwner
          _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
          _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
          _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
          _entity.contentEntryLct = tmp_contentEntryLct
          var _mostRecentContainer_nullFieldCount = 0
          val tmp_containerUid = _resultSet.getLong("containerUid")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntLct = _resultSet.getLong("cntLct")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_fileSize = _resultSet.getLong("fileSize")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_mimeType = _resultSet.getString("mimeType")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_remarks = _resultSet.getString("remarks")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
          if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
          if(_mostRecentContainer_nullFieldCount < 12) {
            if(_entity.mostRecentContainer == null) {
              _entity.mostRecentContainer = Container()
            }
            _entity.mostRecentContainer!!.containerUid = tmp_containerUid
            _entity.mostRecentContainer!!.cntLocalCsn = tmp_cntLocalCsn
            _entity.mostRecentContainer!!.cntMasterCsn = tmp_cntMasterCsn
            _entity.mostRecentContainer!!.cntLastModBy = tmp_cntLastModBy
            _entity.mostRecentContainer!!.cntLct = tmp_cntLct
            _entity.mostRecentContainer!!.fileSize = tmp_fileSize
            _entity.mostRecentContainer!!.containerContentEntryUid = tmp_containerContentEntryUid
            _entity.mostRecentContainer!!.cntLastModified = tmp_cntLastModified
            _entity.mostRecentContainer!!.mimeType = tmp_mimeType
            _entity.mostRecentContainer!!.remarks = tmp_remarks
            _entity.mostRecentContainer!!.mobileOptimized = tmp_mobileOptimized
            _entity.mostRecentContainer!!.cntNumEntries = tmp_cntNumEntries
          }
          var _contentEntryParentChildJoin_nullFieldCount = 0
          val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_childIndex = _resultSet.getInt("childIndex")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
          if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
          if(_contentEntryParentChildJoin_nullFieldCount < 8) {
            if(_entity.contentEntryParentChildJoin == null) {
              _entity.contentEntryParentChildJoin = ContentEntryParentChildJoin()
            }
            _entity.contentEntryParentChildJoin!!.cepcjParentContentEntryUid =
                tmp_cepcjParentContentEntryUid
            _entity.contentEntryParentChildJoin!!.cepcjChildContentEntryUid =
                tmp_cepcjChildContentEntryUid
            _entity.contentEntryParentChildJoin!!.childIndex = tmp_childIndex
            _entity.contentEntryParentChildJoin!!.cepcjUid = tmp_cepcjUid
            _entity.contentEntryParentChildJoin!!.cepcjLocalChangeSeqNum =
                tmp_cepcjLocalChangeSeqNum
            _entity.contentEntryParentChildJoin!!.cepcjMasterChangeSeqNum =
                tmp_cepcjMasterChangeSeqNum
            _entity.contentEntryParentChildJoin!!.cepcjLastChangedBy = tmp_cepcjLastChangedBy
            _entity.contentEntryParentChildJoin!!.cepcjLct = tmp_cepcjLct
          }
          var _scoreProgress_nullFieldCount = 0
          val tmp_resultScore = _resultSet.getInt("resultScore")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_resultMax = _resultSet.getInt("resultMax")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_resultScaled = _resultSet.getFloat("resultScaled")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_resultWeight = _resultSet.getInt("resultWeight")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_progress = _resultSet.getInt("progress")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_success = _resultSet.getByte("success")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_penalty = _resultSet.getInt("penalty")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_totalContent = _resultSet.getInt("totalContent")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
          if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
          if(_scoreProgress_nullFieldCount < 10) {
            if(_entity.scoreProgress == null) {
              _entity.scoreProgress = ContentEntryStatementScoreProgress()
            }
            _entity.scoreProgress!!.resultScore = tmp_resultScore
            _entity.scoreProgress!!.resultMax = tmp_resultMax
            _entity.scoreProgress!!.resultScaled = tmp_resultScaled
            _entity.scoreProgress!!.resultWeight = tmp_resultWeight
            _entity.scoreProgress!!.contentComplete = tmp_contentComplete
            _entity.scoreProgress!!.progress = tmp_progress
            _entity.scoreProgress!!.success = tmp_success
            _entity.scoreProgress!!.penalty = tmp_penalty
            _entity.scoreProgress!!.totalContent = tmp_totalContent
            _entity.scoreProgress!!.totalCompletedContent = tmp_totalCompletedContent
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findAllContentByClazzAssignmentUidDF(clazzAssignmentUid: Long,
      personUid: Long): DoorDataSourceFactory<Int,
      ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer> {
    val _result = object : DoorDataSourceFactory<Int,
        ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>> =
          DoorLiveDataImpl<List<ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>>(_db,
          listOf("ClazzAssignmentContentJoin", "ContentEntry", "ContentEntryParentChildJoin",
          "ClazzAssignmentRollUp", "Container"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
        |                            Container.*, 
        |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
        |                                           
        |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
        |                                                         
        |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
        |                            
        |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
        |                                 
        |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
        |                             
        |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
        |                               
        |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
        |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                        
        |                             0 as assignmentContentWeight,
        |                             1 as totalContent
        |                           
        |                             
        |                      FROM ClazzAssignmentContentJoin
        |                            LEFT JOIN ContentEntry 
        |                            ON ContentEntry.contentEntryUid = cacjContentUid 
        |                            
        |                            LEFT JOIN ContentEntryParentChildJoin 
        |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
        |                           
        |                            LEFT JOIN ClazzAssignmentRollUp
        |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
        |                                AND cachePersonUid = ?
        |                                AND cacheClazzAssignmentUid = ?
        |                                                        
        |                            
        |                            LEFT JOIN Container 
        |                            ON Container.containerUid = 
        |                                (SELECT containerUid 
        |                                   FROM Container 
        |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
        |                               ORDER BY cntLastModified DESC LIMIT 1)
        |                               
        |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
        |                      AND ClazzAssignmentContentJoin.cacjActive
        |                      AND NOT ContentEntry.ceInactive
        |                      AND (ContentEntry.publik OR ? != 0)
        |                      ORDER BY ContentEntry.title ASC , 
        |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
        |                               ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
        |                            Container.*, 
        |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
        |                                           
        |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
        |                                                         
        |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
        |                            
        |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
        |                                 
        |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
        |                             
        |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
        |                               
        |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
        |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                        
        |                             0 as assignmentContentWeight,
        |                             1 as totalContent
        |                           
        |                             
        |                      FROM ClazzAssignmentContentJoin
        |                            LEFT JOIN ContentEntry 
        |                            ON ContentEntry.contentEntryUid = cacjContentUid 
        |                            
        |                            LEFT JOIN ContentEntryParentChildJoin 
        |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
        |                           
        |                            LEFT JOIN ClazzAssignmentRollUp
        |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
        |                                AND cachePersonUid = ?
        |                                AND cacheClazzAssignmentUid = ?
        |                                                        
        |                            
        |                            LEFT JOIN Container 
        |                            ON Container.containerUid = 
        |                                (SELECT containerUid 
        |                                   FROM Container 
        |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
        |                               ORDER BY cntLastModified DESC LIMIT 1)
        |                               
        |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
        |                      AND ClazzAssignmentContentJoin.cacjActive
        |                      AND NOT ContentEntry.ceInactive
        |                      AND (ContentEntry.publik OR ? != 0)
        |                      ORDER BY ContentEntry.title ASC , 
        |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
        |                               ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, clazzAssignmentUid)
          _stmt.setLong(3, clazzAssignmentUid)
          _stmt.setLong(4, personUid)
          _stmt.setInt(5, _limit)
          _stmt.setInt(6, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_assignmentContentWeight = _resultSet.getInt("assignmentContentWeight")
              val tmp_contentEntryUid = _resultSet.getLong("contentEntryUid")
              val tmp_title = _resultSet.getString("title")
              val tmp_description = _resultSet.getString("description")
              val tmp_entryId = _resultSet.getString("entryId")
              val tmp_author = _resultSet.getString("author")
              val tmp_publisher = _resultSet.getString("publisher")
              val tmp_licenseType = _resultSet.getInt("licenseType")
              val tmp_licenseName = _resultSet.getString("licenseName")
              val tmp_licenseUrl = _resultSet.getString("licenseUrl")
              val tmp_sourceUrl = _resultSet.getString("sourceUrl")
              val tmp_thumbnailUrl = _resultSet.getString("thumbnailUrl")
              val tmp_lastModified = _resultSet.getLong("lastModified")
              val tmp_primaryLanguageUid = _resultSet.getLong("primaryLanguageUid")
              val tmp_languageVariantUid = _resultSet.getLong("languageVariantUid")
              val tmp_contentFlags = _resultSet.getInt("contentFlags")
              val tmp_leaf = _resultSet.getBoolean("leaf")
              val tmp_publik = _resultSet.getBoolean("publik")
              val tmp_ceInactive = _resultSet.getBoolean("ceInactive")
              val tmp_completionCriteria = _resultSet.getInt("completionCriteria")
              val tmp_minScore = _resultSet.getInt("minScore")
              val tmp_contentTypeFlag = _resultSet.getInt("contentTypeFlag")
              val tmp_contentOwner = _resultSet.getLong("contentOwner")
              val tmp_contentEntryLocalChangeSeqNum =
                  _resultSet.getLong("contentEntryLocalChangeSeqNum")
              val tmp_contentEntryMasterChangeSeqNum =
                  _resultSet.getLong("contentEntryMasterChangeSeqNum")
              val tmp_contentEntryLastChangedBy = _resultSet.getInt("contentEntryLastChangedBy")
              val tmp_contentEntryLct = _resultSet.getLong("contentEntryLct")
              val _entity = ContentEntryWithParentChildJoinAndStatusAndMostRecentContainer()
              _entity.assignmentContentWeight = tmp_assignmentContentWeight
              _entity.contentEntryUid = tmp_contentEntryUid
              _entity.title = tmp_title
              _entity.description = tmp_description
              _entity.entryId = tmp_entryId
              _entity.author = tmp_author
              _entity.publisher = tmp_publisher
              _entity.licenseType = tmp_licenseType
              _entity.licenseName = tmp_licenseName
              _entity.licenseUrl = tmp_licenseUrl
              _entity.sourceUrl = tmp_sourceUrl
              _entity.thumbnailUrl = tmp_thumbnailUrl
              _entity.lastModified = tmp_lastModified
              _entity.primaryLanguageUid = tmp_primaryLanguageUid
              _entity.languageVariantUid = tmp_languageVariantUid
              _entity.contentFlags = tmp_contentFlags
              _entity.leaf = tmp_leaf
              _entity.publik = tmp_publik
              _entity.ceInactive = tmp_ceInactive
              _entity.completionCriteria = tmp_completionCriteria
              _entity.minScore = tmp_minScore
              _entity.contentTypeFlag = tmp_contentTypeFlag
              _entity.contentOwner = tmp_contentOwner
              _entity.contentEntryLocalChangeSeqNum = tmp_contentEntryLocalChangeSeqNum
              _entity.contentEntryMasterChangeSeqNum = tmp_contentEntryMasterChangeSeqNum
              _entity.contentEntryLastChangedBy = tmp_contentEntryLastChangedBy
              _entity.contentEntryLct = tmp_contentEntryLct
              var _mostRecentContainer_nullFieldCount = 0
              val tmp_containerUid = _resultSet.getLong("containerUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLocalCsn = _resultSet.getLong("cntLocalCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntMasterCsn = _resultSet.getLong("cntMasterCsn")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModBy = _resultSet.getInt("cntLastModBy")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLct = _resultSet.getLong("cntLct")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_fileSize = _resultSet.getLong("fileSize")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_containerContentEntryUid = _resultSet.getLong("containerContentEntryUid")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntLastModified = _resultSet.getLong("cntLastModified")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mimeType = _resultSet.getString("mimeType")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_remarks = _resultSet.getString("remarks")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_mobileOptimized = _resultSet.getBoolean("mobileOptimized")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              val tmp_cntNumEntries = _resultSet.getInt("cntNumEntries")
              if(_resultSet.wasNull()) { _mostRecentContainer_nullFieldCount++ }
              if(_mostRecentContainer_nullFieldCount < 12) {
                if(_entity.mostRecentContainer == null) {
                  _entity.mostRecentContainer = Container()
                }
                _entity.mostRecentContainer!!.containerUid = tmp_containerUid
                _entity.mostRecentContainer!!.cntLocalCsn = tmp_cntLocalCsn
                _entity.mostRecentContainer!!.cntMasterCsn = tmp_cntMasterCsn
                _entity.mostRecentContainer!!.cntLastModBy = tmp_cntLastModBy
                _entity.mostRecentContainer!!.cntLct = tmp_cntLct
                _entity.mostRecentContainer!!.fileSize = tmp_fileSize
                _entity.mostRecentContainer!!.containerContentEntryUid =
                    tmp_containerContentEntryUid
                _entity.mostRecentContainer!!.cntLastModified = tmp_cntLastModified
                _entity.mostRecentContainer!!.mimeType = tmp_mimeType
                _entity.mostRecentContainer!!.remarks = tmp_remarks
                _entity.mostRecentContainer!!.mobileOptimized = tmp_mobileOptimized
                _entity.mostRecentContainer!!.cntNumEntries = tmp_cntNumEntries
              }
              var _contentEntryParentChildJoin_nullFieldCount = 0
              val tmp_cepcjParentContentEntryUid = _resultSet.getLong("cepcjParentContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjChildContentEntryUid = _resultSet.getLong("cepcjChildContentEntryUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_childIndex = _resultSet.getInt("childIndex")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjUid = _resultSet.getLong("cepcjUid")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLocalChangeSeqNum = _resultSet.getLong("cepcjLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjMasterChangeSeqNum = _resultSet.getLong("cepcjMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLastChangedBy = _resultSet.getInt("cepcjLastChangedBy")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              val tmp_cepcjLct = _resultSet.getLong("cepcjLct")
              if(_resultSet.wasNull()) { _contentEntryParentChildJoin_nullFieldCount++ }
              if(_contentEntryParentChildJoin_nullFieldCount < 8) {
                if(_entity.contentEntryParentChildJoin == null) {
                  _entity.contentEntryParentChildJoin = ContentEntryParentChildJoin()
                }
                _entity.contentEntryParentChildJoin!!.cepcjParentContentEntryUid =
                    tmp_cepcjParentContentEntryUid
                _entity.contentEntryParentChildJoin!!.cepcjChildContentEntryUid =
                    tmp_cepcjChildContentEntryUid
                _entity.contentEntryParentChildJoin!!.childIndex = tmp_childIndex
                _entity.contentEntryParentChildJoin!!.cepcjUid = tmp_cepcjUid
                _entity.contentEntryParentChildJoin!!.cepcjLocalChangeSeqNum =
                    tmp_cepcjLocalChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjMasterChangeSeqNum =
                    tmp_cepcjMasterChangeSeqNum
                _entity.contentEntryParentChildJoin!!.cepcjLastChangedBy = tmp_cepcjLastChangedBy
                _entity.contentEntryParentChildJoin!!.cepcjLct = tmp_cepcjLct
              }
              var _scoreProgress_nullFieldCount = 0
              val tmp_resultScore = _resultSet.getInt("resultScore")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultMax = _resultSet.getInt("resultMax")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultScaled = _resultSet.getFloat("resultScaled")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_resultWeight = _resultSet.getInt("resultWeight")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_contentComplete = _resultSet.getBoolean("contentComplete")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_progress = _resultSet.getInt("progress")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_success = _resultSet.getByte("success")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_penalty = _resultSet.getInt("penalty")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalContent = _resultSet.getInt("totalContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              val tmp_totalCompletedContent = _resultSet.getInt("totalCompletedContent")
              if(_resultSet.wasNull()) { _scoreProgress_nullFieldCount++ }
              if(_scoreProgress_nullFieldCount < 10) {
                if(_entity.scoreProgress == null) {
                  _entity.scoreProgress = ContentEntryStatementScoreProgress()
                }
                _entity.scoreProgress!!.resultScore = tmp_resultScore
                _entity.scoreProgress!!.resultMax = tmp_resultMax
                _entity.scoreProgress!!.resultScaled = tmp_resultScaled
                _entity.scoreProgress!!.resultWeight = tmp_resultWeight
                _entity.scoreProgress!!.contentComplete = tmp_contentComplete
                _entity.scoreProgress!!.progress = tmp_progress
                _entity.scoreProgress!!.success = tmp_success
                _entity.scoreProgress!!.penalty = tmp_penalty
                _entity.scoreProgress!!.totalContent = tmp_totalContent
                _entity.scoreProgress!!.totalCompletedContent = tmp_totalCompletedContent
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("ClazzAssignmentContentJoin", "ContentEntry", "ContentEntryParentChildJoin",
          "ClazzAssignmentRollUp", "Container"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
        |                            Container.*, 
        |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
        |                                           
        |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
        |                                                         
        |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
        |                            
        |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
        |                                 
        |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
        |                             
        |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
        |                               
        |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
        |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                        
        |                             0 as assignmentContentWeight,
        |                             1 as totalContent
        |                           
        |                             
        |                      FROM ClazzAssignmentContentJoin
        |                            LEFT JOIN ContentEntry 
        |                            ON ContentEntry.contentEntryUid = cacjContentUid 
        |                            
        |                            LEFT JOIN ContentEntryParentChildJoin 
        |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
        |                           
        |                            LEFT JOIN ClazzAssignmentRollUp
        |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
        |                                AND cachePersonUid = ?
        |                                AND cacheClazzAssignmentUid = ?
        |                                                        
        |                            
        |                            LEFT JOIN Container 
        |                            ON Container.containerUid = 
        |                                (SELECT containerUid 
        |                                   FROM Container 
        |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
        |                               ORDER BY cntLastModified DESC LIMIT 1)
        |                               
        |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
        |                      AND ClazzAssignmentContentJoin.cacjActive
        |                      AND NOT ContentEntry.ceInactive
        |                      AND (ContentEntry.publik OR ? != 0)
        |                      ORDER BY ContentEntry.title ASC , 
        |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
        |                               ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |                    SELECT ContentEntry.*, ContentEntryParentChildJoin.*, 
        |                            Container.*, 
        |                             COALESCE(ClazzAssignmentRollUp.cacheStudentScore,0) AS resultScore,
        |                                           
        |                             COALESCE(ClazzAssignmentRollUp.cacheMaxScore,0) AS resultMax,
        |                                                         
        |                             COALESCE(ClazzAssignmentRollUp.cacheProgress,0) AS progress,                            
        |                            
        |                             COALESCE(ClazzAssignmentRollUp.cacheContentComplete,'FALSE') AS contentComplete,
        |                                 
        |                             COALESCE(ClazzAssignmentRollUp.cacheSuccess,0) AS success,
        |                             
        |                             COALESCE(ClazzAssignmentRollUp.cachePenalty,0) AS penalty,
        |                               
        |                             COALESCE((CASE WHEN ClazzAssignmentRollUp.cacheContentComplete 
        |                                            THEN 1 ELSE 0 END),0) AS totalCompletedContent,
        |                        
        |                             0 as assignmentContentWeight,
        |                             1 as totalContent
        |                           
        |                             
        |                      FROM ClazzAssignmentContentJoin
        |                            LEFT JOIN ContentEntry 
        |                            ON ContentEntry.contentEntryUid = cacjContentUid 
        |                            
        |                            LEFT JOIN ContentEntryParentChildJoin 
        |                            ON ContentEntryParentChildJoin.cepcjChildContentEntryUid = ContentEntry.contentEntryUid 
        |                           
        |                            LEFT JOIN ClazzAssignmentRollUp
        |                            ON cacheContentEntryUid = ClazzAssignmentContentJoin.cacjContentUid
        |                                AND cachePersonUid = ?
        |                                AND cacheClazzAssignmentUid = ?
        |                                                        
        |                            
        |                            LEFT JOIN Container 
        |                            ON Container.containerUid = 
        |                                (SELECT containerUid 
        |                                   FROM Container 
        |                                  WHERE containerContentEntryUid =  ContentEntry.contentEntryUid 
        |                               ORDER BY cntLastModified DESC LIMIT 1)
        |                               
        |                    WHERE ClazzAssignmentContentJoin.cacjAssignmentUid = ?
        |                      AND ClazzAssignmentContentJoin.cacjActive
        |                      AND NOT ContentEntry.ceInactive
        |                      AND (ContentEntry.publik OR ? != 0)
        |                      ORDER BY ContentEntry.title ASC , 
        |                               ContentEntryParentChildJoin.childIndex, ContentEntry.contentEntryUid
        |                               ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, personUid)
          _stmt.setLong(2, clazzAssignmentUid)
          _stmt.setLong(3, clazzAssignmentUid)
          _stmt.setLong(4, personUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun insert(entity: ClazzAssignmentContentJoin): Long {
    val _retVal = _insertAdapterClazzAssignmentContentJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzAssignmentContentJoin): Long {
    val _retVal = _insertAdapterClazzAssignmentContentJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ClazzAssignmentContentJoin>): Unit {
    _insertAdapterClazzAssignmentContentJoin_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzAssignmentContentJoin>): Unit {
    val _sql =
        "UPDATE ClazzAssignmentContentJoin SET cacjContentUid = ?, cacjAssignmentUid = ?, cacjActive = ?, cacjWeight = ?, cacjMCSN = ?, cacjLCSN = ?, cacjLCB = ?, cacjLct = ? WHERE cacjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cacjContentUid)
        _stmt.setLong(2, _entity.cacjAssignmentUid)
        _stmt.setBoolean(3, _entity.cacjActive)
        _stmt.setInt(4, _entity.cacjWeight)
        _stmt.setLong(5, _entity.cacjMCSN)
        _stmt.setLong(6, _entity.cacjLCSN)
        _stmt.setInt(7, _entity.cacjLCB)
        _stmt.setLong(8, _entity.cacjLct)
        _stmt.setLong(9, _entity.cacjUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzAssignmentContentJoin): Unit {
    val _sql =
        "UPDATE ClazzAssignmentContentJoin SET cacjContentUid = ?, cacjAssignmentUid = ?, cacjActive = ?, cacjWeight = ?, cacjMCSN = ?, cacjLCSN = ?, cacjLCB = ?, cacjLct = ? WHERE cacjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cacjContentUid)
      _stmt.setLong(2, entity.cacjAssignmentUid)
      _stmt.setBoolean(3, entity.cacjActive)
      _stmt.setInt(4, entity.cacjWeight)
      _stmt.setLong(5, entity.cacjMCSN)
      _stmt.setLong(6, entity.cacjLCSN)
      _stmt.setInt(7, entity.cacjLCB)
      _stmt.setLong(8, entity.cacjLct)
      _stmt.setLong(9, entity.cacjUid)
      _stmt.executeUpdate()
    }
  }
}
