package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.ContextXObjectStatementJoin
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ContextXObjectStatementJoinDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ContextXObjectStatementJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : ContextXObjectStatementJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun findByStatementAndObjectUid(statementUid: Long, objectUid: Long):
      ContextXObjectStatementJoin? {
    val _result = _dao.findByStatementAndObjectUid(statementUid, objectUid)
    return _result
  }

  public override fun insert(entity: ContextXObjectStatementJoin): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ContextXObjectStatementJoin): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out ContextXObjectStatementJoin>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContextXObjectStatementJoin>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ContextXObjectStatementJoin): Unit {
    _dao.update(entity)
  }
}
