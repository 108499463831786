package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.lib.db.entities.EntityRole
import com.ustadmobile.lib.db.entities.EntityRoleWithNameAndRole
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class EntityRoleDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: EntityRoleDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : EntityRoleDao() {
  public override suspend fun userHasTableLevelPermission(accountPersonUid: Long, permission: Long):
      Boolean {
    val _result = _dao.userHasTableLevelPermission(accountPersonUid, permission)
    return _result
  }

  public override suspend fun findByEntitiyAndPersonGroupAndRole(
    tableId: Int,
    entityUid: Long,
    groupUid: Long,
    roleUid: Long
  ): List<EntityRole> {
    val _result = _dao.findByEntitiyAndPersonGroupAndRole(tableId, entityUid, groupUid, roleUid)
    return _result
  }

  public override fun filterByPersonWithExtra(personGroupUid: Long): DoorDataSourceFactory<Int,
      EntityRoleWithNameAndRole> {
    val _result = _dao.filterByPersonWithExtra(personGroupUid)
    return _result
  }

  public override suspend fun filterByPersonWithExtraAsList(personGroupUid: Long):
      List<EntityRoleWithNameAndRole> {
    val _result = _dao.filterByPersonWithExtraAsList(personGroupUid)
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): EntityRole? {
    val _result = _dao.findByUidAsync(uid)
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<EntityRole?> {
    val _result = _dao.findByUidLive(uid)
    return _result
  }

  public override suspend fun updateAsync(entity: EntityRole): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override suspend fun insertOrReplace(entity: EntityRole): Unit {
    _dao.insertOrReplace(entity)
  }
}
