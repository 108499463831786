package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.DiscussionPost
import com.ustadmobile.lib.db.entities.DiscussionPostWithDetails
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class DiscussionPostDao_JdbcKt(
  public val _db: DoorDatabase
) : DiscussionPostDao() {
  public val _insertAdapterDiscussionPost_: EntityInsertionAdapter<DiscussionPost> = object :
      EntityInsertionAdapter<DiscussionPost>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO DiscussionPost (discussionPostUid, discussionPostTitle, discussionPostMessage, discussionPostStartDate, discussionPostDiscussionTopicUid, discussionPostVisible, discussionPostArchive, discussionPostStartedPersonUid, discussionPostClazzUid, discussionPostLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO DiscussionPost (discussionPostUid, discussionPostTitle, discussionPostMessage, discussionPostStartDate, discussionPostDiscussionTopicUid, discussionPostVisible, discussionPostArchive, discussionPostStartedPersonUid, discussionPostClazzUid, discussionPostLct) VALUES(COALESCE(?,nextval('DiscussionPost_discussionPostUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING discussionPostUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: DiscussionPost):
        Unit {
      if(entity.discussionPostUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.discussionPostUid)
      }
      stmt.setString(2, entity.discussionPostTitle)
      stmt.setString(3, entity.discussionPostMessage)
      stmt.setLong(4, entity.discussionPostStartDate)
      stmt.setLong(5, entity.discussionPostDiscussionTopicUid)
      stmt.setBoolean(6, entity.discussionPostVisible)
      stmt.setBoolean(7, entity.discussionPostArchive)
      stmt.setLong(8, entity.discussionPostStartedPersonUid)
      stmt.setLong(9, entity.discussionPostClazzUid)
      stmt.setLong(10, entity.discussionPostLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO DiscussionPostReplicate(discussionPostPk, discussionPostDestination)
    |      SELECT DISTINCT DiscussionPost.discussionPostUid AS discussionPostPk,
    |             ? AS discussionPostDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |            JOIN DiscussionPost 
    |                 ON DiscussionPost.discussionPostClazzUid = Clazz.clazzUid
    |                 
    |       WHERE DiscussionPost.discussionPostLct != COALESCE(
    |             (SELECT discussionPostVersionId
    |                FROM discussionPostReplicate
    |               WHERE discussionPostPk = DiscussionPost.discussionPostUid
    |                 AND discussionPostDestination = ?), 0) 
    |      /*psql ON CONFLICT(discussionPostPk, discussionPostDestination) DO UPDATE
    |             SET discussionPostPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO DiscussionPostReplicate(discussionPostPk, discussionPostDestination)
    |      SELECT DISTINCT DiscussionPost.discussionPostUid AS discussionPostPk,
    |             ? AS discussionPostDestination
    |             
    |       FROM UserSession
    |             JOIN PersonGroupMember 
    |                  ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                  2 
    |                  
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |                  
    |            JOIN DiscussionPost 
    |                 ON DiscussionPost.discussionPostClazzUid = Clazz.clazzUid
    |                 
    |       WHERE DiscussionPost.discussionPostLct != COALESCE(
    |             (SELECT discussionPostVersionId
    |                FROM discussionPostReplicate
    |               WHERE discussionPostPk = DiscussionPost.discussionPostUid
    |                 AND discussionPostDestination = ?), 0) 
    |       ON CONFLICT(discussionPostPk, discussionPostDestination) DO UPDATE
    |             SET discussionPostPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        REPLACE INTO DiscussionPostReplicate(discussionPostPk, discussionPostDestination)
    |          SELECT DISTINCT DiscussionPost.discussionPostUid AS discussionPostUid,
    |                 UserSession.usClientNodeId AS discussionPostDestination
    |            FROM ChangeLog
    |                 JOIN DiscussionPost
    |                     ON ChangeLog.chTableId = 132
    |                        AND ChangeLog.chEntityPk = DiscussionPost.discussionPostUid
    |                        
    |                        
    |                 JOIN Clazz 
    |                      ON Clazz.clazzUid = DiscussionPost.discussionPostClazzUid
    |                      
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND DiscussionPost.discussionPostLct != COALESCE(
    |                 (SELECT discussionPostVersionId
    |                    FROM discussionPostReplicate
    |                   WHERE discussionPostPk = DiscussionPost.discussionPostUid
    |                     AND DiscussionPostDestination = UserSession.usClientNodeId), 0)
    |         /*psql ON CONFLICT(discussionPostPk, discussionPostDestination) DO UPDATE
    |             SET discussionPostPending = true
    |          */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO DiscussionPostReplicate(discussionPostPk, discussionPostDestination)
    |          SELECT DISTINCT DiscussionPost.discussionPostUid AS discussionPostUid,
    |                 UserSession.usClientNodeId AS discussionPostDestination
    |            FROM ChangeLog
    |                 JOIN DiscussionPost
    |                     ON ChangeLog.chTableId = 132
    |                        AND ChangeLog.chEntityPk = DiscussionPost.discussionPostUid
    |                        
    |                        
    |                 JOIN Clazz 
    |                      ON Clazz.clazzUid = DiscussionPost.discussionPostClazzUid
    |                      
    |                 
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                  2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |                 
    |           WHERE UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |             AND DiscussionPost.discussionPostLct != COALESCE(
    |                 (SELECT discussionPostVersionId
    |                    FROM discussionPostReplicate
    |                   WHERE discussionPostPk = DiscussionPost.discussionPostUid
    |                     AND DiscussionPostDestination = UserSession.usClientNodeId), 0)
    |          ON CONFLICT(discussionPostPk, discussionPostDestination) DO UPDATE
    |             SET discussionPostPending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getPostsByDiscussionTopic(discussionTopicUid: Long):
      DoorDataSourceFactory<Int, DiscussionPostWithDetails> {
    val _result = object : DoorDataSourceFactory<Int, DiscussionPostWithDetails>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<DiscussionPostWithDetails>> =
          DoorLiveDataImpl<List<DiscussionPostWithDetails>>(_db, listOf("Message", "DiscussionPost",
          "Person"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.DiscussionPostWithDetails>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT DiscussionPost.*,
        |            Person.firstNames as authorPersonFirstNames,
        |            Person.lastName as authorPersonLastName,
        |            (
        |                SELECT Message.messageText 
        |                  FROM Message 
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                 ORDER BY messageTimestamp 
        |                  DESC LIMIT 1
        |            ) AS postLatestMessage,
        |            (
        |                SELECT COUNT(*) 
        |                  FROM Message
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                   
        |            ) AS postRepliesCount, 
        |            
        |            (
        |                SELECT Message.messageTimestamp 
        |                  FROM Message 
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                 ORDER BY messageTimestamp 
        |                  DESC LIMIT 1
        |            ) AS postLatestMessageTimestamp
        |             
        |          FROM DiscussionPost     
        |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
        |         WHERE DiscussionPost.discussionPostDiscussionTopicUid = ?
        |           AND CAST(DiscussionPost.discussionPostVisible AS INTEGER) = 1
        |           AND CAST(DiscussionPost.discussionPostArchive AS INTEGER) = 0
        |      ORDER BY DiscussionPost.discussionPostStartDate DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT DiscussionPost.*,
        |            Person.firstNames as authorPersonFirstNames,
        |            Person.lastName as authorPersonLastName,
        |            (
        |                SELECT Message.messageText 
        |                  FROM Message 
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                 ORDER BY messageTimestamp 
        |                  DESC LIMIT 1
        |            ) AS postLatestMessage,
        |            (
        |                SELECT COUNT(*) 
        |                  FROM Message
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                   
        |            ) AS postRepliesCount, 
        |            
        |            (
        |                SELECT Message.messageTimestamp 
        |                  FROM Message 
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                 ORDER BY messageTimestamp 
        |                  DESC LIMIT 1
        |            ) AS postLatestMessageTimestamp
        |             
        |          FROM DiscussionPost     
        |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
        |         WHERE DiscussionPost.discussionPostDiscussionTopicUid = ?
        |           AND CAST(DiscussionPost.discussionPostVisible AS INTEGER) = 1
        |           AND CAST(DiscussionPost.discussionPostArchive AS INTEGER) = 0
        |      ORDER BY DiscussionPost.discussionPostStartDate DESC
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, discussionTopicUid)
          _stmt.setInt(2, _limit)
          _stmt.setInt(3, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_authorPersonFirstNames = _resultSet.getString("authorPersonFirstNames")
              val tmp_authorPersonLastName = _resultSet.getString("authorPersonLastName")
              val tmp_postLatestMessage = _resultSet.getString("postLatestMessage")
              val tmp_postRepliesCount = _resultSet.getInt("postRepliesCount")
              val tmp_postLatestMessageTimestamp = _resultSet.getLong("postLatestMessageTimestamp")
              val tmp_discussionPostUid = _resultSet.getLong("discussionPostUid")
              val tmp_discussionPostTitle = _resultSet.getString("discussionPostTitle")
              val tmp_discussionPostMessage = _resultSet.getString("discussionPostMessage")
              val tmp_discussionPostStartDate = _resultSet.getLong("discussionPostStartDate")
              val tmp_discussionPostDiscussionTopicUid =
                  _resultSet.getLong("discussionPostDiscussionTopicUid")
              val tmp_discussionPostVisible = _resultSet.getBoolean("discussionPostVisible")
              val tmp_discussionPostArchive = _resultSet.getBoolean("discussionPostArchive")
              val tmp_discussionPostStartedPersonUid =
                  _resultSet.getLong("discussionPostStartedPersonUid")
              val tmp_discussionPostClazzUid = _resultSet.getLong("discussionPostClazzUid")
              val tmp_discussionPostLct = _resultSet.getLong("discussionPostLct")
              val _entity = DiscussionPostWithDetails()
              _entity.authorPersonFirstNames = tmp_authorPersonFirstNames
              _entity.authorPersonLastName = tmp_authorPersonLastName
              _entity.postLatestMessage = tmp_postLatestMessage
              _entity.postRepliesCount = tmp_postRepliesCount
              _entity.postLatestMessageTimestamp = tmp_postLatestMessageTimestamp
              _entity.discussionPostUid = tmp_discussionPostUid
              _entity.discussionPostTitle = tmp_discussionPostTitle
              _entity.discussionPostMessage = tmp_discussionPostMessage
              _entity.discussionPostStartDate = tmp_discussionPostStartDate
              _entity.discussionPostDiscussionTopicUid = tmp_discussionPostDiscussionTopicUid
              _entity.discussionPostVisible = tmp_discussionPostVisible
              _entity.discussionPostArchive = tmp_discussionPostArchive
              _entity.discussionPostStartedPersonUid = tmp_discussionPostStartedPersonUid
              _entity.discussionPostClazzUid = tmp_discussionPostClazzUid
              _entity.discussionPostLct = tmp_discussionPostLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Message", "DiscussionPost", "Person"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT DiscussionPost.*,
        |            Person.firstNames as authorPersonFirstNames,
        |            Person.lastName as authorPersonLastName,
        |            (
        |                SELECT Message.messageText 
        |                  FROM Message 
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                 ORDER BY messageTimestamp 
        |                  DESC LIMIT 1
        |            ) AS postLatestMessage,
        |            (
        |                SELECT COUNT(*) 
        |                  FROM Message
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                   
        |            ) AS postRepliesCount, 
        |            
        |            (
        |                SELECT Message.messageTimestamp 
        |                  FROM Message 
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                 ORDER BY messageTimestamp 
        |                  DESC LIMIT 1
        |            ) AS postLatestMessageTimestamp
        |             
        |          FROM DiscussionPost     
        |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
        |         WHERE DiscussionPost.discussionPostDiscussionTopicUid = ?
        |           AND CAST(DiscussionPost.discussionPostVisible AS INTEGER) = 1
        |           AND CAST(DiscussionPost.discussionPostArchive AS INTEGER) = 0
        |      ORDER BY DiscussionPost.discussionPostStartDate DESC
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT DiscussionPost.*,
        |            Person.firstNames as authorPersonFirstNames,
        |            Person.lastName as authorPersonLastName,
        |            (
        |                SELECT Message.messageText 
        |                  FROM Message 
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                 ORDER BY messageTimestamp 
        |                  DESC LIMIT 1
        |            ) AS postLatestMessage,
        |            (
        |                SELECT COUNT(*) 
        |                  FROM Message
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                   
        |            ) AS postRepliesCount, 
        |            
        |            (
        |                SELECT Message.messageTimestamp 
        |                  FROM Message 
        |                 WHERE Message.messageTableId = 132
        |                   AND Message.messageEntityUid = DiscussionPost.discussionPostUid 
        |                 ORDER BY messageTimestamp 
        |                  DESC LIMIT 1
        |            ) AS postLatestMessageTimestamp
        |             
        |          FROM DiscussionPost     
        |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
        |         WHERE DiscussionPost.discussionPostDiscussionTopicUid = ?
        |           AND CAST(DiscussionPost.discussionPostVisible AS INTEGER) = 1
        |           AND CAST(DiscussionPost.discussionPostArchive AS INTEGER) = 0
        |      ORDER BY DiscussionPost.discussionPostStartDate DESC
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setLong(1, discussionTopicUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override suspend fun getPostTitle(postUid: Long): String? {
    var _result = null as kotlin.String??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT DiscussionPost.discussionPostTitle 
    |          FROM DiscussionPost 
    |         WHERE DiscussionPost.discussionPostUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT DiscussionPost.discussionPostTitle 
    |          FROM DiscussionPost 
    |         WHERE DiscussionPost.discussionPostUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, postUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getString(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUid(uid: Long): DiscussionPost? {
    var _result = null as com.ustadmobile.lib.db.entities.DiscussionPost??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |         FROM DiscussionPost
    |        WHERE DiscussionPost.discussionPostUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |         FROM DiscussionPost
    |        WHERE DiscussionPost.discussionPostUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_discussionPostUid = _resultSet.getLong("discussionPostUid")
          val tmp_discussionPostTitle = _resultSet.getString("discussionPostTitle")
          val tmp_discussionPostMessage = _resultSet.getString("discussionPostMessage")
          val tmp_discussionPostStartDate = _resultSet.getLong("discussionPostStartDate")
          val tmp_discussionPostDiscussionTopicUid =
              _resultSet.getLong("discussionPostDiscussionTopicUid")
          val tmp_discussionPostVisible = _resultSet.getBoolean("discussionPostVisible")
          val tmp_discussionPostArchive = _resultSet.getBoolean("discussionPostArchive")
          val tmp_discussionPostStartedPersonUid =
              _resultSet.getLong("discussionPostStartedPersonUid")
          val tmp_discussionPostClazzUid = _resultSet.getLong("discussionPostClazzUid")
          val tmp_discussionPostLct = _resultSet.getLong("discussionPostLct")
          val _entity = DiscussionPost()
          _entity.discussionPostUid = tmp_discussionPostUid
          _entity.discussionPostTitle = tmp_discussionPostTitle
          _entity.discussionPostMessage = tmp_discussionPostMessage
          _entity.discussionPostStartDate = tmp_discussionPostStartDate
          _entity.discussionPostDiscussionTopicUid = tmp_discussionPostDiscussionTopicUid
          _entity.discussionPostVisible = tmp_discussionPostVisible
          _entity.discussionPostArchive = tmp_discussionPostArchive
          _entity.discussionPostStartedPersonUid = tmp_discussionPostStartedPersonUid
          _entity.discussionPostClazzUid = tmp_discussionPostClazzUid
          _entity.discussionPostLct = tmp_discussionPostLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findWithDetailsByUid(uid: Long): DiscussionPostWithDetails? {
    var _result = null as com.ustadmobile.lib.db.entities.DiscussionPostWithDetails??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT DiscussionPost.*,
    |            Person.firstNames as authorPersonFirstNames,
    |            Person.lastName as authorPersonLastName,
    |            '' AS postLatestMessage,
    |            0 AS postRepliesCount, 
    |            DiscussionPost.discussionPostLct AS postLatestMessageTimestamp
    |             
    |          FROM DiscussionPost     
    |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
    |         WHERE DiscussionPost.discussionPostUid = ?
    |           
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT DiscussionPost.*,
    |            Person.firstNames as authorPersonFirstNames,
    |            Person.lastName as authorPersonLastName,
    |            '' AS postLatestMessage,
    |            0 AS postRepliesCount, 
    |            DiscussionPost.discussionPostLct AS postLatestMessageTimestamp
    |             
    |          FROM DiscussionPost     
    |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
    |         WHERE DiscussionPost.discussionPostUid = ?
    |           
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_authorPersonFirstNames = _resultSet.getString("authorPersonFirstNames")
          val tmp_authorPersonLastName = _resultSet.getString("authorPersonLastName")
          val tmp_postLatestMessage = _resultSet.getString("postLatestMessage")
          val tmp_postRepliesCount = _resultSet.getInt("postRepliesCount")
          val tmp_postLatestMessageTimestamp = _resultSet.getLong("postLatestMessageTimestamp")
          val tmp_discussionPostUid = _resultSet.getLong("discussionPostUid")
          val tmp_discussionPostTitle = _resultSet.getString("discussionPostTitle")
          val tmp_discussionPostMessage = _resultSet.getString("discussionPostMessage")
          val tmp_discussionPostStartDate = _resultSet.getLong("discussionPostStartDate")
          val tmp_discussionPostDiscussionTopicUid =
              _resultSet.getLong("discussionPostDiscussionTopicUid")
          val tmp_discussionPostVisible = _resultSet.getBoolean("discussionPostVisible")
          val tmp_discussionPostArchive = _resultSet.getBoolean("discussionPostArchive")
          val tmp_discussionPostStartedPersonUid =
              _resultSet.getLong("discussionPostStartedPersonUid")
          val tmp_discussionPostClazzUid = _resultSet.getLong("discussionPostClazzUid")
          val tmp_discussionPostLct = _resultSet.getLong("discussionPostLct")
          val _entity = DiscussionPostWithDetails()
          _entity.authorPersonFirstNames = tmp_authorPersonFirstNames
          _entity.authorPersonLastName = tmp_authorPersonLastName
          _entity.postLatestMessage = tmp_postLatestMessage
          _entity.postRepliesCount = tmp_postRepliesCount
          _entity.postLatestMessageTimestamp = tmp_postLatestMessageTimestamp
          _entity.discussionPostUid = tmp_discussionPostUid
          _entity.discussionPostTitle = tmp_discussionPostTitle
          _entity.discussionPostMessage = tmp_discussionPostMessage
          _entity.discussionPostStartDate = tmp_discussionPostStartDate
          _entity.discussionPostDiscussionTopicUid = tmp_discussionPostDiscussionTopicUid
          _entity.discussionPostVisible = tmp_discussionPostVisible
          _entity.discussionPostArchive = tmp_discussionPostArchive
          _entity.discussionPostStartedPersonUid = tmp_discussionPostStartedPersonUid
          _entity.discussionPostClazzUid = tmp_discussionPostClazzUid
          _entity.discussionPostLct = tmp_discussionPostLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findWithDetailsByUidLive(uid: Long):
      DoorLiveData<DiscussionPostWithDetails?> {
    val _result = DoorLiveDataImpl<DiscussionPostWithDetails?>(_db, listOf("DiscussionPost",
        "Person"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.DiscussionPostWithDetails??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |        SELECT DiscussionPost.*,
      |            Person.firstNames as authorPersonFirstNames,
      |            Person.lastName as authorPersonLastName,
      |            '' AS postLatestMessage,
      |            0 AS postRepliesCount, 
      |            DiscussionPost.discussionPostLct AS postLatestMessageTimestamp
      |             
      |          FROM DiscussionPost     
      |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
      |         WHERE DiscussionPost.discussionPostUid = ?
      |           
      |    
      """.trimMargin() , postgreSql = """
      |
      |        SELECT DiscussionPost.*,
      |            Person.firstNames as authorPersonFirstNames,
      |            Person.lastName as authorPersonLastName,
      |            '' AS postLatestMessage,
      |            0 AS postRepliesCount, 
      |            DiscussionPost.discussionPostLct AS postLatestMessageTimestamp
      |             
      |          FROM DiscussionPost     
      |          LEFT JOIN Person ON Person.personUid = DiscussionPost.discussionPostStartedPersonUid
      |         WHERE DiscussionPost.discussionPostUid = ?
      |           
      |    
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_authorPersonFirstNames = _resultSet.getString("authorPersonFirstNames")
            val tmp_authorPersonLastName = _resultSet.getString("authorPersonLastName")
            val tmp_postLatestMessage = _resultSet.getString("postLatestMessage")
            val tmp_postRepliesCount = _resultSet.getInt("postRepliesCount")
            val tmp_postLatestMessageTimestamp = _resultSet.getLong("postLatestMessageTimestamp")
            val tmp_discussionPostUid = _resultSet.getLong("discussionPostUid")
            val tmp_discussionPostTitle = _resultSet.getString("discussionPostTitle")
            val tmp_discussionPostMessage = _resultSet.getString("discussionPostMessage")
            val tmp_discussionPostStartDate = _resultSet.getLong("discussionPostStartDate")
            val tmp_discussionPostDiscussionTopicUid =
                _resultSet.getLong("discussionPostDiscussionTopicUid")
            val tmp_discussionPostVisible = _resultSet.getBoolean("discussionPostVisible")
            val tmp_discussionPostArchive = _resultSet.getBoolean("discussionPostArchive")
            val tmp_discussionPostStartedPersonUid =
                _resultSet.getLong("discussionPostStartedPersonUid")
            val tmp_discussionPostClazzUid = _resultSet.getLong("discussionPostClazzUid")
            val tmp_discussionPostLct = _resultSet.getLong("discussionPostLct")
            val _entity = DiscussionPostWithDetails()
            _entity.authorPersonFirstNames = tmp_authorPersonFirstNames
            _entity.authorPersonLastName = tmp_authorPersonLastName
            _entity.postLatestMessage = tmp_postLatestMessage
            _entity.postRepliesCount = tmp_postRepliesCount
            _entity.postLatestMessageTimestamp = tmp_postLatestMessageTimestamp
            _entity.discussionPostUid = tmp_discussionPostUid
            _entity.discussionPostTitle = tmp_discussionPostTitle
            _entity.discussionPostMessage = tmp_discussionPostMessage
            _entity.discussionPostStartDate = tmp_discussionPostStartDate
            _entity.discussionPostDiscussionTopicUid = tmp_discussionPostDiscussionTopicUid
            _entity.discussionPostVisible = tmp_discussionPostVisible
            _entity.discussionPostArchive = tmp_discussionPostArchive
            _entity.discussionPostStartedPersonUid = tmp_discussionPostStartedPersonUid
            _entity.discussionPostClazzUid = tmp_discussionPostClazzUid
            _entity.discussionPostLct = tmp_discussionPostLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateAsync(entity: DiscussionPost): Int {
    var _result = 0
    val _sql =
        "UPDATE DiscussionPost SET discussionPostTitle = ?, discussionPostMessage = ?, discussionPostStartDate = ?, discussionPostDiscussionTopicUid = ?, discussionPostVisible = ?, discussionPostArchive = ?, discussionPostStartedPersonUid = ?, discussionPostClazzUid = ?, discussionPostLct = ? WHERE discussionPostUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.discussionPostTitle)
      _stmt.setString(2, entity.discussionPostMessage)
      _stmt.setLong(3, entity.discussionPostStartDate)
      _stmt.setLong(4, entity.discussionPostDiscussionTopicUid)
      _stmt.setBoolean(5, entity.discussionPostVisible)
      _stmt.setBoolean(6, entity.discussionPostArchive)
      _stmt.setLong(7, entity.discussionPostStartedPersonUid)
      _stmt.setLong(8, entity.discussionPostClazzUid)
      _stmt.setLong(9, entity.discussionPostLct)
      _stmt.setLong(10, entity.discussionPostUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun insert(entity: DiscussionPost): Long {
    val _retVal = _insertAdapterDiscussionPost_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: DiscussionPost): Long {
    val _retVal = _insertAdapterDiscussionPost_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out DiscussionPost>): Unit {
    _insertAdapterDiscussionPost_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out DiscussionPost>): Unit {
    val _sql =
        "UPDATE DiscussionPost SET discussionPostTitle = ?, discussionPostMessage = ?, discussionPostStartDate = ?, discussionPostDiscussionTopicUid = ?, discussionPostVisible = ?, discussionPostArchive = ?, discussionPostStartedPersonUid = ?, discussionPostClazzUid = ?, discussionPostLct = ? WHERE discussionPostUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.discussionPostTitle)
        _stmt.setString(2, _entity.discussionPostMessage)
        _stmt.setLong(3, _entity.discussionPostStartDate)
        _stmt.setLong(4, _entity.discussionPostDiscussionTopicUid)
        _stmt.setBoolean(5, _entity.discussionPostVisible)
        _stmt.setBoolean(6, _entity.discussionPostArchive)
        _stmt.setLong(7, _entity.discussionPostStartedPersonUid)
        _stmt.setLong(8, _entity.discussionPostClazzUid)
        _stmt.setLong(9, _entity.discussionPostLct)
        _stmt.setLong(10, _entity.discussionPostUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: DiscussionPost): Unit {
    val _sql =
        "UPDATE DiscussionPost SET discussionPostTitle = ?, discussionPostMessage = ?, discussionPostStartDate = ?, discussionPostDiscussionTopicUid = ?, discussionPostVisible = ?, discussionPostArchive = ?, discussionPostStartedPersonUid = ?, discussionPostClazzUid = ?, discussionPostLct = ? WHERE discussionPostUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.discussionPostTitle)
      _stmt.setString(2, entity.discussionPostMessage)
      _stmt.setLong(3, entity.discussionPostStartDate)
      _stmt.setLong(4, entity.discussionPostDiscussionTopicUid)
      _stmt.setBoolean(5, entity.discussionPostVisible)
      _stmt.setBoolean(6, entity.discussionPostArchive)
      _stmt.setLong(7, entity.discussionPostStartedPersonUid)
      _stmt.setLong(8, entity.discussionPostClazzUid)
      _stmt.setLong(9, entity.discussionPostLct)
      _stmt.setLong(10, entity.discussionPostUid)
      _stmt.executeUpdate()
    }
  }
}
