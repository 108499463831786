package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ContentEntryPicture
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ContentEntryPictureDao_JdbcKt(
  public val _db: DoorDatabase
) : ContentEntryPictureDao() {
  public val _insertAdapterContentEntryPicture_: EntityInsertionAdapter<ContentEntryPicture> =
      object : EntityInsertionAdapter<ContentEntryPicture>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ContentEntryPicture (cepUid, cepContentEntryUid, cepUri, cepMd5, cepFileSize, cepTimestamp, cepMimeType, cepActive) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ContentEntryPicture (cepUid, cepContentEntryUid, cepUri, cepMd5, cepFileSize, cepTimestamp, cepMimeType, cepActive) VALUES(COALESCE(?,nextval('ContentEntryPicture_cepUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING cepUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: ContentEntryPicture): Unit {
      if(entity.cepUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.cepUid)
      }
      stmt.setLong(2, entity.cepContentEntryUid)
      stmt.setString(3, entity.cepUri)
      stmt.setString(4, entity.cepMd5)
      stmt.setInt(5, entity.cepFileSize)
      stmt.setLong(6, entity.cepTimestamp)
      stmt.setString(7, entity.cepMimeType)
      stmt.setBoolean(8, entity.cepActive)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ContentEntryPictureReplicate(cepPk, cepDestination)
    |         SELECT DISTINCT cepUid AS cepPK,
    |                ? AS siteDestination
    |           FROM ContentEntryPicture
    |          WHERE ContentEntryPicture.cepTimestamp != COALESCE(
    |                (SELECT cepVersionId
    |                   FROM ContentEntryPictureReplicate
    |                  WHERE cepPk = ContentEntryPicture.cepUid
    |                    AND cepDestination = ?), -1) 
    |         /*psql ON CONFLICT(cepPk, cepDestination) DO UPDATE
    |                SET cepPending = true
    |         */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryPictureReplicate(cepPk, cepDestination)
    |         SELECT DISTINCT cepUid AS cepPK,
    |                ? AS siteDestination
    |           FROM ContentEntryPicture
    |          WHERE ContentEntryPicture.cepTimestamp != COALESCE(
    |                (SELECT cepVersionId
    |                   FROM ContentEntryPictureReplicate
    |                  WHERE cepPk = ContentEntryPicture.cepUid
    |                    AND cepDestination = ?), -1) 
    |          ON CONFLICT(cepPk, cepDestination) DO UPDATE
    |                SET cepPending = true
    |                
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |  REPLACE INTO ContentEntryPictureReplicate(cepPk, cepDestination)
    |         SELECT DISTINCT ContentEntryPicture.cepUid AS cepPk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN ContentEntryPicture
    |                    ON ChangeLog.chTableId = 138
    |                       AND ChangeLog.chEntityPk = ContentEntryPicture.cepUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND ContentEntryPicture.cepTimestamp != COALESCE(
    |                (SELECT cepVersionId
    |                   FROM ContentEntryPictureReplicate
    |                  WHERE cepPk = ContentEntryPicture.cepUid
    |                    AND cepDestination = UserSession.usClientNodeId), 0)     
    |        /*psql ON CONFLICT(cepPk, cepDestination) DO UPDATE
    |            SET cepPending = true
    |         */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ContentEntryPictureReplicate(cepPk, cepDestination)
    |         SELECT DISTINCT ContentEntryPicture.cepUid AS cepPk,
    |                UserSession.usClientNodeId AS siteDestination
    |           FROM ChangeLog
    |                JOIN ContentEntryPicture
    |                    ON ChangeLog.chTableId = 138
    |                       AND ChangeLog.chEntityPk = ContentEntryPicture.cepUid
    |                JOIN UserSession ON UserSession.usStatus = 1
    |          WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |            AND ContentEntryPicture.cepTimestamp != COALESCE(
    |                (SELECT cepVersionId
    |                   FROM ContentEntryPictureReplicate
    |                  WHERE cepPk = ContentEntryPicture.cepUid
    |                    AND cepDestination = UserSession.usClientNodeId), 0)     
    |         ON CONFLICT(cepPk, cepDestination) DO UPDATE
    |            SET cepPending = true
    |                        
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun findByContentEntryUidAsync(entryUid: Long): ContentEntryPicture? {
    var _result = null as com.ustadmobile.lib.db.entities.ContentEntryPicture??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |          FROM ContentEntryPicture 
    |         WHERE cepContentEntryUid = ?
    |           AND cepActive
    |      ORDER BY cepTimestamp DESC 
    |         LIMIT 1
    |         
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |          FROM ContentEntryPicture 
    |         WHERE cepContentEntryUid = ?
    |           AND cepActive
    |      ORDER BY cepTimestamp DESC 
    |         LIMIT 1
    |         
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, entryUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_cepUid = _resultSet.getLong("cepUid")
          val tmp_cepContentEntryUid = _resultSet.getLong("cepContentEntryUid")
          val tmp_cepUri = _resultSet.getString("cepUri")
          val tmp_cepMd5 = _resultSet.getString("cepMd5")
          val tmp_cepFileSize = _resultSet.getInt("cepFileSize")
          val tmp_cepTimestamp = _resultSet.getLong("cepTimestamp")
          val tmp_cepMimeType = _resultSet.getString("cepMimeType")
          val tmp_cepActive = _resultSet.getBoolean("cepActive")
          val _entity = ContentEntryPicture()
          _entity.cepUid = tmp_cepUid
          _entity.cepContentEntryUid = tmp_cepContentEntryUid
          _entity.cepUri = tmp_cepUri
          _entity.cepMd5 = tmp_cepMd5
          _entity.cepFileSize = tmp_cepFileSize
          _entity.cepTimestamp = tmp_cepTimestamp
          _entity.cepMimeType = tmp_cepMimeType
          _entity.cepActive = tmp_cepActive
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByContentEntryUidLive(entryUid: Long):
      DoorLiveData<ContentEntryPicture?> {
    val _result = DoorLiveDataImpl<ContentEntryPicture?>(_db, listOf("ContentEntryPicture"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.ContentEntryPicture??
      val _stmtConfig = PreparedStatementConfig("""
      |
      |         SELECT * 
      |          FROM ContentEntryPicture 
      |         WHERE cepContentEntryUid = ?
      |           AND cepActive
      |      ORDER BY cepTimestamp DESC 
      |         LIMIT 1
      |         
      """.trimMargin() , postgreSql = """
      |
      |         SELECT * 
      |          FROM ContentEntryPicture 
      |         WHERE cepContentEntryUid = ?
      |           AND cepActive
      |      ORDER BY cepTimestamp DESC 
      |         LIMIT 1
      |         
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, entryUid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_cepUid = _resultSet.getLong("cepUid")
            val tmp_cepContentEntryUid = _resultSet.getLong("cepContentEntryUid")
            val tmp_cepUri = _resultSet.getString("cepUri")
            val tmp_cepMd5 = _resultSet.getString("cepMd5")
            val tmp_cepFileSize = _resultSet.getInt("cepFileSize")
            val tmp_cepTimestamp = _resultSet.getLong("cepTimestamp")
            val tmp_cepMimeType = _resultSet.getString("cepMimeType")
            val tmp_cepActive = _resultSet.getBoolean("cepActive")
            val _entity = ContentEntryPicture()
            _entity.cepUid = tmp_cepUid
            _entity.cepContentEntryUid = tmp_cepContentEntryUid
            _entity.cepUri = tmp_cepUri
            _entity.cepMd5 = tmp_cepMd5
            _entity.cepFileSize = tmp_cepFileSize
            _entity.cepTimestamp = tmp_cepTimestamp
            _entity.cepMimeType = tmp_cepMimeType
            _entity.cepActive = tmp_cepActive
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateAsync(ContentEntryPicture: ContentEntryPicture): Unit {
    val _sql =
        "UPDATE ContentEntryPicture SET cepContentEntryUid = ?, cepUri = ?, cepMd5 = ?, cepFileSize = ?, cepTimestamp = ?, cepMimeType = ?, cepActive = ? WHERE cepUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, ContentEntryPicture.cepContentEntryUid)
      _stmt.setString(2, ContentEntryPicture.cepUri)
      _stmt.setString(3, ContentEntryPicture.cepMd5)
      _stmt.setInt(4, ContentEntryPicture.cepFileSize)
      _stmt.setLong(5, ContentEntryPicture.cepTimestamp)
      _stmt.setString(6, ContentEntryPicture.cepMimeType)
      _stmt.setBoolean(7, ContentEntryPicture.cepActive)
      _stmt.setLong(8, ContentEntryPicture.cepUid)
      _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: ContentEntryPicture): Long {
    val _retVal = _insertAdapterContentEntryPicture_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ContentEntryPicture): Long {
    val _retVal = _insertAdapterContentEntryPicture_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ContentEntryPicture>): Unit {
    _insertAdapterContentEntryPicture_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ContentEntryPicture>): Unit {
    val _sql =
        "UPDATE ContentEntryPicture SET cepContentEntryUid = ?, cepUri = ?, cepMd5 = ?, cepFileSize = ?, cepTimestamp = ?, cepMimeType = ?, cepActive = ? WHERE cepUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.cepContentEntryUid)
        _stmt.setString(2, _entity.cepUri)
        _stmt.setString(3, _entity.cepMd5)
        _stmt.setInt(4, _entity.cepFileSize)
        _stmt.setLong(5, _entity.cepTimestamp)
        _stmt.setString(6, _entity.cepMimeType)
        _stmt.setBoolean(7, _entity.cepActive)
        _stmt.setLong(8, _entity.cepUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ContentEntryPicture): Unit {
    val _sql =
        "UPDATE ContentEntryPicture SET cepContentEntryUid = ?, cepUri = ?, cepMd5 = ?, cepFileSize = ?, cepTimestamp = ?, cepMimeType = ?, cepActive = ? WHERE cepUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.cepContentEntryUid)
      _stmt.setString(2, entity.cepUri)
      _stmt.setString(3, entity.cepMd5)
      _stmt.setInt(4, entity.cepFileSize)
      _stmt.setLong(5, entity.cepTimestamp)
      _stmt.setString(6, entity.cepMimeType)
      _stmt.setBoolean(7, entity.cepActive)
      _stmt.setLong(8, entity.cepUid)
      _stmt.executeUpdate()
    }
  }
}
