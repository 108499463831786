package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.MessageRead
import io.ktor.client.HttpClient
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class MessageReadDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: MessageReadDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : MessageReadDao() {
  public override suspend fun replicateOnNewNodeChats(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeChats(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override fun insert(entity: MessageRead): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: MessageRead): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out MessageRead>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out MessageRead>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: MessageRead): Unit {
    _dao.update(entity)
  }
}
