package com.ustadmobile.core.db

import com.ustadmobile.door.ext.DoorDatabaseMetadata
import com.ustadmobile.door.replication.ReplicationEntityMetaData
import com.ustadmobile.door.replication.ReplicationFieldMetaData
import kotlin.Boolean
import kotlin.Int
import kotlin.LazyThreadSafetyMode
import kotlin.String
import kotlin.collections.List
import kotlin.collections.Map
import kotlin.reflect.KClass

public class UmAppDatabase_DoorMetadata : DoorDatabaseMetadata<UmAppDatabase>() {
  public override val dbClass: KClass<UmAppDatabase>
    get() = UmAppDatabase::class

  public override val hasReadOnlyWrapper: Boolean
    get() = true

  public override val hasAttachments: Boolean
    get() = true

  public override val syncableTableIdMap: Map<String, Int>
    get() = TABLE_ID_MAP

  public override val version: Int
    get() = 106

  public override val allTables: List<String> = listOf("NetworkNode", "ClazzLog",
      "ClazzLogAttendanceRecord", "Schedule", "DateRange", "HolidayCalendar", "Holiday",
      "ScheduledCheck", "AuditLog", "CustomField", "CustomFieldValue", "CustomFieldValueOption",
      "Person", "Clazz", "ClazzEnrolment", "LeavingReason", "ContentEntry",
      "ContentEntryContentCategoryJoin", "ContentEntryParentChildJoin",
      "ContentEntryRelatedEntryJoin", "ContentCategorySchema", "ContentCategory", "Language",
      "LanguageVariant", "AccessToken", "PersonAuth", "Role", "EntityRole", "PersonGroup",
      "PersonGroupMember", "PersonPicture", "ScrapeQueueItem", "ScrapeRun", "ConnectivityStatus",
      "Container", "ContainerEntry", "ContainerEntryFile", "VerbEntity", "XObjectEntity",
      "StatementEntity", "ContextXObjectStatementJoin", "AgentEntity", "StateEntity",
      "StateContentEntity", "XLangMapEntry", "SyncNode", "LocallyAvailableContainer",
      "ContainerETag", "School", "SchoolMember", "Comments", "Report", "Site", "ContainerImportJob",
      "LearnerGroup", "LearnerGroupMember", "GroupLearningSession", "SiteTerms", "ClazzContentJoin",
      "PersonParentJoin", "ScopedGrant", "ErrorReport", "ClazzAssignment",
      "ClazzAssignmentContentJoin", "CourseAssignmentSubmission",
      "CourseAssignmentSubmissionAttachment", "CourseAssignmentMark", "ClazzAssignmentRollUp",
      "PersonAuth2", "UserSession", "ContentJob", "ContentJobItem", "CourseBlock",
      "CourseTerminology", "CourseGroupSet", "CourseGroupMember", "SqliteChangeSeqNums",
      "UpdateNotification", "ChangeLog", "ZombieAttachmentData", "DoorNode", "ReplicationStatus",
      "ClazzLogReplicate", "ClazzLogAttendanceRecordReplicate",
      "CourseAssignmentSubmissionReplicate", "CourseAssignmentSubmissionAttachmentReplicate",
      "CourseAssignmentMarkReplicate", "CourseBlockReplicate", "CourseTerminologyReplicate",
      "CourseGroupSetReplicate", "CourseGroupMemberReplicate", "ScheduleReplicate",
      "HolidayCalendarReplicate", "HolidayReplicate", "PersonReplicate", "ClazzReplicate",
      "ClazzEnrolmentReplicate", "LeavingReasonReplicate", "ContentEntryReplicate",
      "ContentEntryContentCategoryJoinReplicate", "ContentEntryParentChildJoinReplicate",
      "ContentEntryRelatedEntryJoinReplicate", "ContentCategorySchemaReplicate",
      "ContentCategoryReplicate", "LanguageReplicate", "LanguageVariantReplicate",
      "PersonGroupReplicate", "PersonGroupMemberReplicate", "PersonPictureReplicate",
      "ContainerReplicate", "VerbEntityReplicate", "XObjectEntityReplicate",
      "StatementEntityReplicate", "ContextXObjectStatementJoinReplicate", "AgentEntityReplicate",
      "StateEntityReplicate", "StateContentEntityReplicate", "XLangMapEntryReplicate",
      "SchoolReplicate", "SchoolMemberReplicate", "CommentsReplicate", "ReportReplicate",
      "SiteReplicate", "LearnerGroupReplicate", "LearnerGroupMemberReplicate",
      "GroupLearningSessionReplicate", "SiteTermsReplicate", "ClazzContentJoinReplicate",
      "PersonParentJoinReplicate", "ScopedGrantReplicate", "ErrorReportReplicate",
      "ClazzAssignmentReplicate", "ClazzAssignmentContentJoinReplicate", "PersonAuth2Replicate",
      "UserSessionReplicate", "CoursePicture", "CoursePictureReplicate", "ContentEntryPicture",
      "ContentEntryPictureReplicate", "Chat", "ChatMember", "Message", "MessageReplicate",
      "ChatReplicate", "ChatMemberReplicate", "MessageRead", "MessageReadReplicate",
      "CourseDiscussion", "CourseDiscussionReplicate", "DiscussionTopic",
      "DiscussionTopicReplicate", "DiscussionPost", "DiscussionPostReplicate")


  public override val replicateEntities: Map<Int, ReplicationEntityMetaData> by
      lazy(LazyThreadSafetyMode.NONE) {
        mapOf<Int, ReplicationEntityMetaData>(
        14 to ReplicationEntityMetaData(14, 100, "ClazzLog", "ClazzLogReplicate",
            "ClazzLog_ReceiveView", "clazzLogUid", "clazzLogLastChangedTime", "clPk",
            "clDestination", "clVersionId", "clPending", 
        listOf(ReplicationFieldMetaData("clazzLogUid", -5),ReplicationFieldMetaData("clazzLogClazzUid", -5),ReplicationFieldMetaData("logDate", -5),ReplicationFieldMetaData("timeRecorded", -5),ReplicationFieldMetaData("clazzLogDone",
            16),ReplicationFieldMetaData("cancellationNote", -1),ReplicationFieldMetaData("clazzLogCancelled",
            16),ReplicationFieldMetaData("clazzLogNumPresent",
            4),ReplicationFieldMetaData("clazzLogNumAbsent",
            4),ReplicationFieldMetaData("clazzLogNumPartial",
            4),ReplicationFieldMetaData("clazzLogScheduleUid", -5),ReplicationFieldMetaData("clazzLogStatusFlag",
            4),ReplicationFieldMetaData("clazzLogMSQN", -5),ReplicationFieldMetaData("clazzLogLCSN", -5),ReplicationFieldMetaData("clazzLogLCB",
            4),ReplicationFieldMetaData("clazzLogLastChangedTime", -5),),
        listOf(ReplicationFieldMetaData("clPk", -5),ReplicationFieldMetaData("clVersionId", -5),ReplicationFieldMetaData("clDestination", -5),ReplicationFieldMetaData("clPending",
            16),),
        null,
        null,
        null,
        1000),
        15 to ReplicationEntityMetaData(15, 100, "ClazzLogAttendanceRecord",
            "ClazzLogAttendanceRecordReplicate", "ClazzLogAttendanceRecord_ReceiveView",
            "clazzLogAttendanceRecordUid", "clazzLogAttendanceRecordLastChangedTime", "clarPk",
            "clarDestination", "clarVersionId", "clarPending", 
        listOf(ReplicationFieldMetaData("clazzLogAttendanceRecordUid", -5),ReplicationFieldMetaData("clazzLogAttendanceRecordClazzLogUid", -5),ReplicationFieldMetaData("clazzLogAttendanceRecordPersonUid", -5),ReplicationFieldMetaData("attendanceStatus",
            4),ReplicationFieldMetaData("clazzLogAttendanceRecordMasterChangeSeqNum", -5),ReplicationFieldMetaData("clazzLogAttendanceRecordLocalChangeSeqNum", -5),ReplicationFieldMetaData("clazzLogAttendanceRecordLastChangedBy",
            4),ReplicationFieldMetaData("clazzLogAttendanceRecordLastChangedTime", -5),),
        listOf(ReplicationFieldMetaData("clarPk", -5),ReplicationFieldMetaData("clarVersionId", -5),ReplicationFieldMetaData("clarDestination", -5),ReplicationFieldMetaData("clarPending",
            16),),
        null,
        null,
        null,
        1000),
        21 to ReplicationEntityMetaData(21, 100, "Schedule", "ScheduleReplicate",
            "Schedule_ReceiveView", "scheduleUid", "scheduleLastChangedTime", "schedulePk",
            "scheduleDestination", "scheduleVersionId", "schedulePending", 
        listOf(ReplicationFieldMetaData("scheduleUid", -5),ReplicationFieldMetaData("sceduleStartTime", -5),ReplicationFieldMetaData("scheduleEndTime", -5),ReplicationFieldMetaData("scheduleDay",
            4),ReplicationFieldMetaData("scheduleMonth",
            4),ReplicationFieldMetaData("scheduleFrequency",
            4),ReplicationFieldMetaData("umCalendarUid", -5),ReplicationFieldMetaData("scheduleClazzUid", -5),ReplicationFieldMetaData("scheduleMasterChangeSeqNum", -5),ReplicationFieldMetaData("scheduleLocalChangeSeqNum", -5),ReplicationFieldMetaData("scheduleLastChangedBy",
            4),ReplicationFieldMetaData("scheduleLastChangedTime", -5),ReplicationFieldMetaData("scheduleActive",
            16),),
        listOf(ReplicationFieldMetaData("schedulePk", -5),ReplicationFieldMetaData("scheduleVersionId", -5),ReplicationFieldMetaData("scheduleDestination", -5),ReplicationFieldMetaData("schedulePending",
            16),),
        null,
        null,
        null,
        1000),
        28 to ReplicationEntityMetaData(28, 100, "HolidayCalendar", "HolidayCalendarReplicate",
            "HolidayCalendar_ReceiveView", "umCalendarUid", "umCalendarLct", "hcPk",
            "hcDestination", "hcVersionId", "hcPending", 
        listOf(ReplicationFieldMetaData("umCalendarUid", -5),ReplicationFieldMetaData("umCalendarName", -1),ReplicationFieldMetaData("umCalendarCategory",
            4),ReplicationFieldMetaData("umCalendarActive",
            16),ReplicationFieldMetaData("umCalendarMasterChangeSeqNum", -5),ReplicationFieldMetaData("umCalendarLocalChangeSeqNum", -5),ReplicationFieldMetaData("umCalendarLastChangedBy",
            4),ReplicationFieldMetaData("umCalendarLct", -5),),
        listOf(ReplicationFieldMetaData("hcPk", -5),ReplicationFieldMetaData("hcVersionId", -5),ReplicationFieldMetaData("hcDestination", -5),ReplicationFieldMetaData("hcPending",
            16),),
        null,
        null,
        null,
        1000),
        99 to ReplicationEntityMetaData(99, 100, "Holiday", "HolidayReplicate",
            "Holiday_ReceiveView", "holUid", "holLct", "holidayPk", "holidayDestination",
            "holidayVersionId", "holidayPending", 
        listOf(ReplicationFieldMetaData("holUid", -5),ReplicationFieldMetaData("holMasterCsn", -5),ReplicationFieldMetaData("holLocalCsn", -5),ReplicationFieldMetaData("holLastModBy",
            4),ReplicationFieldMetaData("holLct", -5),ReplicationFieldMetaData("holActive",
            16),ReplicationFieldMetaData("holHolidayCalendarUid", -5),ReplicationFieldMetaData("holStartTime", -5),ReplicationFieldMetaData("holEndTime", -5),ReplicationFieldMetaData("holName", -1),),
        listOf(ReplicationFieldMetaData("holidayPk", -5),ReplicationFieldMetaData("holidayVersionId", -5),ReplicationFieldMetaData("holidayDestination", -5),ReplicationFieldMetaData("holidayPending",
            16),),
        null,
        null,
        null,
        1000),
        9 to ReplicationEntityMetaData(9, 100, "Person", "PersonReplicate", "Person_ReceiveView",
            "personUid", "personLct", "personPk", "personDestination", "personVersionId",
            "personPending", 
        listOf(ReplicationFieldMetaData("personUid", -5),ReplicationFieldMetaData("username", -1),ReplicationFieldMetaData("firstNames", -1),ReplicationFieldMetaData("lastName", -1),ReplicationFieldMetaData("emailAddr", -1),ReplicationFieldMetaData("phoneNum", -1),ReplicationFieldMetaData("gender",
            4),ReplicationFieldMetaData("active", 16),ReplicationFieldMetaData("admin",
            16),ReplicationFieldMetaData("personNotes", -1),ReplicationFieldMetaData("fatherName", -1),ReplicationFieldMetaData("fatherNumber", -1),ReplicationFieldMetaData("motherName", -1),ReplicationFieldMetaData("motherNum", -1),ReplicationFieldMetaData("dateOfBirth", -5),ReplicationFieldMetaData("personAddress", -1),ReplicationFieldMetaData("personOrgId", -1),ReplicationFieldMetaData("personGroupUid", -5),ReplicationFieldMetaData("personMasterChangeSeqNum", -5),ReplicationFieldMetaData("personLocalChangeSeqNum", -5),ReplicationFieldMetaData("personLastChangedBy",
            4),ReplicationFieldMetaData("personLct", -5),ReplicationFieldMetaData("personCountry", -1),ReplicationFieldMetaData("personType",
            4),),
        listOf(ReplicationFieldMetaData("personPk", -5),ReplicationFieldMetaData("personVersionId", -5),ReplicationFieldMetaData("personDestination", -5),ReplicationFieldMetaData("personPending",
            16),),
        null,
        null,
        null,
        1000),
        6 to ReplicationEntityMetaData(6, 0, "Clazz", "ClazzReplicate", "Clazz_ReceiveView",
            "clazzUid", "clazzLct", "clazzPk", "clazzDestination", "clazzVersionId", "clazzPending",
            
        listOf(ReplicationFieldMetaData("clazzUid", -5),ReplicationFieldMetaData("clazzName", -1),ReplicationFieldMetaData("clazzDesc", -1),ReplicationFieldMetaData("attendanceAverage",
            6),ReplicationFieldMetaData("clazzHolidayUMCalendarUid", -5),ReplicationFieldMetaData("clazzScheuleUMCalendarUid", -5),ReplicationFieldMetaData("isClazzActive",
            16),ReplicationFieldMetaData("clazzLocationUid", -5),ReplicationFieldMetaData("clazzStartTime", -5),ReplicationFieldMetaData("clazzEndTime", -5),ReplicationFieldMetaData("clazzFeatures", -5),ReplicationFieldMetaData("clazzSchoolUid", -5),ReplicationFieldMetaData("clazzEnrolmentPolicy",
            4),ReplicationFieldMetaData("clazzTerminologyUid", -5),ReplicationFieldMetaData("clazzMasterChangeSeqNum", -5),ReplicationFieldMetaData("clazzLocalChangeSeqNum", -5),ReplicationFieldMetaData("clazzLastChangedBy",
            4),ReplicationFieldMetaData("clazzLct", -5),ReplicationFieldMetaData("clazzTimeZone", -1),ReplicationFieldMetaData("clazzStudentsPersonGroupUid", -5),ReplicationFieldMetaData("clazzTeachersPersonGroupUid", -5),ReplicationFieldMetaData("clazzPendingStudentsPersonGroupUid", -5),ReplicationFieldMetaData("clazzParentsPersonGroupUid", -5),ReplicationFieldMetaData("clazzCode", -1),),
        listOf(ReplicationFieldMetaData("clazzPk", -5),ReplicationFieldMetaData("clazzVersionId", -5),ReplicationFieldMetaData("clazzDestination", -5),ReplicationFieldMetaData("clazzPending",
            16),),
        null,
        null,
        null,
        1000),
        65 to ReplicationEntityMetaData(65, 1, "ClazzEnrolment", "ClazzEnrolmentReplicate",
            "ClazzEnrolment_ReceiveView", "clazzEnrolmentUid", "clazzEnrolmentLct", "cePk",
            "ceDestination", "ceVersionId", "cePending", 
        listOf(ReplicationFieldMetaData("clazzEnrolmentUid", -5),ReplicationFieldMetaData("clazzEnrolmentPersonUid", -5),ReplicationFieldMetaData("clazzEnrolmentClazzUid", -5),ReplicationFieldMetaData("clazzEnrolmentDateJoined", -5),ReplicationFieldMetaData("clazzEnrolmentDateLeft", -5),ReplicationFieldMetaData("clazzEnrolmentRole",
            4),ReplicationFieldMetaData("clazzEnrolmentAttendancePercentage",
            6),ReplicationFieldMetaData("clazzEnrolmentActive",
            16),ReplicationFieldMetaData("clazzEnrolmentLeavingReasonUid", -5),ReplicationFieldMetaData("clazzEnrolmentOutcome",
            4),ReplicationFieldMetaData("clazzEnrolmentLocalChangeSeqNum", -5),ReplicationFieldMetaData("clazzEnrolmentMasterChangeSeqNum", -5),ReplicationFieldMetaData("clazzEnrolmentLastChangedBy",
            4),ReplicationFieldMetaData("clazzEnrolmentLct", -5),),
        listOf(ReplicationFieldMetaData("cePk", -5),ReplicationFieldMetaData("ceVersionId", -5),ReplicationFieldMetaData("ceDestination", -5),ReplicationFieldMetaData("cePending",
            16),),
        null,
        null,
        null,
        1000),
        410 to ReplicationEntityMetaData(410, 100, "LeavingReason", "LeavingReasonReplicate",
            "LeavingReason_ReceiveView", "leavingReasonUid", "leavingReasonLct", "lrPk",
            "lrDestination", "lrVersionId", "lrPending", 
        listOf(ReplicationFieldMetaData("leavingReasonUid", -5),ReplicationFieldMetaData("leavingReasonTitle", -1),ReplicationFieldMetaData("leavingReasonMCSN", -5),ReplicationFieldMetaData("leavingReasonCSN", -5),ReplicationFieldMetaData("leavingReasonLCB",
            4),ReplicationFieldMetaData("leavingReasonLct", -5),),
        listOf(ReplicationFieldMetaData("lrPk", -5),ReplicationFieldMetaData("lrVersionId", -5),ReplicationFieldMetaData("lrDestination", -5),ReplicationFieldMetaData("lrPending",
            16),),
        null,
        null,
        null,
        1000),
        42 to ReplicationEntityMetaData(42, 100, "ContentEntry", "ContentEntryReplicate",
            "ContentEntry_ReceiveView", "contentEntryUid", "contentEntryLct", "cePk",
            "ceDestination", "ceVersionId", "cePending", 
        listOf(ReplicationFieldMetaData("contentEntryUid", -5),ReplicationFieldMetaData("title", -1),ReplicationFieldMetaData("description", -1),ReplicationFieldMetaData("entryId", -1),ReplicationFieldMetaData("author", -1),ReplicationFieldMetaData("publisher", -1),ReplicationFieldMetaData("licenseType",
            4),ReplicationFieldMetaData("licenseName", -1),ReplicationFieldMetaData("licenseUrl", -1),ReplicationFieldMetaData("sourceUrl", -1),ReplicationFieldMetaData("thumbnailUrl", -1),ReplicationFieldMetaData("lastModified", -5),ReplicationFieldMetaData("primaryLanguageUid", -5),ReplicationFieldMetaData("languageVariantUid", -5),ReplicationFieldMetaData("contentFlags",
            4),ReplicationFieldMetaData("leaf", 16),ReplicationFieldMetaData("publik",
            16),ReplicationFieldMetaData("ceInactive",
            16),ReplicationFieldMetaData("completionCriteria",
            4),ReplicationFieldMetaData("minScore", 4),ReplicationFieldMetaData("contentTypeFlag",
            4),ReplicationFieldMetaData("contentOwner", -5),ReplicationFieldMetaData("contentEntryLocalChangeSeqNum", -5),ReplicationFieldMetaData("contentEntryMasterChangeSeqNum", -5),ReplicationFieldMetaData("contentEntryLastChangedBy",
            4),ReplicationFieldMetaData("contentEntryLct", -5),),
        listOf(ReplicationFieldMetaData("cePk", -5),ReplicationFieldMetaData("ceVersionId", -5),ReplicationFieldMetaData("ceDestination", -5),ReplicationFieldMetaData("cePending",
            16),),
        null,
        null,
        null,
        1000),
        3 to ReplicationEntityMetaData(3, 100, "ContentEntryContentCategoryJoin",
            "ContentEntryContentCategoryJoinReplicate",
            "ContentEntryContentCategoryJoin_ReceiveView", "ceccjUid", "ceccjLct", "ceccjPk",
            "ceccjDestination", "ceccjVersionId", "ceccjPending", 
        listOf(ReplicationFieldMetaData("ceccjUid", -5),ReplicationFieldMetaData("ceccjContentEntryUid", -5),ReplicationFieldMetaData("ceccjContentCategoryUid", -5),ReplicationFieldMetaData("ceccjLocalChangeSeqNum", -5),ReplicationFieldMetaData("ceccjMasterChangeSeqNum", -5),ReplicationFieldMetaData("ceccjLastChangedBy",
            4),ReplicationFieldMetaData("ceccjLct", -5),),
        listOf(ReplicationFieldMetaData("ceccjPk", -5),ReplicationFieldMetaData("ceccjVersionId", -5),ReplicationFieldMetaData("ceccjDestination", -5),ReplicationFieldMetaData("ceccjPending",
            16),),
        null,
        null,
        null,
        1000),
        7 to ReplicationEntityMetaData(7, 100, "ContentEntryParentChildJoin",
            "ContentEntryParentChildJoinReplicate", "ContentEntryParentChildJoin_ReceiveView",
            "cepcjUid", "cepcjLct", "cepcjPk", "cepcjDestination", "cepcjVersionId", "cepcjPending",
            
        listOf(ReplicationFieldMetaData("cepcjParentContentEntryUid", -5),ReplicationFieldMetaData("cepcjChildContentEntryUid", -5),ReplicationFieldMetaData("childIndex",
            4),ReplicationFieldMetaData("cepcjUid", -5),ReplicationFieldMetaData("cepcjLocalChangeSeqNum", -5),ReplicationFieldMetaData("cepcjMasterChangeSeqNum", -5),ReplicationFieldMetaData("cepcjLastChangedBy",
            4),ReplicationFieldMetaData("cepcjLct", -5),),
        listOf(ReplicationFieldMetaData("cepcjPk", -5),ReplicationFieldMetaData("cepcjVersionId", -5),ReplicationFieldMetaData("cepcjDestination", -5),ReplicationFieldMetaData("cepcjPending",
            16),),
        null,
        null,
        null,
        1000),
        8 to ReplicationEntityMetaData(8, 100, "ContentEntryRelatedEntryJoin",
            "ContentEntryRelatedEntryJoinReplicate", "ContentEntryRelatedEntryJoin_ReceiveView",
            "cerejUid", "cerejLct", "cerejPk", "cerejDestination", "cerejVersionId", "cerejPending",
            
        listOf(ReplicationFieldMetaData("cerejUid", -5),ReplicationFieldMetaData("cerejContentEntryUid", -5),ReplicationFieldMetaData("cerejRelatedEntryUid", -5),ReplicationFieldMetaData("cerejLastChangedBy",
            4),ReplicationFieldMetaData("relType",
            4),ReplicationFieldMetaData("comment", -1),ReplicationFieldMetaData("cerejRelLanguageUid", -5),ReplicationFieldMetaData("cerejLocalChangeSeqNum", -5),ReplicationFieldMetaData("cerejMasterChangeSeqNum", -5),ReplicationFieldMetaData("cerejLct", -5),),
        listOf(ReplicationFieldMetaData("cerejPk", -5),ReplicationFieldMetaData("cerejVersionId", -5),ReplicationFieldMetaData("cerejDestination", -5),ReplicationFieldMetaData("cerejPending",
            16),),
        null,
        null,
        null,
        1000),
        2 to ReplicationEntityMetaData(2, 100, "ContentCategorySchema",
            "ContentCategorySchemaReplicate", "ContentCategorySchema_ReceiveView",
            "contentCategorySchemaUid", "contentCategorySchemaLct", "ccsPk", "ccsDestination",
            "ccsVersionId", "ccsPending", 
        listOf(ReplicationFieldMetaData("contentCategorySchemaUid", -5),ReplicationFieldMetaData("schemaName", -1),ReplicationFieldMetaData("schemaUrl", -1),ReplicationFieldMetaData("contentCategorySchemaLocalChangeSeqNum", -5),ReplicationFieldMetaData("contentCategorySchemaMasterChangeSeqNum", -5),ReplicationFieldMetaData("contentCategorySchemaLastChangedBy",
            4),ReplicationFieldMetaData("contentCategorySchemaLct", -5),),
        listOf(ReplicationFieldMetaData("ccsPk", -5),ReplicationFieldMetaData("ccsVersionId", -5),ReplicationFieldMetaData("ccsDestination", -5),ReplicationFieldMetaData("ccsPending",
            16),),
        null,
        null,
        null,
        1000),
        1 to ReplicationEntityMetaData(1, 100, "ContentCategory", "ContentCategoryReplicate",
            "ContentCategory_ReceiveView", "contentCategoryUid", "contentCategoryLct", "ccPk",
            "ccDestination", "ccVersionId", "ccPending", 
        listOf(ReplicationFieldMetaData("contentCategoryUid", -5),ReplicationFieldMetaData("ctnCatContentCategorySchemaUid", -5),ReplicationFieldMetaData("name", -1),ReplicationFieldMetaData("contentCategoryLocalChangeSeqNum", -5),ReplicationFieldMetaData("contentCategoryMasterChangeSeqNum", -5),ReplicationFieldMetaData("contentCategoryLastChangedBy",
            4),ReplicationFieldMetaData("contentCategoryLct", -5),),
        listOf(ReplicationFieldMetaData("ccPk", -5),ReplicationFieldMetaData("ccVersionId", -5),ReplicationFieldMetaData("ccDestination", -5),ReplicationFieldMetaData("ccPending",
            16),),
        null,
        null,
        null,
        1000),
        13 to ReplicationEntityMetaData(13, 100, "Language", "LanguageReplicate",
            "Language_ReceiveView", "langUid", "langLct", "languagePk", "languageDestination",
            "languageVersionId", "languagePending", 
        listOf(ReplicationFieldMetaData("langUid", -5),ReplicationFieldMetaData("name", -1),ReplicationFieldMetaData("iso_639_1_standard", -1),ReplicationFieldMetaData("iso_639_2_standard", -1),ReplicationFieldMetaData("iso_639_3_standard", -1),ReplicationFieldMetaData("Language_Type", -1),ReplicationFieldMetaData("languageActive",
            16),ReplicationFieldMetaData("langLocalChangeSeqNum", -5),ReplicationFieldMetaData("langMasterChangeSeqNum", -5),ReplicationFieldMetaData("langLastChangedBy",
            4),ReplicationFieldMetaData("langLct", -5),),
        listOf(ReplicationFieldMetaData("languagePk", -5),ReplicationFieldMetaData("languageVersionId", -5),ReplicationFieldMetaData("languageDestination", -5),ReplicationFieldMetaData("languagePending",
            16),),
        null,
        null,
        null,
        1000),
        10 to ReplicationEntityMetaData(10, 100, "LanguageVariant", "LanguageVariantReplicate",
            "LanguageVariant_ReceiveView", "langVariantUid", "langVariantLct", "lvPk",
            "lvDestination", "lvVersionId", "lvPending", 
        listOf(ReplicationFieldMetaData("langVariantUid", -5),ReplicationFieldMetaData("langUid", -5),ReplicationFieldMetaData("countryCode", -1),ReplicationFieldMetaData("name", -1),ReplicationFieldMetaData("langVariantLocalChangeSeqNum", -5),ReplicationFieldMetaData("langVariantMasterChangeSeqNum", -5),ReplicationFieldMetaData("langVariantLastChangedBy",
            4),ReplicationFieldMetaData("langVariantLct", -5),),
        listOf(ReplicationFieldMetaData("lvPk", -5),ReplicationFieldMetaData("lvVersionId", -5),ReplicationFieldMetaData("lvDestination", -5),ReplicationFieldMetaData("lvPending",
            16),),
        null,
        null,
        null,
        1000),
        43 to ReplicationEntityMetaData(43, 100, "PersonGroup", "PersonGroupReplicate",
            "PersonGroup_ReceiveView", "groupUid", "groupLct", "pgPk", "pgDestination",
            "pgVersionId", "pgPending", 
        listOf(ReplicationFieldMetaData("groupUid", -5),ReplicationFieldMetaData("groupMasterCsn", -5),ReplicationFieldMetaData("groupLocalCsn", -5),ReplicationFieldMetaData("groupLastChangedBy",
            4),ReplicationFieldMetaData("groupLct", -5),ReplicationFieldMetaData("groupName", -1),ReplicationFieldMetaData("groupActive",
            16),ReplicationFieldMetaData("personGroupFlag", 4),),
        listOf(ReplicationFieldMetaData("pgPk", -5),ReplicationFieldMetaData("pgVersionId", -5),ReplicationFieldMetaData("pgDestination", -5),ReplicationFieldMetaData("pgPending",
            16),),
        null,
        null,
        null,
        1000),
        44 to ReplicationEntityMetaData(44, 0, "PersonGroupMember", "PersonGroupMemberReplicate",
            "PersonGroupMember_ReceiveView", "groupMemberUid", "groupMemberLct", "pgmPk",
            "pgmDestination", "pgmVersionId", "pgmPending", 
        listOf(ReplicationFieldMetaData("groupMemberUid", -5),ReplicationFieldMetaData("groupMemberActive",
            16),ReplicationFieldMetaData("groupMemberPersonUid", -5),ReplicationFieldMetaData("groupMemberGroupUid", -5),ReplicationFieldMetaData("groupMemberMasterCsn", -5),ReplicationFieldMetaData("groupMemberLocalCsn", -5),ReplicationFieldMetaData("groupMemberLastChangedBy",
            4),ReplicationFieldMetaData("groupMemberLct", -5),),
        listOf(ReplicationFieldMetaData("pgmPk", -5),ReplicationFieldMetaData("pgmVersionId", -5),ReplicationFieldMetaData("pgmDestination", -5),ReplicationFieldMetaData("pgmPending",
            16),),
        null,
        null,
        null,
        1000),
        50 to ReplicationEntityMetaData(50, 100, "PersonPicture", "PersonPictureReplicate",
            "PersonPicture_ReceiveView", "personPictureUid", "personPictureLct", "ppPk",
            "ppDestination", "ppVersionId", "ppPending", 
        listOf(ReplicationFieldMetaData("personPictureUid", -5),ReplicationFieldMetaData("personPicturePersonUid", -5),ReplicationFieldMetaData("personPictureMasterCsn", -5),ReplicationFieldMetaData("personPictureLocalCsn", -5),ReplicationFieldMetaData("personPictureLastChangedBy",
            4),ReplicationFieldMetaData("personPictureLct", -5),ReplicationFieldMetaData("personPictureUri", -1),ReplicationFieldMetaData("personPictureMd5", -1),ReplicationFieldMetaData("fileSize",
            4),ReplicationFieldMetaData("picTimestamp", -5),ReplicationFieldMetaData("mimeType", -1),ReplicationFieldMetaData("personPictureActive",
            16),),
        listOf(ReplicationFieldMetaData("ppPk", -5),ReplicationFieldMetaData("ppVersionId", -5),ReplicationFieldMetaData("ppDestination", -5),ReplicationFieldMetaData("ppPending",
            16),),
        "personPictureUri",
        "personPictureMd5",
        "fileSize",
        1000),
        51 to ReplicationEntityMetaData(51, 100, "Container", "ContainerReplicate",
            "Container_ReceiveView", "containerUid", "cntLct", "containerPk",
            "containerDestination", "containerVersionId", "containerPending", 
        listOf(ReplicationFieldMetaData("containerUid", -5),ReplicationFieldMetaData("cntLocalCsn", -5),ReplicationFieldMetaData("cntMasterCsn", -5),ReplicationFieldMetaData("cntLastModBy",
            4),ReplicationFieldMetaData("cntLct", -5),ReplicationFieldMetaData("fileSize", -5),ReplicationFieldMetaData("containerContentEntryUid", -5),ReplicationFieldMetaData("cntLastModified", -5),ReplicationFieldMetaData("mimeType", -1),ReplicationFieldMetaData("remarks", -1),ReplicationFieldMetaData("mobileOptimized",
            16),ReplicationFieldMetaData("cntNumEntries", 4),),
        listOf(ReplicationFieldMetaData("containerPk", -5),ReplicationFieldMetaData("containerVersionId", -5),ReplicationFieldMetaData("containerDestination", -5),ReplicationFieldMetaData("containerPending",
            16),),
        null,
        null,
        null,
        1000),
        62 to ReplicationEntityMetaData(62, 100, "VerbEntity", "VerbEntityReplicate",
            "VerbEntity_ReceiveView", "verbUid", "verbLct", "vePk", "veDestination", "veVersionId",
            "vePending", 
        listOf(ReplicationFieldMetaData("verbUid", -5),ReplicationFieldMetaData("urlId", -1),ReplicationFieldMetaData("verbInActive",
            16),ReplicationFieldMetaData("verbMasterChangeSeqNum", -5),ReplicationFieldMetaData("verbLocalChangeSeqNum", -5),ReplicationFieldMetaData("verbLastChangedBy",
            4),ReplicationFieldMetaData("verbLct", -5),),
        listOf(ReplicationFieldMetaData("vePk", -5),ReplicationFieldMetaData("veVersionId", -5),ReplicationFieldMetaData("veDestination", -5),ReplicationFieldMetaData("vePending",
            16),),
        null,
        null,
        null,
        1000),
        64 to ReplicationEntityMetaData(64, 100, "XObjectEntity", "XObjectEntityReplicate",
            "XObjectEntity_ReceiveView", "xObjectUid", "xObjectLct", "xoePk", "xoeDestination",
            "xoeVersionId", "xoePending", 
        listOf(ReplicationFieldMetaData("xObjectUid", -5),ReplicationFieldMetaData("objectType", -1),ReplicationFieldMetaData("objectId", -1),ReplicationFieldMetaData("definitionType", -1),ReplicationFieldMetaData("interactionType", -1),ReplicationFieldMetaData("correctResponsePattern", -1),ReplicationFieldMetaData("objectContentEntryUid", -5),ReplicationFieldMetaData("objectStatementRefUid", -5),ReplicationFieldMetaData("xObjectMasterChangeSeqNum", -5),ReplicationFieldMetaData("xObjectocalChangeSeqNum", -5),ReplicationFieldMetaData("xObjectLastChangedBy",
            4),ReplicationFieldMetaData("xObjectLct", -5),),
        listOf(ReplicationFieldMetaData("xoePk", -5),ReplicationFieldMetaData("xoeVersionId", -5),ReplicationFieldMetaData("xoeDestination", -5),ReplicationFieldMetaData("xoePending",
            16),),
        null,
        null,
        null,
        1000),
        60 to ReplicationEntityMetaData(60, 1, "StatementEntity", "StatementEntityReplicate",
            "StatementEntity_ReceiveView", "statementUid", "statementLct", "sePk", "seDestination",
            "seVersionId", "sePending", 
        listOf(ReplicationFieldMetaData("statementUid", -5),ReplicationFieldMetaData("statementId", -1),ReplicationFieldMetaData("statementPersonUid", -5),ReplicationFieldMetaData("statementVerbUid", -5),ReplicationFieldMetaData("xObjectUid", -5),ReplicationFieldMetaData("subStatementActorUid", -5),ReplicationFieldMetaData("substatementVerbUid", -5),ReplicationFieldMetaData("subStatementObjectUid", -5),ReplicationFieldMetaData("agentUid", -5),ReplicationFieldMetaData("instructorUid", -5),ReplicationFieldMetaData("authorityUid", -5),ReplicationFieldMetaData("teamUid", -5),ReplicationFieldMetaData("resultCompletion",
            16),ReplicationFieldMetaData("resultSuccess",
            5),ReplicationFieldMetaData("resultScoreScaled",
            6),ReplicationFieldMetaData("resultScoreRaw", -5),ReplicationFieldMetaData("resultScoreMin", -5),ReplicationFieldMetaData("resultScoreMax", -5),ReplicationFieldMetaData("resultDuration", -5),ReplicationFieldMetaData("resultResponse", -1),ReplicationFieldMetaData("timestamp", -5),ReplicationFieldMetaData("stored", -5),ReplicationFieldMetaData("contextRegistration", -1),ReplicationFieldMetaData("contextPlatform", -1),ReplicationFieldMetaData("contextStatementId", -1),ReplicationFieldMetaData("fullStatement", -1),ReplicationFieldMetaData("statementMasterChangeSeqNum", -5),ReplicationFieldMetaData("statementLocalChangeSeqNum", -5),ReplicationFieldMetaData("statementLastChangedBy",
            4),ReplicationFieldMetaData("statementLct", -5),ReplicationFieldMetaData("extensionProgress",
            4),ReplicationFieldMetaData("contentEntryRoot",
            16),ReplicationFieldMetaData("statementContentEntryUid", -5),ReplicationFieldMetaData("statementLearnerGroupUid", -5),ReplicationFieldMetaData("statementClazzUid", -5),),
        listOf(ReplicationFieldMetaData("sePk", -5),ReplicationFieldMetaData("seVersionId", -5),ReplicationFieldMetaData("seDestination", -5),ReplicationFieldMetaData("sePending",
            16),),
        null,
        null,
        null,
        1000),
        66 to ReplicationEntityMetaData(66, 100, "ContextXObjectStatementJoin",
            "ContextXObjectStatementJoinReplicate", "ContextXObjectStatementJoin_ReceiveView",
            "contextXObjectStatementJoinUid", "contextXObjectLct", "cxosjPk", "cxosjDestination",
            "cxosjVersionId", "cxosjPending", 
        listOf(ReplicationFieldMetaData("contextXObjectStatementJoinUid", -5),ReplicationFieldMetaData("contextActivityFlag",
            4),ReplicationFieldMetaData("contextStatementUid", -5),ReplicationFieldMetaData("contextXObjectUid", -5),ReplicationFieldMetaData("verbMasterChangeSeqNum", -5),ReplicationFieldMetaData("verbLocalChangeSeqNum", -5),ReplicationFieldMetaData("verbLastChangedBy",
            4),ReplicationFieldMetaData("contextXObjectLct", -5),),
        listOf(ReplicationFieldMetaData("cxosjPk", -5),ReplicationFieldMetaData("cxosjVersionId", -5),ReplicationFieldMetaData("cxosjDestination", -5),ReplicationFieldMetaData("cxosjPending",
            16),),
        null,
        null,
        null,
        1000),
        68 to ReplicationEntityMetaData(68, 100, "AgentEntity", "AgentEntityReplicate",
            "AgentEntity_ReceiveView", "agentUid", "agentLct", "aePk", "aeDestination",
            "aeVersionId", "aePending", 
        listOf(ReplicationFieldMetaData("agentUid", -5),ReplicationFieldMetaData("agentMbox", -1),ReplicationFieldMetaData("agentMbox_sha1sum", -1),ReplicationFieldMetaData("agentOpenid", -1),ReplicationFieldMetaData("agentAccountName", -1),ReplicationFieldMetaData("agentHomePage", -1),ReplicationFieldMetaData("agentPersonUid", -5),ReplicationFieldMetaData("statementMasterChangeSeqNum", -5),ReplicationFieldMetaData("statementLocalChangeSeqNum", -5),ReplicationFieldMetaData("statementLastChangedBy",
            4),ReplicationFieldMetaData("agentLct", -5),),
        listOf(ReplicationFieldMetaData("aePk", -5),ReplicationFieldMetaData("aeVersionId", -5),ReplicationFieldMetaData("aeDestination", -5),ReplicationFieldMetaData("aePending",
            16),),
        null,
        null,
        null,
        1000),
        70 to ReplicationEntityMetaData(70, 100, "StateEntity", "StateEntityReplicate",
            "StateEntity_ReceiveView", "stateUid", "stateLct", "sePk", "seDestination",
            "seVersionId", "sePending", 
        listOf(ReplicationFieldMetaData("stateUid", -5),ReplicationFieldMetaData("stateId", -1),ReplicationFieldMetaData("agentUid", -5),ReplicationFieldMetaData("activityId", -1),ReplicationFieldMetaData("registration", -1),ReplicationFieldMetaData("isIsactive",
            16),ReplicationFieldMetaData("timestamp", -5),ReplicationFieldMetaData("stateMasterChangeSeqNum", -5),ReplicationFieldMetaData("stateLocalChangeSeqNum", -5),ReplicationFieldMetaData("stateLastChangedBy",
            4),ReplicationFieldMetaData("stateLct", -5),),
        listOf(ReplicationFieldMetaData("sePk", -5),ReplicationFieldMetaData("seVersionId", -5),ReplicationFieldMetaData("seDestination", -5),ReplicationFieldMetaData("sePending",
            16),),
        null,
        null,
        null,
        1000),
        72 to ReplicationEntityMetaData(72, 100, "StateContentEntity",
            "StateContentEntityReplicate", "StateContentEntity_ReceiveView", "stateContentUid",
            "stateContentLct", "scePk", "sceDestination", "sceVersionId", "scePending", 
        listOf(ReplicationFieldMetaData("stateContentUid", -5),ReplicationFieldMetaData("stateContentStateUid", -5),ReplicationFieldMetaData("stateContentKey", -1),ReplicationFieldMetaData("stateContentValue", -1),ReplicationFieldMetaData("isIsactive",
            16),ReplicationFieldMetaData("stateContentMasterChangeSeqNum", -5),ReplicationFieldMetaData("stateContentLocalChangeSeqNum", -5),ReplicationFieldMetaData("stateContentLastChangedBy",
            4),ReplicationFieldMetaData("stateContentLct", -5),),
        listOf(ReplicationFieldMetaData("scePk", -5),ReplicationFieldMetaData("sceVersionId", -5),ReplicationFieldMetaData("sceDestination", -5),ReplicationFieldMetaData("scePending",
            16),),
        null,
        null,
        null,
        1000),
        74 to ReplicationEntityMetaData(74, 100, "XLangMapEntry", "XLangMapEntryReplicate",
            "XLangMapEntry_ReceiveView", "statementLangMapUid", "statementLangMapLct", "xlmePk",
            "xlmeDestination", "xlmeVersionId", "xlmePending", 
        listOf(ReplicationFieldMetaData("verbLangMapUid", -5),ReplicationFieldMetaData("objectLangMapUid", -5),ReplicationFieldMetaData("languageLangMapUid", -5),ReplicationFieldMetaData("languageVariantLangMapUid", -5),ReplicationFieldMetaData("valueLangMap", -1),ReplicationFieldMetaData("statementLangMapMasterCsn",
            4),ReplicationFieldMetaData("statementLangMapLocalCsn",
            4),ReplicationFieldMetaData("statementLangMapLcb",
            4),ReplicationFieldMetaData("statementLangMapLct", -5),ReplicationFieldMetaData("statementLangMapUid", -5),),
        listOf(ReplicationFieldMetaData("xlmePk", -5),ReplicationFieldMetaData("xlmeVersionId", -5),ReplicationFieldMetaData("xlmeDestination", -5),ReplicationFieldMetaData("xlmePending",
            16),),
        null,
        null,
        null,
        1000),
        164 to ReplicationEntityMetaData(164, 100, "School", "SchoolReplicate",
            "School_ReceiveView", "schoolUid", "schoolLct", "schoolPk", "schoolDestination",
            "schoolVersionId", "schoolPending", 
        listOf(ReplicationFieldMetaData("schoolUid", -5),ReplicationFieldMetaData("schoolName", -1),ReplicationFieldMetaData("schoolDesc", -1),ReplicationFieldMetaData("schoolAddress", -1),ReplicationFieldMetaData("schoolActive",
            16),ReplicationFieldMetaData("schoolPhoneNumber", -1),ReplicationFieldMetaData("schoolGender",
            4),ReplicationFieldMetaData("schoolHolidayCalendarUid", -5),ReplicationFieldMetaData("schoolFeatures", -5),ReplicationFieldMetaData("schoolLocationLong",
            8),ReplicationFieldMetaData("schoolLocationLatt",
            8),ReplicationFieldMetaData("schoolEmailAddress", -1),ReplicationFieldMetaData("schoolTeachersPersonGroupUid", -5),ReplicationFieldMetaData("schoolStudentsPersonGroupUid", -5),ReplicationFieldMetaData("schoolPendingStudentsPersonGroupUid", -5),ReplicationFieldMetaData("schoolCode", -1),ReplicationFieldMetaData("schoolMasterChangeSeqNum", -5),ReplicationFieldMetaData("schoolLocalChangeSeqNum", -5),ReplicationFieldMetaData("schoolLastChangedBy",
            4),ReplicationFieldMetaData("schoolLct", -5),ReplicationFieldMetaData("schoolTimeZone", -1),),
        listOf(ReplicationFieldMetaData("schoolPk", -5),ReplicationFieldMetaData("schoolVersionId", -5),ReplicationFieldMetaData("schoolDestination", -5),ReplicationFieldMetaData("schoolPending",
            16),),
        null,
        null,
        null,
        1000),
        200 to ReplicationEntityMetaData(200, 1, "SchoolMember", "SchoolMemberReplicate",
            "SchoolMember_ReceiveView", "schoolMemberUid", "schoolMemberLct", "smPk",
            "smDestination", "smVersionId", "smPending", 
        listOf(ReplicationFieldMetaData("schoolMemberUid", -5),ReplicationFieldMetaData("schoolMemberPersonUid", -5),ReplicationFieldMetaData("schoolMemberSchoolUid", -5),ReplicationFieldMetaData("schoolMemberJoinDate", -5),ReplicationFieldMetaData("schoolMemberLeftDate", -5),ReplicationFieldMetaData("schoolMemberRole",
            4),ReplicationFieldMetaData("schoolMemberActive",
            16),ReplicationFieldMetaData("schoolMemberLocalChangeSeqNum", -5),ReplicationFieldMetaData("schoolMemberMasterChangeSeqNum", -5),ReplicationFieldMetaData("schoolMemberLastChangedBy",
            4),ReplicationFieldMetaData("schoolMemberLct", -5),),
        listOf(ReplicationFieldMetaData("smPk", -5),ReplicationFieldMetaData("smVersionId", -5),ReplicationFieldMetaData("smDestination", -5),ReplicationFieldMetaData("smPending",
            16),),
        null,
        null,
        null,
        1000),
        208 to ReplicationEntityMetaData(208, 100, "Comments", "CommentsReplicate",
            "Comments_ReceiveView", "commentsUid", "commentsLct", "commentsPk",
            "commentsDestination", "commentsVersionId", "commentsPending", 
        listOf(ReplicationFieldMetaData("commentsUid", -5),ReplicationFieldMetaData("commentsText", -1),ReplicationFieldMetaData("commentsEntityType",
            4),ReplicationFieldMetaData("commentsEntityUid", -5),ReplicationFieldMetaData("commentsPublic",
            16),ReplicationFieldMetaData("commentsStatus",
            4),ReplicationFieldMetaData("commentsPersonUid", -5),ReplicationFieldMetaData("commentsToPersonUid", -5),ReplicationFieldMetaData("commentSubmitterUid", -5),ReplicationFieldMetaData("commentsFlagged",
            16),ReplicationFieldMetaData("commentsInActive",
            16),ReplicationFieldMetaData("commentsDateTimeAdded", -5),ReplicationFieldMetaData("commentsDateTimeUpdated", -5),ReplicationFieldMetaData("commentsMCSN", -5),ReplicationFieldMetaData("commentsLCSN", -5),ReplicationFieldMetaData("commentsLCB",
            4),ReplicationFieldMetaData("commentsLct", -5),),
        listOf(ReplicationFieldMetaData("commentsPk", -5),ReplicationFieldMetaData("commentsVersionId", -5),ReplicationFieldMetaData("commentsDestination", -5),ReplicationFieldMetaData("commentsPending",
            16),),
        null,
        null,
        null,
        1000),
        101 to ReplicationEntityMetaData(101, 100, "Report", "ReportReplicate",
            "Report_ReceiveView", "reportUid", "reportLct", "reportPk", "reportDestination",
            "reportVersionId", "reportPending", 
        listOf(ReplicationFieldMetaData("reportUid", -5),ReplicationFieldMetaData("reportOwnerUid", -5),ReplicationFieldMetaData("xAxis",
            4),ReplicationFieldMetaData("reportDateRangeSelection",
            4),ReplicationFieldMetaData("fromDate", -5),ReplicationFieldMetaData("fromRelTo",
            4),ReplicationFieldMetaData("fromRelOffSet", 4),ReplicationFieldMetaData("fromRelUnit",
            4),ReplicationFieldMetaData("toDate", -5),ReplicationFieldMetaData("toRelTo",
            4),ReplicationFieldMetaData("toRelOffSet", 4),ReplicationFieldMetaData("toRelUnit",
            4),ReplicationFieldMetaData("reportTitle", -1),ReplicationFieldMetaData("reportDescription", -1),ReplicationFieldMetaData("reportSeries", -1),ReplicationFieldMetaData("reportInactive",
            16),ReplicationFieldMetaData("isTemplate", 16),ReplicationFieldMetaData("priority",
            4),ReplicationFieldMetaData("reportTitleId", 4),ReplicationFieldMetaData("reportDescId",
            4),ReplicationFieldMetaData("reportMasterChangeSeqNum", -5),ReplicationFieldMetaData("reportLocalChangeSeqNum", -5),ReplicationFieldMetaData("reportLastChangedBy",
            4),ReplicationFieldMetaData("reportLct", -5),),
        listOf(ReplicationFieldMetaData("reportPk", -5),ReplicationFieldMetaData("reportVersionId", -5),ReplicationFieldMetaData("reportDestination", -5),ReplicationFieldMetaData("reportPending",
            16),),
        null,
        null,
        null,
        1000),
        189 to ReplicationEntityMetaData(189, 100, "Site", "SiteReplicate", "Site_ReceiveView",
            "siteUid", "siteLct", "sitePk", "siteDestination", "siteVersionId", "sitePending", 
        listOf(ReplicationFieldMetaData("siteUid", -5),ReplicationFieldMetaData("sitePcsn", -5),ReplicationFieldMetaData("siteLcsn", -5),ReplicationFieldMetaData("siteLcb",
            4),ReplicationFieldMetaData("siteLct", -5),ReplicationFieldMetaData("siteName", -1),ReplicationFieldMetaData("guestLogin",
            16),ReplicationFieldMetaData("registrationAllowed",
            16),ReplicationFieldMetaData("authSalt", -1),),
        listOf(ReplicationFieldMetaData("sitePk", -5),ReplicationFieldMetaData("siteVersionId", -5),ReplicationFieldMetaData("siteDestination", -5),ReplicationFieldMetaData("sitePending",
            16),),
        null,
        null,
        null,
        1000),
        301 to ReplicationEntityMetaData(301, 100, "LearnerGroup", "LearnerGroupReplicate",
            "LearnerGroup_ReceiveView", "learnerGroupUid", "learnerGroupLct", "lgPk",
            "lgDestination", "lgVersionId", "lgPending", 
        listOf(ReplicationFieldMetaData("learnerGroupUid", -5),ReplicationFieldMetaData("learnerGroupName", -1),ReplicationFieldMetaData("learnerGroupDescription", -1),ReplicationFieldMetaData("learnerGroupActive",
            16),ReplicationFieldMetaData("learnerGroupMCSN", -5),ReplicationFieldMetaData("learnerGroupCSN", -5),ReplicationFieldMetaData("learnerGroupLCB",
            4),ReplicationFieldMetaData("learnerGroupLct", -5),),
        listOf(ReplicationFieldMetaData("lgPk", -5),ReplicationFieldMetaData("lgVersionId", -5),ReplicationFieldMetaData("lgDestination", -5),ReplicationFieldMetaData("lgPending",
            16),),
        null,
        null,
        null,
        1000),
        300 to ReplicationEntityMetaData(300, 100, "LearnerGroupMember",
            "LearnerGroupMemberReplicate", "LearnerGroupMember_ReceiveView",
            "learnerGroupMemberUid", "learnerGroupMemberLct", "lgmPk", "lgmDestination",
            "lgmVersionId", "lgmPending", 
        listOf(ReplicationFieldMetaData("learnerGroupMemberUid", -5),ReplicationFieldMetaData("learnerGroupMemberPersonUid", -5),ReplicationFieldMetaData("learnerGroupMemberLgUid", -5),ReplicationFieldMetaData("learnerGroupMemberRole",
            4),ReplicationFieldMetaData("learnerGroupMemberActive",
            16),ReplicationFieldMetaData("learnerGroupMemberMCSN", -5),ReplicationFieldMetaData("learnerGroupMemberCSN", -5),ReplicationFieldMetaData("learnerGroupMemberLCB",
            4),ReplicationFieldMetaData("learnerGroupMemberLct", -5),),
        listOf(ReplicationFieldMetaData("lgmPk", -5),ReplicationFieldMetaData("lgmVersionId", -5),ReplicationFieldMetaData("lgmDestination", -5),ReplicationFieldMetaData("lgmPending",
            16),),
        null,
        null,
        null,
        1000),
        302 to ReplicationEntityMetaData(302, 100, "GroupLearningSession",
            "GroupLearningSessionReplicate", "GroupLearningSession_ReceiveView",
            "groupLearningSessionUid", "groupLearningSessionLct", "glsPk", "glsDestination",
            "glsVersionId", "glsPending", 
        listOf(ReplicationFieldMetaData("groupLearningSessionUid", -5),ReplicationFieldMetaData("groupLearningSessionContentUid", -5),ReplicationFieldMetaData("groupLearningSessionLearnerGroupUid", -5),ReplicationFieldMetaData("groupLearningSessionInactive",
            16),ReplicationFieldMetaData("groupLearningSessionMCSN", -5),ReplicationFieldMetaData("groupLearningSessionCSN", -5),ReplicationFieldMetaData("groupLearningSessionLCB",
            4),ReplicationFieldMetaData("groupLearningSessionLct", -5),),
        listOf(ReplicationFieldMetaData("glsPk", -5),ReplicationFieldMetaData("glsVersionId", -5),ReplicationFieldMetaData("glsDestination", -5),ReplicationFieldMetaData("glsPending",
            16),),
        null,
        null,
        null,
        1000),
        272 to ReplicationEntityMetaData(272, 100, "SiteTerms", "SiteTermsReplicate",
            "SiteTerms_ReceiveView", "sTermsUid", "sTermsLct", "stPk", "stDestination",
            "stVersionId", "stPending", 
        listOf(ReplicationFieldMetaData("sTermsUid", -5),ReplicationFieldMetaData("termsHtml", -1),ReplicationFieldMetaData("sTermsLang", -1),ReplicationFieldMetaData("sTermsLangUid", -5),ReplicationFieldMetaData("sTermsActive",
            16),ReplicationFieldMetaData("sTermsLastChangedBy",
            4),ReplicationFieldMetaData("sTermsPrimaryCsn", -5),ReplicationFieldMetaData("sTermsLocalCsn", -5),ReplicationFieldMetaData("sTermsLct", -5),),
        listOf(ReplicationFieldMetaData("stPk", -5),ReplicationFieldMetaData("stVersionId", -5),ReplicationFieldMetaData("stDestination", -5),ReplicationFieldMetaData("stPending",
            16),),
        null,
        null,
        null,
        1000),
        134 to ReplicationEntityMetaData(134, 100, "ClazzContentJoin", "ClazzContentJoinReplicate",
            "ClazzContentJoin_ReceiveView", "ccjUid", "ccjLct", "ccjPk", "ccjDestination",
            "ccjVersionId", "ccjPending", 
        listOf(ReplicationFieldMetaData("ccjUid", -5),ReplicationFieldMetaData("ccjContentEntryUid", -5),ReplicationFieldMetaData("ccjClazzUid", -5),ReplicationFieldMetaData("ccjActive",
            16),ReplicationFieldMetaData("ccjLocalChangeSeqNum", -5),ReplicationFieldMetaData("ccjMasterChangeSeqNum", -5),ReplicationFieldMetaData("ccjLastChangedBy",
            4),ReplicationFieldMetaData("ccjLct", -5),),
        listOf(ReplicationFieldMetaData("ccjPk", -5),ReplicationFieldMetaData("ccjVersionId", -5),ReplicationFieldMetaData("ccjDestination", -5),ReplicationFieldMetaData("ccjPending",
            16),),
        null,
        null,
        null,
        1000),
        512 to ReplicationEntityMetaData(512, 100, "PersonParentJoin", "PersonParentJoinReplicate",
            "PersonParentJoin_ReceiveView", "ppjUid", "ppjLct", "ppjPk", "ppjDestination",
            "ppjVersionId", "ppjPending", 
        listOf(ReplicationFieldMetaData("ppjUid", -5),ReplicationFieldMetaData("ppjPcsn", -5),ReplicationFieldMetaData("ppjLcsn", -5),ReplicationFieldMetaData("ppjLcb",
            4),ReplicationFieldMetaData("ppjLct", -5),ReplicationFieldMetaData("ppjParentPersonUid", -5),ReplicationFieldMetaData("ppjMinorPersonUid", -5),ReplicationFieldMetaData("ppjRelationship",
            4),ReplicationFieldMetaData("ppjEmail", -1),ReplicationFieldMetaData("ppjPhone", -1),ReplicationFieldMetaData("ppjInactive",
            16),ReplicationFieldMetaData("ppjStatus",
            4),ReplicationFieldMetaData("ppjApprovalTiemstamp", -5),ReplicationFieldMetaData("ppjApprovalIpAddr", -1),),
        listOf(ReplicationFieldMetaData("ppjPk", -5),ReplicationFieldMetaData("ppjVersionId", -5),ReplicationFieldMetaData("ppjDestination", -5),ReplicationFieldMetaData("ppjPending",
            16),),
        null,
        null,
        null,
        1000),
        48 to ReplicationEntityMetaData(48, 0, "ScopedGrant", "ScopedGrantReplicate",
            "ScopedGrant_ReceiveView", "sgUid", "sgLct", "sgPk", "sgDestination", "sgVersionId",
            "sgPending", 
        listOf(ReplicationFieldMetaData("sgUid", -5),ReplicationFieldMetaData("sgPcsn", -5),ReplicationFieldMetaData("sgLcsn", -5),ReplicationFieldMetaData("sgLcb",
            4),ReplicationFieldMetaData("sgLct", -5),ReplicationFieldMetaData("sgTableId",
            4),ReplicationFieldMetaData("sgEntityUid", -5),ReplicationFieldMetaData("sgPermissions", -5),ReplicationFieldMetaData("sgGroupUid", -5),ReplicationFieldMetaData("sgIndex",
            4),ReplicationFieldMetaData("sgFlags", 4),),
        listOf(ReplicationFieldMetaData("sgPk", -5),ReplicationFieldMetaData("sgVersionId", -5),ReplicationFieldMetaData("sgDestination", -5),ReplicationFieldMetaData("sgPending",
            16),),
        null,
        null,
        null,
        1000),
        419 to ReplicationEntityMetaData(419, 100, "ErrorReport", "ErrorReportReplicate",
            "ErrorReport_ReceiveView", "errUid", "errLct", "erPk", "erDestination", "erVersionId",
            "erPending", 
        listOf(ReplicationFieldMetaData("errUid", -5),ReplicationFieldMetaData("errPcsn", -5),ReplicationFieldMetaData("errLcsn", -5),ReplicationFieldMetaData("errLcb",
            4),ReplicationFieldMetaData("errLct", -5),ReplicationFieldMetaData("severity",
            4),ReplicationFieldMetaData("timestamp", -5),ReplicationFieldMetaData("presenterUri", -1),ReplicationFieldMetaData("appVersion", -1),ReplicationFieldMetaData("versionCode",
            4),ReplicationFieldMetaData("errorCode",
            4),ReplicationFieldMetaData("operatingSys", -1),ReplicationFieldMetaData("osVersion", -1),ReplicationFieldMetaData("stackTrace", -1),ReplicationFieldMetaData("message", -1),),
        listOf(ReplicationFieldMetaData("erPk", -5),ReplicationFieldMetaData("erVersionId", -5),ReplicationFieldMetaData("erDestination", -5),ReplicationFieldMetaData("erPending",
            16),),
        null,
        null,
        null,
        1000),
        520 to ReplicationEntityMetaData(520, 100, "ClazzAssignment", "ClazzAssignmentReplicate",
            "ClazzAssignment_ReceiveView", "caUid", "caLct", "caPk", "caDestination", "caVersionId",
            "caPending", 
        listOf(ReplicationFieldMetaData("caUid", -5),ReplicationFieldMetaData("caTitle", -1),ReplicationFieldMetaData("caDescription", -1),ReplicationFieldMetaData("caGroupUid", -5),ReplicationFieldMetaData("caActive",
            16),ReplicationFieldMetaData("caClassCommentEnabled",
            16),ReplicationFieldMetaData("caPrivateCommentsEnabled",
            16),ReplicationFieldMetaData("caCompletionCriteria",
            4),ReplicationFieldMetaData("caRequireFileSubmission",
            16),ReplicationFieldMetaData("caFileType", 4),ReplicationFieldMetaData("caSizeLimit",
            4),ReplicationFieldMetaData("caNumberOfFiles",
            4),ReplicationFieldMetaData("caSubmissionPolicy",
            4),ReplicationFieldMetaData("caMarkingType",
            4),ReplicationFieldMetaData("caRequireTextSubmission",
            16),ReplicationFieldMetaData("caTextLimitType",
            4),ReplicationFieldMetaData("caTextLimit",
            4),ReplicationFieldMetaData("caXObjectUid", -5),ReplicationFieldMetaData("caClazzUid", -5),ReplicationFieldMetaData("caLocalChangeSeqNum", -5),ReplicationFieldMetaData("caMasterChangeSeqNum", -5),ReplicationFieldMetaData("caLastChangedBy",
            4),ReplicationFieldMetaData("caLct", -5),),
        listOf(ReplicationFieldMetaData("caPk", -5),ReplicationFieldMetaData("caVersionId", -5),ReplicationFieldMetaData("caDestination", -5),ReplicationFieldMetaData("caPending",
            16),),
        null,
        null,
        null,
        1000),
        521 to ReplicationEntityMetaData(521, 100, "ClazzAssignmentContentJoin",
            "ClazzAssignmentContentJoinReplicate", "ClazzAssignmentContentJoin_ReceiveView",
            "cacjUid", "cacjLct", "cacjPk", "cacjDestination", "cacjVersionId", "cacjPending", 
        listOf(ReplicationFieldMetaData("cacjUid", -5),ReplicationFieldMetaData("cacjContentUid", -5),ReplicationFieldMetaData("cacjAssignmentUid", -5),ReplicationFieldMetaData("cacjActive",
            16),ReplicationFieldMetaData("cacjWeight",
            4),ReplicationFieldMetaData("cacjMCSN", -5),ReplicationFieldMetaData("cacjLCSN", -5),ReplicationFieldMetaData("cacjLCB",
            4),ReplicationFieldMetaData("cacjLct", -5),),
        listOf(ReplicationFieldMetaData("cacjPk", -5),ReplicationFieldMetaData("cacjVersionId", -5),ReplicationFieldMetaData("cacjDestination", -5),ReplicationFieldMetaData("cacjPending",
            16),),
        null,
        null,
        null,
        1000),
        522 to ReplicationEntityMetaData(522, 100, "CourseAssignmentSubmission",
            "CourseAssignmentSubmissionReplicate", "CourseAssignmentSubmission_ReceiveView",
            "casUid", "casTimestamp", "casPk", "casDestination", "casVersionId", "casPending", 
        listOf(ReplicationFieldMetaData("casUid", -5),ReplicationFieldMetaData("casAssignmentUid", -5),ReplicationFieldMetaData("casSubmitterUid", -5),ReplicationFieldMetaData("casSubmitterPersonUid", -5),ReplicationFieldMetaData("casText", -1),ReplicationFieldMetaData("casType",
            4),ReplicationFieldMetaData("casTimestamp", -5),),
        listOf(ReplicationFieldMetaData("casPk", -5),ReplicationFieldMetaData("casVersionId", -5),ReplicationFieldMetaData("casDestination", -5),ReplicationFieldMetaData("casPending",
            16),),
        null,
        null,
        null,
        1000),
        90 to ReplicationEntityMetaData(90, 100, "CourseAssignmentSubmissionAttachment",
            "CourseAssignmentSubmissionAttachmentReplicate",
            "CourseAssignmentSubmissionAttachment_ReceiveView", "casaUid", "casaTimestamp",
            "casaPk", "casaDestination", "casaVersionId", "casaPending", 
        listOf(ReplicationFieldMetaData("casaUid", -5),ReplicationFieldMetaData("casaSubmissionUid", -5),ReplicationFieldMetaData("casaMimeType", -1),ReplicationFieldMetaData("casaFileName", -1),ReplicationFieldMetaData("casaUri", -1),ReplicationFieldMetaData("casaMd5", -1),ReplicationFieldMetaData("casaSize",
            4),ReplicationFieldMetaData("casaTimestamp", -5),),
        listOf(ReplicationFieldMetaData("casaPk", -5),ReplicationFieldMetaData("casaVersionId", -5),ReplicationFieldMetaData("casaDestination", -5),ReplicationFieldMetaData("casaPending",
            16),),
        "casaUri",
        "casaMd5",
        "casaSize",
        1000),
        523 to ReplicationEntityMetaData(523, 100, "CourseAssignmentMark",
            "CourseAssignmentMarkReplicate", "CourseAssignmentMark_ReceiveView", "camUid", "camLct",
            "camPk", "camDestination", "camVersionId", "camPending", 
        listOf(ReplicationFieldMetaData("camUid", -5),ReplicationFieldMetaData("camAssignmentUid", -5),ReplicationFieldMetaData("camSubmitterUid", -5),ReplicationFieldMetaData("camMark",
            6),ReplicationFieldMetaData("camPenalty", 4),ReplicationFieldMetaData("camLct", -5),),
        listOf(ReplicationFieldMetaData("camPk", -5),ReplicationFieldMetaData("camVersionId", -5),ReplicationFieldMetaData("camDestination", -5),ReplicationFieldMetaData("camPending",
            16),),
        null,
        null,
        null,
        1000),
        678 to ReplicationEntityMetaData(678, 100, "PersonAuth2", "PersonAuth2Replicate",
            "PersonAuth2_ReceiveView", "pauthUid", "pauthLct", "paPk", "paDestination",
            "paVersionId", "paPending", 
        listOf(ReplicationFieldMetaData("pauthUid", -5),ReplicationFieldMetaData("pauthMechanism", -1),ReplicationFieldMetaData("pauthAuth", -1),ReplicationFieldMetaData("pauthLcsn", -5),ReplicationFieldMetaData("pauthPcsn", -5),ReplicationFieldMetaData("pauthLcb", -5),ReplicationFieldMetaData("pauthLct", -5),),
        listOf(ReplicationFieldMetaData("paPk", -5),ReplicationFieldMetaData("paVersionId", -5),ReplicationFieldMetaData("paDestination", -5),ReplicationFieldMetaData("paPending",
            16),),
        null,
        null,
        null,
        1000),
        679 to ReplicationEntityMetaData(679, 0, "UserSession", "UserSessionReplicate",
            "UserSession_ReceiveView", "usUid", "usLct", "usPk", "usDestination", "usVersionId",
            "usPending", 
        listOf(ReplicationFieldMetaData("usUid", -5),ReplicationFieldMetaData("usPcsn", -5),ReplicationFieldMetaData("usLcsn", -5),ReplicationFieldMetaData("usLcb",
            4),ReplicationFieldMetaData("usLct", -5),ReplicationFieldMetaData("usPersonUid", -5),ReplicationFieldMetaData("usClientNodeId", -5),ReplicationFieldMetaData("usStartTime", -5),ReplicationFieldMetaData("usEndTime", -5),ReplicationFieldMetaData("usStatus",
            4),ReplicationFieldMetaData("usReason",
            4),ReplicationFieldMetaData("usAuth", -1),ReplicationFieldMetaData("usSessionType",
            4),),
        listOf(ReplicationFieldMetaData("usPk", -5),ReplicationFieldMetaData("usVersionId", -5),ReplicationFieldMetaData("usDestination", -5),ReplicationFieldMetaData("usPending",
            16),),
        null,
        null,
        null,
        1000),
        124 to ReplicationEntityMetaData(124, 100, "CourseBlock", "CourseBlockReplicate",
            "CourseBlock_ReceiveView", "cbUid", "cbLct", "cbPk", "cbDestination", "cbVersionId",
            "cbPending", 
        listOf(ReplicationFieldMetaData("cbUid", -5),ReplicationFieldMetaData("cbType",
            4),ReplicationFieldMetaData("cbIndentLevel",
            4),ReplicationFieldMetaData("cbModuleParentBlockUid", -5),ReplicationFieldMetaData("cbTitle", -1),ReplicationFieldMetaData("cbDescription", -1),ReplicationFieldMetaData("cbCompletionCriteria",
            4),ReplicationFieldMetaData("cbHideUntilDate", -5),ReplicationFieldMetaData("cbDeadlineDate", -5),ReplicationFieldMetaData("cbLateSubmissionPenalty",
            4),ReplicationFieldMetaData("cbGracePeriodDate", -5),ReplicationFieldMetaData("cbMaxPoints",
            4),ReplicationFieldMetaData("cbMinPoints", 4),ReplicationFieldMetaData("cbIndex",
            4),ReplicationFieldMetaData("cbClazzUid", -5),ReplicationFieldMetaData("cbActive",
            16),ReplicationFieldMetaData("cbHidden",
            16),ReplicationFieldMetaData("cbEntityUid", -5),ReplicationFieldMetaData("cbLct", -5),),
        listOf(ReplicationFieldMetaData("cbPk", -5),ReplicationFieldMetaData("cbVersionId", -5),ReplicationFieldMetaData("cbDestination", -5),ReplicationFieldMetaData("cbPending",
            16),),
        null,
        null,
        null,
        1000),
        450 to ReplicationEntityMetaData(450, 100, "CourseTerminology",
            "CourseTerminologyReplicate", "CourseTerminology_ReceiveView", "ctUid", "ctLct", "ctPk",
            "ctDestination", "ctVersionId", "ctPending", 
        listOf(ReplicationFieldMetaData("ctUid", -5),ReplicationFieldMetaData("ctTitle", -1),ReplicationFieldMetaData("ctTerminology", -1),ReplicationFieldMetaData("ctLct", -5),),
        listOf(ReplicationFieldMetaData("ctPk", -5),ReplicationFieldMetaData("ctVersionId", -5),ReplicationFieldMetaData("ctDestination", -5),ReplicationFieldMetaData("ctPending",
            16),),
        null,
        null,
        null,
        1000),
        242 to ReplicationEntityMetaData(242, 100, "CourseGroupSet", "CourseGroupSetReplicate",
            "CourseGroupSet_ReceiveView", "cgsUid", "cgsLct", "cgsPk", "cgsDestination",
            "cgsVersionId", "cgsPending", 
        listOf(ReplicationFieldMetaData("cgsUid", -5),ReplicationFieldMetaData("cgsName", -1),ReplicationFieldMetaData("cgsTotalGroups",
            4),ReplicationFieldMetaData("cgsActive",
            16),ReplicationFieldMetaData("cgsClazzUid", -5),ReplicationFieldMetaData("cgsLct", -5),),
        listOf(ReplicationFieldMetaData("cgsPk", -5),ReplicationFieldMetaData("cgsVersionId", -5),ReplicationFieldMetaData("cgsDestination", -5),ReplicationFieldMetaData("cgsPending",
            16),),
        null,
        null,
        null,
        1000),
        243 to ReplicationEntityMetaData(243, 100, "CourseGroupMember",
            "CourseGroupMemberReplicate", "CourseGroupMember_ReceiveView", "cgmUid", "cgmLct",
            "cgmPk", "cgmDestination", "cgmVersionId", "cgmPending", 
        listOf(ReplicationFieldMetaData("cgmUid", -5),ReplicationFieldMetaData("cgmSetUid", -5),ReplicationFieldMetaData("cgmGroupNumber",
            4),ReplicationFieldMetaData("cgmPersonUid", -5),ReplicationFieldMetaData("cgmLct", -5),),
        listOf(ReplicationFieldMetaData("cgmPk", -5),ReplicationFieldMetaData("cgmVersionId", -5),ReplicationFieldMetaData("cgmDestination", -5),ReplicationFieldMetaData("cgmPending",
            16),),
        null,
        null,
        null,
        1000),
        125 to ReplicationEntityMetaData(125, 100, "CoursePicture", "CoursePictureReplicate",
            "CoursePicture_ReceiveView", "coursePictureUid", "coursePictureLct", "cpPk",
            "cpDestination", "cpVersionId", "cpPending", 
        listOf(ReplicationFieldMetaData("coursePictureUid", -5),ReplicationFieldMetaData("coursePictureClazzUid", -5),ReplicationFieldMetaData("coursePictureMasterCsn", -5),ReplicationFieldMetaData("coursePictureLocalCsn", -5),ReplicationFieldMetaData("coursePictureLastChangedBy",
            4),ReplicationFieldMetaData("coursePictureLct", -5),ReplicationFieldMetaData("coursePictureUri", -1),ReplicationFieldMetaData("coursePictureMd5", -1),ReplicationFieldMetaData("coursePictureFileSize",
            4),ReplicationFieldMetaData("coursePictureTimestamp", -5),ReplicationFieldMetaData("coursePictureMimeType", -1),ReplicationFieldMetaData("coursePictureActive",
            16),),
        listOf(ReplicationFieldMetaData("cpPk", -5),ReplicationFieldMetaData("cpVersionId", -5),ReplicationFieldMetaData("cpDestination", -5),ReplicationFieldMetaData("cpPending",
            16),),
        "coursePictureUri",
        "coursePictureMd5",
        "coursePictureFileSize",
        1000),
        138 to ReplicationEntityMetaData(138, 100, "ContentEntryPicture",
            "ContentEntryPictureReplicate", "ContentEntryPicture_ReceiveView", "cepUid",
            "cepTimestamp", "cepPk", "cepDestination", "cepVersionId", "cepPending", 
        listOf(ReplicationFieldMetaData("cepUid", -5),ReplicationFieldMetaData("cepContentEntryUid", -5),ReplicationFieldMetaData("cepUri", -1),ReplicationFieldMetaData("cepMd5", -1),ReplicationFieldMetaData("cepFileSize",
            4),ReplicationFieldMetaData("cepTimestamp", -5),ReplicationFieldMetaData("cepMimeType", -1),ReplicationFieldMetaData("cepActive",
            16),),
        listOf(ReplicationFieldMetaData("cepPk", -5),ReplicationFieldMetaData("cepVersionId", -5),ReplicationFieldMetaData("cepDestination", -5),ReplicationFieldMetaData("cepPending",
            16),),
        "cepUri",
        "cepMd5",
        "cepFileSize",
        1000),
        127 to ReplicationEntityMetaData(127, 0, "Chat", "ChatReplicate", "Chat_ReceiveView",
            "chatUid", "chatLct", "chatPk", "chatDestination", "chatVersionId", "chatPending", 
        listOf(ReplicationFieldMetaData("chatUid", -5),ReplicationFieldMetaData("chatStartDate", -5),ReplicationFieldMetaData("chatTitle", -1),ReplicationFieldMetaData("chatGroup",
            16),ReplicationFieldMetaData("chatLct", -5),),
        listOf(ReplicationFieldMetaData("chatPk", -5),ReplicationFieldMetaData("chatVersionId", -5),ReplicationFieldMetaData("chatDestination", -5),ReplicationFieldMetaData("chatPending",
            16),),
        null,
        null,
        null,
        1000),
        128 to ReplicationEntityMetaData(128, 0, "ChatMember", "ChatMemberReplicate",
            "ChatMember_ReceiveView", "chatMemberUid", "chatMemberLct", "chatMemberPk",
            "chatMemberDestination", "chatMemberVersionId", "chatMemberPending", 
        listOf(ReplicationFieldMetaData("chatMemberUid", -5),ReplicationFieldMetaData("chatMemberChatUid", -5),ReplicationFieldMetaData("chatMemberPersonUid", -5),ReplicationFieldMetaData("chatMemberJoinedDate", -5),ReplicationFieldMetaData("chatMemberLeftDate", -5),ReplicationFieldMetaData("chatMemberLct", -5),),
        listOf(ReplicationFieldMetaData("chatMemberPk", -5),ReplicationFieldMetaData("chatMemberVersionId", -5),ReplicationFieldMetaData("chatMemberDestination", -5),ReplicationFieldMetaData("chatMemberPending",
            16),),
        null,
        null,
        null,
        1000),
        126 to ReplicationEntityMetaData(126, 100, "Message", "MessageReplicate",
            "Message_ReceiveView", "messageUid", "messageLct", "messagePk", "messageDestination",
            "messageVersionId", "messagePending", 
        listOf(ReplicationFieldMetaData("messageUid", -5),ReplicationFieldMetaData("messageSenderPersonUid", -5),ReplicationFieldMetaData("messageTableId",
            4),ReplicationFieldMetaData("messageEntityUid", -5),ReplicationFieldMetaData("messageText", -1),ReplicationFieldMetaData("messageTimestamp", -5),ReplicationFieldMetaData("messageClazzUid", -5),ReplicationFieldMetaData("messageLct", -5),),
        listOf(ReplicationFieldMetaData("messagePk", -5),ReplicationFieldMetaData("messageVersionId", -5),ReplicationFieldMetaData("messageDestination", -5),ReplicationFieldMetaData("messagePending",
            16),),
        null,
        null,
        null,
        1000),
        129 to ReplicationEntityMetaData(129, 100, "MessageRead", "MessageReadReplicate",
            "MessageRead_ReceiveView", "messageReadUid", "messageReadLct", "messageReadPk",
            "messageReadDestination", "messageReadVersionId", "messageReadPending", 
        listOf(ReplicationFieldMetaData("messageReadUid", -5),ReplicationFieldMetaData("messageReadPersonUid", -5),ReplicationFieldMetaData("messageReadMessageUid", -5),ReplicationFieldMetaData("messageReadEntityUid", -5),ReplicationFieldMetaData("messageReadLct", -5),),
        listOf(ReplicationFieldMetaData("messageReadPk", -5),ReplicationFieldMetaData("messageReadVersionId", -5),ReplicationFieldMetaData("messageReadDestination", -5),ReplicationFieldMetaData("messageReadPending",
            16),),
        null,
        null,
        null,
        1000),
        130 to ReplicationEntityMetaData(130, 100, "CourseDiscussion", "CourseDiscussionReplicate",
            "CourseDiscussion_ReceiveView", "courseDiscussionUid", "courseDiscussionLct",
            "courseDiscussionPk", "courseDiscussionDestination", "courseDiscussionVersionId",
            "courseDiscussionPending", 
        listOf(ReplicationFieldMetaData("courseDiscussionUid", -5),ReplicationFieldMetaData("courseDiscussionTitle", -1),ReplicationFieldMetaData("courseDiscussionDesc", -1),ReplicationFieldMetaData("courseDiscussionClazzUid", -5),ReplicationFieldMetaData("courseDiscussionActive",
            16),ReplicationFieldMetaData("courseDiscussionLct", -5),),
        listOf(ReplicationFieldMetaData("courseDiscussionPk", -5),ReplicationFieldMetaData("courseDiscussionVersionId", -5),ReplicationFieldMetaData("courseDiscussionDestination", -5),ReplicationFieldMetaData("courseDiscussionPending",
            16),),
        null,
        null,
        null,
        1000),
        131 to ReplicationEntityMetaData(131, 100, "DiscussionTopic", "DiscussionTopicReplicate",
            "DiscussionTopic_ReceiveView", "discussionTopicUid", "discussionTopicLct",
            "discussionTopicPk", "discussionTopicDestination", "discussionTopicVersionId",
            "discussionTopicPending", 
        listOf(ReplicationFieldMetaData("discussionTopicUid", -5),ReplicationFieldMetaData("discussionTopicTitle", -1),ReplicationFieldMetaData("discussionTopicDesc", -1),ReplicationFieldMetaData("discussionTopicStartDate", -5),ReplicationFieldMetaData("discussionTopicCourseDiscussionUid", -5),ReplicationFieldMetaData("discussionTopicVisible",
            16),ReplicationFieldMetaData("discussionTopicArchive",
            16),ReplicationFieldMetaData("discussionTopicIndex",
            4),ReplicationFieldMetaData("discussionTopicClazzUid", -5),ReplicationFieldMetaData("discussionTopicLct", -5),),
        listOf(ReplicationFieldMetaData("discussionTopicPk", -5),ReplicationFieldMetaData("discussionTopicVersionId", -5),ReplicationFieldMetaData("discussionTopicDestination", -5),ReplicationFieldMetaData("discussionTopicPending",
            16),),
        null,
        null,
        null,
        1000),
        132 to ReplicationEntityMetaData(132, 100, "DiscussionPost", "DiscussionPostReplicate",
            "DiscussionPost_ReceiveView", "discussionPostUid", "discussionPostLct",
            "discussionPostPk", "discussionPostDestination", "discussionPostVersionId",
            "discussionPostPending", 
        listOf(ReplicationFieldMetaData("discussionPostUid", -5),ReplicationFieldMetaData("discussionPostTitle", -1),ReplicationFieldMetaData("discussionPostMessage", -1),ReplicationFieldMetaData("discussionPostStartDate", -5),ReplicationFieldMetaData("discussionPostDiscussionTopicUid", -5),ReplicationFieldMetaData("discussionPostVisible",
            16),ReplicationFieldMetaData("discussionPostArchive",
            16),ReplicationFieldMetaData("discussionPostStartedPersonUid", -5),ReplicationFieldMetaData("discussionPostClazzUid", -5),ReplicationFieldMetaData("discussionPostLct", -5),),
        listOf(ReplicationFieldMetaData("discussionPostPk", -5),ReplicationFieldMetaData("discussionPostVersionId", -5),ReplicationFieldMetaData("discussionPostDestination", -5),ReplicationFieldMetaData("discussionPostPending",
            16),),
        null,
        null,
        null,
        1000),
        )
      }


  public companion object {
    public val TABLE_ID_MAP: Map<String, Int> = mapOf<String, Int>()

  }
}
