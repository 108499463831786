package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.ClazzContentJoin
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ClazzContentJoinDao_JdbcKt(
  public val _db: DoorDatabase
) : ClazzContentJoinDao() {
  public val _insertAdapterClazzContentJoin_: EntityInsertionAdapter<ClazzContentJoin> = object :
      EntityInsertionAdapter<ClazzContentJoin>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ClazzContentJoin (ccjUid, ccjContentEntryUid, ccjClazzUid, ccjActive, ccjLocalChangeSeqNum, ccjMasterChangeSeqNum, ccjLastChangedBy, ccjLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ClazzContentJoin (ccjUid, ccjContentEntryUid, ccjClazzUid, ccjActive, ccjLocalChangeSeqNum, ccjMasterChangeSeqNum, ccjLastChangedBy, ccjLct) VALUES(COALESCE(?,nextval('ClazzContentJoin_ccjUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING ccjUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ClazzContentJoin):
        Unit {
      if(entity.ccjUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ccjUid)
      }
      stmt.setLong(2, entity.ccjContentEntryUid)
      stmt.setLong(3, entity.ccjClazzUid)
      stmt.setBoolean(4, entity.ccjActive)
      stmt.setLong(5, entity.ccjLocalChangeSeqNum)
      stmt.setLong(6, entity.ccjMasterChangeSeqNum)
      stmt.setInt(7, entity.ccjLastChangedBy)
      stmt.setLong(8, entity.ccjLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO ClazzContentJoinReplicate(ccjPk, ccjDestination)
    |      SELECT DISTINCT ClazzContentJoin.ccjUid AS ccjUid,
    |             ? AS ccjDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    4398046511104 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzContentJoin    
    |                    ON Clazz.clazzUid = ClazzContentJoin.ccjClazzUid
    |       WHERE UserSession.usClientNodeId = ? 
    |         AND UserSession.usStatus = 1             
    |         AND ClazzContentJoin.ccjLct != COALESCE(
    |             (SELECT ccjVersionId
    |                FROM ClazzContentJoinReplicate
    |               WHERE ccjPk = ClazzContentJoin.ccjUid
    |                 AND ccjDestination = ?), 0) 
    |      /*psql ON CONFLICT(ccjPk, ccjDestination) DO UPDATE
    |             SET ccjPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzContentJoinReplicate(ccjPk, ccjDestination)
    |      SELECT DISTINCT ClazzContentJoin.ccjUid AS ccjUid,
    |             ? AS ccjDestination
    |        FROM UserSession
    |               JOIN PersonGroupMember 
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |                    4398046511104 
    |                    
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |               JOIN ClazzContentJoin    
    |                    ON Clazz.clazzUid = ClazzContentJoin.ccjClazzUid
    |       WHERE UserSession.usClientNodeId = ? 
    |         AND UserSession.usStatus = 1             
    |         AND ClazzContentJoin.ccjLct != COALESCE(
    |             (SELECT ccjVersionId
    |                FROM ClazzContentJoinReplicate
    |               WHERE ccjPk = ClazzContentJoin.ccjUid
    |                 AND ccjDestination = ?), 0) 
    |       ON CONFLICT(ccjPk, ccjDestination) DO UPDATE
    |             SET ccjPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO ClazzContentJoinReplicate(ccjPk, ccjDestination)
    |  SELECT DISTINCT ClazzContentJoin.ccjUid AS ccjUid,
    |         UserSession.usClientNodeId AS ccjDestination
    |    FROM ChangeLog
    |         JOIN ClazzContentJoin
    |             ON ChangeLog.chTableId = 134
    |                AND ChangeLog.chEntityPk = ClazzContentJoin.ccjUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzContentJoin.ccjClazzUid                
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              4398046511104
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzContentJoin.ccjLct != COALESCE(
    |         (SELECT ccjVersionId
    |            FROM ClazzContentJoinReplicate
    |           WHERE ccjPk = ClazzContentJoin.ccjUid
    |             AND ccjDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ccjPk, ccjDestination) DO UPDATE
    |     SET ccjPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO ClazzContentJoinReplicate(ccjPk, ccjDestination)
    |  SELECT DISTINCT ClazzContentJoin.ccjUid AS ccjUid,
    |         UserSession.usClientNodeId AS ccjDestination
    |    FROM ChangeLog
    |         JOIN ClazzContentJoin
    |             ON ChangeLog.chTableId = 134
    |                AND ChangeLog.chEntityPk = ClazzContentJoin.ccjUid
    |         JOIN Clazz 
    |              ON Clazz.clazzUid = ClazzContentJoin.ccjClazzUid                
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |              4398046511104
    |              
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |        
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND ClazzContentJoin.ccjLct != COALESCE(
    |         (SELECT ccjVersionId
    |            FROM ClazzContentJoinReplicate
    |           WHERE ccjPk = ClazzContentJoin.ccjUid
    |             AND ccjDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ccjPk, ccjDestination) DO UPDATE
    |     SET ccjPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun toggleVisibilityClazzContent(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |UPDATE ClazzContentJoin 
    |                       SET ccjActive = ?, 
    |                           ccjLct = ? 
    |                     WHERE ccjContentEntryUid IN (?)
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |UPDATE ClazzContentJoin 
    |                       SET ccjActive = ?, 
    |                           ccjLct = ? 
    |                     WHERE ccjContentEntryUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, toggleVisibility)
      _stmt.setLong(2, changedTime)
      _stmt.setArray(3, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          selectedItem.toTypedArray()))
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun listOfEntriesInClazz(clazzUid: Long): List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT ccjContentEntryUid 
    |          FROM ClazzContentJoin
    |         WHERE ccjClazzUid = ?
    |           AND ccjActive
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT ccjContentEntryUid 
    |          FROM ClazzContentJoin
    |         WHERE ccjClazzUid = ?
    |           AND ccjActive
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ClazzContentJoin): Long {
    val _retVal = _insertAdapterClazzContentJoin_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ClazzContentJoin): Long {
    val _retVal = _insertAdapterClazzContentJoin_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ClazzContentJoin>): Unit {
    _insertAdapterClazzContentJoin_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzContentJoin>): Unit {
    val _sql =
        "UPDATE ClazzContentJoin SET ccjContentEntryUid = ?, ccjClazzUid = ?, ccjActive = ?, ccjLocalChangeSeqNum = ?, ccjMasterChangeSeqNum = ?, ccjLastChangedBy = ?, ccjLct = ? WHERE ccjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.ccjContentEntryUid)
        _stmt.setLong(2, _entity.ccjClazzUid)
        _stmt.setBoolean(3, _entity.ccjActive)
        _stmt.setLong(4, _entity.ccjLocalChangeSeqNum)
        _stmt.setLong(5, _entity.ccjMasterChangeSeqNum)
        _stmt.setInt(6, _entity.ccjLastChangedBy)
        _stmt.setLong(7, _entity.ccjLct)
        _stmt.setLong(8, _entity.ccjUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ClazzContentJoin): Unit {
    val _sql =
        "UPDATE ClazzContentJoin SET ccjContentEntryUid = ?, ccjClazzUid = ?, ccjActive = ?, ccjLocalChangeSeqNum = ?, ccjMasterChangeSeqNum = ?, ccjLastChangedBy = ?, ccjLct = ? WHERE ccjUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.ccjContentEntryUid)
      _stmt.setLong(2, entity.ccjClazzUid)
      _stmt.setBoolean(3, entity.ccjActive)
      _stmt.setLong(4, entity.ccjLocalChangeSeqNum)
      _stmt.setLong(5, entity.ccjMasterChangeSeqNum)
      _stmt.setInt(6, entity.ccjLastChangedBy)
      _stmt.setLong(7, entity.ccjLct)
      _stmt.setLong(8, entity.ccjUid)
      _stmt.executeUpdate()
    }
  }
}
