package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.PersonGroupMember
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class PersonGroupMemberDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: PersonGroupMemberDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : PersonGroupMemberDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    _dao.replicateOnChangeClazzBased()
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeClazzBased(newNodeId)
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    _dao.replicateOnChangeSchoolBased()
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    _dao.replicateOnNewNodeSchoolBased(newNodeId)
  }

  public override suspend fun findAllGroupWherePersonIsIn(personUid: Long):
      List<PersonGroupMember> {
    val _result = _dao.findAllGroupWherePersonIsIn(personUid)
    return _result
  }

  public override suspend fun checkPersonBelongsToGroup(groupUid: Long, personUid: Long):
      List<PersonGroupMember> {
    val _result = _dao.checkPersonBelongsToGroup(groupUid, personUid)
    return _result
  }

  public override suspend fun moveGroupAsync(
    personUid: Long,
    newGroup: Long,
    oldGroup: Long,
    changeTime: Long
  ): Int {
    val _result = _dao.moveGroupAsync(personUid, newGroup, oldGroup, changeTime)
    return _result
  }

  public override suspend fun updateGroupMemberActive(
    activeStatus: Boolean,
    personUid: Long,
    groupUid: Long,
    updateTime: Long
  ): Unit {
    _dao.updateGroupMemberActive(activeStatus, personUid, groupUid, updateTime)
  }

  public override suspend fun findByPersonUidAndGroupUid(personUid: Long, groupUid: Long):
      PersonGroupMember? {
    val _result = _dao.findByPersonUidAndGroupUid(personUid, groupUid)
    return _result
  }

  public override fun insert(entity: PersonGroupMember): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: PersonGroupMember): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out PersonGroupMember>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out PersonGroupMember>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: PersonGroupMember): Unit {
    _dao.update(entity)
  }
}
