package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.LeavingReason
import com.ustadmobile.lib.db.entities.UidAndLabel
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class LeavingReasonDao_JdbcKt(
  public val _db: DoorDatabase
) : LeavingReasonDao() {
  public val _insertAdapterLeavingReason_upsert: EntityInsertionAdapter<LeavingReason> = object :
      EntityInsertionAdapter<LeavingReason>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO LeavingReason (leavingReasonUid, leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct) VALUES(?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO LeavingReason (leavingReasonUid, leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct) VALUES(COALESCE(?,nextval('LeavingReason_leavingReasonUid_seq')), ?, ?, ?, ?, ?) ON CONFLICT (leavingReasonUid) DO UPDATE SET leavingReasonTitle = excluded.leavingReasonTitle,leavingReasonMCSN = excluded.leavingReasonMCSN,leavingReasonCSN = excluded.leavingReasonCSN,leavingReasonLCB = excluded.leavingReasonLCB,leavingReasonLct = excluded.leavingReasonLct" + if(returnsId) { " RETURNING leavingReasonUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: LeavingReason):
        Unit {
      if(entity.leavingReasonUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.leavingReasonUid)
      }
      stmt.setString(2, entity.leavingReasonTitle)
      stmt.setLong(3, entity.leavingReasonMCSN)
      stmt.setLong(4, entity.leavingReasonCSN)
      stmt.setInt(5, entity.leavingReasonLCB)
      stmt.setLong(6, entity.leavingReasonLct)
    }
  }

  public val _insertAdapterLeavingReason_: EntityInsertionAdapter<LeavingReason> = object :
      EntityInsertionAdapter<LeavingReason>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO LeavingReason (leavingReasonUid, leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct) VALUES(?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO LeavingReason (leavingReasonUid, leavingReasonTitle, leavingReasonMCSN, leavingReasonCSN, leavingReasonLCB, leavingReasonLct) VALUES(COALESCE(?,nextval('LeavingReason_leavingReasonUid_seq')), ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING leavingReasonUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: LeavingReason):
        Unit {
      if(entity.leavingReasonUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.leavingReasonUid)
      }
      stmt.setString(2, entity.leavingReasonTitle)
      stmt.setLong(3, entity.leavingReasonMCSN)
      stmt.setLong(4, entity.leavingReasonCSN)
      stmt.setInt(5, entity.leavingReasonLCB)
      stmt.setLong(6, entity.leavingReasonLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |         REPLACE INTO LeavingReasonReplicate(lrPk, lrDestination)
    |          SELECT DISTINCT LeavingReason.leavingReasonUid AS lrPk,
    |                 ? AS lrDestination
    |            FROM LeavingReason
    |           WHERE LeavingReason.leavingReasonLct != COALESCE(
    |                 (SELECT lrVersionId
    |                    FROM LeavingReasonReplicate
    |                   WHERE lrPk = LeavingReason.leavingReasonUid
    |                     AND lrDestination = ?), 0) 
    |          /*psql ON CONFLICT(lrPk, lrDestination) DO UPDATE
    |                 SET lrPending = true
    |          */       
    |     
    """.trimMargin() , postgreSql = """
    |INSERT INTO LeavingReasonReplicate(lrPk, lrDestination)
    |          SELECT DISTINCT LeavingReason.leavingReasonUid AS lrPk,
    |                 ? AS lrDestination
    |            FROM LeavingReason
    |           WHERE LeavingReason.leavingReasonLct != COALESCE(
    |                 (SELECT lrVersionId
    |                    FROM LeavingReasonReplicate
    |                   WHERE lrPk = LeavingReason.leavingReasonUid
    |                     AND lrDestination = ?), 0) 
    |           ON CONFLICT(lrPk, lrDestination) DO UPDATE
    |                 SET lrPending = true
    |                 
    |     
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO LeavingReasonReplicate(lrPk, lrDestination)
    |  SELECT DISTINCT LeavingReason.leavingReasonUid AS lrUid,
    |         UserSession.usClientNodeId AS lrDestination
    |    FROM ChangeLog
    |         JOIN LeavingReason
    |              ON ChangeLog.chTableId = 410
    |                 AND ChangeLog.chEntityPk = LeavingReason.leavingReasonUid
    |         JOIN UserSession 
    |              ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LeavingReason.leavingReasonLct != COALESCE(
    |         (SELECT lrVersionId
    |            FROM LeavingReasonReplicate
    |           WHERE lrPk = LeavingReason.leavingReasonUid
    |             AND lrDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(lrPk, lrDestination) DO UPDATE
    |     SET lrPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LeavingReasonReplicate(lrPk, lrDestination)
    |  SELECT DISTINCT LeavingReason.leavingReasonUid AS lrUid,
    |         UserSession.usClientNodeId AS lrDestination
    |    FROM ChangeLog
    |         JOIN LeavingReason
    |              ON ChangeLog.chTableId = 410
    |                 AND ChangeLog.chEntityPk = LeavingReason.leavingReasonUid
    |         JOIN UserSession 
    |              ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LeavingReason.leavingReasonLct != COALESCE(
    |         (SELECT lrVersionId
    |            FROM LeavingReasonReplicate
    |           WHERE lrPk = LeavingReason.leavingReasonUid
    |             AND lrDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(lrPk, lrDestination) DO UPDATE
    |     SET lrPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllReasons(): DoorDataSourceFactory<Int, LeavingReason> {
    val _result = object : DoorDataSourceFactory<Int, LeavingReason>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<LeavingReason>> =
          DoorLiveDataImpl<List<LeavingReason>>(_db, listOf("LeavingReason"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.LeavingReason>()
        val _stmtConfig =
            PreparedStatementConfig("SELECT * FROM (SELECT * FROM LeavingReason) LIMIT ? OFFSET ? "
            , postgreSql = """
        |SELECT * FROM (SELECT * FROM LeavingReason) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, _limit)
          _stmt.setInt(2, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_leavingReasonUid = _resultSet.getLong("leavingReasonUid")
              val tmp_leavingReasonTitle = _resultSet.getString("leavingReasonTitle")
              val tmp_leavingReasonMCSN = _resultSet.getLong("leavingReasonMCSN")
              val tmp_leavingReasonCSN = _resultSet.getLong("leavingReasonCSN")
              val tmp_leavingReasonLCB = _resultSet.getInt("leavingReasonLCB")
              val tmp_leavingReasonLct = _resultSet.getLong("leavingReasonLct")
              val _entity = LeavingReason()
              _entity.leavingReasonUid = tmp_leavingReasonUid
              _entity.leavingReasonTitle = tmp_leavingReasonTitle
              _entity.leavingReasonMCSN = tmp_leavingReasonMCSN
              _entity.leavingReasonCSN = tmp_leavingReasonCSN
              _entity.leavingReasonLCB = tmp_leavingReasonLCB
              _entity.leavingReasonLct = tmp_leavingReasonLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("LeavingReason"))  {
        var _liveResult = 0
        val _stmtConfig =
            PreparedStatementConfig("SELECT COUNT(*) FROM (SELECT * FROM LeavingReason) " ,
            postgreSql = """
        |SELECT COUNT(*) FROM (SELECT * FROM LeavingReason) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findAllReasonsLive(): List<LeavingReason> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.LeavingReason>()
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM LeavingReason" , postgreSql = """
    |SELECT * FROM LeavingReason
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_leavingReasonUid = _resultSet.getLong("leavingReasonUid")
          val tmp_leavingReasonTitle = _resultSet.getString("leavingReasonTitle")
          val tmp_leavingReasonMCSN = _resultSet.getLong("leavingReasonMCSN")
          val tmp_leavingReasonCSN = _resultSet.getLong("leavingReasonCSN")
          val tmp_leavingReasonLCB = _resultSet.getInt("leavingReasonLCB")
          val tmp_leavingReasonLct = _resultSet.getLong("leavingReasonLct")
          val _entity = LeavingReason()
          _entity.leavingReasonUid = tmp_leavingReasonUid
          _entity.leavingReasonTitle = tmp_leavingReasonTitle
          _entity.leavingReasonMCSN = tmp_leavingReasonMCSN
          _entity.leavingReasonCSN = tmp_leavingReasonCSN
          _entity.leavingReasonLCB = tmp_leavingReasonLCB
          _entity.leavingReasonLct = tmp_leavingReasonLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): LeavingReason? {
    var _result = null as com.ustadmobile.lib.db.entities.LeavingReason??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM LeavingReason WHERE leavingReasonUid = ?" ,
        postgreSql = """
    |SELECT * FROM LeavingReason WHERE leavingReasonUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_leavingReasonUid = _resultSet.getLong("leavingReasonUid")
          val tmp_leavingReasonTitle = _resultSet.getString("leavingReasonTitle")
          val tmp_leavingReasonMCSN = _resultSet.getLong("leavingReasonMCSN")
          val tmp_leavingReasonCSN = _resultSet.getLong("leavingReasonCSN")
          val tmp_leavingReasonLCB = _resultSet.getInt("leavingReasonLCB")
          val tmp_leavingReasonLct = _resultSet.getLong("leavingReasonLct")
          val _entity = LeavingReason()
          _entity.leavingReasonUid = tmp_leavingReasonUid
          _entity.leavingReasonTitle = tmp_leavingReasonTitle
          _entity.leavingReasonMCSN = tmp_leavingReasonMCSN
          _entity.leavingReasonCSN = tmp_leavingReasonCSN
          _entity.leavingReasonLCB = tmp_leavingReasonLCB
          _entity.leavingReasonLct = tmp_leavingReasonLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidList(uidList: List<Long>): List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT leavingReasonUid FROM LeavingReason WHERE leavingReasonUid IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT leavingReasonUid FROM LeavingReason WHERE leavingReasonUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", uidList.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<LeavingReason?> {
    val _result = DoorLiveDataImpl<LeavingReason?>(_db, listOf("LeavingReason"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.LeavingReason??
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM LeavingReason WHERE leavingReasonUid = ?" ,
          postgreSql = """
      |SELECT * FROM LeavingReason WHERE leavingReasonUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_leavingReasonUid = _resultSet.getLong("leavingReasonUid")
            val tmp_leavingReasonTitle = _resultSet.getString("leavingReasonTitle")
            val tmp_leavingReasonMCSN = _resultSet.getLong("leavingReasonMCSN")
            val tmp_leavingReasonCSN = _resultSet.getLong("leavingReasonCSN")
            val tmp_leavingReasonLCB = _resultSet.getInt("leavingReasonLCB")
            val tmp_leavingReasonLct = _resultSet.getLong("leavingReasonLct")
            val _entity = LeavingReason()
            _entity.leavingReasonUid = tmp_leavingReasonUid
            _entity.leavingReasonTitle = tmp_leavingReasonTitle
            _entity.leavingReasonMCSN = tmp_leavingReasonMCSN
            _entity.leavingReasonCSN = tmp_leavingReasonCSN
            _entity.leavingReasonLCB = tmp_leavingReasonLCB
            _entity.leavingReasonLct = tmp_leavingReasonLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun getReasonsFromUids(uidList: List<Long>): List<UidAndLabel> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.UidAndLabel>()
    val _stmtConfig = PreparedStatementConfig("""
    |SELECT LeavingReason.leavingReasonUid AS uid, 
    |            LeavingReason.leavingReasonTitle As labelName  
    |            FROM LeavingReason WHERE leavingReasonUid IN (?)
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |SELECT LeavingReason.leavingReasonUid AS uid, 
    |            LeavingReason.leavingReasonTitle As labelName  
    |            FROM LeavingReason WHERE leavingReasonUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", uidList.toTypedArray()))
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_uid = _resultSet.getLong("uid")
          val tmp_labelName = _resultSet.getString("labelName")
          val _entity = UidAndLabel()
          _entity.uid = tmp_uid
          _entity.labelName = tmp_labelName
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun replaceList(entityList: List<LeavingReason>): Unit {
    _insertAdapterLeavingReason_upsert.insertListAsync(entityList)
  }

  public override suspend fun updateAsync(entity: LeavingReason): Int {
    var _result = 0
    val _sql =
        "UPDATE LeavingReason SET leavingReasonTitle = ?, leavingReasonMCSN = ?, leavingReasonCSN = ?, leavingReasonLCB = ?, leavingReasonLct = ? WHERE leavingReasonUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.leavingReasonTitle)
      _stmt.setLong(2, entity.leavingReasonMCSN)
      _stmt.setLong(3, entity.leavingReasonCSN)
      _stmt.setInt(4, entity.leavingReasonLCB)
      _stmt.setLong(5, entity.leavingReasonLct)
      _stmt.setLong(6, entity.leavingReasonUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun insert(entity: LeavingReason): Long {
    val _retVal = _insertAdapterLeavingReason_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: LeavingReason): Long {
    val _retVal = _insertAdapterLeavingReason_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out LeavingReason>): Unit {
    _insertAdapterLeavingReason_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out LeavingReason>): Unit {
    val _sql =
        "UPDATE LeavingReason SET leavingReasonTitle = ?, leavingReasonMCSN = ?, leavingReasonCSN = ?, leavingReasonLCB = ?, leavingReasonLct = ? WHERE leavingReasonUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.leavingReasonTitle)
        _stmt.setLong(2, _entity.leavingReasonMCSN)
        _stmt.setLong(3, _entity.leavingReasonCSN)
        _stmt.setInt(4, _entity.leavingReasonLCB)
        _stmt.setLong(5, _entity.leavingReasonLct)
        _stmt.setLong(6, _entity.leavingReasonUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: LeavingReason): Unit {
    val _sql =
        "UPDATE LeavingReason SET leavingReasonTitle = ?, leavingReasonMCSN = ?, leavingReasonCSN = ?, leavingReasonLCB = ?, leavingReasonLct = ? WHERE leavingReasonUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.leavingReasonTitle)
      _stmt.setLong(2, entity.leavingReasonMCSN)
      _stmt.setLong(3, entity.leavingReasonCSN)
      _stmt.setInt(4, entity.leavingReasonLCB)
      _stmt.setLong(5, entity.leavingReasonLct)
      _stmt.setLong(6, entity.leavingReasonUid)
      _stmt.executeUpdate()
    }
  }
}
