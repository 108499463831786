package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Language
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class LanguageDao_JdbcKt(
  public val _db: DoorDatabase
) : LanguageDao() {
  public val _insertAdapterLanguage_: EntityInsertionAdapter<Language> = object :
      EntityInsertionAdapter<Language>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Language (langUid, name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Language (langUid, name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct) VALUES(COALESCE(?,nextval('Language_langUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING langUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Language): Unit {
      if(entity.langUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.langUid)
      }
      stmt.setString(2, entity.name)
      stmt.setString(3, entity.iso_639_1_standard)
      stmt.setString(4, entity.iso_639_2_standard)
      stmt.setString(5, entity.iso_639_3_standard)
      stmt.setString(6, entity.Language_Type)
      stmt.setBoolean(7, entity.languageActive)
      stmt.setLong(8, entity.langLocalChangeSeqNum)
      stmt.setLong(9, entity.langMasterChangeSeqNum)
      stmt.setInt(10, entity.langLastChangedBy)
      stmt.setLong(11, entity.langLct)
    }
  }

  public val _insertAdapterLanguage_upsert: EntityInsertionAdapter<Language> = object :
      EntityInsertionAdapter<Language>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO Language (langUid, name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Language (langUid, name, iso_639_1_standard, iso_639_2_standard, iso_639_3_standard, Language_Type, languageActive, langLocalChangeSeqNum, langMasterChangeSeqNum, langLastChangedBy, langLct) VALUES(COALESCE(?,nextval('Language_langUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (langUid) DO UPDATE SET name = excluded.name,iso_639_1_standard = excluded.iso_639_1_standard,iso_639_2_standard = excluded.iso_639_2_standard,iso_639_3_standard = excluded.iso_639_3_standard,Language_Type = excluded.Language_Type,languageActive = excluded.languageActive,langLocalChangeSeqNum = excluded.langLocalChangeSeqNum,langMasterChangeSeqNum = excluded.langMasterChangeSeqNum,langLastChangedBy = excluded.langLastChangedBy,langLct = excluded.langLct" + if(returnsId) { " RETURNING langUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Language): Unit {
      if(entity.langUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.langUid)
      }
      stmt.setString(2, entity.name)
      stmt.setString(3, entity.iso_639_1_standard)
      stmt.setString(4, entity.iso_639_2_standard)
      stmt.setString(5, entity.iso_639_3_standard)
      stmt.setString(6, entity.Language_Type)
      stmt.setBoolean(7, entity.languageActive)
      stmt.setLong(8, entity.langLocalChangeSeqNum)
      stmt.setLong(9, entity.langMasterChangeSeqNum)
      stmt.setInt(10, entity.langLastChangedBy)
      stmt.setLong(11, entity.langLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO LanguageReplicate(languagePk, languageDestination)
    |      SELECT DISTINCT Language.langUid AS languagePk,
    |             ? AS languageDestination
    |        FROM Language
    |       WHERE Language.langLct != COALESCE(
    |             (SELECT languageVersionId
    |                FROM LanguageReplicate
    |               WHERE languagePk = Language.langUid
    |                 AND languageDestination = ?), 0) 
    |      /*psql ON CONFLICT(languagePk, languageDestination) DO UPDATE
    |             SET languagePending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LanguageReplicate(languagePk, languageDestination)
    |      SELECT DISTINCT Language.langUid AS languagePk,
    |             ? AS languageDestination
    |        FROM Language
    |       WHERE Language.langLct != COALESCE(
    |             (SELECT languageVersionId
    |                FROM LanguageReplicate
    |               WHERE languagePk = Language.langUid
    |                 AND languageDestination = ?), 0) 
    |       ON CONFLICT(languagePk, languageDestination) DO UPDATE
    |             SET languagePending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO LanguageReplicate(languagePk, languageDestination)
    |  SELECT DISTINCT Language.langUid AS languageUid,
    |         UserSession.usClientNodeId AS languageDestination
    |    FROM ChangeLog
    |         JOIN Language
    |             ON ChangeLog.chTableId = 13
    |                AND ChangeLog.chEntityPk = Language.langUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Language.langLct != COALESCE(
    |         (SELECT languageVersionId
    |            FROM LanguageReplicate
    |           WHERE languagePk = Language.langUid
    |             AND languageDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(languagePk, languageDestination) DO UPDATE
    |     SET languagePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LanguageReplicate(languagePk, languageDestination)
    |  SELECT DISTINCT Language.langUid AS languageUid,
    |         UserSession.usClientNodeId AS languageDestination
    |    FROM ChangeLog
    |         JOIN Language
    |             ON ChangeLog.chTableId = 13
    |                AND ChangeLog.chEntityPk = Language.langUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND Language.langLct != COALESCE(
    |         (SELECT languageVersionId
    |            FROM LanguageReplicate
    |           WHERE languagePk = Language.langUid
    |             AND languageDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(languagePk, languageDestination) DO UPDATE
    |     SET languagePending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun insertListAsync(languageList: List<Language>): Unit {
    _insertAdapterLanguage_.insertListAsync(languageList)
  }

  public override fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      DoorDataSourceFactory<Int, Language> {
    val _result = object : DoorDataSourceFactory<Int, Language>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<Language>> =
          DoorLiveDataImpl<List<Language>>(_db, listOf("Language"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.Language>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT Language.* 
        |        FROM Language
        |        WHERE name LIKE ?
        |        ORDER BY CASE(?)
        |            WHEN 1 THEN Language.name 
        |            WHEN 3 THEN Language.iso_639_1_standard 
        |            WHEN 5 THEN Language.iso_639_2_standard 
        |            ELSE ''
        |        END ASC,
        |        CASE(?)
        |            WHEN 2 THEN Language.name 
        |            WHEN 4 THEN Language.iso_639_1_standard 
        |            WHEN 6 THEN Language.iso_639_2_standard 
        |            ELSE ''
        |        END DESC
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT Language.* 
        |        FROM Language
        |        WHERE name LIKE ?
        |        ORDER BY CASE(?)
        |            WHEN 1 THEN Language.name 
        |            WHEN 3 THEN Language.iso_639_1_standard 
        |            WHEN 5 THEN Language.iso_639_2_standard 
        |            ELSE ''
        |        END ASC,
        |        CASE(?)
        |            WHEN 2 THEN Language.name 
        |            WHEN 4 THEN Language.iso_639_1_standard 
        |            WHEN 6 THEN Language.iso_639_2_standard 
        |            ELSE ''
        |        END DESC
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setString(1, searchText)
          _stmt.setInt(2, sortOrder)
          _stmt.setInt(3, sortOrder)
          _stmt.setInt(4, _limit)
          _stmt.setInt(5, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_langUid = _resultSet.getLong("langUid")
              val tmp_name = _resultSet.getString("name")
              val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
              val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
              val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
              val tmp_Language_Type = _resultSet.getString("Language_Type")
              val tmp_languageActive = _resultSet.getBoolean("languageActive")
              val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
              val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
              val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
              val tmp_langLct = _resultSet.getLong("langLct")
              val _entity = Language()
              _entity.langUid = tmp_langUid
              _entity.name = tmp_name
              _entity.iso_639_1_standard = tmp_iso_639_1_standard
              _entity.iso_639_2_standard = tmp_iso_639_2_standard
              _entity.iso_639_3_standard = tmp_iso_639_3_standard
              _entity.Language_Type = tmp_Language_Type
              _entity.languageActive = tmp_languageActive
              _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
              _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
              _entity.langLastChangedBy = tmp_langLastChangedBy
              _entity.langLct = tmp_langLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Language"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT Language.* 
        |        FROM Language
        |        WHERE name LIKE ?
        |        ORDER BY CASE(?)
        |            WHEN 1 THEN Language.name 
        |            WHEN 3 THEN Language.iso_639_1_standard 
        |            WHEN 5 THEN Language.iso_639_2_standard 
        |            ELSE ''
        |        END ASC,
        |        CASE(?)
        |            WHEN 2 THEN Language.name 
        |            WHEN 4 THEN Language.iso_639_1_standard 
        |            WHEN 6 THEN Language.iso_639_2_standard 
        |            ELSE ''
        |        END DESC
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT Language.* 
        |        FROM Language
        |        WHERE name LIKE ?
        |        ORDER BY CASE(?)
        |            WHEN 1 THEN Language.name 
        |            WHEN 3 THEN Language.iso_639_1_standard 
        |            WHEN 5 THEN Language.iso_639_2_standard 
        |            ELSE ''
        |        END ASC,
        |        CASE(?)
        |            WHEN 2 THEN Language.name 
        |            WHEN 4 THEN Language.iso_639_1_standard 
        |            WHEN 6 THEN Language.iso_639_2_standard 
        |            ELSE ''
        |        END DESC
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setString(1, searchText)
          _stmt.setInt(2, sortOrder)
          _stmt.setInt(3, sortOrder)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findLanguagesList(): List<Language> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.Language>()
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Language" , postgreSql = """
    |SELECT * FROM Language
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          val tmp_langLct = _resultSet.getLong("langLct")
          val _entity = Language()
          _entity.langUid = tmp_langUid
          _entity.name = tmp_name
          _entity.iso_639_1_standard = tmp_iso_639_1_standard
          _entity.iso_639_2_standard = tmp_iso_639_2_standard
          _entity.iso_639_3_standard = tmp_iso_639_3_standard
          _entity.Language_Type = tmp_Language_Type
          _entity.languageActive = tmp_languageActive
          _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
          _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
          _entity.langLastChangedBy = tmp_langLastChangedBy
          _entity.langLct = tmp_langLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByName(name: String): Language? {
    var _result = null as com.ustadmobile.lib.db.entities.Language??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM Language WHERE name = ? LIMIT 1" ,
        postgreSql = """
    |SELECT * FROM Language WHERE name = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, name)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          val tmp_langLct = _resultSet.getLong("langLct")
          val _entity = Language()
          _entity.langUid = tmp_langUid
          _entity.name = tmp_name
          _entity.iso_639_1_standard = tmp_iso_639_1_standard
          _entity.iso_639_2_standard = tmp_iso_639_2_standard
          _entity.iso_639_3_standard = tmp_iso_639_3_standard
          _entity.Language_Type = tmp_Language_Type
          _entity.languageActive = tmp_languageActive
          _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
          _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
          _entity.langLastChangedBy = tmp_langLastChangedBy
          _entity.langLct = tmp_langLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByTwoCode(langCode: String): Language? {
    var _result = null as com.ustadmobile.lib.db.entities.Language??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Language WHERE iso_639_1_standard = ? LIMIT 1" ,
        postgreSql = """
    |SELECT * FROM Language WHERE iso_639_1_standard = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, langCode)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          val tmp_langLct = _resultSet.getLong("langLct")
          val _entity = Language()
          _entity.langUid = tmp_langUid
          _entity.name = tmp_name
          _entity.iso_639_1_standard = tmp_iso_639_1_standard
          _entity.iso_639_2_standard = tmp_iso_639_2_standard
          _entity.iso_639_3_standard = tmp_iso_639_3_standard
          _entity.Language_Type = tmp_Language_Type
          _entity.languageActive = tmp_languageActive
          _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
          _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
          _entity.langLastChangedBy = tmp_langLastChangedBy
          _entity.langLct = tmp_langLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByTwoCodeAsync(langCode: String): Language? {
    var _result = null as com.ustadmobile.lib.db.entities.Language??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Language WHERE iso_639_1_standard = ? LIMIT 1" ,
        postgreSql = """
    |SELECT * FROM Language WHERE iso_639_1_standard = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, langCode)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          val tmp_langLct = _resultSet.getLong("langLct")
          val _entity = Language()
          _entity.langUid = tmp_langUid
          _entity.name = tmp_name
          _entity.iso_639_1_standard = tmp_iso_639_1_standard
          _entity.iso_639_2_standard = tmp_iso_639_2_standard
          _entity.iso_639_3_standard = tmp_iso_639_3_standard
          _entity.Language_Type = tmp_Language_Type
          _entity.languageActive = tmp_languageActive
          _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
          _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
          _entity.langLastChangedBy = tmp_langLastChangedBy
          _entity.langLct = tmp_langLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByThreeCode(langCode: String): Language? {
    var _result = null as com.ustadmobile.lib.db.entities.Language??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM LANGUAGE WHERE iso_639_3_standard = ? OR iso_639_2_standard = ? LIMIT 1 "
        , postgreSql = """
    |SELECT * FROM LANGUAGE WHERE iso_639_3_standard = ? OR iso_639_2_standard = ? LIMIT 1 
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, langCode)
      _stmt.setString(2, langCode)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          val tmp_langLct = _resultSet.getLong("langLct")
          val _entity = Language()
          _entity.langUid = tmp_langUid
          _entity.name = tmp_name
          _entity.iso_639_1_standard = tmp_iso_639_1_standard
          _entity.iso_639_2_standard = tmp_iso_639_2_standard
          _entity.iso_639_3_standard = tmp_iso_639_3_standard
          _entity.Language_Type = tmp_Language_Type
          _entity.languageActive = tmp_languageActive
          _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
          _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
          _entity.langLastChangedBy = tmp_langLastChangedBy
          _entity.langLct = tmp_langLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun totalLanguageCount(): Int {
    var _result = 0
    val _stmtConfig = PreparedStatementConfig("SELECT COUNT(*) FROM LANGUAGE" , postgreSql = """
    |SELECT COUNT(*) FROM LANGUAGE
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getInt(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun update(entity: Language): Unit {
    val _sql =
        "UPDATE Language SET name = ?, iso_639_1_standard = ?, iso_639_2_standard = ?, iso_639_3_standard = ?, Language_Type = ?, languageActive = ?, langLocalChangeSeqNum = ?, langMasterChangeSeqNum = ?, langLastChangedBy = ?, langLct = ? WHERE langUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.name)
      _stmt.setString(2, entity.iso_639_1_standard)
      _stmt.setString(3, entity.iso_639_2_standard)
      _stmt.setString(4, entity.iso_639_3_standard)
      _stmt.setString(5, entity.Language_Type)
      _stmt.setBoolean(6, entity.languageActive)
      _stmt.setLong(7, entity.langLocalChangeSeqNum)
      _stmt.setLong(8, entity.langMasterChangeSeqNum)
      _stmt.setInt(9, entity.langLastChangedBy)
      _stmt.setLong(10, entity.langLct)
      _stmt.setLong(11, entity.langUid)
      _stmt.executeUpdate()
    }
  }

  public override fun findByUid(primaryLanguageUid: Long): Language? {
    var _result = null as com.ustadmobile.lib.db.entities.Language??
    val _stmtConfig = PreparedStatementConfig("SELECT *  FROM LANGUAGE where langUid = ? LIMIT 1" ,
        postgreSql = """
    |SELECT *  FROM LANGUAGE where langUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, primaryLanguageUid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          val tmp_langLct = _resultSet.getLong("langLct")
          val _entity = Language()
          _entity.langUid = tmp_langUid
          _entity.name = tmp_name
          _entity.iso_639_1_standard = tmp_iso_639_1_standard
          _entity.iso_639_2_standard = tmp_iso_639_2_standard
          _entity.iso_639_3_standard = tmp_iso_639_3_standard
          _entity.Language_Type = tmp_Language_Type
          _entity.languageActive = tmp_languageActive
          _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
          _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
          _entity.langLastChangedBy = tmp_langLastChangedBy
          _entity.langLct = tmp_langLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(primaryLanguageUid: Long): Language? {
    var _result = null as com.ustadmobile.lib.db.entities.Language??
    val _stmtConfig = PreparedStatementConfig("SELECT *  FROM LANGUAGE where langUid = ? LIMIT 1" ,
        postgreSql = """
    |SELECT *  FROM LANGUAGE where langUid = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, primaryLanguageUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_name = _resultSet.getString("name")
          val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
          val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
          val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
          val tmp_Language_Type = _resultSet.getString("Language_Type")
          val tmp_languageActive = _resultSet.getBoolean("languageActive")
          val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
          val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
          val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
          val tmp_langLct = _resultSet.getLong("langLct")
          val _entity = Language()
          _entity.langUid = tmp_langUid
          _entity.name = tmp_name
          _entity.iso_639_1_standard = tmp_iso_639_1_standard
          _entity.iso_639_2_standard = tmp_iso_639_2_standard
          _entity.iso_639_3_standard = tmp_iso_639_3_standard
          _entity.Language_Type = tmp_Language_Type
          _entity.languageActive = tmp_languageActive
          _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
          _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
          _entity.langLastChangedBy = tmp_langLastChangedBy
          _entity.langLct = tmp_langLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: Language): Int {
    var _result = 0
    val _sql =
        "UPDATE Language SET name = ?, iso_639_1_standard = ?, iso_639_2_standard = ?, iso_639_3_standard = ?, Language_Type = ?, languageActive = ?, langLocalChangeSeqNum = ?, langMasterChangeSeqNum = ?, langLastChangedBy = ?, langLct = ? WHERE langUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.name)
      _stmt.setString(2, entity.iso_639_1_standard)
      _stmt.setString(3, entity.iso_639_2_standard)
      _stmt.setString(4, entity.iso_639_3_standard)
      _stmt.setString(5, entity.Language_Type)
      _stmt.setBoolean(6, entity.languageActive)
      _stmt.setLong(7, entity.langLocalChangeSeqNum)
      _stmt.setLong(8, entity.langMasterChangeSeqNum)
      _stmt.setInt(9, entity.langLastChangedBy)
      _stmt.setLong(10, entity.langLct)
      _stmt.setLong(11, entity.langUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun findAllLanguageLive(): DoorLiveData<List<Language>> {
    val _result = DoorLiveDataImpl<List<Language>>(_db, listOf("LANGUAGE"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.Language>()
      val _stmtConfig = PreparedStatementConfig("SELECT * FROM LANGUAGE" , postgreSql = """
      |SELECT * FROM LANGUAGE
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_langUid = _resultSet.getLong("langUid")
            val tmp_name = _resultSet.getString("name")
            val tmp_iso_639_1_standard = _resultSet.getString("iso_639_1_standard")
            val tmp_iso_639_2_standard = _resultSet.getString("iso_639_2_standard")
            val tmp_iso_639_3_standard = _resultSet.getString("iso_639_3_standard")
            val tmp_Language_Type = _resultSet.getString("Language_Type")
            val tmp_languageActive = _resultSet.getBoolean("languageActive")
            val tmp_langLocalChangeSeqNum = _resultSet.getLong("langLocalChangeSeqNum")
            val tmp_langMasterChangeSeqNum = _resultSet.getLong("langMasterChangeSeqNum")
            val tmp_langLastChangedBy = _resultSet.getInt("langLastChangedBy")
            val tmp_langLct = _resultSet.getLong("langLct")
            val _entity = Language()
            _entity.langUid = tmp_langUid
            _entity.name = tmp_name
            _entity.iso_639_1_standard = tmp_iso_639_1_standard
            _entity.iso_639_2_standard = tmp_iso_639_2_standard
            _entity.iso_639_3_standard = tmp_iso_639_3_standard
            _entity.Language_Type = tmp_Language_Type
            _entity.languageActive = tmp_languageActive
            _entity.langLocalChangeSeqNum = tmp_langLocalChangeSeqNum
            _entity.langMasterChangeSeqNum = tmp_langMasterChangeSeqNum
            _entity.langLastChangedBy = tmp_langLastChangedBy
            _entity.langLct = tmp_langLct
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override fun findByUidList(uidList: List<Long>): List<Long> {
    var _result = mutableListOf<kotlin.Long>()
    val _stmtConfig = PreparedStatementConfig("SELECT langUid FROM LANGUAGE WHERE langUid IN (?)"
        ,hasListParams = true, postgreSql = """
    |SELECT langUid FROM LANGUAGE WHERE langUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setArray(1, _db.createArrayOf(_stmt.getConnection(), "BIGINT", uidList.toTypedArray()))
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE Language 
    |           SET languageActive = ?, 
    |               langLct = ?
    |         WHERE langUid IN (?)
    """.trimMargin() ,hasListParams = true, postgreSql = """
    |
    |        UPDATE Language 
    |           SET languageActive = ?, 
    |               langLct = ?
    |         WHERE langUid IN (?)
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, toggleVisibility)
      _stmt.setLong(2, updateTime)
      _stmt.setArray(3, _db.createArrayOf(_stmt.getConnection(), "BIGINT",
          selectedItem.toTypedArray()))
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun replaceList(entityList: List<Language>): Unit {
    _insertAdapterLanguage_upsert.insertList(entityList)
  }

  public override fun insert(entity: Language): Long {
    val _retVal = _insertAdapterLanguage_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Language): Long {
    val _retVal = _insertAdapterLanguage_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Language>): Unit {
    _insertAdapterLanguage_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Language>): Unit {
    val _sql =
        "UPDATE Language SET name = ?, iso_639_1_standard = ?, iso_639_2_standard = ?, iso_639_3_standard = ?, Language_Type = ?, languageActive = ?, langLocalChangeSeqNum = ?, langMasterChangeSeqNum = ?, langLastChangedBy = ?, langLct = ? WHERE langUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.name)
        _stmt.setString(2, _entity.iso_639_1_standard)
        _stmt.setString(3, _entity.iso_639_2_standard)
        _stmt.setString(4, _entity.iso_639_3_standard)
        _stmt.setString(5, _entity.Language_Type)
        _stmt.setBoolean(6, _entity.languageActive)
        _stmt.setLong(7, _entity.langLocalChangeSeqNum)
        _stmt.setLong(8, _entity.langMasterChangeSeqNum)
        _stmt.setInt(9, _entity.langLastChangedBy)
        _stmt.setLong(10, _entity.langLct)
        _stmt.setLong(11, _entity.langUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }
}
