package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.ext.doorPrimaryKeyManager
import com.ustadmobile.door.ext.rootDatabase
import com.ustadmobile.door.util.systemTimeInMillis
import com.ustadmobile.lib.db.entities.PersonParentJoin
import com.ustadmobile.lib.db.entities.PersonParentJoinWithMinorPerson
import kotlin.Boolean
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class PersonParentJoinDao_ReplicateWrapper(
  private val _db: DoorDatabase,
  private val _dao: PersonParentJoinDao
) : PersonParentJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertAsync(entity: PersonParentJoin): Long {
    val _pkManager = _db.rootDatabase.doorPrimaryKeyManager
    if(entity.ppjUid == 0L) {
      val _newPk = _pkManager.nextIdAsync(512)
      entity.ppjUid = _newPk
    }
    entity.ppjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.insertAsync(entity)
    return entity.ppjUid
  }

  public override suspend fun findByUidWithMinorAsync(uid: Long): PersonParentJoinWithMinorPerson? =
      _dao.findByUidWithMinorAsync(uid)

  public override suspend fun findByUidWithMinorAsyncFromWeb(uid: Long):
      PersonParentJoinWithMinorPerson? = _dao.findByUidWithMinorAsyncFromWeb(uid)

  public override suspend fun findByMinorPersonUid(minorPersonUid: Long): List<PersonParentJoin> =
      _dao.findByMinorPersonUid(minorPersonUid)

  public override suspend
      fun findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid: Long,
      clazzUidFilter: Long): List<PersonParentJoinDao.ParentEnrolmentRequired> =
      _dao.findByMinorPersonUidWhereParentNotEnrolledInClazz(minorPersonUid, clazzUidFilter)

  public override suspend fun isParentOf(userPersonUid: Long, minorPersonUid: Long): Boolean =
      _dao.isParentOf(userPersonUid, minorPersonUid)

  public override suspend fun updateAsync(personParentJoin: PersonParentJoin): Unit {
    personParentJoin.ppjLct = systemTimeInMillis()
    //must set versionid and/or primary key here
    _dao.updateAsync(personParentJoin)
  }

  public override suspend fun isMinorApproved(minorPersonUid: Long): Boolean =
      _dao.isMinorApproved(minorPersonUid)
}
