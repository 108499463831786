package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.SyncNode
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.Unit

public class SyncNodeDao_JdbcKt(
  public val _db: DoorDatabase
) : SyncNodeDao() {
  public val _insertAdapterSyncNode_upsert: EntityInsertionAdapter<SyncNode> = object :
      EntityInsertionAdapter<SyncNode>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO SyncNode (nodeClientId) VALUES(?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO SyncNode (nodeClientId) VALUES(?) ON CONFLICT (nodeClientId) DO UPDATE SET " + if(returnsId) { " RETURNING nodeClientId" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: SyncNode): Unit {
      stmt.setLong(1, entity.nodeClientId)
    }
  }

  public override fun replace(syncNode: SyncNode): Unit {
    _insertAdapterSyncNode_upsert.insert(syncNode)
  }

  public override suspend fun getLocalNodeClientId(): Long {
    var _result = 0L
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT COALESCE(
    |               (SELECT nodeClientId 
    |                  FROM SyncNode 
    |                 LIMIT 1), 0)
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT COALESCE(
    |               (SELECT nodeClientId 
    |                  FROM SyncNode 
    |                 LIMIT 1), 0)
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getLong(1)
          _result = _entity
        }
      }
    }
    return _result
  }
}
