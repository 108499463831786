package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.CourseTerminology
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CourseTerminologyDao_JdbcKt(
  public val _db: DoorDatabase
) : CourseTerminologyDao() {
  public val _insertAdapterCourseTerminology_: EntityInsertionAdapter<CourseTerminology> = object :
      EntityInsertionAdapter<CourseTerminology>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO CourseTerminology (ctUid, ctTitle, ctTerminology, ctLct) VALUES(?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO CourseTerminology (ctUid, ctTitle, ctTerminology, ctLct) VALUES(COALESCE(?,nextval('CourseTerminology_ctUid_seq')), ?, ?, ?)" + if(returnsId) { " RETURNING ctUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement,
        entity: CourseTerminology): Unit {
      if(entity.ctUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.ctUid)
      }
      stmt.setString(2, entity.ctTitle)
      stmt.setString(3, entity.ctTerminology)
      stmt.setLong(4, entity.ctLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CourseTerminologyReplicate(ctPk, ctDestination)
    |      SELECT DISTINCT CourseTerminology.ctUid AS ctPk,
    |             ? AS ctDestination
    |        FROM CourseTerminology
    |       WHERE CourseTerminology.ctLct != COALESCE(
    |             (SELECT ctVersionId
    |                FROM CourseTerminologyReplicate
    |               WHERE ctPk = CourseTerminology.ctUid
    |                 AND ctDestination = ?), 0) 
    |      /*psql ON CONFLICT(ctPk, ctDestination) DO UPDATE
    |             SET ctPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseTerminologyReplicate(ctPk, ctDestination)
    |      SELECT DISTINCT CourseTerminology.ctUid AS ctPk,
    |             ? AS ctDestination
    |        FROM CourseTerminology
    |       WHERE CourseTerminology.ctLct != COALESCE(
    |             (SELECT ctVersionId
    |                FROM CourseTerminologyReplicate
    |               WHERE ctPk = CourseTerminology.ctUid
    |                 AND ctDestination = ?), 0) 
    |       ON CONFLICT(ctPk, ctDestination) DO UPDATE
    |             SET ctPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO CourseTerminologyReplicate(ctPk, ctDestination)
    |  SELECT DISTINCT CourseTerminology.ctUid AS ctUid,
    |         UserSession.usClientNodeId AS ctDestination
    |    FROM ChangeLog
    |         JOIN CourseTerminology
    |             ON ChangeLog.chTableId = 450
    |                AND ChangeLog.chEntityPk = CourseTerminology.ctUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseTerminology.ctLct != COALESCE(
    |         (SELECT ctVersionId
    |            FROM CourseTerminologyReplicate
    |           WHERE ctPk = CourseTerminology.ctUid
    |             AND ctDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(ctPk, ctDestination) DO UPDATE
    |     SET ctPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CourseTerminologyReplicate(ctPk, ctDestination)
    |  SELECT DISTINCT CourseTerminology.ctUid AS ctUid,
    |         UserSession.usClientNodeId AS ctDestination
    |    FROM ChangeLog
    |         JOIN CourseTerminology
    |             ON ChangeLog.chTableId = 450
    |                AND ChangeLog.chEntityPk = CourseTerminology.ctUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND CourseTerminology.ctLct != COALESCE(
    |         (SELECT ctVersionId
    |            FROM CourseTerminologyReplicate
    |           WHERE ctPk = CourseTerminology.ctUid
    |             AND ctDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(ctPk, ctDestination) DO UPDATE
    |     SET ctPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllCourseTerminology(): DoorDataSourceFactory<Int, CourseTerminology> {
    val _result = object : DoorDataSourceFactory<Int, CourseTerminology>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<CourseTerminology>>
          = DoorLiveDataImpl<List<CourseTerminology>>(_db, listOf("CourseTerminology"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.CourseTerminology>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT *
        |         FROM CourseTerminology
        |     ORDER BY ctTitle   
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT *
        |         FROM CourseTerminology
        |     ORDER BY ctTitle   
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, _limit)
          _stmt.setInt(2, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_ctUid = _resultSet.getLong("ctUid")
              val tmp_ctTitle = _resultSet.getString("ctTitle")
              val tmp_ctTerminology = _resultSet.getString("ctTerminology")
              val tmp_ctLct = _resultSet.getLong("ctLct")
              val _entity = CourseTerminology()
              _entity.ctUid = tmp_ctUid
              _entity.ctTitle = tmp_ctTitle
              _entity.ctTerminology = tmp_ctTerminology
              _entity.ctLct = tmp_ctLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("CourseTerminology"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT *
        |         FROM CourseTerminology
        |     ORDER BY ctTitle   
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT *
        |         FROM CourseTerminology
        |     ORDER BY ctTitle   
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findAllCourseTerminologyList(): List<CourseTerminology> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.CourseTerminology>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT *
    |         FROM CourseTerminology
    |     ORDER BY ctTitle   
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT *
    |         FROM CourseTerminology
    |     ORDER BY ctTitle   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_ctUid = _resultSet.getLong("ctUid")
          val tmp_ctTitle = _resultSet.getString("ctTitle")
          val tmp_ctTerminology = _resultSet.getString("ctTerminology")
          val tmp_ctLct = _resultSet.getLong("ctLct")
          val _entity = CourseTerminology()
          _entity.ctUid = tmp_ctUid
          _entity.ctTitle = tmp_ctTitle
          _entity.ctTerminology = tmp_ctTerminology
          _entity.ctLct = tmp_ctLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun getTerminologyForClazz(clazzUid: Long): CourseTerminology? {
    var _result = null as com.ustadmobile.lib.db.entities.CourseTerminology??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT *
    |          FROM CourseTerminology
    |               JOIN Clazz 
    |               ON Clazz.clazzTerminologyUid = CourseTerminology.ctUid
    |         WHERE Clazz.clazzUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT *
    |          FROM CourseTerminology
    |               JOIN Clazz 
    |               ON Clazz.clazzTerminologyUid = CourseTerminology.ctUid
    |         WHERE Clazz.clazzUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, clazzUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_ctUid = _resultSet.getLong("ctUid")
          val tmp_ctTitle = _resultSet.getString("ctTitle")
          val tmp_ctTerminology = _resultSet.getString("ctTerminology")
          val tmp_ctLct = _resultSet.getLong("ctLct")
          val _entity = CourseTerminology()
          _entity.ctUid = tmp_ctUid
          _entity.ctTitle = tmp_ctTitle
          _entity.ctTerminology = tmp_ctTerminology
          _entity.ctLct = tmp_ctLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): CourseTerminology? {
    var _result = null as com.ustadmobile.lib.db.entities.CourseTerminology??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT * 
    |         FROM CourseTerminology 
    |        WHERE ctUid = ?
    |        
    """.trimMargin() , postgreSql = """
    |
    |        SELECT * 
    |         FROM CourseTerminology 
    |        WHERE ctUid = ?
    |        
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_ctUid = _resultSet.getLong("ctUid")
          val tmp_ctTitle = _resultSet.getString("ctTitle")
          val tmp_ctTerminology = _resultSet.getString("ctTerminology")
          val tmp_ctLct = _resultSet.getLong("ctLct")
          val _entity = CourseTerminology()
          _entity.ctUid = tmp_ctUid
          _entity.ctTitle = tmp_ctTitle
          _entity.ctTerminology = tmp_ctTerminology
          _entity.ctLct = tmp_ctLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun updateAsync(entity: CourseTerminology): Int {
    var _result = 0
    val _sql =
        "UPDATE CourseTerminology SET ctTitle = ?, ctTerminology = ?, ctLct = ? WHERE ctUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.ctTitle)
      _stmt.setString(2, entity.ctTerminology)
      _stmt.setLong(3, entity.ctLct)
      _stmt.setLong(4, entity.ctUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override fun insert(entity: CourseTerminology): Long {
    val _retVal = _insertAdapterCourseTerminology_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: CourseTerminology): Long {
    val _retVal = _insertAdapterCourseTerminology_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out CourseTerminology>): Unit {
    _insertAdapterCourseTerminology_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out CourseTerminology>): Unit {
    val _sql =
        "UPDATE CourseTerminology SET ctTitle = ?, ctTerminology = ?, ctLct = ? WHERE ctUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.ctTitle)
        _stmt.setString(2, _entity.ctTerminology)
        _stmt.setLong(3, _entity.ctLct)
        _stmt.setLong(4, _entity.ctUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: CourseTerminology): Unit {
    val _sql =
        "UPDATE CourseTerminology SET ctTitle = ?, ctTerminology = ?, ctLct = ? WHERE ctUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.ctTitle)
      _stmt.setString(2, entity.ctTerminology)
      _stmt.setLong(3, entity.ctLct)
      _stmt.setLong(4, entity.ctUid)
      _stmt.executeUpdate()
    }
  }
}
