package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.AgentEntity
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class AgentDao_JdbcKt(
  public val _db: DoorDatabase
) : AgentDao() {
  public val _insertAdapterAgentEntity_: EntityInsertionAdapter<AgentEntity> = object :
      EntityInsertionAdapter<AgentEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO AgentEntity (agentUid, agentMbox, agentMbox_sha1sum, agentOpenid, agentAccountName, agentHomePage, agentPersonUid, statementMasterChangeSeqNum, statementLocalChangeSeqNum, statementLastChangedBy, agentLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO AgentEntity (agentUid, agentMbox, agentMbox_sha1sum, agentOpenid, agentAccountName, agentHomePage, agentPersonUid, statementMasterChangeSeqNum, statementLocalChangeSeqNum, statementLastChangedBy, agentLct) VALUES(COALESCE(?,nextval('AgentEntity_agentUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING agentUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: AgentEntity):
        Unit {
      if(entity.agentUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.agentUid)
      }
      stmt.setString(2, entity.agentMbox)
      stmt.setString(3, entity.agentMbox_sha1sum)
      stmt.setString(4, entity.agentOpenid)
      stmt.setString(5, entity.agentAccountName)
      stmt.setString(6, entity.agentHomePage)
      stmt.setLong(7, entity.agentPersonUid)
      stmt.setLong(8, entity.statementMasterChangeSeqNum)
      stmt.setLong(9, entity.statementLocalChangeSeqNum)
      stmt.setInt(10, entity.statementLastChangedBy)
      stmt.setLong(11, entity.agentLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO AgentEntityReplicate(aePk, aeDestination)
    |      SELECT DISTINCT AgentEntity.agentUid AS aeUid,
    |             ? AS aeDestination
    |        FROM UserSession
    |        JOIN PersonGroupMember 
    |               ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions & 
    |                        64
    |                        
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |        JOIN AgentEntity 
    |             ON AgentEntity.agentPersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |         --notpsql 
    |         AND AgentEntity.agentLct != COALESCE(
    |             (SELECT aeVersionId
    |                FROM AgentEntityReplicate
    |               WHERE aePk = AgentEntity.agentUid
    |                 AND aeDestination = UserSession.usClientNodeId), 0) 
    |         --endnotpsql        
    |      /*psql ON CONFLICT(aePk, aeDestination) DO UPDATE
    |             SET aePending = (SELECT AgentEntity.agentLct
    |                                FROM AgentEntity
    |                               WHERE AgentEntity.agentUid = EXCLUDED.aePk ) 
    |                                     != AgentEntityReplicate.aePk
    |      */       
    |     
    """.trimMargin() , postgreSql = """
    |INSERT INTO AgentEntityReplicate(aePk, aeDestination)
    |      SELECT DISTINCT AgentEntity.agentUid AS aeUid,
    |             ? AS aeDestination
    |        FROM UserSession
    |        JOIN PersonGroupMember 
    |               ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |               
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions & 
    |                        64
    |                        
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |        JOIN AgentEntity 
    |             ON AgentEntity.agentPersonUid = Person.personUid
    |       WHERE UserSession.usClientNodeId = ?
    |       ON CONFLICT(aePk, aeDestination) DO UPDATE
    |             SET aePending = (SELECT AgentEntity.agentLct
    |                                FROM AgentEntity
    |                               WHERE AgentEntity.agentUid = EXCLUDED.aePk ) 
    |                                     != AgentEntityReplicate.aePk
    |             
    |     
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO AgentEntityReplicate(aePk, aeDestination)
    |      SELECT DISTINCT AgentEntity.agentUid AS aeUid,
    |             UserSession.usClientNodeId AS aeDestination
    |        FROM ChangeLog
    |             JOIN AgentEntity
    |                 ON ChangeLog.chTableId = 68
    |                    AND ChangeLog.chEntityPk = AgentEntity.agentUid
    |             JOIN Person 
    |                       ON Person.personUid = AgentEntity.agentPersonUid
    |                  
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |                        64
    |                        
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |               
    |       WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |         --notpsql 
    |         AND AgentEntity.agentLct != COALESCE(
    |             (SELECT aeVersionId
    |                FROM AgentEntityReplicate
    |               WHERE aePk = AgentEntity.agentUid
    |                 AND aeDestination = UserSession.usClientNodeId), 0) 
    |         --endnotpsql 
    |      /*psql ON CONFLICT(aePk, aeDestination) DO UPDATE
    |             SET aePending = (SELECT AgentEntity.agentLct
    |                                FROM AgentEntity
    |                               WHERE AgentEntity.agentUid = EXCLUDED.aePk ) 
    |                                     != AgentEntityReplicate.aePk
    |      */    
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO AgentEntityReplicate(aePk, aeDestination)
    |      SELECT DISTINCT AgentEntity.agentUid AS aeUid,
    |             UserSession.usClientNodeId AS aeDestination
    |        FROM ChangeLog
    |             JOIN AgentEntity
    |                 ON ChangeLog.chTableId = 68
    |                    AND ChangeLog.chEntityPk = AgentEntity.agentUid
    |             JOIN Person 
    |                       ON Person.personUid = AgentEntity.agentPersonUid
    |                  
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |                        64
    |                        
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |               
    |       WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |       ON CONFLICT(aePk, aeDestination) DO UPDATE
    |             SET aePending = (SELECT AgentEntity.agentLct
    |                                FROM AgentEntity
    |                               WHERE AgentEntity.agentUid = EXCLUDED.aePk ) 
    |                                     != AgentEntityReplicate.aePk
    |          
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun getAgentByAnyId(
    openId: String?,
    mbox: String?,
    account: String?,
    homepage: String?,
    sha1: String?
  ): AgentEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.AgentEntity??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM AgentEntity WHERE agentOpenId = ? OR agentMbox = ? OR agentMbox_sha1sum = ? OR (agentAccountName = ? AND agentHomePage = ?)"
        , postgreSql = """
    |SELECT * FROM AgentEntity WHERE agentOpenId = ? OR agentMbox = ? OR agentMbox_sha1sum = ? OR (agentAccountName = ? AND agentHomePage = ?)
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, openId)
      _stmt.setString(2, mbox)
      _stmt.setString(3, sha1)
      _stmt.setString(4, account)
      _stmt.setString(5, homepage)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_agentUid = _resultSet.getLong("agentUid")
          val tmp_agentMbox = _resultSet.getString("agentMbox")
          val tmp_agentMbox_sha1sum = _resultSet.getString("agentMbox_sha1sum")
          val tmp_agentOpenid = _resultSet.getString("agentOpenid")
          val tmp_agentAccountName = _resultSet.getString("agentAccountName")
          val tmp_agentHomePage = _resultSet.getString("agentHomePage")
          val tmp_agentPersonUid = _resultSet.getLong("agentPersonUid")
          val tmp_statementMasterChangeSeqNum = _resultSet.getLong("statementMasterChangeSeqNum")
          val tmp_statementLocalChangeSeqNum = _resultSet.getLong("statementLocalChangeSeqNum")
          val tmp_statementLastChangedBy = _resultSet.getInt("statementLastChangedBy")
          val tmp_agentLct = _resultSet.getLong("agentLct")
          val _entity = AgentEntity()
          _entity.agentUid = tmp_agentUid
          _entity.agentMbox = tmp_agentMbox
          _entity.agentMbox_sha1sum = tmp_agentMbox_sha1sum
          _entity.agentOpenid = tmp_agentOpenid
          _entity.agentAccountName = tmp_agentAccountName
          _entity.agentHomePage = tmp_agentHomePage
          _entity.agentPersonUid = tmp_agentPersonUid
          _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
          _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
          _entity.statementLastChangedBy = tmp_statementLastChangedBy
          _entity.agentLct = tmp_agentLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun getAgentFromPersonUsername(endpoint: String, username: String):
      AgentEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.AgentEntity??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT *
    |          FROM AgentEntity
    |         WHERE agentAccountName = ? 
    |           AND agentHomePage = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT *
    |          FROM AgentEntity
    |         WHERE agentAccountName = ? 
    |           AND agentHomePage = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, username)
      _stmt.setString(2, endpoint)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_agentUid = _resultSet.getLong("agentUid")
          val tmp_agentMbox = _resultSet.getString("agentMbox")
          val tmp_agentMbox_sha1sum = _resultSet.getString("agentMbox_sha1sum")
          val tmp_agentOpenid = _resultSet.getString("agentOpenid")
          val tmp_agentAccountName = _resultSet.getString("agentAccountName")
          val tmp_agentHomePage = _resultSet.getString("agentHomePage")
          val tmp_agentPersonUid = _resultSet.getLong("agentPersonUid")
          val tmp_statementMasterChangeSeqNum = _resultSet.getLong("statementMasterChangeSeqNum")
          val tmp_statementLocalChangeSeqNum = _resultSet.getLong("statementLocalChangeSeqNum")
          val tmp_statementLastChangedBy = _resultSet.getInt("statementLastChangedBy")
          val tmp_agentLct = _resultSet.getLong("agentLct")
          val _entity = AgentEntity()
          _entity.agentUid = tmp_agentUid
          _entity.agentMbox = tmp_agentMbox
          _entity.agentMbox_sha1sum = tmp_agentMbox_sha1sum
          _entity.agentOpenid = tmp_agentOpenid
          _entity.agentAccountName = tmp_agentAccountName
          _entity.agentHomePage = tmp_agentHomePage
          _entity.agentPersonUid = tmp_agentPersonUid
          _entity.statementMasterChangeSeqNum = tmp_statementMasterChangeSeqNum
          _entity.statementLocalChangeSeqNum = tmp_statementLocalChangeSeqNum
          _entity.statementLastChangedBy = tmp_statementLastChangedBy
          _entity.agentLct = tmp_agentLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: AgentEntity): Long {
    val _retVal = _insertAdapterAgentEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: AgentEntity): Long {
    val _retVal = _insertAdapterAgentEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out AgentEntity>): Unit {
    _insertAdapterAgentEntity_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out AgentEntity>): Unit {
    val _sql =
        "UPDATE AgentEntity SET agentMbox = ?, agentMbox_sha1sum = ?, agentOpenid = ?, agentAccountName = ?, agentHomePage = ?, agentPersonUid = ?, statementMasterChangeSeqNum = ?, statementLocalChangeSeqNum = ?, statementLastChangedBy = ?, agentLct = ? WHERE agentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.agentMbox)
        _stmt.setString(2, _entity.agentMbox_sha1sum)
        _stmt.setString(3, _entity.agentOpenid)
        _stmt.setString(4, _entity.agentAccountName)
        _stmt.setString(5, _entity.agentHomePage)
        _stmt.setLong(6, _entity.agentPersonUid)
        _stmt.setLong(7, _entity.statementMasterChangeSeqNum)
        _stmt.setLong(8, _entity.statementLocalChangeSeqNum)
        _stmt.setInt(9, _entity.statementLastChangedBy)
        _stmt.setLong(10, _entity.agentLct)
        _stmt.setLong(11, _entity.agentUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: AgentEntity): Unit {
    val _sql =
        "UPDATE AgentEntity SET agentMbox = ?, agentMbox_sha1sum = ?, agentOpenid = ?, agentAccountName = ?, agentHomePage = ?, agentPersonUid = ?, statementMasterChangeSeqNum = ?, statementLocalChangeSeqNum = ?, statementLastChangedBy = ?, agentLct = ? WHERE agentUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.agentMbox)
      _stmt.setString(2, entity.agentMbox_sha1sum)
      _stmt.setString(3, entity.agentOpenid)
      _stmt.setString(4, entity.agentAccountName)
      _stmt.setString(5, entity.agentHomePage)
      _stmt.setLong(6, entity.agentPersonUid)
      _stmt.setLong(7, entity.statementMasterChangeSeqNum)
      _stmt.setLong(8, entity.statementLocalChangeSeqNum)
      _stmt.setInt(9, entity.statementLastChangedBy)
      _stmt.setLong(10, entity.agentLct)
      _stmt.setLong(11, entity.agentUid)
      _stmt.executeUpdate()
    }
  }
}
