package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.HolidayCalendar
import com.ustadmobile.lib.db.entities.HolidayCalendarWithNumEntries
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class HolidayCalendarDao_JdbcKt(
  public val _db: DoorDatabase
) : HolidayCalendarDao() {
  public val _insertAdapterHolidayCalendar_upsert: EntityInsertionAdapter<HolidayCalendar> = object
      : EntityInsertionAdapter<HolidayCalendar>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT OR REPLACE INTO HolidayCalendar (umCalendarUid, umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO HolidayCalendar (umCalendarUid, umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct) VALUES(COALESCE(?,nextval('HolidayCalendar_umCalendarUid_seq')), ?, ?, ?, ?, ?, ?, ?) ON CONFLICT (umCalendarUid) DO UPDATE SET umCalendarName = excluded.umCalendarName,umCalendarCategory = excluded.umCalendarCategory,umCalendarActive = excluded.umCalendarActive,umCalendarMasterChangeSeqNum = excluded.umCalendarMasterChangeSeqNum,umCalendarLocalChangeSeqNum = excluded.umCalendarLocalChangeSeqNum,umCalendarLastChangedBy = excluded.umCalendarLastChangedBy,umCalendarLct = excluded.umCalendarLct" + if(returnsId) { " RETURNING umCalendarUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: HolidayCalendar):
        Unit {
      if(entity.umCalendarUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.umCalendarUid)
      }
      stmt.setString(2, entity.umCalendarName)
      stmt.setInt(3, entity.umCalendarCategory)
      stmt.setBoolean(4, entity.umCalendarActive)
      stmt.setLong(5, entity.umCalendarMasterChangeSeqNum)
      stmt.setLong(6, entity.umCalendarLocalChangeSeqNum)
      stmt.setInt(7, entity.umCalendarLastChangedBy)
      stmt.setLong(8, entity.umCalendarLct)
    }
  }

  public val _insertAdapterHolidayCalendar_: EntityInsertionAdapter<HolidayCalendar> = object :
      EntityInsertionAdapter<HolidayCalendar>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO HolidayCalendar (umCalendarUid, umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO HolidayCalendar (umCalendarUid, umCalendarName, umCalendarCategory, umCalendarActive, umCalendarMasterChangeSeqNum, umCalendarLocalChangeSeqNum, umCalendarLastChangedBy, umCalendarLct) VALUES(COALESCE(?,nextval('HolidayCalendar_umCalendarUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING umCalendarUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: HolidayCalendar):
        Unit {
      if(entity.umCalendarUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.umCalendarUid)
      }
      stmt.setString(2, entity.umCalendarName)
      stmt.setInt(3, entity.umCalendarCategory)
      stmt.setBoolean(4, entity.umCalendarActive)
      stmt.setLong(5, entity.umCalendarMasterChangeSeqNum)
      stmt.setLong(6, entity.umCalendarLocalChangeSeqNum)
      stmt.setInt(7, entity.umCalendarLastChangedBy)
      stmt.setLong(8, entity.umCalendarLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO HolidayCalendarReplicate(hcPk, hcDestination)
    |      SELECT DISTINCT HolidayCalendar.umCalendarUid AS hcPk,
    |             ? AS hcDestination
    |        FROM HolidayCalendar
    |             JOIN UserSession
    |                  ON UserSession.usClientNodeId = ?
    |        --notpsql 
    |       WHERE HolidayCalendar.umCalendarLct != COALESCE(
    |             (SELECT hcVersionId
    |                FROM HolidayCalendarReplicate
    |               WHERE hcPk = HolidayCalendar.umCalendarUid
    |                 AND hcDestination = UserSession.usClientNodeId), 0) 
    |         --endnotpsql        
    |      /*psql ON CONFLICT(hcPk, hcDestination) DO UPDATE
    |             SET hcPending = (SELECT HolidayCalendar.umCalendarLct
    |                                FROM HolidayCalendar
    |                               WHERE HolidayCalendar.umCalendarUid = EXCLUDED.hcPk ) 
    |                                     != HolidayCalendarReplicate.hcPk
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO HolidayCalendarReplicate(hcPk, hcDestination)
    |      SELECT DISTINCT HolidayCalendar.umCalendarUid AS hcPk,
    |             ? AS hcDestination
    |        FROM HolidayCalendar
    |             JOIN UserSession
    |                  ON UserSession.usClientNodeId = ?
    |       ON CONFLICT(hcPk, hcDestination) DO UPDATE
    |             SET hcPending = (SELECT HolidayCalendar.umCalendarLct
    |                                FROM HolidayCalendar
    |                               WHERE HolidayCalendar.umCalendarUid = EXCLUDED.hcPk ) 
    |                                     != HolidayCalendarReplicate.hcPk
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO HolidayCalendarReplicate(hcPk, hcDestination)
    |  SELECT DISTINCT HolidayCalendar.umCalendarUid AS hcUid,
    |         UserSession.usClientNodeId AS hcDestination
    |    FROM ChangeLog
    |         JOIN HolidayCalendar
    |             ON ChangeLog.chTableId = 28
    |                AND ChangeLog.chEntityPk = HolidayCalendar.umCalendarUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     --notpsql 
    |      AND HolidayCalendar.umCalendarLct != COALESCE(
    |             (SELECT hcVersionId
    |                FROM HolidayCalendarReplicate
    |               WHERE hcPk = HolidayCalendar.umCalendarUid
    |                 AND hcDestination = UserSession.usClientNodeId), 0) 
    |         --endnotpsql    
    |   /*psql ON CONFLICT(hcPk, hcDestination) DO UPDATE
    |             SET hcPending = (SELECT HolidayCalendar.umCalendarLct
    |                                FROM HolidayCalendar
    |                               WHERE HolidayCalendar.umCalendarUid = EXCLUDED.hcPk ) 
    |                                     != HolidayCalendarReplicate.hcPk     
    |        */                                           
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO HolidayCalendarReplicate(hcPk, hcDestination)
    |  SELECT DISTINCT HolidayCalendar.umCalendarUid AS hcUid,
    |         UserSession.usClientNodeId AS hcDestination
    |    FROM ChangeLog
    |         JOIN HolidayCalendar
    |             ON ChangeLog.chTableId = 28
    |                AND ChangeLog.chEntityPk = HolidayCalendar.umCalendarUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |    ON CONFLICT(hcPk, hcDestination) DO UPDATE
    |             SET hcPending = (SELECT HolidayCalendar.umCalendarLct
    |                                FROM HolidayCalendar
    |                               WHERE HolidayCalendar.umCalendarUid = EXCLUDED.hcPk ) 
    |                                     != HolidayCalendarReplicate.hcPk     
    |                                                   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findAllHolidaysWithEntriesCount(): DoorDataSourceFactory<Int,
      HolidayCalendarWithNumEntries> {
    val _result = object : DoorDataSourceFactory<Int, HolidayCalendarWithNumEntries>() {
      public override fun getData(_offset: Int, _limit: Int):
          DoorLiveData<List<HolidayCalendarWithNumEntries>> =
          DoorLiveDataImpl<List<HolidayCalendarWithNumEntries>>(_db, listOf("Holiday",
          "HolidayCalendar"))  {
        var _liveResult =
            mutableListOf<com.ustadmobile.lib.db.entities.HolidayCalendarWithNumEntries>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (SELECT HolidayCalendar.* ,
        |            (SELECT COUNT(*) FROM Holiday 
        |               WHERE holHolidayCalendarUid = HolidayCalendar.umCalendarUid 
        |               AND CAST(holActive AS INTEGER) = 1) AS numEntries 
        |             FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND 
        |             umCalendarCategory = 1) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (SELECT HolidayCalendar.* ,
        |            (SELECT COUNT(*) FROM Holiday 
        |               WHERE holHolidayCalendarUid = HolidayCalendar.umCalendarUid 
        |               AND CAST(holActive AS INTEGER) = 1) AS numEntries 
        |             FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND 
        |             umCalendarCategory = 1) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, _limit)
          _stmt.setInt(2, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_numEntries = _resultSet.getInt("numEntries")
              val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
              val tmp_umCalendarName = _resultSet.getString("umCalendarName")
              val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
              val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
              val tmp_umCalendarMasterChangeSeqNum =
                  _resultSet.getLong("umCalendarMasterChangeSeqNum")
              val tmp_umCalendarLocalChangeSeqNum =
                  _resultSet.getLong("umCalendarLocalChangeSeqNum")
              val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
              val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
              val _entity = HolidayCalendarWithNumEntries()
              _entity.numEntries = tmp_numEntries
              _entity.umCalendarUid = tmp_umCalendarUid
              _entity.umCalendarName = tmp_umCalendarName
              _entity.umCalendarCategory = tmp_umCalendarCategory
              _entity.umCalendarActive = tmp_umCalendarActive
              _entity.umCalendarMasterChangeSeqNum = tmp_umCalendarMasterChangeSeqNum
              _entity.umCalendarLocalChangeSeqNum = tmp_umCalendarLocalChangeSeqNum
              _entity.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
              _entity.umCalendarLct = tmp_umCalendarLct
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Holiday", "HolidayCalendar"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (SELECT HolidayCalendar.* ,
        |            (SELECT COUNT(*) FROM Holiday 
        |               WHERE holHolidayCalendarUid = HolidayCalendar.umCalendarUid 
        |               AND CAST(holActive AS INTEGER) = 1) AS numEntries 
        |             FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND 
        |             umCalendarCategory = 1) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (SELECT HolidayCalendar.* ,
        |            (SELECT COUNT(*) FROM Holiday 
        |               WHERE holHolidayCalendarUid = HolidayCalendar.umCalendarUid 
        |               AND CAST(holActive AS INTEGER) = 1) AS numEntries 
        |             FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND 
        |             umCalendarCategory = 1) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun replaceList(list: List<out HolidayCalendar>): Unit {
    _insertAdapterHolidayCalendar_upsert.insertList(list)
  }

  public override fun findAllHolidaysLiveData(): DoorLiveData<List<HolidayCalendar>> {
    val _result = DoorLiveDataImpl<List<HolidayCalendar>>(_db, listOf("HolidayCalendar"))  {
      var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.HolidayCalendar>()
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND umCalendarCategory = 1"
          , postgreSql = """
      |SELECT * FROM HolidayCalendar WHERE CAST(umCalendarActive AS INTEGER) = 1 AND umCalendarCategory = 1
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          while(_resultSet.next()) {
            val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
            val tmp_umCalendarName = _resultSet.getString("umCalendarName")
            val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
            val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
            val tmp_umCalendarMasterChangeSeqNum =
                _resultSet.getLong("umCalendarMasterChangeSeqNum")
            val tmp_umCalendarLocalChangeSeqNum = _resultSet.getLong("umCalendarLocalChangeSeqNum")
            val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
            val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
            val _entity = HolidayCalendar()
            _entity.umCalendarUid = tmp_umCalendarUid
            _entity.umCalendarName = tmp_umCalendarName
            _entity.umCalendarCategory = tmp_umCalendarCategory
            _entity.umCalendarActive = tmp_umCalendarActive
            _entity.umCalendarMasterChangeSeqNum = tmp_umCalendarMasterChangeSeqNum
            _entity.umCalendarLocalChangeSeqNum = tmp_umCalendarLocalChangeSeqNum
            _entity.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
            _entity.umCalendarLct = tmp_umCalendarLct
            _liveResult.add(_entity)
          }
        }
      }
      _liveResult.toList()
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<HolidayCalendar?> {
    val _result = DoorLiveDataImpl<HolidayCalendar?>(_db, listOf("HolidayCalendar"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.HolidayCalendar??
      val _stmtConfig =
          PreparedStatementConfig("SELECT * FROM HolidayCalendar WHERE umCalendarUid = ? AND CAST(umCalendarActive AS INTEGER) = 1"
          , postgreSql = """
      |SELECT * FROM HolidayCalendar WHERE umCalendarUid = ? AND CAST(umCalendarActive AS INTEGER) = 1
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
            val tmp_umCalendarName = _resultSet.getString("umCalendarName")
            val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
            val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
            val tmp_umCalendarMasterChangeSeqNum =
                _resultSet.getLong("umCalendarMasterChangeSeqNum")
            val tmp_umCalendarLocalChangeSeqNum = _resultSet.getLong("umCalendarLocalChangeSeqNum")
            val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
            val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
            val _entity = HolidayCalendar()
            _entity.umCalendarUid = tmp_umCalendarUid
            _entity.umCalendarName = tmp_umCalendarName
            _entity.umCalendarCategory = tmp_umCalendarCategory
            _entity.umCalendarActive = tmp_umCalendarActive
            _entity.umCalendarMasterChangeSeqNum = tmp_umCalendarMasterChangeSeqNum
            _entity.umCalendarLocalChangeSeqNum = tmp_umCalendarLocalChangeSeqNum
            _entity.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
            _entity.umCalendarLct = tmp_umCalendarLct
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateAsync(entity: HolidayCalendar): Int {
    var _result = 0
    val _sql =
        "UPDATE HolidayCalendar SET umCalendarName = ?, umCalendarCategory = ?, umCalendarActive = ?, umCalendarMasterChangeSeqNum = ?, umCalendarLocalChangeSeqNum = ?, umCalendarLastChangedBy = ?, umCalendarLct = ? WHERE umCalendarUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setString(1, entity.umCalendarName)
      _stmt.setInt(2, entity.umCalendarCategory)
      _stmt.setBoolean(3, entity.umCalendarActive)
      _stmt.setLong(4, entity.umCalendarMasterChangeSeqNum)
      _stmt.setLong(5, entity.umCalendarLocalChangeSeqNum)
      _stmt.setInt(6, entity.umCalendarLastChangedBy)
      _stmt.setLong(7, entity.umCalendarLct)
      _stmt.setLong(8, entity.umCalendarUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun findByUid(uid: Long): HolidayCalendar? {
    var _result = null as com.ustadmobile.lib.db.entities.HolidayCalendar??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM HolidayCalendar WHERE umCalendarUid = ?" , postgreSql
        = """
    |SELECT * FROM HolidayCalendar WHERE umCalendarUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          val tmp_umCalendarName = _resultSet.getString("umCalendarName")
          val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
          val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
          val tmp_umCalendarMasterChangeSeqNum = _resultSet.getLong("umCalendarMasterChangeSeqNum")
          val tmp_umCalendarLocalChangeSeqNum = _resultSet.getLong("umCalendarLocalChangeSeqNum")
          val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
          val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
          val _entity = HolidayCalendar()
          _entity.umCalendarUid = tmp_umCalendarUid
          _entity.umCalendarName = tmp_umCalendarName
          _entity.umCalendarCategory = tmp_umCalendarCategory
          _entity.umCalendarActive = tmp_umCalendarActive
          _entity.umCalendarMasterChangeSeqNum = tmp_umCalendarMasterChangeSeqNum
          _entity.umCalendarLocalChangeSeqNum = tmp_umCalendarLocalChangeSeqNum
          _entity.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
          _entity.umCalendarLct = tmp_umCalendarLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): HolidayCalendar? {
    var _result = null as com.ustadmobile.lib.db.entities.HolidayCalendar??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM HolidayCalendar WHERE umCalendarUid = ?" , postgreSql
        = """
    |SELECT * FROM HolidayCalendar WHERE umCalendarUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_umCalendarUid = _resultSet.getLong("umCalendarUid")
          val tmp_umCalendarName = _resultSet.getString("umCalendarName")
          val tmp_umCalendarCategory = _resultSet.getInt("umCalendarCategory")
          val tmp_umCalendarActive = _resultSet.getBoolean("umCalendarActive")
          val tmp_umCalendarMasterChangeSeqNum = _resultSet.getLong("umCalendarMasterChangeSeqNum")
          val tmp_umCalendarLocalChangeSeqNum = _resultSet.getLong("umCalendarLocalChangeSeqNum")
          val tmp_umCalendarLastChangedBy = _resultSet.getInt("umCalendarLastChangedBy")
          val tmp_umCalendarLct = _resultSet.getLong("umCalendarLct")
          val _entity = HolidayCalendar()
          _entity.umCalendarUid = tmp_umCalendarUid
          _entity.umCalendarName = tmp_umCalendarName
          _entity.umCalendarCategory = tmp_umCalendarCategory
          _entity.umCalendarActive = tmp_umCalendarActive
          _entity.umCalendarMasterChangeSeqNum = tmp_umCalendarMasterChangeSeqNum
          _entity.umCalendarLocalChangeSeqNum = tmp_umCalendarLocalChangeSeqNum
          _entity.umCalendarLastChangedBy = tmp_umCalendarLastChangedBy
          _entity.umCalendarLct = tmp_umCalendarLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: HolidayCalendar): Long {
    val _retVal = _insertAdapterHolidayCalendar_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: HolidayCalendar): Long {
    val _retVal = _insertAdapterHolidayCalendar_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out HolidayCalendar>): Unit {
    _insertAdapterHolidayCalendar_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out HolidayCalendar>): Unit {
    val _sql =
        "UPDATE HolidayCalendar SET umCalendarName = ?, umCalendarCategory = ?, umCalendarActive = ?, umCalendarMasterChangeSeqNum = ?, umCalendarLocalChangeSeqNum = ?, umCalendarLastChangedBy = ?, umCalendarLct = ? WHERE umCalendarUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.umCalendarName)
        _stmt.setInt(2, _entity.umCalendarCategory)
        _stmt.setBoolean(3, _entity.umCalendarActive)
        _stmt.setLong(4, _entity.umCalendarMasterChangeSeqNum)
        _stmt.setLong(5, _entity.umCalendarLocalChangeSeqNum)
        _stmt.setInt(6, _entity.umCalendarLastChangedBy)
        _stmt.setLong(7, _entity.umCalendarLct)
        _stmt.setLong(8, _entity.umCalendarUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: HolidayCalendar): Unit {
    val _sql =
        "UPDATE HolidayCalendar SET umCalendarName = ?, umCalendarCategory = ?, umCalendarActive = ?, umCalendarMasterChangeSeqNum = ?, umCalendarLocalChangeSeqNum = ?, umCalendarLastChangedBy = ?, umCalendarLct = ? WHERE umCalendarUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.umCalendarName)
      _stmt.setInt(2, entity.umCalendarCategory)
      _stmt.setBoolean(3, entity.umCalendarActive)
      _stmt.setLong(4, entity.umCalendarMasterChangeSeqNum)
      _stmt.setLong(5, entity.umCalendarLocalChangeSeqNum)
      _stmt.setInt(6, entity.umCalendarLastChangedBy)
      _stmt.setLong(7, entity.umCalendarLct)
      _stmt.setLong(8, entity.umCalendarUid)
      _stmt.executeUpdate()
    }
  }
}
