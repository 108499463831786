package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.StateEntity
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class StateDao_JdbcKt(
  public val _db: DoorDatabase
) : StateDao() {
  public val _insertAdapterStateEntity_: EntityInsertionAdapter<StateEntity> = object :
      EntityInsertionAdapter<StateEntity>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO StateEntity (stateUid, stateId, agentUid, activityId, registration, isIsactive, timestamp, stateMasterChangeSeqNum, stateLocalChangeSeqNum, stateLastChangedBy, stateLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO StateEntity (stateUid, stateId, agentUid, activityId, registration, isIsactive, timestamp, stateMasterChangeSeqNum, stateLocalChangeSeqNum, stateLastChangedBy, stateLct) VALUES(COALESCE(?,nextval('StateEntity_stateUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING stateUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: StateEntity):
        Unit {
      if(entity.stateUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.stateUid)
      }
      stmt.setString(2, entity.stateId)
      stmt.setLong(3, entity.agentUid)
      stmt.setString(4, entity.activityId)
      stmt.setString(5, entity.registration)
      stmt.setBoolean(6, entity.isIsactive)
      stmt.setLong(7, entity.timestamp)
      stmt.setLong(8, entity.stateMasterChangeSeqNum)
      stmt.setLong(9, entity.stateLocalChangeSeqNum)
      stmt.setInt(10, entity.stateLastChangedBy)
      stmt.setLong(11, entity.stateLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO StateEntityReplicate(sePk, seDestination)
    |      SELECT DISTINCT StateEntity.stateUid AS sePk,
    |             ? AS seDestination
    |        FROM StateEntity
    |             JOIN AgentEntity
    |                  ON StateEntity.agentUid = AgentEntity.agentUid
    |             JOIN UserSession
    |                  ON AgentEntity.agentPersonUid = UserSession.usPersonUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND StateEntity.stateLct != COALESCE(
    |             (SELECT seVersionId
    |                FROM StateEntityReplicate
    |               WHERE sePk = StateEntity.stateUid
    |                 AND seDestination = ?), 0) 
    |      /*psql ON CONFLICT(sePk, seDestination) DO UPDATE
    |             SET sePending = true
    |      */       
    | 
    """.trimMargin() , postgreSql = """
    |INSERT INTO StateEntityReplicate(sePk, seDestination)
    |      SELECT DISTINCT StateEntity.stateUid AS sePk,
    |             ? AS seDestination
    |        FROM StateEntity
    |             JOIN AgentEntity
    |                  ON StateEntity.agentUid = AgentEntity.agentUid
    |             JOIN UserSession
    |                  ON AgentEntity.agentPersonUid = UserSession.usPersonUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND StateEntity.stateLct != COALESCE(
    |             (SELECT seVersionId
    |                FROM StateEntityReplicate
    |               WHERE sePk = StateEntity.stateUid
    |                 AND seDestination = ?), 0) 
    |       ON CONFLICT(sePk, seDestination) DO UPDATE
    |             SET sePending = true
    |             
    | 
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      _stmt.setLong(3, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO StateEntityReplicate(sePk, seDestination)
    |  SELECT DISTINCT StateEntity.stateUid AS seUid,
    |         UserSession.usClientNodeId AS seDestination
    |    FROM ChangeLog
    |         JOIN StateEntity
    |              ON ChangeLog.chTableId = 70
    |                 AND ChangeLog.chEntityPk = StateEntity.stateUid
    |         JOIN AgentEntity
    |              ON StateEntity.agentUid = AgentEntity.agentUid
    |         JOIN UserSession
    |              ON AgentEntity.agentPersonUid = UserSession.usPersonUid
    |                 AND UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND StateEntity.stateLct != COALESCE(
    |         (SELECT seVersionId
    |            FROM StateEntityReplicate
    |           WHERE sePk = StateEntity.stateUid
    |             AND seDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(sePk, seDestination) DO UPDATE
    |     SET sePending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO StateEntityReplicate(sePk, seDestination)
    |  SELECT DISTINCT StateEntity.stateUid AS seUid,
    |         UserSession.usClientNodeId AS seDestination
    |    FROM ChangeLog
    |         JOIN StateEntity
    |              ON ChangeLog.chTableId = 70
    |                 AND ChangeLog.chEntityPk = StateEntity.stateUid
    |         JOIN AgentEntity
    |              ON StateEntity.agentUid = AgentEntity.agentUid
    |         JOIN UserSession
    |              ON AgentEntity.agentPersonUid = UserSession.usPersonUid
    |                 AND UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND StateEntity.stateLct != COALESCE(
    |         (SELECT seVersionId
    |            FROM StateEntityReplicate
    |           WHERE sePk = StateEntity.stateUid
    |             AND seDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(sePk, seDestination) DO UPDATE
    |     SET sePending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByStateId(
    id: String?,
    agentUid: Long,
    activityId: String?,
    registration: String?
  ): StateEntity? {
    var _result = null as com.ustadmobile.lib.db.entities.StateEntity??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM StateEntity WHERE stateId = ? AND agentUid = ? AND activityId = ? AND registration = ? AND isIsactive LIMIT 1"
        , postgreSql = """
    |SELECT * FROM StateEntity WHERE stateId = ? AND agentUid = ? AND activityId = ? AND registration = ? AND isIsactive LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, id)
      _stmt.setLong(2, agentUid)
      _stmt.setString(3, activityId)
      _stmt.setString(4, registration)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_stateUid = _resultSet.getLong("stateUid")
          val tmp_stateId = _resultSet.getString("stateId")
          val tmp_agentUid = _resultSet.getLong("agentUid")
          val tmp_activityId = _resultSet.getString("activityId")
          val tmp_registration = _resultSet.getString("registration")
          val tmp_isIsactive = _resultSet.getBoolean("isIsactive")
          val tmp_timestamp = _resultSet.getLong("timestamp")
          val tmp_stateMasterChangeSeqNum = _resultSet.getLong("stateMasterChangeSeqNum")
          val tmp_stateLocalChangeSeqNum = _resultSet.getLong("stateLocalChangeSeqNum")
          val tmp_stateLastChangedBy = _resultSet.getInt("stateLastChangedBy")
          val tmp_stateLct = _resultSet.getLong("stateLct")
          val _entity = StateEntity()
          _entity.stateUid = tmp_stateUid
          _entity.stateId = tmp_stateId
          _entity.agentUid = tmp_agentUid
          _entity.activityId = tmp_activityId
          _entity.registration = tmp_registration
          _entity.isIsactive = tmp_isIsactive
          _entity.timestamp = tmp_timestamp
          _entity.stateMasterChangeSeqNum = tmp_stateMasterChangeSeqNum
          _entity.stateLocalChangeSeqNum = tmp_stateLocalChangeSeqNum
          _entity.stateLastChangedBy = tmp_stateLastChangedBy
          _entity.stateLct = tmp_stateLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findStateIdByAgentAndActivity(
    agentUid: Long,
    activityId: String,
    registration: String,
    since: String
  ): List<StateEntity> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.StateEntity>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM StateEntity WHERE agentUid = ? AND activityId = ? AND registration = ? AND isIsactive AND timestamp > ?"
        , postgreSql = """
    |SELECT * FROM StateEntity WHERE agentUid = ? AND activityId = ? AND registration = ? AND isIsactive AND timestamp > ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, agentUid)
      _stmt.setString(2, activityId)
      _stmt.setString(3, registration)
      _stmt.setString(4, since)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_stateUid = _resultSet.getLong("stateUid")
          val tmp_stateId = _resultSet.getString("stateId")
          val tmp_agentUid = _resultSet.getLong("agentUid")
          val tmp_activityId = _resultSet.getString("activityId")
          val tmp_registration = _resultSet.getString("registration")
          val tmp_isIsactive = _resultSet.getBoolean("isIsactive")
          val tmp_timestamp = _resultSet.getLong("timestamp")
          val tmp_stateMasterChangeSeqNum = _resultSet.getLong("stateMasterChangeSeqNum")
          val tmp_stateLocalChangeSeqNum = _resultSet.getLong("stateLocalChangeSeqNum")
          val tmp_stateLastChangedBy = _resultSet.getInt("stateLastChangedBy")
          val tmp_stateLct = _resultSet.getLong("stateLct")
          val _entity = StateEntity()
          _entity.stateUid = tmp_stateUid
          _entity.stateId = tmp_stateId
          _entity.agentUid = tmp_agentUid
          _entity.activityId = tmp_activityId
          _entity.registration = tmp_registration
          _entity.isIsactive = tmp_isIsactive
          _entity.timestamp = tmp_timestamp
          _entity.stateMasterChangeSeqNum = tmp_stateMasterChangeSeqNum
          _entity.stateLocalChangeSeqNum = tmp_stateLocalChangeSeqNum
          _entity.stateLastChangedBy = tmp_stateLastChangedBy
          _entity.stateLct = tmp_stateLct
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun updateStateToInActive(
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE StateEntity 
    |           SET isIsactive = ?,
    |               stateLct = ?
    |         WHERE agentUid = ? AND activityId = ? 
    |           AND registration = ? AND isIsactive
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE StateEntity 
    |           SET isIsactive = ?,
    |               stateLct = ?
    |         WHERE agentUid = ? AND activityId = ? 
    |           AND registration = ? AND isIsactive
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, isActive)
      _stmt.setLong(2, updateTime)
      _stmt.setLong(3, agentUid)
      _stmt.setString(4, activityId)
      _stmt.setString(5, registration)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun setStateInActive(
    stateId: String,
    agentUid: Long,
    activityId: String,
    registration: String,
    isActive: Boolean,
    updateTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE StateEntity 
    |          SET isIsactive = ?, 
    |              stateLct = ?
    |        WHERE stateId = ? AND agentUid = ? 
    |          AND activityId = ? AND registration = ? 
    |          AND isIsactive
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE StateEntity 
    |          SET isIsactive = ?, 
    |              stateLct = ?
    |        WHERE stateId = ? AND agentUid = ? 
    |          AND activityId = ? AND registration = ? 
    |          AND isIsactive
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, isActive)
      _stmt.setLong(2, updateTime)
      _stmt.setString(3, stateId)
      _stmt.setLong(4, agentUid)
      _stmt.setString(5, activityId)
      _stmt.setString(6, registration)
      val _numUpdates = _stmt.executeUpdate()
    }
  }

  public override fun insert(entity: StateEntity): Long {
    val _retVal = _insertAdapterStateEntity_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: StateEntity): Long {
    val _retVal = _insertAdapterStateEntity_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out StateEntity>): Unit {
    _insertAdapterStateEntity_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out StateEntity>): Unit {
    val _sql =
        "UPDATE StateEntity SET stateId = ?, agentUid = ?, activityId = ?, registration = ?, isIsactive = ?, timestamp = ?, stateMasterChangeSeqNum = ?, stateLocalChangeSeqNum = ?, stateLastChangedBy = ?, stateLct = ? WHERE stateUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.stateId)
        _stmt.setLong(2, _entity.agentUid)
        _stmt.setString(3, _entity.activityId)
        _stmt.setString(4, _entity.registration)
        _stmt.setBoolean(5, _entity.isIsactive)
        _stmt.setLong(6, _entity.timestamp)
        _stmt.setLong(7, _entity.stateMasterChangeSeqNum)
        _stmt.setLong(8, _entity.stateLocalChangeSeqNum)
        _stmt.setInt(9, _entity.stateLastChangedBy)
        _stmt.setLong(10, _entity.stateLct)
        _stmt.setLong(11, _entity.stateUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: StateEntity): Unit {
    val _sql =
        "UPDATE StateEntity SET stateId = ?, agentUid = ?, activityId = ?, registration = ?, isIsactive = ?, timestamp = ?, stateMasterChangeSeqNum = ?, stateLocalChangeSeqNum = ?, stateLastChangedBy = ?, stateLct = ? WHERE stateUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.stateId)
      _stmt.setLong(2, entity.agentUid)
      _stmt.setString(3, entity.activityId)
      _stmt.setString(4, entity.registration)
      _stmt.setBoolean(5, entity.isIsactive)
      _stmt.setLong(6, entity.timestamp)
      _stmt.setLong(7, entity.stateMasterChangeSeqNum)
      _stmt.setLong(8, entity.stateLocalChangeSeqNum)
      _stmt.setInt(9, entity.stateLastChangedBy)
      _stmt.setLong(10, entity.stateLct)
      _stmt.setLong(11, entity.stateUid)
      _stmt.executeUpdate()
    }
  }
}
