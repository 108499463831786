package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.Comments
import com.ustadmobile.lib.db.entities.CommentsWithPerson
import com.ustadmobile.lib.db.entities.Person
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class CommentsDao_JdbcKt(
  public val _db: DoorDatabase
) : CommentsDao() {
  public val _insertAdapterComments_: EntityInsertionAdapter<Comments> = object :
      EntityInsertionAdapter<Comments>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO Comments (commentsUid, commentsText, commentsEntityType, commentsEntityUid, commentsPublic, commentsStatus, commentsPersonUid, commentsToPersonUid, commentSubmitterUid, commentsFlagged, commentsInActive, commentsDateTimeAdded, commentsDateTimeUpdated, commentsMCSN, commentsLCSN, commentsLCB, commentsLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO Comments (commentsUid, commentsText, commentsEntityType, commentsEntityUid, commentsPublic, commentsStatus, commentsPersonUid, commentsToPersonUid, commentSubmitterUid, commentsFlagged, commentsInActive, commentsDateTimeAdded, commentsDateTimeUpdated, commentsMCSN, commentsLCSN, commentsLCB, commentsLct) VALUES(COALESCE(?,nextval('Comments_commentsUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING commentsUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: Comments): Unit {
      if(entity.commentsUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.commentsUid)
      }
      stmt.setString(2, entity.commentsText)
      stmt.setInt(3, entity.commentsEntityType)
      stmt.setLong(4, entity.commentsEntityUid)
      stmt.setBoolean(5, entity.commentsPublic)
      stmt.setInt(6, entity.commentsStatus)
      stmt.setLong(7, entity.commentsPersonUid)
      stmt.setLong(8, entity.commentsToPersonUid)
      stmt.setLong(9, entity.commentSubmitterUid)
      stmt.setBoolean(10, entity.commentsFlagged)
      stmt.setBoolean(11, entity.commentsInActive)
      stmt.setLong(12, entity.commentsDateTimeAdded)
      stmt.setLong(13, entity.commentsDateTimeUpdated)
      stmt.setLong(14, entity.commentsMCSN)
      stmt.setLong(15, entity.commentsLCSN)
      stmt.setInt(16, entity.commentsLCB)
      stmt.setLong(17, entity.commentsLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CommentsReplicate(commentsPk, commentsDestination)
    |      SELECT DISTINCT Comments.commentsUid AS commentsPk,
    |             ? AS commentsDestination
    |        FROM Comments
    |       WHERE Comments.commentsLct != COALESCE(
    |             (SELECT commentsVersionId
    |                FROM CommentsReplicate
    |               WHERE commentsPk = Comments.commentsUid
    |                 AND commentsDestination = ?), 0) 
    |      /*psql ON CONFLICT(commentsPk, commentsDestination) DO UPDATE
    |             SET commentsPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CommentsReplicate(commentsPk, commentsDestination)
    |      SELECT DISTINCT Comments.commentsUid AS commentsPk,
    |             ? AS commentsDestination
    |        FROM Comments
    |       WHERE Comments.commentsLct != COALESCE(
    |             (SELECT commentsVersionId
    |                FROM CommentsReplicate
    |               WHERE commentsPk = Comments.commentsUid
    |                 AND commentsDestination = ?), 0) 
    |       ON CONFLICT(commentsPk, commentsDestination) DO UPDATE
    |             SET commentsPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO CommentsReplicate(commentsPk, commentsDestination)
    |      SELECT DISTINCT Comments.commentsUid AS commentsPk,
    |             UserSession.usClientNodeId AS commentsDestination
    |        FROM ChangeLog
    |             JOIN Comments
    |                 ON ChangeLog.chTableId = 208
    |                    AND ChangeLog.chEntityPk = Comments.commentsUid
    |             JOIN UserSession 
    |                  ON UserSession.usStatus = 1
    |       WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |         AND Comments.commentsLct != COALESCE(
    |             (SELECT commentsVersionId
    |                FROM CommentsReplicate
    |               WHERE commentsPk = Comments.commentsUid
    |                 AND commentsDestination = UserSession.usClientNodeId), 0)
    |     /*psql ON CONFLICT(commentsPk, commentsDestination) DO UPDATE
    |         SET commentsPending = true
    |      */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO CommentsReplicate(commentsPk, commentsDestination)
    |      SELECT DISTINCT Comments.commentsUid AS commentsPk,
    |             UserSession.usClientNodeId AS commentsDestination
    |        FROM ChangeLog
    |             JOIN Comments
    |                 ON ChangeLog.chTableId = 208
    |                    AND ChangeLog.chEntityPk = Comments.commentsUid
    |             JOIN UserSession 
    |                  ON UserSession.usStatus = 1
    |       WHERE UserSession.usClientNodeId != (
    |             SELECT nodeClientId 
    |               FROM SyncNode
    |              LIMIT 1)
    |         AND Comments.commentsLct != COALESCE(
    |             (SELECT commentsVersionId
    |                FROM CommentsReplicate
    |               WHERE commentsPk = Comments.commentsUid
    |                 AND commentsDestination = UserSession.usClientNodeId), 0)
    |      ON CONFLICT(commentsPk, commentsDestination) DO UPDATE
    |         SET commentsPending = true
    |                     
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUidAsync(uid: Long): Comments? {
    var _result = null as com.ustadmobile.lib.db.entities.Comments??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM Comments WHERE commentsUid = ?  AND CAST(commentsInActive AS INTEGER) = 0"
        , postgreSql = """
    |SELECT * FROM Comments WHERE commentsUid = ?  AND CAST(commentsInActive AS INTEGER) = 0
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_commentsUid = _resultSet.getLong("commentsUid")
          val tmp_commentsText = _resultSet.getString("commentsText")
          val tmp_commentsEntityType = _resultSet.getInt("commentsEntityType")
          val tmp_commentsEntityUid = _resultSet.getLong("commentsEntityUid")
          val tmp_commentsPublic = _resultSet.getBoolean("commentsPublic")
          val tmp_commentsStatus = _resultSet.getInt("commentsStatus")
          val tmp_commentsPersonUid = _resultSet.getLong("commentsPersonUid")
          val tmp_commentsToPersonUid = _resultSet.getLong("commentsToPersonUid")
          val tmp_commentSubmitterUid = _resultSet.getLong("commentSubmitterUid")
          val tmp_commentsFlagged = _resultSet.getBoolean("commentsFlagged")
          val tmp_commentsInActive = _resultSet.getBoolean("commentsInActive")
          val tmp_commentsDateTimeAdded = _resultSet.getLong("commentsDateTimeAdded")
          val tmp_commentsDateTimeUpdated = _resultSet.getLong("commentsDateTimeUpdated")
          val tmp_commentsMCSN = _resultSet.getLong("commentsMCSN")
          val tmp_commentsLCSN = _resultSet.getLong("commentsLCSN")
          val tmp_commentsLCB = _resultSet.getInt("commentsLCB")
          val tmp_commentsLct = _resultSet.getLong("commentsLct")
          val _entity = Comments()
          _entity.commentsUid = tmp_commentsUid
          _entity.commentsText = tmp_commentsText
          _entity.commentsEntityType = tmp_commentsEntityType
          _entity.commentsEntityUid = tmp_commentsEntityUid
          _entity.commentsPublic = tmp_commentsPublic
          _entity.commentsStatus = tmp_commentsStatus
          _entity.commentsPersonUid = tmp_commentsPersonUid
          _entity.commentsToPersonUid = tmp_commentsToPersonUid
          _entity.commentSubmitterUid = tmp_commentSubmitterUid
          _entity.commentsFlagged = tmp_commentsFlagged
          _entity.commentsInActive = tmp_commentsInActive
          _entity.commentsDateTimeAdded = tmp_commentsDateTimeAdded
          _entity.commentsDateTimeUpdated = tmp_commentsDateTimeUpdated
          _entity.commentsMCSN = tmp_commentsMCSN
          _entity.commentsLCSN = tmp_commentsLCSN
          _entity.commentsLCB = tmp_commentsLCB
          _entity.commentsLct = tmp_commentsLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findPublicByEntityTypeAndUidLive(entityType: Int, entityUid: Long):
      DoorDataSourceFactory<Int, CommentsWithPerson> {
    val _result = object : DoorDataSourceFactory<Int, CommentsWithPerson>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<CommentsWithPerson>>
          = DoorLiveDataImpl<List<CommentsWithPerson>>(_db, listOf("Comments", "Person"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.CommentsWithPerson>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT Comments.*, Person.* 
        |          FROM Comments
        |                LEFT JOIN Person 
        |                ON Person.personUid = Comments.commentsPersonUid 
        |         WHERE Comments.commentsEntityType = ? 
        |           AND Comments.commentsEntityUid = ?
        |           AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |           AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |           AND CAST(Comments.commentsPublic AS INTEGER) = 1
        |      ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT Comments.*, Person.* 
        |          FROM Comments
        |                LEFT JOIN Person 
        |                ON Person.personUid = Comments.commentsPersonUid 
        |         WHERE Comments.commentsEntityType = ? 
        |           AND Comments.commentsEntityUid = ?
        |           AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |           AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |           AND CAST(Comments.commentsPublic AS INTEGER) = 1
        |      ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setInt(3, _limit)
          _stmt.setInt(4, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_commentsUid = _resultSet.getLong("commentsUid")
              val tmp_commentsText = _resultSet.getString("commentsText")
              val tmp_commentsEntityType = _resultSet.getInt("commentsEntityType")
              val tmp_commentsEntityUid = _resultSet.getLong("commentsEntityUid")
              val tmp_commentsPublic = _resultSet.getBoolean("commentsPublic")
              val tmp_commentsStatus = _resultSet.getInt("commentsStatus")
              val tmp_commentsPersonUid = _resultSet.getLong("commentsPersonUid")
              val tmp_commentsToPersonUid = _resultSet.getLong("commentsToPersonUid")
              val tmp_commentSubmitterUid = _resultSet.getLong("commentSubmitterUid")
              val tmp_commentsFlagged = _resultSet.getBoolean("commentsFlagged")
              val tmp_commentsInActive = _resultSet.getBoolean("commentsInActive")
              val tmp_commentsDateTimeAdded = _resultSet.getLong("commentsDateTimeAdded")
              val tmp_commentsDateTimeUpdated = _resultSet.getLong("commentsDateTimeUpdated")
              val tmp_commentsMCSN = _resultSet.getLong("commentsMCSN")
              val tmp_commentsLCSN = _resultSet.getLong("commentsLCSN")
              val tmp_commentsLCB = _resultSet.getInt("commentsLCB")
              val tmp_commentsLct = _resultSet.getLong("commentsLct")
              val _entity = CommentsWithPerson()
              _entity.commentsUid = tmp_commentsUid
              _entity.commentsText = tmp_commentsText
              _entity.commentsEntityType = tmp_commentsEntityType
              _entity.commentsEntityUid = tmp_commentsEntityUid
              _entity.commentsPublic = tmp_commentsPublic
              _entity.commentsStatus = tmp_commentsStatus
              _entity.commentsPersonUid = tmp_commentsPersonUid
              _entity.commentsToPersonUid = tmp_commentsToPersonUid
              _entity.commentSubmitterUid = tmp_commentSubmitterUid
              _entity.commentsFlagged = tmp_commentsFlagged
              _entity.commentsInActive = tmp_commentsInActive
              _entity.commentsDateTimeAdded = tmp_commentsDateTimeAdded
              _entity.commentsDateTimeUpdated = tmp_commentsDateTimeUpdated
              _entity.commentsMCSN = tmp_commentsMCSN
              _entity.commentsLCSN = tmp_commentsLCSN
              _entity.commentsLCB = tmp_commentsLCB
              _entity.commentsLct = tmp_commentsLct
              var _commentsPerson_nullFieldCount = 0
              val tmp_personUid = _resultSet.getLong("personUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_username = _resultSet.getString("username")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_firstNames = _resultSet.getString("firstNames")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_lastName = _resultSet.getString("lastName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_gender = _resultSet.getInt("gender")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_active = _resultSet.getBoolean("active")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_admin = _resultSet.getBoolean("admin")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personNotes = _resultSet.getString("personNotes")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherName = _resultSet.getString("fatherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherName = _resultSet.getString("motherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherNum = _resultSet.getString("motherNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personAddress = _resultSet.getString("personAddress")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLct = _resultSet.getLong("personLct")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personCountry = _resultSet.getString("personCountry")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personType = _resultSet.getInt("personType")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              if(_commentsPerson_nullFieldCount < 24) {
                if(_entity.commentsPerson == null) {
                  _entity.commentsPerson = Person()
                }
                _entity.commentsPerson!!.personUid = tmp_personUid
                _entity.commentsPerson!!.username = tmp_username
                _entity.commentsPerson!!.firstNames = tmp_firstNames
                _entity.commentsPerson!!.lastName = tmp_lastName
                _entity.commentsPerson!!.emailAddr = tmp_emailAddr
                _entity.commentsPerson!!.phoneNum = tmp_phoneNum
                _entity.commentsPerson!!.gender = tmp_gender
                _entity.commentsPerson!!.active = tmp_active
                _entity.commentsPerson!!.admin = tmp_admin
                _entity.commentsPerson!!.personNotes = tmp_personNotes
                _entity.commentsPerson!!.fatherName = tmp_fatherName
                _entity.commentsPerson!!.fatherNumber = tmp_fatherNumber
                _entity.commentsPerson!!.motherName = tmp_motherName
                _entity.commentsPerson!!.motherNum = tmp_motherNum
                _entity.commentsPerson!!.dateOfBirth = tmp_dateOfBirth
                _entity.commentsPerson!!.personAddress = tmp_personAddress
                _entity.commentsPerson!!.personOrgId = tmp_personOrgId
                _entity.commentsPerson!!.personGroupUid = tmp_personGroupUid
                _entity.commentsPerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                _entity.commentsPerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                _entity.commentsPerson!!.personLastChangedBy = tmp_personLastChangedBy
                _entity.commentsPerson!!.personLct = tmp_personLct
                _entity.commentsPerson!!.personCountry = tmp_personCountry
                _entity.commentsPerson!!.personType = tmp_personType
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Comments", "Person"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT Comments.*, Person.* 
        |          FROM Comments
        |                LEFT JOIN Person 
        |                ON Person.personUid = Comments.commentsPersonUid 
        |         WHERE Comments.commentsEntityType = ? 
        |           AND Comments.commentsEntityUid = ?
        |           AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |           AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |           AND CAST(Comments.commentsPublic AS INTEGER) = 1
        |      ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT Comments.*, Person.* 
        |          FROM Comments
        |                LEFT JOIN Person 
        |                ON Person.personUid = Comments.commentsPersonUid 
        |         WHERE Comments.commentsEntityType = ? 
        |           AND Comments.commentsEntityUid = ?
        |           AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |           AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |           AND CAST(Comments.commentsPublic AS INTEGER) = 1
        |      ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findPrivateByEntityTypeAndUidAndForPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long
  ): DoorDataSourceFactory<Int, CommentsWithPerson> {
    val _result = object : DoorDataSourceFactory<Int, CommentsWithPerson>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<CommentsWithPerson>>
          = DoorLiveDataImpl<List<CommentsWithPerson>>(_db, listOf("Comments", "Person"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.CommentsWithPerson>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND Comments.commentsPersonUid = ? OR Comments.commentsToPersonUid = ? 
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND Person.personUid = ?
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND Comments.commentsPersonUid = ? OR Comments.commentsToPersonUid = ? 
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND Person.personUid = ?
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setLong(3, personUid)
          _stmt.setLong(4, personUid)
          _stmt.setLong(5, personUid)
          _stmt.setInt(6, _limit)
          _stmt.setInt(7, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_commentsUid = _resultSet.getLong("commentsUid")
              val tmp_commentsText = _resultSet.getString("commentsText")
              val tmp_commentsEntityType = _resultSet.getInt("commentsEntityType")
              val tmp_commentsEntityUid = _resultSet.getLong("commentsEntityUid")
              val tmp_commentsPublic = _resultSet.getBoolean("commentsPublic")
              val tmp_commentsStatus = _resultSet.getInt("commentsStatus")
              val tmp_commentsPersonUid = _resultSet.getLong("commentsPersonUid")
              val tmp_commentsToPersonUid = _resultSet.getLong("commentsToPersonUid")
              val tmp_commentSubmitterUid = _resultSet.getLong("commentSubmitterUid")
              val tmp_commentsFlagged = _resultSet.getBoolean("commentsFlagged")
              val tmp_commentsInActive = _resultSet.getBoolean("commentsInActive")
              val tmp_commentsDateTimeAdded = _resultSet.getLong("commentsDateTimeAdded")
              val tmp_commentsDateTimeUpdated = _resultSet.getLong("commentsDateTimeUpdated")
              val tmp_commentsMCSN = _resultSet.getLong("commentsMCSN")
              val tmp_commentsLCSN = _resultSet.getLong("commentsLCSN")
              val tmp_commentsLCB = _resultSet.getInt("commentsLCB")
              val tmp_commentsLct = _resultSet.getLong("commentsLct")
              val _entity = CommentsWithPerson()
              _entity.commentsUid = tmp_commentsUid
              _entity.commentsText = tmp_commentsText
              _entity.commentsEntityType = tmp_commentsEntityType
              _entity.commentsEntityUid = tmp_commentsEntityUid
              _entity.commentsPublic = tmp_commentsPublic
              _entity.commentsStatus = tmp_commentsStatus
              _entity.commentsPersonUid = tmp_commentsPersonUid
              _entity.commentsToPersonUid = tmp_commentsToPersonUid
              _entity.commentSubmitterUid = tmp_commentSubmitterUid
              _entity.commentsFlagged = tmp_commentsFlagged
              _entity.commentsInActive = tmp_commentsInActive
              _entity.commentsDateTimeAdded = tmp_commentsDateTimeAdded
              _entity.commentsDateTimeUpdated = tmp_commentsDateTimeUpdated
              _entity.commentsMCSN = tmp_commentsMCSN
              _entity.commentsLCSN = tmp_commentsLCSN
              _entity.commentsLCB = tmp_commentsLCB
              _entity.commentsLct = tmp_commentsLct
              var _commentsPerson_nullFieldCount = 0
              val tmp_personUid = _resultSet.getLong("personUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_username = _resultSet.getString("username")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_firstNames = _resultSet.getString("firstNames")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_lastName = _resultSet.getString("lastName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_gender = _resultSet.getInt("gender")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_active = _resultSet.getBoolean("active")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_admin = _resultSet.getBoolean("admin")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personNotes = _resultSet.getString("personNotes")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherName = _resultSet.getString("fatherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherName = _resultSet.getString("motherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherNum = _resultSet.getString("motherNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personAddress = _resultSet.getString("personAddress")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLct = _resultSet.getLong("personLct")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personCountry = _resultSet.getString("personCountry")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personType = _resultSet.getInt("personType")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              if(_commentsPerson_nullFieldCount < 24) {
                if(_entity.commentsPerson == null) {
                  _entity.commentsPerson = Person()
                }
                _entity.commentsPerson!!.personUid = tmp_personUid
                _entity.commentsPerson!!.username = tmp_username
                _entity.commentsPerson!!.firstNames = tmp_firstNames
                _entity.commentsPerson!!.lastName = tmp_lastName
                _entity.commentsPerson!!.emailAddr = tmp_emailAddr
                _entity.commentsPerson!!.phoneNum = tmp_phoneNum
                _entity.commentsPerson!!.gender = tmp_gender
                _entity.commentsPerson!!.active = tmp_active
                _entity.commentsPerson!!.admin = tmp_admin
                _entity.commentsPerson!!.personNotes = tmp_personNotes
                _entity.commentsPerson!!.fatherName = tmp_fatherName
                _entity.commentsPerson!!.fatherNumber = tmp_fatherNumber
                _entity.commentsPerson!!.motherName = tmp_motherName
                _entity.commentsPerson!!.motherNum = tmp_motherNum
                _entity.commentsPerson!!.dateOfBirth = tmp_dateOfBirth
                _entity.commentsPerson!!.personAddress = tmp_personAddress
                _entity.commentsPerson!!.personOrgId = tmp_personOrgId
                _entity.commentsPerson!!.personGroupUid = tmp_personGroupUid
                _entity.commentsPerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                _entity.commentsPerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                _entity.commentsPerson!!.personLastChangedBy = tmp_personLastChangedBy
                _entity.commentsPerson!!.personLct = tmp_personLct
                _entity.commentsPerson!!.personCountry = tmp_personCountry
                _entity.commentsPerson!!.personType = tmp_personType
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Comments", "Person"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND Comments.commentsPersonUid = ? OR Comments.commentsToPersonUid = ? 
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND Person.personUid = ?
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND Comments.commentsPersonUid = ? OR Comments.commentsToPersonUid = ? 
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND Person.personUid = ?
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setLong(3, personUid)
          _stmt.setLong(4, personUid)
          _stmt.setLong(5, personUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findPrivateByEntityTypeAndUidAndForPersonLive2(
    entityType: Int,
    entityUid: Long,
    submitterUid: Long
  ): DoorDataSourceFactory<Int, CommentsWithPerson> {
    val _result = object : DoorDataSourceFactory<Int, CommentsWithPerson>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<CommentsWithPerson>>
          = DoorLiveDataImpl<List<CommentsWithPerson>>(_db, listOf("Comments", "Person"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.CommentsWithPerson>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |            SELECT Comments.*, 
        |                   Person.* 
        |              FROM Comments
        |                   LEFT JOIN Person 
        |                   ON Person.personUid = Comments.commentsPersonUid
        |             WHERE Comments.commentsEntityType = ? 
        |               AND Comments.commentsEntityUid = ?
        |               AND Comments.commentSubmitterUid = ?  
        |               AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |               AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |               AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |          ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |            SELECT Comments.*, 
        |                   Person.* 
        |              FROM Comments
        |                   LEFT JOIN Person 
        |                   ON Person.personUid = Comments.commentsPersonUid
        |             WHERE Comments.commentsEntityType = ? 
        |               AND Comments.commentsEntityUid = ?
        |               AND Comments.commentSubmitterUid = ?  
        |               AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |               AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |               AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |          ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setLong(3, submitterUid)
          _stmt.setInt(4, _limit)
          _stmt.setInt(5, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_commentsUid = _resultSet.getLong("commentsUid")
              val tmp_commentsText = _resultSet.getString("commentsText")
              val tmp_commentsEntityType = _resultSet.getInt("commentsEntityType")
              val tmp_commentsEntityUid = _resultSet.getLong("commentsEntityUid")
              val tmp_commentsPublic = _resultSet.getBoolean("commentsPublic")
              val tmp_commentsStatus = _resultSet.getInt("commentsStatus")
              val tmp_commentsPersonUid = _resultSet.getLong("commentsPersonUid")
              val tmp_commentsToPersonUid = _resultSet.getLong("commentsToPersonUid")
              val tmp_commentSubmitterUid = _resultSet.getLong("commentSubmitterUid")
              val tmp_commentsFlagged = _resultSet.getBoolean("commentsFlagged")
              val tmp_commentsInActive = _resultSet.getBoolean("commentsInActive")
              val tmp_commentsDateTimeAdded = _resultSet.getLong("commentsDateTimeAdded")
              val tmp_commentsDateTimeUpdated = _resultSet.getLong("commentsDateTimeUpdated")
              val tmp_commentsMCSN = _resultSet.getLong("commentsMCSN")
              val tmp_commentsLCSN = _resultSet.getLong("commentsLCSN")
              val tmp_commentsLCB = _resultSet.getInt("commentsLCB")
              val tmp_commentsLct = _resultSet.getLong("commentsLct")
              val _entity = CommentsWithPerson()
              _entity.commentsUid = tmp_commentsUid
              _entity.commentsText = tmp_commentsText
              _entity.commentsEntityType = tmp_commentsEntityType
              _entity.commentsEntityUid = tmp_commentsEntityUid
              _entity.commentsPublic = tmp_commentsPublic
              _entity.commentsStatus = tmp_commentsStatus
              _entity.commentsPersonUid = tmp_commentsPersonUid
              _entity.commentsToPersonUid = tmp_commentsToPersonUid
              _entity.commentSubmitterUid = tmp_commentSubmitterUid
              _entity.commentsFlagged = tmp_commentsFlagged
              _entity.commentsInActive = tmp_commentsInActive
              _entity.commentsDateTimeAdded = tmp_commentsDateTimeAdded
              _entity.commentsDateTimeUpdated = tmp_commentsDateTimeUpdated
              _entity.commentsMCSN = tmp_commentsMCSN
              _entity.commentsLCSN = tmp_commentsLCSN
              _entity.commentsLCB = tmp_commentsLCB
              _entity.commentsLct = tmp_commentsLct
              var _commentsPerson_nullFieldCount = 0
              val tmp_personUid = _resultSet.getLong("personUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_username = _resultSet.getString("username")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_firstNames = _resultSet.getString("firstNames")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_lastName = _resultSet.getString("lastName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_gender = _resultSet.getInt("gender")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_active = _resultSet.getBoolean("active")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_admin = _resultSet.getBoolean("admin")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personNotes = _resultSet.getString("personNotes")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherName = _resultSet.getString("fatherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherName = _resultSet.getString("motherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherNum = _resultSet.getString("motherNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personAddress = _resultSet.getString("personAddress")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLct = _resultSet.getLong("personLct")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personCountry = _resultSet.getString("personCountry")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personType = _resultSet.getInt("personType")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              if(_commentsPerson_nullFieldCount < 24) {
                if(_entity.commentsPerson == null) {
                  _entity.commentsPerson = Person()
                }
                _entity.commentsPerson!!.personUid = tmp_personUid
                _entity.commentsPerson!!.username = tmp_username
                _entity.commentsPerson!!.firstNames = tmp_firstNames
                _entity.commentsPerson!!.lastName = tmp_lastName
                _entity.commentsPerson!!.emailAddr = tmp_emailAddr
                _entity.commentsPerson!!.phoneNum = tmp_phoneNum
                _entity.commentsPerson!!.gender = tmp_gender
                _entity.commentsPerson!!.active = tmp_active
                _entity.commentsPerson!!.admin = tmp_admin
                _entity.commentsPerson!!.personNotes = tmp_personNotes
                _entity.commentsPerson!!.fatherName = tmp_fatherName
                _entity.commentsPerson!!.fatherNumber = tmp_fatherNumber
                _entity.commentsPerson!!.motherName = tmp_motherName
                _entity.commentsPerson!!.motherNum = tmp_motherNum
                _entity.commentsPerson!!.dateOfBirth = tmp_dateOfBirth
                _entity.commentsPerson!!.personAddress = tmp_personAddress
                _entity.commentsPerson!!.personOrgId = tmp_personOrgId
                _entity.commentsPerson!!.personGroupUid = tmp_personGroupUid
                _entity.commentsPerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                _entity.commentsPerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                _entity.commentsPerson!!.personLastChangedBy = tmp_personLastChangedBy
                _entity.commentsPerson!!.personLct = tmp_personLct
                _entity.commentsPerson!!.personCountry = tmp_personCountry
                _entity.commentsPerson!!.personType = tmp_personType
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Comments", "Person"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |            SELECT Comments.*, 
        |                   Person.* 
        |              FROM Comments
        |                   LEFT JOIN Person 
        |                   ON Person.personUid = Comments.commentsPersonUid
        |             WHERE Comments.commentsEntityType = ? 
        |               AND Comments.commentsEntityUid = ?
        |               AND Comments.commentSubmitterUid = ?  
        |               AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |               AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |               AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |          ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |            SELECT Comments.*, 
        |                   Person.* 
        |              FROM Comments
        |                   LEFT JOIN Person 
        |                   ON Person.personUid = Comments.commentsPersonUid
        |             WHERE Comments.commentsEntityType = ? 
        |               AND Comments.commentsEntityUid = ?
        |               AND Comments.commentSubmitterUid = ?  
        |               AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |               AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |               AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |          ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setLong(3, submitterUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findPrivateByEntityTypeAndUidAndPersonLive(
    entityType: Int,
    entityUid: Long,
    personUid: Long
  ): DoorDataSourceFactory<Int, CommentsWithPerson> {
    val _result = object : DoorDataSourceFactory<Int, CommentsWithPerson>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<CommentsWithPerson>>
          = DoorLiveDataImpl<List<CommentsWithPerson>>(_db, listOf("Comments", "Person"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.CommentsWithPerson>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND Person.personUid = ?
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND Person.personUid = ?
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setLong(3, personUid)
          _stmt.setInt(4, _limit)
          _stmt.setInt(5, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_commentsUid = _resultSet.getLong("commentsUid")
              val tmp_commentsText = _resultSet.getString("commentsText")
              val tmp_commentsEntityType = _resultSet.getInt("commentsEntityType")
              val tmp_commentsEntityUid = _resultSet.getLong("commentsEntityUid")
              val tmp_commentsPublic = _resultSet.getBoolean("commentsPublic")
              val tmp_commentsStatus = _resultSet.getInt("commentsStatus")
              val tmp_commentsPersonUid = _resultSet.getLong("commentsPersonUid")
              val tmp_commentsToPersonUid = _resultSet.getLong("commentsToPersonUid")
              val tmp_commentSubmitterUid = _resultSet.getLong("commentSubmitterUid")
              val tmp_commentsFlagged = _resultSet.getBoolean("commentsFlagged")
              val tmp_commentsInActive = _resultSet.getBoolean("commentsInActive")
              val tmp_commentsDateTimeAdded = _resultSet.getLong("commentsDateTimeAdded")
              val tmp_commentsDateTimeUpdated = _resultSet.getLong("commentsDateTimeUpdated")
              val tmp_commentsMCSN = _resultSet.getLong("commentsMCSN")
              val tmp_commentsLCSN = _resultSet.getLong("commentsLCSN")
              val tmp_commentsLCB = _resultSet.getInt("commentsLCB")
              val tmp_commentsLct = _resultSet.getLong("commentsLct")
              val _entity = CommentsWithPerson()
              _entity.commentsUid = tmp_commentsUid
              _entity.commentsText = tmp_commentsText
              _entity.commentsEntityType = tmp_commentsEntityType
              _entity.commentsEntityUid = tmp_commentsEntityUid
              _entity.commentsPublic = tmp_commentsPublic
              _entity.commentsStatus = tmp_commentsStatus
              _entity.commentsPersonUid = tmp_commentsPersonUid
              _entity.commentsToPersonUid = tmp_commentsToPersonUid
              _entity.commentSubmitterUid = tmp_commentSubmitterUid
              _entity.commentsFlagged = tmp_commentsFlagged
              _entity.commentsInActive = tmp_commentsInActive
              _entity.commentsDateTimeAdded = tmp_commentsDateTimeAdded
              _entity.commentsDateTimeUpdated = tmp_commentsDateTimeUpdated
              _entity.commentsMCSN = tmp_commentsMCSN
              _entity.commentsLCSN = tmp_commentsLCSN
              _entity.commentsLCB = tmp_commentsLCB
              _entity.commentsLct = tmp_commentsLct
              var _commentsPerson_nullFieldCount = 0
              val tmp_personUid = _resultSet.getLong("personUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_username = _resultSet.getString("username")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_firstNames = _resultSet.getString("firstNames")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_lastName = _resultSet.getString("lastName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_gender = _resultSet.getInt("gender")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_active = _resultSet.getBoolean("active")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_admin = _resultSet.getBoolean("admin")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personNotes = _resultSet.getString("personNotes")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherName = _resultSet.getString("fatherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherName = _resultSet.getString("motherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherNum = _resultSet.getString("motherNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personAddress = _resultSet.getString("personAddress")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLct = _resultSet.getLong("personLct")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personCountry = _resultSet.getString("personCountry")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personType = _resultSet.getInt("personType")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              if(_commentsPerson_nullFieldCount < 24) {
                if(_entity.commentsPerson == null) {
                  _entity.commentsPerson = Person()
                }
                _entity.commentsPerson!!.personUid = tmp_personUid
                _entity.commentsPerson!!.username = tmp_username
                _entity.commentsPerson!!.firstNames = tmp_firstNames
                _entity.commentsPerson!!.lastName = tmp_lastName
                _entity.commentsPerson!!.emailAddr = tmp_emailAddr
                _entity.commentsPerson!!.phoneNum = tmp_phoneNum
                _entity.commentsPerson!!.gender = tmp_gender
                _entity.commentsPerson!!.active = tmp_active
                _entity.commentsPerson!!.admin = tmp_admin
                _entity.commentsPerson!!.personNotes = tmp_personNotes
                _entity.commentsPerson!!.fatherName = tmp_fatherName
                _entity.commentsPerson!!.fatherNumber = tmp_fatherNumber
                _entity.commentsPerson!!.motherName = tmp_motherName
                _entity.commentsPerson!!.motherNum = tmp_motherNum
                _entity.commentsPerson!!.dateOfBirth = tmp_dateOfBirth
                _entity.commentsPerson!!.personAddress = tmp_personAddress
                _entity.commentsPerson!!.personOrgId = tmp_personOrgId
                _entity.commentsPerson!!.personGroupUid = tmp_personGroupUid
                _entity.commentsPerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                _entity.commentsPerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                _entity.commentsPerson!!.personLastChangedBy = tmp_personLastChangedBy
                _entity.commentsPerson!!.personLct = tmp_personLct
                _entity.commentsPerson!!.personCountry = tmp_personCountry
                _entity.commentsPerson!!.personType = tmp_personType
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Comments", "Person"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND Person.personUid = ?
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND Person.personUid = ?
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setLong(3, personUid)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToLive(
    entityType: Int,
    entityUid: Long,
    personFrom: Long
  ): DoorDataSourceFactory<Int, CommentsWithPerson> {
    val _result = object : DoorDataSourceFactory<Int, CommentsWithPerson>() {
      public override fun getData(_offset: Int, _limit: Int): DoorLiveData<List<CommentsWithPerson>>
          = DoorLiveDataImpl<List<CommentsWithPerson>>(_db, listOf("Comments", "Person"))  {
        var _liveResult = mutableListOf<com.ustadmobile.lib.db.entities.CommentsWithPerson>()
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT * FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND (Comments.commentsToPersonUid = ? 
        |         OR Comments.commentsPersonUid = ?)
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        """.trimMargin() , postgreSql = """
        |SELECT * FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND (Comments.commentsToPersonUid = ? 
        |         OR Comments.commentsPersonUid = ?)
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) LIMIT ? OFFSET ? 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setLong(3, personFrom)
          _stmt.setLong(4, personFrom)
          _stmt.setInt(5, _limit)
          _stmt.setInt(6, _offset)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            while(_resultSet.next()) {
              val tmp_commentsUid = _resultSet.getLong("commentsUid")
              val tmp_commentsText = _resultSet.getString("commentsText")
              val tmp_commentsEntityType = _resultSet.getInt("commentsEntityType")
              val tmp_commentsEntityUid = _resultSet.getLong("commentsEntityUid")
              val tmp_commentsPublic = _resultSet.getBoolean("commentsPublic")
              val tmp_commentsStatus = _resultSet.getInt("commentsStatus")
              val tmp_commentsPersonUid = _resultSet.getLong("commentsPersonUid")
              val tmp_commentsToPersonUid = _resultSet.getLong("commentsToPersonUid")
              val tmp_commentSubmitterUid = _resultSet.getLong("commentSubmitterUid")
              val tmp_commentsFlagged = _resultSet.getBoolean("commentsFlagged")
              val tmp_commentsInActive = _resultSet.getBoolean("commentsInActive")
              val tmp_commentsDateTimeAdded = _resultSet.getLong("commentsDateTimeAdded")
              val tmp_commentsDateTimeUpdated = _resultSet.getLong("commentsDateTimeUpdated")
              val tmp_commentsMCSN = _resultSet.getLong("commentsMCSN")
              val tmp_commentsLCSN = _resultSet.getLong("commentsLCSN")
              val tmp_commentsLCB = _resultSet.getInt("commentsLCB")
              val tmp_commentsLct = _resultSet.getLong("commentsLct")
              val _entity = CommentsWithPerson()
              _entity.commentsUid = tmp_commentsUid
              _entity.commentsText = tmp_commentsText
              _entity.commentsEntityType = tmp_commentsEntityType
              _entity.commentsEntityUid = tmp_commentsEntityUid
              _entity.commentsPublic = tmp_commentsPublic
              _entity.commentsStatus = tmp_commentsStatus
              _entity.commentsPersonUid = tmp_commentsPersonUid
              _entity.commentsToPersonUid = tmp_commentsToPersonUid
              _entity.commentSubmitterUid = tmp_commentSubmitterUid
              _entity.commentsFlagged = tmp_commentsFlagged
              _entity.commentsInActive = tmp_commentsInActive
              _entity.commentsDateTimeAdded = tmp_commentsDateTimeAdded
              _entity.commentsDateTimeUpdated = tmp_commentsDateTimeUpdated
              _entity.commentsMCSN = tmp_commentsMCSN
              _entity.commentsLCSN = tmp_commentsLCSN
              _entity.commentsLCB = tmp_commentsLCB
              _entity.commentsLct = tmp_commentsLct
              var _commentsPerson_nullFieldCount = 0
              val tmp_personUid = _resultSet.getLong("personUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_username = _resultSet.getString("username")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_firstNames = _resultSet.getString("firstNames")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_lastName = _resultSet.getString("lastName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_emailAddr = _resultSet.getString("emailAddr")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_phoneNum = _resultSet.getString("phoneNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_gender = _resultSet.getInt("gender")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_active = _resultSet.getBoolean("active")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_admin = _resultSet.getBoolean("admin")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personNotes = _resultSet.getString("personNotes")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherName = _resultSet.getString("fatherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_fatherNumber = _resultSet.getString("fatherNumber")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherName = _resultSet.getString("motherName")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_motherNum = _resultSet.getString("motherNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personAddress = _resultSet.getString("personAddress")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personOrgId = _resultSet.getString("personOrgId")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personLct = _resultSet.getLong("personLct")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personCountry = _resultSet.getString("personCountry")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              val tmp_personType = _resultSet.getInt("personType")
              if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
              if(_commentsPerson_nullFieldCount < 24) {
                if(_entity.commentsPerson == null) {
                  _entity.commentsPerson = Person()
                }
                _entity.commentsPerson!!.personUid = tmp_personUid
                _entity.commentsPerson!!.username = tmp_username
                _entity.commentsPerson!!.firstNames = tmp_firstNames
                _entity.commentsPerson!!.lastName = tmp_lastName
                _entity.commentsPerson!!.emailAddr = tmp_emailAddr
                _entity.commentsPerson!!.phoneNum = tmp_phoneNum
                _entity.commentsPerson!!.gender = tmp_gender
                _entity.commentsPerson!!.active = tmp_active
                _entity.commentsPerson!!.admin = tmp_admin
                _entity.commentsPerson!!.personNotes = tmp_personNotes
                _entity.commentsPerson!!.fatherName = tmp_fatherName
                _entity.commentsPerson!!.fatherNumber = tmp_fatherNumber
                _entity.commentsPerson!!.motherName = tmp_motherName
                _entity.commentsPerson!!.motherNum = tmp_motherNum
                _entity.commentsPerson!!.dateOfBirth = tmp_dateOfBirth
                _entity.commentsPerson!!.personAddress = tmp_personAddress
                _entity.commentsPerson!!.personOrgId = tmp_personOrgId
                _entity.commentsPerson!!.personGroupUid = tmp_personGroupUid
                _entity.commentsPerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
                _entity.commentsPerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
                _entity.commentsPerson!!.personLastChangedBy = tmp_personLastChangedBy
                _entity.commentsPerson!!.personLct = tmp_personLct
                _entity.commentsPerson!!.personCountry = tmp_personCountry
                _entity.commentsPerson!!.personType = tmp_personType
              }
              _liveResult.add(_entity)
            }
          }
        }
        _liveResult.toList()
      }

      public override fun getLength(): DoorLiveData<Int> = DoorLiveDataImpl<Int>(_db,
          listOf("Comments", "Person"))  {
        var _liveResult = 0
        val _stmtConfig = PreparedStatementConfig("""
        |SELECT COUNT(*) FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND (Comments.commentsToPersonUid = ? 
        |         OR Comments.commentsPersonUid = ?)
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        """.trimMargin() , postgreSql = """
        |SELECT COUNT(*) FROM (
        |        SELECT Comments.*, Person.* FROM Comments
        |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
        |        WHERE Comments.commentsEntityType = ? 
        |        AND Comments.commentsEntityUid = ?
        |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
        |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
        |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
        |        AND (Comments.commentsToPersonUid = ? 
        |         OR Comments.commentsPersonUid = ?)
        |        ORDER BY Comments.commentsDateTimeAdded DESC 
        |    ) 
        |""".trimMargin())
        _db.prepareAndUseStatementAsync(_stmtConfig) {
          _stmt ->
          _stmt.setInt(1, entityType)
          _stmt.setLong(2, entityUid)
          _stmt.setLong(3, personFrom)
          _stmt.setLong(4, personFrom)
          _stmt.executeQueryAsyncKmp().useResults {
             _resultSet ->
            if(_resultSet.next()) {
              val _entity = _resultSet.getInt(1)
              _liveResult = _entity
            }
          }
        }
        _liveResult
      }
    }
    return _result
  }

  public override fun findPrivateCommentsByEntityTypeAndUidAndPersonAndPersonToTest(
    entityType: Int,
    entityUid: Long,
    personFrom: Long,
    personTo: Long
  ): List<CommentsWithPerson> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.CommentsWithPerson>()
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        SELECT Comments.*, Person.* FROM Comments
    |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
    |        WHERE Comments.commentsEntityType = ? 
    |        AND Comments.commentsEntityUid = ?
    |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
    |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
    |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
    |        AND Comments.commentsPersonUid = ? 
    |        OR (? = 0 OR Comments.commentsToPersonUid = ?)
    |        ORDER BY Comments.commentsDateTimeAdded DESC 
    |    
    """.trimMargin() , postgreSql = """
    |
    |        SELECT Comments.*, Person.* FROM Comments
    |        LEFT JOIN Person ON Person.personUid = Comments.commentsPersonUid 
    |        WHERE Comments.commentsEntityType = ? 
    |        AND Comments.commentsEntityUid = ?
    |        AND CAST(Comments.commentsFlagged AS INTEGER) = 0
    |        AND CAST(Comments.commentsInActive AS INTEGER) = 0
    |        AND CAST(Comments.commentsPublic AS INTEGER) = 0
    |        AND Comments.commentsPersonUid = ? 
    |        OR (? = 0 OR Comments.commentsToPersonUid = ?)
    |        ORDER BY Comments.commentsDateTimeAdded DESC 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, entityType)
      _stmt.setLong(2, entityUid)
      _stmt.setLong(3, personFrom)
      _stmt.setLong(4, personTo)
      _stmt.setLong(5, personFrom)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_commentsUid = _resultSet.getLong("commentsUid")
          val tmp_commentsText = _resultSet.getString("commentsText")
          val tmp_commentsEntityType = _resultSet.getInt("commentsEntityType")
          val tmp_commentsEntityUid = _resultSet.getLong("commentsEntityUid")
          val tmp_commentsPublic = _resultSet.getBoolean("commentsPublic")
          val tmp_commentsStatus = _resultSet.getInt("commentsStatus")
          val tmp_commentsPersonUid = _resultSet.getLong("commentsPersonUid")
          val tmp_commentsToPersonUid = _resultSet.getLong("commentsToPersonUid")
          val tmp_commentSubmitterUid = _resultSet.getLong("commentSubmitterUid")
          val tmp_commentsFlagged = _resultSet.getBoolean("commentsFlagged")
          val tmp_commentsInActive = _resultSet.getBoolean("commentsInActive")
          val tmp_commentsDateTimeAdded = _resultSet.getLong("commentsDateTimeAdded")
          val tmp_commentsDateTimeUpdated = _resultSet.getLong("commentsDateTimeUpdated")
          val tmp_commentsMCSN = _resultSet.getLong("commentsMCSN")
          val tmp_commentsLCSN = _resultSet.getLong("commentsLCSN")
          val tmp_commentsLCB = _resultSet.getInt("commentsLCB")
          val tmp_commentsLct = _resultSet.getLong("commentsLct")
          val _entity = CommentsWithPerson()
          _entity.commentsUid = tmp_commentsUid
          _entity.commentsText = tmp_commentsText
          _entity.commentsEntityType = tmp_commentsEntityType
          _entity.commentsEntityUid = tmp_commentsEntityUid
          _entity.commentsPublic = tmp_commentsPublic
          _entity.commentsStatus = tmp_commentsStatus
          _entity.commentsPersonUid = tmp_commentsPersonUid
          _entity.commentsToPersonUid = tmp_commentsToPersonUid
          _entity.commentSubmitterUid = tmp_commentSubmitterUid
          _entity.commentsFlagged = tmp_commentsFlagged
          _entity.commentsInActive = tmp_commentsInActive
          _entity.commentsDateTimeAdded = tmp_commentsDateTimeAdded
          _entity.commentsDateTimeUpdated = tmp_commentsDateTimeUpdated
          _entity.commentsMCSN = tmp_commentsMCSN
          _entity.commentsLCSN = tmp_commentsLCSN
          _entity.commentsLCB = tmp_commentsLCB
          _entity.commentsLct = tmp_commentsLct
          var _commentsPerson_nullFieldCount = 0
          val tmp_personUid = _resultSet.getLong("personUid")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_username = _resultSet.getString("username")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_firstNames = _resultSet.getString("firstNames")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_lastName = _resultSet.getString("lastName")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_emailAddr = _resultSet.getString("emailAddr")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_phoneNum = _resultSet.getString("phoneNum")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_gender = _resultSet.getInt("gender")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_active = _resultSet.getBoolean("active")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_admin = _resultSet.getBoolean("admin")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personNotes = _resultSet.getString("personNotes")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_fatherName = _resultSet.getString("fatherName")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_fatherNumber = _resultSet.getString("fatherNumber")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_motherName = _resultSet.getString("motherName")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_motherNum = _resultSet.getString("motherNum")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_dateOfBirth = _resultSet.getLong("dateOfBirth")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personAddress = _resultSet.getString("personAddress")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personOrgId = _resultSet.getString("personOrgId")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personGroupUid = _resultSet.getLong("personGroupUid")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personMasterChangeSeqNum = _resultSet.getLong("personMasterChangeSeqNum")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personLocalChangeSeqNum = _resultSet.getLong("personLocalChangeSeqNum")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personLastChangedBy = _resultSet.getInt("personLastChangedBy")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personLct = _resultSet.getLong("personLct")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personCountry = _resultSet.getString("personCountry")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          val tmp_personType = _resultSet.getInt("personType")
          if(_resultSet.wasNull()) { _commentsPerson_nullFieldCount++ }
          if(_commentsPerson_nullFieldCount < 24) {
            if(_entity.commentsPerson == null) {
              _entity.commentsPerson = Person()
            }
            _entity.commentsPerson!!.personUid = tmp_personUid
            _entity.commentsPerson!!.username = tmp_username
            _entity.commentsPerson!!.firstNames = tmp_firstNames
            _entity.commentsPerson!!.lastName = tmp_lastName
            _entity.commentsPerson!!.emailAddr = tmp_emailAddr
            _entity.commentsPerson!!.phoneNum = tmp_phoneNum
            _entity.commentsPerson!!.gender = tmp_gender
            _entity.commentsPerson!!.active = tmp_active
            _entity.commentsPerson!!.admin = tmp_admin
            _entity.commentsPerson!!.personNotes = tmp_personNotes
            _entity.commentsPerson!!.fatherName = tmp_fatherName
            _entity.commentsPerson!!.fatherNumber = tmp_fatherNumber
            _entity.commentsPerson!!.motherName = tmp_motherName
            _entity.commentsPerson!!.motherNum = tmp_motherNum
            _entity.commentsPerson!!.dateOfBirth = tmp_dateOfBirth
            _entity.commentsPerson!!.personAddress = tmp_personAddress
            _entity.commentsPerson!!.personOrgId = tmp_personOrgId
            _entity.commentsPerson!!.personGroupUid = tmp_personGroupUid
            _entity.commentsPerson!!.personMasterChangeSeqNum = tmp_personMasterChangeSeqNum
            _entity.commentsPerson!!.personLocalChangeSeqNum = tmp_personLocalChangeSeqNum
            _entity.commentsPerson!!.personLastChangedBy = tmp_personLastChangedBy
            _entity.commentsPerson!!.personLct = tmp_personLct
            _entity.commentsPerson!!.personCountry = tmp_personCountry
            _entity.commentsPerson!!.personType = tmp_personType
          }
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override suspend fun updateInActiveByCommentUid(
    uid: Long,
    inActive: Boolean,
    changeTime: Long
  ): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        UPDATE Comments 
    |           SET commentsInActive = ?,
    |               commentsLct = ?
    |         WHERE Comments.commentsUid = ?
    |    
    """.trimMargin() , postgreSql = """
    |
    |        UPDATE Comments 
    |           SET commentsInActive = ?,
    |               commentsLct = ?
    |         WHERE Comments.commentsUid = ?
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setBoolean(1, inActive)
      _stmt.setLong(2, changeTime)
      _stmt.setLong(3, uid)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun insert(entity: Comments): Long {
    val _retVal = _insertAdapterComments_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: Comments): Long {
    val _retVal = _insertAdapterComments_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out Comments>): Unit {
    _insertAdapterComments_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Comments>): Unit {
    val _sql =
        "UPDATE Comments SET commentsText = ?, commentsEntityType = ?, commentsEntityUid = ?, commentsPublic = ?, commentsStatus = ?, commentsPersonUid = ?, commentsToPersonUid = ?, commentSubmitterUid = ?, commentsFlagged = ?, commentsInActive = ?, commentsDateTimeAdded = ?, commentsDateTimeUpdated = ?, commentsMCSN = ?, commentsLCSN = ?, commentsLCB = ?, commentsLct = ? WHERE commentsUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.commentsText)
        _stmt.setInt(2, _entity.commentsEntityType)
        _stmt.setLong(3, _entity.commentsEntityUid)
        _stmt.setBoolean(4, _entity.commentsPublic)
        _stmt.setInt(5, _entity.commentsStatus)
        _stmt.setLong(6, _entity.commentsPersonUid)
        _stmt.setLong(7, _entity.commentsToPersonUid)
        _stmt.setLong(8, _entity.commentSubmitterUid)
        _stmt.setBoolean(9, _entity.commentsFlagged)
        _stmt.setBoolean(10, _entity.commentsInActive)
        _stmt.setLong(11, _entity.commentsDateTimeAdded)
        _stmt.setLong(12, _entity.commentsDateTimeUpdated)
        _stmt.setLong(13, _entity.commentsMCSN)
        _stmt.setLong(14, _entity.commentsLCSN)
        _stmt.setInt(15, _entity.commentsLCB)
        _stmt.setLong(16, _entity.commentsLct)
        _stmt.setLong(17, _entity.commentsUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: Comments): Unit {
    val _sql =
        "UPDATE Comments SET commentsText = ?, commentsEntityType = ?, commentsEntityUid = ?, commentsPublic = ?, commentsStatus = ?, commentsPersonUid = ?, commentsToPersonUid = ?, commentSubmitterUid = ?, commentsFlagged = ?, commentsInActive = ?, commentsDateTimeAdded = ?, commentsDateTimeUpdated = ?, commentsMCSN = ?, commentsLCSN = ?, commentsLCB = ?, commentsLct = ? WHERE commentsUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setString(1, entity.commentsText)
      _stmt.setInt(2, entity.commentsEntityType)
      _stmt.setLong(3, entity.commentsEntityUid)
      _stmt.setBoolean(4, entity.commentsPublic)
      _stmt.setInt(5, entity.commentsStatus)
      _stmt.setLong(6, entity.commentsPersonUid)
      _stmt.setLong(7, entity.commentsToPersonUid)
      _stmt.setLong(8, entity.commentSubmitterUid)
      _stmt.setBoolean(9, entity.commentsFlagged)
      _stmt.setBoolean(10, entity.commentsInActive)
      _stmt.setLong(11, entity.commentsDateTimeAdded)
      _stmt.setLong(12, entity.commentsDateTimeUpdated)
      _stmt.setLong(13, entity.commentsMCSN)
      _stmt.setLong(14, entity.commentsLCSN)
      _stmt.setInt(15, entity.commentsLCB)
      _stmt.setLong(16, entity.commentsLct)
      _stmt.setLong(17, entity.commentsUid)
      _stmt.executeUpdate()
    }
  }

  public override suspend fun insertListAsync(entityList: List<out Comments>): Unit {
    _insertAdapterComments_.insertListAsync(entityList)
  }

  public override suspend fun updateListAsync(entityList: List<out Comments>): Unit {
    val _sql =
        "UPDATE Comments SET commentsText = ?, commentsEntityType = ?, commentsEntityUid = ?, commentsPublic = ?, commentsStatus = ?, commentsPersonUid = ?, commentsToPersonUid = ?, commentSubmitterUid = ?, commentsFlagged = ?, commentsInActive = ?, commentsDateTimeAdded = ?, commentsDateTimeUpdated = ?, commentsMCSN = ?, commentsLCSN = ?, commentsLCB = ?, commentsLct = ? WHERE commentsUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setString(1, _entity.commentsText)
        _stmt.setInt(2, _entity.commentsEntityType)
        _stmt.setLong(3, _entity.commentsEntityUid)
        _stmt.setBoolean(4, _entity.commentsPublic)
        _stmt.setInt(5, _entity.commentsStatus)
        _stmt.setLong(6, _entity.commentsPersonUid)
        _stmt.setLong(7, _entity.commentsToPersonUid)
        _stmt.setLong(8, _entity.commentSubmitterUid)
        _stmt.setBoolean(9, _entity.commentsFlagged)
        _stmt.setBoolean(10, _entity.commentsInActive)
        _stmt.setLong(11, _entity.commentsDateTimeAdded)
        _stmt.setLong(12, _entity.commentsDateTimeUpdated)
        _stmt.setLong(13, _entity.commentsMCSN)
        _stmt.setLong(14, _entity.commentsLCSN)
        _stmt.setInt(15, _entity.commentsLCB)
        _stmt.setLong(16, _entity.commentsLct)
        _stmt.setLong(17, _entity.commentsUid)
        _stmt.executeUpdateAsyncKmp()
      }
      _stmt.getConnection().commit()
    }
  }
}
