package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.LanguageVariant
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class LanguageVariantDao_JdbcKt(
  public val _db: DoorDatabase
) : LanguageVariantDao() {
  public val _insertAdapterLanguageVariant_: EntityInsertionAdapter<LanguageVariant> = object :
      EntityInsertionAdapter<LanguageVariant>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO LanguageVariant (langVariantUid, langUid, countryCode, name, langVariantLocalChangeSeqNum, langVariantMasterChangeSeqNum, langVariantLastChangedBy, langVariantLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO LanguageVariant (langVariantUid, langUid, countryCode, name, langVariantLocalChangeSeqNum, langVariantMasterChangeSeqNum, langVariantLastChangedBy, langVariantLct) VALUES(COALESCE(?,nextval('LanguageVariant_langVariantUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING langVariantUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: LanguageVariant):
        Unit {
      if(entity.langVariantUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.langVariantUid)
      }
      stmt.setLong(2, entity.langUid)
      stmt.setString(3, entity.countryCode)
      stmt.setString(4, entity.name)
      stmt.setLong(5, entity.langVariantLocalChangeSeqNum)
      stmt.setLong(6, entity.langVariantMasterChangeSeqNum)
      stmt.setInt(7, entity.langVariantLastChangedBy)
      stmt.setLong(8, entity.langVariantLct)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO LanguageVariantReplicate(lvPk, lvDestination)
    |      SELECT DISTINCT LanguageVariant.langVariantUid AS lvPk,
    |             ? AS lvDestination
    |        FROM LanguageVariant
    |       WHERE LanguageVariant.langVariantLct != COALESCE(
    |             (SELECT lvVersionId
    |                FROM LanguageVariantReplicate
    |               WHERE lvPk = LanguageVariant.langVariantUid
    |                 AND lvDestination = ?), 0) 
    |      /*psql ON CONFLICT(lvPk, lvDestination) DO UPDATE
    |             SET lvPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LanguageVariantReplicate(lvPk, lvDestination)
    |      SELECT DISTINCT LanguageVariant.langVariantUid AS lvPk,
    |             ? AS lvDestination
    |        FROM LanguageVariant
    |       WHERE LanguageVariant.langVariantLct != COALESCE(
    |             (SELECT lvVersionId
    |                FROM LanguageVariantReplicate
    |               WHERE lvPk = LanguageVariant.langVariantUid
    |                 AND lvDestination = ?), 0) 
    |       ON CONFLICT(lvPk, lvDestination) DO UPDATE
    |             SET lvPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO LanguageVariantReplicate(lvPk, lvDestination)
    |  SELECT DISTINCT LanguageVariant.langVariantUid AS lvUid,
    |         UserSession.usClientNodeId AS lvDestination
    |    FROM ChangeLog
    |         JOIN LanguageVariant
    |             ON ChangeLog.chTableId = 10
    |                AND ChangeLog.chEntityPk = LanguageVariant.langVariantUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LanguageVariant.langVariantLct != COALESCE(
    |         (SELECT lvVersionId
    |            FROM LanguageVariantReplicate
    |           WHERE lvPk = LanguageVariant.langVariantUid
    |             AND lvDestination = UserSession.usClientNodeId), 0)
    | /*psql ON CONFLICT(lvPk, lvDestination) DO UPDATE
    |     SET lvPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO LanguageVariantReplicate(lvPk, lvDestination)
    |  SELECT DISTINCT LanguageVariant.langVariantUid AS lvUid,
    |         UserSession.usClientNodeId AS lvDestination
    |    FROM ChangeLog
    |         JOIN LanguageVariant
    |             ON ChangeLog.chTableId = 10
    |                AND ChangeLog.chEntityPk = LanguageVariant.langVariantUid
    |         JOIN UserSession ON UserSession.usStatus = 1
    |   WHERE UserSession.usClientNodeId != (
    |         SELECT nodeClientId 
    |           FROM SyncNode
    |          LIMIT 1)
    |     AND LanguageVariant.langVariantLct != COALESCE(
    |         (SELECT lvVersionId
    |            FROM LanguageVariantReplicate
    |           WHERE lvPk = LanguageVariant.langVariantUid
    |             AND lvDestination = UserSession.usClientNodeId), 0)
    |  ON CONFLICT(lvPk, lvDestination) DO UPDATE
    |     SET lvPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByCode(countryCode: String): LanguageVariant? {
    var _result = null as com.ustadmobile.lib.db.entities.LanguageVariant??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM LanguageVariant WHERE countryCode = ? LIMIT 1" ,
        postgreSql = """
    |SELECT * FROM LanguageVariant WHERE countryCode = ? LIMIT 1
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setString(1, countryCode)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_langVariantUid = _resultSet.getLong("langVariantUid")
          val tmp_langUid = _resultSet.getLong("langUid")
          val tmp_countryCode = _resultSet.getString("countryCode")
          val tmp_name = _resultSet.getString("name")
          val tmp_langVariantLocalChangeSeqNum = _resultSet.getLong("langVariantLocalChangeSeqNum")
          val tmp_langVariantMasterChangeSeqNum =
              _resultSet.getLong("langVariantMasterChangeSeqNum")
          val tmp_langVariantLastChangedBy = _resultSet.getInt("langVariantLastChangedBy")
          val tmp_langVariantLct = _resultSet.getLong("langVariantLct")
          val _entity = LanguageVariant()
          _entity.langVariantUid = tmp_langVariantUid
          _entity.langUid = tmp_langUid
          _entity.countryCode = tmp_countryCode
          _entity.name = tmp_name
          _entity.langVariantLocalChangeSeqNum = tmp_langVariantLocalChangeSeqNum
          _entity.langVariantMasterChangeSeqNum = tmp_langVariantMasterChangeSeqNum
          _entity.langVariantLastChangedBy = tmp_langVariantLastChangedBy
          _entity.langVariantLct = tmp_langVariantLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: LanguageVariant): Long {
    val _retVal = _insertAdapterLanguageVariant_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: LanguageVariant): Long {
    val _retVal = _insertAdapterLanguageVariant_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out LanguageVariant>): Unit {
    _insertAdapterLanguageVariant_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out LanguageVariant>): Unit {
    val _sql =
        "UPDATE LanguageVariant SET langUid = ?, countryCode = ?, name = ?, langVariantLocalChangeSeqNum = ?, langVariantMasterChangeSeqNum = ?, langVariantLastChangedBy = ?, langVariantLct = ? WHERE langVariantUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.langUid)
        _stmt.setString(2, _entity.countryCode)
        _stmt.setString(3, _entity.name)
        _stmt.setLong(4, _entity.langVariantLocalChangeSeqNum)
        _stmt.setLong(5, _entity.langVariantMasterChangeSeqNum)
        _stmt.setInt(6, _entity.langVariantLastChangedBy)
        _stmt.setLong(7, _entity.langVariantLct)
        _stmt.setLong(8, _entity.langVariantUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: LanguageVariant): Unit {
    val _sql =
        "UPDATE LanguageVariant SET langUid = ?, countryCode = ?, name = ?, langVariantLocalChangeSeqNum = ?, langVariantMasterChangeSeqNum = ?, langVariantLastChangedBy = ?, langVariantLct = ? WHERE langVariantUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.langUid)
      _stmt.setString(2, entity.countryCode)
      _stmt.setString(3, entity.name)
      _stmt.setLong(4, entity.langVariantLocalChangeSeqNum)
      _stmt.setLong(5, entity.langVariantMasterChangeSeqNum)
      _stmt.setInt(6, entity.langVariantLastChangedBy)
      _stmt.setLong(7, entity.langVariantLct)
      _stmt.setLong(8, entity.langVariantUid)
      _stmt.executeUpdate()
    }
  }
}
