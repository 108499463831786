package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.door.DoorLiveDataImpl
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.prepareAndUseStatementAsync
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.door.jdbc.ext.executeQueryAsyncKmp
import com.ustadmobile.door.jdbc.ext.executeUpdateAsyncKmp
import com.ustadmobile.lib.db.entities.PersonGroup
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public class PersonGroupDao_JdbcKt(
  public val _db: DoorDatabase
) : PersonGroupDao() {
  public val _insertAdapterPersonGroup_: EntityInsertionAdapter<PersonGroup> = object :
      EntityInsertionAdapter<PersonGroup>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO PersonGroup (groupUid, groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag) VALUES(?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO PersonGroup (groupUid, groupMasterCsn, groupLocalCsn, groupLastChangedBy, groupLct, groupName, groupActive, personGroupFlag) VALUES(COALESCE(?,nextval('PersonGroup_groupUid_seq')), ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING groupUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: PersonGroup):
        Unit {
      if(entity.groupUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.groupUid)
      }
      stmt.setLong(2, entity.groupMasterCsn)
      stmt.setLong(3, entity.groupLocalCsn)
      stmt.setInt(4, entity.groupLastChangedBy)
      stmt.setLong(5, entity.groupLct)
      stmt.setString(6, entity.groupName)
      stmt.setBoolean(7, entity.groupActive)
      stmt.setInt(8, entity.personGroupFlag)
    }
  }

  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    |     REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |      SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |    ,
    |             ? AS pgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |             JOIN PersonGroup
    |                    ON PersonGroup.groupUid = PersonsWithPerm_GroupMember.groupMemberGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND 
    |      PersonGroup.groupLct != COALESCE(
    |         (SELECT pgVersionId
    |            FROM PersonGroupReplicate
    |           WHERE pgPk = PersonGroup.groupUid
    |             AND pgDestination = UserSession.usClientNodeId), 0)
    |    
    |      /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |      */       
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |      SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |    ,
    |             ? AS pgDestination
    |        FROM UserSession
    |             JOIN PersonGroupMember
    |                    ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |             
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |                    64
    |                    
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |             JOIN PersonGroupMember PersonsWithPerm_GroupMember
    |                    ON PersonsWithPerm_GroupMember.groupMemberPersonUid = Person.personUid
    |             JOIN PersonGroup
    |                    ON PersonGroup.groupUid = PersonsWithPerm_GroupMember.groupMemberGroupUid
    |       WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND 
    |      PersonGroup.groupLct != COALESCE(
    |         (SELECT pgVersionId
    |            FROM PersonGroupReplicate
    |           WHERE pgPk = PersonGroup.groupUid
    |             AND pgDestination = UserSession.usClientNodeId), 0)
    |    
    |       ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |             
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      _stmt.setLong(2, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChange(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM ChangeLog
    |         JOIN PersonGroup
    |              ON ChangeLog.chTableId = 43
    |                AND ChangeLog.chEntityPk = PersonGroup.groupUid
    |         JOIN PersonGroupMember
    |              ON PersonGroupMember.groupMemberGroupUid = PersonGroup.groupUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                        
    |   WHERE 
    |            UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |        
    |     AND 
    |      PersonGroup.groupLct != COALESCE(
    |         (SELECT pgVersionId
    |            FROM PersonGroupReplicate
    |           WHERE pgPk = PersonGroup.groupUid
    |             AND pgDestination = UserSession.usClientNodeId), 0)
    |    
    | /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |  */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM ChangeLog
    |         JOIN PersonGroup
    |              ON ChangeLog.chTableId = 43
    |                AND ChangeLog.chEntityPk = PersonGroup.groupUid
    |         JOIN PersonGroupMember
    |              ON PersonGroupMember.groupMemberGroupUid = PersonGroup.groupUid
    |         JOIN Person
    |              ON PersonGroupMember.groupMemberPersonUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                        
    |   WHERE 
    |            UserSession.usClientNodeId != (
    |                 SELECT nodeClientId 
    |                   FROM SyncNode
    |                  LIMIT 1)
    |        
    |     AND 
    |      PersonGroup.groupLct != COALESCE(
    |         (SELECT pgVersionId
    |            FROM PersonGroupReplicate
    |           WHERE pgPk = PersonGroup.groupUid
    |             AND pgDestination = UserSession.usClientNodeId), 0)
    |    
    |  ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |                 
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeClazzBased(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |              
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                 2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |              
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |    */               
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Clazz 
    |              ON ScopedGrantEntity.sgTableId = 6
    |                 AND ScopedGrantEntity.sgEntityUid = Clazz.clazzUid
    |              
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |                    AND (ScopedGrant.sgPermissions & 
    |        
    |                 2
    |                 
    |              
    |                                                       ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |                                               
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |              
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |                   
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeClazzBased(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |              2 
    |              
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON Clazz.clazzUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 6
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |      /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |      */      
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |               JOIN ScopedGrant
    |                    ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions & 
    |        
    |              2 
    |              
    |                       ) > 0
    |               JOIN Clazz 
    |                    ON 
    |            ((ScopedGrant.sgTableId = -2
    |                                AND ScopedGrant.sgEntityUid = -2)
    |                            OR (ScopedGrant.sgTableId = 6
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzUid)
    |                            OR (ScopedGrant.sgTableId = 164
    |                                AND ScopedGrant.sgEntityUid = Clazz.clazzSchoolUid))
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON Clazz.clazzUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 6
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |       ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |            
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangePersonBased(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Person 
    |              ON ScopedGrantEntity.sgTableId = 9
    |                 AND ScopedGrantEntity.sgEntityUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                        
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |    */                     
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN Person 
    |              ON ScopedGrantEntity.sgTableId = 9
    |                 AND ScopedGrantEntity.sgEntityUid = Person.personUid
    |         
    |            JOIN ScopedGrant 
    |                   ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT clazzEnrolmentClazzUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentPersonUid = Person.personUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid IN (
    |                        SELECT DISTINCT schoolMemberSchoolUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberPersonUid = Person.personUid
    |                           AND schoolMemberActive))
    |                           )
    |        
    |                   AND (ScopedGrant.sgPermissions & 
    |        
    |              64
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                        
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodePersonBased(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |              64
    |              
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON Person.personUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 9
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |      /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |      */      
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |            JOIN ScopedGrant
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                    AND (ScopedGrant.sgPermissions &
    |              64
    |              
    |                                                    ) > 0
    |            JOIN Person 
    |                 ON 
    |                ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                 OR (ScopedGrant.sgTableId = 9
    |                    AND ScopedGrant.sgEntityUid = Person.personUid)
    |                 OR (ScopedGrant.sgTableId = 6       
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT clazzEnrolmentPersonUid
    |                          FROM ClazzEnrolment
    |                         WHERE clazzEnrolmentClazzUid =ScopedGrant.sgEntityUid 
    |                           AND ClazzEnrolment.clazzEnrolmentActive))
    |                 OR (ScopedGrant.sgTableId = 164
    |                    AND Person.personUid IN (
    |                        SELECT DISTINCT schoolMemberPersonUid
    |                          FROM SchoolMember
    |                         WHERE schoolMemberSchoolUid = ScopedGrant.sgEntityUid
    |                           AND schoolMemberActive))
    |                           )    
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON Person.personUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 9
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |       ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |            
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnChangeSchoolBased(): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN School 
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |              536870912
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |                        
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |    /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |    */                     
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |       SELECT DISTINCT PersonGroup.groupUid AS pgUid,
    |              UserSession.usClientNodeId AS pgDestination
    |         FROM ChangeLog
    |              JOIN PersonGroup
    |                   ON ChangeLog.chTableId = 43
    |                      AND ChangeLog.chEntityPk = PersonGroup.groupUid     
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON PersonGroup.groupUid = ScopedGrantEntity.sgGroupUid
    |         JOIN School 
    |              ON ScopedGrantEntity.sgTableId = 164
    |                 AND ScopedGrantEntity.sgEntityUid = School.schoolUid
    |         
    |            JOIN ScopedGrant
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |                        AND (SCopedGrant.sgPermissions &
    |        
    |              536870912
    |              
    |                                                     ) > 0
    |             JOIN PersonGroupMember AS PrsGrpMbr
    |                   ON ScopedGrant.sgGroupUid = PrsGrpMbr.groupMemberGroupUid
    |              JOIN UserSession
    |                   ON UserSession.usPersonUid = PrsGrpMbr.groupMemberPersonUid
    |                      AND UserSession.usStatus = 1
    |                      
    |                        
    |        WHERE UserSession.usClientNodeId != (
    |                SELECT nodeClientId 
    |                  FROM SyncNode
    |                 LIMIT 1)
    |          AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0)
    |     ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |     SET pgPending = true
    |                         
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override suspend fun replicateOnNewNodeSchoolBased(newNodeId: Long): Unit {
    val _stmtConfig = PreparedStatementConfig("""
    |
    | REPLACE INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |              536870912
    |              
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON School.schoolUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 9
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |      /*psql ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |      */      
    |    
    """.trimMargin() , postgreSql = """
    |INSERT INTO PersonGroupReplicate(pgPk, pgDestination)
    |  SELECT DISTINCT 
    |      PersonGroup.groupUid AS pgUid
    |     ,
    |         UserSession.usClientNodeId AS pgDestination
    |    FROM UserSession
    |         JOIN PersonGroupMember 
    |              ON UserSession.usPersonUid = PersonGroupMember.groupMemberPersonUid
    |         
    |            JOIN ScopedGrant 
    |                 ON ScopedGrant.sgGroupUid = PersonGroupMember.groupMemberGroupUid
    |                        AND (ScopedGrant.sgPermissions &
    |                 
    |        
    |              536870912
    |              
    |                    ) > 0
    |            JOIN School
    |                 ON 
    |            ((ScopedGrant.sgTableId = -2
    |                    AND ScopedGrant.sgEntityUid = -2)
    |                OR (ScopedGrant.sgTableId = 164
    |                    AND ScopedGrant.sgEntityUid = School.schoolUid))
    |        
    |        
    |         JOIN ScopedGrant ScopedGrantEntity
    |              ON School.schoolUid = ScopedGrantEntity.sgEntityUid
    |                 AND ScopedGrantEntity.sgTableId = 9
    |         JOIN PersonGroup
    |              ON ScopedGrantEntity.sgGroupUid = PersonGroup.groupUid
    |   WHERE UserSession.usClientNodeId = ?
    |         AND UserSession.usStatus = 1
    |         AND PersonGroup.groupLct != COALESCE(
    |              (SELECT pgVersionId
    |                 FROM PersonGroupReplicate
    |                WHERE pgPk = PersonGroup.groupUid
    |                  AND pgDestination = UserSession.usClientNodeId), 0) 
    |       ON CONFLICT(pgPk, pgDestination) DO UPDATE
    |             SET pgPending = true
    |            
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, newNodeId)
      val _numUpdates = _stmt.executeUpdateAsyncKmp()
    }
  }

  public override fun findByUid(uid: Long): PersonGroup? {
    var _result = null as com.ustadmobile.lib.db.entities.PersonGroup??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM PersonGroup WHERE groupUid = ?" ,
        postgreSql = """
    |SELECT * FROM PersonGroup WHERE groupUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_groupUid = _resultSet.getLong("groupUid")
          val tmp_groupMasterCsn = _resultSet.getLong("groupMasterCsn")
          val tmp_groupLocalCsn = _resultSet.getLong("groupLocalCsn")
          val tmp_groupLastChangedBy = _resultSet.getInt("groupLastChangedBy")
          val tmp_groupLct = _resultSet.getLong("groupLct")
          val tmp_groupName = _resultSet.getString("groupName")
          val tmp_groupActive = _resultSet.getBoolean("groupActive")
          val tmp_personGroupFlag = _resultSet.getInt("personGroupFlag")
          val _entity = PersonGroup()
          _entity.groupUid = tmp_groupUid
          _entity.groupMasterCsn = tmp_groupMasterCsn
          _entity.groupLocalCsn = tmp_groupLocalCsn
          _entity.groupLastChangedBy = tmp_groupLastChangedBy
          _entity.groupLct = tmp_groupLct
          _entity.groupName = tmp_groupName
          _entity.groupActive = tmp_groupActive
          _entity.personGroupFlag = tmp_personGroupFlag
          _result = _entity
        }
      }
    }
    return _result
  }

  public override suspend fun findByUidAsync(uid: Long): PersonGroup? {
    var _result = null as com.ustadmobile.lib.db.entities.PersonGroup??
    val _stmtConfig = PreparedStatementConfig("SELECT * FROM PersonGroup WHERE groupUid = ?" ,
        postgreSql = """
    |SELECT * FROM PersonGroup WHERE groupUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_groupUid = _resultSet.getLong("groupUid")
          val tmp_groupMasterCsn = _resultSet.getLong("groupMasterCsn")
          val tmp_groupLocalCsn = _resultSet.getLong("groupLocalCsn")
          val tmp_groupLastChangedBy = _resultSet.getInt("groupLastChangedBy")
          val tmp_groupLct = _resultSet.getLong("groupLct")
          val tmp_groupName = _resultSet.getString("groupName")
          val tmp_groupActive = _resultSet.getBoolean("groupActive")
          val tmp_personGroupFlag = _resultSet.getInt("personGroupFlag")
          val _entity = PersonGroup()
          _entity.groupUid = tmp_groupUid
          _entity.groupMasterCsn = tmp_groupMasterCsn
          _entity.groupLocalCsn = tmp_groupLocalCsn
          _entity.groupLastChangedBy = tmp_groupLastChangedBy
          _entity.groupLct = tmp_groupLct
          _entity.groupName = tmp_groupName
          _entity.groupActive = tmp_groupActive
          _entity.personGroupFlag = tmp_personGroupFlag
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun findByUidLive(uid: Long): DoorLiveData<PersonGroup?> {
    val _result = DoorLiveDataImpl<PersonGroup?>(_db, listOf("PersonGroup"))  {
      var _liveResult = null as com.ustadmobile.lib.db.entities.PersonGroup??
      val _stmtConfig = PreparedStatementConfig("SELECT * FROM PersonGroup WHERE groupUid = ?" ,
          postgreSql = """
      |SELECT * FROM PersonGroup WHERE groupUid = ?
      |""".trimMargin())
      _db.prepareAndUseStatementAsync(_stmtConfig) {
        _stmt ->
        _stmt.setLong(1, uid)
        _stmt.executeQueryAsyncKmp().useResults {
           _resultSet ->
          if(_resultSet.next()) {
            val tmp_groupUid = _resultSet.getLong("groupUid")
            val tmp_groupMasterCsn = _resultSet.getLong("groupMasterCsn")
            val tmp_groupLocalCsn = _resultSet.getLong("groupLocalCsn")
            val tmp_groupLastChangedBy = _resultSet.getInt("groupLastChangedBy")
            val tmp_groupLct = _resultSet.getLong("groupLct")
            val tmp_groupName = _resultSet.getString("groupName")
            val tmp_groupActive = _resultSet.getBoolean("groupActive")
            val tmp_personGroupFlag = _resultSet.getInt("personGroupFlag")
            val _entity = PersonGroup()
            _entity.groupUid = tmp_groupUid
            _entity.groupMasterCsn = tmp_groupMasterCsn
            _entity.groupLocalCsn = tmp_groupLocalCsn
            _entity.groupLastChangedBy = tmp_groupLastChangedBy
            _entity.groupLct = tmp_groupLct
            _entity.groupName = tmp_groupName
            _entity.groupActive = tmp_groupActive
            _entity.personGroupFlag = tmp_personGroupFlag
            _liveResult = _entity
          }
        }
      }
      _liveResult
    }
    return _result
  }

  public override suspend fun updateAsync(entity: PersonGroup): Int {
    var _result = 0
    val _sql =
        "UPDATE PersonGroup SET groupMasterCsn = ?, groupLocalCsn = ?, groupLastChangedBy = ?, groupLct = ?, groupName = ?, groupActive = ?, personGroupFlag = ? WHERE groupUid = ?"
    _db.prepareAndUseStatementAsync(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.groupMasterCsn)
      _stmt.setLong(2, entity.groupLocalCsn)
      _stmt.setInt(3, entity.groupLastChangedBy)
      _stmt.setLong(4, entity.groupLct)
      _stmt.setString(5, entity.groupName)
      _stmt.setBoolean(6, entity.groupActive)
      _stmt.setInt(7, entity.personGroupFlag)
      _stmt.setLong(8, entity.groupUid)
      _result += _stmt.executeUpdateAsyncKmp()
    }
    return _result
  }

  public override suspend fun findNameByGroupUid(groupUid: Long): String? {
    var _result = null as kotlin.String??
    val _stmtConfig = PreparedStatementConfig("""
    |
    |        Select CASE
    |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
    |               ELSE PersonGroup.groupName 
    |               END AS name
    |          FROM PersonGroup
    |               LEFT JOIN Person
    |                         ON Person.personGroupUid = PersonGroup.groupUid
    |         WHERE PersonGroup.groupUid = ?
    |         LIMIT 1
    |    
    """.trimMargin() , postgreSql = """
    |
    |        Select CASE
    |               WHEN Person.firstNames IS NOT NULL THEN Person.firstNames
    |               ELSE PersonGroup.groupName 
    |               END AS name
    |          FROM PersonGroup
    |               LEFT JOIN Person
    |                         ON Person.personGroupUid = PersonGroup.groupUid
    |         WHERE PersonGroup.groupUid = ?
    |         LIMIT 1
    |    
    |""".trimMargin())
    _db.prepareAndUseStatementAsync(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, groupUid)
      _stmt.executeQueryAsyncKmp().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val _entity = _resultSet.getString(1)
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: PersonGroup): Long {
    val _retVal = _insertAdapterPersonGroup_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: PersonGroup): Long {
    val _retVal = _insertAdapterPersonGroup_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out PersonGroup>): Unit {
    _insertAdapterPersonGroup_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out PersonGroup>): Unit {
    val _sql =
        "UPDATE PersonGroup SET groupMasterCsn = ?, groupLocalCsn = ?, groupLastChangedBy = ?, groupLct = ?, groupName = ?, groupActive = ?, personGroupFlag = ? WHERE groupUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.groupMasterCsn)
        _stmt.setLong(2, _entity.groupLocalCsn)
        _stmt.setInt(3, _entity.groupLastChangedBy)
        _stmt.setLong(4, _entity.groupLct)
        _stmt.setString(5, _entity.groupName)
        _stmt.setBoolean(6, _entity.groupActive)
        _stmt.setInt(7, _entity.personGroupFlag)
        _stmt.setLong(8, _entity.groupUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: PersonGroup): Unit {
    val _sql =
        "UPDATE PersonGroup SET groupMasterCsn = ?, groupLocalCsn = ?, groupLastChangedBy = ?, groupLct = ?, groupName = ?, groupActive = ?, personGroupFlag = ? WHERE groupUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.groupMasterCsn)
      _stmt.setLong(2, entity.groupLocalCsn)
      _stmt.setInt(3, entity.groupLastChangedBy)
      _stmt.setLong(4, entity.groupLct)
      _stmt.setString(5, entity.groupName)
      _stmt.setBoolean(6, entity.groupActive)
      _stmt.setInt(7, entity.personGroupFlag)
      _stmt.setLong(8, entity.groupUid)
      _stmt.executeUpdate()
    }
  }
}
