package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDbType
import com.ustadmobile.door.EntityInsertionAdapter
import com.ustadmobile.door.PreparedStatementConfig
import com.ustadmobile.door.ext.prepareAndUseStatement
import com.ustadmobile.door.ext.useResults
import com.ustadmobile.door.jdbc.PreparedStatement
import com.ustadmobile.lib.db.entities.ClazzLog
import com.ustadmobile.lib.db.entities.ScheduledCheck
import kotlin.Boolean
import kotlin.IllegalArgumentException
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.List

public class ScheduledCheckDao_JdbcKt(
  public val _db: DoorDatabase
) : ScheduledCheckDao() {
  public val _insertAdapterScheduledCheck_: EntityInsertionAdapter<ScheduledCheck> = object :
      EntityInsertionAdapter<ScheduledCheck>(_db) {
    public override fun makeSql(returnsId: Boolean) = when(dbType) {
      DoorDbType.SQLITE -> {
        "INSERT INTO ScheduledCheck (scheduledCheckUid, checkTime, checkType, checkUuid, checkParameters, scClazzLogUid, scheduledCheckMasterCsn, scheduledCheckLocalCsn, scheduledCheckLastChangedBy, scheduledCheckLct) VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?)"
      }
      DoorDbType.POSTGRES ->  {
        "INSERT INTO ScheduledCheck (scheduledCheckUid, checkTime, checkType, checkUuid, checkParameters, scClazzLogUid, scheduledCheckMasterCsn, scheduledCheckLocalCsn, scheduledCheckLastChangedBy, scheduledCheckLct) VALUES(COALESCE(?,nextval('ScheduledCheck_scheduledCheckUid_seq')), ?, ?, ?, ?, ?, ?, ?, ?, ?)" + if(returnsId) { " RETURNING scheduledCheckUid" } else "" 
      }
      else -> {
        throw IllegalArgumentException("Unsupported db type")
      }
    }

    public override fun bindPreparedStmtToEntity(stmt: PreparedStatement, entity: ScheduledCheck):
        Unit {
      if(entity.scheduledCheckUid == 0L) {
        stmt.setObject(1, null)
      } else {
        stmt.setLong(1, entity.scheduledCheckUid)
      }
      stmt.setLong(2, entity.checkTime)
      stmt.setInt(3, entity.checkType)
      stmt.setString(4, entity.checkUuid)
      stmt.setString(5, entity.checkParameters)
      stmt.setLong(6, entity.scClazzLogUid)
      stmt.setLong(7, entity.scheduledCheckMasterCsn)
      stmt.setLong(8, entity.scheduledCheckLocalCsn)
      stmt.setInt(9, entity.scheduledCheckLastChangedBy)
      stmt.setLong(10, entity.scheduledCheckLct)
    }
  }

  public override fun deleteCheck(scheduledCheck: ScheduledCheck): Unit {
    var _numChanges = 0
    _db.prepareAndUseStatement("DELETE FROM ScheduledCheck WHERE scheduledCheckUid = ?") {
       _stmt ->
      _stmt.setLong(1, scheduledCheck.scheduledCheckUid)
      _numChanges += _stmt.executeUpdate()
    }
  }

  public override fun findPendingLogsWithoutScheduledCheck(checkType: Int, fromDate: Long):
      List<ClazzLog> {
    var _result = mutableListOf<com.ustadmobile.lib.db.entities.ClazzLog>()
    val _stmtConfig =
        PreparedStatementConfig("SELECT ClazzLog.* FROM ClazzLog  WHERE NOT EXISTS(SELECT scClazzLogUid FROM ScheduledCheck WHERE  scClazzLogUid = ClazzLog.clazzLogUid AND ScheduledCheck.checkType = ?)  AND ClazzLog.logDate >= ?"
        , postgreSql = """
    |SELECT ClazzLog.* FROM ClazzLog  WHERE NOT EXISTS(SELECT scClazzLogUid FROM ScheduledCheck WHERE  scClazzLogUid = ClazzLog.clazzLogUid AND ScheduledCheck.checkType = ?)  AND ClazzLog.logDate >= ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setInt(1, checkType)
      _stmt.setLong(2, fromDate)
      _stmt.executeQuery().useResults {
         _resultSet ->
        while(_resultSet.next()) {
          val tmp_clazzLogUid = _resultSet.getLong("clazzLogUid")
          val tmp_clazzLogClazzUid = _resultSet.getLong("clazzLogClazzUid")
          val tmp_logDate = _resultSet.getLong("logDate")
          val tmp_timeRecorded = _resultSet.getLong("timeRecorded")
          val tmp_clazzLogDone = _resultSet.getBoolean("clazzLogDone")
          val tmp_cancellationNote = _resultSet.getString("cancellationNote")
          val tmp_clazzLogCancelled = _resultSet.getBoolean("clazzLogCancelled")
          val tmp_clazzLogNumPresent = _resultSet.getInt("clazzLogNumPresent")
          val tmp_clazzLogNumAbsent = _resultSet.getInt("clazzLogNumAbsent")
          val tmp_clazzLogNumPartial = _resultSet.getInt("clazzLogNumPartial")
          val tmp_clazzLogScheduleUid = _resultSet.getLong("clazzLogScheduleUid")
          val tmp_clazzLogStatusFlag = _resultSet.getInt("clazzLogStatusFlag")
          val tmp_clazzLogMSQN = _resultSet.getLong("clazzLogMSQN")
          val tmp_clazzLogLCSN = _resultSet.getLong("clazzLogLCSN")
          val tmp_clazzLogLCB = _resultSet.getInt("clazzLogLCB")
          val tmp_clazzLogLastChangedTime = _resultSet.getLong("clazzLogLastChangedTime")
          val _entity = ClazzLog()
          _entity.clazzLogUid = tmp_clazzLogUid
          _entity.clazzLogClazzUid = tmp_clazzLogClazzUid
          _entity.logDate = tmp_logDate
          _entity.timeRecorded = tmp_timeRecorded
          _entity.clazzLogDone = tmp_clazzLogDone
          _entity.cancellationNote = tmp_cancellationNote
          _entity.clazzLogCancelled = tmp_clazzLogCancelled
          _entity.clazzLogNumPresent = tmp_clazzLogNumPresent
          _entity.clazzLogNumAbsent = tmp_clazzLogNumAbsent
          _entity.clazzLogNumPartial = tmp_clazzLogNumPartial
          _entity.clazzLogScheduleUid = tmp_clazzLogScheduleUid
          _entity.clazzLogStatusFlag = tmp_clazzLogStatusFlag
          _entity.clazzLogMSQN = tmp_clazzLogMSQN
          _entity.clazzLogLCSN = tmp_clazzLogLCSN
          _entity.clazzLogLCB = tmp_clazzLogLCB
          _entity.clazzLogLastChangedTime = tmp_clazzLogLastChangedTime
          _result.add(_entity)
        }
      }
    }
    return _result
  }

  public override fun findByUid(uid: Long): ScheduledCheck? {
    var _result = null as com.ustadmobile.lib.db.entities.ScheduledCheck??
    val _stmtConfig =
        PreparedStatementConfig("SELECT * FROM ScheduledCheck WHERE scheduledCheckUid = ?" ,
        postgreSql = """
    |SELECT * FROM ScheduledCheck WHERE scheduledCheckUid = ?
    |""".trimMargin())
    _db.prepareAndUseStatement(_stmtConfig) {
      _stmt ->
      _stmt.setLong(1, uid)
      _stmt.executeQuery().useResults {
         _resultSet ->
        if(_resultSet.next()) {
          val tmp_scheduledCheckUid = _resultSet.getLong("scheduledCheckUid")
          val tmp_checkTime = _resultSet.getLong("checkTime")
          val tmp_checkType = _resultSet.getInt("checkType")
          val tmp_checkUuid = _resultSet.getString("checkUuid")
          val tmp_checkParameters = _resultSet.getString("checkParameters")
          val tmp_scClazzLogUid = _resultSet.getLong("scClazzLogUid")
          val tmp_scheduledCheckMasterCsn = _resultSet.getLong("scheduledCheckMasterCsn")
          val tmp_scheduledCheckLocalCsn = _resultSet.getLong("scheduledCheckLocalCsn")
          val tmp_scheduledCheckLastChangedBy = _resultSet.getInt("scheduledCheckLastChangedBy")
          val tmp_scheduledCheckLct = _resultSet.getLong("scheduledCheckLct")
          val _entity = ScheduledCheck()
          _entity.scheduledCheckUid = tmp_scheduledCheckUid
          _entity.checkTime = tmp_checkTime
          _entity.checkType = tmp_checkType
          _entity.checkUuid = tmp_checkUuid
          _entity.checkParameters = tmp_checkParameters
          _entity.scClazzLogUid = tmp_scClazzLogUid
          _entity.scheduledCheckMasterCsn = tmp_scheduledCheckMasterCsn
          _entity.scheduledCheckLocalCsn = tmp_scheduledCheckLocalCsn
          _entity.scheduledCheckLastChangedBy = tmp_scheduledCheckLastChangedBy
          _entity.scheduledCheckLct = tmp_scheduledCheckLct
          _result = _entity
        }
      }
    }
    return _result
  }

  public override fun insert(entity: ScheduledCheck): Long {
    val _retVal = _insertAdapterScheduledCheck_.insertAndReturnId(entity)
    return _retVal
  }

  public override suspend fun insertAsync(entity: ScheduledCheck): Long {
    val _retVal = _insertAdapterScheduledCheck_.insertAndReturnIdAsync(entity)
    return _retVal
  }

  public override fun insertList(entityList: List<out ScheduledCheck>): Unit {
    _insertAdapterScheduledCheck_.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ScheduledCheck>): Unit {
    val _sql =
        "UPDATE ScheduledCheck SET checkTime = ?, checkType = ?, checkUuid = ?, checkParameters = ?, scClazzLogUid = ?, scheduledCheckMasterCsn = ?, scheduledCheckLocalCsn = ?, scheduledCheckLastChangedBy = ?, scheduledCheckLct = ? WHERE scheduledCheckUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.getConnection().setAutoCommit(false)
      for(_entity in entityList) {
        _stmt.setLong(1, _entity.checkTime)
        _stmt.setInt(2, _entity.checkType)
        _stmt.setString(3, _entity.checkUuid)
        _stmt.setString(4, _entity.checkParameters)
        _stmt.setLong(5, _entity.scClazzLogUid)
        _stmt.setLong(6, _entity.scheduledCheckMasterCsn)
        _stmt.setLong(7, _entity.scheduledCheckLocalCsn)
        _stmt.setInt(8, _entity.scheduledCheckLastChangedBy)
        _stmt.setLong(9, _entity.scheduledCheckLct)
        _stmt.setLong(10, _entity.scheduledCheckUid)
        _stmt.executeUpdate()
      }
      _stmt.getConnection().commit()
    }
  }

  public override fun update(entity: ScheduledCheck): Unit {
    val _sql =
        "UPDATE ScheduledCheck SET checkTime = ?, checkType = ?, checkUuid = ?, checkParameters = ?, scClazzLogUid = ?, scheduledCheckMasterCsn = ?, scheduledCheckLocalCsn = ?, scheduledCheckLastChangedBy = ?, scheduledCheckLct = ? WHERE scheduledCheckUid = ?"
    _db.prepareAndUseStatement(_sql) {
       _stmt ->
      _stmt.setLong(1, entity.checkTime)
      _stmt.setInt(2, entity.checkType)
      _stmt.setString(3, entity.checkUuid)
      _stmt.setString(4, entity.checkParameters)
      _stmt.setLong(5, entity.scClazzLogUid)
      _stmt.setLong(6, entity.scheduledCheckMasterCsn)
      _stmt.setLong(7, entity.scheduledCheckLocalCsn)
      _stmt.setInt(8, entity.scheduledCheckLastChangedBy)
      _stmt.setLong(9, entity.scheduledCheckLct)
      _stmt.setLong(10, entity.scheduledCheckUid)
      _stmt.executeUpdate()
    }
  }
}
