package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDataSourceFactory
import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.door.DoorLiveData
import com.ustadmobile.lib.db.entities.Language
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class LanguageDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: LanguageDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : LanguageDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun insertListAsync(languageList: List<Language>): Unit {
    _dao.insertListAsync(languageList)
  }

  public override fun findLanguagesAsSource(sortOrder: Int, searchText: String):
      DoorDataSourceFactory<Int, Language> {
    val _result = _dao.findLanguagesAsSource(sortOrder, searchText)
    return _result
  }

  public override fun findLanguagesList(): List<Language> {
    val _result = _dao.findLanguagesList()
    return _result
  }

  public override fun findByName(name: String): Language? {
    val _result = _dao.findByName(name)
    return _result
  }

  public override fun findByTwoCode(langCode: String): Language? {
    val _result = _dao.findByTwoCode(langCode)
    return _result
  }

  public override suspend fun findByTwoCodeAsync(langCode: String): Language? {
    val _result = _dao.findByTwoCodeAsync(langCode)
    return _result
  }

  public override fun findByThreeCode(langCode: String): Language? {
    val _result = _dao.findByThreeCode(langCode)
    return _result
  }

  public override fun totalLanguageCount(): Int {
    val _result = _dao.totalLanguageCount()
    return _result
  }

  public override fun update(entity: Language): Unit {
    _dao.update(entity)
  }

  public override fun findByUid(primaryLanguageUid: Long): Language? {
    val _result = _dao.findByUid(primaryLanguageUid)
    return _result
  }

  public override suspend fun findByUidAsync(primaryLanguageUid: Long): Language? {
    val _result = _dao.findByUidAsync(primaryLanguageUid)
    return _result
  }

  public override suspend fun updateAsync(entity: Language): Int {
    val _result = _dao.updateAsync(entity)
    return _result
  }

  public override fun findAllLanguageLive(): DoorLiveData<List<Language>> {
    val _result = _dao.findAllLanguageLive()
    return _result
  }

  public override fun findByUidList(uidList: List<Long>): List<Long> {
    val _result = _dao.findByUidList(uidList)
    return _result
  }

  public override suspend fun toggleVisibilityLanguage(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    updateTime: Long
  ): Unit {
    _dao.toggleVisibilityLanguage(toggleVisibility, selectedItem, updateTime)
  }

  public override fun replaceList(entityList: List<Language>): Unit {
    _dao.replaceList(entityList)
  }

  public override fun insert(entity: Language): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: Language): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out Language>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out Language>): Unit {
    _dao.updateList(entityList)
  }
}
