package com.ustadmobile.core.db.dao

import com.ustadmobile.door.DoorDatabase
import com.ustadmobile.door.DoorDatabaseRepository
import com.ustadmobile.lib.db.entities.ClazzContentJoin
import io.ktor.client.HttpClient
import kotlin.Boolean
import kotlin.Long
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List

@Suppress("REDUNDANT_PROJECTION", "LocalVariableName", "ClassName")
public class ClazzContentJoinDao_Repo(
  public val _db: DoorDatabase,
  public val _repo: DoorDatabaseRepository,
  public val _dao: ClazzContentJoinDao,
  public val _httpClient: HttpClient,
  public val _clientId: Long,
  public val _endpoint: String
) : ClazzContentJoinDao() {
  public override suspend fun replicateOnNewNode(newNodeId: Long): Unit {
    _dao.replicateOnNewNode(newNodeId)
  }

  public override suspend fun replicateOnChange(): Unit {
    _dao.replicateOnChange()
  }

  public override suspend fun toggleVisibilityClazzContent(
    toggleVisibility: Boolean,
    selectedItem: List<Long>,
    changedTime: Long
  ): Unit {
    _dao.toggleVisibilityClazzContent(toggleVisibility, selectedItem, changedTime)
  }

  public override suspend fun listOfEntriesInClazz(clazzUid: Long): List<Long> {
    val _result = _dao.listOfEntriesInClazz(clazzUid)
    return _result
  }

  public override fun insert(entity: ClazzContentJoin): Long {
    val _result = _dao.insert(entity)
    return _result
  }

  public override suspend fun insertAsync(entity: ClazzContentJoin): Long {
    val _result = _dao.insertAsync(entity)
    return _result
  }

  public override fun insertList(entityList: List<out ClazzContentJoin>): Unit {
    _dao.insertList(entityList)
  }

  public override fun updateList(entityList: List<out ClazzContentJoin>): Unit {
    _dao.updateList(entityList)
  }

  public override fun update(entity: ClazzContentJoin): Unit {
    _dao.update(entity)
  }
}
